import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';

import { NavController, Platform, PopoverController, ToastController } from "@ionic/angular";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { ActivatedRoute, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { MenuController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "./shared-services/user-service/user.service";
import { Observable, Subscription } from "rxjs";
import { AccountEquipoService, TManageAccountEquipoGrants } from "./shared-services/account-equipo/account-equipo.service";
import { map } from "rxjs/operators";
import { HandballTimerService } from "./shared-services/timer/handball-timer.service";
import { CoreService } from "./shared-services/core.service";
import { NGXLogger } from "ngx-logger";
import { CommonUserModel } from "./shared-services/model/common-user.model";
import { NetworkErrorPopoverComponent } from "./main/pages/aehandler-module/pages/game-module/pages/manage-module/components/network-error-popover/network-error-popover.component";
import { I18nService } from "./i18n/i18n.service";
import { IconService } from "./shared-services/icons-service/icon.service";
import { TokenDto } from './api/hai-api';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _user$: Observable<CommonUserModel>;
  private _user: CommonUserModel;
  private userSubscription: Subscription;
  public isOpen: boolean;
  private superAdminToken: string;
  public get accountId() {
    return this.core.accountEquipoService.selectedAccountEquipo$.getValue()?.id ?? -1;
  }
  public disableMenu = false;
  public isMobileScreen: boolean;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenSize();
  }

  private _accountEquipos$ = new Observable<TManageAccountEquipoGrants[]>(null);
  get accountEquipos$() {
    return this._accountEquipos$;
  }
  private _selectedAccountEquipo$ = new Observable<TManageAccountEquipoGrants>(null);
  get selectedAccountEquipo$() {
    return this._selectedAccountEquipo$;
  }
  private _gameEnded$ = new Observable<boolean>(null);
  get gameEnded$() {
    return this._gameEnded$;
  }

  get isSuperAdmin$() {
    return this.userService.isSuperAdmin$;
  }

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navCtrl: NavController,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public menuController: MenuController,
    private readonly i18nService: I18nService,
    private readonly translate: TranslateService,
    private readonly userService: UserService,
    private readonly timerService: HandballTimerService,
    private readonly accountEquipoService: AccountEquipoService,
    public readonly core: CoreService,
    private readonly popoverCntl: PopoverController,
    private readonly logger: NGXLogger,
    private readonly toastCntl: ToastController,
    private readonly iconService: IconService
  ) {
    this.initializeApp();
    this.iconService.registerExternalIcons();

    this.activatedRoute.queryParams.subscribe((params) => {
      const lang = params.lang as string;
      const allowedLangs = ["en", "es", "de", "fr"];
      if (lang && allowedLangs.includes(lang)) {
        this.i18nService.curLangCode = { code: lang, label: lang.toUpperCase() };
      }
    });
    this._user$ = this.userService.user$;
    this._accountEquipos$ = this.accountEquipoService.accountEquipos$.pipe(map((itm) => itm?.filter((row) => !row.deleted).sort((a, b) => b.id - a.id)));
    this._gameEnded$ = this.timerService.gameEnded$;
    this._selectedAccountEquipo$ = this.accountEquipoService.selectedAccountEquipo$;
    this.userSubscription = this.user$.subscribe((u) => {
      this._user = u;
    });

    let length = false,
      enabled = false;
    this.core.gameDataService.gameSystems$.subscribe((data) => {
      length = data?.length ? true : false;
      document.body.style.setProperty("--hai-gamesystem-enabled-popwidth", length && enabled ? "80%" : "--auto");
    });
    this.core.gameDataService.gameSystemEnabled$.subscribe((data) => {
      enabled = data;
      document.body.style.setProperty("--hai-gamesystem-enabled-popwidth", length && enabled ? "80%" : "--auto");
    });
  }

  ngOnInit(): void {
    this.checkScreenSize();
    window.addEventListener('resize', () => {
      this.checkScreenSize();
    });
    this.router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        this.disableMenu = this.router.url.includes("/game/manage/") || this.router.url.startsWith("/public/") || this.router.url.startsWith("/auth");
        this.menuController.close();
        this.isOpen = false;
      } else if (value instanceof NavigationCancel) {
        this.disableMenu = this.router.url.startsWith('/auth');
      }
    });
  }

  private checkScreenSize() {
    this.isMobileScreen = window.innerWidth < 768;
  }

  async onLoginBackToSuperAdminClick() {
    await this.core.loadingService.present();
    await this.core.accountEquipoService.fireAccountEquipoChange(null);
    const accessToken = await this.core.storageService.getSuperAdminAccessTokenAsPromise();
    const refresherToken = await this.core.storageService.getSuperAdminRefresherTokenAsPromise();
    const tokenDto: TokenDto = {
      accessToken,
      refresherToken
    };
    await this.userService.loginByToken(tokenDto);
    await this.core.storageService.wipeOutSuperAdminTokens();
    await this.core.loadingService.dismiss();
    this.router.navigate(['/super-admin']);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    // this.authService.getToken();
    this.translate.setDefaultLang("en");
  }

  onAccountEquipoSelectChange(event) {
    this.userService.setAccountEquipo(event);
  }

  // When Logout Button is pressed
  async logout() {
    await this.menuController.close();
    // this._user = null;
    setTimeout(async () => {
      await this.userService.logout();
      this.disableMenu = true;
      this.navCtrl.navigateRoot("/");
    }, 50);
  }

  get user$(): Observable<CommonUserModel> {
    return this._user$;
  }

  get user(): CommonUserModel {
    return this._user;
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  handleNoPermission = (prop) => this.userService.handleNoPermission(prop, true);

  goToLink(url) {
    window.open(url, "_blank")?.focus();
  }

  public menuOpened(): void {
    this.logger.debug("AppComponent.menuOpened");
  }

  private async presentAlert(): Promise<void> {
    await this.popoverCntl.dismiss();
    const popover = await this.popoverCntl.create({
      component: NetworkErrorPopoverComponent,
      cssClass: "global__team-verification-popover",
      // translucent: true,
      backdropDismiss: false,
      mode: "ios",
    });
    await popover.present();
  }

  humanizeDevice = (device: string) => ({ pwa: "web" }[device] ?? device);

  async toggleMenu() {
    if (this.isMobileScreen) {
      this.core.toggleMobileDevice();
      this.isOpen = false;
    } else {
      this.isOpen = !this.isOpen;
    }
  }

  onRouteSelect() {
    if (this.isMobileScreen) {
      this.toggleMenu();
    }
  }
}
