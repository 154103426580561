import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init, browserTracingIntegration, replayIntegration } from '@sentry/angular';

init({
  dsn: "https://0793915fc1f4471d80ae0b1a95f78499@sentry.arsahosting.com/5",
  environment: environment.name,
  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracePropagationTargets: ["localhost", environment.apiPath],
  //routingInstrumentation: angularRouterInstrumentation(), // Updated function
  tracesSampleRate: 1.0, // Adjust for production
  // autoSessionTracking: true,

  // Session Replay
  replaysSessionSampleRate: 0.0, // Disable automatic sessions recording even on errors
  replaysOnErrorSampleRate: 0.0 // Disable automatic sessions recording even on errors
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
