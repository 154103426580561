/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SeasonDto { 
    /**
     * season id
     */
    id?: number;
    /**
     * Season name
     */
    seasonName: string;
    /**
     * user id
     */
    accountEquipoId: number;
    /**
     * true if it is current season
     */
    isCurrent: boolean;
    /**
     * deleted flag
     */
    deleted: boolean;
}

