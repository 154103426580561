/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateUserDto { 
    /**
     * First name
     */
    firstName: string;
    /**
     * Last name
     */
    lastName: string;
    /**
     * E-Mail
     */
    email: string;
    /**
     * password
     */
    password: string;
    /**
     * Street Name
     */
    street: string;
    /**
     * City
     */
    city: string;
    /**
     * Postal Code
     */
    postalCode: string;
    /**
     * Country
     */
    country: string;
    /**
     * Accept newsletter
     */
    receiveNewsletter?: boolean;
    /**
     * Language Code
     */
    lang: string;
}

