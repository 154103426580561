<div class="content-wrapper">
  <div class="button-container lg:whitespace-nowrap pb-4">
    <button
      (click)="openPdf()"
      [ngClass]="!core.isMobileDevice ? 'rounded-3xl p-2 px-8 py-3' : ''"
      class="
        print-button
        focus:outline-none focus:ring focus:border-blue-400
        active:bg-blue-800
      "
    >
      {{ "DOWNLOAD PDF" | translate }}
      <ion-icon *ngIf="!core.isMobileDevice" name="print" class="pl-2"></ion-icon>
    </button>
    <button
        [ngClass]="!core.isMobileDevice ? 'rounded-xl p-2 px-8 py-3' : ''"
        *ngIf="(core.accountEquipoService.selectedAccountEquipo$ | async)?.account?.permissions.accessScouting
              && (core.accountEquipoService.selectedAccountEquipo$ | async)?.account?.permissions.pastGames
              && (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('view_game')
              && (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('scouting')"
        (click)="matchReport()"
        class="match-report-button
        focus:outline-none focus:ring focus:border-blue-400
        active:bg-blue-800
        capitalize
      "
    >
      {{ "MATCH REPORT" | translate }}
    </button>
    <button
        [ngClass]="!core.isMobileDevice ? 'rounded-xl p-2 px-8 py-3' : ''"
        *ngIf="(core.accountEquipoService.selectedAccountEquipo$ | async)?.account?.permissions.pastGames
              && (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('view_game')
              && (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('create_game')
              && (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('dashboard')
              && (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('manage_teams')"
        (click)="onDownloadPlayerScoreClick()"
        class="player-score-button"
    >
      {{ "Download Player Score" | translate }}
    </button>
  </div>

  <app-custom-tabs
    type="main"
    class="page-tabs flex-auto"
    (selection)="selectedTab = $event"
  >
    <app-custom-tab
      type="main"
      [label]="'QUICK STATS' | translate"
      [fullHeight]="true"
      [selected]="selectedTab"
      [defaultTab]="true"
    >

      <ng-select
        class="mobile-tab-selector mt-4"
        maxSelectedItems="1"
        [clearable]="false"
        [(ngModel)]="selectedSubTab">
        <ng-option [value]="'OVERVIEW' | translate">
          <span class="select-options-text">
            {{ 'OVERVIEW' | translate }}
          </span>
        </ng-option>
        <ng-option [value]="(quickStatsStateHelper.getObservableByName('quickStatsViewModelList') | async)[0]?.teamName">
          <span class="select-options-text">
            {{ (quickStatsStateHelper.getObservableByName('quickStatsViewModelList') | async)[0]?.teamName }}
          </span>
        </ng-option>
        <ng-option [value]="(quickStatsStateHelper.getObservableByName('quickStatsViewModelList') | async)[1]?.teamName">
          <span class="select-options-text">
            {{ (quickStatsStateHelper.getObservableByName('quickStatsViewModelList') | async)[1]?.teamName }}
          </span>
        </ng-option>
      </ng-select>
      <app-custom-tabs
        class="page-tabs subtabs flex-auto"
        (selection)="selectedSubTab = $event"
      >
        <app-custom-tab
          [label]="'OVERVIEW' | translate"
          [fullHeight]="true"
          [selected]="selectedSubTab"
          [defaultTab]="true"
        >
          <app-quick-stats
            *ngIf="('OVERVIEW' | translate) == (selectedSubTab | translate)"
            [quickStatsColumnModelList]="
              quickStatsStateHelper.getObservableByName('quickStatsViewModelList')
                | async
            "
            [homeGoalsConnectionsPlPosModel]="
              quickStatsStateHelper.getObservableByName(
                'homeGoalsConnectionsPlPosModel'
              ) | async
            "
            [visitorGoalsConnectionsPlPosModel]="
              quickStatsStateHelper.getObservableByName(
                'visitorGoalsConnectionsPlPosModel'
              ) | async
            "
            [homeGoalsPositionModel]="
              quickStatsStateHelper.getObservableByName('homeGoalPositionList')
                | async
            "
            [visitorGoalsPositionModel]="
              quickStatsStateHelper.getObservableByName('visitorGoalPositionList')
                | async
            "
            [homeGoalCounters]="
              quickStatsStateHelper.getObservableByName('homeGoalCounter') | async
            "
            [visitorGoalCounters]="
              quickStatsStateHelper.getObservableByName('visitorGoalCounter')
                | async
            "
            [semaphorModel]="
              quickStatsStateHelper.getObservableByName('victoryPercentage')
                | async
            "
            [screenWidth]="screenWidth$ | async"
          ></app-quick-stats>
        </app-custom-tab>
        <app-custom-tab
            [label]="'TIMELINE' | translate"
            [fullHeight]="true"
            [selected]="selectedSubTab"
        >
          <app-quick-stats-timeline
              [timelineCalculationStats]="quickStatsStateHelper
                    .getObservableByName('timelineStatsAggregatedModel') | async"
            [homeTeam]="homeName"
            [visitorTeam]="visitorName"
          />
        </app-custom-tab>
        <app-custom-tab
          [label]="
            (quickStatsStateHelper.getObservableByName('quickStatsViewModelList')
              | async)[0]?.teamName
          "
          [fullHeight]="true"
          [selected]="selectedSubTab"
        >
          <app-quick-stats-team
            [simplifiedPlayerModels]="
              teamOverviewStatsStateHelper.getObservableByName(
                'homeIndividualSimplifiedStatsTeamOverview'
              ) | async
            "
            [accumulatedCounterListWithTiming]="
              timelineStatsStateHelper.getObservableByName(
                'accumulatedCounterList'
              ) | async
            "
            [teamLabels]="
              timelineStatsStateHelper.getObservableByName('goalsFirstHalf')
                | async
            "
            [screenWidth]="screenWidth$ | async"
          ></app-quick-stats-team>
          <app-quick-stats-team-team-stats
            [quickStatsColumnModelList]="
              quickStatsStateHelper.getObservableByName('quickStatsViewModelList')
                | async
            "
            [quickGoalCounters]="
              quickStatsStateHelper.getObservableByName('homeGoalCounter') | async
            "
            [isHomeTeam]="true"
          ></app-quick-stats-team-team-stats>
        </app-custom-tab>
        <app-custom-tab
          [label]="
            (quickStatsStateHelper.getObservableByName('quickStatsViewModelList')
              | async)[1]?.teamName
          "
          [fullHeight]="true"
          [selected]="selectedSubTab"
        >
          <app-quick-stats-team
            [simplifiedPlayerModels]="
              teamOverviewStatsStateHelper.getObservableByName(
                'visitorIndividualSimplifiedStatsTeamOverview'
              ) | async
            "
            [accumulatedCounterListWithTiming]="
              timelineStatsStateHelper.getObservableByName(
                'accumulatedCounterList'
              ) | async
            "
            [teamLabels]="
              timelineStatsStateHelper.getObservableByName('goalsFirstHalf')
                | async
            "
            [screenWidth]="screenWidth$ | async"
          ></app-quick-stats-team>
          <app-quick-stats-team-team-stats
            [quickStatsColumnModelList]="
              quickStatsStateHelper.getObservableByName('quickStatsViewModelList')
                | async
            "
            [quickGoalCounters]="
              quickStatsStateHelper.getObservableByName('visitorGoalCounter')
                | async
            "
            [isHomeTeam]="false"
          ></app-quick-stats-team-team-stats>
        </app-custom-tab>
      </app-custom-tabs>
    </app-custom-tab>
    <app-custom-tab
      type="main"
      [label]="'ADVANCED STATS' | translate"
      [fullHeight]="true"
      [selected]="selectedTab"
      class="page-tabs subtabs flex-auto"
    >
      <app-advanced-stats
        *ngIf="('ADVANCED STATS' | translate) == (selectedTab | translate)"
        [isPublic]="isPublic"
        [isGameTracker]="isGameTracker"
        [game]="game"
        [editable]="editable"
      ></app-advanced-stats>
    </app-custom-tab>
  </app-custom-tabs>
</div>
