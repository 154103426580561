<div class="confirm-modal-wrapper my-8">
  <div class="py-4 w-full">
    <span>{{ 'Edit Position' | translate }}</span>
    <hr class="mx-auto my-2" />
    <span class="explanation-text">{{ playerName }}</span>
  </div>
  <div class="input-group text-black mt-2">
    <ng-select
        [placeholder]="'Position'"
        class="custom"
        maxSelectedItems="1"
        [searchable]="true"
        [clearable]="false"
        [multiple]="false"
        [(ngModel)]="currentPosition"
        bindLabel="position"
        appendTo="body"
    >
      <ng-option *ngFor="let pos of startingPositions" [value]="pos.code">
        {{ pos.name }}
      </ng-option>
    </ng-select>
    <div class="button-wrapper mt-6">
      <span (click)="onCancel()" class="close-btn">{{ 'Close' | translate }}</span>
      <ion-button color="primary" (click)="onConfirm()">{{'Confirm' | translate }}</ion-button>
    </div>
  </div>
</div>
