import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CounterModel } from '@handballai/stats-calculation';
import { ExternalGoalkeeperEfficiencyModel } from '@handballai/stats-calculation';
import { NGXLogger } from 'ngx-logger';
import { EfficiencyTableModel } from 'src/app/main/components/shots-stats/shot-stats.model';
import { transformExternalModelToTableModel } from 'src/app/main/components/shots-stats/shot-stats.helper';
import { CoreService } from 'src/app/shared-services/core.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  DoughnutController, ChartType
} from 'chart.js';

Chart.register(
    ArcElement,
    Tooltip,
    Legend,
    Title,
    ChartDataLabels,
    DoughnutController
);


@Component({
    selector: 'app-shots-stats',
    templateUrl: './shots-stats.component.html',
    styleUrls: ['./shots-stats.component.scss'],
    standalone: false
})
export class ShotsStatsComponent implements OnInit {

  @ViewChild('doughnut1Canvas', { static: false }) doughnut1Canvas: any;
  @ViewChild('doughnut2Canvas', { static: false }) doughnut2Canvas: any;
  @ViewChild('doughnut4Canvas', { static: false }) doughnut4Canvas: any;
  @ViewChild('doughnut5Canvas', { static: false }) doughnut5Canvas: any;

  doughnut1Chart: any;
  doughnut2Chart: any;
  doughnut4Chart: any;
  doughnut5Chart: any;


  chartOptions: any = {
    plugins: {
      title: {
        text: 'SAVES / GOALS %',
        display: true,
        font: {
          size: 20
        },
        color: '#0e375f'
      },
      datalabels: {
        color: '#0e375f',
        font: {
          size: 14
        },
        formatter: (value: number, context: any) => {
          if (!value) return '';
          const dataset = context.dataset;
          const total = dataset.data.reduce((acc: number, data: number) => acc + data, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value} [${percentage}%]`;
        }
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 14
          },
          color: '#0e375f'
        }
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const datasetLabel = context.label;
            const datasetValue = context.dataset.data[context.dataIndex];

            const totalDataValue = context.dataset.data.reduce(
                (sum, currentValue) => sum + currentValue,
                0
            );
            const percentage = (datasetValue / totalDataValue) * 100;

            return `${datasetLabel}: ${datasetValue} [${percentage.toFixed(0)}%]`;
          }
        }
      }
    }
  };

  chart2Options: any = {
    plugins: {
      title: {
        text: 'GOALS / NO GOALS %',
        display: true,
        font: {
          size: 20
        },
        color: '#0e375f'
      },
      datalabels: {
        color: '#0e375f',
        font: {
          size: 14
        },
        formatter: (value: number, context: any) => {
          if (!value) return '';
          const dataset = context.dataset;
          const total = dataset.data.reduce((acc: number, data: number) => acc + data, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value} [${percentage}%]`;
        }
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 14
          },
          color: '#0e375f'
        }
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const datasetLabel = context.label;
            const datasetValue = context.dataset.data[context.dataIndex];

            const totalDataValue = context.dataset.data.reduce(
                (sum, currentValue) => sum + currentValue,
                0
            );
            const percentage = (datasetValue / totalDataValue) * 100;

            return `${datasetLabel}: ${datasetValue} [${percentage.toFixed(0)}%]`;
          }
        }
      }
    }
  };

  chart3Options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: true,
        text: 'GOALS / FAILED SHOTS %',
        font: {
          size: 20
        },
        color: '#0e375f'
      },
      datalabels: {
        color: '#0e375f',
        font: {
          size: 14
        },
        formatter: (value: number, context: any) => {
          if (!value) return '';
          const dataset = context.dataset;
          const total = dataset.data.reduce((acc: number, data: number) => acc + data, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value} [${percentage}%]`;
        }
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.dataset.data[context.dataIndex];
            const total = context.dataset.data.reduce(
                (sum, currentValue) => sum + currentValue,
                0
            );
            const percentage = ((value / total) * 100).toFixed(0);
            return `${context.label}: ${value} [${percentage}%]`;
          }
        }
      }
    }
  };

  public cOptions: any = {
    title: {
      text: 'SAVES / GOALS %',
      display: true,
      fontSize: 20,
      fontColor: '#0e375f'
    },
    plugins: {
      datalabels: { font: { size: 80 }},
      labels: {
        fontSize: 14,
        fontColor: '#0e375f',
        render: (args: { value: number; percentage: number; label: string }) => `${args.value} [${args.percentage}%]`
      }
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const datasetLabel = data.labels[tooltipItem.index];
          const datasetValue = data.datasets[0].data[tooltipItem.index];

          const dataset = data.datasets[0];
          const value = dataset.data[tooltipItem.index] as number;
          const dataValues: number[] = dataset.data as number[];

          const totalDataValue = dataValues.reduce((sum, currentValue) => sum + currentValue, 0);
          const percentage = (value / totalDataValue) * 100;

          return `${datasetLabel}: ${datasetValue} [${percentage.toFixed(0)}%]`;
        }
      }
    },
  };
  public c2Options: any = {
    title: {
      text: 'GOALS / NO GOALS %',
      display: true,
      fontSize: 20,
      fontColor: '#0e375f'
    },
    plugins: {
      datalabels: { font: { size: 80 }},
      labels: {
        fontSize: 14,
        fontColor: '#0e375f',
        render: (args: { value: number; percentage: number; label: string }) => `${args.value} [${args.percentage}%]`
      }
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const datasetLabel = data.labels[tooltipItem.index];
          const datasetValue = data.datasets[0].data[tooltipItem.index];

          const dataset = data.datasets[0];
          const value = dataset.data[tooltipItem.index] as number;
          const dataValues: number[] = dataset.data as number[];

          const totalDataValue = dataValues.reduce((sum, currentValue) => sum + currentValue, 0);
          const percentage = (value / totalDataValue) * 100;

          return `${datasetLabel}: ${datasetValue} [${percentage.toFixed(0)}%]`;
        }
      }
    },
  };
  public circleColors: any[] = [{ backgroundColor: ['#ECB22E', '#2EB67D'] }];
  public circleColors2: any[] = [{ backgroundColor: ['#ECB22E', '#2EB67D'] }];
  public homeGoalSavesValues: number[] = [0, 0];
  public homeGoalNoGoalValues: number[] = [0, 0];
  public visitorGoalSavesValues: number[] = [0, 0];
  public visitorGoalNoGoalValues: number[] = [0, 0];


 // public doughnutChartData: number[] = [0, 0]; // Initial values
  public doughnutChartType: ChartType = 'doughnut';
  // Updated color configuration for ng2-charts v3
  public doughnutChartDataSet = [
    {
      data: this.homeGoalNoGoalValues,
      backgroundColor: ['#ECB22E', '#2EB67D'], // Set colors directly in the dataset
    }
  ];
  public doughnutChartLabels: any[] = ['GOALS', 'NO GOALS'];

/*
  public doughnutChartData: ChartData = {
    labels: this.doughnutChartLabels,
    datasets: [{
      data: this.doughnutChartData,
      backgroundColor: ['#ECB22E', '#2EB67D'], // Set colors directly in the dataset
    }]
  };*/

  public doughnutChartOptions = {
    responsive: true,
    legend: { position: 'top' },
    title: {
      display: true,
      text: 'GOALS / NO GOALS %',
      fontSize: 20,
      fontColor: '#0e375f',
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[0];
          const value = dataset.data[tooltipItem.index] as number;
          const total = dataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
          const percentage = ((value / total) * 100).toFixed(0);
          return `${data.labels[tooltipItem.index]}: ${value} [${percentage}%]`;
        },
      },
    },
  };

  columns = [
    { prop: 'goalkeeperLabel', name: '', sortable: false, frozenLeft: true, resizable: false },
    { prop: 'total', resizable: false },
    { prop: 'min15eff', resizable: false },
    { prop: 'min30eff', resizable: false },
    { prop: 'min45eff', resizable: false },
    { prop: 'min60eff', resizable: false },
  ];

  columnsFH = [
    { prop: 'goalkeeperLabel', name: '', sortable: false, frozenLeft: true, resizable: false },
    { prop: 'min10eff', resizable: false },
    { prop: 'min20eff', resizable: false },
    { prop: 'min30eff', resizable: false },
    { prop: 'extraFHeff', resizable: false },
    { prop: 'totalFH', resizable: false },
  ];
  columnsSH = [
    { prop: 'goalkeeperLabel', name: '', sortable: false, frozenLeft: true, resizable: false },
    { prop: 'min40eff', resizable: false },
    { prop: 'min50eff', resizable: false },
    { prop: 'min60eff', resizable: false },
    { prop: 'extraSHeff', resizable: false },
    { prop: 'total', resizable: false },
  ];

  private _homeGoalCounters: CounterModel[] = [];
  private _visitorGoalCounters: CounterModel[] = [];
  private _homeGoalkeeperEfficiencyCounters: ExternalGoalkeeperEfficiencyModel[];
  private _visitorGoalkeeperEfficiencyCounters: ExternalGoalkeeperEfficiencyModel[];
  private _homeEfficiencyTableModel: EfficiencyTableModel[] = [];
  private _visitorEfficiencyTableModel: EfficiencyTableModel[] = [];
  private _homeTeamLabel = '';
  private _visitorTeamLabel = '';
  private _homeGoalConnectionCounter: CounterModel[] = [];
  private _visitorGoalConnectionCounter: CounterModel[] = [];
  public tablesAllColsShown = false;
  public tablesGKAllColsShown = false;

  constructor(
    public readonly core: CoreService,
    private readonly logger: NGXLogger,
  ) { }

  ngOnInit(): void { }

  createDoughnutChart(data: number[], labels: string[], chartType: string, canvasType: string, options: any) {
    if (!this[canvasType]) return;
    const ctx = this[canvasType].nativeElement.getContext('2d');

    if (this[chartType]) {
      this[chartType].destroy();
    }

    this[chartType] = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels,
        datasets: [{
          data: data,
          backgroundColor: this.circleColors[0].backgroundColor,
          borderColor: this.circleColors[0].borderColor,
          borderWidth: 1
        }]
      },
      options
    });
  }

  get homeGoalCounters(): CounterModel[] {
    return this._homeGoalCounters;
  }

  @Input()
  set homeGoalCounters(value: CounterModel[]) {
    this._homeGoalCounters = value;
    this.logger.debug('ShotsStatsComponent.homeGoalCounters', value);
    if (value.length > 1) {
      this.visitorGoalSavesValues = [
        value.filter(c => c.counterType === 'GOAL')[0].counter,
        value.filter(c => c.counterType === 'SAVE')[0].counter,
      ];
      this.visitorGoalNoGoalValues = [
        value.filter(c => c.counterType === 'GOAL')[0].counter,
        value.filter(c => c.counterType === 'POST_OUT')[0].counter
        + value.filter(c => c.counterType == 'SAVE')[0].counter
      ];
      setTimeout(() => {
        this.createDoughnutChart(this.visitorGoalSavesValues, ['GOALS', 'SAVES'], 'doughnut4Chart', 'doughnut4Canvas', this.chartOptions);
        this.createDoughnutChart(this.visitorGoalNoGoalValues, ['GOALS', 'FAILED SHOTS'], 'doughnut5Chart', 'doughnut5Canvas', this.chart2Options);
      }, 200);
    }
  }

  get visitorGoalCounters(): CounterModel[] {
    return this._visitorGoalCounters;
  }

  public onExpandTableColumnsClick() {
    this.tablesAllColsShown = !this.tablesAllColsShown;
  }

  public onExpandGKTableColumnsClick() {
    this.tablesGKAllColsShown = !this.tablesGKAllColsShown;
  }

  @Input()
  set visitorGoalCounters(value: CounterModel[]) {
    this._visitorGoalCounters = value;
    this.logger.debug('ShotsStatsComponent.visitorGoalCounters', value);
    if (value.length > 1) {
      this.homeGoalSavesValues = [
        value.filter(c => c.counterType === 'GOAL')[0].counter,
        value.filter(c => c.counterType === 'SAVE')[0].counter,
      ];
      this.homeGoalNoGoalValues = [
        value.filter(c => c.counterType === 'GOAL')[0].counter,
        value.filter(c => c.counterType === 'POST_OUT')[0].counter
        + value.filter(c => c.counterType == 'SAVE')[0].counter
      ];
      this.doughnutChartDataSet[0].data = this.homeGoalNoGoalValues;
      setTimeout(() => {
        this.createDoughnutChart(this.homeGoalSavesValues, ['GOALS', 'SAVES'], 'doughnut1Chart', 'doughnut1Canvas', this.chartOptions);
        this.createDoughnutChart(this.homeGoalNoGoalValues, ['GOALS', 'NO GOALS'], 'doughnut2Chart', 'doughnut2Canvas', this.chart2Options);
       // this.createDoughnutChart(this.homeGoalNoGoalValues, ['GOALS', 'NO GOALS'], 'doughnut3Chart', 'doughnut3Canvas', this.chart3Options);
      }, 200);
    }
  }


  get homeGoalConnectionCounter(): CounterModel[] {
    return this._homeGoalConnectionCounter;
  }

  @Input()
  set homeGoalConnectionCounter(value: CounterModel[]) {
    this._homeGoalConnectionCounter = value;
  }

  get visitorGoalConnectionCounter(): CounterModel[] {
    return this._visitorGoalConnectionCounter;
  }

  @Input()
  set visitorGoalConnectionCounter(value: CounterModel[]) {
    this._visitorGoalConnectionCounter = value;
  }

  get homeGoalkeeperEfficiencyCounters(): ExternalGoalkeeperEfficiencyModel[] {
    return this._homeGoalkeeperEfficiencyCounters;
  }

  @Input()
  set homeGoalkeeperEfficiencyCounters(value: ExternalGoalkeeperEfficiencyModel[]) {
    this._homeGoalkeeperEfficiencyCounters = value;
    this.logger.debug('HomeGoalKeeperEfficiency: ', value);
    this._homeEfficiencyTableModel = [...this._homeGoalkeeperEfficiencyCounters]
        .map(val => transformExternalModelToTableModel(val));
  }

  get visitorGoalkeeperEfficiencyCounters(): ExternalGoalkeeperEfficiencyModel[] {
    return this._visitorGoalkeeperEfficiencyCounters;
  }

  @Input()
  set visitorGoalkeeperEfficiencyCounters(value: ExternalGoalkeeperEfficiencyModel[]) {
    this._visitorGoalkeeperEfficiencyCounters = value;
    this.logger.debug('VisitorGoalKeeperEfficiency: ', value);
    this._visitorEfficiencyTableModel = [...this._visitorGoalkeeperEfficiencyCounters]
        .map(val => transformExternalModelToTableModel(val));
  }

  get homeEfficiencyTableModel(): EfficiencyTableModel[] {
    return this._homeEfficiencyTableModel;
  }

  get visitorEfficiencyTableModel(): EfficiencyTableModel[] {
    return this._visitorEfficiencyTableModel;
  }

  get homeTeamLabel(): string {
    return this._homeTeamLabel;
  }

  @Input()
  set homeTeamLabel(value: string) {
    if (value && value.length > 0) {
      this._homeTeamLabel = value;
    }
  }

  get visitorTeamLabel(): string {
    return this._visitorTeamLabel;
  }

  @Input()
  set visitorTeamLabel(value: string) {
    if (value && value.length > 0) {
      this._visitorTeamLabel = value;
    }
  }

}

