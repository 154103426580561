import { Injectable } from '@angular/core';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import {
    EventCountersModel,
    IndividualStatsEventModelList, IndividualStatsEventModelTable, IndividualStatsGoalModelList,
    IndividualStatsKpiModeList,
    IndividualStatsModel1,
    SimplifiedPlayerStats,
    TeamOverviewStatsModel,
    TeamOverviewViewCounterModel,
    TeamOverviewConsumerPossInterface,
    TeamOverviewConsumerSuspensionInterface
} from '@handballai/stats-calculation';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { PhasesActionTypes, ExecutionPositionTypes } from 'src/app/shared-services/actions/action-types';
import { CoreService } from 'src/app/shared-services/core.service';
import {
    TeamOverviewSubConsumerCore
} from '@handballai/stats-calculation';
import {
    getPlayerScoreStatisticsDto,
    PlayerStatsDtoPart, transformToPlayerStatisticsDto
} from 'src/app/shared-services/statistics/playbyplay/sub-consumer/team-overview/team-overview-sub-consumer.helper';
import { HalftimeTypesExtended } from '../../../video/model/video-tracker.util';

@Injectable({
    providedIn: 'root'
})
export class TeamOverviewSubConsumerService implements TeamOverviewConsumerPossInterface, TeamOverviewConsumerSuspensionInterface {

    constructor(
        private readonly logger: NGXLogger,
    ) { }

    get homeTeamOverviewModel$(): BehaviorSubject<TeamOverviewStatsModel[]> {
        return this._homeTeamOverviewModel$;
    }

    get visitorTeamOverviewModel$(): BehaviorSubject<TeamOverviewStatsModel[]> {
        return this._visitorTeamOverviewModel$;
    }

    get homeTeamEventCounters$(): BehaviorSubject<EventCountersModel> {
        return this._homeTeamEventCounters$;
    }

    get visitorTeamEventCounters$(): BehaviorSubject<EventCountersModel> {
        return this._visitorTeamEventCounters$;
    }

    get individualStatsModel1$(): BehaviorSubject<IndividualStatsModel1[]> {
        return this._individualStatsModel1$;
    }

    get individualStatsKpiModelList$(): BehaviorSubject<IndividualStatsKpiModeList[]> {
        return this._individualStatsKpiModelList$;
    }

    get individualStatsEventsModelList$(): BehaviorSubject<IndividualStatsEventModelList[]> {
        return this._individualStatsEventsModelList$;
    }

    get individualStatsGoalsModelList$(): BehaviorSubject<IndividualStatsGoalModelList[]> {
        return this._individualStatsGoalsModelList$;
    }
    get homeIndividualStatsEventModelList$(): BehaviorSubject<IndividualStatsEventModelTable[]> {
        return this._homeIndividualStatsEventModelList$;
    }
    get visitorIndividualStatsEventModelList$(): BehaviorSubject<IndividualStatsEventModelTable[]> {
        return this._visitorIndividualStatsEventModelList$;
    }
    get homeIndividualSimplifiedStatsModel$(): BehaviorSubject<SimplifiedPlayerStats[]>{
        return this._homeIndividualSimplifiedStatsModel$;
    }
    get visitorIndividualSimplifiedStatsModel$(): BehaviorSubject<SimplifiedPlayerStats[]>{
        return this._visitorIndividualSimplifiedStatsModel$;
    }

    private _core: CoreService;

    private _homeTeamOverviewModel$ = new BehaviorSubject<TeamOverviewStatsModel[]>([]);
    private _visitorTeamOverviewModel$ = new BehaviorSubject<TeamOverviewStatsModel[]>([]);
    private _individualStatsModel1$ = new BehaviorSubject<IndividualStatsModel1[]>([]);
    private _individualStatsKpiModelList$ = new BehaviorSubject<IndividualStatsKpiModeList[]>([]);
    private _individualStatsEventsModelList$ = new BehaviorSubject<IndividualStatsEventModelList[]>([]);
    private _individualStatsGoalsModelList$ = new BehaviorSubject<IndividualStatsGoalModelList[]>([]);
    private _homeIndividualStatsEventModelList$ = new BehaviorSubject<IndividualStatsEventModelTable[]>([]);
    private _visitorIndividualStatsEventModelList$ = new BehaviorSubject<IndividualStatsEventModelTable[]>([]);
    private _homeTeamEventCounters$ = new BehaviorSubject<EventCountersModel>(null);
    private _visitorTeamEventCounters$ = new BehaviorSubject<EventCountersModel>(null);
    private _homeIndividualSimplifiedStatsModel$ = new BehaviorSubject<SimplifiedPlayerStats[]>([]);
    private _visitorIndividualSimplifiedStatsModel$ = new BehaviorSubject<SimplifiedPlayerStats[]>([]);
    private _teamOverviewConsumerCore: TeamOverviewSubConsumerCore;
    initCore(core: CoreService) {
        this._core = core;

        this.subscribeToPlaytimeService();
    }

    public init(): void {
        this._teamOverviewConsumerCore = new TeamOverviewSubConsumerCore(
            this._core.gameService.gameModel.home.name,
            this._core.gameService.gameModel.visitor.name,
            this._core.gameService.gameModel.home.id,
            this._core.gameService.gameModel.visitor.id,
            [...this._core.gameService.gameModel.home.currentField, ...this._core.gameService.gameModel.home?.currentBench],
            [...this._core.gameService.gameModel.visitor.currentField, ...this._core.gameService.gameModel.visitor?.currentBench],
        );
        this.resetSubjects();
        this.fireSubjects();
    }

    public addGoal(
        teamMarker: TeamMarker,
        playerId: number,
        shooterPos: ExecutionPositionTypes,
        phase: PhasesActionTypes,
        offenseSystem: string,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addGoal', playerId);
        this._teamOverviewConsumerCore.addGoal(teamMarker, playerId, shooterPos, phase, offenseSystem, minutes, halfTime);
        this.addPlayTime();
        this.fireSubjects();
    }

    public addGoalGk(
        teamMarker: TeamMarker,
        playerId: number,
        shooterPos: ExecutionPositionTypes,
        phase: PhasesActionTypes,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addGoalGk', playerId);
        this._teamOverviewConsumerCore.addGoalGk(teamMarker, playerId, shooterPos, phase, minutes, halfTime);
        this.addPlayTime();
        this.fireSubjects();
    }

    public addReceivedGoal(
        teamMarker: TeamMarker,
        playerId: number,
        shooterPos: ExecutionPositionTypes,
        phase: PhasesActionTypes,
        minutes: number
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addReceivedGoal', playerId);
        this._teamOverviewConsumerCore.addReceivedGoal(teamMarker, playerId, shooterPos, phase, minutes);
    }

    public addPostOutGk(
        teamMarker: TeamMarker,
        playerId: number,
        shooterPos: ExecutionPositionTypes,
        phase: PhasesActionTypes,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addPostOutGk', playerId);
        this._teamOverviewConsumerCore.addPostOutGk(teamMarker, playerId, shooterPos, phase, minutes, halfTime);
        this.addPlayTime();
        this.fireSubjects();
    }

    public addSave(
        teamMarker: TeamMarker,
        playerId: number,
        shooterPos: ExecutionPositionTypes,
        phase: PhasesActionTypes,
        offenseSystem: string,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addSave', playerId);
        this._teamOverviewConsumerCore.addSave(teamMarker, playerId, shooterPos, phase, offenseSystem, minutes, halfTime);
        this.addPlayTime();
        this.fireSubjects();
    }

    public add7MetersFailedShot(
        teamMarker: TeamMarker,
        playerId: number,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.add7MetersFailedShot', playerId);
        this._teamOverviewConsumerCore.add7MetersFailedShot(teamMarker, playerId, minutes, halfTime);
    }

    public addSaveGk(
        teamMarker: TeamMarker,
        playerId: number,
        shooterPos: ExecutionPositionTypes,
        phase: PhasesActionTypes,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addSaveGk', playerId);
        this._teamOverviewConsumerCore.addSaveGk(teamMarker, playerId, shooterPos, phase, minutes, halfTime);
        this.addPlayTime();
        this.fireSubjects();
    }

    public addAssist(
        teamMarker: TeamMarker,
        playerId: number,
        shooterPos: ExecutionPositionTypes,
        phase: PhasesActionTypes,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this._teamOverviewConsumerCore.addAssist(teamMarker, playerId, shooterPos, phase, minutes, halfTime);
        this.addPlayTime();
        this.fireSubjects();
    }

    public addPostOut(
        teamMarker: TeamMarker,
        playerId: number,
        shooterPos: ExecutionPositionTypes,
        phase: PhasesActionTypes,
        offenseSystem: string,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this._teamOverviewConsumerCore.addPostOut(teamMarker, playerId, shooterPos, phase, offenseSystem, minutes, halfTime);
        this.addPlayTime();
        this.fireSubjects();
    }

    public add7MetersPostOut(
        teamMarker: TeamMarker,
        playerId: number,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.add7MetersPostOut', playerId);
        this._teamOverviewConsumerCore.add7MetersPostOut(teamMarker, playerId, minutes, halfTime);
    }

    public addLostBall(
        teamMarker: TeamMarker,
        playerId: number,
        phase: PhasesActionTypes,
        offenseSystem: string,
        defensePlayer: number | undefined,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addLostBall', playerId);
        this._teamOverviewConsumerCore.addLostBall(
            teamMarker,
            playerId,
            phase,
            offenseSystem,
            defensePlayer,
            minutes,
            halfTime
        );
        this.addPlayTime();
        this.fireSubjects();
    }

    public addAttackFault(
        teamMarker: TeamMarker,
        playerId: number,
        phase: PhasesActionTypes,
        offenseSystem: string,
        defensePlayer: number | undefined,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addAttackFault', playerId);
        this._teamOverviewConsumerCore.addAttackFault(
            teamMarker,
            playerId,
            phase,
            offenseSystem,
            defensePlayer,
            minutes,
            halfTime
        );
        this.addPlayTime();
        this.fireSubjects();
    }

    public addTechnicalMistake(
        teamMarker: TeamMarker,
        playerId: number,
        phase: PhasesActionTypes,
        offenseSystem: string,
        defensePlayer: number | undefined,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addTechnicalMistake', playerId);
        this._teamOverviewConsumerCore.addTechnicalMistake(
            teamMarker,
            playerId,
            phase,
            offenseSystem,
            defensePlayer,
            minutes,
            halfTime);
        this.addPlayTime();
        this.fireSubjects();
    }

    public add2MinEvent(
        teamMarker: TeamMarker,
        playerId: number,
        defensePlayer: number | undefined,
        minutes: number
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.add2MinEvent', playerId);
        this._teamOverviewConsumerCore.add2MinEvent(teamMarker, playerId, defensePlayer, minutes);
        this.addPlayTime();
        this.fireSubjects();
    }

    public add7Meter(
        teamMarker: TeamMarker,
        playerId: number,
        defensePlayer: number | undefined,
        minutes: number
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.add7Meter', playerId);
        this._teamOverviewConsumerCore.add7Meter(teamMarker, playerId, defensePlayer, minutes);
        this.addPlayTime();
        this.fireSubjects();
    }


    public add7MeterSuspension(
        teamMarker: TeamMarker,
        playerId: number,
        defensePlayer: number | undefined,
        minutes: number
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.add7MeterSuspension', playerId);
        this._teamOverviewConsumerCore.add7MeterSuspension(teamMarker, playerId, defensePlayer, minutes);
        this.addPlayTime();
        this.fireSubjects();
    }

    public addShotBlocked(
        shooterPos: ExecutionPositionTypes,
        teamMarker: TeamMarker,
        playerId: number,
        defensePlayer: number | undefined,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addShotBlocked', playerId);
        this._teamOverviewConsumerCore.addShotBlocked(shooterPos, teamMarker, playerId, defensePlayer, minutes, halfTime);
        this.addPlayTime();
        this.fireSubjects();
    }

    public add1And1(
        teamMarker: TeamMarker,
        playerId: number,
        defensePlayer: number | undefined,
        minutes: number
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.add1And1', playerId);
        this._teamOverviewConsumerCore.add1And1(teamMarker, playerId, defensePlayer, minutes);
        this.addPlayTime();
        this.fireSubjects();
    }

    public addFoul(
        teamMarker: TeamMarker,
        playerId: number,
        defensePlayer: number | undefined,
        minutes: number
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addFoul', playerId);
        this._teamOverviewConsumerCore.addFoul(teamMarker, playerId, defensePlayer, minutes);
        this.addPlayTime();
        this.fireSubjects();
    }

    add2Min(
        teamMarker: TeamMarker,
        playerId: number,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this._teamOverviewConsumerCore.add2Min(teamMarker, playerId, minutes, halfTime);
        this.addPlayTime();
        this.fireSubjects();
    }

    addBlueCard(
        teamMarker: TeamMarker,
        playerId: number,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this._teamOverviewConsumerCore.addRedCard(teamMarker, playerId, minutes, halfTime);
        this.addPlayTime();
        this.fireSubjects();
    }

    addRedCard(
        teamMarker: TeamMarker,
        playerId: number,
        minutes: number,
        halfTime: HalftimeTypesExtended
    ): void {
        this._teamOverviewConsumerCore.addBlueCard(teamMarker, playerId, minutes, halfTime);
        this.addPlayTime();
        this.fireSubjects();
    }

    public addYellowCard(
        teamMarker: TeamMarker,
        playerId: number
    ): void {
        this._teamOverviewConsumerCore.addYellowCard(teamMarker, playerId);
        this.addPlayTime();
        this.fireSubjects();
    }


    public addPossessions(
        teamMarker: TeamMarker,
        playerId: number,
        phase: PhasesActionTypes,
        offenseSystem: string
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addPossessions', playerId);
        this._teamOverviewConsumerCore.addPossessions(teamMarker, playerId, phase, offenseSystem);
        this.addPlayTime();
        this.fireSubjects();
    }

    public addDefPossessions(
        teamMarker: TeamMarker,
        playerId: number,
        phase: PhasesActionTypes,
        offenseSystem: string
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addDefPossessions', playerId);
        this._teamOverviewConsumerCore.addDefPossessions(teamMarker, playerId, phase, offenseSystem);
        this.addPlayTime();
        this.fireSubjects();
    }

    private addPlayTime(
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addPlayTime');
        // Workaround - we have no teamMarker here
        Array.from(this._teamOverviewConsumerCore.teamOverviewModelMap.get('HOME').keys()).forEach(playerId => {
            this._teamOverviewConsumerCore.teamOverviewModelMap.get('HOME').get(playerId)
                .addPlayTime(this._core.playTimeService.getPlayerPlayTimeInfo(playerId).playTimeSec);
        });
        Array.from(this._teamOverviewConsumerCore.teamOverviewModelMap.get('VISITOR').keys()).forEach(playerId => {
            this._teamOverviewConsumerCore.teamOverviewModelMap.get('VISITOR').get(playerId)
                .addPlayTime(this._core.playTimeService.getPlayerPlayTimeInfo(playerId).playTimeSec);
        });
    }

    private resetSubjects(): void {
        this._homeTeamOverviewModel$.next([]);
        this._visitorTeamOverviewModel$.next([]);
        this._individualStatsModel1$.next([]);
        this._individualStatsKpiModelList$.next([]);
        this._individualStatsEventsModelList$.next([]);
        this._individualStatsGoalsModelList$.next([]);
    }

    public fireSubjects(): void {
        const home = this._teamOverviewConsumerCore.teamOverviewModelMap.get('HOME');
        const visitor = this._teamOverviewConsumerCore.teamOverviewModelMap.get('VISITOR');


        this._homeTeamOverviewModel$.next(
            [...Array.from(home.values()).map(counter => counter
                .transformToViewModel(this._teamOverviewConsumerCore.overallGoals.get('VISITOR').counter))]
        );
        this._visitorTeamOverviewModel$.next(
            [...Array.from(visitor.values()).map(counter => counter
                .transformToViewModel(this._teamOverviewConsumerCore.overallGoals.get('HOME').counter))]
        );
        this._homeIndividualSimplifiedStatsModel$.next(
            [...Array.from(home.values()).map(counter => counter
                .transformToViewModelSimplifiedPlayerStats(this._teamOverviewConsumerCore.overallGoals.get('VISITOR').counter))]
        );
        this._visitorIndividualSimplifiedStatsModel$.next(
            [...Array.from(visitor.values()).map(counter => counter
                .transformToViewModelSimplifiedPlayerStats(this._teamOverviewConsumerCore.overallGoals.get('HOME').counter))]
        );
        this._individualStatsModel1$.next([
            ...Array.from(home.values()).map(counter => counter.transformToIndividualStatsModel1()),
            ...Array.from(visitor.values()).map(counter => counter.transformToIndividualStatsModel1())
        ]);
        this._individualStatsKpiModelList$.next([
            ...Array.from(home.values()).map(counter => counter.transformToIndividualStatsKpiModelList()),
            ...Array.from(visitor.values()).map(counter => counter.transformToIndividualStatsKpiModelList())
        ]);
        this._individualStatsEventsModelList$.next([
            ...Array.from(home.values()).map(counter => counter.transformToIndividualStatsEventModelList()),
            ...Array.from(visitor.values()).map(counter => counter.transformToIndividualStatsEventModelList())
        ]);
        this._individualStatsGoalsModelList$.next([
            ...Array.from(home.values()).map(counter => counter.transformToIndividualStatsGoalModelList()),
            ...Array.from(visitor.values()).map(counter => counter.transformToIndividualStatsGoalModelList())
        ]);
        this._homeIndividualStatsEventModelList$
            .next(Array.from(home.values()).map(counter => counter.transformToIndividualStatsEventModelTable()));
        this._visitorIndividualStatsEventModelList$
            .next(Array.from(visitor.values()).map(counter => counter.transformToIndividualStatsEventModelTable()));

        const mapFn = (base: EventCountersModel, counters: TeamOverviewViewCounterModel): EventCountersModel => {
            const current = counters.transformEventsCounters();
            const statsModel = counters.transformToIndividualStatsModel1();
            base.totalAttackFaultProvoke += current.totalAttackFaultProvoke;
            base.totalTechnicalMistakeProvoke += current.totalTechnicalMistakeProvoke;
            base.totalLostBallProvoke += current.totalLostBallProvoke;
            base.totalTwoMinCommit += current.totalTwoMinCommit;
            base.totalSevenMetersCommit += current.totalSevenMetersCommit;
            base.totalSevenMSuspCommit += current.totalSevenMSuspCommit;
            base.totalOneAndOneLost += current.totalOneAndOneLost;
            base.totalFoulCommit += current.totalFoulCommit;
            base.totalShotBlock += current.totalShotBlock;
            base.totalAttackFaultCommit += current.totalAttackFaultCommit;
            base.totalTechnicalMistakeCommit += current.totalTechnicalMistakeCommit;
            base.totalLostBall += current.totalLostBall;
            base.totalTwoMinProvoke += current.totalTwoMinProvoke;
            base.totalSevenMetersProvoke += current.totalSevenMetersProvoke;
            base.totalSevenMSuspProvoke += current.totalSevenMSuspProvoke;
            base.totalOneAndOneWon += current.totalOneAndOneWon;
            base.totalFoulReceive += current.totalFoulReceive;
            base.totalShotBlocked += current.totalShotBlocked;
            return base;
        };
        this._homeTeamEventCounters$.next(Array.from(home.values()).reduce(mapFn, {
            totalAttackFaultProvoke: 0,
            totalTechnicalMistakeProvoke: 0,
            totalLostBallProvoke: 0,
            totalTwoMinCommit: 0,
            totalSevenMetersCommit: 0,
            totalSevenMSuspCommit: 0,
            totalOneAndOneLost: 0,
            totalFoulCommit: 0,
            totalShotBlock: 0,
            totalAttackFaultCommit: 0,
            totalTechnicalMistakeCommit: 0,
            totalLostBall: 0,
            totalTwoMinProvoke: 0,
            totalSevenMetersProvoke: 0,
            totalSevenMSuspProvoke: 0,
            totalOneAndOneWon: 0,
            totalFoulReceive: 0,
            totalShotBlocked: 0,
        }));
        this._visitorTeamEventCounters$.next(Array.from(visitor.values()).reduce(mapFn, {
            totalAttackFaultProvoke: 0,
            totalTechnicalMistakeProvoke: 0,
            totalLostBallProvoke: 0,
            totalTwoMinCommit: 0,
            totalSevenMetersCommit: 0,
            totalSevenMSuspCommit: 0,
            totalOneAndOneLost: 0,
            totalFoulCommit: 0,
            totalShotBlock: 0,
            totalAttackFaultCommit: 0,
            totalTechnicalMistakeCommit: 0,
            totalLostBall: 0,
            totalTwoMinProvoke: 0,
            totalSevenMetersProvoke: 0,
            totalSevenMSuspProvoke: 0,
            totalOneAndOneWon: 0,
            totalFoulReceive: 0,
            totalShotBlocked: 0,
        }));
        this.logger.debug(
            'TeamOverviewSubConsumerService.fireSubjects',
            this._homeTeamOverviewModel$.value,
            this._visitorTeamOverviewModel$.value
        );

    }

    public getPlayerStatisticsDto(): Map<number, PlayerStatsDtoPart> {
        const home = this._teamOverviewConsumerCore.teamOverviewModelMap.get('HOME');
        const visitor = this._teamOverviewConsumerCore.teamOverviewModelMap.get('VISITOR');

        return [
            ...Array.from(home.entries()).map(itm => [
                itm[0],
                transformToPlayerStatisticsDto(itm[1], this._teamOverviewConsumerCore.overallGoals.get('VISITOR').counter)
            ]),
            ...Array.from(visitor.entries()).map(itm => [
                itm[0],
                transformToPlayerStatisticsDto(itm[1], this._teamOverviewConsumerCore.overallGoals.get('HOME').counter)
            ])
        ].reduce((acc, current) => {
            acc.set(current[0] as number, current[1] as PlayerStatsDtoPart);
            return acc;
        }, new Map<number, PlayerStatsDtoPart>());
    }

    public getPlayerScoreStatistics(): any {
        const home = this._teamOverviewConsumerCore.teamOverviewModelMap.get('HOME');
        const visitor = this._teamOverviewConsumerCore.teamOverviewModelMap.get('VISITOR');

        return [
            ...Array.from(home.entries()).map(itm => [
                itm[0],
                itm[1].player.name,
                itm[1].player.position,
                itm[1].player.teamName,
                getPlayerScoreStatisticsDto(itm[1])
            ]),
            ...Array.from(visitor.entries()).map(itm => [
                itm[0],
                itm[1].player.name,
                itm[1].player.position,
                itm[1].player.teamName,
                getPlayerScoreStatisticsDto(itm[1])
            ])
        ].reduce((acc: any[], current: any) => {
            const score = {
                teamName: current[3],
                playerName: current[1],
                position: current[2]
            };
            const map = current[4];

            for (const [key, value] of map.entries()) {
                score[key] = value.toFixed(1);
            }
            acc.push(score);
            return acc;
        }, []);
    }

    private subscribeToPlaytimeService(): void {
        this._core.playTimeService.registerForPlayTimeEvents(() => {
            this.addPlayTime();
            this.fireSubjects();
        });
    }
}
