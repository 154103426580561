<div class="score-board-wrapper">
  <div class="score-board-header">
    <ion-buttons slot="start">
      <ion-menu-toggle>
        <ion-menu-button></ion-menu-button>
      </ion-menu-toggle>
    </ion-buttons>
    <div class="score-board-header-left">
      <!-- TEAM -->
      <div class="score-board-team">
        <div class="score-board-team-left">
          <ng-container *ngIf="timerModel.halfTime=='T1' || !needToSwapFieldHalftime;
          then !swappedTeams ? homeTeamBadge : visitorTeamBadge else !swappedTeams ? visitorTeamBadge : homeTeamBadge"></ng-container>
        </div>
        <div class="score-board-team-logo optimize-contrast"></div>
        <div class="score-board-team-right">
          <ng-container *ngIf="timerModel.halfTime=='T1' || !needToSwapFieldHalftime;
          then !swappedTeams ? visitorTeamBadge : homeTeamBadge else !swappedTeams ? homeTeamBadge : visitorTeamBadge"></ng-container>
        </div>
      </div>
      <!-- SCORE -->
      <div class="score-board-score">
        <div class="grid-col (1/4) undo-button-container">
          <ion-button color="danger"
                      [fill]="'outline'"
                      [disabled]="!isUndoEnabled"
                      (click)="onUndoSelected()" style="background-color: white">
            <ion-label>Undo</ion-label>
          </ion-button>
        </div>
        <div class="grid-col (1/2) timer-container">
          <div class="score-board-score-left">
            <span class="score-text capitalize">score</span>
            <span *ngIf="!swappedTeams" class="score-number font-semibold flex" [ngClass]="(timerModel.halfTime=='T1' || !needToSwapFieldHalftime) ? 'flex-row' : 'flex-row-reverse'">
              <span [ngClass]="homeTeamColor">{{homeScore}}</span>:<span [ngClass]="visitorTeamColor">{{visitorScore}}</span>
            </span>
            <span *ngIf="swappedTeams" class="score-number font-semibold flex" [ngClass]="(timerModel.halfTime=='T1' || !needToSwapFieldHalftime) ? 'flex-row-reverse' : 'flex-row'">
              <span [ngClass]="homeTeamColor">{{homeScore}}</span>:<span [ngClass]="visitorTeamColor">{{visitorScore}}</span>
            </span>
          </div>
          <button class="score-board-score-play"
                  [disabled]="gameEnded$|async"
                  (click)="onRunningToggle()">
            <ion-icon name="play-outline" *ngIf="!(gameRunning$|async)" class="play-icon"></ion-icon>
            <ion-icon name="pause-outline" *ngIf="gameRunning$|async"></ion-icon>
          </button>
          <div class="score-board-score-right">
            <span class="score-text capitalize">time {{timerModel?.halfTime}}</span>
            <span class="score-number font-semibold">{{timerModel?.gameCounter}}</span>
          </div>
        </div>
        <div *ngIf="(timerModel.halfTime === 'T1' || timerModel.halfTime === 'T2') && !_core.handballTimerService.isFirstHalftimeETStarted; else extraTime" class="grid-col (1/4) end-button-container">
          <ion-button *ngIf="timerModel.halfTime=='T1' && ((gameRunning$ | async) || timerModel?.gameCounter !== '00:00')" color="danger"
                  [fill]="'outline'"
                  (click)="onEndFirstHalf()">
            <ion-label>{{'End 1st Half' | translate}}</ion-label>
          </ion-button>
          <ion-button *ngIf="timerModel.halfTime=='T2' && !(gameEnded$|async) && ((gameRunning$ | async) || timerModel?.gameCounter !== '00:00')" color="danger"
                  (click)="onEndGame()">
            <ion-label>{{'End game' | translate}}</ion-label>
          </ion-button>
        </div>
        <ng-template #extraTime>
          <div class="grid-col (1/4) end-button-container">
            <ion-button *ngIf="!_core.handballTimerService.isSecondHalfTimeETStarted && ((gameRunning$ | async) || timerModel?.gameCounter !== '00:00')" color="danger"
                        [fill]="'outline'"
                        (click)="onEndFirstHalf(true)">
              <ion-label>{{'End 1st Half'|translate}}</ion-label>
            </ion-button>
            <ion-button *ngIf="_core.handballTimerService.isSecondHalfTimeETStarted && !(gameEnded$ | async)  && ((gameRunning$ | async) || timerModel?.gameCounter !== '00:00')" color="danger"
                        (click)="onEndGame()">
              <ion-label>{{'End game'|translate}}</ion-label>
            </ion-button>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="score-board-header-right" [ngSwitch]="scoreBoardLocation">
      <!-- BUTTONS STATS & PLAY BY PLAY -->
        <div class="score-board-header-buttons" *ngSwitchCase="'GAME'">
          <button class="score-board-header-button -stats font-semibold capitalize"
                  [class.-active]="false" (click)="goToAdvancedStats()">
            <ion-icon class="mr-4" name="stats-chart"></ion-icon> Stats
          </button>
        </div>
        <div class="score-board-header-buttons" *ngSwitchCase="'PBP'">
          <button class="score-board-header-button -stats font-semibold capitalize"
                  [class.-active]="false" (click)="goToAdvancedStats()">
            <ion-icon class="mr-4" name="stats-chart"></ion-icon> Stats
          </button>
          <button class="score-board-header-button -stats font-semibold capitalize"
                  [class.-active]="false" (click)="goToGame()">
            <ion-icon class="mr-4" name="play-circle"></ion-icon> Game
          </button>
        </div>
      <div class="score-board-header-buttons" *ngSwitchCase="'AD_STATS'">
        <button class="score-board-header-button -stats font-semibold capitalize"
                [class.-active]="false" (click)="goToGame()">
          <ion-icon class="mr-4" name="play-circle"></ion-icon> Game
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #homeTeamBadge>
  <div class="score-board-team-name font-bold">
    <span>{{homeTeamName}}</span>
  </div>
  <div class="score-board-team-shield -avatar -team-A relative" [ngClass]="homeTeamColor"
       (click)="onSuspensionToBench('HOME')">
    <div class="overflow-effect"
         [ngStyle]="{'background-image': 'url(assets/shields/1470136715-WappenFchseBerlin.jpg)'}">
      <span>{{extractTeamFirstLetters(homeTeamName)}}</span>
    </div>
  </div>
</ng-template>

<ng-template #visitorTeamBadge>
  <div class="score-board-team-name font-bold">
    <span>{{visitorName}}</span>
  </div>
  <div class="score-board-team-shield -avatar -team-B relative" [ngClass]="visitorTeamColor"
       (click)="onSuspensionToBench('VISITOR')">
    <div class="overflow-effect"
         [ngStyle]="{'background-image': 'url(assets/shields/flensburg-handwitt.jpg)'}">
      <span>{{extractTeamFirstLetters(visitorName)}}</span>
    </div>
  </div>
</ng-template>
