<div class="overview-stats w-full mt-6">
  <!-- <h3>{{'OVERALL OVERVIEW'|translate}}</h3> -->
  <div *ngIf="core.isMobileDevice" class="expand-toggle">
    <ion-toggle (ionChange)="onExpandTeamsTableColumnsClick()"></ion-toggle>
  </div>
  <ngx-datatable
    class="material"
    [rows]="overviewStatsAggregatedViewModel"
    [columns]="columns"
    [limit]="5"
    [columnMode]="'flex'"
    rowHeight="auto"
    [scrollbarH]="true"
    [footerHeight]="false"
    [headerHeight]="50"
  >
    <ngx-datatable-column name="teamName"
                          [flexGrow]="2"
                          [frozenLeft]="true"
                          [sortable]="false"
                          [resizeable]="false"
                          [cellClass]="getRowClass">
      <ng-template let-column="column" ngx-datatable-header-template>
        {{'Team'|translate}}
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{value}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="goals"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> {{'Goals'|translate}} </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span> {{value}} </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="saves"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  FS 
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('FS') | translate"></ion-icon>
        </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span> {{value}} </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="postOuts"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> PO
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('PO') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || teamsAllColsShown" name="gkSaves"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> S
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('S') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column *ngIf="!core.isMobileDevice || teamsAllColsShown" name="lostBalls"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> LB
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('LB') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || teamsAllColsShown" name="possessions"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> POSS
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('POSS') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column *ngIf="!core.isMobileDevice || teamsAllColsShown" name="efficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> EFF%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('EFF%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span> {{value | number: '1.0-0'}}% </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || teamsAllColsShown" name="shootingEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  SHOTEF%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('SHOTEF%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span> {{value | number: '1.0-0'}}% </span>
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column *ngIf="!core.isMobileDevice || teamsAllColsShown" name="lostBallEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  LB%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('LB%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span> {{value | number: '1.0-0'}}% </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || teamsAllColsShown" name="equalityEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  EQEF%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('EQEF%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || teamsAllColsShown" name="superiorityEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
        <span>  SUPEF%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('SUPEF%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || teamsAllColsShown" name="inferiorityEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
        <span>  INFEF%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('INFEF%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span> 
      </ng-template>
    </ngx-datatable-column>
    <!-- Temp removed Victory-->
  <!--  <ngx-datatable-column prop="percent"-->
  <!--                        [flexGrow]="1"-->
  <!--                        [sortable]="false"-->
  <!--                        [resizeable]="false">-->
  <!--    <ng-template let-column="column" ngx-datatable-header-template>-->
  <!--      V%-->
  <!--      <ion-icon name="help-circle-outline" tooltip="Victory %"></ion-icon>-->
  <!--    </ng-template>-->
  <!--    <ng-template let-value="value" ngx-datatable-cell-template>-->
  <!--      &lt;!&ndash; {{value | number: '1.0-0'}}% &ndash;&gt;-->
  <!--      <ion-avatar><div [class.red]="value <= 55" [class.yellow]="value > 55 && value <= 85" [class.green]="value > 85" class="circle_wrapper"></div></ion-avatar>-->
  <!--    </ng-template>-->
  <!--  </ngx-datatable-column>-->
  
  </ngx-datatable>
  
  <hr class="separator">
  
  <div class="overviewStats flex flex-col lg:flex-row items-center mb-4">
    <div class="overview-stats__label-wrapper mr-2">
      <h6 class="mt-4 mb-0">Select defense system:</h6>
    </div>
    <!-- <div class="overview-stats__button-wrapper" *ngFor="let ds of defenseSystems">
      <ion-button class="button button-block"
                  [fill]="selectedDefenseSystem == ds.code ? 'solid' : 'outline'"
                  (click)="onSelectDefenseSystem(ds.code)">
        <ion-label>{{ ds.desc }}</ion-label>
      </ion-button>
    </div> -->
    <app-custom-tabs class="page-tabs flex-auto" (selection)="onSelectDefenseSystem($event)">
      <ng-container *ngFor="let ds of defenseSystems let i = index">
        <app-custom-tab [label]="ds.desc" [fullHeight]="true" [selected]="selectedDefenseSystem" [defaultTab]="i == 0"></app-custom-tab>
      </ng-container>
    </app-custom-tabs>
  </div>


  <div *ngIf="core.isMobileDevice" class="expand-toggle">
    <ion-toggle (ionChange)="onExpandDefenseSystemTableColumnsClick()"></ion-toggle>
  </div>
  <ngx-datatable
          class="material"
          [rows]="selectedHomeOverviewStatsByPhaseModelList"
          [columns]="teamColumnsT1"
          [limit]="5"
          [columnMode]="'flex'"
          rowHeight="auto"
          [scrollbarH]="true"
          [footerHeight]="false"
          [headerHeight]="50"
  >
    <ngx-datatable-column name="phase"
                          [flexGrow]="core.isMobileDevice ? 1.8 : 2"
                          [frozenLeft]="true"
                          [sortable]="false"
                          [resizeable]="false"
                          [cellClass]="'first-row'">
      <ng-template let-column="column" ngx-datatable-header-template>
        {{homeTeamName}}
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <ng-container *ngIf="value=='OFFENSE_POSITIONAL'"> {{'OFFENSE POSITIONAL'|translate}}</ng-container>
        <ng-container *ngIf="value=='COUNTER_GOAL'">{{'COUNTER GOAL'|translate}}</ng-container>
        <ng-container *ngIf="value=='FAST_BREAK'">{{'FAST BREAK'|translate}}</ng-container>
        <ng-container *ngIf="!['OFFENSE_POSITIONAL','COUNTER_GOAL','FAST_BREAK'].includes(value)">{{value|translate}}</ng-container>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="goals"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> {{'Goals'|translate}} </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>
          {{value}}
        </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="saves"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  FS 
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('FS') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>
          {{value}}
        </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="postOuts"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> PO
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('PO') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>
          {{value}}
        </span>
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="gkSaves"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> S
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('S') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>
          {{value}}
        </span>
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="lostBalls"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> LB
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('LB') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>
          {{value}}
        </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="possessions"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> POSS
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('POSS') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>
          {{value}}
        </span>
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="efficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> EFF%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('EFF%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>
            {{value | number: '1.0-0'}}%
        </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="shootingEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  SHOTEF%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('SHOTEF%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>
          {{value | number: '1.0-0'}}%
        </span>
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="lostBallEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  LB%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('LB%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>
          {{value | number: '1.0-0'}}%
        </span>
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="possessionsWithoutFoul"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> POSSNF
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('POSSNF') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>
          {{value}}
        </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="possessionsWithFoul"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> POSSWF
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('POSSWF') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>
          {{value}}
        </span>
      </ng-template>
    </ngx-datatable-column>
  
  </ngx-datatable>
  <hr class="separator">
  <ngx-datatable
          class="material mt-3"
          [rows]="selectedVisitorOverviewStatsByPhaseModelList"
          [columns]="teamColumnsT1"
          [limit]="5"
          [columnMode]="'flex'"
          rowHeight="auto"
          [scrollbarH]="true"
          [footerHeight]="false"
          [headerHeight]="50"
  >
    <ngx-datatable-column name="phase"
                          [flexGrow]="2"
                          [frozenLeft]="true"
                          [sortable]="false"
                          [resizeable]="false"
                          [cellClass]="'first-row'">
      <ng-template let-column="column" ngx-datatable-header-template>
        {{visitorTeamName}}
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <ng-container *ngIf="value=='OFFENSE_POSITIONAL'"> {{'OFFENSE POSITIONAL'|translate}}</ng-container>
        <ng-container *ngIf="value=='COUNTER_GOAL'">{{'COUNTER GOAL'|translate}}</ng-container>
        <ng-container *ngIf="value=='FAST_BREAK'">{{'FAST BREAK'|translate}}</ng-container>
        <ng-container *ngIf="!['OFFENSE_POSITIONAL','COUNTER_GOAL','FAST_BREAK'].includes(value)">{{value|translate}}</ng-container>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="goals"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false"
                         >
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> {{'Goals'|translate}} </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span> {{value}} </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="saves"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  FS 
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('FS') | translate"></ion-icon>
        </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value}}</span> 
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="postOuts"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> PO
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('PO') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="gkSaves"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> S
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('S') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="lostBalls"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> LB
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('LB') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="possessions"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> POSS
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('POSS') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="efficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> EFF%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('EFF%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span> {{value | number: '1.0-0'}}% </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="shootingEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  SHOTEF%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('SHOTEF%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span> {{value | number: '1.0-0'}}% </span>
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="lostBallEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  LB%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('LB%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span> {{value | number: '1.0-0'}}% </span>
      </ng-template>
    </ngx-datatable-column>
  
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="possessionsWithoutFoul"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> POSSNF
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('POSSNF') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="possessionsWithFoul"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> POSSWF
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('POSSWF') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
  
  </ngx-datatable>
  <hr class="separator">
  <ngx-datatable
          class="material mt-3"
          [rows]="selectedHomeOverStatsOffenseSystemModelList"
          [columns]="teamColumnsT2"
          [columnMode]="'flex'"
          [limit]="5"
          rowHeight="auto"
          [scrollbarH]="true"
          [footerHeight]="false"
          [headerHeight]="50"
  >
    <ngx-datatable-column name="phase"
                          [frozenLeft]="true"
                          [flexGrow]="2"
                          [sortable]="false"
                          [resizeable]="false"
                          [cellClass]="'first-row'">
      <ng-template let-column="column" ngx-datatable-header-template>
        {{homeTeamName}}
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <ng-container *ngIf="value=='OFFENSE_POSITIONAL'"> {{'OFFENSE POSITIONAL'|translate}}</ng-container>
        <ng-container *ngIf="value=='COUNTER_GOAL'">{{'COUNTER GOAL'|translate}}</ng-container>
        <ng-container *ngIf="value=='FAST_BREAK'">{{'FAST BREAK'|translate}}</ng-container>
        <ng-container *ngIf="!['OFFENSE_POSITIONAL','COUNTER_GOAL','FAST_BREAK'].includes(value)">{{value|translate}}</ng-container>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="equalityEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  EQEF%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('EQEF%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="equalityFivePlusOneVsSixEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> EQ%5+1v6 
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('EQ%5+1v6') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="equalitySixVsFivePlusOneEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>EQ%6v5+1
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('EQ%6v5+1') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="superioritySixVsFiveEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>SUP%6v5
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('SUP%6v5') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="superioritySevenVsSixEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>SUP%7v6
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('SUP%7v6') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="superiorityOtherEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>SUP%Other
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('SUP%Other') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="inferiorityFiveVsSixEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>INF%5vs6
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('INF%5vs6') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="inferioritySixVsSevenEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> INF%6vs7
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('INF%6vs7') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="inferiorityOtherEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  INF%Other
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('INF%Other') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
  
  </ngx-datatable>
  <hr class="separator">
  <ngx-datatable
          class="material mt-3"
          [rows]="selectedVisitorOverStatsOffenseSystemModelList"
          [columns]="teamColumnsT2"
          [columnMode]="'flex'"
          [limit]="5"
          rowHeight="auto"
          [scrollbarH]="true"
          [footerHeight]="false"
          [headerHeight]="50"
  >
    <ngx-datatable-column name="phase"
                          [frozenLeft]="true"
                          [flexGrow]="2"
                          [sortable]="false"
                          [resizeable]="false"
                          [cellClass]="'first-row'">
      <ng-template let-column="column" ngx-datatable-header-template>
        {{visitorTeamName}}
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <ng-container *ngIf="value=='OFFENSE_POSITIONAL'"> {{'OFFENSE POSITIONAL'|translate}}</ng-container>
        <ng-container *ngIf="value=='COUNTER_GOAL'">{{'COUNTER GOAL'|translate}}</ng-container>
        <ng-container *ngIf="value=='FAST_BREAK'">{{'FAST BREAK'|translate}}</ng-container>
        <ng-container *ngIf="!['OFFENSE_POSITIONAL','COUNTER_GOAL','FAST_BREAK'].includes(value)">{{value|translate}}</ng-container>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="equalityEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  EQEF%
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('EQEF%') | translate"></ion-icon>
       </span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="equalityFivePlusOneVsSixEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> EQ%5+1v6 
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('EQ%5+1v6') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="equalitySixVsFivePlusOneEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>EQ%6v5+1
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('EQ%6v5+1') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="superioritySixVsFiveEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>SUP%6v5
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('SUP%6v5') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="superioritySevenVsSixEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>SUP%7v6
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('SUP%7v6') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="superiorityOtherEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>SUP%Other
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('SUP%Other') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="inferiorityFiveVsSixEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>INF%5vs6
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('INF%5vs6') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="inferioritySixVsSevenEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span> INF%6vs7
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('INF%6vs7') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || defenseSystemAllColsShown" name="inferiorityOtherEfficiency"
                          [flexGrow]="1"
                          [sortable]="false"
                          [resizeable]="false">
      <ng-template let-column="column" ngx-datatable-header-template>
       <span>  INF%Other
        <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('INF%Other') | translate"></ion-icon></span>
      </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
       <span>{{value | number: '1.0-0'}}%</span>
      </ng-template>
    </ngx-datatable-column>
  
  </ngx-datatable>
</div>
