import { GameModel, GamePlayerModel } from 'src/app/shared-services/model/game.model';
import { CoreService } from 'src/app/shared-services/core.service';
import { BehaviorSubject } from 'rxjs';
import { DefenseSystem, TeamMarker } from '@handballai/stats-calculation';
import { Timeout } from 'src/app/shared-services/game/timeout';
import { NGXLogger } from 'ngx-logger';
import { IGameInterface } from 'src/app/shared-services/game/game-interface';
import { TimeOutEvent } from 'src/app/shared-services/game/events/time-out-event';
import { SuspensionActionTypes } from 'src/app/shared-services/actions/action-types';
import { DefenseChangeEvent } from 'src/app/shared-services/game/events/defense-events';
import {
    applyTimeOut,
    enableAllowedTimeOuts,
    initGamePlayerSlotIdsForLite,
    resetTimeOut,
    setAllTimeOuts,
    toggleDisablePlayerList,
    undoTimeOut
} from 'src/app/shared-services/game/game-service-helper';
import { TimerModel } from 'src/app/shared-services/timer/timer-model';
import { TimeOutSet } from './events/time-out-reset';

export class LiteGameEngine implements IGameInterface {

    constructor(
        private readonly logger: NGXLogger
    ) {}

    get gameModel(): GameModel {
        return this._gameModel;
    }

    get gameId(): number {
        return this._gameId;
    }

    set gameId(value: number) {
        this._gameId = value;
    }

    get gameHash(): string {
        return this._gameHash;
    }

    get gameHash$() {
        return this._gameHash$;
    }

    set gameHash(value: string) {
        this._gameHash = value;
        this._gameHash$.next(this._gameHash);
    }

    get homeBench$(): BehaviorSubject<GamePlayerModel[]> {
        return this._homeBench$;
    }

    get homeDefenseSystem$(): BehaviorSubject<DefenseSystem> {
        return this._homeDefenseSystem$;
    }

    get homeField$(): BehaviorSubject<GamePlayerModel[]> {
        return this._homeField$;
    }

    get homePlayers$(): BehaviorSubject<GamePlayerModel[]> {
        return this._homePlayers$;
    }

    get homeGameSuspended$(): BehaviorSubject<GamePlayerModel[]> {
        return this._homeGameSuspended$;
    }

    get homeName$(): BehaviorSubject<string> {
        return this._homeName$;
    }

    get homeScore$(): BehaviorSubject<number> {
        return this._homeScore$;
    }

    get homeSuspended$(): BehaviorSubject<GamePlayerModel[]> {
        return this._homeSuspended$;
    }

    get homeTimeOuts$(): BehaviorSubject<Timeout[]> {
        return this._homeTimeOuts$;
    }

    get visitorBench$(): BehaviorSubject<GamePlayerModel[]> {
        return this._visitorBench$;
    }

    get visitorDefenseSystem$(): BehaviorSubject<DefenseSystem> {
        return this._visitorDefenseSystem$;
    }

    get visitorField$(): BehaviorSubject<GamePlayerModel[]> {
        return this._visitorField$;
    }

    get visitorPlayers$(): BehaviorSubject<GamePlayerModel[]> {
        return this._visitorPlayers$;
    }

    get visitorGameSuspended$(): BehaviorSubject<GamePlayerModel[]> {
        return this._visitorGameSuspended$;
    }

    get visitorName$(): BehaviorSubject<string> {
        return this._visitorName$;
    }

    get visitorScore$(): BehaviorSubject<number> {
        return this._visitorScore$;
    }

    get visitorSuspended$(): BehaviorSubject<GamePlayerModel[]> {
        return this._visitorSuspended$;
    }

    get visitorTimeOuts$(): BehaviorSubject<Timeout[]> {
        return this._visitorTimeOuts$;
    }


    private _gameHash: string;
    private _gameId: number;
    private _gameModel: GameModel;
    private _homePlayers$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _homeField$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _homeBench$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _homeSuspended$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _homeGameSuspended$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _visitorPlayers$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _visitorField$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _visitorBench$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _visitorSuspended$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _visitorGameSuspended$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _homeTimeOuts$ = new BehaviorSubject<Timeout[]>(['open', 'open', 'disabled']);
    private _visitorTimeOuts$ = new BehaviorSubject<Timeout[]>(['open', 'open', 'disabled']);
    private _homeName$ = new BehaviorSubject<string>(null);
    private _visitorName$ = new BehaviorSubject<string>(null);
    private _homeScore$ = new BehaviorSubject<number>(null);
    private _visitorScore$ = new BehaviorSubject<number>(null);
    private _homeDefenseSystem$ = new BehaviorSubject<DefenseSystem>('6:0');
    private _visitorDefenseSystem$ = new BehaviorSubject<DefenseSystem>('6:0');
    private _gameHash$ =  new BehaviorSubject<string>(null);
    private _core: CoreService;


    setAllTimeOuts(timeouts: TimeOutSet): void {
        setAllTimeOuts(
            timeouts,
            this._homeTimeOuts$,
            this._visitorTimeOuts$
        );
    }

    applyTimeOut(timeOutEvent: TimeOutEvent): void {
        applyTimeOut(
            timeOutEvent,
            this._homeTimeOuts$,
            this._visitorTimeOuts$
        );
    }

    undoTimeOut(timeOutEvent: TimeOutEvent): void {
        undoTimeOut(
            timeOutEvent,
            this._homeTimeOuts$,
            this._visitorTimeOuts$
        );
    }

    bringBackPlayerAfterSuspensionHome(newFieldPlayer: GamePlayerModel): void {
        const playerBack = this._gameModel.home.currentField.filter(pl => pl.id === newFieldPlayer.id)[0];
        playerBack.suspensionType = newFieldPlayer.suspensionType === 'REGULAR_SUSPENSION' ? undefined : 'GAME_SUSPENSION_CONFIRMED';
        this._gameModel.home.currentField = [
            ...this._gameModel.home.currentField.filter(pl => pl.id !== newFieldPlayer.id),
            playerBack
        ];
        this._homeField$.next([...this._gameModel.home.currentField]);
    }

    bringBackPlayerAfterSuspensionVisitor(newFieldPlayer: GamePlayerModel): void {
        const playerBack = this._gameModel.visitor.currentField.filter(pl => pl.id === newFieldPlayer.id)[0];
        playerBack.suspensionType = newFieldPlayer.suspensionType === 'REGULAR_SUSPENSION' ? undefined : 'GAME_SUSPENSION_CONFIRMED';
        this._gameModel.visitor.currentField = [
            ...this._gameModel.visitor.currentField.filter(pl => pl.id !== newFieldPlayer.id),
            playerBack
        ];
        this._visitorField$.next([...this._gameModel.visitor.currentField]);
    }

    bringBackSubstituteForGameSuspendedPlayerHome(playerSubstituted: GamePlayerModel, playerMovedOut: GamePlayerModel): void {}
    bringBackSubstituteForGameSuspendedPlayerVisitor(playerSubstituted: GamePlayerModel, playerMovedOut: GamePlayerModel): void {}
    cancelPlayerSubstitutionPopupHome(playerOnSuspension: GamePlayerModel, suspensionType: SuspensionActionTypes): void {}
    cancelPlayerSubstitutionPopupVisitor(playerOnSuspension: GamePlayerModel, suspensionType: SuspensionActionTypes): void {}

    changeDefenseSystem(defenseChangeEvent: DefenseChangeEvent): void {
        this.logger.debug('GameService.changeDefenseSystem: ', defenseChangeEvent);

        if (defenseChangeEvent.teamMarker === 'HOME') {
            const currDefenseSystem = this._homeDefenseSystem$.value;
            if (currDefenseSystem !== defenseChangeEvent.defenseSystem) {
                this._gameModel.home.defenseSystem = defenseChangeEvent.defenseSystem;
                this._homeDefenseSystem$.next(this._gameModel.home.defenseSystem);
            }
        } else {
            const currDefenseSystem = this._visitorDefenseSystem$.value;
            if (currDefenseSystem !== defenseChangeEvent.defenseSystem) {
                this._gameModel.visitor.defenseSystem = defenseChangeEvent.defenseSystem;
                this._visitorDefenseSystem$.next(this._gameModel.visitor.defenseSystem);
            }
        }
    }

    changeOffenseSystem(homeOffenseSystem: string, visitorOffenseSystem: string): void {
        this._gameModel.home.offenseSystem = homeOffenseSystem;
        this._gameModel.visitor.offenseSystem = visitorOffenseSystem;
    }

    decrementHomeScore(): void {
        this._gameModel.scoreHome--;
        this._homeScore$.next(this._gameModel.scoreHome);
    }

    decrementVisitorScore(): void {
        this._gameModel.scoreVisitor--;
        this._visitorScore$.next(this._gameModel.scoreVisitor);
    }

    enableAllowedTimeOuts(): void {
        enableAllowedTimeOuts(
            this._homeTimeOuts$,
            this._visitorTimeOuts$
        );
    }

    exchangePlayerHome(newFieldPlayer: GamePlayerModel, newBenchPlayer: GamePlayerModel): void {}
    exchangePlayerVisitor(newFieldPlayer: GamePlayerModel, newBenchPlayer: GamePlayerModel): void {}

    incrementHomeScore(): void {
        this._gameModel.scoreHome++;
        this._homeScore$.next(this._gameModel.scoreHome);
    }

    incrementVisitorScore(): void {
        this._gameModel.scoreVisitor++;
        this._visitorScore$.next(this._gameModel.scoreVisitor);
    }

    initCore(core: CoreService): void {
        this._core = core;
    }

    initGame(gameModel: GameModel): void {
        this._gameModel = gameModel;
        this._gameModel.home.currentField = initGamePlayerSlotIdsForLite(this._gameModel.home.currentField);
        this._gameModel.visitor.currentField = initGamePlayerSlotIdsForLite(this._gameModel.visitor.currentField);
        this._homeField$.next([...this._gameModel.home.currentField]);
        this._homePlayers$.next([...this._gameModel.home.currentField]);
        this._visitorField$.next([...this._gameModel.visitor.currentField]);
        this._visitorPlayers$.next([...this._gameModel.visitor.currentField]);
        this._homeName$.next(this._gameModel.home.name);
        this._visitorName$.next(this._gameModel.visitor.name);
        this._homeScore$.next(this._gameModel.scoreHome);
        this._visitorScore$.next(this._gameModel.scoreVisitor);
        this._homeDefenseSystem$.next(this._gameModel.home.defenseSystem);
        this._visitorDefenseSystem$.next(this._gameModel.visitor.defenseSystem);
    }

    putPlayerOnBenchHome(playerOnBench: GamePlayerModel): void {}
    putPlayerOnBenchHomeWithoutPenalty(playerOnBench: GamePlayerModel): void {}
    putPlayerOnBenchVisitor(playerOnBench: GamePlayerModel): void {}

    putPlayerOnBenchHomeLite(playerOnBench: GamePlayerModel, suspensionType: SuspensionActionTypes) {
        this._gameModel.home.currentField = [...this._gameModel.home.currentField.map(pl => {
            if (pl.id === playerOnBench.id) {
                pl.suspensionType = suspensionType === '2_MIN' ? 'REGULAR_SUSPENSION' : 'GAME_SUSPENSION';
            }
            return pl;
        })];
        this._homeField$.next([...this._gameModel.home.currentField]);
    }
    putPlayerOnBenchVisitorLite(playerOnBench: GamePlayerModel, suspensionType: SuspensionActionTypes) {
        this._gameModel.visitor.currentField = [...this._gameModel.visitor.currentField.map(pl => {
            if (pl.id === playerOnBench.id) {
                pl.suspensionType = suspensionType === '2_MIN' ? 'REGULAR_SUSPENSION' : 'GAME_SUSPENSION';
            }
            return pl;
        })];
        this._visitorField$.next([...this._gameModel.visitor.currentField]);
    }

    putPlayerOnBenchVisitorWithoutPenalty(playerOnBench: GamePlayerModel): void {}
    putPlayerOnGameSuspensionHome(playerOnGameSuspension: GamePlayerModel): void {}
    putPlayerOnGameSuspensionVisitor(playerOnGameSuspension: GamePlayerModel): void {}

    continueTrackingRemoveGameSuspendedPlayer(playerId: number, team: TeamMarker) {
        const teamGameModel = team === 'HOME' ? this._gameModel.home : this._gameModel.visitor;
        const teamField$ = team === 'HOME' ? this._homeBench$ : this._visitorBench$;
        const playerModel = teamGameModel.currentField.find(p => p.id === playerId);
        playerModel.suspensionType = 'GAME_SUSPENSION_CONFIRMED';
        teamGameModel.currentField = [
            ...teamGameModel.currentField.filter(pl => pl.id !== playerModel.id),
            playerModel
        ];
        teamField$.next(teamGameModel.currentField);
    }

    continueTrackingSetPlayerPenalty(benchPlayer: GamePlayerModel, team: TeamMarker) {}
    replaceFieldPlayers(homeFieldPlayerIds: number[], visitorFieldPlayerIds: number[]): void {}

    putPos1PlayerOnBenchHome(playerOnBench: GamePlayerModel, suspensionType: SuspensionActionTypes): GamePlayerModel {
        let playerToReturn: GamePlayerModel;

        if (playerOnBench.position === 'gk') {
            const secondGoalKeeper = this._gameModel.home.currentField
                .filter(pl => pl.id !== playerOnBench.id && pl.position === 'gk')[0];
            secondGoalKeeper.slotId = 1;
            secondGoalKeeper.disabled = false;
            playerOnBench.suspensionType = suspensionType === '2_MIN' ? 'REGULAR_SUSPENSION' : 'GAME_SUSPENSION';
            playerOnBench.slotId = 2;
            this._gameModel.home.currentField = [
                ...this._gameModel.home.currentField.filter(pl => pl.position !== 'gk'),
                playerOnBench, secondGoalKeeper
            ];
            playerToReturn = secondGoalKeeper;
        } else {
            const goalkeeperInField = this._gameModel.home.currentField.filter(pl => pl.position === 'gk' && pl.slotId > 2)[0];
            playerOnBench.slotId = goalkeeperInField.slotId;
            goalkeeperInField.slotId = 1;
            playerOnBench.suspensionType = suspensionType === '2_MIN' ? 'REGULAR_SUSPENSION' : 'GAME_SUSPENSION';
            this._gameModel.home.currentField = [
                ...this._gameModel.home.currentField
                    .filter(pl => pl.id !== playerOnBench.id)
                    .filter(pl => pl.id !== goalkeeperInField.id),
                playerOnBench, goalkeeperInField
            ];
            playerToReturn = goalkeeperInField;
        }
        this._homeField$.next([...this._gameModel.home.currentField]);
        return playerToReturn;
    }

    putPos1PlayerOnBenchVisitor(playerOnBench: GamePlayerModel, suspensionType: SuspensionActionTypes): GamePlayerModel {
        let playerToReturn: GamePlayerModel;

        if (playerOnBench.position === 'gk') {
            const secondGoalKeeper = this._gameModel.visitor.currentField
                .filter(pl => pl.id !== playerOnBench.id && pl.position === 'gk')[0];
            secondGoalKeeper.slotId = 1;
            secondGoalKeeper.disabled = false;
            playerOnBench.suspensionType = suspensionType === '2_MIN' ? 'REGULAR_SUSPENSION' : 'GAME_SUSPENSION';
            playerOnBench.slotId = 2;
            this._gameModel.visitor.currentField = [
                ...this._gameModel.visitor.currentField.filter(pl => pl.position !== 'gk'),
                playerOnBench, secondGoalKeeper
            ];
            playerToReturn = secondGoalKeeper;
        } else {
            const goalkeeperInField = this._gameModel.visitor.currentField.filter(pl => pl.position === 'gk' && pl.slotId > 2)[0];
            playerOnBench.slotId = goalkeeperInField.slotId;
            goalkeeperInField.slotId = 1;
            playerOnBench.suspensionType = suspensionType === '2_MIN' ? 'REGULAR_SUSPENSION' : 'GAME_SUSPENSION';
            this._gameModel.visitor.currentField = [
                ...this._gameModel.visitor.currentField
                    .filter(pl => pl.id !== playerOnBench.id)
                    .filter(pl => pl.id !== goalkeeperInField.id),
                playerOnBench, goalkeeperInField
            ];
            playerToReturn = goalkeeperInField;
        }
        this._visitorField$.next([...this._gameModel.visitor.currentField]);
        return playerToReturn;
    }

    resetTimeOut(): void {
        resetTimeOut(
            this._homeTimeOuts$,
            this._visitorTimeOuts$
        );
    }

    substitutePlayerFromBenchHome(substitutePlayer: GamePlayerModel, suspensionType: SuspensionActionTypes): void {}
    substitutePlayerFromBenchVisitor(substitutePlayer: GamePlayerModel, suspensionType: SuspensionActionTypes): void {}

    swapFieldPlayerHome(firstPlayer: GamePlayerModel, secondPlayer: GamePlayerModel): void {
        const slotIdFirstPlayer = firstPlayer.slotId;
        const slotIdSecondPlayer = secondPlayer.slotId;
        firstPlayer.slotId = slotIdSecondPlayer;
        secondPlayer.slotId = slotIdFirstPlayer;
        this._gameModel.home.currentField = [...this._gameModel.home.currentField
            .filter(pl => pl.id !== firstPlayer.id)
            .filter(pl => pl.id !== secondPlayer.id), firstPlayer, secondPlayer];
        this._homeField$.next(this._gameModel.home.currentField);
    }

    swapFieldPlayerVisitor(firstPlayer: GamePlayerModel, secondPlayer: GamePlayerModel): void {
        const slotIdFirstPlayer = firstPlayer.slotId;
        const slotIdSecondPlayer = secondPlayer.slotId;
        firstPlayer.slotId = slotIdSecondPlayer;
        secondPlayer.slotId = slotIdFirstPlayer;
        this._gameModel.visitor.currentField = [...this._gameModel.visitor.currentField
            .filter(pl => pl.id !== firstPlayer.id)
            .filter(pl => pl.id !== secondPlayer.id), firstPlayer, secondPlayer];
        this._visitorField$.next(this._gameModel.visitor.currentField);
    }

    swapGoalkeeperHome(firstPlayer: GamePlayerModel, secondPlayer: GamePlayerModel): void {
        const slotIdFirstPlayer = firstPlayer.slotId;
        const slotIdSecondPlayer = secondPlayer.slotId;
        firstPlayer.slotId = slotIdSecondPlayer;
        secondPlayer.slotId = slotIdFirstPlayer;
        firstPlayer.disabled = false;
        secondPlayer.disabled = true;
        firstPlayer.suspensionType = undefined;
        this._gameModel.home.currentField = [
            ...this._gameModel.home.currentField.filter(pl => pl.id !== firstPlayer.id && pl.id !== secondPlayer.id),
            firstPlayer, secondPlayer
        ];
        this._homeField$.next(this._gameModel.home.currentField);
    }

    swapGoalkeeperVisitor(firstPlayer: GamePlayerModel, secondPlayer: GamePlayerModel): void {
        const slotIdFirstPlayer = firstPlayer.slotId;
        const slotIdSecondPlayer = secondPlayer.slotId;
        firstPlayer.slotId = slotIdSecondPlayer;
        secondPlayer.slotId = slotIdFirstPlayer;
        firstPlayer.disabled = false;
        secondPlayer.disabled = true;
        firstPlayer.suspensionType = undefined;
        this._gameModel.visitor.currentField = [
            ...this._gameModel.visitor.currentField.filter(pl => pl.id !== firstPlayer.id && pl.id !== secondPlayer.id),
            firstPlayer, secondPlayer
        ];
        this._visitorField$.next(this._gameModel.visitor.currentField);
    }


    toggleBenchHome(disable: boolean): void {}
    toggleBenchVisitor(disable: boolean): void {}

    toggleFieldHome(disable: boolean): void {
        // We switch all player to disabled state
        this._gameModel.home.currentField = toggleDisablePlayerList(this._gameModel.home.currentField, disable);
        if (!disable) {
            // Switch all to enable, except the second goalkeeper
            const plAtSlot1 = this._gameModel.home.currentField.filter(pl => pl.slotId===1)[0];
            const secondGks = this._gameModel.home.currentField.filter(pl => pl.position==='gk' && (pl.slotId === 2 || pl.slotId === 3) && plAtSlot1.position==='gk');
            secondGks.forEach(itm=>itm.disabled = true);
            const filterSlots = secondGks.map(itm => itm.slotId);
            this._gameModel.home.currentField = [...this._gameModel.home.currentField.filter(pl => !filterSlots.includes(pl.slotId)), ...secondGks];
        }
        this._homeField$.next([...this._gameModel.home.currentField]);
    }

    toggleFieldHomeWithPlayer(player: GamePlayerModel): void {
        this._homeField$.next([...this._gameModel.home.currentField]);
    }

    toggleFieldVisitor(disable: boolean): void {
        // We switch all player to disabled state
        this._gameModel.visitor.currentField = toggleDisablePlayerList(this._gameModel.visitor.currentField, disable);
        if (!disable) {
            // Switch all to enable, except the second goalkeeper
            const plAtSlot1 = this._gameModel.visitor.currentField.filter(pl => pl.slotId===1)[0];
            const secondGks = this._gameModel.visitor.currentField.filter(pl => pl.position==='gk' && (pl.slotId === 2 || pl.slotId === 3) && plAtSlot1.position==='gk');
            secondGks.forEach(itm=>itm.disabled = true);
            const filterSlots = secondGks.map(itm => itm.slotId);
            this._gameModel.visitor.currentField =[...this._gameModel.visitor.currentField.filter(pl => !filterSlots.includes(pl.slotId)), ...secondGks];
        }
        this._visitorField$.next([...this._gameModel.visitor.currentField]);
    }

    toggleFieldVisitorWithPlayer(player: GamePlayerModel): void {
        this._visitorField$.next([...this._gameModel.visitor.currentField]);
    }

    toggleSuspensionsHome(disable: boolean): void {}
    toggleSuspensionsVisitor(disable: boolean): void {}

    undoPlayerAfterSuspensionHome(newFieldPlayer: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        this._gameModel.home.currentField = [...this._gameModel.home.currentField.map(pl => {
            if (pl.id === newFieldPlayer.id) {
                pl.suspensionType = undefined;
            }
            return pl;
        })];
        this._homeField$.next([...this._gameModel.home.currentField]);

    }

    undoPlayerAfterSuspensionVisitor(newFieldPlayer: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        this._gameModel.visitor.currentField = [...this._gameModel.visitor.currentField.map(pl => {
            if (pl.id === newFieldPlayer.id) {
                pl.suspensionType = undefined;
            }
            return pl;
        })];
        this._visitorField$.next([...this._gameModel.visitor.currentField]);
    }

    undoPlayerInGameHomeAfterPenalty(
        playerOnSuspension: GamePlayerModel,
        playerSelectedToGoBack: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        this._gameModel.home.currentField = [...this._gameModel.home.currentField.map(pl => {
            if (pl.id === playerOnSuspension.id) {
                pl.suspensionType = suspensionType === '2_MIN' ? 'REGULAR_SUSPENSION' : 'GAME_SUSPENSION';
            }
            return pl;
        })];
        this._homeField$.next([...this._gameModel.home.currentField]);

    }

    undoPlayerInGameVisitorAfterPenalty(
        playerOnSuspension: GamePlayerModel,
        playerSelectedToGoBack: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        this._gameModel.visitor.currentField = [...this._gameModel.visitor.currentField.map(pl => {
            if (pl.id === playerOnSuspension.id) {
                pl.suspensionType = suspensionType === '2_MIN' ? 'REGULAR_SUSPENSION' : 'GAME_SUSPENSION';
            }
            return pl;
        })];
        this._visitorField$.next([...this._gameModel.visitor.currentField]);
    }

    undoPos1AfterSuspensionHome(suspendedPos1: GamePlayerModel, suspensionType: SuspensionActionTypes): void {}
    undoPos1AfterSuspensionVisitor(suspendedPos1: GamePlayerModel, suspensionType: SuspensionActionTypes): void {}
    undoPos1SuspensionSubstituteHome(suspendedSubstitute: GamePlayerModel): void {}
    undoPos1SuspensionSubstituteVisitor(suspendedSubstitute: GamePlayerModel): void {}

    toggleSecondGoalkeeperHome(): void {
        const secondGks = this._gameModel.home.currentField.filter(pl => pl.position==='gk' && (pl.slotId === 2 || pl.slotId === 3));
        secondGks.forEach(itm => itm.disabled = false);
        const filterSlots = secondGks.map(itm => itm.slotId);
        this._gameModel.home.currentField = [...this._gameModel.home.currentField.filter(pl => !filterSlots.includes(pl.slotId)), ...secondGks];
        this._homeField$.next([...this._gameModel.home.currentField]);
    }

    toggleSecondGoalkeeperVisitor(): void {
        const secondGks = this._gameModel.visitor.currentField.filter(pl => pl.position==='gk' && (pl.slotId === 2 || pl.slotId === 3));
        secondGks.forEach(itm => itm.disabled = false);
        const filterSlots = secondGks.map(itm => itm.slotId);
        this._gameModel.visitor.currentField = [...this._gameModel.visitor.currentField.filter(pl => !filterSlots.includes(pl.slotId)), ...secondGks];
        this._visitorField$.next([...this._gameModel.visitor.currentField]);
    }

    swapPlayerForFreeSlotIdHome(player: GamePlayerModel, slotId: number): void {
        if (this._gameModel.home.currentField.some(pl => pl.slotId === slotId)) {
            this.logger.debug('LiteGameEngine.swapPlayerForFreeSlotIdHome: Prevented since slotId already occupied (maybe from a field player in goal)', slotId);
        } else {
            player.slotId = slotId;
            this._gameModel.home.currentField = [
                ...this._gameModel.home.currentField.filter(pl => pl.id !== player.id),
                player
            ];
            this._homeField$.next([...this._gameModel.home.currentField]);
        }
    }

    swapPlayerForFreeSlotIdVisitor(player: GamePlayerModel, slotId: number): void {
        if (this._gameModel.visitor.currentField.some(pl => pl.slotId === slotId)) {
            this.logger.debug('LiteGameEngine.swapPlayerForFreeSlotIdVisitor: Prevented since slotId already occupied (maybe from a field player in goal)', slotId);
        } else {
            player.slotId = slotId;
            this._gameModel.visitor.currentField = [
                ...this._gameModel.visitor.currentField.filter(pl => pl.id !== player.id),
                player
            ];
            this._visitorField$.next([...this._gameModel.visitor.currentField]);
        }
    }

    undoPos1PlayerSuspensionLiteHome(
        playerOnSuspension: GamePlayerModel,
        playerSelectedToGoBack: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        playerOnSuspension.suspensionType = undefined;

        if (playerOnSuspension.position === 'gk') {
            playerOnSuspension.slotId = 1;
            playerOnSuspension.disabled = false;
            playerSelectedToGoBack.slotId = 2;
            playerSelectedToGoBack.disabled = true;
        } else {
            playerSelectedToGoBack.slotId = playerOnSuspension.slotId;
            playerOnSuspension.slotId = 1;
        }

        this._gameModel.home.currentField = [
            ...this._gameModel.home.currentField
                .filter(pl => pl.id !== playerOnSuspension.id)
                .filter(pl => pl.id !== playerSelectedToGoBack.id),
            playerOnSuspension,
            playerSelectedToGoBack
        ];

        this._homeField$.next([...this._gameModel.home.currentField]);
    }

    undoPos1PlayerSuspensionLiteVisitor(
        playerOnSuspension: GamePlayerModel,
        playerSelectedToGoBack: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        playerOnSuspension.suspensionType = undefined;

        if (playerOnSuspension.position === 'gk') {
            playerOnSuspension.slotId = 1;
            playerOnSuspension.disabled = false;
            playerSelectedToGoBack.slotId = 2;
            playerSelectedToGoBack.disabled = true;
        } else {
            playerSelectedToGoBack.slotId = playerOnSuspension.slotId;
            playerOnSuspension.slotId = 1;
        }

        this._gameModel.visitor.currentField = [
            ...this._gameModel.visitor.currentField
                .filter(pl => pl.id !== playerOnSuspension.id)
                .filter(pl => pl.id !== playerSelectedToGoBack.id),
            playerOnSuspension,
            playerSelectedToGoBack
        ];

        this._visitorField$.next([...this._gameModel.visitor.currentField]);
    }

    homeEmptyGoal(): boolean {
        return this._gameModel.home.currentField.filter(pl => pl.slotId === 1)[0].position !== 'gk';
    }

    visitorEmptyGoal(): boolean {
        return this._gameModel.visitor.currentField.filter(pl => pl.slotId === 1)[0].position !== 'gk';
    }

    swapGoalAdditionalPlayerWithGoalkeeperOnBenchHome(): void {
        const goalKeeperOnBench = this._gameModel.home.currentField.filter(pl => pl.position === 'gk' && pl.slotId > 2)[0];
        goalKeeperOnBench.isBench = false;
        const additionalFieldPlayer = this._gameModel.home.currentField.filter(pl => pl.slotId === 1)[0];
        additionalFieldPlayer.slotId = goalKeeperOnBench.slotId;
        goalKeeperOnBench.slotId = 1;
        this._gameModel.home.currentField = [...this._gameModel.home.currentField
            .filter(pl => pl.id !== goalKeeperOnBench.id)
            .filter(pl => pl.id !== additionalFieldPlayer.id), goalKeeperOnBench, additionalFieldPlayer];
        this._homeField$.next(this._gameModel.home.currentField);
    }

    swapGoalAdditionalPlayerWithGoalkeeperOnBenchVisitor(): void {
        const goalKeeperOnBench = this._gameModel.visitor.currentField.filter(pl => pl.position === 'gk' && pl.slotId > 2)[0];
        goalKeeperOnBench.isBench = false;
        const additionalFieldPlayer = this._gameModel.visitor.currentField.filter(pl => pl.slotId === 1)[0];
        additionalFieldPlayer.slotId = goalKeeperOnBench.slotId;
        goalKeeperOnBench.slotId = 1;
        this._gameModel.visitor.currentField = [...this._gameModel.visitor.currentField
            .filter(pl => pl.id !== goalKeeperOnBench.id)
            .filter(pl => pl.id !== additionalFieldPlayer.id), goalKeeperOnBench, additionalFieldPlayer];
        this._visitorField$.next(this._gameModel.visitor.currentField);
    }

    performClockTickEvent(timer: TimerModel): void  {
        // intentionally do nothing
    }

    deleteVideoSuspensionEventHome(
        playerId: number,
        suspensionType: SuspensionActionTypes,
        suspendedPlayerId: number
    ): boolean {
        const suspendedPlayer = this._gameModel.home.currentField.filter(pl => pl.id === playerId)[0];
        if (suspendedPlayer.suspensionType) {
            suspendedPlayer.suspensionType = undefined;
            suspendedPlayer.disabled = suspendedPlayer.position === 'gk';
        }
        this._gameModel.home.currentField = [...this._gameModel.home.currentField];
        this._homeField$.next(this._gameModel.home.currentField);
        return false;
    }

    deleteVideoSuspensionEventVisitor(
        playerId: number,
        suspensionType: SuspensionActionTypes,
        suspendedPlayerId: number
    ): boolean {
        const suspendedPlayer = this._gameModel.visitor.currentField.filter(pl => pl.id === playerId)[0];
        if (suspendedPlayer.suspensionType) {
            suspendedPlayer.suspensionType = undefined;
            suspendedPlayer.disabled = suspendedPlayer.position === 'gk';
        }
        this._gameModel.visitor.currentField = [...this._gameModel.visitor.currentField];
        this._visitorField$.next(this._gameModel.visitor.currentField);
        return false;
    }

}
