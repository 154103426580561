import { NGXLogger } from 'ngx-logger';
import { tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { LoadingService } from '../loading/loading.service';

export const loggerFn = <T>(logger: NGXLogger, functionName: string) =>
  tap<T>(t => logger.debug(`Data received - ${functionName}: `, t));

export const toastrSuccessFn = <T>(toastrService: ToastController, slug: string, msg: string) =>
  tap<T>(async () => {
    const toast = await toastrService
      .create({header: slug, message: msg, color: 'success', duration: 10000});
    await toast.present();
  });

export const handleErrorFn = (
    toastrService: ToastController,
    loadingService: LoadingService,
    message: string,
    stopSpinner = true,
    silentError = false,
) => {
  return async (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (stopSpinner) {
        await loadingService.dismiss();
    }
    if (!silentError) {
      const toast = await toastrService
          .create({header: 'Error',
            color: 'danger',
            message: `An error has occurred: ${message} - ${errorMessage}!`,
            duration: 10000});
      await toast.present();
    }
    return throwError(new Error(errorMessage));
  };
};
