import { Component, Input, OnInit } from '@angular/core';
import { Colors, Label, MultiDataSet, SingleDataSet } from 'ng2-charts';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements OnInit {

  private _labels: Label[];
  private _data: SingleDataSet;
  private _height: string;
  private _colors: Colors[] = [{ backgroundColor: ['#08A93A', '#FEC200', '#FF3143'] }];
  private _doughnutChartType: ChartType = 'doughnut';
  private _doughnutChartOptions: ChartOptions = {
    responsive: false,
    maintainAspectRatio: true,
    legend: {
      display: false,
      labels: {
        fontSize: 8
      }
    }
  };

  constructor() { }

  ngOnInit() {}

  @Input()
  set colors(value: Colors[]) {
    this._colors = value;
  }

  get colors(): Colors[] {
    return this._colors;
  }

  get doughnutChartType(): ChartType {
    return this._doughnutChartType;
  }

  get labels(): Label[] {
    return this._labels;
  }

  @Input()
  set labels(value: Label[]) {
    this._labels = value;
  }

  get data(): SingleDataSet {
    return this._data;
  }

  @Input()
  set data(value: SingleDataSet) {
    this._data = value;
  }

  @Input()
  set doughnutChartOptions(value: Chart.ChartOptions) {
    this._doughnutChartOptions = value;
  }

  get doughnutChartOptions(): ChartOptions {
    return this._doughnutChartOptions;
  }

  @Input()
  set colorsAsList(value: string[]) {
    this._colors = [{backgroundColor: [...value]}];
  }

  get height(): string {
    return this._height;
  }

  @Input()
  set height(value: string) {
    this._height = value;
  }
}
