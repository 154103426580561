import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomTabsComponent } from './custom-tabs/custom-tabs.component';
import { CustomTabComponent } from './custom-tabs/custom-tab/custom-tab.component';
import { HeaderComponent } from './header/header.component';
import { HeaderContainerComponent } from './header/header-container.component';
import { HandballaiLogoComponent } from './handballai-logo/handballai-logo.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseChartDirective } from 'ng2-charts';
import { IndividualStatsComponent } from 'src/app/main/components/individual-stats/individual-stats.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PlayByPlayGridComponent } from './play-by-play-grid/play-by-play-grid.component';
import { ExecutionPositionComponent } from 'src/app/main/components/shooter-position/shooter-position.component';
import { ShotsStatsComponent } from 'src/app/main/components/shots-stats/shots-stats.component';
import { TimelineStatsComponent } from 'src/app/main/components/timeline-stats/timeline-stats.component';
import { OverviewStatsComponent } from 'src/app/main/components/overview-stats/overview-stats.component';
import { TeamStatsComponent } from 'src/app/main/components/team-stats/team-stats.component';
import { TranslateModule } from '@ngx-translate/core';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { PdfViewerPage } from './pdf-viewer/pdf-viewer';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AdvancedStatsComponent } from './advanced-stats/advanced-stats.component';
import { GameStatsComponent } from './game-stats/game-stats.component';
import { QuickStatsComponent } from './quick-stats/quick-stats.component';

import { HistoricPlayByPlayComponent } from './historic-play-by-play/historic-play-by-play.component';
import { ShotTransformPipe } from './shot-pipe/shot-transform.pipe';
import { QuickStatsTableComponent } from './quick-stats-table/quick-stats-table.component';
import { SyncPopverComponent } from './sync-popver/sync-popver.component';
import { AccordionComponent } from 'src/app/main/components/accordion/accordion.component';
import { GameSystemStatsComponent } from 'src/app/main/components/game-system-stats/game-system-stats.component';
import { QuickStatsTeamComponent } from './quick-stats-team/quick-stats-team.component';
import { QuickStatsTeamTeamStatsComponent } from './quick-stats-team-team-stats/quick-stats-team-team-stats.component';
import { PlayByPlayEditComponent } from './play-by-play-edit-popover/play-by-play-edit.component';
import { ManageTableComponent } from './manage-table/manage-table.component';
import { GameSystemStatsContainerComponent } from 'src/app/main/components/game-system-stats/game-system-stats-container.component';
import { GameSystemPlayerStatsComponent } from 'src/app/main/components/game-system-stats/game-system-player-stats.component';
import { DashIndividualStatsComponent } from './dash-individual-stats/dash-individual-stats.component';
import { DashTeamStatsComponent } from './dash-team-stats/dash-team-stats.component';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { StackedNavigationComponent } from './stacked-navigation/stacked-navigation.component';
import { StackedPageComponent } from './stacked-page/stacked-page.component';
import { SafePipe } from './safe.pipe';
import { DateResolverPipe } from './date-resolver/date-resolver.pipe';
import { SeasonsSelectComponent } from './seasons-select/seasons-select.component';
import { DashboardAccordionComponent } from './dashboard-accordion/dashboard-accordion.component';
import { QuickStatsTimelineComponent } from './quick-stats-timeline/quick-stats-timeline.component';
import { IonicSelectableComponent } from 'ionic-selectable';

const COMPONENTS = [
  CustomTabsComponent,
  CustomTabComponent,
  HeaderComponent,
  HeaderContainerComponent,
  HandballaiLogoComponent,
  DoughnutChartComponent,
  DashIndividualStatsComponent,
  DashTeamStatsComponent,
  UserAvatarComponent,
  IndividualStatsComponent,
  PlayByPlayEditComponent,
  PlayByPlayGridComponent,
  HistoricPlayByPlayComponent,
  ExecutionPositionComponent,
  ShotsStatsComponent,
  TimelineStatsComponent,
  OverviewStatsComponent,
  TeamStatsComponent,
  PdfViewerPage,
  AdvancedStatsComponent,
  GameStatsComponent,
  QuickStatsComponent,
  QuickStatsTableComponent,
  SyncPopverComponent,
  AccordionComponent,
  GameSystemStatsComponent,
  QuickStatsTeamComponent,
  QuickStatsTeamTeamStatsComponent,
  ManageTableComponent,
  GameSystemStatsContainerComponent,
  GameSystemPlayerStatsComponent,
  StackedNavigationComponent,
  StackedPageComponent,
  SeasonsSelectComponent,
  DashboardAccordionComponent,
  QuickStatsTimelineComponent
];

const PIPES = [
  ShotTransformPipe,
  SafePipe,
  DateResolverPipe
];

@NgModule({
  declarations: [ ...COMPONENTS, ...PIPES, ],
  imports: [
    IonicModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    TooltipModule,
    NgxDatatableModule,
    PdfJsViewerModule,
    TranslateModule.forChild(),
    IonicSelectableComponent,
    BaseChartDirective
  ],
  providers: [DecimalPipe, SvgIconsModule],
  exports: [...COMPONENTS, ...PIPES, ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {}
