import {
    VideoPlayByPlay,
    VideoPlayTimeContainer,
    VideoTrackerContainer
} from 'src/app/shared-services/statistics/video/model/video-tracker.util';
import { GameService } from 'src/app/shared-services/game/game.service';
import { GamePlayerModel } from 'src/app/shared-services/model/game.model';
import {
    ExchangePlayerPopoverContainerComponent
} from 'src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/exchange-player-popover/exchange-player-popover-container.component';
import { PopoverController } from '@ionic/angular';
import { GameTeamModel, HalftimeTypes } from '@handballai/stats-calculation';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { GameType } from 'src/app/shared-services/game/game-type.model';
import { TimeOutSet } from 'src/app/shared-services/game/events/time-out-reset';

export interface DeleteEventResult {
    eventList: VideoPlayByPlay[];
    videoContainerFirstHalf: VideoTrackerContainer;
    videoContainerSecondHalf: VideoTrackerContainer;
    createTimerEvent: boolean;
}

export const deleteEventHandler = async (
  popoverController: PopoverController,
  gamePlayerModel: Map<number, GamePlayerModel>,
  gameService: GameService,
  eventToDelete: VideoPlayByPlay,
  eventList: VideoPlayByPlay[],
  videoContainerFirstHalf: VideoTrackerContainer,
  videoContainerSecondHalf: VideoTrackerContainer,
): Promise<DeleteEventResult> => {

    const videoContainer = eventToDelete.eventTime.halftime === 'T1' ? videoContainerFirstHalf : videoContainerSecondHalf;

    let finalEventList: VideoPlayByPlay[];
    let createEvent = false;
    // Also 2_MIN is here to check if it is created by double event
    if (eventToDelete.event === '2_MIN' || eventToDelete.event === '2MIN_PROVOKE' || eventToDelete.event === '7M_PROVOKE_SUSPENSION' ) {
        // Delete two events + Slot of clock time
        finalEventList = eventList
            .filter(vpp => vpp.eventTime.secondsSinceStartOfGame !== eventToDelete.eventTime.secondsSinceStartOfGame);
        const deleteEvent = eventList
            .filter(vpp => vpp.eventTime.secondsSinceStartOfGame === eventToDelete.eventTime.secondsSinceStartOfGame);
        const twoMinEvent = deleteEvent.filter(ev => ev.event === '2_MIN');
        videoContainer.removeNodeWithSuspension(eventToDelete.eventTime.secondsSinceStartOfGame);
        if (twoMinEvent.length === 1) {
            const offensePlayerIdToDelete = await findOffensePlayerForEventDeletion(
                popoverController,
                gameService.gameMode$.value,
                twoMinEvent[0].teamMarker === 'HOME' ? gameService.gameModel.home : gameService.gameModel.visitor,
                gamePlayerModel,
                twoMinEvent[0]
            );
            if (twoMinEvent[0].teamMarker === 'HOME') {
                createEvent = gameService.deleteVideoSuspensionEventHome(
                    offensePlayerIdToDelete,
                    '2_MIN',
                    twoMinEvent[0].offensePlayer.id
                );
            } else {
                createEvent = gameService.deleteVideoSuspensionEventVisitor(
                    offensePlayerIdToDelete,
                    '2_MIN',
                    twoMinEvent[0].offensePlayer.id
                );
            }
        }
    } else if (eventToDelete.event === 'RED_CARD' || eventToDelete.event === 'BLUE_CARD') {
        finalEventList = eventList.filter(vpp => vpp.orderId !== eventToDelete.orderId);
        videoContainer.removeNodeWithSuspension(eventToDelete.eventTime.secondsSinceStartOfGame);
        const offensePlayerIdToDelete = await findOffensePlayerForEventDeletion(
            popoverController,
            gameService.gameMode$.value,
            eventToDelete.teamMarker === 'HOME' ? gameService.gameModel.home : gameService.gameModel.visitor,
            gamePlayerModel,
            eventToDelete
        );
        if (eventToDelete.teamMarker === 'HOME') {
            createEvent = gameService.deleteVideoSuspensionEventHome(
                offensePlayerIdToDelete,
                eventToDelete.event,
                eventToDelete.offensePlayer.id
            );
        } else {
            createEvent = gameService.deleteVideoSuspensionEventVisitor(
                offensePlayerIdToDelete,
                eventToDelete.event,
                eventToDelete.offensePlayer.id
            );
        }
    } else if (eventToDelete.event === 'TIME_OUT') {
        finalEventList = eventList.filter(vpp => vpp.orderId !== eventToDelete.orderId);
        videoContainer.removeNodeWithSuspension(eventToDelete.eventTime.secondsSinceStartOfGame); // Removes the timeout node since it is const function (equal to suspension)
        const timeoutsHalf: TimeOutSet = finalEventList.sort((a,b)=>a.orderId-b.orderId).reduce((acc, vpp) => {
            if (acc.currentHalf != vpp.eventTime.halftime) {
                acc.currentHalf = vpp.eventTime.halftime;
                acc.homeHalfTimeouts = 0;
                acc.visitorHalfTimeouts = 0;
            }
            if (vpp.event.includes('TIME_OUT') && vpp.teamMarker === 'HOME') {acc.homeHalfTimeouts++; acc.homeTimeouts++;}
            if (vpp.event.includes('TIME_OUT') && vpp.teamMarker === 'VISITOR') {acc.visitorHalfTimeouts++; acc.homeTimeouts++;}
            console.warn(`[${vpp.orderId}] TIMEOUTS HALF`, acc);
            return acc;
        }, { currentHalf: 'T1' as HalftimeTypes, homeHalfTimeouts: 0, visitorHalfTimeouts: 0, homeTimeouts: 0, visitorTimeouts: 0 });
        console.log('TIMEOUTS HALF', timeoutsHalf);
        gameService.setAllTimeOuts(timeoutsHalf);
    } else {
        // Just delete the event
        finalEventList = eventList.filter(vpp => vpp.orderId !== eventToDelete.orderId);
    }

    return {
        eventList: finalEventList,
        videoContainerFirstHalf: eventToDelete.eventTime.halftime === 'T1' ? videoContainer : videoContainerFirstHalf,
        videoContainerSecondHalf: eventToDelete.eventTime.halftime === 'T2' ? videoContainer : videoContainerSecondHalf,
        createTimerEvent: createEvent
    };
};

export const findOffensePlayerForEventDeletion = async (
    popoverController: PopoverController,
    gameMode: GameType,
    gameTeamModel: GameTeamModel,
    gamePlayerModel: Map<number, GamePlayerModel>,
    twoMinuteEvent: VideoPlayByPlay
): Promise<number> => {
    return new Promise(async (resolve) => {
        if (gameMode === 'LITE_MODE') {
            resolve(twoMinuteEvent.offensePlayer.id);
        } else {
            const returnSubject = new Subject<number>();
            returnSubject
                .pipe(first())
                .subscribe(value => resolve(value));
            const popover = await popoverController.create({
                component: ExchangePlayerPopoverContainerComponent,
                cssClass: 'global__popover global__popover--doubleEvent',
                // translucent: true,
                backdropDismiss: false,
                componentProps: {
                    team: gameTeamModel,
                    eventTime: twoMinuteEvent.eventTime,
                    teamMarker: twoMinuteEvent.teamMarker,
                    suspensionAction: twoMinuteEvent.event,
                    selectedSuspendedPlayer: twoMinuteEvent.offensePlayer,
                    isRegularMode: false,
                    returnSubject: returnSubject
                }
            });
            await popover.present();
        }
    });
};
