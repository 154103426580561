<div class="confirm-modal-wrapper my-8">
  <div class="header">
    <ion-item lines="none">
      <ion-label>
        {{'Organizations'|translate}}
      </ion-label>
      <ion-button (click)="onCreateAccount()" color="secondary">
        <ion-icon name="add-outline"></ion-icon>
      </ion-button>
    </ion-item>
    <hr style="background-color: black; height: 3px;">
  </div>
  <ion-item *ngFor="let account of accounts; let i = index" lines="full" [color]="account.id === selectedAccount ? 'alternative':''"
            class="item-default clickAllowedSource"
            (click)="onAccountSelected(account.id)"
  >
    <span class="clickAllowedSource ongoing" [class.expired]="isExpiredDate(account)">
            {{ isExpiredDate(account) ? 'expired':'ongoing' }}
          </span>
    <ion-label class="clickAllowedSource">
      {{account.name}}<br>
      <span class="clickAllowedSource">
              {{account.renewalDate | dateFormat }} - {{account.renewalPrice|priceFormat}}/{{account.renewalPeriod}}
            </span>
    </ion-label>

    <ion-buttons slot="end">
      <ion-button
          (click)="openOrganizationOptions($event, account)"
          color="secondary"
          class="game-options-btn"
      >
        <ion-icon
            slot="icon-only"
            name="ellipsis-horizontal"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>
</div>
