import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CoreService } from '../../../../../shared-services/core.service';
import { ModalController, ToastController } from '@ionic/angular';
import { AddFolderModalComponent } from '../add-folder-modal/add-folder-modal.component';
import { MyTeamFolderDto, MyTeamPlayerDto } from '../../../../../api/hai-players-api';
import { PlayersService } from '../../players.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
    selector: 'app-manage-folder-access-modal',
    templateUrl: './manage-folder-access-modal.component.html',
    styleUrls: ['./manage-folder-access-modal.component.scss'],
    standalone: false
})
export class ManageFolderAccessModalComponent  implements OnInit {

  @ViewChild('select') select!: NgSelectComponent;
  @Input() folder: MyTeamFolderDto;
  @Input() players: MyTeamPlayerDto[] = [];
  @Input() accountEquipoId?: number;
  public newPlayer: any;

  public availablePlayers: MyTeamPlayerDto[] = [];
  public playersForFolder: any[] = [];
  public playersForFolderIds: number[] = [];

  constructor(private readonly core: CoreService,
              private readonly modalCntrl: ModalController,
              private readonly toastCntl: ToastController,
              private readonly cdr: ChangeDetectorRef,
              private readonly playersService: PlayersService

  ) { }

  async ngOnInit() {
    await this.playersService.getMyTeamPlayersByFolderId(this.folder.id);
    this.playersService.myTeamPlayersForSpecificFolder$.subscribe(players => {
      this.playersForFolder = players;
      this.playersForFolderIds = players.map(player => player.id);
      this.cdr.detectChanges();
    });

    this.playersService.myTeamPlayers$.subscribe(players => {
      if (this.players.length) {
        this.availablePlayers = this.players;
      } else {
        this.availablePlayers = players;
      }
      this.cdr.detectChanges();
    });
  }

  public async onCancel(): Promise<void> {
    await this.modalCntrl.dismiss();
  }

  public async onPlayerAdd(player: MyTeamPlayerDto) {
    if (player && this.folder) {
      await this.playersService.addFolderPermissionToThePlayer(player.id.toString(), this.folder.id, this.accountEquipoId);
      this.select.handleClearClick();
    }
  }

  public async onRemovePlayerClick(player: MyTeamPlayerDto) {
    await this.playersService.removeFolderPermissionFromThePlayer(player.id.toString(), this.folder.id, this.accountEquipoId);
  }

  public async onRemoveAllPlayerAccessesClick() {
    if (this.playersForFolder.length) {
      await this.playersService.removeFolderPermissionFromThePlayer(this.playersForFolder.map(p => p.id).toString(), this.folder.id, this.accountEquipoId);
    }
  }

  public async onAddAllPlayersClick() {
    if (this.availablePlayers.length) {
      await this.playersService.addFolderPermissionToThePlayer(this.availablePlayers.map(p => p.id).toString(), this.folder.id, this.accountEquipoId);
    }
  }

}
