import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CoreService } from 'src/app/shared-services/core.service';
import { CounterModel, ExecutionPositionTypes, GoalZoneTypes } from '@handballai/stats-calculation';
import {
  aggregateByShooterLocation,
  aggregateByShotLocationAndExecutionPosition,
  executionPositionNameMap,
  ExecutionPositionStructure,
  ShotLocationStructure,
} from 'src/app/main/components/shooter-position/shooter-position-helper';
import { NGXLogger } from 'ngx-logger';
import { TypePlayerTeamGamesSelection } from '../../pages/aehandler-module/pages/scouting-module/components/type-player-team-game-selector/type-player-team-game-selector.component';
import { VideoModalComponent } from '../../pages/aehandler-module/pages/scouting-module/components/your-search/video-modal/video-modal.component';
import { PostOutZoneTypes } from 'src/app/shared-services/actions/action-types';
import { BehaviorSubject } from 'rxjs';

export type ShotLocation = PostOutZoneTypes|GoalZoneTypes;
export type CounterSelectModel = {
  event: ('GOAL'|'SAVE'|'POST_OUT')[];
  executionPosition?: ExecutionPositionTypes;
  shotLocation?: ShotLocation;
}

@Component({
    selector: 'app-shooter-position',
    templateUrl: './shooter-position.component.html',
    styleUrls: ['./shooter-position.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ExecutionPositionComponent implements OnInit, OnChanges {
  containerWidth = 775;
  containerHeight = 755; // rectWidth/pathRealWidth*pathRealHeight;
  public readonly sP: { pm: { gk: { x: string; y: string; }; lw: { x: string; y: string; }; rw: { x: string; y: string; }; lb: { x: string; y: string; }; rb: { x: string; y: string; }; cb: { x: string; y: string; }; lp: { x: string; y: string; }; LeftWing: { x: string; y: string; }; RightWing: { x: string; y: string; }; Center6m: { x: string; y: string; }; Left6m: { x: string; y: string; }; Right6m: { x: string; y: string; }; Center9m: { x: string; y: string; }; Left9m: { x: string; y: string; }; Right9m: { x: string; y: string; }; '7Meters': { x: string; y: string; }; OppositeField: { x: string; y: string; }; '7MetersLabel': { x: string; y: string; }; OppositeFieldLabel: { x: string; y: string; }; }; containerWidth: number; containerHeight: number; rectH: number; rectW: number; rectYC: number; rectXC: number; calcPos: (percent: string, axis?: "x" | "y", compensate?: number) => number; };

  private _goalCounters: CounterModel[] = [];
  private _goalConnectionCounter: CounterModel[] = [];
  private _executionPositionStructure: ExecutionPositionStructure = undefined;
  private _shotLocationStructure: ShotLocationStructure = undefined;
  private _shotLocationSelected = '';

  @Input() displayCounterSelect: boolean = false;
  @Input() targetSelection: TypePlayerTeamGamesSelection;
  @Input() position?: string;

  @Output()
  counterSelect = new EventEmitter<CounterSelectModel>();

  constructor(public core: CoreService, private readonly logger: NGXLogger) {
    this.sP = {
      pm: this.core.positionMap,
      containerWidth: this.containerWidth,
      containerHeight: this.containerHeight,

      rectH: 22 * 4,
      rectW: 45 * 4,
      rectYC: (-22 * 4) / 2,
      rectXC: (-45 * 4) / 2,

      calcPos: (percent: string, axis: 'x' | 'y' = 'x', compensate = 0) =>
        (+percent.replace('%', '') * (axis == 'x' ? this.containerWidth : this.containerHeight)) / 100 + compensate,
    };
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.position) {
      this.onExecutionPositionSelect(this.position);
    }
  }

  get goalCounters(): CounterModel[] {
    return this._goalCounters;
  }

  @Input()
  set goalCounters(value: CounterModel[]) {
    this._goalCounters = value;
  }

  @Input()
  set goalConnectionCounter(value: CounterModel[]) {
    this._goalConnectionCounter = value;
    this._shotLocationSelected = '';
    const shotConnectionStructure = aggregateByShotLocationAndExecutionPosition(this._goalConnectionCounter);
    this._shotLocationStructure = shotConnectionStructure.shotLocations;
    this._executionPositionStructure = shotConnectionStructure.executionPositions;
  }

  get shotLocationStructure(): ShotLocationStructure {
    return this._shotLocationStructure;
  }

  get executionPositionStructure(): ExecutionPositionStructure {
    return this._executionPositionStructure;
  }

  get shotLocationSelected(): string {
    return this._shotLocationSelected;
  }

  public getGoalStatsByTypeAndSegment(type: string, segment: number): number {
    const count = this._goalCounters.filter((counterType) => counterType.counterType === `${type}-${segment}`);
    return count.length > 0 ? count[0].counter : 0;
  }

  public getGoalStatsByType(type: string): number {
    const count = this._goalCounters.filter((counterType) => counterType.counterType === type);
    return count.length > 0 ? count[0].counter : 0;
  }

  public getShotLocationForGoalsBySegment(segment: number): number {
    if (!this._shotLocationStructure) {
      return 0;
    }
    return this._shotLocationStructure[`goals${segment}`];
  }

  public getShotLocationForShotsBySegment(segment: number): number {
    if (!this._shotLocationStructure) {
      return 0;
    }
    return this._shotLocationStructure[`shoots${segment}`];
  }

  public showShooterPosition() {
    if (!this.position) {
      return true;
    }
    if (this.executionPositionStructure?.shootsLeftWing > 0 && this.position === 'CONNECT_G_LEFT_WING:CONNECT_S_LEFT_WING') {
      return true;
    } else if (this.executionPositionStructure?.shootsRightWing > 0 && this.position === 'CONNECT_G_RIGHT_WING:CONNECT_S_RIGHT_WING') {
      return true;
    } else if (this.executionPositionStructure?.shootsCenter6m > 0 && this.position === 'CONNECT_G_CENTER_6M:CONNECT_S_CENTER_6M') {
      return true;
    } else if (this.executionPositionStructure?.shootsLeft6m > 0 && this.position === 'CONNECT_G_LEFT_6M:CONNECT_S_LEFT_6M') {
      return true;
    } else if (this.executionPositionStructure?.shootsRight6m > 0 && this.position === 'CONNECT_G_RIGHT_6M:CONNECT_S_RIGHT_6M') {
      return true;
    } else if (this.executionPositionStructure?.shootsCenter9m > 0 && this.position === 'CONNECT_G_CENTER_9M:CONNECT_S_CENTER_9M') {
      return true;
    } else if (this.executionPositionStructure?.shootsLeft9m > 0 && this.position === 'CONNECT_G_LEFT_9M:CONNECT_S_LEFT_9M') {
      return true;
    } else if (this.executionPositionStructure?.shootsRight9m > 0 && this.position === 'CONNECT_G_RIGHT_9M:CONNECT_S_RIGHT_9M') {
      return true;
    } else if (this.executionPositionStructure?.shoots7Meters > 0 && this.position === 'CONNECT_G_7_METERS:CONNECT_S_7_METERS') {
      return true;
    } else if (this.executionPositionStructure?.shootsOppositeField > 0 && this.position === 'CONNECT_G_OPPOSITE_FIELD:CONNECT_S_OPPOSITE_FIELD') {
      return true;
    }
    return false;
  }

  selectedLocation = new BehaviorSubject<{goalsProp: string; shotsProp: string;}>({goalsProp: '',shotsProp: ''});
  public onExecutionPositionSelect(identifier: string): void {
    this.logger.debug('ExecutionPositionComponent.onExecutionPositionSelect', identifier, this._shotLocationSelected);
    if (this._shotLocationSelected === identifier) {
      this._shotLocationStructure = aggregateByShotLocationAndExecutionPosition(
        this._goalConnectionCounter
      ).shotLocations;
      this._shotLocationSelected = '';
    } else {
      const identifierParts = identifier.split(':');
      this.selectedLocation.next({
        goalsProp: executionPositionNameMap.get(identifierParts[0]),
        shotsProp: executionPositionNameMap.get(identifierParts[1]),
      });
      this._shotLocationSelected = identifier;
      this._shotLocationStructure = aggregateByShooterLocation(
        this._goalConnectionCounter,
        identifierParts[0],
        identifierParts[1]
      );
    }
  }

  public calculateHeatMapColor(scoreValue: number, insideGoal?: boolean): any {
    if (this.position && !insideGoal) {
      return '#fff';
    }
    if (scoreValue >= 90) {
      return '#e64115'; // Red
    } else if (scoreValue >= 70) {
      return '#FF8C00'; // Orange
    } else if (scoreValue >= 40) {
      return '#ecb22e'; // Yellow
    } else if (scoreValue > 0) {
      return '#41946e'; // Green
    }
    if (insideGoal) {
      return '#0d385f';
    }
    return '#1e78ff';
  }

  public counterSelectClick(type: 'GOAL'|'SHOT'='GOAL', shotLocation?: ShotLocation) {
    const counterSelect: CounterSelectModel = {event: type=='GOAL' ? ['GOAL']:['GOAL', 'SAVE', 'POST_OUT']};
    if (this.shotLocationSelected) {
      counterSelect.executionPosition = this.shotLocationSelected.split(':')[0].split('_G_')[1] as ExecutionPositionTypes;
    }
    if (shotLocation) {
      counterSelect.shotLocation = shotLocation;
      if (['0', '10', '11'].includes(counterSelect.shotLocation)) {
        counterSelect.event = ['GOAL', 'SAVE', 'POST_OUT'];
      }
    }
    this.counterSelect.emit(counterSelect);
    if (this.targetSelection) this.playVideo(counterSelect);
  }

  public playVideo(counterSelect: CounterSelectModel) {
    VideoModalComponent.OnSearch(
      this.core,
      this.targetSelection,
      counterSelect.event,
      this.targetSelection.playerPosition=='gk' ? true : false, // Failed shot (other team save)
      false, // TotalAssist (false)
      `${counterSelect.event.includes('GOAL')?'Goals':'Failed shoots'} ${counterSelect.executionPosition?'from '+counterSelect.executionPosition.toLowerCase().replace('_', ' ')+' ':''}${counterSelect.shotLocation?'to '+counterSelect.shotLocation:''}`,
      {
        executionPosition: [counterSelect.executionPosition],
        shotLocation: [counterSelect.shotLocation],
      }
    );
  }
}
