import { Component, Input, OnInit } from '@angular/core';
import { AccumulatedCounterBasedOnPointInTimeList, FirstHalfGoals, SimplifiedPlayerStats } from '@handballai/stats-calculation';
import { ChartPoint } from 'chart.js';
import { NGXLogger } from 'ngx-logger';
import {
  combineAndSortGoalDataHelper,
  generateGoalByGoalDataColumnsHelper,
  generateGoalByGoalDataRowsHelper,
  goalKeeperTableColumnsHelper, goalKeeperTableColumnsWithETHelper,
  goalKeeperTableLegendMapHelper, goalKeeperTableMobileColumnsHelper,
  GoalTimeLineCombined,
  playerTableColumnsHelper, playerTableColumnsWithETHelper, playerTableLegendDisplayMapHelper,
  playerTableLegendMapHelper, playerTableMobileColumnsHelper,
  teamGoalByGoalColumnsHelper
} from '../../pages/aehandler-module/pages/game-module/components/pdf-report/pdf-report-quickstats-icons-table.helper';
import { BehaviorSubject } from 'rxjs';
import { CoreService } from '../../../shared-services/core.service';

@Component({
  selector: 'app-quick-stats-team',
  templateUrl: './quick-stats-team.component.html',
  styleUrls: ['./quick-stats-team.component.scss']
})
export class QuickStatsTeamComponent implements OnInit {
  private _simplifiedPlayerModels: SimplifiedPlayerStats[] | undefined = undefined;
  private _accumulatedCounterListWithTiming: AccumulatedCounterBasedOnPointInTimeList[] = [];
  private _homeGoalGraph: ChartPoint[] = [];
  private _visitorGoalGraph: ChartPoint[] = [];
  private _goalByGoal: GoalTimeLineCombined[] = [];
  private _homeTeamLabel: string;
  private _awayTeamLabel: string;
  public playerPerformanceTableAllColsShown = false;

  private _playerTableColumns = playerTableColumnsHelper;

  private _goalKeeperTableColumns = goalKeeperTableColumnsHelper;
  
  private _playerTableLegendMap = playerTableLegendMapHelper;
  
  private _goalKeeperTableLegendMap = goalKeeperTableLegendMapHelper;
  
  private _teamGoalByGoalColumns = teamGoalByGoalColumnsHelper;
  
  private _playerTableForLegendDisplay = playerTableLegendDisplayMapHelper;
  
  private _teamGoalByGoalRows = [
    [],
    []
  ];

  public screenWidth$ = new BehaviorSubject<number>(0);
  
  constructor(
    private readonly logger: NGXLogger,
    public core: CoreService
  ) {
    //
  }

  ngOnInit(): void {
    if (this.core.isMobileDevice) {
      this._playerTableColumns = playerTableMobileColumnsHelper;
      this._goalKeeperTableColumns = goalKeeperTableMobileColumnsHelper;
    }
  }

  public onExpandPlayerPerformanceTableColumnsClick() {
    this.playerPerformanceTableAllColsShown = !this.playerPerformanceTableAllColsShown;
    if (this.playerPerformanceTableAllColsShown) {
      this._playerTableColumns = playerTableColumnsHelper;
      this._goalKeeperTableColumns = goalKeeperTableColumnsHelper;
    } else {
      this._playerTableColumns = playerTableMobileColumnsHelper;
      this._goalKeeperTableColumns = goalKeeperTableMobileColumnsHelper;
    }
  }

  get simplifiedFieldPlayerModels() {
    return this._simplifiedPlayerModels.filter(model => model.playerPosition !== 'gk');
  }
  get simplifiedGoalkeeperPlayerModels() {
    return this._simplifiedPlayerModels
        .filter(model => model.playerPosition === 'gk');
  }
  get playerTableColumns() {
    return this._playerTableColumns;
  }
  get teamGoalByGoalColumns() {
    return generateGoalByGoalDataColumnsHelper();
  }
  get teamGoalByGoalRows() {
    return generateGoalByGoalDataRowsHelper(this._homeTeamLabel, this._awayTeamLabel, this._goalByGoal);
  }
  get playerTableLegendMap() {
    return this._playerTableLegendMap;
  }
  get playerTableForLegendDisplay() {
    return this._playerTableForLegendDisplay;
  }

  get goalKeeperTableColumns() {
    return this._goalKeeperTableColumns;
  }

  get goalKeeperTableLegendMap() {
    return this._goalKeeperTableLegendMap;
  }

  @Input()
  set screenWidth(value: number) {
    this.screenWidth$.next(value);
  }

  @Input()
  set simplifiedPlayerModels(value: SimplifiedPlayerStats[]) {
    if (value) {
      this.logger.debug('QuickStatsTeamComponent.SimplifiedPlayerStats', value);
      this._simplifiedPlayerModels = value;
      this.checkIfThereAreExtraTimeOrPenaltiesEvents();
    }
  }

  @Input()
  set accumulatedCounterListWithTiming(value: AccumulatedCounterBasedOnPointInTimeList[]) {
    this.logger.debug('QuickStatsTeamComponent.accumulatedCounterListWithTiming', value);
    if (value && value.length > 0) {
      this._accumulatedCounterListWithTiming = value;
      this._homeGoalGraph = this._accumulatedCounterListWithTiming[0].map(goalTimePoint => ({
        x: goalTimePoint.minuteOfGame,
        y: goalTimePoint.accumulatedCounter
      }));
      this._visitorGoalGraph = this._accumulatedCounterListWithTiming[1].map(goalTimePoint => ({
        x: goalTimePoint.minuteOfGame,
        y: goalTimePoint.accumulatedCounter
      }));
    }
    this._goalByGoal = combineAndSortGoalDataHelper(this._homeGoalGraph, this._visitorGoalGraph);
  }

  @Input()
  set teamLabels(value: FirstHalfGoals[]) {
    this.logger.debug('QuickStatsTeamComponent.teamLabels', value);
    if (value) {
      if (value.length > 0) {
        this._homeTeamLabel = `${value[0].teamName}`;
        this._awayTeamLabel = `${value[1].teamName}`;
      }
    }
  }

  private checkIfThereAreExtraTimeOrPenaltiesEvents() {
    let hasAnyEventsInETorPenalties = false;
    this._simplifiedPlayerModels.forEach(stats => {
      for (const [key, value] of Object.entries(stats.extraTime1)) {
        if (value > 0) {
          hasAnyEventsInETorPenalties = true;
        }
      }
      for (const [key, value] of Object.entries(stats.extraTime2)) {
        if (value > 0) {
          hasAnyEventsInETorPenalties = true;
        }
      }
      for (const [key, value] of Object.entries(stats.penalties)) {
        if (value > 0) {
          hasAnyEventsInETorPenalties = true;
        }
      }
    });
    if (hasAnyEventsInETorPenalties) {
      this._playerTableColumns = playerTableColumnsWithETHelper;
      this._goalKeeperTableColumns = goalKeeperTableColumnsWithETHelper;
    } else {
      this._playerTableColumns = playerTableColumnsHelper;
      this._goalKeeperTableColumns = goalKeeperTableColumnsHelper;
    }
  }

  public isNumber(value): boolean {
    return typeof value === 'number';
  }

  public getRowClass = (row: any) => {
    return { 'same-coloring': true };
  }

  public getCellClass = ({ row, column, value }) => {
    return { 'is-goal': value > 0, 'no-goal': !this.isNumber(value) };
  }

  public getCellClassPlayerTable = ({ row, column, value }) => {
    return {
      'ngx-data-playerperformance-table': true,
      'ngx-data-playerperformance-table-border1': column.prop === "extra1Bucket",
      'ngx-data-playerperformance-table-border2': column.prop === "extra2Bucket",
      'ngx-data-playerperformance-table-dotted': column.prop.includes("Bucket") && !column.prop.includes("extra")
    };
  }
}
