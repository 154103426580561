import { TeamModel } from '../model/team.model';

export interface ValidationRule {
    fireRule(teamModel: TeamModel): string[];
}

export const applyRules = (
    teamModel: TeamModel,
    validationRules: ValidationRule[]
): string[] =>
    validationRules.reduce((accu, curr) =>
        [...accu, ...curr.fireRule(teamModel)], [] as string[]);
