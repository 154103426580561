/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GameSystemDto { 
    /**
     * player id
     */
    id?: number;
    /**
     * Name of the action on pbp
     */
    actionName: string;
    /**
     * Name of the Action on the UI
     */
    actionDisplayName: string;
    /**
     * could be applied to complete mode
     */
    applyToCompleteMode: boolean;
    /**
     * could be applied to lite mode
     */
    applyToLiteMode: boolean;
}

