<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{title}}
    </ion-title>
    <ion-button slot="end" (click)="onUploadFileClick()" fill="clear">
      <ion-icon class="upload-txt" style="font-size: 1.5rem; margin-left: 20px; margin-right: 10px; cursor: pointer;" name="cloud-upload-outline"></ion-icon>
      <ion-label class="upload-txt" >{{ 'Share to the folder' | translate }}</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng2-pdfjs-viewer [pdfSrc]="pdfurl" [viewBookmark]="false" [openFile]="false"></ng2-pdfjs-viewer>
</ion-content>
