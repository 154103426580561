export const allGames = `<svg width="359" height="283" viewBox="0 0 359 283" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_0_1)">
<rect x="20" y="19" width="319" height="243" rx="30" fill="white"/>
</g>
<path d="M60.9046 54.5752C61.1513 54.3543 61.4811 54.2244 61.8415 54.2386C62.5275 54.2658 63.0858 54.8251 63.1117 55.5111C63.1403 56.2662 62.5366 56.8874 61.7878 56.8874C61.4484 56.8874 61.139 56.7596 60.9045 56.5497V59.0955H63.4503C63.2294 59.3422 63.0995 59.672 63.1137 60.0324C63.1409 60.7184 63.7002 61.2767 64.3862 61.3026C65.1413 61.3312 65.7624 60.7275 65.7624 59.9787C65.7624 59.6393 65.6346 59.3299 65.4248 59.0954H68V52.8833C68 52.3954 67.6045 52 67.1168 52H60.9046V54.5752Z" fill="#0D385F"/>
<path d="M59.3045 63.8415C59.2903 63.4811 59.4202 63.1513 59.6411 62.9045H57.0953V65.4503C56.8486 65.2294 56.5188 65.0995 56.1584 65.1137C55.4724 65.1409 54.9141 65.7002 54.8882 66.3862C54.8596 67.1413 55.4633 67.7624 56.2121 67.7624C56.5515 67.7624 56.8609 67.6346 57.0954 67.4248V70H63.3075C63.7953 70 64.1908 69.6045 64.1908 69.1167V62.9045H61.6156C61.8255 63.139 61.9532 63.4484 61.9532 63.7878C61.9532 64.5366 61.3321 65.1402 60.577 65.1117C59.8909 65.0858 59.3316 64.5275 59.3045 63.8415V63.8415Z" fill="#0D385F"/>
<path d="M57.9787 58.0467C57.6393 58.0467 57.3298 58.1745 57.0954 58.3843V55.8091H50.8833C50.3954 55.8091 50 56.2046 50 56.6924V61.8499H51.189C51.275 60.6517 52.2633 59.6891 53.4712 59.6435C53.5018 59.6423 53.5321 59.6418 53.5625 59.6418C54.8169 59.6418 55.8477 60.6175 55.9359 61.8499H57.0954V60.3588C57.3422 60.5797 57.672 60.7096 58.0324 60.6954C58.7183 60.6682 59.2767 60.1089 59.3026 59.4229C59.3311 58.6678 58.7274 58.0467 57.9787 58.0467V58.0467Z" fill="#0D385F"/>
<path d="M54.498 68.0879C54.0453 67.6179 53.8096 66.9993 53.8344 66.3463C53.88 65.1385 54.8426 64.1502 56.0408 64.0641V62.9046H54.5497C54.7595 62.6701 54.8873 62.3607 54.8873 62.0213C54.8873 61.2725 54.2662 60.6689 53.5111 60.6974C52.8251 60.7233 52.2658 61.2816 52.2386 61.9676C52.2244 62.328 52.3543 62.6578 52.5752 62.9045H50V69.1167C50 69.6046 50.3954 70 50.8833 70H56.0408V68.8108C55.459 68.7682 54.9044 68.51 54.498 68.0879V68.0879Z" fill="#0D385F"/>
<defs>
<filter id="filter0_d_0_1" x="0" y="0" width="359" height="283" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="10"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.376471 0 0 0 0 0.423529 0 0 0 0 0.501961 0 0 0 0.08 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
</filter>
</defs>
</svg>

`;
