import { formatNumber } from "@angular/common";
import { TeamModel } from "@handballai/stats-calculation";
import { Canvas, Cell, ICustomTableLayout, Img, IStyleDefinition, Line, PdfMakeWrapper, Rect, SVG, Table, Txt } from "pdfmake-wrapper";
import { PdfViewerPage } from "src/app/main/components/pdf-viewer/pdf-viewer";
import { pdfColors } from "../../../game-module/components/pdf-report/pdf-colors";
import { RectConfig, toUpperCase } from "../../../game-module/components/pdf-report/pdf-report.component";
import { handballField, logoWithTextSVG, svgTextLogo } from "../../../game-module/components/pdf-report/pdf-svg-in-string-format";
import { addEmptyLines, addHorizontalLine, addPageBreak, calculatePercentageItem } from "../individual-performance/individual-pdf.helper";
import { decimalToFixed, getGameSystemColor, globalDataDate, teamLogoResolver } from "../../../game-module/components/pdf-report/pdf-report-helpers";
import { CoreService } from "src/app/shared-services/core.service";

const PDF_URL = 'www.handball.ai'
const toFixedNum = (n: number, deceimals: number) => n?.toFixed(deceimals)?.replace(/\.?0*$/, '');
const pdfCreationDate = globalDataDate(new Date().toDateString());

let selectedTeamInfo
let translateServices
let paceAndPhaseTablesArray
let selectedGames
let goalkeeperReport;

export function createTeamPdf(printOptions, loadingCtrl, translateService, modalCtrl, selectedTeamId, selectedTeamData: TeamModel, core: CoreService) {
  paceAndPhaseTablesArray = []
  selectedGames = []

  selectedGames = printOptions['selectedGames'];
  translateServices = translateService;
  selectedTeamInfo = selectedTeamData;

  goalkeeperReport = printOptions['goalkeeperReport'];

  // TODO logg delete after presentation
  console.log('%c printOptions gameSystemTbl', 'color: yellow',  printOptions['gameSystemTbl']);

  loadingCtrl.create({ message: core.trans.instant('Generating PDF. Please wait') }).then(async loading => {
    loading.present();
    const pdf = new PdfMakeWrapper();
    const selectedTeam = printOptions["teams"].find(team => team.id === selectedTeamId).name;

    const accTeamShooting = document.querySelectorAll("#acc-teamshooting svg");
    const accTeamShootingGk = document.querySelectorAll("#acc-teamshooting-gk svg");
    const teamConnGoals = document.querySelectorAll("#team-connGoals svg");

    const teamDoughnut1_PDF: HTMLCanvasElement = document.querySelector("#team-doughnut1_pdf canvas");
    const teamDoughnut2_PDF: HTMLCanvasElement = document.querySelector("#team-doughnut2_pdf canvas");

    const teamEventsTbl3 = printOptions["teamEventsTbl3"];
    const teamEventsTbl4 = printOptions["teamEventsTbl4"];
    const paceAndPhaseColumns = printOptions["paceAndPhaseColumns"];
    const paceAndPhase = printOptions["paceAndPhase"];
    const playersTbl = printOptions["playersTbl"];
    const quickStats = printOptions["quickStats"];
    const gameSystemTbl = printOptions["gameSystemTbl"];

    const alternatingColorTableLayout: ICustomTableLayout = {
      fillColor: function (rowIndex) {
        return (rowIndex % 2 === 0 && rowIndex != 0) ? '#f0f0f0' : 'white';
      },
      hLineWidth: function (rowIndex) {
        return (rowIndex === 1) ? 1 : 0;
      },
      hLineColor: function (rowIndex) {
        return (rowIndex === 1) ? '#f0f0f0' : 'white';
      },
      vLineWidth: function (rowIndex) {
        return 0;
      },
    };

    const quickStatsLines = [1, 7, 13, 19, 25];
    const quickStatsTableLayout: ICustomTableLayout = {
      hLineWidth: function (rowIndex) {
        return (quickStatsLines.includes(rowIndex)) ? 1 : 0;
      },
      hLineColor: function (rowIndex) {
        return (quickStatsLines.includes(rowIndex)) ? '#0d385f' : 'white';
      },
      vLineWidth: function (rowIndex) {
        return 0;
      },
    };

    const layoutTableBodyGrid: ICustomTableLayout = {
      hLineWidth: (i: any, node: any) => {
        if (i === 0 || i === node.table.body.length) {
          return 0;
        }
        return 2;
      },
      vLineWidth: (i: any, node: any) => {
        return (i === 0 || i === node.table.widths.length) ? 0 : 2;
      },
      hLineColor: function (i: any, node: any) {
        // return (i === 0 || i === node.table.body.length) ? 'red' : 'blue';
        return (i === 0 || i === node.table.body.length) ? 'red' : '#F1F5F8';
      },
      vLineColor: function (i: any, node: any) {
        // return (i === 0 || i === node.table.body.length) ? 'red' : 'blue';
        return (i === 0 || i === node.table.widths.length) ? 'red' : '#F1F5F8';
      },
      paddingTop: function (i: any, node: any) {
        return 1
      },
      paddingBottom: function (i: any, node: any) {
        return 1;
      }
    };


    const layoutTableBodyGridTest2: ICustomTableLayout = {
      hLineWidth: (i: any, node: any) => {
        if (i === 0 || i === node.table.body.length) {
          return 0;
        }
        return 2;
      },
      vLineWidth: (i: any, node: any) => {
        return (i === 0 || i === node.table.widths.length) ? 0 : 2;
      },
      hLineColor: function (i: any, node: any) {
        return (i === 0 || i === node.table.body.length) ? 'red' : '#FFF';

      },
      vLineColor: function (i: any, node: any) {
        return (i === 0 || i === node.table.widths.length) ? 'red' : '#FFF';
      }
    };


    const layoutTableIndividualA: ICustomTableLayout = {
      hLineWidth: (rowIndex?: number, node?: any, columnIndex?: number) => {
        if (
          node.table.body[rowIndex - 1]
          &&
          [
            'Assists',
            // 'Posession Events'
            'Posessions'
          ].includes(node.table.body[rowIndex - 1][0]['text'])
        ) {
          return 2
        }

        if (
          node.table.body[rowIndex]
          &&
          [
            'Positional Offense %',
            'Superiority %'
          ].includes(node.table.body[rowIndex][0]['text'])
        ) {
          return 2
        }
        return 0;
      },
      vLineWidth: (i: any, node: any) => {
        return (i === 0 || i === node.table.widths.length) ? 0 : 2;
      },
      hLineColor: function (i: any, node: any) {
        return (i === 0 || i === node.table.body.length) ? 'red' : '#F1F5F8';
      },
      vLineColor: function (i: any, node: any) {
        return (i === 0 || i === node.table.widths.length) ? 'red' : '#F1F5F8';
      },

      paddingTop: function (i: any, node: any) {
        return 1
      },
      paddingBottom: function (i: any, node: any) {
        return 1;
      }
    }

    pdf.styles({
      firstPageTeamCell: {
        fontSize: 20,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      firstPageGlobalDate: {
        fontSize: 17,
        color: '#1A375C',
        alignment: 'center',
        bold: true,
      },

      headerTeamCell: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      headerTeamCellSmall: {
        fontSize: 7
      },

      header__cell__individual_a: {
        fontSize: 9,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      table__cell__individual_a: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },

      header__cell__individual_b: {
        fontSize: 9,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      table__cell__individual_b: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },

      header__cell__individual_d: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      table__cell__individual_d: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },

      header__cell__individual_e: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      table__cell__individual_e: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },

      header__cell__individual_f: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      table__cell__individual_f: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },

      team_table_info: {
        fontSize: 11,
        color: '#1A375C',
        alignment: 'center',
        bold: true,
      },
      goals_failed_shorts_chart: {
        fontSize: 11,
        color: '#1A375C',
        alignment: 'center',
        bold: true,

      },
      goals_per_phase_chart: {
        fontSize: 11,
        color: '#1A375C',
        alignment: 'center',
        bold: true,
      },
      goals_failed_shorts_chart_legend: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'center',
        bold: true,
      }
    });

    let teamTbl3TableBody = [];
    teamEventsTbl3.forEach(element => {
      teamTbl3TableBody.push([
        new Txt(element.template).style("alternatingColorTableContentStyle").end,
        new Txt(element.total).style("alternatingColorTableContentStyle").end,
        new Txt(element.average).style("alternatingColorTableContentStyle").end
      ])
    });
    const teamTbl3Table = new Table(teamTbl3TableBody).headerRows(1).layout(alternatingColorTableLayout);


    let teamTbl4TableBody = [];
    teamEventsTbl4.forEach(element => {
      teamTbl4TableBody.push([
        new Txt(element.template).style("alternatingColorTableContentStyle").end,
        new Txt(element.total).style("alternatingColorTableContentStyle").end,
        new Txt(element.average).style("alternatingColorTableContentStyle").end
      ])
    });
    const teamTbl4Table = new Table(teamTbl4TableBody).headerRows(1).layout(alternatingColorTableLayout);


    if (paceAndPhaseColumns.length > 0 && paceAndPhase) {
      let paceAndPhaseHeders = []
      let paceAndPhaseHedersTemp = []
      let paceAndPhaseBody = []
      let paceAndPhaseBodyTemp = []
      let paceAndPhaseArr = [];

      const paceAndPhaseDefaultObj = (obj, key, val) => {
        obj[key] = val
        return obj
      }

      paceAndPhase.forEach((phase, idx) => {
        let paceAndPhaseObj = paceAndPhaseDefaultObj({}, 'template', phase['template'])
        paceAndPhaseArr.push(paceAndPhaseObj)
      });

      let n = 0
      let paceAndPhaseMap = new Map();

      let paceAndPhaseArrTemp = [...JSON.parse(JSON.stringify(paceAndPhaseArr))]
      for (let i = 0; i <= paceAndPhaseColumns.length; i++) {
        paceAndPhaseHedersTemp.push(paceAndPhaseColumns[i])

        if (paceAndPhaseColumns[i]) {
          const prop = paceAndPhaseColumns[i]["prop"];

          paceAndPhaseArrTemp.forEach((e, idx) => {
            e[prop] = paceAndPhase[idx][prop]
          })

          if ((paceAndPhaseHedersTemp.length % 10 === 0 ) ||  (paceAndPhaseColumns.length === i+1) ) {
            // prepare headers
            paceAndPhaseHeders.push(paceAndPhaseHedersTemp)
            // propare bodies
            paceAndPhaseBody.push(paceAndPhaseArrTemp)

            // paceAndPhaseMap.set(n, {
            //   headers: paceAndPhaseHedersTemp,
            //   body: paceAndPhaseArrTemp
            // })
            paceAndPhaseTablesArray.push({
              headers: paceAndPhaseHedersTemp,
              body: paceAndPhaseArrTemp
            })

            // clear arrays/ clear refs
            paceAndPhaseHedersTemp = []
            paceAndPhaseHedersTemp.push(paceAndPhaseColumns[0])
            paceAndPhaseArrTemp = []
            paceAndPhaseArrTemp = [...JSON.parse(JSON.stringify(paceAndPhaseArr))]

            n++;
          }
        }
      }
    }

    const quickStatsTableBody = [];
    if (quickStats.totalQuickStats.totalPlayedTime) {
      quickStatsTableBody.push([
        new Txt(translateService.instant("TIME")).style("alternatingColorTableHeaderStyle").end,
        new Txt(quickStats.totalQuickStats.totalPlayedTime).style("alternatingColorTableContentStyle").end,
        new Txt(quickStats.avgQuickStats.totalPlayedTime).style("alternatingColorTableContentStyle").end
      ]);
    }
    if (quickStats.totalQuickStats.totalPossessions) {
      quickStatsTableBody.push([
        new Txt(translateService.instant("POSESSIONS")).style("alternatingColorTableHeaderStyle").end,
        new Txt(toFixedNum(quickStats.totalQuickStats.totalPossessions, 2)).style("alternatingColorTableContentStyle").end,
        new Txt(toFixedNum(quickStats.avgQuickStats.totalPossessions, 2)).style("alternatingColorTableContentStyle").end
      ]);
    }

    // temporary comment POSESSION EVENTS due to the fact we do not have them in object as property
    // quickStatsTableBody.push([
    //   new Txt(translateService.instant("POSESSION EVENTS")).style("alternatingColorTableHeaderStyle").end,
    //   new Txt(toFixedNum(quickStats.totalQuickStats.totalPossessionEvents, 2)).style("alternatingColorTableContentStyle").end,
    //   new Txt(toFixedNum(quickStats.avgQuickStats.totalPossessionEvents, 2)).style("alternatingColorTableContentStyle").end
    // ]);

    //line
    quickStatsTableBody.push([
      new Txt(translateService.instant("GOALS")).style("alternatingColorTableHeaderStyle").end,
      new Txt(toFixedNum(quickStats.totalQuickStats.totalGoals, 2)).style("alternatingColorTableContentStyle").end,
      new Txt(toFixedNum(quickStats.avgQuickStats.totalGoals, 2)).style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt(translateService.instant("FAILED SHOTS")).style("alternatingColorTableHeaderStyle").end,
      new Txt(toFixedNum(quickStats.totalQuickStats.totalFailedShots, 2)).style("alternatingColorTableContentStyle").end,
      new Txt(toFixedNum(quickStats.avgQuickStats.totalFailedShots, 2)).style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt(translateService.instant("POSTS/OUT")).style("alternatingColorTableHeaderStyle").end,
      new Txt(toFixedNum(quickStats.totalQuickStats.totalPostOuts, 2)).style("alternatingColorTableContentStyle").end,
      new Txt(toFixedNum(quickStats.avgQuickStats.totalPostOuts, 2)).style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt(translateService.instant("ASSISTS")).style("alternatingColorTableHeaderStyle").end,
      new Txt(toFixedNum(quickStats.totalQuickStats.totalAssist, 2)).style("alternatingColorTableContentStyle").end,
      new Txt(toFixedNum(quickStats.avgQuickStats.totalAssist, 2)).style("alternatingColorTableContentStyle").end
    ]);
    //line
    quickStatsTableBody.push([
      new Txt(translateService.instant("EFF %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.totalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.totalQuickStats.totalPossessions, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.totalGoals, quickStats.totalQuickStats.totalPossessions) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.totalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.totalPossessions, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.totalGoals, quickStats.avgQuickStats.totalPossessions) + " %)").style("alternatingColorTableContentStyle").end
    ]);

    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.totalGoals / quickStats.totalQuickStats.totalPossessionEvents) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.totalGoals / quickStats.avgQuickStats.totalPossessionEvents) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("SHOOTING EFF %'")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.totalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber((quickStats.totalQuickStats.totalFailedShots + quickStats.totalQuickStats.totalGoals), "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.totalGoals, (quickStats.totalQuickStats.totalFailedShots + quickStats.totalQuickStats.totalGoals)) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.totalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber((quickStats.avgQuickStats.totalFailedShots + quickStats.avgQuickStats.totalGoals), "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.totalGoals, (quickStats.avgQuickStats.totalFailedShots + quickStats.avgQuickStats.totalGoals)) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.totalGoals / (quickStats.totalQuickStats.totalFailedShots + quickStats.totalQuickStats.totalGoals)) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.totalGoals / (quickStats.avgQuickStats.totalFailedShots + quickStats.avgQuickStats.totalGoals)) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("LOST BALLS %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.totalLostBalls, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.totalQuickStats.totalPossessions, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.totalLostBalls, quickStats.totalQuickStats.totalPossessions) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.totalLostBalls, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.totalPossessions, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.totalLostBalls, quickStats.avgQuickStats.totalPossessions) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.totalLostBalls / quickStats.totalQuickStats.totalPossessionEvents) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.totalLostBalls / quickStats.avgQuickStats.totalPossessionEvents) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    //line
    quickStatsTableBody.push([
      new Txt(translateService.instant("POSITIONAL OFFENSE %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.offensePositionalGoals, "en-US", "1.0-1")
        + " / " +
          formatNumber(quickStats.totalQuickStats.offensePositionalEffectivePossession, "en-US", "1.0-1")
          + " (" + formatNumber(quickStats.totalQuickStats.offensePositionalPossession, "en-US", "1.0-1") + ") "
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.offensePositionalGoals, quickStats.totalQuickStats.offensePositionalEffectivePossession) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.offensePositionalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.offensePositionalEffectivePossession, "en-US", "1.0-1")
        + " (" + formatNumber(quickStats.avgQuickStats.offensePositionalPossession, "en-US", "1.0-1") + ") "
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.offensePositionalGoals, quickStats.avgQuickStats.offensePositionalEffectivePossession) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.offensePositionalGoals / quickStats.totalQuickStats.offensePositionalPossession) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.offensePositionalGoals / quickStats.avgQuickStats.offensePositionalPossession) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("FASTBREAK %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.fastBreakGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.totalQuickStats.fastBreakEffectivePossession, "en-US", "1.0-1")
        + " (" + formatNumber(quickStats.totalQuickStats.fastBreakPossession, "en-US", "1.0-1") + ") "
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.fastBreakGoals, quickStats.totalQuickStats.fastBreakEffectivePossession) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.fastBreakGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.fastBreakEffectivePossession, "en-US", "1.0-1")
        + " (" + formatNumber(quickStats.avgQuickStats.fastBreakPossession, "en-US", "1.0-1") + ") "
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.fastBreakGoals, quickStats.avgQuickStats.fastBreakEffectivePossession) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.fastBreakGoals / quickStats.totalQuickStats.fastBreakPossession) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.fastBreakGoals / quickStats.avgQuickStats.fastBreakPossession) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("COUNTER GOAL %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.counterGoalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.totalQuickStats.counterGoalEffectivePossession, "en-US", "1.0-1")
          + " (" + formatNumber(quickStats.totalQuickStats.counterGoalPossession, "en-US", "1.0-1") + ") "
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.counterGoalGoals, quickStats.totalQuickStats.counterGoalEffectivePossession) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.counterGoalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.counterGoalEffectivePossession, "en-US", "1.0-1")
        + " (" + formatNumber(quickStats.avgQuickStats.counterGoalPossession, "en-US", "1.0-1") + ") "
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.counterGoalGoals, quickStats.avgQuickStats.counterGoalEffectivePossession) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.counterGoalGoals / quickStats.totalQuickStats.counterGoalPossession) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.counterGoalGoals / quickStats.avgQuickStats.counterGoalPossession) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    //line
    quickStatsTableBody.push([
      new Txt(translateService.instant("SUPERIORITY %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.superiorityGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.totalQuickStats.superiorityPossessions, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.superiorityGoals, quickStats.totalQuickStats.superiorityPossessions) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.superiorityGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.superiorityPossessions, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.superiorityGoals, quickStats.avgQuickStats.superiorityPossessions) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.superiorityGoals / quickStats.totalQuickStats.superiorityPossessions) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.superiorityGoals / quickStats.avgQuickStats.superiorityPossessions) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("EQUALITY %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.equalityGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.totalQuickStats.equalityPossessions, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.equalityGoals, quickStats.totalQuickStats.equalityPossessions) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.equalityGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.equalityPossessions, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.equalityGoals, quickStats.avgQuickStats.equalityPossessions) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.equalityGoals / quickStats.totalQuickStats.equalityPossessions) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.equalityGoals / quickStats.avgQuickStats.equalityPossessions) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("7 METERS GOALS %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.total7MetersGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber((quickStats.total7MetersGoals + quickStats.total7MetersFailedShoots), "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.total7MetersGoals, (quickStats.total7MetersGoals + quickStats.total7MetersFailedShoots)) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avg7MetersGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber((quickStats.avg7MetersGoals + quickStats.avg7MetersFailedShoots), "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avg7MetersGoals, (quickStats.avg7MetersGoals + quickStats.avg7MetersFailedShoots)) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.total7MetersGoals / (quickStats.total7MetersGoals + quickStats.total7MetersFailedShoots)) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avg7MetersGoals / (quickStats.avg7MetersGoals + quickStats.avg7MetersFailedShoots)) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    pdf.pageSize('A4');

    pdf.background(function (i: any, node: any) {
      if (i === 1) {
        return {
          canvas: [
            {
              type: 'rect',
              x: 0, y: 0, w: 841.89, h: 595.28,
              color: pdfColors.bgRectTableWhite
            }
          ]
        }
      }

      return {
        canvas: [
          {
            type: 'rect',
            x: 0, y: 0, w: 841.89, h: 595.28,
            color: pdfColors.bgBaseLightGrey
          }
        ]
      }
    });

    pdf.pageOrientation('landscape');
    pdf.pageMargins([10, 10, 10, 10]);

    pdf.info({
      title: 'PDF Report',
      author: 'Handball.AI',
      subject: 'Game pdf report',
    });

    // page 1
    // Hanball Field
    pdf.add(
      new SVG(`${handballField}`).alignment('right').width(300).end
    );

    // if svg logo will throw error use png instead
    // this.pdf.add(await new Img('/assets/images/pdfReportImgLogoHandball.png').width(250).alignment('center').build());
    pdf.add(
      // await new Img('/assets/images/pdfReportImgLogoHandball.png').width(250).alignment('center').build()
      new SVG(`${svgTextLogo}`).width('270')
        .alignment('center')
        .absolutePosition(0, 250)
        .end
    );
    addEmptyLines(pdf, 2);

    pdf.add(
      new Table([
        [
          new Cell(
            new Txt('').end
          ).end,

          new Cell(
            await teamLogoResolver(selectedTeamData.shieldUrl, 'center', 100)
          ).end,

          new Cell(
            new Table([
              [
                new Txt(`Consolidated report of: ${selectedGames.length} Games\n`).end
              ],
              [
                new Txt(`${selectedTeam}`).end
              ],
              [
                new Txt(`${selectedTeamData.country.toUpperCase()}, ${toUpperCase(selectedTeamData.division)}, ${toUpperCase(selectedTeamData.gender)}`).end
              ]
            ])
              .style('first_page__general_info_cell')
              .heights((row: number) => 20)
              .relativePosition(0, 10)
              .layout({ defaultBorder: false })
              .end)
            .end,

          new Cell(
            new Txt('').end
          ).end
        ]
      ]).layout({ defaultBorder: false }).widths(['30%', '15%', '35%', '20%']).end);

    addEmptyLines(pdf, 7);

    pdf.add(new Txt(pdfCreationDate).alignment('center').style('firstPageGlobalDate').end)

    addPageBreak(pdf);
    // End of page #1

    // page 2
    await addHeaderPage(pdf, toUpperCase('OVERVIEW'), selectedTeam);


    const createImageCellsWithPage = (connections, page) => {
      const startIndex = page * 8;
      const endIndex = startIndex + 8;
      const cells = [];

      for (let i = startIndex; i < endIndex && i < connections.length; i += 2) {
        const row = [];

        if (i < connections.length) {
          row.push(connections[i] ? new SVG(connections[i].outerHTML).width(200).end : new Txt('').end);
        }
        if (i + 1 < connections.length) {
          row.push(connections[i + 1] ? new SVG(connections[i + 1].outerHTML).width(200).end : new Txt('').end);
        }

        cells.push(row);
      }

      return cells;
    };

    if (goalkeeperReport) {
      if (goalkeeperReport) {
        let images = createImageCellsWithPage(accTeamShootingGk, 0);

        pdf.add(
            new Table([
              createImageCellsWithPage(accTeamShootingGk, 0)
            ])
                .layout('noBorders')
                .widths('*')
                .margin(images.length < 3 ? [140, 80, 0, 0] : [0, 80, 0, 0])
                .end
        );

        if (accTeamShootingGk.length > 8) {
          images = createImageCellsWithPage(accTeamShootingGk, 1);
          addPageBreak(pdf);
          // End of page #2

          // page 3
          await addHeaderPage(pdf, toUpperCase('OVERVIEW'), selectedTeam);


          pdf.add(
              new Table([
                createImageCellsWithPage(accTeamShootingGk, 1)
              ])
                  .layout('noBorders')
                  .widths('*')
                  .margin(images.length < 3 ? [140, 80, 0, 0] : [0, 80, 0, 0])
                  .end
          );
        }

        if (accTeamShootingGk.length > 16) {
          images = createImageCellsWithPage(accTeamShootingGk, 2);
          addPageBreak(pdf);
          // End of page #3

          // page 4
          await addHeaderPage(pdf, toUpperCase('OVERVIEW'), selectedTeam);

          pdf.add(
              new Table([
                createImageCellsWithPage(accTeamShootingGk, 2)
              ])
                  .layout('noBorders')
                  .widths('*')
                  .margin(images.length < 3 ? [140, 80, 0, 0] : [0, 80, 0, 0])
                  .end
          );
        }

        pdf.create().getBase64(b64 => {
          modalCtrl.create({
            component: PdfViewerPage,
            componentProps: { data: b64, fileName: selectedTeam },
            cssClass: 'modal-pagescreen',
            showBackdrop: false
          }).then(t => t.present());
          loading.dismiss();
        });
      }
      return;
    }

    pdf.add(new Txt(toUpperCase(translateService.instant('ACCUMULATED TEAM SHOOTING (All the games)'))).style('team_table_info').margin([0, 5, 15, 5]).alignment('right').end);

    {
      quickStatsTableBody.forEach(row => {
        row.forEach((cell, idx) => {
          if (idx === 0) {
            cell['text'] = cell['text'].indexOf('+') >= 0 ? toUpperCase([...cell['text']].map(c => {
              if (c === '+') {
                return ''
              } else if (c === '/') {
                return ' / '
              }
              return c
            }).join(''))
              :
              toUpperCase(cell['text'])
            cell['bold'] = true
            cell['alignment'] = 'left'
          }

          // bottom margin for line charts
          if (row[0]['text'] === '') {
            cell['margin'] = [0, 0, 0, 2]
          }

          if (row[0]['text'] !== '') {
            cell['margin'] = [0, 2, 0, 0]
          }

          cell['style'] = ''
          cell['style'] = ['table__cell__individual_a']
        })
      });

      const quickStatsT = new Table(quickStatsTableBody);

      let quickStatsTablesConfig = {
        quickStatsT,
        tBodyFirstRectHeight: 0,
        tFooterFirstRectHeight: 20,
        tFooterSecondRectHeight: 10
      }

      quickStatsT['_body'].forEach((row, idx) => {
        if (
          quickStatsT['_body'] && quickStatsT['_body'][row] && quickStatsT['_body'][row][0]
          &&
          (quickStatsT['_body'][row][0]['text'] === '')) {
          quickStatsTablesConfig.tBodyFirstRectHeight += 1.25
        }
        else {
          quickStatsTablesConfig.tBodyFirstRectHeight += 11.35
        }
      });

      pdf.add(
        new Table([
          [
            new Table([
              [
                createCustomTable({
                  rectType: 'header',
                  rectWidth: 330,
                  rectColor: pdfColors.bgRectTableLightBlue,
                  firstRectHeight: 28,
                  secondRectHeight: 18
                })
              ],
              [
                new Cell(await addOverviewTableHeadersOnly('individual_a'))
                  .margin([0, 0, 0, 0]).relativePosition(0, -25).end
              ],
              [
                createCustomTable({
                  rectType: 'body',
                  rectWidth: 330,
                  rectColor: pdfColors.bgRectTableWhite,
                  firstRectHeight: quickStatsTablesConfig.tBodyFirstRectHeight
                })
              ],
              [
                createCustomTable({
                  rectType: 'footer',
                  rectWidth: 330,
                  rectColor: pdfColors.bgRectTableWhite,
                  firstRectHeight: 15,
                  secondRectHeight: 5
                })
              ],
              [
                new Cell(
                  new Table([
                    [
                      quickStatsT
                        .layout(layoutTableIndividualA)
                        .widths(['32%', '34%', '34%'])
                        // .heights((row: number) => 13)
                        .heights((row: number) => {
                          if (
                            quickStatsT['_body'] && quickStatsT['_body'][row] && quickStatsT['_body'][row][0]
                            &&
                            (quickStatsT['_body'][row][0]['text'] === '')) {
                            return 1
                          }
                          else {
                            return 13
                          }
                        })
                        .end
                    ]
                  ])
                    .layout({ defaultBorder: false })
                    .widths('*').end)
                  .margin([0, 0, 0, 0])
                  .relativePosition(0, -(quickStatsTablesConfig.tBodyFirstRectHeight + 15))
                  .end
              ]
            ])
              .layout({
                defaultBorder: false,
                paddingLeft: function (i, node) { return 0; },
                paddingRight: function (i, node) { return 0; },
                paddingTop: function (i, node) { return 0; },
                paddingBottom: function (i, node) { return 0; }
              })
              .margin([0, 0, 0, 0])
              .widths('*').end,

              // Saves of the Goalkeepers / Goals against
            // charts
            new Table([
              [
                createCustomTable({
                  rectType: 'header',
                  rectWidth: 155,
                  rectColor: pdfColors.bgRectTableWhite,
                  firstRectHeight: 217,
                  secondRectHeight: 18
                })
              ],
              [
                new Txt(
                  toUpperCase('SAVES / GOALS %')
                ).relativePosition(0, -205).alignment('center').end
              ],
              [
                new Table([
                  [
                    await new Img(teamDoughnut1_PDF.toDataURL()).width(140).alignment('center').margin([0, 2, 0, 2]).build()
                  ]
                ]).layout({
                  defaultBorder: false,
                  paddingLeft: function (i, node) { return 0; },
                  paddingRight: function (i, node) { return 0; },
                  paddingTop: function (i, node) { return 0; },
                  paddingBottom: function (i, node) { return 0; }
                })
                  .relativePosition(0, -185)
                  .margin([0, 0, 0, 0])
                  .widths('*').end,
              ],
              [
                new Table([
                  [
                    new Txt('').end,
                    createCustomTable({
                      rectType: 'body',
                      rectWidth: 7,
                      rectColor: '#36ABE0',
                      firstRectHeight: 7,
                    }),
                    new Txt(
                      toUpperCase('goals\n against')
                    )
                    .style('goals_failed_shorts_chart_legend')
                    .alignment('left')
                    .margin([2,0,15,0])
                    .end,

                    createCustomTable({
                      rectType: 'body',
                      rectWidth: 7,
                      rectColor: '#0E375F',
                      firstRectHeight: 7
                    }),

                    new Txt(
                      'Saves of the\nGoalkeepers'
                    )
                    .style('goals_failed_shorts_chart_legend')
                    .alignment('left')
                    .margin([2,0,0,0])
                    .end
                  ]
                ])
                .relativePosition(0, - 30)
                .widths(['5%', '8%', '37%', '8%', '42%'])
                .layout({
                  defaultBorder: false,
                  paddingLeft: (i, node) => 0,
                  paddingRight: (i, node) => 0,
                  paddingTop: (i, node) => 0,
                  paddingBottom: (i, node) => 0
                })
                .end
              ],

              // charts #2
              [
                createCustomTable({
                  rectType: 'header',
                  rectWidth: 155,
                  rectColor: pdfColors.bgRectTableWhite,
                  firstRectHeight: 217,
                  secondRectHeight: 18
                })
              ],
              [
                new Txt(
                  toUpperCase("GOALS / NO GOALS %")
                ).relativePosition(0, -205).alignment('center').end
              ],
              [
                new Table([
                  [
                    await new Img(teamDoughnut2_PDF.toDataURL()).width(140).alignment('center').margin([0, 2, 0, 2]).build(),
                  ]
                ]).layout({
                  defaultBorder: false,
                  paddingLeft: function (i, node) { return 0; },
                  paddingRight: function (i, node) { return 0; },
                  paddingTop: function (i, node) { return 0; },
                  paddingBottom: function (i, node) { return 0; }
                })
                  .relativePosition(0, -185)
                  .margin([0, 0, 0, 0])
                  .widths('*').end,
              ],
              [
                new Table([
                  [
                    new Txt('').end,
                    createCustomTable({
                      rectType: 'body',
                      rectWidth: 7,
                      rectColor: '#36ABE0',
                      firstRectHeight: 7
                    }),

                    new Txt(
                      toUpperCase('goals\n against')
                    )
                    .style('goals_failed_shorts_chart_legend')
                    .alignment('left')
                    .margin([2,0,15,0])
                    .end,

                    createCustomTable({
                      rectType: 'body',
                      rectWidth: 7,
                      rectColor: '#0E375F',
                      firstRectHeight: 7
                    }),

                    new Txt(
                      'Saves and\n Post Outs'
                    )
                    .style('goals_failed_shorts_chart_legend')
                    .alignment('left')
                    .margin([2,0,0,0])
                    .end
                  ]
                ])
                .relativePosition(0, -30)
                .widths(['5%', '8%', '37%', '8%', '42%'])
                .layout({
                  defaultBorder: false,
                  paddingLeft: (i, node) => 0,
                  paddingRight: (i, node) => 0,
                  paddingTop: (i, node) => 0,
                  paddingBottom: (i, node) => 0
                })
                .end
              ]
            ])
              .layout({
                defaultBorder: false,
                paddingLeft: function (i, node) { return 0; },
                paddingRight: function (i, node) { return 0; },
                paddingTop: function (i, node) { return 0; },
                paddingBottom: function (i, node) { return 0; }
              })
              .margin([0, 0, 0, 0])
              .widths('*').end,

            new Table([
              [
                accTeamShooting[0] ? new SVG(accTeamShooting[0].outerHTML).width(290).end : new Txt('').end
              ],
              [
                new Table([
                  [
                    createCustomTable({
                      rectType: 'rect',
                      rectWidth: 287.6,
                      rectColor: pdfColors.bgRectTableDarktBlue,
                      firstRectX: 0,
                      firstRectY: 5,
                      firstRectHeight: 10,
                    })
                  ]
                ])
                  .relativePosition(0, -176)
                  .layout({
                    defaultBorder: false,
                    paddingLeft: function (i, node) { return 0; },
                    paddingRight: function (i, node) { return 0; },
                    paddingTop: function (i, node) { return 0; },
                    paddingBottom: function (i, node) { return 0; }
                  })
                  .margin([0, 0, 0, 0])
                  .widths('*').end
              ],
              [
                accTeamShooting[1] ? new SVG(accTeamShooting[1].outerHTML).width(290).relativePosition(0, -7).end : new Txt('').end,
              ],
              [
                new Table([
                  [
                    createCustomTable({
                      rectType: 'rect',
                      rectWidth: 287.5,
                      rectColor: '#7EAAD6',
                      firstRectX: 0,
                      firstRectY: 5,
                      firstRectHeight: 10,
                    })
                  ]
                ])
                  .relativePosition(0, 260)
                  .layout({
                    defaultBorder: false,
                    paddingLeft: function (i, node) { return 0; },
                    paddingRight: function (i, node) { return 0; },
                    paddingTop: function (i, node) { return 0; },
                    paddingBottom: function (i, node) { return 0; }
                  })
                  .margin([0, 0, 0, 0])
                  .widths('*').end
              ]
            ]).layout('noBorders').alignment('center').margin([0, 10, 0, 0]).widths('*').end
          ]
        ])
          .layout({
            defaultBorder: false
          }).headerRows(1).margin([0, 0, 0, 10]).widths(['42%', '20%', '38%']).end);

      // page 2 row #3
      const resolveIndividualAggregatedTable = (tableData: Table) => {
        tableData['_body'].shift();
        tableData['_body'].forEach(row => {
          row.forEach((cell, idx) => {
            cell['style'] = ''
            cell['style'] = ['table__cell__individual_b']
            cell['margin'] = [0, 1, 0, 1]
            if (idx === 0) {
              cell['alignment'] = 'left'
            }
          })
        })
      }
    }

    addPageBreak(pdf);
    // end of page 2


    // page 3
    await addHeaderPage(pdf, toUpperCase('OVERVIEW'), selectedTeam);
    pdf.add(
      new Table([
        [
          new Txt(toUpperCase(translateService.instant('Connection & Goals'))).alignment('left').style('team_table_info').margin([0, 10, 0, 0]).end,
          new Txt(toUpperCase(translateService.instant('TEAM EVENTS'))).alignment('right').style('team_table_info').margin([0, 5, 10, 0]).end
        ]
      ]).layout({ defaultBorder: false }).margin([0, 0, 0, 0]).widths(['50%', '50%']).end
    )

    const resolveIndividualAggregatedTable = (tableData: Table) => {
      // tableData['_body'].shift()
      tableData['_body'].forEach(row => {
        row.forEach((cell, idx) => {
          cell['style'] = ''
          cell['style'] = ['table__cell__individual_b']
          cell['margin'] = [0, 3, 0, 0]
          if (idx === 0) {
            cell['alignment'] = 'left'
          }
          else {
            cell['fontSize'] = 9
          }
        })
      })
    }

    resolveIndividualAggregatedTable(teamTbl3Table)
    resolveIndividualAggregatedTable(teamTbl4Table)

    pdf.add(new Table([
      [
        new Table([
          [
            teamConnGoals[0] ? new SVG(teamConnGoals[0].outerHTML).width(310).margin([0, 10, 0, 0]).end : new Txt('').end
          ]
        ]).layout('noBorders').end,

        new Table([
           [

             new Table([
               [
                 createCustomTable({
                   rectType: 'header',
                   rectWidth: 475,
                   rectColor: pdfColors.bgRectTableLightBlue,
                   firstRectHeight: 28,
                   secondRectHeight: 18
                 })
               ],
               [
                 new Cell(await addOverviewTableHeadersOnly('individual_b'))
                   .margin([0, 0, 0, 0]).relativePosition(0, -25).end
               ],
               [
                 createCustomTable({
                   rectType: 'body',
                   rectWidth: 475,
                   rectColor: pdfColors.bgRectTableWhite,
                   firstRectHeight: 160
                 })
               ],
               [
                 createCustomTable({
                   rectType: 'footer',
                   rectWidth: 475,
                   rectColor: pdfColors.bgRectTableWhite,
                   firstRectHeight: 15,
                   secondRectHeight: 5
                 })
               ],
               [
                 new Cell(
                   teamTbl3Table
                     .widths(['32%', '34%', '32%'])
                     .layout(layoutTableBodyGrid)
                     .heights((row: number) => 15)
                     .end
                 )
                   .relativePosition(2, -175)
                   .end,
               ],
             ])
               .dontBreakRows(true)
               .layout({
                 defaultBorder: false,
                 paddingLeft: function (i, node) { return 0; },
                 paddingRight: function (i, node) { return 0; },
                 paddingTop: function (i, node) { return 0; },
                 paddingBottom: function (i, node) { return 0; }
               })
               .margin([0, 0, 0, 0])
               .widths('*').end
           ],
          [
            new Table([
              [
                createCustomTable({
                  rectType: 'header',
                  rectWidth: 475,
                  rectColor: pdfColors.bgRectTableLightBlue,
                  firstRectHeight: 28,
                  secondRectHeight: 18
                })
              ],
              [
                new Cell(await addOverviewTableHeadersOnly('individual_b'))
                    .margin([0, 0, 0, 0]).relativePosition(0, -25).end
              ],
              [
                createCustomTable({
                  rectType: 'body',
                  rectWidth: 475,
                  rectColor: pdfColors.bgRectTableWhite,
                  firstRectHeight: 160
                })
              ],
              [
                createCustomTable({
                  rectType: 'footer',
                  rectWidth: 475,
                  rectColor: pdfColors.bgRectTableWhite,
                  firstRectHeight: 15,
                  secondRectHeight: 5
                })
              ],
              [
                new Cell(
                    teamTbl4Table
                        // .dontBreakRows(true)
                        .widths(['32%', '34%', '32%'])
                        .layout(layoutTableBodyGrid)
                        .heights((row: number) => 15)
                        .end
                )
                    .relativePosition(2, -175)
                    .end,
              ],
            ])
                .dontBreakRows(true)
                .layout({
                  defaultBorder: false,
                  paddingLeft: function (i, node) { return 0; },
                  paddingRight: function (i, node) { return 0; },
                  paddingTop: function (i, node) { return 0; },
                  paddingBottom: function (i, node) { return 0; }
                })
                .margin([0, 0, 0, 0])
                .widths('*').end
          ]
        ])
            .layout({
              defaultBorder: false,
              paddingLeft: function (i, node) { return 0; },
              paddingRight: function (i, node) { return 0; },
              paddingTop: function (i, node) { return 0; },
              paddingBottom: function (i, node) { return 0; }
            })
            .margin([0, 0, 0, 0])
            .widths('*').end,
      ]
    ])
        .layout({ defaultBorder: false })
        .headerRows(1)
        .margin([0, 0, 0, 10])
        .widths(['40%', '60%',])
        .end);
    addPageBreak(pdf);

    // end of page 3

    // page 4
    if (
      paceAndPhaseColumns?.length > 0
      &&
      paceAndPhase
      &&
      selectedGames?.length > 0
    ) {
      paceAndPhaseTablesArray.forEach((pace_and_phase, idx) => {

        paceAndPhaseTablesArray[idx]['body'] = pace_and_phase['body'].map(obj => {
          let row = []
          for (let prop in obj) {

            let isFirtsColumn = prop === 'template'

            const cell = new Cell(
              new Txt(isFirtsColumn ? toUpperCase(obj[prop]) : obj[prop])
                .style('table__cell__individual_d')
                .alignment(isFirtsColumn ? 'left' : 'center')
                .margin([0, 1, 0, 0])
                .end
            ).end
            row.push(cell)
          }

          return row
        })
        // if (obj[prop] === 'Link To The Game') { return null}
        paceAndPhaseTablesArray[idx]['body'].pop();
      });

      const createPaceAndPhaseTable = async (config: any) => {
        pdf.add(
          new Table([
            [
              new Table([
                [
                  createCustomTable({
                    rectType: 'header',
                    rectWidth: config?.bodyWidths?.length > 2 ? 818 : 418,
                    rectColor: pdfColors.bgRectTableLightBlue,
                    firstRectHeight: config?.bodyWidths?.length > 6 ? 38 : 28,
                    secondRectHeight: config?.bodyWidths?.length > 6 ? 28 : 18
                  })
                ],
                [
                  new Cell(await addOverviewTableHeadersOnly('individual_d', config?.headers))
                    .margin([0, 0, 0, 0]).relativePosition(0, (config?.bodyWidths?.length > 6 ? -40 : -25)).end
                ],
                [
                  createCustomTable({
                    rectType: 'body',
                    rectWidth: config?.bodyWidths?.length > 2 ? 818 : 418,
                    rectColor: pdfColors.bgRectTableWhite,
                    firstRectHeight: 160
                  })
                ],
                [
                  createCustomTable({
                    rectType: 'footer',
                    rectWidth: config?.bodyWidths?.length > 2 ? 818 : 418,
                    rectColor: pdfColors.bgRectTableWhite,
                    firstRectHeight: 15,
                    secondRectHeight: 5
                  })
                ],
                [
                  new Cell(
                    new Table(config?.tableData)
                      .dontBreakRows(true)
                      .widths(config?.bodyWidths)
                      .layout(layoutTableBodyGrid)
                      .heights((row: number) => 13)
                      .end)
                    .relativePosition(0, -175)
                    .end,
                ]
              ])
                .dontBreakRows(true)
                .layout({
                  defaultBorder: false,
                  paddingLeft: function (i, node) { return 0; },
                  paddingRight: function (i, node) { return 0; },
                  paddingTop: function (i, node) { return 0; },
                  paddingBottom: function (i, node) { return 0; }
                })
                .margin([0, 0, 0, 0])
                .widths('*').end
            ]

          ])
            .layout({
              defaultBorder: false,
              paddingLeft: function (i, node) { return 0; },
              paddingRight: function (i, node) { return 0; },
              paddingTop: function (i, node) { return 0; },
              paddingBottom: function (i, node) { return 0; }
            })
            .margin([0, 0, 0, 0])
            .widths('*').end
        );
      }

      for (let idx = 0; idx < paceAndPhaseTablesArray.length; idx++) {
        const paceAndPhaseHeaders = paceAndPhaseTablesArray[idx]['headers'];

        const resolveWidths = (tablePart: 'headersW' | 'bodyW', hLenghs) => {
          let headerWidth = ''
          if (tablePart === 'headersW') {
            switch (hLenghs) {
              case 10:
                headerWidth = '8.88%'
                break;
              case 9:
                headerWidth = '10%'
                break;
              case 8:
                headerWidth = '11.4%'
                break;
              case 7:
                headerWidth = '13.3%'
                break;
              case 6:
                headerWidth = '16%'
                break;
              case 5:
                headerWidth = '20%'
                break;
              case 4:
                headerWidth = '26.7%'
                break;
              case 3:
                headerWidth = '40%'
                break;
              case 2:
              case 1:
                headerWidth = '30%'
                break;
              default:
                headerWidth = '8.88%'
                break;
            }
          } else {

            switch (hLenghs) {
              case 10:
                headerWidth = '8.88%'
                break;
              case 9:
                headerWidth = '10%'
                break;
              case 8:
                headerWidth = '11.4%'
                break;
              case 7:
                headerWidth = '13.3%'
                break;
              case 6:
                headerWidth = '16%'
                break;
              case 5:
                headerWidth = '20%'
                break;
              case 4:
                headerWidth = '26.65%'
                break;
              case 3:
                headerWidth = '40%'
                break;
              case 2:
              case 1:
                headerWidth = '30%'
                break;
              default:
                headerWidth = '8.88%'
                break;
            }
          }

          return headerWidth
        }

        let headersWidths = []
        let bodyWidths = []
        let hLenghs = paceAndPhaseHeaders.length;

        paceAndPhaseHeaders.forEach((header, idx) => {
          if (idx === 0) {
            headersWidths.push('20%')
            bodyWidths.push('20%')
          } else {
            let headerWidth = resolveWidths('headersW', hLenghs)
            let bodyWidth = resolveWidths('bodyW', hLenghs)

            headersWidths.push(headerWidth)
            bodyWidths.push(bodyWidth)
          }
        })

        if ((idx === 0) || (idx % 2 === 0)) {
          await addHeaderPage(pdf, toUpperCase('OVERVIEW'), selectedTeam);
          pdf.add(new Txt(toUpperCase(translateService.instant('PACE & PHASES DEVELOPMENT'))).style('team_table_info').alignment('left').margin([0, 5, 0, 5]).end);

          await createPaceAndPhaseTable({
            headers: {
              headers: paceAndPhaseHeaders,
              widths: headersWidths
            },
            bodyWidths,
            tableData: paceAndPhaseTablesArray[idx]['body']
          });
          if (idx === paceAndPhaseTablesArray.length - 1) {
            addPageBreak(pdf);
          }
        } else {
          await createPaceAndPhaseTable({
            headers: {
              headers: paceAndPhaseHeaders,
              widths: headersWidths
            },
            bodyWidths,
            tableData: paceAndPhaseTablesArray[idx]['body']
          });
          addPageBreak(pdf);
        }
      }
    }
    // end of page 4

    // page 5
    const playerTableBody = [];
    playersTbl.forEach(element => {
      playerTableBody.push([
        new Txt(element.playerName).style('table__cell__individual_e').alignment('left').end,
        new Txt(element.playedPoses).style('table__cell__individual_e').end,
        new Txt(element.possessionEvents).style('table__cell__individual_e').end,
        new Txt(element.timePlayed).style('table__cell__individual_e').end,
        new Txt(element.goals).style('table__cell__individual_e').end,
        new Txt(element.assists).style('table__cell__individual_e').end,
        new Txt(element.failedShots).style('table__cell__individual_e').end,
        new Txt(element.goalsReceived).style('table__cell__individual_e').end,
        new Txt(element.saves).style('table__cell__individual_e').end,
        new Txt(element.postOuts).style('table__cell__individual_e').end,
        new Txt(element.lostBalls).style('table__cell__individual_e').end,
        new Txt(element.lostBallsPerc).style('table__cell__individual_e').end,
        new Txt(element.eff).style('table__cell__individual_e').end,
        new Txt(element.shootEff).style('table__cell__individual_e').end,
        new Txt(element.playerScore).style('table__cell__individual_e').end,
      ]);
    });

    // const playersTable = new Table(playerTableBody);
    await addHeaderPage(pdf, toUpperCase('OVERVIEW'), selectedTeam);
    pdf.add(new Txt(toUpperCase(translateService.instant('PLAYERS'))).style('team_table_info').alignment('left').margin([10, 0, 0, 5]).end);
    pdf.add(
      new Table([
        [
          createCustomTable({
            rectType: 'header',
            rectWidth: 818,
            rectColor: pdfColors.bgRectTableLightBlue,
            firstRectHeight: 28,
            secondRectHeight: 18
          })
        ],
        [
          new Cell(await addOverviewTableHeadersOnly('individual_e'))
            .margin([0, 0, 0, 0]).relativePosition(0, -27).end
        ],
        [
          new Cell(
            new Table(playerTableBody)
              .dontBreakRows(true)
              .widths([
                '20.5%', '7.7%', '7%', '5.7%', '4.7%',
                '5%', '5.5%', '5.7%', '4.9%', '5.5%',
                '5.7%', '5.7%', '4.9%', '5.7%', '5.5%'
              ])
              .layout(layoutTableBodyGridTest2)
              .end
          ).end,
        ],
      ])
        .layout({
          defaultBorder: false,
          paddingLeft: function (i, node) { return 0; },
          paddingRight: function (i, node) { return 0; },
          paddingTop: function (i, node) { return 0; },
          paddingBottom: function (i, node) { return 0; }
        })
        .margin([0, 0, 0, 0])
        .widths('*').end

    );
    // end of page 5

    //  page 6
    const gameSystemTableBody = [];
     gameSystemTbl.forEach(element => {
       gameSystemTableBody.push([
         // new Cell(new Txt(element.categoryName + " - " + element.actionDisplayName).style('table__cell__individual_f').end).fillColor(getGameSystemColor(element.colorCode)).end,

         new Cell(new Txt(element.categoryName + " - " + element.actionDisplayName).style('table__cell__individual_f').end)
           .color(getGameSystemColor(element.colorCode)).alignment('left').end,

         new Txt(element.numberOfActionsPlayed).style('table__cell__individual_f').end,
         new Txt(element.numberOfGoals).style('table__cell__individual_f').end,
         new Txt(element.numberOfSaves).style('table__cell__individual_f').end,
         new Txt(element.numberOfPostOuts).style('table__cell__individual_f').end,
         new Txt(element.numberOfActionsPlayedWith7Meters).style('table__cell__individual_f').end,


         new Txt(element.numberOfFault).style('table__cell__individual_f').end,
         new Txt(element.numberOfSuspension).style('table__cell__individual_f').end,
         new Txt(element.numberOfLostBall).style('table__cell__individual_f').end,

         new Txt(decimalToFixed(element.percentageGoals) + "%").style('table__cell__individual_f').end,
         new Txt(decimalToFixed(element.percentageNoGoals) + "%").style('table__cell__individual_f').end,
         new Txt(decimalToFixed(element.percentage7MetersGoals) + "%").style('table__cell__individual_f').end
       ]);
     });

    gameSystemTableBody.forEach(row => {
      row.forEach(cell => {
        cell['margin'] = [0, 1, 0, 0]
      })
    });

    if (gameSystemTbl && gameSystemTbl?.length > 0) {
      addPageBreak(pdf);
      await addHeaderPage(pdf, toUpperCase('OVERVIEW'), selectedTeam);
      pdf.add(new Txt(toUpperCase(translateService.instant('GAME SYSTEM'))).style('team_table_info').alignment('left').margin([10, 5, 0, 5]).end);

      pdf.add(
        new Table([
          [
            createCustomTable({
              rectType: 'header',
              rectWidth: 818,
              rectColor: pdfColors.bgRectTableLightBlue,
              firstRectHeight: 28,
              secondRectHeight: 18
            })
          ],
          [
            new Cell(await addOverviewTableHeadersOnly('individual_f'))
              .margin([0, 0, 0, 0]).relativePosition(0, -25).end
          ],
          [
            new Cell(
              new Table(gameSystemTableBody)
                .dontBreakRows(true)
                .widths([
                  '20%', '7.27%','7.27%','7.27%','7.27%',
                  '7.27%','7.27%','7.27%','7.27%','7.27%',
                  '7.27%','7.27%'
                ])
                // .layout({defaultBorder: true})

                .layout(layoutTableBodyGridTest2)
                .heights((row: number) => 8)
                .end
            ).end,
          ],
        ])
          .layout({
            defaultBorder: false,
            paddingLeft: function (i, node) { return 0; },
            paddingRight: function (i, node) { return 0; },
            paddingTop: function (i, node) { return 0; },
            paddingBottom: function (i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*').end
      );
    }

    pdf.create().getBase64(b64 => {
      modalCtrl.create({
        component: PdfViewerPage,
        componentProps: { data: b64, fileName: selectedTeam },//
        cssClass: 'modal-pagescreen',
        showBackdrop: false
      }).then(t => t.present());
      loading.dismiss();
    });
  });
}

export async function addHeaderPage(pdf: PdfMakeWrapper, pageTitle: string, selectedTeam: string) {
  pdf.add(new Table([
    [
      new Cell(
        new SVG(logoWithTextSVG)
          .width('40').end).end,

      new Cell(
        await teamLogoResolver(selectedTeamInfo?.shieldUrl, 'center', 50)
      ).end,

      new Cell(
        new Table([
          [
            new Txt(`${selectedTeam}`).style(['headerTeamCell']).end
          ]
        ])
          .layout({ defaultBorder: false })
          .relativePosition(0, 10)
          .end
      ).end,

      new Cell(
        new Txt([
          new Txt(`${pageTitle} ${selectedTeam}\n`).style(['headerTeamCell'])
            .alignment('center')
            .end,
          new Txt(`${PDF_URL}`).style(['headerTeamCell', 'headerTeamCellSmall'])
            .decoration('underline').alignment('center')
            .end
        ])
          .relativePosition(0, 15)
          .end
      ).end,

      new Cell(
        new Txt([
          new Txt(`${selectedTeam}\n`).style(['headerTeamCell', 'headerTeamCellSmall']).alignment('right').lineHeight(1.5).end,
          new Txt(`${pdfCreationDate}`).style(['headerTeamCell', 'headerTeamCellSmall']).alignment('right').lineHeight(1.5).end
        ])
          .relativePosition(0, 20).end
      ).end
    ]
  ])
    .layout({ defaultBorder: false })
    .widths(['12%', '8%', '15%', '30%', '35%'])
    .end);
  addHorizontalLine(pdf);
}

async function addOverviewTableHeadersOnly(
  tableType: string,
  headerConfig?: any
) {
  let widths = []
  let headerTitles: any = []

  switch (tableType) {
    case 'individual_a':
      widths = [
        '32%', '34%', '34%'
      ];
      headerTitles = [
        'Totals',
        toUpperCase(translateServices.instant('PLAYER TOTALS')),
        toUpperCase(translateServices.instant('PLAYER AVERAGE'))
      ];
      break;
    case 'individual_b':
      widths = [
        '33%', '34%', '33%'
      ];
      headerTitles = [
        'All Events',
        toUpperCase(translateServices.instant('Total')),
        toUpperCase(translateServices.instant('Average'))
      ];
      break;

    case 'individual_d':
      widths = []
      headerTitles = headerConfig?.headers.map(header => header?.name ? header.name : '')
      widths = headerConfig?.widths
      break;
    case 'individual_e':
      widths = [
        '20%', '7.7%', '7%', '5.7%', '4.7%',
        '5%', '5.7%', '5.7%', '4.9%', '5.7%',
        '5.7%', '5.7%', '4.9%', '5.7%', '5.7%'
      ];
      headerTitles = [
        toUpperCase(translateServices.instant("Player Name")),
        toUpperCase(translateServices.instant("Played Possessions")),
        toUpperCase(translateServices.instant("Possession Events")),
        toUpperCase(translateServices.instant("Time Played")),
        toUpperCase(translateServices.instant("Goals")),
        toUpperCase(translateServices.instant("Assists")),
        toUpperCase(translateServices.instant("Failed Shots")),
        toUpperCase(translateServices.instant("Goals Received")),
        toUpperCase(translateServices.instant("Saves")),
        toUpperCase(translateServices.instant("Post Outs")),
        toUpperCase(translateServices.instant("Lost Balls")),
        toUpperCase(translateServices.instant("% Lost Balls")),
        toUpperCase(translateServices.instant("% Eff")),
        toUpperCase(translateServices.instant("% Shoot Eff")),
        toUpperCase(translateServices.instant("Player Score")),
      ];
      break;

    case 'individual_f':
      widths = [
        '19.6%', '7.47%', '7.47%', '7.27%', '7.27%',
        '7.27%', '7.27%', '7.27%', '7.27%', '7.27%',
        '7.27%', '7.27%'
      ]
      headerTitles = [
        toUpperCase('SYSTEM'),
        toUpperCase('TIMES PLAYED'),
        toUpperCase('N° GOALS'),
        toUpperCase('N° SAVES'),
        toUpperCase('N° POST / OUT'),
        toUpperCase('N° 7 METERS'),

        toUpperCase('N° FAULT'),
        toUpperCase('N° SUSPENSION'),
        toUpperCase('N° LOST BALL'),

        toUpperCase('GOALS %'),
        toUpperCase('NO GOALS %'),
        toUpperCase('Goals 7 Meters %')
      ];
      break;

    default:
      break;
  }

  let cellrows: Object[][] = [
    getArrayHeaderCellsFromStrings(headerTitles, null, null, 'table__header__cell')
  ];

  cellrows.forEach(
    cellRow => {
      cellRow.forEach(cell => {
        switch (tableType) {
          case 'individual_a':
            cell['style'] = ''
            cell['style'] = 'header__cell__individual_a'
            break;
          case 'individual_b':
            cell['style'] = ''
            cell['style'] = 'header__cell__individual_b'
            break;
          case 'individual_d':
            cell['style'] = ''
            cell['style'] = 'header__cell__individual_d'
            break;
          case 'individual_e':
            cell['style'] = ''
            cell['style'] = 'header__cell__individual_e'
            break;
          case 'individual_f':
            cell['style'] = ''
            cell['style'] = 'header__cell__individual_f'
            break;

          default:
            break;
        }
      })
    }
  );

  cellrows[0][0]['alignment'] = 'left';
  cellrows[0][0]['margin'] = [10, 0, 0, 0];

  switch (tableType) {
    case 'individual_a':
    case 'individual_b':
    case 'individual_d':
    case 'individual_e':
    case 'individual_f':
      return new Table(cellrows).headerRows(1)
        .widths(widths).margin(0)
        .end;
    default:
      break;
  }
}

function createCustomTable(config: RectConfig, data?: any,) {
  const {
    rectType,
    rectWidth,
    rectColor,

    firstRectX,
    firstRectY,
    firstRectHeight,
    firstRectLineColor,
    firstRectColor,

    secondRectX,
    secondRectY,
    secondRectHeight
  } = config;

  switch (rectType) {
    case 'header':
      return [
        new Canvas(
          [
            new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 10], [rectWidth, firstRectHeight ? firstRectHeight : 30]).lineColor(rectColor).color(rectColor).round(10).end,
            new Rect([secondRectX ? secondRectX : 0, secondRectY ? secondRectY : 20], [rectWidth, secondRectHeight ? secondRectHeight : 20]).lineColor(rectColor).color(rectColor).end,
          ]
        ).end
      ];
    case 'body':
      return [
        new Canvas(
          [
            new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 0], [rectWidth, firstRectHeight ? firstRectHeight : 40]).lineColor(rectColor).color(rectColor).end,
          ]
        ).end
      ];
    case 'footer':
      return [
        new Canvas(
          [
            new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 0], [rectWidth, firstRectHeight ? firstRectHeight : 28]).lineColor(rectColor).color(rectColor).round(10).end,
            new Rect([secondRectX ? secondRectX : 0, secondRectY ? secondRectY : 0], [rectWidth, secondRectHeight ? secondRectHeight : 18]).lineColor(rectColor).color(rectColor).end
          ]
        ).end,
      ];
    case 'rect':
      return [
        new Canvas(
          [
            new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 0], [rectWidth, firstRectHeight ? firstRectHeight : 28]).lineColor(rectColor).color(rectColor).round(10).end,
          ]
        ).end,
      ];
    case 'rect-with-border':
      return [
        new Canvas(
          [
            new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 0], [rectWidth, firstRectHeight ? firstRectHeight : 28]).lineColor(firstRectLineColor).color(firstRectColor).round(10).end,
          ]
        ).end,
      ];
  }
}

function getArrayHeaderCellsFromStrings(headerTitles: Object[], teamNameBgColor: string, teamNameColor: string, headerCellClass?: string) {
  var cellsArray: Object[] = [];
  for (let i = 0; i < headerTitles.length; i++) {
    let cell = new Cell(new Txt('' + (headerTitles[i]['label'] ?? headerTitles[i])).end).fillColor(teamNameBgColor).color(teamNameColor).alignment('center').margin([3, 1]).border([false, false, false, false]).style(headerCellClass ? headerCellClass : '');
    if (headerTitles[i]['cspan']) cell = cell.colSpan(headerTitles[i]['cspan']);
    if (headerTitles[i]['bold'] !== false) cell = cell.bold();
    cellsArray.push(cell.end);
  }
  return cellsArray;
}
