import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { ManageAccountDto } from "src/app/api/hai-api";
import { OrganizationOptionsPopoverData } from "./organization-options-popover-data.model";

@Component({
  templateUrl: "./organization-options-popover.component.html",
  styleUrls: ["./organization-options-popover.component.scss"],
})
export class OrganizationOptionsPopoverComponent implements OnInit {
  @Input() organization: ManageAccountDto;

  constructor(private readonly popoverCntl: PopoverController) {}

  ngOnInit(): void {}

  dismiss() {
    this.popoverCntl.dismiss();
  }

  accountRename(): void {
    const data: OrganizationOptionsPopoverData = {
      accountRename: true,
    };
    this.popoverCntl.dismiss(data);
  }
  subscriptionChange(): void {
    const data: OrganizationOptionsPopoverData = {
      subscriptionChange: true,
    };
    this.popoverCntl.dismiss(data);
  }
  changePlan(): void {
    const data: OrganizationOptionsPopoverData = {
      changePlan: true,
    };
    this.popoverCntl.dismiss(data);
  }
}
