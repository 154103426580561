import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface TimeOfEventsSelection {
  minSecondsSinceStartOfGame: number;
  maxSecondsSinceStartOfGame: number;
}
interface SelectedValue {
  lower: number;
  upper: number;
}
@Component({
  selector: 'app-time-of-events',
  templateUrl: './time-of-events.component.html',
  styleUrls: ['./time-of-events.component.scss'],
})
export class TimeOfEventsComponent {
  public readonly limits: SelectedValue = { lower: 0, upper: 60 };
  public readonly defaultValue: SelectedValue = { lower: 0, upper: 60 };
  public showSelector = true;

  private selectionData: TimeOfEventsSelection = {
    minSecondsSinceStartOfGame: 0,
    maxSecondsSinceStartOfGame: 0
  };
  @Input() set selection(value: TimeOfEventsSelection) {
    if (!value) return;
    this.selectionData = value;
    this.showSelector = false;
    this.defaultValue.lower = value.minSecondsSinceStartOfGame/60;
    this.defaultValue.upper = value.maxSecondsSinceStartOfGame/60;
    setTimeout(() => this.showSelector = true, 100);
  }
  @Output() selectionChange: EventEmitter<TimeOfEventsSelection> = new EventEmitter<TimeOfEventsSelection>();
  @Output() change: EventEmitter<TimeOfEventsSelection> = new EventEmitter<TimeOfEventsSelection>();

  constructor() {}

  fireChange(ev: any) {
    this.selectionData.minSecondsSinceStartOfGame = ev.detail.value.lower*60;
    this.selectionData.maxSecondsSinceStartOfGame = ev.detail.value.upper*60;
    this.selectionChange.next(this.selectionData);
    this.change.next({...this.selectionData});
  }

}
