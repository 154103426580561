import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TeamModel, TeamSearchModel } from '@handballai/stats-calculation';
import { PopoverController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { TManageAccountEquipoGrants } from 'src/app/shared-services/account-equipo/account-equipo.service';
import { CoreService } from 'src/app/shared-services/core.service';
import { UiEventDispatcherService } from 'src/app/shared-services/ui-event-dispatcher/ui-event-dispatcher.service';

@Component({
    selector: 'app-confirm-delete-popup',
    templateUrl: './confirm-delete-popup.component.html',
    styleUrls: ['./confirm-delete-popup.component.scss'],
    standalone: false
})
export class ConfirmDeletePopupComponent implements OnInit {

  private _account;
  private _fg: FormGroup;

  @Input() header: string;
  @Input() subheader: string;
  @Input() placeholder: string;

  @Input() confirmButtonText: string;
  @Input() cancelButtonText: string;
  @Input() disableCancelButton: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly core: CoreService,
    private readonly popoverController: PopoverController,
  ) {

  }

  ngOnInit() {
    this.initForm();
   }




  get fg(): FormGroup {
    return this._fg;
  }


  get account(): any {
    return this._account;
  }


  public async onCancel(): Promise<void> {
    await this.popoverController.dismiss('', 'cancel');
  }



  public async onConfirmName(): Promise<void> {
    await this.popoverController.dismiss('', 'confirm');
  }

  private initForm(): void {
    if (!this._fg) {
      this._fg = this.fb.group({
        nameFc: [''],
      });
    }
  }


}
