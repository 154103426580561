<div class="w-full mb-1 flex justify-center mt-4">
    <ng-select
            [placeholder]="'Type to select...'|translate"
            class="custom custom-width-select"
            maxSelectedItems="5"
            [searchable]="true"
            [clearable]="true" [(ngModel)]="selectedPlayer" (change)="onPlayerSelectChange()">
        <ng-option *ngFor="let players of players"
                   [value]="players.id">
            {{players.teamName}} - {{players.backNumber}}: {{players.name}} - {{players.position}}
        </ng-option>
    </ng-select>
</div>
<div class="individual-stats__main individual-stats-table">
    <div class="individual-stats__left-pane">
        <div class="w-full p-2 individual-stats__card-container" style="padding-top: 0;">
            <div class="individual-stats__table-row1">
                <div class="individual-stats__table-row-50-left">
                    <div class="w-full">
                        <ngx-datatable
                                class="material"
                                [rows]="individualStatsGoalsMode"
                                [columns]="goalEventColumns"
                                [limit]="4"
                                [columnMode]="'flex'"
                                rowHeight="auto"
                                [footerHeight]="false"
                                [messages]="{emptyMessage: 'No data to display' | translate}"
                                [headerHeight]="50"
                        >
                            <ngx-datatable-column prop="phase"
                                                  [flexGrow]="2"
                                                  [sortable]="false"
                                                  [resizeable]="false"
                                                  [cellClass]="'first-column'">
                                <ng-template let-column="column" ngx-datatable-header-template></ng-template>
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    {{value | translate}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column prop="goal"
                                                  [flexGrow]="1"
                                                  [sortable]="false"
                                                  [resizeable]="false"
                                                  [cellClass]="'center-column'">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <span>G
                                    <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('G')"></ion-icon>
                                    </span>
                                </ng-template>
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    {{value}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column prop="failedShots"
                                                  [flexGrow]="1"
                                                  [sortable]="false"
                                                  [resizeable]="false"
                                                  [cellClass]="'center-column'">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <span>FS
                                    <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('FS')"></ion-icon>
                                    </span>
                                </ng-template>
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    {{value}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column prop="saves"
                                                  [flexGrow]="1"
                                                  [sortable]="false"
                                                  [resizeable]="false">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    <span>S
                                    <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('S')"></ion-icon>
                                    </span>
                                </ng-template>
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    {{value}}
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
                <div class="individual-stats__table-row-50-right">
                    <div class="w-full">
                        <ngx-datatable
                                class="material"
                                [rows]="individualStatsKpiModel"
                                [columns]="eventColumns1"
                                [limit]="4"
                                [columnMode]="'flex'"
                                rowHeight="auto"
                                [footerHeight]="false"
                                [messages]="{emptyMessage: 'No data to display' | translate}"
                                [headerHeight]="50"
                        >
                            <ngx-datatable-column prop="kpiName"
                                                  [flexGrow]="2"
                                                  [sortable]="false"
                                                  [resizeable]="false"
                                                  [cellClass]="'first-column'">
                                <ng-template let-column="column" ngx-datatable-header-template>
                                    {{ 'General Stats' | translate }}
                                </ng-template>
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    {{value | translate}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column prop="kpi"
                                                  [flexGrow]="1"
                                                  [sortable]="false"
                                                  [resizeable]="false">
                                <ng-template let-column="column" ngx-datatable-header-template></ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                   <span>
                                    {{row.kpiName === 'Time Played' ? formatPlaytime(row.kpi) : row.kpi | number: '1.0-1'}}
                                   </span> 
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
            <div class="individual-stats__table-row-100">
                <ngx-datatable
                        class="material"
                        [rows]="individualStatsModel1"
                        [columns]="eventColumns2"
                        [limit]="1"
                        [columnMode]="'flex'"
                        rowHeight="auto"
                        [footerHeight]="false"
                        [messages]="{emptyMessage: 'No data to display' | translate}"
                        [headerHeight]="50"
                >
                    <ngx-datatable-column prop="min2"
                                          [flexGrow]="1"
                                          [sortable]="false"
                                          [resizeable]="false"
                                          [cellClass]="'first-column'">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <span>{{'2 Min'|translate}}</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span>{{value}}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column prop="yellowCard"
                                          [flexGrow]="1"
                                          [sortable]="false"
                                          [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <span>{{'Yellow Card'|translate}}</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span>{{value}}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column prop="redCard"
                                          [flexGrow]="1"
                                          [sortable]="false"
                                          [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <span>{{'Red Card'|translate}}</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span>{{value}}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column prop="blueCard"
                                          [flexGrow]="1"
                                          [sortable]="false"
                                          [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <span>{{'Blue Card'|translate}}</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span>{{value}}</span>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
            <div class="individual-stats__table-row-100">
                <ngx-datatable
                        class="material"
                        [rows]="individualStatsEventModel"
                        [columns]="eventColumns3"
                        [limit]="9"
                        [columnMode]="'flex'"
                        rowHeight="auto"
                        [footerHeight]="false"
                        [messages]="{emptyMessage: 'No data to display' | translate}"
                        [headerHeight]="50"
                >
                    <ngx-datatable-column prop="defenseEventsName"
                                          [flexGrow]="2"
                                          [sortable]="false"
                                          [resizeable]="false"
                                          [cellClass]="'first-column'">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            {{'EVENTS'|translate}}
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{value | translate}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column prop="defenseEvents"
                                          [flexGrow]="1"
                                          [sortable]="false"
                                          [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <span>{{'DEFENSE'|translate}}</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span>{{value}}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column prop="offenseEvents"
                                          [flexGrow]="1"
                                          [sortable]="false"
                                          [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <span>{{'OFFENSE'|translate}}</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span>{{value}}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column prop="offenseEventsName"
                                          [flexGrow]="2"
                                          [sortable]="false"
                                          [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            {{'EVENTS'|translate}}
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{value | translate}}
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </div>
    <div class="individual-stats__right-pane">
        <div class="card-container w-full p-2 individual-stats__card-container" style="padding: 0;">
            <div style="padding: 10px;">
                <app-shooter-position [goalCounters]="selectedPlayerGoalCounters"
                                      [goalConnectionCounter]="selectedPlayerGoalConnectionCounters">
                </app-shooter-position>
            </div>
            <div class="w-full individual-stats__table-events">
                <ngx-datatable class="material w-full"
                               [rows]="selectedPlayerEventList"
                               [columns]="columns"
                               [columnMode]="columnMode"
                               [limit]="5"
                               rowHeight="auto"
                               [footerHeight]="50"
                               [headerHeight]="50">
                    <ngx-datatable-column name="eventTime" [flexGrow]="1" [sortable]="false" [cellClass]="'first-column'">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <span>
                                {{'Time'|translate}}
                            </span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span>{{value.halftime}}-{{value.minutesSinceHalftime | number: '2.0-0'}}
                                :{{value.secondsSinceHalftime  | number: '2.0-0'}}
                                -{{transformTimeFormat(value.timestamp) | date: 'HH:mm:ss' }}</span>
                            
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="eventType" [flexGrow]="1" [sortable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <span>
                                {{'Event'|translate}}
                            </span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span>{{value}}</span>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </div>
</div>
