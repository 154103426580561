import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { DateTime } from 'luxon';
import { CounterModel } from '@handballai/stats-calculation';
import { EasyPlayerEvent } from '@handballai/stats-calculation';
import { GamePlayerModel } from 'src/app/shared-services/model/game.model';
import { AdvancedStatPlayerModel } from '@handballai/stats-calculation';
import { PlayTimeInfo } from '@handballai/stats-calculation';
import { formatTimeToHoursMinutesAndSeconds, timerFormatHelper } from 'src/app/shared-services/helper/timer-format.helper';
import {
  IndividualStatsEventModel,
  IndividualStatsEventModelList, IndividualStatsGoalModel, IndividualStatsGoalModelList,
  IndividualStatsKpiModel,
  IndividualStatsKpiModeList,
  IndividualStatsModel1
} from '@handballai/stats-calculation';
import { CoreService } from 'src/app/shared-services/core.service';

@Component({
    selector: 'app-individual-stats',
    templateUrl: './individual-stats.component.html',
    styleUrls: ['./individual-stats.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IndividualStatsComponent implements OnInit {

  private _players: AdvancedStatPlayerModel[] = [];
  private _selectedPlayer: number;
  private _playerSelected$ = new EventEmitter<number | null>();
  private _columns = [
    { prop: 'event' },
    { prop: 'eventTime' }
  ];
  private _columnMode = ColumnMode.flex;

  private _goalEventColumns = [
    { prop: 'phase', name: 'Phase'},
    { prop: 'goal', name: 'Goal'},
    { prop: 'failedShots', name: 'Failed Shots'},
    { prop: 'saves', name: 'Saves' },
  ];

  private _eventColumns1 = [
    { prop: 'kpiName', name: 'Kpi Name' },
    { prop: 'kpi', name: 'Kpi' },
  ];

  private _eventColumns2 = [
    {prop: 'min2', name: '2 Min.'},
    {prop: 'yellowCard', name: 'Yellow Cards'},
    {prop: 'redCard', name: 'Red Cards'},
    {prop: 'blueCard', name: 'Blue Cards'},
  ];

  private _eventColumns3 = [
    { prop: 'defenseEventsName', name: 'Defense Event Names' },
    { prop: 'defenseEvents', name: 'Defense Events' },
    { prop: 'offenseEvents', name: 'Offense Events' },
    { prop: 'offenseEventsName', name: 'Offense Event Names' },
  ];


  private _selectedPlayerGoalCounters: CounterModel[] = [];
  private _selectedPlayerEventList: EasyPlayerEvent[] = [];
  private _gamePlayerModel: GamePlayerModel[];
  private _selectedPlayerPlayTimeInfo: PlayTimeInfo[];
  private _individualStatsModel1: IndividualStatsModel1[] = [];
  private _individualStatsKpiModeList: IndividualStatsKpiModeList[] = [];
  private _individualStatsEventModelList: IndividualStatsEventModelList[] = [];
  private _individualStatsGoalsModelList: IndividualStatsGoalModelList[] = [];
  private _selectedPlayerGoalConnectionCounters: CounterModel[] = [];

  constructor(
    private readonly logger: NGXLogger,
    public readonly core: CoreService,
  ) { }

  ngOnInit(): void {
    this.logger.debug('IndividualStatsComponent.ngOnInit', this._selectedPlayer);
    this._selectedPlayer = undefined;
    this._playerSelected$.emit(null);
  }

  get players(): AdvancedStatPlayerModel[] {
    return this._players;
  }

  @Input()
  set players(value: AdvancedStatPlayerModel[]) {
    if (value) {
      this._players = value;
    }
  }

  get selectedPlayer(): number {
    return this._selectedPlayer;
  }

  set selectedPlayer(value: number) {
    this.logger.debug('IndividualStatsComponent.set selectedPlayer, new selected player', this._selectedPlayer, value);
    this._selectedPlayer = value;
  }


  @Output()
  get playerSelected$(): EventEmitter<number> {
    return this._playerSelected$;
  }


  get columns() {
    return this._columns;
  }

  get columnMode(): ColumnMode {
    return this._columnMode;
  }

  get selectedPlayerGoalCounters(): CounterModel[] {
    return this._selectedPlayerGoalCounters;
  }

  get selectedPlayerGoalConnectionCounters(): CounterModel[] {
    return this._selectedPlayerGoalConnectionCounters;
  }

  get goalEventColumns() {
    return this._goalEventColumns;
  }

  get eventColumns1() {
    return this._eventColumns1;
  }

  get eventColumns2() {
    return this._eventColumns2;
  }

  get eventColumns3() {
    return this._eventColumns3;
  }

  @Input()
  set selectedPlayerGoalCounters(value: CounterModel[]) {
    this.logger.debug('IndividualStatsComponent.selectedPlayerGoalCounters', value);
    this._selectedPlayerGoalCounters = value;
  }

  @Input()
  set selectedPlayerGoalConnectionCounters(value: CounterModel[]) {
    this._selectedPlayerGoalConnectionCounters = value;
  }

  get selectedPlayerEventList(): EasyPlayerEvent[] {
    return this._selectedPlayerEventList;
  }

  @Input()
  set selectedPlayerEventList(value: EasyPlayerEvent[]) {
    this.logger.debug('IndividualStatsComponent.selectedPlayerEventList', value);
    this._selectedPlayerEventList = value;
  }

  get gamePlayerModel(): GamePlayerModel[] {
    return this._gamePlayerModel;
  }

  @Input()
  set gamePlayerModel(value: GamePlayerModel[]) {
    this.logger.debug('IndividualStatsComponent.gamePlayerModel', value);
    this._gamePlayerModel = value;
  }

  get selectedPlayerPlayTimeInfo(): PlayTimeInfo[] {
    if (this._selectedPlayerPlayTimeInfo && this._selectedPlayerPlayTimeInfo.length > 0) {
      return this._selectedPlayerPlayTimeInfo;
    }
    return [];
  }

  @Input()
  set selectedPlayerPlayTimeInfo(value: PlayTimeInfo[]) {
    this.logger.debug('IndividualStatsComponent.selectedPlayerPlayTimeInfo', value);
    if (value && value.length > 0) {
      this._selectedPlayerPlayTimeInfo = value;
    }
  }

  get individualStatsModel1(): IndividualStatsModel1[] {
    if (this._selectedPlayer) {
      return this._individualStatsModel1.filter(plm => plm.playerId === this._selectedPlayer);
    }
    return [];
  }

  @Input()
  set individualStatsModel1(value: IndividualStatsModel1[]) {
    if (value && value.length > 0) {
      this._individualStatsModel1 = value;
    }
  }

  get individualStatsKpiModel(): IndividualStatsKpiModel[] {
    if (this._selectedPlayer) {
      if (this.core.gameService.gameMode$.value === 'COMPLETE_MODE') {
        return this._individualStatsKpiModeList.filter(plm => plm.playerId === this._selectedPlayer)[0].kpiModel;
      } else {
        return this._individualStatsKpiModeList
            .filter(plm => plm.playerId === this._selectedPlayer)[0].kpiModel
            .filter(plm =>
                plm.kpiName === 'Possession Events'
                || plm.kpiName === 'Player Score'
            );
      }
    }
    return [];
  }

  @Input()
  set individualStatsKpiModeList(value: IndividualStatsKpiModeList[]) {
    if (value && value.length > 0) {
      this._individualStatsKpiModeList = value;
    }
  }


  get individualStatsEventModel(): IndividualStatsEventModel[] {
    if (this._selectedPlayer) {
      return this._individualStatsEventModelList.filter(plm => plm.playerId === this._selectedPlayer)[0].eventModel;
    }
    return [];
  }

  @Input()
  set individualStatsEventModelList(value: IndividualStatsEventModelList[]) {
    if (value && value.length > 0) {
      this._individualStatsEventModelList = value;
    }
  }


  get individualStatsGoalsMode(): IndividualStatsGoalModel[] {
    if (this._selectedPlayer) {
      return this._individualStatsGoalsModelList.filter(plm => plm.playerId === this._selectedPlayer)[0].goalModel;
    }
    return [];
  }

  @Input()
  set individualStatsGoalsModelList(value: IndividualStatsGoalModelList[]) {
    if (value && value.length > 0) {
      this._individualStatsGoalsModelList = value;
    }
  }

  public onPlayerSelectChange(): void {
    this.logger.debug('IndividualStatsComponent.onPlayerSelectChange', this._selectedPlayer);

    if (this._selectedPlayer) {
      this._playerSelected$.emit(this._selectedPlayer);
    } else {
      this._playerSelected$.emit(null);
    }
  }

  public transformTimeFormat(dateTime: DateTime): Date {
    return dateTime.toJSDate();
  }

  public formatPlaytime(playtime: number): string {
    return formatTimeToHoursMinutesAndSeconds(playtime);
  }

}

