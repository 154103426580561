import { Injectable } from '@angular/core';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import {
    EventCountersModel,
    IndividualStatsEventModelList, IndividualStatsEventModelTable, IndividualStatsGoalModelList,
    IndividualStatsKpiModeList,
    IndividualStatsModel1,
    SimplifiedPlayerStats,
    TeamOverviewStatsModel,
    TeamOverviewViewCounterModel,
    TeamOverviewConsumerPossInterface,
    TeamOverviewConsumerSuspensionInterface
} from '@handballai/stats-calculation';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { PhasesActionTypes, ExecutionPositionTypes } from 'src/app/shared-services/actions/action-types';
import { CoreService } from 'src/app/shared-services/core.service';
import { TeamOverviewSubConsumerCore } from '@handballai/stats-calculation';
import { TeamPlayerScoreSubConsumerCore, TeamPlayerScoreViewCounterModel } from '@handballai/playerscore-calculation';
import { PlayerStatsDtoPart, transformToPlayerStatisticsDto } from 'src/app/shared-services/statistics/playbyplay/sub-consumer/team-overview/team-overview-sub-consumer.helper';
import { HalftimeTypesExtended } from '../../../video/model/video-tracker.util';

@Injectable({
    providedIn: 'root'
})
export class TeamOverviewSubConsumerService implements TeamOverviewConsumerPossInterface {

    constructor(
        private readonly logger: NGXLogger,
    ) { }

    get homeTeamOverviewModel$(): BehaviorSubject<TeamOverviewStatsModel[]> {
        return this._homeTeamOverviewModel$;
    }

    get visitorTeamOverviewModel$(): BehaviorSubject<TeamOverviewStatsModel[]> {
        return this._visitorTeamOverviewModel$;
    }

    get homeTeamEventCounters$(): BehaviorSubject<EventCountersModel> {
        return this._homeTeamEventCounters$;
    }

    get visitorTeamEventCounters$(): BehaviorSubject<EventCountersModel> {
        return this._visitorTeamEventCounters$;
    }

    get individualStatsModel1$(): BehaviorSubject<IndividualStatsModel1[]> {
        return this._individualStatsModel1$;
    }

    get individualStatsKpiModelList$(): BehaviorSubject<IndividualStatsKpiModeList[]> {
        return this._individualStatsKpiModelList$;
    }

    get individualStatsEventsModelList$(): BehaviorSubject<IndividualStatsEventModelList[]> {
        return this._individualStatsEventsModelList$;
    }

    get individualStatsGoalsModelList$(): BehaviorSubject<IndividualStatsGoalModelList[]> {
        return this._individualStatsGoalsModelList$;
    }
    get homeIndividualStatsEventModelList$(): BehaviorSubject<IndividualStatsEventModelTable[]> {
        return this._homeIndividualStatsEventModelList$;
    }
    get visitorIndividualStatsEventModelList$(): BehaviorSubject<IndividualStatsEventModelTable[]> {
        return this._visitorIndividualStatsEventModelList$;
    }
    get homeIndividualSimplifiedStatsModel$(): BehaviorSubject<SimplifiedPlayerStats[]>{
        return this._homeIndividualSimplifiedStatsModel$;
    }
    get visitorIndividualSimplifiedStatsModel$(): BehaviorSubject<SimplifiedPlayerStats[]>{
        return this._visitorIndividualSimplifiedStatsModel$;
    }

    private _core: CoreService;

    private _homeTeamOverviewModel$ = new BehaviorSubject<TeamOverviewStatsModel[]>([]);
    private _visitorTeamOverviewModel$ = new BehaviorSubject<TeamOverviewStatsModel[]>([]);
    private _individualStatsModel1$ = new BehaviorSubject<IndividualStatsModel1[]>([]);
    private _individualStatsKpiModelList$ = new BehaviorSubject<IndividualStatsKpiModeList[]>([]);
    private _individualStatsEventsModelList$ = new BehaviorSubject<IndividualStatsEventModelList[]>([]);
    private _individualStatsGoalsModelList$ = new BehaviorSubject<IndividualStatsGoalModelList[]>([]);
    private _homeIndividualStatsEventModelList$ = new BehaviorSubject<IndividualStatsEventModelTable[]>([]);
    private _visitorIndividualStatsEventModelList$ = new BehaviorSubject<IndividualStatsEventModelTable[]>([]);
    private _homeTeamEventCounters$ = new BehaviorSubject<EventCountersModel>(null);
    private _visitorTeamEventCounters$ = new BehaviorSubject<EventCountersModel>(null);
    private _homeIndividualSimplifiedStatsModel$ = new BehaviorSubject<SimplifiedPlayerStats[]>([]);
    private _visitorIndividualSimplifiedStatsModel$ = new BehaviorSubject<SimplifiedPlayerStats[]>([]);
    private _teamOverviewConsumerCore: TeamOverviewSubConsumerCore;
    private _teamPlayerScoreConsumerCore: TeamPlayerScoreSubConsumerCore;

    initCore(core: CoreService) {
        this._core = core;
        this.subscribeToPlaytimeService();
        this.subscribeToEventProcessorFireEvents();
    }

    public init(): void {
        this._teamOverviewConsumerCore = new TeamOverviewSubConsumerCore(
            this._core.gameService.gameModel.home.name,
            this._core.gameService.gameModel.visitor.name,
            this._core.gameService.gameModel.home.id,
            this._core.gameService.gameModel.visitor.id,
            [...this._core.gameService.gameModel.home.currentField, ...this._core.gameService.gameModel.home?.currentBench],
            [...this._core.gameService.gameModel.visitor.currentField, ...this._core.gameService.gameModel.visitor?.currentBench],
            this._core.playByPlayProducerService,
            'TeamOverviewSubConsumerService'
        );
        this._teamPlayerScoreConsumerCore = new TeamPlayerScoreSubConsumerCore(
            this._core.gameService.gameModel.home.name,
            this._core.gameService.gameModel.visitor.name,
            this._core.gameService.gameModel.home.id,
            this._core.gameService.gameModel.visitor.id,
            [...this._core.gameService.gameModel.home.currentField, ...this._core.gameService.gameModel.home?.currentBench],
            [...this._core.gameService.gameModel.visitor.currentField, ...this._core.gameService.gameModel.visitor?.currentBench],
            this._core.playByPlayProducerService,
            'TeamOverviewSubConsumerService'
        );
        this.resetSubjects();
        this.fireSubjects();
    }

    //#region Event Subscriptions
    public addPossessions(
        teamMarker: TeamMarker,
        playerId: number,
        phase: PhasesActionTypes,
        offenseSystem: string
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addPossessions', playerId);
        this._teamOverviewConsumerCore.addPossessions(teamMarker, playerId, phase, offenseSystem);
        this.fireSubjects();
    }

    public addDefPossessions(
        teamMarker: TeamMarker,
        playerId: number,
        phase: PhasesActionTypes,
        offenseSystem: string
    ): void {
        this.logger.debug('TeamOverviewSubConsumerService.addDefPossessions', playerId);
        this._teamOverviewConsumerCore.addDefPossessions(teamMarker, playerId, phase, offenseSystem);
        this.fireSubjects();
    }
    //#endregion

    private resetSubjects(): void {
        this._homeTeamOverviewModel$.next([]);
        this._visitorTeamOverviewModel$.next([]);
        this._individualStatsModel1$.next([]);
        this._individualStatsKpiModelList$.next([]);
        this._individualStatsEventsModelList$.next([]);
        this._individualStatsGoalsModelList$.next([]);
    }

    public fireSubjects(): void {
        const home = this._teamOverviewConsumerCore.teamOverviewModelMap.get('HOME');
        const visitor = this._teamOverviewConsumerCore.teamOverviewModelMap.get('VISITOR');
        this._homeTeamOverviewModel$.next(
            [...Array.from(home.values()).map(counter => counter.transformToViewModel(
                this._teamOverviewConsumerCore.overallGoals.get('VISITOR').counter,
                this._core.playTimeService.getPlayerPlayTimeInfo(counter.player.id).playTimeSec,
                this._teamPlayerScoreConsumerCore.teamOverviewModelMap.get('HOME').get(counter.player.id).transformToViewModel()
            ))]
        );
        this._visitorTeamOverviewModel$.next(
            [...Array.from(visitor.values()).map(counter => counter.transformToViewModel(
                this._teamOverviewConsumerCore.overallGoals.get('HOME').counter,
                this._core.playTimeService.getPlayerPlayTimeInfo(counter.player.id).playTimeSec,
                this._teamPlayerScoreConsumerCore.teamOverviewModelMap.get('VISITOR').get(counter.player.id).transformToViewModel()
            ))]
        );
        this._homeIndividualSimplifiedStatsModel$.next(
            [...Array.from(home.values()).map(counter => counter
                .transformToViewModelSimplifiedPlayerStats(this._teamOverviewConsumerCore.overallGoals.get('VISITOR').counter))]
        );
        this._visitorIndividualSimplifiedStatsModel$.next(
            [...Array.from(visitor.values()).map(counter => counter
                .transformToViewModelSimplifiedPlayerStats(this._teamOverviewConsumerCore.overallGoals.get('HOME').counter))]
        );
        this._individualStatsModel1$.next([
            ...Array.from(home.values()).map(counter => counter.transformToIndividualStatsModel1()),
            ...Array.from(visitor.values()).map(counter => counter.transformToIndividualStatsModel1())
        ]);
        this._individualStatsKpiModelList$.next([
            ...Array.from(home.values()).map(counter => counter.transformToIndividualStatsKpiModelList(
                this._core.playTimeService.getPlayerPlayTimeInfo(counter.player.id).playTimeSec,
                this._teamPlayerScoreConsumerCore.teamOverviewModelMap.get('HOME').get(counter.player.id).playerScoreViewModel.score
            )),
            ...Array.from(visitor.values()).map(counter => counter.transformToIndividualStatsKpiModelList(
                this._core.playTimeService.getPlayerPlayTimeInfo(counter.player.id).playTimeSec,
                this._teamPlayerScoreConsumerCore.teamOverviewModelMap.get('VISITOR').get(counter.player.id).playerScoreViewModel.score
            ))
        ]);
        this._individualStatsEventsModelList$.next([
            ...Array.from(home.values()).map(counter => counter.transformToIndividualStatsEventModelList()),
            ...Array.from(visitor.values()).map(counter => counter.transformToIndividualStatsEventModelList())
        ]);
        this._individualStatsGoalsModelList$.next([
            ...Array.from(home.values()).map(counter => counter.transformToIndividualStatsGoalModelList()),
            ...Array.from(visitor.values()).map(counter => counter.transformToIndividualStatsGoalModelList())
        ]);
        this._homeIndividualStatsEventModelList$
            .next(Array.from(home.values()).map(counter => counter.transformToIndividualStatsEventModelTable(
                this._core.playTimeService.getPlayerPlayTimeInfo(counter.player.id).playTimeSec,
            )));
        this._visitorIndividualStatsEventModelList$
            .next(Array.from(visitor.values()).map(counter => counter.transformToIndividualStatsEventModelTable(
                this._core.playTimeService.getPlayerPlayTimeInfo(counter.player.id).playTimeSec,
            )));

        const mapFn = (base: EventCountersModel, counters: TeamOverviewViewCounterModel): EventCountersModel => {
            const current = counters.transformEventsCounters();
            const statsModel = counters.transformToIndividualStatsModel1();
            base.totalAttackFaultProvoke += current.totalAttackFaultProvoke;
            base.totalTechnicalMistakeProvoke += current.totalTechnicalMistakeProvoke;
            base.totalLostBallProvoke += current.totalLostBallProvoke;
            base.totalTwoMinCommit += current.totalTwoMinCommit;
            base.totalSevenMetersCommit += current.totalSevenMetersCommit;
            base.totalSevenMSuspCommit += current.totalSevenMSuspCommit;
            base.totalOneAndOneLost += current.totalOneAndOneLost;
            base.totalFoulCommit += current.totalFoulCommit;
            base.totalShotBlock += current.totalShotBlock;
            base.totalAttackFaultCommit += current.totalAttackFaultCommit;
            base.totalTechnicalMistakeCommit += current.totalTechnicalMistakeCommit;
            base.totalLostBall += current.totalLostBall;
            base.totalTwoMinProvoke += current.totalTwoMinProvoke;
            base.totalSevenMetersProvoke += current.totalSevenMetersProvoke;
            base.totalSevenMSuspProvoke += current.totalSevenMSuspProvoke;
            base.totalOneAndOneWon += current.totalOneAndOneWon;
            base.totalFoulReceive += current.totalFoulReceive;
            base.totalShotBlocked += current.totalShotBlocked;
            return base;
        };
        this._homeTeamEventCounters$.next(Array.from(home.values()).reduce(mapFn, {
            totalAttackFaultProvoke: 0,
            totalTechnicalMistakeProvoke: 0,
            totalLostBallProvoke: 0,
            totalTwoMinCommit: 0,
            totalSevenMetersCommit: 0,
            totalSevenMSuspCommit: 0,
            totalOneAndOneLost: 0,
            totalFoulCommit: 0,
            totalShotBlock: 0,
            totalAttackFaultCommit: 0,
            totalTechnicalMistakeCommit: 0,
            totalLostBall: 0,
            totalTwoMinProvoke: 0,
            totalSevenMetersProvoke: 0,
            totalSevenMSuspProvoke: 0,
            totalOneAndOneWon: 0,
            totalFoulReceive: 0,
            totalShotBlocked: 0,
        }));
        this._visitorTeamEventCounters$.next(Array.from(visitor.values()).reduce(mapFn, {
            totalAttackFaultProvoke: 0,
            totalTechnicalMistakeProvoke: 0,
            totalLostBallProvoke: 0,
            totalTwoMinCommit: 0,
            totalSevenMetersCommit: 0,
            totalSevenMSuspCommit: 0,
            totalOneAndOneLost: 0,
            totalFoulCommit: 0,
            totalShotBlock: 0,
            totalAttackFaultCommit: 0,
            totalTechnicalMistakeCommit: 0,
            totalLostBall: 0,
            totalTwoMinProvoke: 0,
            totalSevenMetersProvoke: 0,
            totalSevenMSuspProvoke: 0,
            totalOneAndOneWon: 0,
            totalFoulReceive: 0,
            totalShotBlocked: 0,
        }));
        this.logger.debug(
            'TeamOverviewSubConsumerService.fireSubjects',
            this._homeTeamOverviewModel$.value,
            this._visitorTeamOverviewModel$.value
        );

    }

    public getPlayerStatisticsDto(): Map<number, PlayerStatsDtoPart> {
        const home = this._teamOverviewConsumerCore.teamOverviewModelMap.get('HOME');
        const visitor = this._teamOverviewConsumerCore.teamOverviewModelMap.get('VISITOR');

        return [
            ...Array.from(home.entries()).map(itm => [
                itm[0],
                transformToPlayerStatisticsDto(
                    itm[1],
                    this._teamOverviewConsumerCore.overallGoals.get('VISITOR').counter,
                    this._core.playTimeService.getPlayerPlayTimeInfo(itm[1].player.id).playTimeSec,
                    this._teamPlayerScoreConsumerCore.teamOverviewModelMap.get('HOME').get(itm[1].player.id).playerScoreViewModel
                )
            ]),
            ...Array.from(visitor.entries()).map(itm => [
                itm[0],
                transformToPlayerStatisticsDto(
                    itm[1],
                    this._teamOverviewConsumerCore.overallGoals.get('HOME').counter,
                    this._core.playTimeService.getPlayerPlayTimeInfo(itm[1].player.id).playTimeSec,
                    this._teamPlayerScoreConsumerCore.teamOverviewModelMap.get('VISITOR').get(itm[1].player.id).playerScoreViewModel
                )
            ])
        ].reduce((acc, current) => {
            acc.set(current[0] as number, current[1] as PlayerStatsDtoPart);
            return acc;
        }, new Map<number, PlayerStatsDtoPart>());
    }

    public getPlayerScoreStatistics(): any {
        return this._teamPlayerScoreConsumerCore.getPlayerScoreStatistics();
    }

    private subscribeToPlaytimeService(): void {
        this._core.playTimeService.registerForPlayTimeEvents(() => {
            this.fireSubjects();
        });
    }
    private subscribeToEventProcessorFireEvents() {
        this._core.playByPlayProducerService.onChangedContext(['TeamOverviewSubConsumerService'], () => {
            this.fireSubjects();
        });
    }
}
