import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, throwError, from } from 'rxjs';
import { CommonUserModel } from '../model/common-user.model';
import { CreateUserDto, EmailDto, LoginDto, PlanPermsDto, RegistrationService, ResetPasswordDto, TokenDto, UpdateUserDto } from '../../api/hai-api';
import { NGXLogger } from 'ngx-logger';
import { AlertController, ToastController } from '@ionic/angular';
import { handleErrorFn, loggerFn, toastrSuccessFn } from '../helper/http-helper';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AccessToken } from '../model/token.model';
import { CoreService } from '../core.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nService } from 'src/app/i18n/i18n.service';
import * as Sentry from '@sentry/browser';
import { ConfirmModalComponent } from '../../main/pages/aehandler-module/pages/game-module/components/confirm-modal/confirm-modal';
import { LoginWithRefresherDto } from '../../api/hai-players-api';

export const GrantsOptions = [
    'create_game',
    'view_game',
    'manage_teams',
    'track_game',
    'dashboard',
    'scouting',
  ] as const;
export type GrantsType = typeof GrantsOptions[number];

@Injectable({
    providedIn: 'root'
})
export class UserService {

  tg = (def: string, skipLangs: string[] = []) => {
    return this.i18nService.tg(def, skipLangs);
  }

  constructor(
        private readonly router: Router,
        private readonly toastCntl: ToastController,
        private readonly alertCntl: AlertController,
        private readonly logger: NGXLogger,
        private readonly registrationService: RegistrationService,
        public readonly i18nService: I18nService,
    ) {
    }

    get user$(): BehaviorSubject<CommonUserModel> {
        return this._user$;
    }

    get loginSuccess$(): BehaviorSubject<boolean> {
        return this._loginSuccess$;
    }

    get isAvailable$(): BehaviorSubject<boolean> {
        return this._isAvailable$;
    }

    get isSuperAdmin$(): BehaviorSubject<boolean> {
        return this._isSuperAdmin$;
    }

    get users$(): BehaviorSubject<UpdateUserDto[]> {
        return this._users$;
    }


    private _core: CoreService;

    private _user$ = new BehaviorSubject<CommonUserModel>(null);
    private _loginSuccess$ = new BehaviorSubject<boolean>(false);
    private _isAvailable$ = new BehaviorSubject<boolean>(false);
    private _users$ = new BehaviorSubject<UpdateUserDto[]>([]);
    private _isSuperAdmin$ = new BehaviorSubject<boolean>(false);

    initCore(core: CoreService) {
        this._core = core;
    }

    public async validateAccount(verificationCode: string): Promise<void> {
        this._core.loadingService.present();
        await firstValueFrom(this.registrationService.registrationControllerVerifyLogin({
            verificationCode
        }).pipe(
            loggerFn(this.logger, 'UserDataService.validateAccount'),
            toastrSuccessFn(this.toastCntl, 'Registration', 'Account validated'),
            tap(async () => {
                await this._core.loadingService.dismiss();
            }),
            catchError(handleErrorFn(
                this.toastCntl,
                this._core.loadingService,
                'Error! Account cannot be validated at this moment!'
            ))
        ));
    }

    public requestPasswordReset(email: string): void {
        this._core.loadingService.present();
        this.registrationService.registrationControllerResetRequestPassword(
            'handball.ai',
            { email } as EmailDto
        ).pipe(
            loggerFn(this.logger, 'UserDataService.requestPasswordReset'),
            toastrSuccessFn(this.toastCntl, 'Registration', 'Reset link sent'),
            tap(async () => {
                await this._core.loadingService.dismiss();
            }),
            catchError(handleErrorFn(
                this.toastCntl,
                this._core.loadingService,
                'Error! Reset link cannot be sended at this moment!'
            ))
        ).subscribe(async () => {
        }, error => {
        });
    }

    public getAllUsers(): void {
        this._core.loadingService.present();
        this.registrationService.registrationControllerGetAccountEquiposByIds().pipe(
            loggerFn(this.logger, 'UserDataService.getAllUser'),
            tap(async () => {
                await this._core.loadingService.dismiss();
            }),
            catchError(handleErrorFn(
                this.toastCntl,
                this._core.loadingService,
                'Error! Could not retrieve Users!'
            ))
        ).subscribe((users: UpdateUserDto[]) => {
            this.users$.next(users);
        }, error => {
        });
    }

    public async updateUser(user: CommonUserModel): Promise<void> {
        this._core.loadingService.present();
        this.registrationService.registrationControllerUpdateUser(
            user.id.toString(),
            {
                id: user.id,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                street: user.street,
                city: user.city,
                postalCode: user.postalCode,
                country: user.country,
                receiveNewsletter: user.receiveNewsletter,
                lang: user.lang,
                endGameNotifications: user.endGameNotifications,
                imageUrl: user.imageUrl,
                swapFieldHalftime: user.swapFieldHalftime
            } as UpdateUserDto
        ).pipe(
            loggerFn(this.logger, 'UserDataService.updateUser'),
            toastrSuccessFn(this.toastCntl, 'Registration', 'User updated'),
            map(user => ({
                id: user.id,
                email: user.email,
                city: user.city,
                country: user.country,
                postalCode: user.postalCode,
                street: user.street,
                receiveNewsletter: user.receiveNewsletter,
                firstName: user.firstName,
                lastName: user.lastName,
                lang: user.lang,
                numExpiredAccounts: user.numExpiredAccounts,
                endGameNotifications: user.endGameNotifications,
                imageUrl: user.imageUrl,
                swapFieldHalftime: user.swapFieldHalftime
            } as CommonUserModel)),
            loggerFn(this.logger, 'UserDataService.updateUser after transformation'),
            tap(async () => {
                await this._core.loadingService.dismiss();
            }),
            tap(async (us: CommonUserModel) => {
                await this._core.storageService.saveUser(us);
            }),
            catchError(handleErrorFn(
                this.toastCntl,
                this._core.loadingService,
                'Error! User cannot be updated at this moment!'
            ))
        ).subscribe((user: CommonUserModel) => {
            this.user$.next(user);
        }, error => {
        });
    }

    public async deleteUser(id: string): Promise<void> {
        console.warn('yiiii')
        this._core.loadingService.present();
        this.registrationService.registrationControllerDeleteUser(id)
        .pipe(
            loggerFn(this.logger, 'UserDataService.blockUser'),
            toastrSuccessFn(this.toastCntl, 'Registration', 'User deleted'),
            tap(async () => {
                await this._core.loadingService.dismiss();
                await this.logout();
                // this._user = null;
                this.router.navigate(['/']);
            }),
            catchError(handleErrorFn(
                this.toastCntl,
                this._core.loadingService,
                'Error! User cannot be blocked at this moment!'
            ))
        ).subscribe(async () => {
        }, error => {
        });
    }

    public async blockUser(id: string): Promise<void> {
        this._core.loadingService.present();
        this.registrationService.registrationControllerBlockUser(id)
        .pipe(
            loggerFn(this.logger, 'UserDataService.blockUser'),
            toastrSuccessFn(this.toastCntl, 'Administration', 'User blocked'),
            tap(async () => {
                await this._core.loadingService.dismiss();
            }),
            catchError(handleErrorFn(
                this.toastCntl,
                this._core.loadingService,
                'Error! User cannot be blocked at this moment!'
            ))
        ).subscribe(async () => {
        }, error => {
        });
    }

    public changePassword(reset: ResetPasswordDto, cbOk: Function = null): void {
        this._core.loadingService.present();
        this.registrationService.registrationControllerResetPassword(reset).pipe(
            loggerFn(this.logger, 'UserDataService.changePassword'),
            toastrSuccessFn(this.toastCntl, 'Registration', 'Password has been changed, you can now login with your new password'),
            tap(async () => {
                await this._core.loadingService.dismiss();
            }),
            catchError(handleErrorFn(
                this.toastCntl,
                this._core.loadingService,
                'Password has not been changed!'
            ))
        ).subscribe(async () => cbOk?.(), error => {
        });
    }

    public registerUser(user: CommonUserModel): void {
        this._core.loadingService.present();
        this.registrationService.registrationControllerRegisterUser(
            'handball.ai',
            {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                city: user.city,
                country: user.country,
                password: user.password,
                postalCode: user.postalCode,
                receiveNewsletter: user.receiveNewsletter,
                street: user.street,
                lang: user.lang,
                endGameNotifications: user.endGameNotifications,
                imageUrl: user.imageUrl,
                swapFieldHalftime: user.swapFieldHalftime
            } as CreateUserDto).pipe(
            loggerFn(this.logger, 'UserDataService.registerUser'),
            toastrSuccessFn(this.toastCntl, 'Registration', 'Registration was successful'),
            tap(async () => {
                await this._core.loadingService.dismiss();
            }),
            catchError(err => {
              handleErrorFn(
                  this.toastCntl,
                  this._core.loadingService,
                  'User could not be registered!'
              );
              return throwError(err);
            })
        ).subscribe(async (token: TokenDto) => {
            await this._core.storageService.saveToken(token);
            this._loginSuccess$.next(true);
            this._core.loadingService.dismiss();
        }, async error => {
          this.logger.error(error);
          const toast = await this.toastCntl
              .create({
                icon: 'close-outline',
                color: 'danger',
                message: `User could not be registered!`,
                duration: 10000
              });
          await toast.present();
          this._loginSuccess$.next(false);
          this._core.loadingService.dismiss();
        });
    }

    public resetLoginState(): void {
        this.loginSuccess$.next(false);
    }

    public async login(loginDto: LoginDto): Promise<void> {
        await this._core.loadingService.present();
        this.registrationService
          .registrationControllerLogin("handball.ai", loginDto)
          .pipe(
            loggerFn(this.logger, "UserDataService.login"),
            catchError((error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 400) {
                  this.toastCntl
                    .create({
                      icon: "close-outline",
                      color: "danger",
                      message: `Wrong User or Password!`,
                      duration: 10000,
                    })
                    .then((value) => value.present());
                }
              } else {
                this.toastCntl
                  .create({
                    icon: "close-outline",
                    color: "danger",
                    message: `Unknown Login Error!`,
                    duration: 10000,
                  })
                  .then((value) => value.present());
              }
              return throwError(error);
            }),
            finalize(async () => await this._core.loadingService.dismiss())
          )
          .subscribe({
            next: async (token: TokenDto) => {
              await this.loginByToken(token, true);
            },
            error: async (error) => {
              await this._core.loadingService.dismiss();
              this.logger.error("Error Message in login: ", error);
              this._loginSuccess$.next(false);
            },
          });
    }

    public async loginByToken(token: TokenDto, fromLogin= false) {
        this.logger.debug('Success in login subscribe');
        await this._core.storageService.saveToken(token);
        const accessToken = await this._core.storageService.getAccessTokenAsObject();
        const superAdminAccessToken = await this._core.storageService.getSuperAdminAccessTokenAsPromise();
        if (accessToken.roles.includes('restricted') && !superAdminAccessToken) {
            this.toastCntl.create({
                icon: 'close-outline',
                color: 'danger',
                message: `You need to validate your account before login!`,
                duration: 10000
            }).then(t => t.present());
            this.logout();
        } else {
            await this.userInitialization(accessToken, null, fromLogin);
            if ((this.user$.getValue().firstName.trim()) == '') {
              ConfirmModalComponent.Open(this._core.popoverCtrl, {
                title: await this.tg(_(`Your profile is empty. Edit it now?`)),
                noAndYesButtons: true
              }, async (d) => {
                if (d.data) {
                  this.router.navigateByUrl('/user/profile?edit=true');
                }
              });
            }
        }
    }

    public async logout(): Promise<void> {
        this._user$.next(null);
        await this._core.storageService.wipeOutTokens();
    }

    userInitialization(jwtVal: AccessToken, accountEquipoId= null, fromLogin= false) {
        return new Promise<void>((ok, ko) => {
            if (jwtVal) {
                this.findUserByIdSilent(jwtVal.sub, true, undefined, fromLogin).then(async () => {
                    console.log('userInitialization', jwtVal);
                    Sentry.setTag('userId', jwtVal.sub);
                    let aeId = accountEquipoId ?? (await this._core.storageService.getSelectedAccountEquipoIdAsPromise());
                    if (!aeId || !jwtVal.accountEquipos.map(ae => +ae.split(':')[0]).includes(aeId)) {
                        if (jwtVal.accountEquipos.length) {
                            aeId = +jwtVal.accountEquipos[0].split(':')[0];
                            this.logger.error('Disallowed account equipo id', accountEquipoId);
                        } else {
                            aeId = null;
                        }
                    } else {
                        this.logger.info('New account-equipo id', accountEquipoId);
                    }
                    await this._core.accountEquipoService.getAccountEquipos();
                    if (aeId) {
                        Sentry.setTag('accountEquipoId', aeId);
                        await this._core.seasonService.getSeasons(aeId);
                        await this._core.storageService.saveSelectedAccountEquipoId(aeId);
                        await this._core.accountEquipoService.fireAccountEquipoChange(aeId);
                    }
                    this._core.houseKeepingService.checkForPendingUploads();

                    if (aeId) {
                        await this._core.teamService.initializeModelFromRemote(jwtVal.sub);
                        if (fromLogin) {
                            this._core.navigateToUserAllowedPlace();
                        }
                    } else {
                        await this.router.navigate(['/organization']);
                    }

                    if (jwtVal.superAdmin) {
                        this._isSuperAdmin$.next(true);
                    } else {
                      this._isSuperAdmin$.next(false);
                    }
                    const superAdminAccessToken = await this._core.storageService.getSuperAdminAccessTokenAsPromise();
                    const superAdminRefreshToken = await this._core.storageService.getSuperAdminRefresherTokenAsPromise();
                    if (superAdminAccessToken && superAdminRefreshToken) {
                      const tokenDto: TokenDto = {
                        accessToken: superAdminAccessToken,
                        refresherToken: superAdminRefreshToken
                      };
                      await this._core.storageService.saveSuperAdminToken(tokenDto);
                    }
                    this._loginSuccess$.next(true);
                    ok();

            }).catch(async err => {
              this.logger.warn('UserService.userInitialization: Cannot initialize user', err);
              const refreshToken = await this._core.storageService.getRefresherTokenAsObject();
              if (!refreshToken) {
                this.logout();
              }
              ko();
            });
          } else {
            ok();
          }
        });
    }

    async setAccountEquipo(id: number|string) {
        try {
            id = parseInt(`${id}`);
        } catch (er) {
            this.logger.error('Cannot change account equipo to non-numeric value', id);
        }
        if (this._core.handballTimerService.gameEnded$.getValue()) {
            this.logger.log('Changing account equipo to', id);
            if (this.router.url.startsWith('/account/')) {
                const currAccId = this.router.url.replace('/account/', '').split('/')[0];
                if (currAccId != id) {
                    const newUrl = this.router.url.replace('/account/'+currAccId, '/account/'+id);
                    console.log('Url for account mismatch, navigating to correct one. Old['+this.router.url+'], New['+newUrl+']')
                    this.router.navigateByUrl(newUrl);
                    return; // Will perform after navigate through account equipo handler component call
                }
            }
            this.userInitialization(await this._core.storageService.getAccessTokenAsObject(), id);
        } else {
            this.logger.log('Prevented account equipo change because game is not ended');
        }
    }

    findUserByIdSilent(userId: number, silentError=false, dismissResult=false, presentLoading=true): Promise<CommonUserModel> {
        return new Promise<CommonUserModel>(async (ok, ko) => {
            if (presentLoading) await this._core.loadingService.present();
            this.registrationService.registrationControllerFindUserById(userId.toString())
                .pipe(
                    loggerFn(this.logger, 'UserDataService.findUserByIdSilent before transformation'),
                    map(user => ({
                        id: user.id,
                        email: user.email,
                        city: user.city,
                        country: user.country,
                        postalCode: user.postalCode,
                        street: user.street,
                        receiveNewsletter: user.receiveNewsletter,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        lang: user.lang,
                        numExpiredAccounts: user.numExpiredAccounts,
                        endGameNotifications: user.endGameNotifications,
                        imageUrl: user.imageUrl,
                        swapFieldHalftime: user.swapFieldHalftime
                    } as CommonUserModel)),
                    loggerFn(this.logger, 'UserDataService.findUserByIdSilent after transformation'),
                    tap(async () => {
                        await this._core.loadingService.dismiss();
                    }),
                    tap(async (us: CommonUserModel) => {
                        if(!dismissResult){
                            await this._core.storageService.saveUser(us);
                        }
                    }),
                    catchError(handleErrorFn(
                        this.toastCntl,
                        this._core.loadingService,
                        'Error while reading user data!',
                        true,
                        silentError
                    ))
                )
                .subscribe(async (u: CommonUserModel) => {
                    if(!dismissResult){
                        this._user$.next(u);
                    }

                    if (u instanceof Observable) {
                        ko(u);
                    } else {
                        ok(u);
                    }
                });
        });
    }

    async refreshToken(): Promise<TokenDto> {
      const refresherToken = await this._core.storageService.getRefresherTokenAsPromise();
      const refresherDto: LoginWithRefresherDto = {
        refresherToken
      };
      return new Promise<TokenDto>(async (ok, ko) => {
        this.registrationService.registrationControllerLoginWithRefresher(refresherDto)
            .pipe(
                map(token => token),
                catchError(handleErrorFn(
                    this.toastCntl,
                    this._core.loadingService,
                    'Error while refreshing token!',
                    true,
                ))
            )
            .subscribe(async (token: TokenDto) => {
              await this._core.storageService.saveToken(token);
              const jwt = await this._core.storageService.getAccessTokenAsObject();
              await this.userInitialization(jwt);
              if (token instanceof Observable) {
                ko(token);
              } else {
                ok(token);
              }
            });
      });
    }

    public checkSwapFieldHalftime(): boolean {
        return this.user$.value?.swapFieldHalftime;
    }

    checkForCountablePermission(permKey: keyof PlanPermsDto, counter: number, preventNavigate = false, context = null) {
        const ctx = context ?? this._core.accountEquipoService.selectedAccountEquipo$.value?.account?.permissions;
        const permCounter = ctx[permKey];
        const hasPerm = permCounter == -1 || permCounter > counter;
        if (!hasPerm) {
            this.handleNoPermission(permKey, preventNavigate);
        }
        return hasPerm;
    }
    checkForBoolPermission(permKey: keyof PlanPermsDto, preventNavigate = false, handleNoPermission = true, context: PlanPermsDto = null) {
        const ctx = context ?? this._core.accountEquipoService.selectedAccountEquipo$.value?.account?.permissions ?? {};
        const hasPerm = ctx[permKey];
        if (!hasPerm && handleNoPermission) {
            this.handleNoPermission(permKey, preventNavigate);
        }
        return hasPerm;
    }
    checkForGrant(grant: GrantsType, preventNavigate = false, handleNoPermission = true) {
        const hasGrant = this._core.accountEquipoService.selectedAccountEquipo$.value?.grants.includes(grant);
        if (!hasGrant && handleNoPermission) {
            this.handleNoPermission(grant, preventNavigate, true);
        }
        return hasGrant;
    }

    checkForXpsKey(): boolean {
        return !!this._core.accountEquipoService.selectedAccountEquipo$.value.xpsToken;
    }

    handleNoPermission(permKey: string, preventNavigate = false, isGrant= false) {
        this.logger.info('Plan does not have ' + ((isGrant) ? 'grant' : 'sufficient permission for'), permKey);
        console.trace('Plan does not have ' + ((isGrant) ? 'grant' : 'sufficient permission for'), permKey);
        this.toastCntl.create({
            color: 'danger',
            message: 'Plan does not have ' + ((isGrant) ? 'grant ' : 'sufficient permission for ') + permKey,
            duration: 6000
        }).then(t => t.present());
        if (!preventNavigate) { this._core.navigateToUserAllowedPlace(); }
    }

}
