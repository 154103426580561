import { Component, Input, OnInit } from '@angular/core';
import { PlayByPlayModel, TeamStatsPlayerModel } from '@handballai/stats-calculation';
import { DateTime } from 'luxon';
import { GameDataService } from 'src/app/shared-services/game-data/game-data.service';
import { AdvancedStatPlayerModel } from '@handballai/stats-calculation';
import { NGXLogger } from 'ngx-logger';
import { CoreService } from 'src/app/shared-services/core.service';
import { getBackNumbersForTeam, transformGameSystem } from 'src/app/shared-services/helper/game-system.helper';
import { ModalController, PopoverController } from '@ionic/angular';
import { PlayByPlayEditComponent } from '../play-by-play-edit-popover/play-by-play-edit.component';
import { VideoPlayByPlay } from '../../../shared-services/statistics/video/model/video-tracker.util';
import { VideoTrackerService } from '../../../shared-services/statistics/video/video-tracker.service';

@Component({
  selector: 'app-play-by-play-grid',
  templateUrl: './play-by-play-grid.component.html',
  styleUrls: ['./play-by-play-grid.component.scss']
})
export class PlayByPlayGridComponent implements OnInit {

  private _gameId: number;
  private _editable: boolean;
  private _playByPlayRecords: PlayByPlayModel[];
  private _players: AdvancedStatPlayerModel[] = [];
  private _columns = [
    { prop: 'teamMarker' },
    { prop: 'teamName' },
    { prop: 'event' },
    { prop: 'eventTime' },
    { prop: 'offensePlayer' },
    { prop: 'defensePlayer' },
    { prop: 'assistantPlayer' },
    { prop: 'executionPosition' },
    { prop: 'shotLocation' },
    { prop: 'possessions' },
    { prop: 'phase' },
    { prop: 'defenseSystem' },
    { prop: 'offenseSystem' },
    { prop: 'offense' },
    { prop: 'defense' },
    { prop: 'setup' },
    { prop: 'actions' },
    { prop: 'important' },
    { prop: 'gameSystem'}
  ];
  public allTableColsShown = false;
  private _modalTable: boolean = false;

  public getRowClass = (row: any) => {
    return row.row.teamMarker === 'HOME' ? {
      'home-row-color': true
    } : {
      'visitor-row-color': true
    };
  }

  constructor(
      public readonly core: CoreService,
      private readonly gameDataService: GameDataService,
      private readonly logger: NGXLogger,
      private readonly modalController: ModalController,
      private readonly videoTrackerService: VideoTrackerService,
      private readonly popoverController: PopoverController,
  ) { }

  ngOnInit(): void {
  }

  get players(): AdvancedStatPlayerModel[] {
    return this._players;
  }

  public async onExpandTableColumnsClick() {
    this.allTableColsShown = !this.allTableColsShown;
  }

  @Input()
  set players(value: AdvancedStatPlayerModel[]) {
    if (value) {
      this._players = value;
    }
  }

  @Input()
  set gameId(value: number) {
    this._gameId = value;
  }

  @Input()
  set editable(value: boolean) {
    this._editable = value;
  }

  get editable(): boolean {
    return this._editable;
  }

  @Input()
  set playByPlayRecords(value: PlayByPlayModel[]) {
    this.logger.debug('PlayByPlayGridComponent.playByPlayRecords: ', value);
    this._playByPlayRecords = value;
  }

  get playByPlayRecords(): PlayByPlayModel[] {
    return this._playByPlayRecords;
  }

  @Input()
  set modalTable(value: boolean) {
    this._modalTable = value;
  }

  get modalTable(): boolean {
    return this._modalTable;
  }

  get columns() {
    return this._columns;
  }

  public async onPBPSelectChange(row: PlayByPlayModel) {
    this.logger.debug('PlayByPlayGridComponent.onPBPSelectChange: ', row);
    const popover = this.modalController.create({
      component: PlayByPlayEditComponent,
      backdropDismiss: false,
      cssClass: 'modal-edit-event',
      componentProps: {
        pbpEntry: row,
        readonlyFields: ['offense', 'defense']
      },
    });
    popover.then((modal: HTMLIonModalElement) => {
      modal.onDidDismiss().then(event => {
        if (event.data) {
          if (this._modalTable) {
            this.core.videoTrackerService.updatePlayByPlayEvent(event.data);
          } else {
            this.onPBPUpdate(event.data);
          }
        }
      })
      modal.present();
    });
  }

  public getBackNumbersForTeam(players: TeamStatsPlayerModel[]): string {
    return getBackNumbersForTeam(players);
  }

  public async onPBPDeleteClick(row: PlayByPlayModel) {
    const videoPBP: VideoPlayByPlay = {
      ...row,
      tempOrderId: -1
    };
    await this.core.videoTrackerService.deletePlayByPlayEvent(videoPBP);
    this._playByPlayRecords = this.core.videoTrackerService.videoPlayByPlayEvents$.value;
  }

  public transformTimeFormat(dateTime: DateTime): Date {
    return dateTime.toJSDate();
  }

  public transformGameSystem(serializedGameSystem: string): string {
    return transformGameSystem(serializedGameSystem);
  }

  public async onPBPUpdate(pbp: PlayByPlayModel, close=false) {
    return new Promise<boolean>(async (ok, ko) => {
      if (this._gameId && pbp.id) {
        try {
          await this.core.loadingService.present();
          await this.gameDataService.updatePlayPlayByPlayToGame(this._gameId, [pbp]);
          await this.gameDataService.readGame(this._gameId, null, false, undefined, true);
        } catch (err) {
          console.error(err);
        }
      }
      // TODO: Subscribe to pbp read events and call next line once all is reloaded
      if (this.gameDataService.game$.getValue().gameStatus != 'started' || close) {
        await this.gameDataService.updateGameStatistics();
      }
      await this.core.loadingService.dismiss();
      ok(true);
    });
  }

  removeUnderscore(str: string) {
    return str.replace(/_/g, ' ');
  }

}
