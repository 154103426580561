import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import {
  AccumulatedCounterBasedOnPointInTimeList,
  FirstHalfGoals,
  GoalPossessionOverview,
  SecondHalfGoals, SemaphorModel,
  XGoalTimeSeries
} from '@handballai/stats-calculation';
import { ChartDataSets, ChartOptions, ChartPoint } from 'chart.js';
import { CoreService } from 'src/app/shared-services/core.service';



@Component({
  selector: 'app-timeline-stats',
  templateUrl: './timeline-stats.component.html',
  styleUrls: ['./timeline-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineStatsComponent implements OnInit {

  private _timeLineStatsGoalsFirstHalf: FirstHalfGoals[] = [];
  private _timeLineStatsGoalsSecondHalf: SecondHalfGoals[] = [];
  private _timeLineStatsGoalsPossessionOverview: GoalPossessionOverview[] = [];
  private _timeLineStatsXGoalTimeSeries: XGoalTimeSeries[] = [];
  private _homeGoalGraph: ChartPoint[] = [];
  private _visitorGoalGraph: ChartPoint[] = [];
  private _homeSavesGraph: ChartPoint[] = [];
  private _visitorSavesGraph: ChartPoint[] = [];
  private _homePosessionsGraph: ChartPoint[] = [];
  private _visitorPosessionsGraph: ChartPoint[] = [];
  private _homeLostBallsGraph: ChartPoint[] = [];
  private _visitorLostBallsGraph: ChartPoint[] = [];
  // private _homeXGGraph: ChartPoint[] = [];
  // private _visitorXGGraph: ChartPoint[] = [];
  private _homeTeamLabel = '';
  private _awayTeamLabel = '';
  private _accumulatedCounterListWithTiming: AccumulatedCounterBasedOnPointInTimeList[] = [];
  private _chartData: ChartDataSets[] = [];
  private _semaphoreRows: SemaphorModel[] = [];

  private _chartOptions: ChartOptions = {
    title: {
      display: true,
      text: ['Goals']
    },
    showLines: true,
    responsive: true,
    tooltips: {
      enabled: false,
      backgroundColor: '#0000FF',
      mode: 'index',
      titleAlign: 'center',
      intersect: false,
      titleFontSize: 16,
    },
    hover: {
      mode: null,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMax: 25
          },
          scaleLabel: {
            display: true,
            labelString: 'Goals'
          }
        }
      ],
      xAxes: [
          {
            ticks: {
              beginAtZero: true,

            },
            scaleLabel: {
              display: true,
              labelString: 'Minutes'
            }
          }
      ]
    },
    elements: {
      line: {
        fill: false,
        tension: 0
      },
      point: {
        radius: 5
      }
    }
  };
  private _chartLabels = [];


  // ------
  // TABLES
  // ------

  firstHalfColumns = [
    { prop: 'teamName', name: '', sortable: false, draggable: false, frozenLeft: true, flexGrow: 2 },
    { prop: 'min5GoalCounter', name: '5\'', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'min10GoalCounter', name: '10\'', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'min15GoalCounter', name: '15\'', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'min20GoalCounter', name: '20\'', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'min25GoalCounter', name: '25\'', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'min30GoalCounter', name: '30\'', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'total', name: 'Total First Half', sortable: false, draggable: false, flexGrow: 2 }
  ];

  secondHalfColumns = [
    { prop: 'teamName', name: '', sortable: false, draggable: false, frozenLeft: true, flexGrow: 2 },
    { prop: 'min35GoalCounter', name: '35\'', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'min40GoalCounter', name: '40\'', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'min45GoalCounter', name: '45\'', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'min50GoalCounter', name: '50\'', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'min55GoalCounter', name: '55\'', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'min60GoalCounter', name: '60\'', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'total', name: 'Total Second Half', sortable: false, draggable: false, flexGrow: 2 }
  ];

  totalsColumns = [
    { prop: 'teamName', name: '', sortable: false, frozenLeft: true, flexGrow: 2},
    { prop: 'goals', name: 'Goals', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'possessions', name: 'Possessions', sortable: false, draggable: false, flexGrow: 1 },
  ];

  semaphoreColumns = [
    { prop: 'team', name: '', sortable: false, frozenLeft: true },
    { prop: 'p35', name: '35 %', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'p55', name: '55 %', sortable: false, draggable: false, flexGrow: 1 },
    { prop: 'p85', name: '85 %', sortable: false, draggable: false, flexGrow: 1 },
  ];

  constructor(
      private readonly logger: NGXLogger,
      public core: CoreService
  ) { }

  ngOnInit(): void { }


  get timeLineStatsGoalsFirstHalf(): FirstHalfGoals[] {
    return this._timeLineStatsGoalsFirstHalf;
  }

  @Input()
  set timeLineStatsGoalsFirstHalf(value: FirstHalfGoals[]) {
    this.logger.debug('TimelineStatsComponent.timeLineStatsGoalsFirstHalf', value);
    if (value) {
      this._timeLineStatsGoalsFirstHalf = value;
      if (value.length > 0) {
        this._homeTeamLabel = `${value[0].teamName}`;
        this._awayTeamLabel = `${value[1].teamName}`;
        this.createChartData();
      }
    }
  }

  get timeLineStatsGoalsSecondHalf(): SecondHalfGoals[] {
    return this._timeLineStatsGoalsSecondHalf;
  }

  @Input()
  set timeLineStatsGoalsSecondHalf(value: SecondHalfGoals[]) {
    this.logger.debug('TimelineStatsComponent.timeLineStatsGoalsSecondHalf', value);
    if (value) {
      this._timeLineStatsGoalsSecondHalf = value;
      if (value.length > 0) {
        this._homeTeamLabel = `${value[0].teamName}`;
        this._awayTeamLabel = `${value[1].teamName}`;
        this.createChartData();
      }
    }
  }

  get timeLineStatsGoalsPossessionOverview(): GoalPossessionOverview[] {
    return this._timeLineStatsGoalsPossessionOverview;
  }

  @Input()
  set timeLineStatsGoalsPossessionOverview(value: GoalPossessionOverview[]) {
    this._timeLineStatsGoalsPossessionOverview = value;
    this.logger.debug('TimelineStatsComponent.timeLineStatsGoalsPossessionOverview', value);
  }

  get timeLineStatsXGoalTimeSeries(): XGoalTimeSeries[] {
    return this._timeLineStatsXGoalTimeSeries;
  }

  @Input()
  set timeLineStatsXGoalTimeSeries(value: XGoalTimeSeries[]) {
    this.logger.debug('TimelineStatsComponent.timeLineStatsXGoalTimeSeries', value);
    // if (value) {
    //   this._timeLineStatsXGoalTimeSeries = value;
    //   if (value.length > 1) {
    //     this._homeXGGraph = this._timeLineStatsXGoalTimeSeries[0].xGoalItems.map(xgTimePoint => ({
    //       x: xgTimePoint.minutesSinceStartOfGame,
    //       y: xgTimePoint.xg
    //     }));
    //     this._visitorXGGraph = this._timeLineStatsXGoalTimeSeries[1].xGoalItems.map(xgTimePoint => ({
    //       x: xgTimePoint.minutesSinceStartOfGame,
    //       y: xgTimePoint.xg
    //     }));
    //   }
    //   this.createChartData();
    // }
  }

  @Input()
  set accumulatedCounterListWithTiming(value: AccumulatedCounterBasedOnPointInTimeList[]) {
    this.logger.debug('TimelineStatsComponent.accumulatedCounterListWithTiming', value);
    if (value) {
      this._accumulatedCounterListWithTiming = value;
      if (value.length > 1) {
        this._homeGoalGraph = this._accumulatedCounterListWithTiming[0].map(goalTimePoint => ({
          x: goalTimePoint.minuteOfGame,
          y: goalTimePoint.accumulatedCounter
        }));
        this._visitorGoalGraph = this._accumulatedCounterListWithTiming[1].map(goalTimePoint => ({
          x: goalTimePoint.minuteOfGame,
          y: goalTimePoint.accumulatedCounter
        }));
        this._homeSavesGraph = this._accumulatedCounterListWithTiming[2].map(goalTimePoint => ({
          x: goalTimePoint.minuteOfGame,
          y: goalTimePoint.accumulatedCounter
        }));
        this._visitorSavesGraph = this._accumulatedCounterListWithTiming[3].map(goalTimePoint => ({
          x: goalTimePoint.minuteOfGame,
          y: goalTimePoint.accumulatedCounter
        }));
        this._homePosessionsGraph = this._accumulatedCounterListWithTiming[4].map(goalTimePoint => ({
          x: goalTimePoint.minuteOfGame,
          y: goalTimePoint.accumulatedCounter
        }));
        this._visitorPosessionsGraph = this._accumulatedCounterListWithTiming[5].map(goalTimePoint => ({
          x: goalTimePoint.minuteOfGame,
          y: goalTimePoint.accumulatedCounter
        }));
        this._homeLostBallsGraph = this._accumulatedCounterListWithTiming[6].map(goalTimePoint => ({
          x: goalTimePoint.minuteOfGame,
          y: goalTimePoint.accumulatedCounter
        }));
        this._visitorLostBallsGraph = this._accumulatedCounterListWithTiming[7].map(goalTimePoint => ({
          x: goalTimePoint.minuteOfGame,
          y: goalTimePoint.accumulatedCounter
        }));
      }
      this.createChartData();

    }
  }

  get semaphoreRows(): SemaphorModel[] {
    return this._semaphoreRows;
  }

  @Input()
  set semaphoreRows(value: SemaphorModel[]) {
    if (value && value.length > 0) {
      this._semaphoreRows = value;
    }
  }

  get homeTeamLabel(): string {
    return this._homeTeamLabel;
  }


  get awayTeamLabel(): string {
    return this._awayTeamLabel;
  }


  get chartData() {
    return this._chartData;
  }


  get chartLabels(): string[] {
    return this._chartLabels;
  }


  get chartOptions(): Chart.ChartOptions {
    return this._chartOptions;
  }

  private createChartData(): void {
    this._chartData = [
      {
        data: this._homeGoalGraph,
        label: this._homeTeamLabel+' G',
        showLine: true,
        fill: false,
        borderColor: '#2EB67D',
        backgroundColor: '#2EB67D',
        pointBackgroundColor: '#2EB67D',

      },
      {
        data: this._visitorGoalGraph,
        label: this._awayTeamLabel+' G',
        showLine: true,
        fill: false,
        borderDash: [10, 10],
        borderColor: '#2EB67D',
        backgroundColor: '#2EB67D',
        pointBackgroundColor: '#2EB67D'
      },
      {
        data: this._homeSavesGraph,
        label: this._homeTeamLabel+' S',
        showLine: true,
        fill: false,
        borderColor: '#ECB22E',
        backgroundColor: '#ECB22E',
        pointBackgroundColor: '#ECB22E',

      },
      {
        data: this._visitorSavesGraph,
        label: this._awayTeamLabel+' S',
        showLine: true,
        fill: false,
        borderDash: [10, 10],
        borderColor: '#ECB22E',
        backgroundColor: '#ECB22E',
        pointBackgroundColor: '#ECB22E'
      },
      {
        data: this._homePosessionsGraph,
        label: this._homeTeamLabel+' POSS',
        showLine: true,
        fill: false,
        borderColor: '#0D385F',
        backgroundColor: '#0D385F',
        pointBackgroundColor: '#0D385F',

      },
      {
        data: this._visitorPosessionsGraph,
        label: this._awayTeamLabel+' POSS',
        showLine: true,
        fill: false,
        borderDash: [10, 10],
        borderColor: '#0D385F',
        backgroundColor: '#0D385F',
        pointBackgroundColor: '#0D385F'
      },
      {
        data: this._homeLostBallsGraph,
        label: this._homeTeamLabel+' LB',
        showLine: true,
        fill: false,
        borderColor: '#E01E5A',
        backgroundColor: '#E01E5A',
        pointBackgroundColor: '#E01E5A',

      },
      {
        data: this._visitorLostBallsGraph,
        label: this._awayTeamLabel+' LB',
        showLine: true,
        fill: false,
        borderDash: [10, 10],
        borderColor: '#E01E5A',
        backgroundColor: '#E01E5A',
        pointBackgroundColor: '#E01E5A'
      },
      // {
      //   data: this._homeXGGraph,
      //   label: `XG ${this._homeTeamLabel}`,
      //   borderDash: [3],
      //   showLine: true,
      //   fill: false,
      //   borderColor: 'rgba(34,91,185,0.74)',
      //   backgroundColor: 'rgba(34,91,185,0.74)',
      //   pointBackgroundColor: 'rgba(34,91,185,0.74)'
      // },
      // {
      //   data: this._visitorXGGraph,
      //   label: `XG ${this._awayTeamLabel}`,
      //   borderDash: [3],
      //   showLine: true,
      //   fill: false,
      //   borderColor: '#a70000',
      //   backgroundColor: '#a70000',
      //   pointBackgroundColor: '#a70000'
      // },
    ];
  }
}
