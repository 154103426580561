<div class="confirm-modal-wrapper my-8" >
  <div class="py-4 w-full">
    <span *ngIf="!folder" class="team-title">{{ 'Add New Folder' | translate}}</span>
    <span *ngIf="folder" class="team-title">{{ 'Edit Folder' | translate}}</span>
    <hr class="mx-auto my-2">
  </div>
  <div class="input-group text-black mt-2" [formGroup]="formGroup">
    <input
        type="text"
        class="focus:outline-none focus:ring focus:border-blue-400 rounded-3xl mb-5 p-2 w-full"
        [placeholder]="'Folder Name' | translate"
        [formControlName]="'name'"
    />
  </div>
  <div class="button-wrapper mt-6">
    <span (click)="onCancel()" class="close-btn">{{'Close'|translate}}</span>
    <ion-button [disabled]="formGroup.invalid" color="primary" (click)="onConfirm()">{{'Confirm'|translate}}</ion-button>
  </div>
</div>
