import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CounterModel } from '@handballai/stats-calculation';
import { BehaviorSubject } from 'rxjs';
import { PlayerEventModel } from '@handballai/stats-calculation';
import { CoreService } from 'src/app/shared-services/core.service';
import { SuspensionConsumerCore } from '@handballai/stats-calculation';

@Injectable({
  providedIn: 'root'
})
export class SuspensionConsumerService {

  private static SUSPENSION_EVENTS = [
    '2_MIN',
    'YELLOW_CARD',
    'RED_CARD',
    'BLUE_CARD'
  ] as PlayerEventModel[];

  private _suspensionConsumerCore: SuspensionConsumerCore;

  constructor(
    private readonly logger: NGXLogger,
  ) { }

  get selectedPlayerCounters$(): BehaviorSubject<CounterModel[]> {
    return this._selectedPlayerCounters$;
  }

  private _core: CoreService;

  private _selectedPlayerCounters$ = new BehaviorSubject<CounterModel[]>([]);
  initCore(core: CoreService) {
    this._core = core;

    this.subscribeToSuspensionsForCounters();
    this.subscribeToSuspensionsForPlayerEvent();
  }

  public init(): void {
    this._suspensionConsumerCore = new SuspensionConsumerCore(
        this._core.gameService.gameModel.home.players,
        this._core.gameService.gameModel.visitor.players
    );
  }

  public selectPlayer(id: number): void {
    this._selectedPlayerCounters$
        .next([...Array.from(this._suspensionConsumerCore.playerCounterMap.get(id).values())]);
  }

  public deSelectPlayer(): void {
    this._selectedPlayerCounters$.next([]);
  }


  private subscribeToSuspensionsForCounters(): void {
    this._core.playByPlayProducerService.on(SuspensionConsumerService.SUSPENSION_EVENTS, pbp => {
      this.logger.debug('SuspensionConsumerService.SuspensionEventsForCounters: ', pbp);
      this._suspensionConsumerCore.addSuspensionEvent(
          pbp.teamMarker,
          pbp.phase,
          pbp.event,
          pbp.offensePlayer,
          this._core.teamOverviewSubConsumerService,
          pbp.eventTime.secondsSinceStartOfGame / 60,
          pbp.eventTime.halftime
      );
    });
  }

  private subscribeToSuspensionsForPlayerEvent(): void {
    this._core.playByPlayProducerService.on(SuspensionConsumerService.SUSPENSION_EVENTS, pbp => {
      this.logger.debug('SuspensionConsumerService.SuspensionEventsForPlayerEvents: ', pbp);
      this._core.playerEventListConsumerService.addPlayerEvent({
        eventTime: pbp.eventTime,
        phase: pbp.phase,
        eventType: pbp.event,
        playerId: pbp.offensePlayer.id
      });
    });
  }

}
