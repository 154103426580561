import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Router, RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";

import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { ApiModule, Configuration} from './api/hai-api';
import { ApiModule as PlayersApiModule, Configuration as PlayersConfiguration} from './api/hai-players-api';
import { LoggerConfig, LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { TokenInterceptorService } from "./shared-services/token/token-interceptor.service";
import { IonicStorageModule } from "@ionic/storage-angular";
import { Drivers } from "@ionic/storage";
import { ComponentsModule } from "./main/components/components.module";
import { environment } from "src/environments/environment";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import * as Sentry from "@sentry/angular";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AppVersion } from "@awesome-cordova-plugins/app-version/ngx";
import { TooltipModule } from "ng2-tooltip-directive";
import { IonicSelectableModule } from "ionic-selectable";
import { SvgIconsModule } from "@ngneat/svg-icon";
import { SocketIoModule } from "ngx-socket-io";
import { CoreService } from "./shared-services/core.service";
import { AuthGuard } from "./guard/auth.guard";
import { GameGuard } from "./guard/game.guard";
import { I18nService } from "./i18n/i18n.service";
import { GameSystemService } from "./main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/game-system/game-system.service";
import { ScoutingService } from "./main/pages/aehandler-module/pages/scouting-module/scouting.service";
import { AccountEquipoService } from "./shared-services/account-equipo/account-equipo.service";
import { AccountService } from "./shared-services/account/account.service";
import { ActionService } from "./shared-services/actions/action.service";
import { AdminUserService } from "./shared-services/admin-user/admin-user.service";
import { BasicDataService } from "./shared-services/country/basic-data.service";
import { DashboardsService } from "./shared-services/dashboards/dashboards.service";
import { CsvDownloadService } from "./shared-services/download/csv-download.service";
import { ElectronService } from "./shared-services/electron.service";
import { GameDataService } from "./shared-services/game-data/game-data.service";
import { GameHandlerService } from "./shared-services/game/game-handler.service";
import { GameStatsHandlerService } from "./shared-services/game/game-stats-handler.service";
import { GameService } from "./shared-services/game/game.service";
import { HouseKeepingService } from "./shared-services/housekeeping/house-keeping.service";
import { GameLoadingService } from "./shared-services/loading/game-loading.service";
import { LoadingService } from "./shared-services/loading/loading.service";
import { GameSeedService } from "./shared-services/statistics/game-seed/game-seed.service";
import { EventFeedService } from "./shared-services/statistics/ingest/event-feed.service";
import { PlayTimeService } from "./shared-services/statistics/play-time/play-time.service";
import { EasyStatsService } from "./shared-services/statistics/playbyplay/consumer/easy-stats/easy-stats.service";
import { GameSystemConsumerService } from "./shared-services/statistics/playbyplay/consumer/game-system/game-system-consumer.service";
import { GoalConsumerService } from "./shared-services/statistics/playbyplay/consumer/goal/goal-consumer.service";
import { GoalkeeperEfficiencyConsumerService } from "./shared-services/statistics/playbyplay/consumer/goalkeeper-efficiency/goalkeeper-efficiency-consumer.service";
import { LostBallConsumerService } from "./shared-services/statistics/playbyplay/consumer/lost-ball/lost-ball-consumer.service";
import { MiscEventConsumerService } from "./shared-services/statistics/playbyplay/consumer/misc-event/misc-event-consumer.service";
import { OverviewConsumerService } from "./shared-services/statistics/playbyplay/consumer/overview/overview-consumer.service";
import { PlayerEventListConsumerService } from "./shared-services/statistics/playbyplay/consumer/player-events/player-event-list-consumer.service";
import { PossessionsConsumerService } from "./shared-services/statistics/playbyplay/consumer/possessions/possessions-consumer.service";
import { SuspensionConsumerService } from "./shared-services/statistics/playbyplay/consumer/suspension/suspension-consumer.service";
import { TimelineConsumerService } from "./shared-services/statistics/playbyplay/consumer/timeline/timeline-consumer.service";
import { PlayByPlayProducerService } from "./shared-services/statistics/playbyplay/producer/play-by-play-producer.service";
import { TeamOverviewSubConsumerService } from "./shared-services/statistics/playbyplay/sub-consumer/team-overview/team-overview-sub-consumer.service";
import { VideoTrackerService } from "./shared-services/statistics/video/video-tracker.service";
import { StorageService } from "./shared-services/storage-service/storage.service";
import { TeamValidationService } from "./shared-services/team/team-validation.service";
import { TeamService } from "./shared-services/team/team.service";
import { HandballTimerService } from "./shared-services/timer/handball-timer.service";
import { TimerWrapperService } from "./shared-services/timer/timer-wrapper.service";
import { EventBufferService } from "./shared-services/ui-event-dispatcher/buffer/event-buffer.service";
import { UiEventDispatcherService } from "./shared-services/ui-event-dispatcher/ui-event-dispatcher.service";
import { UserService } from "./shared-services/user-service/user.service";
import { SeasonService } from "./shared-services/season/season.service";
import { VideoDownloadService } from './shared-services/download/video-download.service';
import { PlayersService } from './main/pages/players-module/players.service';

export const createTranslateLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, "./assets/i18n/", ".json");

const APP_SERVICES = [
  AuthGuard,
  GameGuard,
  I18nService,
  GameSystemService,
  ScoutingService,
  ElectronService,
  AccountService,
  AccountEquipoService,
  SeasonService,
  ActionService,
  AdminUserService,
  BasicDataService,
  DashboardsService,
  CsvDownloadService,
  GameHandlerService,
  GameStatsHandlerService,
  GameService,
  GameDataService,
  HouseKeepingService,
  GameLoadingService,
  LoadingService,
  GameSeedService,
  EventFeedService,
  PlayTimeService,
  EasyStatsService,
  GameSystemConsumerService,
  GoalConsumerService,
  GoalkeeperEfficiencyConsumerService,
  LostBallConsumerService,
  MiscEventConsumerService,
  OverviewConsumerService,
  PlayerEventListConsumerService,
  PossessionsConsumerService,
  SuspensionConsumerService,
  TimelineConsumerService,
  PlayByPlayProducerService,
  TeamOverviewSubConsumerService,
  VideoTrackerService,
  StorageService,
  TeamValidationService,
  TeamService,
  HandballTimerService,
  TimerWrapperService,
  UiEventDispatcherService,
  EventBufferService,
  UserService,
  VideoDownloadService,
  PlayersService
];

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    NgSelectModule,
    FormsModule,
    IonicStorageModule.forRoot({
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    TooltipModule.forRoot({}),
    IonicSelectableModule,
    SocketIoModule,
    ApiModule.forRoot(() => new Configuration({
      basePath: environment.apiPath,
      withCredentials: false,
    })),
    PlayersApiModule.forRoot(() => new PlayersConfiguration({
      basePath: environment.playersApiPath,
      withCredentials: false,
    })),
    LoggerModule.forRoot({
      // level: NgxLoggerLevel.INFO,
      level: environment.production ? NgxLoggerLevel.OFF : NgxLoggerLevel.DEBUG,
    } as LoggerConfig),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production || environment.name == "beta",
    }),
    SvgIconsModule.forRoot(),
  ],
  providers: [
    StatusBar,
    AppVersion,
    CoreService,
    SplashScreen,
    ...APP_SERVICES,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },

    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
