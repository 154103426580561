
<div class="grid flex-auto" *ngIf="false">
  <div class="grid-col (3/4) pr-2 ">

    <h2>{{'First Half'|translate}}</h2>
    <ngx-datatable
      class="material"
      [rows]="timeLineStatsGoalsFirstHalf"
      [columns]="firstHalfColumns"
      [columnMode]="'flex'"
      [limit]="5"
      rowHeight="auto"
      [scrollbarH]="true"
      [footerHeight]="false"
      [headerHeight]="50"
    >
    </ngx-datatable>


    <h2>{{'Second Half'|translate}}</h2>
    <ngx-datatable
      class="material"
      [rows]="timeLineStatsGoalsSecondHalf"
      [columns]="secondHalfColumns"
      [columnMode]="'flex'"
      [limit]="5"
      rowHeight="auto"
      [scrollbarH]="true"
      [footerHeight]="false"
      [headerHeight]="50"
    >
    </ngx-datatable>
  </div>

  <div class="grid-col (1/4) pr-2 ">

    <div>
      <h2>TG</h2>
      <ngx-datatable
        class="material"
        [rows]="timeLineStatsGoalsPossessionOverview"
        [columns]="totalsColumns"
        [columnMode]="'flex'"
        [limit]="5"
        rowHeight="auto"
        [scrollbarH]="true"
        [footerHeight]="false"
        [headerHeight]="50"
      >
      </ngx-datatable>
    </div>

    <div>
      <h2>{{'Victory %'|translate}}</h2>
      <ngx-datatable
        class="material semaphore"
        [rows]="semaphoreRows"
        [columns]="semaphoreColumns"
        [columnMode]="'flex'"
        [limit]="5"
        rowHeight="auto"
        [scrollbarH]="true"
        [footerHeight]="false"
        [headerHeight]="50"
      >
        <ngx-datatable-column name="p35" [flexGrow]="1">
          <ng-template let-row="row" ngx-datatable-header-template>
            <span>35 %</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ion-avatar><div [class.active]="row.percent <= 55" class="circle_wrapper red"></div></ion-avatar>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="p55" [flexGrow]="1">
          <ng-template let-row="row" ngx-datatable-header-template>
            <span>55 %</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ion-avatar><div [class.active]="row.percent > 55 && row.percent <= 85" class="circle_wrapper yellow"></div></ion-avatar>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="p85" [flexGrow]="1">
          <ng-template let-row="row" ngx-datatable-header-template>
            <span>85 %</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <ion-avatar><div [class.active]="row.percent > 85" class="circle_wrapper green"></div></ion-avatar>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>


</div>


<div class="chart-container">
  <ng-container *ngIf="!core.isMobileDevice">
    <canvas baseChart
            [chartType]="'scatter'"
            [legend]="true"
            [datasets]="chartData"
            [options]="chartOptions">
    </canvas>
  </ng-container>
  <ng-container *ngIf="core.isMobileDevice">
    <canvas baseChart
            height="300"
            [chartType]="'scatter'"
            [legend]="true"
            [datasets]="chartData"
            [options]="chartOptions">
    </canvas>
  </ng-container>
</div>
