
<div class="confirm-modal-wrapper my-8" >
  <div class="py-4 w-full">
    <span>{{isChangePlan ? ('Change Plan'|translate) : ("Create Account"|translate)}}</span>
    <hr class="mx-auto my-2">
    <span *ngIf="core.device !== 'ios' && !isSelectPlanPart" class="explanation-text">{{isSelectPlanPart ? ('Select the plan for your account'|translate) : ('Fill the name for you new account'|translate)}}</span>
  </div>
  <ng-container *ngIf="core.device !== 'ios'">
    <div class="input-group text-black mt-2" [formGroup]="fg"  *ngIf="!isSelectPlanPart">
      <input
          type="text"
          class="focus:outline-none focus:ring focus:border-blue-400 rounded-3xl p-2 w-full"
          [placeholder]="'My new account' | translate"
          [formControlName]="'nameFc'"
        />
    </div>
    <ng-container *ngIf="isSelectPlanPart">
      <div class="plans-container">
        <div style="text-align: left" class="mb-2 w-1/2">
          <span class="span-text">{{ 'Select plan' | translate }}</span>
          <div class="checkbox-wrapper flex justify-center items-center space-x-2 mb-2" *ngFor="let plan of plansOptions">
            <div class="w-1/2 flex justify-end">
              <ion-checkbox color="primary" (click)="onSelectionChanged(plan)" [(ngModel)]="plan['isChecked']"></ion-checkbox>
            </div>
            <div class="w-3/4 flex justify-start">
              <ion-label (click)="onSelectionChanged(plan, true)" class="ml-1 pointer-txt">{{plan.label}}</ion-label>
            </div>
          </div>
          <br>
          <span class="span-text">{{ 'Renewal period' | translate }}</span>
          <div class="checkbox-wrapper flex justify-center items-center space-x-2 mb-2" *ngFor="let period of renewalPeriods">
            <div class="w-1/2 flex justify-end">
              <ion-checkbox color="primary" (click)="onSelectionPeriodChanged(period)" [(ngModel)]="period['isChecked']"></ion-checkbox>
            </div>
            <div class="w-3/4 flex justify-start">
              <ion-label (click)="onSelectionPeriodChanged(period, true)" class="ml-1 pointer-txt">{{period.label}}</ion-label>
            </div>
          </div>
        </div>
        <div style="text-align: left" class="mb-2 w-1/2">
          <span class="span-text">{{ 'MyTeam Addon' | translate }}</span>
          <div class="checkbox-wrapper flex justify-center items-center space-x-2 mb-2" *ngFor="let myTeamOption of myTeamOptions">
            <div class="w-1/2 flex justify-end">
              <ion-checkbox color="primary" (click)="onSelectionMyTeamChanged(myTeamOption)" [(ngModel)]="myTeamOption['isChecked']"></ion-checkbox>
            </div>
            <div class="w-3/4 flex justify-start">
              <ion-label  (click)="onSelectionMyTeamChanged(myTeamOption, true)" class="ml-1 pointer-txt">{{ myTeamOption.label }}</ion-label>
            </div>
          </div>
          <br>
          <span class="span-text">{{ 'Grand Total' | translate }}</span><br>
          <ng-container *ngIf="planSelected && renewalPeriodSelected && myTeamOption && selectedPlanPrice">
            <p style="text-align: center; margin-top: 15px; margin-right: 20px">
              {{ selectedPlanPrice }} € / {{ finalPlan.includes('Yearly') ? 'yearly' : 'monthly' }}
            </p>
            <p style="text-align: center; margin-top: 15px; font-size: 14px; margin-right: 20px" *ngIf="myTeamOption === 'yes'">
              {{ 'Your Current Plan + Team Addon' | translate }}
            </p>
          </ng-container>
          <p style="text-align: center; margin-top: 15px; font-size: 14px; margin-right: 20px"
             *ngIf="planSelected && renewalPeriodSelected && myTeamOption === 'yes' && !selectedPlanPrice">
            {{ 'MyTeam Addon is only available with Scouting' | translate }}
          </p>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="core.device == 'ios'" class="input-group text-black mt-2">
    Account creation is only available on the web version
  </div>

  <div class="button-wrapper mt-6">
    <span (click)="onCancel()" class="close-btn"
      >{{'Close'|translate}}</span>
    <ion-button *ngIf="core.device !== 'ios'" [disabled]="isSelectPlanPart && (!myTeamOption || !finalPlan || !planSelected || !renewalPeriodSelected || !selectedPlanPrice)" color="primary" (click)="onConfirm()">{{'Confirm'|translate}}</ion-button>
  </div>
</div>
