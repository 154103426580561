import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanDeactivate } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GameGuard implements CanDeactivate<CanComponentDeactivate> {
    constructor(private logger: NGXLogger) { }

  canDeactivate(
    component: CanComponentDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let url: string = state.url;
      console.log('Url: ' + url);

      const canDeactivate = component?.canDeactivate ? component?.canDeactivate() : true;
      if (!canDeactivate) this.logger.info('Prevented deactivation');
      return canDeactivate;
  }

}
