import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NavController } from "@ionic/angular";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NGXLogger } from "ngx-logger";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import {
  AccountEquipoUserDto,
  AdminAccountEquipoDto,
} from "src/app/api/hai-api";
import { AccountService } from "src/app/shared-services/account/account.service";
import { CommonUserModel } from "src/app/shared-services/model/common-user.model";
import { UserService } from "src/app/shared-services/user-service/user.service";
import { ConfirmModalComponent } from '../../pages/aehandler-module/pages/game-module/components/confirm-modal/confirm-modal';
import { CoreService } from '../../../shared-services/core.service';

@Component({
    selector: "app-manage-table",
    templateUrl: "./manage-table.component.html",
    styleUrls: ["./manage-table.component.scss"],
    standalone: false
})
export class ManageTableComponent implements OnInit {
  @ViewChild("editTable") table: DatatableComponent;
  @Input() accountId!: string;
  selectedAccountEquipoData: AdminAccountEquipoDto | null = null;
  get selectedAccountEquipo() { return this.selectedAccountEquipoData; }
  @Input() set selectedAccountEquipo(value: AdminAccountEquipoDto | null) {
    this.selectedAccountEquipoData = value;
    const grants = this.getDefaultGrants();
    if (value?.account.permissions.dashboard) grants.push('dashboard');
    if (value?.account.permissions.scheduleGames) grants.push('track_game');
    if (value?.account.permissions.accessScouting) grants.push('scouting');
    if (value?.account.permissions.scoutingConnect) grants.push('scouting_connect');
    this.availableGrants.next(grants);
  };

  @Output() accountEquiposLength: EventEmitter<number> =
    new EventEmitter<number>();

  public columns = [
    { prop: "name" },
    { prop: "xpsToken" },
    { prop: "account", name: "Number" },
    { prop: "users", name: "Name" },
    { prop: "_buttons" },
  ];

  public showHideDelete = 0.1;
  public availableGrants = new BehaviorSubject([]);

  private readonly _accountEquipos$: Observable<AdminAccountEquipoDto[]>;
  private readonly _user$: Observable<CommonUserModel>;

  constructor(
    public nav: NavController,
    private readonly logger: NGXLogger,
    private readonly userService: UserService,
    private readonly accountService: AccountService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly core: CoreService
  ) {
    this._accountEquipos$ = this.accountService.lastAccountEquipos$.pipe(
      map((itm) => {
        itm = itm?.filter((row) => !row.deleted).sort((a, b) => b.id - a.id);
        setTimeout(() => this.table.rowDetail.expandAllRows(), 150);
        return itm;
      }),
      tap((itm) => this.accountEquiposLength.emit(itm?.length))
    );
    this._user$ = this.userService.user$;
  }

  ngOnInit(): void {
    this.logger.debug("ManageTableComponent.ngOnInit - id: ", this.accountId);
    if (this.accountId !== "new") {
      this.accountService.getAccountEquipos(+this.accountId);
    }
  }

  getDefaultGrants = () => ['create_game','view_game','manage_teams'];

  get accountEquipos$(): Observable<AdminAccountEquipoDto[]> {
    return this._accountEquipos$;
  }
  get user$(): Observable<CommonUserModel> {
    return this._user$;
  }

  hasGrant = (grants: string[], grant: string) => grants.includes(grant);
  toggleGrant(grants: string[], grant: string) {
    if (this.hasGrant(grants, grant)) {
      grants.splice(grants.indexOf(grant), 1);
    } else {
      grants.push(grant);
    }
  }
  async addUser(users: AccountEquipoUserDto[]) {
    const account = await this.accountService.getAccountById(+this.accountId);
    if (
      this.userService.checkForCountablePermission(
        "maxUsers",
        users.length,
        true,
        account.permissions
      )
    ) {
      users.push({ grants: [], userEmail: "" } as AccountEquipoUserDto);
    }
  }
  deleteMe<T>(haystack: T[], needle: T) {
    haystack.splice(haystack.indexOf(needle), 1);
  }

  public onAccountEquipoSelectChange(row: AdminAccountEquipoDto) {
    this.selectedAccountEquipo = row ? JSON.parse(JSON.stringify(row)) : null;
    this.table.rowDetail.expandAllRows();
  }

  public async onAccountEquipoCreateOrUpdate(
    account: AdminAccountEquipoDto,
    delFlag: boolean = false
  ) {
    if (delFlag) {
      await ConfirmModalComponent.Open(this.core.popoverCtrl, {
        title: `Are you sure you want to delete this account equipo ?`,
      }, (d) => {
        if (d.data) {
          return new Promise<AdminAccountEquipoDto>((ok, ko) => {
            if (delFlag) {
              account.deleted = true;
            }
            this.accountService.saveAccountEquipo(account).then((savedAccount) => {
              this.selectedAccountEquipo = null;
              this.changeDetectorRef.detectChanges();
              ok(savedAccount);
              this.accountService.getAccountEquipos(+this.accountId);
            });
          });
        }
      });
    } else {
      return new Promise<AdminAccountEquipoDto>((ok, ko) => {
        this.accountService.saveAccountEquipo(account).then((savedAccount) => {
          this.selectedAccountEquipo = null;
          this.changeDetectorRef.detectChanges();
          ok(savedAccount);
          this.accountService.getAccountEquipos(+this.accountId);
        });
      });
    }
  }
}
