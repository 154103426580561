import { ChangeDetectionStrategy, Component, ContentChildren, QueryList } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CoreService } from 'src/app/shared-services/core.service';
import { StackedPageComponent } from '../stacked-page/stacked-page.component';

@Component({
  selector: 'app-stacked-navigation',
  templateUrl: './stacked-navigation.component.html',
  styleUrls: ['./stacked-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackedNavigationComponent {

  @ContentChildren(StackedPageComponent) set pages(value: QueryList<StackedPageComponent<any>>) {
    console.log('pages', value);
  }

  constructor(
    private readonly logger: NGXLogger,
    public readonly core: CoreService,
  ) {
  }

}

