import Dexie, { Table } from 'dexie';
import { GameDto, PlayByPlayDto, PlayTimeDto } from './api/hai-api';

export interface GameLocalEntity extends Omit<GameDto, 'playByPlayDto'|'playTimeDto'> {
  id?: number;
  srvId?: number;
  isUploadPending: number;
  // folderId: number;
  // accountEquipoId: number;
  // home: string;
  // visitor: string;
  // goalsHome: number;
  // goalsVisitor: number;
  // date: string;
  // homeId: number;
  // visitorId: number;
  // gameEnded: boolean;
  // playByPlay: Array<PlayByPlayLocalEntity>;
  // playTime: Array<PlayTimeLocalEntity>;
  // accessHash?: string;
  // gameModelSnapshot: string;
  // gameType: string;
  // gameStatus: string;
  // csvRequestedBy?: number;
  // csvRequestAccept?: boolean;
  // trackers: Array<number>;
}
export interface PlayByPlayLocalEntity extends PlayByPlayDto {
  id?: number;
  srvId?: number|boolean;
  gameSrvId: number;
  gameSystem?: string;
  videoTimestamp?: number;
}
export interface PlayTimeLocalEntity extends PlayTimeDto {
  id?: number;
  srvId?: number|boolean;
  gameSrvId: number;
}

export class AppDB extends Dexie {
  game!: Table<GameLocalEntity, number>;
  playByPlay!: Table<PlayByPlayLocalEntity, number>;
  playTime!: Table<PlayTimeLocalEntity, number>;

  constructor() {
    super('_handball_ai');
    // we might consider to have a flag about the save status of the game

    this.version(3).stores({
      game: '++id, srvId, isUploadPending',
      playByPlay: '++id, gameSrvId',
      playTime: '++id, gameSrvId',
    });
  }
}

export const db = new AppDB();
