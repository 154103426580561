/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WatchlistDto } from './watchlistDto';


export interface WatchlistFolderDto { 
    /**
     * id
     */
    id?: number;
    /**
     * name
     */
    name: string;
    /**
     * deleted
     */
    deleted: boolean;
    /**
     * watchlists
     */
    watchlists: Array<WatchlistDto>;
}

