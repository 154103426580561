
<div class="confirm-modal-wrapper my-8" >
  <div class="py-4 w-full">
    <span>{{"Edit Organization" | translate}}</span>
    <hr class="mx-auto my-2">
    <span class="explanation-text">{{'Type the new name for your account' | translate}}</span>
  </div>
  <div class="input-group text-black mt-2" [formGroup]="fg">
    <input
        type="text"
        class="focus:outline-none focus:ring focus:border-blue-400 rounded-3xl p-2 w-full"
        [placeholder]="'My new account' | translate"
        [formControlName]="'nameFc'"
      />
  </div>
  <div class="button-wrapper mt-6">
    <span (click)="onCancel()" class="close-btn"
      >{{'Close'|translate}}</span>
    <ion-button color="primary" (click)="onConfirmName(this.fg.get('nameFc').value)">{{'Confirm'|translate}}</ion-button>
  </div>
</div>
