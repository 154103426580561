import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { GameSystemCategoryDto } from "src/app/api/hai-api";
import { TGameSystemPbp } from "src/app/shared-services/game-system/game-system.model";

@Injectable({
  providedIn: "root",
})
export class GameSystemService {
  private _gameSystemSelected$ = new BehaviorSubject<TGameSystemPbp[]>([]);
  private _gameActionNameSelected$ = new BehaviorSubject<string[]>([]);

  public gameSystemSelected$ = this._gameSystemSelected$;
  public gameActionNameSelected$ = this._gameActionNameSelected$;

  get gameActionNameSelected(): string[] {
    return this._gameActionNameSelected$.getValue();
  }

  get gameSystemSelected() {
    return this._gameSystemSelected$.getValue();
  }

  public onGameSystemSelected(
    gameSystemCategory: string,
    gameSystem: string,
    gameSystemDtos: GameSystemCategoryDto[]
  ): void {
    if (gameSystemCategory === 'Type of Shot') {
      this._gameActionNameSelected$.next([...this.gameActionNameSelected.filter(ga => !ga.startsWith(`${gameSystemCategory}:`))]);
    }

    if (
      this.gameActionNameSelected.includes(
        `${gameSystemCategory}:${gameSystem}`
      )
    ) {
      this._gameActionNameSelected$.next([
        ...this.gameActionNameSelected.filter(
          (ga) => ga !== `${gameSystemCategory}:${gameSystem}`
        ),
      ]);
    } else {
      this._gameActionNameSelected$.next([
        ...this.gameActionNameSelected,
        `${gameSystemCategory}:${gameSystem}`,
      ]);
    }
    this._gameSystemSelected$.next(
      this.gameActionNameSelected.reduce((selected, curr) => {
        const splittedCurr = curr.split(":");
        const category = gameSystemDtos.filter(
          (gsc) => gsc.categoryName === splittedCurr[0]
        )[0];
        const gameAction = category.gameSystems.filter(
          (gs) => gs.actionName === splittedCurr[1]
        )[0];
        selected.push({
          categoryName: category.categoryName,
          actionDisplayName: gameAction.actionDisplayName,
          colorCode: category.colorCode,
          actionName: gameAction.actionName,
        } as TGameSystemPbp);
        return selected;
      }, [] as TGameSystemPbp[])
    );
  }

  clearSelections() {
    this._gameActionNameSelected$.next([]);
    this._gameSystemSelected$.next([]);
  }

  setGameSystemSelected(gameSystemSelected: TGameSystemPbp[]) {
    this._gameSystemSelected$.next(gameSystemSelected);
  }

  setGameActionNameSelected(gameActionNameSelected: string[]) {
    this._gameActionNameSelected$.next(gameActionNameSelected);
  }
}
