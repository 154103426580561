<ion-header *ngIf="!hideHeader">
    <ion-toolbar>
        <ion-title>
            {{'Edit event'|translate}}
        </ion-title>

        <ion-buttons slot="end">
            <ion-button (click)="dismiss(false)">
                <ion-label>{{'Cancel'|translate}}</ion-label>
            </ion-button>
            <ion-button (click)="dismiss(true)" class="save-button">
                <ion-label>{{'Save'|translate}}</ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="w-full play-by-play-edit__wrapper" *ngIf="pbpEntryData">

        <ion-list>
            <ion-item [tooltip]="'This value cannot be edited, but you can delete and register again the event'|translate">
                <ion-label color="danger">{{'Event type'|translate}}</ion-label>
                <ion-input slot="end" style="text-align: right;" [readonly]="true" [ngModel]="pbpEntryData.event"></ion-input>
            </ion-item>
            <ion-item [tooltip]="'This value cannot be edited, but you can delete and register again the event'|translate">
                <ion-label color="danger">{{'Team'|translate}}</ion-label>
                <ion-input slot="end" style="text-align: right;" [readonly]="true" [ngModel]="pbpEntryData.teamName"></ion-input>
            </ion-item>
            <ion-item [tooltip]="'This value cannot be edited, but you can delete and register again the event'|translate">
                <ion-label color="danger">{{'Event time'|translate}}</ion-label>
                <ion-input slot="end" style="text-align: right;" [readonly]="true" [ngModel]="pbpEntryData.eventTime.halftime+' '+pbpEntryData.eventTime.minutesSinceHalftime+':'+pbpEntryData.eventTime.secondsSinceHalftime"></ion-input>
            </ion-item>

            <ion-item-divider></ion-item-divider>

            <ion-item>
                <ion-icon name="create-outline"></ion-icon>
                <ion-label class="select-label">{{ "Defense player" | translate }}</ion-label>
                <ionic-selectable [disabled]="readonlyFields.includes('defensePlayer')" item-content [(ngModel)]="pbpEntryData.defensePlayer" [items]="defensePlayers$|async"
                                      [isMultiple]="false" itemValueField="id" itemTextField="name" [canSearch]="true"
                                      [placeholder]="'Select player'|translate">
                    <ng-template ionicSelectableHeaderTemplate></ng-template>
                    <ng-template ionicSelectableItemTemplate let-port="item" let-isPortSelected="isItemSelected">
                        {{port.backNumber}}, {{port.name}}, {{port.position}}
                    </ng-template>
                    <ng-template ionicSelectableValueTemplate let-port="value">
                        <div class="ionic-selectable-value-item">
                            {{port.backNumber}}, {{port.name}}, {{port.position}}
                        </div>
                    </ng-template>
                </ionic-selectable>
            </ion-item>
            <ion-item>
                <ion-icon name="create-outline"></ion-icon>
                <ion-label class="select-label">{{ "Defense" | translate }}</ion-label>
                <ionic-selectable [disabled]="readonlyFields.includes('defense')" *ngIf="(gameMode$|async)=='COMPLETE_MODE'"
                        item-content [(ngModel)]="pbpEntryData.defense" [items]="defensePlayers$|async"
                        [isMultiple]="true" itemValueField="id" itemTextField="name" [canSearch]="true"
                        [placeholder]="'Select player'|translate">
                    <ng-template ionicSelectableHeaderTemplate></ng-template>
                    <ng-template ionicSelectableItemTemplate let-port="item" let-isPortSelected="isItemSelected">
                        {{port.backNumber}}, {{port.name}}, {{port.position}}
                    </ng-template>
                    <ng-template ionicSelectableValueTemplate let-ports="value">
                        <div class="ionic-selectable-value-item">
                            {{ports.length}} selected
                        </div>
                    </ng-template>
                </ionic-selectable>
            </ion-item>
            <ion-item>
                <ion-icon name="create-outline"></ion-icon>
                <ion-label class="select-label">{{ "Offense Player" | translate }}</ion-label>
                <ionic-selectable [disabled]="readonlyFields.includes('offensePlayer')" item-content [(ngModel)]="pbpEntryData.offensePlayer" [items]="offensePlayers$|async"
                                      [isMultiple]="false" itemValueField="id" itemTextField="name" [canSearch]="true"
                                      [placeholder]="'Select player'|translate">
                    <ng-template ionicSelectableHeaderTemplate></ng-template>
                    <ng-template ionicSelectableItemTemplate let-port="item" let-isPortSelected="isItemSelected">
                        {{port.backNumber}}, {{port.name}}, {{port.position}}
                    </ng-template>
                    <ng-template ionicSelectableValueTemplate let-port="value">
                        <div class="ionic-selectable-value-item">
                            {{port.backNumber}}, {{port.name}}, {{port.position}}
                        </div>
                    </ng-template>
                </ionic-selectable>
            </ion-item>
            <ion-item>
                <ion-icon name="create-outline"></ion-icon>
                <ion-label class="select-label">{{ "Offense" | translate }}</ion-label>
                <ionic-selectable [disabled]="readonlyFields.includes('offense')" *ngIf="(gameMode$|async)=='COMPLETE_MODE'"
                        item-content [(ngModel)]="pbpEntryData.offense" [items]="offensePlayers$|async"
                        [isMultiple]="true" itemValueField="id" itemTextField="name" [canSearch]="true"
                        [placeholder]="'Select player'|translate">
                    <ng-template ionicSelectableHeaderTemplate></ng-template>
                    <ng-template ionicSelectableItemTemplate let-port="item" let-isPortSelected="isItemSelected">
                        {{port.backNumber}}, {{port.name}}, {{port.position}}
                    </ng-template>
                    <ng-template ionicSelectableValueTemplate let-ports="value">
                        <div class="ionic-selectable-value-item">
                            {{ports.length}} selected
                        </div>
                    </ng-template>
                </ionic-selectable>
            </ion-item>
            <ion-item *ngIf="shotEntriesEventTypes.includes(pbpEntryData.event)">
                <ion-icon name="create-outline"></ion-icon>
                <ion-label class="select-label">{{ "Assistant player" | translate }}</ion-label>
                <ionic-selectable [disabled]="readonlyFields.includes('assistantPlayer')" item-content [(ngModel)]="pbpEntryData.assistantPlayer" [items]="offensePlayers$|async"
                                      [isMultiple]="false" itemValueField="id" itemTextField="name" [canSearch]="true"
                                      [placeholder]="'Select player'|translate">
                    <ng-template ionicSelectableHeaderTemplate></ng-template>
                    <ng-template ionicSelectableItemTemplate let-port="item" let-isPortSelected="isItemSelected">
                        {{port.backNumber}}, {{port.name}}, {{port.position}}
                    </ng-template>
                    <ng-template ionicSelectableValueTemplate let-port="value">
                        <div class="ionic-selectable-value-item">
                            {{port.backNumber}}, {{port.name}}, {{port.position}}
                        </div>
                    </ng-template>
                </ionic-selectable>
            </ion-item>
            <ion-item>
                <ion-icon name="create-outline"></ion-icon>
                <ion-label class="select-label">{{ "Defense system" | translate }}</ion-label>
                <ion-select [disabled]="readonlyFields.includes('defenseSystem')" [(ngModel)]="pbpEntryData.defenseSystem" [placeholder]="'Select defense system'|translate">
                    <ion-select-option [value]="'6:0'">6:0</ion-select-option>
                    <ion-select-option [value]="'3:2:1'">3:2:1</ion-select-option>
                    <ion-select-option [value]="'5:1'">5:1</ion-select-option>
                    <ion-select-option [value]="'4:2'">4:2</ion-select-option>
                    <ion-select-option [value]="'OTHER'">OTHER</ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item>
                <ion-icon name="create-outline"></ion-icon>
                <ion-label class="select-label">{{ "Phase" | translate }}</ion-label>
                <ion-select [disabled]="readonlyFields.includes('phase')" [(ngModel)]="pbpEntryData.phase" [placeholder]="'Select phase'|translate" interface="popover">
                    <ion-select-option [value]="'OFFENSE_POSITIONAL'">OFFENSE POSITIONAL</ion-select-option>
                    <ion-select-option [value]="'COUNTER_GOAL'">COUNTER GOAL</ion-select-option>
                    <ion-select-option [value]="'FAST_BREAK'">FAST BREAK</ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item *ngIf="shotEntriesEventTypes.includes(pbpEntryData.event)">
                <ion-icon name="create-outline"></ion-icon>
                <ion-label class="select-label">{{ "Shooter Position" | translate }}</ion-label>
                <ion-select [disabled]="readonlyFields.includes('executionPosition')" [(ngModel)]="pbpEntryData.executionPosition" [placeholder]="'Select shooter position'|translate" interface="popover">
                    <ion-select-option [value]="'LEFT_WING'">LEFT WING</ion-select-option>
                    <ion-select-option [value]="'LEFT_6M'">LEFT 6M</ion-select-option>
                    <ion-select-option [value]="'CENTER_6M'">CENTER 6M</ion-select-option>
                    <ion-select-option [value]="'RIGHT_6M'">RIGHT 6M</ion-select-option>
                    <ion-select-option [value]="'RIGHT_WING'">RIGHT WING</ion-select-option>
                    <ion-select-option [value]="'LEFT_9M'">LEFT 9M</ion-select-option>
                    <ion-select-option [value]="'CENTER_9M'">CENTER 9M</ion-select-option>
                    <ion-select-option [value]="'RIGHT_9M'">RIGHT 9M</ion-select-option>
                    <ion-select-option [value]="'7_METERS'">7 METERS</ion-select-option>
                    <ion-select-option [value]="'OPPOSITE_FIELD'">OPPOSITE FIELD</ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item *ngIf="shotEntriesEventTypes.includes(pbpEntryData.event)">
                <ion-icon name="create-outline"></ion-icon>
                <ion-label class="select-label">{{ "Shot location" | translate }}</ion-label>
                <ion-select [disabled]="readonlyFields.includes('shotLocation')" [(ngModel)]="pbpEntryData.shotLocation" [placeholder]="'Shot location'|translate">
                    <ion-select-option *ngFor="let i of (pbpEntryData.event=='POST_OUT'?postOutShotLocations:goalSaveShotLocations)" [value]="i">
                        <!-- TODO: Put a human readable select -->
                        {{i}}
                    </ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item>
                <ion-icon name="create-outline"></ion-icon>
                <ion-label class="select-label">{{ "Offense system" | translate }}</ion-label>
                <ion-select [disabled]="readonlyFields.includes('offenseSystem')" [(ngModel)]="pbpEntryData.offenseSystem" [placeholder]="'Offense system'|translate">
                    <ion-select-option *ngFor="let offense of offenseSystemOpts" [value]="offense">
                        {{offense}}
                    </ion-select-option>
                </ion-select>
            </ion-item>

            <ion-item>
                <ion-icon name="create-outline"></ion-icon>
                <ion-label class="select-label">{{ "Game System" | translate }}</ion-label>
                <ionic-selectable *ngIf="gameSystemDto?.length"
                        item-content [(ngModel)]="pbpEntryData.gameSystem" [items]="gameSystemDto"
                        [isMultiple]="true" itemValueField="actionDisplayName" itemTextField="actionDisplayName" [canSearch]="true">
                    <ng-template ionicSelectableHeaderTemplate></ng-template>
                    <ng-template ionicSelectableItemTemplate let-port="item" let-isPortSelected="isItemSelected">
                        {{port.categoryName}}: {{port.actionDisplayName}}
                    </ng-template>
                    <ng-template ionicSelectableValueTemplate let-ports="value">
                        <div class="ionic-selectable-value-item">
                            {{ports.length}} selected
                        </div>
                    </ng-template>
                </ionic-selectable>
            </ion-item>

            <ion-item lines="full">
                <ion-icon name="create-outline"></ion-icon>
                <ion-checkbox [disabled]="readonlyFields.includes('important')" [(ngModel)]="pbpEntryData.important"></ion-checkbox>
                <ion-label style="margin-left: 10px;">{{'Important'|translate}}</ion-label>
            </ion-item>
        </ion-list>
        <div *ngIf="hideHeader" style="margin-top: 15px" class="button-wrapper mt-6">
            <ion-button (click)="save()" color="primary">{{'Save'|translate}}</ion-button>
        </div>
    </div>
</ion-content>
