import { Injectable } from '@angular/core';
import { PlayByPlayModel } from '@handballai/stats-calculation';
import { csvRowFactoryFn } from 'src/app/shared-services/timer/timer-helper';
import { createHeader } from 'src/app/shared-services/download/csv-export.helper';
import { DateTime } from 'luxon';
import * as fileSaver from 'file-saver';
import { CoreService } from '../core.service';
import { ExternalAccountEquiposService } from 'src/app/api/hai-api';
import { concatMap, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CsvDownloadService {

  private _core: CoreService;
  initCore(core: CoreService) {
      this._core = core;
  }

  constructor(
    private readonly externalAccountEquipoService: ExternalAccountEquiposService,
  ) { }

  public downloadFileAsCsv(
      startTimeOfGame: DateTime,
      playByPlayRecords: PlayByPlayModel[],
      gameDescription: string
  ): void {
    fileSaver.saveAs(
        new Blob(
            [this.transformRecordsToFile(
                startTimeOfGame,
                playByPlayRecords,
                gameDescription,
            )],
            { type: 'text/csv;charset=utf-8;' }),
        `playByplay-${DateTime.now().toISO()}.csv`
    );
  }

  private transformRecordsToFile(
      startTimeOfGame: DateTime,
      playByPlayRecords: PlayByPlayModel[],
      gameDescription: string,
  ): string {

    const firstHalfCsv = playByPlayRecords.filter(pbp => pbp.eventTime.halftime === 'T1').reduce(csvRowFactoryFn(
        gameDescription,
        0,
    ), createHeader());
    const secondHalf = playByPlayRecords.filter(pbp => pbp.eventTime.halftime === 'T2');
    let fullCsv: string;
    if (secondHalf.length > 0) {
      const offsetSecondHalf = secondHalf[0].eventTime.secondsSinceStartOfGame - secondHalf[0].eventTime.secondsSinceHalftime;
      fullCsv = secondHalf.reduce(csvRowFactoryFn(
          gameDescription,
          offsetSecondHalf
      ), firstHalfCsv);
    } else {
      fullCsv = firstHalfCsv;
    }
    return fullCsv;
  }

  csvRequest(gameId) {
    return new Promise(async (ok, ko) => {
      await this._core.loadingService.present();
      const aid = await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) => this.externalAccountEquipoService
              .accountEquipoHandlerControllerRequestGameCsv(user.id.toString(), aid.toString(), gameId)),
          finalize(async () => await this._core.loadingService.dismiss())
        )
        .subscribe(() => { ok(true); });
    });
  }

  csvRequestConsume(gameId) {
    return new Promise(async (ok, ko) => {
      await this._core.loadingService.present();
      const aid = await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) => this.externalAccountEquipoService
              .accountEquipoHandlerControllerRequestCsvConsume(user.id.toString(), aid.toString(), gameId)),
          finalize(async () => await this._core.loadingService.dismiss())
        )
        .subscribe(() => {ok(true)});
    });
  }

  exportArrayOfObjectsToCSV(dataArray: any[], fileName: string) {
    const csvData = this.convertToCSV(dataArray);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

    fileSaver.saveAs(blob,  fileName + '.csv');
  }

  private convertToCSV(dataArray: any[]): string {
    const header = Object.keys(dataArray[0]).join(',');
    const body = dataArray.map(obj => Object.values(obj).join(',')).join('\n');
    return `${header}\n${body}`;
  }

}
