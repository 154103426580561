<!-- <div class="w-full ion-text-center my-4">
    <ion-icon name="time-outline" class="global__icon-text timer-popover__headline mr-3"></ion-icon>
    <span class="global__icon-text timer-popover__headline">{{'Network Issues'|translate}}</span>
</div>
<hr class="mx-auto my-2">
<div class="flex flex-row timer-popover__content-text my-4">
    <div style="color: black;">
        {{'The game its saved at your device, but the connection to the server was lost during the game. don\'t worry! Please login and click "Sync Data". After that the game will be correctly saved.'|translate}}
    </div>

    <button class="timer-popover__button focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full p-1 rounded-3xl mr-0.5"
        (click)="dismiss()">
        <div class="flex items-center">
        <div class="w-1/5">
            <ion-icon name="log-in-outline" class="global__icon-text"></ion-icon>
        </div>
        <div class="global__icon-text w-4/5">{{'Login'|translate}}</div>
        </div>
    </button>
</div> -->

<div class="network-modal-wrapper my-6">
    <span class="title">{{'Network Issues'|translate}}</span>
    <hr>
    <span class="explanation-text">
        {{'The game its saved at your device, but the connection to the server was lost during the game. don\'t worry! Please login and click "Sync Data". After that the game will be correctly saved.'|translate}}
    </span>
    <div class="button-wrapper mt-6">
        <ion-button (click)="dismiss()" color="primary">{{'Login'|translate}}</ion-button>
    </div>
</div>