/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlanPermsDto } from './planPermsDto';


export interface ManageAccountDto { 
    /**
     * acount id
     */
    id?: number;
    /**
     * Name of the account
     */
    name: string;
    /**
     * Account permissions definition
     */
    permissions: PlanPermsDto;
    /**
     * Renewal Price in €
     */
    renewalPrice: number;
    /**
     * Renewal Period (monthly/yearly)
     */
    renewalPeriod: string;
    /**
     * Next Renewal Date
     */
    renewalDate: string;
    /**
     * User id for account administrator
     */
    administratorId: number;
    /**
     * Indicates if have a valid stripe subscription
     */
    activeSubscription?: boolean;
}

