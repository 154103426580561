<div class="teams-stats">
  <span *ngIf="!isPublic && !(selectedAccountEquipo$|async)?.account?.permissions?.playerScore">
    {{'Available from plan'|translate}}: <b>team</b>
  </span>

  <div class="grid flex-auto"
    [class.disabled_section]="!isPublic && !(selectedAccountEquipo$|async)?.account?.permissions?.playerScore"
  >

    <!-- selectteam -->
    <div class="flex flex-col w-full">
      <app-custom-tabs class="page-tabs flex-auto" (selection)="setTeamSelected($event)">
        <app-custom-tab [label]="homeTeamName" [fullHeight]="true" [selected]="selectedTeam.name" [defaultTab]="true"></app-custom-tab>
        <app-custom-tab [label]="visitorName" [fullHeight]="true" [selected]="selectedTeam.name"></app-custom-tab>
      </app-custom-tabs>
      <!-- selecttable -->
      <app-custom-tabs class="page-tabs flex-auto" (selection)="setTableSelected($event)">
        <app-custom-tab [label]="'Players Table'|translate" [fullHeight]="true" [selected]="selectedTable|translate" [defaultTab]="true"></app-custom-tab>
        <app-custom-tab [label]="'Player Events Table'|translate" [fullHeight]="true" [selected]="selectedTable|translate"></app-custom-tab>
        <app-custom-tab [label]="'Team Events Table'|translate" [fullHeight]="true" [selected]="selectedTable|translate"></app-custom-tab>
      </app-custom-tabs>
    </div>


    <div class="w-full pr-1" *ngIf="selectedTeam.code==='home'">
      <div class="legend">
        <h5>{{homeTeamName}}</h5>
      </div>
      <div *ngIf="selectedTable !== ('Team Events Table'|translate) && core.isMobileDevice" class="expand-toggle">
        <ion-toggle [(ngModel)]="tablesAllColsShown" ></ion-toggle>
      </div>
      <ngx-datatable *ngIf="(translationsInited$|async) && selectedTable==('Players Table'|translate)"
        class="material"
        style="height: 100%;"
        [rows]="homeTeamOverStats"
        [columns]="columns"
        [columnMode]="'flex'"
        [limit]="50"
        rowHeight="auto"
        [scrollbarH]="true"
        [footerHeight]="50"
        [headerHeight]="50"
      >
        <ngx-datatable-column name="playerName"
                              [frozenLeft]="true"
                              [flexGrow]="2"
                              [sortable]="false"
                              [resizeable]="false"
                              [headerClass]="'column-left'">
          <ng-template let-column="column" ngx-datatable-header-template>
            {{'Player Name'|translate}}
          </ng-template>
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <div class="position-edit">
              <p>
                {{value}}
              </p>
              <ion-icon *ngIf="core.gameDataService.game$ | async" [tooltip]="'Edit Position' | translate" (click)="onEditPositionClick(row)"
                        class="edit-btn" name="pencil-outline"></ion-icon>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="playedPossessions"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false" *ngIf="gameMode === 'COMPLETE_MODE'">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>PLPOS
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('PLPOS')"></ion-icon>
            </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
            <progress [value]="(value / homeTeamOverStatsMax.playedPossessions * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="possessionEvents"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>POSE
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('POSE')"></ion-icon>
            </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
            <progress [value]="(value / homeTeamOverStatsMax.possessionEvents * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="totalMinutes"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false" *ngIf="gameMode === 'COMPLETE_MODE'">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>TM
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('TM')"></ion-icon>
            </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{formatPlaytime(value)}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="goals"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>TG
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('TG')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
            <progress [value]="(value / homeTeamOverStatsMax.goals * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="failedShots"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>FS
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('FS')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
            <progress [value]="(value / homeTeamOverStatsMax.failedShots * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="goalsReceived"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>RG
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('RG')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{calculateReceivedGoals(row)}}
            <progress *ngIf="row.playerName.includes(' - gk')" [value]="(value / homeTeamOverStatsMax.goalsReceived * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="saves"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>S
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('S')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
           {{calculateSaves(row)}}
            <progress *ngIf="row.playerName.includes(' - gk')" [value]="(value / homeTeamOverStatsMax.saves * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="postOuts"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>PO
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('PO')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
            <progress [value]="(value / homeTeamOverStatsMax.postOuts * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="lostBalls"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>LB
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('LB')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
            <progress [value]="(value / homeTeamOverStatsMax.lostBalls * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="percentageLostBallsPossEvents"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>LB%
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('LB%')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | number: '1.0-0'}}%
            <progress [value]="(value / homeTeamOverStatsMax.percentageLostBallsPossEvents * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="percentageEfficiencyPossEvent"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>EFF%
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('EFF%')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{calculateEfficiency(row)}}
            <progress *ngIf="!row.playerName.includes(' - gk')" [value]="(value / homeTeamOverStatsMax.percentageEfficiencyPossEvent * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="percentageShotEfficiency"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>SHOTEF%
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('SHOTEF%')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{calculateShotEff(row)}}
            <progress *ngIf="!row.playerName.includes(' - gk')" [value]="(value / homeTeamOverStatsMax.percentageShotEfficiency * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="playerScore"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>{{'Score'|translate}}</span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value | number: '1.0-1'}}</span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>

      <ngx-datatable *ngIf="(translationsInited$|async) && selectedTable==('Player Events Table'|translate)"
        class="material span-minwidth"
        style="height: 100%;"
        [rows]="homePlayerEventsTbl$|async"
        [columns]="plEventsColumns"
        [columnMode]="'force'"
        [limit]="50"
        rowHeight="auto"
        [scrollbarH]="true"
        [footerHeight]="50"
        [headerHeight]="50"
      >
        <ngx-datatable-column prop="playerName"
                              [frozenLeft]="true"
                              [sortable]="false"
                              [resizeable]="false"
                              [width]="180"
                              [headerClass]="'column-left'">
          <ng-template let-column="column" ngx-datatable-header-template>
            {{'Player Name'|translate}}
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column prop="totalMinutes"
                              [sortable]="false"
                              [resizeable]="false" [width]="30" *ngIf="gameMode === 'COMPLETE_MODE'">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>TM</span>
            <span><ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>

          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{formatPlaytime(value)}}</span>
          </ng-template>
        </ngx-datatable-column>
          <ngx-datatable-column prop="attackFaultProvoke"
                                [sortable]="false"
                                [resizeable]="false" [width]="30">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>AF Prov</span>
              <span><ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="attackFaultCommit"
                                [sortable]="false"
                                [resizeable]="false" [width]="30">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>AF Com</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="technicalMistakeProvoke"
                                [sortable]="false"
                                [resizeable]="false" [width]="30">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>TeM Prov</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="technicalMistakeCommit"
                                [sortable]="false"
                                [resizeable]="false" [width]="30">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>TeM Com</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="lostBallProvoke"
                                [sortable]="false"
                                [resizeable]="false" [width]="30">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>LB Prov</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="lostBallCommit"
                              [sortable]="false"
                              [resizeable]="false" [width]="30">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>LB Com</span>
            <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value||''}}</span>
          </ng-template>
        </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="twoMinProvoke"
                                [sortable]="false"
                                [resizeable]="false" [width]="30">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>2m Prov</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="twoMinCommit"
                                [sortable]="false"
                                [resizeable]="false" [width]="30">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>2m Com</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="sevenMetersProvoke"
                                [sortable]="false"
                                [resizeable]="false" [width]="30">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>7m Prov</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="sevenMetersCommit"
                                [sortable]="false"
                                [resizeable]="false" [width]="30">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>7m Com</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="sevenMSuspProvoke"
                                [sortable]="false"
                                [resizeable]="false" [width]="30">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>7mS Prov</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="sevenMSuspCommit"
                                [sortable]="false"
                                [resizeable]="false" [width]="30">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>7mS Com</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="oneAndOneLost"
                                [sortable]="false"
                                [resizeable]="false" [width]="30">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>1&1 Lost</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="oneAndOneWon"
                                [sortable]="false"
                                [resizeable]="false" [width]="30">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>1&1 Won</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="foulReceive"
                              [sortable]="false"
                              [resizeable]="false" [width]="30">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>F Rec</span>
            <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value||''}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="(!core.isMobileDevice || tablesAllColsShown)" prop="foulCommit"
                              [sortable]="false"
                              [resizeable]="false" [width]="30">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>F Com</span>
            <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value||''}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="(!core.isMobileDevice || tablesAllColsShown)" prop="shotBlock"
                              [sortable]="false"
                              [resizeable]="false" [width]="30">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>SB</span>
           <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value||''}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="shotBlocked"
                              [sortable]="false"
                              [resizeable]="false" [width]="30">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>SBd</span>
           <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value||''}}</span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>

      <div class="flex flex-col lg:flex-row w-full" *ngIf="(translationsInited$|async) && selectedTable==('Team Events Table'|translate)">
        <div class="w-full lg:w-1/2 pl-2">
          <ngx-datatable
            style="width: 100%; box-shadow: none"
            class="material"
            [rows]="homeEventsTblLeft$ | async"
            [columns]="totalAvg"
            [columnMode]="'flex'"
            [limit]="10"
            rowHeight="auto"
            [scrollbarH]="true"
            [footerHeight]="false"
            [headerHeight]="50"
          >
          <ngx-datatable-column
                              [prop]="'template'"
                              [frozenLeft]="true"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
                    <ngx-datatable-column prop="total"
                              [frozenLeft]="false"
                              [flexGrow]="1"
                              [sortable]="true"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>{{'Total'|translate}}</span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value}}</span>
          </ng-template>
        </ngx-datatable-column>
          </ngx-datatable>
        </div>
        <div class="w-full lg:w-1/2 pl-2 mt-4 lg:mt-0">
          <ngx-datatable
            style="width: 100%; box-shadow: none"
            class="material"
            [rows]="homeEventsTblRight$ | async"
            [columns]="totalAvg"
            [columnMode]="'flex'"
            [limit]="10"
            rowHeight="auto"
            [scrollbarH]="true"
            [footerHeight]="false"
            [headerHeight]="50"
          >
          <ngx-datatable-column
                              [prop]="'template'"
                              [frozenLeft]="true"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
                    <ngx-datatable-column prop="total"
                              [frozenLeft]="false"
                              [flexGrow]="1"
                              [sortable]="true"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>{{'Total'|translate}}</span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value}}</span>
          </ng-template>
        </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>

    <div class="w-full pr-1" *ngIf="selectedTeam.code==='visitor'">

      <div class="legend">
        <h5>{{visitorName}}</h5>
      </div>

      <div *ngIf="selectedTable !== ('Team Events Table'|translate) && core.isMobileDevice" class="expand-toggle">
        <ion-toggle [(ngModel)]="tablesAllColsShown" ></ion-toggle>
      </div>
      <ngx-datatable *ngIf="(translationsInited$|async) && selectedTable==('Players Table'|translate)"
        class="material"
        [rows]="visitorTeamOverStats"
        [columns]="columns"
        [columnMode]="'flex'"
        [limit]="50"
        rowHeight="auto"
        [scrollbarH]="true"
        [footerHeight]="50"
        [headerHeight]="50"

      >
        <ngx-datatable-column name="playerName"
                              [frozenLeft]="true"
                              [flexGrow]="2"
                              [sortable]="false"
                              [resizeable]="false"
                              [headerClass]="'column-left'">
          <ng-template let-column="column" ngx-datatable-header-template>
            {{'Player Name'|translate}}
          </ng-template>
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <div class="position-edit">
              <p>
                {{value}}
              </p>
              <ion-icon *ngIf="core.gameDataService.game$ | async" [tooltip]="'Edit Position' | translate" (click)="onEditPositionClick(row)"
                        class="edit-btn" name="pencil-outline"></ion-icon>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="playedPossessions"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false" *ngIf="gameMode === 'COMPLETE_MODE'">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>PLPOS
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('PLPOS')"></ion-icon>
            </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
            <progress [value]="(value / visitorTeamOverStatsMax.playedPossessions * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="possessionEvents"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>POSE
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('POSE')"></ion-icon>
            </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
            <progress [value]="(value / visitorTeamOverStatsMax.possessionEvents * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="totalMinutes"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false" *ngIf="gameMode === 'COMPLETE_MODE'">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>TM
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('TM')"></ion-icon>
            </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{formatPlaytime(value)}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="goals"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>TG
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('TG')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
            <progress [value]="(value / visitorTeamOverStatsMax.goals * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="failedShots"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>FS
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('FS')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
            <progress [value]="(value / visitorTeamOverStatsMax.failedShots * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="goalsReceived"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>RG
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('RG')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{calculateReceivedGoals(row)}}
            <progress *ngIf="row.playerName.includes(' - gk')" [value]="(value / visitorTeamOverStatsMax.goalsReceived * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="saves"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>S
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('S')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{calculateSaves(row)}}
            <progress *ngIf="row.playerName.includes(' - gk')" [value]="(value / visitorTeamOverStatsMax.saves * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="postOuts"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>PO
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('PO')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
            <progress [value]="(value / visitorTeamOverStatsMax.postOuts * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="lostBalls"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>LB
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('LB')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
            <progress [value]="(value / visitorTeamOverStatsMax.lostBalls * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="percentageLostBallsPossEvents"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>LB%
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('LB%')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | number: '1.0-0'}}%
            <progress [value]="(value / visitorTeamOverStatsMax.percentageLostBallsPossEvents * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="percentageEfficiencyPossEvent"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>EFF%
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('EFF%')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{calculateEfficiency(row)}}
            <progress *ngIf="!row.playerName.includes(' - gk')" [value]="(value / visitorTeamOverStatsMax.percentageEfficiencyPossEvent * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="percentageShotEfficiency"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>SHOTEF%
            <ion-icon name="help-circle-outline" [tooltip]="core.getShortTooltip('SHOTEF%')"></ion-icon>
          </span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{calculateShotEff(row)}}
            <progress *ngIf="!row.playerName.includes(' - gk')" [value]="(value / visitorTeamOverStatsMax.percentageShotEfficiency * 100) || 0" max="100"></progress>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" name="playerScore"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>{{'Score'|translate}}</span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value | number: '1.0-1'}}</span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>

      <ngx-datatable *ngIf="(translationsInited$|async) && selectedTable==('Player Events Table'|translate)"
        class="material span-minwidth"
        style="height: 100%;"
        [rows]="visitorPlayerEventsTbl$|async"
        [columns]="plEventsColumns"
        [columnMode]="'force'"
        [limit]="50"
        rowHeight="auto"
        [scrollbarH]="true"
        [footerHeight]="50"
        [headerHeight]="50"
      >
        <ngx-datatable-column prop="playerName"
                              [frozenLeft]="true"
                              [sortable]="false"
                              [resizeable]="false" [width]="180"
                              [headerClass]="'column-left'">
          <ng-template let-column="column" ngx-datatable-header-template>
            {{'Player Name'|translate}}
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column prop="totalMinutes"
                              [width]="30"
                              [sortable]="false"
                              [resizeable]="false" *ngIf="gameMode === 'COMPLETE_MODE'">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>TM</span>
            <span><ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{formatPlaytime(value)}}</span>
          </ng-template>
        </ngx-datatable-column>
          <ngx-datatable-column prop="attackFaultProvoke"
                                [width]="30"
                                [sortable]="false"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>AF Prov</span>
              <span><ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="attackFaultCommit"
                                [width]="30"
                                [sortable]="false"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>AF Com</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="technicalMistakeProvoke"
                                [width]="30"
                                [sortable]="false"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>TeM Prov</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="technicalMistakeCommit"
                                [width]="30"
                                [sortable]="false"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>TeM Com</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="lostBallProvoke"
                                [width]="30"
                                [sortable]="false"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>LB Prov</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="lostBallCommit"
                              [width]="30"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>LB Com</span>
            <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value||''}}</span>
          </ng-template>
        </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="twoMinProvoke"
                                [width]="30"
                                [sortable]="false"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>2m Prov</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="twoMinCommit"
                                [width]="30"
                                [sortable]="false"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>2m Com</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="sevenMetersProvoke"
                                [width]="30"
                                [sortable]="false"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>7m Prov</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="sevenMetersCommit"
                                [width]="30"
                                [sortable]="false"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>7m Com</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="sevenMSuspProvoke"
                                [width]="30"
                                [sortable]="false"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>7mS Prov</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="sevenMSuspCommit"
                                [width]="30"
                                [sortable]="false"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>7mS Com</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="oneAndOneLost"
                                [width]="30"
                                [sortable]="false"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>1&1 Lost</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="oneAndOneWon"
                                [width]="30"
                                [sortable]="false"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>1&1 Won</span>
              <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span>{{value||''}}</span>
            </ng-template>
          </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="foulReceive"
                              [width]="30"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>F Rec</span>
            <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value||''}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="(!core.isMobileDevice || tablesAllColsShown)" prop="foulCommit"
                              [width]="30"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>F Com</span>
           <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value||''}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="(!core.isMobileDevice || tablesAllColsShown)" prop="shotBlock"
                              [width]="30"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>SB</span>
           <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value||''}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="shotBlocked"
                              [width]="30"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>SBd</span>
           <span> <ion-icon name="help-circle-outline" [tooltip]="column.name"></ion-icon></span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value||''}}</span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>

      <div class="flex flex-col lg:flex-row w-full" *ngIf="(translationsInited$|async) && selectedTable==('Team Events Table'|translate)">
        <div class="w-full lg:w-1/2 pl-2">
          <ngx-datatable
            style="width: 100%; box-shadow: none"
            class="material"
            [rows]="visitorEventsTblLeft$ | async"
            [columns]="totalAvg"
            [columnMode]="'flex'"
            [limit]="10"
            rowHeight="auto"
            [scrollbarH]="true"
            [footerHeight]="false"
            [headerHeight]="50"
          >
          <ngx-datatable-column
                              [prop]="'template'"
                              [frozenLeft]="true"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
                    <ngx-datatable-column prop="total"
                              [frozenLeft]="false"
                              [flexGrow]="1"
                              [sortable]="true"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>{{'Total'|translate}}</span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value}}</span>
          </ng-template>
        </ngx-datatable-column>
          </ngx-datatable>
        </div>
        <div class="w-full lg:w-1/2 pl-2 mt-4 lg:mt-0">
          <ngx-datatable
            style="width: 100%; box-shadow: none"
            class="material"
            [rows]="visitorEventsTblRight$ | async"
            [columns]="totalAvg"
            [columnMode]="'flex'"
            [limit]="10"
            rowHeight="auto"
            [scrollbarH]="true"
            [footerHeight]="false"
            [headerHeight]="50"
          >
          <ngx-datatable-column
                              [prop]="'template'"
                              [frozenLeft]="true"
                              [flexGrow]="1"
                              [sortable]="false"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
                    <ngx-datatable-column prop="total"
                              [frozenLeft]="false"
                              [flexGrow]="1"
                              [sortable]="true"
                              [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <span>{{'Total'|translate}}</span>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value}}</span>
          </ng-template>
        </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
