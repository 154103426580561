import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CounterModel } from '@handballai/stats-calculation';
import { ExternalGoalkeeperEfficiencyModel } from '@handballai/stats-calculation';
import { NGXLogger } from 'ngx-logger';
import { EfficiencyTableModel } from 'src/app/main/components/shots-stats/shot-stats.model';
import { transformExternalModelToTableModel } from 'src/app/main/components/shots-stats/shot-stats.helper';
import { Color } from 'ng2-charts';
import { CoreService } from 'src/app/shared-services/core.service';

@Component({
  selector: 'app-shots-stats',
  templateUrl: './shots-stats.component.html',
  styleUrls: ['./shots-stats.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShotsStatsComponent implements OnInit {

  public cOptions: any = {
    title: {
      text: 'SAVES / GOALS %',
      display: true,
      fontSize: 20,
      fontColor: '#0e375f'
    },
    plugins: {
      datalabels: { font: { size: 80 }},
      labels: {
        fontSize: 14,
        fontColor: '#0e375f',
        render: (args: { value: number; percentage: number; label: string }) => `${args.value} [${args.percentage}%]`
      }
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const datasetLabel = data.labels[tooltipItem.index];
          const datasetValue = data.datasets[0].data[tooltipItem.index];

          const dataset = data.datasets[0];
          const value = dataset.data[tooltipItem.index] as number;
          const dataValues: number[] = dataset.data as number[];

          const totalDataValue = dataValues.reduce((sum, currentValue) => sum + currentValue, 0);
          const percentage = (value / totalDataValue) * 100;

          return `${datasetLabel}: ${datasetValue} [${percentage.toFixed(0)}%]`;
        }
      }
    },
  };
  public c2Options: any = {
    title: {
      text: 'GOALS / NO GOALS %',
      display: true,
      fontSize: 20,
      fontColor: '#0e375f'
    },
    plugins: {
      datalabels: { font: { size: 80 }},
      labels: {
        fontSize: 14,
        fontColor: '#0e375f',
        render: (args: { value: number; percentage: number; label: string }) => `${args.value} [${args.percentage}%]`
      }
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const datasetLabel = data.labels[tooltipItem.index];
          const datasetValue = data.datasets[0].data[tooltipItem.index];

          const dataset = data.datasets[0];
          const value = dataset.data[tooltipItem.index] as number;
          const dataValues: number[] = dataset.data as number[];

          const totalDataValue = dataValues.reduce((sum, currentValue) => sum + currentValue, 0);
          const percentage = (value / totalDataValue) * 100;

          return `${datasetLabel}: ${datasetValue} [${percentage.toFixed(0)}%]`;
        }
      }
    },
  };
  public circleColors: Color[] = [{ backgroundColor: ['#ECB22E', '#2EB67D'] }];
  public circleColors2: Color[] = [{ backgroundColor: ['#ECB22E', '#2EB67D'] }];
  public homeGoalSavesValues: number[] = [0, 0];
  public homeGoalNoGoalValues: number[] = [0, 0];
  public visitorGoalSavesValues: number[] = [0, 0];
  public visitorGoalNoGoalValues: number[] = [0, 0];



  columns = [
    { prop: 'goalkeeperLabel', name: '', sortable: false, frozenLeft: true, resizable: false },
    { prop: 'total', resizable: false },
    { prop: 'min15eff', resizable: false },
    { prop: 'min30eff', resizable: false },
    { prop: 'min45eff', resizable: false },
    { prop: 'min60eff', resizable: false },
  ];

  columnsFH = [
    { prop: 'goalkeeperLabel', name: '', sortable: false, frozenLeft: true, resizable: false },
    { prop: 'min10eff', resizable: false },
    { prop: 'min20eff', resizable: false },
    { prop: 'min30eff', resizable: false },
    { prop: 'extraFHeff', resizable: false },
    { prop: 'totalFH', resizable: false },
  ];
  columnsSH = [
    { prop: 'goalkeeperLabel', name: '', sortable: false, frozenLeft: true, resizable: false },
    { prop: 'min40eff', resizable: false },
    { prop: 'min50eff', resizable: false },
    { prop: 'min60eff', resizable: false },
    { prop: 'extraSHeff', resizable: false },
    { prop: 'total', resizable: false },
  ];

  private _homeGoalCounters: CounterModel[] = [];
  private _visitorGoalCounters: CounterModel[] = [];
  private _homeGoalkeeperEfficiencyCounters: ExternalGoalkeeperEfficiencyModel[];
  private _visitorGoalkeeperEfficiencyCounters: ExternalGoalkeeperEfficiencyModel[];
  private _homeEfficiencyTableModel: EfficiencyTableModel[] = [];
  private _visitorEfficiencyTableModel: EfficiencyTableModel[] = [];
  private _homeTeamLabel = '';
  private _visitorTeamLabel = '';
  private _homeGoalConnectionCounter: CounterModel[] = [];
  private _visitorGoalConnectionCounter: CounterModel[] = [];
  public tablesAllColsShown = false;
  public tablesGKAllColsShown = false;

  constructor(
    public readonly core: CoreService,
    private readonly logger: NGXLogger,
  ) { }

  ngOnInit(): void { }


  get homeGoalCounters(): CounterModel[] {
    return this._homeGoalCounters;
  }

  @Input()
  set homeGoalCounters(value: CounterModel[]) {
    this._homeGoalCounters = value;
    this.logger.debug('ShotsStatsComponent.homeGoalCounters', value);
    if (value.length > 1) {
      this.visitorGoalSavesValues = [
        value.filter(c => c.counterType === 'GOAL')[0].counter,
        value.filter(c => c.counterType === 'SAVE')[0].counter,
      ];
      this.visitorGoalNoGoalValues = [
        value.filter(c => c.counterType === 'GOAL')[0].counter,
        value.filter(c => c.counterType === 'POST_OUT')[0].counter
        + value.filter(c => c.counterType == 'SAVE')[0].counter
      ];
    }
  }

  get visitorGoalCounters(): CounterModel[] {
    return this._visitorGoalCounters;
  }

  public onExpandTableColumnsClick() {
    this.tablesAllColsShown = !this.tablesAllColsShown;
  }

  public onExpandGKTableColumnsClick() {
    this.tablesGKAllColsShown = !this.tablesGKAllColsShown;
  }

  @Input()
  set visitorGoalCounters(value: CounterModel[]) {
    this._visitorGoalCounters = value;
    this.logger.debug('ShotsStatsComponent.visitorGoalCounters', value);
    if (value.length > 1) {
      this.homeGoalSavesValues = [
        value.filter(c => c.counterType === 'GOAL')[0].counter,
        value.filter(c => c.counterType === 'SAVE')[0].counter,
      ];
      this.homeGoalNoGoalValues = [
        value.filter(c => c.counterType === 'GOAL')[0].counter,
        value.filter(c => c.counterType === 'POST_OUT')[0].counter
        + value.filter(c => c.counterType == 'SAVE')[0].counter
      ];
    }
  }


  get homeGoalConnectionCounter(): CounterModel[] {
    return this._homeGoalConnectionCounter;
  }

  @Input()
  set homeGoalConnectionCounter(value: CounterModel[]) {
    this._homeGoalConnectionCounter = value;
  }

  get visitorGoalConnectionCounter(): CounterModel[] {
    return this._visitorGoalConnectionCounter;
  }

  @Input()
  set visitorGoalConnectionCounter(value: CounterModel[]) {
    this._visitorGoalConnectionCounter = value;
  }

  get homeGoalkeeperEfficiencyCounters(): ExternalGoalkeeperEfficiencyModel[] {
    return this._homeGoalkeeperEfficiencyCounters;
  }

  @Input()
  set homeGoalkeeperEfficiencyCounters(value: ExternalGoalkeeperEfficiencyModel[]) {
    this._homeGoalkeeperEfficiencyCounters = value;
    this.logger.debug('HomeGoalKeeperEfficiency: ', value);
    this._homeEfficiencyTableModel = [...this._homeGoalkeeperEfficiencyCounters]
        .map(val => transformExternalModelToTableModel(val));
  }

  get visitorGoalkeeperEfficiencyCounters(): ExternalGoalkeeperEfficiencyModel[] {
    return this._visitorGoalkeeperEfficiencyCounters;
  }

  @Input()
  set visitorGoalkeeperEfficiencyCounters(value: ExternalGoalkeeperEfficiencyModel[]) {
    this._visitorGoalkeeperEfficiencyCounters = value;
    this.logger.debug('VisitorGoalKeeperEfficiency: ', value);
    this._visitorEfficiencyTableModel = [...this._visitorGoalkeeperEfficiencyCounters]
        .map(val => transformExternalModelToTableModel(val));
  }

  get homeEfficiencyTableModel(): EfficiencyTableModel[] {
    return this._homeEfficiencyTableModel;
  }

  get visitorEfficiencyTableModel(): EfficiencyTableModel[] {
    return this._visitorEfficiencyTableModel;
  }

  get homeTeamLabel(): string {
    return this._homeTeamLabel;
  }

  @Input()
  set homeTeamLabel(value: string) {
    if (value && value.length > 0) {
      this._homeTeamLabel = value;
    }
  }

  get visitorTeamLabel(): string {
    return this._visitorTeamLabel;
  }

  @Input()
  set visitorTeamLabel(value: string) {
    if (value && value.length > 0) {
      this._visitorTeamLabel = value;
    }
  }

}

