import { UiBaseEvent, UiEventType } from './ui-base-event';
import { GamePlayerModel } from '@handballai/stats-calculation';
import { TeamMarker } from '../../game/team-marker';
import {
    DefensePlayerActionTypes,
    GoalActionTypes,
    GoalZoneTypes,
    OffensePlayerActionTypes, PhasesActionTypes,
    PostOutZoneTypes,
    ExecutionPositionTypes, SuspensionActionTypes, SuspensionToBenchActionTypes
} from '../../actions/action-types';
import { EventTime } from '@handballai/stats-calculation';
import { GameType } from 'src/app/shared-services/game/game-type.model';
import { TimeOutEvent } from 'src/app/shared-services/game/events/time-out-event';

export interface GamePlayerEventPayload {
    playerModel: GamePlayerModel;
    teamMarker: TeamMarker;
}

export interface ShowGoalPopupEventPayload {
    goalActionType: GoalActionTypes;
    videoSrc?: string;
    videoCurrentTime?: number;
    homeTeamColor?: string;
    visitorTeamColor?: string;
}

export interface ShowVideoPopupEventPayload {
    src: string;
    videoCurrentTime:number;
}

export interface GoalPopupSaveEventPayload {
    goalActionType: GoalActionTypes;
    playerScored: GamePlayerModel;
    playerAssist?: GamePlayerModel;
    goalZone?: GoalZoneTypes;
    postOutZone?: PostOutZoneTypes;
    executionPosition?: ExecutionPositionTypes;
    important: boolean;
    gameSystem?: string;
}

export interface ShowDoubleEventPopupEventPayload {
    actionType: OffensePlayerActionTypes | DefensePlayerActionTypes;
    videoSrc?: string;
    requirePlayerSelect?: boolean;
    videoCurrentTime?: number;
}

export interface DoubleEventPopupEventSavedPayload {
    suspensionActionType?: SuspensionActionTypes;
    opponentPlayerSelected?: GamePlayerModel;
    executionPosition?: ExecutionPositionTypes;
    sourceAction: OffensePlayerActionTypes | DefensePlayerActionTypes | SuspensionToBenchActionTypes;
    gameSystem?: string;
}

export interface PhaseSelectedEventPayload {
    phaseSelected: PhasesActionTypes;
}

export interface SuspensionSelectedEventPayload {
    suspensionSelected: SuspensionActionTypes;
}

export interface ShowSubstitutePopupEventPayload {
    playerModel: GamePlayerModel;
    teamMarker: TeamMarker;
    suspensionAction: SuspensionActionTypes;
}

export interface SubstitutePopupSavedEventPayload {
    playerModel: GamePlayerModel;
    teamMarker: TeamMarker;
    suspensionAction: SuspensionActionTypes;
    suspendedPlayer: GamePlayerModel;
}

export interface SubstituteGoalKeeperPopupSavedEventPayload {
    teamMarker: TeamMarker;
    suspendedPlayer: GamePlayerModel;
}

export interface SwapFreeSlotForPlayerLiteEventPayload {
    teamMarker: TeamMarker;
    slotId: number;
}

export interface SuspensionSelectedEventPayload {
    suspensionSelected: SuspensionActionTypes;
    gameSystem?: string;
}

export interface SuspensionToBenchSelectedEventPayload {
    suspensionSelected: SuspensionToBenchActionTypes;
    teamMarker: TeamMarker;
    gameType: GameType;
    videoSrc: string;
    videoCurrentTime?: number;
}

export interface DoubleEventPopupSuspensionToBenchSavePayload {
    suspensionSelected: SuspensionToBenchActionTypes;
    suspendedPlayer: GamePlayerModel;
    teamMarker: TeamMarker;
    executionPosition?: ExecutionPositionTypes;
}

export interface TimeoutEventPayload {
    timeOutEvent: TimeOutEvent;
    teamMarker: TeamMarker;
}

export class PlayerSelectEvent implements UiBaseEvent<GamePlayerEventPayload> {
    eventType: UiEventType;
    payload: GamePlayerEventPayload;
    timestamp: EventTime;

    constructor(payload: GamePlayerEventPayload, timestamp: EventTime) {
        this.eventType = 'PLAYER_SELECTED';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class PlayerDeSelectEvent implements UiBaseEvent<GamePlayerEventPayload> {
    eventType: UiEventType;
    payload: GamePlayerEventPayload;
    timestamp: EventTime;

    constructor(payload: GamePlayerEventPayload, timestamp: EventTime) {
        this.eventType = 'PLAYER_DESELECTED';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class ShowGoalPopupEvent implements UiBaseEvent<ShowGoalPopupEventPayload> {
    eventType: UiEventType;
    payload: ShowGoalPopupEventPayload;
    constructor(payload: ShowGoalPopupEventPayload) {
        this.eventType = 'SHOW_GOAL_POPUP';
        this.payload = payload;
    }
}

export class ShowVideoPopupEvent implements UiBaseEvent<ShowVideoPopupEventPayload> {
    eventType: UiEventType;
    payload: ShowVideoPopupEventPayload;
    constructor(payload: ShowVideoPopupEventPayload) {
        this.eventType = 'SHOW_VIDEO_POPUP';
        this.payload = payload;
    }
}

export class ShowGameSystemPopupEvent implements UiBaseEvent<ShowVideoPopupEventPayload> {
    eventType: UiEventType;
    payload: ShowVideoPopupEventPayload;
    constructor(payload: ShowVideoPopupEventPayload) {
        this.eventType = 'SHOW_GAME_SYSTEM_POPUP';
        this.payload = payload;
    }
}

export class PlayerExchangeEvent implements UiBaseEvent<GamePlayerEventPayload> {
    eventType: UiEventType;
    payload: GamePlayerEventPayload;
    timestamp: EventTime;

    constructor(payload: GamePlayerEventPayload, timeStamp: EventTime) {
        this.eventType = 'PLAYER_EXCHANGED';
        this.payload = payload;
        this.timestamp = timeStamp;
    }
}

export class GoalPopupCanceledEvent implements UiBaseEvent<any> {
    eventType: UiEventType;
    constructor() {
        this.eventType = 'GOAL_POPUP_CANCELED';
    }
}

export class GoalPopupSavedEvent implements UiBaseEvent<GoalPopupSaveEventPayload> {
    eventType: UiEventType;
    payload: GoalPopupSaveEventPayload;
    timestamp: EventTime;
    constructor(payload: GoalPopupSaveEventPayload, timestamp: EventTime) {
        this.eventType = 'GOAL_POPUP_SAVED';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class ShowDoubleEventPopupEvent implements UiBaseEvent<ShowDoubleEventPopupEventPayload> {
    eventType: UiEventType;
    payload: ShowDoubleEventPopupEventPayload;

    constructor(payload: ShowDoubleEventPopupEventPayload) {
        this.eventType = 'SHOW_DOUBLE_EVENT_POPUP';
        this.payload = payload;
    }
}

export class DoubleEventPopupCanceledEvent implements UiBaseEvent<any> {
    eventType: UiEventType;
    constructor() {
        this.eventType = 'DOUBLE_EVENT_POPUP_CANCELED';
    }
}

export class DoubleEventPopupSavedEvent implements UiBaseEvent<DoubleEventPopupEventSavedPayload> {
    eventType: UiEventType;
    payload: DoubleEventPopupEventSavedPayload;

    constructor(payload: DoubleEventPopupEventSavedPayload) {
        this.eventType = 'DOUBLE_EVENT_POPUP_SAVED';
        this.payload = payload;
    }
}

export class PhaseSelectedEvent implements UiBaseEvent<PhaseSelectedEventPayload> {
    eventType: UiEventType;
    payload: PhaseSelectedEventPayload;

    constructor(payload: PhaseSelectedEventPayload) {
        this.eventType = 'PHASE_EVENT_SELECTED';
        this.payload = payload;
    }
}

export class PhaseDeSelectedEvent implements UiBaseEvent<any> {
    eventType: UiEventType;
    constructor() {
        this.eventType = 'PHASE_EVENT_DESELECTED';
    }
}

export class SuspensionSelectedEvent implements UiBaseEvent<SuspensionSelectedEventPayload> {
    eventType: UiEventType;
    payload: SuspensionSelectedEventPayload;

    constructor(payload: SuspensionSelectedEventPayload) {
        this.eventType = 'SUSPENSION_EVENT_SELECTED';
        this.payload = payload;
    }
}

export class ShowSubstitutePopupEvent implements UiBaseEvent<ShowSubstitutePopupEventPayload> {
    eventType: UiEventType;
    payload: ShowSubstitutePopupEventPayload;
    timestamp: EventTime;

    constructor(payload: ShowSubstitutePopupEventPayload, timestamp: EventTime) {
        this.eventType = 'SHOW_SUBSTITUTE_POPUP';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class CancelSubstitutePopupEvent implements UiBaseEvent<ShowSubstitutePopupEventPayload> {
    eventType: UiEventType;
    payload: ShowSubstitutePopupEventPayload;
    timestamp: EventTime;

    constructor(payload: ShowSubstitutePopupEventPayload, timestamp: EventTime) {
        this.eventType = 'CANCEL_SUBSTITUTE_POPUP';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class SubstitutePopupSavedEvent implements UiBaseEvent<SubstitutePopupSavedEventPayload> {
    eventType: UiEventType;
    payload: SubstitutePopupSavedEventPayload;
    timestamp: EventTime;

    constructor(payload: SubstitutePopupSavedEventPayload, timestamp: EventTime) {
        this.eventType = 'SUBSTITUTE_POPUP_SAVED';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class PassiveButtonSelectedEvent implements UiBaseEvent<any> {
    eventType: UiEventType = 'PASSIVE_BUTTON_SELECTED';
}

export class PlayerSwapEvent implements UiBaseEvent<GamePlayerEventPayload> {
    eventType: UiEventType;
    payload: GamePlayerEventPayload;
    timestamp: EventTime;

    constructor(payload: GamePlayerEventPayload, timestamp: EventTime) {
        this.eventType = 'PLAYER_SWAPPED';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class SubstituteGoalKeeperPopupSavedEvent implements UiBaseEvent<SubstituteGoalKeeperPopupSavedEventPayload> {
    eventType: UiEventType;
    payload: SubstituteGoalKeeperPopupSavedEventPayload;
    timestamp: EventTime;

    constructor(payload: SubstituteGoalKeeperPopupSavedEventPayload, timestamp: EventTime) {
        this.eventType = 'POS_1_SUSPENSION_POPUP_SAVED';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class PlayerSelectLiteEvent implements UiBaseEvent<GamePlayerEventPayload> {
    eventType: UiEventType;
    payload: GamePlayerEventPayload;
    timestamp: EventTime;

    constructor(payload: GamePlayerEventPayload, timestamp: EventTime) {
        this.eventType = 'PLAYER_SELECTED_LITE';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class PlayerDeSelectLiteEvent implements UiBaseEvent<GamePlayerEventPayload> {
    eventType: UiEventType;
    payload: GamePlayerEventPayload;
    timestamp: EventTime;

    constructor(payload: GamePlayerEventPayload, timestamp: EventTime) {
        this.eventType = 'PLAYER_DESELECTED_LITE';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class GoalkeeperSelectLiteEvent implements UiBaseEvent<GamePlayerEventPayload> {
    eventType: UiEventType;
    payload: GamePlayerEventPayload;
    timestamp: EventTime;

    constructor(payload: GamePlayerEventPayload, timestamp: EventTime) {
        this.eventType = 'GOALKEEPER_SELECTED_LITE';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class GoalkeeperDeSelectLiteEvent implements UiBaseEvent<GamePlayerEventPayload> {
    eventType: UiEventType;
    payload: GamePlayerEventPayload;
    timestamp: EventTime;

    constructor(payload: GamePlayerEventPayload, timestamp: EventTime) {
        this.eventType = 'GOALKEEPER_DESELECTED_LITE';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class PlayerSwapLiteEvent implements UiBaseEvent<GamePlayerEventPayload> {
    eventType: UiEventType;
    payload: GamePlayerEventPayload;
    timestamp: EventTime;

    constructor(payload: GamePlayerEventPayload, timestamp: EventTime) {
        this.eventType = 'PLAYER_SWAPPED_LITE';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class GoalkeeperSwapLiteEvent implements UiBaseEvent<GamePlayerEventPayload> {
    eventType: UiEventType;
    payload: GamePlayerEventPayload;
    timestamp: EventTime;

    constructor(payload: GamePlayerEventPayload, timestamp: EventTime) {
        this.eventType = 'GOALKEEPER_SWAPPED_LITE';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class ShowDoubleEventPopupLiteEvent implements UiBaseEvent<ShowDoubleEventPopupEventPayload> {
    eventType: UiEventType;
    payload: ShowDoubleEventPopupEventPayload;

    constructor(payload: ShowDoubleEventPopupEventPayload) {
        this.eventType = 'SHOW_DOUBLE_EVENT_POPUP_LITE';
        this.payload = payload;
    }
}

export class DoubleEventPopupCanceledLiteEvent implements UiBaseEvent<any> {
    eventType: UiEventType;
    constructor() {
        this.eventType = 'DOUBLE_EVENT_POPUP_CANCELED_LITE';
    }
}

export class DoubleEventPopupSavedLiteEvent implements UiBaseEvent<DoubleEventPopupEventSavedPayload> {
    eventType: UiEventType;
    payload: DoubleEventPopupEventSavedPayload;

    constructor(payload: DoubleEventPopupEventSavedPayload) {
        this.eventType = 'DOUBLE_EVENT_POPUP_SAVED_LITE';
        this.payload = payload;
    }
}

export class SuspensionSelectedLiteEvent implements UiBaseEvent<SuspensionSelectedEventPayload> {
    eventType: UiEventType;
    payload: SuspensionSelectedEventPayload;

    constructor(payload: SuspensionSelectedEventPayload) {
        this.eventType = 'SUSPENSION_EVENT_SELECTED_LITE';
        this.payload = payload;
    }
}
export class BringBackSuspensionLiteEvent implements UiBaseEvent<SuspensionSelectedEventPayload> {
    eventType: UiEventType;
    payload: SuspensionSelectedEventPayload;

    constructor(payload: SuspensionSelectedEventPayload) {
        this.eventType = 'BRING_BACK_SUSPENSION_EVENT_LITE';
        this.payload = payload;
    }
}

export class SwapPlayerForFreeSlotLiteEvent implements UiBaseEvent<SwapFreeSlotForPlayerLiteEventPayload> {
    eventType: UiEventType;
    payload: SwapFreeSlotForPlayerLiteEventPayload;

    constructor(payload: SwapFreeSlotForPlayerLiteEventPayload) {
        this.eventType = 'SWAP_PLAYER_FOR_FREE_SLOT_EVENT_LITE';
        this.payload = payload;
    }
}


export class SuspensionToBenchSelectedEvent implements UiBaseEvent<SuspensionToBenchSelectedEventPayload> {
    eventType: UiEventType;
    payload: SuspensionToBenchSelectedEventPayload;
    timestamp: EventTime;


    constructor(payload: SuspensionToBenchSelectedEventPayload, timestamp: EventTime) {
        this.eventType = 'SUSPENSION_TO_BENCH_EVENT_SELECTED';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}

export class DoubleEventSuspensionToBenchSavedEvent implements UiBaseEvent<DoubleEventPopupSuspensionToBenchSavePayload> {
    eventType: UiEventType;
    payload: DoubleEventPopupSuspensionToBenchSavePayload;

    constructor(payload: DoubleEventPopupSuspensionToBenchSavePayload) {
        this.eventType = 'DOUBLE_EVENT_POPUP_SUSPENSION_TO_BENCH_SAVED';
        this.payload = payload;
    }
}

export class DoubleEventSuspensionToBenchSavedLiteEvent implements UiBaseEvent<DoubleEventPopupSuspensionToBenchSavePayload> {
    eventType: UiEventType;
    payload: DoubleEventPopupSuspensionToBenchSavePayload;

    constructor(payload: DoubleEventPopupSuspensionToBenchSavePayload) {
        this.eventType = 'DOUBLE_EVENT_POPUP_SUSPENSION_TO_BENCH_SAVED_LITE';
        this.payload = payload;
    }
}


export class TimeoutEvent implements UiBaseEvent<TimeoutEventPayload> {
    eventType: UiEventType;
    timestamp: EventTime;
    payload: TimeoutEventPayload;

    constructor(payload: TimeoutEventPayload, timestamp: EventTime) {
        this.eventType = 'TIMEOUT_EVENT';
        this.payload = payload;
        this.timestamp = timestamp;
    }
}
