import { Observable } from 'rxjs';
import { OverviewConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/overview/overview-consumer.service';

export class OverviewStatsStateHelper {

    private _overviewStatsStateMap = new Map<string, Observable<any>>();

    constructor(
        private readonly overviewConsumerService: OverviewConsumerService,
    ) {
        this._overviewStatsStateMap.set('overviewAggregatedModel', this.overviewConsumerService.overviewStatsAggregatedViewModel$);
        this._overviewStatsStateMap.set(
            'homeOverStatsByPhaseModelListPerDefenseSystem',
            this.overviewConsumerService.homeOverStatsByPhaseModelListPerDefenseSystem$
            );
        this._overviewStatsStateMap.set(
            'visitorOverStatsByPhaseModelListPerDefenseSystem',
            this.overviewConsumerService.visitorOverStatsByPhaseModelListPerDefenseSystem$
        );
        this._overviewStatsStateMap.set(
            'homeOverStatsOffenseSystemModelListPerDefenseSystem',
            this.overviewConsumerService.homeOverviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem$
        );
        this._overviewStatsStateMap.set(
            'visitorOverStatsOffenseSystemModelListPerDefenseSystem',
            this.overviewConsumerService.visitorOverviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem$
        );
    }

    public getObservableByName(name: string): Observable<any> {
        return this._overviewStatsStateMap.get(name);
    }

}
