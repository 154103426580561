import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { SystemPhaseSelection } from '../../../selector-check-box-list/selector-check-box-list.component';
import { TimeOfEventsSelection } from '../../../time-of-events/time-of-events.component';

@Component({
  templateUrl: './video-modal-extra-filters-popover.component.html',
  styleUrls: ['./video-modal-extra-filters-popover.component.scss'],
})
export class VideoModalExtraFiltersPopoverComponent implements OnInit {

  @Input()
  systemPhaseSelection: SystemPhaseSelection;
  @Input()
  timeOfEventsSelection: TimeOfEventsSelection;
  @Input() showOffenseDefenseFilter: boolean = false;

  @Input() selectionOffenseEvents: any;

  @Input() selectionDefenseEvents: any;

  @Input() selectionTimeEventsData: any;

  @Input() availableEvents: string[] = [];
  @Input() availableOffenseSystem: string[] = [];
  @Input() availablePhases: string[] = [];
  @Input() availableDefenseSystem: string[] = [];

  constructor(private popoverCntl: PopoverController) {}

  ngOnInit(): void {}

  dismiss() {
    this.popoverCntl.dismiss({
      systemPhaseSelection: this.systemPhaseSelection,
      timeOfEventsSelection: this.timeOfEventsSelection,
      selectionDefenseEvents: this.selectionDefenseEvents,
      selectionOffenseEvents: this.selectionOffenseEvents,
      selectionTimeEventsData: this.selectionTimeEventsData
    });
  }

}
