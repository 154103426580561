<!-- position goal -->
<!-- <div class="post-out-segment-top">
    <div>
      {{getShotLocationForShotsBySegment(0)}}
    </div>
</div>
<div class="goal-wrapper">
    <div class="post-out-segment-left">
        {{getShotLocationForShotsBySegment( 10)}}
    </div>
    <div class="grid goal" style="width: 85%;">
        <div
                class="positionGoal-cell {{ 'positionGoal-cell-v' + index }} grid-col (1/3)"
                *ngFor="let zone of [1, 2, 3, 4, 5, 6, 7, 8, 9]; let index = index"
        >
            <div class="rectangle">
            <span class="positionGoal-cell-content">
            {{getShotLocationForGoalsBySegment(zone)}}/{{getShotLocationForShotsBySegment(zone)}}
            </span>
            </div>
        </div>
    </div>
    <div class="post-out-segment-right">
        {{getShotLocationForShotsBySegment(11)}}
    </div>
</div> -->
<ng-container *ngIf="showShooterPosition()">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 776.568 435.942">
      <g id="Group_6" data-name="Group 6" transform="translate(41.943 53)">
        <g id="Path_7" data-name="Path 7" transform="translate(-41.721 -53)" fill="#0d385f">
          <path
            d="M 0.2783883810043335 435.442138671875 L 0.4996795356273651 0.5000303983688354 L 774.5906982421875 0.5000303983688354 L 775.8446655273438 435.2806396484375 L 0.2783883810043335 435.442138671875 Z"
            stroke="none"
          />
          <path
            d="M 0.99945068359375 1.000030517578125 L 0.77862548828125 434.9420166015625 L 775.34326171875 434.7807312011719 L 774.0919799804688 1.000030517578125 L 0.99945068359375 1.000030517578125 M -6.103515625e-05 3.0517578125e-05 L 775.089111328125 3.0517578125e-05 L 776.3461303710938 435.780517578125 L -0.22186279296875 435.9422302246094 L -6.103515625e-05 3.0517578125e-05 Z"
            stroke="none"
            fill="#fff"
          />
        </g>
        <!-- Goal Rectangles -->
        <path
          id="Rectangle_3"
          data-name="Rectangle 3"
          d="M0,0H22V42.454H0Z"
          transform="translate(47.55 340)"
          fill="#fff"
        />
        <rect
          id="Rectangle_4"
          data-name="Rectangle 4"
          width="22"
          height="48"
          transform="translate(47.55 229)"
          fill="#78cfe8"
        />
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="22"
          height="49"
          transform="translate(47.55 120)"
          fill="#fff"
        />
        <rect
          id="Rectangle_6"
          data-name="Rectangle 6"
          width="23"
          height="66"
          transform="translate(113.55 40) rotate(90)"
          fill="#427dc1"
        />
        <rect
          id="Rectangle_7"
          data-name="Rectangle 7"
          width="23"
          height="74"
          transform="translate(247.55 40) rotate(90)"
          fill="#427dc1"
        />
        <rect
          id="Rectangle_8"
          data-name="Rectangle 8"
          width="23"
          height="71"
          transform="translate(374.55 40) rotate(90)"
          fill="#427dc1"
        />
        <rect
          id="Rectangle_9"
          data-name="Rectangle 9"
          width="23"
          height="74"
          transform="translate(508.55 40) rotate(90)"
          fill="#427dc1"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="23"
          height="77"
          transform="translate(649.55 40) rotate(90)"
          fill="#427dc1"
        />
        <rect
          id="Rectangle_11"
          data-name="Rectangle 11"
          width="22"
          height="41"
          transform="translate(627.55 341)"
          fill="#fff"
        />
        <rect
          id="Rectangle_12"
          data-name="Rectangle 12"
          width="22"
          height="48"
          transform="translate(627.55 229)"
          fill="#fff"
        />
        <rect
          id="Rectangle_13"
          data-name="Rectangle 13"
          width="22"
          height="49"
          transform="translate(627.55 120)"
          fill="#fff"
        />
        <rect
          id="Rectangle_4-2"
          data-name="Rectangle 4"
          width="22"
          height="48"
          transform="translate(47.55 229)"
          fill="#fff"
        />
        <rect
          id="Rectangle_5-2"
          data-name="Rectangle 5"
          width="22"
          height="58"
          transform="translate(47.55 62)"
          fill="#427dc1"
        />
        <rect
          id="Rectangle_5-3"
          data-name="Rectangle 5"
          width="22"
          height="60"
          transform="translate(47.55 169)"
          fill="#427dc1"
        />
        <rect
          id="Rectangle_5-4"
          data-name="Rectangle 5"
          width="22"
          height="63"
          transform="translate(47.55 277)"
          fill="#427dc1"
        />
        <rect
          id="Rectangle_6-2"
          data-name="Rectangle 6"
          width="23"
          height="60"
          transform="translate(173.55 40) rotate(90)"
          fill="#fff"
        />
        <path
          id="Rectangle_6-3"
          data-name="Rectangle 6"
          d="M0,0H23V60H0Z"
          transform="translate(306.55 40) rotate(90)"
          fill="#fff"
        />
        <rect
          id="Rectangle_6-4"
          data-name="Rectangle 6"
          width="23"
          height="60"
          transform="translate(434.55 40) rotate(90)"
          fill="#fff"
        />
        <rect
          id="Rectangle_6-5"
          data-name="Rectangle 6"
          width="23"
          height="66"
          transform="translate(574.55 40) rotate(90)"
          fill="#fff"
        />
        <rect
          id="Rectangle_13-2"
          data-name="Rectangle 13"
          width="22"
          height="59"
          transform="translate(627.55 62)"
          fill="#427dc1"
        />
        <rect
          id="Rectangle_13-3"
          data-name="Rectangle 13"
          width="22"
          height="60"
          transform="translate(627.55 169)"
          fill="#427dc1"
        />
        <rect
          id="Rectangle_13-4"
          data-name="Rectangle 13"
          width="22"
          height="64"
          transform="translate(627.55 277)"
          fill="#427dc1"
        />

        <!-- TOP Segment -->
        <text
          text-anchor="middle"
          transform="translate(360 -4)"
          fill="#fff"
          font-size="30"
          font-family="HelveticaNeue, Helvetica Neue"
        >
          <tspan x="0" y="0" [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT', '0')">
            {{ getShotLocationForShotsBySegment(0) }}
          </tspan>
        </text>

        <!-- LEFT Segment -->
        <text
          text-anchor="middle"
          transform="translate(10 207)"
          fill="#fff"
          font-size="30"
          font-family="HelveticaNeue, Helvetica Neue"
        >
          <tspan x="0" y="0" [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT', '10')">
            {{ getShotLocationForShotsBySegment(10) }}
          </tspan>
        </text>

        <!-- RIGHT Segment -->
        <text
          text-anchor="middle"
          transform="translate(695 207)"
          fill="#fff"
          font-size="30"
          font-family="HelveticaNeue, Helvetica Neue"
        >
          <tspan x="0" y="0" [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT', '11')">
            {{ getShotLocationForShotsBySegment(11) }}
          </tspan>
        </text>

        <!-- Zones Segments -->
        <!--#e0dfdf-->
        <rect width="186" height="106" transform="translate(68.55 63)"
              [attr.fill]="calculateHeatMapColor((getShotLocationForGoalsBySegment(1) / getShotLocationForShotsBySegment(1)) * 100, true)"
        />
        <!--#0f375f-->
        <text
          text-anchor="middle"
          transform="translate(154.55 130)"
          fill="#fff"
          font-size="30"
          font-family="HelveticaNeue, Helvetica Neue"
        >
          <tspan x="0" y="0">
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('GOAL', '1')">{{ getShotLocationForGoalsBySegment(1) }}</tspan>
            /
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT', '1')">{{ getShotLocationForShotsBySegment(1) }}</tspan>
          </tspan>
        </text>

        <rect width="186" height="106" transform="translate(254.55 63)"
              [attr.fill]="calculateHeatMapColor((getShotLocationForGoalsBySegment(2) / getShotLocationForShotsBySegment(2)) * 100, true)"
        />
        <text
          text-anchor="middle"
          transform="translate(351.55 130)"
          fill="#fff"
          font-size="30"
          font-family="HelveticaNeue, Helvetica Neue"
        >
          <tspan x="0" y="0">
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('GOAL', '2')">{{ getShotLocationForGoalsBySegment(2) }}</tspan>
            /
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT', '2')">{{ getShotLocationForShotsBySegment(2) }}</tspan>
          </tspan>
        </text>

        <rect width="187" height="106" transform="translate(440.55 63)"
              [attr.fill]="calculateHeatMapColor((getShotLocationForGoalsBySegment(3) / getShotLocationForShotsBySegment(3)) * 100, true)"
        />
        <text
          text-anchor="middle"
          transform="translate(532.55 130)"
          fill="#fff"
          font-size="30"
          font-family="HelveticaNeue, Helvetica Neue"
        >
          <tspan x="0" y="0">
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('GOAL', '3')">{{ getShotLocationForGoalsBySegment(3) }}</tspan>
            /
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT', '3')">{{ getShotLocationForShotsBySegment(3) }}</tspan>
          </tspan>
        </text>

        <rect width="186" height="106" transform="translate(68.55 169)"
              [attr.fill]="calculateHeatMapColor((getShotLocationForGoalsBySegment(4) / getShotLocationForShotsBySegment(4)) * 100, true)"
        />
        <text
          text-anchor="middle"
          transform="translate(154.55 233.461)"
          fill="#fff"
          font-size="30"
          font-family="HelveticaNeue, Helvetica Neue"
        >
          <tspan x="0" y="0">
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('GOAL', '4')">{{ getShotLocationForGoalsBySegment(4) }}</tspan>
            /
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT', '4')">{{ getShotLocationForShotsBySegment(4) }}</tspan>
          </tspan>
        </text>

        <rect width="186" height="106" transform="translate(254.55 169)"
              [attr.fill]="calculateHeatMapColor((getShotLocationForGoalsBySegment(5) / getShotLocationForShotsBySegment(5)) * 100, true)"
        />
        <text
          text-anchor="middle"
          transform="translate(351.55 233.961)"
          fill="#fff"
          font-size="30"
          font-family="HelveticaNeue, Helvetica Neue"
        >
          <tspan x="0" y="0">
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('GOAL', '5')">{{ getShotLocationForGoalsBySegment(5) }}</tspan>
            /
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT', '5')">{{ getShotLocationForShotsBySegment(5) }}</tspan>
          </tspan>
        </text>

        <rect width="187" height="106" transform="translate(440.55 169)"
              [attr.fill]="calculateHeatMapColor((getShotLocationForGoalsBySegment(6) / getShotLocationForShotsBySegment(6)) * 100, true)"
        />
        <text
          text-anchor="middle"
          transform="translate(532.55 233.461)"
          fill="#fff"
          font-size="30"
          font-family="HelveticaNeue, Helvetica Neue"
        >
          <tspan x="0" y="0">
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('GOAL', '6')">{{ getShotLocationForGoalsBySegment(6) }}</tspan>
            /
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT', '6')">{{ getShotLocationForShotsBySegment(6) }}</tspan>
          </tspan>
        </text>

        <rect width="186" height="107" transform="translate(68.55 275)"
              [attr.fill]="calculateHeatMapColor((getShotLocationForGoalsBySegment(7) / getShotLocationForShotsBySegment(7)) * 100, true)"
        />
        <text
          text-anchor="middle"
          transform="translate(154.55 337.461)"
          fill="#fff"
          font-size="30"
          font-family="HelveticaNeue, Helvetica Neue"
        >
          <tspan x="0" y="0">
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('GOAL', '7')">{{ getShotLocationForGoalsBySegment(7) }}</tspan>
            /
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT', '7')">{{ getShotLocationForShotsBySegment(7) }}</tspan>
          </tspan>
        </text>

        <rect width="186" height="107" transform="translate(254.55 275)"
              [attr.fill]="calculateHeatMapColor((getShotLocationForGoalsBySegment(8) / getShotLocationForShotsBySegment(8)) * 100, true)"
        />
        <text
          text-anchor="middle"
          transform="translate(351.55 337.461)"
          fill="#fff"
          font-size="30"
          font-family="HelveticaNeue, Helvetica Neue"
        >
          <tspan x="0" y="0">
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('GOAL', '8')">{{ getShotLocationForGoalsBySegment(8) }}</tspan>
            /
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT', '8')">{{ getShotLocationForShotsBySegment(8) }}</tspan>
          </tspan>
        </text>

        <rect width="187" height="107" transform="translate(440.55 275)"
              [attr.fill]="calculateHeatMapColor((getShotLocationForGoalsBySegment(9) / getShotLocationForShotsBySegment(9)) * 100, true)"
        />
        <text
          text-anchor="middle"
          transform="translate(532.55 337.461)"
          fill="#fff"
          font-size="30"
          font-family="HelveticaNeue, Helvetica Neue"
        >
          <tspan x="0" y="0">
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('GOAL', '9')">{{ getShotLocationForGoalsBySegment(9) }}</tspan>
            /
            <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT', '9')">{{ getShotLocationForShotsBySegment(9) }}</tspan>
          </tspan>
        </text>
      </g>
    </svg>
  </div>

  <div id="field">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 775 755">
      <defs>
        <clipPath id="clip-Web_1920_1">
          <rect width="775" height="755" />
        </clipPath>
      </defs>
      <g id="Web_1920_1" clip-path="url(#clip-Web_1920_1)">
        <rect width="775" height="755" fill="#fff" />
        <g id="Group_1" data-name="Group 1" transform="translate(-44 -31.381)">
          <path
            id="field"
            d="M0,776.616H757.619V0H0Z"
            transform="translate(819.552 31.381) rotate(90)"
            fill="#7eaad6"
            stroke="#fff"
            stroke-width="2"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M218.977,585.168S98.064,590.144,24.02,463.194c0,0-26.363-47.285-23.851-85.865L0,218.027S1.425,148.336,31.545,108.5c0,0,48.947-69.691,110.471-92.087,0,0,43.509-18.676,76.98-16.184Z"
            transform="translate(141.316 254.768) rotate(-90)"
            fill="#427dc1"
            stroke="#fff"
            stroke-width="1"
          />
          <path
            id="Path_3"
            data-name="Path 3"
            d="M223.47,587.01S100.083,592,24.5,464.661c0,0-26.9-47.446-24.332-86.148L0,218.708s1.447-69.872,32.19-109.867c0,0,49.957-69.87,112.718-92.38,0,0,44.377-18.733,78.557-16.231Z"
            transform="translate(139.474 254.851) rotate(-90)"
            fill="#427dc1"
            stroke="#fff"
            stroke-miterlimit="10"
            stroke-width="2"
          />
          <path
            id="Path_5"
            data-name="Path 5"
            d="M315.283,852.4C268.4,848.956,134.5,825.684,46.1,675.156L36.051,658.947,16.818,618.525,6.637,576s-2.613-20.187-1.957-30.14c0,0-3.857-51.681-4.608-103.873S4.68,325.626,4.68,325.626s8.262-109.462,52.441-167.513c0,0,71.835-101.6,162.09-134.249,0,0,54.593-23.272,101.652-23.864"
            transform="translate(0 363.555) rotate(-90)"
            fill="rgba(0,0,0,0)"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            stroke-dasharray="11.95 11.95"
          />
          <line
            id="Line_11"
            data-name="Line 11"
            y1="38.935"
            transform="translate(446.984 198.03) rotate(90)"
            fill="none"
            stroke="#fff"
            stroke-width="1"
          />
        </g>
      </g>

      <!-- Calibration dots -->
      <g><circle cx="0%" cy="0%" r="2" fill="red" /></g>
      <g><circle cx="0%" cy="100%" r="2" fill="red" /></g>
      <g><circle cx="100%" cy="0%" r="2" fill="red" /></g>
      <g><circle cx="100%" cy="100%" r="2" fill="red" /></g>

      <g>
        <rect
          class="hover"
          [ngClass]="{ 'rect-active': shotLocationSelected && shotLocationSelected !== 'CONNECT_G_LEFT_WING:CONNECT_S_LEFT_WING' }"
          (click)="onExecutionPositionSelect('CONNECT_G_LEFT_WING:CONNECT_S_LEFT_WING')"
          [attr.x]="sP.calcPos(sP.pm.LeftWing.x, 'x', sP.rectXC + 15 * 4)"
          [attr.y]="sP.calcPos(sP.pm.LeftWing.y, 'y', sP.rectYC)"
          [attr.height]="sP.rectH"
          [attr.width]="sP.rectW"
          rx="30"
          [attr.fill]="position === 'CONNECT_G_LEFT_WING:CONNECT_S_LEFT_WING' ? '#0d385f' : calculateHeatMapColor((executionPositionStructure?.goalsLeftWing / executionPositionStructure?.shootsLeftWing) * 100)"
        />
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm.LeftWing.x, 'x', 15 * 4)"
          [attr.y]="sP.calcPos(sP.pm.LeftWing.y, 'y', 24)"
          font-family="'Open Sans', sans-serif"
          font-size="32"
          [attr.fill]="(!position || position === 'CONNECT_G_LEFT_WING:CONNECT_S_LEFT_WING') ? '#fff' : '#d3d3d3'"
        >
          {{ executionPositionStructure?.goalsLeftWing }}/{{ executionPositionStructure?.shootsLeftWing }}
        </text>
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm.LeftWing.x, 'x', 15 * 4)"
          [attr.y]="sP.calcPos(sP.pm.LeftWing.y, 'y', -16)"
          font-family="'Open Sans', sans-serif"
          font-size="24"
          [attr.fill]="(!position || position === 'CONNECT_G_LEFT_WING:CONNECT_S_LEFT_WING') ? '#fff' : '#d3d3d3'"
        >
          {{'Left Wing'|translate}}
        </text>
      </g>

      <g>
        <rect
          class="hover"
          [ngClass]="{ 'rect-active': shotLocationSelected && shotLocationSelected !== 'CONNECT_G_RIGHT_WING:CONNECT_S_RIGHT_WING' }"
          (click)="onExecutionPositionSelect('CONNECT_G_RIGHT_WING:CONNECT_S_RIGHT_WING')"
          [attr.x]="sP.calcPos(sP.pm['RightWing'].x, 'x', sP.rectXC - 15 * 4)"
          [attr.y]="sP.calcPos(sP.pm['RightWing'].y, 'y', sP.rectYC)"
          [attr.height]="sP.rectH"
          [attr.width]="sP.rectW"
          rx="30"
          [attr.fill]="position === 'CONNECT_G_RIGHT_WING:CONNECT_S_RIGHT_WING' ? '#0d385f' : calculateHeatMapColor((executionPositionStructure?.goalsRightWing / executionPositionStructure?.shootsRightWing) * 100)"
        />
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['RightWing'].x, 'x', -(15 * 4))"
          [attr.y]="sP.calcPos(sP.pm['RightWing'].y, 'y', 24)"
          font-family="'Open Sans', sans-serif"
          font-size="32"
          [attr.fill]="(!position || position === 'CONNECT_G_RIGHT_WING:CONNECT_S_RIGHT_WING') ? '#fff' : '#d3d3d3'"
        >
          {{ executionPositionStructure?.goalsRightWing }}/{{ executionPositionStructure?.shootsRightWing }}
        </text>
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['RightWing'].x, 'x', -(15 * 4))"
          [attr.y]="sP.calcPos(sP.pm['RightWing'].y, 'y', -16)"
          font-family="'Open Sans', sans-serif"
          font-size="24"
          [attr.fill]="(!position || position === 'CONNECT_G_RIGHT_WING:CONNECT_S_RIGHT_WING') ? '#fff' : '#d3d3d3'"
        >
          {{'Right Wing'|translate}}
        </text>
      </g>

      <g>
        <rect
          class="hover"
          [ngClass]="{ 'rect-active': shotLocationSelected && shotLocationSelected !== 'CONNECT_G_CENTER_6M:CONNECT_S_CENTER_6M' }"
          (click)="onExecutionPositionSelect('CONNECT_G_CENTER_6M:CONNECT_S_CENTER_6M')"
          [attr.x]="sP.calcPos(sP.pm['Center6m'].x, 'x', sP.rectXC)"
          [attr.y]="sP.calcPos(sP.pm['Center6m'].y, 'y', sP.rectYC)"
          [attr.height]="sP.rectH"
          [attr.width]="sP.rectW"
          rx="30"
          [attr.fill]="position === 'CONNECT_G_CENTER_6M:CONNECT_S_CENTER_6M' ? '#0d385f' : calculateHeatMapColor((executionPositionStructure?.goalsCenter6m / executionPositionStructure?.shootsCenter6m) * 100)"
        />
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['Center6m'].x)"
          [attr.y]="sP.calcPos(sP.pm['Center6m'].y, 'y', 24)"
          font-family="'Open Sans', sans-serif"
          [attr.fill]="(!position || position === 'CONNECT_G_CENTER_6M:CONNECT_S_CENTER_6M') ? '#fff' : '#d3d3d3'"
          font-size="32"
        >
          {{ executionPositionStructure?.goalsCenter6m }}/{{ executionPositionStructure?.shootsCenter6m }}
        </text>
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['Center6m'].x)"
          [attr.y]="sP.calcPos(sP.pm['Center6m'].y, 'y', -16)"
          font-family="'Open Sans', sans-serif"
          [attr.fill]="(!position || position === 'CONNECT_G_CENTER_6M:CONNECT_S_CENTER_6M') ? '#fff' : '#d3d3d3'"
          font-size="24"
        >
          {{'Center 6M'|translate}}
        </text>
      </g>

      <g>
        <rect
          class="hover"
          [ngClass]="{ 'rect-active': shotLocationSelected && shotLocationSelected !== 'CONNECT_G_LEFT_6M:CONNECT_S_LEFT_6M' }"
          (click)="onExecutionPositionSelect('CONNECT_G_LEFT_6M:CONNECT_S_LEFT_6M')"
          [attr.x]="sP.calcPos(sP.pm['Left6m'].x, 'x', sP.rectXC)"
          [attr.y]="sP.calcPos(sP.pm['Left6m'].y, 'y', sP.rectYC)"
          [attr.height]="sP.rectH"
          [attr.width]="sP.rectW"
          rx="30"
          [attr.fill]="position === 'CONNECT_G_LEFT_6M:CONNECT_S_LEFT_6M' ? '#0d385f' : calculateHeatMapColor((executionPositionStructure?.goalsLeft6m / executionPositionStructure?.shootsLeft6m) * 100)"
        />
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['Left6m'].x)"
          [attr.y]="sP.calcPos(sP.pm['Left6m'].y, 'y', 24)"
          font-family="'Open Sans', sans-serif"
          [attr.fill]="(!position || position === 'CONNECT_G_LEFT_6M:CONNECT_S_LEFT_6M') ? '#fff' : '#d3d3d3'"
          font-size="32"
        >
          {{ executionPositionStructure?.goalsLeft6m }}/{{ executionPositionStructure?.shootsLeft6m }}
        </text>
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['Left6m'].x)"
          [attr.y]="sP.calcPos(sP.pm['Left6m'].y, 'y', -16)"
          font-family="'Open Sans', sans-serif"
          [attr.fill]="(!position || position === 'CONNECT_G_LEFT_6M:CONNECT_S_LEFT_6M') ? '#fff' : '#d3d3d3'"
          font-size="24"
        >
          {{'Left 6M'|translate}}
        </text>
      </g>

      <g>
        <rect
          class="hover"
          [ngClass]="{ 'rect-active': shotLocationSelected && shotLocationSelected !== 'CONNECT_G_RIGHT_6M:CONNECT_S_RIGHT_6M' }"
          (click)="onExecutionPositionSelect('CONNECT_G_RIGHT_6M:CONNECT_S_RIGHT_6M')"
          [attr.x]="sP.calcPos(sP.pm['Right6m'].x, 'x', sP.rectXC)"
          [attr.y]="sP.calcPos(sP.pm['Right6m'].y, 'y', sP.rectYC)"
          [attr.height]="sP.rectH"
          [attr.width]="sP.rectW"
          rx="30"
          [attr.fill]="position === 'CONNECT_G_RIGHT_6M:CONNECT_S_RIGHT_6M' ? '#0d385f' : calculateHeatMapColor((executionPositionStructure?.goalsRight6m / executionPositionStructure?.shootsRight6m) * 100)"
        />
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['Right6m'].x)"
          [attr.y]="sP.calcPos(sP.pm['Right6m'].y, 'y', 24)"
          font-family="'Open Sans', sans-serif"
          [attr.fill]="(!position || position === 'CONNECT_G_RIGHT_6M:CONNECT_S_RIGHT_6M') ? '#fff' : '#d3d3d3'"
          font-size="32"
        >
          {{ executionPositionStructure?.goalsRight6m }}/{{ executionPositionStructure?.shootsRight6m }}
        </text>
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['Right6m'].x)"
          [attr.y]="sP.calcPos(sP.pm['Right6m'].y, 'y', -16)"
          font-family="'Open Sans', sans-serif"
          [attr.fill]="(!position || position === 'CONNECT_G_RIGHT_6M:CONNECT_S_RIGHT_6M') ? '#fff' : '#d3d3d3'"
          font-size="24"
        >
          {{'Right 6M'|translate}}
        </text>
      </g>

      <g>
        <rect
          class="hover"
          [ngClass]="{ 'rect-active': shotLocationSelected && shotLocationSelected !== 'CONNECT_G_CENTER_9M:CONNECT_S_CENTER_9M' }"
          (click)="onExecutionPositionSelect('CONNECT_G_CENTER_9M:CONNECT_S_CENTER_9M')"
          [attr.x]="sP.calcPos(sP.pm['Center9m'].x, 'x', sP.rectXC)"
          [attr.y]="sP.calcPos(sP.pm['Center9m'].y, 'y', sP.rectYC)"
          [attr.height]="sP.rectH"
          [attr.width]="sP.rectW"
          rx="30"
          [attr.fill]="position === 'CONNECT_G_CENTER_9M:CONNECT_S_CENTER_9M' ? '#0d385f' : calculateHeatMapColor((executionPositionStructure?.goalsCenter9m / executionPositionStructure?.shootsCenter9m) * 100)"
        />
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['Center9m'].x)"
          [attr.y]="sP.calcPos(sP.pm['Center9m'].y, 'y', 24)"
          [attr.fill]="(!position || position === 'CONNECT_G_CENTER_9M:CONNECT_S_CENTER_9M') ? '#fff' : '#d3d3d3'"
          font-family="'Open Sans', sans-serif"
          font-size="32"
        >
          {{ executionPositionStructure?.goalsCenter9m }}/{{ executionPositionStructure?.shootsCenter9m }}
        </text>
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['Center9m'].x)"
          [attr.y]="sP.calcPos(sP.pm['Center9m'].y, 'y', -16)"
          [attr.fill]="(!position || position === 'CONNECT_G_CENTER_9M:CONNECT_S_CENTER_9M') ? '#fff' : '#d3d3d3'"
          font-family="'Open Sans', sans-serif"
          font-size="24"
        >
          {{'Center 9M'|translate}}
        </text>
      </g>

      <g>
        <rect
          class="hover"
          [ngClass]="{ 'rect-active': shotLocationSelected && shotLocationSelected !== 'CONNECT_G_LEFT_9M:CONNECT_S_LEFT_9M' }"
          (click)="onExecutionPositionSelect('CONNECT_G_LEFT_9M:CONNECT_S_LEFT_9M')"
          [attr.x]="sP.calcPos(sP.pm['Left9m'].x, 'x', sP.rectXC)"
          [attr.y]="sP.calcPos(sP.pm['Left9m'].y, 'y', sP.rectYC)"
          [attr.height]="sP.rectH"
          [attr.width]="sP.rectW"
          rx="30"
          [attr.fill]="position === 'CONNECT_G_LEFT_9M:CONNECT_S_LEFT_9M' ? '#0d385f' : calculateHeatMapColor((executionPositionStructure?.goalsLeft9m / executionPositionStructure?.shootsLeft9m) * 100)"
        />
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['Left9m'].x)"
          [attr.y]="sP.calcPos(sP.pm['Left9m'].y, 'y', 24)"
          font-family="'Open Sans', sans-serif"
          [attr.fill]="(!position || position === 'CONNECT_G_LEFT_9M:CONNECT_S_LEFT_9M') ? '#fff' : '#d3d3d3'"
          font-size="32"
        >
          {{ executionPositionStructure?.goalsLeft9m }}/{{ executionPositionStructure?.shootsLeft9m }}
        </text>
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['Left9m'].x)"
          [attr.y]="sP.calcPos(sP.pm['Left9m'].y, 'y', -16)"
          font-family="'Open Sans', sans-serif"
          [attr.fill]="(!position || position === 'CONNECT_G_LEFT_9M:CONNECT_S_LEFT_9M') ? '#fff' : '#d3d3d3'"
          font-size="24"
        >
          {{'Left 9M'|translate}}
        </text>
      </g>

      <g>
        <rect
          class="hover"
          [ngClass]="{ 'rect-active': shotLocationSelected && shotLocationSelected !== 'CONNECT_G_RIGHT_9M:CONNECT_S_RIGHT_9M' }"
          (click)="onExecutionPositionSelect('CONNECT_G_RIGHT_9M:CONNECT_S_RIGHT_9M')"
          [attr.x]="sP.calcPos(sP.pm['Right9m'].x, 'x', sP.rectXC)"
          [attr.y]="sP.calcPos(sP.pm['Right9m'].y, 'y', sP.rectYC)"
          [attr.height]="sP.rectH"
          [attr.width]="sP.rectW"
          rx="30"
          [attr.fill]="position === 'CONNECT_G_RIGHT_9M:CONNECT_S_RIGHT_9M' ? '#0d385f' : calculateHeatMapColor((executionPositionStructure?.goalsRight9m / executionPositionStructure?.shootsRight9m) * 100)"
        />
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['Right9m'].x)"
          [attr.y]="sP.calcPos(sP.pm['Right9m'].y, 'y', 24)"
          font-family="'Open Sans', sans-serif"
          [attr.fill]="(!position || position === 'CONNECT_G_RIGHT_9M:CONNECT_S_RIGHT_9M') ? '#fff' : '#d3d3d3'"
          font-size="32"
        >
          {{ executionPositionStructure?.goalsRight9m }}/{{ executionPositionStructure?.shootsRight9m }}
        </text>
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['Right9m'].x)"
          [attr.y]="sP.calcPos(sP.pm['Right9m'].y, 'y', -16)"
          font-family="'Open Sans', sans-serif"
          [attr.fill]="(!position || position === 'CONNECT_G_RIGHT_9M:CONNECT_S_RIGHT_9M') ? '#fff' : '#d3d3d3'"
          font-size="24"
        >
          {{'Right 9M'|translate}}
        </text>
      </g>

      <g>
        <rect
          class="hover"
          [ngClass]="{ 'rect-active': shotLocationSelected && shotLocationSelected !== 'CONNECT_G_7_METERS:CONNECT_S_7_METERS' }"
          (click)="onExecutionPositionSelect('CONNECT_G_7_METERS:CONNECT_S_7_METERS')"
          [attr.x]="sP.calcPos(sP.pm['7Meters'].x, 'x', -(sP.rectW + 15) / 2)"
          [attr.y]="sP.calcPos(sP.pm['7Meters'].y, 'y', sP.rectYC)"
          [attr.height]="sP.rectH"
          [attr.width]="sP.rectW + 15"
          rx="30"
          [attr.fill]="position === 'CONNECT_G_7_METERS:CONNECT_S_7_METERS' ? '#0d385f' : calculateHeatMapColor((executionPositionStructure?.goals7Meters / executionPositionStructure?.shoots7Meters) * 100)"
        />
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['7Meters'].x)"
          [attr.y]="sP.calcPos(sP.pm['7Meters'].y, 'y', 24)"
          font-family="'Open Sans', sans-serif"
          [attr.fill]="(!position || position === 'CONNECT_G_7_METERS:CONNECT_S_7_METERS') ? '#fff' : '#d3d3d3'"
          font-size="32"
        >
          {{ executionPositionStructure?.goals7Meters }}/{{ executionPositionStructure?.shoots7Meters }}
        </text>
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['7Meters'].x)"
          [attr.y]="sP.calcPos(sP.pm['7Meters'].y, 'y', -16)"
          font-family="'Open Sans', sans-serif"
          [attr.fill]="(!position || position === 'CONNECT_G_7_METERS:CONNECT_S_7_METERS') ? '#fff' : '#d3d3d3'"
          font-size="24"
        >
          {{'7 Meters'|translate}}
        </text>
      </g>

      <g>
        <rect
          class="hover"
          [ngClass]="{ 'rect-active': shotLocationSelected && shotLocationSelected !== 'CONNECT_G_OPPOSITE_FIELD:CONNECT_S_OPPOSITE_FIELD' }"
          (click)="onExecutionPositionSelect('CONNECT_G_OPPOSITE_FIELD:CONNECT_S_OPPOSITE_FIELD')"
          [attr.x]="sP.calcPos(sP.pm['OppositeField'].x, 'x', -(sP.rectW + 15) / 2)"
          [attr.y]="sP.calcPos(sP.pm['OppositeField'].y, 'y', sP.rectYC)"
          [attr.height]="sP.rectH"
          [attr.width]="sP.rectW + 15"
          rx="30"
          [attr.fill]="position === 'CONNECT_G_OPPOSITE_FIELD:CONNECT_S_OPPOSITE_FIELD' ? '#0d385f' : calculateHeatMapColor((executionPositionStructure?.goalsOppositeField / executionPositionStructure?.shootsOppositeField) * 100)"
        />
      </g>
      <g>
        <text
          class="no-interact"
          text-anchor="middle"
          [attr.x]="sP.calcPos(sP.pm['OppositeField'].x)"
          [attr.y]="sP.calcPos(sP.pm['OppositeField'].y, 'y', 24)"
          font-family="'Open Sans', sans-serif"
          [attr.fill]="(!position || position === 'CONNECT_G_OPPOSITE_FIELD:CONNECT_S_OPPOSITE_FIELD') ? '#fff' : '#d3d3d3'"
          font-size="32"
        >
          {{ executionPositionStructure?.goalsOppositeField }}/{{ executionPositionStructure?.shootsOppositeField }}
        </text>
      </g>
      <g>
        <text
        class="no-interact"
        text-anchor="middle"
        [attr.x]="sP.calcPos(sP.pm['OppositeField'].x)"
        [attr.y]="sP.calcPos(sP.pm['OppositeField'].y, 'y', -16)"
        font-family="'Open Sans', sans-serif"
        [attr.fill]="(!position || position === 'CONNECT_G_OPPOSITE_FIELD:CONNECT_S_OPPOSITE_FIELD') ? '#fff' : '#d3d3d3'"
        font-size="24"
        >
          {{'Opposite Field'|translate}}
      </text>
    </g>
    <g *ngIf="displayCounterSelect && shotLocationSelected && executionPositionStructure">
      <text
        fill="white"
        text-anchor="middle"
        [attr.x]="sP.calcPos(sP.pm['Center6m'].x)"
        [attr.y]="100"
        font-family="'Open Sans', sans-serif"
        font-size="40"
      >
        <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('GOAL')">{{ executionPositionStructure[(selectedLocation|async).goalsProp] || '' }}</tspan>
        /
        <tspan [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT')">{{ executionPositionStructure[(selectedLocation|async).shotsProp] || '' }}</tspan>
      </text>
    </g>
    </svg>

    <!-- <div
      *ngIf="shotLocationSelected && executionPositionStructure"
      id="playButton"
      (click)="counterSelectClick()"
      [ngClass]="{ hiddeButton: shotLocationSelected === '' }"
    >
      <!-- <ion-icon name="play-circle-outline"></ion-icon> ->
      <span [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('GOAL')">{{ executionPositionStructure[(selectedLocation|async).goalsProp] || '' }}</span>
      /
      <span [class.selectable_span]="displayCounterSelect" (click)="displayCounterSelect&&counterSelectClick('SHOT')">{{ executionPositionStructure[(selectedLocation|async).shotsProp] || '' }}</span>
    </div> -->
  </div>

</ng-container>
