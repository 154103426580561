import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { TeamStatsPlayerModel } from '@handballai/stats-calculation';


export const createHeader = (): string =>
    'Game\t Start\t End\t Period\t Team\t Event\t Phase\t Offense System\t Defense System\t Assist\t Offense Player Number\t ' +
    'Offense Player Name\t Offense Player Position\t Defense Player Number\t Defense Player Name\t Defense Player Position\t ' +
    'Shooter Position\t Shot Location\t Home Team\t Visitor Team\t Possession\t Important\t Game System\n ';

// export const createStartTime = (
//     minutesSinceHalfTime: number,
//     secSinceHalfTime: number
//     ): string => {
//   if (secSinceHalfTime === 0 && minutesSinceHalfTime === 0)  {
//       return `00:00, `;
//   }
//   const secAll = minutesSinceHalfTime * 60 + secSinceHalfTime - 10;
//   return `${zeroPad(Math.floor(secAll / 60), 2)}:${zeroPad((secAll % 60), 2)}, `;
// };

export const getHomeTeam = (
    teamMarker: TeamMarker,
    offense: TeamStatsPlayerModel[],
    defense: TeamStatsPlayerModel[]
): string => {
    if (teamMarker == 'HOME') {
        return offense.map(pl => pl.name).join(', ');
    } else {
        return defense.map(pl => pl.name).join(', ');
    }
};

export const getVisitorTeam = (
    teamMarker: TeamMarker,
    offense: TeamStatsPlayerModel[],
    defense: TeamStatsPlayerModel[]
): string => {
    if (teamMarker == 'VISITOR') {
        return offense.map(pl => pl.name).join(', ');
    } else {
        return defense.map(pl => pl.name).join(', ');
    }
};

// export const getOffenseSystem = (
//     offense: TeamStatsPlayerModel[],
//     defense: TeamStatsPlayerModel[]
// ): string => {
//     const offNumWithoutGk = offense.filter(pl => pl.position !== 'gk').length;
//     const defNumWithoutGk = defense.filter(pl => pl.position !== 'gk').length;
//     if (offNumWithoutGk === 7 && defNumWithoutGk === 6) {
//         return '7vs6';
//     }
//     if (offNumWithoutGk > defNumWithoutGk) {
//         return 'Superiority';
//     } else if (offNumWithoutGk < defNumWithoutGk) {
//         return 'Inferiority';
//     }
//     return 'Equality';
// };
