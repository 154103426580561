<ion-app>
  <ion-split-pane contentId="main" [ngClass]="{ collapsed: (!isOpen && !core.isMobileMenuOpen), 'not-visible': disableMenu || (!core.isMobileMenuOpen && core.isMobileDevice) }" when="xs">
    <ion-menu contentId="main" [ngClass]="{ show: isOpen || core.isMobileMenuOpen }">
      <ion-header class="ion-no-margin" no-shadow>
        <div class="version" [ngClass]="{ open: isOpen || core.isMobileMenuOpen }">
          <small>{{ humanizeDevice(core.device) }} {{ core.version.getVersion() }}</small>
        </div>
        <ion-toolbar translucent color="secondary" class="ion-no-margin">
          <div class="logo-wrapper">
            <app-handballai-logo width="39" height="30"></app-handballai-logo>
          </div>
        </ion-toolbar>
      </ion-header>

      <ion-content color="secondary" class="ion-no-margin">
        <div class="menu-flex-container">
          <ng-template #commonOptions>
            <ion-item *ngIf="!isMobileScreen" (click)="toggleMenu()" lines="none" color="secondary" style="cursor: pointer">
              <span class="icon-wrapper">
                <svg-icon [key]="isOpen ? 'close_menu' : 'open_menu'"></svg-icon>
              </span>
            </ion-item>
            <ion-item
              lines="none"
              color="warning"
              style="cursor: pointer; overflow: visible; z-index: 2"
              *ngIf="core.version.updateable"
              (click)="core.version.update()"
            >
              <ion-icon *ngIf="!isOpen" name="infinite-outline"></ion-icon>
              <ion-label style="white-space: unset; text-align: center">
                {{ 'New version avalaible. Click here to update.' | translate }}
                <ng-container style="white-space: unset" *ngIf="core.device != 'pwa'">
                  <br /><br />
                  {{ 'If you prefer, you can use also the latest version at' | translate }} https://app.handball.ai
                </ng-container>
              </ion-label>
            </ion-item>
          </ng-template>

          <ion-list no-lines *ngIf="selectedAccountEquipo$ | async" color="secondary" class="ion-no-padding">
            <ng-container *ngTemplateOutlet="commonOptions"></ng-container>

            <ion-accordion-group>
              <ion-item
                lines="none"
                color="secondary"
                style="cursor: pointer"
                *ngIf="
                  (selectedAccountEquipo$ | async).grants.includes('create_game') &&
                  !((selectedAccountEquipo$ | async).grants.includes('track_game') && (selectedAccountEquipo$ | async)?.account?.permissions.scheduleGames)
                "
                (click)="onRouteSelect()"
                [routerLink]="['account', accountId, 'game', 'start']"
                routerLinkActive="active-link"
              >
                <a>
                  <span class="active-link-marker"></span>
                  <ion-icon name="create-outline" slot="start"></ion-icon>
                  <ion-label>{{ 'New Game' | translate }}</ion-label>
                </a>
              </ion-item>

              <ion-accordion *ngIf="(selectedAccountEquipo$ | async).grants.includes('track_game') && (selectedAccountEquipo$ | async)?.account?.permissions.scheduleGames">
                <ion-item slot="header" color="secondary">
                  <ion-icon name="game-controller-outline" slot="start"></ion-icon>
                  <ion-label>Create Game</ion-label>
                </ion-item>

                <div slot="content">
                  <ion-item
                    (click)="onRouteSelect()"
                    [routerLink]="['account', accountId, 'game', 'track']"
                    routerLinkActive="active-link"
                    lines="none"
                    style="cursor: pointer"
                    color="secondary"
                  >
                    <a>
                      <span class="active-link-marker"></span>
                      <!-- <ion-icon name="football-outline" slot="start"></ion-icon> -->
                      <span class="icon-wrapper">
                        <svg-icon key="track_game"></svg-icon>
                      </span>
                      <ion-label>{{ 'Track Games' | translate }}</ion-label>
                    </a>
                  </ion-item>
                  <ion-item
                    lines="none"
                    color="secondary"
                    style="cursor: pointer"
                    (click)="onRouteSelect()"
                    [routerLink]="['account', accountId, 'game', 'schedule']"
                    routerLinkActive="active-link"
                    *ngIf="(selectedAccountEquipo$ | async).grants.includes('create_game')"
                  >
                    <a>
                      <span class="active-link-marker"></span>
                      <!-- <ion-icon name="football-outline" slot="start"></ion-icon> -->
                      <span class="icon-wrapper">
                        <svg-icon key="schedule_game"></svg-icon>
                      </span>
                      <ion-label>{{ 'Schedule Game' | translate }}</ion-label>
                    </a>
                  </ion-item>
                  <ion-item
                    lines="none"
                    color="secondary"
                    style="cursor: pointer"
                    *ngIf="(selectedAccountEquipo$ | async).grants.includes('create_game')"
                    (click)="onRouteSelect()"
                    [routerLink]="['account', accountId, 'game', 'start']"
                    routerLinkActive="active-link"
                  >
                    <a>
                      <span class="active-link-marker"></span>
                      <ion-icon name="create-outline" slot="start"></ion-icon>
                      <ion-label>{{ 'New Game' | translate }}</ion-label>
                    </a>
                  </ion-item>
                </div>
              </ion-accordion>

              <!-- IF view_game dashboard scouting OR missing any of permissions !pastGames !dashboard !accessScouting (handles option to upgrade account)-->
              <ion-accordion *ngIf="
                !(selectedAccountEquipo$ | async)?.account?.permissions.pastGames ||
                !(selectedAccountEquipo$ | async)?.account?.permissions.dashboard ||
                !(selectedAccountEquipo$ | async)?.account?.permissions.accessScouting ||
                (selectedAccountEquipo$ | async).grants.includes('view_game') ||
                (selectedAccountEquipo$ | async).grants.includes('dashboard') ||
                (selectedAccountEquipo$ | async).grants.includes('scouting')
              ">
                <ion-item slot="header" color="secondary">
                  <ion-icon name="stats-chart-outline" slot="start"></ion-icon>
                  <ion-label>{{ 'Scouting & Analysis' | translate }}</ion-label>
                </ion-item>

                <div slot="content">
                  <ng-container *ngIf="(selectedAccountEquipo$ | async)?.account?.permissions.pastGames; else noPastGames">
                    <ion-item
                      lines="none"
                      color="secondary"
                      style="cursor: pointer"
                      (click)="onRouteSelect()"
                      [routerLink]="['account', accountId, 'game', 'search']"
                      routerLinkActive="active-link"
                      *ngIf="(selectedAccountEquipo$ | async).grants.includes('view_game')"
                    >
                      <a>
                        <span class="active-link-marker"></span>
                        <!-- <ion-icon name="briefcase-outline" slot="start"></ion-icon> -->
                        <span class="icon-wrapper">
                          <svg-icon key="past_games"></svg-icon>
                        </span>
                        <ion-label>{{ 'Past Games' | translate }}</ion-label>
                      </a>
                    </ion-item>
                  </ng-container>
                  <ng-template #noPastGames>
                    <ion-item
                      lines="none"
                      color="secondary"
                      style="cursor: pointer"
                      (click)="handleNoPermission('pastGames')"
                    >
                      <!-- <ion-icon name="briefcase-outline" slot="start"></ion-icon> -->
                      <span class="icon-wrapper">
                        <svg-icon key="past_games"></svg-icon>
                      </span>
                      <ion-label>{{ 'Past Games' | translate }}</ion-label>
                      <ion-badge>{{ 'TEAM' | translate }}</ion-badge>
                    </ion-item>
                  </ng-template>
                  <ng-container *ngIf="(selectedAccountEquipo$ | async)?.account?.permissions.dashboard; else noDashboards">
                    <ion-item
                      lines="none"
                      color="secondary"
                      style="cursor: pointer"
                      (click)="onRouteSelect()"
                      [routerLink]="['account', accountId, 'my-dashboards']"
                      routerLinkActive="active-link"
                      *ngIf="(selectedAccountEquipo$ | async).grants.includes('dashboard')"
                    >
                      <a>
                        <span class="active-link-marker"></span>
                        <!-- <ion-icon name="stats-chart-outline" slot="start"></ion-icon> -->
                        <span class="icon-wrapper">
                          <svg-icon key="dashboard"></svg-icon>
                        </span>
                        <ion-label>{{ 'My Dashboards' | translate }}</ion-label>
                      </a>
                    </ion-item>
                  </ng-container>
                  <ng-template #noDashboards>
                    <ion-item
                      lines="none"
                      color="secondary"
                      style="cursor: pointer"
                      (click)="handleNoPermission('dashboard')"
                    >
                      <!-- <ion-icon name="stats-chart-outline" slot="start"></ion-icon> -->
                      <span class="icon-wrapper">
                        <svg-icon key="dashboard"></svg-icon>
                      </span>
                      <ion-label>{{ 'My Dashboards' | translate }}</ion-label>
                      <ion-badge>{{ 'TEAM' | translate }}</ion-badge>
                    </ion-item>
                  </ng-template>

                  <ng-container *ngIf="(selectedAccountEquipo$ | async)?.account?.permissions.accessScouting; else noScouting">
                    <ion-item
                        lines="none"
                        color="secondary"
                        style="cursor: pointer"
                        (click)="onRouteSelect()"[routerLink]="['account', accountId, 'scouting']"
                        routerLinkActive="active-link"
                        *ngIf="(selectedAccountEquipo$ | async).grants.includes('scouting')"
                    >
                      <span class="active-link-marker"></span>
                      <a>
                        <ion-icon name="videocam-outline"></ion-icon>
                        <ion-label>{{ 'Scouting' | translate }}</ion-label>
                      </a>
                    </ion-item>
                    <ion-item
                        lines="none"
                        color="secondary"
                        style="cursor: pointer"
                        (click)="onRouteSelect()"
                        [routerLink]="['account', accountId, 'tagging']"
                        routerLinkActive="active-link"
                        *ngIf="(selectedAccountEquipo$ | async).grants.includes('scouting')"
                    >
                      <span class="active-link-marker"></span>
                      <a>
                        <ion-icon name="pricetags-outline"></ion-icon>
                        <ion-label>{{ 'Tagged Games' | translate }}</ion-label>
                      </a>
                    </ion-item>
                  </ng-container>
                  <ng-template #noScouting>
                    <ion-item
                        lines="none"
                        color="secondary"
                        style="cursor: pointer"
                    >
                      <!-- (click)="handleNoPermission('dashboard')" -->
                      <!-- <ion-icon name="stats-chart-outline" slot="start"></ion-icon> -->
                      <a>
                        <ion-icon name="videocam-outline"></ion-icon>
                        <ion-label>{{ 'Scouting' | translate }}</ion-label>
                        <ion-badge>{{ 'UPGRADE PLAN' | translate }}</ion-badge>
                      </a>
                    </ion-item>
                  </ng-template>
                </div>
              </ion-accordion>

              <ion-accordion *ngIf="(selectedAccountEquipo$ | async).grants.includes('manage_teams')">
                <ion-item slot="header" color="secondary">
                  <ion-icon name="finger-print-outline" slot="start"></ion-icon>
                  <ion-label>{{ 'Coaching Zone' | translate }}</ion-label>
                </ion-item>

                <div slot="content">
                  <ion-item
                    lines="none"
                    color="secondary"
                    style="cursor: pointer"
                    (click)="onRouteSelect()"
                    [routerLink]="['account', accountId, 'game-system']"
                    routerLinkActive="active-link"
                  >
                    <a>
                      <span class="active-link-marker"></span>
                      <!-- <ion-icon name="construct-outline" slot="start"></ion-icon> -->
                      <span class="icon-wrapper">
                      <svg-icon key="game_system"></svg-icon>
                    </span>
                      <ion-label>{{ 'Game System' | translate }}</ion-label>
                    </a>
                  </ion-item>
                  <ion-item
                    lines="none"
                    color="secondary"
                    style="cursor: pointer"
                    (click)="onRouteSelect()"
                    [routerLink]="['account', accountId, 'team']"
                    routerLinkActive="active-link"
                  >
                    <a>
                      <span class="active-link-marker"></span>
                      <!-- <ion-icon name="shield-outline" slot="start"></ion-icon> -->
                      <span class="icon-wrapper">
                        <svg-icon key="manage_teams"></svg-icon>
                      </span>
                      <ion-label>{{ 'Manage Teams' | translate }}</ion-label>
                    </a>
                  </ion-item>
                </div>
              </ion-accordion>


              <ion-item
                  lines="none"
                  color="secondary"
                  style="cursor: pointer"
                  (click)="onRouteSelect()"
                  [routerLink]="['my-team']"
                  routerLinkActive="active-link"
              >
                <a>
                  <span class="active-link-marker"></span>
                  <span class="icon-wrapper">
                    <svg-icon key="players"></svg-icon>
                  </span>
                  <ion-label>{{ 'My Team' | translate }}</ion-label>
                </a>
              </ion-item>

              <ion-accordion>
                <ion-item slot="header" color="secondary">
                  <ion-icon name="storefront-outline" slot="start"></ion-icon>
                  <ion-label>{{ 'General' | translate }}</ion-label>
                </ion-item>

                <div slot="content">
                  <ion-item
                    lines="none"
                    color="secondary"
                    style="cursor: pointer"
                    (click)="onRouteSelect()"
                    [routerLink]="['organization']"
                    routerLinkActive="active-link"
                  >
                    <a>
                      <span class="active-link-marker"></span>
                      <!-- <ion-icon name="business-outline" slot="start"></ion-icon> -->
                      <span class="icon-wrapper">
                        <svg-icon key="my_organizations"></svg-icon>
                      </span>
                      <ion-label>{{ 'My Organizations' | translate }}</ion-label>
                    </a>
                  </ion-item>
                  <ion-item
                    lines="none"
                    style="cursor: pointer"
                    color="secondary"
                    (click)="router.navigate(['/user/profile']); onRouteSelect()"
                    [routerLink]="['user/profile']"
                    routerLinkActive="active-link"
                  >
                    <span class="active-link-marker"></span>
                    <img [ngClass]="{'menu-open': isOpen || core.isMobileMenuOpen}" *ngIf="(user$ | async)?.imageUrl" [src]="(user$ | async)?.imageUrl">
                    <ion-icon *ngIf="!(user$ | async)?.imageUrl" name="person-circle-outline"></ion-icon>
                    <ion-label>{{ 'Profile' | translate }}</ion-label>
                  </ion-item>
                  <ion-item
                    lines="none"
                    color="secondary"
                    style="cursor: pointer"
                    (click)="router.navigate(['/super-admin']); onRouteSelect()"
                    *ngIf="isSuperAdmin$ | async"
                    [routerLink]="['super-admin']"
                    routerLinkActive="active-link"
                  >
                    <span class="active-link-marker"></span>
                    <a>
                      <span class="icon-wrapper">
                        <svg-icon key="super_admin"></svg-icon>
                      </span>
                      <ion-label>{{ 'Super Admin Menu' | translate }}</ion-label>
                    </a>
                  </ion-item>
                </div>
              </ion-accordion>
            </ion-accordion-group>

          </ion-list>

          <ion-list *ngIf="(core.accountEquipoService.accountEquipos$|async)!==null && !(core.accountEquipoService.accountEquipos$|async).length" class="ion-no-padding">
            <ng-container *ngTemplateOutlet="commonOptions"></ng-container>
            <ion-item color="danger" style="overflow: visible; z-index: 2">
              <ion-icon class="hide-opened" name="alert-outline"></ion-icon>
              <ion-label style="white-space: unset">
                {{ 'You need to be part of an account to use the application' | translate }}
              </ion-label>
            </ion-item>
            <ion-item
              color="secondary"
              style="cursor: pointer"
              (click)="onRouteSelect()"
              [routerLink]="['organization']"
              routerLinkActive="active-link"
            >
              <span class="active-link-marker"></span>
              <a>
                <span class="icon-wrapper">
                  <svg-icon key="my_organizations"></svg-icon>
                </span>
                <ion-label>{{ 'My Organizations' | translate }}</ion-label>
              </a>
            </ion-item>
          </ion-list>

          <ion-list style="margin-top: 20px" class="ion-no-padding">
            <ion-item
                *ngIf="core.storageService.superAdminToken"
                lines="none"
                style="cursor: pointer"
                color="secondary"
                (click)="onLoginBackToSuperAdminClick()"
            >
              <ion-icon name="enter-outline" slot="start"></ion-icon>
              <ion-label>{{ 'Super Admin Login' | translate }}</ion-label>
            </ion-item>

            <ion-accordion-group>
              <ion-accordion>
                <ion-item slot="header" color="secondary">
                  <ion-icon name="help-circle-outline" slot="start"></ion-icon>
                  <ion-label>{{ 'Support' | translate }}</ion-label>
                </ion-item>

                <div slot="content">
                  <ion-item
                    lines="none"
                    color="secondary"
                    style="cursor: pointer"
                    (click)="goToLink('https://handball.ai/wiki/')"
                  >
                    <span class="icon-wrapper">
                      <svg-icon key="faqs"></svg-icon>
                    </span>
                    <ion-label>{{ 'FAQS' | translate }}</ion-label>
                  </ion-item>

                  <ion-item
                    color="secondary"
                    lines="none"
                    style="cursor: pointer"
                    (click)="goToLink('https://handball.ai/contact-us')"
                  >
                    <span class="icon-wrapper">
                      <svg-icon key="contact"></svg-icon>
                    </span>
                    <ion-label>{{ 'Contact' | translate }}</ion-label>
                  </ion-item>
                </div>
              </ion-accordion>
            </ion-accordion-group>


            <ion-item (click)="logout(); onRouteSelect()" lines="none" color="secondary" style="cursor: pointer">
              <span class="icon-wrapper">
                <svg-icon key="logout"></svg-icon>
              </span>
              <ion-label>{{ 'LogOut' | translate }}</ion-label>
            </ion-item>
          </ion-list>
        </div>
      </ion-content>
    </ion-menu>
    <div *ngIf="isOpen || core.isMobileMenuOpen" class="backdrop" (click)="toggleMenu()"></div>
    <ion-router-outlet id="main" class="main-container"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
