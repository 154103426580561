<div class="confirm-modal-wrapper my-8">
  <div class="header">
    <ion-item lines="none">
      <ion-label>
        {{ 'Folders' | translate }}
      </ion-label>
    </ion-item>
    <hr style="background-color: black; height: 3px" />
  </div>
  <ng-container *ngFor="let folder of folders">
    <ion-item (click)="onFolderSelect(folder)"
              [color]="selectedFolder.name === folder.name ? 'alternative' : ''"
              lines="full" class="item-default clickAllowedSource">
      <div style="display: flex; width: 100%">
        <div style="display: flex; align-items: center">
          <ion-icon *ngIf="folder.children?.length"
                    class="chevron" (click)="onExpandClick(folder, $event)"
                    [name]="folder.expanded ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
          <ion-avatar class="clickAllowedSource">
            <img class="clickAllowedSource" alt="folder" src="/assets/svg/folder.svg" />
          </ion-avatar>
        </div>
        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
          <ion-label class="clickAllowedSource"> {{ folder.name }} </ion-label>
          <!--<ion-button (click)="onFolderDetailsClick(folder, $event)" color="secondary">
            <ion-icon name="document-text-outline"></ion-icon>
          </ion-button>-->
          <ion-icon [id]="'click-trigger-folder' + folder.id" (click)="onFolderMenuClick(folder, $event)" style="cursor:pointer;" name="ellipsis-horizontal"></ion-icon>
          <ion-popover [trigger]="'click-trigger-folder' + folder.id" triggerAction="click">
            <ng-template>
              <ion-item (click)="onFolderDetailsClick(folder, $event)" style="cursor: pointer">{{'Manage Folder Access' | translate}}</ion-item>
              <ion-item (click)="addFolder(folder)" style="cursor: pointer">{{'Create New Folder' | translate}}</ion-item>
              <ion-item (click)="onDeleteFolderClick(folder)" class="hover-item" style="cursor: pointer">{{'Delete Folder' | translate}}</ion-item>
            </ng-template>
          </ion-popover>
        </div>
      </div>
    </ion-item>
    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: folder.expanded ? folder.children : [], index: 0 }"></ng-container>
  </ng-container>

  <ng-template #recursiveList let-items let-index="index">
    <ng-container *ngFor="let item of items; let i = index">
      <ion-item (click)="onFolderSelect(item)" [color]="selectedFolder.name === item.name ? 'alternative' : ''"
                lines="full" class="item-default clickAllowedSource">
        <div style="display: flex; align-items: center; width: 100%">
          <div [style.margin-left]="(index+1) * 25 + 'px'" style="display: flex; align-items: center">
            <ion-icon *ngIf="item.children?.length"
                      class="chevron" (click)="onExpandClick(item, $event)"
                      [name]="item.expanded ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
            <ion-avatar class="clickAllowedSource">
              <img class="clickAllowedSource" alt="folder" src="/assets/svg/folder.svg" />
            </ion-avatar>
          </div>

          <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
            <ion-label class="clickAllowedSource"> {{ item.name }} </ion-label>
            <!--<ion-button (click)="onFolderDetailsClick(item, $event)" color="secondary">
              <ion-icon name="document-text-outline"></ion-icon>
            </ion-button>-->
            <ion-icon [id]="'click-trigger-folder' + item.id" (click)="onFolderMenuClick(item, $event)" style="cursor:pointer;" name="ellipsis-horizontal"></ion-icon>
            <ion-popover [trigger]="'click-trigger-folder' + item.id" triggerAction="click">
              <ng-template>
                <ion-item (click)="onFolderDetailsClick(item, $event)" style="cursor: pointer">{{'Manage Folder Access' | translate}}</ion-item>
                <ion-item (click)="addFolder(item)" style="cursor: pointer">{{'Create New Folder' | translate}}</ion-item>
                <ion-item (click)="onDeleteFolderClick(item)" class="hover-item" style="cursor: pointer">{{'Delete Folder' | translate}}</ion-item>
              </ng-template>
            </ion-popover>
          </div>
        </div>

        <!--<ion-label class="clickAllowedSource"> {{ item.name }} </ion-label>-->
      </ion-item>
      <div *ngIf="item.children && item.children.length && item.expanded">
        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.children, index: index+1 }"></ng-container>
      </div>
    </ng-container>
  </ng-template>
</div>
