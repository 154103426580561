import { CounterModel, ExecutionPositionTypes } from '@handballai/stats-calculation';
import { TGameSystemPbp } from 'src/app/shared-services/game-system/game-system.model';
import {
    GameSystemPlayerStatsViewModel, GameSystemPlayerStatsWrapper,
    GameSystemStatsViewModel, GameSystemStatsWrapper,
    GameSystemViewModelAggregationHelper
} from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-view.model';
import {
    ExtendedGamePlayerModel
} from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/extended-game-player.model';

export type GameSystemEvents = 'LOST_BALL' | 'FAULT' | 'SUSPENSION' | 'GOAL' | 'SAVE' | 'POST_OUT' | 'SEVEN_METERS_GOAL' | 'SEVEN_METERS_SAVE' | 'SEVEN_METERS_POST_OUT';

export interface IGameSystemKey extends TGameSystemPbp {
    eventType: GameSystemEvents;
}

export class GameSystemKeyImpl implements IGameSystemKey {
    actionDisplayName: string;
    actionName: string;
    categoryName: string;
    colorCode: string;
    eventType: GameSystemEvents;

    constructor(key?: Partial<IGameSystemKey>) {
        Object.assign(this, key);
    }

    public generateKey(): string {
        return `${this.actionName}:${this.eventType}`;
    }
}



export interface IGameSystemCounterHolder {
    gameSystemKey: GameSystemKeyImpl;
    counter: CounterModel;
}

export class GameSystemStatsModel {
    private readonly _counterMap: Map<string, IGameSystemCounterHolder>;

    constructor(
        private readonly teamName: string,
    ) {
        this._counterMap = new Map<string, IGameSystemCounterHolder>();
    }

    public addLostBall(
        gameSystem: TGameSystemPbp
    ): void {
        const keyObj = this.gameSystemKeyFactory(
            'LOST_BALL',
            gameSystem
        );
        this.addCounters(keyObj.generateKey(), keyObj);
    }

    public addSuspension(
        gameSystem: TGameSystemPbp
    ): void {
        const keyObj = this.gameSystemKeyFactory(
            'SUSPENSION',
            gameSystem
        );
        this.addCounters(keyObj.generateKey(), keyObj);
    }

    public addFault(
        gameSystem: TGameSystemPbp
    ): void {
        const keyObj = this.gameSystemKeyFactory(
            'FAULT',
            gameSystem
        );
        this.addCounters(keyObj.generateKey(), keyObj);
    }

    public addGoal(
        gameSystem: TGameSystemPbp,
        executionPosition?: ExecutionPositionTypes
    ): void {
        if (executionPosition && executionPosition === '7_METERS') {
            const keyObj = this.gameSystemKeyFactory(
                'SEVEN_METERS_GOAL',
                gameSystem
            );
            this.addCounters(keyObj.generateKey(), keyObj);

        }
        const keyObj = this.gameSystemKeyFactory(
            'GOAL',
            gameSystem
        );
        this.addCounters(keyObj.generateKey(), keyObj);

    }

    public addSave(
        gameSystem: TGameSystemPbp,
        executionPosition?: ExecutionPositionTypes
    ): void {
        if (executionPosition && executionPosition === '7_METERS') {
            const keyObj = this.gameSystemKeyFactory(
                'SEVEN_METERS_SAVE',
                gameSystem
            );
            this.addCounters(keyObj.generateKey(), keyObj);

        }
        const keyObj = this.gameSystemKeyFactory(
            'SAVE',
            gameSystem
        );
        this.addCounters(keyObj.generateKey(), keyObj);

    }

    public addPostOut(
        gameSystem: TGameSystemPbp,
        executionPosition?: ExecutionPositionTypes
    ): void {
        if (executionPosition && executionPosition === '7_METERS') {
            const keyObj = this.gameSystemKeyFactory(
                'SEVEN_METERS_POST_OUT',
                gameSystem
            );
            this.addCounters(keyObj.generateKey(), keyObj);

        }
        const keyObj = this.gameSystemKeyFactory(
            'POST_OUT',
            gameSystem
        );
        this.addCounters(keyObj.generateKey(), keyObj);

    }

    public generateGameSystemStats(): GameSystemStatsWrapper {
        const aggrModel = this.generateGameSystemStatsAggregationModel();
        return {
            teamName: this.teamName,
            gameSystemStatsView: this.generateGameSystemStatsViewModel(aggrModel),
        };
    }

    public generateGameSystemPlayerStats(player: ExtendedGamePlayerModel): GameSystemPlayerStatsWrapper {
        const aggrModel = this.generateGameSystemStatsAggregationModel();
        return {
            teamName: this.teamName,
            gameSystemPlayerStatsView: this.generateGameSystemStatsViewModel(aggrModel, player)
        };
    }

    private generateGameSystemStatsViewModel(
        gameSystemMap: Map<string, GameSystemViewModelAggregationHelper>,
        player?: ExtendedGamePlayerModel
    ): GameSystemStatsViewModel[] | GameSystemPlayerStatsViewModel[] {
        return !player ? Array.from(gameSystemMap.values()).map(holder => ({
            categoryName: holder.categoryName,
            actionName: holder.actionName,
            actionDisplayName: holder.actionDisplayName,
            colorCode: holder.colorCode,
            numberOfActionsPlayed: holder.numberOfActionsPlayed,
            numberOfFault: holder.numberOfFault,
            numberOfSuspension: holder.numberOfSuspension,
            numberOfLostBall: holder.numberOfLostBall,
            numberOfGoals: holder.numberOfGoals,
            numberOfSaves: holder.numberOfSaves,
            numberOfPostOuts: holder.numberOfPostOuts,
            numberOfActionsPlayedWith7Meters: holder.numberOfActionsPlayedWith7Meters,
            percentageGoals: holder.numberOfActionsPlayed === 0
                ? 0
                : 100 * (holder.numberOfGoals
                / holder.numberOfActionsPlayed),
            percentageNoGoals: holder.numberOfActionsPlayed === 0
                ? 0
                : 100 * ((holder.numberOfSaves + holder.numberOfPostOuts)
                / holder.numberOfActionsPlayed),
            percentage7MetersGoals: holder.numberOfActionsPlayedWith7Meters === 0
                ? 0
                : 100 * (holder.numberOfGoals7Meters / holder.numberOfActionsPlayedWith7Meters)
        }) as GameSystemStatsViewModel)
            : Array.from(gameSystemMap.values()).map(holder => ({
                playerDescription: `${player.backNumber}, ${player.name} - ${player.position}`,
                categoryName: holder.categoryName,
                actionName: holder.actionName,
                actionDisplayName: holder.actionDisplayName,
                colorCode: holder.colorCode,
                numberOfActionsPlayed: holder.numberOfActionsPlayed,
                numberOfFault: holder.numberOfFault,
                numberOfSuspension: holder.numberOfSuspension,
                numberOfLostBall: holder.numberOfLostBall,
                numberOfGoals: holder.numberOfGoals,
                numberOfSaves: holder.numberOfSaves,
                numberOfPostOuts: holder.numberOfPostOuts,
                numberOfActionsPlayedWith7Meters: holder.numberOfActionsPlayedWith7Meters,
                percentageGoals: holder.numberOfActionsPlayed === 0
                    ? 0
                    : 100 * (holder.numberOfGoals
                    / holder.numberOfActionsPlayed),
                percentageNoGoals: holder.numberOfActionsPlayed === 0
                    ? 0
                    : 100 * ((holder.numberOfSaves + holder.numberOfPostOuts)
                    / holder.numberOfActionsPlayed),
                percentage7MetersGoals: holder.numberOfActionsPlayedWith7Meters === 0
                    ? 0
                    : 100 * (holder.numberOfGoals7Meters / holder.numberOfActionsPlayedWith7Meters)
            }) as GameSystemPlayerStatsViewModel);
    }

    public generateGameSystemStatsAggregationModel(): Map<string, GameSystemViewModelAggregationHelper> {
        return Array.from(this._counterMap.entries())
            .reduce((accu, curr) => {
                const parts = curr[0].split(':');
                if (!accu.has(parts[0])) {
                    const gameSystemKeys = curr[1].gameSystemKey;
                    accu.set(parts[0], new GameSystemViewModelAggregationHelper(
                        gameSystemKeys.categoryName,
                        gameSystemKeys.actionName,
                        gameSystemKeys.actionDisplayName,
                        gameSystemKeys.colorCode
                    ));
                }
                accu.get(parts[0]).addGameSystemEventKey(curr[1]);
                return accu;
            }, new Map<string, GameSystemViewModelAggregationHelper>());
    }

    private gameSystemKeyFactory(
        gameSystemEvent: GameSystemEvents,
        gameSystem: TGameSystemPbp
    ): GameSystemKeyImpl {
        return new GameSystemKeyImpl({
            eventType: gameSystemEvent,
            actionName: gameSystem.actionName,
            categoryName: gameSystem.categoryName,
            actionDisplayName: gameSystem.actionDisplayName,
            colorCode: gameSystem.colorCode
        });
    }

    private addCounters(serKey: string, gameSystemKeyImpl: GameSystemKeyImpl): void {
        if (!this._counterMap.has(serKey)) {
            this._counterMap.set(serKey, {gameSystemKey: gameSystemKeyImpl, counter: new CounterModel(serKey, 1)});
        } else {
            const counterHolder = this._counterMap.get(serKey);
            counterHolder.counter = counterHolder.counter.increment();
            this._counterMap.set(serKey, counterHolder);
        }
    }
}
