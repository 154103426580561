import { Observable } from 'rxjs';
import { GoalConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/goal/goal-consumer.service';
import { LostBallConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/lost-ball/lost-ball-consumer.service';
import { MiscEventConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/misc-event/misc-event-consumer.service';
import {
    PlayerEventListConsumerService
} from 'src/app/shared-services/statistics/playbyplay/consumer/player-events/player-event-list-consumer.service';
import {
    PossessionsConsumerService
} from 'src/app/shared-services/statistics/playbyplay/consumer/possessions/possessions-consumer.service';
import { SuspensionConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/suspension/suspension-consumer.service';
import { PlayTimeService } from 'src/app/shared-services/statistics/play-time/play-time.service';

export class IndividualStatsStateHelper {

    private _playerStatsStateMap = new Map<string, Observable<any>>();

    constructor(
        private readonly goalConsumerService: GoalConsumerService,
        private readonly lostBallConsumerService: LostBallConsumerService,
        private readonly miscEventConsumerService: MiscEventConsumerService,
        private readonly playerEventListConsumerService: PlayerEventListConsumerService,
        private readonly possessionConsumerService: PossessionsConsumerService,
        private readonly suspensionConsumerService: SuspensionConsumerService,
        private readonly playTimeService: PlayTimeService,
    ) {
        this._playerStatsStateMap.set('goalCounter', this.goalConsumerService.selectedPlayerCounters$);
        this._playerStatsStateMap.set('lostBallCounter', this.lostBallConsumerService.selectedPlayerCounters$);
        this._playerStatsStateMap.set('miscEventCounter', this.miscEventConsumerService.selectedPlayerCounters$);
        this._playerStatsStateMap.set('playerEventList', this.playerEventListConsumerService.selectedPlayerEventList$);
        this._playerStatsStateMap.set('possessionCounter', this.possessionConsumerService.selectedPlayerPossessionCounter$);
        this._playerStatsStateMap.set('suspensionCounter', this.suspensionConsumerService.selectedPlayerCounters$);
        this._playerStatsStateMap.set('playTimeEventList', this.playTimeService.playerPlayTimeInfo$);
        this._playerStatsStateMap.set('goalConnectionCounter', this.goalConsumerService.selectedPlayerGoalConnectionCounters$);
    }

    public getObservableByName(name: string): Observable<any> {
        return this._playerStatsStateMap.get(name);
    }

    public selectPlayer(id: number): void {
        this.goalConsumerService.selectPlayer(id);
        this.lostBallConsumerService.selectPlayer(id);
        this.miscEventConsumerService.selectPlayer(id);
        this.playerEventListConsumerService.selectPlayer(id);
        this.possessionConsumerService.selectPlayerPossessions(id);
        this.suspensionConsumerService.selectPlayer(id);
        this.playTimeService.selectEventsForPlayer(id);
    }

    public deSelectPlayer(): void {
        this.goalConsumerService.deSelectPlayer();
        this.lostBallConsumerService.deSelectPlayer();
        this.miscEventConsumerService.deSelectPlayer();
        this.playerEventListConsumerService.deSelectPlayer();
        this.possessionConsumerService.deSelectPlayerPossessions();
        this.suspensionConsumerService.deSelectPlayer();
        this.playTimeService.deSelectPlayer();
    }
}
