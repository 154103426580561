import { Observable } from 'rxjs';
import {
    TeamOverviewSubConsumerService
} from 'src/app/shared-services/statistics/playbyplay/sub-consumer/team-overview/team-overview-sub-consumer.service';

export class TeamOverviewStatsStateHelper {

    private _teamStatsStateMap = new Map<string, Observable<any>>();

    constructor(
        private readonly teamOverviewSubConsumerService: TeamOverviewSubConsumerService,
    ) {
        this._teamStatsStateMap.set('homeTeamOverview', this.teamOverviewSubConsumerService.homeTeamOverviewModel$);
        this._teamStatsStateMap.set('visitorTeamOverview', this.teamOverviewSubConsumerService.visitorTeamOverviewModel$);
        this._teamStatsStateMap.set('homeIndividualSimplifiedStatsTeamOverview', this.teamOverviewSubConsumerService.homeIndividualSimplifiedStatsModel$);
        this._teamStatsStateMap.set('visitorIndividualSimplifiedStatsTeamOverview', this.teamOverviewSubConsumerService.visitorIndividualSimplifiedStatsModel$);
        this._teamStatsStateMap.set('homeTeamEventCounters', this.teamOverviewSubConsumerService.homeTeamEventCounters$);
        this._teamStatsStateMap.set('visitorTeamEventCounters', this.teamOverviewSubConsumerService.visitorTeamEventCounters$);
        this._teamStatsStateMap.set('individualStatsModel1', this.teamOverviewSubConsumerService.individualStatsModel1$);
        this._teamStatsStateMap.set('individualStatsKpiModelList', this.teamOverviewSubConsumerService.individualStatsKpiModelList$);
        this._teamStatsStateMap.set('individualStatsEventsModelList', this.teamOverviewSubConsumerService.individualStatsEventsModelList$);
        this._teamStatsStateMap.set('individualStatsGoalsModelList', this.teamOverviewSubConsumerService.individualStatsGoalsModelList$);
        this._teamStatsStateMap.set('homePlayerEventsTbl$', this.teamOverviewSubConsumerService.homeIndividualStatsEventModelList$);
        this._teamStatsStateMap.set('visitorPlayerEventsTbl$', this.teamOverviewSubConsumerService.visitorIndividualStatsEventModelList$);
    }

    public getObservableByName(name: string): Observable<any> {
        return this._teamStatsStateMap.get(name);
    }

}
