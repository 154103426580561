import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CoreService } from 'src/app/shared-services/core.service';
import { ExecutionPositionTypes, TeamMarker } from '@handballai/stats-calculation';
import {
    GameSystemStatsModel
} from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-stats.model';
import { BehaviorSubject } from 'rxjs';
import {
    GameSystemPlayerStatsViewModel,
    GameSystemStatsViewModel
} from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-view.model';
import {
    ExtendedGamePlayerModel
} from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/extended-game-player.model';
import { createExtendedGamePlayerMap } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/game-system.helper';
import { TGameSystemPbp } from 'src/app/shared-services/game-system/game-system.model';

@Injectable({
    providedIn: 'root'
})
export class GameSystemConsumerService {

    private _core: CoreService;
    private _overviewModelMap: Map<TeamMarker, GameSystemStatsModel>;
    private _homeTeamName$ = new BehaviorSubject<string>('');
    private _homeGameSystemStatsView$ = new BehaviorSubject<GameSystemStatsViewModel[]>([]);
    private _visitorTeamName$ = new BehaviorSubject<string>('');
    private _visitorGameSystemStatsView$ = new BehaviorSubject<GameSystemStatsViewModel[]>([]);
    private _playerMap: Map<number, ExtendedGamePlayerModel>;
    private _homePlayerSystemStatsMap: Map<number, GameSystemStatsModel>;
    private _visitorPlayerSystemStatsMap: Map<number, GameSystemStatsModel>;
    private _homePlayerGameSystemStatsView$ = new BehaviorSubject<GameSystemPlayerStatsViewModel[]>([]);
    private _visitorPlayerGameSystemStatsView$ = new BehaviorSubject<GameSystemPlayerStatsViewModel[]>([]);

    constructor(
        private readonly logger: NGXLogger
    ) {
    }

    public initCore(core: CoreService): void {
        this._core = core;
        this.subscribeToGoalEvent();
        this.subscribeToSaveEvent();
        this.subscribeToPostOutEvent();
        this.subscribeToLostBallEvent();
        this.subscribeToSuspensionEvent();
        this.subscribeToFaultEvent();
    }

    get homeTeamName$(): BehaviorSubject<string> {
        return this._homeTeamName$;
    }

    get homeGameSystemStatsView$(): BehaviorSubject<GameSystemStatsViewModel[]> {
        return this._homeGameSystemStatsView$;
    }

    get visitorTeamName$(): BehaviorSubject<string> {
        return this._visitorTeamName$;
    }

    get visitorGameSystemStatsView$(): BehaviorSubject<GameSystemStatsViewModel[]> {
        return this._visitorGameSystemStatsView$;
    }

    get homePlayerGameSystemStatsView$(): BehaviorSubject<GameSystemPlayerStatsViewModel[]> {
        return this._homePlayerGameSystemStatsView$;
    }

    get visitorPlayerGameSystemStatsView$(): BehaviorSubject<GameSystemPlayerStatsViewModel[]> {
        return this._visitorPlayerGameSystemStatsView$;
    }

    public init(): void {
        this.logger.debug('GameSystemConsumerService.init: ', this._core.gameService.gameModel);
        this._overviewModelMap = new Map<TeamMarker, GameSystemStatsModel>([
            ['HOME', new GameSystemStatsModel(
                this._core.gameService.gameModel.home.name
            )],
            ['VISITOR', new GameSystemStatsModel(
                this._core.gameService.gameModel.visitor.name
            )]
        ]);

        this._playerMap = createExtendedGamePlayerMap(
            this._core.gameService.gameModel.visitor.name,
            this._core.gameService.gameModel.visitor.players,
            createExtendedGamePlayerMap(
                this._core.gameService.gameModel.home.name,
                this._core.gameService.gameModel.home.players,
            )
        );
        this._homePlayerSystemStatsMap = new Map<number, GameSystemStatsModel>();
        this._visitorPlayerSystemStatsMap = new Map<number, GameSystemStatsModel>();

        this.resetSubjects();
        this.fireSubjects();
    }

    private subscribeToGoalEvent(): void {
        this._core.playByPlayProducerService.on(['GOAL'], pbp => {
            this.logger.debug('GameSystemConsumerService.goalEventSubscription: ', pbp);
            if (pbp.gameSystem) {
                this.transformGameOpening(pbp.gameSystem).forEach(gs => {
                    this._overviewModelMap.get(pbp.teamMarker).addGoal(gs, pbp?.executionPosition);
                    this.addGoalToPlayerStats(pbp.offensePlayer.id, pbp.teamMarker, gs, pbp?.executionPosition);
                });
            }
            this.fireSubjects();
        });
    }

    private subscribeToSaveEvent(): void {
        this._core.playByPlayProducerService.on(['SAVE'], pbp => {
            this.logger.debug('GameSystemConsumerService.saveEventSubscription: ', pbp);
            if (pbp.gameSystem) {
                this.transformGameOpening(pbp.gameSystem)
                    .forEach(gs => {
                        this._overviewModelMap.get(pbp.teamMarker).addSave(gs, pbp?.executionPosition);
                        this.addSaveToPlayerStats(pbp.offensePlayer.id, pbp.teamMarker, gs, pbp?.executionPosition);
                    });
            }
            this.fireSubjects();
        });
    }

    private subscribeToPostOutEvent(): void {
        this._core.playByPlayProducerService.on(['POST_OUT'], pbp => {
            this.logger.debug('GameSystemConsumerService.postEventSubscription: ', pbp);
            if (pbp.gameSystem) {
                this.transformGameOpening(pbp.gameSystem)
                    .forEach(gs => {
                        this._overviewModelMap.get(pbp.teamMarker).addPostOut(gs, pbp?.executionPosition);
                        this.addPostOutToPlayerStats(pbp.offensePlayer.id, pbp.teamMarker, gs, pbp?.executionPosition);
                    });
            }
            this.fireSubjects();
        });
    }

    private subscribeToLostBallEvent(): void {
        this._core.playByPlayProducerService.on(['ATTACK_FAULT_COMMIT','TECHNICAL_MISTAKE_COMMIT','LOST_BALL'], pbp => {
            this.logger.debug('GameSystemConsumerService.otherEventSubscription: ', pbp);
            if (pbp.gameSystem) {
                this.transformGameOpening(pbp.gameSystem)
                    .forEach(gs => {
                        this._overviewModelMap.get(pbp.teamMarker).addLostBall(gs);
                        this.addLostBallToPlayerStats(pbp.offensePlayer.id, pbp.teamMarker, gs);
                    });
            }
            this.fireSubjects();
        });
    }
    private subscribeToSuspensionEvent(): void {
        this._core.playByPlayProducerService.on(['2MIN_PROVOKE','7M_PROVOKE_SUSPENSION'], pbp => {
            this.logger.debug('GameSystemConsumerService.otherEventSubscription: ', pbp);
            if (pbp.gameSystem) {
                this.transformGameOpening(pbp.gameSystem)
                    .forEach(gs => {
                        this._overviewModelMap.get(pbp.teamMarker).addSuspension(gs);
                        this.addSuspensionToPlayerStats(pbp.offensePlayer.id, pbp.teamMarker, gs);
                    });
            }
            this.fireSubjects();
        });
    }
    private subscribeToFaultEvent(): void {
        this._core.playByPlayProducerService.on(['FOUL_RECEIVE'], pbp => {
            this.logger.debug('GameSystemConsumerService.otherEventSubscription: ', pbp);
            if (pbp.gameSystem) {
                this.transformGameOpening(pbp.gameSystem)
                    .forEach(gs => {
                        this._overviewModelMap.get(pbp.teamMarker).addFault(gs);
                        this.addFaultToPlayerStats(pbp.offensePlayer.id, pbp.teamMarker, gs);
                    });
            }
            this.fireSubjects();
        });
    }

    private transformGameOpening(gameOpening: string): TGameSystemPbp[] {
        const gs = JSON.parse(gameOpening);
        if (Array.isArray(gs)) {
            return gs;
        } else {
            return [gs];
        }
    }

    private addGoalToPlayerStats(
        playerId: number,
        teamMarker: TeamMarker,
        gameSystem: TGameSystemPbp,
        executionPosition?: ExecutionPositionTypes
    ): void {
        const playerMap = this.selectPlayerMap(playerId, teamMarker);
        playerMap.get(playerId).addGoal(gameSystem, executionPosition);
    }

    private addSaveToPlayerStats(
        playerId: number,
        teamMarker: TeamMarker,
        gameSystem: TGameSystemPbp,
        executionPosition?: ExecutionPositionTypes
    ): void {
        const playerMap = this.selectPlayerMap(playerId, teamMarker);
        playerMap.get(playerId).addSave(gameSystem, executionPosition);
    }

    private addPostOutToPlayerStats(
        playerId: number,
        teamMarker: TeamMarker,
        gameSystem: TGameSystemPbp,
        executionPosition?: ExecutionPositionTypes
    ): void {
        const playerMap = this.selectPlayerMap(playerId, teamMarker);
        playerMap.get(playerId).addPostOut(gameSystem, executionPosition);
    }

    private addLostBallToPlayerStats(
        playerId: number,
        teamMarker: TeamMarker,
        gameSystem: TGameSystemPbp,
    ): void {
        const playerMap = this.selectPlayerMap(playerId, teamMarker);
        playerMap.get(playerId).addLostBall(gameSystem);
    }
    private addSuspensionToPlayerStats(
        playerId: number,
        teamMarker: TeamMarker,
        gameSystem: TGameSystemPbp,
    ): void {
        const playerMap = this.selectPlayerMap(playerId, teamMarker);
        playerMap.get(playerId).addSuspension(gameSystem);
    }
    private addFaultToPlayerStats(
        playerId: number,
        teamMarker: TeamMarker,
        gameSystem: TGameSystemPbp,
    ): void {
        const playerMap = this.selectPlayerMap(playerId, teamMarker);
        playerMap.get(playerId).addFault(gameSystem);
    }

    private selectPlayerMap(
        playerId: number,
        teamMarker: TeamMarker,
    ): Map<number, GameSystemStatsModel> {
        let playerMap: Map<number, GameSystemStatsModel>;
        if (teamMarker === 'HOME') {
            playerMap = this._homePlayerSystemStatsMap;
        } else {
            playerMap = this._visitorPlayerSystemStatsMap;
        }
        if (!playerMap.has(playerId)) {
            playerMap.set(playerId, new GameSystemStatsModel(
                teamMarker === 'HOME' ? this._homeTeamName$.value : this._visitorTeamName$.value
            ));
        }
        return playerMap;
    }
    private resetSubjects(): void {
        this._homeTeamName$.next('');
        this._homeGameSystemStatsView$.next([]);
        this._visitorTeamName$.next('');
        this._visitorGameSystemStatsView$.next([]);
        this._homePlayerGameSystemStatsView$.next([]);
        this._visitorPlayerGameSystemStatsView$.next([]);
    }

    private flatMapPlayerStats(playerMap: Map<number, GameSystemStatsModel>): GameSystemPlayerStatsViewModel[] {
        return Array.from(playerMap.entries())
            .map(entry => entry[1].generateGameSystemPlayerStats(this._playerMap.get(entry[0])))
            .reduce((accu, curr) => {
                accu = [...accu, ...curr.gameSystemPlayerStatsView];
                return accu;
            }, [] as GameSystemPlayerStatsViewModel[]);
    }
    private fireSubjects(): void {
      const homeStatsWrapper = this._overviewModelMap.get('HOME').generateGameSystemStats();
      const visitorStatsWrapper = this._overviewModelMap.get('VISITOR').generateGameSystemStats();
      const homePlayerStatsWrapper = this.flatMapPlayerStats(this._homePlayerSystemStatsMap);
      const visitorPlayerStatsWrapper = this.flatMapPlayerStats(this._visitorPlayerSystemStatsMap);
      this.logger.debug('GameSystemConsumerService.fireSubjects() - home', homePlayerStatsWrapper);
      this.logger.debug('GameSystemConsumerService.fireSubjects() - visitor', visitorPlayerStatsWrapper);
      this._homeTeamName$.next(homeStatsWrapper.teamName);
      this._homeGameSystemStatsView$.next([...homeStatsWrapper.gameSystemStatsView]);
      this._homePlayerGameSystemStatsView$.next([...homePlayerStatsWrapper]);
      this._visitorTeamName$.next(visitorStatsWrapper.teamName);
      this._visitorGameSystemStatsView$.next([...visitorStatsWrapper.gameSystemStatsView]);
      this._visitorPlayerGameSystemStatsView$.next([...visitorPlayerStatsWrapper]);
    }


}
