<!-- <ion-content> -->
<div class="handball-playByPlay-container">
    <div *ngIf="!core.isMobileDevice" class="mb-5 mt-5 w-full flex justify-end">
        <ion-button class="ion-text-uppercase" *ngIf="(selectedAccountEquipo$|async)?.account?.permissions.askDownloadPlayByPlay && !game.csvRequestedBy"
                (click)="onCsvRequest()" [disabled]="playByPlayRecords.length === 0">
            {{'Request CSV'|translate}}
        </ion-button>
        <ion-text *ngIf="game.csvRequestedBy && game.csvRequestAccept===null">CSV Download Requested {{(game.csvRequestedBy==(userId|async)) ? 'by you':'by other user'}}</ion-text>
        <ion-text *ngIf="game.csvRequestedBy && game.csvRequestAccept===false">CSV Download Rejected, contact info@handball.ai to get it.</ion-text>
        <ion-button class="ion-text-uppercase text-sm" [color]="'secondary'"
                    *ngIf="!(selectedAccountEquipo$|async)?.account?.permissions.askDownloadPlayByPlay || (game.csvRequestAccept && game.csvRequestedBy==(userId|async))"
                (click)="onSaveCsvClicked()" [disabled]="playByPlayRecords.length === 0">
           <span class="px-4 font-bold text-base">{{'Download CSV'|translate}}</span> 
        </ion-button>
    </div>
    
<div class="handball-playByPlay__grid-wrapper">
    
    <app-play-by-play-grid class="w-full"
                            [players]="players"
                            [playByPlayRecords]="playByPlayRecords"
                            [editable]="editable" [gameId]="game.id">
    </app-play-by-play-grid>
</div>
</div>
<!-- </ion-content> -->
