<div class="game-system-stats">
  <!-- <h3>{{'GAME SYSTEM'|translate}}</h3> -->
  <div class="legend">
    <h5>{{homeTeamName}}</h5>
  </div>
  <div *ngIf="core.isMobileDevice" class="expand-toggle">
    <ion-toggle (ionChange)="onExpandTableColumnsClick()"></ion-toggle>
  </div>
  <div class="game-system-stats-game-system-table game-table">
    <ngx-datatable
            class="material"
            style="width: 100%; box-shadow: none"
            [rows]="homeGameSystemStatsView"
            [columns]="columns1"
            [limit]="20"
            [groupRowsBy]="'playerDescription'"
            [columnMode]="'force'"
            rowHeight="auto"
            [scrollbarH]="true"
            [footerHeight]="false"
            [headerHeight]="50"
            [groupExpansionDefault]="true"
    >
      <ngx-datatable-group-header [rowHeight]="70">
        <ng-template ngx-datatable-group-header-template let-group="group">
          <div style="width: 300px; padding: 1rem;">
           <span>{{createGroupName(group)}}</span>
          </div>
        </ng-template>
      </ngx-datatable-group-header>
      <ngx-datatable-column prop="actionDisplayName"
                            [flexGrow]="2"
                            [sortable]="false"
                            [resizeable]="false"
                            [cellClass]="getRowClass">
        <ng-template let-column="column" ngx-datatable-header-template>
          ACTION
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template >
          {{row.categoryName}} - {{row.actionDisplayName}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column prop="numberOfActionsPlayed"
                            [flexGrow]="core.isMobileDevice ? 0.7 : 1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'TIMES PLAYED'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="numberOfGoals"
                            [flexGrow]="core.isMobileDevice ? 0.7 : 1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° GOALS'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="numberOfSaves"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° SAVES'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="numberOfPostOuts"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° POST / OUT'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="numberOfActionsPlayedWith7Meters"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° 7 METERS'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="numberOfFault"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° FAULT'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="numberOfSuspension"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° SUSPENSION'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="numberOfLostBall"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° LOST BALL'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="percentageGoals"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'GOALS %'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value | number: '1.0-0'}} %
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="percentageNoGoals"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'NO GOALS %'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value | number: '1.0-0'}} %
          </span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
  <hr/>
  <div class="legend">
    <h5>{{visitorTeamName}}</h5>
  </div>
  <div *ngIf="core.isMobileDevice" class="expand-toggle">
    <ion-toggle (ionChange)="onExpandVisitorTableColumnsClick()"></ion-toggle>
  </div>
  <div class="game-system-stats-game-system-table game-table">
    <ngx-datatable
            class="material"
            style="width: 100%; box-shadow: none"
            [rows]="visitorGameSystemStatsView"
            [columns]="columns1"
            [groupRowsBy]="'playerDescription'"
            [limit]="20"
            [columnMode]="'force'"
            rowHeight="auto"
            [scrollbarH]="true"
            [footerHeight]="false"
            [headerHeight]="50"
            [groupExpansionDefault]="true"
    >
      <ngx-datatable-group-header [rowHeight]="70">
        <ng-template ngx-datatable-group-header-template let-group="group">
          <div style="width: 300px; padding: 1rem;">
           <span>{{createGroupName(group)}}</span>
          </div>
        </ng-template>
      </ngx-datatable-group-header>
      <ngx-datatable-column prop="actionDisplayName"
                            [flexGrow]="2"
                            [sortable]="false"
                            [resizeable]="false"
                            [cellClass]="getRowClass">
        <ng-template let-column="column" ngx-datatable-header-template>
          ACTION
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template >
          {{row.categoryName}} - {{row.actionDisplayName}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="numberOfActionsPlayed"
                            [flexGrow]="core.isMobileDevice ? 0.7 : 1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'TIMES PLAYED'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="numberOfGoals"
                            [flexGrow]="core.isMobileDevice ? 0.7 : 1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° GOALS'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tableVisitorAllColsShown" prop="numberOfSaves"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° SAVES'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tableVisitorAllColsShown" prop="numberOfPostOuts"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° POST / OUT'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tableVisitorAllColsShown" prop="numberOfActionsPlayedWith7Meters"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° 7 METERS'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tableVisitorAllColsShown" prop="numberOfFault"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° FAULT'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tableVisitorAllColsShown" prop="numberOfSuspension"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° SUSPENSION'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tableVisitorAllColsShown" prop="numberOfLostBall"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'N° LOST BALL'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value}}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tableVisitorAllColsShown" prop="percentageGoals"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'GOALS %'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value | number: '1.0-0'}} %
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tableVisitorAllColsShown" prop="percentageNoGoals"
                            [flexGrow]="1"
                            [sortable]="false"
                            [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'NO GOALS %'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
          <span>
            {{value | number: '1.0-0'}} %
          </span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
