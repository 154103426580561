import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { CoreService } from 'src/app/shared-services/core.service';

@Component({
    selector: 'app-network-error-popover',
    templateUrl: './network-error-popover.component.html',
    styleUrls: ['./network-error-popover.component.scss'],
    standalone: false
})
export class NetworkErrorPopoverComponent implements OnInit {

  constructor(
    private readonly core: CoreService,
    private readonly navCntl: NavController,
    private readonly popoverCntl: PopoverController,
  ) { }

  ngOnInit(): void {
  }

  public async dismiss(): Promise<void> {
    await this.core.userService.logout();
    this.navCntl.navigateRoot('/');
    await this.popoverCntl.dismiss();
  }
}
