<app-lite-double-event-popover [teamMarker]="teamMarker"
                               [fieldPlayers]="fieldPlayers"
                               [opponentTeam]="opponentTeam"
                               [sourceEvent]="sourceEvent"
                               [requirePlayerSelect]="requirePlayerSelect"
                               [isSuspensionToTheBench]="isSuspensionToTheBench"
                               [sourceSuspensionEvent]="sourceSuspensionEvent"
                               [gameSystemDtos]="gameSystem$ | async" [gameMode]="gameMode$ | async"
                               [gameSystemEnabled]="gameSystemEnabled | async"
                               [gameActionNameSelected]="gameActionNameSelected"
                               [videoSrc]="videoSrc" [videoCurrentTime]="videoCurrentTime">
</app-lite-double-event-popover>
