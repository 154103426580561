import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-dashboard-accordion',
    templateUrl: './dashboard-accordion.component.html',
    styleUrls: ['./dashboard-accordion.component.scss'],
    standalone: false
})
export class DashboardAccordionComponent  implements OnInit {

  @Input() title: string = '';
  @Input() description: string = '';
  @Input() isOpen: boolean = false;

  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {}


  toggleAccordion() {
    this.toggle.emit(!this.isOpen);
  }
}
