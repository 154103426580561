import { Component, ViewChild, ElementRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { PdfMakeWrapper, Img, Table, Txt, Cell, SVG, ICustomTableLayout, Rect, Canvas, IImg, ISVG, Columns } from 'pdfmake-wrapper';
import { CoreService} from 'src/app/shared-services/core.service';
import { GameFilterTableModel } from 'src/app/shared-services/game-data/model/game-filter-table.model';
import { PdfTeamStats} from 'src/app/shared-services/dashboards/dashboards.service';
import { GoalkeepersDetailTableRow, GoalkeeperSummary, IndividualPerformanceTableRow, KeyVal, PdfReportData, Team } from './typings';
import { ModalController } from '@ionic/angular';
import { PdfViewerPage } from 'src/app/main/components/pdf-viewer/pdf-viewer';
import { PlayerStatsAggregateDto, PlayerStatsDto, TeamsStatsDto, TeamStatsAggregateDto } from 'src/app/api/hai-api';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { GameType } from 'src/app/shared-services/game/game-type.model';
import { Connection, Goals, SimplifiedPlayerStats } from '@handballai/stats-calculation';
import { GameEndStorageModel } from 'src/app/shared-services/storage-service/model/game-end-storage.model';
import { transformGoalConnectionCounter } from '../../../dashboard-module/goal-connection-helper';
import {
  combineAndSortGoalDataHelper,
  generateGoalByGoal,
  generatePlayerIconImageHelper,
  generatePlayerIconLegendHelper,
  goalKeeperTableColumnsHelper,
  goalKeeperTableColumnsWithETHelper,
  goalKeeperTableLegendMapHelper,
  GoalTimeLineCombined,
  playerTableColumnsHelper, playerTableColumnsWithETHelper,
  playerTableLegendMapHelper
} from './pdf-report-quickstats-icons-table.helper';
import { calculateSummaryPossessions } from './summary-possessions.helper';
import { NGXLogger } from 'ngx-logger';
import { GameSystemStatsViewModel } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-view.model';
import {
  convertPlayByPlayToGameSystem} from 'src/app/main/pages/aehandler-module/pages/game-module/components/pdf-report/pdf-game-system.helper';


import {pdfColors} from './pdf-colors';
import { handballField, logoWithTextSVG, svgTextLogo, teamLogoSVG } from './pdf-svg-in-string-format';
import { globalDataDate } from './pdf-report-helpers';
import { PlayersService } from 'src/app/main/pages/players-module/players.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  BarController,
  BarElement,
  CategoryScale,
  PointElement,
  DoughnutController
} from 'chart.js';

Chart.register(
    ArcElement,
    Tooltip,
    Legend,
    Title,
    ChartDataLabels,
    DoughnutController,
    BarController,
    BarElement,
    CategoryScale,
    PointElement
);

const PDF_URL = 'www.handball.ai';
const removePercentge = (s) => {
  return [...s].filter(e => e !== '%').join('').trimStart()
}

export interface RectConfig {
  rectType: 'header' | 'body' | 'footer' | 'rect' | 'rect-with-border' | 'progress-bar';
  rectWidth: number;
  rectColor: string;

  firstRectX?: number;
  firstRectY?: number;
  firstRectHeight?: number;

  firstRectColor?: string,
  firstRectLineColor?: string,

  secondRectX?: number;
  secondRectY?: number;
  secondRectHeight?: number;
}

export const toUpperCase = (s) => {
  return s.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase())
}

const layoutTableBodyGrid: ICustomTableLayout = {
  hLineWidth: (i: any, node: any) => {
    if (i === 0 || i === node.table.body.length) {
      return 0;
    }
    return 2;
  },
  vLineWidth: (i: any, node: any) => {
    return (i === 0 || i === node.table.widths.length) ? 0 : 2;
  },
  hLineColor: function (i: any, node: any) {
    // return (i === 0 || i === node.table.body.length) ? 'red' : 'blue';
    return (i === 0 || i === node.table.body.length) ? 'red' : '#F1F5F8';
  },
  vLineColor: function (i: any, node: any) {
    // return (i === 0 || i === node.table.body.length) ? 'red' : 'blue';
    return (i === 0 || i === node.table.widths.length) ? 'red' : '#F1F5F8';
  },

  // paddingTop: function (i: any, node: any) {
  //   return (i === node.table.widths.length - 1) ? 0 : 3;
  // },
  // paddingBottom: function (i: any, node: any) {
  //   return (i === node.table.widths.length - 1) ? 0 : 3;
  // }


  paddingTop: function (i: any, node: any) {
    return 1
    // return (i === node.table.widths.length - 1) ? 0 : 1;
  },
  paddingBottom: function (i: any, node: any) {
    return 1;
    return (i === node.table.widths.length - 1) ? 0 : 1;
  }
};

const layoutTableBodyGridTest: ICustomTableLayout = {
  hLineWidth: (i: any, node: any) => {
    if (i === 0 || i === node.table.body.length) {
      return 0;
    }
    return 2;
  },
  vLineWidth: (i: any, node: any) => {
    return (i === 0 || i === node.table.widths.length) ? 0 : 2;
  },
  hLineColor: function (i: any, node: any) {
    return (i === 0 || i === node.table.body.length) ? 'red' : '#FFFFFF';
  },
  vLineColor: function (i: any, node: any) {
    return (i === 0 || i === node.table.body.length) ? 'red' : '#FFFFFF';
  },

  paddingTop: function (i: any, node: any) {
    return 1
  },
  paddingBottom: function (i: any, node: any) {
    return 1;
  }
};

export const layoutTableBodyGridTest2: ICustomTableLayout = {
  hLineWidth: (i: any, node: any) => {
    if (i === 0 || i === node.table.body.length) {
      return 0;
    }
    return 2;
  },
  vLineWidth: (i: any, node: any) => {
    return (i === 0 || i === node.table.widths.length) ? 0 : 2;
  },
  hLineColor: function (i: any, node: any) {
    return (i === 0 || i === node.table.body.length) ? 'red' : '#FFF';

  },
  vLineColor: function (i: any, node: any) {
    return (i === 0 || i === node.table.widths.length) ? 'red' : '#FFF';
  }
};


@Component({
    selector: 'app-pdf-report',
    templateUrl: './pdf-report.component.html',
    styleUrls: ['./pdf-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class PdfReportComponent {
  teamHeaderLogoHome: ISVG | IImg;
  teamHeaderLogoVisitor: ISVG | IImg;


  gameType: GameType = 'COMPLETE_MODE';
  public globalData: PdfReportData = {
    date: "10/03/2021",
    home: {
      name: "Team 1 Name",
      logo: "https://images.beta.handball.ai/1667341130971_image-ad38.jpg",
      overview: {
        summaryPossessions: '',
        overviewTable: [
          {
            "goals": 10,
            "failedShots": 10,
            "saves": 8,
            "postOut": 5,
            "lostBalls": 10,
            "posessions": 1,
            "efficiency": 4,
            "shootingEfficiency": 7,
            "lostBallsEfficiency": 10,
          },
          {
            "goals": 11,
            "failedShots": 11,
            "saves": 9,
            "postOut": 6,
            "lostBalls": 11,
            "posessions": 2,
            "efficiency": 5,
            "shootingEfficiency": 8,
            "lostBallsEfficiency": 11,
          },
          {
            "goals": 12,
            "failedShots": 12,
            "saves": 10,
            "postOut": 7,
            "lostBalls": 12,
            "posessions": 3,
            "efficiency": 6,
            "shootingEfficiency": 9,
            "lostBallsEfficiency": 12,
          },
        ],

        efficiencySummaryTable: [
          {
            equalityEfficiencySummary: 1,
            superiorityEfficiencySummary: 4,
            inferiorityEfficiencySummary: 7,
          },
          {
            equalityEfficiencySummary: 2,
            superiorityEfficiencySummary: 5,
            inferiorityEfficiencySummary: 8,
          },
          {
            equalityEfficiencySummary: 3,
            superiorityEfficiencySummary: 6,
            inferiorityEfficiencySummary: 9,
          },
        ],

        efficiencyDetailTable: [
          {
            defenseSystem: '6:0',
            equalityEff: 1,
            superiorityEff: 2,
            inferiorityEff: 3
          },
          {
            defenseSystem: '5:1',
            equalityEff: 1,
            superiorityEff: 2,
            inferiorityEff: 3
          },
          {
            defenseSystem: '3:2-1',
            equalityEff: 1,
            superiorityEff: 2,
            inferiorityEff: 3
          },
          {
            defenseSystem: '4:2',
            equalityEff: 1,
            superiorityEff: 2,
            inferiorityEff: 3
          },
          {
            defenseSystem: 'others',
            equalityEff: 1,
            superiorityEff: 2,
            inferiorityEff: 3
          },
        ],

        quickTable: {
          left: [
            {key: 'LOST BALLS %', value: 423},
            {key: 'OFFENSE POSITIONAL %', value: 423},
            {key: 'FAST BREAK %', value: 423},
            {key: 'COUNTER GOAL', value: 423},
            {key: '7M GOAL', value: 423},
            {key: '7M', value: 423},
            {key: 'GOALS', value: 423},
          ],
          right: [
            {key: 'POSSESIONS', value: 423},
            {key: 'SUPERIORITY', value: 423},
            {key: '7vs6', value: 423},
            {key: '% EFF. DEFFENSE', value: 423},
            {key: '% EFF. OFFENSE', value: 423},
            {key: 'SAVES', value: 423},
            {key: 'LOST BALLS', value: 423},
          ]
        },

        goalkeeperSummary: {
          goals: 28,
          saves: 12
        },

        goalkeepersDetailTable: [
          {
            imageUrl: '',
            backNumber: '01',
            name: 'A.DIVA',
            goals: 11,
            saves: 3,
            rol: 'rw'
          },
          {
            imageUrl: '',
            backNumber: '87',
            name: 'R.LAIS',
            goals: 17,
            saves: 9,
            rol: 'rw'
          }
        ],

        connectionGoals: {
          connections: [
            { startPos: 'gk', endPos: 'lw', weight: 3 },
            { startPos: 'lw', endPos: 'gk', weight: 4 },
            { startPos: 'rw', endPos: 'lb', weight: 1 },
            { startPos: 'lb', endPos: 'rw', weight: 7 },
          ],
          goals: [
            { position: 'gk', weight: 3, label: 'LAB:gk' },
            { position: 'lw', weight: 4, label: 'LAB:lw' },
            { position: 'rw', weight: 1, label: 'LAB:rw' },
            { position: 'lb', weight: 7, label: 'LAB:lb' },
          ]
        },
        shootingLocation: [
          { position: 'LEFT_WING', weight: 3, label: 'LAB:LEFT_WING' },
          { position: 'LEFT_6M', weight: 4, label: 'LAB:LEFT_6M' },
          { position: 'CENTER_6M', weight: 1, label: 'LAB:CENTER_6M' },
          { position: 'RIGHT_6M', weight: 2, label: 'LAB:RIGHT_6M' },
        ],
      },
      individualPerformanceTable: [
        {
          rol: 'player',
          imageUrl: '/assets/avatar.svg',
          backNumber: '03',
          name: 'E. Arrojeria',
          time: '10:45',
          goals: 3,
          possesionsPlayed: 3,
          failedShots: 0,
          saves: 0,
          outs: 0,
          losts: 4,
          performance: '0.5',

          attackFaultProvoke: 0,
          technicalMistakeProvoke: 0,
          lostBallProvoke: 0,
          twoMinCommit: 0,
          sevenMetersCommit: 0,
          sevenMSuspCommit: 0,
          oneAndOneLost: 0,
          foulCommit: 0,
          shotBlock: 0,
          attackFaultCommit: 0,
          technicalMistakeCommit: 0,
          lostBall: 0,
          twoMinProvoke: 0,
          sevenMetersProvoke: 0,
          sevenMSuspProvoke: 0,
          oneAndOneWon: 0,
          foulReceive: 0,
          shotBlocked: 0,
        },
      ],
      timeline: {
        goals: [3, 1, 2, 2, 1, 2, 3, 1, 2, 2, 1, 2],
        possesions: [1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3],
        lostBalls: [1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3],
        saves: [1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3],
      },
      goalkeepersShooting: [],
      gameSystemTable: [],
    },
    visitor: {
      name: "Team 2 Name",
      logo: "https://images.beta.handball.ai/1667341130971_image-ad38.jpg",
      overview: {
        summaryPossessions: '',
        overviewTable: [
          {
            goals: 10,
            failedShots: 10,
            saves: 8,
            postOut: 5,
            lostBalls: 10,
            posessions: 1,
            efficiency: 4,
            shootingEfficiency: 7,
            lostBallsEfficiency: 10,
          },
          {
            goals: 11,
            failedShots: 11,
            saves: 9,
            postOut: 6,
            lostBalls: 11,
            posessions: 2,
            efficiency: 5,
            shootingEfficiency: 8,
            lostBallsEfficiency: 11,
          },
          {
            goals: 12,
            failedShots: 12,
            saves: 10,
            postOut: 7,
            lostBalls: 12,
            posessions: 3,
            efficiency: 6,
            shootingEfficiency: 9,
            lostBallsEfficiency: 12,
          }
        ],

        efficiencySummaryTable: [
          {
            equalityEfficiencySummary: 1,
            superiorityEfficiencySummary: 4,
            inferiorityEfficiencySummary: 7,
          },
          {
            equalityEfficiencySummary: 2,
            superiorityEfficiencySummary: 5,
            inferiorityEfficiencySummary: 8,
          },
          {
            equalityEfficiencySummary: 3,
            superiorityEfficiencySummary: 6,
            inferiorityEfficiencySummary: 9,
          }
        ],

        efficiencyDetailTable: [
          {
            defenseSystem: '6:0',
            equalityEff: 1,
            superiorityEff: 2,
            inferiorityEff: 3
          },
          {
            defenseSystem: '5:1',
            equalityEff: 1,
            superiorityEff: 2,
            inferiorityEff: 3
          },
          {
            defenseSystem: '3:2-1',
            equalityEff: 1,
            superiorityEff: 2,
            inferiorityEff: 3
          },
          {
            defenseSystem: '4:2',
            equalityEff: 1,
            superiorityEff: 2,
            inferiorityEff: 3
          },
          {
            defenseSystem: 'others',
            equalityEff: 1,
            superiorityEff: 2,
            inferiorityEff: 3
          },
        ],

        quickTable: {
          left: [
            {key: 'LOST BALLS', value: 423},
            {key: 'OFFENSE POSITIONAL', value: 423},
            {key: 'FAST BREAK', value: 423},
            {key: 'COUNTER GOAL', value: 423},
            {key: '7M GOAL', value: 423},
            {key: '7M', value: 423},
            {key: 'GOALS', value: 423},
          ],
          right: [
            {key: 'POSSESIONS', value: 423},
            {key: 'SUPERIORITY', value: 423},
            {key: '7vs6', value: 423},
            {key: '% EFF. DEFFENSE', value: 423},
            {key: '% EFF. OFFENSE', value: 423},
            {key: 'SAVES', value: 423},
            {key: 'LOST BALLS', value: 423},
          ]
        },

        goalkeeperSummary: {
          goals: 28,
          saves: 12
        },

        goalkeepersDetailTable: [
          {
            imageUrl: '',
            backNumber: '01',
            name: 'A.DIVA',
            goals: 11,
            saves: 3,
            rol: 'rw'
          },
          {
            imageUrl: '',
            backNumber: '87',
            name: 'R.LAIS',
            goals: 17,
            saves: 9,
            rol: 'rw'
          }
        ],

        connectionGoals: {
          connections: [
            { startPos: 'gk', endPos: 'lw', weight: 3 },
            { startPos: 'lw', endPos: 'gk', weight: 4 },
            { startPos: 'rw', endPos: 'lb', weight: 1 },
            { startPos: 'lb', endPos: 'rw', weight: 7 },
            { startPos: 'gk', endPos: 'lp', weight: 5 },
            { startPos: 'lb', endPos: 'lw', weight: 7 },
          ],
          goals: [
            { position: 'gk', weight: 3, label: 'LAB:gk' },
            { position: 'lw', weight: 4, label: 'LAB:lw' },
            { position: 'rw', weight: 1, label: 'LAB:rw' },
            { position: 'lb', weight: 7, label: 'LAB:lb' },
            { position: 'lp', weight: 6, label: 'LAB:lp' },
          ]
        },
        shootingLocation: [
          { position: 'LEFT_WING', weight: 3, label: 'LAB:LEFT_WING' },
          { position: 'LEFT_6M', weight: 4, label: 'LAB:LEFT_6M' },
          { position: 'CENTER_6M', weight: 1, label: 'LAB:CENTER_6M' },
          { position: 'RIGHT_6M', weight: 7, label: 'LAB:RIGHT_6M' },
        ],
      },
      individualPerformanceTable: [
        {
          rol: 'player',
          imageUrl: '/assets/avatar.svg',
          backNumber: '03',
          name: 'E. Arrojeria',
          time: '10:45',
          goals: 3,
          possesionsPlayed: 3,
          failedShots: 0,
          saves: 0,
          outs: 0,
          losts: 4,
          performance: '0.5',

          attackFaultProvoke: 0,
          technicalMistakeProvoke: 0,
          lostBallProvoke: 0,
          twoMinCommit: 0,
          sevenMetersCommit: 0,
          sevenMSuspCommit: 0,
          oneAndOneLost: 0,
          foulCommit: 0,
          shotBlock: 0,
          attackFaultCommit: 0,
          technicalMistakeCommit: 0,
          lostBall: 0,
          twoMinProvoke: 0,
          sevenMetersProvoke: 0,
          sevenMSuspProvoke: 0,
          oneAndOneWon: 0,
          foulReceive: 0,
          shotBlocked: 0,
        },
      ],
      timeline: {
        goals: [3, 1, 2, 2, 1, 2, 3, 1, 2, 2, 1, 2],
        possesions: [1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 13],
        lostBalls: [1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3],
        saves: [1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3],
      },
      goalkeepersShooting: [],
      gameSystemTable: [],
    },
  };

  private pdf: PdfMakeWrapper;

  public cOptions: any;
  public circleColors: any[] = [{ backgroundColor: ['#36ABE0', '#0E375F'] }];

  public canvas1Values: number[] = [];
  public canvas2Values: number[] = [];

  // canvas3 / canvas4
  public canvas3BarColors: any[] = [
    { backgroundColor: '#2EB67D' },
    { backgroundColor: '#36C5F0' },
    { backgroundColor: '#ECB22E' },
    { backgroundColor: '#A5A5A5' },
    { backgroundColor: '#E01E5A' },
  ];

  public canvas3Values: any[] = [
    { data: [], label: '' },
    { data: [], label: '' }
  ];;
  public canvas3Labels: any[] = [];
  public canvas4Values: any[] = [
    { data: [], label: '' },
    { data: [], label: '' }
  ];;
  public canvas4Labels: any[] = [];

  public barOptions: any = {
    //title: { text: 'GOALKEEPERS', display: true, fontSize: 20},
    plugins: {
      labels: {
        fontSize: 10,
        fontColor: '#0e375f',
        render: (args: { value: number; percentage: number; label: string }) => args.value === 0 ? '' : `${args.percentage}%`
      }
    },
    animation: {
      duration: 0
    },
  };

// for canvas3/canvas4
  public pluginsBar3: any = [{
    id: "increase-legend-spacing",
    beforeInit(chart) {
      const originalFit = (chart.legend as any).fit;
      (chart.legend as any).fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 35;
      };
    }
  }];

  // for canvas3/canvas4
  public canvas3BarOptions: any = {
    scales: {
      xAxes: [{
        ticks: {
          fontSize: 13,
          fontStyle: 'bold',
          fontFamily: "'Montserrat', sans-serif",
          fontColor: '#1A375C',
        }
      }],
      yAxes: [{
        ticks: {
          fontSize: 17,
          fontStyle: 'bold',
          fontFamily: "'Montserrat', sans-serif",
          fontColor: '#1A375C',
        }
      }],
  },
    legend: {
      labels: {
        boxWidth: 50,
        padding: 25,
        fontSize: 13,
        fontStyle: 'bold',
        fontFamily: "'Montserrat', sans-serif",
        fontColor: '#1A375C'
      },
    },
    plugins: {
      labels: {
        fontSize: 12,
        fontColor: '#1A375C',
        fontFamily: "'Montserrat', sans-serif",

        render: (args: { value: number; percentage: number; label: string }) => args.value === 0 ? '' : `${args.percentage}%`
      }
    },
    animation: {
      duration: 0
    },
  };

  private _simplifiedPlayerModelsHome: SimplifiedPlayerStats[] | undefined = undefined;
  private _simplifiedPlayerModelsGuest: SimplifiedPlayerStats[] | undefined = undefined;
  private _extraTimeOrPenaltiesPlayed = false;
  private _goalByGoal: GoalTimeLineCombined[] = [];

  private svgArrowDown : string = '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="red" class="bi bi-arrow-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/></svg>';
  private svgArrowUp : string = '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="green" class="bi bi-arrow-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/></svg>';


  private fontSize = 8;
  private homeTeamBgColor = '#2aacd3';
  private homeTeamOddRowsColor = '#ededed';
  private homeTeamFontColor = 'white';
  private visitorTeamBgColor = '#0e375f';
  private visitorTeamOddRowsColor = '#ededed';
  private visitorTeamFontColor = 'white';

  @ViewChild('chart1') canvas1: ElementRef<HTMLCanvasElement>;
  @ViewChild('chart2') canvas2: ElementRef<HTMLCanvasElement>;
  @ViewChild('chart3') canvas3: ElementRef<HTMLCanvasElement>;
  @ViewChild('chart4') canvas4: ElementRef<HTMLCanvasElement>;

  doughnut1Chart: any;
  doughnut2Chart: any;
  bar3Chart: any;
  bar4Chart: any;
  line5Chart: any;

  chartDoughnutOptions: any;

  chartBarOptions: any = {
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: 13,
            style: 'bold',
            family: "'Montserrat', sans-serif"
          },
          color: '#1A375C'
        }
      },
      y: {
        stacked: true,
        ticks: {
          font: {
            size: 17,
            style: 'bold',
            family: "'Montserrat', sans-serif"
          },
          color: '#1A375C'
        }
      }
    },
    plugins: {
      datalabels: {
        color: '#0e375f',
        font: {
          size: 14
        },
        anchor: 'end',
        align: 'top',
        formatter: (value: number, context: any) => {
          if (value === 0) return '';
          const chartData = context.chart.data.datasets;
          const playerIndex = context.dataIndex;

          const totalActions = chartData.reduce((sum: number, dataset: any) => {
            return sum + dataset.data[playerIndex];
          }, 0);

          const percentage = ((value / totalActions) * 100).toFixed(0);

          return `${percentage}%`;
        }
      },
      legend: {
        labels: {
          boxWidth: 50,
          padding: 25,
          font: {
            size: 13,
            style: 'bold',
            family: "'Montserrat', sans-serif"
          },
          color: '#1A375C'
        }
      },
      labels: {
        font: {
          size: 12,
          family: "'Montserrat', sans-serif"
        },
        color: '#1A375C',
        render: (args: any) => args.value === 0 ? '' : `${args.percentage}%`
      },
      'increase-legend-spacing': {
        beforeInit(chart: any) {
          const originalFit = chart.legend.fit;
          chart.legend.fit = function fit() {
            originalFit.bind(chart.legend)();
            this.height += 35;
          };
        }
      }
    },
    layout: {
      padding: {
        top: 30
      }
    },
    animation: {
      duration: 0
    }
  };

  tg = (def: string, skipLangs: string[] = []) => {
    return this.core.i18nService.tg(def, skipLangs);
  };
  skippedLangs = ['de', 'fr'];

  constructor(
    private readonly core: CoreService,
    private readonly modalCtrl: ModalController,
    private readonly logger: NGXLogger,
    private readonly playersService: PlayersService
  ) {
    this.init();

    this.core.teamOverviewSubConsumerService.homeIndividualSimplifiedStatsModel$.subscribe({
      next: (value) => {
        this._simplifiedPlayerModelsHome = value;
      }
    });
    this.core.teamOverviewSubConsumerService.visitorIndividualSimplifiedStatsModel$.subscribe({
      next: (value) => {
        this._simplifiedPlayerModelsGuest = value;
      }
    });
    this.core.timelineConsumerService.accumulatedCounterList$.subscribe({
      next: (value) => {
        if (value && value.length > 0) {
          let _accumulatedCounterListWithTiming = value;
          let _homeGoalGraph = _accumulatedCounterListWithTiming[0].map(goalTimePoint => ({
            x: goalTimePoint.minuteOfGame,
            y: goalTimePoint.accumulatedCounter
          }));
          let _visitorGoalGraph = _accumulatedCounterListWithTiming[1].map(goalTimePoint => ({
            x: goalTimePoint.minuteOfGame,
            y: goalTimePoint.accumulatedCounter
          }));
          this._goalByGoal = combineAndSortGoalDataHelper(_homeGoalGraph, _visitorGoalGraph);
        }
      }
    });
  }

  async init() {
    // Init static data
    this.cOptions = {
      title: {
        text: await this.tg(_('GOALKEEPERS'), this.skippedLangs),
        display: false,
        fontSize: 20
      },
      plugins: {
        labels: {
          fontSize: 15,
          fontColor: '#FFFFFF',
          render: (args: { value: number; percentage: number; label: string }) => ``//`${args.value} [${args.percentage}%]`
        }
      },
      animation: {
        duration: 0
      },
    };

    this.chartDoughnutOptions = {
      plugins: {
        title: {
          text: await this.tg(_('GOALKEEPERS'), this.skippedLangs),
          display: false,
          font: {
            size: 20
          }
        },
        datalabels: {
          display: false
        },
        labels: {
          font: {
            size: 15
          },
          color: '#FFFFFF',
          render: (args: any) => ''
        }
      },
      animation: {
        duration: 0
      }
    };
  }

  get simplifiedFieldPlayerModelsHome() {
    return this._simplifiedPlayerModelsHome.filter((model) => { return !(model.playerPosition === 'gk') && !model.playerPosition.toLowerCase().includes('coach') });
  }

  get simplifiedGoalkeeperPlayerModelsHome() {
    return this._simplifiedPlayerModelsHome.filter((model) => { return (model.playerPosition === 'gk') });
  }

  get simplifiedFieldPlayerModelsGuest() {
    return this._simplifiedPlayerModelsGuest.filter((model) => { return !(model.playerPosition === 'gk') && !model.playerPosition.toLowerCase().includes('coach') });
  }
  get simplifiedGoalkeeperPlayerModelsGuest() {
    return this._simplifiedPlayerModelsGuest.filter((model) => { return (model.playerPosition === 'gk') });
  }

  @Input()
  set game(game: GameFilterTableModel) {

    this.logger.debug('PdfReportComponent set game called...');
    if (!game) return;
    this.loadGameServer(game);
  }

  @Input()
  set localGame(value: {
    date: string,
    homeId: number,
    visitorId: number,
    homeName: string,
    visitorName: string,
    gameType: GameType,
    endGameStorageModel: GameEndStorageModel
  }) {
    this.logger.debug('PdfReportComponent set localGame called...');
    if (!value) return;
    this.gameType = value.gameType;
    this.globalData.date = value.date;
    this.globalData.home.name = value.homeName;
    this.globalData.visitor.name = value.visitorName;
    const homePlayByPlay = value.endGameStorageModel.updateGameResultDto.playByPlayDto
        .filter(pbp => pbp.teamMarker === 'HOME');
    const visitorPlayByPlay = value.endGameStorageModel.updateGameResultDto.playByPlayDto
        .filter(pbp => pbp.teamMarker === 'VISITOR');
    const homeTeamStats: PdfTeamStats = {
      teamId: value.homeId,
      teamStatsAggregate: Promise.resolve(teamAggregatedDtoHandlerFn(
        value.endGameStorageModel.updateGameResultDto.teamStatsDto.filter(t => t.teamId==value.homeId)[0],
        value.homeId+'',
        value.homeName
      )),
      getPlayerStatisticsAggregatedByTeamAndGame: Promise.resolve(
        value.endGameStorageModel.updateGameResultDto.playerStatsDto
          .filter(t => t.teamId==value.homeId)
          .map(pl => playerAggregatedDtoHandlerFn(pl, value.homeId+'', value.homeName))
      ),
      teamGameSystemModel: Promise.resolve(convertPlayByPlayToGameSystem(homePlayByPlay)),
    };


    const visitorTeamStats: PdfTeamStats = {
      teamId: value.visitorId,
      teamStatsAggregate: Promise.resolve(teamAggregatedDtoHandlerFn(
        value.endGameStorageModel.updateGameResultDto.teamStatsDto.filter(t => t.teamId==value.visitorId)[0],
        value.visitorId+'',
        value.visitorName
      )),
      getPlayerStatisticsAggregatedByTeamAndGame: Promise.resolve(
        value.endGameStorageModel.updateGameResultDto.playerStatsDto
          .filter(t => t.teamId==value.visitorId)
          .map(pl => playerAggregatedDtoHandlerFn(pl, value.visitorId+'', value.visitorName))
      ),
      teamGameSystemModel: Promise.resolve(convertPlayByPlayToGameSystem(visitorPlayByPlay)),
    };

    this.loadGame(homeTeamStats, visitorTeamStats);
  }

  async loadGameServer(game: GameFilterTableModel) {
    this.logger.debug('PdfReportComponent.loadGameServer()', game);
    this.gameType = game.gameType;
    const statsHome: PdfTeamStats = await this.core.dashboardsService.getTeamStats(game.homeId, false, game.id+'', game.gameType);
    const statsVisitor: PdfTeamStats = await this.core.dashboardsService.getTeamStats(game.visitorId, false, game.id+'', game.gameType);
    statsHome.teamGameSystemModel = Promise.resolve(convertPlayByPlayToGameSystem(
        await this.core.dashboardsService.getPlayByPlayForGameSystem(game.homeId.toString(), false, game.id.toString()))
    );
    statsVisitor.teamGameSystemModel = Promise.resolve(convertPlayByPlayToGameSystem(
        await this.core.dashboardsService.getPlayByPlayForGameSystem(game.visitorId.toString(), false, game.id.toString()))
    );
    this.logger.debug('PdfReportComponent.loadGameServer() stats; ', statsHome, statsVisitor);
    this.globalData.date = game.date;
    this.globalData.home.name = game.home;
    this.globalData.visitor.name = game.visitor;
    this.loadGame(statsHome, statsVisitor);
  }

  async loadGame(statsHome: PdfTeamStats, statsVisitor: PdfTeamStats) {
    this.logger.debug('PdfReportComponent.loadGame()', statsHome, statsVisitor);

    await this.fillTeamData(statsHome, 'home', statsVisitor);
    await this.fillTeamData(statsVisitor, 'visitor', statsHome);

    //swap timeline saves - we are showing the saves like we do in timeline graph
    const homeTimelineSaves = this.globalData['home'].timeline.saves;
    const visitorTimelineSaves = this.globalData['visitor'].timeline.saves;
    this.globalData['home'].timeline.saves = visitorTimelineSaves;
    this.globalData['visitor'].timeline.saves = homeTimelineSaves;

    // Init graphs
    this.canvas1Values = [this.globalData.home.overview.goalkeeperSummary.goals, this.globalData.home.overview.goalkeeperSummary.saves];
    this.canvas2Values = [this.globalData.visitor.overview.goalkeeperSummary.goals, this.globalData.visitor.overview.goalkeeperSummary.saves];

    setTimeout(async () => {
      await this.initCanvasDoughnut();
      await this.initCanvas3();
      await this.initCanvas4();
    }, 200);

    // Wait a bit to render all
    setTimeout(() => {
      this.createPDFReport().then(pdf => {
        this.modalCtrl.dismiss();

        pdf.getBase64(b64 => {
          this.modalCtrl.create({
            component: PdfViewerPage,
            componentProps: { data: b64, fileName: this.globalData?.home?.name + ' vs ' + this.globalData?.visitor?.name },
            cssClass: 'modal-pagescreen',
            showBackdrop: false
          }).then(t=>t.present());
        });
      });
    }, 1000);
  }


  async fillTeamData(data: PdfTeamStats, team:'home'|'visitor'='home', opponent: PdfTeamStats) {

    // Global team data
    const teamData = this.core.teamService.getTeamByExternalId(data.teamId);
    this.globalData[team].logo = teamData.shieldUrl;

    // GOALKEEPERS SHOOTING

    let gkPlayers = (await data.getPlayerStatisticsAggregatedByTeamAndGame)
      .filter(pl => pl.playerPosition=='gk');

    if (gkPlayers.length > 2) {
      gkPlayers = gkPlayers.sort((pl1, pl2) => parseInt(pl2.totalTime)-parseInt(pl1.totalTime)).slice(0, 2);
    }

    this.globalData[team].goalkeepersShooting = gkPlayers.map(plDto => ({
      name: plDto.playerName,
      backNumber: plDto.playerBackNumber,
      counters: transformGoalConnectionCounter(plDto),
    }));

    // OVERVIEW TABLE
    // Offense positional
    this.globalData[team].overview.overviewTable[0].goals = +(await data.teamStatsAggregate).totalGoalsOffensePositional;
    this.globalData[team].overview.overviewTable[0].failedShots = +(await data.teamStatsAggregate).totalFailedShotsOffensePositional;
    this.globalData[team].overview.overviewTable[0].saves = +(await data.teamStatsAggregate).totalGkSaveOffensePositional;
    this.globalData[team].overview.overviewTable[0].postOut = +(await data.teamStatsAggregate).totalPostOutOffensePositional;
    this.globalData[team].overview.overviewTable[0].lostBalls = +(await data.teamStatsAggregate).totalLostBallsOffensePositional;
    this.globalData[team].overview.overviewTable[0].posessions = +(await data.teamStatsAggregate).totalPossOffensePositional;
    const offensePositionalEfficiency = +(await data.teamStatsAggregate).totalEffectivePossOffensePositional !== 0 ? +((+(await data.teamStatsAggregate).totalGoalsOffensePositional / +(await data.teamStatsAggregate).totalEffectivePossOffensePositional) * 100).toFixed(0):0;
    this.globalData[team].overview.overviewTable[0].efficiency = offensePositionalEfficiency;
    // TODO: Wait until post/outs and failed shots
    this.globalData[team].overview.overviewTable[0].shootingEfficiency = +this.calculateShootingEfficiency(
        +(await data.teamStatsAggregate).totalGoalsOffensePositional,
        +(await data.teamStatsAggregate).totalFailedShotsOffensePositional,
        +(await data.teamStatsAggregate).totalPostOutOffensePositional
    );
    const offensePositionalLostBallsEfficiency = +(await data.teamStatsAggregate).totalEffectivePossOffensePositional ? +(+(await data.teamStatsAggregate).totalLostBallsOffensePositional / +(await data.teamStatsAggregate).totalEffectivePossOffensePositional * 100).toFixed(0):0;
    this.globalData[team].overview.overviewTable[0].lostBallsEfficiency = offensePositionalLostBallsEfficiency;

    // Counter goal
    this.globalData[team].overview.overviewTable[1].goals = +(await data.teamStatsAggregate).totalGoalsCounterGoals;
    this.globalData[team].overview.overviewTable[1].failedShots = +(await data.teamStatsAggregate).totalFailedShotsCounterGoal;
    this.globalData[team].overview.overviewTable[1].saves = +(await data.teamStatsAggregate).totalGkSaveCounterGoal;
    this.globalData[team].overview.overviewTable[1].postOut = +(await data.teamStatsAggregate).totalPostOutCounterGoal;
    this.globalData[team].overview.overviewTable[1].lostBalls = +(await data.teamStatsAggregate).totalLostBallsCounterGoals;
    this.globalData[team].overview.overviewTable[1].posessions = +(await data.teamStatsAggregate).totalPossCounterGoal;
    const counterGoalEfficiency = +(await data.teamStatsAggregate).totalEffectivePossCounterGoal !== 0 ? +((+(await data.teamStatsAggregate).totalGoalsCounterGoals / +(await data.teamStatsAggregate).totalEffectivePossCounterGoal) * 100).toFixed(0):0;
    this.globalData[team].overview.overviewTable[1].efficiency = counterGoalEfficiency;
    // TODO: Wait until post/outs and failed shots
    this.globalData[team].overview.overviewTable[1].shootingEfficiency = +this.calculateShootingEfficiency(
        +(await data.teamStatsAggregate).totalGoalsCounterGoals,
        +(await data.teamStatsAggregate).totalFailedShotsCounterGoal,
        +(await data.teamStatsAggregate).totalPostOutCounterGoal
    );
    const counterGoalLostBallsEfficiency = +(await data.teamStatsAggregate).totalEffectivePossCounterGoal ? +(+(await data.teamStatsAggregate).totalLostBallsCounterGoals / +(await data.teamStatsAggregate).totalEffectivePossCounterGoal * 100).toFixed(0):0;
    this.globalData[team].overview.overviewTable[1].lostBallsEfficiency = counterGoalLostBallsEfficiency;

    // Fast break
    this.globalData[team].overview.overviewTable[2].goals = +(await data.teamStatsAggregate).totalGoalsFastBreak;
    this.globalData[team].overview.overviewTable[2].failedShots = +(await data.teamStatsAggregate).totalFailedShotsFastBreak;
    this.globalData[team].overview.overviewTable[2].saves = +(await data.teamStatsAggregate).totalGkSaveFastBreak;
    this.globalData[team].overview.overviewTable[2].postOut = +(await data.teamStatsAggregate).totalPostOutFastBreak;
    this.globalData[team].overview.overviewTable[2].lostBalls = +(await data.teamStatsAggregate).totalLostBallsFastBreak;
    this.globalData[team].overview.overviewTable[2].posessions = +(await data.teamStatsAggregate).totalPossFastBreak;
    const fastBreakEfficiency = +(await data.teamStatsAggregate).totalEffectivePossFastBreak !== 0 ? +((+(await data.teamStatsAggregate).totalGoalsFastBreak / +(await data.teamStatsAggregate).totalEffectivePossFastBreak) * 100).toFixed(0):0;
    this.globalData[team].overview.overviewTable[2].efficiency = fastBreakEfficiency;
    // TODO: Wait until post/outs and failed shots
    this.globalData[team].overview.overviewTable[2].shootingEfficiency = +this.calculateShootingEfficiency(
        +(await data.teamStatsAggregate).totalGoalsFastBreak,
        +(await data.teamStatsAggregate).totalFailedShotsFastBreak,
        +(await data.teamStatsAggregate).totalPostOutFastBreak
    );
    const fastBreakLostBallsEfficiency = +(await data.teamStatsAggregate).totalEffectivePossFastBreak ? +(+(await data.teamStatsAggregate).totalLostBallsFastBreak / +(await data.teamStatsAggregate).totalEffectivePossFastBreak * 100).toFixed(0):0;
    this.globalData[team].overview.overviewTable[2].lostBallsEfficiency = fastBreakLostBallsEfficiency;

    //Set posses with double counting
    this.globalData[team].overview.summaryPossessions = calculateSummaryPossessions(
        +((await data.teamStatsAggregate).totalPossessions),
        +((await data.teamStatsAggregate).totalPossOffensePositional),
        +((await data.teamStatsAggregate).totalPossFastBreak),
        +((await data.teamStatsAggregate).totalPossCounterGoal),
    );
    // OVERVIEW EFF PER PHASE TABLE
    // Offense positional
    this.globalData[team].overview.efficiencySummaryTable[0].equalityEfficiencySummary = +(+(await data.teamStatsAggregate).avgEffOffensePositionalEquality).toFixed(0);
    this.globalData[team].overview.efficiencySummaryTable[0].superiorityEfficiencySummary = +(+(await data.teamStatsAggregate).avgEffOffensePositionalSuperiority).toFixed(0);
    this.globalData[team].overview.efficiencySummaryTable[0].inferiorityEfficiencySummary = +(+(await data.teamStatsAggregate).avgEffOffensePositionalInferiority).toFixed(0);

    // Counter goal
    this.globalData[team].overview.efficiencySummaryTable[1].equalityEfficiencySummary = +(+(await data.teamStatsAggregate).avgEffCounterGoalEquality).toFixed(0);
    this.globalData[team].overview.efficiencySummaryTable[1].superiorityEfficiencySummary = +(+(await data.teamStatsAggregate).avgEffCounterGoalSuperiority).toFixed(0);
    this.globalData[team].overview.efficiencySummaryTable[1].inferiorityEfficiencySummary = +(+(await data.teamStatsAggregate).avgEffCounterGoalInferiority).toFixed(0);

    // Fast break
    this.globalData[team].overview.efficiencySummaryTable[2].equalityEfficiencySummary = +(+(await data.teamStatsAggregate).avgEffFastBreakEquality).toFixed(0);
    this.globalData[team].overview.efficiencySummaryTable[2].superiorityEfficiencySummary = +(+(await data.teamStatsAggregate).avgEffFastBreakSuperiority).toFixed(0);
    this.globalData[team].overview.efficiencySummaryTable[2].inferiorityEfficiencySummary = +(+(await data.teamStatsAggregate).avgEffFastBreakInferiority).toFixed(0);

    //eff detail table
    this.globalData[team].overview.efficiencyDetailTable[0].equalityEff = +(+(await data.teamStatsAggregate).avgSixZeroEqualityEfficiency).toFixed(0);
    this.globalData[team].overview.efficiencyDetailTable[0].superiorityEff = +(+(await data.teamStatsAggregate).avgSixZeroSuperiorityEfficiency).toFixed(0);
    this.globalData[team].overview.efficiencyDetailTable[0].inferiorityEff = +(+(await data.teamStatsAggregate).avgSixZeroInferiorityEfficiency).toFixed(0);

    this.globalData[team].overview.efficiencyDetailTable[1].equalityEff = +(+(await data.teamStatsAggregate).avgFiveOneEqualityEfficiency).toFixed(0);
    this.globalData[team].overview.efficiencyDetailTable[1].superiorityEff = +(+(await data.teamStatsAggregate).avgFiveOneSuperiorityEfficiency).toFixed(0);
    this.globalData[team].overview.efficiencyDetailTable[1].inferiorityEff = +(+(await data.teamStatsAggregate).avgFiveOneInferiorityEfficiency).toFixed(0);

    this.globalData[team].overview.efficiencyDetailTable[2].equalityEff = +(+(await data.teamStatsAggregate).avgThreeTwoOneEqualityEfficiency).toFixed(0);
    this.globalData[team].overview.efficiencyDetailTable[2].superiorityEff = +(+(await data.teamStatsAggregate).avgThreeTwoOneSuperiorityEfficiency).toFixed(0);
    this.globalData[team].overview.efficiencyDetailTable[2].inferiorityEff = +(+(await data.teamStatsAggregate).avgThreeTwoOneInferiorityEfficiency).toFixed(0);

    this.globalData[team].overview.efficiencyDetailTable[3].equalityEff = +(+(await data.teamStatsAggregate).avgFourTwoEqualityEfficiency).toFixed(0);
    this.globalData[team].overview.efficiencyDetailTable[3].superiorityEff = +(+(await data.teamStatsAggregate).avgFourTwoSuperiorityEfficiency).toFixed(0);
    this.globalData[team].overview.efficiencyDetailTable[3].inferiorityEff = +(+(await data.teamStatsAggregate).avgFourTwoInferiorityEfficiency).toFixed(0);

    this.globalData[team].overview.efficiencyDetailTable[4].equalityEff = +(+(await data.teamStatsAggregate).avgOtherEqualityEfficiency).toFixed(0);
    this.globalData[team].overview.efficiencyDetailTable[4].superiorityEff = +(+(await data.teamStatsAggregate).avgOtherSuperiorityEfficiency).toFixed(0);
    this.globalData[team].overview.efficiencyDetailTable[4].inferiorityEff = +(+(await data.teamStatsAggregate).avgOtherInferiorityEfficiency).toFixed(0);

    //QUICKTABLE
    const totalLostBallsEfficiency = +(await data.teamStatsAggregate).totalPossessions ? +(+(await data.teamStatsAggregate).totalLostBalls / +(await data.teamStatsAggregate).totalPossessions  * 100 ).toFixed(0):0;
    this.globalData[team].overview.quickTable = {
      left: [
        {key: await this.tg(_('OFFENSE POSITIONAL %'), this.skippedLangs), value: offensePositionalEfficiency.toFixed(0) +' %'},
        {key: await this.tg(_('COUNTER GOAL %'), this.skippedLangs), value: counterGoalEfficiency.toFixed(0) +' %'},
        {key: await this.tg(_('FAST BREAK %'), this.skippedLangs), value: fastBreakEfficiency.toFixed(0) +' %'},
        {key: '', value: null},
        {key: await this.tg(_('LOST BALLS'), this.skippedLangs), value: +(await data.teamStatsAggregate).totalLostBalls},
        {key: await this.tg(_('LOST BALLS %'), this.skippedLangs), value: totalLostBallsEfficiency.toFixed(0) +' %'},
        {key: '', value: null},
        {key: await this.tg(_('7M GOAL'), this.skippedLangs), value: +(await data.teamStatsAggregate).totalGoals7Meters},
        // {key:await this.tg(_( '7M'), this.skippedLangs), value: +(await data.teamStatsAggregate).totalGoals7},
      ],
      right: [
        {key: await this.tg(_('GOALS'), this.skippedLangs), value: +(await data.teamStatsAggregate).totalGoals},
        {key: await this.tg(_('SAVES'), this.skippedLangs), value: +(await data.teamStatsAggregate).totalSaves},
        {key: await this.tg(_('TEAM POSSESIONS'), this.skippedLangs), value: calculateSummaryPossessions(
              +((await data.teamStatsAggregate).totalPossessions),
              +((await data.teamStatsAggregate).totalEffectivePossOffensePositional),
              +((await data.teamStatsAggregate).totalEffectivePossFastBreak),
              +((await data.teamStatsAggregate).totalEffectivePossCounterGoal),
          )},
        {key: '', value: null},
        {key: await this.tg(_('SUPERIORITY'), this.skippedLangs), value: (+(await data.teamStatsAggregate).avgSuperiorityEfficiency).toFixed(0) +' %'},
        //TODO: Fetch from server
        {key: await this.tg(_('EQUALITY'), this.skippedLangs), value: (+(await data.teamStatsAggregate).avgEqualityEfficiency).toFixed(0) +' %'},
        // {key:await this.tg(_( '7vs6'), this.skippedLangs), value: +(await data.teamStatsAggregate).avgEff7vs6},
        {key: '', value: null},
        {key: await this.tg(_('EFF. DEFFENSE %'), this.skippedLangs), value: (+(await data.teamStatsAggregate).avgEffDefense).toFixed(0) +' %'},
        {key: await this.tg(_('EFF. OFFENSE %'), this.skippedLangs), value: (+(await data.teamStatsAggregate).avgEfficiency).toFixed(0) +' %'},
      ]
    };

     //GOALKEEPER SUMMARY
     this.globalData[team].overview.goalkeeperSummary.goals = +(await opponent.teamStatsAggregate).totalGoals;
     this.globalData[team].overview.goalkeeperSummary.saves = +(await data.teamStatsAggregate).totalSaves;
     //GOALKEEPER DETAIL
     let sumGoalkeepersGoals = 0;
     this.globalData[team].overview.goalkeepersDetailTable = [
       ...(await data.getPlayerStatisticsAggregatedByTeamAndGame).filter(p => p.playerPosition=='gk').map(p => {
         sumGoalkeepersGoals += +p.totalReceivedGoals;
         return {
           imageUrl: teamData.players.filter(pl => pl.id == +p.playerId)[0]?.imageUrl || '/assets/avatar.svg',
           backNumber: p.playerBackNumber,
           name: p.playerName,
           rol: p.playerPosition,
           goals: +p.totalReceivedGoals,
           saves: +p.totalSaves,
         } as GoalkeepersDetailTableRow;
       }),
       {
         imageUrl: '/assets/svg/football-team-bench.svg',
         backNumber: '',
         name: await this.tg(_('Without GK'), this.skippedLangs),
         goals: +(await opponent.teamStatsAggregate).totalGoals-sumGoalkeepersGoals,
         saves: 0,
       } as GoalkeepersDetailTableRow
     ];



    //SHOOTING LOCATION
    this.globalData[team].overview.shootingLocation = await Promise.resolve(Promise.all(
      ['LeftWing','Left9m','Left6m','Center6m','Center9m','RightWing','Right9m','Right6m','7Meters','OppositeField'].map(async pos => {
        return {
          label: (await data.teamStatsAggregate)['totalGoals'+pos],
          position: pos,
          weight: +(await data.teamStatsAggregate)['totalGoals'+pos],
        } as Goals
      })
    ));

    // Goals & connections
    const possitions = ['Gk', 'Lw', 'Rw', 'Lb', 'Rb', 'Cb', 'Lp'];
    const connections: Connection[] = [];
    for (let pos_a of possitions) for (let pos_b of possitions) if (pos_a!=pos_b) {
      const value = +(await data.teamStatsAggregate)['totalAssist'+pos_a+pos_b];
      if (value) connections.push({startPos: pos_a.toLowerCase(), endPos: pos_b.toLowerCase(), weight: value});
    }
    this.globalData[team].overview.connectionGoals.connections = connections;
    // TODO: Fetch from server or agregate from players
    const goals: Goals[] = [];
    const gObj = (await data.getPlayerStatisticsAggregatedByTeamAndGame).reduce((base, p) => {
      base[p.playerPosition] += +p.totalGoals;
      return base;
    }, {
      'gk':0, 'lw':0, 'rw':0, 'lb':0, 'rb':0, 'cb':0, 'lp':0,
    });
    for (const pos in gObj) goals.push({label: ''+gObj[pos], weight: gObj[pos], position: pos});
    this.globalData[team].overview.connectionGoals.goals = goals;


    //INDIVIDUAL PERFORMANCE
    const playerMapping = (player: PlayerStatsAggregateDto): IndividualPerformanceTableRow => {
      const min = Math.floor(+player.totalTime / 60);
      return {
        // TODO: Unused property at pdf??
        rol: player.playerPosition,
        imageUrl: teamData.players.filter(pl => pl.id == +player.playerId)[0]?.imageUrl || '/assets/avatar.svg',
        backNumber: player.playerBackNumber,
        name: player.playerName,
        time: ('0'+min).substr(-2) + ':' + ('0'+(+player.totalTime - (min*60))).substr(-2),
        goals: +player.totalGoals,
        possesionsPlayed: +player.totalPossessions,
        failedShots: (+player.totalFailedShotsOffensePositional)+(+player.totalFailedShotsFastBreak)+(+player.totalFailedShotsCounterGoal),
        saves: +player.totalSaves,
        outs: +player.totalPostOut,
        losts: +player.totalLostBalls,
        performance: (+player.avgPlayerScore).toFixed(1),
        // Counters Table
        attackFaultProvoke: +player.totalAttackFaultProvoke,
        technicalMistakeProvoke: +player.totalTechnicalMistakeProvoke,
        lostBallProvoke: +player.totalLostBallProvoke,
        twoMinCommit: +player.totalTwoMinCommit,
        sevenMetersCommit: +player.totalSevenMetersCommit,
        sevenMSuspCommit: +player.totalSevenMSuspCommit,
        oneAndOneLost: +player.totalOneAndOneLost,
        foulCommit: +player.totalFoulCommit,
        shotBlock: +player.totalShotBlock,
        attackFaultCommit: +player.totalAttackFaultCommit,
        technicalMistakeCommit: +player.totalTechnicalMistakeCommit,
        lostBall: +player.totalLostBall,
        twoMinProvoke: +player.totalTwoMinProvoke,
        sevenMetersProvoke: +player.totalSevenMetersProvoke,
        sevenMSuspProvoke: +player.totalSevenMSuspProvoke,
        oneAndOneWon: +player.totalOneAndOneWon,
        foulReceive: +player.totalFoulReceive,
        shotBlocked: +player.totalShotBlocked,
        playerId: player.playerId
      };
    };
    this.globalData[team].individualPerformanceTable = [
      ...(await data.getPlayerStatisticsAggregatedByTeamAndGame).filter(p => p.playerPosition=='gk' && !p.playerPosition.toLowerCase().includes('coach')).sort((a,b) => {
        return +(b.totalTime)-(+a.totalTime);
      }).map(playerMapping),
      ...(await data.getPlayerStatisticsAggregatedByTeamAndGame).filter(p => p.playerPosition!='gk' && !p.playerPosition.toLowerCase().includes('coach')).sort((a,b) => {
        return +(b.totalTime)-(+a.totalTime);
      }).map(playerMapping)
    ];

    // TIMELINE TABLES
    const minKeys = ['Min5','Min10','Min15','Min20','Min25','Min30','Min35','Min40','Min45','Min50','Min55','Min60'];
    this.globalData[team].timeline.goals = await Promise.resolve(Promise.all(minKeys.map(async mk => +(await data.teamStatsAggregate)['totalGoal'+mk])));
    this.globalData[team].timeline.possesions = await Promise.resolve(Promise.all(minKeys.map(async mk => +(await data.teamStatsAggregate)['totalPoss'+mk])));
    this.globalData[team].timeline.lostBalls = await Promise.resolve(Promise.all(minKeys.map(async mk => +(await data.teamStatsAggregate)['totalLostBall'+mk])));
    this.globalData[team].timeline.saves = await Promise.resolve(Promise.all(minKeys.map(async mk => +(await data.teamStatsAggregate)['totalSaves'+mk])));

    let hasAnyEventsInETorPenaltiesHome = false;
    let goalsInET1H = 0;
    let goalsInET2H = 0;
    let goalsInPH = 0;
    let possesionsInET1H = 0;
    let possesionsInET2H = 0;
    let possesionsInPH = 0;
    let lostBallsInET1H = 0;
    let lostBallsInET2H = 0;
    let lostBallsInPH = 0;
    let savesInET1H = 0;
    let savesInET2H = 0;
    let savesInPH = 0;
    this._simplifiedPlayerModelsHome.forEach(stats => {
      for (const [key, value] of Object.entries(stats.extraTime1)) {
        if (value > 0) {
          hasAnyEventsInETorPenaltiesHome = true;
          if (key === 'goal') {
            goalsInET1H += value;
          }
          if (key === 'lostBalls') {
            lostBallsInET1H += value;
          }
          if (key === 'save') {
            savesInET1H += value;
          }
        }
      }
      for (const [key, value] of Object.entries(stats.extraTime2)) {
        if (value > 0) {
          hasAnyEventsInETorPenaltiesHome = true;
          if (key === 'goal') {
            goalsInET2H += value;
          }
          if (key === 'lostBalls') {
            lostBallsInET2H += value;
          }
          if (key === 'save') {
            savesInET2H += value;
          }
        }
      }
      for (const [key, value] of Object.entries(stats.penalties)) {
        if (value > 0) {
          hasAnyEventsInETorPenaltiesHome = true;
          if (key === 'goal') {
            goalsInPH += value;
          }
          if (key === 'lostBalls') {
            lostBallsInPH += value;
          }
          if (key === 'save') {
            savesInPH += value;
          }
        }
      }
    });
    if (hasAnyEventsInETorPenaltiesHome && this.globalData.home.timeline.goals.length <= 12) {
      this._extraTimeOrPenaltiesPlayed = true;
      this.globalData.home.timeline.goals = [...this.globalData.home.timeline.goals, goalsInET1H, goalsInET2H, goalsInPH];
      this.globalData.home.timeline.possesions = [...this.globalData.home.timeline.possesions, possesionsInET1H, possesionsInET2H, possesionsInPH];
      this.globalData.home.timeline.saves = [...this.globalData.home.timeline.saves, savesInET1H, savesInET2H, savesInPH];
      this.globalData.home.timeline.lostBalls = [...this.globalData.home.timeline.lostBalls, lostBallsInET1H, lostBallsInET2H, lostBallsInPH];
    }

    let hasAnyEventsInETorPenaltiesVisitor = false;
    let goalsInET1V = 0;
    let goalsInET2V = 0;
    let goalsInPV = 0;
    let possesionsInET1V = 0;
    let possesionsInET2V = 0;
    let possesionsInPV = 0;
    let lostBallsInET1V = 0;
    let lostBallsInET2V = 0;
    let lostBallsInPV = 0;
    let savesInET1V = 0;
    let savesInET2V = 0;
    let savesInPV = 0;
    this._simplifiedPlayerModelsGuest.forEach(stats => {
      for (const [key, value] of Object.entries(stats.extraTime1)) {
        if (value > 0) {
          hasAnyEventsInETorPenaltiesVisitor = true;
          if (key === 'goal') {
            goalsInET1V += value;
          }
          if (key === 'lostBalls') {
            lostBallsInPV += value;
          }
          if (key === 'save') {
            savesInPV += value;
          }
        }
      }
      for (const [key, value] of Object.entries(stats.extraTime2)) {
        if (value > 0) {
          hasAnyEventsInETorPenaltiesVisitor = true;
          if (key === 'goal') {
            goalsInET2V += value;
          }
          if (key === 'lostBalls') {
            lostBallsInPV += value;
          }
          if (key === 'save') {
            savesInPV += value;
          }
        }
      }
      for (const [key, value] of Object.entries(stats.penalties)) {
        if (value > 0) {
          hasAnyEventsInETorPenaltiesVisitor = true;
          if (key === 'goal') {
            goalsInPV += value;
          }
          if (key === 'lostBalls') {
            lostBallsInPV += value;
          }
          if (key === 'save') {
            savesInPV += value;
          }
        }
      }
    });
    if (hasAnyEventsInETorPenaltiesVisitor && this.globalData.visitor.timeline.goals.length <= 12) {
      this._extraTimeOrPenaltiesPlayed = true;
      this.globalData.visitor.timeline.goals = [...this.globalData.visitor.timeline.goals, goalsInET1V, goalsInET2V, goalsInPV];
      this.globalData.visitor.timeline.possesions = [...this.globalData.visitor.timeline.possesions, possesionsInET1V, possesionsInET2V, possesionsInPV];
      this.globalData.visitor.timeline.saves = [...this.globalData.visitor.timeline.saves, savesInET1V, savesInET2V, savesInPV];
      this.globalData.visitor.timeline.lostBalls = [...this.globalData.visitor.timeline.lostBalls, lostBallsInET1V, lostBallsInET2V, lostBallsInPV];
    }
    // Game system table

    this.globalData[team].gameSystemTable = [...await data.teamGameSystemModel];
  }

  private calculateShootingEfficiency(
      goals: number,
      failedShots: number,
      postOuts: number
  ): string {
    const res = goals + failedShots + postOuts === 0 ? 0 : 100 * (goals / (goals + failedShots + postOuts));
    return res.toFixed(0);
  }

  private async initCanvasDoughnut() {
    if (!this.canvas1) return;
    const ctx = this.canvas1.nativeElement.getContext('2d');

    if (this.doughnut1Chart) {
      this.doughnut1Chart.destroy();
    }

    this.doughnut1Chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['GOALS', 'SAVES'],
        datasets: [{
          data: this.canvas1Values,
          backgroundColor: this.circleColors[0].backgroundColor,
          borderColor: this.circleColors[0].borderColor,
          borderWidth: 1
        }]
      },
      options: this.chartDoughnutOptions
    });


    if (!this.canvas2) return;
    const ctx2 = this.canvas2.nativeElement.getContext('2d');

    if (this.doughnut2Chart) {
      this.doughnut2Chart.destroy();
    }

    this.doughnut2Chart = new Chart(ctx2, {
      type: 'doughnut',
      data: {
        labels: ['GOALS', 'SAVES'],
        datasets: [{
          data: this.canvas2Values,
          backgroundColor: this.circleColors[0].backgroundColor,
          borderColor: this.circleColors[0].borderColor,
          borderWidth: 1
        }]
      },
      options: this.chartDoughnutOptions
    });
  }

  private async initCanvas3 () {
    let goals = [], saves = [], failedShots = [], outs = [], losts = [], players : any = [];
    let originalData = this.globalData.home.individualPerformanceTable;
    for (let i = 0; i < originalData.length; i++) {
      players.push(originalData[i]['backNumber'] + '-' + originalData[i]['name']);
      goals.push(originalData[i]['goals']);
      failedShots.push(originalData[i]['failedShots']);
      saves.push(originalData[i]['saves']);
      outs.push(originalData[i]['outs']);
      losts.push(originalData[i]['losts']);
    }

    this.canvas3Values = [
      { data: goals, label: toUpperCase(await this.tg(_('GOALS'), this.skippedLangs)), stack: 'a'},
      { data: failedShots, label: toUpperCase(await this.tg(_('FAILED SHOTS'), this.skippedLangs)), stack: 'a' },
      { data: saves, label: toUpperCase(await this.tg(_('SAVES'), this.skippedLangs)), stack: 'a' },
      { data: outs, label: toUpperCase(await this.tg(_('OUTS'), this.skippedLangs)), stack: 'a' },
      { data: losts, label: toUpperCase(await this.tg(_('LOST BALLS'), this.skippedLangs)), stack: 'a' }
    ];

    this.canvas3Labels = players;

    setTimeout(() => {

      if (!this.canvas3) return;
      const ctx = this.canvas3.nativeElement.getContext('2d');

      if (this.bar3Chart) {
        this.bar3Chart.destroy();
      }

      this.bar3Chart = new Chart(ctx, {
        type: 'bar',
        plugins: this.pluginsBar3,
        data: {
          labels: this.canvas3Labels,
          datasets: this.canvas3Values?.length ? this.canvas3Values.map((dataset, index) => ({
            label: dataset.label,
            data: dataset.data,
            backgroundColor: this.canvas3BarColors[index].backgroundColor,
            borderWidth: 1,
            barPercentage: 0.8,
            categoryPercentage: 0.9,
            borderRadius: 0,
          })) : []
        },
        options: this.chartBarOptions
      });
    }, 200);
  }

  private async initCanvas4 () {
    let goals = [], saves = [], failedShots = [], outs = [], losts = [], players : any = [];
    let originalData = this.globalData.visitor.individualPerformanceTable;
    for (let i = 0; i < originalData.length; i++) {
      players.push(originalData[i]['backNumber'] + '-' + originalData[i]['name']);
      goals.push(originalData[i]['goals']);
      failedShots.push(originalData[i]['failedShots']);
      saves.push(originalData[i]['saves']);
      outs.push(originalData[i]['outs']);
      losts.push(originalData[i]['losts']);
    }
    this.canvas4Values = [
      { data: goals, label: toUpperCase(await this.tg(_('GOALS'), this.skippedLangs)), stack: 'a' },
      { data: failedShots, label: toUpperCase(await this.tg(_('FAILED SHOTS'), this.skippedLangs)), stack: 'a' },
      { data: saves, label: toUpperCase(await this.tg(_('SAVES'), this.skippedLangs)), stack: 'a' },
      { data: outs, label: toUpperCase(await this.tg(_('OUTS'), this.skippedLangs)), stack: 'a' },
      { data: losts, label: toUpperCase(await this.tg(_('LOST BALLS'), this.skippedLangs)), stack: 'a' }
    ];
    this.canvas4Labels = players;

    setTimeout(() => {
      if (!this.canvas4) return;
      const ctx = this.canvas4.nativeElement.getContext('2d');

      if (this.bar4Chart) {
        this.bar4Chart.destroy();
      }

      this.bar4Chart = new Chart(ctx, {
        type: 'bar',
        plugins: this.pluginsBar3,
        data: {
          labels: this.canvas4Labels,
          datasets: this.canvas4Values?.length ? this.canvas4Values.map((dataset, index) => ({
            label: dataset.label,
            data: dataset.data,
            backgroundColor: this.canvas3BarColors[index].backgroundColor,
            borderWidth: 1,
            barPercentage: 0.8,
            categoryPercentage: 0.9,
            borderRadius: 0,
          })) : []
        },
        options: this.chartBarOptions
      });
    }, 200);
  }

  /**
   *
   */
  public async createPDFReport() {
    console.log('createPDFReport WORKS');


    this.pdf = new PdfMakeWrapper();
    this.pdf.pageSize('A4');

    this.pdf.background(function (i: any, node: any) {
      if (i === 1) {
        return {
          canvas: [
            {
              type: 'rect',
              x: 0, y: 0, w: 841.89, h: 595.28,
              color: pdfColors.bgRectTableWhite
            }
          ]
        }
      }

      return {
        canvas: [
          {
            type: 'rect',
            x: 0, y: 0, w: 841.89, h: 595.28,
            color: pdfColors.bgBaseLightGrey
          }
        ]
      }
    }),

    this.pdf.pageOrientation('landscape');
    this.pdf.pageMargins([ 10, 10, 10, 10 ]);

    this.pdf.info({
      title: await this.tg(_('PDF Report'), this.skippedLangs),
      author: 'Handball.AI',
      subject: await this.tg(_('Game pdf report'), this.skippedLangs),
    });

    this.pdf.styles({
      firstPageTeamCell: {
        fontSize: 20,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      firstPageGlobalDate: {
        fontSize: 17,
        color: '#1A375C',
        alignment: 'center',
        bold: true,
      },
      headerTeamCell: {
        fontSize: 10,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      headerTeamCellSmall: {
        fontSize: 8
      },
      table__header__cell: {
        fontSize: 10,
        color: '#1A375C',
        alignment: 'center',
        bold: true,
      },
      table__body__cell: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      header__cell_b1: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      header__cell_b2: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      header__cell_b3: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      header__cell_b4: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      body__cell_b4: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      body__cell_b4_total: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      header__cell_b5: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      body__cell_b5: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      header__cell_b6: {
        fontSize: 6,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      player_cell_b6: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      header__cell_b7: {
        fontSize: 6,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      header__cell_b8: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },

      header__cell_b9: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      body__cell_b9: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      header__cell_b10: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      body__cell_b10: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      header__cell_a: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },
      goalkeepers_labels_save_goal_chart: {
        fontSize: 11,
        color: '#1A375C',
        alignment: 'center',
        bold: true,
      }
    });

    // prepare logo headers for teams
    this.teamHeaderLogoHome = await this.teamLogoResolver(this.globalData.home.logo, 'right', 50);
    this.teamHeaderLogoVisitor = await this.teamLogoResolver(this.globalData.visitor.logo, 'left', 50);

    // First page
    // Hanball Field
    this.pdf.add(
      new SVG(`${handballField}`).alignment('right').width(300).end
    );

    // if svg logo will throw error use png instead
    // this.pdf.add(await new Img('/assets/images/pdfReportImgLogoHandball.png').width(250).alignment('center').build());
    this.pdf.add(
      // await new Img('/assets/images/pdfReportImgLogoHandball.png').width(250).alignment('center').build()
      new SVG(`${svgTextLogo}`).width('270')
        .alignment('center')
        .absolutePosition(0, 250)
        .end
    );
    this.addEmptyLines(5);

    this.pdf.add(
      new Table([
        [
          // logo square team 1
          new Cell(
            await this.teamLogoResolver(this.globalData.home.logo, 'right', 100)
          ).end,

          new Cell(new Txt(`Team\n${this.globalData.home.name}`).style('firstPageTeamCell')
          .relativePosition(20, 25).end).end,

          new Cell(
            new Txt(`VS\n`).style(['firstPageTeamCell']).alignment('center').relativePosition(0, 35).width(5).end,
          ).end,

          new Cell(new Txt(`Team\n${this.globalData.visitor.name}`).style('firstPageTeamCell')
          .alignment('right')
          .relativePosition(-20, 25)
          .end).end,

          // logo square team 2
          new Cell(
            await this.teamLogoResolver(this.globalData.visitor.logo, 'left', 100)
            ).end
        ]
      ])
      .layout('noBorders').widths(['15%', '30%', '10%', '30%', '15%']).end
    );

    this.addEmptyLines(3);

    this.pdf.add(new Txt(`${globalDataDate(this.globalData.date)}`).alignment('center').style('firstPageGlobalDate').end)
    this.addPageBreak();

    // page 2
    await this.addHeaderPage(`${toUpperCase(await this.tg(_('OVERVIEW'), this.skippedLangs))} ${this.globalData.home.name}`);

    this.pdf.add(
      this.createCustomTable({
        rectType: 'header',
        rectWidth: 822,
        rectColor: pdfColors.bgRectTableLightBlue,
        firstRectHeight: 28,
        secondRectHeight: 18
      })
    );

    await this.addOverviewTableHeadersOnly(
      this.globalData.home,
      this.homeTeamBgColor,
      this.homeTeamFontColor,
      this.homeTeamOddRowsColor,
      'A'
    );

    this.pdf.add(
      this.createCustomTable({
        rectType: 'body',
        rectWidth: 822,
        rectColor: pdfColors.bgRectTableWhite,
        firstRectHeight: 45,
      })
    );

    this.pdf.add(
      this.createCustomTable({
        rectType: 'footer',
        rectWidth: 822,
        rectColor: pdfColors.bgRectTableBlue,
        firstRectHeight: 20,
        secondRectHeight: 10
      })
    );

    await this.addOverviewTable(
        this.globalData.home,
        this.homeTeamBgColor,
        this.homeTeamFontColor,
        this.homeTeamOddRowsColor,
    );
    // end of Overview table

    // second block
    await this.addThreeColumnOverviewTable(this.globalData.home, this.canvas1, this.homeTeamBgColor, this.homeTeamFontColor, this.homeTeamOddRowsColor);

    // third block
    await this.addOverviewQuickTableAndShooting(this.globalData.home, this.canvas1, this.homeTeamBgColor, this.homeTeamFontColor, this.homeTeamOddRowsColor);

    this.addPageBreak();
    // end of page 2


    // // 3rd page
    // //GK HOME
    await this.addHeaderPage(`${toUpperCase(await this.tg(_('GK SHOOTING'), this.skippedLangs))} ${this.globalData.home.name}`);

    const homeGk1Canvas = document.querySelectorAll("#gk_shooting-home-0 svg");
    const homeGk1Name = document.querySelector("#gk_shooting-home-0 .select-label")?.innerHTML;
    const homeGk2Canvas = document.querySelectorAll("#gk_shooting-home-1 svg");
    const homeGk2Name = document.querySelector("#gk_shooting-home-1 .select-label")?.innerHTML;

    this.pdf.add(new Table([
      [
        await new Txt(homeGk1Name).width(250).fontSize(15).alignment('center').margin([0,7]).end,
        new Txt('').end,
        await new Txt(homeGk2Name).width(250).fontSize(15).alignment('center').margin([0,7]).end
      ],
      [
        homeGk1Canvas[0] ? await new SVG(homeGk1Canvas[0].outerHTML).width(300).alignment('center').margin([0, 0, 0, -5]).end:new Txt('').end,
        new Txt('').end,
        homeGk2Canvas[0] ? await new SVG(homeGk2Canvas[0].outerHTML).width(300).alignment('center').margin([0, 0, 0, -5]).end:new Txt('').end,
      ],
      [
        homeGk1Canvas[1] ? await new SVG(homeGk1Canvas[1]?.outerHTML).width(300).alignment('center').end:new Txt('').end,
        new Txt('').end,
        homeGk2Canvas[1] ? await new SVG(homeGk2Canvas[1].outerHTML).width(300).alignment('center').end:new Txt('').end,
      ],
      [
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'rect',
                rectWidth: 297.7,
                rectColor: pdfColors.bgRectTableDarktBlue,
                firstRectX: 0,
                firstRectY: 5,
                firstRectHeight: 10,
              })
            ]
          ]).absolutePosition(41.4, 100)
          .layout({
            defaultBorder: false,
            paddingLeft: function(i, node) { return 0; },
            paddingRight: function(i, node) { return 0; },
            paddingTop: function(i, node) { return 0; },
            paddingBottom: function(i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*').end
        ],

        new Txt('').end,

        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'rect',
                rectWidth: 297.7,
                rectColor: pdfColors.bgRectTableDarktBlue,
                firstRectX: 0,
                firstRectY: 5,
                firstRectHeight: 10,
              })
            ]
          ]).absolutePosition(500.5, 100)
          .layout({
            defaultBorder: false,
            paddingLeft: function(i, node) { return 0; },
            paddingRight: function(i, node) { return 0; },
            paddingTop: function(i, node) { return 0; },
            paddingBottom: function(i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*').end
        ],
      ],
      [
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'rect',
                rectWidth: 297.7,
                rectColor: '#7EAAD6',
                firstRectX: 0,
                firstRectY: 0,
                firstRectHeight: 10,
              })
            ]
          ]).absolutePosition(41.4, 565)
          .layout({
            defaultBorder: false,
            paddingLeft: function(i, node) { return 0; },
            paddingRight: function(i, node) { return 0; },
            paddingTop: function(i, node) { return 0; },
            paddingBottom: function(i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*').end
        ],
        new Txt('').end,
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'rect',
                rectWidth: 297.7,
                rectColor: '#7EAAD6',
                firstRectX: 0,
                firstRectY: 0,
                firstRectHeight: 10,
              })
            ]
          ]).absolutePosition(500.5, 565)
          .layout({
            defaultBorder: false,
            paddingLeft: function(i, node) { return 0; },
            paddingRight: function(i, node) { return 0; },
            paddingTop: function(i, node) { return 0; },
            paddingBottom: function(i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*').end
        ],

      ]
    ])
    .layout('noBorders').headerRows(1).margin([0, 0, 0, 10]).widths(['45%', '10%', '45%']).end);

    // 4 page
    await this.addHeaderPage(`${toUpperCase(await this.tg(_('OVERVIEW'), this.skippedLangs))} ${this.globalData.visitor.name}`);

    this.pdf.add(
      this.createCustomTable({
        rectType: 'header',
        rectWidth: 822,
        rectColor: pdfColors.bgRectTableLightBlue,
        firstRectHeight: 28,
        secondRectHeight: 18
      })
    );

    await this.addOverviewTableHeadersOnly(
      this.globalData.visitor,
      this.visitorTeamBgColor,
      this.visitorTeamFontColor,
      this.visitorTeamOddRowsColor,
      'A'
    );

    this.pdf.add(
      this.createCustomTable({
        rectType: 'body',
        rectWidth: 822,
        rectColor: pdfColors.bgRectTableWhite,
        firstRectHeight: 45,
      })
    );

    this.pdf.add(
      this.createCustomTable({
        rectType: 'footer',
        rectWidth: 822,
        rectColor: pdfColors.bgRectTableDarktBlue,
        firstRectHeight: 20,
        secondRectHeight: 10
      })
    );

    await this.addOverviewTable(
        this.globalData.visitor,
        this.visitorTeamBgColor,
        this.visitorTeamFontColor,
        this.visitorTeamOddRowsColor);
    await this.addThreeColumnOverviewTable(this.globalData.visitor, this.canvas2, this.visitorTeamBgColor, this.visitorTeamFontColor, this.visitorTeamOddRowsColor);
    await this.addOverviewQuickTableAndShooting(this.globalData.visitor, this.canvas2, this.visitorTeamBgColor, this.visitorTeamFontColor, this.visitorTeamOddRowsColor);
    // this.addFooterToPagePDF(2);
    this.addPageBreak();

    // 5 page
    //GK VISITOR
    await this.addHeaderPage(`${toUpperCase(await this.tg(_('GK SHOOTING'), this.skippedLangs))} ${this.globalData.visitor.name}`);

    const visitorGk1Canvas = document.querySelectorAll("#gk_shooting-visitor-0 svg");
    const visitorGk1Name = document.querySelector("#gk_shooting-visitor-0 .select-label")?.innerHTML;
    const visitorGk2Canvas = document.querySelectorAll("#gk_shooting-visitor-1 svg");
    const visitorGk2Name = document.querySelector("#gk_shooting-visitor-1 .select-label")?.innerHTML;

    this.pdf.add(new Table([
      [
        new Txt(visitorGk1Name).width(250).fontSize(15).alignment('center').margin([0,7]).end,
        new Txt('').end,
        new Txt(visitorGk2Name).width(250).fontSize(15).alignment('center').margin([0,7]).end
      ],
      [
        visitorGk1Canvas[0] ? await new SVG(visitorGk1Canvas[0].outerHTML).width(300).alignment('center').margin([0, 0, 0, -5]).end:new Txt('').end,
        new Txt('').margin([0, 0, 0, -5]).end,
        visitorGk2Canvas[1] ? await new SVG(visitorGk2Canvas[0].outerHTML).width(300).alignment('center').margin([0, 0, 0, -5]).end:new Txt('').end,
      ],
      [
        visitorGk1Canvas[0] ? await new SVG(visitorGk1Canvas[1].outerHTML).width(300).alignment('center').end:new Txt('').end,
        new Txt('').end,
        visitorGk2Canvas[1] ? await new SVG(visitorGk2Canvas[1].outerHTML).width(300).alignment('center').end:new Txt('').end,
      ],
      [
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'rect',
                rectWidth: 297.7,
                rectColor: pdfColors.bgRectTableDarktBlue,
                firstRectX: 0,
                firstRectY: 5,
                firstRectHeight: 10,
              })
            ]
          ]).absolutePosition(41.4, 100)
          .layout({
            defaultBorder: false,
            paddingLeft: function(i, node) { return 0; },
            paddingRight: function(i, node) { return 0; },
            paddingTop: function(i, node) { return 0; },
            paddingBottom: function(i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*').end
        ],
        new Txt('').end,
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'rect',
                rectWidth: 297.7,
                rectColor: pdfColors.bgRectTableDarktBlue,
                firstRectX: 0,
                firstRectY: 5,
                firstRectHeight: 10,
              })
            ]
          ]).absolutePosition(500.5, 100)
          .layout({
            defaultBorder: false,
            paddingLeft: function(i, node) { return 0; },
            paddingRight: function(i, node) { return 0; },
            paddingTop: function(i, node) { return 0; },
            paddingBottom: function(i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*').end
        ],
      ],
      [
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'rect',
                rectWidth: 297.7,
                rectColor: '#7EAAD6',
                firstRectX: 0,
                firstRectY: 0,
                firstRectHeight: 10,
              })
            ]
          ]).absolutePosition(41.4, 565)
          .layout({
            defaultBorder: false,
            paddingLeft: function(i, node) { return 0; },
            paddingRight: function(i, node) { return 0; },
            paddingTop: function(i, node) { return 0; },
            paddingBottom: function(i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*').end
        ],
        new Txt('').end,
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'rect',
                rectWidth: 297.7,
                rectColor: '#7EAAD6',
                firstRectX: 0,
                firstRectY: 0,
                firstRectHeight: 10,
              })
            ]
          ]).absolutePosition(500.5, 565)
          .layout({
            defaultBorder: false,
            paddingLeft: function(i, node) { return 0; },
            paddingRight: function(i, node) { return 0; },
            paddingTop: function(i, node) { return 0; },
            paddingBottom: function(i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*').end
        ],
      ]
    ])
    .layout('noBorders').headerRows(1).margin([0, 0, 0, 10]).widths(['45%', '10%', '45%']).end);


    const alternatingColorTableLayoutHome: ICustomTableLayout = {
      fillColor: function (rowIndex) {
          if(rowIndex === 0) return '#2aacd3';
          return (rowIndex % 2 === 0 && rowIndex != 0) ? '#f0f0f0' : 'white';
      },
      hLineWidth: function (rowIndex) {
          return (rowIndex === 1) ? 2 : 0;
      },
      hLineColor: function (rowIndex) {
          return '#f0f0f0';
      },
      vLineWidth: function (rowIndex) {
          return (rowIndex === 1) ? 0 : 0;
      },
      vLineColor: function (rowIndex) {
          return '#f0f0f0';
      },
    };

    const alternatingColorTableLayoutVisitor: ICustomTableLayout = {
      fillColor: function (rowIndex) {
          if(rowIndex === 0) return '#0e375f';
          return (rowIndex % 2 === 0 && rowIndex != 0) ? '#f0f0f0' : 'white';
      },
      hLineWidth: function (rowIndex) {
          return (rowIndex === 1) ? 2 : 0;
      },
      hLineColor: function (rowIndex) {
          return '#f0f0f0';
      },
      vLineWidth: function (rowIndex) {
          return (rowIndex === 1) ? 0 : 0;
      },
      vLineColor: function (rowIndex) {
          return '#f0f0f0';
      },
    };

    // page 6
    await this.addHeaderPage(`${toUpperCase(await this.tg(_('INDIVIDUAL PERFORMANCE'), this.skippedLangs))}`);
    await this.addIndividualPerformanceTeamTable(this.globalData.home, this.homeTeamBgColor, this.homeTeamFontColor, this.homeTeamOddRowsColor);
    // this.addFooterToPagePDF(3);
    this.addPageBreak();

    // page 7
    await this.addHeaderPage(toUpperCase(await this.tg(_('INDIVIDUAL PERFORMANCE'), this.skippedLangs)));
    this.addEmptyLines(1);
    this.pdf.add(await new Img(this.canvas3.nativeElement.toDataURL()).width(800).alignment('center').build())
    this.addPageBreak();

    // page 8
    await this.addHeaderPage(toUpperCase(await this.tg(_('INDIVIDUAL PERFORMANCE'), this.skippedLangs)));
    await this.addIndividualCountersTeamTable(this.globalData.home, this.homeTeamBgColor, this.homeTeamFontColor, this.homeTeamOddRowsColor);
    this.addPageBreak();

    // page 9
    await this.addHeaderPageIconLegend(toUpperCase(await this.tg(_('INDIVIDUAL PERFORMANCE'), this.skippedLangs)));
    this.pdf.add(
      new Table([
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'rect-with-border',
                rectWidth: 297.7,
                rectColor: pdfColors.bgRectTableWhite,
                firstRectX: 0,
                firstRectY: 0,
                firstRectHeight: 25,

                firstRectColor: pdfColors.bgRectTableBlue,
                firstRectLineColor: pdfColors.bgRectTableWhite
              })
            ],
            [
              new Cell(
                new Txt(`${this.globalData.home['name']}`).fontSize(11).color('#FFFFFF').end
              )
                .margin([0, 0, 0, 0]).relativePosition(0, -20).end
            ]
          ])
            .layout({
              defaultBorder: false,
              paddingLeft: function (i, node) { return 0; },
              paddingRight: function (i, node) { return 0; },
              paddingTop: function (i, node) { return 0; },
              paddingBottom: function (i, node) { return 0; }
            })
            .alignment('center')
            .margin([0, 0, 0, 0])
            .widths('*').end
        ],

      ])
        .layout({
          defaultBorder: false
        })
        .widths(['100%']).end
    );

    this.pdf.add(new Table([
      [
        new Table([
          [
            this.createCustomTable({
              rectType: 'header',
              rectWidth: 818,
              rectColor: pdfColors.bgRectTableLightBlue,
              firstRectHeight: 25,
              secondRectHeight: 15
            })
          ],
          [
            new Cell(
              await this.addOverviewTableHeadersOnly(this.globalData.home, null, null, null, 'B6'))

              .margin([0, 0, 0, 0])
              .relativePosition(0, -20)
              .end
          ],
        ])
          .layout({
            defaultBorder: false,
            paddingLeft: function (i, node) { return 0; },
            paddingRight: function (i, node) { return 0; },
            paddingTop: function (i, node) { return 0; },
            paddingBottom: function (i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*')
          .end,
      ]
    ]).layout({
      defaultBorder: false,
      paddingLeft: function (i, node) { return 0; },
      paddingRight: function (i, node) { return 0; },
      paddingTop: function (i, node) { return 0; },
      paddingBottom: function (i, node) { return 0; }
    })
      .margin([0, 0, 0, 0]).headerRows(1).margin([0, 0, 0, 0]).widths(['*']).end
    );

    this.pdf.add((await this.generateFieldPlayerIconTableForPdf(this.simplifiedFieldPlayerModelsHome, this.globalData.home.individualPerformanceTable)).layout(layoutTableBodyGridTest2).end);

    this.pdf.add(new Table([
      [
        new Table([
          [
            this.createCustomTable({
              rectType: 'header',
              rectWidth: 818,
              rectColor: pdfColors.bgRectTableLightBlue,
              firstRectHeight: 25,
              secondRectHeight: 15
            })
          ],
          [
            new Cell(
              await this.addOverviewTableHeadersOnly(this.globalData.home, null, null, null, 'B7'))
              .margin([0, 0, 0, 0])
              .relativePosition(0, -25)
              .end
          ]
        ])
          .layout({
            defaultBorder: false,
            paddingLeft: function (i, node) { return 0; },
            paddingRight: function (i, node) { return 0; },
            paddingTop: function (i, node) { return 0; },
            paddingBottom: function (i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*')
          .end,
      ]
    ]).layout({
      defaultBorder: false,
      paddingLeft: function (i, node) { return 0; },
      paddingRight: function (i, node) { return 0; },
      paddingTop: function (i, node) { return 0; },
      paddingBottom: function (i, node) { return 0; }
    })
      .margin([0, 0, 0, 0]).headerRows(1).margin([0, 0, 0, 0]).widths(['*']).end
    );

    const goalkeepersHomeTeam = this.globalData.home.individualPerformanceTable.filter(p => p.rol === 'gk');
    this.pdf.add((await this.generateGoalkeeperIconTableForPdf(this.simplifiedGoalkeeperPlayerModelsHome, goalkeepersHomeTeam)).layout(layoutTableBodyGridTest2).end);
    this.addPageBreak();
    // end of page 9

    // page 10
    await this.addHeaderPage(`${toUpperCase(await this.tg(_('INDIVIDUAL PERFORMANCE'), this.skippedLangs))}`);
    await this.addIndividualPerformanceTeamTable(this.globalData.visitor, this.visitorTeamBgColor, this.visitorTeamFontColor, this.visitorTeamOddRowsColor);
    // this.addFooterToPagePDF(4);
    this.addPageBreak();

    // page 11
    await this.addHeaderPage(toUpperCase(await this.tg(_('INDIVIDUAL PERFORMANCE'), this.skippedLangs)));
    this.addEmptyLines(1);
    this.pdf.add(await new Img(this.canvas4.nativeElement.toDataURL()).width(800).alignment('center').build())
    this.addPageBreak();
    // END of page 11

    // page 12
    await this.addHeaderPage(`${toUpperCase(await this.tg(_('INDIVIDUAL PERFORMANCE'), this.skippedLangs))}`);
    await this.addIndividualCountersTeamTable(this.globalData.visitor, this.visitorTeamBgColor, this.visitorTeamFontColor, this.visitorTeamOddRowsColor);
    this.addPageBreak();
    // end of page 12

    // page 13
    await this.addHeaderPageIconLegend(toUpperCase(await this.tg(_('INDIVIDUAL PERFORMANCE'), this.skippedLangs)));


    this.pdf.add(
      new Table([
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'rect-with-border',
                rectWidth: 297.7,
                rectColor: pdfColors.bgRectTableWhite,
                firstRectX: 0,
                firstRectY: 0,
                firstRectHeight: 25,

                firstRectColor: pdfColors.bgRectTableDarktBlue,
                firstRectLineColor: pdfColors.bgRectTableWhite
              })
            ],
            [
              new Cell(
                new Txt(`${this.globalData.visitor['name']}`).fontSize(11).color('#FFFFFF').end
              )
                .margin([0, 0, 0, 0]).relativePosition(0, -20).end
            ]
          ])
            .layout({
              defaultBorder: false,
              paddingLeft: function (i, node) { return 0; },
              paddingRight: function (i, node) { return 0; },
              paddingTop: function (i, node) { return 0; },
              paddingBottom: function (i, node) { return 0; }
            })
            .alignment('center')
            .margin([0, 0, 0, 0])
            .widths('*').end
        ],

      ])
        .layout({
          defaultBorder: false
        })
        .widths(['100%']).end
    );

    this.pdf.add(new Table([
      [
        new Table([
          [
            this.createCustomTable({
              rectType: 'header',
              rectWidth: 818,
              rectColor: pdfColors.bgRectTableLightBlue,
              firstRectHeight: 25,
              secondRectHeight: 15
            })
          ],
          [
            new Cell(
              await this.addOverviewTableHeadersOnly(this.globalData.visitor, null, null, null, 'B6'))

              .margin([0, 0, 0, 0])
              .relativePosition(0, -20)
              .end
          ]
        ])
          .layout({
            defaultBorder: false,
            paddingLeft: function (i, node) { return 0; },
            paddingRight: function (i, node) { return 0; },
            paddingTop: function (i, node) { return 0; },
            paddingBottom: function (i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*')
          .end,
      ]
    ]).layout({
      defaultBorder: false,
      paddingLeft: function (i, node) { return 0; },
      paddingRight: function (i, node) { return 0; },
      paddingTop: function (i, node) { return 0; },
      paddingBottom: function (i, node) { return 0; }
    })
      .margin([0, 0, 0, 0]).headerRows(1).margin([0, 0, 0, 0]).widths(['*']).end
    );

    this.pdf.add((await this.generateFieldPlayerIconTableForPdf(this.simplifiedFieldPlayerModelsGuest, this.globalData.visitor.individualPerformanceTable)).layout(layoutTableBodyGridTest2).end);

    this.pdf.add(new Table([
      [
        new Table([
          [
            this.createCustomTable({
              rectType: 'header',
              rectWidth: 818,
              rectColor: pdfColors.bgRectTableLightBlue,
              firstRectHeight: 25,
              secondRectHeight: 15
            })
          ],
          [
            new Cell(
              await this.addOverviewTableHeadersOnly(this.globalData.visitor, null, null, null, 'B7'))

              .margin([0, 0, 0, 0])
              .relativePosition(0, -20)
              .end
          ]
        ])
          .layout({
            defaultBorder: false,
            paddingLeft: function (i, node) { return 0; },
            paddingRight: function (i, node) { return 0; },
            paddingTop: function (i, node) { return 0; },
            paddingBottom: function (i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*')
          .end,
      ]
    ]).layout({
      defaultBorder: false,
      paddingLeft: function (i, node) { return 0; },
      paddingRight: function (i, node) { return 0; },
      paddingTop: function (i, node) { return 0; },
      paddingBottom: function (i, node) { return 0; }
    })
      .margin([0, 0, 0, 0]).headerRows(1).margin([0, 0, 0, 0]).widths(['*']).end
    );

    const goalkeepersVisitorTeam = this.globalData.visitor.individualPerformanceTable.filter(p => p.rol === 'gk');
    console.warn('ARSA TEST GK', goalkeepersVisitorTeam);
    this.pdf.add((await this.generateGoalkeeperIconTableForPdf(this.simplifiedGoalkeeperPlayerModelsGuest, goalkeepersVisitorTeam)).layout(layoutTableBodyGridTest2).end);
    this.addPageBreak();
    // end of page 13

    // page 14
      // TimeLine page
      await this.addHeaderPage(toUpperCase(await this.tg(_('TIMELINE'), this.skippedLangs)));
      this.pdf.add(new Table([
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'header',
                rectWidth: 818,
                rectColor: pdfColors.bgRectTableLightBlue,
                firstRectHeight: 25,
                secondRectHeight: 15
              })
            ],
            [
              new Cell(
                await this.addOverviewTableHeadersOnly(this.globalData.home, null, null, null, 'B8'))

                .margin([0, 0, 0, 0])
                .relativePosition(0, -20)
                .end
            ],
            // body shape
            [
              this.createCustomTable({
                rectType: 'body',
                rectWidth: 818,
                rectColor: pdfColors.bgRectTableWhite,
                firstRectHeight: 70
              })
            ],
            [
              this.createCustomTable({
                rectType: 'footer',
                rectWidth: 818,
                rectColor: pdfColors.bgRectTableWhite,
                firstRectHeight: 15,
                secondRectHeight: 5
              })
            ]
          ])
            .layout({
              defaultBorder: false,
              paddingLeft: function (i, node) { return 0; },
              paddingRight: function (i, node) { return 0; },
              paddingTop: function (i, node) { return 0; },
              paddingBottom: function (i, node) { return 0; }
            })
            .margin([0, 0, 0, 0])
            .widths('*')
            .end,
        ]
      ]).layout({
        defaultBorder: false,
        paddingLeft: function (i, node) { return 0; },
        paddingRight: function (i, node) { return 0; },
        paddingTop: function (i, node) { return 0; },
        paddingBottom: function (i, node) { return 0; }
      })
      .margin([0, 0, 0, 0]).headerRows(1).margin([0, 0, 0, 0]).widths(['*']).end
    );

    this.pdf.add(await this.getTimelineTableFrom5To30Minutes(this.globalData.home.name, 0, this._extraTimeOrPenaltiesPlayed ? 15 : 12));

    this.pdf.add(new Table([
      [
        new Table([
          [
            this.createCustomTable({
              rectType: 'header',
              rectWidth: 818,
              rectColor: pdfColors.bgRectTableLightBlue,
              firstRectHeight: 25,
              secondRectHeight: 15
            })
          ],
          [
            new Cell(
              await this.addOverviewTableHeadersOnly(this.globalData.visitor, null, null, null, 'B8'))

              .margin([0, 0, 0, 0])
              .relativePosition(0, -20)
              .end
          ],
          // body shape
          [
            this.createCustomTable({
              rectType: 'body',
              rectWidth: 818,
              rectColor: pdfColors.bgRectTableWhite,
              firstRectHeight: 70
            })
          ],
          [
            this.createCustomTable({
              rectType: 'footer',
              rectWidth: 818,
              rectColor: pdfColors.bgRectTableWhite,
              firstRectHeight: 15,
              secondRectHeight: 5
            })
          ]
        ])
          .layout({
            defaultBorder: false,
            paddingLeft: function (i, node) { return 0; },
            paddingRight: function (i, node) { return 0; },
            paddingTop: function (i, node) { return 0; },
            paddingBottom: function (i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*')
          .end,
      ]
    ]).layout({
      defaultBorder: false,
      paddingLeft: function (i, node) { return 0; },
      paddingRight: function (i, node) { return 0; },
      paddingTop: function (i, node) { return 0; },
      paddingBottom: function (i, node) { return 0; }
    })
      .margin([0, 0, 0, 0]).headerRows(1).margin([0, 0, 0, 0]).widths(['*']).end
    )

    this.pdf.add(await this.getTimelineTableFrom5To30Minutes(this.globalData.visitor.name, 0,  this._extraTimeOrPenaltiesPlayed ? 15 : 12));

    this.pdf.add(new Table([
      [
        new Table([
          [
            this.createCustomTable({
              rectType: 'header',
              rectWidth: 818,
              rectColor: pdfColors.bgRectTableLightBlue,
              firstRectHeight: 25,
              secondRectHeight: 15
            })
          ],
          [
            // this.globalData.visitor
            new Cell(
              await this.addOverviewTableHeadersOnly(null, null, null, null, 'B9_1'))
              .margin([0, 0, 0, 0])
              .relativePosition(0, -20)
              .end
          ]
        ])
          .layout({
            defaultBorder: false,
            paddingLeft: function (i, node) { return 0; },
            paddingRight: function (i, node) { return 0; },
            paddingTop: function (i, node) { return 0; },
            paddingBottom: function (i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*')
          .end,
      ]
    ]).layout({
      defaultBorder: false,
      paddingLeft: function (i, node) { return 0; },
      paddingRight: function (i, node) { return 0; },
      paddingTop: function (i, node) { return 0; },
      paddingBottom: function (i, node) { return 0; }
    })
      .margin([0, 0, 0, 0]).headerRows(1).margin([0, 0, 0, 0]).widths(['*']).end
    )

    this.pdf.add((await generateGoalByGoal(this._goalByGoal, this.globalData.home['name'], this.globalData.visitor['name'], this.homeTeamBgColor, this.visitorTeamBgColor, [0, 31]))
      .layout(layoutTableBodyGridTest2)
      .widths([
        '24%', '2.46%',
        '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%',
        '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%',
        '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.4%'
      ])
      .end)

    this.pdf.add(new Table([
      [
        new Table([
          [
            this.createCustomTable({
              rectType: 'header',
              rectWidth: 818,
              rectColor: pdfColors.bgRectTableLightBlue,
              firstRectHeight: 25,
              secondRectHeight: 15
            })
          ],
          [
            // this.globalData.visitor
            new Cell(
              await this.addOverviewTableHeadersOnly(null, null, null, null, 'B9_2'))
              .margin([0, 0, 0, 0])
              .relativePosition(0, -20)
              .end
          ]
        ])
          .layout({
            defaultBorder: false,
            paddingLeft: function (i, node) { return 0; },
            paddingRight: function (i, node) { return 0; },
            paddingTop: function (i, node) { return 0; },
            paddingBottom: function (i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*')
          .end,
      ]
    ]).layout({
      defaultBorder: false,
      paddingLeft: function (i, node) { return 0; },
      paddingRight: function (i, node) { return 0; },
      paddingTop: function (i, node) { return 0; },
      paddingBottom: function (i, node) { return 0; }
    })
      .margin([0, 0, 0, 0]).headerRows(1).margin([0, 0, 0, 0]).widths(['*']).end
    );
    this.pdf.add((await generateGoalByGoal(this._goalByGoal, this.globalData.home['name'], this.globalData.visitor['name'], this.homeTeamBgColor, this.visitorTeamBgColor, [30, 61]))
      .layout(layoutTableBodyGridTest2)
      .widths([
        '24%', '2.46%',
        '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%',
        '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%',
        '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.4%'
      ]).end)

      this.addPageBreak();

    // page 15
    // http://localhost:4200/account/85/game/4474/stats
    if (this.globalData.home.gameSystemTable.length > 0 && this.globalData.visitor.gameSystemTable.length > 0) {
      await this.addHeaderPage(toUpperCase(await this.tg(_('GAME SYSTEM'), this.skippedLangs)));
      this.pdf.add(
        new Table([
          [
            new Table([
              [
                this.createCustomTable({
                  rectType: 'rect-with-border',
                  rectWidth: 297.7,
                  rectColor: pdfColors.bgRectTableWhite,
                  firstRectX: 0,
                  firstRectY: 0,
                  firstRectHeight: 25,

                  firstRectColor: pdfColors.bgRectTableBlue,
                  firstRectLineColor: pdfColors.bgRectTableWhite
                })
              ],
              [
                new Cell(
                  new Txt(`${this.globalData.home['name']}`).fontSize(11).color('#FFFFFF').end
                )
                  .margin([0, 0, 0, 0]).relativePosition(0, -20).end
              ]
            ])
              .layout({
                defaultBorder: false,
                paddingLeft: function (i, node) { return 0; },
                paddingRight: function (i, node) { return 0; },
                paddingTop: function (i, node) { return 0; },
                paddingBottom: function (i, node) { return 0; }
              })
              .alignment('center')
              .margin([0, 0, 0, 0])
              .widths('*').end
          ],

        ])
          .layout({
            defaultBorder: false
          })
          .widths(['100%']).end
      );

      this.pdf.add(new Table([
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'header',
                rectWidth: 818,
                rectColor: pdfColors.bgRectTableLightBlue,
                firstRectHeight: 25,
                secondRectHeight: 15
              })
            ],
            [
              new Cell(
                await this.addOverviewTableHeadersOnly(this.globalData.home, null, null, null, 'B10'))

                .margin([0, 0, 0, 0])
                .relativePosition(0, -20)
                .end
            ]
          ])
            .layout({
              defaultBorder: false,
              paddingLeft: function (i, node) { return 0; },
              paddingRight: function (i, node) { return 0; },
              paddingTop: function (i, node) { return 0; },
              paddingBottom: function (i, node) { return 0; }
            })
            .margin([0, 0, 0, 0])
            .widths('*')
            .end,
        ]
      ]).layout({
        defaultBorder: false,
        paddingLeft: function (i, node) { return 0; },
        paddingRight: function (i, node) { return 0; },
        paddingTop: function (i, node) { return 0; },
        paddingBottom: function (i, node) { return 0; }
      })
        .margin([0, 0, 0, 0]).headerRows(1).margin([0, 0, 0, 0]).widths(['*']).end
      );

      this.pdf.add(new Table([
        [
          await this.getGameSystemTeamTable(
            this.globalData.home,
            this.homeTeamBgColor,
            this.homeTeamFontColor,
            this.homeTeamOddRowsColor
          )
        ]
      ]).layout('noBorders').widths(['100%']).end);

      this.addPageBreak();

      // page 16
      await this.addHeaderPage(toUpperCase(await this.tg(_('GAME SYSTEM'), this.skippedLangs)));
      this.pdf.add(
        new Table([
          [
            new Table([
              [
                this.createCustomTable({
                  rectType: 'rect-with-border',
                  rectWidth: 297.7,
                  rectColor: pdfColors.bgRectTableWhite,
                  firstRectX: 0,
                  firstRectY: 0,
                  firstRectHeight: 25,
                  firstRectColor: pdfColors.bgRectTableDarktBlue,
                  firstRectLineColor: pdfColors.bgRectTableWhite
                })
              ],
              [
                new Cell(
                  new Txt(`${this.globalData.visitor['name']}`).fontSize(11).color('#FFFFFF').end
                )
                  .margin([0, 0, 0, 0]).relativePosition(0, -20).end
              ]
            ])
              .layout({
                defaultBorder: false,
                paddingLeft: function (i, node) { return 0; },
                paddingRight: function (i, node) { return 0; },
                paddingTop: function (i, node) { return 0; },
                paddingBottom: function (i, node) { return 0; }
              })
              .alignment('center')
              .margin([0, 0, 0, 0])
              .widths('*').end
          ],
        ])
          .layout({
            defaultBorder: false
          })
          .widths(['100%']).end
      );

      this.pdf.add(new Table([
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'header',
                rectWidth: 818,
                rectColor: pdfColors.bgRectTableLightBlue,
                firstRectHeight: 25,
                secondRectHeight: 15
              })
            ],
            [
              new Cell(
                await this.addOverviewTableHeadersOnly(this.globalData.home, null, null, null, 'B10'))

                .margin([0, 0, 0, 0])
                .relativePosition(0, -20)
                .end
            ]
          ])
            .layout({
              defaultBorder: false,
              paddingLeft: function (i, node) { return 0; },
              paddingRight: function (i, node) { return 0; },
              paddingTop: function (i, node) { return 0; },
              paddingBottom: function (i, node) { return 0; }
            })
            .margin([0, 0, 0, 0])
            .widths('*')
            .end,
        ]
      ]).layout({
        defaultBorder: false,
        paddingLeft: function (i, node) { return 0; },
        paddingRight: function (i, node) { return 0; },
        paddingTop: function (i, node) { return 0; },
        paddingBottom: function (i, node) { return 0; }
      })
        .margin([0, 0, 0, 0]).headerRows(1).margin([0, 0, 0, 0]).widths(['*']).end
      );

      this.pdf.add(new Table([
        [
          await this.getGameSystemTeamTable(
            this.globalData.visitor,
            this.visitorTeamBgColor,
            this.visitorTeamFontColor,
            this.visitorTeamOddRowsColor
          )
        ]
      ]).layout('noBorders').widths(['100%']).end);
    }
    // Create and open in a tab the pdf

    // console.log('GENERATED OBJ', this.pdf.getDefinition());
    return this.pdf.create();
  }

  private async resolvePlayerCell(playerCellData, idx) {
    return new Cell(
      new Table([
        [
          new Cell(new Txt(`${playerCellData?.backNumber}`).end).end,

          // v2
          // new Cell(
          //   await this.teamLogoResolver(playerCellData.imageUrl, "center", 8)
          // ).margin(0).end,

          // new Cell(
          //   new Txt('').end
          //   // new SVG(`${playerAvatarSVG}`).width(10).end
          //   // new SVG(`${handballField}`).alignment('right').width(300).end
          // ).margin(0).end,

          // v3
          // new Cell(
          //   new Table([
          //     [new Txt(`${player?.name.split(" ")[0]}`).end],
          //     // [new Txt(`${player?.name.split(" ")[1]}`).end],
          //     // [new Txt(`${player?.rol}`.toUpperCase()).end]
          //   ]).end
          // ).end

          new Cell(
            new Txt(`${playerCellData?.name}`).alignment('left').end
          ).end,

          new Cell(
            new Txt(`${playerCellData?.rol?.toUpperCase() ? playerCellData?.rol?.toUpperCase() : ''}`).end
          ).end
        ],
      ])
        .layout({
          defaultBorder: false,
        })
        // .widths(['10%', '10%', '*', '10%'])
        .widths(['10%', '*', '15%'])
        .end
    )
    .style(['player_cell_b6'])
    .margin(0)
    // .margin(idx === 0 ? [5, 0, 0, 0] : 0)
    .end;
  }

  private async generateGoalkeeperIconTableForPdf(_simplifiedFieldPlayerModels: SimplifiedPlayerStats[], playersTeamList?): Promise<Table> {
    const rows = [[]];
    const images = new Map();

    const goalKeeperTableColumns = this._extraTimeOrPenaltiesPlayed ? goalKeeperTableColumnsWithETHelper : goalKeeperTableColumnsHelper;
    const indexNum = this._extraTimeOrPenaltiesPlayed ? 14 : 11;

    for (let symbol of goalKeeperTableLegendMapHelper) {
      images.set(symbol.prop, await new Img('/assets/playerstatsIcons/' + symbol.img).fit([9.5, 9.5]).build());
    }

    goalKeeperTableColumns.forEach((element) => {
      if (element.prop.includes("Bucket") || element.prop.includes("Name")) {
        rows[0].push(new Txt("\n" + element.name).fontSize(8).alignment('center').color('white').end);
      } else {
        rows[0].push(new Txt(element.name).fontSize(8).alignment('center').color('white').end);
      }
    });

    let rowIndex = 0;
    for (const player of _simplifiedFieldPlayerModels) {
      rowIndex++;
      rows.push([]);

      let index = 0
      for (const column of goalKeeperTableColumns) {

        if (index > 0 && index < indexNum) {
          // do translation to image
          let cell = [];
          let row = [];
          goalKeeperTableLegendMapHelper.forEach((symbol) => {
            if (symbol.img !== '1x1.png') {
              let count = (player[column.prop])[symbol.prop];
              if (count > 0) {
                for (let i = 0; i < count; i++) {
                  // hard cut after 4 images no line
                  if (row.length >= 4) {
                    cell.push(new Columns(row).end);
                    row = [];
                  }
                  row.push(new Cell(images.get(symbol.prop)).margin([0, 0.2, 0.2, 0.2]).end);
                }
              }
            }
          });
          // fill rows to same columns because else pdfmakewrapper crashes
          while (row.length <= 3) {
            row.push("");
          }
          cell.push(new Columns(row).end);
          row = [];
          // push to final rowcolumn
          rows[rowIndex].push(cell);
        } else if (column.name.includes("%")) {
          rows[rowIndex].push(new Txt((player[column.prop] as number).toFixed(0) + " %").fontSize(8).alignment('center').end);
        } else {
          rows[rowIndex].push(new Txt(player[column.prop]).fontSize(8).alignment('center').end);

          if (playersTeamList) {
            let playerCellData = playersTeamList.filter(p => (p.playerId === player.playerId) || (p.backNumber === player.playerName.split('-')[0].trim()))[0]
            if (playerCellData) {
              rows[rowIndex][0] = await this.resolvePlayerCell(playerCellData, index)
            }
          }
        }
        index++;
      }
    }
    rows.shift();

    const fieldPlayerTable = new Table(rows)
      .widths(this._extraTimeOrPenaltiesPlayed ? [
        '18%', '5.7%',
        '5.7%', '5.7%', '5.7%', '5.7%', '5.7%',
        '5.7%', '5.7%', '5.7%', '5.7%',
        '4.2%', '4.1%', '4.1%',
        '3.7%', '5%', '3.7%'
      ] : [
        '23.4%%', '6.4%',
        '6.4%', '6.4%', '6.4%', '6.4%', '6.4%',
        '6.4%', '6.4%', '6.4%', '6.4%',
        '3.7%', '5%', '3.7%'
      ])
    return fieldPlayerTable;
  }

  private async generateFieldPlayerIconTableForPdf(_simplifiedFieldPlayerModels: SimplifiedPlayerStats[], playersTeamList?): Promise<Table> {

    const rows = [[]];
    const images = new Map();

    for (let symbol of playerTableLegendMapHelper) {
      images.set(symbol.prop, await new Img('/assets/playerstatsIcons/' + symbol.img).fit([9, 9]).build());
    }

    const playerTableColumns = this._extraTimeOrPenaltiesPlayed ? playerTableColumnsWithETHelper : playerTableColumnsHelper;
    const indexNum = this._extraTimeOrPenaltiesPlayed ? 14 : 11;

    playerTableColumns.forEach((element) => {
      rows[0].push(new Txt(element.name).fontSize(7).alignment('center').color('white').end);
    });

    let rowIndex = 0;
    _simplifiedFieldPlayerModels.forEach((player: SimplifiedPlayerStats) => {
      rowIndex++;
      rows.push([]);

      playerTableColumns.forEach(async (column, index) => {
        if (index > 0 && index < indexNum) {
          // do translation to image
          let cell = [];
          let row = [];
          playerTableLegendMapHelper.forEach((symbol) => {
            if ((player[column.prop])[symbol.prop] > 0) {
              for (let i = 0; i < (player[column.prop])[symbol.prop]; i++) {
                // hard cut after 4 images no line
                if (row.length >= 4) {
                  cell.push(new Columns(row).end);
                  row = [];
                }
                row.push(new Cell(images.get(symbol.prop)).margin([0, 0.5, 0.5, 0.5]).end);
              }
            }
          });
          // fill rows to same columns because else pdfmakewrapper crashes
          while (row.length <= 3) {
            row.push("");
          }
          cell.push(new Columns(row).end);
          row = [];
          // push to final rowcolumn
          rows[rowIndex].push(cell);
        } else if (column.name.includes("%")) {
          rows[rowIndex].push(new Txt((player[column.prop] as number).toFixed(0) + " %").fontSize(7).alignment('center').end);
        } else {

          rows[rowIndex].push(new Txt(player[column.prop]).fontSize(7).alignment('center').end);

          if (playersTeamList) {
            let playerCellData = playersTeamList.filter(p => (p.playerId === player.playerId) || (p.backNumber === player.playerName.split('-')[0].trim()))[0]
            if (playerCellData) {
              rows[rowIndex][0] = await this.resolvePlayerCell(playerCellData, index)
            }
          }
        }
      });
    });

    rows.shift();

    const fieldPlayerTable = new Table(rows)
      .widths(this._extraTimeOrPenaltiesPlayed ? [
        '18%', '5.4%',
        '5.4%', '5.4%', '5.4%', '5.4%', '5.4%',
        '5.4%', '5.4%', '5.4%', '5.4%', '3.1%',
        '4.2%', '4.1%', '4.1%',
        '3.1%', '3.1%', '3.1%', '3.1%',
      ] : [
        '23.4%%', '6.1%',
        '6.1%', '6.1%', '6.1%', '6.1%', '6.1%',
        '6.1%', '6.1%', '6.1%', '6.1%', '3.1%',
        '3.1%', '3.1%', '3.1%', '3.1%',
      ]);
    return fieldPlayerTable;
  }

  private async dataRowsResolver(dataRows, config?) {
    const isValueObject = (val: any) => {
      if (val === null) return false
      return typeof val === 'object';
    }

    let cellrows: Object[][] = [];
    let index = 0;
    for (const dataRow of dataRows) {
      let cellRow = [];

      let idx = 0;
      for (const el of dataRow) {
        let topMargin = config?.getTimelineTableFrom5To30Minutes ? 3 : 5;
        let player: IndividualPerformanceTableRow;

        if (idx === 0) {
          if (
            config?.getIndividualPerformanceTable
            ||
            config?.getIndividualCountersTable
            ||
            config?.getGoalkeeperDetailTable
          ) {
            player = config?.sourceData[index];
          }
        }

        let cellContent =
          (idx === 0 && config?.getIndividualPerformanceTable)
            || (idx === 0 && config?.getIndividualCountersTable)
            || (idx === 0 && config?.getGoalkeeperDetailTable)
            ? new Cell(
              new Table([
                [
                  new Cell(new Txt(`${player?.backNumber}`).end).end,

                  // v2
                  // new Cell(
                  //   await this.teamLogoResolver(player?.imageUrl, "center", 10)
                  // ).margin(0).end,

                   // v2
                  //  new Cell(
                  //   // await this.teamLogoResolver(player?.imageUrl, "center", 10)
                  //   // new SVG(`${player?.imageUrl ===  '/assets/svg/football-team-bench.svg' ? footballTeamBench : playerAvatarSVG}`).width(10).end
                  //   new Txt('').end
                  //   // new SVG(`${playerAvatarSVG}`).width(10).end
                  // ).margin(0).end,

                  // v3
                  // new Cell(
                  //   new Table([
                  //     [new Txt(`${player?.name.split(" ")[0]}`).end],
                  //     // [new Txt(`${player?.name.split(" ")[1]}`).end],
                  //     // [new Txt(`${player?.rol}`.toUpperCase()).end]
                  //   ]).end
                  // ).end

                  new Cell(
                    new Txt(`${player?.name}`).alignment('left').end
                  ).end,

                  new Cell(
                    new Txt(`${player?.rol?.toUpperCase() ? player?.rol?.toUpperCase() : ''}`).end
                  ).end
                ],
              ])
                .layout({
                  defaultBorder: false,
                })
                // .widths(['10%', '10%', '*', '10%'])
                .widths(['15%', '*', '15%'])
                .end
            ).margin(idx === 0 ? [5, 0, 0, 0] : 0).end
            :
            //   {
            //     "value": 0,
            //     "percent": 0,
            //     "showArrow": false,
            //     "rectWidth": 40,
            //     "rectHeight": 10,
            //     "percentColor": "#E01E5A"
            // }
            isValueObject(el)
              ? // resolveObjectCell(el) ?
              new Cell(
                new Table([
                  // [new Txt("").width("<1-100>%").end],
                  [new SVG(this.getSvgProgressBarA(el?.value, 42, 10, el?.percent, false, el?.percentColor)).end],
                  // [new Txt("").width("<1-100>%").end],
                ])
                  .layout({
                    defaultBorder: false,
                    paddingRight: function (i, node) {
                      return 3;
                    },
                  })
                  // .heights((row: number) => 7)
                  .end
              ).end
              : new Cell(new Txt(`${el}`).end)
                .alignment(idx === 0 ? "left" : config && config["quickTabelStats"] && idx === 2 ? "left" : "center")
                .margin(
                  idx === 0
                    ? config?.getIndividualPerformanceTable || config?.getTimelineTableFrom5To30Minutes
                      ? [5, topMargin, 0, 0]
                      : [5, 0, 0, 0]
                    :
                    config?.getIndividualPerformanceTable || config?.getTimelineTableFrom5To30Minutes
                      ? [0, topMargin, 0, 0]
                      : 0
                ).end;

        cellRow.push(cellContent)
        idx++;
      }
      cellrows.push(cellRow)
      index++;
    };

    cellrows.forEach(cell => {
      cell.forEach((c, idx) => {
        c['style'] = 'table__body__cell'
      })
    });

    return cellrows
  }

  private async teamLogoResolver(logoUrl: string, alignment: "left" | "center" | "right" | "justify", width: number): Promise<ISVG | IImg> {
    if (logoUrl) {
      return await new Img(
        await this.getBase64ImageFromURL(
          `${logoUrl}`
        )
      ).width(width).alignment(alignment).build()
    }
    return (
        new SVG(`${teamLogoSVG}`)
        .alignment(alignment)
        .width(width).end
    )
  }

  private async  getBase64ImageFromURL(url: any): Promise<string> {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        let ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }

  private createCustomTable(config: RectConfig, data?: any,) {
    const {
      rectType,
      rectWidth,
      rectColor,

      firstRectX,
      firstRectY,
      firstRectHeight,
      firstRectLineColor,
      firstRectColor,

      secondRectX,
      secondRectY,
      secondRectHeight
    } = config;

    switch (rectType) {
      case 'header':
        return [
          new Canvas(
            [
              new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 10], [rectWidth, firstRectHeight ? firstRectHeight : 30]).lineColor(rectColor).color(rectColor).round(10).end,
              new Rect([secondRectX ? secondRectX : 0, secondRectY ? secondRectY : 20], [rectWidth, secondRectHeight ? secondRectHeight : 20]).lineColor(rectColor).color(rectColor).end,
            ]
          ).end
        ];
      case 'body':
        return [
          new Canvas(
            [
              new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 0], [rectWidth, firstRectHeight ? firstRectHeight : 40]).lineColor(rectColor).color(rectColor).end,
            ]
          ).end
        ];
      case 'footer':
        return [
          new Canvas(
            [
              new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 0], [rectWidth, firstRectHeight ? firstRectHeight : 28]).lineColor(rectColor).color(rectColor).round(10).end,
              new Rect([secondRectX ? secondRectX : 0, secondRectY ? secondRectY : 0], [rectWidth, secondRectHeight ? secondRectHeight : 18]).lineColor(rectColor).color(rectColor).end
            ]
          ).end,
        ];
      case 'rect':
        return [
          new Canvas(
            [
              new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 0], [rectWidth, firstRectHeight ? firstRectHeight : 28]).lineColor(rectColor).color(rectColor).round(10).end,
            ]
          ).end,
        ];
      case 'rect-with-border':
        return [
          new Canvas(
            [
              new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 0], [rectWidth, firstRectHeight ? firstRectHeight : 28]).lineColor(firstRectLineColor).color(firstRectColor).round(10).end,
            ]
          ).end,
        ];
    }
  }

  /**
   *
   * @param n
   */
  private addEmptyLines(n: number) {
    for (let i = 0; i < n; i++) {
      this.pdf.add(new Txt(' ').end);
    }
  }

  /**
   *
   */
  private addPageBreak() {
    this.pdf.add(new Txt('').pageBreak('after').end);
  }

  /**
   *
   */
  private addHorizontalLine () {
    this.pdf.add(new Table([
      [''],
      ['']
    ]).widths(['*']).height(5).headerRows(1).layout('headerLineOnly').alignment('center').end);
  }

  /**
   *
   */

  private async addHeaderPage(pageTitle: string) {
    this.pdf.add(new Table([
      [
        new Cell(new SVG(logoWithTextSVG)
          .width('40').end).end,

        new Cell(
          // await this.teamLogoResolver(this.globalData.home.logo, 'right', 50)
          this.teamHeaderLogoHome
          ).end,

        new Cell(new Txt(`Team\n${this.globalData.home.name}`)
          .style('headerTeamCell')
          .relativePosition(10, 15)
          .end).end,

        new Cell(
          new Txt([
            new Txt(`${pageTitle}\n`).style(['headerTeamCell', 'headerTeamCellSmall']).alignment('center').lineHeight(1.5).end,
            // new Txt(`VS\n`).style(['headerTeamCell']).alignment('center').lineHeight(1.5).end,
            new Txt(`${PDF_URL}`).style(['headerTeamCell', 'headerTeamCellSmall']).decoration('underline').alignment('center').lineHeight(1.5).end
          ]).relativePosition(0, 15).end
        ).end,

        new Cell(new Txt(`Team\n${this.globalData.visitor.name}`)
          .alignment('right')
          .style('headerTeamCell')
          .relativePosition(-10, 15)
          .end).end,

        // logo square team 2
        new Cell(
          // await this.teamLogoResolver(this.globalData.visitor.logo, 'left', 50)
          this.teamHeaderLogoVisitor
          ).end,

        new Cell(
          new Txt([
            new Txt(`${this.globalData.home.name}\n`).style(['headerTeamCell', 'headerTeamCellSmall']).alignment('right').lineHeight(1.5).end,
            new Txt(`${this.globalData.visitor.name}\n`).style(['headerTeamCell', 'headerTeamCellSmall']).alignment('right').lineHeight(1.5).end,
            new Txt(`${globalDataDate(this.globalData.date)}`).style(['headerTeamCell', 'headerTeamCellSmall']).alignment('right').lineHeight(1.5).end,
          ]).end
        ).end
      ]
    ]).layout('noBorders').widths(['15%', '10%', '20%', '10%', '20%', '10%', '15%']).end);

    this.addHorizontalLine();
  }

  private async addHeaderPageIconLegend(pageTitle: string) {
    this.pdf.add(new Table([

      [
        this.createCustomTable({
          rectType: 'rect-with-border',
          rectWidth: 824,
          rectColor: pdfColors.bgRectTableWhite,
          firstRectX: 0,
          firstRectY: 0,
          firstRectHeight: 60,

          firstRectColor: pdfColors.bgRectTableWhite,
          firstRectLineColor: pdfColors.bgRectTableWhite
        })
      ],
      [(await generatePlayerIconImageHelper()).fontSize(8).relativePosition(0, -55).style('noBorders').end],
      [(await generatePlayerIconLegendHelper()).relativePosition(0, -40).fontSize(8).style('noBorders').end]
    ]).layout('noBorders').widths(['*']).end);
    this.addHorizontalLine();
  }

  /**
   *
   */
   private async addFooterToPagePDF (pageNumber: number) {
    this.addHorizontalLine();
    this.pdf.add(new Txt('www.handball.ai - page ' + pageNumber).alignment('center').absolutePosition(0, 525).end);
  }


  /**
   *
   * @param headerTitles
   * @param teamNameBgColor
   * @param teamNameColor
   * @returns
   */

   private getArrayHeaderCellsFromStrings(headerTitles: Object[], teamNameBgColor: string, teamNameColor: string, headerCellClass?: string) {
    var cellsArray: Object[] = [];
    for (let i = 0; i < headerTitles.length; i++) {
      let cell = new Cell(new Txt('' + (headerTitles[i]['label'] ?? headerTitles[i])).end).fillColor(teamNameBgColor).color(teamNameColor).alignment('center').margin([3, 1]).border([false,false,false,false]).style(headerCellClass ? headerCellClass : '');
      if (headerTitles[i]['cspan']) cell = cell.colSpan(headerTitles[i]['cspan']);
      if (headerTitles[i]['bold']!==false) cell = cell.bold();
      // if (headerTitles[i].rspan) cell.rowSpan(headerTitles[i].rspan);
      cellsArray.push(cell.end);
    }
    return cellsArray;
  }

  /**
   *
   * @param cellRows
   * @param data
   * @param oddRowBgColor
   */
  private addCellRowsFromData(cellRows: Object[][], data: Object[][], oddRowBgColor: string, alignmentOverride: ('left' | 'center' | 'right')[] = [], cellModifiers: Object[][] = []) {
    for (let i = 0; i < data.length; i++) {
      let newLine = [];
      const bgColor = (i % 2 == 1) ? 'white' : oddRowBgColor;
      for (let j = 0; j < data[i].length; j++) {
        let alignment = alignmentOverride[j] ?? ((typeof data[i][j] == 'string') ? (((data[i][j] as string).includes('%')) ? 'center' : 'left') : 'center');
        let cellContent = new Cell(new Txt('' + data[i][j]).end).alignment(alignment);
        if (typeof data[i][j] == 'object') {
          if (data[i][j]) {
            cellContent = new Cell(new SVG(this.getSvgProgressBar(data[i][j]['value'], data[i][j]['rectWidth'], data[i][j]['rectHeight'], data[i][j]['percent'], data[i][j]['showArrow'], data[i][j]['percentColor'])).end);
          } else {
            cellContent = new Cell(new Txt('').end);
          }
        }

        cellContent = cellContent.margin([3, 1]).border([false]);

        if (cellModifiers[i] && cellModifiers[i][j]) {
          if (cellModifiers[i][j]?.['border']) {
            cellContent = cellContent.border(cellModifiers[i][j]['border']);
          }
          if (cellModifiers[i][j]?.['bold']) {
            cellContent = cellContent.bold();
          }
          if (cellModifiers[i][j]?.['bgColor']) {
            cellContent = cellContent.fillColor(cellModifiers[i][j]['bgColor']);
          } else {
            cellContent = cellContent.fillColor(bgColor);
          }
          if (cellModifiers[i][j]?.['color']) {
            cellContent = cellContent.color(cellModifiers[i][j]['color']);
          } else {
            cellContent = cellContent.color('black');
          }
        }
        newLine.push(cellContent.end);
      }
      cellRows.push(newLine);
    }
  }

  /**
   *
   */
  private async addOverviewTableHeadersOnly(
    team?: Team,
    teamNameBgColor?: string,
    teamNameColor?: string,
    oddRowBgColor?: string,
    tableType?: string
  ) {
    let widths = []
    let headerTitles: any = []

    switch (tableType) {
      case 'A':
        widths = [
          '13.45%', '9.64%', '9.64%', '9.64%', '9.43%',
          '9.64%', '9.64%', '9.64%', '9.64%', '9.63%'
        ];

        headerTitles = [
          `${team["name"]}`,
          toUpperCase(await this.tg(_('GOALS'), this.skippedLangs)),
          toUpperCase(await this.tg(_('FAILED SHOTS'), this.skippedLangs)),
          toUpperCase(await this.tg(_('SAVES'), this.skippedLangs)),
          toUpperCase(await this.tg(_('POSTS/OUT'), this.skippedLangs)).split('/').map(el => (el === '/' ? ` ${el} ` : el)).join(' / '),
          toUpperCase(await this.tg(_('LOST BALLS'), this.skippedLangs)),
          toUpperCase(await this.tg(_('POSESSIONS'), this.skippedLangs)),
          toUpperCase(removePercentge(await this.tg(_('% EFFICIENCY'), this.skippedLangs))),
          toUpperCase(removePercentge(await this.tg(_('% SHOOTING EFFIC.'), this.skippedLangs))),
          toUpperCase(removePercentge(await this.tg(_('% LOST BALLS EFF.'), this.skippedLangs)))
        ];
        break;

      case 'B1':
        widths = [
          '28%', '24%', '24%', '24%'
        ];

        headerTitles = [
          team["name"],
          toUpperCase(await this.tg(_('EQUALITY EFFIC.'), this.skippedLangs)),
          toUpperCase(await this.tg(_('SUPERIORITY EFFIC.'), this.skippedLangs)),
          toUpperCase(await this.tg(_('INFERIORITY EFFIC.'), this.skippedLangs)),
        ];
        break;

      case 'B2':
        widths = ['*', '15%', '15%', '15%'];

        headerTitles = [
          toUpperCase(await this.tg(_('GOALKEEPERS'), this.skippedLangs)),
          toUpperCase(await this.tg(_('RECEIVE GOALS'), this.skippedLangs)),
          toUpperCase(await this.tg(_('SAVES'), this.skippedLangs)),
          toUpperCase(await this.tg(_('% EFFIC.'), this.skippedLangs)),
        ];
        break;

      case 'B3':
        widths = ['35%', '15%', '35%', '15%'];

        headerTitles = [
          // TODO => add to dictinary 'QUICK TABLE STATS'
          toUpperCase('QUICK TABLE STATS'),
          toUpperCase('total'),
          toUpperCase(''),
          toUpperCase('total'),
        ];
        break;
      case 'B4':
        widths = this.gameType === 'COMPLETE_MODE' ? [
          '24%%', '4.83%', '6.83%', '6.83%', '6.83%',
          '6.83%', '6.83%', '6.83%', '6.83%',
          '4.83%', '4.83%',
          '6.83%', '6.83%',
        ]
          :
          [
            '27%%', '7.5%', '7.5%', '7.5%', '7.5%',
            '7.5%', '7.5%', '13.5%', '13.5%'
          ];

        const createIndividualPerformanceTableHeader = async (): Promise<any[]> => {
          return this.gameType === 'COMPLETE_MODE' ? [
            toUpperCase(await this.tg(_('PLAYER'), this.skippedLangs)),
            toUpperCase(await this.tg(_('TIME'), this.skippedLangs)),
            toUpperCase(await this.tg(_('PLAYED POSSESSIONS'), this.skippedLangs)),
            toUpperCase(await this.tg(_('POSSESIONS EVENTS'), this.skippedLangs)),
            toUpperCase(await this.tg(_('GOALS'), this.skippedLangs)),
            toUpperCase(await this.tg(_('FAILED SHOTS'), this.skippedLangs)),
            toUpperCase(await this.tg(_('SAVES'), this.skippedLangs)),
            toUpperCase(await this.tg(_('OUTS'), this.skippedLangs)),
            toUpperCase(await this.tg(_('LOST BALLS'), this.skippedLangs)),
            `${toUpperCase(await this.tg(_('% EFFIC.'), this.skippedLangs)).split('').filter(el => el !== '%').join('').trimStart()} %`,
            `${toUpperCase(await this.tg(_('% SHOOT'), this.skippedLangs)).split('').filter(el => el !== '%').join('').trimStart()} %`,
            `${toUpperCase(await this.tg(_('% LOST BALLS'), this.skippedLangs)).split('').filter(el => el !== '%').join('').trimStart()} %`,
            toUpperCase(await this.tg(_('PLAY / SCORE'), this.skippedLangs)),
          ] : [
            toUpperCase(await this.tg(_('PLAYER'), this.skippedLangs)),
            toUpperCase(await this.tg(_('POSSESIONS EVENTS'), this.skippedLangs)),
            toUpperCase(await this.tg(_('GOALS'), this.skippedLangs)).toUpperCase(),
            toUpperCase(await this.tg(_('FAILED SHOTS'), this.skippedLangs)),
            toUpperCase(await this.tg(_('SAVES'), this.skippedLangs)),
            toUpperCase(await this.tg(_('OUTS'), this.skippedLangs)),

            toUpperCase(await this.tg(_('LOST BALLS'), this.skippedLangs)),
            toUpperCase(await this.tg(_('% SHOOT'), this.skippedLangs)),
            toUpperCase(await this.tg(_('PLAY / SCORE'), this.skippedLangs)),
          ];
        };
        headerTitles = await createIndividualPerformanceTableHeader()
        break;

      case 'B5':
        widths = this.gameType === 'COMPLETE_MODE' ? [
          '23.4%%', '4.1%',
          '7.5%', '7.5%', '7.5%',
          '7.5%', '7.5%', '7.5%', '7.5%',
          '9.35%',
          '9.55%'
        ] : [
          '25.5%%', '8.5%',
          '8.5%', '7.5%', '7.5%',
          '7.5%', '7.5%', '7.5%',
          '9.35%',
          '9.55%'
        ];

        const createIndividualCounterTableHeader = async (): Promise<any[]> => {

          return this.gameType === 'COMPLETE_MODE' ? [
            toUpperCase(await this.tg(_('Player'), this.skippedLangs)),
            toUpperCase(await this.tg(_('Time'), this.skippedLangs)),
            toUpperCase(await this.tg(_('Attack Fault'), this.skippedLangs)),
            toUpperCase(await this.tg(_('Tech Mistake'), this.skippedLangs)),
            toUpperCase(await this.tg(_('Lost Ball'), this.skippedLangs)),
            toUpperCase(await this.tg(_('2 min'), this.skippedLangs)),
            toUpperCase(await this.tg(_('7 meters'), this.skippedLangs)),
            toUpperCase(await this.tg(_('7m + Susp'), this.skippedLangs)),
            toUpperCase(await this.tg(_('1&1'), this.skippedLangs)),
            toUpperCase(await this.tg(_('Foul'), this.skippedLangs)),
            toUpperCase(await this.tg(_('Shot'), this.skippedLangs))
          ] : [
            toUpperCase(await this.tg(_('Player'), this.skippedLangs)),
            toUpperCase(await this.tg(_('Attack Fault'), this.skippedLangs)),
            toUpperCase(await this.tg(_('Tech Mistake'), this.skippedLangs)),
            toUpperCase(await this.tg(_('Lost Ball'), this.skippedLangs)),
            toUpperCase(await this.tg(_('2 min'), this.skippedLangs)),
            toUpperCase(await this.tg(_('7 meters'), this.skippedLangs)),
            toUpperCase(await this.tg(_('7m + Susp'), this.skippedLangs)),
            toUpperCase(await this.tg(_('1&1'), this.skippedLangs)),
            toUpperCase(await this.tg(_('Foul'), this.skippedLangs)),
            // toUpperCase(await this.tg(_('Block'), this.skippedLangs)),
            toUpperCase(await this.tg(_('Shot'), this.skippedLangs))
          ];
        }
        headerTitles = await createIndividualCounterTableHeader()
        break;

      case 'B6':
        if (this._extraTimeOrPenaltiesPlayed) {
          widths = [
            '18%', '5.4%',
            '5.4%', '5.4%', '5.4%', '5.4%', '5.4%',
            '5.4%', '5.4%', '5.4%', '5.4%', '3.1%',
            '4.2%', '4.1%', '4.1%',
            '3.1%', '3.1%', '3.1%', '3.1%',
          ];
          headerTitles = playerTableColumnsWithETHelper.map(el => el.name)
        } else {
          widths = [
            '22.8%',
            '6.1%',
            '6.1%', '6.2%', '6.2%', '6.2%', '6.2%',
            '6.2%', '6.2%', '6.2%', '6.2%', '3.2%',
            '3%', '3.5%', '2.9%', '3%',
          ];
          headerTitles = playerTableColumnsHelper.map(el => el.name)
        }
        break;

      case 'B7':
        if (this._extraTimeOrPenaltiesPlayed) {
          widths = [
            '18%', '5.7%',
            '5.7%', '5.7%', '5.7%', '5.7%', '5.7%',
            '5.7%', '5.7%', '5.7%', '5.7%',
            '4.2%', '4.1%', '4.1%',
            '3.7%', '5%', '3.7%'
          ];

          headerTitles = goalKeeperTableColumnsWithETHelper.map(el => el.name)
        } else {
          widths = [
            '22.8%', '6.5%',
            '6.5%', '6.5%', '6.5%', '6.5%', '6.5%',
            '6.5%', '6.5%', '6.5%', '6.5%',
            '3.7%', '5%', '3.7%'
          ];

          headerTitles = goalKeeperTableColumnsHelper.map(el => el.name)
        }
        break;

      case 'B8':
        if (this._extraTimeOrPenaltiesPlayed) {
          widths = [
            '11.5%', '5.9%', '5.9%', '5.9%', '5.9%',
            '5.9%', '5.9%', '5.9%', '5.9%', '5.9%',
            '5.9%', '5.9%', '5.9%', '5.9%', '5.9%', '5.9%'
          ];

          headerTitles = [`${team["name"]}`, '5\'', '10\'', '15\'', '20\'', '25\'', '30\'', '35\'', '40\'', '45\'', '50\'', '55\'', '60\'', 'ET1', 'ET2', 'P'];
        } else {
          widths = [
            '29%', '5.9%', '5.9%', '5.9%', '5.9%',
            '5.9%', '5.9%', '5.9%', '5.9%', '5.9%',
            '5.9%', '5.9%', '5.9%'
          ];

          headerTitles = [`${team["name"]}`, '5\'', '10\'', '15\'', '20\'', '25\'', '30\'', '35\'', '40\'', '45\'', '50\'', '55\'', '60\''];
        }
        break;

      case 'B9_1':
      case 'B9_2':
        widths = [
          '20.0%', '4.0%', '2.46%',
          '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%',
          '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%',
          '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.46%', '2.4%'
        ];

        headerTitles = tableType === 'B9_1' ? [`Teams`, 'Mins:', '0',
          '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
          '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
          '21', '22', '23', '24', '25', '26', '27', '28', '29', '30']
          :
          [`Teams`, 'Mins:', '30',
            '31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
            '41', '42', '43', '44', '45', '46', '47', '48', '49', '50',
            '51', '52', '53', '54', '55', '56', '57', '58', '59', '60'];
        break;

      case 'B10':
        widths = [
          '20.8%', '10%', '8%', '8%', '10%',
          '10%', '11%', '11%', '11%'
        ];

        headerTitles = [
          'ACTION',
          'TIMES PLAYED',
          'N° GOALS',
          'N° SAVES',
          'N° POST / OUT',
          'N° 7 METERS',
          'GOALS %',
          'NO GOALS %',
          'GOALS 7 METERS %'].map(e => toUpperCase(e));
      default:
        break;
    }

    let cellrows: Object[][] = [
      this.getArrayHeaderCellsFromStrings(headerTitles, null, null, 'table__header__cell')
    ];

    let prov = toUpperCase(await this.tg(_('Prov'), this.skippedLangs));
    let com = toUpperCase(await this.tg(_('Com'), this.skippedLangs))
    let block = toUpperCase(await this.tg(_('Block'), this.skippedLangs))

    let subHeaderRow: Object[] = [];
    if (tableType === 'B5') {
      let subHeaderTitles;
      this.gameType === 'COMPLETE_MODE' ? [
        toUpperCase(await this.tg(_('Player'), this.skippedLangs)),
        toUpperCase(await this.tg(_('Time'), this.skippedLangs)),
        toUpperCase(await this.tg(_('Attack Fault'), this.skippedLangs)),
        toUpperCase(await this.tg(_('Tech Mistake'), this.skippedLangs)),
        toUpperCase(await this.tg(_('Lost Ball'), this.skippedLangs)),
        toUpperCase(await this.tg(_('2 min'), this.skippedLangs)),
        toUpperCase(await this.tg(_('7 meters'), this.skippedLangs)),
        toUpperCase(await this.tg(_('7m + Susp'), this.skippedLangs)),
        toUpperCase(await this.tg(_('1&1'), this.skippedLangs)),
        toUpperCase(await this.tg(_('Foul'), this.skippedLangs)),
        toUpperCase(await this.tg(_('Shot'), this.skippedLangs))
      ] : [
        toUpperCase(await this.tg(_('Player'), this.skippedLangs)),
        toUpperCase(await this.tg(_('Attack Fault'), this.skippedLangs)),
        toUpperCase(await this.tg(_('Tech Mistake'), this.skippedLangs)),
        toUpperCase(await this.tg(_('Lost Ball'), this.skippedLangs)),
        toUpperCase(await this.tg(_('2 min'), this.skippedLangs)),
        toUpperCase(await this.tg(_('7 meters'), this.skippedLangs)),
        toUpperCase(await this.tg(_('7m + Susp'), this.skippedLangs)),
        toUpperCase(await this.tg(_('1&1'), this.skippedLangs)),
        toUpperCase(await this.tg(_('Foul'), this.skippedLangs)),
        toUpperCase(await this.tg(_('Block'), this.skippedLangs)),
        toUpperCase(await this.tg(_('Shot'), this.skippedLangs))
      ];

        subHeaderTitles =  this.gameType === 'COMPLETE_MODE' ? [
              '', '',
              [`${prov}`, `${com}`],
              [`${prov}`, `${com}`],
              [`${prov}`, `${com}`],
              [`${prov}`, `${com}`],
              [`${prov}`, `${com}`],
              [`${prov}`, `${com}`],
              ['Lost', 'Won'],
              [`Receive`, `${com}`],
              [`${block}`, `Blocked`]
            ]
            :
            [
              '',
              [`${prov}`, `${com}`],
              [`${prov}`, `${com}`],
              [`${prov}`, `${com}`],
              [`${prov}`, `${com}`],
              [`${prov}`, `${com}`],
              [`${prov}`, `${com}`],
              ['Lost', 'Won'],
              [`Receive`, `${com}`],
              [`${block}`, `Blocked`]
            ];


      subHeaderTitles.forEach(subHeader => {
        let cell = {};

        if (Array.isArray(subHeader) ) {
          let arr = []
          subHeader.forEach((sub, idx) => {
            let v = idx === 0 ? new Cell(new Txt(`${toUpperCase(sub)}`).end)
            .border([false, false, true, false])
            .end
            :
            new Cell(new Txt(`${toUpperCase(sub)}`).end)
            .border([false, false, false, false])
            .end;

            arr.push(v);
          });


          cell = new Table([
            arr
          ])
          .margin([3,3,3,3])
          .layout({
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 'transparent' : '#F1F5F8';
            },
            vLineColor: function (i, node) {
              return '#F1F5F8'
            },

            hLineWidth: (rowIndex?: number, node?: any, columnIndex?: number) => {
              if (rowIndex === 0 || rowIndex === node.table.body.length) {
                return 0;
              }
              return 2;
            },
            vLineWidth: (rowIndex?: number, node?: any, columnIndex?: number) => {
              return (rowIndex === 0 || rowIndex === node.table.widths.length) ? 0 : 2;
            },
          })
          .margin([0, 0, 0, 0])
          .widths(['50%', '50%']).end
        } else {
          cell = new Cell(new Txt(`${subHeader}`).end)
          .border([false])
          .end
        }
        subHeaderRow.push(cell)
      });
      cellrows.push(subHeaderRow)
    }

    cellrows.forEach(
      cellRow => {
        cellRow.forEach(cell => {
          switch (tableType) {
            case 'A':
              cell['style'] = ''
              cell['style'] = 'header__cell_a'
              break;
            case 'B1':
              cell['style'] = ''
              cell['style'] = 'header__cell_b1'
              break;
            case 'B2':
              cell['style'] = ''
              cell['style'] = 'header__cell_b2'
              break;
            case 'B3':
              cell['style'] = ''
              cell['style'] = 'header__cell_b3'
              break;
            case 'B4':
              cell['style'] = ''
              cell['style'] = 'header__cell_b4'
              break;
            case 'B5':
              cell['style'] = ''
              cell['style'] = 'header__cell_b5'
              break;
            case 'B6':
              cell['style'] = ''
              cell['style'] = 'header__cell_b6'
              break;
            case 'B7':
              cell['style'] = ''
              cell['style'] = 'header__cell_b7'
              break;
            case 'B8':
              cell['style'] = ''
              cell['style'] = 'header__cell_b8'
              break;
            case 'B9_1':
            case 'B9_2':
              cell['style'] = ''
              cell['style'] = 'header__cell_b9'
              cell['margin'] = [0, 0, 0, 0]
              break;
            case 'B10':
              cell['style'] = ''
              cell['style'] = 'header__cell_b10'
              cell['margin'] = [0, 0, 0, 0]
              break;
            default:
              break;
          }
        })
      }
    );

    cellrows[0][0]['alignment'] = 'left';
    cellrows[0][0]['margin'] = [10, 0, 0, 0];

    switch(tableType) {
      case 'A':
        this.pdf.add(new Table(cellrows).layout('noBorders').headerRows(1)
          .relativePosition(0, -20)
          .widths(widths)
          .margin([0, 0, 0, 0]).end);
        break;
      case 'B1':
      case 'B2':
      case 'B3':
      case 'B4':
      case 'B6':
      case 'B7':
      case 'B8':
      case 'B9_1':
      case 'B9_2':
      case 'B10':
        return new Table(cellrows).headerRows(1)
        .widths(widths).margin(0).end;

      case 'B5':
        return new Table(cellrows).headerRows(1)
        .layout({
          vLineColor: (rowIndex?: number, node?: any, columnIndex?: number) => {
            // return (i === 0 || i === node.table.body.length) ? 'red' : 'blue';
            return (rowIndex === 0 || rowIndex === node.table.widths.length) ? 'red' : '#F1F5F8';
          },

          hLineColor: (rowIndex?: number, node?: any, columnIndex?: number) => {
            // return (i === 0 || i === node.table.body.length) ? 'red' : 'blue';
            return (rowIndex === 0 || rowIndex === node.table.widths.length) ? 'red' : '#F1F5F8';
          },

          hLineWidth: (i: any, node: any) => {
            if (i === 0 || i === node.table.body.length) {
              return 0;
            }
            return 2;
          },
          vLineWidth: (i: any, node: any) => {
            return (i === 0 || i === node.table.widths.length) ? 0 : 2;
          },
        })
        .widths(widths).margin(0).end;

      default:
         break;
    }
  }

  private async addOverviewTable(
    team: Team,
    teamNameBgColor: string,
    teamNameColor: string,
    oddRowBgColor: string,
) {
  let cellrows: Object[][] = [];
  let sourceData = team.overview.overviewTable;
  let rowNames: string[] = [
    `${await this.tg(_('Offense Possitional'), this.skippedLangs)} %` ,
    `${await this.tg(_('Counter Goal'), this.skippedLangs)} %`,
     `${await this.tg(_('Fastbreak'), this.skippedLangs)} %`,
  ];
  let dataIndividualName: string[] = ['goals', 'failedShots', 'saves', 'postOut', 'lostBalls', 'posessions', 'efficiency', 'shootingEfficiency', 'lostBallsEfficiency'];
  let dataRows: Object[][] = [];

  for (let i = 0; i < sourceData.length; i++) {
    let newDataRow = [rowNames[i]];
    for (let j = 0; j < dataIndividualName.length; j++) {
      let value = sourceData[i][dataIndividualName[j]];
      if (dataIndividualName[j].includes('fficiency')) value += ' %';
      newDataRow.push(value);
    }
    dataRows.push(newDataRow);
  }

  dataRows.forEach(dataRow => {
    let cellRow = []
    dataRow.forEach(
      (el, idx) => {
        let cellContent = new Cell(new Txt(`${el}`).end)
        .alignment(idx === 0 ? 'left' : 'center')
        .margin((idx === 0 ? [5, 0, 0, 0] : 0))
        .end;
        cellRow.push(cellContent)
      }
    );
    cellrows.push(cellRow)
  })

  // total
  let counters = sourceData.reduce((counters, row) => {
    counters.goals += row.goals;
    counters.failedShots += row.failedShots;
    counters.saves += row.saves;
    counters.postOut += row.postOut;
    counters.lostBalls += row.lostBalls;
    counters.posessions += row.posessions;
    return counters;
  }, { goals: 0, failedShots: 0, saves: 0, postOut: 0, lostBalls: 0, posessions: 0 });
  let totals = ['', counters.goals, counters.failedShots, counters.saves, counters.postOut, counters.lostBalls, team.overview.summaryPossessions, '', '', ''];

  let totalRaw: Object[] = []
  totals.forEach(
    (el, idx) => {
      let cellContent = new Cell(new Txt(`${idx === 0 ? 'Total' : el}`).end)
      .alignment(idx === 0 ? 'left' : 'center')
      .color('#FFFFFF')
      .margin((idx === 0 ? [5,4,0,0] : [0,4,0,0]))
      .end;
      totalRaw.push(cellContent);
    }
  );
  cellrows.push(totalRaw);

  cellrows.forEach(cell => {
    cell.forEach((c, idx) => {
      c['fontSize'] = 8
      c['style'] = 'table__body__cell'
    })
  })

  this.pdf.add(new Table(cellrows).headerRows(1)
    .widths([
      '13.45%', '9.64%', '9.64%', '9.64%', '9.43%',
      '9.64%', '9.64%', '9.64%','9.64%', '9.63%'
    ])
    .layout(layoutTableBodyGrid)
    .relativePosition(0, -61)
    .margin([0, 0, 0, 10]).end);
}

  /**
   *
   */

   private async getKeyValueTableCombained(data: KeyVal[], oddRowBgColor: string) {
    data.forEach(e => {
      e.key = toUpperCase(e.key)
    });

    let x = data.filter(e => e.value !== null).slice(0,7)
    let y = data.filter(e => e.value !== null).slice(7)

    let dataRows = []

    x.forEach((el, i) => {
      let arr2 = []
      if (y[i]) {
        arr2.push(y[i]['key'], y[i]['value'])
      } else {
        arr2.push('', '')
      }

      dataRows.push([el.key, el.value, ...arr2])
    });

    let cellrows: Object[][] = [];
    cellrows = await this.dataRowsResolver(dataRows, {quickTabelStats: true});

    return new Table(cellrows).layout({
      defaultBorder: false
    }).headerRows(0).margin(0)
    .layout(layoutTableBodyGrid)
    .widths(['35%', '15%', '35%', '15%'])
    .end;
  }

  /**
  *
  */

  private async addOverviewQuickTableAndShooting(team: Team, canvas: ElementRef<HTMLCanvasElement>, teamNameBgColor: string, teamNameColor: string, oddRowBgColor: string) {
    this.pdf.add(new Table([
      [
        new Table([
          [
            this.createCustomTable({
              rectType: 'header',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableLightBlue,
              firstRectHeight: 28,
              secondRectHeight: 18
            })
          ],
          [
            new Cell(await this.addOverviewTableHeadersOnly(team, teamNameBgColor, teamNameColor, oddRowBgColor, 'B3'))
              .margin([0, 0, 0, 0])
              .relativePosition(0, -25)
              .end
          ],
          [
            this.createCustomTable({
              rectType: 'body',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableWhite,
              firstRectHeight: 80
            })
          ],
          [
            this.createCustomTable({
              rectType: 'footer',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableWhite,
              firstRectHeight: 25,
              secondRectHeight: 15
            })
          ],
          [
            new Cell(
              await this.getKeyValueTableCombained(
                [
                  ...team['overview']['quickTable']['left'],
                  ...team['overview']['quickTable']['right']
                ], oddRowBgColor)
            ).margin([0, 0, 0, 0]).relativePosition(0, -100).end,
          ],
        ])
          .layout({
            defaultBorder: false,
            paddingLeft: function (i, node) { return 0; },
            paddingRight: function (i, node) { return 0; },
            paddingTop: function (i, node) { return 0; },
            paddingBottom: function (i, node) { return 0; }
          })
          .margin([0, 0, 0, 0])
          .widths('*')
          .end,

        new Table([
          [
            this.createCustomTable({
              rectType: 'header',
              rectWidth: 465,
              rectColor: pdfColors.bgRectTableWhite,
              firstRectHeight: 134,
              secondRectHeight: 18
            })
          ],
          [
            new Txt('Connection & Goals').color('red')
              .fontSize(7)
              .color('#1A375C')
              .relativePosition(125, -135)
              .end
          ],
          [
            new Txt('Shooting Location').color('red')
              .fontSize(7)
              .color('#1A375C')
              .relativePosition(290, -138)
              .end
          ],
          [
            new Table([
              [new Cell(
                new SVG(this.core.getShootingFieldImg(team.overview.connectionGoals, '#0E375F', 'white')).width(120).end).end],
            ]).margin([0, 0, 0, 0]).layout('noBorders').widths(['*'])
              .relativePosition(100, -133)
              .end,
          ],
          [
            new Table([
              [new Cell(new SVG(this.core.getShootingFieldImg({
                goals: [
                  { position: '7MetersLabel', weight: 0, label: '7 meters' },
                  { position: 'OppositeFieldLabel', weight: 0, label: 'Opposite Field' },
                  ...team.overview.shootingLocation
                ], connections: []
              }, '#0E375F', 'white')).width(120).end).end],
            ])
              .relativePosition(260, -138)
              .margin([0, 0, 0, 0]).layout('noBorders')
              .widths('*')
              .end,
          ]
        ])
          .margin([0, 0, 0, 0]).layout('noBorders').widths(['*']).end,
      ]
    ])
      .layout({ defaultBorder: false }).headerRows(1).margin([0, 0, 0, 10]).widths(['42%', '*']).end);
  }

  /**
  *
  */
  private async addThreeColumnOverviewTable(team: Team, canvas: ElementRef<HTMLCanvasElement>, teamNameBgColor: string, teamNameColor: string, oddRowBgColor: string) {
    const saves = team.overview.goalkeeperSummary.saves;
    const goals = team.overview.goalkeeperSummary.goals;

    const savesPercent = Math.round(saves / ((saves + goals)/100))
    const goalsPercent = Math.round(goals / ((saves + goals)/100))

    let goalkeeperDetailsTable = await this.getGoalkeeperDetailTable(team, teamNameBgColor, teamNameColor, oddRowBgColor);
    let goalkeeperDetailsCongfig = {
      goalkeeperDetailsTable,
      tBodyFirstRectHeight: 0,
      tFooterFirstRectHeight: 20,
      tFooterSecondRectHeight: 10
    }

    goalkeeperDetailsTable.table.body.forEach((row, idx) => {
      if (idx !== 0 ) {
        goalkeeperDetailsCongfig.tBodyFirstRectHeight += 20
      }
    })

    this.pdf.add(
      new Table([
      [
        // column I
        new Table([
          [
            this.createCustomTable({
              rectType: 'header',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableLightBlue,
              firstRectHeight: 28,
              secondRectHeight: 18
            })
          ],
          [
            new Cell(await this.addOverviewTableHeadersOnly(team, teamNameBgColor, teamNameColor, oddRowBgColor, 'B1'))
            .margin([0,0,0,0]).relativePosition(0, -28).end
          ],
          [
            this.createCustomTable({
              rectType: 'body',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableWhite,
              firstRectHeight: 28
            })
          ],
          [
            this.createCustomTable({
              rectType: 'footer',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableWhite,
              firstRectHeight: 15,
              secondRectHeight: 5
            })
          ],
          [
            new Cell(
              await this.getOverviewEfficiencySummaryTable(team, teamNameBgColor, teamNameColor, oddRowBgColor, 'B1')
            ).margin([0, 0, 0, 0]).relativePosition(0, -42).end,
          ],
          [
            await this.createCustomTable({
              rectType: 'header',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableLightBlue,
              firstRectHeight: 28,
              secondRectHeight: 18
            })
          ],
          [
            new Cell(await this.addOverviewTableHeadersOnly(team, teamNameBgColor, teamNameColor, oddRowBgColor, 'B1'))
            .margin([0,0,0,0]).relativePosition(0, -28).end
          ],
          [
            await this.createCustomTable({
              rectType: 'body',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableWhite,
              firstRectHeight: 55
            })
          ],
          [
            await this.createCustomTable({
              rectType: 'footer',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableWhite,
              firstRectHeight: 15,
              secondRectHeight: 5
            })
          ],
          [
            new Cell(
              await this.getOverviewEfficienbyDetailTable(team, teamNameBgColor, teamNameColor, oddRowBgColor)
            ).margin([0, 0, 0, 0]).relativePosition(0, -70).end,
          ],
        ])
        .layout({
          defaultBorder: false,
          paddingLeft: function(i, node) { return 0; },
          paddingRight: function(i, node) { return 0; },
          paddingTop: function(i, node) { return 0; },
          paddingBottom: function(i, node) { return 0; }
        })
        .margin([0, 0, 0, 0])
        .widths('*').end,

        // column II goals/saves chart
        new Table([
          [
            await this.createCustomTable({
              rectType: 'header',
              rectWidth: 130,
              rectColor: pdfColors.bgRectTableWhite,
              firstRectHeight: 185,
              secondRectHeight: 18
            })
          ],
          [
            new Table([
              [
                new Cell(
                  new Txt(`${toUpperCase( await this.tg(_('GOALKEEPERS'), this.skippedLangs))}`).margin(2).alignment('center').style('goalkeepers_labels_save_goal_chart').end
                ).end
              ],
              [
                new Cell(
                  new Txt(`Saves / Goals`).alignment('center').style('goalkeepers_labels_save_goal_chart').end
                ).end
              ],
              [
                await new Img(canvas.nativeElement.toDataURL()).width(100).alignment('center').margin([0,2,0,2]).build(),

              ],
              [
                new Cell(
                  new Txt(`Saves ${savesPercent} %`).margin(2).alignment('center').style('goalkeepers_labels_save_goal_chart').end
                ).end
              ],
              [
                new Cell(
                  new Txt(`Goals ${goalsPercent} %`).margin(2).alignment('center').style('goalkeepers_labels_save_goal_chart').end
                ).end
              ]
            ]).layout({
              defaultBorder: false,
              paddingLeft: function(i, node) { return 0; },
              paddingRight: function(i, node) { return 0; },
              paddingTop: function(i, node) { return 0; },
              paddingBottom: function(i, node) { return 0; }
            })
            .relativePosition(0, -180)
            .margin([0, 0, 0, 0])
            .widths('*').end,
          ]

        ])
        .layout({
          defaultBorder: false,
          paddingLeft: function(i, node) { return 0; },
          paddingRight: function(i, node) { return 0; },
          paddingTop: function(i, node) { return 0; },
          paddingBottom: function(i, node) { return 0; }
        })
        .margin([0, 0, 0, 0])
        .widths('*').end,

        // column III
        new Table([
          [
            this.createCustomTable({
              rectType: 'header',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableLightBlue,
              firstRectHeight: 28,
              secondRectHeight: 18
            })
          ],
          [
            new Cell(await this.addOverviewTableHeadersOnly(team, teamNameBgColor, teamNameColor, oddRowBgColor, 'B2'))
            .margin([0,0,0,0]).relativePosition(0, -28).end
          ],
          [
            this.createCustomTable({
              rectType: 'body',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableWhite,
              firstRectHeight: 1
            })
          ],
          [
            this.createCustomTable({
              rectType: 'footer',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableWhite,
              firstRectHeight: 15,
              secondRectHeight: 5
            })
          ],
          [
            new Cell(
              await this.getGoalkeepersSummaryTable(team, teamNameBgColor, teamNameColor, oddRowBgColor)
            ).margin([0, 0, 0, 0]).relativePosition(0, -15).end,
          ],

          // second tabel right side
          [
            this.createCustomTable({
              rectType: 'header',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableLightBlue,
              firstRectHeight: 28,
              secondRectHeight: 18
            })
          ],
          [
            new Cell(await this.addOverviewTableHeadersOnly(team, teamNameBgColor, teamNameColor, oddRowBgColor, 'B2'))
            .margin([0,0,0,0]).relativePosition(0, -28).end
          ],
          [
            this.createCustomTable({
              rectType: 'body',
              rectWidth: 330,
              rectColor: pdfColors.bgRectTableWhite,
              // rectColor: 'red',

              firstRectHeight: goalkeeperDetailsCongfig.tBodyFirstRectHeight
            })
          ],
          [
            this.createCustomTable({
              rectType: 'footer',
              rectWidth: 330,
              rectColor: team === this.globalData.home ? pdfColors.bgRectTableBlue : pdfColors.bgRectTableDarktBlue,
              firstRectHeight: 20,
              secondRectHeight: 10
            })
          ],
          [
            new Cell(
              goalkeeperDetailsCongfig.goalkeeperDetailsTable,
            ).margin([0, 0, 0, 0]).relativePosition(
              0,
              -(goalkeeperDetailsCongfig.tBodyFirstRectHeight + 20)).end
          ],
        ])
        .layout({
          defaultBorder: false,
          paddingLeft: function(i, node) { return 0; },
          paddingRight: function(i, node) { return 0; },
          paddingTop: function(i, node) { return 0; },
          paddingBottom: function(i, node) { return 0; }
        })
        .margin([0, 0, 0, 0])
        .widths('*').end,
      ]
    ])
    .layout({defaultBorder: false})
    .headerRows(1)
    .margin([0, 0, 0, 0]).widths(['42%', '16%', '42%']).end);
  }

  /**
  *
  */
  private async getOverviewEfficiencySummaryTable(team: Team, teamNameBgColor: string, teamNameColor: string, oddRowBgColor: string, tableType?: string) {

    let widths = [];

    widths = [
      '28%', '24%', '24%', '24%'
    ];

    let cellrows: Object[][] = [];

    let sourceData: Object[] = team["overview"]["efficiencySummaryTable"];
    let rowNames: string[] = [
      await this.tg(_('Offense Possitional'), this.skippedLangs),
      await this.tg(_('Counter Goal'), this.skippedLangs),
      await this.tg(_('Fastbreak'), this.skippedLangs),
    ];
    let dataIndividualName: string[] = ['equalityEfficiencySummary', 'superiorityEfficiencySummary', 'inferiorityEfficiencySummary'];
    let dataRows: Object[][] = [];
    for (let i = 0; i < sourceData.length; i++) {
      let newDataRow = [rowNames[i]];
      for (let j = 0; j < dataIndividualName.length; j++) {
        let value = sourceData[i][dataIndividualName[j]];
        if (dataIndividualName[j].includes('fficiency')) value += ' %';
        newDataRow.push(value);
      }
      dataRows.push(newDataRow);
    }

    cellrows = await this.dataRowsResolver(dataRows);

    return new Table(cellrows).layout(layoutTableBodyGrid)
    .headerRows(1)
    .widths(widths)
    .end;
  }

  /**
   *
   */
private async getOverviewEfficienbyDetailTable(team: Team, teamNameBgColor: string, teamNameColor: string, oddRowBgColor: string) {
    let cellrows: Object[][] = [];

    let sourceData: Object[] = team["overview"]["efficiencyDetailTable"];
    let dataIndividualName: string[] = ['defenseSystem', 'equalityEff', 'superiorityEff', 'inferiorityEff'];
    let dataRows: Object[][] = [];
    for (let i = 0; i < sourceData.length; i++) {
      let newDataRow = [];
      for (let j = 0; j < dataIndividualName.length; j++) {
        let value = sourceData[i][dataIndividualName[j]];
        if (dataIndividualName[j].includes('Eff')) value += ' %';
        newDataRow.push(value);
      }
      dataRows.push(newDataRow);
    }

    cellrows = await this.dataRowsResolver(dataRows);

    let widths = [];
    widths = [
      '28%', '24%', '24%', '24%'
    ];

    // this.addCellRowsFromData(cellrows, dataRows, oddRowBgColor);
    return new Table(cellrows)
    .layout(layoutTableBodyGrid)
    .headerRows(1)
    .widths(widths)
    .end;
  }

  /**
   *
   */
  private async getGoalkeepersSummaryTable(team: Team, teamNameBgColor: string, teamNameColor: string, oddRowBgColor: string) {
    let cellrows: Object[][] = [];

    let sourceData: GoalkeeperSummary = team.overview.goalkeeperSummary;
    let goals = sourceData.goals;
    let saves = sourceData.saves;
    let effic = (goals+saves!=0) ? parseFloat((saves / (goals+saves)*100).toFixed(0)) : 0;
    let dataRows: Object[][] = [[team['name'], goals, saves, effic+' %']];

    cellrows = await this.dataRowsResolver(dataRows)

    return new Table(cellrows).fontSize(this.fontSize).layout('noBorders')
    .headerRows(1).layout(layoutTableBodyGrid)
    // .widths(['*', '20%', '20%', '20%'])
    .widths(['*', '15%', '15%', '15%'])
    .end;
  }

  private async getGoalkeeperDetailTable(team: Team, teamNameBgColor: string, teamNameColor: string, oddRowBgColor: string) {
    let cellrows: Object[][] = [];

    let sourceData: GoalkeepersDetailTableRow[] = team.overview.goalkeepersDetailTable;

    let dataRows: Object[][] = [];
    let sumGoals = 0;
    let sumSaves = 0;

    for (let i = 0; i < sourceData.length; i++) {
      let player = sourceData[i]['backNumber'] + ' - ' + sourceData[i]['name'];
      let goals = sourceData[i]['goals'];
      sumGoals += goals;
      let saves = sourceData[i]['saves'];
      sumSaves += saves;
      let effic = ((goals+saves)!=0) ? parseFloat((saves / (goals+saves)*100).toFixed(0)) : 0;
      dataRows.push([player, goals, saves, effic+' %']);
    };

    cellrows = await this.dataRowsResolver(dataRows, {
      getGoalkeeperDetailTable: true,
      sourceData
    });

    const totals = ['', sumGoals, sumSaves, (((sumGoals+sumSaves)!=0) ? parseFloat((sumSaves / (sumGoals+sumSaves)*100).toFixed(0)):0)+' %'];

    let totalRaw: Object[] = []
    totals.forEach(
      (el, idx) => {
        let cellContent = new Cell(new Txt(`${idx === 0 ? 'Total' : el}`).end)
        .alignment(idx === 0 ? 'left' : 'center')
        .color('#FFFFFF')
        .margin((idx === 0 ? [5, 5, 0, 0] : [0, 5, 0, 0]))
        .end;
        totalRaw.push(cellContent);
      }
    );
    cellrows.push(totalRaw);
    cellrows.forEach(cell => {
      cell.forEach((c, idx) => {
        c['style'] = 'table__body__cell'
      })
    });

    return new Table(cellrows).layout(layoutTableBodyGrid)
    .headerRows(1)
    .widths(['*', '15%', '15%', '15%'])
    .heights((row: number) => {
      return 10
    }).end;
  }

  private async addIndividualPerformanceTeamTable(team: Team, teamNameBgColor: string, teamNameColor: string, oddRowBgColor: string) {
    this.pdf.add(
      new Table([
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'rect-with-border',
                rectWidth: 297.7,
                rectColor: pdfColors.bgRectTableWhite,
                firstRectX: 0,
                firstRectY: 0,
                firstRectHeight: 25,
                firstRectColor: team === this.globalData.home ? pdfColors.bgRectTableBlue : pdfColors.bgRectTableDarktBlue,
                // firstRectColor: pdfColors.bgRectTableBlue,
                firstRectLineColor: pdfColors.bgRectTableWhite
              })
            ],
            [
              new Cell(
                new Txt(`${team['name']}`).fontSize(11).color('#FFFFFF').end
              )
            .margin([0,0,0,0]).relativePosition(0, -20).end
            ]
          ])
          .layout({
            defaultBorder: false,
            paddingLeft: function(i, node) { return 0; },
            paddingRight: function(i, node) { return 0; },
            paddingTop: function(i, node) { return 0; },
            paddingBottom: function(i, node) { return 0; }
          })
          .alignment('center')
          .margin([0, 0, 0, 0])
          .widths('*').end
        ],

      ])
      .layout({
        defaultBorder: false
      })
      .widths(['100%']).end
    );

    this.pdf.add(new Table([
        [
          await this.getIndividualPerformanceTable(team, teamNameBgColor, teamNameColor, oddRowBgColor),
        ]
      ])
      .relativePosition(0, 0)
      .layout({
        defaultBorder: false
      })
      .widths(['100%']).end
    );
  }

  private async addIndividualCountersTeamTable(team: Team, teamNameBgColor: string, teamNameColor: string, oddRowBgColor: string) {
    this.pdf.add(
      new Table([
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'rect-with-border',
                rectWidth: 297.7,
                rectColor: pdfColors.bgRectTableWhite,
                firstRectX: 0,
                firstRectY: 0,
                firstRectHeight: 25,
                firstRectColor: team === this.globalData.home ? pdfColors.bgRectTableBlue : pdfColors.bgRectTableDarktBlue,
                firstRectLineColor: pdfColors.bgRectTableWhite
              })
            ],
            [
              new Cell(
                new Txt(`${team['name']}`).fontSize(11).color('#FFFFFF').end
              )
            .margin([0,0,0,0]).relativePosition(0, -20).end
            ]
          ])
          .layout({
            defaultBorder: false,
            paddingLeft: function(i, node) { return 0; },
            paddingRight: function(i, node) { return 0; },
            paddingTop: function(i, node) { return 0; },
            paddingBottom: function(i, node) { return 0; }
          })
          .alignment('center')
          .margin([0, 0, 0, 0])
          .widths('*').end
        ],

      ])
      .layout({
        defaultBorder: false
      })
      .widths(['100%']).end
    );

    this.pdf.add(new Table([
      [
        await this.getIndividualCountersTable(team, teamNameBgColor, teamNameColor, oddRowBgColor),

      ]
    ])
    .relativePosition(0, 0)
    .layout({
      defaultBorder: false
    })
    .widths(['100%']).end
  );
  }

  /**
   *
   * @param team
   * @param teamNameBgColor
   * @param teamNameColor
   * @param oddRowBgColor
   */

  private async getIndividualCountersTable(team: Team, teamNameBgColor: string, teamNameColor: string, oddRowBgColor: string) {

    let headerTitles = []
    let cellrows: Object[][] = []

    this.pdf.add(new Table([
          [
            new Table([
              [
                this.createCustomTable({
                  rectType: 'header',
                  rectWidth: 818,
                  rectColor: pdfColors.bgRectTableLightBlue,
                  firstRectHeight: 35,
                  secondRectHeight: 25
                })
              ],
              [
                new Cell(
                    await this.addOverviewTableHeadersOnly(team, teamNameBgColor, teamNameColor, oddRowBgColor, 'B5'))
                    .margin([0, 0, 0, 0])
                    .relativePosition(0, -33)
                    .end
              ]
            ])
                .layout({
                  defaultBorder: false,
                  paddingLeft: function(i, node) {
                    return 0;
                  },
                  paddingRight: function(i, node) {
                    return 0;
                  },
                  paddingTop: function(i, node) {
                    return 0;
                  },
                  paddingBottom: function(i, node) {
                    return 0;
                  }
                })
                .margin([0, 0, 0, 0])
                .widths('*')
                .end,
          ]
        ]).layout({
          defaultBorder: false,
          paddingLeft: function(i, node) {
            return 0;
          },
          paddingRight: function(i, node) {
            return 0;
          },
          paddingTop: function(i, node) {
            return 0;
          },
          paddingBottom: function(i, node) {
            return 0;
          }
        })
            .margin([0, 0, 0, 0]).headerRows(1).margin([0, 0, 0, 0]).widths(['*']).end
    );

    let sourceData: IndividualPerformanceTableRow[] = team.individualPerformanceTable;
    let dataRows: Object[][] = [];
    let dataModifiers: Object[][] = [];

    let sumAttackFaultProvoke = 0, sumAttackFaultCommit = 0, sumTechnicalMistakeProvoke = 0, sumTechnicalMistakeCommit = 0,
        sumLostBallProvoke = 0, sumLostBall = 0, sumTwoMinProvoke = 0, sumTwoMinCommit = 0, sumSevenMetersProvoke = 0,
        sumSevenMetersCommit = 0, sumSevenMSuspProvoke = 0, sumSevenMSuspCommit = 0, sumOneAndOneLost = 0, sumOneAndOneWon = 0,
        sumFoulReceive = 0, sumFoulCommit = 0, sumShotBlock = 0, sumShotBlocked = 0,
        sumGoals = 0;
    let maxAttackFaultProvoke = 0, maxAttackFaultCommit = 0, maxTechnicalMistakeProvoke = 0, maxTechnicalMistakeCommit = 0,
        maxLostBallProvoke = 0, maxLostBall = 0, maxTwoMinProvoke = 0, maxTwoMinCommit = 0, maxSevenMetersProvoke = 0,
        maxSevenMetersCommit = 0, maxSevenMSuspProvoke = 0, maxSevenMSuspCommit = 0, maxOneAndOneLost = 0, maxOneAndOneWon = 0,
        maxFoulReceive = 0, maxFoulCommit = 0, maxShotBlock = 0, maxShotBlocked = 0;

    for (let i = 0; i < sourceData.length; i++) {
      sumAttackFaultProvoke += sourceData[i].attackFaultProvoke;
      maxAttackFaultProvoke = (maxAttackFaultProvoke > sourceData[i].attackFaultProvoke) ? maxAttackFaultProvoke : sourceData[i].attackFaultProvoke;
      sumAttackFaultCommit += sourceData[i].attackFaultCommit;
      maxAttackFaultCommit = (maxAttackFaultCommit > sourceData[i].attackFaultCommit) ? maxAttackFaultCommit : sourceData[i].attackFaultCommit;
      sumTechnicalMistakeProvoke += sourceData[i].technicalMistakeProvoke;
      maxTechnicalMistakeProvoke = (maxTechnicalMistakeProvoke > sourceData[i].technicalMistakeProvoke) ? maxTechnicalMistakeProvoke : sourceData[i].technicalMistakeProvoke;
      sumTechnicalMistakeCommit += sourceData[i].technicalMistakeCommit;
      maxTechnicalMistakeCommit = (maxTechnicalMistakeCommit > sourceData[i].technicalMistakeCommit) ? maxTechnicalMistakeCommit : sourceData[i].technicalMistakeCommit;
      sumLostBallProvoke += sourceData[i].lostBallProvoke;
      maxLostBallProvoke = (maxLostBallProvoke > sourceData[i].lostBallProvoke) ? maxLostBallProvoke : sourceData[i].lostBallProvoke;
      sumLostBall += sourceData[i].lostBall;
      maxLostBall = (maxLostBall > sourceData[i].lostBall) ? maxLostBall : sourceData[i].lostBall;
      sumTwoMinProvoke += sourceData[i].twoMinProvoke;
      maxTwoMinProvoke = (maxTwoMinProvoke > sourceData[i].twoMinProvoke) ? maxTwoMinProvoke : sourceData[i].twoMinProvoke;
      sumTwoMinCommit += sourceData[i].twoMinCommit;
      maxTwoMinCommit = (maxTwoMinCommit > sourceData[i].twoMinCommit) ? maxTwoMinCommit : sourceData[i].twoMinCommit;
      sumSevenMetersProvoke += sourceData[i].sevenMetersProvoke;
      maxSevenMetersProvoke = (maxSevenMetersProvoke > sourceData[i].sevenMetersProvoke) ? maxSevenMetersProvoke : sourceData[i].sevenMetersProvoke;
      sumSevenMetersCommit += sourceData[i].sevenMetersCommit;
      maxSevenMetersCommit = (maxSevenMetersCommit > sourceData[i].sevenMetersCommit) ? maxSevenMetersCommit : sourceData[i].sevenMetersCommit;
      sumSevenMSuspProvoke += sourceData[i].sevenMSuspProvoke;
      maxSevenMSuspProvoke = (maxSevenMSuspProvoke > sourceData[i].sevenMSuspProvoke) ? maxSevenMSuspProvoke : sourceData[i].sevenMSuspProvoke;
      sumSevenMSuspCommit += sourceData[i].sevenMSuspCommit;
      maxSevenMSuspCommit = (maxSevenMSuspCommit > sourceData[i].sevenMSuspCommit) ? maxSevenMSuspCommit : sourceData[i].sevenMSuspCommit;
      sumOneAndOneLost += sourceData[i].oneAndOneLost;
      maxOneAndOneLost = (maxOneAndOneLost > sourceData[i].oneAndOneLost) ? maxOneAndOneLost : sourceData[i].oneAndOneLost;
      sumOneAndOneWon += sourceData[i].oneAndOneWon;
      maxOneAndOneWon = (maxOneAndOneWon > sourceData[i].oneAndOneWon) ? maxOneAndOneWon : sourceData[i].oneAndOneWon;
      sumFoulReceive += sourceData[i].foulReceive;
      maxFoulReceive = (maxFoulReceive > sourceData[i].foulReceive) ? maxFoulReceive : sourceData[i].foulReceive;
      sumFoulCommit += sourceData[i].foulCommit;
      maxFoulCommit = (maxFoulCommit > sourceData[i].foulCommit) ? maxFoulCommit : sourceData[i].foulCommit;
      sumShotBlock += sourceData[i].shotBlock;
      maxShotBlock = (maxShotBlock > sourceData[i].shotBlock) ? maxShotBlock : sourceData[i].shotBlock;
      sumShotBlocked += sourceData[i].shotBlocked;
      maxShotBlocked = (maxShotBlocked > sourceData[i].shotBlocked) ? maxShotBlocked : sourceData[i].shotBlocked;
      sumGoals += sourceData[i].goals;
    }

    for (let i = 0; i < sourceData.length; i++) {
      let player = sourceData[i]['backNumber'] + ' - ' + sourceData[i]['name'];
      dataRows.push(this.createIndividualCounterDataRows(player, sourceData[i]));
    }

    cellrows = await this.dataRowsResolver(
        dataRows,
        {
          getIndividualCountersTable: true,
          sourceData
        }
    );

    cellrows.forEach((x, idx) => {
      x.forEach((c, i) => {

        if (this.gameType === 'COMPLETE_MODE') {
          if (i > 1) {

            if (i % 2 === 0) {
              c['border'] = [true, true, false, true]
            } else {
              c['border'] = [false, true, true, true]
            }

          }
        } else {
          if (i > 0) {

            if (i % 2 !== 0) {
              c['border'] = [true, true, false, true]
            } else {
              c['border'] = [false, true, true, true]
            }

          }
        }

      })
    });

    let totals: any[];
    if (this.gameType === 'COMPLETE_MODE') {
      totals = [
        '',
        '',
        sumAttackFaultProvoke,
        sumAttackFaultCommit,
        sumTechnicalMistakeProvoke,
        sumTechnicalMistakeCommit,
        sumLostBallProvoke,
        sumLostBall,
        sumTwoMinProvoke,
        sumTwoMinCommit,
        sumSevenMetersProvoke,
        sumSevenMetersCommit,
        sumSevenMSuspProvoke,
        sumSevenMSuspCommit,
        sumOneAndOneLost,
        sumOneAndOneWon,
        sumFoulReceive,
        sumFoulCommit,
        sumShotBlock,
        sumShotBlocked
      ];
    } else {
      totals = [
        '',
        sumAttackFaultProvoke,
        sumAttackFaultCommit,
        sumTechnicalMistakeProvoke,
        sumTechnicalMistakeCommit,
        sumLostBallProvoke,
        sumLostBall,
        sumTwoMinProvoke,
        sumTwoMinCommit,
        sumSevenMetersProvoke,
        sumSevenMetersCommit,
        sumSevenMSuspProvoke,
        sumSevenMSuspCommit,
        sumOneAndOneLost,
        sumOneAndOneWon,
        sumFoulReceive,
        sumFoulCommit,
        sumShotBlock,
        sumShotBlocked
      ];
    }
    let totalRaw: Object[] = []
    totals.forEach(
          (el, idx) => {
            let cellContent = new Cell(new Txt(`${idx === 0 ? 'Total' : el}`).end)
                .alignment(idx === 0 ? 'left' : 'center')
                .style(['body__cell_b5'])
                .margin((idx === 0 ? [5, 4, 0, 0] : [0, 4, 0, 0]))
                .end;
            totalRaw.push(cellContent);
          }
      );
      cellrows.push(totalRaw);

      // B5
      return this.gameType === 'COMPLETE_MODE' ? new Table(cellrows)
              .layout(layoutTableBodyGridTest2)
              .headerRows(1)
              // getIndividualCountersTable
              .widths([
                '22.5%', '4.83%', '3.75%', '3.75%', '3.75%',
                '3.75%', '3.8%', '3.8%', '3.8%', '3.8%',
                '3.75%', '3.75%', '3.75%', '3.8%', '3.8%',
                '3.8%',
                '4.65%', '4.65%',
                '4.8%', '4.9%'
              ])
              .end
          : new Table(cellrows)
              .layout(layoutTableBodyGridTest2)
              .headerRows(1)

              .widths([
                '25.3%',
                '4.25%', '4.25%',
                '4.25%', '4.25%',

                '3.8%', '3.8%',
                '3.7%', '3.7%',

                '3.8%', '3.8%',
                '3.8%', '3.8%',

                '3.8%', '3.8%',

                '4.75%', '4.75%',
                '4.9%', '4.9%'
              ])
              .end;

  }

  /**
   *
   * @param team
   * @param teamNameBgColor
   * @param teamNameColor
   * @param oddRowBgColor
   */
  private async getIndividualPerformanceTable(team: Team, teamNameBgColor: string, teamNameColor: string, oddRowBgColor: string) {
    let headerTitles = [];
    let cellrows: Object[][] = []

    this.pdf.add(new Table([
        [
          new Table([
            [
              this.createCustomTable({
                rectType: 'header',
                rectWidth: 818,
                rectColor: pdfColors.bgRectTableLightBlue,
                firstRectHeight: 28,
                secondRectHeight: 18
              })
            ],
            [
              new Cell(await this.addOverviewTableHeadersOnly(team, teamNameBgColor, teamNameColor, oddRowBgColor, 'B4'))
                .margin([0, 0, 0, 0])
                .relativePosition(0, -25)
                .end
            ]
          ])
            .layout({
              defaultBorder: false,
              paddingLeft: function (i, node) { return 0; },
              paddingRight: function (i, node) { return 0; },
              paddingTop: function (i, node) { return 0; },
              paddingBottom: function (i, node) { return 0; }
            })
            .margin([0, 0, 0, 0])
            .widths('*')
            .end,
        ]
      ]).layout({
        defaultBorder: false,
        paddingLeft: function (i, node) { return 0; },
        paddingRight: function (i, node) { return 0; },
        paddingTop: function (i, node) { return 0; },
        paddingBottom: function (i, node) { return 0; }
      })
      .margin([0, 0, 0, 0]).headerRows(1).margin([0, 0, 0, 0]).widths(['*']).end
    );

    let sourceData: IndividualPerformanceTableRow[] = team.individualPerformanceTable;
    let dataRows: Object[][] = [];
    let sumGoals = 0, sumSaves = 0, sumOuts = 0, sumLosts = 0, sumPossesions = 0, sumFailedShots = 0;
    let maxGoals = 0, maxSaves = 0, maxOuts = 0, maxLosts = 0, maxPossesions = 0, maxPossesionsPlayed = 0, maxFailedShots = 0;
    for (let i = 0; i < sourceData.length; i++) {
      let goals = sourceData[i]['goals'];
      sumGoals += goals;
      let failedShots = sourceData[i].failedShots;
      sumFailedShots += failedShots;
      let saves = sourceData[i].saves;
      sumSaves += saves;
      let outs = sourceData[i].outs;
      sumOuts += outs;
      let losts = sourceData[i].losts;
      sumLosts += losts;
      let possesionsPlayed = sourceData[i].possesionsPlayed;
      let possesions = goals + saves + outs + losts + failedShots;
      sumPossesions += possesions;
      if (goals > maxGoals) {
        maxGoals = goals;
      }
      if (failedShots > maxFailedShots) {
        maxFailedShots = failedShots;
      }
      if (possesions > maxPossesions) {
        maxPossesions = possesions;
      }
      if (possesionsPlayed > maxPossesionsPlayed) {
        maxPossesionsPlayed = possesionsPlayed;
      }
      if (saves > maxSaves) {
        maxSaves = saves;
      }
      if (outs > maxOuts) {
        maxOuts = outs;
      }
      if (losts > maxLosts) {
        maxLosts = losts;
      }
    }

    for (let i = 0; i < sourceData.length; i++) {
      let player = sourceData[i]['backNumber'] + ' - ' + sourceData[i]['name'];
      let countPossesionsPlayed = sourceData[i]['possesionsPlayed'];
      let possesionsPlayed = {value: countPossesionsPlayed, percent: (countPossesionsPlayed / maxPossesionsPlayed * 100), showArrow: false, rectWidth: 50, rectHeight: 10, percentColor: null};
      let countGoals = sourceData[i]['goals'];
      let goals = {value: countGoals, percent: (sourceData[i]['goals'] / maxGoals * 100), showArrow: false, rectWidth: 40, rectHeight: 10, percentColor: '#2EB67D'};
      let countFailedShots = sourceData[i]['failedShots'];
      let failedShots = {value: countFailedShots, percent: (sourceData[i]['failedShots'] / maxFailedShots * 100), showArrow: false, rectWidth: 40, rectHeight: 10, percentColor: '#36C5F0'};
      let countSaves = sourceData[i]['saves'];
      let saves = {value: countSaves, percent: (sourceData[i]['saves'] / maxSaves * 100), showArrow: false, rectWidth: 40, rectHeight: 10, percentColor: '#ECB22E'};
      let countOuts = sourceData[i]['outs'];
      let outs = {value: countOuts, percent: (sourceData[i]['outs'] / maxOuts * 100), showArrow: false, rectWidth: 40, rectHeight: 10, percentColor: '#A1A1A1'};
      let countLosts = sourceData[i]['losts'];
      let losts = {value: countLosts, percent: (sourceData[i]['losts'] / maxLosts * 100), showArrow: false, rectWidth: 40, rectHeight: 10, percentColor: '#E01E5A'};
      let countPossesions = countGoals + countSaves + countOuts + countLosts + countFailedShots;
      let possesions = {value: countPossesions, percent: (countPossesions / maxPossesions * 100), showArrow: false, rectWidth: 50, rectHeight: 10, percentColor: null};

      let shootsPercentDivisor = countGoals + countFailedShots + countOuts;

      let efficiency = (countPossesions > 0) ? parseFloat((countGoals / countPossesions * 100).toFixed(0)) : 0;
      let shootsPercent = (shootsPercentDivisor > 0) ? parseFloat((countGoals / shootsPercentDivisor * 100).toFixed(0)) : 0;
      let lostsPercent = (countPossesions > 0) ? parseFloat((countLosts / countPossesions * 100).toFixed(0)) : 0;
      if (this.gameType === 'COMPLETE_MODE') {
        dataRows.push([player, sourceData[i]['time'], possesionsPlayed, possesions, goals, failedShots, saves, outs, losts,
          efficiency+' %', shootsPercent+' %', lostsPercent+' %', sourceData[i]['performance']]);
      } else {
        dataRows.push([player, possesions, goals, failedShots, saves, outs, losts, shootsPercent+' %',  sourceData[i]['performance']]);
      }
    }

    cellrows = await this.dataRowsResolver(dataRows, {
      getIndividualPerformanceTable: true,
      sourceData
    });

    let shootsPercentDivisor = sumGoals+sumSaves+sumOuts;
    let efficiency = (sumPossesions > 0)? parseFloat((sumGoals/sumPossesions*100).toFixed(0)) : 0;
    let shootsPercent = (shootsPercentDivisor > 0)? parseFloat((sumGoals/shootsPercentDivisor*100).toFixed(0)) : 0;
    let lostsPercent = (sumPossesions > 0)? parseFloat((sumLosts/sumPossesions*100).toFixed(0)) : 0;

    let totals: any[];
    if (this.gameType === 'COMPLETE_MODE') {
      totals = ['', '', '', sumPossesions, sumGoals, sumFailedShots, sumSaves, sumOuts, sumLosts, '', shootsPercent+' %', '', ''];
    } else {
      totals = ['', sumPossesions, sumGoals, sumFailedShots, sumSaves, sumOuts, sumLosts, shootsPercent+' %', ''];
    }

    let totalRaw: Object[] = []
    totals.forEach(
      (el, idx) => {
        let cellContent = new Cell(new Txt(`${idx === 0 ? 'Total' : el}`).end)
        .alignment(idx === 0 ? 'left' : 'center')
        .style(['body__cell_b4_total'])
        .margin((idx === 0 ? [5,4,0,0] : [0,4,0,0]))
        .end;
        totalRaw.push(cellContent);
      }
    );
    cellrows.push(totalRaw);

    // B4
    return this.gameType === 'COMPLETE_MODE' ? new Table(cellrows)
      .headerRows(1)
      .layout(layoutTableBodyGridTest)
      .widths([
        '24.1%%', '4.83%', '6.83%', '6.83%', '6.83%',
        '6.83%', '6.83%', '6.83%', '6.83%',
        '4.83%', '5%',
        '6.83%', '6.05%',
      ])
      .end :
      new Table(cellrows)
        .headerRows(1)
        .layout(layoutTableBodyGridTest)
        .widths([
          '27%%', '7.5%', '7.5%', '7.5%', '7.5%',
          '7.5%', '7.5%', '13.5%', '13.5%'
        ]).end;
  }

  /**
   *
   * @param value
   * @param rectWidth
   * @param rectPercent
   * @returns
   */
  private getSvgProgressBar (value: number, rectWidth: number, rectHeight: number, rectPercent: number, showArrow: boolean, percentColor: string) {
    let str: string = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 ${rectWidth} ${rectHeight}">
    <g>
      <linearGradient id="SVGID_0"><stop offset="0%" style="stop-color:${percentColor||'rgb(255,165,0)'};stop-opacity: 1"/><stop offset="100%" style="stop-color:${percentColor||'rgb(255,165,0)'};stop-opacity: 1"/></linearGradient>
      <rect x="0" y="0" width="${rectPercent}%" height="100%" fill="url(#SVGID_0)" />
    </g>
    <g><text text-anchor="end" x="95%" y="65%" font-family="'Open Sans', sans-serif" font-size="8">${value}</text></g>`;
    if (showArrow) {
      str += `<g><path transform="rotate(0, 8, 5)" fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/></g>`;
    }
    str += `</svg>`;
    return str;
  };

  private getSvgProgressBarA (value: number, rectWidth: number, rectHeight: number, rectPercent: number, showArrow: boolean, percentColor: string) {
    let str: string = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 ${rectWidth} ${rectHeight}">
    <g>
      <linearGradient id="SVGID_0"><stop offset="0%" style="stop-color:${percentColor||'rgb(255,165,0)'};stop-opacity: 1"/><stop offset="100%" style="stop-color:${percentColor||'rgb(255,165,0)'};stop-opacity: 1"/></linearGradient>
      <rect x="0" y="0" width="${rectPercent}%" height="100%" fill="url(#SVGID_0)"  rx="5px" ry="5px"/>
    </g>
    <g><text text-anchor="end" x="95%" y="80%" font-family="'Open Sans', sans-serif" font-size="8">${value}</text></g>`;
    if (showArrow) {
      str += `<g><path transform="rotate(0, 8, 5)" fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/></g>`;
    }
    str += `</svg>`;
    return str;
  };

  // getTimelineTableFrom5To30Minutes
  private async getTimelineTableFrom5To30Minutes(team: string, initialIndex, finalIndex) {

    let cellrows: Object[][] = []
    let dataRows: Object[][] = []

    if (team === this.globalData.home.name) {
      // First row home local - Goals
      let newDataRow: Object[] = [toUpperCase(await this.tg(_('GOALS'), this.skippedLangs))];
      for (let i = initialIndex; i < finalIndex; i++) {
        newDataRow.push(this.globalData.home.timeline.goals[i]);
      }
      dataRows.push(newDataRow);
      // Second row home local - Possesions
      newDataRow = [toUpperCase(await this.tg(_('POSSESIONS'), this.skippedLangs))];
      for (var i = initialIndex; i < finalIndex; i++) {
        newDataRow.push(this.globalData.home.timeline.possesions[i]);
      }
      dataRows.push(newDataRow);
      // Third row home local - Lost Balls
      newDataRow = [toUpperCase(await this.tg(_('LOST BALLS'), this.skippedLangs))];
      for (var i = initialIndex; i < finalIndex; i++) {
        newDataRow.push(this.globalData.home.timeline.lostBalls[i]);
      }
      dataRows.push(newDataRow);
      // Fourth row home local - Saves
      newDataRow = [toUpperCase(await this.tg(_('SAVES'), this.skippedLangs))];
      for (var i = initialIndex; i < finalIndex; i++) {
        newDataRow.push(this.globalData.home.timeline.saves[i]);
      }
      dataRows.push(newDataRow);
    }
    else {
      // this.globalData.visitor.name,
      // Third row visitor local - Goals
      let newDataRow: Object[] = [toUpperCase(await this.tg(_('GOALS'), this.skippedLangs))];
      for (let i = initialIndex; i < finalIndex; i++) {
        newDataRow.push(this.globalData.visitor.timeline.goals[i]);
      }
      dataRows.push(newDataRow);
      // Second row visitor local - Possesions
      newDataRow = [toUpperCase(await this.tg(_('POSSESIONS'), this.skippedLangs))];
      for (let i = initialIndex; i < finalIndex; i++) {
        newDataRow.push(this.globalData.visitor.timeline.possesions[i]);
      }
      dataRows.push(newDataRow);
      // Third row visitor local - Lost Balls
      newDataRow = [toUpperCase(await this.tg(_('LOST BALLS'), this.skippedLangs))];
      for (let i = initialIndex; i < finalIndex; i++) {
        newDataRow.push(this.globalData.visitor.timeline.lostBalls[i]);
      }
      dataRows.push(newDataRow);
      // Fourth row visitor local - Saves
      newDataRow = [toUpperCase(await this.tg(_('SAVES'), this.skippedLangs))];
      for (let i = initialIndex; i < finalIndex; i++) {
        newDataRow.push(this.globalData.visitor.timeline.saves[i]);
      }
      dataRows.push(newDataRow);
    }
    cellrows = await this.dataRowsResolver(dataRows, {
      getTimelineTableFrom5To30Minutes: true
    });

    return new Table(cellrows)
      .headerRows(1)
      .layout(layoutTableBodyGrid).relativePosition(0, -82)
      .heights((row: number) => {
        return 15
      })
      .widths(this._extraTimeOrPenaltiesPlayed ? [
        '11.5%', '5.9%', '5.9%', '5.9%', '5.9%',
        '5.9%', '5.9%', '5.9%', '5.9%', '5.9%',
        '5.9%', '5.9%', '5.9%', '5.9%', '5.9%', '5.9%'
      ] : [
        '29%', '5.9%', '5.9%', '5.9%', '5.9%',
        '5.9%', '5.9%', '5.9%', '5.9%', '5.9%',
        '5.9%', '5.9%', '5.9%'
      ])
      .end;
  }

  private createIndividualCounterDataRows(
    player: any,
    sourceData: IndividualPerformanceTableRow,
  ): any[] {
      return this.gameType === 'COMPLETE_MODE' ?
          [
            player,
            sourceData['time'],
            (sourceData['attackFaultProvoke'] != 0) ? sourceData['attackFaultProvoke'] : '', // Red
            (sourceData['attackFaultCommit'] != 0) ? sourceData['attackFaultCommit'] : '', // Green
            (sourceData['technicalMistakeProvoke'] != 0) ? sourceData['technicalMistakeProvoke'] : '', // Red
            (sourceData['technicalMistakeCommit'] != 0) ? sourceData['technicalMistakeCommit'] : '', // Green
            (sourceData['lostBallProvoke'] != 0) ? sourceData['lostBallProvoke'] : '', // Red
            (sourceData['lostBall'] != 0) ? sourceData['lostBall'] : '', // Green
            (sourceData['twoMinProvoke'] != 0) ? sourceData['twoMinProvoke'] : '', // Green
            (sourceData['twoMinCommit'] != 0) ? sourceData['twoMinCommit'] : '', // Red
            (sourceData['sevenMetersProvoke'] != 0) ? sourceData['sevenMetersProvoke'] : '', // Green
            (sourceData['sevenMetersCommit'] != 0) ? sourceData['sevenMetersCommit'] : '', // Red
            (sourceData['sevenMSuspProvoke'] != 0) ? sourceData['sevenMSuspProvoke'] : '', // Green
            (sourceData['sevenMSuspCommit'] != 0) ? sourceData['sevenMSuspCommit'] : '', // Red
            (sourceData['oneAndOneLost'] != 0) ? sourceData['oneAndOneLost'] : '', // Red
            (sourceData['oneAndOneWon'] != 0) ? sourceData['oneAndOneWon'] : '', // Green
            (sourceData['foulReceive'] != 0) ? sourceData['foulReceive'] : '',
            (sourceData['foulCommit'] != 0) ? sourceData['foulCommit'] : '',
            (sourceData['shotBlock'] != 0) ? sourceData['shotBlock'] : '', // Green
            (sourceData['shotBlocked'] != 0) ? sourceData['shotBlocked'] : '', // Red
          ] : [
            player,
            (sourceData['attackFaultProvoke'] != 0) ? sourceData['attackFaultProvoke'] : '', // Red
            (sourceData['attackFaultCommit'] != 0) ? sourceData['attackFaultCommit'] : '', // Green
            (sourceData['technicalMistakeProvoke'] != 0) ? sourceData['technicalMistakeProvoke'] : '', // Red
            (sourceData['technicalMistakeCommit'] != 0) ? sourceData['technicalMistakeCommit'] : '', // Green
            (sourceData['lostBallProvoke'] != 0) ? sourceData['lostBallProvoke'] : '', // Red
            (sourceData['lostBall'] != 0) ? sourceData['lostBall'] : '', // Green
            (sourceData['twoMinProvoke'] != 0) ? sourceData['twoMinProvoke'] : '', // Green
            (sourceData['twoMinCommit'] != 0) ? sourceData['twoMinCommit'] : '', // Red
            (sourceData['sevenMetersProvoke'] != 0) ? sourceData['sevenMetersProvoke'] : '', // Green
            (sourceData['sevenMetersCommit'] != 0) ? sourceData['sevenMetersCommit'] : '', // Red
            (sourceData['sevenMSuspProvoke'] != 0) ? sourceData['sevenMSuspProvoke'] : '', // Green
            (sourceData['sevenMSuspCommit'] != 0) ? sourceData['sevenMSuspCommit'] : '', // Red
            (sourceData['oneAndOneLost'] != 0) ? sourceData['oneAndOneLost'] : '', // Red
            (sourceData['oneAndOneWon'] != 0) ? sourceData['oneAndOneWon'] : '', // Green
            (sourceData['foulReceive'] != 0) ? sourceData['foulReceive'] : '',
            (sourceData['foulCommit'] != 0) ? sourceData['foulCommit'] : '',
            (sourceData['shotBlock'] != 0) ? sourceData['shotBlock'] : '', // Green
            (sourceData['shotBlocked'] != 0) ? sourceData['shotBlocked'] : '', // Red
          ];
  }

  // Jump Shot
  // Wing Transition


  private async getGameSystemTeamTable(
    team: Team,
    teamNameBgColor: string,
    teamNameColor: string,
    oddRowBgColor: string
  ) {
    let dataRows: Object[][] = [];
    let sourceData: GameSystemStatsViewModel[] = team.gameSystemTable;
    // let sourceData: GameSystemStatsViewModel[] = mockedGameSystemArray;

    let cellModifiers: Object[][] = [];
    for (let i = 0; i < sourceData.length; i++) {

      dataRows.push([
        `${sourceData[i].categoryName} - ${sourceData[i].actionDisplayName}`,
        sourceData[i].numberOfActionsPlayed.toFixed(0),
        sourceData[i].numberOfGoals.toFixed(0),
        sourceData[i].numberOfSaves.toFixed(0),
        sourceData[i].numberOfPostOuts.toFixed(0),
        sourceData[i].numberOfActionsPlayedWith7Meters.toFixed(0),
        `${sourceData[i].percentageGoals.toFixed(0)} %`,
        `${sourceData[i].percentageNoGoals.toFixed(0)} %`,
        `${sourceData[i].percentage7MetersGoals.toFixed(0)} %`,
      ]);

      cellModifiers.push([
        { color: sourceData[i].colorCode },
        { color: '#1A375C' },
        { color: '#1A375C' },
        { color: '#1A375C' },
        { color: '#1A375C' },
        { color: '#1A375C' },
        { color: '#1A375C' },
        { color: '#1A375C' },
        { color: '#1A375C' }
      ]);
    }

    let cellrows: Object[][] = [];
    cellrows = await this.dataRowsResolver(dataRows);

    cellrows.forEach((row, idx) => {
      row.forEach((cell, i) => {
        if (i === 0) {
          cell['color'] = cellModifiers[idx][0]['color'] === 'yellow' ? 'orange' : cellModifiers[idx][0]['color']
        }
      })
    });

    return new Table(cellrows).layout({
      defaultBorder: false
    })
      .headerRows(0).margin(0)
      .layout(layoutTableBodyGridTest2)
      .widths([
        '21%', '10%', '7.9%', '7.9%', '10%',
        '10%', '10.8%', '11%', '11.5%'
      ]).end;
  }

  // ----
  // DATA
  // ----

  // X-Axis (Data)
  HomeGoals = [1, 2, 3, 4, 4,
    5, 5, 6, 6, 7, 8, 9, 10, 10, 10, 10, 11, 12, 12, 12, 12, 13,
    14, 15, 15, 15, 16, 16, 17, 17, 18, 18, 19, 20, 21, 22, 23, 24,
    25, 25, 26, 26, 27, 27, 28, 29, 30, 30, 30, 31, 32, 33, 34, 35,
    35, 35, 35, 36, 37, 37];
  AwayGoals = [1, 1, 2, 3, 3, 3, 3, 4,
    5, 6, 7, 7, 8, 8, 8, 8, 9, 10, 11, 12, 12, 13, 14, 14, 14, 14,
    15, 16, 16, 17, 18, 19, 19, 20, 21, 22, 23, 24, 24, 24, 25, 25,
    25, 26, 26, 27, 27, 28, 29, 29, 29, 29, 29, 30, 30, 31, 31, 31,
    32, 33];
  HomeXG = [0.32, 0.68, 1.01, 1.62,
    2.37, 3.09, 3.59, 4.39, 4.85, 5.37, 5.67, 6.46, 7.24, 7.63, 7.9,
    8.46, 9.22, 9.89, 10.25, 10.96, 11.52, 11.74, 12.03, 12.51, 13.27,
    13.48, 13.92, 14.39, 15.12, 15.33, 15.66, 16.12, 16.72, 17.47,
    18.01, 18.73, 19.31, 19.8, 20.17, 20.96, 21.65, 22.38, 23.09,
    23.66, 24.39, 24.9, 25.52, 25.87, 26.11, 26.52, 26.77, 27.34,
    27.81, 28.21, 29, 29.58, 30.27, 30.79, 31.47, 32.06];
  AwayXG = [0.77,
    1.14, 1.71, 2.27, 3.01, 3.57, 3.84, 4.37, 4.67, 5.3, 5.97, 6.28,
    6.93, 7.46, 7.73, 8.52, 9.19, 9.68, 10.39, 10.61, 11.35, 11.56,
    12.22, 12.68, 12.92, 13.65, 13.99, 14.39, 14.99, 15.26, 15.83,
    16.19, 16.55, 16.77, 17.37, 17.93, 18.17, 18.89, 19.45, 20.21,
    20.53, 20.82, 21.05, 21.57, 22.11, 22.55, 23.08, 23.62, 24.25,
    24.49, 25.14, 25.74, 26.46, 27.17, 27.86, 28.5, 28.88, 29.39,
    29.6, 30.19];

  // Y-axis
  MinuteLabels = ['Minute 1', 'Minute 2', 'Minute 3', 'Minute 4', 'Minute 5', 'Minute 6', 'Minute 7', 'Minute 8', 'Minute 9', 'Minute 10',
    'Minute 11', 'Minute 12', 'Minute 13', 'Minute 14', 'Minute 15', 'Minute 16', 'Minute 17', 'Minute 18', 'Minute 19', 'Minute 20', 'Minute 21',
    'Minute 22', 'Minute 23', 'Minute 24', 'Minute 25', 'Minute 26', 'Minute 27', 'Minute 28', 'Minute 29', 'Minute 30', 'Minute 31', 'Minute 32',
    'Minute 33', 'Minute 34', 'Minute 35', 'Minute 36', 'Minute 37', 'Minute 38', 'Minute 39', 'Minute 40', 'Minute 41', 'Minute 42', 'Minute 43',
    'Minute 44', 'Minute 45', 'Minute 46', 'Minute 47', 'Minute 48', 'Minute 49', 'Minute 50', 'Minute 51', 'Minute 52', 'Minute 53', 'Minute 54',
    'Minute 55', 'Minute 56', 'Minute 57', 'Minute 58', 'Minute 59', 'Minute 60'];


  // -----
  // CHART
  // -----

  HomeTeamName = 'Flemsburg';

  AwayTeamName = 'Füchse';

  HomeTeamLabel = 'Goals '.concat(this.HomeTeamName);
  AwayTeamLabel = 'Goals '.concat(this.AwayTeamName);

  HomeTeamLabelXG = 'XG '.concat(this.HomeTeamName);
  AwayTeamLabelXG = 'XG '.concat(this.AwayTeamName);

  chartData = [
    {
      data: this.HomeGoals,
      label: this.HomeTeamLabel
    },
    {
      data: this.AwayGoals,
      label: this.AwayTeamLabel
    },
    {
      data: this.HomeXG,
      label: this.HomeTeamLabelXG,
      borderDash: [3]
    },
    {
      data: this.AwayXG,
      label: this.AwayTeamLabelXG,
      borderDash: [3]

    }
  ];
  chartLabels = this.MinuteLabels;


}

export function teamAggregatedDtoHandlerFn(teamStatsDto: TeamsStatsDto, teamId: string, teamName: string): TeamStatsAggregateDto {
  return {
    teamId,
    teamName,
    totalPossessions: teamStatsDto.possessionsPlayed+'',
    avgPossessions: teamStatsDto.possessionsPlayed+'',
    totalGoals: teamStatsDto.goals+'',
    avgGoals: teamStatsDto.goals+'',
    totalSaves: teamStatsDto.saves+'',
    avgSaves: teamStatsDto.saves+'',
    totalLostBalls: teamStatsDto.lostBalls+'',
    avgTotalBalls: teamStatsDto.lostBalls+'',
    totalShotBlock: teamStatsDto.shotBlocked+'',
    avgShotBlock: teamStatsDto.shotBlocked+'',
    totalShotBlocked: teamStatsDto.shotBlocked+'',
    avgShotBlocked: teamStatsDto.shotBlocked+'',
    totalOneAndOneWon: teamStatsDto.oneAndOneWon+'',
    avgOneAndOneWon: teamStatsDto.oneAndOneWon+'',
    totalOneAndOneLost: teamStatsDto.oneAndOneLost+'',
    avgOneAndOneLost: teamStatsDto.oneAndOneLost+'',
    totalFoulReceive: teamStatsDto.foulReceive+'',
    avgFoulReceive: teamStatsDto.foulReceive+'',
    totalFoulCommit: teamStatsDto.foulCommit+'',
    avgFoulCommit: teamStatsDto.foulCommit+'',
    totalSevenMSuspProvoke: teamStatsDto.sevenMSuspProvoke+'',
    avgSevenMSuspProvoke: teamStatsDto.sevenMSuspProvoke+'',
    totalSevenMSuspCommit: teamStatsDto.sevenMSuspCommit+'',
    avgSevenMSuspCommit: teamStatsDto.sevenMSuspCommit+'',
    totalSevenMetersProvoke: teamStatsDto.sevenMetersProvoke+'',
    avgSevenMetersProvoke: teamStatsDto.sevenMetersProvoke+'',
    totalSevenMetersCommit: teamStatsDto.sevenMetersCommit+'',
    avgSevenMetersCommit: teamStatsDto.sevenMetersCommit+'',
    totalTwoMinProvoke: teamStatsDto.twoMinProvoke+'',
    avgTwoMinProvoke: teamStatsDto.twoMinProvoke+'',
    totalTwoMinCommit: teamStatsDto.twoMinCommit+'',
    avgTwoMinCommit: teamStatsDto.twoMinCommit+'',
    totalLostBall: teamStatsDto.lostBall+'',
    avgLostBall: teamStatsDto.lostBall+'',
    totalLostBallProvoke: teamStatsDto.lostBallProvoke+'',
    avgLostBallProvoke: teamStatsDto.lostBallProvoke+'',
    totalTechnicalMistakeCommit: teamStatsDto.technicalMistakeCommit+'',
    avgTechnicalMistakeCommit: teamStatsDto.technicalMistakeCommit+'',
    totalTechnicalMistakeProvoke: teamStatsDto.technicalMistakeProvoke+'',
    avgTechnicalMistakeProvoke: teamStatsDto.technicalMistakeProvoke+'',
    totalAttackFaultCommit: teamStatsDto.attackFaultCommit+'',
    avgAttackFaultCommit: teamStatsDto.attackFaultCommit+'',
    totalAttackFaultProvoke: teamStatsDto.attackFaultProvoke+'',
    avgAttackFaultProvoke: teamStatsDto.attackFaultProvoke+'',
    avgEfficiency: teamStatsDto.efficiency+'',
    avgShotEfficiency: teamStatsDto.shotEfficiency+'',
    avgLostBallPerc: teamStatsDto.lostBallPerc+'',
    totalGoalsOffensePositional: teamStatsDto.goalsOffensePositional+'',
    totalGoalsFastBreak: teamStatsDto.goalsFastBreak+'',
    totalGoalsCounterGoals: teamStatsDto.goalsCounterGoal+'',
    avgGoalsOffensePositional: teamStatsDto.goalsOffensePositional+'',
    avgGoalsFastBreak: teamStatsDto.goalsFastBreak+'',
    avgGoalsCounterGoals: teamStatsDto.goalsCounterGoal+'',
    totalSavesOffensePositional: teamStatsDto.savesOffensePositional+'',
    totalSavesFastBreak: teamStatsDto.savesFastBreak+'',
    totalSavesCounterGoals: teamStatsDto.savesCounterGoal+'',
    totalLostBallsOffensePositional: teamStatsDto.lostBallsOffensePositional+'',
    totalLostBallsFastBreak: teamStatsDto.lostBallsFastBreak+'',
    totalLostBallsCounterGoals: teamStatsDto.lostBallsCounterGoal+'',
    totalGoals1: teamStatsDto.goals1+'',
    totalGoals2: teamStatsDto.goals2+'',
    totalGoals3: teamStatsDto.goals3+'',
    totalGoals4: teamStatsDto.goals4+'',
    totalGoals5: teamStatsDto.goals5+'',
    totalGoals6: teamStatsDto.goals6+'',
    totalGoals7: teamStatsDto.goals7+'',
    totalGoals8: teamStatsDto.goals8+'',
    totalGoals9: teamStatsDto.goals9+'',
    totalSaves1: teamStatsDto.saves1+'',
    totalSaves2: teamStatsDto.saves2+'',
    totalSaves3: teamStatsDto.saves3+'',
    totalSaves4: teamStatsDto.saves4+'',
    totalSaves5: teamStatsDto.saves5+'',
    totalSaves6: teamStatsDto.saves6+'',
    totalSaves7: teamStatsDto.saves7+'',
    totalSaves8: teamStatsDto.saves8+'',
    totalSaves9: teamStatsDto.saves9+'',
    totalGoalsLeftWing: teamStatsDto.goalsLeftWing+'',
    totalGoalsLeft9m: teamStatsDto.goalsLeft9m+'',
    totalGoalsLeft6m: teamStatsDto.goalsLeft6m+'',
    totalGoalsCenter6m: teamStatsDto.goalsCenter6m+'',
    totalGoalsCenter9m: teamStatsDto.goalsCenter9m+'',
    totalGoalsRightWing: teamStatsDto.goalsRightWing+'',
    totalGoalsRight9m: teamStatsDto.goalsRight9m+'',
    totalGoalsRight6m: teamStatsDto.goalsRight6m+'',
    totalGoals7Meters: teamStatsDto.goals7Meters+'',
    avgGoals7Meters: teamStatsDto.goals7Meters+'',
    totalGoalsOppositeField: teamStatsDto.goalsOppositeField+'',
    totalSavesLeftWing: teamStatsDto.savesLeftWing+'',
    totalSavesLeft9m: teamStatsDto.savesLeft9m+'',
    totalSavesLeft6m: teamStatsDto.savesLeft6m+'',
    totalSavesCenter6m: teamStatsDto.savesCenter6m+'',
    totalSavesCenter9m: teamStatsDto.savesCenter9m+'',
    totalSavesRightWing: teamStatsDto.savesRightWing+'',
    totalSavesRight9m: teamStatsDto.savesRight9m+'',
    totalSavesRight6m: teamStatsDto.savesRight6m+'',
    totalSaves7Meters: teamStatsDto.saves7Meters+'',
    totalSavesOppositeField: teamStatsDto.savesOppositeField+'',
    avgPace: teamStatsDto.pace+'',
    totalDefenseSixToZero: teamStatsDto.def6To0+'',
    totalDefenseFiveToOne: teamStatsDto.def5To1+'',
    totalDefenseThreeToTwoToOne: teamStatsDto.def3to2to1+'',
    totalDefenseFourToTwo: teamStatsDto.def4to2+'',
    totalDefenseOther: teamStatsDto.defOther+'',
    totalPossOffensePositional: teamStatsDto.possessionsOffensePositional+'',
    totalPossFastBreak: teamStatsDto.possessionsFastBreak+'',
    totalPossCounterGoal: teamStatsDto.possessionsCounterGoal+'',
    avgPossOffensePositional: teamStatsDto.possessionsOffensePositional+'',
    avgPossFastBreak: teamStatsDto.possessionsFastBreak+'',
    avgPossCounterGoal: teamStatsDto.possessionsCounterGoal+'',
    totalEffectivePossOffensePositional: teamStatsDto.effectivePossessionsOffensePositional+'',
    totalEffectivePossFastBreak: teamStatsDto.effectivePossessionsFastBreak+'',
    totalEffectivePossCounterGoal: teamStatsDto.effectivePossessionsCounterGoal+'',
    avgEffectivePossOffensePositional: teamStatsDto.effectivePossessionsOffensePositional+'',
    avgEffectivePossFastBreak: teamStatsDto.effectivePossessionsFastBreak+'',
    avgEffectivePossCounterGoal: teamStatsDto.effectivePossessionsCounterGoal+'',
    avgEffOffensePositionalEquality: teamStatsDto.effOffensePositionalEquality+'',
    avgEffFastBreakEquality: teamStatsDto.effFastBreakEquality+'',
    avgEffCounterGoalEquality: teamStatsDto.effCounterGoalEquality+'',
    avgEffOffensePositionalSuperiority: teamStatsDto.effOffensePositionalSuperiority+'',
    avgEffFastBreakSuperiority: teamStatsDto.effFastBreakSuperiority+'',
    avgEffCounterGoalSuperiority: teamStatsDto.effCounterGoalSuperiority+'',
    avgEffOffensePositionalInferiority: teamStatsDto.effOffensePositionalInferiority+'',
    avgEffFastBreakInferiority: teamStatsDto.effFastBreakInferiority+'',
    avgEffCounterGoalInferiority: teamStatsDto.effCounterGoalInferiority+'',
    avgEffAgainst60: teamStatsDto.effAgainst60+'',
    avgEffAgainst51: teamStatsDto.effAgainst51+'',
    avgEffAgainst321: teamStatsDto.effAgainst321+'',
    avgEffAgainst42: teamStatsDto.effAgainst42+'',
    avgEffAgainstOther: teamStatsDto.effAgainstOther+'',
    totalPostOutOffensePositional: teamStatsDto.postOutOffensePositional+'',
    totalPostOutFastBreak: teamStatsDto.postOutFastBreak+'',
    totalPostOutCounterGoal: teamStatsDto.postOutCounterGoal+'',
    avgPostOutOffensePositional: teamStatsDto.postOutOffensePositional+'',
    avgPostOutFastBreak: teamStatsDto.postOutFastBreak+'',
    avgPostOutCounterGoal: teamStatsDto.postOutCounterGoal+'',
    totalFailedShotsOffensePositional: teamStatsDto.failedShotsOffensePositional+'',
    totalFailedShotsFastBreak: teamStatsDto.failedShotsFastBreak+'',
    totalFailedShotsCounterGoal: teamStatsDto.failedShotsCounterGoal+'',
    avgFailedShotsOffensePositional: teamStatsDto.failedShotsOffensePositional+'',
    avgFailedShotsFastBreak: teamStatsDto.failedShotsFastBreak+'',
    avgFailedShotsCounterGoal: teamStatsDto.failedShotsCounterGoal+'',
    avgEffSuperiority: teamStatsDto.effSuperiority+'',
    avgEff7vs6: teamStatsDto.eff7vs6+'',
    avgEffDefense: teamStatsDto.effDefense+'',
    totalPossMin5: teamStatsDto.possMin5+'',
    totalPossMin10: teamStatsDto.possMin10+'',
    totalPossMin15: teamStatsDto.possMin15+'',
    totalPossMin20: teamStatsDto.possMin20+'',
    totalPossMin25: teamStatsDto.possMin25+'',
    totalPossMin30: teamStatsDto.possMin30+'',
    totalPossMin35: teamStatsDto.possMin35+'',
    totalPossMin40: teamStatsDto.possMin40+'',
    totalPossMin45: teamStatsDto.possMin45+'',
    totalPossMin50: teamStatsDto.possMin50+'',
    totalPossMin55: teamStatsDto.possMin55+'',
    totalPossMin60: teamStatsDto.possMin60+'',
    totalLostBallMin5: teamStatsDto.lostBallMin5+'',
    totalLostBallMin10: teamStatsDto.lostBallMin10+'',
    totalLostBallMin15: teamStatsDto.lostBallMin15+'',
    totalLostBallMin20: teamStatsDto.lostBallMin20+'',
    totalLostBallMin25: teamStatsDto.lostBallMin25+'',
    totalLostBallMin30: teamStatsDto.lostBallMin30+'',
    totalLostBallMin35: teamStatsDto.lostBallMin35+'',
    totalLostBallMin40: teamStatsDto.lostBallMin40+'',
    totalLostBallMin45: teamStatsDto.lostBallMin45+'',
    totalLostBallMin50: teamStatsDto.lostBallMin50+'',
    totalLostBallMin55: teamStatsDto.lostBallMin55+'',
    totalLostBallMin60: teamStatsDto.lostBallMin60+'',
    totalGoalMin5: teamStatsDto.goalMin5+'',
    totalGoalMin10: teamStatsDto.goalMin10+'',
    totalGoalMin15: teamStatsDto.goalMin15+'',
    totalGoalMin20: teamStatsDto.goalMin20+'',
    totalGoalMin25: teamStatsDto.goalMin25+'',
    totalGoalMin30: teamStatsDto.goalMin30+'',
    totalGoalMin35: teamStatsDto.goalMin35+'',
    totalGoalMin40: teamStatsDto.goalMin40+'',
    totalGoalMin45: teamStatsDto.goalMin45+'',
    totalGoalMin50: teamStatsDto.goalMin50+'',
    totalGoalMin55: teamStatsDto.goalMin55+'',
    totalGoalMin60: teamStatsDto.goalMin60+'',
    totalSavesMin5: teamStatsDto.savesMin5+'',
    totalSavesMin10: teamStatsDto.savesMin10+'',
    totalSavesMin15: teamStatsDto.savesMin15+'',
    totalSavesMin20: teamStatsDto.savesMin20+'',
    totalSavesMin25: teamStatsDto.savesMin25+'',
    totalSavesMin30: teamStatsDto.savesMin30+'',
    totalSavesMin35: teamStatsDto.savesMin35+'',
    totalSavesMin40: teamStatsDto.savesMin40+'',
    totalSavesMin45: teamStatsDto.savesMin45+'',
    totalSavesMin50: teamStatsDto.savesMin50+'',
    totalSavesMin55: teamStatsDto.savesMin55+'',
    totalSavesMin60: teamStatsDto.savesMin60+'',
    totalAssistGkLw: teamStatsDto.assistGkLw+'',
    totalAssistGkRw: teamStatsDto.assistGkRw+'',
    totalAssistGkLb: teamStatsDto.assistGkLb+'',
    totalAssistGkRb: teamStatsDto.assistGkRb+'',
    totalAssistGkCb: teamStatsDto.assistGkCb+'',
    totalAssistGkLp: teamStatsDto.assistGkLp+'',
    totalAssistLwGk: teamStatsDto.assistLwGk+'',
    totalAssistLwRw: teamStatsDto.assistLwRw+'',
    totalAssistLwLb: teamStatsDto.assistLwLb+'',
    totalAssistLwRb: teamStatsDto.assistLwRb+'',
    totalAssistLwCb: teamStatsDto.assistLwCb+'',
    totalAssistLwLp: teamStatsDto.assistLwLp+'',
    totalAssistRwGk: teamStatsDto.assistRwGk+'',
    totalAssistRwLw: teamStatsDto.assistRwLw+'',
    totalAssistRwLb: teamStatsDto.assistRwLb+'',
    totalAssistRwRb: teamStatsDto.assistRwRb+'',
    totalAssistRwCb: teamStatsDto.assistRwCb+'',
    totalAssistRwLp: teamStatsDto.assistRwLp+'',
    totalAssistLbGk: teamStatsDto.assistLbGk+'',
    totalAssistLbLw: teamStatsDto.assistLbLw+'',
    totalAssistLbRw: teamStatsDto.assistLbRw+'',
    totalAssistLbRb: teamStatsDto.assistLbRb+'',
    totalAssistLbCb: teamStatsDto.assistLbCb+'',
    totalAssistLbLp: teamStatsDto.assistLbLp+'',
    totalAssistRbGk: teamStatsDto.assistRbGk+'',
    totalAssistRbLw: teamStatsDto.assistRbLw+'',
    totalAssistRbRw: teamStatsDto.assistRbRw+'',
    totalAssistRbLb: teamStatsDto.assistRbLb+'',
    totalAssistRbCb: teamStatsDto.assistRbCb+'',
    totalAssistRbLp: teamStatsDto.assistRbLp+'',
    totalAssistCbGk: teamStatsDto.assistCbGk+'',
    totalAssistCbLw: teamStatsDto.assistCbLw+'',
    totalAssistCbRw: teamStatsDto.assistCbRw+'',
    totalAssistCbLb: teamStatsDto.assistCbLb+'',
    totalAssistCbRb: teamStatsDto.assistCbRb+'',
    totalAssistCbLp: teamStatsDto.assistCbLp+'',
    totalAssistLpGk: teamStatsDto.assistLpGk+'',
    totalAssistLpLw: teamStatsDto.assistLpLw+'',
    totalAssistLpRw: teamStatsDto.assistLpRw+'',
    totalAssistLpLb: teamStatsDto.assistLpLb+'',
    totalAssistLpRb: teamStatsDto.assistLpRb+'',
    totalAssistLpCb: teamStatsDto.assistLpCb+'',
    avgAssistGkLw: teamStatsDto.assistGkLw+'',
    avgAssistGkRw: teamStatsDto.assistGkRw+'',
    avgAssistGkLb: teamStatsDto.assistGkLb+'',
    avgAssistGkRb: teamStatsDto.assistGkRb+'',
    avgAssistGkCb: teamStatsDto.assistGkCb+'',
    avgAssistGkLp: teamStatsDto.assistGkLp+'',
    avgAssistLwGk: teamStatsDto.assistLwGk+'',
    avgAssistLwRw: teamStatsDto.assistLwRw+'',
    avgAssistLwLb: teamStatsDto.assistLwLb+'',
    avgAssistLwRb: teamStatsDto.assistLwRb+'',
    avgAssistLwCb: teamStatsDto.assistLwCb+'',
    avgAssistLwLp: teamStatsDto.assistLwLp+'',
    avgAssistRwGk: teamStatsDto.assistRwGk+'',
    avgAssistRwLw: teamStatsDto.assistRwLw+'',
    avgAssistRwLb: teamStatsDto.assistRwLb+'',
    avgAssistRwRb: teamStatsDto.assistRwRb+'',
    avgAssistRwCb: teamStatsDto.assistRwCb+'',
    avgAssistRwLp: teamStatsDto.assistRwLp+'',
    avgAssistLbGk: teamStatsDto.assistLbGk+'',
    avgAssistLbLw: teamStatsDto.assistLbLw+'',
    avgAssistLbRw: teamStatsDto.assistLbRw+'',
    avgAssistLbRb: teamStatsDto.assistLbRb+'',
    avgAssistLbCb: teamStatsDto.assistLbCb+'',
    avgAssistLbLp: teamStatsDto.assistLbLp+'',
    avgAssistRbGk: teamStatsDto.assistRbGk+'',
    avgAssistRbLw: teamStatsDto.assistRbLw+'',
    avgAssistRbRw: teamStatsDto.assistRbRw+'',
    avgAssistRbLb: teamStatsDto.assistRbLb+'',
    avgAssistRbCb: teamStatsDto.assistRbCb+'',
    avgAssistRbLp: teamStatsDto.assistRbLp+'',
    avgAssistCbGk: teamStatsDto.assistCbGk+'',
    avgAssistCbLw: teamStatsDto.assistCbLw+'',
    avgAssistCbRw: teamStatsDto.assistCbRw+'',
    avgAssistCbLb: teamStatsDto.assistCbLb+'',
    avgAssistCbRb: teamStatsDto.assistCbRb+'',
    avgAssistCbLp: teamStatsDto.assistCbLp+'',
    avgAssistLpGk: teamStatsDto.assistLpGk+'',
    avgAssistLpLw: teamStatsDto.assistLpLw+'',
    avgAssistLpRw: teamStatsDto.assistLpRw+'',
    avgAssistLpLb: teamStatsDto.assistLpLb+'',
    avgAssistLpRb: teamStatsDto.assistLpRb+'',
    avgAssistLpCb: teamStatsDto.assistLpCb+'',
    avgSixZeroSuperiorityEfficiency: teamStatsDto.sixZeroSuperiorityEfficiency+'',
    avgFiveOneSuperiorityEfficiency: teamStatsDto.fiveOneSuperiorityEfficiency+'',
    avgThreeTwoOneSuperiorityEfficiency: teamStatsDto.threeTwoOneSuperiorityEfficiency+'',
    avgFourTwoSuperiorityEfficiency: teamStatsDto.fourTwoSuperiorityEfficiency+'',
    avgOtherSuperiorityEfficiency: teamStatsDto.otherSuperiorityEfficiency+'',
    avgSixZeroInferiorityEfficiency: teamStatsDto.sixZeroInferiorityEfficiency+'',
    avgFiveOneInferiorityEfficiency: teamStatsDto.fiveOneInferiorityEfficiency+'',
    avgThreeTwoOneInferiorityEfficiency: teamStatsDto.threeTwoOneInferiorityEfficiency+'',
    avgFourTwoInferiorityEfficiency: teamStatsDto.fourTwoInferiorityEfficiency+'',
    avgOtherInferiorityEfficiency: teamStatsDto.otherInferiorityEfficiency+'',
    avgSixZeroEqualityEfficiency: teamStatsDto.sixZeroEqualityEfficiency+'',
    avgFiveOneEqualityEfficiency: teamStatsDto.fiveOneEqualityEfficiency+'',
    avgThreeTwoOneEqualityEfficiency: teamStatsDto.threeTwoOneEqualityEfficiency+'',
    avgFourTwoEqualityEfficiency: teamStatsDto.fourTwoEqualityEfficiency+'',
    avgOtherEqualityEfficiency: teamStatsDto.otherEqualityEfficiency+'',
    totalGkSaveOffensePositional: teamStatsDto.gkSaveOffensePositional+'',
    totalGkSaveFastBreak: teamStatsDto.gkSaveFastBreak+'',
    totalGkSaveCounterGoal: teamStatsDto.gkSaveCounterGoal+'',
    avgSuperiorityEfficiency: teamStatsDto.superiorityEfficiency+'',
    avgEqualityEfficiency: teamStatsDto.equalityEfficiency+'',
    avgInferiorityEfficiency: teamStatsDto.inferiorityEfficiency+'',
    totalPostOutCenter6m: teamStatsDto.postOutCenter6m+'',
    totalPostOutCenter9m: teamStatsDto.postOutCenter9m+'',
    totalPostOutLeft6m: teamStatsDto.postOutLeft6m+'',
    totalPostOutLeft9m: teamStatsDto.postOutLeft9m+'',
    totalPostOutLeftWing: teamStatsDto.postOutLeftWing+'',
    totalPostOutOppositeField: teamStatsDto.postOutOppositeField+'',
    totalPostOutRight6m: teamStatsDto.postOutRight6m+'',
    totalPostOutRight9m: teamStatsDto.postOutRight9m+'',
    totalPostOutRightWing: teamStatsDto.postOutRightWing+'',
    totalPostOut7Meters: teamStatsDto.postOut7Meters+'',
    totalPostOut0: teamStatsDto.postOut0+'',
    totalPostOut10: teamStatsDto.postOut10+'',
    totalPostOut11: teamStatsDto.postOut11+'',
    totalGoalsLeftWing0: teamStatsDto.goalsLeftWing0+'',
    totalGoalsLeftWing1: teamStatsDto.goalsLeftWing1+'',
    totalGoalsLeftWing2: teamStatsDto.goalsLeftWing2+'',
    totalGoalsLeftWing3: teamStatsDto.goalsLeftWing3+'',
    totalGoalsLeftWing4: teamStatsDto.goalsLeftWing4+'',
    totalGoalsLeftWing5: teamStatsDto.goalsLeftWing5+'',
    totalGoalsLeftWing6: teamStatsDto.goalsLeftWing6+'',
    totalGoalsLeftWing7: teamStatsDto.goalsLeftWing7+'',
    totalGoalsLeftWing8: teamStatsDto.goalsLeftWing8+'',
    totalGoalsLeftWing9: teamStatsDto.goalsLeftWing9+'',
    totalGoalsLeftWing10: teamStatsDto.goalsLeftWing10+'',
    totalGoalsLeftWing11: teamStatsDto.goalsLeftWing11+'',
    totalGoalsLeft6m0: teamStatsDto.goalsLeft6m0+'',
    totalGoalsLeft6m1: teamStatsDto.goalsLeft6m1+'',
    totalGoalsLeft6m2: teamStatsDto.goalsLeft6m2+'',
    totalGoalsLeft6m3: teamStatsDto.goalsLeft6m3+'',
    totalGoalsLeft6m4: teamStatsDto.goalsLeft6m4+'',
    totalGoalsLeft6m5: teamStatsDto.goalsLeft6m5+'',
    totalGoalsLeft6m6: teamStatsDto.goalsLeft6m6+'',
    totalGoalsLeft6m7: teamStatsDto.goalsLeft6m7+'',
    totalGoalsLeft6m8: teamStatsDto.goalsLeft6m8+'',
    totalGoalsLeft6m9: teamStatsDto.goalsLeft6m9+'',
    totalGoalsLeft6m10: teamStatsDto.goalsLeft6m10+'',
    totalGoalsLeft6m11: teamStatsDto.goalsLeft6m11+'',
    totalGoalsCenter6m0: teamStatsDto.goalsCenter6m0+'',
    totalGoalsCenter6m1: teamStatsDto.goalsCenter6m1+'',
    totalGoalsCenter6m2: teamStatsDto.goalsCenter6m2+'',
    totalGoalsCenter6m3: teamStatsDto.goalsCenter6m3+'',
    totalGoalsCenter6m4: teamStatsDto.goalsCenter6m4+'',
    totalGoalsCenter6m5: teamStatsDto.goalsCenter6m5+'',
    totalGoalsCenter6m6: teamStatsDto.goalsCenter6m6+'',
    totalGoalsCenter6m7: teamStatsDto.goalsCenter6m7+'',
    totalGoalsCenter6m8: teamStatsDto.goalsCenter6m8+'',
    totalGoalsCenter6m9: teamStatsDto.goalsCenter6m9+'',
    totalGoalsCenter6m10: teamStatsDto.goalsCenter6m10+'',
    totalGoalsCenter6m11: teamStatsDto.goalsCenter6m11+'',
    totalGoalsRight6m0: teamStatsDto.goalsRight6m0+'',
    totalGoalsRight6m1: teamStatsDto.goalsRight6m1+'',
    totalGoalsRight6m2: teamStatsDto.goalsRight6m2+'',
    totalGoalsRight6m3: teamStatsDto.goalsRight6m3+'',
    totalGoalsRight6m4: teamStatsDto.goalsRight6m4+'',
    totalGoalsRight6m5: teamStatsDto.goalsRight6m5+'',
    totalGoalsRight6m6: teamStatsDto.goalsRight6m6+'',
    totalGoalsRight6m7: teamStatsDto.goalsRight6m7+'',
    totalGoalsRight6m8: teamStatsDto.goalsRight6m8+'',
    totalGoalsRight6m9: teamStatsDto.goalsRight6m9+'',
    totalGoalsRight6m10: teamStatsDto.goalsRight6m10+'',
    totalGoalsRight6m11: teamStatsDto.goalsRight6m11+'',
    totalGoalsRightWing0: teamStatsDto.goalsRightWing0+'',
    totalGoalsRightWing1: teamStatsDto.goalsRightWing1+'',
    totalGoalsRightWing2: teamStatsDto.goalsRightWing2+'',
    totalGoalsRightWing3: teamStatsDto.goalsRightWing3+'',
    totalGoalsRightWing4: teamStatsDto.goalsRightWing4+'',
    totalGoalsRightWing5: teamStatsDto.goalsRightWing5+'',
    totalGoalsRightWing6: teamStatsDto.goalsRightWing6+'',
    totalGoalsRightWing7: teamStatsDto.goalsRightWing7+'',
    totalGoalsRightWing8: teamStatsDto.goalsRightWing8+'',
    totalGoalsRightWing9: teamStatsDto.goalsRightWing9+'',
    totalGoalsRightWing10: teamStatsDto.goalsRightWing10+'',
    totalGoalsRightWing11: teamStatsDto.goalsRightWing11+'',
    totalGoalsLeft9m0: teamStatsDto.goalsLeft9m0+'',
    totalGoalsLeft9m1: teamStatsDto.goalsLeft9m1+'',
    totalGoalsLeft9m2: teamStatsDto.goalsLeft9m2+'',
    totalGoalsLeft9m3: teamStatsDto.goalsLeft9m3+'',
    totalGoalsLeft9m4: teamStatsDto.goalsLeft9m4+'',
    totalGoalsLeft9m5: teamStatsDto.goalsLeft9m5+'',
    totalGoalsLeft9m6: teamStatsDto.goalsLeft9m6+'',
    totalGoalsLeft9m7: teamStatsDto.goalsLeft9m7+'',
    totalGoalsLeft9m8: teamStatsDto.goalsLeft9m8+'',
    totalGoalsLeft9m9: teamStatsDto.goalsLeft9m9+'',
    totalGoalsLeft9m10: teamStatsDto.goalsLeft9m10+'',
    totalGoalsLeft9m11: teamStatsDto.goalsLeft9m11+'',
    totalGoalsCenter9m0: teamStatsDto.goalsCenter9m0+'',
    totalGoalsCenter9m1: teamStatsDto.goalsCenter9m1+'',
    totalGoalsCenter9m2: teamStatsDto.goalsCenter9m2+'',
    totalGoalsCenter9m3: teamStatsDto.goalsCenter9m3+'',
    totalGoalsCenter9m4: teamStatsDto.goalsCenter9m4+'',
    totalGoalsCenter9m5: teamStatsDto.goalsCenter9m5+'',
    totalGoalsCenter9m6: teamStatsDto.goalsCenter9m6+'',
    totalGoalsCenter9m7: teamStatsDto.goalsCenter9m7+'',
    totalGoalsCenter9m8: teamStatsDto.goalsCenter9m8+'',
    totalGoalsCenter9m9: teamStatsDto.goalsCenter9m9+'',
    totalGoalsCenter9m10: teamStatsDto.goalsCenter9m10+'',
    totalGoalsCenter9m11: teamStatsDto.goalsCenter9m11+'',
    totalGoalsRight9m0: teamStatsDto.goalsRight9m0+'',
    totalGoalsRight9m1: teamStatsDto.goalsRight9m1+'',
    totalGoalsRight9m2: teamStatsDto.goalsRight9m2+'',
    totalGoalsRight9m3: teamStatsDto.goalsRight9m3+'',
    totalGoalsRight9m4: teamStatsDto.goalsRight9m4+'',
    totalGoalsRight9m5: teamStatsDto.goalsRight9m5+'',
    totalGoalsRight9m6: teamStatsDto.goalsRight9m6+'',
    totalGoalsRight9m7: teamStatsDto.goalsRight9m7+'',
    totalGoalsRight9m8: teamStatsDto.goalsRight9m8+'',
    totalGoalsRight9m9: teamStatsDto.goalsRight9m9+'',
    totalGoalsRight9m10: teamStatsDto.goalsRight9m10+'',
    totalGoalsRight9m11: teamStatsDto.goalsRight9m11+'',
    totalGoals7Meters0: teamStatsDto.goals7Meters0+'',
    totalGoals7Meters1: teamStatsDto.goals7Meters1+'',
    totalGoals7Meters2: teamStatsDto.goals7Meters2+'',
    totalGoals7Meters3: teamStatsDto.goals7Meters3+'',
    totalGoals7Meters4: teamStatsDto.goals7Meters4+'',
    totalGoals7Meters5: teamStatsDto.goals7Meters5+'',
    totalGoals7Meters6: teamStatsDto.goals7Meters6+'',
    totalGoals7Meters7: teamStatsDto.goals7Meters7+'',
    totalGoals7Meters8: teamStatsDto.goals7Meters8+'',
    totalGoals7Meters9: teamStatsDto.goals7Meters9+'',
    totalGoals7Meters10: teamStatsDto.goals7Meters10+'',
    totalGoals7Meters11: teamStatsDto.goals7Meters11+'',
    totalGoalsOppositeField0: teamStatsDto.goalsOppositeField0+'',
    totalGoalsOppositeField1: teamStatsDto.goalsOppositeField1+'',
    totalGoalsOppositeField2: teamStatsDto.goalsOppositeField2+'',
    totalGoalsOppositeField3: teamStatsDto.goalsOppositeField3+'',
    totalGoalsOppositeField4: teamStatsDto.goalsOppositeField4+'',
    totalGoalsOppositeField5: teamStatsDto.goalsOppositeField5+'',
    totalGoalsOppositeField6: teamStatsDto.goalsOppositeField6+'',
    totalGoalsOppositeField7: teamStatsDto.goalsOppositeField7+'',
    totalGoalsOppositeField8: teamStatsDto.goalsOppositeField8+'',
    totalGoalsOppositeField9: teamStatsDto.goalsOppositeField9+'',
    totalGoalsOppositeField10: teamStatsDto.goalsOppositeField10+'',
    totalGoalsOppositeField11: teamStatsDto.goalsOppositeField11+'',
    totalShootsLeftWing0: teamStatsDto.shootsLeftWing0+'',
    totalShootsLeftWing1: teamStatsDto.shootsLeftWing1+'',
    totalShootsLeftWing2: teamStatsDto.shootsLeftWing2+'',
    totalShootsLeftWing3: teamStatsDto.shootsLeftWing3+'',
    totalShootsLeftWing4: teamStatsDto.shootsLeftWing4+'',
    totalShootsLeftWing5: teamStatsDto.shootsLeftWing5+'',
    totalShootsLeftWing6: teamStatsDto.shootsLeftWing6+'',
    totalShootsLeftWing7: teamStatsDto.shootsLeftWing7+'',
    totalShootsLeftWing8: teamStatsDto.shootsLeftWing8+'',
    totalShootsLeftWing9: teamStatsDto.shootsLeftWing9+'',
    totalShootsLeftWing10: teamStatsDto.shootsLeftWing10+'',
    totalShootsLeftWing11: teamStatsDto.shootsLeftWing11+'',
    totalShootsLeft6m0: teamStatsDto.shootsLeft6m0+'',
    totalShootsLeft6m1: teamStatsDto.shootsLeft6m1+'',
    totalShootsLeft6m2: teamStatsDto.shootsLeft6m2+'',
    totalShootsLeft6m3: teamStatsDto.shootsLeft6m3+'',
    totalShootsLeft6m4: teamStatsDto.shootsLeft6m4+'',
    totalShootsLeft6m5: teamStatsDto.shootsLeft6m5+'',
    totalShootsLeft6m6: teamStatsDto.shootsLeft6m6+'',
    totalShootsLeft6m7: teamStatsDto.shootsLeft6m7+'',
    totalShootsLeft6m8: teamStatsDto.shootsLeft6m8+'',
    totalShootsLeft6m9: teamStatsDto.shootsLeft6m9+'',
    totalShootsLeft6m10: teamStatsDto.shootsLeft6m10+'',
    totalShootsLeft6m11: teamStatsDto.shootsLeft6m11+'',
    totalShootsCenter6m0: teamStatsDto.shootsCenter6m0+'',
    totalShootsCenter6m1: teamStatsDto.shootsCenter6m1+'',
    totalShootsCenter6m2: teamStatsDto.shootsCenter6m2+'',
    totalShootsCenter6m3: teamStatsDto.shootsCenter6m3+'',
    totalShootsCenter6m4: teamStatsDto.shootsCenter6m4+'',
    totalShootsCenter6m5: teamStatsDto.shootsCenter6m5+'',
    totalShootsCenter6m6: teamStatsDto.shootsCenter6m6+'',
    totalShootsCenter6m7: teamStatsDto.shootsCenter6m7+'',
    totalShootsCenter6m8: teamStatsDto.shootsCenter6m8+'',
    totalShootsCenter6m9: teamStatsDto.shootsCenter6m9+'',
    totalShootsCenter6m10: teamStatsDto.shootsCenter6m10+'',
    totalShootsCenter6m11: teamStatsDto.shootsCenter6m11+'',
    totalShootsRight6m0: teamStatsDto.shootsRight6m0+'',
    totalShootsRight6m1: teamStatsDto.shootsRight6m1+'',
    totalShootsRight6m2: teamStatsDto.shootsRight6m2+'',
    totalShootsRight6m3: teamStatsDto.shootsRight6m3+'',
    totalShootsRight6m4: teamStatsDto.shootsRight6m4+'',
    totalShootsRight6m5: teamStatsDto.shootsRight6m5+'',
    totalShootsRight6m6: teamStatsDto.shootsRight6m6+'',
    totalShootsRight6m7: teamStatsDto.shootsRight6m7+'',
    totalShootsRight6m8: teamStatsDto.shootsRight6m8+'',
    totalShootsRight6m9: teamStatsDto.shootsRight6m9+'',
    totalShootsRight6m10: teamStatsDto.shootsRight6m10+'',
    totalShootsRight6m11: teamStatsDto.shootsRight6m11+'',
    totalShootsRightWing0: teamStatsDto.shootsRightWing0+'',
    totalShootsRightWing1: teamStatsDto.shootsRightWing1+'',
    totalShootsRightWing2: teamStatsDto.shootsRightWing2+'',
    totalShootsRightWing3: teamStatsDto.shootsRightWing3+'',
    totalShootsRightWing4: teamStatsDto.shootsRightWing4+'',
    totalShootsRightWing5: teamStatsDto.shootsRightWing5+'',
    totalShootsRightWing6: teamStatsDto.shootsRightWing6+'',
    totalShootsRightWing7: teamStatsDto.shootsRightWing7+'',
    totalShootsRightWing8: teamStatsDto.shootsRightWing8+'',
    totalShootsRightWing9: teamStatsDto.shootsRightWing9+'',
    totalShootsRightWing10: teamStatsDto.shootsRightWing10+'',
    totalShootsRightWing11: teamStatsDto.shootsRightWing11+'',
    totalShootsLeft9m0: teamStatsDto.shootsLeft9m0+'',
    totalShootsLeft9m1: teamStatsDto.shootsLeft9m1+'',
    totalShootsLeft9m2: teamStatsDto.shootsLeft9m2+'',
    totalShootsLeft9m3: teamStatsDto.shootsLeft9m3+'',
    totalShootsLeft9m4: teamStatsDto.shootsLeft9m4+'',
    totalShootsLeft9m5: teamStatsDto.shootsLeft9m5+'',
    totalShootsLeft9m6: teamStatsDto.shootsLeft9m6+'',
    totalShootsLeft9m7: teamStatsDto.shootsLeft9m7+'',
    totalShootsLeft9m8: teamStatsDto.shootsLeft9m8+'',
    totalShootsLeft9m9: teamStatsDto.shootsLeft9m9+'',
    totalShootsLeft9m10: teamStatsDto.shootsLeft9m10+'',
    totalShootsLeft9m11: teamStatsDto.shootsLeft9m11+'',
    totalShootsCenter9m0: teamStatsDto.shootsCenter9m0+'',
    totalShootsCenter9m1: teamStatsDto.shootsCenter9m1+'',
    totalShootsCenter9m2: teamStatsDto.shootsCenter9m2+'',
    totalShootsCenter9m3: teamStatsDto.shootsCenter9m3+'',
    totalShootsCenter9m4: teamStatsDto.shootsCenter9m4+'',
    totalShootsCenter9m5: teamStatsDto.shootsCenter9m5+'',
    totalShootsCenter9m6: teamStatsDto.shootsCenter9m6+'',
    totalShootsCenter9m7: teamStatsDto.shootsCenter9m7+'',
    totalShootsCenter9m8: teamStatsDto.shootsCenter9m8+'',
    totalShootsCenter9m9: teamStatsDto.shootsCenter9m9+'',
    totalShootsCenter9m10: teamStatsDto.shootsCenter9m10+'',
    totalShootsCenter9m11: teamStatsDto.shootsCenter9m11+'',
    totalShootsRight9m0: teamStatsDto.shootsRight9m0+'',
    totalShootsRight9m1: teamStatsDto.shootsRight9m1+'',
    totalShootsRight9m2: teamStatsDto.shootsRight9m2+'',
    totalShootsRight9m3: teamStatsDto.shootsRight9m3+'',
    totalShootsRight9m4: teamStatsDto.shootsRight9m4+'',
    totalShootsRight9m5: teamStatsDto.shootsRight9m5+'',
    totalShootsRight9m6: teamStatsDto.shootsRight9m6+'',
    totalShootsRight9m7: teamStatsDto.shootsRight9m7+'',
    totalShootsRight9m8: teamStatsDto.shootsRight9m8+'',
    totalShootsRight9m9: teamStatsDto.shootsRight9m9+'',
    totalShootsRight9m10: teamStatsDto.shootsRight9m10+'',
    totalShootsRight9m11: teamStatsDto.shootsRight9m11+'',
    totalShoots7Meters0: teamStatsDto.shoots7Meters0+'',
    totalShoots7Meters1: teamStatsDto.shoots7Meters1+'',
    totalShoots7Meters2: teamStatsDto.shoots7Meters2+'',
    totalShoots7Meters3: teamStatsDto.shoots7Meters3+'',
    totalShoots7Meters4: teamStatsDto.shoots7Meters4+'',
    totalShoots7Meters5: teamStatsDto.shoots7Meters5+'',
    totalShoots7Meters6: teamStatsDto.shoots7Meters6+'',
    totalShoots7Meters7: teamStatsDto.shoots7Meters7+'',
    totalShoots7Meters8: teamStatsDto.shoots7Meters8+'',
    totalShoots7Meters9: teamStatsDto.shoots7Meters9+'',
    totalShoots7Meters10: teamStatsDto.shoots7Meters10+'',
    totalShoots7Meters11: teamStatsDto.shoots7Meters11+'',
    totalShootsOppositeField0: teamStatsDto.shootsOppositeField0+'',
    totalShootsOppositeField1: teamStatsDto.shootsOppositeField1+'',
    totalShootsOppositeField2: teamStatsDto.shootsOppositeField2+'',
    totalShootsOppositeField3: teamStatsDto.shootsOppositeField3+'',
    totalShootsOppositeField4: teamStatsDto.shootsOppositeField4+'',
    totalShootsOppositeField5: teamStatsDto.shootsOppositeField5+'',
    totalShootsOppositeField6: teamStatsDto.shootsOppositeField6+'',
    totalShootsOppositeField7: teamStatsDto.shootsOppositeField7+'',
    totalShootsOppositeField8: teamStatsDto.shootsOppositeField8+'',
    totalShootsOppositeField9: teamStatsDto.shootsOppositeField9+'',
    totalShootsOppositeField10: teamStatsDto.shootsOppositeField10+'',
    totalShootsOppositeField11: teamStatsDto.shootsOppositeField11+'',
    avgTotalShots: teamStatsDto.totalShots+'',
    avgGoalsSuperiority: teamStatsDto.goalsSuperiority+'',
    avgGoalsEquality: teamStatsDto.goalsEquality+'',
    avgPossessionsSuperiority: teamStatsDto.possessionsSuperiority+'',
    avgPossessionsEquality: teamStatsDto.possessionsEquality+'',
    avgShots7Meters: teamStatsDto.shots7Meters+'',
    totalTotalShots: teamStatsDto.totalShots+'',
    totalGoalsSuperiority: teamStatsDto.goalsSuperiority+'',
    totalGoalsEquality: teamStatsDto.goalsEquality+'',
    totalPossessionsSuperiority: teamStatsDto.possessionsSuperiority+'',
    totalPossessionsEquality: teamStatsDto.possessionsEquality+'',
    totalShots7Meters: teamStatsDto.shots7Meters+'',
    totalGkReceivedOffensePositional: teamStatsDto.gkReceivedOffensePositional+'',
    totalGkReceivedCounterGoal: teamStatsDto.gkReceivedCounterGoal+'',
    totalGkReceivedFastBreak: teamStatsDto.gkReceivedFastBreak+'',
    totalDefenseReceivedGoals: teamStatsDto.defenseReceivedGoals+'',
    totalDefensePossessions: teamStatsDto.defensePossessions+'',
    totalOpponentFailedShots: teamStatsDto.opponentFailedShots+'',
    totalPossessionsEquality6vs6: teamStatsDto.possessionsEquality6vs6,
    totalGoalsEquality6vs6: teamStatsDto.goalsEquality6vs6,
    totalSavesEquality6vs6: teamStatsDto.savesEquality6vs6,
    totalLostBallsEquality6vs6: teamStatsDto.lostBallsEquality6vs6,
    totalPostOutEquality6vs6: teamStatsDto.postOutEquality6vs6,
    totalShotsEquality6vs6: teamStatsDto.shotsEquality6vs6,
    totalPossessionsEquality5p1vs6: teamStatsDto.possessionsEquality5p1vs6,
    totalGoalsEquality5p1vs6: teamStatsDto.goalsEquality5p1vs6,
    totalSavesEquality5p1vs6: teamStatsDto.savesEquality5p1vs6,
    totalLostBallsEquality5p1vs6: teamStatsDto.lostBallsEquality5p1vs6,
    totalPostOutEquality5p1vs6: teamStatsDto.postOutEquality5p1vs6,
    totalShotsEquality5p1vs6: teamStatsDto.shotsEquality5p1vs6,
    totalPossessionsSuperiority6vs5: teamStatsDto.possessionsSuperiority6vs5,
    totalGoalsSuperiority6vs5: teamStatsDto.goalsSuperiority6vs5,
    totalSavesSuperiority6vs5: teamStatsDto.savesSuperiority6vs5,
    totalLostBallsSuperiority6vs5: teamStatsDto.lostBallsSuperiority6vs5,
    totalPostOutSuperiority6vs5: teamStatsDto.postOutSuperiority6vs5,
    totalShotsSuperiority6vs5: teamStatsDto.shotsSuperiority6vs5,
    totalPossessionsSuperiority7vs6: teamStatsDto.possessionsSuperiority7vs6,
    totalGoalsSuperiority7vs6: teamStatsDto.goalsSuperiority7vs6,
    totalSavesSuperiority7vs6: teamStatsDto.savesSuperiority7vs6,
    totalLostBallsSuperiority7vs6: teamStatsDto.lostBallsSuperiority7vs6,
    totalPostOutSuperiority7vs6: teamStatsDto.postOutSuperiority7vs6,
    totalShotsSuperiority7vs6: teamStatsDto.shotsSuperiority7vs6,
    totalPlayedTimeOffense: teamStatsDto.playedTimeOffense+'',
    avgPlayedTimeOffense: teamStatsDto.playedTimeOffense+'',
    totalPlayedTimeDefense: teamStatsDto.playedTimeDefense+'',
    avgPlayedTimeDefense: teamStatsDto.playedTimeDefense+'',
    totalPlayedTimeOffenseInferiority: teamStatsDto.playedTimeOffenseInferiority+'',
    avgPlayedTimeOffenseInferiority: teamStatsDto.playedTimeOffenseInferiority+'',
    totalPlayedTimeDefenseInferiority: teamStatsDto.playedTimeDefenseInferiority+'',
    avgPlayedTimeDefenseInferiority: teamStatsDto.playedTimeDefenseInferiority+'',
    totalPlayedTimeOffenseSuperiority: teamStatsDto.playedTimeOffenseSuperiority+'',
    avgPlayedTimeOffenseSuperiority: teamStatsDto.playedTimeOffenseSuperiority+'',
    totalPlayedTimeDefenseSuperiority: teamStatsDto.playedTimeDefenseSuperiority+'',
    avgPlayedTimeDefenseSuperiority: teamStatsDto.playedTimeDefenseSuperiority+'',
    totalPlayedTimeOffenseEquality: teamStatsDto.playedTimeOffenseEquality+'',
    avgPlayedTimeOffenseEquality: teamStatsDto.playedTimeOffenseEquality+'',
    totalPlayedTimeDefenseEquality: teamStatsDto.playedTimeDefenseEquality+'',
    avgPlayedTimeDefenseEquality: teamStatsDto.playedTimeDefenseEquality+'',
  };
}

export function playerAggregatedDtoHandlerFn(playerStatsDto: PlayerStatsDto, teamId: string, teamName: string): PlayerStatsAggregateDto {
  return {
    playerId: playerStatsDto.playerId+'',
    playerName: playerStatsDto.playerName+'',
    playerBackNumber: playerStatsDto.playerBackNumber+'',
    playerPosition: playerStatsDto.playerPosition+'',
    totalGamesPlayed: '-',
    totalTime: playerStatsDto.timePlayed+'',
    avgTime: playerStatsDto.timePlayed+'',
    totalPossessions: playerStatsDto.possessionsPlayed+'',
    avgPossessions: playerStatsDto.possessionsPlayed+'',
    totalGoals: playerStatsDto.goals+'',
    avgGoals: playerStatsDto.goals+'',
    totalSaves: playerStatsDto.saves+'',
    avgSaves: playerStatsDto.saves+'',
    totalLostBalls: playerStatsDto.lostBalls+'',
    avgTotalBalls: playerStatsDto.lostBalls+'',
    totalShotBlock: playerStatsDto.shotBlocked+'',
    avgShotBlock: playerStatsDto.shotBlocked+'',
    totalShotBlocked: playerStatsDto.shotBlocked+'',
    avgShotBlocked: playerStatsDto.shotBlocked+'',
    totalOneAndOneWon: playerStatsDto.oneAndOneWon+'',
    avgOneAndOneWon: playerStatsDto.oneAndOneWon+'',
    totalOneAndOneLost: playerStatsDto.oneAndOneLost+'',
    avgOneAndOneLost: playerStatsDto.oneAndOneLost+'',
    totalFoulReceive: playerStatsDto.foulReceive+'',
    avgFoulReceive: playerStatsDto.foulReceive+'',
    totalFoulCommit: playerStatsDto.foulCommit+'',
    avgFoulCommit: playerStatsDto.foulCommit+'',
    totalSevenMSuspProvoke: playerStatsDto.sevenMSuspProvoke+'',
    avgSevenMSuspProvoke: playerStatsDto.sevenMSuspProvoke+'',
    totalSevenMSuspCommit: playerStatsDto.sevenMSuspCommit+'',
    avgSevenMSuspCommit: playerStatsDto.sevenMSuspCommit+'',
    totalSevenMetersProvoke: playerStatsDto.sevenMetersProvoke+'',
    avgSevenMetersProvoke: playerStatsDto.sevenMetersProvoke+'',
    totalSevenMetersCommit: playerStatsDto.sevenMetersCommit+'',
    avgSevenMetersCommit: playerStatsDto.sevenMetersCommit+'',
    totalTwoMinProvoke: playerStatsDto.twoMinProvoke+'',
    avgTwoMinProvoke: playerStatsDto.twoMinProvoke+'',
    totalTwoMinCommit: playerStatsDto.twoMinCommit+'',
    avgTwoMinCommit: playerStatsDto.twoMinCommit+'',
    totalLostBall: playerStatsDto.lostBall+'',
    avgLostBall: playerStatsDto.lostBall+'',
    totalLostBallProvoke: playerStatsDto.lostBallProvoke+'',
    avgLostBallProvoke: playerStatsDto.lostBallProvoke+'',
    totalTechnicalMistakeCommit: playerStatsDto.technicalMistakeCommit+'',
    avgTechnicalMistakeCommit: playerStatsDto.technicalMistakeCommit+'',
    totalTechnicalMistakeProvoke: playerStatsDto.technicalMistakeProvoke+'',
    avgTechnicalMistakeProvoke: playerStatsDto.technicalMistakeProvoke+'',
    totalAttackFaultCommit: playerStatsDto.attackFaultCommit+'',
    avgAttackFaultCommit: playerStatsDto.attackFaultCommit+'',
    totalAttackFaultProvoke: playerStatsDto.attackFaultProvoke+'',
    avgAttackFaultProvoke: playerStatsDto.attackFaultProvoke+'',
    avgEfficiency: playerStatsDto.efficiency+'',
    avgShotEfficiency: playerStatsDto.shotEfficiency+'',
    avgLostBallPerc: playerStatsDto.lostBallPerc+'',
    avgScore: playerStatsDto.score+'',
    avgPlusMinus: playerStatsDto.plusMinus+'',
    totalGoalsOffensePositional: playerStatsDto.goalsOffensePositional+'',
    totalGoalsFastBreak: playerStatsDto.goalsFastBreak+'',
    totalGoalsCounterGoals: playerStatsDto.goalsCounterGoal+'',
    avgGoalsOffensePositional: playerStatsDto.goalsOffensePositional+'',
    avgGoalsFastBreak: playerStatsDto.goalsFastBreak+'',
    avgGoalsCounterGoals: playerStatsDto.goalsCounterGoal+'',
    totalSavesOffensePositional: playerStatsDto.savesOffensePositional+'',
    totalSavesFastBreak: playerStatsDto.savesFastBreak+'',
    totalSavesCounterGoals: playerStatsDto.savesCounterGoal+'',
    totalLostBallsOffensePositional: playerStatsDto.lostBallsOffensePositional+'',
    totalLostBallsFastBreak: playerStatsDto.lostBallsFastBreak+'',
    totalLostBallsCounterGoals: playerStatsDto.lostBallsCounterGoal+'',
    avgLostBallsOffensePositional: playerStatsDto.lostBallsOffensePositional+'',
    avgLostBallsFastBreak: playerStatsDto.lostBallsFastBreak+'',
    avgLostBallsCounterGoals: playerStatsDto.lostBallsCounterGoal+'',
    totalLostBallsEquality: playerStatsDto.lostBallsEquality+'',
    totalLostBallsSuperiority: playerStatsDto.lostBallsSuperiority+'',
    avgLostBallsEquality: playerStatsDto.lostBallsEquality+'',
    avgLostBallsSuperiority: playerStatsDto.lostBallsSuperiority+'',
    totalGoals1: playerStatsDto.goals1+'',
    totalGoals2: playerStatsDto.goals2+'',
    totalGoals3: playerStatsDto.goals3+'',
    totalGoals4: playerStatsDto.goals4+'',
    totalGoals5: playerStatsDto.goals5+'',
    totalGoals6: playerStatsDto.goals6+'',
    totalGoals7: playerStatsDto.goals7+'',
    totalGoals8: playerStatsDto.goals8+'',
    totalGoals9: playerStatsDto.goals9+'',
    totalSaves1: playerStatsDto.saves1+'',
    totalSaves2: playerStatsDto.saves2+'',
    totalSaves3: playerStatsDto.saves3+'',
    totalSaves4: playerStatsDto.saves4+'',
    totalSaves5: playerStatsDto.saves5+'',
    totalSaves6: playerStatsDto.saves6+'',
    totalSaves7: playerStatsDto.saves7+'',
    totalSaves8: playerStatsDto.saves8+'',
    totalSaves9: playerStatsDto.saves9+'',
    totalGoalsLeftWing: playerStatsDto.goalsLeftWing+'',
    totalGoalsLeft9m: playerStatsDto.goalsLeft9m+'',
    totalGoalsLeft6m: playerStatsDto.goalsLeft6m+'',
    totalGoalsCenter6m: playerStatsDto.goalsCenter6m+'',
    totalGoalsCenter9m: playerStatsDto.goalsCenter9m+'',
    totalGoalsRightWing: playerStatsDto.goalsRightWing+'',
    totalGoalsRight9m: playerStatsDto.goalsRight9m+'',
    totalGoalsRight6m: playerStatsDto.goalsRight6m+'',
    totalGoals7Meters: playerStatsDto.goals7Meters+'',
    avgGoals7Meters: playerStatsDto.goals7Meters+'',
    totalGoalsOppositeField: playerStatsDto.goalsOppositeField+'',
    totalSavesLeftWing: playerStatsDto.savesLeftWing+'',
    totalSavesLeft9m: playerStatsDto.savesLeft9m+'',
    totalSavesLeft6m: playerStatsDto.savesLeft6m+'',
    totalSavesCenter6m: playerStatsDto.savesCenter6m+'',
    totalSavesCenter9m: playerStatsDto.savesCenter9m+'',
    totalSavesRightWing: playerStatsDto.savesRightWing+'',
    totalSavesRight9m: playerStatsDto.savesRight9m+'',
    totalSavesRight6m: playerStatsDto.savesRight6m+'',
    totalSaves7Meters: playerStatsDto.saves7Meters+'',
    totalSavesOppositeField: playerStatsDto.savesOppositeField+'',
    totalFailedShotsOffensePositional: playerStatsDto.failedShotsOffensePositional+'',
    totalFailedShotsFastBreak: playerStatsDto.failedShotsFastBreak+'',
    totalFailedShotsCounterGoal: playerStatsDto.failedShotsCounterGoal+'',
    avgFailedShotsOffensePositional: playerStatsDto.failedShotsOffensePositional+'',
    avgFailedShotsFastBreak: playerStatsDto.failedShotsFastBreak+'',
    avgFailedShotsCounterGoal: playerStatsDto.failedShotsCounterGoal+'',
    totalPostOut: playerStatsDto.postOut+'',
    avgPostOut: playerStatsDto.postOut+'',
    totalReceivedGoals: playerStatsDto.receivedGoals+'',
    avgReceivedGoals: playerStatsDto.receivedGoals+'',
    totalPostOutCenter6m: playerStatsDto.postOutCenter6m+'',
    totalPostOutCenter9m: playerStatsDto.postOutCenter9m+'',
    totalPostOutLeft6m: playerStatsDto.postOutLeft6m+'',
    totalPostOutLeft9m: playerStatsDto.postOutLeft9m+'',
    totalPostOutLeftWing: playerStatsDto.postOutLeftWing+'',
    totalPostOutOppositeField: playerStatsDto.postOutOppositeField+'',
    totalPostOutRight6m: playerStatsDto.postOutRight6m+'',
    totalPostOutRight9m: playerStatsDto.postOutRight9m+'',
    totalPostOutRightWing: playerStatsDto.postOutRightWing+'',
    totalPostOut7Meters: playerStatsDto.postOut7Meters+'',
    totalPostOut0: playerStatsDto.postOut0+'',
    totalPostOut10: playerStatsDto.postOut10+'',
    totalPostOut11: playerStatsDto.postOut11+'',
    totalGoalsLeftWing0: playerStatsDto.goalsLeftWing0+'',
    totalGoalsLeftWing1: playerStatsDto.goalsLeftWing1+'',
    totalGoalsLeftWing2: playerStatsDto.goalsLeftWing2+'',
    totalGoalsLeftWing3: playerStatsDto.goalsLeftWing3+'',
    totalGoalsLeftWing4: playerStatsDto.goalsLeftWing4+'',
    totalGoalsLeftWing5: playerStatsDto.goalsLeftWing5+'',
    totalGoalsLeftWing6: playerStatsDto.goalsLeftWing6+'',
    totalGoalsLeftWing7: playerStatsDto.goalsLeftWing7+'',
    totalGoalsLeftWing8: playerStatsDto.goalsLeftWing8+'',
    totalGoalsLeftWing9: playerStatsDto.goalsLeftWing9+'',
    totalGoalsLeftWing10: playerStatsDto.goalsLeftWing10+'',
    totalGoalsLeftWing11: playerStatsDto.goalsLeftWing11+'',
    totalGoalsLeft6m0: playerStatsDto.goalsLeft6m0+'',
    totalGoalsLeft6m1: playerStatsDto.goalsLeft6m1+'',
    totalGoalsLeft6m2: playerStatsDto.goalsLeft6m2+'',
    totalGoalsLeft6m3: playerStatsDto.goalsLeft6m3+'',
    totalGoalsLeft6m4: playerStatsDto.goalsLeft6m4+'',
    totalGoalsLeft6m5: playerStatsDto.goalsLeft6m5+'',
    totalGoalsLeft6m6: playerStatsDto.goalsLeft6m6+'',
    totalGoalsLeft6m7: playerStatsDto.goalsLeft6m7+'',
    totalGoalsLeft6m8: playerStatsDto.goalsLeft6m8+'',
    totalGoalsLeft6m9: playerStatsDto.goalsLeft6m9+'',
    totalGoalsLeft6m10: playerStatsDto.goalsLeft6m10+'',
    totalGoalsLeft6m11: playerStatsDto.goalsLeft6m11+'',
    totalGoalsCenter6m0: playerStatsDto.goalsCenter6m0+'',
    totalGoalsCenter6m1: playerStatsDto.goalsCenter6m1+'',
    totalGoalsCenter6m2: playerStatsDto.goalsCenter6m2+'',
    totalGoalsCenter6m3: playerStatsDto.goalsCenter6m3+'',
    totalGoalsCenter6m4: playerStatsDto.goalsCenter6m4+'',
    totalGoalsCenter6m5: playerStatsDto.goalsCenter6m5+'',
    totalGoalsCenter6m6: playerStatsDto.goalsCenter6m6+'',
    totalGoalsCenter6m7: playerStatsDto.goalsCenter6m7+'',
    totalGoalsCenter6m8: playerStatsDto.goalsCenter6m8+'',
    totalGoalsCenter6m9: playerStatsDto.goalsCenter6m9+'',
    totalGoalsCenter6m10: playerStatsDto.goalsCenter6m10+'',
    totalGoalsCenter6m11: playerStatsDto.goalsCenter6m11+'',
    totalGoalsRight6m0: playerStatsDto.goalsRight6m0+'',
    totalGoalsRight6m1: playerStatsDto.goalsRight6m1+'',
    totalGoalsRight6m2: playerStatsDto.goalsRight6m2+'',
    totalGoalsRight6m3: playerStatsDto.goalsRight6m3+'',
    totalGoalsRight6m4: playerStatsDto.goalsRight6m4+'',
    totalGoalsRight6m5: playerStatsDto.goalsRight6m5+'',
    totalGoalsRight6m6: playerStatsDto.goalsRight6m6+'',
    totalGoalsRight6m7: playerStatsDto.goalsRight6m7+'',
    totalGoalsRight6m8: playerStatsDto.goalsRight6m8+'',
    totalGoalsRight6m9: playerStatsDto.goalsRight6m9+'',
    totalGoalsRight6m10: playerStatsDto.goalsRight6m10+'',
    totalGoalsRight6m11: playerStatsDto.goalsRight6m11+'',
    totalGoalsRightWing0: playerStatsDto.goalsRightWing0+'',
    totalGoalsRightWing1: playerStatsDto.goalsRightWing1+'',
    totalGoalsRightWing2: playerStatsDto.goalsRightWing2+'',
    totalGoalsRightWing3: playerStatsDto.goalsRightWing3+'',
    totalGoalsRightWing4: playerStatsDto.goalsRightWing4+'',
    totalGoalsRightWing5: playerStatsDto.goalsRightWing5+'',
    totalGoalsRightWing6: playerStatsDto.goalsRightWing6+'',
    totalGoalsRightWing7: playerStatsDto.goalsRightWing7+'',
    totalGoalsRightWing8: playerStatsDto.goalsRightWing8+'',
    totalGoalsRightWing9: playerStatsDto.goalsRightWing9+'',
    totalGoalsRightWing10: playerStatsDto.goalsRightWing10+'',
    totalGoalsRightWing11: playerStatsDto.goalsRightWing11+'',
    totalGoalsLeft9m0: playerStatsDto.goalsLeft9m0+'',
    totalGoalsLeft9m1: playerStatsDto.goalsLeft9m1+'',
    totalGoalsLeft9m2: playerStatsDto.goalsLeft9m2+'',
    totalGoalsLeft9m3: playerStatsDto.goalsLeft9m3+'',
    totalGoalsLeft9m4: playerStatsDto.goalsLeft9m4+'',
    totalGoalsLeft9m5: playerStatsDto.goalsLeft9m5+'',
    totalGoalsLeft9m6: playerStatsDto.goalsLeft9m6+'',
    totalGoalsLeft9m7: playerStatsDto.goalsLeft9m7+'',
    totalGoalsLeft9m8: playerStatsDto.goalsLeft9m8+'',
    totalGoalsLeft9m9: playerStatsDto.goalsLeft9m9+'',
    totalGoalsLeft9m10: playerStatsDto.goalsLeft9m10+'',
    totalGoalsLeft9m11: playerStatsDto.goalsLeft9m11+'',
    totalGoalsCenter9m0: playerStatsDto.goalsCenter9m0+'',
    totalGoalsCenter9m1: playerStatsDto.goalsCenter9m1+'',
    totalGoalsCenter9m2: playerStatsDto.goalsCenter9m2+'',
    totalGoalsCenter9m3: playerStatsDto.goalsCenter9m3+'',
    totalGoalsCenter9m4: playerStatsDto.goalsCenter9m4+'',
    totalGoalsCenter9m5: playerStatsDto.goalsCenter9m5+'',
    totalGoalsCenter9m6: playerStatsDto.goalsCenter9m6+'',
    totalGoalsCenter9m7: playerStatsDto.goalsCenter9m7+'',
    totalGoalsCenter9m8: playerStatsDto.goalsCenter9m8+'',
    totalGoalsCenter9m9: playerStatsDto.goalsCenter9m9+'',
    totalGoalsCenter9m10: playerStatsDto.goalsCenter9m10+'',
    totalGoalsCenter9m11: playerStatsDto.goalsCenter9m11+'',
    totalGoalsRight9m0: playerStatsDto.goalsRight9m0+'',
    totalGoalsRight9m1: playerStatsDto.goalsRight9m1+'',
    totalGoalsRight9m2: playerStatsDto.goalsRight9m2+'',
    totalGoalsRight9m3: playerStatsDto.goalsRight9m3+'',
    totalGoalsRight9m4: playerStatsDto.goalsRight9m4+'',
    totalGoalsRight9m5: playerStatsDto.goalsRight9m5+'',
    totalGoalsRight9m6: playerStatsDto.goalsRight9m6+'',
    totalGoalsRight9m7: playerStatsDto.goalsRight9m7+'',
    totalGoalsRight9m8: playerStatsDto.goalsRight9m8+'',
    totalGoalsRight9m9: playerStatsDto.goalsRight9m9+'',
    totalGoalsRight9m10: playerStatsDto.goalsRight9m10+'',
    totalGoalsRight9m11: playerStatsDto.goalsRight9m11+'',
    totalGoals7Meters0: playerStatsDto.goals7Meters0+'',
    totalGoals7Meters1: playerStatsDto.goals7Meters1+'',
    totalGoals7Meters2: playerStatsDto.goals7Meters2+'',
    totalGoals7Meters3: playerStatsDto.goals7Meters3+'',
    totalGoals7Meters4: playerStatsDto.goals7Meters4+'',
    totalGoals7Meters5: playerStatsDto.goals7Meters5+'',
    totalGoals7Meters6: playerStatsDto.goals7Meters6+'',
    totalGoals7Meters7: playerStatsDto.goals7Meters7+'',
    totalGoals7Meters8: playerStatsDto.goals7Meters8+'',
    totalGoals7Meters9: playerStatsDto.goals7Meters9+'',
    totalGoals7Meters10: playerStatsDto.goals7Meters10+'',
    totalGoals7Meters11: playerStatsDto.goals7Meters11+'',
    totalGoalsOppositeField0: playerStatsDto.goalsOppositeField0+'',
    totalGoalsOppositeField1: playerStatsDto.goalsOppositeField1+'',
    totalGoalsOppositeField2: playerStatsDto.goalsOppositeField2+'',
    totalGoalsOppositeField3: playerStatsDto.goalsOppositeField3+'',
    totalGoalsOppositeField4: playerStatsDto.goalsOppositeField4+'',
    totalGoalsOppositeField5: playerStatsDto.goalsOppositeField5+'',
    totalGoalsOppositeField6: playerStatsDto.goalsOppositeField6+'',
    totalGoalsOppositeField7: playerStatsDto.goalsOppositeField7+'',
    totalGoalsOppositeField8: playerStatsDto.goalsOppositeField8+'',
    totalGoalsOppositeField9: playerStatsDto.goalsOppositeField9+'',
    totalGoalsOppositeField10: playerStatsDto.goalsOppositeField10+'',
    totalGoalsOppositeField11: playerStatsDto.goalsOppositeField11+'',
    totalShootsLeftWing0: playerStatsDto.shootsLeftWing0+'',
    totalShootsLeftWing1: playerStatsDto.shootsLeftWing1+'',
    totalShootsLeftWing2: playerStatsDto.shootsLeftWing2+'',
    totalShootsLeftWing3: playerStatsDto.shootsLeftWing3+'',
    totalShootsLeftWing4: playerStatsDto.shootsLeftWing4+'',
    totalShootsLeftWing5: playerStatsDto.shootsLeftWing5+'',
    totalShootsLeftWing6: playerStatsDto.shootsLeftWing6+'',
    totalShootsLeftWing7: playerStatsDto.shootsLeftWing7+'',
    totalShootsLeftWing8: playerStatsDto.shootsLeftWing8+'',
    totalShootsLeftWing9: playerStatsDto.shootsLeftWing9+'',
    totalShootsLeftWing10: playerStatsDto.shootsLeftWing10+'',
    totalShootsLeftWing11: playerStatsDto.shootsLeftWing11+'',
    totalShootsLeft6m0: playerStatsDto.shootsLeft6m0+'',
    totalShootsLeft6m1: playerStatsDto.shootsLeft6m1+'',
    totalShootsLeft6m2: playerStatsDto.shootsLeft6m2+'',
    totalShootsLeft6m3: playerStatsDto.shootsLeft6m3+'',
    totalShootsLeft6m4: playerStatsDto.shootsLeft6m4+'',
    totalShootsLeft6m5: playerStatsDto.shootsLeft6m5+'',
    totalShootsLeft6m6: playerStatsDto.shootsLeft6m6+'',
    totalShootsLeft6m7: playerStatsDto.shootsLeft6m7+'',
    totalShootsLeft6m8: playerStatsDto.shootsLeft6m8+'',
    totalShootsLeft6m9: playerStatsDto.shootsLeft6m9+'',
    totalShootsLeft6m10: playerStatsDto.shootsLeft6m10+'',
    totalShootsLeft6m11: playerStatsDto.shootsLeft6m11+'',
    totalShootsCenter6m0: playerStatsDto.shootsCenter6m0+'',
    totalShootsCenter6m1: playerStatsDto.shootsCenter6m1+'',
    totalShootsCenter6m2: playerStatsDto.shootsCenter6m2+'',
    totalShootsCenter6m3: playerStatsDto.shootsCenter6m3+'',
    totalShootsCenter6m4: playerStatsDto.shootsCenter6m4+'',
    totalShootsCenter6m5: playerStatsDto.shootsCenter6m5+'',
    totalShootsCenter6m6: playerStatsDto.shootsCenter6m6+'',
    totalShootsCenter6m7: playerStatsDto.shootsCenter6m7+'',
    totalShootsCenter6m8: playerStatsDto.shootsCenter6m8+'',
    totalShootsCenter6m9: playerStatsDto.shootsCenter6m9+'',
    totalShootsCenter6m10: playerStatsDto.shootsCenter6m10+'',
    totalShootsCenter6m11: playerStatsDto.shootsCenter6m11+'',
    totalShootsRight6m0: playerStatsDto.shootsRight6m0+'',
    totalShootsRight6m1: playerStatsDto.shootsRight6m1+'',
    totalShootsRight6m2: playerStatsDto.shootsRight6m2+'',
    totalShootsRight6m3: playerStatsDto.shootsRight6m3+'',
    totalShootsRight6m4: playerStatsDto.shootsRight6m4+'',
    totalShootsRight6m5: playerStatsDto.shootsRight6m5+'',
    totalShootsRight6m6: playerStatsDto.shootsRight6m6+'',
    totalShootsRight6m7: playerStatsDto.shootsRight6m7+'',
    totalShootsRight6m8: playerStatsDto.shootsRight6m8+'',
    totalShootsRight6m9: playerStatsDto.shootsRight6m9+'',
    totalShootsRight6m10: playerStatsDto.shootsRight6m10+'',
    totalShootsRight6m11: playerStatsDto.shootsRight6m11+'',
    totalShootsRightWing0: playerStatsDto.shootsRightWing0+'',
    totalShootsRightWing1: playerStatsDto.shootsRightWing1+'',
    totalShootsRightWing2: playerStatsDto.shootsRightWing2+'',
    totalShootsRightWing3: playerStatsDto.shootsRightWing3+'',
    totalShootsRightWing4: playerStatsDto.shootsRightWing4+'',
    totalShootsRightWing5: playerStatsDto.shootsRightWing5+'',
    totalShootsRightWing6: playerStatsDto.shootsRightWing6+'',
    totalShootsRightWing7: playerStatsDto.shootsRightWing7+'',
    totalShootsRightWing8: playerStatsDto.shootsRightWing8+'',
    totalShootsRightWing9: playerStatsDto.shootsRightWing9+'',
    totalShootsRightWing10: playerStatsDto.shootsRightWing10+'',
    totalShootsRightWing11: playerStatsDto.shootsRightWing11+'',
    totalShootsLeft9m0: playerStatsDto.shootsLeft9m0+'',
    totalShootsLeft9m1: playerStatsDto.shootsLeft9m1+'',
    totalShootsLeft9m2: playerStatsDto.shootsLeft9m2+'',
    totalShootsLeft9m3: playerStatsDto.shootsLeft9m3+'',
    totalShootsLeft9m4: playerStatsDto.shootsLeft9m4+'',
    totalShootsLeft9m5: playerStatsDto.shootsLeft9m5+'',
    totalShootsLeft9m6: playerStatsDto.shootsLeft9m6+'',
    totalShootsLeft9m7: playerStatsDto.shootsLeft9m7+'',
    totalShootsLeft9m8: playerStatsDto.shootsLeft9m8+'',
    totalShootsLeft9m9: playerStatsDto.shootsLeft9m9+'',
    totalShootsLeft9m10: playerStatsDto.shootsLeft9m10+'',
    totalShootsLeft9m11: playerStatsDto.shootsLeft9m11+'',
    totalShootsCenter9m0: playerStatsDto.shootsCenter9m0+'',
    totalShootsCenter9m1: playerStatsDto.shootsCenter9m1+'',
    totalShootsCenter9m2: playerStatsDto.shootsCenter9m2+'',
    totalShootsCenter9m3: playerStatsDto.shootsCenter9m3+'',
    totalShootsCenter9m4: playerStatsDto.shootsCenter9m4+'',
    totalShootsCenter9m5: playerStatsDto.shootsCenter9m5+'',
    totalShootsCenter9m6: playerStatsDto.shootsCenter9m6+'',
    totalShootsCenter9m7: playerStatsDto.shootsCenter9m7+'',
    totalShootsCenter9m8: playerStatsDto.shootsCenter9m8+'',
    totalShootsCenter9m9: playerStatsDto.shootsCenter9m9+'',
    totalShootsCenter9m10: playerStatsDto.shootsCenter9m10+'',
    totalShootsCenter9m11: playerStatsDto.shootsCenter9m11+'',
    totalShootsRight9m0: playerStatsDto.shootsRight9m0+'',
    totalShootsRight9m1: playerStatsDto.shootsRight9m1+'',
    totalShootsRight9m2: playerStatsDto.shootsRight9m2+'',
    totalShootsRight9m3: playerStatsDto.shootsRight9m3+'',
    totalShootsRight9m4: playerStatsDto.shootsRight9m4+'',
    totalShootsRight9m5: playerStatsDto.shootsRight9m5+'',
    totalShootsRight9m6: playerStatsDto.shootsRight9m6+'',
    totalShootsRight9m7: playerStatsDto.shootsRight9m7+'',
    totalShootsRight9m8: playerStatsDto.shootsRight9m8+'',
    totalShootsRight9m9: playerStatsDto.shootsRight9m9+'',
    totalShootsRight9m10: playerStatsDto.shootsRight9m10+'',
    totalShootsRight9m11: playerStatsDto.shootsRight9m11+'',
    totalShoots7Meters0: playerStatsDto.shoots7Meters0+'',
    totalShoots7Meters1: playerStatsDto.shoots7Meters1+'',
    totalShoots7Meters2: playerStatsDto.shoots7Meters2+'',
    totalShoots7Meters3: playerStatsDto.shoots7Meters3+'',
    totalShoots7Meters4: playerStatsDto.shoots7Meters4+'',
    totalShoots7Meters5: playerStatsDto.shoots7Meters5+'',
    totalShoots7Meters6: playerStatsDto.shoots7Meters6+'',
    totalShoots7Meters7: playerStatsDto.shoots7Meters7+'',
    totalShoots7Meters8: playerStatsDto.shoots7Meters8+'',
    totalShoots7Meters9: playerStatsDto.shoots7Meters9+'',
    totalShoots7Meters10: playerStatsDto.shoots7Meters10+'',
    totalShoots7Meters11: playerStatsDto.shoots7Meters11+'',
    totalShootsOppositeField0: playerStatsDto.shootsOppositeField0+'',
    totalShootsOppositeField1: playerStatsDto.shootsOppositeField1+'',
    totalShootsOppositeField2: playerStatsDto.shootsOppositeField2+'',
    totalShootsOppositeField3: playerStatsDto.shootsOppositeField3+'',
    totalShootsOppositeField4: playerStatsDto.shootsOppositeField4+'',
    totalShootsOppositeField5: playerStatsDto.shootsOppositeField5+'',
    totalShootsOppositeField6: playerStatsDto.shootsOppositeField6+'',
    totalShootsOppositeField7: playerStatsDto.shootsOppositeField7+'',
    totalShootsOppositeField8: playerStatsDto.shootsOppositeField8+'',
    totalShootsOppositeField9: playerStatsDto.shootsOppositeField9+'',
    totalShootsOppositeField10: playerStatsDto.shootsOppositeField10+'',
    totalShootsOppositeField11: playerStatsDto.shootsOppositeField11+'',
    avgTotalShots: playerStatsDto.totalShots+'',
    avgPossessionsOffensePositional: playerStatsDto.possessionsOffensePositional+'',
    avgPossessionsCounterGoal: playerStatsDto.possessionsCounterGoal+'',
    avgPossessionsFastBreak: playerStatsDto.possessionsFastBreak+'',
    avgGoalsSuperiority: playerStatsDto.goalsSuperiority+'',
    avgGoalsEquality: playerStatsDto.goalsEquality+'',
    avgPossessionsSuperiority: playerStatsDto.possessionsSuperiority+'',
    avgPossessionsEquality: playerStatsDto.possessionsEquality+'',
    avgShots7Meters: playerStatsDto.shots7Meters+'',
    totalTotalShots: playerStatsDto.totalShots+'',
    totalPossessionsOffensePositional: playerStatsDto.possessionsOffensePositional+'',
    totalPossessionsCounterGoal: playerStatsDto.possessionsCounterGoal+'',
    totalPossessionsFastBreak: playerStatsDto.possessionsFastBreak+'',
    totalGoalsSuperiority: playerStatsDto.goalsSuperiority+'',
    totalGoalsEquality: playerStatsDto.goalsEquality+'',
    totalPossessionsSuperiority: playerStatsDto.possessionsSuperiority+'',
    totalPossessionsEquality: playerStatsDto.possessionsEquality+'',
    totalShots7Meters: playerStatsDto.shots7Meters+'',
    totalAssist: playerStatsDto.assist+'',
    avgAssist: playerStatsDto.assist+'',
    totalGkPostOut: playerStatsDto.gkPostOut+'',
    avgGkPostOut: playerStatsDto.gkPostOut+'',
    totalGkSaves7m: playerStatsDto.gkSaves7Meters+'',
    totalGkReceived7m: playerStatsDto.gkReceivedGoals7Meters+'',
    totalDefensePossOffensePositional: playerStatsDto.defensePossOffensePositional+'',
    totalDefensePossFastBreak: playerStatsDto.defensePossFastBreak+'',
    totalDefensePossCounterGoal: playerStatsDto.defensePossCounterGoal+'',
    totalDefenseReceivedGoalsOffensePositional: playerStatsDto.defenseReceivedGoalsOffensePositional+'',
    totalDefenseReceivedGoalsFastBreak: playerStatsDto.defenseReceivedGoalsFastBreak+'',
    totalDefenseReceivedGoalsCounterGoal: playerStatsDto.defenseReceivedGoalsCounterGoal+'',
    avgPlayerScore: playerStatsDto.playerScore+'',
    totalPlayerScore: playerStatsDto.playerScore+'',
    totalScore: playerStatsDto.playerScore+'',
    avgScore5: playerStatsDto.score5+'',
    totalScore5: playerStatsDto.score5+'',
    avgScorePos: playerStatsDto.scorePos+'',
    totalScorePos: playerStatsDto.scorePos+'',
    avgScoreNeg: playerStatsDto.scoreNeg+'',
    totalScoreNeg: playerStatsDto.scoreNeg+'',
    avgScoreAttack: playerStatsDto.scoreAttack+'',
    totalScoreAttack: playerStatsDto.scoreAttack+'',
    avgScoreDef: playerStatsDto.scoreDef+'',
    totalScoreDef: playerStatsDto.scoreDef+'',
    totalPostOutOffensePositional: playerStatsDto.postOutOffensePositional+'',
    totalPostOutFastBreak: playerStatsDto.postOutFastBreak+'',
    totalPostOutCounterGoal: playerStatsDto.postOutCounterGoal+'',
    totalPostOutEquality: playerStatsDto.postOutEquality+'',
    totalPostOutSuperiority: playerStatsDto.postOutSuperiority+'',
    avgPostOutOffensePositional: playerStatsDto.postOutOffensePositional+'',
    avgPostOutFastBreak: playerStatsDto.postOutFastBreak+'',
    avgPostOutCounterGoal: playerStatsDto.postOutCounterGoal+'',
    avgPostOutEquality: playerStatsDto.postOutEquality+'',
    avgPostOutSuperiority: playerStatsDto.postOutSuperiority+'',
    totalFailedShotsEquality: playerStatsDto.failedShotsEquality+'',
    totalFailedShotsSuperiority: playerStatsDto.failedShotsSuperiority+'',
    avgFailedShotsEquality: playerStatsDto.failedShotsEquality+'',
    avgFailedShotsSuperiority: playerStatsDto.failedShotsSuperiority+'',
    totalPlayedTimeOffense: playerStatsDto.playedTimeOffense+'',
    avgPlayedTimeOffense: playerStatsDto.playedTimeOffense+'',
    totalPlayedTimeDefense: playerStatsDto.playedTimeDefense+'',
    avgPlayedTimeDefense: playerStatsDto.playedTimeDefense+'',
    totalPlayedTimeOffenseInferiority: playerStatsDto.playedTimeOffenseInferiority+'',
    avgPlayedTimeOffenseInferiority: playerStatsDto.playedTimeOffenseInferiority+'',
    totalPlayedTimeDefenseInferiority: playerStatsDto.playedTimeDefenseInferiority+'',
    avgPlayedTimeDefenseInferiority: playerStatsDto.playedTimeDefenseInferiority+'',
    totalPlayedTimeOffenseSuperiority: playerStatsDto.playedTimeOffenseSuperiority+'',
    avgPlayedTimeOffenseSuperiority: playerStatsDto.playedTimeOffenseSuperiority+'',
    totalPlayedTimeDefenseSuperiority: playerStatsDto.playedTimeDefenseSuperiority+'',
    avgPlayedTimeDefenseSuperiority: playerStatsDto.playedTimeDefenseSuperiority+'',
    totalPlayedTimeOffenseEquality: playerStatsDto.playedTimeOffenseEquality+'',
    avgPlayedTimeOffenseEquality: playerStatsDto.playedTimeOffenseEquality+'',
    totalPlayedTimeDefenseEquality: playerStatsDto.playedTimeDefenseEquality+'',
    avgPlayedTimeDefenseEquality: playerStatsDto.playedTimeDefenseEquality+'',
  }
}
