import { Observable } from 'rxjs';
import { GameSystemConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/game-system-consumer.service';

export class GameSystemStatsStateHelper {

    private gameSystemStatsStateMap = new Map<string, Observable<any>>();

    constructor(
        private readonly gameSystemConsumerService: GameSystemConsumerService,
    ) {
        this.gameSystemStatsStateMap.set('homeGameSystemTeamName', this.gameSystemConsumerService.homeTeamName$);
        this.gameSystemStatsStateMap.set('visitorGameSystemTeamName', this.gameSystemConsumerService.visitorTeamName$);

        this.gameSystemStatsStateMap.set(
            'homeGameSystemStatsView',
            this.gameSystemConsumerService.homeGameSystemStatsView$
        );
        this.gameSystemStatsStateMap.set(
            'visitorGameSystemStatsView',
            this.gameSystemConsumerService.visitorGameSystemStatsView$
        );
        this.gameSystemStatsStateMap.set(
            'homePlayerGameSystemStatsView',
            this.gameSystemConsumerService.homePlayerGameSystemStatsView$
        );
        this.gameSystemStatsStateMap.set(
            'visitorPlayerGameSystemStatsView',
            this.gameSystemConsumerService.visitorPlayerGameSystemStatsView$
        );
    }

    public getObservableByName(name: string): Observable<any> {
        return this.gameSystemStatsStateMap.get(name);
    }

}
