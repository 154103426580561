import { EventEmitter, Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { environment } from "src/environments/environment";
import { CoreService } from "../core.service";

@Injectable({
  providedIn: "root",
})
export class RealtimeGameDataService {
  core: CoreService = null;
  ackEvents: EventEmitter<string> = new EventEmitter<string>();

  socket: Socket = null;

  constructor() {}

  public initCore(core: CoreService) {
    this.core = core;
  }

  public async connectRealtime() {
    this.socket = new Socket({
      url: environment.wssPath + "/gamepush/" + this.core.gameDataService.getGameIdRunning(),
      options: {
        // query: {
        //   payload: cookieService.get('user')
        // }
        extraHeaders: {
          Authorization: await this.core.storageService.getAccessTokenAsPromise(),
        }
      }
    });
    this.socket.on("ack", (ack) => this.ackEvents.emit(ack));
  }

  public makePayment() {
    // const amount = (this.core.cart?.cart.shoppingCart?.total + this.core.cart?.cart.shoppingCart?.priceShipping).toFixed(2);
    // this.socket.emit('pinpad-request', `${amount}|invoicenum${Math.random()*100}`);
  }
}
