import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { CounterModel } from '@handballai/stats-calculation';
import { BehaviorSubject } from 'rxjs';
import { populateCounterByTypes } from 'src/app/shared-services/statistics/common/util/counter-util';
import { easyStatsCounterTypes } from '@handballai/stats-calculation';
import { getOpponentTeamMarker } from 'src/app/shared-services/helper/statistics-helper';
import { CoreService } from 'src/app/shared-services/core.service';

@Injectable({
  providedIn: 'root'
})
export class EasyStatsService {

  constructor(
      private readonly logger: NGXLogger,
  ) {}

  get homeTeamCounters$(): BehaviorSubject<CounterModel[]> {
    return this._homeTeamCounters$;
  }

  get visitorTeamCounters$(): BehaviorSubject<CounterModel[]> {
    return this._visitorTeamCounters$;
  }
  private _core: CoreService;

  private _teamCounterMap: Map<TeamMarker, Map<string, CounterModel>>;
  private _homeTeamCounters$ = new BehaviorSubject<CounterModel[]>([]);
  private _visitorTeamCounters$ = new BehaviorSubject<CounterModel[]>([]);
  initCore(core: CoreService) {
    this._core = core;
    this.subscribeToGoalEvent();
    this.subscribeToRedEvent();
    this.subscribeToSavePostOutEvent();
    this.subscribeToFaultEvent();
  }

  public init(): void {
    this._teamCounterMap = new Map<TeamMarker, Map<string, CounterModel>>([
      ['HOME', populateCounterByTypes(easyStatsCounterTypes)],
      ['VISITOR', populateCounterByTypes(easyStatsCounterTypes)]
    ]);
    this._homeTeamCounters$.next([]);
    this._visitorTeamCounters$.next([]);
  }

  private updateTeamObserver(): void {
    this._homeTeamCounters$.next([...Array.from(this._teamCounterMap.get('HOME').values())]);
    this._visitorTeamCounters$.next([...Array.from(this._teamCounterMap.get('VISITOR').values())]);
  }

  private incrementTeamCounter(
      teamMarker: TeamMarker,
      miscEventCounterType: string
  ): void {
    this._teamCounterMap
        .get(teamMarker)
        .set(miscEventCounterType, this._teamCounterMap.get(teamMarker).get(miscEventCounterType).increment());
  }

  private subscribeToGoalEvent(): void {
    this._core.playByPlayProducerService.on(['GOAL'], pbp => {
      this.incrementTeamCounter(pbp.teamMarker, 'GOAL');
      this.incrementTeamCounter(getOpponentTeamMarker(pbp.teamMarker), 'GK_GOAL');
      this.incrementTeamCounter(pbp.teamMarker, `OFFENSE_GOAL-${pbp.phase}`);
      this.incrementTeamCounter(getOpponentTeamMarker(pbp.teamMarker), `DEFENSE_GOAL-${pbp.phase}`);
      this.updateTeamObserver();
    });
  }

  private subscribeToSavePostOutEvent(): void {
    this._core.playByPlayProducerService.on(['SAVE'], pbp => {
      this.incrementTeamCounter(getOpponentTeamMarker(pbp.teamMarker), 'SAVE_POST_OUT');
      this.incrementTeamCounter(getOpponentTeamMarker(pbp.teamMarker), 'GK_SAVE_POST_OUT');
      this.updateTeamObserver();
    });
  }

  private subscribeToRedEvent(): void {
    this._core.playByPlayProducerService.on([
      'PASSIVE',
      'ATTACK_FAULT_COMMIT',
      'TECHNICAL_MISTAKE_COMMIT',
      'LOST_BALL'
    ], pbp => {
      this.incrementTeamCounter(pbp.teamMarker, 'RED_ACTIONS');
      this.updateTeamObserver();
    });
  }

  private subscribeToFaultEvent(): void {
    this._core.playByPlayProducerService.on([
      'FOUL_RECEIVE',
    ], pbp => {
      this.incrementTeamCounter(getOpponentTeamMarker(pbp.teamMarker), 'FAULT');
      this.updateTeamObserver();
    });
    this._core.playByPlayProducerService.on([
      'ATTACK_FAULT_COMMIT'
    ], pbp => {
      this.incrementTeamCounter(pbp.teamMarker, 'FAULT');
      this.updateTeamObserver();
    });
  }

}
