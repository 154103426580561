/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PlayerScoreStatsDto { 
    /**
     * id
     */
    id?: number;
    /**
     * Game id
     */
    gameId?: number;
    /**
     * Game descr
     */
    gameDesc?: string;
    /**
     * gameDate
     */
    gameDate?: string;
    /**
     * player id
     */
    playerId: number;
    /**
     * player name
     */
    playerName: string;
    /**
     * player position
     */
    playerPosition: string;
    /**
     * player back number
     */
    playerBackNumber: number;
    /**
     * team id
     */
    teamId: number;
    /**
     * team name
     */
    teamName: string;
    /**
     * time played
     */
    timePlayed: number;
    /**
     * playerScore
     */
    playerScore: number;
    /**
     * score
     */
    score: number;
    /**
     * score
     */
    score5: number;
    /**
     * scorePos
     */
    scorePos: number;
    /**
     * scoreNeg
     */
    scoreNeg: number;
    /**
     * scoreAttack
     */
    scoreAttack: number;
    /**
     * scoreDef
     */
    scoreDef: number;
}

