import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { GameSystemCategoryDto } from "src/app/api/hai-api";
import { CoreService } from "src/app/shared-services/core.service";
import { TGameSystemPbp } from "src/app/shared-services/game-system/game-system.model";
import { GameType } from "src/app/shared-services/game/game-type.model";
import { GameSystemService } from "../game-system/game-system.service";

@Component({
  selector: "app-game-system-popup",
  templateUrl: "./game-system-popup.component.html",
  styleUrls: ["./game-system-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameSystemPopupComponent implements OnInit {
  @Input() videoSrc: string;
  @Input() videoCurrentTime: number;
  private _gameSystemDtos: GameSystemCategoryDto[];
  gameSystemDtos$: Observable<GameSystemCategoryDto[]> =
    this.core.gameDataService.gameSystems$.pipe(
      tap((gameSystemDtos) => (this._gameSystemDtos = gameSystemDtos))
    );
  gameMode$: Observable<GameType> = this.core.gameService.gameMode$;
  gameSystemSelected$: Observable<TGameSystemPbp[]> =
    this.gameSystemService.gameSystemSelected$;
  gameActionNameSelected$: Observable<string[]> =
    this.gameSystemService.gameActionNameSelected$;

  private initialGameSystemSelected: TGameSystemPbp[];
  private initialGameActionNameSelected: string[];
  constructor(
    private readonly core: CoreService,
    private readonly popoverController: PopoverController,
    private gameSystemService: GameSystemService
  ) {}

  ngOnInit(): void {
    this.initialGameSystemSelected = JSON.parse(
      JSON.stringify(this.gameSystemService.gameSystemSelected)
    );
    this.initialGameActionNameSelected = JSON.parse(
      JSON.stringify(this.gameSystemService.gameActionNameSelected)
    );
  }

  onGameSystemSelected(gameSystemCategory: string, gameSystem: string) {
    this.gameSystemService.onGameSystemSelected(
      gameSystemCategory,
      gameSystem,
      this._gameSystemDtos
    );
  }

  public async save(): Promise<void> {
    await this.popoverController.dismiss();
  }

  public async cancel(): Promise<void> {
    if (
      JSON.stringify(this.initialGameSystemSelected) !==
      JSON.stringify(this.gameSystemService.gameSystemSelected)
    ) {
      this.gameSystemService.setGameSystemSelected(
        this.initialGameSystemSelected
      );
    }
    if (
      JSON.stringify(this.initialGameActionNameSelected) !==
      JSON.stringify(this.gameSystemService.gameActionNameSelected)
    ) {
      this.gameSystemService.setGameActionNameSelected(
        this.initialGameActionNameSelected
      );
    }
      await this.popoverController.dismiss();
  }
}
