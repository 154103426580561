import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { CounterModel, EventTime, PlayByPlayModel, PlayerEventModelOptions, PositionCode, TeamStatsPlayerModel } from '@handballai/stats-calculation';
import { goalLocationMap } from '@handballai/stats-calculation';
import {
  PhasesActionTypes
} from 'src/app/shared-services/actions/action-types';
import { PlayerEventModel } from '@handballai/stats-calculation';
import { BehaviorSubject } from 'rxjs';
import { getOpponentTeamMarker } from 'src/app/shared-services/helper/statistics-helper';
import { LineupStatsDto, PlayerStatsDto, TeamsStatsDto } from 'src/app/api/hai-api';
import { TeamsStatsDtoPart } from '../overview/overview-consumer.service';
import { CoreService } from 'src/app/shared-services/core.service';
import { Goals } from '@handballai/stats-calculation';
import { GoalConsumerCore } from '@handballai/stats-calculation';
import {
  PlayerStatsDtoPart
} from 'src/app/shared-services/statistics/playbyplay/sub-consumer/team-overview/team-overview-sub-consumer.helper';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class GoalConsumerService {

  constructor(
      private readonly logger: NGXLogger,
  ) { }

  get homeTeamCounters$(): BehaviorSubject<CounterModel[]> {
    return this._homeTeamCounters$;
  }

  get visitorTeamCounters$(): BehaviorSubject<CounterModel[]> {
    return this._visitorTeamCounters$;
  }

  get selectedPlayerCounters$(): BehaviorSubject<CounterModel[]> {
    return this._selectedPlayerCounters$;
  }

  get homeGoalPositionList$(): BehaviorSubject<Goals[]> {
    return this._homeGoalPositionList$;
  }

  get visitorGoalPositionList$(): BehaviorSubject<Goals[]> {
    return this._visitorGoalPositionList$;
  }

  get homeGoalConnectionsCounters$(): BehaviorSubject<CounterModel[]> {
    return this._homeGoalConnectionsCounters$;
  }

  get visitorGoalConnectionsCounters$(): BehaviorSubject<CounterModel[]> {
    return this._visitorGoalConnectionsCounters$;
  }

  get selectedPlayerGoalConnectionCounters$(): BehaviorSubject<CounterModel[]> {
    return this._selectedPlayerGoalConnectionCounters$;
  }

  private _core: CoreService;

  private _homeTeamCounters$ = new BehaviorSubject<CounterModel[]>([]);
  private _homeGoalConnectionsCounters$ = new BehaviorSubject<CounterModel[]>([]);
  private _visitorGoalConnectionsCounters$ = new BehaviorSubject<CounterModel[]>([]);
  private _visitorTeamCounters$ = new BehaviorSubject<CounterModel[]>([]);
  private _selectedPlayerCounters$ = new BehaviorSubject<CounterModel[]>([]);
  private _homeGoalPositionList$ = new BehaviorSubject<Goals[]>([]);
  private _visitorGoalPositionList$ = new BehaviorSubject<Goals[]>([]);
  private _selectedPlayerGoalConnectionCounters$ = new BehaviorSubject<CounterModel[]>([]);
  private _goalConsumerCore: GoalConsumerCore;

  initCore(core: CoreService) {
    this._core = core;
    this.subscribeToAllEvents();
    this.subscribeToGoalSaveEvent();
    this.subscribeToPostOutEvent();
    this.subscribeToSaveEventForGk();
  }

  // Moved to core
  public init(): void {
    this._goalConsumerCore = new GoalConsumerCore(
        this._core.gameService.gameModel.home.players,
        this._core.gameService.gameModel.visitor.players
    );
    this.resetTeamSubjects();
  }

  public selectPlayer(id: number): void {
    this._selectedPlayerCounters$
        .next([
            ...Array
                .from(this._goalConsumerCore.playerCounterMap.get(id).values()).filter(co => !co.counterType.includes('CONNECT'))
        ]);
    this._selectedPlayerGoalConnectionCounters$
        .next([
            ...Array
                .from(this._goalConsumerCore.playerCounterMap.get(id).values()).filter(co => co.counterType.includes('CONNECT'))
        ]);
  }

  public deSelectPlayer(): void {
    this._selectedPlayerCounters$.next([]);
    this._selectedPlayerGoalConnectionCounters$.next([]);
  }

  private performEasyPlayerEvents(
      eventType: PlayerEventModel,
      eventTime: EventTime,
      offensePlayerId: number,
      offensePlayerPosition: PositionCode,
      phase: PhasesActionTypes,
      assistantPlayerId?: number
  ): void {

    this._core.playerEventListConsumerService.addPlayerEvent({
      eventTime: eventTime,
      phase: phase,
      eventType: offensePlayerPosition === 'gk' ? `${eventType}_SHOT` : eventType,
      playerId: offensePlayerId
    });

    if (assistantPlayerId) {
      this._core.playerEventListConsumerService.addPlayerEvent({
        eventTime: eventTime,
        phase: phase,
        eventType: 'GOAL_ASSIST',
        playerId: assistantPlayerId
      });
    }


  }

  private updateTeamObserver(): void {
    this._homeTeamCounters$.next([...Array
        .from(this._goalConsumerCore.teamCounterMap.get('HOME').values())
        .filter(co => !co.counterType.includes('CONNECT'))]);
    this._visitorTeamCounters$.next([...Array
        .from(this._goalConsumerCore.teamCounterMap.get('VISITOR').values())
        .filter(co => !co.counterType.includes('CONNECT'))]);
    this._homeGoalPositionList$.next(this.calcGoalsPositions(this._goalConsumerCore.teamCounterMap.get('HOME')));
    this._visitorGoalPositionList$.next(this.calcGoalsPositions(this._goalConsumerCore.teamCounterMap.get('VISITOR')));
    this._homeGoalConnectionsCounters$.next([...Array
        .from(this._goalConsumerCore.teamCounterMap.get('HOME').values())
        .filter(co => co.counterType.includes('CONNECT'))]);
    this._visitorGoalConnectionsCounters$.next([...Array
        .from(this._goalConsumerCore.teamCounterMap.get('VISITOR').values())
        .filter(co => co.counterType.includes('CONNECT'))]);
  }

  private subscribeToGoalSaveEvent(): void {
    this._core.playByPlayProducerService.on(['GOAL', 'SAVE'], pbp => {
      this.logger.debug('GoalConsumerService.goalSaveEventSubscription: ', pbp);
      this._goalConsumerCore.performEventReceived(
          pbp.event,
          pbp.teamMarker,
          pbp.offensePlayer.id,
          pbp.offensePlayer.position,
          pbp.phase,
          pbp.shotLocation,
          pbp.executionPosition,
          pbp.assistantPlayer?.id,
          pbp.defensePlayer?.id,
      );

      this._goalConsumerCore.incrementSaveEvent7MetersForGk(pbp.teamMarker, pbp.event, pbp.defensePlayer?.id, pbp.executionPosition);
      this._goalConsumerCore.increment7MetersFailedShotsForSave(pbp.teamMarker, pbp.event, pbp.executionPosition);

      this._goalConsumerCore.incrementFailedShots(pbp.teamMarker, pbp.event);

      this.performEasyPlayerEvents(
          pbp.event,
          pbp.eventTime,
          pbp.offensePlayer.id,
          pbp.offensePlayer.position,
          pbp.phase,
          pbp.assistantPlayer?.id
      );

      if (pbp.defensePlayer) {
        this._core.playerEventListConsumerService.addPlayerEvent({
          eventTime: pbp.eventTime,
          phase: pbp.phase,
          eventType: pbp.event,
          playerId: pbp.defensePlayer.id
        });
      }

      this.updateTeamObserver();
    });
  }

  private subscribeToPostOutEvent(): void {
    this._core.playByPlayProducerService.on(['POST_OUT'], pbp => {
      this.logger.debug('GoalConsumerService.postOutEventSubscription: ', pbp);

      this._goalConsumerCore.performEventReceived(
          pbp.event,
          pbp.teamMarker,
          pbp.offensePlayer.id,
          pbp.offensePlayer.position,
          pbp.phase,
          pbp.shotLocation,
          pbp.executionPosition,
          pbp.assistantPlayer?.id,
          pbp.defensePlayer?.id,
      );

      this._goalConsumerCore.increment7MetersFailedShots(pbp.teamMarker, pbp.executionPosition);

      this.performEasyPlayerEvents(
          pbp.event,
          pbp.eventTime,
          pbp.offensePlayer.id,
          pbp.offensePlayer.position,
          pbp.phase,
          pbp.assistantPlayer?.id
      );

      this.updateTeamObserver();
    });
  }

  private subscribeToSaveEventForGk(): void {
    this._core.playByPlayProducerService.on(['SAVE'], pbp => {
      this.logger.debug('GoalConsumerService.subscribeToSaveEventForGk: ', pbp);
      this._goalConsumerCore.incrementSaveEventForGk(pbp.teamMarker, pbp.phase);
      this.updateTeamObserver();
    });
  }

  private calcGoalsPositions(teamMap: Map<string, CounterModel>): Goals[] {
    return [
      'GOAL-LEFT_WING',
      'GOAL-LEFT_6M',
      'GOAL-CENTER_6M',
      'GOAL-RIGHT_6M',
      'GOAL-RIGHT_WING',
      'GOAL-LEFT_9M',
      'GOAL-CENTER_9M',
      'GOAL-RIGHT_9M',
      'GOAL-7_METERS',
      'GOAL-OPPOSITE_FIELD',
    ].map(key => ({
      position: goalLocationMap.get(key).goalGraphPosition,
      label: `${teamMap.get(key).counter}`,
      weight: teamMap.get(key).counter
    }));
  }

  //#region ARSA
  private _possHasGoal: boolean;
  private _lastPbp: PlayByPlayModel|undefined;
  // private _lastPossesionId: number;
  // private _lastPbpEventTime: EventTime;
  private _teamPlayedTimeCounters;
  private _teamEventsCounters;
  private _teamEventsDefenseEff;
  private _playerPlayedTimeCounters;
  private _playerEventsCounters;
  private _playerEventsDefenseEff;
  private _lineUpCounters: {
    [key: string]: Omit<LineupStatsDto, 'efficiencyDefense'|'efficiencyOffense'> & {
      lastPossId: number;
    };
  } = {};
  private readonly _lineUpCountersTpl = {
    lastPossId: 0,
    goals: 0,
    possessionsPlayed: 0,
    possessionsOffense: 0,
    noGoals: 0,
    possessionsDefense: 0,
    saves: 0,
    lostBalls: 0,
    teamMarker: 'HOME',
  };
  private readonly _playerTimeCountersTpl = {
    playedTimeOffense: 0,
    playedTimeDefense: 0,
    playedTimeOffenseInferiority: 0,
    playedTimeDefenseInferiority: 0,
    playedTimeOffenseSuperiority: 0,
    playedTimeDefenseSuperiority: 0,
    playedTimeOffenseEquality: 0,
    playedTimeDefenseEquality: 0,
  };
  private readonly _eventCountersTpl = {
    eventsFastBreak: 0,
    eventsCounterGoal: 0,
    eventsOffensePositional: 0,
    eventsSuperiority7vs6: 0,
    eventsSuperiority6vs5: 0,
    eventsSuperiorityOthers: 0,
    eventsEquality: 0,
    eventsInferiority: 0,
    eventsDefense: 0,
    eventsOffense: 0,
  };
  private readonly _extraPlayerCountersTpl = {
    yellowCard: 0,
    blueCard: 0,
    redCard: 0,
  };
  private readonly _extraTeamCountersTpl = {
    timeOuts: 0,
    // goalsInferiority: 0, // Should be at already calculated counters
    gkReceivedInferiority: 0,
  };

  private orderedFieldSetupKey(fieldSetup: TeamStatsPlayerModel[]) {
    return fieldSetup.map(i => i.id).sort((a, b) => (+b)-(+a)).join(':');
  }

  private subscribeToAllEvents() {
    this._core.playByPlayProducerService.on([...PlayerEventModelOptions], pbp => {
      const isNewPossitionEvent = pbp.possessionId != (this._lastPbp?.possessionId ?? 0);

      //#region Team Counters
      const otherTeamMarker = pbp.teamMarker=='HOME' ? 'VISITOR':'HOME';

      if (pbp.possessionId > 1 && (isNewPossitionEvent||pbp.event == 'SECOND_HALF_END') && !this._possHasGoal && this._lastPbp) {
        // Add noGoals to desired places (for previous possession defense team)
        const lastOtherTeamMarker = this._lastPbp.teamMarker=='HOME' ? 'VISITOR':'HOME';
        this._teamEventsDefenseEff[lastOtherTeamMarker].noGoals++;
      }

      this._teamPlayedTimeCounters[pbp.teamMarker].playedTimeOffense += pbp.eventTime.secondsSinceStartOfGame-(this._lastPbp?.eventTime.secondsSinceStartOfGame ?? 0);
      this._teamPlayedTimeCounters[otherTeamMarker].playedTimeDefense += pbp.eventTime.secondsSinceStartOfGame-(this._lastPbp?.eventTime.secondsSinceStartOfGame ?? 0);
      if (['INFERIORITY-5vs6', 'INFERIORITY-6vs7', 'INFERIORITY-OTHER'].includes(pbp.offenseSystem)) {
        this._teamPlayedTimeCounters[pbp.teamMarker].playedTimeOffenseInferiority += pbp.eventTime.secondsSinceStartOfGame-(this._lastPbp?.eventTime.secondsSinceStartOfGame ?? 0);
        this._teamPlayedTimeCounters[otherTeamMarker].playedTimeDefenseInferiority += pbp.eventTime.secondsSinceStartOfGame-(this._lastPbp?.eventTime.secondsSinceStartOfGame ?? 0);
      }
      if (['EQUALITY', 'EQUALITY-5+1vs6', 'EQUALITY-6vs5+1'].includes(pbp.offenseSystem)) {
        this._teamPlayedTimeCounters[pbp.teamMarker].playedTimeOffenseEquality += pbp.eventTime.secondsSinceStartOfGame-(this._lastPbp?.eventTime.secondsSinceStartOfGame ?? 0);
        this._teamPlayedTimeCounters[otherTeamMarker].playedTimeDefenseEquality += pbp.eventTime.secondsSinceStartOfGame-(this._lastPbp?.eventTime.secondsSinceStartOfGame ?? 0);
      }
      if (['SUPERIORITY-6vs5', 'SUPERIORITY-7vs6', 'SUPERIORITY-OTHER'].includes(pbp.offenseSystem)) {
        this._teamPlayedTimeCounters[pbp.teamMarker].playedTimeOffenseSuperiority += pbp.eventTime.secondsSinceStartOfGame-(this._lastPbp?.eventTime.secondsSinceStartOfGame ?? 0);
        this._teamPlayedTimeCounters[otherTeamMarker].playedTimeDefenseSuperiority += pbp.eventTime.secondsSinceStartOfGame-(this._lastPbp?.eventTime.secondsSinceStartOfGame ?? 0);
      }

      this._teamEventsCounters[pbp.teamMarker].eventsOffense++;
      this._teamEventsCounters[otherTeamMarker].eventsDefense++;
      if (pbp.phase == 'FAST_BREAK') this._teamEventsCounters[pbp.teamMarker].eventsFastBreak++;
      if (pbp.phase == 'COUNTER_GOAL') this._teamEventsCounters[pbp.teamMarker].eventsCounterGoal++;
      if (pbp.phase == 'OFFENSE_POSITIONAL') this._teamEventsCounters[pbp.teamMarker].eventsOffensePositional++;
      if (pbp.offenseSystem == 'SUPERIORITY-7vs6') this._teamEventsCounters[pbp.teamMarker].eventsSuperiority7vs6++;
      if (pbp.offenseSystem == 'SUPERIORITY-6vs5') this._teamEventsCounters[pbp.teamMarker].eventsSuperiority6vs5++;
      if (pbp.offenseSystem == 'SUPERIORITY-OTHER') this._teamEventsCounters[pbp.teamMarker].eventsSuperiorityOthers++;
      if (['EQUALITY', 'EQUALITY-5+1vs6', 'EQUALITY-6vs5+1'].includes(pbp.offenseSystem)) this._teamEventsCounters[pbp.teamMarker].eventsEquality++;
      if (['INFERIORITY-5vs6', 'INFERIORITY-6vs7', 'INFERIORITY-OTHER'].includes(pbp.offenseSystem)) this._teamEventsCounters[pbp.teamMarker].eventsInferiority++;

      if (pbp.event == 'TIME_OUT') this._teamEventsCounters[pbp.teamMarker].timeOuts++;
      if (pbp.event == 'GOAL' && ['SUPERIORITY-7vs6', 'SUPERIORITY-6vs5', 'SUPERIORITY-OTHER'].includes(pbp.offenseSystem)) {
        this._teamEventsCounters[otherTeamMarker].gkReceivedInferiority++;
      }

      // if (pbp.event == 'LOST_BALL' || pbp.event == 'ATTACK_FAULT_COMMIT' || pbp.event == 'SAVE' || pbp.event == 'POST_OUT' || pbp.event == 'TECHNICAL_MISTAKE_COMMIT') {
      //   if (pbp.event == 'SAVE') {
      //     this._teamEventsDefenseEff[pbp.teamMarker].noGoals++;
      //   } else {
      //     this._teamEventsDefenseEff[otherTeamMarker].noGoals++;
      //   }
      // }

      //#endregion

      //#region Player Counters
      for (const pl of pbp.offense) {
        if (this._playerPlayedTimeCounters[pl.id] == undefined) this._playerPlayedTimeCounters[pl.id] = {...this._playerTimeCountersTpl};
        if (this._playerEventsCounters[pl.id] == undefined) this._playerEventsCounters[pl.id] = {...this._eventCountersTpl, ...this._extraPlayerCountersTpl};

        // TODO: Calculate merging with playtime records if pbp-ev time start < curr playtime(input) || pbp-ev time end > curr playtime(out)
        let startSeconds = (this._lastPbp?.eventTime.secondsSinceStartOfGame ?? 0);
        let countedSeconds = 0;
        this._core.playTimeService.playtimeEvents.filter(pt => (
          pt.eventTime.secondsSinceStartOfGame > startSeconds && pt.eventTime.secondsSinceStartOfGame < pbp.eventTime.secondsSinceStartOfGame &&
          pt.playerId == pl.id
        )).sort((a, b) => a.seq - b.seq).forEach(pt => {
          if (pt.eventType == 'PLAY_TIME_START') {
            startSeconds = pt.eventTime.secondsSinceStartOfGame;
          } else if (pt.eventType == 'PLAY_TIME_END') {
            countedSeconds += pt.eventTime.secondsSinceStartOfGame - startSeconds;
          }
        });
        const eventTimeInterval = pbp.eventTime.secondsSinceStartOfGame - startSeconds; // Everytime appearing at current pbp needs to be a last PLAY_TIME_START or there is any pt event
        this._playerPlayedTimeCounters[pl.id].playedTimeOffense += eventTimeInterval;
        if (['INFERIORITY-5vs6', 'INFERIORITY-6vs7', 'INFERIORITY-OTHER'].includes(pbp.offenseSystem)) {
          this._playerPlayedTimeCounters[pl.id].playedTimeOffenseInferiority += eventTimeInterval;
        }
        if (['EQUALITY', 'EQUALITY-5+1vs6', 'EQUALITY-6vs5+1'].includes(pbp.offenseSystem)) {
          this._playerPlayedTimeCounters[pl.id].playedTimeOffenseEquality += eventTimeInterval;
        }
        if (['SUPERIORITY-6vs5', 'SUPERIORITY-7vs6', 'SUPERIORITY-OTHER'].includes(pbp.offenseSystem)) {
          this._playerPlayedTimeCounters[pl.id].playedTimeOffenseSuperiority += eventTimeInterval;
        }

        this._playerEventsCounters[pl.id].eventsOffense++;
        if (pbp.phase == 'FAST_BREAK') this._playerEventsCounters[pl.id].eventsFastBreak++;
        if (pbp.phase == 'COUNTER_GOAL') this._playerEventsCounters[pl.id].eventsCounterGoal++;
        if (pbp.phase == 'OFFENSE_POSITIONAL') this._playerEventsCounters[pl.id].eventsOffensePositional++;
        if (pbp.offenseSystem == 'SUPERIORITY-7vs6') this._playerEventsCounters[pl.id].eventsSuperiority7vs6++;
        if (pbp.offenseSystem == 'SUPERIORITY-6vs5') this._playerEventsCounters[pl.id].eventsSuperiority6vs5++;
        if (pbp.offenseSystem == 'SUPERIORITY-OTHER') this._playerEventsCounters[pl.id].eventsSuperiorityOthers++;
        if (['EQUALITY', 'EQUALITY-5+1vs6', 'EQUALITY-6vs5+1'].includes(pbp.offenseSystem)) this._playerEventsCounters[pl.id].eventsEquality++;
        if (['INFERIORITY-5vs6', 'INFERIORITY-6vs7', 'INFERIORITY-OTHER'].includes(pbp.offenseSystem)) this._playerEventsCounters[pl.id].eventsInferiority++;
      }

      for (const pl of pbp.defense) {
        if (this._playerPlayedTimeCounters[pl.id] == undefined) this._playerPlayedTimeCounters[pl.id] = {...this._playerTimeCountersTpl};
        if (this._playerEventsCounters[pl.id] == undefined) this._playerEventsCounters[pl.id] = {...this._eventCountersTpl, ...this._extraPlayerCountersTpl};

        let startSeconds = (this._lastPbp?.eventTime.secondsSinceStartOfGame ?? 0);
        let countedSeconds = 0;
        this._core.playTimeService.playtimeEvents.filter(pt => (
          pt.eventTime.secondsSinceStartOfGame > startSeconds && pt.eventTime.secondsSinceStartOfGame < pbp.eventTime.secondsSinceStartOfGame &&
          pt.playerId == pl.id
        )).sort((a, b) => a.seq - b.seq).forEach(pt => {
          if (pt.eventType == 'PLAY_TIME_START') {
            startSeconds = pt.eventTime.secondsSinceStartOfGame;
          } else if (pt.eventType == 'PLAY_TIME_END') {
            countedSeconds += pt.eventTime.secondsSinceStartOfGame - startSeconds;
          }
        });
        const eventTimeInterval = pbp.eventTime.secondsSinceStartOfGame - startSeconds; // Everytime appearing at current pbp needs to be a last PLAY_TIME_START or there is any pt event
        this._playerPlayedTimeCounters[pl.id].playedTimeDefense += eventTimeInterval;
        if (['INFERIORITY-5vs6', 'INFERIORITY-6vs7', 'INFERIORITY-OTHER'].includes(pbp.offenseSystem)) {
          this._playerPlayedTimeCounters[pl.id].playedTimeDefenseInferiority += eventTimeInterval;
        }
        if (['EQUALITY', 'EQUALITY-5+1vs6', 'EQUALITY-6vs5+1'].includes(pbp.offenseSystem)) {
          this._playerPlayedTimeCounters[pl.id].playedTimeDefenseEquality += eventTimeInterval;
        }
        if (['SUPERIORITY-6vs5', 'SUPERIORITY-7vs6', 'SUPERIORITY-OTHER'].includes(pbp.offenseSystem)) {
          this._playerPlayedTimeCounters[pl.id].playedTimeDefenseSuperiority += eventTimeInterval;
        }

        if (pbp.event == 'YELLOW_CARD') this._playerPlayedTimeCounters[pl.id].yellowCard++;
        if (pbp.event == 'BLUE_CARD') this._playerPlayedTimeCounters[pl.id].blueCard++;
        if (pbp.event == 'RED_CARD') this._playerPlayedTimeCounters[pl.id].redCard++;

        this._playerEventsCounters[pl.id].eventsDefense++;
      }
      // Going out times calculation
      if (this._lastPbp) { // We assume we can skip opt out changes between start game and first event since we have a FIRST_HALF_START event just at start
        const offenseInPrevEvent = this._lastPbp.teamMarker == pbp.teamMarker ? this._lastPbp.offense : this._lastPbp.defense;
        const defenseInPrevEvent = this._lastPbp.teamMarker == pbp.teamMarker ? this._lastPbp.defense : this._lastPbp.offense;
        const actualPbpOffense = pbp.offense.map(pl => pl.id);
        const actualPbpDefense = pbp.offense.map(pl => pl.id);
        // Iterate on players that are at previous event and not in this event
        for(const pl of offenseInPrevEvent) if (!actualPbpOffense.includes(pl.id)) {
          let startSeconds = (this._lastPbp.eventTime.secondsSinceStartOfGame ?? 0);
          let countedSeconds = 0;
          this._core.playTimeService.playtimeEvents.filter(pt => (
            pt.eventTime.secondsSinceStartOfGame > startSeconds && pt.eventTime.secondsSinceStartOfGame < pbp.eventTime.secondsSinceStartOfGame &&
            pt.playerId == pl.id
          )).sort((a, b) => a.seq - b.seq).forEach(pt => {
            if (pt.eventType == 'PLAY_TIME_START') {
              startSeconds = pt.eventTime.secondsSinceStartOfGame;
            } else if (pt.eventType == 'PLAY_TIME_END') {
              countedSeconds += pt.eventTime.secondsSinceStartOfGame - startSeconds;
            }
          });
          this._playerPlayedTimeCounters[pl.id].playedTimeOffense += countedSeconds;
          if (['INFERIORITY-5vs6', 'INFERIORITY-6vs7', 'INFERIORITY-OTHER'].includes(pbp.offenseSystem)) {
            this._playerPlayedTimeCounters[pl.id].playedTimeOffenseInferiority += countedSeconds;
          }
          if (['EQUALITY', 'EQUALITY-5+1vs6', 'EQUALITY-6vs5+1'].includes(pbp.offenseSystem)) {
            this._playerPlayedTimeCounters[pl.id].playedTimeOffenseEquality += countedSeconds;
          }
          if (['SUPERIORITY-6vs5', 'SUPERIORITY-7vs6', 'SUPERIORITY-OTHER'].includes(pbp.offenseSystem)) {
            this._playerPlayedTimeCounters[pl.id].playedTimeOffenseSuperiority += countedSeconds;
          }
        }
        for(const pl of defenseInPrevEvent) if (!actualPbpDefense.includes(pl.id)) {
          let startSeconds = (this._lastPbp.eventTime.secondsSinceStartOfGame ?? 0);
          let countedSeconds = 0;
          this._core.playTimeService.playtimeEvents.filter(pt => (
            pt.eventTime.secondsSinceStartOfGame > startSeconds && pt.eventTime.secondsSinceStartOfGame < pbp.eventTime.secondsSinceStartOfGame &&
            pt.playerId == pl.id
          )).sort((a, b) => a.seq - b.seq).forEach(pt => {
            if (pt.eventType == 'PLAY_TIME_START') {
              startSeconds = pt.eventTime.secondsSinceStartOfGame;
            } else if (pt.eventType == 'PLAY_TIME_END') {
              countedSeconds += pt.eventTime.secondsSinceStartOfGame - startSeconds;
            }
          });
          this._playerPlayedTimeCounters[pl.id].playedTimeDefense += countedSeconds;
          if (['INFERIORITY-5vs6', 'INFERIORITY-6vs7', 'INFERIORITY-OTHER'].includes(pbp.offenseSystem)) {
            this._playerPlayedTimeCounters[pl.id].playedTimeDefenseInferiority += countedSeconds;
          }
          if (['EQUALITY', 'EQUALITY-5+1vs6', 'EQUALITY-6vs5+1'].includes(pbp.offenseSystem)) {
            this._playerPlayedTimeCounters[pl.id].playedTimeDefenseEquality += countedSeconds;
          }
          if (['SUPERIORITY-6vs5', 'SUPERIORITY-7vs6', 'SUPERIORITY-OTHER'].includes(pbp.offenseSystem)) {
            this._playerPlayedTimeCounters[pl.id].playedTimeDefenseSuperiority += countedSeconds;
          }
        }
      }

      //#endregion

      //#region Lineup Counters
      const offenseFieldUniqueSetup = this.orderedFieldSetupKey(pbp.offense);
      if (this._lineUpCounters[offenseFieldUniqueSetup] == undefined) {
        const team = pbp.teamMarker=='HOME' ? this._core.gameService.gameModel.home : this._core.gameService.gameModel.visitor;
        this._lineUpCounters[offenseFieldUniqueSetup] = {
          ...this._lineUpCountersTpl,
          lineup: offenseFieldUniqueSetup,
          teamId: team.id,
          teamName: team.name,
        };
        // console.warn('Added field unique setup: '+pbp.teamMarker, offenseFieldUniqueSetup);
      }
      const defenseFieldUniqueSetup = this.orderedFieldSetupKey(pbp.defense);
      if (this._lineUpCounters[defenseFieldUniqueSetup] == undefined) {
        const team = otherTeamMarker=='HOME' ? this._core.gameService.gameModel.home : this._core.gameService.gameModel.visitor;
        this._lineUpCounters[defenseFieldUniqueSetup] = {
          ...this._lineUpCountersTpl,
          lineup: defenseFieldUniqueSetup,
          teamId: team.id,
          teamName: team.name,
        };
        // console.warn('Added field unique setup: '+otherTeamMarker, defenseFieldUniqueSetup);
      }
      if (!['FIRST_HALF_START', 'SECOND_HALF_START'].includes(pbp.event) && this._lineUpCounters[offenseFieldUniqueSetup].lastPossId != pbp.possessionId) {
        this._lineUpCounters[offenseFieldUniqueSetup].possessionsPlayed++;
        this._lineUpCounters[offenseFieldUniqueSetup].possessionsOffense++;
      }
      if (!['FIRST_HALF_START', 'SECOND_HALF_START'].includes(pbp.event) && this._lineUpCounters[defenseFieldUniqueSetup].lastPossId != pbp.possessionId) {
        this._lineUpCounters[defenseFieldUniqueSetup].possessionsPlayed++;
        this._lineUpCounters[defenseFieldUniqueSetup].possessionsDefense++;
      }
      if (pbp.event == 'GOAL') this._lineUpCounters[offenseFieldUniqueSetup].goals++;
      if (pbp.event == 'SAVE') this._lineUpCounters[defenseFieldUniqueSetup].saves++;
      if (['LOST_BALL', 'TECHNICAL_MISTAKE_COMMIT', 'ATTACK_FAULT_COMMIT', 'PASSIVE'].includes(pbp.event)) {
        this._lineUpCounters[offenseFieldUniqueSetup].lostBalls++;
      }

      if (pbp.possessionId > 1 && (isNewPossitionEvent||pbp.event == 'SECOND_HALF_END') && !this._possHasGoal) {
        // Add noGoals to desired places (to last event of previous possession defense setup)
        this._lineUpCounters[this.orderedFieldSetupKey(this._lastPbp.defense)].noGoals++;
      }
      // if (pbp.event == 'SAVE') this._lineUpCounters[defenseFieldUniqueSetup].noGoals++;
      // if (pbp.event == 'LOST_BALL' || pbp.event == 'ATTACK_FAULT_COMMIT' || pbp.event == 'POST_OUT' || pbp.event == 'TECHNICAL_MISTAKE_COMMIT') {
      //   this._lineUpCounters[offenseFieldUniqueSetup].noGoals++;
      // }
      //#endregion

      if (pbp.event == 'GOAL') {
        this._possHasGoal = true;
      }
      if (isNewPossitionEvent && pbp.event != 'GOAL') {
        this._possHasGoal = false;
      }
      this._lastPbp = pbp;
      // this._lastPossesionId = pbp.possessionId;
      // this._lastPossesionTeam = pbp.teamMarker;
      // this._lastPbpEventTime = pbp.eventTime;
    });
  }
  //#endregion

  public transformToTeamStatisticsDto(dtoPart: Map<TeamMarker, TeamsStatsDtoPart>): TeamsStatsDto[] {
    // console.warn('LINEUP__STATS', this._lineUpCounters);
    return Array.from(this._goalConsumerCore.teamCounterMap.entries()).map(itm => {
      // const bestOffenseLineUp = Object.values(this._lineUpCounters).filter((l: any) => l.teamMarker == itm[0] && l.possessions != 0).sort((a:any, b:any) => b.goals-a.goals)[0] as any;
      // const worseOffenseLineUp = Object.values(this._lineUpCounters).filter((l: any) => l.teamMarker == itm[0] && l.possessions != 0).sort((a:any, b:any) => a.goals-b.goals)[0] as any;
      // const bestDefenseLineUp = Object.values(this._lineUpCounters).filter((l: any) => l.teamMarker == itm[0] && l.possessionsDefense != 0).sort((a:any, b:any) => b.noGoals-a.noGoals)[0] as any;
      // const worseDefenseLineUp = Object.values(this._lineUpCounters).filter((l: any) => l.teamMarker == itm[0] && l.possessionsDefense != 0).sort((a:any, b:any) => a.noGoals-b.noGoals)[0] as any;

      const otherTeamMarker = itm[0]=='HOME' ? 'VISITOR':'HOME';
      const res: TeamsStatsDto = {
        //#region Old counters
        goalsOffensePositional: itm[1].get('GOAL-OFFENSE_POSITIONAL').counter,
        goalsFastBreak: itm[1].get('GOAL-FAST_BREAK').counter,
        goalsCounterGoal: itm[1].get('GOAL-COUNTER_GOAL').counter,
        savesOffensePositional: itm[1].get('GK-SAVE-OFFENSE_POSITIONAL').counter,
        savesFastBreak: itm[1].get('GK-SAVE-FAST_BREAK').counter,
        savesCounterGoal: itm[1].get('GK-SAVE-COUNTER_GOAL').counter,
        goalsLeftWing: itm[1].get('GOAL-LEFT_WING').counter,
        goalsLeft6m: itm[1].get('GOAL-LEFT_6M').counter,
        goalsCenter6m: itm[1].get('GOAL-CENTER_6M').counter,
        goalsRight6m: itm[1].get('GOAL-RIGHT_6M').counter,
        goalsRightWing: itm[1].get('GOAL-RIGHT_WING').counter,
        goalsLeft9m: itm[1].get('GOAL-LEFT_9M').counter,
        goalsCenter9m: itm[1].get('GOAL-CENTER_9M').counter,
        goalsRight9m: itm[1].get('GOAL-RIGHT_9M').counter,
        goals7Meters: itm[1].get('GOAL-7_METERS').counter,
        goalsOppositeField: itm[1].get('GOAL-OPPOSITE_FIELD').counter,
        savesLeftWing: itm[1].get('SAVE-LEFT_WING').counter,
        savesLeft6m: itm[1].get('SAVE-LEFT_6M').counter,
        savesCenter6m: itm[1].get('SAVE-CENTER_6M').counter,
        savesRight6m: itm[1].get('SAVE-RIGHT_6M').counter,
        savesRightWing: itm[1].get('SAVE-RIGHT_WING').counter,
        savesLeft9m: itm[1].get('SAVE-LEFT_9M').counter,
        savesCenter9m: itm[1].get('SAVE-CENTER_9M').counter,
        savesRight9m: itm[1].get('SAVE-RIGHT_9M').counter,
        saves7Meters: itm[1].get('SAVE-7_METERS').counter,
        savesOppositeField: itm[1].get('SAVE-OPPOSITE_FIELD').counter,
        goals1: itm[1].get('GOAL-1').counter,
        goals2: itm[1].get('GOAL-2').counter,
        goals3: itm[1].get('GOAL-3').counter,
        goals4: itm[1].get('GOAL-4').counter,
        goals5: itm[1].get('GOAL-5').counter,
        goals6: itm[1].get('GOAL-6').counter,
        goals7: itm[1].get('GOAL-7').counter,
        goals8: itm[1].get('GOAL-8').counter,
        goals9: itm[1].get('GOAL-9').counter,
        saves1: itm[1].get('SAVE-1').counter,
        saves2: itm[1].get('SAVE-2').counter,
        saves3: itm[1].get('SAVE-3').counter,
        saves4: itm[1].get('SAVE-4').counter,
        saves5: itm[1].get('SAVE-5').counter,
        saves6: itm[1].get('SAVE-6').counter,
        saves7: itm[1].get('SAVE-7').counter,
        saves8: itm[1].get('SAVE-8').counter,
        saves9: itm[1].get('SAVE-9').counter,
        gkSaveOffensePositional: itm[1].get('GK-SAVE-OFFENSE_POSITIONAL').counter,
        gkSaveCounterGoal: itm[1].get('GK-SAVE-COUNTER_GOAL').counter,
        gkSaveFastBreak: itm[1].get('GK-SAVE-FAST_BREAK').counter,
        postOutCenter6m: itm[1].get('POST_OUT-CENTER_6M').counter,
        postOutCenter9m: itm[1].get('POST_OUT-CENTER_9M').counter,
        postOutLeft6m: itm[1].get('POST_OUT-LEFT_6M').counter,
        postOutLeft9m: itm[1].get('POST_OUT-LEFT_9M').counter,
        postOutLeftWing: itm[1].get('POST_OUT-LEFT_WING').counter,
        postOutOppositeField: itm[1].get('POST_OUT-OPPOSITE_FIELD').counter,
        postOutRight6m: itm[1].get('POST_OUT-RIGHT_6M').counter,
        postOutRight9m: itm[1].get('POST_OUT-RIGHT_9M').counter,
        postOutRightWing: itm[1].get('POST_OUT-RIGHT_WING').counter,
        postOut7Meters: itm[1].get('POST_OUT-7_METERS').counter,
        postOut0: itm[1].get('POST_OUT-0').counter,
        postOut10: itm[1].get('POST_OUT-10').counter,
        postOut11: itm[1].get('POST_OUT-11').counter,
        goalsLeftWing0: itm[1].get('CONNECT_G_LEFT_WING_0').counter,
        goalsLeftWing1: itm[1].get('CONNECT_G_LEFT_WING_1').counter,
        goalsLeftWing2: itm[1].get('CONNECT_G_LEFT_WING_2').counter,
        goalsLeftWing3: itm[1].get('CONNECT_G_LEFT_WING_3').counter,
        goalsLeftWing4: itm[1].get('CONNECT_G_LEFT_WING_4').counter,
        goalsLeftWing5: itm[1].get('CONNECT_G_LEFT_WING_5').counter,
        goalsLeftWing6: itm[1].get('CONNECT_G_LEFT_WING_6').counter,
        goalsLeftWing7: itm[1].get('CONNECT_G_LEFT_WING_7').counter,
        goalsLeftWing8: itm[1].get('CONNECT_G_LEFT_WING_8').counter,
        goalsLeftWing9: itm[1].get('CONNECT_G_LEFT_WING_9').counter,
        goalsLeftWing10: itm[1].get('CONNECT_G_LEFT_WING_10').counter,
        goalsLeftWing11: itm[1].get('CONNECT_G_LEFT_WING_11').counter,
        goalsLeft6m0: itm[1].get('CONNECT_G_LEFT_6M_0').counter,
        goalsLeft6m1: itm[1].get('CONNECT_G_LEFT_6M_1').counter,
        goalsLeft6m2: itm[1].get('CONNECT_G_LEFT_6M_2').counter,
        goalsLeft6m3: itm[1].get('CONNECT_G_LEFT_6M_3').counter,
        goalsLeft6m4: itm[1].get('CONNECT_G_LEFT_6M_4').counter,
        goalsLeft6m5: itm[1].get('CONNECT_G_LEFT_6M_5').counter,
        goalsLeft6m6: itm[1].get('CONNECT_G_LEFT_6M_6').counter,
        goalsLeft6m7: itm[1].get('CONNECT_G_LEFT_6M_7').counter,
        goalsLeft6m8: itm[1].get('CONNECT_G_LEFT_6M_8').counter,
        goalsLeft6m9: itm[1].get('CONNECT_G_LEFT_6M_9').counter,
        goalsLeft6m10: itm[1].get('CONNECT_G_LEFT_6M_10').counter,
        goalsLeft6m11: itm[1].get('CONNECT_G_LEFT_6M_11').counter,
        goalsCenter6m0: itm[1].get('CONNECT_G_CENTER_6M_0').counter,
        goalsCenter6m1: itm[1].get('CONNECT_G_CENTER_6M_1').counter,
        goalsCenter6m2: itm[1].get('CONNECT_G_CENTER_6M_2').counter,
        goalsCenter6m3: itm[1].get('CONNECT_G_CENTER_6M_3').counter,
        goalsCenter6m4: itm[1].get('CONNECT_G_CENTER_6M_4').counter,
        goalsCenter6m5: itm[1].get('CONNECT_G_CENTER_6M_5').counter,
        goalsCenter6m6: itm[1].get('CONNECT_G_CENTER_6M_6').counter,
        goalsCenter6m7: itm[1].get('CONNECT_G_CENTER_6M_7').counter,
        goalsCenter6m8: itm[1].get('CONNECT_G_CENTER_6M_8').counter,
        goalsCenter6m9: itm[1].get('CONNECT_G_CENTER_6M_9').counter,
        goalsCenter6m10: itm[1].get('CONNECT_G_CENTER_6M_10').counter,
        goalsCenter6m11: itm[1].get('CONNECT_G_CENTER_6M_11').counter,
        goalsRight6m0: itm[1].get('CONNECT_G_RIGHT_6M_0').counter,
        goalsRight6m1: itm[1].get('CONNECT_G_RIGHT_6M_1').counter,
        goalsRight6m2: itm[1].get('CONNECT_G_RIGHT_6M_2').counter,
        goalsRight6m3: itm[1].get('CONNECT_G_RIGHT_6M_3').counter,
        goalsRight6m4: itm[1].get('CONNECT_G_RIGHT_6M_4').counter,
        goalsRight6m5: itm[1].get('CONNECT_G_RIGHT_6M_5').counter,
        goalsRight6m6: itm[1].get('CONNECT_G_RIGHT_6M_6').counter,
        goalsRight6m7: itm[1].get('CONNECT_G_RIGHT_6M_7').counter,
        goalsRight6m8: itm[1].get('CONNECT_G_RIGHT_6M_8').counter,
        goalsRight6m9: itm[1].get('CONNECT_G_RIGHT_6M_9').counter,
        goalsRight6m10: itm[1].get('CONNECT_G_RIGHT_6M_10').counter,
        goalsRight6m11: itm[1].get('CONNECT_G_RIGHT_6M_11').counter,
        goalsRightWing0: itm[1].get('CONNECT_G_RIGHT_WING_0').counter,
        goalsRightWing1: itm[1].get('CONNECT_G_RIGHT_WING_1').counter,
        goalsRightWing2: itm[1].get('CONNECT_G_RIGHT_WING_2').counter,
        goalsRightWing3: itm[1].get('CONNECT_G_RIGHT_WING_3').counter,
        goalsRightWing4: itm[1].get('CONNECT_G_RIGHT_WING_4').counter,
        goalsRightWing5: itm[1].get('CONNECT_G_RIGHT_WING_5').counter,
        goalsRightWing6: itm[1].get('CONNECT_G_RIGHT_WING_6').counter,
        goalsRightWing7: itm[1].get('CONNECT_G_RIGHT_WING_7').counter,
        goalsRightWing8: itm[1].get('CONNECT_G_RIGHT_WING_8').counter,
        goalsRightWing9: itm[1].get('CONNECT_G_RIGHT_WING_9').counter,
        goalsRightWing10: itm[1].get('CONNECT_G_RIGHT_WING_10').counter,
        goalsRightWing11: itm[1].get('CONNECT_G_RIGHT_WING_11').counter,
        goalsLeft9m0: itm[1].get('CONNECT_G_LEFT_9M_0').counter,
        goalsLeft9m1: itm[1].get('CONNECT_G_LEFT_9M_1').counter,
        goalsLeft9m2: itm[1].get('CONNECT_G_LEFT_9M_2').counter,
        goalsLeft9m3: itm[1].get('CONNECT_G_LEFT_9M_3').counter,
        goalsLeft9m4: itm[1].get('CONNECT_G_LEFT_9M_4').counter,
        goalsLeft9m5: itm[1].get('CONNECT_G_LEFT_9M_5').counter,
        goalsLeft9m6: itm[1].get('CONNECT_G_LEFT_9M_6').counter,
        goalsLeft9m7: itm[1].get('CONNECT_G_LEFT_9M_7').counter,
        goalsLeft9m8: itm[1].get('CONNECT_G_LEFT_9M_8').counter,
        goalsLeft9m9: itm[1].get('CONNECT_G_LEFT_9M_9').counter,
        goalsLeft9m10: itm[1].get('CONNECT_G_LEFT_9M_10').counter,
        goalsLeft9m11: itm[1].get('CONNECT_G_LEFT_9M_11').counter,
        goalsCenter9m0: itm[1].get('CONNECT_G_CENTER_9M_0').counter,
        goalsCenter9m1: itm[1].get('CONNECT_G_CENTER_9M_1').counter,
        goalsCenter9m2: itm[1].get('CONNECT_G_CENTER_9M_2').counter,
        goalsCenter9m3: itm[1].get('CONNECT_G_CENTER_9M_3').counter,
        goalsCenter9m4: itm[1].get('CONNECT_G_CENTER_9M_4').counter,
        goalsCenter9m5: itm[1].get('CONNECT_G_CENTER_9M_5').counter,
        goalsCenter9m6: itm[1].get('CONNECT_G_CENTER_9M_6').counter,
        goalsCenter9m7: itm[1].get('CONNECT_G_CENTER_9M_7').counter,
        goalsCenter9m8: itm[1].get('CONNECT_G_CENTER_9M_8').counter,
        goalsCenter9m9: itm[1].get('CONNECT_G_CENTER_9M_9').counter,
        goalsCenter9m10: itm[1].get('CONNECT_G_CENTER_9M_10').counter,
        goalsCenter9m11: itm[1].get('CONNECT_G_CENTER_9M_11').counter,
        goalsRight9m0: itm[1].get('CONNECT_G_RIGHT_9M_0').counter,
        goalsRight9m1: itm[1].get('CONNECT_G_RIGHT_9M_1').counter,
        goalsRight9m2: itm[1].get('CONNECT_G_RIGHT_9M_2').counter,
        goalsRight9m3: itm[1].get('CONNECT_G_RIGHT_9M_3').counter,
        goalsRight9m4: itm[1].get('CONNECT_G_RIGHT_9M_4').counter,
        goalsRight9m5: itm[1].get('CONNECT_G_RIGHT_9M_5').counter,
        goalsRight9m6: itm[1].get('CONNECT_G_RIGHT_9M_6').counter,
        goalsRight9m7: itm[1].get('CONNECT_G_RIGHT_9M_7').counter,
        goalsRight9m8: itm[1].get('CONNECT_G_RIGHT_9M_8').counter,
        goalsRight9m9: itm[1].get('CONNECT_G_RIGHT_9M_9').counter,
        goalsRight9m10: itm[1].get('CONNECT_G_RIGHT_9M_10').counter,
        goalsRight9m11: itm[1].get('CONNECT_G_RIGHT_9M_11').counter,
        goals7Meters0: itm[1].get('CONNECT_G_7_METERS_0').counter,
        goals7Meters1: itm[1].get('CONNECT_G_7_METERS_1').counter,
        goals7Meters2: itm[1].get('CONNECT_G_7_METERS_2').counter,
        goals7Meters3: itm[1].get('CONNECT_G_7_METERS_3').counter,
        goals7Meters4: itm[1].get('CONNECT_G_7_METERS_4').counter,
        goals7Meters5: itm[1].get('CONNECT_G_7_METERS_5').counter,
        goals7Meters6: itm[1].get('CONNECT_G_7_METERS_6').counter,
        goals7Meters7: itm[1].get('CONNECT_G_7_METERS_7').counter,
        goals7Meters8: itm[1].get('CONNECT_G_7_METERS_8').counter,
        goals7Meters9: itm[1].get('CONNECT_G_7_METERS_9').counter,
        goals7Meters10: itm[1].get('CONNECT_G_7_METERS_10').counter,
        goals7Meters11: itm[1].get('CONNECT_G_7_METERS_11').counter,
        goalsOppositeField0: itm[1].get('CONNECT_G_OPPOSITE_FIELD_0').counter,
        goalsOppositeField1: itm[1].get('CONNECT_G_OPPOSITE_FIELD_1').counter,
        goalsOppositeField2: itm[1].get('CONNECT_G_OPPOSITE_FIELD_2').counter,
        goalsOppositeField3: itm[1].get('CONNECT_G_OPPOSITE_FIELD_3').counter,
        goalsOppositeField4: itm[1].get('CONNECT_G_OPPOSITE_FIELD_4').counter,
        goalsOppositeField5: itm[1].get('CONNECT_G_OPPOSITE_FIELD_5').counter,
        goalsOppositeField6: itm[1].get('CONNECT_G_OPPOSITE_FIELD_6').counter,
        goalsOppositeField7: itm[1].get('CONNECT_G_OPPOSITE_FIELD_7').counter,
        goalsOppositeField8: itm[1].get('CONNECT_G_OPPOSITE_FIELD_8').counter,
        goalsOppositeField9: itm[1].get('CONNECT_G_OPPOSITE_FIELD_9').counter,
        goalsOppositeField10: itm[1].get('CONNECT_G_OPPOSITE_FIELD_10').counter,
        goalsOppositeField11: itm[1].get('CONNECT_G_OPPOSITE_FIELD_11').counter,
        shootsLeftWing0: itm[1].get('CONNECT_S_LEFT_WING_0').counter,
        shootsLeftWing1: itm[1].get('CONNECT_S_LEFT_WING_1').counter,
        shootsLeftWing2: itm[1].get('CONNECT_S_LEFT_WING_2').counter,
        shootsLeftWing3: itm[1].get('CONNECT_S_LEFT_WING_3').counter,
        shootsLeftWing4: itm[1].get('CONNECT_S_LEFT_WING_4').counter,
        shootsLeftWing5: itm[1].get('CONNECT_S_LEFT_WING_5').counter,
        shootsLeftWing6: itm[1].get('CONNECT_S_LEFT_WING_6').counter,
        shootsLeftWing7: itm[1].get('CONNECT_S_LEFT_WING_7').counter,
        shootsLeftWing8: itm[1].get('CONNECT_S_LEFT_WING_8').counter,
        shootsLeftWing9: itm[1].get('CONNECT_S_LEFT_WING_9').counter,
        shootsLeftWing10: itm[1].get('CONNECT_S_LEFT_WING_10').counter,
        shootsLeftWing11: itm[1].get('CONNECT_S_LEFT_WING_11').counter,
        shootsLeft6m0: itm[1].get('CONNECT_S_LEFT_6M_0').counter,
        shootsLeft6m1: itm[1].get('CONNECT_S_LEFT_6M_1').counter,
        shootsLeft6m2: itm[1].get('CONNECT_S_LEFT_6M_2').counter,
        shootsLeft6m3: itm[1].get('CONNECT_S_LEFT_6M_3').counter,
        shootsLeft6m4: itm[1].get('CONNECT_S_LEFT_6M_4').counter,
        shootsLeft6m5: itm[1].get('CONNECT_S_LEFT_6M_5').counter,
        shootsLeft6m6: itm[1].get('CONNECT_S_LEFT_6M_6').counter,
        shootsLeft6m7: itm[1].get('CONNECT_S_LEFT_6M_7').counter,
        shootsLeft6m8: itm[1].get('CONNECT_S_LEFT_6M_8').counter,
        shootsLeft6m9: itm[1].get('CONNECT_S_LEFT_6M_9').counter,
        shootsLeft6m10: itm[1].get('CONNECT_S_LEFT_6M_10').counter,
        shootsLeft6m11: itm[1].get('CONNECT_S_LEFT_6M_11').counter,
        shootsCenter6m0: itm[1].get('CONNECT_S_CENTER_6M_0').counter,
        shootsCenter6m1: itm[1].get('CONNECT_S_CENTER_6M_1').counter,
        shootsCenter6m2: itm[1].get('CONNECT_S_CENTER_6M_2').counter,
        shootsCenter6m3: itm[1].get('CONNECT_S_CENTER_6M_3').counter,
        shootsCenter6m4: itm[1].get('CONNECT_S_CENTER_6M_4').counter,
        shootsCenter6m5: itm[1].get('CONNECT_S_CENTER_6M_5').counter,
        shootsCenter6m6: itm[1].get('CONNECT_S_CENTER_6M_6').counter,
        shootsCenter6m7: itm[1].get('CONNECT_S_CENTER_6M_7').counter,
        shootsCenter6m8: itm[1].get('CONNECT_S_CENTER_6M_8').counter,
        shootsCenter6m9: itm[1].get('CONNECT_S_CENTER_6M_9').counter,
        shootsCenter6m10: itm[1].get('CONNECT_S_CENTER_6M_10').counter,
        shootsCenter6m11: itm[1].get('CONNECT_S_CENTER_6M_11').counter,
        shootsRight6m0: itm[1].get('CONNECT_S_RIGHT_6M_0').counter,
        shootsRight6m1: itm[1].get('CONNECT_S_RIGHT_6M_1').counter,
        shootsRight6m2: itm[1].get('CONNECT_S_RIGHT_6M_2').counter,
        shootsRight6m3: itm[1].get('CONNECT_S_RIGHT_6M_3').counter,
        shootsRight6m4: itm[1].get('CONNECT_S_RIGHT_6M_4').counter,
        shootsRight6m5: itm[1].get('CONNECT_S_RIGHT_6M_5').counter,
        shootsRight6m6: itm[1].get('CONNECT_S_RIGHT_6M_6').counter,
        shootsRight6m7: itm[1].get('CONNECT_S_RIGHT_6M_7').counter,
        shootsRight6m8: itm[1].get('CONNECT_S_RIGHT_6M_8').counter,
        shootsRight6m9: itm[1].get('CONNECT_S_RIGHT_6M_9').counter,
        shootsRight6m10: itm[1].get('CONNECT_S_RIGHT_6M_10').counter,
        shootsRight6m11: itm[1].get('CONNECT_S_RIGHT_6M_11').counter,
        shootsRightWing0: itm[1].get('CONNECT_S_RIGHT_WING_0').counter,
        shootsRightWing1: itm[1].get('CONNECT_S_RIGHT_WING_1').counter,
        shootsRightWing2: itm[1].get('CONNECT_S_RIGHT_WING_2').counter,
        shootsRightWing3: itm[1].get('CONNECT_S_RIGHT_WING_3').counter,
        shootsRightWing4: itm[1].get('CONNECT_S_RIGHT_WING_4').counter,
        shootsRightWing5: itm[1].get('CONNECT_S_RIGHT_WING_5').counter,
        shootsRightWing6: itm[1].get('CONNECT_S_RIGHT_WING_6').counter,
        shootsRightWing7: itm[1].get('CONNECT_S_RIGHT_WING_7').counter,
        shootsRightWing8: itm[1].get('CONNECT_S_RIGHT_WING_8').counter,
        shootsRightWing9: itm[1].get('CONNECT_S_RIGHT_WING_9').counter,
        shootsRightWing10: itm[1].get('CONNECT_S_RIGHT_WING_10').counter,
        shootsRightWing11: itm[1].get('CONNECT_S_RIGHT_WING_11').counter,
        shootsLeft9m0: itm[1].get('CONNECT_S_LEFT_9M_0').counter,
        shootsLeft9m1: itm[1].get('CONNECT_S_LEFT_9M_1').counter,
        shootsLeft9m2: itm[1].get('CONNECT_S_LEFT_9M_2').counter,
        shootsLeft9m3: itm[1].get('CONNECT_S_LEFT_9M_3').counter,
        shootsLeft9m4: itm[1].get('CONNECT_S_LEFT_9M_4').counter,
        shootsLeft9m5: itm[1].get('CONNECT_S_LEFT_9M_5').counter,
        shootsLeft9m6: itm[1].get('CONNECT_S_LEFT_9M_6').counter,
        shootsLeft9m7: itm[1].get('CONNECT_S_LEFT_9M_7').counter,
        shootsLeft9m8: itm[1].get('CONNECT_S_LEFT_9M_8').counter,
        shootsLeft9m9: itm[1].get('CONNECT_S_LEFT_9M_9').counter,
        shootsLeft9m10: itm[1].get('CONNECT_S_LEFT_9M_10').counter,
        shootsLeft9m11: itm[1].get('CONNECT_S_LEFT_9M_11').counter,
        shootsCenter9m0: itm[1].get('CONNECT_S_CENTER_9M_0').counter,
        shootsCenter9m1: itm[1].get('CONNECT_S_CENTER_9M_1').counter,
        shootsCenter9m2: itm[1].get('CONNECT_S_CENTER_9M_2').counter,
        shootsCenter9m3: itm[1].get('CONNECT_S_CENTER_9M_3').counter,
        shootsCenter9m4: itm[1].get('CONNECT_S_CENTER_9M_4').counter,
        shootsCenter9m5: itm[1].get('CONNECT_S_CENTER_9M_5').counter,
        shootsCenter9m6: itm[1].get('CONNECT_S_CENTER_9M_6').counter,
        shootsCenter9m7: itm[1].get('CONNECT_S_CENTER_9M_7').counter,
        shootsCenter9m8: itm[1].get('CONNECT_S_CENTER_9M_8').counter,
        shootsCenter9m9: itm[1].get('CONNECT_S_CENTER_9M_9').counter,
        shootsCenter9m10: itm[1].get('CONNECT_S_CENTER_9M_10').counter,
        shootsCenter9m11: itm[1].get('CONNECT_S_CENTER_9M_11').counter,
        shootsRight9m0: itm[1].get('CONNECT_S_RIGHT_9M_0').counter,
        shootsRight9m1: itm[1].get('CONNECT_S_RIGHT_9M_1').counter,
        shootsRight9m2: itm[1].get('CONNECT_S_RIGHT_9M_2').counter,
        shootsRight9m3: itm[1].get('CONNECT_S_RIGHT_9M_3').counter,
        shootsRight9m4: itm[1].get('CONNECT_S_RIGHT_9M_4').counter,
        shootsRight9m5: itm[1].get('CONNECT_S_RIGHT_9M_5').counter,
        shootsRight9m6: itm[1].get('CONNECT_S_RIGHT_9M_6').counter,
        shootsRight9m7: itm[1].get('CONNECT_S_RIGHT_9M_7').counter,
        shootsRight9m8: itm[1].get('CONNECT_S_RIGHT_9M_8').counter,
        shootsRight9m9: itm[1].get('CONNECT_S_RIGHT_9M_9').counter,
        shootsRight9m10: itm[1].get('CONNECT_S_RIGHT_9M_10').counter,
        shootsRight9m11: itm[1].get('CONNECT_S_RIGHT_9M_11').counter,
        shoots7Meters0: itm[1].get('CONNECT_S_7_METERS_0').counter,
        shoots7Meters1: itm[1].get('CONNECT_S_7_METERS_1').counter,
        shoots7Meters2: itm[1].get('CONNECT_S_7_METERS_2').counter,
        shoots7Meters3: itm[1].get('CONNECT_S_7_METERS_3').counter,
        shoots7Meters4: itm[1].get('CONNECT_S_7_METERS_4').counter,
        shoots7Meters5: itm[1].get('CONNECT_S_7_METERS_5').counter,
        shoots7Meters6: itm[1].get('CONNECT_S_7_METERS_6').counter,
        shoots7Meters7: itm[1].get('CONNECT_S_7_METERS_7').counter,
        shoots7Meters8: itm[1].get('CONNECT_S_7_METERS_8').counter,
        shoots7Meters9: itm[1].get('CONNECT_S_7_METERS_9').counter,
        shoots7Meters10: itm[1].get('CONNECT_S_7_METERS_10').counter,
        shoots7Meters11: itm[1].get('CONNECT_S_7_METERS_11').counter,
        shootsOppositeField0: itm[1].get('CONNECT_S_OPPOSITE_FIELD_0').counter,
        shootsOppositeField1: itm[1].get('CONNECT_S_OPPOSITE_FIELD_1').counter,
        shootsOppositeField2: itm[1].get('CONNECT_S_OPPOSITE_FIELD_2').counter,
        shootsOppositeField3: itm[1].get('CONNECT_S_OPPOSITE_FIELD_3').counter,
        shootsOppositeField4: itm[1].get('CONNECT_S_OPPOSITE_FIELD_4').counter,
        shootsOppositeField5: itm[1].get('CONNECT_S_OPPOSITE_FIELD_5').counter,
        shootsOppositeField6: itm[1].get('CONNECT_S_OPPOSITE_FIELD_6').counter,
        shootsOppositeField7: itm[1].get('CONNECT_S_OPPOSITE_FIELD_7').counter,
        shootsOppositeField8: itm[1].get('CONNECT_S_OPPOSITE_FIELD_8').counter,
        shootsOppositeField9: itm[1].get('CONNECT_S_OPPOSITE_FIELD_9').counter,
        shootsOppositeField10: itm[1].get('CONNECT_S_OPPOSITE_FIELD_10').counter,
        shootsOppositeField11: itm[1].get('CONNECT_S_OPPOSITE_FIELD_11').counter,
        shots7Meters: itm[1].get('7-M_FAILED_SHOT').counter + itm[1].get('GOAL-7_METERS').counter,
        gkReceivedOffensePositional: itm[1].get('GKRECEIVED-OFFENSE_POSITIONAL').counter,
        gkReceivedCounterGoal: itm[1].get('GKRECEIVED-COUNTER_GOAL').counter,
        gkReceivedFastBreak: itm[1].get('GKRECEIVED-FAST_BREAK').counter,
        opponentFailedShots: itm[1].get('OPPONENT_FAILED_SHOTX').counter,

        teamId: dtoPart.get(itm[0]).teamId,
        teamName: dtoPart.get(itm[0]).teamName,
        possessionsPlayed: dtoPart.get(itm[0]).possessionsPlayed,
        goals: dtoPart.get(itm[0]).goals,
        saves: dtoPart.get(itm[0]).saves,
        lostBalls: dtoPart.get(itm[0]).lostBalls,
        lostBallsOffensePositional: dtoPart.get(itm[0]).lostBallsOffensePositional,
        lostBallsFastBreak: dtoPart.get(itm[0]).lostBallsFastBreak,
        lostBallsCounterGoal: dtoPart.get(itm[0]).lostBallsCounterGoal,
        attackFaultProvoke: dtoPart.get(itm[0]).attackFaultProvoke,
        attackFaultCommit: dtoPart.get(itm[0]).attackFaultCommit,
        technicalMistakeProvoke: dtoPart.get(itm[0]).technicalMistakeProvoke,
        technicalMistakeCommit: dtoPart.get(itm[0]).technicalMistakeCommit,
        pace: dtoPart.get(itm[0]).pace,
        lostBallProvoke: dtoPart.get(itm[0]).lostBallProvoke,
        lostBall: dtoPart.get(itm[0]).lostBall,
        twoMinCommit: dtoPart.get(itm[0]).twoMinCommit,
        twoMinProvoke: dtoPart.get(itm[0]).twoMinProvoke,
        sevenMetersCommit: dtoPart.get(itm[0]).sevenMetersCommit,
        sevenMetersProvoke: dtoPart.get(itm[0]).sevenMetersProvoke,
        sevenMSuspCommit: dtoPart.get(itm[0]).sevenMSuspCommit,
        sevenMSuspProvoke: dtoPart.get(itm[0]).sevenMSuspProvoke,
        foulCommit: dtoPart.get(itm[0]).foulCommit,
        foulReceive: dtoPart.get(itm[0]).foulReceive,
        oneAndOneLost: dtoPart.get(itm[0]).oneAndOneLost,
        oneAndOneWon: dtoPart.get(itm[0]).oneAndOneWon,
        block: dtoPart.get(itm[0]).block,
        shotBlocked: dtoPart.get(itm[0]).shotBlocked,
        efficiency: dtoPart.get(itm[0]).efficiency,
        shotEfficiency: dtoPart.get(itm[0]).shotEfficiency,
        lostBallPerc: dtoPart.get(itm[0]).lostBallPerc,
        def6To0: dtoPart.get(itm[0]).def6To0,
        def5To1: dtoPart.get(itm[0]).def5To1,
        def3to2to1: dtoPart.get(itm[0]).def3to2to1,
        def4to2: dtoPart.get(itm[0]).def4to2,
        defOther: dtoPart.get(itm[0]).defOther,
        possessionsOffensePositional: dtoPart.get(itm[0]).possessionsOffensePositional,
        possessionsCounterGoal: dtoPart.get(itm[0]).possessionsCounterGoal,
        possessionsFastBreak: dtoPart.get(itm[0]).possessionsFastBreak,
        effectivePossessionsOffensePositional: dtoPart.get(itm[0]).effectivePossessionsOffensePositional,
        effectivePossessionsCounterGoal: dtoPart.get(itm[0]).effectivePossessionsCounterGoal,
        effectivePossessionsFastBreak: dtoPart.get(itm[0]).effectivePossessionsFastBreak,
        effOffensePositionalEquality: dtoPart.get(itm[0]).effOffensePositionalEquality,
        effFastBreakEquality: dtoPart.get(itm[0]).effFastBreakEquality,
        effCounterGoalEquality: dtoPart.get(itm[0]).effCounterGoalEquality,
        effOffensePositionalSuperiority: dtoPart.get(itm[0]).effOffensePositionalSuperiority,
        effFastBreakSuperiority: dtoPart.get(itm[0]).effFastBreakSuperiority,
        effCounterGoalSuperiority: dtoPart.get(itm[0]).effCounterGoalSuperiority,
        effOffensePositionalInferiority: dtoPart.get(itm[0]).effOffensePositionalInferiority,
        effFastBreakInferiority: dtoPart.get(itm[0]).effFastBreakInferiority,
        effCounterGoalInferiority: dtoPart.get(itm[0]).effCounterGoalInferiority,
        effAgainst60: 0,
        effAgainst51: 0,
        effAgainst321: 0,
        effAgainst42: 0,
        effAgainstOther: 0,
        postOutOffensePositional: dtoPart.get(itm[0]).postOutOffensePositional,
        postOutFastBreak: dtoPart.get(itm[0]).postOutFastBreak,
        postOutCounterGoal: dtoPart.get(itm[0]).postOutCounterGoal,
        failedShotsOffensePositional: dtoPart.get(itm[0]).failedShotsOffensePositional,
        failedShotsFastBreak: dtoPart.get(itm[0]).failedShotsFastBreak,
        failedShotsCounterGoal: dtoPart.get(itm[0]).failedShotsCounterGoal,
        sixZeroSuperiorityEfficiency: dtoPart.get(itm[0]).sixZeroSuperiorityEfficiency,
        fiveOneSuperiorityEfficiency: dtoPart.get(itm[0]).fiveOneSuperiorityEfficiency,
        threeTwoOneSuperiorityEfficiency: dtoPart.get(itm[0]).threeTwoOneSuperiorityEfficiency,
        fourTwoSuperiorityEfficiency: dtoPart.get(itm[0]).fourTwoSuperiorityEfficiency,
        otherSuperiorityEfficiency: dtoPart.get(itm[0]).otherSuperiorityEfficiency,
        sixZeroInferiorityEfficiency: dtoPart.get(itm[0]).sixZeroInferiorityEfficiency,
        fiveOneInferiorityEfficiency: dtoPart.get(itm[0]).fiveOneInferiorityEfficiency,
        threeTwoOneInferiorityEfficiency: dtoPart.get(itm[0]).threeTwoOneInferiorityEfficiency,
        fourTwoInferiorityEfficiency: dtoPart.get(itm[0]).fourTwoInferiorityEfficiency,
        otherInferiorityEfficiency: dtoPart.get(itm[0]).otherInferiorityEfficiency,
        sixZeroEqualityEfficiency: dtoPart.get(itm[0]).sixZeroEqualityEfficiency,
        fiveOneEqualityEfficiency: dtoPart.get(itm[0]).fiveOneEqualityEfficiency,
        threeTwoOneEqualityEfficiency: dtoPart.get(itm[0]).threeTwoOneEqualityEfficiency,
        fourTwoEqualityEfficiency: dtoPart.get(itm[0]).fourTwoEqualityEfficiency,
        otherEqualityEfficiency: dtoPart.get(itm[0]).otherEqualityEfficiency,
        effSuperiority: 0,
        eff7vs6: 0,
        effDefense: dtoPart.get(itm[0]).effDefense,
        possMin5: dtoPart.get(itm[0]).possMin5,
        possMin10: dtoPart.get(itm[0]).possMin10,
        possMin15: dtoPart.get(itm[0]).possMin15,
        possMin20: dtoPart.get(itm[0]).possMin20,
        possMin25: dtoPart.get(itm[0]).possMin25,
        possMin30: dtoPart.get(itm[0]).possMin30,
        possMin35: dtoPart.get(itm[0]).possMin35,
        possMin40: dtoPart.get(itm[0]).possMin40,
        possMin45: dtoPart.get(itm[0]).possMin45,
        possMin50: dtoPart.get(itm[0]).possMin50,
        possMin55: dtoPart.get(itm[0]).possMin55,
        possMin60: dtoPart.get(itm[0]).possMin60,

        lostBallMin5: dtoPart.get(itm[0]).lostBallMin5,
        lostBallMin10: dtoPart.get(itm[0]).lostBallMin10,
        lostBallMin15: dtoPart.get(itm[0]).lostBallMin15,
        lostBallMin20: dtoPart.get(itm[0]).lostBallMin20,
        lostBallMin25: dtoPart.get(itm[0]).lostBallMin25,
        lostBallMin30: dtoPart.get(itm[0]).lostBallMin30,
        lostBallMin35: dtoPart.get(itm[0]).lostBallMin35,
        lostBallMin40: dtoPart.get(itm[0]).lostBallMin40,
        lostBallMin45: dtoPart.get(itm[0]).lostBallMin45,
        lostBallMin50: dtoPart.get(itm[0]).lostBallMin50,
        lostBallMin55: dtoPart.get(itm[0]).lostBallMin55,
        lostBallMin60: dtoPart.get(itm[0]).lostBallMin60,
        goalMin5: dtoPart.get(itm[0]).goalMin5,
        goalMin10: dtoPart.get(itm[0]).goalMin10,
        goalMin15: dtoPart.get(itm[0]).goalMin15,
        goalMin20: dtoPart.get(itm[0]).goalMin20,
        goalMin25: dtoPart.get(itm[0]).goalMin25,
        goalMin30: dtoPart.get(itm[0]).goalMin30,
        goalMin35: dtoPart.get(itm[0]).goalMin35,
        goalMin40: dtoPart.get(itm[0]).goalMin40,
        goalMin45: dtoPart.get(itm[0]).goalMin45,
        goalMin50: dtoPart.get(itm[0]).goalMin50,
        goalMin55: dtoPart.get(itm[0]).goalMin55,
        goalMin60: dtoPart.get(itm[0]).goalMin60,
        savesMin5: dtoPart.get(itm[0]).savesMin5,
        savesMin10: dtoPart.get(itm[0]).savesMin10,
        savesMin15: dtoPart.get(itm[0]).savesMin15,
        savesMin20: dtoPart.get(itm[0]).savesMin20,
        savesMin25: dtoPart.get(itm[0]).savesMin25,
        savesMin30: dtoPart.get(itm[0]).savesMin30,
        savesMin35: dtoPart.get(itm[0]).savesMin35,
        savesMin40: dtoPart.get(itm[0]).savesMin40,
        savesMin45: dtoPart.get(itm[0]).savesMin45,
        savesMin50: dtoPart.get(itm[0]).savesMin50,
        savesMin55: dtoPart.get(itm[0]).savesMin55,
        savesMin60: dtoPart.get(itm[0]).savesMin60,
        assistGkLw: dtoPart.get(itm[0]).assistGkLw,
        assistGkRw: dtoPart.get(itm[0]).assistGkRw,
        assistGkLb: dtoPart.get(itm[0]).assistGkLb,
        assistGkRb: dtoPart.get(itm[0]).assistGkRb,
        assistGkCb: dtoPart.get(itm[0]).assistGkCb,
        assistGkLp: dtoPart.get(itm[0]).assistGkLp,
        assistLwGk: dtoPart.get(itm[0]).assistLwGk,
        assistLwRw: dtoPart.get(itm[0]).assistLwRw,
        assistLwLb: dtoPart.get(itm[0]).assistLwLb,
        assistLwRb: dtoPart.get(itm[0]).assistLwRb,
        assistLwCb: dtoPart.get(itm[0]).assistLwCb,
        assistLwLp: dtoPart.get(itm[0]).assistLwLp,
        assistRwGk: dtoPart.get(itm[0]).assistRwGk,
        assistRwLw: dtoPart.get(itm[0]).assistRwLw,
        assistRwLb: dtoPart.get(itm[0]).assistRwLb,
        assistRwRb: dtoPart.get(itm[0]).assistRwRb,
        assistRwCb: dtoPart.get(itm[0]).assistRwCb,
        assistRwLp: dtoPart.get(itm[0]).assistRwLp,
        assistLbGk: dtoPart.get(itm[0]).assistLbGk,
        assistLbLw: dtoPart.get(itm[0]).assistLbLw,
        assistLbRw: dtoPart.get(itm[0]).assistLbRw,
        assistLbRb: dtoPart.get(itm[0]).assistLbRb,
        assistLbCb: dtoPart.get(itm[0]).assistLbCb,
        assistLbLp: dtoPart.get(itm[0]).assistLbLp,
        assistRbGk: dtoPart.get(itm[0]).assistRbGk,
        assistRbLw: dtoPart.get(itm[0]).assistRbLw,
        assistRbRw: dtoPart.get(itm[0]).assistRbRw,
        assistRbLb: dtoPart.get(itm[0]).assistRbLb,
        assistRbCb: dtoPart.get(itm[0]).assistRbCb,
        assistRbLp: dtoPart.get(itm[0]).assistRbLp,
        assistCbGk: dtoPart.get(itm[0]).assistCbGk,
        assistCbLw: dtoPart.get(itm[0]).assistCbLw,
        assistCbRw: dtoPart.get(itm[0]).assistCbRw,
        assistCbLb: dtoPart.get(itm[0]).assistCbLb,
        assistCbRb: dtoPart.get(itm[0]).assistCbRb,
        assistCbLp: dtoPart.get(itm[0]).assistCbLp,
        assistLpGk: dtoPart.get(itm[0]).assistLpGk,
        assistLpLw: dtoPart.get(itm[0]).assistLpLw,
        assistLpRw: dtoPart.get(itm[0]).assistLpRw,
        assistLpLb: dtoPart.get(itm[0]).assistLpLb,
        assistLpRb: dtoPart.get(itm[0]).assistLpRb,
        assistLpCb: dtoPart.get(itm[0]).assistLpCb,
        superiorityEfficiency: dtoPart.get(itm[0]).superiorityEfficiency,
        equalityEfficiency: dtoPart.get(itm[0]).equalityEfficiency,
        inferiorityEfficiency: dtoPart.get(itm[0]).inferiorityEfficiency,
        totalShots: dtoPart.get(itm[0]).totalShots,
        possessionsEquality: dtoPart.get(itm[0]).possessionsEquality,
        goalsEquality: dtoPart.get(itm[0]).goalsEquality,
        goalsInferiority: dtoPart.get(itm[0]).goalsInferiority,
        goalsSuperiority: dtoPart.get(itm[0]).goalsSuperiority,
        possessionsSuperiority: dtoPart.get(itm[0]).possessionsSuperiority,
        defensePossessions: dtoPart.get(itm[0]).defensePossessions,
        defenseReceivedGoals: dtoPart.get(itm[0]).defenseReceivedGoals,
        possessionsEquality6vs6: dtoPart.get(itm[0]).possessionsEquality6vs6,
        goalsEquality6vs6: dtoPart.get(itm[0]).goalsEquality6vs6,
        savesEquality6vs6: dtoPart.get(itm[0]).savesEquality6vs6,
        lostBallsEquality6vs6: dtoPart.get(itm[0]).lostBallsEquality6vs6,
        postOutEquality6vs6: dtoPart.get(itm[0]).postOutEquality6vs6,
        shotsEquality6vs6: dtoPart.get(itm[0]).shotsEquality6vs6,
        possessionsEquality5p1vs6: dtoPart.get(itm[0]).possessionsEquality5p1vs6,
        goalsEquality5p1vs6: dtoPart.get(itm[0]).goalsEquality5p1vs6,
        savesEquality5p1vs6: dtoPart.get(itm[0]).savesEquality5p1vs6,
        lostBallsEquality5p1vs6: dtoPart.get(itm[0]).lostBallsEquality5p1vs6,
        postOutEquality5p1vs6: dtoPart.get(itm[0]).postOutEquality5p1vs6,
        shotsEquality5p1vs6: dtoPart.get(itm[0]).shotsEquality5p1vs6,
        possessionsSuperiority6vs5: dtoPart.get(itm[0]).possessionsSuperiority6vs5,
        goalsSuperiority6vs5: dtoPart.get(itm[0]).goalsSuperiority6vs5,
        savesSuperiority6vs5: dtoPart.get(itm[0]).savesSuperiority6vs5,
        lostBallsSuperiority6vs5: dtoPart.get(itm[0]).lostBallsSuperiority6vs5,
        postOutSuperiority6vs5: dtoPart.get(itm[0]).postOutSuperiority6vs5,
        shotsSuperiority6vs5: dtoPart.get(itm[0]).shotsSuperiority6vs5,
        possessionsSuperiority7vs6: dtoPart.get(itm[0]).possessionsSuperiority7vs6,
        goalsSuperiority7vs6: dtoPart.get(itm[0]).goalsSuperiority7vs6,
        savesSuperiority7vs6: dtoPart.get(itm[0]).savesSuperiority7vs6,
        lostBallsSuperiority7vs6: dtoPart.get(itm[0]).lostBallsSuperiority7vs6,
        postOutSuperiority7vs6: dtoPart.get(itm[0]).postOutSuperiority7vs6,
        shotsSuperiority7vs6: dtoPart.get(itm[0]).shotsSuperiority7vs6,
        //#endregion

        eventsFastBreak: this._teamEventsCounters[itm[0]].eventsFastBreak,
        eventsCounterGoal: this._teamEventsCounters[itm[0]].eventsCounterGoal,
        eventsOffensePositional: this._teamEventsCounters[itm[0]].eventsOffensePositional,
        eventsSuperiority7vs6: this._teamEventsCounters[itm[0]].eventsSuperiority7vs6,
        eventsSuperiority6vs5: this._teamEventsCounters[itm[0]].eventsSuperiority6vs5,
        eventsSuperiorityOthers: this._teamEventsCounters[itm[0]].eventsSuperiorityOthers,
        eventsEquality: this._teamEventsCounters[itm[0]].eventsEquality,
        eventsInferiority: this._teamEventsCounters[itm[0]].eventsInferiority,
        eventsDefense: this._teamEventsCounters[itm[0]].eventsDefense,
        eventsOffense: this._teamEventsCounters[itm[0]].eventsOffense,

        playedTimeOffense: this._teamPlayedTimeCounters[itm[0]].playedTimeOffense,
        playedTimeDefense: this._teamPlayedTimeCounters[itm[0]].playedTimeDefense,
        playedTimeOffenseInferiority: this._teamPlayedTimeCounters[itm[0]].playedTimeOffenseInferiority,
        playedTimeDefenseInferiority: this._teamPlayedTimeCounters[itm[0]].playedTimeDefenseInferiority,
        playedTimeOffenseSuperiority: this._teamPlayedTimeCounters[itm[0]].playedTimeOffenseSuperiority,
        playedTimeDefenseSuperiority: this._teamPlayedTimeCounters[itm[0]].playedTimeDefenseSuperiority,
        playedTimeOffenseEquality: this._teamPlayedTimeCounters[itm[0]].playedTimeOffenseEquality,
        playedTimeDefenseEquality: this._teamPlayedTimeCounters[itm[0]].playedTimeDefenseEquality,
        noGoals: this._teamEventsDefenseEff[itm[0]].noGoals,
        efficiencyDefense: dtoPart.get(otherTeamMarker).possessionsPlayed ? (this._teamEventsDefenseEff[itm[0]].noGoals / dtoPart.get(otherTeamMarker).possessionsPlayed * 100):0,

        timeOuts: this._teamEventsCounters[itm[0]].timeOuts,
        gkReceivedInferiority: this._teamEventsCounters[itm[0]].gkReceivedInferiority,
      };
      return res;
    });
  }

  public transformToPlayerStatisticsDto(dtoPart: Map<number, PlayerStatsDtoPart>): PlayerStatsDto[] {
    return Array.from(this._goalConsumerCore.playerCounterMap.entries()).filter(itm => {
      // In case we are in complete mode we don not want to aggregate players on the match if they did not play (even if present on match report)
      return dtoPart.get(itm[0]).timePlayed > 0 || this._core.gameService.gameMode$.getValue() !== 'COMPLETE_MODE';
    }).map(itm => {
      const res: PlayerStatsDto = {
        //#region Old counters
        // goalsOffensePositional: itm[1].get('GOAL-OFFENSE_POSITIONAL').counter,
        // goalsFastBreak: itm[1].get('GOAL-FAST_BREAK').counter,
        // goalsCounterGoal: itm[1].get('GOAL-COUNTER_GOAL').counter,
        savesOffensePositional: itm[1].get('SAVE-OFFENSE_POSITIONAL').counter,
        savesFastBreak: itm[1].get('SAVE-FAST_BREAK').counter,
        savesCounterGoal: itm[1].get('SAVE-COUNTER_GOAL').counter,
        goalsLeftWing: itm[1].get('GOAL-LEFT_WING').counter,
        goalsLeft6m: itm[1].get('GOAL-LEFT_6M').counter,
        goalsCenter6m: itm[1].get('GOAL-CENTER_6M').counter,
        goalsRight6m: itm[1].get('GOAL-RIGHT_6M').counter,
        goalsRightWing: itm[1].get('GOAL-RIGHT_WING').counter,
        goalsLeft9m: itm[1].get('GOAL-LEFT_9M').counter,
        goalsCenter9m: itm[1].get('GOAL-CENTER_9M').counter,
        goalsRight9m: itm[1].get('GOAL-RIGHT_9M').counter,
        goals7Meters: itm[1].get('GOAL-7_METERS').counter,
        goalsOppositeField: itm[1].get('GOAL-OPPOSITE_FIELD').counter,
        savesLeftWing: itm[1].get('SAVE-LEFT_WING').counter,
        savesLeft6m: itm[1].get('SAVE-LEFT_6M').counter,
        savesCenter6m: itm[1].get('SAVE-CENTER_6M').counter,
        savesRight6m: itm[1].get('SAVE-RIGHT_6M').counter,
        savesRightWing: itm[1].get('SAVE-RIGHT_WING').counter,
        savesLeft9m: itm[1].get('SAVE-LEFT_9M').counter,
        savesCenter9m: itm[1].get('SAVE-CENTER_9M').counter,
        savesRight9m: itm[1].get('SAVE-RIGHT_9M').counter,
        saves7Meters: itm[1].get('SAVE-7_METERS').counter,
        savesOppositeField: itm[1].get('SAVE-OPPOSITE_FIELD').counter,
        goals1: itm[1].get('GOAL-1').counter,
        goals2: itm[1].get('GOAL-2').counter,
        goals3: itm[1].get('GOAL-3').counter,
        goals4: itm[1].get('GOAL-4').counter,
        goals5: itm[1].get('GOAL-5').counter,
        goals6: itm[1].get('GOAL-6').counter,
        goals7: itm[1].get('GOAL-7').counter,
        goals8: itm[1].get('GOAL-8').counter,
        goals9: itm[1].get('GOAL-9').counter,
        saves1: itm[1].get('SAVE-1').counter,
        saves2: itm[1].get('SAVE-2').counter,
        saves3: itm[1].get('SAVE-3').counter,
        saves4: itm[1].get('SAVE-4').counter,
        saves5: itm[1].get('SAVE-5').counter,
        saves6: itm[1].get('SAVE-6').counter,
        saves7: itm[1].get('SAVE-7').counter,
        saves8: itm[1].get('SAVE-8').counter,
        saves9: itm[1].get('SAVE-9').counter,
        postOutCenter6m: itm[1].get('POST_OUT-CENTER_6M').counter,
        postOutCenter9m: itm[1].get('POST_OUT-CENTER_9M').counter,
        postOutLeft6m: itm[1].get('POST_OUT-LEFT_6M').counter,
        postOutLeft9m: itm[1].get('POST_OUT-LEFT_9M').counter,
        postOutLeftWing: itm[1].get('POST_OUT-LEFT_WING').counter,
        postOutOppositeField: itm[1].get('POST_OUT-OPPOSITE_FIELD').counter,
        postOutRight6m: itm[1].get('POST_OUT-RIGHT_6M').counter,
        postOutRight9m: itm[1].get('POST_OUT-RIGHT_9M').counter,
        postOutRightWing: itm[1].get('POST_OUT-RIGHT_WING').counter,
        postOut7Meters: itm[1].get('POST_OUT-7_METERS').counter,
        postOut0: itm[1].get('POST_OUT-0').counter,
        postOut10: itm[1].get('POST_OUT-10').counter,
        postOut11: itm[1].get('POST_OUT-11').counter,
        goalsLeftWing0: itm[1].get('CONNECT_G_LEFT_WING_0').counter,
        goalsLeftWing1: itm[1].get('CONNECT_G_LEFT_WING_1').counter,
        goalsLeftWing2: itm[1].get('CONNECT_G_LEFT_WING_2').counter,
        goalsLeftWing3: itm[1].get('CONNECT_G_LEFT_WING_3').counter,
        goalsLeftWing4: itm[1].get('CONNECT_G_LEFT_WING_4').counter,
        goalsLeftWing5: itm[1].get('CONNECT_G_LEFT_WING_5').counter,
        goalsLeftWing6: itm[1].get('CONNECT_G_LEFT_WING_6').counter,
        goalsLeftWing7: itm[1].get('CONNECT_G_LEFT_WING_7').counter,
        goalsLeftWing8: itm[1].get('CONNECT_G_LEFT_WING_8').counter,
        goalsLeftWing9: itm[1].get('CONNECT_G_LEFT_WING_9').counter,
        goalsLeftWing10: itm[1].get('CONNECT_G_LEFT_WING_10').counter,
        goalsLeftWing11: itm[1].get('CONNECT_G_LEFT_WING_11').counter,
        goalsLeft6m0: itm[1].get('CONNECT_G_LEFT_6M_0').counter,
        goalsLeft6m1: itm[1].get('CONNECT_G_LEFT_6M_1').counter,
        goalsLeft6m2: itm[1].get('CONNECT_G_LEFT_6M_2').counter,
        goalsLeft6m3: itm[1].get('CONNECT_G_LEFT_6M_3').counter,
        goalsLeft6m4: itm[1].get('CONNECT_G_LEFT_6M_4').counter,
        goalsLeft6m5: itm[1].get('CONNECT_G_LEFT_6M_5').counter,
        goalsLeft6m6: itm[1].get('CONNECT_G_LEFT_6M_6').counter,
        goalsLeft6m7: itm[1].get('CONNECT_G_LEFT_6M_7').counter,
        goalsLeft6m8: itm[1].get('CONNECT_G_LEFT_6M_8').counter,
        goalsLeft6m9: itm[1].get('CONNECT_G_LEFT_6M_9').counter,
        goalsLeft6m10: itm[1].get('CONNECT_G_LEFT_6M_10').counter,
        goalsLeft6m11: itm[1].get('CONNECT_G_LEFT_6M_11').counter,
        goalsCenter6m0: itm[1].get('CONNECT_G_CENTER_6M_0').counter,
        goalsCenter6m1: itm[1].get('CONNECT_G_CENTER_6M_1').counter,
        goalsCenter6m2: itm[1].get('CONNECT_G_CENTER_6M_2').counter,
        goalsCenter6m3: itm[1].get('CONNECT_G_CENTER_6M_3').counter,
        goalsCenter6m4: itm[1].get('CONNECT_G_CENTER_6M_4').counter,
        goalsCenter6m5: itm[1].get('CONNECT_G_CENTER_6M_5').counter,
        goalsCenter6m6: itm[1].get('CONNECT_G_CENTER_6M_6').counter,
        goalsCenter6m7: itm[1].get('CONNECT_G_CENTER_6M_7').counter,
        goalsCenter6m8: itm[1].get('CONNECT_G_CENTER_6M_8').counter,
        goalsCenter6m9: itm[1].get('CONNECT_G_CENTER_6M_9').counter,
        goalsCenter6m10: itm[1].get('CONNECT_G_CENTER_6M_10').counter,
        goalsCenter6m11: itm[1].get('CONNECT_G_CENTER_6M_11').counter,
        goalsRight6m0: itm[1].get('CONNECT_G_RIGHT_6M_0').counter,
        goalsRight6m1: itm[1].get('CONNECT_G_RIGHT_6M_1').counter,
        goalsRight6m2: itm[1].get('CONNECT_G_RIGHT_6M_2').counter,
        goalsRight6m3: itm[1].get('CONNECT_G_RIGHT_6M_3').counter,
        goalsRight6m4: itm[1].get('CONNECT_G_RIGHT_6M_4').counter,
        goalsRight6m5: itm[1].get('CONNECT_G_RIGHT_6M_5').counter,
        goalsRight6m6: itm[1].get('CONNECT_G_RIGHT_6M_6').counter,
        goalsRight6m7: itm[1].get('CONNECT_G_RIGHT_6M_7').counter,
        goalsRight6m8: itm[1].get('CONNECT_G_RIGHT_6M_8').counter,
        goalsRight6m9: itm[1].get('CONNECT_G_RIGHT_6M_9').counter,
        goalsRight6m10: itm[1].get('CONNECT_G_RIGHT_6M_10').counter,
        goalsRight6m11: itm[1].get('CONNECT_G_RIGHT_6M_11').counter,
        goalsRightWing0: itm[1].get('CONNECT_G_RIGHT_WING_0').counter,
        goalsRightWing1: itm[1].get('CONNECT_G_RIGHT_WING_1').counter,
        goalsRightWing2: itm[1].get('CONNECT_G_RIGHT_WING_2').counter,
        goalsRightWing3: itm[1].get('CONNECT_G_RIGHT_WING_3').counter,
        goalsRightWing4: itm[1].get('CONNECT_G_RIGHT_WING_4').counter,
        goalsRightWing5: itm[1].get('CONNECT_G_RIGHT_WING_5').counter,
        goalsRightWing6: itm[1].get('CONNECT_G_RIGHT_WING_6').counter,
        goalsRightWing7: itm[1].get('CONNECT_G_RIGHT_WING_7').counter,
        goalsRightWing8: itm[1].get('CONNECT_G_RIGHT_WING_8').counter,
        goalsRightWing9: itm[1].get('CONNECT_G_RIGHT_WING_9').counter,
        goalsRightWing10: itm[1].get('CONNECT_G_RIGHT_WING_10').counter,
        goalsRightWing11: itm[1].get('CONNECT_G_RIGHT_WING_11').counter,
        goalsLeft9m0: itm[1].get('CONNECT_G_LEFT_9M_0').counter,
        goalsLeft9m1: itm[1].get('CONNECT_G_LEFT_9M_1').counter,
        goalsLeft9m2: itm[1].get('CONNECT_G_LEFT_9M_2').counter,
        goalsLeft9m3: itm[1].get('CONNECT_G_LEFT_9M_3').counter,
        goalsLeft9m4: itm[1].get('CONNECT_G_LEFT_9M_4').counter,
        goalsLeft9m5: itm[1].get('CONNECT_G_LEFT_9M_5').counter,
        goalsLeft9m6: itm[1].get('CONNECT_G_LEFT_9M_6').counter,
        goalsLeft9m7: itm[1].get('CONNECT_G_LEFT_9M_7').counter,
        goalsLeft9m8: itm[1].get('CONNECT_G_LEFT_9M_8').counter,
        goalsLeft9m9: itm[1].get('CONNECT_G_LEFT_9M_9').counter,
        goalsLeft9m10: itm[1].get('CONNECT_G_LEFT_9M_10').counter,
        goalsLeft9m11: itm[1].get('CONNECT_G_LEFT_9M_11').counter,
        goalsCenter9m0: itm[1].get('CONNECT_G_CENTER_9M_0').counter,
        goalsCenter9m1: itm[1].get('CONNECT_G_CENTER_9M_1').counter,
        goalsCenter9m2: itm[1].get('CONNECT_G_CENTER_9M_2').counter,
        goalsCenter9m3: itm[1].get('CONNECT_G_CENTER_9M_3').counter,
        goalsCenter9m4: itm[1].get('CONNECT_G_CENTER_9M_4').counter,
        goalsCenter9m5: itm[1].get('CONNECT_G_CENTER_9M_5').counter,
        goalsCenter9m6: itm[1].get('CONNECT_G_CENTER_9M_6').counter,
        goalsCenter9m7: itm[1].get('CONNECT_G_CENTER_9M_7').counter,
        goalsCenter9m8: itm[1].get('CONNECT_G_CENTER_9M_8').counter,
        goalsCenter9m9: itm[1].get('CONNECT_G_CENTER_9M_9').counter,
        goalsCenter9m10: itm[1].get('CONNECT_G_CENTER_9M_10').counter,
        goalsCenter9m11: itm[1].get('CONNECT_G_CENTER_9M_11').counter,
        goalsRight9m0: itm[1].get('CONNECT_G_RIGHT_9M_0').counter,
        goalsRight9m1: itm[1].get('CONNECT_G_RIGHT_9M_1').counter,
        goalsRight9m2: itm[1].get('CONNECT_G_RIGHT_9M_2').counter,
        goalsRight9m3: itm[1].get('CONNECT_G_RIGHT_9M_3').counter,
        goalsRight9m4: itm[1].get('CONNECT_G_RIGHT_9M_4').counter,
        goalsRight9m5: itm[1].get('CONNECT_G_RIGHT_9M_5').counter,
        goalsRight9m6: itm[1].get('CONNECT_G_RIGHT_9M_6').counter,
        goalsRight9m7: itm[1].get('CONNECT_G_RIGHT_9M_7').counter,
        goalsRight9m8: itm[1].get('CONNECT_G_RIGHT_9M_8').counter,
        goalsRight9m9: itm[1].get('CONNECT_G_RIGHT_9M_9').counter,
        goalsRight9m10: itm[1].get('CONNECT_G_RIGHT_9M_10').counter,
        goalsRight9m11: itm[1].get('CONNECT_G_RIGHT_9M_11').counter,
        goals7Meters0: itm[1].get('CONNECT_G_7_METERS_0').counter,
        goals7Meters1: itm[1].get('CONNECT_G_7_METERS_1').counter,
        goals7Meters2: itm[1].get('CONNECT_G_7_METERS_2').counter,
        goals7Meters3: itm[1].get('CONNECT_G_7_METERS_3').counter,
        goals7Meters4: itm[1].get('CONNECT_G_7_METERS_4').counter,
        goals7Meters5: itm[1].get('CONNECT_G_7_METERS_5').counter,
        goals7Meters6: itm[1].get('CONNECT_G_7_METERS_6').counter,
        goals7Meters7: itm[1].get('CONNECT_G_7_METERS_7').counter,
        goals7Meters8: itm[1].get('CONNECT_G_7_METERS_8').counter,
        goals7Meters9: itm[1].get('CONNECT_G_7_METERS_9').counter,
        goals7Meters10: itm[1].get('CONNECT_G_7_METERS_10').counter,
        goals7Meters11: itm[1].get('CONNECT_G_7_METERS_11').counter,
        goalsOppositeField0: itm[1].get('CONNECT_G_OPPOSITE_FIELD_0').counter,
        goalsOppositeField1: itm[1].get('CONNECT_G_OPPOSITE_FIELD_1').counter,
        goalsOppositeField2: itm[1].get('CONNECT_G_OPPOSITE_FIELD_2').counter,
        goalsOppositeField3: itm[1].get('CONNECT_G_OPPOSITE_FIELD_3').counter,
        goalsOppositeField4: itm[1].get('CONNECT_G_OPPOSITE_FIELD_4').counter,
        goalsOppositeField5: itm[1].get('CONNECT_G_OPPOSITE_FIELD_5').counter,
        goalsOppositeField6: itm[1].get('CONNECT_G_OPPOSITE_FIELD_6').counter,
        goalsOppositeField7: itm[1].get('CONNECT_G_OPPOSITE_FIELD_7').counter,
        goalsOppositeField8: itm[1].get('CONNECT_G_OPPOSITE_FIELD_8').counter,
        goalsOppositeField9: itm[1].get('CONNECT_G_OPPOSITE_FIELD_9').counter,
        goalsOppositeField10: itm[1].get('CONNECT_G_OPPOSITE_FIELD_10').counter,
        goalsOppositeField11: itm[1].get('CONNECT_G_OPPOSITE_FIELD_11').counter,
        shootsLeftWing0: itm[1].get('CONNECT_S_LEFT_WING_0').counter,
        shootsLeftWing1: itm[1].get('CONNECT_S_LEFT_WING_1').counter,
        shootsLeftWing2: itm[1].get('CONNECT_S_LEFT_WING_2').counter,
        shootsLeftWing3: itm[1].get('CONNECT_S_LEFT_WING_3').counter,
        shootsLeftWing4: itm[1].get('CONNECT_S_LEFT_WING_4').counter,
        shootsLeftWing5: itm[1].get('CONNECT_S_LEFT_WING_5').counter,
        shootsLeftWing6: itm[1].get('CONNECT_S_LEFT_WING_6').counter,
        shootsLeftWing7: itm[1].get('CONNECT_S_LEFT_WING_7').counter,
        shootsLeftWing8: itm[1].get('CONNECT_S_LEFT_WING_8').counter,
        shootsLeftWing9: itm[1].get('CONNECT_S_LEFT_WING_9').counter,
        shootsLeftWing10: itm[1].get('CONNECT_S_LEFT_WING_10').counter,
        shootsLeftWing11: itm[1].get('CONNECT_S_LEFT_WING_11').counter,
        shootsLeft6m0: itm[1].get('CONNECT_S_LEFT_6M_0').counter,
        shootsLeft6m1: itm[1].get('CONNECT_S_LEFT_6M_1').counter,
        shootsLeft6m2: itm[1].get('CONNECT_S_LEFT_6M_2').counter,
        shootsLeft6m3: itm[1].get('CONNECT_S_LEFT_6M_3').counter,
        shootsLeft6m4: itm[1].get('CONNECT_S_LEFT_6M_4').counter,
        shootsLeft6m5: itm[1].get('CONNECT_S_LEFT_6M_5').counter,
        shootsLeft6m6: itm[1].get('CONNECT_S_LEFT_6M_6').counter,
        shootsLeft6m7: itm[1].get('CONNECT_S_LEFT_6M_7').counter,
        shootsLeft6m8: itm[1].get('CONNECT_S_LEFT_6M_8').counter,
        shootsLeft6m9: itm[1].get('CONNECT_S_LEFT_6M_9').counter,
        shootsLeft6m10: itm[1].get('CONNECT_S_LEFT_6M_10').counter,
        shootsLeft6m11: itm[1].get('CONNECT_S_LEFT_6M_11').counter,
        shootsCenter6m0: itm[1].get('CONNECT_S_CENTER_6M_0').counter,
        shootsCenter6m1: itm[1].get('CONNECT_S_CENTER_6M_1').counter,
        shootsCenter6m2: itm[1].get('CONNECT_S_CENTER_6M_2').counter,
        shootsCenter6m3: itm[1].get('CONNECT_S_CENTER_6M_3').counter,
        shootsCenter6m4: itm[1].get('CONNECT_S_CENTER_6M_4').counter,
        shootsCenter6m5: itm[1].get('CONNECT_S_CENTER_6M_5').counter,
        shootsCenter6m6: itm[1].get('CONNECT_S_CENTER_6M_6').counter,
        shootsCenter6m7: itm[1].get('CONNECT_S_CENTER_6M_7').counter,
        shootsCenter6m8: itm[1].get('CONNECT_S_CENTER_6M_8').counter,
        shootsCenter6m9: itm[1].get('CONNECT_S_CENTER_6M_9').counter,
        shootsCenter6m10: itm[1].get('CONNECT_S_CENTER_6M_10').counter,
        shootsCenter6m11: itm[1].get('CONNECT_S_CENTER_6M_11').counter,
        shootsRight6m0: itm[1].get('CONNECT_S_RIGHT_6M_0').counter,
        shootsRight6m1: itm[1].get('CONNECT_S_RIGHT_6M_1').counter,
        shootsRight6m2: itm[1].get('CONNECT_S_RIGHT_6M_2').counter,
        shootsRight6m3: itm[1].get('CONNECT_S_RIGHT_6M_3').counter,
        shootsRight6m4: itm[1].get('CONNECT_S_RIGHT_6M_4').counter,
        shootsRight6m5: itm[1].get('CONNECT_S_RIGHT_6M_5').counter,
        shootsRight6m6: itm[1].get('CONNECT_S_RIGHT_6M_6').counter,
        shootsRight6m7: itm[1].get('CONNECT_S_RIGHT_6M_7').counter,
        shootsRight6m8: itm[1].get('CONNECT_S_RIGHT_6M_8').counter,
        shootsRight6m9: itm[1].get('CONNECT_S_RIGHT_6M_9').counter,
        shootsRight6m10: itm[1].get('CONNECT_S_RIGHT_6M_10').counter,
        shootsRight6m11: itm[1].get('CONNECT_S_RIGHT_6M_11').counter,
        shootsRightWing0: itm[1].get('CONNECT_S_RIGHT_WING_0').counter,
        shootsRightWing1: itm[1].get('CONNECT_S_RIGHT_WING_1').counter,
        shootsRightWing2: itm[1].get('CONNECT_S_RIGHT_WING_2').counter,
        shootsRightWing3: itm[1].get('CONNECT_S_RIGHT_WING_3').counter,
        shootsRightWing4: itm[1].get('CONNECT_S_RIGHT_WING_4').counter,
        shootsRightWing5: itm[1].get('CONNECT_S_RIGHT_WING_5').counter,
        shootsRightWing6: itm[1].get('CONNECT_S_RIGHT_WING_6').counter,
        shootsRightWing7: itm[1].get('CONNECT_S_RIGHT_WING_7').counter,
        shootsRightWing8: itm[1].get('CONNECT_S_RIGHT_WING_8').counter,
        shootsRightWing9: itm[1].get('CONNECT_S_RIGHT_WING_9').counter,
        shootsRightWing10: itm[1].get('CONNECT_S_RIGHT_WING_10').counter,
        shootsRightWing11: itm[1].get('CONNECT_S_RIGHT_WING_11').counter,
        shootsLeft9m0: itm[1].get('CONNECT_S_LEFT_9M_0').counter,
        shootsLeft9m1: itm[1].get('CONNECT_S_LEFT_9M_1').counter,
        shootsLeft9m2: itm[1].get('CONNECT_S_LEFT_9M_2').counter,
        shootsLeft9m3: itm[1].get('CONNECT_S_LEFT_9M_3').counter,
        shootsLeft9m4: itm[1].get('CONNECT_S_LEFT_9M_4').counter,
        shootsLeft9m5: itm[1].get('CONNECT_S_LEFT_9M_5').counter,
        shootsLeft9m6: itm[1].get('CONNECT_S_LEFT_9M_6').counter,
        shootsLeft9m7: itm[1].get('CONNECT_S_LEFT_9M_7').counter,
        shootsLeft9m8: itm[1].get('CONNECT_S_LEFT_9M_8').counter,
        shootsLeft9m9: itm[1].get('CONNECT_S_LEFT_9M_9').counter,
        shootsLeft9m10: itm[1].get('CONNECT_S_LEFT_9M_10').counter,
        shootsLeft9m11: itm[1].get('CONNECT_S_LEFT_9M_11').counter,
        shootsCenter9m0: itm[1].get('CONNECT_S_CENTER_9M_0').counter,
        shootsCenter9m1: itm[1].get('CONNECT_S_CENTER_9M_1').counter,
        shootsCenter9m2: itm[1].get('CONNECT_S_CENTER_9M_2').counter,
        shootsCenter9m3: itm[1].get('CONNECT_S_CENTER_9M_3').counter,
        shootsCenter9m4: itm[1].get('CONNECT_S_CENTER_9M_4').counter,
        shootsCenter9m5: itm[1].get('CONNECT_S_CENTER_9M_5').counter,
        shootsCenter9m6: itm[1].get('CONNECT_S_CENTER_9M_6').counter,
        shootsCenter9m7: itm[1].get('CONNECT_S_CENTER_9M_7').counter,
        shootsCenter9m8: itm[1].get('CONNECT_S_CENTER_9M_8').counter,
        shootsCenter9m9: itm[1].get('CONNECT_S_CENTER_9M_9').counter,
        shootsCenter9m10: itm[1].get('CONNECT_S_CENTER_9M_10').counter,
        shootsCenter9m11: itm[1].get('CONNECT_S_CENTER_9M_11').counter,
        shootsRight9m0: itm[1].get('CONNECT_S_RIGHT_9M_0').counter,
        shootsRight9m1: itm[1].get('CONNECT_S_RIGHT_9M_1').counter,
        shootsRight9m2: itm[1].get('CONNECT_S_RIGHT_9M_2').counter,
        shootsRight9m3: itm[1].get('CONNECT_S_RIGHT_9M_3').counter,
        shootsRight9m4: itm[1].get('CONNECT_S_RIGHT_9M_4').counter,
        shootsRight9m5: itm[1].get('CONNECT_S_RIGHT_9M_5').counter,
        shootsRight9m6: itm[1].get('CONNECT_S_RIGHT_9M_6').counter,
        shootsRight9m7: itm[1].get('CONNECT_S_RIGHT_9M_7').counter,
        shootsRight9m8: itm[1].get('CONNECT_S_RIGHT_9M_8').counter,
        shootsRight9m9: itm[1].get('CONNECT_S_RIGHT_9M_9').counter,
        shootsRight9m10: itm[1].get('CONNECT_S_RIGHT_9M_10').counter,
        shootsRight9m11: itm[1].get('CONNECT_S_RIGHT_9M_11').counter,
        shoots7Meters0: itm[1].get('CONNECT_S_7_METERS_0').counter,
        shoots7Meters1: itm[1].get('CONNECT_S_7_METERS_1').counter,
        shoots7Meters2: itm[1].get('CONNECT_S_7_METERS_2').counter,
        shoots7Meters3: itm[1].get('CONNECT_S_7_METERS_3').counter,
        shoots7Meters4: itm[1].get('CONNECT_S_7_METERS_4').counter,
        shoots7Meters5: itm[1].get('CONNECT_S_7_METERS_5').counter,
        shoots7Meters6: itm[1].get('CONNECT_S_7_METERS_6').counter,
        shoots7Meters7: itm[1].get('CONNECT_S_7_METERS_7').counter,
        shoots7Meters8: itm[1].get('CONNECT_S_7_METERS_8').counter,
        shoots7Meters9: itm[1].get('CONNECT_S_7_METERS_9').counter,
        shoots7Meters10: itm[1].get('CONNECT_S_7_METERS_10').counter,
        shoots7Meters11: itm[1].get('CONNECT_S_7_METERS_11').counter,
        shootsOppositeField0: itm[1].get('CONNECT_S_OPPOSITE_FIELD_0').counter,
        shootsOppositeField1: itm[1].get('CONNECT_S_OPPOSITE_FIELD_1').counter,
        shootsOppositeField2: itm[1].get('CONNECT_S_OPPOSITE_FIELD_2').counter,
        shootsOppositeField3: itm[1].get('CONNECT_S_OPPOSITE_FIELD_3').counter,
        shootsOppositeField4: itm[1].get('CONNECT_S_OPPOSITE_FIELD_4').counter,
        shootsOppositeField5: itm[1].get('CONNECT_S_OPPOSITE_FIELD_5').counter,
        shootsOppositeField6: itm[1].get('CONNECT_S_OPPOSITE_FIELD_6').counter,
        shootsOppositeField7: itm[1].get('CONNECT_S_OPPOSITE_FIELD_7').counter,
        shootsOppositeField8: itm[1].get('CONNECT_S_OPPOSITE_FIELD_8').counter,
        shootsOppositeField9: itm[1].get('CONNECT_S_OPPOSITE_FIELD_9').counter,
        shootsOppositeField10: itm[1].get('CONNECT_S_OPPOSITE_FIELD_10').counter,
        shootsOppositeField11: itm[1].get('CONNECT_S_OPPOSITE_FIELD_11').counter,
        gkReceivedGoals7Meters: itm[1].get('GK-RECEIVED_7_METERS').counter,
        gkSaves7Meters: itm[1].get('GK-SAVE_7_METERS').counter,


        playerId: dtoPart.get(itm[0]).playerId,
        playerName: dtoPart.get(itm[0]).playerName,
        playerPosition: dtoPart.get(itm[0]).playerPosition,
        playerBackNumber: dtoPart.get(itm[0]).playerBackNumber,
        teamName: dtoPart.get(itm[0]).teamName,
        teamId: dtoPart.get(itm[0]).teamId,
        timePlayed: dtoPart.get(itm[0]).timePlayed,
        possessionsPlayed: dtoPart.get(itm[0]).possessionsPlayed,
        goals: dtoPart.get(itm[0]).goals,
        saves: dtoPart.get(itm[0]).saves,
        lostBalls: dtoPart.get(itm[0]).lostBalls,
        lostBallsOffensePositional: dtoPart.get(itm[0]).lostBallsOffensePositional,
        lostBallsFastBreak: dtoPart.get(itm[0]).lostBallsFastBreak,
        lostBallsEquality: dtoPart.get(itm[0]).lostBallsEquality,
        lostBallsSuperiority: dtoPart.get(itm[0]).lostBallsSuperiority,
        lostBallsCounterGoal: dtoPart.get(itm[0]).lostBallsCounterGoal,
        attackFaultProvoke: dtoPart.get(itm[0]).attackFaultProvoke,
        attackFaultCommit: dtoPart.get(itm[0]).attackFaultCommit,
        technicalMistakeProvoke: dtoPart.get(itm[0]).technicalMistakeProvoke,
        technicalMistakeCommit: dtoPart.get(itm[0]).technicalMistakeCommit,
        lostBallProvoke: dtoPart.get(itm[0]).lostBallProvoke,
        lostBall: dtoPart.get(itm[0]).lostBall,
        twoMinCommit: dtoPart.get(itm[0]).twoMinCommit,
        twoMinProvoke: dtoPart.get(itm[0]).twoMinProvoke,
        suspTwoMin: dtoPart.get(itm[0]).suspTwoMin,
        sevenMetersCommit: dtoPart.get(itm[0]).sevenMetersCommit,
        sevenMetersProvoke: dtoPart.get(itm[0]).sevenMetersProvoke,
        sevenMSuspCommit: dtoPart.get(itm[0]).sevenMSuspCommit,
        sevenMSuspProvoke: dtoPart.get(itm[0]).sevenMSuspProvoke,
        foulCommit: dtoPart.get(itm[0]).foulCommit,
        foulReceive: dtoPart.get(itm[0]).foulReceive,
        oneAndOneLost: dtoPart.get(itm[0]).oneAndOneLost,
        oneAndOneWon: dtoPart.get(itm[0]).oneAndOneWon,
        block: dtoPart.get(itm[0]).block,
        shotBlocked: dtoPart.get(itm[0]).shotBlocked,
        efficiency: dtoPart.get(itm[0]).efficiency,
        shotEfficiency: dtoPart.get(itm[0]).shotEfficiency,
        lostBallPerc: dtoPart.get(itm[0]).lostBallPerc,
        score: dtoPart.get(itm[0]).score,
        plusMinus: dtoPart.get(itm[0]).plusMinus,
        failedShotsOffensePositional: dtoPart.get(itm[0]).failedShotsOffensePositional,
        failedShotsFastBreak: dtoPart.get(itm[0]).failedShotsFastBreak,
        failedShotsCounterGoal: dtoPart.get(itm[0]).failedShotsCounterGoal,
        postOut: dtoPart.get(itm[0]).postOut,
        receivedGoals: dtoPart.get(itm[0]).receivedGoals,

        totalShots: dtoPart.get(itm[0]).totalShots,
        possessionsOffensePositional: dtoPart.get(itm[0]).possessionsOffensePositional,
        possessionsCounterGoal: dtoPart.get(itm[0]).possessionsCounterGoal,
        possessionsFastBreak: dtoPart.get(itm[0]).possessionsFastBreak,
        goalsSuperiority: dtoPart.get(itm[0]).goalsSuperiority,
        goalsEquality: dtoPart.get(itm[0]).goalsEquality,
        possessionsSuperiority: dtoPart.get(itm[0]).possessionsSuperiority,
        possessionsEquality: dtoPart.get(itm[0]).possessionsEquality,
        shots7Meters: dtoPart.get(itm[0]).shots7Meters + itm[1].get('GOAL-7_METERS').counter,
        assist: dtoPart.get(itm[0]).assist,
        gkPostOut: dtoPart.get(itm[0]).gkPostOut,
        defensePossOffensePositional: dtoPart.get(itm[0]).defensePossOffensePositional,
        defensePossFastBreak: dtoPart.get(itm[0]).defensePossFastBreak,
        defensePossCounterGoal: dtoPart.get(itm[0]).defensePossCounterGoal,
        defenseReceivedGoalsOffensePositional: dtoPart.get(itm[0]).defenseReceivedGoalsOffensePositional,
        defenseReceivedGoalsFastBreak: dtoPart.get(itm[0]).defenseReceivedGoalsFastBreak,
        defenseReceivedGoalsCounterGoal: dtoPart.get(itm[0]).defenseReceivedGoalsCounterGoal,

        playerScore: dtoPart.get(itm[0]).playerScore,
        score5: dtoPart.get(itm[0]).score5,
        scorePos: dtoPart.get(itm[0]).scorePos,
        scoreNeg: dtoPart.get(itm[0]).scoreNeg,
        scoreAttack: dtoPart.get(itm[0]).scoreAttack,
        scoreDef: dtoPart.get(itm[0]).scoreDef,

        postOutOffensePositional: dtoPart.get(itm[0]).postOutOffensePositional,
        postOutFastBreak: dtoPart.get(itm[0]).postOutFastBreak,
        postOutCounterGoal: dtoPart.get(itm[0]).postOutCounterGoal,
        postOutEquality: dtoPart.get(itm[0]).postOutEquality,
        postOutSuperiority: dtoPart.get(itm[0]).postOutSuperiority,
        failedShotsEquality: dtoPart.get(itm[0]).failedShotsEquality,
        failedShotsSuperiority: dtoPart.get(itm[0]).failedShotsSuperiority,
        goalsOffensePositional: dtoPart.get(itm[0]).goalsOffensePositional,
        goalsCounterGoal: dtoPart.get(itm[0]).goalsCounterGoal,
        goalsFastBreak: dtoPart.get(itm[0]).goalsFastBreak,
        //#endregion

        eventsFastBreak: this._playerEventsCounters[itm[0]]?.eventsFastBreak ?? 0,
        eventsCounterGoal: this._playerEventsCounters[itm[0]]?.eventsCounterGoal ?? 0,
        eventsOffensePositional: this._playerEventsCounters[itm[0]]?.eventsOffensePositional ?? 0,
        eventsSuperiority7vs6: this._playerEventsCounters[itm[0]]?.eventsSuperiority7vs6 ?? 0,
        eventsSuperiority6vs5: this._playerEventsCounters[itm[0]]?.eventsSuperiority6vs5 ?? 0,
        eventsSuperiorityOthers: this._playerEventsCounters[itm[0]]?.eventsSuperiorityOthers ?? 0,
        eventsEquality: this._playerEventsCounters[itm[0]]?.eventsEquality ?? 0,
        eventsInferiority: this._playerEventsCounters[itm[0]]?.eventsInferiority ?? 0,
        eventsDefense: this._playerEventsCounters[itm[0]]?.eventsDefense ?? 0,
        eventsOffense: this._playerEventsCounters[itm[0]]?.eventsOffense ?? 0,

        playedTimeOffense: this._playerPlayedTimeCounters[itm[0]]?.playedTimeOffense ?? 0,
        playedTimeDefense: this._playerPlayedTimeCounters[itm[0]]?.playedTimeDefense ?? 0,
        playedTimeOffenseInferiority: this._playerPlayedTimeCounters[itm[0]]?.playedTimeOffenseInferiority ?? 0,
        playedTimeDefenseInferiority: this._playerPlayedTimeCounters[itm[0]]?.playedTimeDefenseInferiority ?? 0,
        playedTimeOffenseSuperiority: this._playerPlayedTimeCounters[itm[0]]?.playedTimeOffenseSuperiority ?? 0,
        playedTimeDefenseSuperiority: this._playerPlayedTimeCounters[itm[0]]?.playedTimeDefenseSuperiority ?? 0,
        playedTimeOffenseEquality: this._playerPlayedTimeCounters[itm[0]]?.playedTimeOffenseEquality ?? 0,
        playedTimeDefenseEquality: this._playerPlayedTimeCounters[itm[0]]?.playedTimeDefenseEquality ?? 0,

        yellowCard: this._playerEventsCounters[itm[0]]?.yellowCard,
        blueCard: this._playerEventsCounters[itm[0]]?.blueCard,
        redCard: this._playerEventsCounters[itm[0]]?.redCard,
      };
      return res;
    });
  }

  public transformToLineupStatisticsDto(): LineupStatsDto[] {
    return Object.values(this._lineUpCounters).map(counter => ({
      teamId: counter.teamId,
      teamName: counter.teamName,
      lineup: counter.lineup,
      possessionsPlayed: counter.possessionsPlayed,
      possessionsOffense: counter.possessionsOffense,
      possessionsDefense: counter.possessionsDefense,
      goals: counter.goals,
      saves: counter.saves,
      lostBalls: counter.lostBalls,
      efficiencyOffense: counter.possessionsOffense ? counter.goals/counter.possessionsOffense*100 : 0,
      efficiencyDefense: counter.possessionsDefense ? counter.noGoals/counter.possessionsDefense*100 : 0,
      noGoals: counter.noGoals,
    })) ?? [];
  }

  private resetTeamSubjects(): void {
    this._lastPbp = undefined;
    this._possHasGoal = false;
    this._playerPlayedTimeCounters = {};
    this._playerEventsCounters = {};
    this._playerEventsDefenseEff = {};
    this._teamPlayedTimeCounters = {
      HOME: {...this._playerTimeCountersTpl},
      VISITOR: {...this._playerTimeCountersTpl},
    };
    this._teamEventsCounters = {
      HOME: {...this._eventCountersTpl, ...this._extraTeamCountersTpl},
      VISITOR: {...this._eventCountersTpl, ...this._extraTeamCountersTpl},
    };
    this._teamEventsDefenseEff = {
      HOME: {
        noGoals: 0,
        eventsCounterGoal: 0,
      },
      VISITOR: {
        noGoals: 0,
        eventsCounterGoal: 0,
      },
    };
    this._lineUpCounters = {};
    this._homeTeamCounters$.next([]);
    this._visitorTeamCounters$.next([]);
    this._homeGoalPositionList$.next([]);
    this._visitorGoalPositionList$.next([]);
    this._homeGoalConnectionsCounters$.next([]);
    this._visitorGoalConnectionsCounters$.next([]);
  }
}
