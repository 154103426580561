import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { GameDto } from 'src/app/api/hai-api';
import { CoreService } from 'src/app/shared-services/core.service';
import { GoalConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/goal/goal-consumer.service';
import { OverviewConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/overview/overview-consumer.service';
import { QuickStatsStateHelper } from 'src/app/shared-services/statistics/common/state-helper/quick-stats-state-helper';
import { TimelineConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/timeline/timeline-consumer.service';
import { TeamOverviewSubConsumerService } from 'src/app/shared-services/statistics/playbyplay/sub-consumer/team-overview/team-overview-sub-consumer.service';
import { TeamOverviewStatsStateHelper } from 'src/app/shared-services/statistics/common/state-helper/team-overview-stats-state-helper';
import { TimelineStatsStateHelper } from 'src/app/shared-services/statistics/common/state-helper/timeline-stats-state-helper';
import { Location } from '@angular/common'
import { openPdfHelper } from 'src/app/shared-services/helper/realtime-pdf.helper';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CsvDownloadService } from '../../../shared-services/download/csv-download.service';
import { PlayersService } from '../../pages/players-module/players.service';

@Component({
    selector: 'app-game-stats',
    templateUrl: './game-stats.component.html',
    styleUrls: ['./game-stats.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GameStatsComponent implements OnInit {

  private _game: GameDto = null;
  private _editable = true;
  private _isPublic = false;
  private _isGameTracker = false;

  public selectedTab = 'QUICK STATS';
  public selectedSubTab = 'OVERVIEW';
  public screenWidth$ = new BehaviorSubject<number>(window.innerWidth);
  public gameId: string;
  public timelineCalculationStats: any;
  public homeName: string;
  public visitorName: string;

  private readonly _quickStatsStateHelper: QuickStatsStateHelper;
  private readonly _teamOverviewStatsStateHelper: TeamOverviewStatsStateHelper;
  private readonly _timelineStatsStateHelper: TimelineStatsStateHelper;

  constructor(
    private readonly logger: NGXLogger,
    public readonly core: CoreService,
    private readonly goalConsumerService: GoalConsumerService,
    private readonly overviewConsumerService: OverviewConsumerService,
    private readonly timelineConsumerService: TimelineConsumerService,
    private readonly teamOverviewSubConsumerService: TeamOverviewSubConsumerService,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private csvDownloadService: CsvDownloadService,
    private playersService: PlayersService
  ) {
    this._quickStatsStateHelper = new QuickStatsStateHelper
    (
        this.goalConsumerService,
        this.overviewConsumerService,
        this.timelineConsumerService
    );

    this.gameId = this.activatedRoute.snapshot.paramMap.get('id');
    this._teamOverviewStatsStateHelper = new TeamOverviewStatsStateHelper(teamOverviewSubConsumerService);
    this._timelineStatsStateHelper = new TimelineStatsStateHelper(timelineConsumerService);
    this.playersService.getMyTeamFoldersData();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setUpStatsView(window.innerWidth);
  }


  @Input()
  set isPublic(value: boolean) {
    this._isPublic = value;
  }

  get isPublic(): boolean {
    return this._isPublic;
  }

  @Input()
  set isGameTracker(value: boolean) {
    this._isGameTracker = value;
  }

  get isGameTracker(): boolean {
    return this._isGameTracker;
  }

  @Input()
  set game(value: GameDto) {
    this._game = value;
    if (value) {
      this.homeName = value.home;
      this.visitorName = value.visitor;
    }
  }

  get game(): GameDto {
    return this._game;
  }

  @Input()
  set editable(value: boolean) {
    this._editable = value;
  }

  get editable(): boolean {
    return this._editable;
  }

  get quickStatsStateHelper(): QuickStatsStateHelper {
    return this._quickStatsStateHelper;
  }

  get teamOverviewStatsStateHelper(): TeamOverviewStatsStateHelper {
    return this._teamOverviewStatsStateHelper;
  }

  get timelineStatsStateHelper(): TimelineStatsStateHelper {
    return this._timelineStatsStateHelper;
  }

  ngOnInit() {
    this.homeName = this.core.gameService.gameModel.home.name;
    this.visitorName = this.core.gameService.gameModel.visitor.name;
  }

  goBack(){
    this.location.back();
  }

  public async openPdf(): Promise<void> {
    await openPdfHelper(
        this.core,
        this.core.loadingCtrl,
        this.core.modalCtrl
    );
  }

  private setUpStatsView (width: number) {
    this.screenWidth$.next(width);
  }

  public matchReport(): void {
    this.router.navigate([
        '/account/' +
        this.core.accountEquipoService.selectedAccountEquipo$.getValue().id +
        '/game/' +
        this.gameId +
        '/match-report'
    ]);
  }

  public onDownloadPlayerScoreClick() {
    const playerScores = this.core.teamOverviewSubConsumerService.getPlayerScoreStatistics();
    const maxStatsValue = Math.max(...playerScores.map(stats => stats.score));
    const teams = Array.from(new Set(playerScores.map(stats => stats.teamName)));
    playerScores.forEach(stats => {
      stats.score5 = this.calculateAlignedScore(stats.score, 5, maxStatsValue);
      stats.match = teams[0] + ' vs ' + teams[1];
      delete stats.playerId;
    });
    this.csvDownloadService.exportArrayOfObjectsToCSV(playerScores, playerScores[0].match);
  }


  private calculateAlignedScore(element: number, scoreValue: number, maxElement: number): number {
    const percentage = (element / maxElement) * 100;

    const alignedValue = (percentage / 100) * scoreValue;

    return Math.round(alignedValue * 10) / 10;
  }

}
