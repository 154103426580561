import { Component, Input, OnInit } from '@angular/core';
import { GamePlayerModel, GameTeamModel } from 'src/app/shared-services/model/game.model';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { EventTime } from '@handballai/stats-calculation';

@Component({
    selector: 'app-exchange-player-for-goalkeeper-container',
    templateUrl: './exchange-player-for-goalkeeper-container.component.html',
    styleUrls: ['./exchange-player-for-goalkeeper-container.component.scss'],
    standalone: false
})
export class ExchangePlayerForGoalkeeperContainerComponent implements OnInit {

  private _fieldPlayers: GamePlayerModel[];
  private _teamMarker: TeamMarker;
  private _team: GameTeamModel;
  private _eventTime: EventTime;
  private _selectedPlayer: GamePlayerModel;

  constructor() { }

  ngOnInit(): void {
  }

  get fieldPlayers(): GamePlayerModel[] {
    return this._fieldPlayers;
  }

  @Input()
  set fieldPlayers(value: GamePlayerModel[]) {
    this._fieldPlayers = value;
  }

  get teamMarker(): TeamMarker {
    return this._teamMarker;
  }

  @Input()
  set teamMarker(value: TeamMarker) {
    this._teamMarker = value;
  }

  get team(): GameTeamModel {
    return this._team;
  }

  @Input()
  set team(value: GameTeamModel) {
    this._team = value;
  }

  get eventTime(): EventTime {
    return this._eventTime;
  }

  @Input()
  set eventTime(value: EventTime) {
    this._eventTime = value;
  }

  get selectedPlayer(): GamePlayerModel {
    return this._selectedPlayer;
  }

  @Input()
  set selectedPlayer(value: GamePlayerModel) {
    this._selectedPlayer = value;
  }
}
