import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { UiBaseEvent } from './events/ui-base-event';
import {
  GoalkeeperDeSelectLiteEvent,
  GoalkeeperSelectLiteEvent,
  PhaseDeSelectedEvent,
  PhaseSelectedEvent,
  PlayerDeSelectEvent, PlayerDeSelectLiteEvent,
  PlayerSelectEvent,
  PlayerSelectLiteEvent, SuspensionToBenchSelectedEvent, TimeoutEvent
} from './events/ui-events';
import { UiCommandExecutionContext } from './commands/ui-base-command';
import { UiCommandExecutor } from './commands/ui-commands';
import { PopoverController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { CoreService } from '../core.service';
import { EventTime } from '@handballai/stats-calculation';
export type UiEventState = 'INITIAL' | 'EVENT_OPEN' | 'EVENT_RESET';

@Injectable({
  providedIn: 'root'
})
export class UiEventDispatcherService {

  constructor(
    private readonly logger: NGXLogger,
    private readonly toastController: ToastController,
    private readonly popoverController: PopoverController,
  ) { }


  get uiEventState$(): BehaviorSubject<UiEventState> {
    return this._uiEventState$;
  }

  get phasesSelectEvent(): PhaseSelectedEvent {
    return this._phasesSelectEvent;
  }

  get eventSlots(): UiBaseEvent<any>[] {
    return this._eventSlots;
  }

  get playerSelectEvent(): PlayerSelectEvent  {
    return this._playerSelectEvent;
  }

  get extraSelectEvent(): SuspensionToBenchSelectedEvent  {
    return this._extraSelectEvent;
  }

  get extraTimeoutEvent(): TimeoutEvent {
    return this._extraTimeoutEvent;
  }

  private _core: CoreService;

  private _eventSlots: UiBaseEvent<any>[] = [];
  private uiCommandExecutor = new UiCommandExecutor();
  private uiCommandExecutionContext: UiCommandExecutionContext;
  private readonly _uiEventState$ = new BehaviorSubject<UiEventState>('INITIAL');
  private _playerSelectEvent: PlayerSelectEvent;
  private _phasesSelectEvent: PhaseSelectedEvent;
  private _extraSelectEvent: SuspensionToBenchSelectedEvent;
  private _extraTimeoutEvent: TimeoutEvent;

  initCore(core: CoreService) {
    this._core = core;

    this.uiCommandExecutionContext = {
      eventDispatcher: this,
      logger: this.logger,
      actionService: this._core.actionService,
      gameService: this._core.gameService,
      gameDataService: this._core.gameDataService,
      popoverController: this.popoverController,
      eventFeedService: this._core.eventFeedService,
      toastController: this.toastController,
      eventBufferService: this._core.eventBufferService,
    } as UiCommandExecutionContext;
  }

  public pushEventSlot0(
      event: PlayerSelectEvent
          | PlayerDeSelectEvent
          | PlayerSelectLiteEvent
          | PlayerDeSelectLiteEvent
          | GoalkeeperSelectLiteEvent
          | GoalkeeperDeSelectLiteEvent
  ): void {
    this._eventSlots.push(event);
    this._uiEventState$.next('EVENT_OPEN');
    this._playerSelectEvent = event;
    this.uiCommandExecutor.executeCommand(event, this.uiCommandExecutionContext);
    this._core.gameSystemService.clearSelections();
  }

  public pushEventSlot<T>(event: UiBaseEvent<T>): void {
    this._eventSlots.push(event);
    this.uiCommandExecutor.executeCommand(event, this.uiCommandExecutionContext);
  }

  public pushPhasesSlot(event: PhaseSelectedEvent | PhaseDeSelectedEvent) {
    if (event instanceof PhaseSelectedEvent) {
      this._phasesSelectEvent = event;
    } else {
      this._phasesSelectEvent = undefined;
    }
  }

  public pushExtraEventSlot(event: SuspensionToBenchSelectedEvent) {
    this._extraSelectEvent = event;
    this.uiCommandExecutor.executeCommand(
        event,
        this.uiCommandExecutionContext
    );
  }

  public pushExtraTimeoutEventSlot(event: TimeoutEvent) {
    this._extraTimeoutEvent = event;
    this.uiCommandExecutor.executeCommand(event, this.uiCommandExecutionContext);
  }

  public clearAllSlots(): void {
    // workaround for hanging event state
    setTimeout(() => {
      this._uiEventState$.next('EVENT_RESET');
    }, 0);
    this.logger.debug('UiEventDispatcherService.clearAllSlots', this._uiEventState$.value);
    this._eventSlots = [] as UiBaseEvent<any>[];
    this._playerSelectEvent = undefined;
    this._phasesSelectEvent = undefined;
    this._extraSelectEvent = undefined;
    this._extraTimeoutEvent = undefined;
  }

  public resetEventStateToInitial(): void {
    this._uiEventState$.next('INITIAL');
  }

  public updatePlayerEventWithVideoTime(videoTime: EventTime): void {
    if (this._playerSelectEvent) {
      this._playerSelectEvent.timestamp = videoTime;
    }
  }
}
