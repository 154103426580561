import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CounterModel, MiscEventConsumerCore } from '@handballai/stats-calculation';
import { BehaviorSubject } from 'rxjs';
import { CoreService } from 'src/app/shared-services/core.service';

@Injectable({
  providedIn: 'root'
})
export class MiscEventConsumerService {

  private _miscEventConsumerCore: MiscEventConsumerCore;

  constructor(
      private readonly logger: NGXLogger,
  ) { }

  private _selectedPlayerCounters$ = new BehaviorSubject<CounterModel[]>([]);
  get selectedPlayerCounters$(): BehaviorSubject<CounterModel[]> {
    return this._selectedPlayerCounters$;
  }
  private _core: CoreService;

  initCore(core: CoreService) {
    this._core = core;
    this.subscribeTo7MeterProvoke();
    this.subscribeTo7MeterProvokeSuspension();
    this.subscribeTo2MinProvoke();
    this.subscribeToFoulReceive();
    this.subscribeToOneAndOneWon();
    this.subscribeToShotBlocked();
  }

  public init(): void {
    this._miscEventConsumerCore = new MiscEventConsumerCore(
      this._core.gameService.gameModel.home.players,
      this._core.gameService.gameModel.visitor.players
    );
  }

  public selectPlayer(id: number): void {
    this._selectedPlayerCounters$
        .next([...Array.from(this._miscEventConsumerCore.playerCounterMap.get(id).values())]);
  }

  public deSelectPlayer(): void {
    this._selectedPlayerCounters$.next([]);
  }

  private subscribeToFoulReceive(): void {
    this._core.playByPlayProducerService.on([
      'FOUL_RECEIVE'
    ], pbp => {
      this.logger.debug('MiscEventConsumerService.subscribeToFoulReceive: ', pbp);

      this._miscEventConsumerCore.incrementTeamCounter(pbp.teamMarker, `${pbp.event}-${pbp.phase}`);
      this._miscEventConsumerCore.incrementPlayerCounter(pbp.offensePlayer.id, `${pbp.event}-${pbp.phase}`);
      this._core.playerEventListConsumerService.addPlayerEvent({
        eventTime: pbp.eventTime,
        phase: pbp.phase,
        eventType: pbp.event,
        playerId: pbp.offensePlayer.id
      });
      if (pbp.defensePlayer) {
        this._miscEventConsumerCore.incrementPlayerCounter(pbp.defensePlayer.id, `FOUL_COMMIT`);
        this._core.playerEventListConsumerService.addPlayerEvent({
          eventTime: pbp.eventTime,
          phase: pbp.phase,
          eventType: 'FOUL_COMMIT',
          playerId: pbp.defensePlayer.id
        });
      }
    });
  }

  private subscribeTo2MinProvoke(): void {
    this._core.playByPlayProducerService.on([
      '2MIN_PROVOKE',
    ], pbp => {
      this.logger.debug('MiscEventConsumerService.subscribeTo2MinProvoke: ', pbp);

      this._miscEventConsumerCore.incrementTeamCounter(pbp.teamMarker, `${pbp.event}-${pbp.phase}`);
      this._miscEventConsumerCore.incrementPlayerCounter(pbp.offensePlayer.id, `${pbp.event}-${pbp.phase}`);
      this._core.playerEventListConsumerService.addPlayerEvent({
        eventTime: pbp.eventTime,
        phase: pbp.phase,
        eventType: pbp.event,
        playerId: pbp.offensePlayer.id
      });

      if (pbp.defensePlayer) {
        this._miscEventConsumerCore.incrementPlayerCounter(pbp.defensePlayer.id, `2MIN_COMMIT`);
        this._core.playerEventListConsumerService.addPlayerEvent({
          eventTime: pbp.eventTime,
          phase: pbp.phase,
          eventType: '2MIN_COMMIT',
          playerId: pbp.defensePlayer.id
        });
      }
    });
  }

  private subscribeTo7MeterProvokeSuspension(): void {
    this._core.playByPlayProducerService.on([
      '7M_PROVOKE_SUSPENSION',
    ], pbp => {
      this.logger.debug('MiscEventConsumerService.subscribeTo7MeterProvokeSuspension: ', pbp);

      this._miscEventConsumerCore.incrementTeamCounter(pbp.teamMarker, `${pbp.event}-${pbp.phase}`);
      this._miscEventConsumerCore.incrementPlayerCounter(pbp.offensePlayer.id, `${pbp.event}-${pbp.phase}`);
      this._core.playerEventListConsumerService.addPlayerEvent({
        eventTime: pbp.eventTime,
        phase: pbp.phase,
        eventType: pbp.event,
        playerId: pbp.offensePlayer.id
      });

      if (pbp.defensePlayer) {
        this._miscEventConsumerCore.incrementPlayerCounter(pbp.defensePlayer.id, `7M_SUSPENSION_COMMIT`);
        this._core.playerEventListConsumerService.addPlayerEvent({
          eventTime: pbp.eventTime,
          phase: pbp.phase,
          eventType: '7M_SUSPENSION_COMMIT',
          playerId: pbp.defensePlayer.id
        });
      }
    });
  }

  private subscribeTo7MeterProvoke(): void {
    this._core.playByPlayProducerService.on([
      '7M_PROVOKE',
    ], pbp => {
      this.logger.debug('MiscEventConsumerService.subscribeTo7MeterProvoke: ', pbp);

      this._miscEventConsumerCore.incrementTeamCounter(pbp.teamMarker, `${pbp.event}-${pbp.phase}`);
      this._miscEventConsumerCore.incrementPlayerCounter(pbp.offensePlayer.id, `${pbp.event}-${pbp.phase}`);
      this._core.playerEventListConsumerService.addPlayerEvent({
        eventTime: pbp.eventTime,
        phase: pbp.phase,
        eventType: pbp.event,
        playerId: pbp.offensePlayer.id
      });

      if (pbp.defensePlayer) {
        this._miscEventConsumerCore.incrementPlayerCounter(pbp.defensePlayer.id, `7M_COMMIT`);
        this._core.playerEventListConsumerService.addPlayerEvent({
          eventTime: pbp.eventTime,
          phase: pbp.phase,
          eventType: '7M_COMMIT',
          playerId: pbp.defensePlayer.id
        });
      }
    });
  }

  private subscribeToShotBlocked(): void {
    this._core.playByPlayProducerService.on([
      'SHOT_BLOCKED'
    ], pbp => {
      this.logger.debug('MiscEventConsumerService.shotBlockedSubscription: ', pbp);

      this._miscEventConsumerCore.incrementTeamCounter(pbp.teamMarker, `${pbp.event}-${pbp.phase}`);
      this._miscEventConsumerCore.incrementPlayerCounter(pbp.offensePlayer.id, `${pbp.event}-${pbp.phase}`);

      this._core.playerEventListConsumerService.addPlayerEvent({
        eventTime: pbp.eventTime,
        phase: pbp.phase,
        eventType: pbp.event,
        playerId: pbp.offensePlayer.id
      });

      if (pbp.defensePlayer) {
        this._miscEventConsumerCore.incrementPlayerCounter(pbp.defensePlayer.id, 'BLOCK');
        this._core.playerEventListConsumerService.addPlayerEvent({
          eventTime: pbp.eventTime,
          phase: pbp.phase,
          eventType: 'BLOCK',
          playerId: pbp.defensePlayer.id
        });
      }
    });
  }

  private subscribeToOneAndOneWon(): void {
    this._core.playByPlayProducerService.on(['1-1_WON'], pbp => {
      this.logger.debug('MiscEventConsumerService.1-1WonSubscription: ', pbp);

      this._miscEventConsumerCore.incrementTeamCounter(pbp.teamMarker, `${pbp.event}-${pbp.phase}`);
      this._miscEventConsumerCore.incrementPlayerCounter(pbp.offensePlayer.id, `${pbp.event}-${pbp.phase}`);

      this._core.playerEventListConsumerService.addPlayerEvent({
        eventTime: pbp.eventTime,
        phase: pbp.phase,
        eventType: pbp.event,
        playerId: pbp.offensePlayer.id
      });

      if (pbp.defensePlayer) {
        this._core.playerEventListConsumerService.addPlayerEvent({
          eventTime: pbp.eventTime,
          phase: pbp.phase,
          eventType: '1-1_LOST',
          playerId: pbp.defensePlayer.id
        });
        this._miscEventConsumerCore.incrementPlayerCounter(pbp.defensePlayer.id, `1-1_LOST`);
      }
    });
  }
}
