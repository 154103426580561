import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { GameDto, PlayByPlayDto } from 'src/app/api/hai-api';
import { HalftimeTypes, PlayByPlayModel, GameModel } from '@handballai/stats-calculation';
import { PlayerEventModel } from '@handballai/stats-calculation';
import { PhasesActionTypes } from 'src/app/shared-services/actions/action-types';
import { DefenseSystem } from '@handballai/stats-calculation';
import { DateTime } from 'luxon';
import { GamePlayerModel } from 'src/app/shared-services/model/game.model';
import { mapGamePlayerToTeamStatsPlayer } from 'src/app/shared-services/helper/statistics-helper';
import { CoreService } from '../core.service';
import { GameType } from 'src/app/shared-services/game/game-type.model';
import { populateGamePlayerMap } from 'src/app/shared-services/statistics/ingest/event-feed.util';

@Injectable({
  providedIn: 'root'
})
export class GameStatsHandlerService {

  constructor(
      private readonly logger: NGXLogger,
  ) { }
  private _core: CoreService;

  private gameModel: GameModel;
  private gamePlayerMap = new Map<number, GamePlayerModel>();
  initCore(core: CoreService) {
      this._core = core;
  }

  public init(
      gameDto: GameDto
  ): void {

    this.gameModel = JSON.parse(gameDto.gameModelSnapshot);
    // TODO: Store gameMode in backend and apply the value
    // Currently defaults to keep compile happy
    this.gameModel.startDate = DateTime.fromISO(this.gameModel.startDate as unknown as string);
    this._core.gameService.initGame(this.gameModel, gameDto.gameType as GameType, 'POST_LIVE_VIDEO_MODE');
    this.createGamePlayerMap();
    this._core.timerWrapperService.initTimeWrapperService(false);
    // Init Playtime from external model
    this._core.playTimeService.initFromExternalModel(gameDto.playTimeDto);

    //Init the remaining services based on Game Service
    this._core.goalConsumerService.init();
    this._core.lostBallConsumerService.init();
    this._core.miscEventConsumerService.init();
    this._core.playerEventListConsumerService.init();
    this._core.possessionsConsumerService.init();
    this._core.suspensionConsumerService.init();
    this._core.playByPlayProducerService.init();
    this._core.easyStatsService.init();
    this._core.eventFeedService.init();
    this._core.gameSeedService.init(
        this.gameModel.home.name,
        this.gameModel.visitor.name,
        this.gameModel.home.players,
        this.gameModel.visitor.players
    );
    this._core.goalkeeperEfficiencyConsumerService.init();
    this._core.timelineConsumerService.init();
    this._core.teamOverviewSubConsumerService.init();
    this._core.overviewConsumerService.init();
    this._core.gameSystemConsumerService.init();
  }

  public addPlayByPlayEvent(externalPbP: PlayByPlayDto): void {
    const pbp: PlayByPlayModel = this.convertPbpDtoToPbpModel(externalPbP);
    this._core.eventFeedService.addExternalPlayByPlayPEvent(pbp);
  }

  public convertPbpDtoToPbpModel(externalPbP: PlayByPlayDto): PlayByPlayModel {
    const offense = externalPbP.offense.split(':').filter(plId => {
      if (!this.gamePlayerMap.has(+plId)) this.logger.error(`Player ${plId} from offense not found in gamePlayerMap`, externalPbP, this.gamePlayerMap);
      return true; // Everytime return true to notice the error
    }).map(playerId => mapGamePlayerToTeamStatsPlayer(this.gamePlayerMap.get(+playerId)));
    const defense = externalPbP.defense.split(':').filter(plId => {
      if (!this.gamePlayerMap.has(+plId)) this.logger.error(`Player ${plId} from defense not found in gamePlayerMap`, externalPbP, this.gamePlayerMap);
      return true; // Everytime return true to notice the error
    }).map(playerId => mapGamePlayerToTeamStatsPlayer(this.gamePlayerMap.get(+playerId)));
    return {
      id: externalPbP.id,
      event: externalPbP.event as PlayerEventModel,
      teamName: externalPbP.teamName,
      teamMarker: externalPbP.teamMarker,
      teamId: externalPbP.teamId,
      phase: externalPbP.phase as PhasesActionTypes,
      orderId: externalPbP.orderId,
      defenseSystem: externalPbP.defenseSystem as DefenseSystem,
      eventTime: {
        timestamp: DateTime.fromISO(externalPbP.timestamp),
        secondsSinceStartOfGame: externalPbP.secondsSinceStartOfGame,
        secondsSinceHalftime: externalPbP.secondsSinceHalftime,
        minutesSinceHalftime: externalPbP.minutesSinceHalftime,
        halftime: externalPbP.halftime as HalftimeTypes,
        eventDuration: externalPbP?.eventDuration,
      },
      possessions: externalPbP.possessions,
      possessionId: externalPbP.possessionId,
      executionPosition: externalPbP?.executionPosition,
      offenseSystem: externalPbP.offenseSystem,
      shotLocation: externalPbP?.shotLocation,
      offensePlayer: externalPbP.offensePlayer ? mapGamePlayerToTeamStatsPlayer(
        this.gamePlayerMap.get(externalPbP.offensePlayer)
      ) : undefined,
      defensePlayer: externalPbP.defensePlayer ? mapGamePlayerToTeamStatsPlayer(
        this.gamePlayerMap.get(externalPbP.defensePlayer)
      ) : undefined,
      assistantPlayer: externalPbP.assistantPlayer ? mapGamePlayerToTeamStatsPlayer(
        this.gamePlayerMap.get(externalPbP.assistantPlayer)
      ) : undefined,
      offense,
      defense,
      important: externalPbP.important,
      gameSystem: externalPbP?.gameSystem,
      videoTimestamp: externalPbP?.videoTimestamp,
    } as PlayByPlayModel;
  }

  private createGamePlayerMap(): void {
    this.gamePlayerMap = populateGamePlayerMap(this.gameModel);
  }
}
