import { Component, Input, OnInit } from '@angular/core';
import { GamePlayerModel, GameTeamModel } from 'src/app/shared-services/model/game.model';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { SuspensionActionTypes } from 'src/app/shared-services/actions/action-types';
import { EventTime } from '@handballai/stats-calculation';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-exchange-player-popover-container',
    templateUrl: './exchange-player-popover-container.component.html',
    styleUrls: ['./exchange-player-popover-container.component.scss'],
    standalone: false
})
export class ExchangePlayerPopoverContainerComponent implements OnInit {

  private _teamMarker: TeamMarker;
  private _team: GameTeamModel;
  private _eventTime: EventTime;
  private _suspensionAction: SuspensionActionTypes;
  private _selectedSuspendedPlayer: GamePlayerModel;
  private _isRegularMode = true;
  private _returnSubject: Subject<number> | undefined;

  constructor() { }

  ngOnInit() {}

  get teamMarker(): TeamMarker {
    return this._teamMarker;
  }

  @Input()
  set teamMarker(value: TeamMarker) {
    this._teamMarker = value;
  }

  get team(): GameTeamModel {
    return this._team;
  }

  @Input()
  set team(value: GameTeamModel) {
    this._team = value;
  }

  get eventTime(): EventTime {
    return this._eventTime;
  }

  @Input()
  set eventTime(value: EventTime) {
    this._eventTime = value;
  }

  get suspensionAction(): SuspensionActionTypes {
    return this._suspensionAction;
  }

  @Input()
  set suspensionAction(value: SuspensionActionTypes) {
    this._suspensionAction = value;
  }

  get selectedSuspendedPlayer(): GamePlayerModel {
    return this._selectedSuspendedPlayer;
  }

  @Input()
  set selectedSuspendedPlayer(value: GamePlayerModel) {
    this._selectedSuspendedPlayer = value;
  }


  get isRegularMode(): boolean {
    return this._isRegularMode;
  }

  @Input()
  set isRegularMode(value: boolean) {
    this._isRegularMode = value;
  }

  get returnSubject(): Subject<number> | undefined {
    return this._returnSubject;
  }

  @Input()
  set returnSubject(value: Subject<number> | undefined) {
    this._returnSubject = value;
  }
}
