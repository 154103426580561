import { PlayerStatsAggregateDto, TeamStatsAggregateDto } from 'src/app/api/hai-api';
import { CounterModel } from '@handballai/stats-calculation';
import { EventCountersModel } from '@handballai/stats-calculation';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export function shotsMap(ind: TeamStatsAggregateDto|PlayerStatsAggregateDto) {
    if (!ind) { return []; }
    return [
      new CounterModel('GOAL', +ind.totalGoals ),
      new CounterModel('GOAL-OFFENSE_POSITIONAL', +ind.totalGoalsOffensePositional ),
      new CounterModel('GOAL-COUNTER_GOAL', +ind.totalGoalsCounterGoals ),
      new CounterModel('GOAL-FAST_BREAK', +ind.totalGoalsFastBreak ),
      new CounterModel('GOAL-1', +ind.totalGoals1 ),
      new CounterModel('GOAL-2', +ind.totalGoals2 ),
      new CounterModel('GOAL-3', +ind.totalGoals3 ),
      new CounterModel('GOAL-4', +ind.totalGoals4 ),
      new CounterModel('GOAL-5', +ind.totalGoals5 ),
      new CounterModel('GOAL-6', +ind.totalGoals6 ),
      new CounterModel('GOAL-7', +ind.totalGoals7 ),
      new CounterModel('GOAL-8', +ind.totalGoals8 ),
      new CounterModel('GOAL-9', +ind.totalGoals9 ),
      new CounterModel('GOAL-LEFT_WING', +ind.totalGoalsLeftWing ),
      new CounterModel('GOAL-LEFT_6M', +ind.totalGoalsLeft6m ),
      new CounterModel('GOAL-CENTER_6M', +ind.totalGoalsCenter6m ),
      new CounterModel('GOAL-RIGHT6M', +ind.totalGoalsRight6m ),
      new CounterModel('GOAL-RIGHT_WING', +ind.totalGoalsRightWing ),
      new CounterModel('GOAL-LEFT_9M', +ind.totalGoalsLeft9m ),
      new CounterModel('GOAL-CENTER_9M', +ind.totalGoalsCenter9m ),
      new CounterModel('GOAL-RIGHT_9M', +ind.totalGoalsRight9m ),
      new CounterModel('GOAL-7_METERS', +ind.totalGoals7Meters ),
      new CounterModel('GOAL-OPPOSITE_FIELD', +ind.totalGoalsOppositeField ),
      new CounterModel('SAVE', +ind.totalSaves ),
      new CounterModel('SAVE-OFFENSE_POSITIONAL', +ind.totalSavesOffensePositional ),
      new CounterModel('SAVE-COUNTER_GOAL', +ind.totalSavesCounterGoals ),
      new CounterModel('SAVE-FAST_BREAK', +ind.totalSavesFastBreak ),
      new CounterModel('SAVE-1', +ind.totalSaves1 ),
      new CounterModel('SAVE-2', +ind.totalSaves2 ),
      new CounterModel('SAVE-3', +ind.totalSaves3 ),
      new CounterModel('SAVE-4', +ind.totalSaves4 ),
      new CounterModel('SAVE-5', +ind.totalSaves5 ),
      new CounterModel('SAVE-6', +ind.totalSaves6 ),
      new CounterModel('SAVE-7', +ind.totalSaves7 ),
      new CounterModel('SAVE-8', +ind.totalSaves8 ),
      new CounterModel('SAVE-9', +ind.totalSaves9 ),
      new CounterModel('SAVE-LEFT_WING', +ind.totalSavesLeftWing ),
      new CounterModel('SAVE-LEFT_6M', +ind.totalSavesLeft6m ),
      new CounterModel('SAVE-CENTER_6M', +ind.totalSavesCenter6m ),
      new CounterModel('SAVE-RIGHT_6M', +ind.totalSavesRight6m ),
      new CounterModel('SAVE-RIGHT_WING', +ind.totalSavesRightWing ),
      new CounterModel('SAVE-LEFT_9M', +ind.totalSavesLeft9m ),
      new CounterModel('SAVE-CENTER_9M', +ind.totalSavesCenter9m ),
      new CounterModel('SAVE-RIGHT_9M', +ind.totalSavesRight9m ),
      new CounterModel('SAVE-7_METERS', +ind.totalSaves7Meters ),
      new CounterModel('SAVE-OPPOSITE_FIELD', +ind.totalSavesOppositeField ),
      new CounterModel('POST_OUT', (+ind.totalPostOutOffensePositional) + (+ind.totalPostOutCounterGoal) + (+ind.totalPostOutFastBreak)),
      new CounterModel('POST_OUT-OFFENSE_POSITIONAL',  +ind.totalPostOutOffensePositional),
      new CounterModel('POST_OUT-COUNTER_GOAL', +ind.totalPostOutCounterGoal ),
      new CounterModel('POST_OUT-FAST_BREAK', +ind.totalPostOutFastBreak ),
      new CounterModel('POST_OUT-0', +ind.totalPostOut0 ),
      new CounterModel('POST_OUT-10', +ind.totalPostOut10 ),
      new CounterModel('POST_OUT-11', +ind.totalPostOut11 ),
      new CounterModel('POST_OUT-LEFT_WING', +ind.totalPostOutLeftWing ),
      new CounterModel('POST_OUT-LEFT_6M', +ind.totalPostOutLeft6m ),
      new CounterModel('POST_OUT-CENTER_6M', +ind.totalPostOutCenter6m ),
      new CounterModel('POST_OUT-RIGHT_6M', +ind.totalPostOutRight6m ),
      new CounterModel('POST_OUT-RIGHT_WING', +ind.totalPostOutRightWing ),
      new CounterModel('POST_OUT-LEFT_9M', +ind.totalPostOutLeft9m ),
      new CounterModel('POST_OUT-CENTER_9M', +ind.totalPostOutCenter9m ),
      new CounterModel('POST_OUT-RIGHT_9M', +ind.totalPostOutRight9m ),
      new CounterModel('POST_OUT-7_METERS', +ind.totalPostOut7Meters ),
      new CounterModel('POST_OUT-OPPOSITE_FIELD', +ind.totalPostOutOppositeField ),
    ];
}

export const eventTableLeftMap = async (ind: EventCountersModel|PlayerStatsAggregateDto|TeamStatsAggregateDto, tg: Function) => {
  if (!ind) {
    return [];
  }
  return [
    {
      template: await tg(_("Attack Fault Provoke")),
      total: ind.totalAttackFaultProvoke,
      ...((ind['avgAttackFaultProvoke']) ? {average: ind['avgAttackFaultProvoke']}:{}),
    },
    {
      template: await tg(_("Technical Mistake Provoke")),
      total: ind.totalTechnicalMistakeProvoke,
      ...((ind['avgTechnicalMistakeProvoke']) ? {average: ind['avgTechnicalMistakeProvoke']}:{}),
    },
    {
      template: await tg(_("Lost Ball Provoke")),
      total: ind.totalLostBallProvoke,
      ...((ind['avgLostBallProvoke']) ? {average: ind['avgLostBallProvoke']}:{}),
    },
    {
      template: await tg(_("2 Minute Commit")),
      total: ind.totalTwoMinCommit,
      ...((ind['avgTwoMinCommit']) ? {average: ind['avgTwoMinCommit']}:{}),
    },
    {
      template: await tg(_("7 Meters Commit")),
      total: ind.totalSevenMetersCommit,
      ...((ind['avgSevenMetersCommit']) ? {average: ind['avgSevenMetersCommit']}:{}),
    },
    {
      template: await tg(_("7 Meters Commit + Suspension")),
      total: ind.totalSevenMSuspCommit,
      ...((ind['avgSevenMSuspCommit']) ? {average: ind['avgSevenMSuspCommit']}:{}),
    },
    {
      template: await tg(_("1&1 Lost")),
      total: ind.totalOneAndOneLost,
      ...((ind['avgOneAndOneLost']) ? {average: ind['avgOneAndOneLost']}:{}),
    },
    {
      template: await tg(_("Foul Commit")),
      total: ind.totalFoulCommit,
      ...((ind['avgFoulCommit']) ? {average: ind['avgFoulCommit']}:{}),
    },
    {
      template: await tg(_("Block")),
      total: ind.totalShotBlock,
      ...((ind['avgShotBlock']) ? {average: ind['avgShotBlock']}:{}),
    },
  ];
}
export const eventTableRightMap = async (ind: EventCountersModel|PlayerStatsAggregateDto|TeamStatsAggregateDto, tg: Function) => {
  if (!ind) {
    return [];
  }
  return [
    {
      template: await tg(_("Attack Fault Commit")),
      total: ind.totalAttackFaultCommit,
      ...((ind['avgAttackFaultCommit']) ? {average: ind['avgAttackFaultCommit']}:{}),
    },
    {
      template: await tg(_("Technical Mistake Commit")),
      total: ind.totalTechnicalMistakeCommit,
      ...((ind['avgTechnicalMistakeCommit']) ? {average: ind['avgTechnicalMistakeCommit']}:{}),
    },
    {
      template: await tg(_("Lost Ball Commit")),
      total: ind.totalLostBall,
      ...((ind['avgLostBall']) ? {average: ind['avgLostBall']}:{}),
    },
    {
      template: await tg(_("2 Minute Provoke")),
      total: ind.totalTwoMinProvoke,
      ...((ind['avgTwoMinProvoke']) ? {average: ind['avgTwoMinProvoke']}:{}),
    },
    {
      template: await tg(_("7 Meters Provoke")),
      total: ind.totalSevenMetersProvoke,
      ...((ind['avgSevenMetersProvoke']) ? {average: ind['avgSevenMetersProvoke']}:{}),
    },
    {
      template: await tg(_("7 Meters Provoke + Suspension")),
      total: ind.totalSevenMSuspProvoke,
      ...((ind['avgSevenMSuspProvoke']) ? {average: ind['avgSevenMSuspProvoke']}:{}),
    },
    {
      template: await tg(_("1&1 Won")),
      total: ind.totalOneAndOneWon,
      ...((ind['avgOneAndOneWon']) ? {average: ind['avgOneAndOneWon']}:{}),
    },
    {
      template: await tg(_("Foul Receive")),
      total: ind.totalFoulReceive,
      ...((ind['avgFoulReceive']) ? {average: ind['avgFoulReceive']}:{}),
    },
    {
      template: await tg(_("Shot Blocked")),
      total: ind.totalShotBlocked,
      ...((ind['avgShotBlocked']) ? {average: ind['avgShotBlocked']}:{}),
    },
  ];
}
