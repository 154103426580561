<div class="mt-4 mr-4 dataContainer">
  <div class="display-flex-space-between">
    <app-selector-check-box-list [showOffenseDefenseFilter]="showOffenseDefenseFilter"
                                 [availableEvents]="availableEvents"
                                 [availableOffenseSystem]="availableOffenseSystem"
                                 [availablePhases]="availablePhases"
                                 [availableDefenseSystem]="availableDefenseSystem"
                                 [selectionOffenseEvents]="selectionOffenseEvents"
                                 [selectionDefenseEvents]="selectionDefenseEvents"
                                 [selectionTimeEventsData]="selectionTimeEventsData"
                                 [(selection)]="systemPhaseSelection"></app-selector-check-box-list>
  </div>
  <div class="mt-2">
    <app-time-of-events [(selection)]="timeOfEventsSelection"></app-time-of-events>
  </div>
  <div class="buttons">
    <button (click)="dismiss()"
      class="darkBtn event-button focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full rounded-3xl"
    >
      <div class="buttonContent">
        <ion-icon style="font-size: 1.5rem" name="funnel-outline"></ion-icon>
        <ion-label class="nameLabel">{{ 'Filter' | translate }}</ion-label>
      </div>
    </button>
  </div>
</div>
