import { Injectable } from '@angular/core';
import { CoreService } from '../../../shared-services/core.service';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { MyTeamFileDto, MyTeamFilesDto, MyTeamFolderDto, MyTeamPlayerDto } from '../../../api/hai-players-api';
import { TManageAccountEquipoGrants } from '../../../shared-services/account-equipo/account-equipo.service';
import { ToastController } from '@ionic/angular';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { ScoutingPlaylistDto } from '../../../api/hai-api';
import { ManageFolderAccessModalComponent } from './components/manage-folder-access-modal/manage-folder-access-modal.component';

@Injectable({
  providedIn: 'root',
})
export class PlayersService {

  selectedEquipo: TManageAccountEquipoGrants;
  private _myTeamFolders$ = new BehaviorSubject<MyTeamFolderDto[]>([]);
  private _myTeamPlayers$ = new BehaviorSubject<MyTeamPlayerDto[]>([]);
  private _myTeamFilesForFolder$ = new BehaviorSubject<MyTeamFileDto[]>([]);
  private _myTeamPlayersForSpecificFolder$ = new BehaviorSubject<MyTeamPlayerDto[]>([]);
  public filesUpdated$ = new BehaviorSubject<boolean>(false);
  private _reorderedVideos: MyTeamFileDto[] = [];
  private _reorderedScoutingPlaylist: ScoutingPlaylistDto;
  private _foldersStateInFoldersModal: MyTeamFolderDto[] = [];
  private _accountEquipoIdInFoldersModal: number;
  private _uploadProgress$ = new BehaviorSubject<number>(0);

  constructor(private core: CoreService, private httpClient: HttpClient, private readonly logger: NGXLogger, private readonly toastCntl: ToastController) {
    this.core.accountEquipoService.selectedAccountEquipo$.subscribe(async selectedAccountEquipo => {
      this.selectedEquipo = selectedAccountEquipo;
    });
  }

  get uploadProgress() {
    return this._uploadProgress$;
  }

  get myTeamFolders$() {
    return this._myTeamFolders$;
  }

  get myTeamPlayers$() {
    return this._myTeamPlayers$;
  }

  get myTeamFilesForFolder$() {
    return this._myTeamFilesForFolder$;
  }

  get myTeamPlayersForSpecificFolder$() {
    return this._myTeamPlayersForSpecificFolder$;
  }

  get reorderedVideos() {
    return this._reorderedVideos;
  }

  get reorderedScoutingPlaylist() {
    return this._reorderedScoutingPlaylist;
  }

  set reorderedScoutingPlaylist(playlist: ScoutingPlaylistDto) {
    this._reorderedScoutingPlaylist = playlist;
  }

  get foldersStateInFoldersModal() {
    return this._foldersStateInFoldersModal;
  }

  set foldersStateInFoldersModal(folders: MyTeamFolderDto[]) {
    this._foldersStateInFoldersModal = folders;
  }

  get accountEquipoIdInFoldersModal() {
    return this._accountEquipoIdInFoldersModal;
  }

  set accountEquipoIdInFoldersModal(accountEquipoId: number) {
    this._accountEquipoIdInFoldersModal = accountEquipoId;
  }

  set reorderedVideos(newReorderedVideos: MyTeamFileDto[]) {
    this._reorderedVideos = newReorderedVideos;
  }

  public async getMyTeamFoldersData() {
    if (!this.selectedEquipo?.grants?.includes('manage_my_team') || !this.selectedEquipo?.account?.permissions?.accessMyTeam) {
      return;
    }
    const user = await this.core.storageService.getUserAsPromise();
    this.core.myTeamFoldersService
      .myTeamFolderControllerGetAllFolders(String(user.id), String(this.selectedEquipo.id))
      .subscribe(async (myTeamFolders) => {
        this._myTeamFolders$.next(myTeamFolders);
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async addNewFolderPromise(folder: MyTeamFolderDto, accountEquipoId?: number): Promise<MyTeamFolderDto> {
    folder.accountEquipoId = accountEquipoId ?? this.selectedEquipo.id;
    await this.core.loadingService.present();
    return new Promise<MyTeamFolderDto>(async (ok, ko) => {
      const user = await this.core.storageService.getUserAsPromise();
      this.core.myTeamFoldersService.myTeamFolderControllerAddFolder(
        String(user.id),
        String(accountEquipoId ?? this.selectedEquipo.id),
        folder)
        .subscribe(async myTeamFolder => {
          this.logger.debug('PlayersService.addNewFolderPromise() - folder added successfully');
          await this.core.loadingService.dismiss();
          ok(myTeamFolder);
        },
          async error => {
            await this.core.loadingService.dismiss();
            this.logger.error('PlayersService.addNewFolderPromise() - error adding folder for account!', error);
            ko(error);
          });
    });
  }

  public async getFoldersForAccount(accountEquipoId?: number): Promise<MyTeamFolderDto[]> {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    return new Promise<MyTeamFolderDto[]>((ok, ko) => {
      this.core.myTeamFoldersService.myTeamFolderControllerGetAllFolders(String(user.id), String(accountEquipoId ?? this.selectedEquipo.id))
        .subscribe(async myTeamFolders => {
          this.logger.debug('PlayersService.getFoldersForAccount() - folders for account read successfully');
          await this.core.loadingService.dismiss();
          ok(myTeamFolders);
        },
          async error => {
            await this.core.loadingService.dismiss();
            this.logger.error('PlayersService.getFoldersForAccount() - error reading folders for account!', error);
            ko(error);
          });
    });
  }

  public async getFoldersForPlayer(playerId: number): Promise<MyTeamFolderDto[]> {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    return new Promise<MyTeamFolderDto[]>((ok, ko) => {
      this.core.myTeamFoldersService.myTeamFolderControllerGetAllFoldersForPlayer(String(user.id), String(this.selectedEquipo.id), String(playerId))
        .subscribe(async myTeamFolders => {
          this.logger.debug('PlayersService.this.selectedEquipo.id() - folders for player read successfully');
          await this.core.loadingService.dismiss();
          ok(myTeamFolders);
        },
          async error => {
            await this.core.loadingService.dismiss();
            this.logger.error('PlayersService.this.selectedEquipo.id() - error reading folders for player!', error);
            ko(error);
          });
    });
  }

  public async getPlayersPromise(accountEquipoId?: number): Promise<MyTeamPlayerDto[]> {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    return new Promise<MyTeamPlayerDto[]>((ok, ko) => {
      this.core.myTeamPlayersService.myTeamPlayersControllerGetAllPlayersForAccount(String(user.id), String(accountEquipoId ?? this.selectedEquipo.id))
        .subscribe(async myTeamPlayers => {
          this.logger.debug('PlayersService.getPlayersPromise - players read successfully');
          await this.core.loadingService.dismiss();
          ok(myTeamPlayers);
        },
          async error => {
            await this.core.loadingService.dismiss();
            this.logger.error('PlayersService.getPlayersPromise - error reading players!', error);
            ko(error);
          });
    });
  }

  public async getMyTeamPlayersData() {
    if (!this.selectedEquipo?.grants?.includes('manage_my_team') || !this.selectedEquipo?.account?.permissions?.accessMyTeam) {
      return;
    }
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    this.core.myTeamPlayersService
      .myTeamPlayersControllerGetAllPlayersForAccount(String(user.id), String(this.selectedEquipo.id))
      .subscribe(async (myTeamPlayers) => {
        this._myTeamPlayers$.next(myTeamPlayers);
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async getMyTeamPlayersByFolderId(folderId: number, accountEquipoId?: number) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    this.core.myTeamPlayersService
      .myTeamPlayersControllerGetAllPlayersForFolder(String(user.id), String(accountEquipoId ?? this.selectedEquipo.id), folderId.toString())
      .subscribe(async (myTeamPlayers) => {
        this._myTeamPlayersForSpecificFolder$.next(myTeamPlayers);
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async getMyTeamFilesByFolderId(folderId: number) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFilesService
      .myTeamFileControllerGetAllFilesForFolder(String(user.id), String(this.selectedEquipo.id), folderId.toString())
      .subscribe(async (myTeamFiles) => {
        this._myTeamFilesForFolder$.next(myTeamFiles);
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async deleteFile(folderId: number, fileId: number) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFilesService
      .myTeamFileControllerDeleteFile(String(user.id), String(this.selectedEquipo.id), folderId.toString(), fileId.toString())
      .subscribe(async () => {
        await this.getMyTeamFilesByFolderId(folderId);
        await this.core.loadingService.dismiss();
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully deleted the file!`,
            duration: 5000,
          })
          .then((value) => value.present());
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async deleteFiles(folderId: number, fileIds: string) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFilesService
      .myTeamFileControllerDeleteFiles(String(user.id), String(this.selectedEquipo.id), folderId.toString(), fileIds)
      .subscribe(async () => {
        await this.getMyTeamFilesByFolderId(folderId);
        await this.core.loadingService.dismiss();
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully deleted selected videos!`,
            duration: 5000,
          })
          .then((value) => value.present());
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async updateFile(folderId: number, file: MyTeamFileDto) {
    file.sourceAccountId = this.core.accountEquipoService.selectedAccountEquipo$.getValue().id;
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFilesService
      .myTeamFileControllerUpdateFile(String(user.id), String(this.selectedEquipo.id), file.id.toString(), folderId.toString(), file)
      .subscribe(async () => {
        await this.getMyTeamFilesByFolderId(folderId);
        await this.core.loadingService.dismiss();
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully updated the file!`,
            duration: 5000,
          })
          .then((value) => value.present());
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  async uploadFileToS3WithProgress(blob: Blob, signedUrl: string, folderId: number, file: MyTeamFileDto, accountEquipoId?: number): Promise<void> {
    const headers = new HttpHeaders({ 'ngsw-bypass': ''});
    return new Promise<void>((resolve, reject) => {
      this.httpClient.put(signedUrl, blob, {
        reportProgress: true,
        observe: 'events',
        headers
      }).subscribe({
        next: async (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            setTimeout(async () => {
              await this.core.loadingService.dismiss();
            }, 1000);
            this._uploadProgress$.next(event.loaded / (event.total ?? 1));
          } else if (event.type === HttpEventType.Response) {
            await this.core.loadingService.dismiss();
            this._uploadProgress$.next(0);
            await this.confirmFileUpload(folderId, file, accountEquipoId);
            resolve();
          }
        },
        error: (err) => {
          this._uploadProgress$.next(0);
          reject(err);
        }
      });
    });
  }

  downloadFileFromS3(signedUrl: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      fetch(signedUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to download file');
          }
          return response.blob();
        })
        .then(blob => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64Data = reader.result as string;
            const base64Content = base64Data.split(',')[1];
            resolve(base64Content);
          };
          reader.onerror = error => {
            reject(error);
          };
          reader.readAsDataURL(blob);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  downloadFileFromS3AsBlob(signedUrl: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      fetch(signedUrl)
        .then(response => {
          if (!response.ok) {
            this.toastCntl.create({
              icon: 'close-outline',
              color: 'danger',
              message: `Failed to download file!`,
              duration: 5000
            }).then(t => t.present());
            return;
          }
          return response.blob();
        })
        .then(blob => {
          if (blob) {
            const reader = new FileReader();
            reader.onload = () => {
              resolve(blob);
            };
            reader.onerror = error => {
              reject(error);
            };
            reader.readAsDataURL(blob);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public async addNewFile(folderId: number, file: MyTeamFileDto, blob?: Blob, accountEquipoId?: number) {
    file.sourceAccountId = this.core.accountEquipoService.selectedAccountEquipo$.getValue().id;
    await this.core.loadingService.present(blob ? 'Uploading file, in the meantime, you can continue using the platform as usual.' : null);
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFilesService
      .myTeamFileControllerAddFile(
        String(user.id),
        String(accountEquipoId ?? this.selectedEquipo.id),
        folderId.toString(),
        file
      )
      .subscribe(async (file) => {
        if (blob) {
          await this.uploadFileToS3WithProgress(blob, file.s3Url, folderId, file, accountEquipoId);
        } else {
          this.core.toastCtrl
              .create({
                icon: 'checkmark-circle-outline',
                color: 'success',
                message: `Successfully saved new file!`,
                duration: 5000,
              })
              .then((value) => value.present());
        }
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async confirmFileUpload(folderId: number, file: MyTeamFileDto, accountEquipoId?: number) {
    file.sourceAccountId = this.core.accountEquipoService.selectedAccountEquipo$.getValue().id;
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFilesService
        .myTeamFileControllerConfirmFileUpload(
            String(user.id),
            String(accountEquipoId ?? this.selectedEquipo.id),
            folderId.toString(),
            file
        )
        .subscribe(async (file) => {
          await this.getMyTeamFilesByFolderId(folderId);
          this.core.toastCtrl
              .create({
                icon: 'checkmark-circle-outline',
                color: 'success',
                message: `Successfully saved new file!`,
                duration: 5000,
              })
              .then((value) => value.present());
          await this.core.loadingService.dismiss();
        }, async error => {
          this.logger.error(error);
          const errorToast = await this.toastCntl
              .create({
                icon: 'close-outline',
                color: 'danger',
                message: error?.error?.message,
                duration: 5000,
              });
          await errorToast.present();
          await this.core.loadingService.dismiss();
        });
  }

  public async addMultipleFiles(folderId: number, files: MyTeamFilesDto, accountEquipoId?: number) {
    if (!accountEquipoId && (!this.selectedEquipo?.grants?.includes('manage_my_team') || !this.selectedEquipo?.account?.permissions?.accessMyTeam)) {
      return;
    }
    files.files.forEach((file) => {
      file.sourceAccountId = this.core.accountEquipoService.selectedAccountEquipo$.getValue().id;
    });
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFilesService
      .myTeamFileControllerAddBulkFiles(
        String(user.id),
        String(accountEquipoId ?? this.selectedEquipo.id),
        folderId.toString(),
        files
      )
      .subscribe(async (files) => {
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully saved files!`,
            duration: 5000,
          })
          .then((value) => value.present());
        this.filesUpdated$.next(true);
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async saveOrdersForMultipleFiles(folderId: number, files: MyTeamFilesDto, accountEquipoId?: number) {
    if (!accountEquipoId && (!this.selectedEquipo?.grants?.includes('manage_my_team') || !this.selectedEquipo?.account?.permissions?.accessMyTeam)) {
      return;
    }
    if (!files.files.length) {
      return;
    }
    files.files.forEach((file) => {
      file.sourceAccountId = this.core.accountEquipoService.selectedAccountEquipo$.getValue().id;
    });
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFilesService
        .myTeamFileControllerSaveOrderForBulkFiles(
            String(user.id),
            String(accountEquipoId ?? this.selectedEquipo.id),
            folderId.toString(),
            files
        )
        .subscribe(async (files) => {
          this.core.toastCtrl
              .create({
                icon: 'checkmark-circle-outline',
                color: 'success',
                message: `Successfully saved files!`,
                duration: 5000,
              })
              .then((value) => value.present());
          this.filesUpdated$.next(true);
          await this.core.loadingService.dismiss();
        }, async error => {
          this.logger.error(error);
          const errorToast = await this.toastCntl
              .create({
                icon: 'close-outline',
                color: 'danger',
                message: error?.error?.message,
                duration: 5000,
              });
          await errorToast.present();
          await this.core.loadingService.dismiss();
        });
  }

  public async addNewFolder(folder: MyTeamFolderDto, accountEquipoId?: number) {
    folder.accountEquipoId = accountEquipoId ?? this.selectedEquipo.id;
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFoldersService
      .myTeamFolderControllerAddFolder(
        String(user.id),
        String(accountEquipoId ?? this.selectedEquipo.id),
        folder
      )
      .subscribe(async (res) => {
        const players = await this.getPlayersPromise(accountEquipoId);
        const modal = await this.core.modalCtrl.create({
          component: ManageFolderAccessModalComponent,
          backdropDismiss: true,
          id: 'folder-access-modal',
          componentProps: {
            folder: res,
            players,
            accountEquipoId
          },
        });
        modal.onDidDismiss().then(({ data, role }) => {
        });
        modal.present();
        this._accountEquipoIdInFoldersModal = null;
        this._foldersStateInFoldersModal = [];
        this.getMyTeamFoldersData();
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully saved new folder!`,
            duration: 5000,
          })
          .then((value) => value.present());
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async updateFolder(folder: MyTeamFolderDto) {
    folder.accountEquipoId = this.selectedEquipo.id;
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFoldersService
      .myTeamFolderControllerUpdateFolder(
        String(user.id),
        String(this.selectedEquipo.id),
        folder.id.toString(),
        folder
      )
      .subscribe(async (res) => {
        this.getMyTeamFoldersData();
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully updated folder!`,
            duration: 5000,
          })
          .then((value) => value.present());
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async deleteFolder(folderId: number) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFoldersService
      .myTeamFolderControllerDeleteFolder(
        String(user.id),
        String(this.selectedEquipo.id),
        folderId.toString()
      )
      .subscribe(async () => {
        this.getMyTeamFoldersData();
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully deleted folder!`,
            duration: 5000,
          })
          .then((value) => value.present());
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async addNewPlayer(player: MyTeamPlayerDto) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamPlayersService
      .myTeamPlayersControllerAddPlayer(
        String(user.id),
        String(this.selectedEquipo.id),
        player
      )
      .subscribe(async (res) => {
        this.getMyTeamPlayersData();
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully saved new player!`,
            duration: 5000,
          })
          .then((value) => value.present());
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async deletePlayer(playerId: number) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamPlayersService
      .myTeamPlayersControllerDeletePlayer(
        String(user.id),
        String(this.selectedEquipo.id),
        playerId.toString()
      )
      .subscribe(async (res) => {
        this.getMyTeamPlayersData();
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully deleted player!`,
            duration: 5000,
          })
          .then((value) => value.present());
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async deleteAllPlayers() {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamPlayersService
      .myTeamPlayersControllerDeleteAllPlayers(
        String(user.id),
        String(this.selectedEquipo.id)
      )
      .subscribe(async (res) => {
        this.getMyTeamPlayersData();
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully deleted all players!`,
            duration: 5000,
          })
          .then((value) => value.present());
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async updatePlayer(playerId: number, player: MyTeamPlayerDto) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamPlayersService
      .myTeamPlayersControllerUpdatePlayer(
        String(user.id),
        String(this.selectedEquipo.id),
        playerId.toString(),
        player
      )
      .subscribe(async (res) => {
        this.getMyTeamPlayersData();
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully updated player!`,
            duration: 5000,
          })
          .then((value) => value.present());
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async copyFolderWithoutParentFolder(folderId: number, accountEquipoId?: number) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFoldersService
        .myTeamFolderControllerCopyFolderAsMainFolder(
            String(user.id),
            String(accountEquipoId ?? this.selectedEquipo.id),
            folderId.toString()
        )
        .subscribe(async (res) => {
          await this.getMyTeamFoldersData();
          this.core.toastCtrl
              .create({
                icon: 'checkmark-circle-outline',
                color: 'success',
                message: `Successfully copied folder!`,
                duration: 5000,
              })
              .then((value) => value.present());
          await this.core.loadingService.dismiss();
        }, async error => {
          this.logger.error(error);
          const errorToast = await this.toastCntl
              .create({
                icon: 'close-outline',
                color: 'danger',
                message: error?.error?.message,
                duration: 5000,
              });
          await errorToast.present();
          await this.core.loadingService.dismiss();
        });
  }

  public async copyFolderToAnotherFolder(folderId: number, parentFolderId: number, accountEquipoId?: number) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFoldersService
      .myTeamFolderControllerCopyFolderToAnotherParentFolder(
        String(user.id),
        String(accountEquipoId ?? this.selectedEquipo.id),
        folderId.toString(),
        parentFolderId.toString()
      )
      .subscribe(async (res) => {
        await this.getMyTeamFoldersData();
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully copied folder!`,
            duration: 5000,
          })
          .then((value) => value.present());
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async addFolderPermissionToThePlayer(playerIds: string, folderId: number, accountEquipoId?: number) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFoldersService
      .myTeamFolderControllerAddFolderPermissionToThePlayer(
        String(user.id),
        String(accountEquipoId ?? this.selectedEquipo.id),
        folderId.toString(),
        playerIds.toString()
      )
      .subscribe(async (res) => {
        await this.getMyTeamPlayersByFolderId(folderId, accountEquipoId);
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully added player access!`,
            duration: 5000,
          })
          .then((value) => value.present());
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async removeFolderPermissionFromThePlayer(playerIds: string, folderId: number, accountEquipoId?: number) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    await this.core.myTeamFoldersService
      .myTeamFolderControllerRemoveFolderPermissionFromThePlayer(
        String(user.id),
        String(accountEquipoId ?? this.selectedEquipo.id),
        folderId.toString(),
        playerIds.toString()
      )
      .subscribe(async (res) => {
        await this.getMyTeamPlayersByFolderId(folderId, accountEquipoId);
        this.core.toastCtrl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully removed player access!`,
            duration: 5000,
          })
          .then((value) => value.present());
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async requestMyTeamPermission(accountEquipoId: string) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    this.core.myTeamPlayersService
      .myTeamPlayersControllerSendRequestForMyTeamPermission(String(user.id), accountEquipoId.toString())
      .subscribe(async () => {
        await this.core.loadingService.dismiss();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

  public async resendInvitationLinkToThePlayer(playerId: number) {
    await this.core.loadingService.present();
    const user = await this.core.storageService.getUserAsPromise();
    this.core.myTeamPlayersService
      .myTeamPlayersControllerResendInvitationLinkToThePlayer(String(user.id), String(this.selectedEquipo.id), playerId.toString())
      .subscribe(async () => {
        await this.core.loadingService.dismiss();
        const successToast = await this.toastCntl
          .create({
            icon: 'checkmark-circle-outline',
            color: 'success',
            message: `Successfully resent an invitation link to the player!`,
            duration: 3500,
          });
        await successToast.present();
      }, async error => {
        this.logger.error(error);
        const errorToast = await this.toastCntl
          .create({
            icon: 'close-outline',
            color: 'danger',
            message: error?.error?.message,
            duration: 5000,
          });
        await errorToast.present();
        await this.core.loadingService.dismiss();
      });
  }

}
