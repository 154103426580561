import { GameModel, GamePlayerModel, GameTeamModel } from '../model/game.model';
import { PlayerModel, TeamModel } from '../model/team.model';
import { DateTime } from 'luxon';
import { GameType } from 'src/app/shared-services/game/game-type.model';

export const TStaffStatusOptions = ['active', 'inactive'] as const;
type TStaffStatus = (typeof TStaffStatusOptions)[number];

export const convertPlayerToGamePlayerModel = (
    player: PlayerModel,
    gameMode: GameType
): GamePlayerModel =>
    ({
        id: player.id,
        name: player.name,
        position: player.position,
        backNumber: player.backNumber,
        isBench: gameMode === 'LITE_MODE' || gameMode == 'TAGGING_MODE' ? false : player.status === 'bench',
        disabled: gameMode === 'LITE_MODE' || gameMode == 'TAGGING_MODE' ? false : player.status === 'bench',
        playTime: 0,
        goalsSaves: 0,
        totalShots: 0,
    } as GamePlayerModel);

export const convertTeamToGameTeamModel = (
    team: TeamModel,
    gameMode: GameType
): GameTeamModel => {
    const convertedPlayers = team.players.filter(p => p.status != 'out')
        .filter(p => !TStaffStatusOptions.includes(p.status as TStaffStatus))
        .filter(p => !p.deleted).map(pl => convertPlayerToGamePlayerModel(pl, gameMode));
    const convertedCoaches = team.players.filter(p => TStaffStatusOptions.includes(p.status as TStaffStatus))
        .filter(p => !p.deleted).filter(p => p.status === 'active')
        .map(pl => convertPlayerToGamePlayerModel(pl, gameMode));
    return {
        id: team._id,
        name: team.teamname,
        players: convertedPlayers,
        offenseSystem: 'EQUALITY',
        currentGameSuspensions: [],
        currentPenaltyTime: [],
        gameCoaches: convertedCoaches,
        currentField: convertedPlayers.filter(pl => !pl.isBench),
        currentBench: convertedPlayers.filter(pl => pl.isBench),
        defenseSystem: '6:0'
    } as GameTeamModel;
};

export const createGameModel = (
    home: TeamModel,
    visitor: TeamModel,
    gameMode: GameType,
    scheduleDate?: DateTime,
): GameModel =>
    ({
        home: convertTeamToGameTeamModel(home, gameMode),
        visitor: convertTeamToGameTeamModel(visitor, gameMode),
        scoreHome: 0,
        scoreVisitor: 0,
        startDate: scheduleDate ?? DateTime.now()
    } as GameModel);
