import { UiBaseCommand, UiCommandExecutionContext } from "./ui-base-command";
import {
  ShowVideoPopupEvent,
} from "../events/ui-events";
import { VideoPopupComponent } from "src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/video-popup/video-popup.component";

export class ShowVideoPopupCommand
  implements UiBaseCommand<ShowVideoPopupEvent>
{
  async execute(
    actualEvent: ShowVideoPopupEvent,
    eventContext: UiCommandExecutionContext
  ): Promise<void> {
    const popover = await eventContext.popoverController.create({
      component: VideoPopupComponent,
      cssClass: 'global__popover global__popover--game-system',
      // translucent: true,
      backdropDismiss: false,
      componentProps: {
        src: actualEvent.payload.src,
        videoCurrentTime: actualEvent.payload.videoCurrentTime,
      },
    });
    await popover.present();
  }
}

