import { Injectable } from '@angular/core';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { BehaviorSubject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import {
  ExternalGoalkeeperEfficiencyModel,
  GoalkeeperEfficiencyModel
} from '@handballai/stats-calculation';
import { getOpponentTeamMarker } from 'src/app/shared-services/helper/statistics-helper';
import { CoreService } from 'src/app/shared-services/core.service';

@Injectable({
  providedIn: 'root'
})
export class GoalkeeperEfficiencyConsumerService {

  constructor(
      private readonly logger: NGXLogger,
  ) { }

  get homeGoalkeeperEfficiencyCounters$(): BehaviorSubject<ExternalGoalkeeperEfficiencyModel[]> {
    return this._homeGoalkeeperEfficiencyCounters$;
  }

  get visitorGoalkeeperEfficiencyCounters$(): BehaviorSubject<ExternalGoalkeeperEfficiencyModel[]> {
    return this._visitorGoalkeeperEfficiencyCounters$;
  }
  private _core: CoreService;

  private _teamEfficiencyMap: Map<TeamMarker, Map<number, GoalkeeperEfficiencyModel>>;
  private _homeGoalkeeperEfficiencyCounters$ = new BehaviorSubject<ExternalGoalkeeperEfficiencyModel[]>([]);
  private _visitorGoalkeeperEfficiencyCounters$ = new BehaviorSubject<ExternalGoalkeeperEfficiencyModel[]>([]);
  initCore(core: CoreService) {
    this._core = core;
    this.subscribeToGoalEvent();
    this.subscribeToSaveEvent();
  }

  public init(): void {
    this._teamEfficiencyMap = new Map<TeamMarker, Map<number, GoalkeeperEfficiencyModel>>([
      ['HOME', new Map<number, GoalkeeperEfficiencyModel>()],
      ['VISITOR', new Map<number, GoalkeeperEfficiencyModel>()]
    ]);
    this.resetTeamSubjects();
  }


  private updateTeamObserver(): void {
    this._homeGoalkeeperEfficiencyCounters$.next([
      ...Array.from(this._teamEfficiencyMap.get('HOME').values()).map(value => value.externalEfficiencyModel)
    ]);
    this._visitorGoalkeeperEfficiencyCounters$.next([
      ...Array.from(this._teamEfficiencyMap.get('VISITOR').values()).map(value => value.externalEfficiencyModel)
    ]);
  }

  private subscribeToGoalEvent(): void {
    this._core.playByPlayProducerService.on(['GOAL'], pbp => {
      this.logger.debug('GoalkeeperEfficiencyConsumerService.goalSubscription: ', pbp);
      if (pbp.defensePlayer) {
        if (!this._teamEfficiencyMap.get(getOpponentTeamMarker(pbp.teamMarker)).has(pbp.defensePlayer.id)) {
          this._teamEfficiencyMap.get(getOpponentTeamMarker(pbp.teamMarker)).set(pbp.defensePlayer.id, new GoalkeeperEfficiencyModel(
              pbp.defensePlayer.id,
              `${pbp.defensePlayer.backNumber} - ${pbp.defensePlayer.name}`
          ));
        }
        const teamMap = this._teamEfficiencyMap.get(getOpponentTeamMarker(pbp.teamMarker));
        teamMap.get(pbp.defensePlayer.id).incrementGoalCounter(pbp.eventTime.secondsSinceStartOfGame / 60, pbp.eventTime.halftime);
        this.updateTeamObserver();
      }
    });
  }

  private subscribeToSaveEvent(): void {
    this._core.playByPlayProducerService.on(['SAVE'], pbp => {
      this.logger.debug('GoalkeeperEfficiencyConsumerService.saveSubscription: ', pbp);

      if (pbp.defensePlayer) {
        if (!this._teamEfficiencyMap.get(getOpponentTeamMarker(pbp.teamMarker)).has(pbp.defensePlayer.id)) {
          this._teamEfficiencyMap.get(getOpponentTeamMarker(pbp.teamMarker)).set(pbp.defensePlayer.id, new GoalkeeperEfficiencyModel(
              pbp.defensePlayer.id,
              `${pbp.defensePlayer.backNumber} - ${pbp.defensePlayer.name}`
          ));
        }
        const teamMap = this._teamEfficiencyMap.get(getOpponentTeamMarker(pbp.teamMarker));
        teamMap.get(pbp.defensePlayer.id).incrementSaveCounter(pbp.eventTime.secondsSinceStartOfGame / 60, pbp.eventTime.halftime);
        this.updateTeamObserver();
      }
    });
  }


  private resetTeamSubjects(): void {
    this._homeGoalkeeperEfficiencyCounters$.next([]);
    this._visitorGoalkeeperEfficiencyCounters$.next([]);
  }
}
