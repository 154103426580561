import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { HalftimeTypes, PlaytimeEventModel } from '@handballai/stats-calculation';
import { BehaviorSubject } from 'rxjs';
import { DateTime } from 'luxon';
import { PlayByPlayDto, PlayTimeDto } from 'src/app/api/hai-api';
import { PlayerEventModel } from '@handballai/stats-calculation';
import { PlayTimeAggregationModel, PlayTimeInfo } from '@handballai/stats-calculation';
import { CoreService } from '../../core.service';


export type PlayTimeEventListenerCb = () => void;


@Injectable({
  providedIn: 'root'
})
export class PlayTimeService {

  constructor(
    private readonly logger: NGXLogger,
  ) { }

  get playtimeEvents(): PlaytimeEventModel[] {
    return this._playtimeEvents;
  }

  get playerPlayTimeInfo$(): BehaviorSubject<PlayTimeInfo[]> {
    return this._playerPlayTimeInfo$;
  }
  private _core: CoreService;

  private _playerMap: Map<number, PlayTimeAggregationModel>;
  private _playtimeEvents: PlaytimeEventModel[] = [];
  private _playerPlayTimeInfo$ = new BehaviorSubject<PlayTimeInfo[]>([]);
  private seqCounter = 0;
  private factor = 1;
  private realtimeMode = true;
  private _playTimeListeners: PlayTimeEventListenerCb[] = [];
  initCore(core: CoreService) {
      this._core = core;
  }

  public initFromInternalModel(): void {
    this.realtimeMode = true;
    this.initializePlayTimeModelFromGame();
    this.seqCounter = 1;
    this.factor = 1;
  }

  public addStartPlayTimeEvents(): void {
    const now = DateTime.now();
    this._playtimeEvents = [
      ...this._core.gameService.gameModel.home.currentField,
      ...this._core.gameService.gameModel.visitor.currentField
    ].reduce((eventList, player) => {
      const playTimeEvent = {
        eventTime: {
          halftime: 'T1',
          minutesSinceHalftime: 0,
          secondsSinceHalftime: 0,
          secondsSinceStartOfGame: 0,
          timestamp: now,
        },
        seq: this.seqCounter,
        eventType: 'PLAY_TIME_START',
        playerId: player.id,
      } as PlaytimeEventModel;
      this._playerMap.set(player.id, this._playerMap.get(player.id).addPlayTimeEvent(playTimeEvent));
      eventList.push(playTimeEvent);
      this.seqCounter++;
      return eventList;
    }, [] as PlaytimeEventModel[]);
  }

  public registerForPlayTimeEvents(cb: PlayTimeEventListenerCb): void {
    this._playTimeListeners.push(cb);
  }

  public convertPtmDtoToPtmModel(pt: PlayTimeDto): PlaytimeEventModel {
    return {
      playerId: pt.playerId,
      seq: pt.orderId,
      eventType: pt.event as PlayerEventModel,
      eventTime: {
        halftime: pt.halftime as HalftimeTypes,
        secondsSinceStartOfGame: pt.secondsSinceStartOfGame,
        minutesSinceHalftime: pt.minutesSinceHalftime,
        secondsSinceHalftime: pt.secondsSinceHalftime,
        timestamp: DateTime.fromISO(pt.timestamp)
      },
      videoTimestamp: pt.videoTimestamp,
    };
  }

  public initFromExternalModel(playTimes: PlayTimeDto[]): void {
      this.logger.debug('PlayTimeService.initFromExternalModel', playTimes);
      this.realtimeMode = false;

      this.initializePlayTimeModelFromGame();

      this._playtimeEvents = playTimes.sort((a, b) => a.orderId - b.orderId).map(pt => this.convertPtmDtoToPtmModel(pt));
      if (this._playtimeEvents.length > 0) {
        this._core.timerWrapperService
            .setCurrentSeconds(this._playtimeEvents[this._playtimeEvents.length - 1].eventTime.secondsSinceStartOfGame);
      }
      for (const curr of this._playtimeEvents) {
        if (!this._playerMap.get(curr.playerId)) {
          this.logger.error('PlayTimeService.initFromExternalModel, cannot found player with id '+curr.playerId)
        }
        this._playerMap.set(curr.playerId, this._playerMap.get(curr.playerId).addPlayTimeEvent(curr));
      }
  }

  public selectEventsForPlayer(playerId: number): void {
    this._playerPlayTimeInfo$.next([
        this.getPlayerPlayTimeInfo(playerId)
    ]);
  }

  public deSelectPlayer(): void {
    this._playerPlayTimeInfo$.next([]);
  }

  public getPlayerPlayTimeInfo(playerId: number): PlayTimeInfo {
    const playerInfo = this._playerMap.get(playerId).playTimeInfo;
    if (playerInfo.isInGame) {
      const delta = this._core.timerWrapperService.currentGameSeconds() - playerInfo.lastTimeSecondsSinceStart;
      if (delta > 0) {
        playerInfo.playTimeSec = playerInfo.playTimeSec + delta;
      }
    }
    return playerInfo;
  }

  public async addPlayTimeEvent(
      playTimeEvents: PlaytimeEventModel[],
      saveAtServer = true
  ): Promise<PlaytimeEventModel[]> {
    const playerEvents: PlaytimeEventModel[] = [];
    playTimeEvents.forEach(ev => {
      const playerEvent = {
        ...ev,
        seq: this.seqCounter
      };
      this.seqCounter++;
      this.logger.debug('PlayTimeService.addPlayTimeEvent - playerEvent: ', playerEvent);
      this._playtimeEvents.push(playerEvent);
      this._playerMap.set(playerEvent.playerId, this._playerMap.get(playerEvent.playerId).addPlayTimeEvent(playerEvent));
      this.firePlaytimeEvent();
      playerEvents.push(playerEvent);
    });
    if (saveAtServer) {
      await this._core.gameDataService.addPlayTimeRecord(this._core.gameService.gameId, playerEvents);
    }
    return playerEvents;
  }

  private firePlaytimeEvent(): void {
    this._playTimeListeners.forEach(listener => listener());
  }

  private initializePlayTimeModelFromGame(): void {
    this._playerMap = [
      // ...this._core.gameService.gameModel.home.currentField,
      // ...this._core.gameService.gameModel.visitor.currentField,
      // ...this._core.gameService.gameModel.home.currentBench,
      // ...this._core.gameService.gameModel.visitor.currentBench,
      // // Added on video tracker (initFromExternalModel) periodic save game when a player is suspended or at penalty
      // ...this._core.gameService.gameModel.home.currentGameSuspensions,
      // ...this._core.gameService.gameModel.visitor.currentGameSuspensions,
      // ...this._core.gameService.gameModel.home.currentPenaltyTime,
      // ...this._core.gameService.gameModel.visitor.currentPenaltyTime,
      // Changed after tracker (initFromExternalModel) periodic save game when a player is suspended or at penalty (errors when red card)
      ...this._core.gameService.gameModel.home.players,
      ...this._core.gameService.gameModel.visitor.players,
    ].reduce((plMap, curr) => {
      plMap.set(curr.id, new PlayTimeAggregationModel(
          curr.id,
          curr.name,
          curr.backNumber,
          curr.position,
          0
      ));
      return plMap;
    }, new Map<number, PlayTimeAggregationModel>());
  }
}
