import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimerModel } from 'src/app/shared-services/timer/timer-model';
import { PopoverController, ToastController } from '@ionic/angular';
import { TimerPopoverComponent } from '../timer-popover/timer-popover.component';
import { Router } from '@angular/router';
import { ScoreBoardLocation } from './display-indicator';
import { UiEventDispatcherService } from 'src/app/shared-services/ui-event-dispatcher/ui-event-dispatcher.service';
import { BehaviorSubject } from 'rxjs';
import { CoreService } from 'src/app/shared-services/core.service';
import { PlayerEvent, PlaytimeEventModel, TeamMarker } from '@handballai/stats-calculation';
import { GameEndStorageModel } from 'src/app/shared-services/storage-service/model/game-end-storage.model';
import { db } from 'src/app/db';
import { PlayByPlayDto, PlayTimeDto } from 'src/app/api/hai-api';
import { ExtraTimePenaltyPopoverComponent } from '../extra-time-penalty-popover/extra-time-penalty-popover.component';

@Component({
    selector: 'app-score-board',
    templateUrl: './score-board.component.html',
    styleUrls: ['./score-board.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ScoreBoardComponent implements OnInit {

    private _timerModel: TimerModel;
    private _homeTeamName: string;
    private _visitorName: string;
    private _homeScore: number;
    private _visitorScore: number;
    private _scoreBoardLocation: ScoreBoardLocation;
    private _isUndoEnabled = false;
    private _undoClicked$ = new EventEmitter<void>();
    private _suspensionToBench$ = new EventEmitter<TeamMarker>();
    private _needToSwapFieldHalftime: boolean;

    public homeTeamColor: string;
    public visitorTeamColor: string;

    constructor(
        private readonly popoverCntl: PopoverController,
        private readonly router: Router,
        public readonly _core: CoreService,
        private readonly toastCtrl: ToastController,
        private readonly uiEventDispatcherService: UiEventDispatcherService,
    ) {
        this.loadTeamColors();
    }

    get swappedTeams(): boolean {
        return this._core.gameService.swappedTeams;
    }

    extractTeamFirstLetters = extractTeamFirstLetters;

    ngOnInit() {
    }

    async loadTeamColors() {
        let homeTeam = await this._core.storageService.storage.get('HAI_HOME_TEAM');
        this.homeTeamColor = homeTeam.primaryColor;
        let visitorTeam = await this._core.storageService.storage.get('HAI_VISITOR_TEAM');
        this.visitorTeamColor = homeTeam.primaryColor === visitorTeam.primaryColor ? visitorTeam.secondaryColor : visitorTeam.primaryColor;
    }

    get gameRunning$(): BehaviorSubject<boolean> {
        return this._core.handballTimerService.gameRunning$;
    }

    get gameEnded$(): BehaviorSubject<boolean> {
        return this._core.handballTimerService.gameEnded$;
    }

    get timerModel(): TimerModel {
        return this._timerModel;
    }

    @Input()
    set timerModel(value: TimerModel) {
        if (value) {
            this._timerModel = value;
        }
    }

    get needToSwapFieldHalftime(): boolean {
        return this._needToSwapFieldHalftime;
    }

    @Input()
    set needToSwapFieldHalftime(value: boolean) {
        this._needToSwapFieldHalftime = value;
    }

    get homeTeamName(): string {
        return this._homeTeamName;
    }

    @Input()
    set homeTeamName(value: string) {
        this._homeTeamName = value;
    }

    get visitorName(): string {
        return this._visitorName;
    }

    @Input()
    set visitorName(value: string) {
        this._visitorName = value;
    }

    get homeScore(): number {
        return this._homeScore;
    }

    @Input()
    set homeScore(value: number) {
        this._homeScore = value;
    }

    get visitorScore(): number {
        return this._visitorScore;
    }

    @Input()
    set visitorScore(value: number) {
        this._visitorScore = value;
    }

    get scoreBoardLocation(): ScoreBoardLocation {
        return this._scoreBoardLocation;
    }

    @Input()
    set scoreBoardLocation(value: ScoreBoardLocation) {
        this._scoreBoardLocation = value;
    }

    get isUndoEnabled(): boolean {
        return this._isUndoEnabled;
    }

    @Input()
    set isUndoEnabled(value: boolean) {
        this._isUndoEnabled = value;
    }

    @Output()
    get undoClicked$(): EventEmitter<void> {
        return this._undoClicked$;
    }

    @Output()
    get suspensionToBench$(): EventEmitter<TeamMarker> {
        return this._suspensionToBench$;
    }

    public async onRunningToggle(): Promise<void> {
        if (this._core.handballTimerService.isGameRunning) {
            this._core.handballTimerService.interruptGame();
        } else {
            await this._core.handballTimerService.resumeGame();
        }
    }

    public async onEndFirstHalf(extraTime?: boolean): Promise<void> {
        const closePlayTimeSlots = await this.closePlayTimeSlots();
        await this._core.handballTimerService.endFirstHalf(extraTime);
        const openPlayTimeSlots = await this.openPlayTimeSlots();

        await this._core.eventBufferService.openTransaction(); // Important push all events in undo queue through
        await this._core.gameDataService.addPlayTimeRecord(this._core.gameService.gameId, [...closePlayTimeSlots, ...openPlayTimeSlots]);

        // Get full play by play and play time events from indexedDB
        const fullPlayByPlay: PlayByPlayDto[] = await db.playByPlay.where('gameSrvId').equals(this._core.gameService.gameId).toArray();
        const fullPlayerEvents: PlayTimeDto[] = await db.playTime.where('gameSrvId').equals(this._core.gameService.gameId).toArray();

        const endGameStorageModel = {
            gameId: this._core.gameService.gameId,
            updateGameResultDto: {
                firstHalfEnded: true,
                secondHalfEnded: false,
                gameEnded: false,
                goalsHome: this._core.gameService.gameModel.scoreHome,
                goalsVisitor: this._core.gameService.gameModel.scoreVisitor,
                playerStatsDto: this._core.goalConsumerService.transformToPlayerStatisticsDto(
                    this._core.teamOverviewSubConsumerService.getPlayerStatisticsDto()
                ),
                teamStatsDto: this._core.goalConsumerService.transformToTeamStatisticsDto(
                    this._core.overviewConsumerService.generateTeamStatsDto()
                ),
                lineupStatsDto: this._core.goalConsumerService.transformToLineupStatisticsDto(),
                playByPlayDto: fullPlayByPlay,
                playTimeDto: fullPlayerEvents,
                gameDateTime: this._core.gameService.gameModel.startDate.toISO(),
            }
        } as GameEndStorageModel;
        try {
            // Update game on server. Maybe we should skip this since we already have a working websocket connection
            await this._core.gameDataService.updateGame(
                endGameStorageModel.gameId,
                endGameStorageModel.updateGameResultDto
            );

            this.toastCtrl.create({
                icon: "checkmark-circle-outline",
                color: 'success',
                message: `Successfully saved First Half Game Stats on server!`,
                duration: 5000
            }).then(value => value.present());

        } catch (err) {
            console.log('Connection error saving at half time', err);
            // this.logger.error('TimerPopoverComponent.onEndGameConfirm Error updating the Game writing to Local Storage', err);
            // await this.presentAlert();
        }

    }

    public async onEndGame(): Promise<void> {
        this._core.handballTimerService.interruptGame();
        const closePlayTimeSlots = await this.closePlayTimeSlots();
        if (this._core.accountEquipoService.extraTimeEnabled && this.homeScore === this.visitorScore
            && !this._core.handballTimerService.penaltiesStarted) {
            const popover = await this.popoverCntl.create({
                component: ExtraTimePenaltyPopoverComponent,
                cssClass: 'global__timer-popover',
                componentProps: {
                    halfTime: this.timerModel.halfTime,
                },
                backdropDismiss: false,
            });
            popover.present();

            popover.onDidDismiss().then(async (res) => {
                if (res.data.endGame) {
                    await this.openEndGamePopover(closePlayTimeSlots);
                } else {
                    if (!this._core.handballTimerService.isFirstHalftimeETStarted) {
                        await this._core.handballTimerService.endSecondHalf();
                    } else if (this._core.handballTimerService.isSecondHalfTimeETStarted) {
                        await this._core.handballTimerService.endSecondHalf(true);
                    }

                    const openPlayTimeSlots = await this.openPlayTimeSlots();
                    await this._core.eventBufferService.openTransaction(); // Important push all events in undo queue through
                    await this._core.gameDataService.addPlayTimeRecord(this._core.gameService.gameId, [...closePlayTimeSlots, ...openPlayTimeSlots]);
                }
            });
        } else {
            await this.openEndGamePopover(closePlayTimeSlots);
        }
    }

    // private addGameControlEvent(event: PlayerEvent, teamMarker: TeamMarker): void {

    // }

    public onUndoSelected(): void {
        this.undoClicked$.emit();
    }

    public goToPlayByPlay(): void {
        this.router.navigate(['/account/'+this._core.accountEquipoService.selectedAccountEquipo$.getValue().id+'/game/manage/play-by-play']);
    }

    public goToGame(): void {
        this.uiEventDispatcherService.resetEventStateToInitial();
        this.router.navigate(['/account/'+this._core.accountEquipoService.selectedAccountEquipo$.getValue().id+'/game/manage']);
    }

    public goToAdvancedStats(): void {
        this.router.navigate(['/account/'+this._core.accountEquipoService.selectedAccountEquipo$.getValue().id+'/game/manage/advanced-stats']);
    }

    private async openEndGamePopover(closePlayTimeSlots: PlaytimeEventModel[]): Promise<void> {
        const popover = await this.popoverCntl.create({
            component: TimerPopoverComponent,
            cssClass: 'global__timer-popover',
            // translucent: true,
            backdropDismiss: false,
            componentProps: {
                closePlayTimeSlots,
            }
        });
        await popover.present();
    }

    private async closePlayTimeSlots(): Promise<PlaytimeEventModel[]> {
        const timer = this._core.handballTimerService.gameCounter$.value;
        const playerInGameClose = [
            ...this._core.gameService.gameModel.home.currentField,
            ...this._core.gameService.gameModel.visitor.currentField
        ].map(pl => ({
                eventTime: {
                    halftime: timer.halfTime,
                    timestamp: timer.wallClock,
                    secondsSinceStartOfGame: timer.counterSecSinceStart,
                    secondsSinceHalftime: timer.seconds,
                    minutesSinceHalftime: timer.minutes
                },
                playerId: pl.id,
                eventType: 'PLAY_TIME_END'
            } as PlaytimeEventModel
        ));
        return await this._core.playTimeService.addPlayTimeEvent(playerInGameClose, false);
    }
    private async openPlayTimeSlots(): Promise<PlaytimeEventModel[]> {
        const timer = this._core.handballTimerService.gameCounter$.value;
        const playerInGameOpen = [
            ...this._core.gameService.gameModel.home.currentField,
            ...this._core.gameService.gameModel.visitor.currentField
        ].map(pl => ({
                eventTime: {
                    halftime: timer.halfTime,
                    timestamp: timer.wallClock,
                    secondsSinceStartOfGame: timer.counterSecSinceStart,
                    secondsSinceHalftime: timer.seconds,
                    minutesSinceHalftime: timer.minutes
                },
                playerId: pl.id,
                eventType: 'PLAY_TIME_START'
            } as PlaytimeEventModel
        ));
        return await this._core.playTimeService.addPlayTimeEvent(playerInGameOpen, false);
    }

    public async onSuspensionToBench(teamMarker: TeamMarker): Promise<void> {
        await this.onRunningToggle();
        this._suspensionToBench$.emit(teamMarker);
    }
}


export function extractTeamFirstLetters(name: string) {
    if (!name) return '';

    const words = name.split(' ');

    if (words.length == 1) {
        return words[0].substring(0, 2);
    }
    return words[0][0] + words[1][0];
}
