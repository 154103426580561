import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from '../../../../../shared-services/core.service';
import { PopoverController } from '@ionic/angular';
import { MyTeamFolderDto } from '../../../../../api/hai-players-api';
import { AdminAccountEquipoDto } from '../../../../../api/hai-api';
import { PlayersService } from '../../players.service';
import { AddFolderModalComponent } from '../add-folder-modal/add-folder-modal.component';

@Component({
  selector: 'app-select-folder-for-upload-modal',
  templateUrl: './select-folder-for-upload-modal.component.html',
  styleUrls: ['./select-folder-for-upload-modal.component.scss'],
})
export class SelectFolderForUploadModalComponent  implements OnInit {

  @Input() folders: MyTeamFolderDto[] = [];
  @Input() accounts: AdminAccountEquipoDto[] = [];
  @Input() selectedFolderId: number;
  @Input() folderToBeMovedId: number;
  selectedFolder: any;
  accountHasAnyFolders = true;
  selectedAccount: AdminAccountEquipoDto;
  showBackArrow = false;
  accountsCopy: AdminAccountEquipoDto[] = [];
  public expandedFolderIds: number[] = [];

  constructor(private readonly core: CoreService,
              private readonly popoverController: PopoverController,
              private readonly playersService: PlayersService
  ) { }

  ngOnInit() {
    this.accountsCopy = [...this.accounts];
    if (this.accounts.length === 1) {
      this.onAccountSelect(this.accounts[0]);
    }
  }

  public async onCancel(): Promise<void> {
    await this.popoverController.dismiss();
  }

  public onBackArrowClick() {
    this.accountsCopy = [...this.accounts];
    this.accountHasAnyFolders = true;
  }

  public async addNewFolder(parentFolderId?: number) {
    const popover = await this.core.popoverCtrl.create({
      component: AddFolderModalComponent,
      cssClass: 'add-player-popover',
    });
    popover.onDidDismiss().then(async res => {
      if (res.data) {
        const newFolder: MyTeamFolderDto = {
          ...res.data.folder,
          parentId: parentFolderId ?? undefined
        };
        await this.playersService.getMyTeamPlayersData();
        await this.playersService.addNewFolderPromise(newFolder, this.selectedAccount?.id);
        this.folders = await this.playersService.getFoldersForAccount(this.selectedAccount?.id);
        const foldersWithExpandedField = [];
        this.folders.forEach(fld => {
          this.expandChildrenFolders(fld);
          foldersWithExpandedField.push(
              {
                ...fld,
                expanded: this.expandedFolderIds.includes(fld.id)
              }
          );
        });

        this.folders = foldersWithExpandedField;
      }
    });
    await popover.present();
  }

  public expandChildrenFolders(folder: any) {
    if (folder.children?.length) {
      folder.children.forEach((child) => {
        if (this.expandedFolderIds.includes(child.id)) {
          child.expanded = true;
        }
        this.expandChildrenFolders(child);
      });
    }
  }

  public onFolderExpanded(folderId: number) {
    const index = this.expandedFolderIds.indexOf(folderId);
    if (index === -1) {
      this.expandedFolderIds.push(folderId);
    }
  }

  public onFolderCollapsed(folderId: number) {
    const index = this.expandedFolderIds.indexOf(folderId);
    if (index > -1) {
      this.expandedFolderIds.splice(index, 1);
    }
  }

  public async onAccountSelect(account: AdminAccountEquipoDto, showBackArrow?: boolean): Promise<void> {
    this.selectedAccount = account;
    this.folders = await this.playersService.getFoldersForAccount(account.id);
    this.accountsCopy = [];
    if (!this.folders.length) {
      this.accountHasAnyFolders = false;
    }
    this.showBackArrow = showBackArrow;
  }

  public async onFolderSelect(folder: MyTeamFolderDto): Promise<void> {
    if (this.selectedFolderId === folder.id) {
      const toast = await this.core.toastCtrl.create({
        icon: 'close-outline',
        color: 'danger',
        message: `You cannot share file to the same folder where you selected it !`,
        duration: 4000,
      });
      await toast.present();
    } else {
      await this.popoverController.dismiss({ folder, accountEquipoId: this.selectedAccount?.id });
    }
  }

  public async onAddSubFolderClick(folder: any, event: any) {
    event.stopPropagation();
    event.preventDefault();
    await this.addNewFolder(folder.id);
  }

  public onExpandClick(folder: any, event: any) {
    event.stopPropagation();
    event.preventDefault();
    folder.expanded = !folder.expanded;
    if (!folder.expanded) {
      folder.children.forEach(fld => fld.expanded = false);
    }
    if (!folder.expanded) {
      this.onFolderCollapsed(folder.id);
      this.collapseChildrenFolders(folder);
    } else {
      this.onFolderExpanded(folder.id);
    }
  }

  public collapseChildrenFolders(folder: any) {
    if (folder.children?.length) {
      folder.children.forEach((child) => {
        child.expanded = false;
        this.onFolderCollapsed(folder.id);
        this.collapseChildrenFolders(child);
      });
    }
  }


}
