/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PlayerDto { 
    /**
     * player id
     */
    id?: number;
    /**
     * Name of the player
     */
    name: string;
    /**
     * Tricot number
     */
    backNumber: number;
    /**
     * Position playing
     */
    position: string;
    /**
     * Playing or Bench
     */
    isPlaying: boolean;
    /**
     * player image url
     */
    imageUrl?: string;
    /**
     * player status playing/bench/out
     */
    status: string;
    /**
     * Deleted flag of the player
     */
    deleted: boolean;
}

