<app-header [headerTitleTemplate]="headerTitleTemplate" [headerTitle]="headerTitle"
    [hasUploadPending]="hasPendingUploads$ | async" (onSynchronize$)="onSynchronize()" [showSync]="showSync"
    [showGoBackButton]="showGoBackButton" [goBackActionOverride]="goBackActionOverride"
    [showOrgIcon]="showOrgIcon" [showFolderIcon]="showFolderIcon" [accounts]="accounts" (onAccountSelected)="onAccountSelected.emit($event)"
    [playListFolders]="playListFolders" (onSelectFolder)="onSelectFolder.emit($event)"
    [selectedAccount]="selectedAccount" [selectedPlaylistFolder]="selectedPlaylistFolder"
    [selectedWatchlistFolder]="selectedWatchlistFolder" [watchListFolders]="watchListFolders"
    [selectedMyTeamFolder]="selectedMyTeamFolder" [myTeamFolders]="myTeamFolders">
    <ng-content>
    </ng-content>
</app-header>
