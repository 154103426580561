/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ScoutingPlayByPlayDto { 
    /**
     * playByPlay id
     */
    id?: number;
    /**
     * playByPlay order id
     */
    orderId: number;
    /**
     * possession id
     */
    possessionId: number;
    /**
     * Game Event
     */
    event: string;
    /**
     * Seconds since start of game
     */
    secondsSinceStartOfGame: number;
    /**
     * Half time
     */
    halftime: string;
    /**
     * Minutes since Half Time
     */
    minutesSinceHalftime: number;
    /**
     * Seconds since Half Time
     */
    secondsSinceHalftime: number;
    /**
     * Event time stamp
     */
    timestamp: string;
    /**
     * Offense Player id
     */
    offensePlayer?: number;
    /**
     * Defense Player id
     */
    defensePlayer?: number;
    /**
     * Assistant Player id
     */
    assistantPlayer?: number;
    /**
     * Shooter Position
     */
    shooterPosition?: string;
    /**
     * Shooter Position
     */
    executionPosition?: string;
    /**
     * Shot Location
     */
    shotLocation?: string;
    /**
     * Possession Counter
     */
    possessions: number;
    /**
     * Phase
     */
    phase: string;
    /**
     * Defense System
     */
    defenseSystem: string;
    /**
     * Ids of offense players
     */
    offense: string;
    /**
     * Ids of defense players
     */
    defense: string;
    /**
     * Team Marker
     */
    teamMarker: string;
    /**
     * Team Id
     */
    teamId: number;
    /**
     * Team Name
     */
    teamName: string;
    /**
     * Offense System
     */
    offenseSystem: string;
    /**
     * flag for important event
     */
    important: boolean;
    /**
     * serialized json string of game system
     */
    xpsGameOpening?: string;
    /**
     * serialized json string of game system
     */
    gameSystem?: string;
    /**
     * Event time stamp in seconds of the video
     */
    videoTimestamp?: number;
    /**
     * Event duration in seconds (currently only used at video_mode)
     */
    eventDuration?: number;
    /**
     * Signed url to reproduce event video
     */
    videoUrl: string;
    /**
     * Game Date
     */
    gameDate: string;
    /**
     * Game Home Team
     */
    gameHomeTeam: string;
    /**
     * Game Visitor Team
     */
    gameVisitorTeam: string;
}

