/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlayByPlayDto } from './playByPlayDto';
import { PlayerStatsDto } from './playerStatsDto';
import { LineupStatsDto } from './lineupStatsDto';
import { TeamsStatsDto } from './teamsStatsDto';
import { PlayTimeDto } from './playTimeDto';


export interface UpdateGameResultDto { 
    /**
     * Home Team Goals
     */
    goalsHome: number;
    /**
     * Visitor Team Goals
     */
    goalsVisitor: number;
    /**
     * PlayerStatistics
     */
    playerStatsDto: Array<PlayerStatsDto>;
    /**
     * LineupStatistics
     */
    lineupStatsDto: Array<LineupStatsDto>;
    /**
     * TeamStatistics
     */
    teamStatsDto: Array<TeamsStatsDto>;
    /**
     * PlayByPlayDto
     */
    playByPlayDto: Array<PlayByPlayDto>;
    /**
     * PlayTimeDto
     */
    playTimeDto: Array<PlayTimeDto>;
    /**
     * first half end flag
     */
    firstHalfEnded: boolean;
    /**
     * second half end flag
     */
    secondHalfEnded: boolean;
    /**
     * end GameFlag
     */
    gameEnded: boolean;
    /**
     * folder id
     */
    folderId: number;
    /**
     * Game status (scheduled, started, saved, ended)
     */
    gameStatus: string;
    /**
     * Date of the Game
     */
    gameDateTime: string;
    /**
     * JSON string with serialized game entities
     */
    gameModelSnapshot?: string;
}

