import { GameModel, GamePlayerModel, GameTeamModel } from '@handballai/stats-calculation';
import { TeamMarker } from '../../game/team-marker';
import { GameService } from '../../game/game.service';
import { UiEventDispatcherService } from '../ui-event-dispatcher.service';
import { ActionService } from '../../actions/action.service';
import { PlayerSelectEvent, SubstitutePopupSavedEvent, SuspensionSelectedEvent } from '../events/ui-events';
import { UiCommandExecutionContext } from './ui-base-command';

export const extractTeamFromMarker = (teamMarker: TeamMarker, gameModel: GameModel): GameTeamModel =>
    teamMarker === 'HOME' ? gameModel.home : gameModel.visitor;


export const eventChainFinished = (
    eventDispatcher: UiEventDispatcherService,
    actionService: ActionService,
    gameService: GameService
): void => {
    eventDispatcher.clearAllSlots();
    actionService.disableAllButtons();
    gameService.toggleBenchHome(true);
    gameService.toggleFieldHome(false);
    gameService.toggleSuspensionsHome(false);
    gameService.toggleFieldVisitor(false);
    gameService.toggleBenchVisitor(true);
    gameService.toggleSuspensionsVisitor(false);
};

export const handle2min = (
    actualEvent: SuspensionSelectedEvent,
    eventContext: UiCommandExecutionContext,
    selectedPlayerEvent: PlayerSelectEvent
): void => {
    if (selectedPlayerEvent.payload.teamMarker === 'HOME') {
        eventContext.gameService.putPlayerOnBenchHome(selectedPlayerEvent.payload.playerModel);
    } else {
        eventContext.gameService.putPlayerOnBenchVisitor(selectedPlayerEvent.payload.playerModel);
    }
};


export const handleRedAndBlueCard = (
    actualEvent: SuspensionSelectedEvent,
    eventContext: UiCommandExecutionContext,
    selectedPlayerEvent: PlayerSelectEvent
): void => {
    if (selectedPlayerEvent.payload.teamMarker === 'HOME') {
        eventContext.gameService.putPlayerOnGameSuspensionHome(selectedPlayerEvent.payload.playerModel);
    } else {
        eventContext.gameService.putPlayerOnGameSuspensionVisitor(selectedPlayerEvent.payload.playerModel);
    }

};

export const handlePenalty = (
    actualEvent: SuspensionSelectedEvent,
    eventContext: UiCommandExecutionContext,
    selectedPlayerEvent: PlayerSelectEvent
): void => {

    switch (actualEvent.payload.suspensionSelected) {
        case 'YELLOW_CARD':
            break;
        case 'RED_CARD':
            handleRedAndBlueCard(actualEvent, eventContext, selectedPlayerEvent);
            break;
        case 'BLUE_CARD':
            handleRedAndBlueCard(actualEvent, eventContext, selectedPlayerEvent);
            break;
        default:
            handle2min(actualEvent, eventContext, selectedPlayerEvent);
    }
};

export const handleSubstitutionOf2min = (
    actualEvent: SubstitutePopupSavedEvent,
    eventContext: UiCommandExecutionContext
): void => {
    if (actualEvent.payload.teamMarker === 'HOME') {
        eventContext.gameService.bringBackPlayerAfterSuspensionHome(actualEvent.payload.playerModel);
    } else {
        eventContext.gameService.bringBackPlayerAfterSuspensionVisitor(actualEvent.payload.playerModel);
    }
};

export const handleSubstitutionOfRedBlueCard = (
    actualEvent: SubstitutePopupSavedEvent,
    eventContext: UiCommandExecutionContext
): void => {
    if (actualEvent.payload.teamMarker === 'HOME') {
        eventContext.gameService.bringBackSubstituteForGameSuspendedPlayerHome(
            actualEvent.payload.playerModel,
            actualEvent.payload.suspendedPlayer
        );
    } else {
        eventContext.gameService.bringBackSubstituteForGameSuspendedPlayerVisitor(
            actualEvent.payload.playerModel,
            actualEvent.payload.suspendedPlayer
        );
    }
};

export const handleSubstitutionOfSuspendedPlayer = (
    actualEvent: SubstitutePopupSavedEvent,
    eventContext: UiCommandExecutionContext
): void => {
    if (actualEvent.payload.suspensionAction === '2_MIN') {
        handleSubstitutionOf2min(actualEvent, eventContext);
    } else {
        handleSubstitutionOfRedBlueCard(actualEvent, eventContext);
    }
};

export const isReplacementPossible = (
    eventContext: UiCommandExecutionContext,
    player: GamePlayerModel,
    teamMarker: TeamMarker,
    firstSelectedPlayer: GamePlayerModel
): boolean => {
    let isGkInField: boolean;
    if (player.position === 'gk' && firstSelectedPlayer.position === 'gk') {
        return true; //Both players are goalkeepers
    }
    if (teamMarker === 'HOME') {
        isGkInField  = eventContext.gameService.gameModel.home.currentField.filter(pl => pl.position === 'gk').length > 0;
    } else {
        isGkInField  = eventContext.gameService.gameModel.visitor.currentField.filter(pl => pl.position === 'gk').length > 0;
    }

    if (player.position === 'gk' && isGkInField) {
        eventContext.toastController.create({
            icon: 'close-outline',
            color: 'danger',
            message: `You cannot replace a Field-Player by Goalkeeper!`,
            duration: 5000
        }).then(value => value.present());

        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
            );
        return false;
    }
    return true;
};
