import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ExternalAccountEquiposService, SeasonDto } from "src/app/api/hai-api";
import { CoreService } from "../core.service";
import { BehaviorSubject, Observable, firstValueFrom, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SeasonService {
  get selectedSeason$() {
    return this._selectedSeason$;
  }

  get currentSeason$(): Observable<SeasonDto> {
    return this._currentSeason$;
  }

  get seasons$() {
    return this._seasons$;
  }

  private _currentSeason$ = new BehaviorSubject<SeasonDto>(null);
  private _selectedSeason$ = new BehaviorSubject<SeasonDto>(null);
  private _seasons$ = new BehaviorSubject<SeasonDto[]>(null);

  constructor(private logger: NGXLogger, private readonly externalAccountEquipoService: ExternalAccountEquiposService) {}
  private _core: CoreService;

  initCore(core: CoreService) {
    this._core = core;
  }

  getSeasons(aid: string): Promise<SeasonDto[]> {
    return new Promise<SeasonDto[]>((ok, ko) => {
      this._core.storageService.getAccessTokenAsObject().then((val) => {
        if (val) {
          this.externalAccountEquipoService.accountEquipoHandlerControllerReadSeasons(val.sub.toString(), aid).subscribe({
            next: (ae) => {
              this.logger.debug("SeasonService.getSeasons() - seasons read successfully");
              ae.forEach((season) => {
                if (season.isCurrent) {
                  this._selectedSeason$.next(season);
                  this._currentSeason$.next(season);
                }
              });
              this._seasons$.next(ae);
              ok(this.seasons$.value);
            },
            error: (error) => {
              this.logger.error("SeasonService.getSeasons() - error reading seasons!");
              ko(error);
            },
          });
        }
      });
    });
  }

  async createSeason(season: SeasonDto): Promise<SeasonDto> {
    const val = await this._core.storageService.getAccessTokenAsObject();
    if (val) {
      const accountEquipoId = this._core.accountEquipoService.selectedAccountEquipo$.getValue().id;

      return firstValueFrom(
        this.externalAccountEquipoService.accountEquipoHandlerControllerSaveSeason(
          val.sub.toString(),
          this._core.accountEquipoService.selectedAccountEquipo$.value.id.toString(),
          season
        ).pipe(tap(season => {
          this.seasons$.next([...this.seasons$.value, season]);
        }))
      );
    }
  }

  async updateSeason(season: SeasonDto) {
    const val = await this._core.storageService.getAccessTokenAsObject();
    if (val) {
      await firstValueFrom(
        this.externalAccountEquipoService
          .accountEquipoHandlerControllerUpdateSeason(
            val.sub.toString(),
            this._core.accountEquipoService.selectedAccountEquipo$.value.id.toString(),
            season.id.toString(),
            season
          )
          .pipe(
            tap((res) => {
              this.logger.debug("SeasonService.updateSeason() - Season updated successfully");

              // Update local seasons and fire new change
              this._seasons$.next(this._seasons$.getValue().map(s => {
                if (s.id == res.id) for (const k in res) {
                  s[k] = res[k];
                }
                if (s.isCurrent) this._currentSeason$.next(s);
                return s;
              }));
            })
          )
      );
    }
  }
}
