import { Injectable } from '@angular/core';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import {
  AccumulatedCounterBasedOnPointInTimeList,
  FirstHalfGoals, GoalPossessionOverview,
  GoalsXgTimelineModel,
  SecondHalfGoals, SemaphorModel,
  XGoalTimeSeries
} from '@handballai/stats-calculation';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { getOpponentTeamMarker } from 'src/app/shared-services/helper/statistics-helper';
import { CoreService } from 'src/app/shared-services/core.service';
import { possessionsSubscriptionModel } from '@handballai/stats-calculation';

@Injectable({
  providedIn: 'root'
})
export class TimelineConsumerService {

  constructor(
      private readonly logger: NGXLogger,
  ) { }

  get timeLineStatsGoalsFirstHalf$(): BehaviorSubject<FirstHalfGoals[]> {
    return this._timeLineStatsGoalsFirstHalf$;
  }

  get timeLineStatsGoalsSecondHalf$(): BehaviorSubject<SecondHalfGoals[]> {
    return this._timeLineStatsGoalsSecondHalf$;
  }

  get timeLineStatsGoalPossessionOverview$(): BehaviorSubject<GoalPossessionOverview[]> {
    return this._timeLineStatsGoalPossessionOverview$;
  }

  get timeLineStatsXGoalGraph$(): BehaviorSubject<XGoalTimeSeries[]> {
    return this._timeLineStatsXGoalGraph$;
  }

  get accumulatedCounterList$(): BehaviorSubject<AccumulatedCounterBasedOnPointInTimeList[]> {
    return this._accumulatedCounterList$;
  }

  get timeLineSemaphor$(): BehaviorSubject<SemaphorModel[]> {
    return this._timeLineSemaphor$;
  }
  private _core: CoreService;

  private _teamTimeLineModelMap: Map<TeamMarker, GoalsXgTimelineModel>;
  private _timeLineStatsGoalsFirstHalf$ = new BehaviorSubject<FirstHalfGoals[]>([]);
  private _timeLineStatsGoalsSecondHalf$ = new BehaviorSubject<SecondHalfGoals[]>([]);
  private _timeLineStatsGoalPossessionOverview$ = new BehaviorSubject<GoalPossessionOverview[]>([]);
  private _timeLineStatsXGoalGraph$ = new BehaviorSubject<XGoalTimeSeries[]>([]);
  private _accumulatedCounterList$ = new BehaviorSubject<AccumulatedCounterBasedOnPointInTimeList[]>([]);
  private _timeLineSemaphor$ = new BehaviorSubject<SemaphorModel[]>([]);
  initCore(core: CoreService) {
    this._core = core;
    this.subscribeToGoalEvent();
    this.subscribeToSavePostOutEvent();
    this.subscribeToLostBallsEvent();
    this.subscribeToAllPossessionEvents();
  }

  public init(): void {
    this.logger.debug('TimelineConsumerService.init: ', this._core.gameService.gameModel);
    this._teamTimeLineModelMap = new Map<TeamMarker, GoalsXgTimelineModel>([
        ['HOME', new GoalsXgTimelineModel(this._core.gameService.gameModel.home.name)],
        ['VISITOR', new GoalsXgTimelineModel(this._core.gameService.gameModel.visitor.name)]
    ]);
    this.resetSubjects();
  }

  private subscribeToGoalEvent(): void {
    this._core.playByPlayProducerService.on(['GOAL'], pbp => {
      this.logger.debug('TimelineConsumerService.goalEventSubscription: ', pbp);
      this._teamTimeLineModelMap.get(pbp.teamMarker)
          .addGoal(
              pbp.phase,
              pbp.executionPosition,
              pbp.eventTime.halftime,
              pbp.eventTime.secondsSinceStartOfGame / 60,
              pbp.possessions);
      this._teamTimeLineModelMap.get(getOpponentTeamMarker(pbp.teamMarker)).addGoalsOfGk();
      this.fireSubjects();
    });
  }

  private subscribeToSavePostOutEvent(): void {
    this._core.playByPlayProducerService.on(['SAVE', 'POST_OUT'], pbp => {
      this.logger.debug('TimelineConsumerService.savePostOutEventSubscription: ', pbp);
      this._teamTimeLineModelMap.get(pbp.teamMarker)
          .addSavePostOut(
              pbp.phase,
              pbp.executionPosition,
              pbp.eventTime.secondsSinceStartOfGame / 60,
              pbp.possessions);

      // Only saves should be treated here
      if (pbp.event === 'SAVE') {
        this._teamTimeLineModelMap.get(getOpponentTeamMarker(pbp.teamMarker))
            .addSavesOfGk(pbp.eventTime.secondsSinceStartOfGame / 60);
      }
      this.fireSubjects();
    });
  }

  private subscribeToLostBallsEvent(): void {
    this._core.playByPlayProducerService.on([
      'ATTACK_FAULT_COMMIT',
      'LOST_BALL',
      'PASSIVE',
      'TECHNICAL_MISTAKE_COMMIT'
    ], pbp => {
      this.logger.debug('TimelineConsumerService.subscribeToLostBallsEvent: ', pbp);
      this._teamTimeLineModelMap.get(pbp.teamMarker)
          .addLostBall(pbp.eventTime.secondsSinceStartOfGame / 60);
      this._teamTimeLineModelMap.get(getOpponentTeamMarker(pbp.teamMarker))
          .addLostBallOpponent();
      this.fireSubjects();
    });
  }

  private subscribeToAllPossessionEvents(): void {
    this._core.playByPlayProducerService.on(possessionsSubscriptionModel, pbp => {
      this.logger.debug('TimelineConsumerService.subscribeToAllPossessionEvents: ', pbp);
      this._teamTimeLineModelMap.get(pbp.teamMarker)
          .addPossessions(pbp.possessions, pbp.eventTime.secondsSinceStartOfGame / 60);
      this.fireSubjects();
    });
  }


  private fireSubjects(): void {
    const homeTimeLineStatModel = this._teamTimeLineModelMap.get('HOME').timeLineStatsModel;
    const visitorTimeLineStatModel = this._teamTimeLineModelMap.get('VISITOR').timeLineStatsModel;
    this._timeLineStatsGoalPossessionOverview$.next([
        homeTimeLineStatModel.goalPossessionOverview,
        visitorTimeLineStatModel.goalPossessionOverview
    ]);
    this._timeLineStatsGoalsFirstHalf$.next([
      homeTimeLineStatModel.firstHalfGoals,
      visitorTimeLineStatModel.firstHalfGoals
    ]);
    this._timeLineStatsGoalsSecondHalf$.next([
      homeTimeLineStatModel.secondHalfGoals,
      visitorTimeLineStatModel.secondHalfGoals
    ]);
    this._timeLineStatsXGoalGraph$.next([
      homeTimeLineStatModel.xGoalItems,
      visitorTimeLineStatModel.xGoalItems
    ]);
    this._accumulatedCounterList$.next([
      homeTimeLineStatModel.accumulatedGoals,
      visitorTimeLineStatModel.accumulatedGoals,
      homeTimeLineStatModel.accumulatedSaves,
      visitorTimeLineStatModel.accumulatedSaves,
      homeTimeLineStatModel.accumulatedPosessions,
      visitorTimeLineStatModel.accumulatedPosessions,
      homeTimeLineStatModel.accumulatedLostBalls,
      visitorTimeLineStatModel.accumulatedLostBalls,
    ]);
    this._timeLineSemaphor$.next([
        homeTimeLineStatModel.semaphor,
        visitorTimeLineStatModel.semaphor
    ]);
  }

  private resetSubjects(): void {
    this._timeLineStatsGoalPossessionOverview$.next([]);
    this._timeLineStatsGoalsFirstHalf$.next([]);
    this._timeLineStatsGoalsSecondHalf$.next([]);
    this._timeLineStatsXGoalGraph$.next([]);
    this._accumulatedCounterList$.next([]);
    this._timeLineSemaphor$.next([]);
  }
}
