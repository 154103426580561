import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { I18nService, ILanguageSelectorItem } from "../../../i18n/i18n.service";
import { Observable } from "rxjs";
import { CoreService } from "src/app/shared-services/core.service";
import { PopoverController } from "@ionic/angular";
import { SyncPopverComponent } from "src/app/main/components/sync-popver/sync-popver.component";
import {
  AccountEquipoService,
  TManageAccountEquipoGrants,
} from "src/app/shared-services/account-equipo/account-equipo.service";
import { map } from "rxjs/operators";
import { HandballTimerService } from "src/app/shared-services/timer/handball-timer.service";
import { UserService } from "src/app/shared-services/user-service/user.service";
import { ManageAccountDto, ScoutingPlaylistDto, WatchlistFolderDto } from '../../../api/hai-api';

@Component({
    selector: "app-header-container",
    templateUrl: "./header-container.component.html",
    styleUrls: ["./header-container.component.css"],
    standalone: false
})
export class HeaderContainerComponent implements OnInit {
  @Input() headerTitleTemplate: TemplateRef<any>;
  @Input() headerTitle: string;
  @Input() showGoBackButton: boolean = false;
  @Input() showFolderIcon: boolean = false;
  @Input() showOrgIcon: boolean = false;
  @Input() accounts: ManageAccountDto[] = [];
  @Input() playListFolders: ScoutingPlaylistDto[] = [];
  @Input() watchListFolders: WatchlistFolderDto[] = [];
  @Input() myTeamFolders: any[] = [];
  @Input() selectedAccount: number;
  @Input() selectedPlaylistFolder: ScoutingPlaylistDto;
  @Input() selectedWatchlistFolder: WatchlistFolderDto;
  @Input() selectedMyTeamFolder: any;
  @Output() goBackActionOverride: EventEmitter<void> = new EventEmitter<void>();
  @Output() onAccountSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() onSelectFolder: EventEmitter<ScoutingPlaylistDto | WatchlistFolderDto> = new EventEmitter<ScoutingPlaylistDto | WatchlistFolderDto>();
  private readonly _currLang$: Observable<ILanguageSelectorItem>;
  private readonly _hasPendingUploads$: Observable<boolean>;
  private _showSync = true;

  constructor(
    private readonly i18nService: I18nService,
    private readonly core: CoreService,
    private readonly popoverController: PopoverController,
    private readonly accountEquipoService: AccountEquipoService,
    private readonly timerService: HandballTimerService,
    private readonly userService: UserService
  ) {
    this._currLang$ = this.i18nService.currLang$;
    this._hasPendingUploads$ = core.houseKeepingService.hasPendingUploads$;
  }

  ngOnInit(): void {}

  get currLang$(): Observable<ILanguageSelectorItem> {
    return this._currLang$;
  }

  get hasPendingUploads$(): Observable<boolean> {
    return this._hasPendingUploads$;
  }

  public onLangCodeChanged(langCode: ILanguageSelectorItem): void {
    this.i18nService.curLangCode = langCode;
  }

  get showSync(): boolean {
    return this._showSync;
  }

  @Input()
  set showSync(value: boolean) {
    this._showSync = value;
  }

  public async onSynchronize(): Promise<void> {
    const popover = await this.popoverController.create({
      component: SyncPopverComponent,
      cssClass: "global__timer-popover",
      // translucent: true,
      backdropDismiss: false,
      mode: "ios",
    });
    await popover.present();
  }
}
