import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { CoreService } from 'src/app/shared-services/core.service';

@Component({
    selector: 'app-scouting-folder-name-popup',
    templateUrl: './scouting-folder-name-popup.component.html',
    styleUrls: ['./scouting-folder-name-popup.component.scss'],
    standalone: false
})
export class ScoutingFolderNamePopupComponent implements OnInit {
  private _account;
  private _fg: FormGroup;

  public hasCurrentName: boolean = false;

  @Input()
  set currentName(value: string) {
    if (value) {
      this.hasCurrentName = true;
      this.initForm(value);
    }
  }

  @Input() selectedVideos;
  @Input() modalTitle: string;
  @Input() message: string;
  @Input() currentUser: string;
  @Input() selectedEquipo: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly core: CoreService,
    private readonly popoverController: PopoverController
  ) {}

  ngOnInit(name?: string) {
    this.initForm();
  }

  get fg(): FormGroup {
    return this._fg;
  }

  get account(): any {
    return this._account;
  }

  public async onCancel(): Promise<void> {
    await this.popoverController.dismiss();
  }

  public async onConfirmName(name): Promise<void> {
    await this.popoverController.dismiss({ name });
  }

  private initForm(name?: string): void {
    if (!this._fg) {
      this._fg = this.fb.group({
        nameFc: [name ?? ''],
      });
    }
  }
}
