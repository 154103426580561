import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { CoreService } from '../core.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(
      private readonly loadingController: LoadingController
  ) {
    this.loadingCounter = 0;
  }
  private _core: CoreService;

  private loading: HTMLIonLoadingElement;
  private loadingCounter: number;
  initCore(core: CoreService) {
      this._core = core;
  }

  public async present(): Promise<void> {
    if (this.loadingCounter <= 0) {
      this.loadingCounter++;
      this.loading = await this.loadingController.create({message: this._core.trans.instant('Please wait...')});
      await this.loading.present();
    } else {
      this.loadingCounter++;
    }
  }

  public async dismiss(): Promise<void> {
    if (this.loadingCounter > 0) {
      --this.loadingCounter;
    }
    if (this.loadingCounter <= 0) {
      if (this.loading) {
        await this.loading.dismiss();
        setTimeout(() => {
          this.removeLoading();
        }, 100);
      }
      this.loadingCounter = 0;
    }
  }

  removeLoading() {
    const loadingElement = document.querySelector('ion-loading');
    if (loadingElement) {
      loadingElement.remove();
    }
  }
}
