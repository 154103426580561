import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { PlayByPlayModel } from '@handballai/stats-calculation';
import { BehaviorSubject } from 'rxjs';
import {
  EventProcessorCb
} from 'src/app/shared-services/statistics/playbyplay/processor/play-by-play-event-processor';
import { PlayerEventModel } from '@handballai/stats-calculation';
import { CoreService } from 'src/app/shared-services/core.service';
import { IPlayByPlayProducerService } from 'src/app/shared-services/statistics/playbyplay/producer/play-by-play-producer.interface';
import { PlayByPlayProducerCore } from '@handballai/stats-calculation';

@Injectable({
  providedIn: 'root'
})
export class PlayByPlayProducerService implements IPlayByPlayProducerService {

  private _playByPlayProducerCore = new PlayByPlayProducerCore();

  constructor(
      private readonly logger: NGXLogger
  ) { }

  get pBpRecords$(): BehaviorSubject<PlayByPlayModel[]> {
    return this._pBpRecords$;
  }

  get pbpRecords(): PlayByPlayModel[] {
    return this._playByPlayProducerCore.pbpRecords;
  }
  private _core: CoreService;

  private _pBpRecords$ = new BehaviorSubject<PlayByPlayModel[]>([]);
  initCore(core: CoreService) {
      this._core = core;
  }

  init(): void {
    this._playByPlayProducerCore.init();
    this._pBpRecords$.next([]);
  }

  on(
      event: PlayerEventModel[],
      eventProcessor: EventProcessorCb
  ): void {
    this.logger.debug('PlayByPlayProducerService - on registered for event: ', event);
    this._playByPlayProducerCore.on(event, eventProcessor);
  }

  addPbpRecord(pbpRecord: PlayByPlayModel): void {
    this.logger.debug('PlayByPlayProducerService - fire event: ', pbpRecord.event);
    this._playByPlayProducerCore.addPbpRecord(pbpRecord);
    this._pBpRecords$.next(this._playByPlayProducerCore.pbpRecords);
  }
}
