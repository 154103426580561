<ion-content>
  <div class="suspension-to-bench-popup-container">
    <div class="suspension-to-bench-popup-title uppercase mt-4 mb-6 text-xl font-bold" *ngIf="!isGameStartSelect">{{'SELECT TEAM'|translate}}</div>
    <div class="suspension-to-bench-popup-title uppercase mt-4 mb-6 text-xl font-bold" *ngIf="isGameStartSelect">{{'SELECT THE TEAM STARTING THE GAME'|translate}}</div>


    <div class="score-board-team">
      <div class="score-board-team-left">
        <div class="score-board-team-name font-bold">
          <span>{{ !teamsSwapped ? homeTeamName : visitorTeamName}}</span>
        </div>
        <div class="score-board-team-shield -avatar -team-A relative" [ngClass]="!teamsSwapped ? homeTeamColor : visitorTeamColor"
             (click)="onTeamSelected(!teamsSwapped ? 'HOME' : 'VISITOR')">
          <div class="overflow-effect"
               [ngStyle]="{'background-image': 'url(assets/shields/1470136715-WappenFchseBerlin.jpg)'}">
            <span>{{extractTeamFirstLetters(!teamsSwapped ? homeTeamName : visitorTeamName)}}</span>
          </div>
        </div>
      </div>
      <ion-button class="swap-button" (click)="swapTeams()">
        <ion-icon name="swap-horizontal-outline"></ion-icon>
      </ion-button>
      <div class="score-board-team-right">
        <div class="score-board-team-name font-bold">
          <span>{{!teamsSwapped ? visitorTeamName : homeTeamName}}</span>
        </div>
        <div class="score-board-team-shield -avatar -team-B relative" [ngClass]="!teamsSwapped ? visitorTeamColor : homeTeamColor"
             (click)="onTeamSelected(!teamsSwapped ? 'VISITOR' : 'HOME')">
          <div class="overflow-effect"
               [ngStyle]="{'background-image': 'url(assets/shields/flensburg-handwitt.jpg)'}">
            <span>{{extractTeamFirstLetters(!teamsSwapped ? visitorTeamName : homeTeamName)}}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</ion-content>
