<div class="modal-wrapper">
  <!--Game Options-->
  <ion-list>
    <ion-item
      button
      (click)="accountRename()"
      class="close-folder dd-button"
    >
      <ion-icon slot="end" name="text-outline"></ion-icon>
      {{ "Rename" | translate }}
    </ion-item>
    <ion-item
      button
      (click)="changePlan()"
      class="close-folder dd-button"
    >
      <ion-icon slot="end" name="swap-vertical-outline"></ion-icon>
      {{ "Change plan" | translate }}
    </ion-item>
    <ion-item
      button
      (click)="subscriptionChange()"
      class="close-folder dd-button"
    >
      <ion-icon slot="end" name="card-outline"></ion-icon>
      {{ "Payment setup" | translate }}
    </ion-item>
  </ion-list>
</div>
