import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


export interface ToggleSubmitEvent {
  isOpen: boolean;
  name: string;
}
@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
    standalone: false
})
export class AccordionComponent implements OnInit {
  private _name: string;

  private _isListItemOpened = false;
  private _toggleSubmit$ = new EventEmitter<ToggleSubmitEvent>();

  get name(): string {
    return this._name;
  }

  @Input()
  set name(value: string) {
    this._name = value;
  }

  get isListItemOpened(): boolean {
    return this._isListItemOpened;
  }

  @Input()
  set isListItemOpened(value: boolean) {
    this._isListItemOpened = value;
  }

  @Output()
  get toggleSubmit$(): EventEmitter<ToggleSubmitEvent> {
    return this._toggleSubmit$;
  }

  constructor() { }

  ngOnInit() {}

  toggleAccordion(): void {
    this._isListItemOpened = !this._isListItemOpened;
    this._toggleSubmit$.emit({
      isOpen: this._isListItemOpened,
      name: this._name
    });
  }

}
