<app-custom-tabs class="page-tabs flex-auto" (selection)="selectedTab = $event">
  <app-custom-tab [label]="'TEAM'|translate" [fullHeight]="true" [selected]="selectedTab" [defaultTab]="true">
    <app-game-system-stats class="full-height"
                           [homeTeamName]="homeTeamName"
                           [visitorTeamName]="visitorTeamName"
                           [homeGameSystemStatsView]="homeGameSystemStatsView"
                           [visitorGameSystemStatsView]="visitorGameSystemStatsView">
    </app-game-system-stats>
  </app-custom-tab>
  <app-custom-tab [label]="'INDIVIDUAL'|translate" [fullHeight]="true" [selected]="selectedTab">
    <app-game-system-player-stats class="full-height"
                           [homeTeamName]="homeTeamName"
                           [visitorTeamName]="visitorTeamName"
                           [homeGameSystemStatsView]="homePlayerGameSystemStatsView"
                           [visitorGameSystemStatsView]="visitorPlayerGameSystemStatsView">
    </app-game-system-player-stats>
  </app-custom-tab>
</app-custom-tabs>
