<div class="modal-wrapper">
  <ion-list class="popop-item">
    <ng-container>
      <ion-item button (click)="optionSelected('edit')" class="create-folder dd-button">
        <ion-icon slot="end" name="create-outline"></ion-icon>
        {{ 'Edit' | translate }}
      </ion-item>
      <ion-item button (click)="optionSelected('delete')" class="close dd-button">
        <ion-icon slot="end" name="trash-outline"></ion-icon>
        {{ 'Delete' | translate }}
      </ion-item>
      <ion-item *ngIf="hasMoveOption" button (click)="optionSelected('move')" class="close-folder dd-button">
        <ion-icon slot="end" name="swap-horizontal-outline"></ion-icon>
        {{ 'Move to Playlist' | translate }}
      </ion-item>
      <ion-item *ngIf="hasShareOption" button (click)="optionSelected('share')" class="create-folder dd-button">
        <ion-icon slot="end" name="folder-outline"></ion-icon>
        {{ 'Share to a folder' | translate }}
      </ion-item>
    </ng-container>
  </ion-list>
</div>
