<div class="w-full global__game-page-content account-manager__main">
  <div class="player-detail-form__main w-full">
    <div class="table-wrapper w-full mng">
      <ngx-datatable
        #editTable
        class="material"
        [rows]="accountEquipos$ | async"
        [columns]="columns"
        [columnMode]="'flex'"
        [limit]="50"
        rowHeight="auto"
        [sorts]="[{ prop: 'backNumber', dir: 'asc' }]"
        [footerHeight]="false"
        [headerHeight]="0"
      >
        <ngx-datatable-column
          name="imageUrl"
          [flexGrow]="1"
          [resizeable]="true"
          [sortable]="false"

        >
          <ng-template ngx-datatable-header-template></ng-template>
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template
          >
            <div class="buttonsAvatar delete-btn">
              <!--<ng-container
                *ngIf="
                  selectedAccountEquipo && row.id == selectedAccountEquipo.id
                "
              >
                <div>
                  <ion-button
                    (click)="onAccountEquipoCreateOrUpdate(row, true)"
                    color="danger"
                  >
                    <ion-icon
                      name="trash-bin-outline"
                      size="medium"
                    ></ion-icon>
                  </ion-button>
                </div>
              </ng-container>--->
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="Name"
          prop="name"
          [flexGrow]="1"
          [resizeable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="column-name">{{'Name'|translate}}</div>
            <ng-container
              *ngIf="
                !selectedAccountEquipo || row.id != selectedAccountEquipo.id;
                else editMode
              "
            >
              {{ row.name }}
            </ng-container>
            <ng-template #editMode>
              <!-- <ion-item class="inline-input" no-lines> -->
              <ion-input [(ngModel)]="selectedAccountEquipo.name"></ion-input>
              <!-- </ion-item> -->
            </ng-template>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="XPS Token"
          prop="xpsToken"
          [flexGrow]="1"
          [resizeable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="column-name">XPS Token</div>
            <ng-container
              *ngIf="
                !selectedAccountEquipo || row.id != selectedAccountEquipo.id;
                else editMode
              "
            >
              {{ row?.xpsToken }}
            </ng-container>
            <ng-template #editMode>
              <!-- <ion-item class="inline-input"> -->
              <ion-input
                [(ngModel)]="selectedAccountEquipo.xpsToken"
              ></ion-input>
              <!-- </ion-item> -->
            </ng-template>
          </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column
          name="Stats share Token"
          prop="iframeToken"
          [flexGrow]="1"
          [resizeable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="column-name">{{'Stats Share Token'|translate}}</div>
            <ng-container
              *ngIf="
                !selectedAccountEquipo || row.id != selectedAccountEquipo.id;
                else editMode
              "
            >
              {{ row?.iframeToken }}
            </ng-container>
            <ng-template #editMode>
              <ion-input
                [(ngModel)]="selectedAccountEquipo.iframeToken"
              ></ion-input>
            </ng-template>
          </ng-template>
        </ngx-datatable-column> -->
        <ngx-datatable-column
          [name]="'Organization' | translate"
          prop="account"
          [flexGrow]="1"
          [resizeable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="column-name">{{'Organization'|translate}}</div>
            <div [ngClass]="selectedAccountEquipo && row.id === selectedAccountEquipo.id ? 'non-editable-column' : ''">
              {{ row.account.name }}
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [name]="'User Count' | translate"
          prop="users"
          [flexGrow]="1"
          [resizeable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="column-name">{{'Users Count'|translate}}</div>
            <div [ngClass]="selectedAccountEquipo && row.id === selectedAccountEquipo.id ? 'non-editable-column' : ''">
              {{ row.users.length }}
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="_buttons"
          [flexGrow]="1"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template ngx-datatable-header-template></ng-template>
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template
          >
            <div class="buttonsAvatar">
              <ng-container
                *ngIf="!selectedAccountEquipo || row.id != selectedAccountEquipo.id;
                  else editMode
                "
              >
                <ion-button
                  [tooltip]="'Edit Account Equipo' | translate"
                  *ngIf="!selectedAccountEquipo"
                  (click)="onAccountEquipoSelectChange(row)"
                >
                  <ion-icon name="create-outline"></ion-icon>
                </ion-button>
                <ion-button
                    [tooltip]="'Delete Account Equipo' | translate"
                    *ngIf="!selectedAccountEquipo"
                    (click)="onAccountEquipoCreateOrUpdate(row, true)"
                    color="danger"
                >
                  <ion-icon
                      name="trash-bin-outline"
                      size="medium"
                  ></ion-icon>
                </ion-button>
              </ng-container>
              <ng-template #editMode>
                <!-- <ion-button
                  (click)="onAccountEquipoSelectChange(null)"
                  color="danger"
                > -->
                <span style="background-color: red" class="cust-icon">
                  <ion-icon
                    name="close-outline"
                    (click)="onAccountEquipoSelectChange(null)"
                  ></ion-icon>
                </span>
                <!-- </ion-button> -->
                <!-- <ion-button
                  (click)="onAccountEquipoCreateOrUpdate(selectedAccountEquipo)"
                  color="success"
                > -->
                <span style="background-color: #1de4c9; color: #11637a" class="cust-icon">
                  <ion-icon
                    name="checkmark-outline"
                    (click)="
                      onAccountEquipoCreateOrUpdate(selectedAccountEquipo)
                    "
                  >
                  </ion-icon>
                </span>
                <!-- </ion-button> -->
              </ng-template>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-row-detail #myDetailRow>
          <ng-template
            let-row="row"
            let-expanded="expanded"
            ngx-datatable-row-detail-template
          >
            <div class="table-wrapper">
              <div class="w-full mt-4 mb-4" *ngIf="selectedAccountEquipo && row.id == selectedAccountEquipo.id">
                <div class="flex flex-row ml-4">
                  <div class="w-24">
                    <ion-button
                        [tooltip]="'Add User' | translate"
                        (click)="addUser(selectedAccountEquipo.users)"
                        color="success"
                      >
                        <ion-icon [name]="'add-outline'"></ion-icon>
                      </ion-button>
                  </div>
                  <div class="border-top-white grid-wrapper w-full p-4 pl-6">
                    <div class="col-span-1">{{ "Email" | translate }}</div>
                    <div class="col-span-4">{{ "Permissions" | translate }}</div>
                  </div>
                </div>
                <div class="flex flex-row ml-4 items-center" *ngFor="let user of selectedAccountEquipo.users; let i = index"
                 >
                  <div class="w-24">
                    <ion-button
                          [tooltip]="'Delete User' | translate"
                          *ngIf="user.userEmail != (user$ | async).email"
                          (click)="deleteMe(selectedAccountEquipo.users, user)"
                          color="danger"
                        >
                          <ion-icon name="trash-bin-outline"></ion-icon>
                    </ion-button>
                  </div>
                  <div class="grid-wrapper w-full p-4 pl-6"
                  [ngClass]="{'border-bottom-white': i == selectedAccountEquipo.users.length - 1 }">
                    <div class="col-span-2 lg:col-span-1">
                      <ion-item class="inline-input" no-lines>
                        <ion-input
                          [disabled]="user.userEmail == (user$ | async).email"
                          [(ngModel)]="user.userEmail"
                        ></ion-input>
                      </ion-item>
                    </div>
                    <div class="col-span-3 lg:col-span-4">
                      <ion-button
                            *ngFor="let g of availableGrants|async"
                            [fill]="hasGrant(user.grants, g) ? 'solid' : 'outline'"
                            [size]="'small'"
                            [color]="'primary'"
                            (click)="toggleGrant(user.grants, g)"
                          >
                            <ion-label>{{ g }}</ion-label>
                          </ion-button>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </ng-template>
        </ngx-datatable-row-detail>
      </ngx-datatable>
    </div>
  </div>
</div>
