import { IImg, Img, ISVG, SVG } from "pdfmake-wrapper";
import { DateTime } from 'luxon';

import { teamLogoSVG } from "./pdf-svg-in-string-format";


export const getBase64ImageFromURL = async (url: any): Promise<string> =>  {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        let ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0);
  
        const dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
  }

export const  teamLogoResolver = async (logoUrl: string, alignment: "left" | "center" | "right" | "justify", width: number): Promise<ISVG | IImg> => {
    if (logoUrl) {
      return await new Img(
        await getBase64ImageFromURL(
          `${logoUrl}`
        )
      ).width(width).alignment(alignment).build()
    }
    return (
        new SVG(`${teamLogoSVG}`)
        .alignment(alignment)
        .width(width).end
    )
  }
  
  // convert date to Figma Format with Luxon
export const globalDataDate = (d: string): string => DateTime.fromJSDate(new Date(d)).toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' });


export const getGameSystemColor = (colorCode): string => {
  if (colorCode === 'red') {
    return 'eb445a';
  } else if (colorCode === 'blue') {
    return '#2aacd3';
  } else if (colorCode === 'yellow') {
    return '#ECB22E';
  } else {
    return '#2EB67D';
  }
}


export const decimalToFixed = (num: number, precision = 1) => {
  return isNaN(num) ? num : num % 1 === 0 ? num : parseFloat(num.toFixed(precision))
}