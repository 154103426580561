<div class="grid flex-auto quick-stats-container" [ngClass]="(screenWidth$ | async) >= 760 ? 'mt-6' : 'mt-2'" >
  <div *ngIf="(selectedTeam$ | async)" class="grid-col p-2">
    <div class="grid flex-auto">
      <div class="grid-col (1/2) pr-2" (click)="changeTeamMode('Home')">
        <span class="team-label" [style.background-color]="(selectedTeam$ | async) !== 'Visitor' ? homeTeamColor : '#D3D3D3'">
          {{ homeTeamName$ | async }}
        </span>
      </div>
      <div class="grid-col (1/2)" (click)="changeTeamMode('Visitor')">
        <span 
          class="team-label"
          [style.background-color]="(selectedTeam$ | async) !== 'Home' ? visitorTeamColor : '#D3D3D3'"
          [style.color]="(selectedTeam$ | async) !== 'Home' ? 'black' : 'white'"
        >
          {{ visitorTeamName$ | async }}
        </span>
      </div>
    </div>
  </div>
  <div class="grid-col pr-2" [ngClass]="{'(3/5)': (screenWidth$ | async) >= 1024}">
    <app-quick-stats-table
      [homeQuickOverviewModel]="homeQuickOverviewModel"
      [visitorQuickOverviewModel]="visitorQuickOverviewModel"
      [home7MetersGoals]="home7MetersGoals"
      [home7MetersFailedShoots]="home7MetersFailedShoots"
      [visitor7MetersGoals]="visitor7MetersGoals"
      [visitor7MetersFailedShots]="visitor7MetersFailedShots"
      [showOneTeam]="false"
      [showDashBoard]="false"
      [showSaves]="false"
      [usePossessionEvents]="false"
      [semaphorModel]="semaphorModel"
      [noHeaderColor]="true"
      [selectedTeam]="selectedTeam$ | async"
    >
      <div first-column-header class="">
        <span>Teams:</span>
      </div>
      <div
        second-column-header
        class="team-label mr-1"
        [style.background-color]="homeTeamColor"
      >
        {{ homeTeamName$ | async }}
      </div>
      <div
        third-column-header
        class="team-label ml-1"
        [style.background-color]="visitorTeamColor"
        [style.color]="'black'"
      >
        {{ visitorTeamName$ | async }}
      </div>
    </app-quick-stats-table>
    <div class="goalkeeper-wrapper">
      <div class="goalkeeper-label-box ml-2">
        {{ "GOALKEEPER" | translate }}
      </div>
      <div *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="goalkeeper-outer-wrapper">
        <div class="goalkeeper-team-wrapper">
          <div class="goalkeeper-canvas">
            <canvas #doughnut1Canvas height="300" style="height: 300px">{{ doughnut1Chart }}</canvas>
            <div class="w-full p-2 doughnut-legend">
              {{ "Saves of the Goalkeepers / Goals received" | translate }}
            </div>
          </div>
          <div class="goalkeeper-canvas">
            <canvas #doughnut2Canvas height="300" style="height: 300px">{{ doughnut2Chart }}</canvas>
            <div class="w-full p-2 doughnut-legend">
              {{ "Saves and Post Outs / Goals received" | translate }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(selectedTeam$ | async) !== 'Home'" class="goalkeeper-outer-wrapper">
        <div class="goalkeeper-team-wrapper">
          <div class="goalkeeper-canvas">
            <canvas #doughnut3Canvas height="300" style="height: 300px">{{ doughnut3Chart }}</canvas>
            <div class="w-full p-2 doughnut-legend">
              {{ "Saves of the Goalkeepers / Goals received" | translate }}
            </div>
          </div>
          <div class="goalkeeper-canvas">
            <canvas #doughnut4Canvas height="300" style="height: 300px">{{ doughnut4Chart }}</canvas>
            <div class="w-full p-2 doughnut-legend">
              {{ "Saves and Post Outs / Goals received" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="grid-col pr-2" style="padding: 5px 0" [ngClass]="{'(2/5)': (screenWidth$ | async) >= 1024}" [ngStyle]="{'width': !(selectedTeam$ | async) ? 'inherit' : '100%'}">
    <div class="grid flex-auto">
      <div
        *ngIf="(selectedTeam$ | async) !== 'Visitor'"
        class="grid-col pr-2 column-container"
        [ngClass]="{'(1/2)': !(selectedTeam$ | async)}"
        style="flex-direction: column"
      >
        <span *ngIf="!(selectedTeam$ | async)" class="team-label" [style.background-color]="homeTeamColor">
          {{ homeTeamName$ | async }}
        </span>
        <div class="image-container">
          <span>{{ 'Connection & Goals' | translate }}</span>
          <div class="svg-container" [innerHTML]="svgCandGhome$ | async"></div>
        </div>

        <div class="image-container">
          <span>{{'Shooting Location' | translate}}</span>
          <div class="svg-container" [innerHTML]="svgSLhome$ | async"></div>
        </div>
      </div>

      <div
        *ngIf="(selectedTeam$ | async) !== 'Home'"
        class="grid-col pr-2 column-container"
        [ngClass]="{'(1/2)': !(selectedTeam$ | async)}"
        style="flex-direction: column"
      >
        <span *ngIf="!(selectedTeam$ | async)" class="team-label" [style.background-color]="visitorTeamColor" [style.color]="'black'">
          {{ visitorTeamName$ | async }}
        </span>
        <div class="image-container">
          <span>{{ 'Connection & Goals' | translate }}</span>
          <div
            class="svg-container"
            [innerHTML]="svgCandGvisitor$ | async"
          ></div>
        </div>

        <div class="image-container">
          <span>{{'Shooting Location' | translate}}</span>
          <div class="svg-container" [innerHTML]="svgSLvisitor$ | async"></div>
        </div>
      </div>
    </div>
  </div>
</div>
