import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import {
  averageEffOffenseDefenseSystem, averageEffPerDefenseSystemHelper,
  averageEffPerPhaseHelper, averageFailedShotsPerPhaseHelper, averagePostOurPerPhaseHelper,
  OverviewStatsAggregatedViewModel, OverviewStatsByPhaseModelList, OverviewStatsExtendedOffenseSystemByPhaseModelList,
  OverviewStatsOffenseSystemByPhaseModelList, QuickStatsColumnModelList, QuickStatsTimelineModel
} from '@handballai/stats-calculation';
import { OffenseSystem } from '@handballai/stats-calculation';
import { possessionsSubscriptionModel } from '@handballai/stats-calculation';
import { BehaviorSubject } from 'rxjs';
import { TeamsStatsDto } from 'src/app/api/hai-api';
import { CoreService } from 'src/app/shared-services/core.service';
import { ConnectionGoals } from '@handballai/stats-calculation';
import { OverviewConsumerCore } from '@handballai/stats-calculation';

export type TeamsStatsDtoPart = Omit<TeamsStatsDto, 'goalsOffensePositional'
  | 'goalsFastBreak'
  | 'goalsCounterGoal'
  | 'savesOffensePositional'
  | 'savesFastBreak'
  | 'savesCounterGoal'
  | 'goalsLeftWing'
  | 'goalsLeft6m'
  | 'goalsCenter6m'
  | 'goalsRight6m'
  | 'goalsRightWing'
  | 'goalsLeft9m'
  | 'goalsCenter9m'
  | 'goalsRight9m'
  | 'goals7Meters'
  | 'goalsOppositeField'
  | 'savesLeftWing'
  | 'savesLeft6m'
  | 'savesCenter6m'
  | 'savesRight6m'
  | 'savesRightWing'
  | 'savesLeft9m'
  | 'savesCenter9m'
  | 'savesRight9m'
  | 'saves7Meters'
  | 'savesOppositeField'
  | 'goals1'
  | 'goals2'
  | 'goals3'
  | 'goals4'
  | 'goals5'
  | 'goals6'
  | 'goals7'
  | 'goals8'
  | 'goals9'
  | 'saves1'
  | 'saves2'
  | 'saves3'
  | 'saves4'
  | 'saves5'
  | 'saves6'
  | 'saves7'
  | 'saves8'
  | 'saves9'
  | 'effSuperiority'
  | 'eff7vs6'
  | 'gkSaveOffensePositional'
  | 'gkSaveFastBreak'
  | 'gkSaveCounterGoal'
  | 'postOutCenter6m'
  | 'postOutCenter9m'
  | 'postOutLeft6m'
  | 'postOutLeft9m'
  | 'postOutLeftWing'
  | 'postOutOppositeField'
  | 'postOutRight6m'
  | 'postOutRight9m'
  | 'postOutRightWing'
  | 'postOut7Meters'
  | 'postOut0'
  | 'postOut10'
  | 'postOut11'
  | 'goalsLeftWing0'
  | 'goalsLeftWing1'
  | 'goalsLeftWing2'
  | 'goalsLeftWing3'
  | 'goalsLeftWing4'
  | 'goalsLeftWing5'
  | 'goalsLeftWing6'
  | 'goalsLeftWing7'
  | 'goalsLeftWing8'
  | 'goalsLeftWing9'
  | 'goalsLeftWing10'
  | 'goalsLeftWing11'
  | 'goalsLeft6m0'
  | 'goalsLeft6m1'
  | 'goalsLeft6m2'
  | 'goalsLeft6m3'
  | 'goalsLeft6m4'
  | 'goalsLeft6m5'
  | 'goalsLeft6m6'
  | 'goalsLeft6m7'
  | 'goalsLeft6m8'
  | 'goalsLeft6m9'
  | 'goalsLeft6m10'
  | 'goalsLeft6m11'
  | 'goalsCenter6m0'
  | 'goalsCenter6m1'
  | 'goalsCenter6m2'
  | 'goalsCenter6m3'
  | 'goalsCenter6m4'
  | 'goalsCenter6m5'
  | 'goalsCenter6m6'
  | 'goalsCenter6m7'
  | 'goalsCenter6m8'
  | 'goalsCenter6m9'
  | 'goalsCenter6m10'
  | 'goalsCenter6m11'
  | 'goalsRight6m0'
  | 'goalsRight6m1'
  | 'goalsRight6m2'
  | 'goalsRight6m3'
  | 'goalsRight6m4'
  | 'goalsRight6m5'
  | 'goalsRight6m6'
  | 'goalsRight6m7'
  | 'goalsRight6m8'
  | 'goalsRight6m9'
  | 'goalsRight6m10'
  | 'goalsRight6m11'
  | 'goalsRightWing0'
  | 'goalsRightWing1'
  | 'goalsRightWing2'
  | 'goalsRightWing3'
  | 'goalsRightWing4'
  | 'goalsRightWing5'
  | 'goalsRightWing6'
  | 'goalsRightWing7'
  | 'goalsRightWing8'
  | 'goalsRightWing9'
  | 'goalsRightWing10'
  | 'goalsRightWing11'
  | 'goalsLeft9m0'
  | 'goalsLeft9m1'
  | 'goalsLeft9m2'
  | 'goalsLeft9m3'
  | 'goalsLeft9m4'
  | 'goalsLeft9m5'
  | 'goalsLeft9m6'
  | 'goalsLeft9m7'
  | 'goalsLeft9m8'
  | 'goalsLeft9m9'
  | 'goalsLeft9m10'
  | 'goalsLeft9m11'
  | 'goalsCenter9m0'
  | 'goalsCenter9m1'
  | 'goalsCenter9m2'
  | 'goalsCenter9m3'
  | 'goalsCenter9m4'
  | 'goalsCenter9m5'
  | 'goalsCenter9m6'
  | 'goalsCenter9m7'
  | 'goalsCenter9m8'
  | 'goalsCenter9m9'
  | 'goalsCenter9m10'
  | 'goalsCenter9m11'
  | 'goalsRight9m0'
  | 'goalsRight9m1'
  | 'goalsRight9m2'
  | 'goalsRight9m3'
  | 'goalsRight9m4'
  | 'goalsRight9m5'
  | 'goalsRight9m6'
  | 'goalsRight9m7'
  | 'goalsRight9m8'
  | 'goalsRight9m9'
  | 'goalsRight9m10'
  | 'goalsRight9m11'
  | 'goals7Meters0'
  | 'goals7Meters1'
  | 'goals7Meters2'
  | 'goals7Meters3'
  | 'goals7Meters4'
  | 'goals7Meters5'
  | 'goals7Meters6'
  | 'goals7Meters7'
  | 'goals7Meters8'
  | 'goals7Meters9'
  | 'goals7Meters10'
  | 'goals7Meters11'
  | 'goalsOppositeField0'
  | 'goalsOppositeField1'
  | 'goalsOppositeField2'
  | 'goalsOppositeField3'
  | 'goalsOppositeField4'
  | 'goalsOppositeField5'
  | 'goalsOppositeField6'
  | 'goalsOppositeField7'
  | 'goalsOppositeField8'
  | 'goalsOppositeField9'
  | 'goalsOppositeField10'
  | 'goalsOppositeField11'
  | 'shootsLeftWing0'
  | 'shootsLeftWing1'
  | 'shootsLeftWing2'
  | 'shootsLeftWing3'
  | 'shootsLeftWing4'
  | 'shootsLeftWing5'
  | 'shootsLeftWing6'
  | 'shootsLeftWing7'
  | 'shootsLeftWing8'
  | 'shootsLeftWing9'
  | 'shootsLeftWing10'
  | 'shootsLeftWing11'
  | 'shootsLeft6m0'
  | 'shootsLeft6m1'
  | 'shootsLeft6m2'
  | 'shootsLeft6m3'
  | 'shootsLeft6m4'
  | 'shootsLeft6m5'
  | 'shootsLeft6m6'
  | 'shootsLeft6m7'
  | 'shootsLeft6m8'
  | 'shootsLeft6m9'
  | 'shootsLeft6m10'
  | 'shootsLeft6m11'
  | 'shootsCenter6m0'
  | 'shootsCenter6m1'
  | 'shootsCenter6m2'
  | 'shootsCenter6m3'
  | 'shootsCenter6m4'
  | 'shootsCenter6m5'
  | 'shootsCenter6m6'
  | 'shootsCenter6m7'
  | 'shootsCenter6m8'
  | 'shootsCenter6m9'
  | 'shootsCenter6m10'
  | 'shootsCenter6m11'
  | 'shootsRight6m0'
  | 'shootsRight6m1'
  | 'shootsRight6m2'
  | 'shootsRight6m3'
  | 'shootsRight6m4'
  | 'shootsRight6m5'
  | 'shootsRight6m6'
  | 'shootsRight6m7'
  | 'shootsRight6m8'
  | 'shootsRight6m9'
  | 'shootsRight6m10'
  | 'shootsRight6m11'
  | 'shootsRightWing0'
  | 'shootsRightWing1'
  | 'shootsRightWing2'
  | 'shootsRightWing3'
  | 'shootsRightWing4'
  | 'shootsRightWing5'
  | 'shootsRightWing6'
  | 'shootsRightWing7'
  | 'shootsRightWing8'
  | 'shootsRightWing9'
  | 'shootsRightWing10'
  | 'shootsRightWing11'
  | 'shootsLeft9m0'
  | 'shootsLeft9m1'
  | 'shootsLeft9m2'
  | 'shootsLeft9m3'
  | 'shootsLeft9m4'
  | 'shootsLeft9m5'
  | 'shootsLeft9m6'
  | 'shootsLeft9m7'
  | 'shootsLeft9m8'
  | 'shootsLeft9m9'
  | 'shootsLeft9m10'
  | 'shootsLeft9m11'
  | 'shootsCenter9m0'
  | 'shootsCenter9m1'
  | 'shootsCenter9m2'
  | 'shootsCenter9m3'
  | 'shootsCenter9m4'
  | 'shootsCenter9m5'
  | 'shootsCenter9m6'
  | 'shootsCenter9m7'
  | 'shootsCenter9m8'
  | 'shootsCenter9m9'
  | 'shootsCenter9m10'
  | 'shootsCenter9m11'
  | 'shootsRight9m0'
  | 'shootsRight9m1'
  | 'shootsRight9m2'
  | 'shootsRight9m3'
  | 'shootsRight9m4'
  | 'shootsRight9m5'
  | 'shootsRight9m6'
  | 'shootsRight9m7'
  | 'shootsRight9m8'
  | 'shootsRight9m9'
  | 'shootsRight9m10'
  | 'shootsRight9m11'
  | 'shoots7Meters0'
  | 'shoots7Meters1'
  | 'shoots7Meters2'
  | 'shoots7Meters3'
  | 'shoots7Meters4'
  | 'shoots7Meters5'
  | 'shoots7Meters6'
  | 'shoots7Meters7'
  | 'shoots7Meters8'
  | 'shoots7Meters9'
  | 'shoots7Meters10'
  | 'shoots7Meters11'
  | 'shootsOppositeField0'
  | 'shootsOppositeField1'
  | 'shootsOppositeField2'
  | 'shootsOppositeField3'
  | 'shootsOppositeField4'
  | 'shootsOppositeField5'
  | 'shootsOppositeField6'
  | 'shootsOppositeField7'
  | 'shootsOppositeField8'
  | 'shootsOppositeField9'
  | 'shootsOppositeField10'
  | 'shootsOppositeField11'
  | 'shots7Meters'
  | 'gkReceivedOffensePositional'
  | 'gkReceivedFastBreak'
  | 'gkReceivedCounterGoal'
  | 'opponentFailedShots'
  | 'eventsFastBreak'
  | 'eventsCounterGoal'
  | 'eventsOffensePositional'
  | 'eventsSuperiority7vs6'
  | 'eventsSuperiority6vs5'
  | 'eventsSuperiorityOthers'
  | 'eventsEquality'
  | 'eventsInferiority'
  | 'eventsDefense'
  | 'eventsOffense'
  | 'playedTimeOffense'
  | 'playedTimeDefense'
  | 'playedTimeOffenseInferiority'
  | 'playedTimeDefenseInferiority'
  | 'playedTimeOffenseSuperiority'
  | 'playedTimeDefenseSuperiority'
  | 'playedTimeOffenseEquality'
  | 'playedTimeDefenseEquality'
  | 'efficiencyDefense'
  | 'noGoals'
  | 'bestLineupOffense'
  | 'bestLineupDefense'
  | 'bestLineupGoals'
  | 'bestLineupPossessions'
  | 'bestLineupEfficiency'
  | 'bestLineupNoGoal'
  | 'bestLineupPossessionsDefense'
  | 'bestLineupEfficiencyDefense'
  | 'worseLineupOffense'
  | 'worseLineupDefense'
  | 'worseLineupGoals'
  | 'worseLineupPossessions'
  | 'worseLineupEfficiency'
  | 'worseLineupNoGoal'
  | 'worseLineupPossessionsDefense'
  | 'worseLineupEfficiencyDefense'
  | 'timeOuts'
  | 'gkReceivedInferiority'
> & {
  possMin5FB: number;
  possMin10FB: number;
  possMin15FB: number;
  possMin20FB: number;
  possMin25FB: number;
  possMin30FB: number;
  possMin35FB: number;
  possMin40FB: number;
  possMin45FB: number;
  possMin50FB: number;
  possMin55FB: number;
  possMin60FB: number;
  possMin5CG: number;
  possMin10CG: number;
  possMin15CG: number;
  possMin20CG: number;
  possMin25CG: number;
  possMin30CG: number;
  possMin35CG: number;
  possMin40CG: number;
  possMin45CG: number;
  possMin50CG: number;
  possMin55CG: number;
  possMin60CG: number;
  possMin5OP: number;
  possMin10OP: number;
  possMin15OP: number;
  possMin20OP: number;
  possMin25OP: number;
  possMin30OP: number;
  possMin35OP: number;
  possMin40OP: number;
  possMin45OP: number;
  possMin50OP: number;
  possMin55OP: number;
  possMin60OP: number;

  twoMinsMin5: number;
  twoMinsMin10: number;
  twoMinsMin15: number;
  twoMinsMin20: number;
  twoMinsMin25: number;
  twoMinsMin30: number;
  twoMinsMin35: number;
  twoMinsMin40: number;
  twoMinsMin45: number;
  twoMinsMin50: number;
  twoMinsMin55: number;
  twoMinsMin60: number;

  goalMin5FB: number;
  goalMin10FB: number;
  goalMin15FB: number;
  goalMin20FB: number;
  goalMin25FB: number;
  goalMin30FB: number;
  goalMin35FB: number;
  goalMin40FB: number;
  goalMin45FB: number;
  goalMin50FB: number;
  goalMin55FB: number;
  goalMin60FB: number;
  goalMin5OP: number;
  goalMin10OP: number;
  goalMin15OP: number;
  goalMin20OP: number;
  goalMin25OP: number;
  goalMin30OP: number;
  goalMin35OP: number;
  goalMin40OP: number;
  goalMin45OP: number;
  goalMin50OP: number;
  goalMin55OP: number;
  goalMin60OP: number;
  goalMin5CG: number;
  goalMin10CG: number;
  goalMin15CG: number;
  goalMin20CG: number;
  goalMin25CG: number;
  goalMin30CG: number;
  goalMin35CG: number;
  goalMin40CG: number;
  goalMin45CG: number;
  goalMin50CG: number;
  goalMin55CG: number;
  goalMin60CG: number;
};


@Injectable({
  providedIn: 'root'
})
export class OverviewConsumerService {

  constructor(
      private readonly logger: NGXLogger,
  ) {
  }

  get overviewStatsAggregatedViewModel$(): BehaviorSubject<OverviewStatsAggregatedViewModel[]> {
    return this._overviewStatsAggregatedViewModel$;
  }

  get timelineStatsAggregatedModel$(): BehaviorSubject<QuickStatsTimelineModel[]> {
    return this._timelineStatsAggregatedModel$;
  }

  get homeOverStatsByPhaseModelListPerDefenseSystem$(): BehaviorSubject<OverviewStatsByPhaseModelList[]> {
    return this._homeOverStatsByPhaseModelListPerDefenseSystem$;
  }

  get visitorOverStatsByPhaseModelListPerDefenseSystem$(): BehaviorSubject<OverviewStatsByPhaseModelList[]> {
    return this._visitorOverStatsByPhaseModelListPerDefenseSystem$;
  }

  get homeOverviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem$():
      BehaviorSubject<OverviewStatsExtendedOffenseSystemByPhaseModelList[]> {
    return this._homeOverviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem$;
  }

  get visitorOverviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem$():
      BehaviorSubject<OverviewStatsExtendedOffenseSystemByPhaseModelList[]> {
    return this._visitorOverviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem$;
  }

  get quickStatsViewModelList$(): BehaviorSubject<QuickStatsColumnModelList> {
    return this._quickStatsViewModelList$;
  }

  get homeConnectionModel$(): BehaviorSubject<ConnectionGoals> {
    return this._homeConnectionModel$;
  }

  get visitorConnectionModel$(): BehaviorSubject<ConnectionGoals> {
    return this._visitorConnectionModel$;
  }

  private _core: CoreService;

  private _timelineStatsAggregatedModel$ = new BehaviorSubject<QuickStatsTimelineModel[]>([]);
  private _overviewStatsAggregatedViewModel$ = new BehaviorSubject<OverviewStatsAggregatedViewModel[]>([]);
  private _homeOverStatsByPhaseModelListPerDefenseSystem$ = new BehaviorSubject<OverviewStatsByPhaseModelList[]>([]);
  private _visitorOverStatsByPhaseModelListPerDefenseSystem$ = new BehaviorSubject<OverviewStatsByPhaseModelList[]>([]);
  private _homeOverStatsOffenseSystemModelListPerDefenseSystem$ =
      new BehaviorSubject<OverviewStatsOffenseSystemByPhaseModelList[]>([]);
  private _visitorOverStatsOffenseSystemModelListPerDefenseSystem$ =
      new BehaviorSubject<OverviewStatsOffenseSystemByPhaseModelList[]>([]);

  private _homeOverviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem$ =
      new BehaviorSubject<OverviewStatsExtendedOffenseSystemByPhaseModelList[]>([]);
  private _visitorOverviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem$ =
      new BehaviorSubject<OverviewStatsExtendedOffenseSystemByPhaseModelList[]>([]);
  private _quickStatsViewModelList$ = new BehaviorSubject<QuickStatsColumnModelList>([]);

  private _homeConnectionModel$ = new BehaviorSubject<ConnectionGoals>(null);
  private _visitorConnectionModel$ = new BehaviorSubject<ConnectionGoals>(null);
  private _overviewConsumerCore: OverviewConsumerCore;

  initCore(core: CoreService) {
    this._core = core;
    this.subscribeToGoalEvent();
    this.subscribeToSaveEvent();
    this.subscribeToPostOutEvent();
    this.subscribeToLostBallEvent();
    this.subscribeToAllPossessions();
    this.subscribeTo2Min();
    this.subscribeTo7MetersSuspension();
    this.subscribeTo7Meters();
    this.subscribeToAttackFault();
    this.subscribeToBlueCard();
    this.subscribeToLostBall();
    this.subscribeToOneAndOne();
    this.subscribeToRedCard();
    this.subscribeToFoul();
    this.subscribeToTechnicalMistake();
    this.subscribeTo2MinProvoke();
    this.subscribeToShotBlocked();
    this.subscribeToYellowCard();
  }

  public init(): void {
    this.logger.debug('OverviewConsumerService.init: ', this._core.gameService.gameModel);
    this._overviewConsumerCore = new OverviewConsumerCore(
        this._core.gameService.gameModel.home.name,
        this._core.gameService.gameModel.visitor.name
    );
    this.resetSubjects();
    this.fireSubjects();
  }

  private subscribeToGoalEvent(): void {
    this._core.playByPlayProducerService.on(['GOAL'], pbp => {
      this.logger.debug('OverviewConsumerService.goalEventSubscription: ', pbp);
      this._overviewConsumerCore.addGoal(
          pbp.teamMarker,
          pbp.phase,
          pbp.defenseSystem,
          pbp.offenseSystem,
          pbp.eventTime,
          pbp.offensePlayer,
          pbp.eventTime.halftime,
          pbp?.assistantPlayer
      );
      this.fireSubjects();
    });
  }

  private subscribeToSaveEvent(): void {
    this._core.playByPlayProducerService.on(['SAVE'], pbp => {
      this.logger.debug('OverviewConsumerService.savePostOutEventSubscription: ', pbp);
      this._overviewConsumerCore.addSave(
          pbp.teamMarker,
          pbp.phase,
          pbp.defenseSystem,
          pbp.offenseSystem,
          pbp.eventTime,
          pbp.eventTime.halftime,
          pbp?.defensePlayer
      );
      this.fireSubjects();
    });
  }

  private subscribeToPostOutEvent(): void {
    this._core.playByPlayProducerService.on(['POST_OUT'], pbp => {
      this.logger.debug('OverviewConsumerService.savePostOutEventSubscription: ', pbp);
      this._overviewConsumerCore.addPostOut(
          pbp.teamMarker,
          pbp.phase,
          pbp.defenseSystem,
          pbp.offenseSystem
      );

      this.fireSubjects();
    });
  }

  private subscribeToLostBallEvent(): void {
    this._core.playByPlayProducerService.on([
      'ATTACK_FAULT_COMMIT',
      'LOST_BALL',
      'PASSIVE',
      'TECHNICAL_MISTAKE_COMMIT'
    ], pbp => {
      this.logger.debug('OverviewConsumerService.savePostOutEventSubscription: ', pbp);
      this._overviewConsumerCore.addLostBall(
          pbp.teamMarker,
          pbp.phase,
          pbp.defenseSystem,
          pbp.offenseSystem,
          pbp.eventTime,
          pbp.eventTime.halftime
      );
      this.fireSubjects();
    });
  }

  private subscribeToAllPossessions(): void {
    this._core.playByPlayProducerService.on([...possessionsSubscriptionModel], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeToAllPossessions: ', pbp);
      this._overviewConsumerCore.addPossessions(
          pbp.teamMarker,
          pbp.phase,
          pbp.defenseSystem,
          pbp.offenseSystem,
          pbp.eventTime,
          pbp.possessionId,
          pbp.event,
          pbp.eventTime.halftime
      );
      this.fireSubjects();
    });
  }

  private subscribeToAttackFault(): void {
    this._core.playByPlayProducerService.on(['ATTACK_FAULT_COMMIT'], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeToAttackFault: ', pbp);
      this._overviewConsumerCore.addAttackFaultCommit(
          pbp.teamMarker,
          pbp?.defensePlayer
      );
      this.fireSubjects();
    });
  }

  private subscribeToLostBall(): void {
    this._core.playByPlayProducerService.on(['LOST_BALL'], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeToLostBall: ', pbp);
      this._overviewConsumerCore.addLostBallDe(
          pbp.teamMarker,
          pbp?.defensePlayer
      );
      this.fireSubjects();
    });
  }

  private subscribeToTechnicalMistake(): void {
    this._core.playByPlayProducerService.on(['TECHNICAL_MISTAKE_COMMIT'], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeToTechnicalMistake: ', pbp);
      this._overviewConsumerCore.addTechnicalMistake(
          pbp.teamMarker,
          pbp?.defensePlayer
      );
      this.fireSubjects();
    });
  }

  private subscribeTo2MinProvoke(): void {
    this._core.playByPlayProducerService.on(['2MIN_PROVOKE'], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeTo2MinProvoke: ', pbp);
      this._overviewConsumerCore.add2MinDe(
          pbp.teamMarker,
          pbp?.defensePlayer
      );

      this.fireSubjects();
    });
  }

  private subscribeTo7MetersSuspension(): void {
    this._core.playByPlayProducerService.on(['7M_PROVOKE_SUSPENSION'], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeTo7MetersSuspension: ', pbp);
      this._overviewConsumerCore.add7MetersSuspension(
          pbp.teamMarker,
          pbp?.defensePlayer
      );

      this.fireSubjects();
    });
  }

  private subscribeTo7Meters(): void {
    this._core.playByPlayProducerService.on(['7M_PROVOKE'], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeTo7Meters: ', pbp);
      this._overviewConsumerCore.add7Meters(
          pbp.teamMarker,
          pbp?.defensePlayer
      );

      this.fireSubjects();
    });
  }

  private subscribeToShotBlocked(): void {
    this._core.playByPlayProducerService.on(['SHOT_BLOCKED'], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeToShotBlocked: ', pbp);
      this._overviewConsumerCore.addShotBlocked(
          pbp.teamMarker,
          pbp?.defensePlayer
      );

      this.fireSubjects();
    });
  }

  private subscribeToOneAndOne(): void {
    this._core.playByPlayProducerService.on(['1-1_WON'], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeToOneAndOne: ', pbp);
      this._overviewConsumerCore.addOneAndOne(
          pbp.teamMarker,
          pbp?.defensePlayer
      );

      this.fireSubjects();
    });
  }

  private subscribeToFoul(): void {
    this._core.playByPlayProducerService.on(['FOUL_RECEIVE'], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeToFoul: ', pbp);
      this._overviewConsumerCore.addFoulReceive(
          pbp.teamMarker,
          pbp?.defensePlayer
      );

      this.fireSubjects();
    });
  }

  private subscribeTo2Min(): void {
    this._core.playByPlayProducerService.on(['2_MIN'], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeTo2Min: ', pbp);
      this._overviewConsumerCore.addTwoMin(pbp.teamMarker, pbp.eventTime, pbp.eventTime.halftime);
      this.fireSubjects();
    });
  }

  private subscribeToYellowCard(): void {
    this._core.playByPlayProducerService.on(['YELLOW_CARD'], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeToYellowCard: ', pbp);
      this._overviewConsumerCore.addYellowCard(pbp.teamMarker);
      this.fireSubjects();
    });
  }

  private subscribeToRedCard(): void {
    this._core.playByPlayProducerService.on(['RED_CARD'], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeToRedCard: ', pbp);
      this._overviewConsumerCore.addRedCard(pbp.teamMarker);
      this.fireSubjects();
    });
  }

  private subscribeToBlueCard(): void {
    this._core.playByPlayProducerService.on(['BLUE_CARD'], pbp => {
      this.logger.debug('OverviewConsumerService.subscribeToBlueCard: ', pbp);
      this._overviewConsumerCore.addBlueCard(pbp.teamMarker);
      this.fireSubjects();
    });
  }

  public generateTeamStatsDto(): Map<TeamMarker, TeamsStatsDtoPart> {
    const home = this._overviewConsumerCore.overviewModelMap.get('HOME')
        .calcOverviewStatsAggregatedViewModel(this._overviewConsumerCore.overviewModelMap.get('VISITOR'));
    const visitor = this._overviewConsumerCore.overviewModelMap.get('VISITOR')
        .calcOverviewStatsAggregatedViewModel(this._overviewConsumerCore.overviewModelMap.get('HOME'));
    const homeAdditionalDoubleEvents = this._overviewConsumerCore.overviewModelMap.get('HOME').calcAdditionalOverviewDoubleEvent();
    const visitorAdditionalDoubleEvents = this._overviewConsumerCore.overviewModelMap.get('VISITOR').calcAdditionalOverviewDoubleEvent();
    const homePerPhaseModelAndDefenseModel = averageEffPerPhaseHelper(this._overviewConsumerCore.overviewModelMap.get('HOME')
        .calcPossessionsAndGoalsPerPhaseAndOffenseListPerDefenseSystem());
    const visitorPerPhaseModelAndDefenseModel = averageEffPerPhaseHelper(this._overviewConsumerCore.overviewModelMap.get('VISITOR')
        .calcPossessionsAndGoalsPerPhaseAndOffenseListPerDefenseSystem());
    const homeAverageEffOffenseDefenseSystem = averageEffOffenseDefenseSystem(this._overviewConsumerCore.overviewModelMap.get('HOME')
        .calcPossessionsAndGoalsPerPhaseAndOffenseListPerDefenseSystem());
    const visitorAverageEffOffenseDefenseSystem = averageEffOffenseDefenseSystem(this._overviewConsumerCore.overviewModelMap.get('VISITOR')
        .calcPossessionsAndGoalsPerPhaseAndOffenseListPerDefenseSystem());
    const homeEffPerDefenseSystem = averageEffPerDefenseSystemHelper(this._overviewConsumerCore.overviewModelMap.get('HOME')
        .calcOverStatsOffenseSystemListPerDefenseSystem());
    const visitorEffPerDefenseSystem = averageEffPerDefenseSystemHelper(this._overviewConsumerCore.overviewModelMap.get('VISITOR')
        .calcOverStatsOffenseSystemListPerDefenseSystem());
    const homePostOutPhaseModel = averagePostOurPerPhaseHelper(this._overviewConsumerCore.overviewModelMap.get('HOME')
        .calcOverStatsByPhaseModelListPerDefenseSystem());
    const visitorPostOutPhaseModel = averagePostOurPerPhaseHelper(this._overviewConsumerCore.overviewModelMap.get('VISITOR')
        .calcOverStatsByPhaseModelListPerDefenseSystem());
    const homeFailedShotsPhaseModel = averageFailedShotsPerPhaseHelper(this._overviewConsumerCore.overviewModelMap.get('HOME')
        .calcOverStatsByPhaseModelListPerDefenseSystem());
    const visitorFailedShotsPhaseModel = averageFailedShotsPerPhaseHelper(this._overviewConsumerCore.overviewModelMap.get('VISITOR')
        .calcOverStatsByPhaseModelListPerDefenseSystem());
    const homeQuickStats = this._overviewConsumerCore.overviewModelMap.get('HOME').calcQuickStatsColumnModel();
    const visitorQuickStats = this._overviewConsumerCore.overviewModelMap.get('VISITOR').calcQuickStatsColumnModel();

    const homeOverviewStatsViewModel = this._overviewConsumerCore.overviewModelMap.get('HOME')
        .calcModel(this._overviewConsumerCore.overviewModelMap.get('VISITOR'));

    const visitorOverviewStatsViewModel = this._overviewConsumerCore.overviewModelMap.get('VISITOR')
        .calcModel(this._overviewConsumerCore.overviewModelMap.get('HOME'));

    const homeExtendedOffenseStats = homeOverviewStatsViewModel.overviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem;
    const visitorExtendedOffenseStats = visitorOverviewStatsViewModel.overviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem;

    let homeGoalsEquality5p1vs6 = 0;
    let homeGoalsEquality6vs6 = 0;
    let homeGoalsSuperiority6vs5 = 0;
    let homeGoalsSuperiority7vs6 = 0;

    let homePossessionsEquality6vs6 = 0;
    let homePossessionsEquality5p1vs6 = 0;
    let homePossessionsSuperiority6vs5 = 0;
    let homePossessionsSuperiority7vs6 = 0;

    let homeSavesEquality6vs6 = 0;
    let homeSavesEquality5p1vs6 = 0;
    let homeSavesSuperiority6vs5 = 0;
    let homeSavesSuperiority7vs6 = 0;

    let homeLostBallsEquality6vs6 = 0;
    let homeLostBallsEquality5p1vs6 = 0;
    let homeLostBallsSuperiority6vs5 = 0;
    let homeLostBallsSuperiority7vs6 = 0;

    let homePostOutsEquality6vs6 = 0;
    let homePostOutsEquality5p1vs6 = 0;
    let homePostOutsSuperiority6vs5 = 0;
    let homePostOutsSuperiority7vs6 = 0;
    homeExtendedOffenseStats.forEach(phase => {
      homeGoalsEquality5p1vs6 += phase.map(stats => stats.goalsEquality5p1vs6).reduce((a, b) => a + b, 0);
      homeGoalsEquality6vs6 += phase.map(stats => stats.goalsEquality6vs6).reduce((a, b) => a + b, 0);
      homeGoalsSuperiority6vs5 += phase.map(stats => stats.goalsSuperiority6vs5).reduce((a, b) => a + b, 0);
      homeGoalsSuperiority7vs6 += phase.map(stats => stats.goalsSuperiority7vs6).reduce((a, b) => a + b, 0);

      homePossessionsEquality6vs6 += phase.map(stats => stats.possessionsEquality6vs6).reduce((a, b) => a + b, 0);
      homePossessionsEquality5p1vs6 += phase.map(stats => stats.possessionsEquality5p1vs6).reduce((a, b) => a + b, 0);
      homePossessionsSuperiority6vs5 += phase.map(stats => stats.possessionsSuperiority6vs5).reduce((a, b) => a + b, 0);
      homePossessionsSuperiority7vs6 += phase.map(stats => stats.possessionsSuperiority7vs6).reduce((a, b) => a + b, 0);

      homeSavesEquality6vs6 += phase.map(stats => stats.savesEquality6vs6).reduce((a, b) => a + b, 0);
      homeSavesEquality5p1vs6 += phase.map(stats => stats.savesEquality5p1vs6).reduce((a, b) => a + b, 0);
      homeSavesSuperiority6vs5 += phase.map(stats => stats.savesSuperiority6vs5).reduce((a, b) => a + b, 0);
      homeSavesSuperiority7vs6 += phase.map(stats => stats.savesSuperiority7vs6).reduce((a, b) => a + b, 0);

      homeLostBallsEquality6vs6 += phase.map(stats => stats.lostBallsEquality6vs6).reduce((a, b) => a + b, 0);
      homeLostBallsEquality5p1vs6 += phase.map(stats => stats.lostBallsEquality5p1vs6).reduce((a, b) => a + b, 0);
      homeLostBallsSuperiority6vs5 += phase.map(stats => stats.lostBallsSuperiority6vs5).reduce((a, b) => a + b, 0);
      homeLostBallsSuperiority7vs6 += phase.map(stats => stats.lostBallsSuperiority7vs6).reduce((a, b) => a + b, 0);

      homePostOutsEquality6vs6 += phase.map(stats => stats.postOutsEquality6vs6).reduce((a, b) => a + b, 0);
      homePostOutsEquality5p1vs6 += phase.map(stats => stats.postOutsEquality5p1vs6).reduce((a, b) => a + b, 0);
      homePostOutsSuperiority6vs5 += phase.map(stats => stats.postOutsSuperiority6vs5).reduce((a, b) => a + b, 0);
      homePostOutsSuperiority7vs6 += phase.map(stats => stats.postOutsSuperiority7vs6).reduce((a, b) => a + b, 0);
    });

    let visitorGoalsEquality5p1vs6 = 0;
    let visitorGoalsEquality6vs6 = 0;
    let visitorGoalsSuperiority6vs5 = 0;
    let visitorGoalsSuperiority7vs6 = 0;

    let visitorPossessionsEquality6vs6 = 0;
    let visitorPossessionsEquality5p1vs6 = 0;
    let visitorPossessionsSuperiority6vs5 = 0;
    let visitorPossessionsSuperiority7vs6 = 0;

    let visitorSavesEquality6vs6 = 0;
    let visitorSavesEquality5p1vs6 = 0;
    let visitorSavesSuperiority6vs5 = 0;
    let visitorSavesSuperiority7vs6 = 0;

    let visitorLostBallsEquality6vs6 = 0;
    let visitorLostBallsEquality5p1vs6 = 0;
    let visitorLostBallsSuperiority6vs5 = 0;
    let visitorLostBallsSuperiority7vs6 = 0;

    let visitorPostOutsEquality6vs6 = 0;
    let visitorPostOutsEquality5p1vs6 = 0;
    let visitorPostOutsSuperiority6vs5 = 0;
    let visitorPostOutsSuperiority7vs6 = 0;
    visitorExtendedOffenseStats.forEach(phase => {
      visitorGoalsEquality5p1vs6 += phase.map(stats => stats.goalsEquality5p1vs6).reduce((a, b) => a + b, 0);
      visitorGoalsEquality6vs6 += phase.map(stats => stats.goalsEquality6vs6).reduce((a, b) => a + b, 0);
      visitorGoalsSuperiority6vs5 += phase.map(stats => stats.goalsSuperiority6vs5).reduce((a, b) => a + b, 0);
      visitorGoalsSuperiority7vs6 += phase.map(stats => stats.goalsSuperiority7vs6).reduce((a, b) => a + b, 0);

      visitorPossessionsEquality6vs6 += phase.map(stats => stats.possessionsEquality6vs6).reduce((a, b) => a + b, 0);
      visitorPossessionsEquality5p1vs6 += phase.map(stats => stats.possessionsEquality5p1vs6).reduce((a, b) => a + b, 0);
      visitorPossessionsSuperiority6vs5 += phase.map(stats => stats.possessionsSuperiority6vs5).reduce((a, b) => a + b, 0);
      visitorPossessionsSuperiority7vs6 += phase.map(stats => stats.possessionsSuperiority7vs6).reduce((a, b) => a + b, 0);

      visitorSavesEquality6vs6 += phase.map(stats => stats.savesEquality6vs6).reduce((a, b) => a + b, 0);
      visitorSavesEquality5p1vs6 += phase.map(stats => stats.savesEquality5p1vs6).reduce((a, b) => a + b, 0);
      visitorSavesSuperiority6vs5 += phase.map(stats => stats.savesSuperiority6vs5).reduce((a, b) => a + b, 0);
      visitorSavesSuperiority7vs6 += phase.map(stats => stats.savesSuperiority7vs6).reduce((a, b) => a + b, 0);

      visitorLostBallsEquality6vs6 += phase.map(stats => stats.lostBallsEquality6vs6).reduce((a, b) => a + b, 0);
      visitorLostBallsEquality5p1vs6 += phase.map(stats => stats.lostBallsEquality5p1vs6).reduce((a, b) => a + b, 0);
      visitorLostBallsSuperiority6vs5 += phase.map(stats => stats.lostBallsSuperiority6vs5).reduce((a, b) => a + b, 0);
      visitorLostBallsSuperiority7vs6 += phase.map(stats => stats.lostBallsSuperiority7vs6).reduce((a, b) => a + b, 0);

      visitorPostOutsEquality6vs6 += phase.map(stats => stats.postOutsEquality6vs6).reduce((a, b) => a + b, 0);
      visitorPostOutsEquality5p1vs6 += phase.map(stats => stats.postOutsEquality5p1vs6).reduce((a, b) => a + b, 0);
      visitorPostOutsSuperiority6vs5 += phase.map(stats => stats.postOutsSuperiority6vs5).reduce((a, b) => a + b, 0);
      visitorPostOutsSuperiority7vs6 += phase.map(stats => stats.postOutsSuperiority7vs6).reduce((a, b) => a + b, 0);
    });
    const map = new Map<TeamMarker, TeamsStatsDtoPart>();
    map.set('HOME', {
      teamId: this._core.gameService.gameModel.home.id,
      teamName: home.teamName,
      possessionsPlayed: home.possessions,
      goals: home.goals,
      saves: home.gkSaves,
      lostBalls: home.lostBalls,
      lostBallsOffensePositional: homeAdditionalDoubleEvents.lostBallsOffensePositional,
      lostBallsFastBreak: homeAdditionalDoubleEvents.lostBallsFastBreak,
      lostBallsCounterGoal: homeAdditionalDoubleEvents.lostBallsCounterGoal,
      attackFaultProvoke: homeAdditionalDoubleEvents.attackFaultProvoke,
      attackFaultCommit: homeAdditionalDoubleEvents.attackFaultCommit,
      technicalMistakeProvoke: homeAdditionalDoubleEvents.technicalMistakeProvoke,
      technicalMistakeCommit: homeAdditionalDoubleEvents.technicalMistakeCommit,
      pace: home.goals + home.gkSaves + home.postOuts + home.lostBalls - homeAdditionalDoubleEvents.pace,
      lostBallProvoke: homeAdditionalDoubleEvents.lostBallProvoke,
      lostBall: homeAdditionalDoubleEvents.lostBall,
      twoMinCommit: homeAdditionalDoubleEvents.twoMinCommit,
      twoMinProvoke: homeAdditionalDoubleEvents.twoMinProvoke,
      sevenMetersCommit: homeAdditionalDoubleEvents.sevenMetersCommit,
      sevenMetersProvoke: homeAdditionalDoubleEvents.sevenMetersProvoke,
      sevenMSuspCommit: homeAdditionalDoubleEvents.sevenMSuspCommit,
      sevenMSuspProvoke: homeAdditionalDoubleEvents.sevenMSuspProvoke,
      foulCommit: homeAdditionalDoubleEvents.foulCommit,
      foulReceive: homeAdditionalDoubleEvents.foulReceive,
      oneAndOneLost: homeAdditionalDoubleEvents.oneAndOneLost,
      oneAndOneWon: homeAdditionalDoubleEvents.oneAndOneWon,
      block: homeAdditionalDoubleEvents.block,
      shotBlocked: homeAdditionalDoubleEvents.shotBlocked,
      efficiency: home.efficiency,
      shotEfficiency: home.shootingEfficiency,
      lostBallPerc: home.lostBallEfficiency,
      def6To0: homeAdditionalDoubleEvents.def6To0,
      def5To1: homeAdditionalDoubleEvents.def5To1,
      def3to2to1: homeAdditionalDoubleEvents.def3to2to1,
      def4to2: homeAdditionalDoubleEvents.def4to2,
      defOther: homeAdditionalDoubleEvents.defOther,
      effDefense: visitor.possessions === 0 ? 0 : 100 * (1 - (visitor.goals / visitor.possessions)),
      possessionsOffensePositional: homeAdditionalDoubleEvents.possessionsOffensePositional,
      possessionsCounterGoal: homeAdditionalDoubleEvents.possessionsCounterGoal,
      possessionsFastBreak: homeAdditionalDoubleEvents.possessionsFastBreak,
      effectivePossessionsOffensePositional: homeAdditionalDoubleEvents.effectivePossessionsOffensePositional,
      effectivePossessionsCounterGoal: homeAdditionalDoubleEvents.effectivePossessionsCounterGoal,
      effectivePossessionsFastBreak: homeAdditionalDoubleEvents.effectivePossessionsFastBreak,
      effOffensePositionalEquality: homePerPhaseModelAndDefenseModel.offensePositionalEqualityEfficiency,
      effCounterGoalEquality: homePerPhaseModelAndDefenseModel.counterGoalEqualityEfficiency,
      effFastBreakEquality: homePerPhaseModelAndDefenseModel.fastBreakEqualityEfficiency,
      effOffensePositionalSuperiority: homePerPhaseModelAndDefenseModel.offensePositionalSuperiorityEfficiency,
      effCounterGoalSuperiority: homePerPhaseModelAndDefenseModel.counterGoalSuperiorityEfficiency,
      effFastBreakSuperiority: homePerPhaseModelAndDefenseModel.fastBreakSuperiorityEfficiency,
      effOffensePositionalInferiority: homePerPhaseModelAndDefenseModel.offensePositionalInferiorityEfficiency,
      effCounterGoalInferiority: homePerPhaseModelAndDefenseModel.counterGoalInferiorityEfficiency,
      effFastBreakInferiority: homePerPhaseModelAndDefenseModel.fastBreakInferiorityEfficiency,
      effAgainst60: homeEffPerDefenseSystem.effAgainst60,
      effAgainst51: homeEffPerDefenseSystem.effAgainst51,
      effAgainst321: homeEffPerDefenseSystem.effAgainst321,
      effAgainst42: homeEffPerDefenseSystem.effAgainst42,
      effAgainstOther: homeEffPerDefenseSystem.effAgainstOther,
      postOutOffensePositional: homePostOutPhaseModel.offensePositionalPostOut,
      postOutFastBreak: homePostOutPhaseModel.fastBreakPostOut,
      postOutCounterGoal: homePostOutPhaseModel.counterGoalPostOut,
      failedShotsOffensePositional: homeFailedShotsPhaseModel.offensePositionalFailedShots,
      failedShotsFastBreak: homeFailedShotsPhaseModel.fastBreakFailedShots,
      failedShotsCounterGoal: homeFailedShotsPhaseModel.counterGoalFailedShots,
      sixZeroSuperiorityEfficiency: homeAverageEffOffenseDefenseSystem.sixZeroSuperiorityEfficiency,
      fiveOneSuperiorityEfficiency: homeAverageEffOffenseDefenseSystem.fiveOneSuperiorityEfficiency,
      threeTwoOneSuperiorityEfficiency: homeAverageEffOffenseDefenseSystem.threeTwoOneSuperiorityEfficiency,
      fourTwoSuperiorityEfficiency: homeAverageEffOffenseDefenseSystem.fourTwoSuperiorityEfficiency,
      otherSuperiorityEfficiency: homeAverageEffOffenseDefenseSystem.otherSuperiorityEfficiency,
      sixZeroInferiorityEfficiency: homeAverageEffOffenseDefenseSystem.sixZeroInferiorityEfficiency,
      fiveOneInferiorityEfficiency: homeAverageEffOffenseDefenseSystem.fiveOneInferiorityEfficiency,
      threeTwoOneInferiorityEfficiency: homeAverageEffOffenseDefenseSystem.threeTwoOneInferiorityEfficiency,
      fourTwoInferiorityEfficiency: homeAverageEffOffenseDefenseSystem.fourTwoInferiorityEfficiency,
      otherInferiorityEfficiency: homeAverageEffOffenseDefenseSystem.otherInferiorityEfficiency,
      sixZeroEqualityEfficiency: homeAverageEffOffenseDefenseSystem.sixZeroEqualityEfficiency,
      fiveOneEqualityEfficiency: homeAverageEffOffenseDefenseSystem.fiveOneEqualityEfficiency,
      threeTwoOneEqualityEfficiency: homeAverageEffOffenseDefenseSystem.threeTwoOneEqualityEfficiency,
      fourTwoEqualityEfficiency: homeAverageEffOffenseDefenseSystem.fourTwoEqualityEfficiency,
      otherEqualityEfficiency: homeAverageEffOffenseDefenseSystem.otherEqualityEfficiency,
      lostBallMin5: this._overviewConsumerCore.overviewModelMap.get('HOME').lostBallTimeBucket.min5Counter.counter,
      lostBallMin10: this._overviewConsumerCore.overviewModelMap.get('HOME').lostBallTimeBucket.min10Counter.counter,
      lostBallMin15: this._overviewConsumerCore.overviewModelMap.get('HOME').lostBallTimeBucket.min15Counter.counter,
      lostBallMin20: this._overviewConsumerCore.overviewModelMap.get('HOME').lostBallTimeBucket.min20Counter.counter,
      lostBallMin25: this._overviewConsumerCore.overviewModelMap.get('HOME').lostBallTimeBucket.min25Counter.counter,
      lostBallMin30: this._overviewConsumerCore.overviewModelMap.get('HOME').lostBallTimeBucket.min30Counter.counter,
      lostBallMin35: this._overviewConsumerCore.overviewModelMap.get('HOME').lostBallTimeBucket.min35Counter.counter,
      lostBallMin40: this._overviewConsumerCore.overviewModelMap.get('HOME').lostBallTimeBucket.min40Counter.counter,
      lostBallMin45: this._overviewConsumerCore.overviewModelMap.get('HOME').lostBallTimeBucket.min45Counter.counter,
      lostBallMin50: this._overviewConsumerCore.overviewModelMap.get('HOME').lostBallTimeBucket.min50Counter.counter,
      lostBallMin55: this._overviewConsumerCore.overviewModelMap.get('HOME').lostBallTimeBucket.min55Counter.counter,
      lostBallMin60: this._overviewConsumerCore.overviewModelMap.get('HOME').lostBallTimeBucket.min60Counter.counter,
      goalMin5: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucket.min5Counter.counter,
      goalMin10: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucket.min10Counter.counter,
      goalMin15: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucket.min15Counter.counter,
      goalMin20: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucket.min20Counter.counter,
      goalMin25: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucket.min25Counter.counter,
      goalMin30: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucket.min30Counter.counter,
      goalMin35: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucket.min35Counter.counter,
      goalMin40: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucket.min40Counter.counter,
      goalMin45: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucket.min45Counter.counter,
      goalMin50: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucket.min50Counter.counter,
      goalMin55: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucket.min55Counter.counter,
      goalMin60: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucket.min60Counter.counter,
      goalMin5FB: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min5FBCounter.counter,
      goalMin10FB: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min10FBCounter.counter,
      goalMin15FB: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min15FBCounter.counter,
      goalMin20FB: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min20FBCounter.counter,
      goalMin25FB: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min25FBCounter.counter,
      goalMin30FB: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min30FBCounter.counter,
      goalMin35FB: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min35FBCounter.counter,
      goalMin40FB: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min40FBCounter.counter,
      goalMin45FB: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min45FBCounter.counter,
      goalMin50FB: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min50FBCounter.counter,
      goalMin55FB: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min55FBCounter.counter,
      goalMin60FB: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min60FBCounter.counter,
      goalMin5OP: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min5OPCounter.counter,
      goalMin10OP: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min10OPCounter.counter,
      goalMin15OP: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min15OPCounter.counter,
      goalMin20OP: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min20OPCounter.counter,
      goalMin25OP: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min25OPCounter.counter,
      goalMin30OP: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min30OPCounter.counter,
      goalMin35OP: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min35OPCounter.counter,
      goalMin40OP: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min40OPCounter.counter,
      goalMin45OP: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min45OPCounter.counter,
      goalMin50OP: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min50OPCounter.counter,
      goalMin55OP: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min55OPCounter.counter,
      goalMin60OP: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min60OPCounter.counter,
      goalMin5CG: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min5CGCounter.counter,
      goalMin10CG: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min10CGCounter.counter,
      goalMin15CG: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min15CGCounter.counter,
      goalMin20CG: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min20CGCounter.counter,
      goalMin25CG: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min25CGCounter.counter,
      goalMin30CG: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min30CGCounter.counter,
      goalMin35CG: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min35CGCounter.counter,
      goalMin40CG: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min40CGCounter.counter,
      goalMin45CG: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min45CGCounter.counter,
      goalMin50CG: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min50CGCounter.counter,
      goalMin55CG: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min55CGCounter.counter,
      goalMin60CG: this._overviewConsumerCore.overviewModelMap.get('HOME').goalTimeBucketWithPhase.min60CGCounter.counter,
      savesMin5: this._overviewConsumerCore.overviewModelMap.get('HOME').savesTimeBucket.min5Counter.counter,
      savesMin10: this._overviewConsumerCore.overviewModelMap.get('HOME').savesTimeBucket.min10Counter.counter,
      savesMin15: this._overviewConsumerCore.overviewModelMap.get('HOME').savesTimeBucket.min15Counter.counter,
      savesMin20: this._overviewConsumerCore.overviewModelMap.get('HOME').savesTimeBucket.min20Counter.counter,
      savesMin25: this._overviewConsumerCore.overviewModelMap.get('HOME').savesTimeBucket.min25Counter.counter,
      savesMin30: this._overviewConsumerCore.overviewModelMap.get('HOME').savesTimeBucket.min30Counter.counter,
      savesMin35: this._overviewConsumerCore.overviewModelMap.get('HOME').savesTimeBucket.min35Counter.counter,
      savesMin40: this._overviewConsumerCore.overviewModelMap.get('HOME').savesTimeBucket.min40Counter.counter,
      savesMin45: this._overviewConsumerCore.overviewModelMap.get('HOME').savesTimeBucket.min45Counter.counter,
      savesMin50: this._overviewConsumerCore.overviewModelMap.get('HOME').savesTimeBucket.min50Counter.counter,
      savesMin55: this._overviewConsumerCore.overviewModelMap.get('HOME').savesTimeBucket.min55Counter.counter,
      savesMin60: this._overviewConsumerCore.overviewModelMap.get('HOME').savesTimeBucket.min60Counter.counter,
      possMin5: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionTimeBucket.min5Counter.counter,
      possMin10: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionTimeBucket.min10Counter.counter,
      possMin15: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionTimeBucket.min15Counter.counter,
      possMin20: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionTimeBucket.min20Counter.counter,
      possMin25: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionTimeBucket.min25Counter.counter,
      possMin30: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionTimeBucket.min30Counter.counter,
      possMin35: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionTimeBucket.min35Counter.counter,
      possMin40: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionTimeBucket.min40Counter.counter,
      possMin45: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionTimeBucket.min45Counter.counter,
      possMin50: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionTimeBucket.min50Counter.counter,
      possMin55: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionTimeBucket.min55Counter.counter,
      possMin60: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionTimeBucket.min60Counter.counter,
      twoMinsMin5: this._overviewConsumerCore.overviewModelMap.get('HOME').twoMinsTimeBucket.min5Counter.counter,
      twoMinsMin10: this._overviewConsumerCore.overviewModelMap.get('HOME').twoMinsTimeBucket.min10Counter.counter,
      twoMinsMin15: this._overviewConsumerCore.overviewModelMap.get('HOME').twoMinsTimeBucket.min15Counter.counter,
      twoMinsMin20: this._overviewConsumerCore.overviewModelMap.get('HOME').twoMinsTimeBucket.min20Counter.counter,
      twoMinsMin25: this._overviewConsumerCore.overviewModelMap.get('HOME').twoMinsTimeBucket.min25Counter.counter,
      twoMinsMin30: this._overviewConsumerCore.overviewModelMap.get('HOME').twoMinsTimeBucket.min30Counter.counter,
      twoMinsMin35: this._overviewConsumerCore.overviewModelMap.get('HOME').twoMinsTimeBucket.min35Counter.counter,
      twoMinsMin40: this._overviewConsumerCore.overviewModelMap.get('HOME').twoMinsTimeBucket.min40Counter.counter,
      twoMinsMin45: this._overviewConsumerCore.overviewModelMap.get('HOME').twoMinsTimeBucket.min45Counter.counter,
      twoMinsMin50: this._overviewConsumerCore.overviewModelMap.get('HOME').twoMinsTimeBucket.min50Counter.counter,
      twoMinsMin55: this._overviewConsumerCore.overviewModelMap.get('HOME').twoMinsTimeBucket.min55Counter.counter,
      twoMinsMin60: this._overviewConsumerCore.overviewModelMap.get('HOME').twoMinsTimeBucket.min60Counter.counter,
      possMin5FB: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min5FBCounter.counter,
      possMin10FB: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min10FBCounter.counter,
      possMin15FB: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min15FBCounter.counter,
      possMin20FB: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min20FBCounter.counter,
      possMin25FB: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min25FBCounter.counter,
      possMin30FB: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min30FBCounter.counter,
      possMin35FB: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min35FBCounter.counter,
      possMin40FB: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min40FBCounter.counter,
      possMin45FB: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min45FBCounter.counter,
      possMin50FB: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min50FBCounter.counter,
      possMin55FB: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min55FBCounter.counter,
      possMin60FB: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min60FBCounter.counter,
      possMin5CG: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min5CGCounter.counter,
      possMin10CG: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min10CGCounter.counter,
      possMin15CG: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min15CGCounter.counter,
      possMin20CG: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min20CGCounter.counter,
      possMin25CG: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min25CGCounter.counter,
      possMin30CG: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min30CGCounter.counter,
      possMin35CG: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min35CGCounter.counter,
      possMin40CG: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min40CGCounter.counter,
      possMin45CG: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min45CGCounter.counter,
      possMin50CG: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min50CGCounter.counter,
      possMin55CG: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min55CGCounter.counter,
      possMin60CG: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min60CGCounter.counter,
      possMin5OP: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min5OPCounter.counter,
      possMin10OP: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min10OPCounter.counter,
      possMin15OP: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min15OPCounter.counter,
      possMin20OP: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min20OPCounter.counter,
      possMin25OP: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min25OPCounter.counter,
      possMin30OP: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min30OPCounter.counter,
      possMin35OP: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min35OPCounter.counter,
      possMin40OP: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min40OPCounter.counter,
      possMin45OP: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min45OPCounter.counter,
      possMin50OP: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min50OPCounter.counter,
      possMin55OP: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min55OPCounter.counter,
      possMin60OP: this._overviewConsumerCore.overviewModelMap.get('HOME').possessionAndPhaseTimeBucket.min60OPCounter.counter,
      assistGkLw: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('gk_lw'),
      assistGkRw: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('gk_rw'),
      assistGkLb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('gk_lb'),
      assistGkRb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('gk_rb'),
      assistGkCb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('gk_cb'),
      assistGkLp: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('gk_lp'),
      assistLwGk: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lw_gk'),
      assistLwRw: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lw_rw'),
      assistLwLb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lw_lb'),
      assistLwRb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lw_rb'),
      assistLwCb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lw_cb'),
      assistLwLp: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lw_lp'),
      assistRwGk: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('rw_gk'),
      assistRwLw: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('rw_lw'),
      assistRwLb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('rw_lb'),
      assistRwRb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('rw_rb'),
      assistRwCb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('rw_cb'),
      assistRwLp: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('rw_lp'),
      assistLbGk: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lb_gk'),
      assistLbLw: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lb_lw'),
      assistLbRw: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lb_rw'),
      assistLbRb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lb_rb'),
      assistLbCb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lb_cb'),
      assistLbLp: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lb_lp'),
      assistRbGk: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('rb_gk'),
      assistRbLw: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('rb_lw'),
      assistRbRw: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('rb_rw'),
      assistRbLb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('rb_lb'),
      assistRbCb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('rb_cb'),
      assistRbLp: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('rb_lp'),
      assistCbGk: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('cb_gk'),
      assistCbLw: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('cb_lw'),
      assistCbRw: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('cb_rw'),
      assistCbLb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('cb_lb'),
      assistCbRb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('cb_rb'),
      assistCbLp: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('cb_lp'),
      assistLpGk: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lp_gk'),
      assistLpLw: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lp_lw'),
      assistLpRw: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lp_rw'),
      assistLpLb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lp_lb'),
      assistLpRb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lp_rb'),
      assistLpCb: this._overviewConsumerCore.overviewModelMap.get('HOME').assistRelationShipCounter.getCounterValue('lp_cb'),
      superiorityEfficiency: home.superiorityEfficiency,
      equalityEfficiency: home.equalityEfficiency,
      inferiorityEfficiency: home.inferiorityEfficiency,
      possessionsEquality: homeQuickStats.equalityPossessions,
      goalsInferiority: homeQuickStats.inferiorityGoals,
      goalsEquality: homeQuickStats.equalityGoals,
      goalsSuperiority: homeQuickStats.superiorityGoals,
      possessionsSuperiority: homeQuickStats.superiorityPossessions,
      totalShots: homeQuickStats.totalFailedShots + homeQuickStats.totalGoals,
      defensePossessions: visitor.possessions,
      defenseReceivedGoals: visitor.goals,
      possessionsEquality6vs6: homePossessionsEquality6vs6,
      goalsEquality6vs6: homeGoalsEquality6vs6,
      savesEquality6vs6: homeSavesEquality6vs6,
      lostBallsEquality6vs6: homeLostBallsEquality6vs6,
      postOutEquality6vs6: homePostOutsEquality6vs6,
      shotsEquality6vs6: homeGoalsEquality6vs6 + homeSavesEquality6vs6 + homePostOutsEquality6vs6,
      possessionsEquality5p1vs6: homePossessionsEquality5p1vs6,
      goalsEquality5p1vs6: homeGoalsEquality5p1vs6,
      savesEquality5p1vs6: homeSavesEquality5p1vs6,
      lostBallsEquality5p1vs6: homeLostBallsEquality5p1vs6,
      postOutEquality5p1vs6: homePostOutsEquality5p1vs6,
      shotsEquality5p1vs6: homeGoalsEquality5p1vs6 + homeSavesEquality5p1vs6 + homePostOutsEquality5p1vs6,
      possessionsSuperiority6vs5: homePossessionsSuperiority6vs5,
      goalsSuperiority6vs5: homeGoalsSuperiority6vs5,
      savesSuperiority6vs5: homeSavesSuperiority6vs5,
      lostBallsSuperiority6vs5: homeLostBallsSuperiority6vs5,
      postOutSuperiority6vs5: homePostOutsSuperiority6vs5,
      shotsSuperiority6vs5: homeGoalsSuperiority6vs5 + homeSavesSuperiority6vs5 + homePostOutsSuperiority6vs5,
      possessionsSuperiority7vs6: homePossessionsSuperiority7vs6,
      goalsSuperiority7vs6: homeGoalsSuperiority7vs6,
      savesSuperiority7vs6: homeSavesSuperiority7vs6,
      lostBallsSuperiority7vs6: homeLostBallsSuperiority7vs6,
      postOutSuperiority7vs6: homePostOutsSuperiority7vs6,
      shotsSuperiority7vs6: homeGoalsSuperiority7vs6 + homeSavesSuperiority7vs6 + homePostOutsSuperiority7vs6,
    });
    map.set('VISITOR', {
      teamId: this._core.gameService.gameModel.visitor.id,
      teamName: visitor.teamName,
      possessionsPlayed: visitor.possessions,
      goals: visitor.goals,
      saves: visitor.gkSaves,
      lostBalls: visitor.lostBalls,
      lostBallsOffensePositional: visitorAdditionalDoubleEvents.lostBallsOffensePositional,
      lostBallsFastBreak: visitorAdditionalDoubleEvents.lostBallsFastBreak,
      lostBallsCounterGoal: visitorAdditionalDoubleEvents.lostBallsCounterGoal,
      attackFaultProvoke: visitorAdditionalDoubleEvents.attackFaultProvoke,
      attackFaultCommit: visitorAdditionalDoubleEvents.attackFaultCommit,
      technicalMistakeProvoke: visitorAdditionalDoubleEvents.technicalMistakeProvoke,
      technicalMistakeCommit: visitorAdditionalDoubleEvents.technicalMistakeCommit,
      pace: visitor.goals + visitor.gkSaves + visitor.postOuts + visitor.lostBalls - visitorAdditionalDoubleEvents.pace,
      lostBallProvoke: visitorAdditionalDoubleEvents.lostBallProvoke,
      lostBall: visitorAdditionalDoubleEvents.lostBall,
      twoMinCommit: visitorAdditionalDoubleEvents.twoMinCommit,
      twoMinProvoke: visitorAdditionalDoubleEvents.twoMinProvoke,
      sevenMetersCommit: visitorAdditionalDoubleEvents.sevenMetersCommit,
      sevenMetersProvoke: visitorAdditionalDoubleEvents.sevenMetersProvoke,
      sevenMSuspCommit: visitorAdditionalDoubleEvents.sevenMSuspCommit,
      sevenMSuspProvoke: visitorAdditionalDoubleEvents.sevenMSuspProvoke,
      foulCommit: visitorAdditionalDoubleEvents.foulCommit,
      foulReceive: visitorAdditionalDoubleEvents.foulReceive,
      oneAndOneLost: visitorAdditionalDoubleEvents.oneAndOneLost,
      oneAndOneWon: visitorAdditionalDoubleEvents.oneAndOneWon,
      block: visitorAdditionalDoubleEvents.block,
      shotBlocked: visitorAdditionalDoubleEvents.shotBlocked,
      efficiency: visitor.efficiency,
      shotEfficiency: visitor.shootingEfficiency,
      lostBallPerc: visitor.lostBallEfficiency,
      def6To0: visitorAdditionalDoubleEvents.def6To0,
      def5To1: visitorAdditionalDoubleEvents.def5To1,
      def3to2to1: visitorAdditionalDoubleEvents.def3to2to1,
      def4to2: visitorAdditionalDoubleEvents.def4to2,
      defOther: visitorAdditionalDoubleEvents.defOther,
      effDefense: home.possessions === 0 ? 0 : 100 * (1 - (home.goals / home.possessions)),
      possessionsOffensePositional: visitorAdditionalDoubleEvents.possessionsOffensePositional,
      possessionsCounterGoal: visitorAdditionalDoubleEvents.possessionsCounterGoal,
      possessionsFastBreak: visitorAdditionalDoubleEvents.possessionsFastBreak,
      effectivePossessionsOffensePositional: visitorAdditionalDoubleEvents.effectivePossessionsOffensePositional,
      effectivePossessionsCounterGoal: visitorAdditionalDoubleEvents.effectivePossessionsCounterGoal,
      effectivePossessionsFastBreak: visitorAdditionalDoubleEvents.effectivePossessionsFastBreak,
      effOffensePositionalEquality: visitorPerPhaseModelAndDefenseModel.offensePositionalEqualityEfficiency,
      effCounterGoalEquality: visitorPerPhaseModelAndDefenseModel.counterGoalEqualityEfficiency,
      effFastBreakEquality: visitorPerPhaseModelAndDefenseModel.fastBreakEqualityEfficiency,
      effOffensePositionalSuperiority: visitorPerPhaseModelAndDefenseModel.offensePositionalSuperiorityEfficiency,
      effCounterGoalSuperiority: visitorPerPhaseModelAndDefenseModel.counterGoalSuperiorityEfficiency,
      effFastBreakSuperiority: visitorPerPhaseModelAndDefenseModel.fastBreakSuperiorityEfficiency,
      effOffensePositionalInferiority: visitorPerPhaseModelAndDefenseModel.offensePositionalInferiorityEfficiency,
      effCounterGoalInferiority: visitorPerPhaseModelAndDefenseModel.counterGoalInferiorityEfficiency,
      effFastBreakInferiority: visitorPerPhaseModelAndDefenseModel.fastBreakInferiorityEfficiency,
      effAgainst60: visitorEffPerDefenseSystem.effAgainst60,
      effAgainst51: visitorEffPerDefenseSystem.effAgainst51,
      effAgainst321: visitorEffPerDefenseSystem.effAgainst321,
      effAgainst42: visitorEffPerDefenseSystem.effAgainst42,
      effAgainstOther: visitorEffPerDefenseSystem.effAgainstOther,
      postOutOffensePositional: visitorPostOutPhaseModel.offensePositionalPostOut,
      postOutFastBreak: visitorPostOutPhaseModel.fastBreakPostOut,
      postOutCounterGoal: visitorPostOutPhaseModel.counterGoalPostOut,
      failedShotsOffensePositional: visitorFailedShotsPhaseModel.offensePositionalFailedShots,
      failedShotsFastBreak: visitorFailedShotsPhaseModel.fastBreakFailedShots,
      failedShotsCounterGoal: visitorFailedShotsPhaseModel.counterGoalFailedShots,
      sixZeroSuperiorityEfficiency: visitorAverageEffOffenseDefenseSystem.sixZeroSuperiorityEfficiency,
      fiveOneSuperiorityEfficiency: visitorAverageEffOffenseDefenseSystem.fiveOneSuperiorityEfficiency,
      threeTwoOneSuperiorityEfficiency: visitorAverageEffOffenseDefenseSystem.threeTwoOneSuperiorityEfficiency,
      fourTwoSuperiorityEfficiency: visitorAverageEffOffenseDefenseSystem.fourTwoSuperiorityEfficiency,
      otherSuperiorityEfficiency: visitorAverageEffOffenseDefenseSystem.otherSuperiorityEfficiency,
      sixZeroInferiorityEfficiency: visitorAverageEffOffenseDefenseSystem.sixZeroInferiorityEfficiency,
      fiveOneInferiorityEfficiency: visitorAverageEffOffenseDefenseSystem.fiveOneInferiorityEfficiency,
      threeTwoOneInferiorityEfficiency: visitorAverageEffOffenseDefenseSystem.threeTwoOneInferiorityEfficiency,
      fourTwoInferiorityEfficiency: visitorAverageEffOffenseDefenseSystem.fourTwoInferiorityEfficiency,
      otherInferiorityEfficiency: visitorAverageEffOffenseDefenseSystem.otherInferiorityEfficiency,
      sixZeroEqualityEfficiency: visitorAverageEffOffenseDefenseSystem.sixZeroEqualityEfficiency,
      fiveOneEqualityEfficiency: visitorAverageEffOffenseDefenseSystem.fiveOneEqualityEfficiency,
      threeTwoOneEqualityEfficiency: visitorAverageEffOffenseDefenseSystem.threeTwoOneEqualityEfficiency,
      fourTwoEqualityEfficiency: visitorAverageEffOffenseDefenseSystem.fourTwoEqualityEfficiency,
      otherEqualityEfficiency: visitorAverageEffOffenseDefenseSystem.otherEqualityEfficiency,
      lostBallMin5: this._overviewConsumerCore.overviewModelMap.get('VISITOR').lostBallTimeBucket.min5Counter.counter,
      lostBallMin10: this._overviewConsumerCore.overviewModelMap.get('VISITOR').lostBallTimeBucket.min10Counter.counter,
      lostBallMin15: this._overviewConsumerCore.overviewModelMap.get('VISITOR').lostBallTimeBucket.min15Counter.counter,
      lostBallMin20: this._overviewConsumerCore.overviewModelMap.get('VISITOR').lostBallTimeBucket.min20Counter.counter,
      lostBallMin25: this._overviewConsumerCore.overviewModelMap.get('VISITOR').lostBallTimeBucket.min25Counter.counter,
      lostBallMin30: this._overviewConsumerCore.overviewModelMap.get('VISITOR').lostBallTimeBucket.min30Counter.counter,
      lostBallMin35: this._overviewConsumerCore.overviewModelMap.get('VISITOR').lostBallTimeBucket.min35Counter.counter,
      lostBallMin40: this._overviewConsumerCore.overviewModelMap.get('VISITOR').lostBallTimeBucket.min40Counter.counter,
      lostBallMin45: this._overviewConsumerCore.overviewModelMap.get('VISITOR').lostBallTimeBucket.min45Counter.counter,
      lostBallMin50: this._overviewConsumerCore.overviewModelMap.get('VISITOR').lostBallTimeBucket.min50Counter.counter,
      lostBallMin55: this._overviewConsumerCore.overviewModelMap.get('VISITOR').lostBallTimeBucket.min55Counter.counter,
      lostBallMin60: this._overviewConsumerCore.overviewModelMap.get('VISITOR').lostBallTimeBucket.min60Counter.counter,
      goalMin5: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucket.min5Counter.counter,
      goalMin10: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucket.min10Counter.counter,
      goalMin15: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucket.min15Counter.counter,
      goalMin20: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucket.min20Counter.counter,
      goalMin25: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucket.min25Counter.counter,
      goalMin30: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucket.min30Counter.counter,
      goalMin35: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucket.min35Counter.counter,
      goalMin40: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucket.min40Counter.counter,
      goalMin45: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucket.min45Counter.counter,
      goalMin50: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucket.min50Counter.counter,
      goalMin55: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucket.min55Counter.counter,
      goalMin60: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucket.min60Counter.counter,
      goalMin5FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min5FBCounter.counter,
      goalMin10FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min10FBCounter.counter,
      goalMin15FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min15FBCounter.counter,
      goalMin20FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min20FBCounter.counter,
      goalMin25FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min25FBCounter.counter,
      goalMin30FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min30FBCounter.counter,
      goalMin35FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min35FBCounter.counter,
      goalMin40FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min40FBCounter.counter,
      goalMin45FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min45FBCounter.counter,
      goalMin50FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min50FBCounter.counter,
      goalMin55FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min55FBCounter.counter,
      goalMin60FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min60FBCounter.counter,
      goalMin5OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min5OPCounter.counter,
      goalMin10OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min10OPCounter.counter,
      goalMin15OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min15OPCounter.counter,
      goalMin20OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min20OPCounter.counter,
      goalMin25OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min25OPCounter.counter,
      goalMin30OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min30OPCounter.counter,
      goalMin35OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min35OPCounter.counter,
      goalMin40OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min40OPCounter.counter,
      goalMin45OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min45OPCounter.counter,
      goalMin50OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min50OPCounter.counter,
      goalMin55OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min55OPCounter.counter,
      goalMin60OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min60OPCounter.counter,
      goalMin5CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min5CGCounter.counter,
      goalMin10CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min10CGCounter.counter,
      goalMin15CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min15CGCounter.counter,
      goalMin20CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min20CGCounter.counter,
      goalMin25CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min25CGCounter.counter,
      goalMin30CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min30CGCounter.counter,
      goalMin35CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min35CGCounter.counter,
      goalMin40CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min40CGCounter.counter,
      goalMin45CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min45CGCounter.counter,
      goalMin50CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min50CGCounter.counter,
      goalMin55CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min55CGCounter.counter,
      goalMin60CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').goalTimeBucketWithPhase.min60CGCounter.counter,
      savesMin5: this._overviewConsumerCore.overviewModelMap.get('VISITOR').savesTimeBucket.min5Counter.counter,
      savesMin10: this._overviewConsumerCore.overviewModelMap.get('VISITOR').savesTimeBucket.min10Counter.counter,
      savesMin15: this._overviewConsumerCore.overviewModelMap.get('VISITOR').savesTimeBucket.min15Counter.counter,
      savesMin20: this._overviewConsumerCore.overviewModelMap.get('VISITOR').savesTimeBucket.min20Counter.counter,
      savesMin25: this._overviewConsumerCore.overviewModelMap.get('VISITOR').savesTimeBucket.min25Counter.counter,
      savesMin30: this._overviewConsumerCore.overviewModelMap.get('VISITOR').savesTimeBucket.min30Counter.counter,
      savesMin35: this._overviewConsumerCore.overviewModelMap.get('VISITOR').savesTimeBucket.min35Counter.counter,
      savesMin40: this._overviewConsumerCore.overviewModelMap.get('VISITOR').savesTimeBucket.min40Counter.counter,
      savesMin45: this._overviewConsumerCore.overviewModelMap.get('VISITOR').savesTimeBucket.min45Counter.counter,
      savesMin50: this._overviewConsumerCore.overviewModelMap.get('VISITOR').savesTimeBucket.min50Counter.counter,
      savesMin55: this._overviewConsumerCore.overviewModelMap.get('VISITOR').savesTimeBucket.min55Counter.counter,
      savesMin60: this._overviewConsumerCore.overviewModelMap.get('VISITOR').savesTimeBucket.min60Counter.counter,
      possMin5: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionTimeBucket.min5Counter.counter,
      possMin10: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionTimeBucket.min10Counter.counter,
      possMin15: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionTimeBucket.min15Counter.counter,
      possMin20: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionTimeBucket.min20Counter.counter,
      possMin25: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionTimeBucket.min25Counter.counter,
      possMin30: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionTimeBucket.min30Counter.counter,
      possMin35: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionTimeBucket.min35Counter.counter,
      possMin40: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionTimeBucket.min40Counter.counter,
      possMin45: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionTimeBucket.min45Counter.counter,
      possMin50: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionTimeBucket.min50Counter.counter,
      possMin55: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionTimeBucket.min55Counter.counter,
      possMin60: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionTimeBucket.min60Counter.counter,
      twoMinsMin5: this._overviewConsumerCore.overviewModelMap.get('VISITOR').twoMinsTimeBucket.min5Counter.counter,
      twoMinsMin10: this._overviewConsumerCore.overviewModelMap.get('VISITOR').twoMinsTimeBucket.min10Counter.counter,
      twoMinsMin15: this._overviewConsumerCore.overviewModelMap.get('VISITOR').twoMinsTimeBucket.min15Counter.counter,
      twoMinsMin20: this._overviewConsumerCore.overviewModelMap.get('VISITOR').twoMinsTimeBucket.min20Counter.counter,
      twoMinsMin25: this._overviewConsumerCore.overviewModelMap.get('VISITOR').twoMinsTimeBucket.min25Counter.counter,
      twoMinsMin30: this._overviewConsumerCore.overviewModelMap.get('VISITOR').twoMinsTimeBucket.min30Counter.counter,
      twoMinsMin35: this._overviewConsumerCore.overviewModelMap.get('VISITOR').twoMinsTimeBucket.min35Counter.counter,
      twoMinsMin40: this._overviewConsumerCore.overviewModelMap.get('VISITOR').twoMinsTimeBucket.min40Counter.counter,
      twoMinsMin45: this._overviewConsumerCore.overviewModelMap.get('VISITOR').twoMinsTimeBucket.min45Counter.counter,
      twoMinsMin50: this._overviewConsumerCore.overviewModelMap.get('VISITOR').twoMinsTimeBucket.min50Counter.counter,
      twoMinsMin55: this._overviewConsumerCore.overviewModelMap.get('VISITOR').twoMinsTimeBucket.min55Counter.counter,
      twoMinsMin60: this._overviewConsumerCore.overviewModelMap.get('VISITOR').twoMinsTimeBucket.min60Counter.counter,
      possMin5FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min5FBCounter.counter,
      possMin10FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min10FBCounter.counter,
      possMin15FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min15FBCounter.counter,
      possMin20FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min20FBCounter.counter,
      possMin25FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min25FBCounter.counter,
      possMin30FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min30FBCounter.counter,
      possMin35FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min35FBCounter.counter,
      possMin40FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min40FBCounter.counter,
      possMin45FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min45FBCounter.counter,
      possMin50FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min50FBCounter.counter,
      possMin55FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min55FBCounter.counter,
      possMin60FB: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min60FBCounter.counter,
      possMin5CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min5CGCounter.counter,
      possMin10CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min10CGCounter.counter,
      possMin15CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min15CGCounter.counter,
      possMin20CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min20CGCounter.counter,
      possMin25CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min25CGCounter.counter,
      possMin30CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min30CGCounter.counter,
      possMin35CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min35CGCounter.counter,
      possMin40CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min40CGCounter.counter,
      possMin45CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min45CGCounter.counter,
      possMin50CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min50CGCounter.counter,
      possMin55CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min55CGCounter.counter,
      possMin60CG: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min60CGCounter.counter,
      possMin5OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min5OPCounter.counter,
      possMin10OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min10OPCounter.counter,
      possMin15OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min15OPCounter.counter,
      possMin20OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min20OPCounter.counter,
      possMin25OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min25OPCounter.counter,
      possMin30OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min30OPCounter.counter,
      possMin35OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min35OPCounter.counter,
      possMin40OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min40OPCounter.counter,
      possMin45OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min45OPCounter.counter,
      possMin50OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min50OPCounter.counter,
      possMin55OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min55OPCounter.counter,
      possMin60OP: this._overviewConsumerCore.overviewModelMap.get('VISITOR').possessionAndPhaseTimeBucket.min60OPCounter.counter,
      assistGkLw: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('gk_lw'),
      assistGkRw: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('gk_rw'),
      assistGkLb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('gk_lb'),
      assistGkRb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('gk_rb'),
      assistGkCb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('gk_cb'),
      assistGkLp: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('gk_lp'),
      assistLwGk: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lw_gk'),
      assistLwRw: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lw_rw'),
      assistLwLb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lw_lb'),
      assistLwRb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lw_rb'),
      assistLwCb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lw_cb'),
      assistLwLp: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lw_lp'),
      assistRwGk: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('rw_gk'),
      assistRwLw: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('rw_lw'),
      assistRwLb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('rw_lb'),
      assistRwRb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('rw_rb'),
      assistRwCb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('rw_cb'),
      assistRwLp: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('rw_lp'),
      assistLbGk: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lb_gk'),
      assistLbLw: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lb_lw'),
      assistLbRw: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lb_rw'),
      assistLbRb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lb_rb'),
      assistLbCb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lb_cb'),
      assistLbLp: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lb_lp'),
      assistRbGk: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('rb_gk'),
      assistRbLw: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('rb_lw'),
      assistRbRw: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('rb_rw'),
      assistRbLb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('rb_lb'),
      assistRbCb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('rb_cb'),
      assistRbLp: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('rb_lp'),
      assistCbGk: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('cb_gk'),
      assistCbLw: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('cb_lw'),
      assistCbRw: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('cb_rw'),
      assistCbLb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('cb_lb'),
      assistCbRb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('cb_rb'),
      assistCbLp: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('cb_lp'),
      assistLpGk: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lp_gk'),
      assistLpLw: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lp_lw'),
      assistLpRw: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lp_rw'),
      assistLpLb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lp_lb'),
      assistLpRb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lp_rb'),
      assistLpCb: this._overviewConsumerCore.overviewModelMap.get('VISITOR').assistRelationShipCounter.getCounterValue('lp_cb'),
      superiorityEfficiency: visitor.superiorityEfficiency,
      equalityEfficiency: visitor.equalityEfficiency,
      inferiorityEfficiency: visitor.inferiorityEfficiency,
      possessionsEquality: visitorQuickStats.equalityPossessions,
      goalsInferiority: visitorQuickStats.inferiorityGoals,
      goalsEquality: visitorQuickStats.equalityGoals,
      goalsSuperiority: visitorQuickStats.superiorityGoals,
      possessionsSuperiority: visitorQuickStats.superiorityPossessions,
      totalShots: visitorQuickStats.totalFailedShots + visitorQuickStats.totalGoals,
      defensePossessions: visitor.possessions,
      defenseReceivedGoals: home.goals,
      possessionsEquality6vs6: visitorPossessionsEquality6vs6,
      goalsEquality6vs6: visitorGoalsEquality6vs6,
      savesEquality6vs6: visitorSavesEquality6vs6,
      lostBallsEquality6vs6: visitorLostBallsEquality6vs6,
      postOutEquality6vs6: visitorPostOutsEquality6vs6,
      shotsEquality6vs6: visitorGoalsEquality6vs6 + visitorSavesEquality6vs6 + visitorPostOutsEquality6vs6,
      possessionsEquality5p1vs6: visitorPossessionsEquality5p1vs6,
      goalsEquality5p1vs6: visitorGoalsEquality5p1vs6,
      savesEquality5p1vs6: visitorSavesEquality5p1vs6,
      lostBallsEquality5p1vs6: visitorLostBallsEquality5p1vs6,
      postOutEquality5p1vs6: visitorPostOutsEquality5p1vs6,
      shotsEquality5p1vs6: visitorGoalsEquality5p1vs6 + visitorSavesEquality5p1vs6 + visitorPostOutsEquality5p1vs6,
      possessionsSuperiority6vs5: visitorPossessionsSuperiority6vs5,
      goalsSuperiority6vs5: visitorGoalsSuperiority6vs5,
      savesSuperiority6vs5: visitorSavesSuperiority6vs5,
      lostBallsSuperiority6vs5: visitorLostBallsSuperiority6vs5,
      postOutSuperiority6vs5: visitorPostOutsSuperiority6vs5,
      shotsSuperiority6vs5: visitorGoalsSuperiority6vs5 + visitorSavesSuperiority6vs5 + visitorPostOutsSuperiority6vs5,
      possessionsSuperiority7vs6: visitorPossessionsSuperiority7vs6,
      goalsSuperiority7vs6: visitorGoalsSuperiority7vs6,
      savesSuperiority7vs6: visitorSavesSuperiority7vs6,
      lostBallsSuperiority7vs6: visitorLostBallsSuperiority7vs6,
      postOutSuperiority7vs6: visitorPostOutsSuperiority7vs6,
      shotsSuperiority7vs6: visitorGoalsSuperiority7vs6 + visitorSavesSuperiority7vs6 + visitorPostOutsSuperiority7vs6,
    });
    return map;
  }


  private fireSubjects(): void {
    const homeOverviewStatsViewModel = this._overviewConsumerCore.overviewModelMap.get('HOME')
        .calcModel(this._overviewConsumerCore.overviewModelMap.get('VISITOR'));
    const visitorOverviewStatsViewModel = this._overviewConsumerCore.overviewModelMap.get('VISITOR')
        .calcModel(this._overviewConsumerCore.overviewModelMap.get('HOME'));


    this._overviewStatsAggregatedViewModel$.next([
      homeOverviewStatsViewModel.overviewAggregated,
      visitorOverviewStatsViewModel.overviewAggregated
    ]);

    this._homeOverStatsByPhaseModelListPerDefenseSystem$.next(homeOverviewStatsViewModel.overStatsByPhaseModelListPerDefenseSystem);
    this._visitorOverStatsByPhaseModelListPerDefenseSystem$.next(visitorOverviewStatsViewModel.overStatsByPhaseModelListPerDefenseSystem);

    this._homeOverStatsOffenseSystemModelListPerDefenseSystem$
        .next(homeOverviewStatsViewModel.overviewStatsOffenseSystemByPhaseModelListPerDefenseSystem);
    this._visitorOverStatsOffenseSystemModelListPerDefenseSystem$
        .next(visitorOverviewStatsViewModel.overviewStatsOffenseSystemByPhaseModelListPerDefenseSystem);
    this._homeOverviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem$
        .next(homeOverviewStatsViewModel.overviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem);
    this._visitorOverviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem$
        .next(visitorOverviewStatsViewModel.overviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem);
    this._quickStatsViewModelList$.next([
      this._overviewConsumerCore.overviewModelMap.get('HOME').calcQuickStatsColumnModel(),
      this._overviewConsumerCore.overviewModelMap.get('VISITOR').calcQuickStatsColumnModel()
    ]);
    this._homeConnectionModel$.next(this._overviewConsumerCore.overviewModelMap.get('HOME').calcConnectionModel());
    this._visitorConnectionModel$.next(this._overviewConsumerCore.overviewModelMap.get('VISITOR').calcConnectionModel());

    const homeTimelineStatsModel: QuickStatsTimelineModel = this._overviewConsumerCore.overviewModelMap.get('HOME')
        .calcQuickStatsTimelineEvents(this._overviewConsumerCore.overviewModelMap.get('VISITOR'));
    const visitorTimelineStatsModel: QuickStatsTimelineModel = this._overviewConsumerCore.overviewModelMap.get('VISITOR')
        .calcQuickStatsTimelineEvents(this._overviewConsumerCore.overviewModelMap.get('HOME'));

    this._timelineStatsAggregatedModel$.next([
      homeTimelineStatsModel,
      visitorTimelineStatsModel
    ]);
  }

  private resetSubjects(): void {
    this._overviewStatsAggregatedViewModel$.next([]);
    this._timelineStatsAggregatedModel$.next([]);
    this._homeOverStatsByPhaseModelListPerDefenseSystem$.next([]);
    this._visitorOverStatsByPhaseModelListPerDefenseSystem$.next([]);
    this._homeOverStatsOffenseSystemModelListPerDefenseSystem$.next([]);
    this._visitorOverStatsOffenseSystemModelListPerDefenseSystem$.next([]);
    this._homeOverviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem$.next([]);
    this._visitorOverviewStatsExtendedOffenseSystemByPhaseModelListPerDefenseSystem$.next([]);
    this._quickStatsViewModelList$.next([]);
    this._homeConnectionModel$.next(null);
    this._visitorConnectionModel$.next(null);
  }


  private calculateOffenseSituation(offenseSituation: string): OffenseSystem {
    if (offenseSituation.includes('EQUALITY')) {
        return 'EQUALITY';
    } else if (offenseSituation.includes('SUPERIORITY')) {
      return 'SUPERIORITY';
    } else {
      return 'INFERIORITY';
    }
  }

}
