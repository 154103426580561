<div class="dash-individual flex flex-col flex-auto content" id="ind-capture">
  <div class="display-flex-space-between">
    <div *ngIf="individualAggregated$|async" class="collapse-expand-txt">
    <span (click)="onExpandAllClick()" style="cursor: pointer;">
      {{ 'Expand All' | translate }}
    </span>

      <span style="margin: 0 15px">
      |
    </span>

      <span (click)="onCollapseAllClick()" style="cursor: pointer;">
      {{ 'Collapse All' | translate }}
    </span>
    </div>
    <div *ngIf="individualAggregated$|async" class="button-container lg:whitespace-nowrap" [ngClass]="!core.isMobileDevice ? 'pb-4' : ''">
      <button
          (click)="printGkReport()"
          class="print-button mr-5 p-2 focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full p-2 rounded-3xl px-8 py-3"
      >
        <span >{{ 'GK REPORT' | translate }}</span>
        <ion-icon *ngIf="!core.isMobileDevice" name="print" [ngClass]="!core.isMobileDevice ? 'pl-2' : ''"></ion-icon>
      </button>
      <button
          (click)="print()"
          class="print-button p-2 focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full p-2 rounded-3xl px-8 py-3"
      >
        <span *ngIf="!core.isMobileDevice">{{ "PRINT REPORT" | translate }}</span>
        <span style="width: 100%" *ngIf="core.isMobileDevice">{{ "REPORT" | translate }}</span>
        <ion-icon *ngIf="!core.isMobileDevice" name="print" [ngClass]="!core.isMobileDevice ? 'pl-2' : ''"></ion-icon>
      </button>
    </div>
  </div>
  <ng-container *ngIf="individualAggregated$|async">
    <app-dashboard-accordion
        [title]="'OVERVIEW' | translate"
        [description]="'(Player Totals/Averages and Shooting Positions)' | translate"
        [isOpen]="overviewAccordionOpen"
        (toggle)="onAccordionOverviewToggle($event)"
    >
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-col w-full lg:w-1/2 pr-2 mt-4" id="overview">
          <div class="quick-stats-wrapper mb-2" *ngIf="(individualAggregated$|async).playerPosition !== 'gk'">
            <app-quick-stats-table id="ind-quickstats" class="w-full" width="250px"
                                   [showOneTeam]="false"
                                   [showDashBoard]="true"
                                   [showSaves]="false"
                                   [usePossessionEvents]="true"
                                   [homeQuickOverviewModel]="totalQuickStats$ | async"
                                   [home7MetersGoals]=" total7MetersGoals$ | async"
                                   [home7MetersFailedShoots]="total7MetersFailedShoots$ | async"
                                   [visitorQuickOverviewModel]="avgQuickStats$ | async"
                                   [visitor7MetersGoals]="avg7MetersGoals$ | async"
                                   [visitor7MetersFailedShots]="avg7MetersFailedShoots$ | async">
              <div second-column-header>
                {{'PLAYER TOTALS' | translate}}
              </div>
              <div third-column-header>
                {{'PLAYER AVERAGE' | translate}}
              </div>
            </app-quick-stats-table>
          </div>

          <ng-container *ngIf="(individualAggregated$|async)?.playerPosition">
            <div class="w-full">
              <h2 class="select-label uppercase">
                <span *ngIf="(individualAggregated$|async).playerPosition === 'gk'">{{ 'Goalkeeper Performance' | translate }}</span>
                <span *ngIf="(individualAggregated$|async).playerPosition !== 'gk'">{{ 'Player Performance' | translate }}</span>
              </h2>
              <hr class="separator">
            </div>

            <div class="w-full doughnut-outer-wrapper mt-5 mb-5" *ngIf="(individualAggregated$|async).playerPosition !== 'gk'; else gkdoughnut">

              <div class="doughnut-wrapper" id="ind-doughnut1">
                <canvas #doughnut1Canvas>{{ doughnut1Chart }}</canvas>
                <div class="w-full p-2 doughnut-legend">
                  {{'Goals scored / Failed Shots' | translate}}
                </div>
              </div>
              <div class="doughnut-wrapper" id="ind-doughnut2">
                <canvas #doughnut2Canvas>{{ doughnut2Chart }}</canvas>
                <div class="w-full p-2 doughnut-legend">
                  {{'Goals scored by Phase'|translate}}
                </div>
              </div>
            </div>
          </ng-container>

          <ng-template #gkdoughnut>
            <div class="w-full doughnut-outer-wrapper mt-5 mb-5">
              <div class="doughnut-wrapper" id="ind-doughnut1">
                <canvas #doughnut1GkCanvas>{{ doughnut1GkChart }}</canvas>

                 <div class="w-full p-2 doughnut-legend">
                   {{'Saves of the Goalkeepers / Goals received'|translate}}
                 </div>
               </div>
               <div class="doughnut-wrapper" id="ind-doughnut2">
                 <canvas #doughnut2GkCanvas>{{ doughnut2GkChart }}</canvas>
                 <div class="w-full p-2 doughnut-legend">
                   {{'Saves and Post Outs / Goals received'|translate}}
                 </div>
               </div>
             </div>
           </ng-template>


           <!-- for PDF move to invisible zone -->
          <ng-container>
            <div class="w-full doughnut-outer-wrapper mt-5 mb-5" *ngIf="(individualAggregated$|async).playerPosition !== 'gk'; else gkdoughnut_pdf" style="position: absolute; left: -1999px; visibility: hidden">
              <div class="doughnut-wrapper" id="ind-doughnut1_pdf">
                <canvas #doughnut1CanvasPDF>{{ doughnut1ChartPDF }}</canvas>
                <div class="w-full p-2 doughnut-legend">
                  {{'Goals scored / Failed Shots'|translate}}
                </div>
              </div>
              <div class="doughnut-wrapper" id="ind-doughnut2_pdf">
                <canvas #doughnut2CanvasPDF>{{ doughnut2ChartPDF }}</canvas>
                <div class="w-full p-2 doughnut-legend">
                  {{'Goals scored by Phase'|translate}}
                </div>
              </div>
            </div>
          </ng-container>

          <ng-template #gkdoughnut_pdf>
            <div class="w-full doughnut-outer-wrapper mt-5 mb-5" id="gkdoughnut_pdf" style="position: absolute; left: -1999px;">
              <div class="doughnut-wrapper" id="ind-doughnut1PDF">
                <canvas #doughnut1GkCanvasPDF>{{ doughnut1GkChart }}</canvas>
                <div class="w-full p-2 doughnut-legend">
                  {{'Saves of the Goalkeepers / Goals received'|translate}}
                </div>
              </div>
              <div class="doughnut-wrapper" id="ind-doughnut2PDF">
                <canvas #doughnut2GkCanvasPDF>{{ doughnut2GkChart }}</canvas>
                <div class="w-full p-2 doughnut-legend">
                  {{'Saves and Post Outs / Goals received'|translate}}
                </div>
              </div>
            </div>
          </ng-template>

          <!-- <h2 class="select-label" style="margin-top: 30px;">
            {{'Individual' | translate}} {{'Events' | translate}}
          </h2>
           <hr />
          <div class="grid w-full mt-2" id="ind-events">
            <div class="grid (2/4) flex flex-col w-full pl-2">
              <ngx-datatable
                style="width: 100%; box-shadow: none"
                class="material"
                [rows]="individualAggregatedTbl3$ | async"
                [columns]="totalAvg"
                [columnMode]="'flex'"
                [limit]="100"
                rowHeight="auto"
                [scrollbarH]="true"
                [footerHeight]="false"
                [headerHeight]="50"
              >
              </ngx-datatable>
            </div>
            <div class="grid (2/4) flex flex-col w-full pl-2">
              <ngx-datatable
                style="width: 100%; box-shadow: none"
                class="material"
                [rows]="individualAggregatedTbl4$ | async"
                [columns]="totalAvg"
                [columnMode]="'flex'"
                [limit]="100"
                rowHeight="auto"
                [scrollbarH]="true"
                [footerHeight]="false"
                [headerHeight]="50"
              >
              </ngx-datatable>
            </div>
          </div> -->

        </div>

        <div class="flex flex-col w-full lg:w-1/2 vl" id="lastGames">
          <h2 class="select-label text-center">{{ "Player Shooting" | translate }}</h2>
          <hr class="separator">
          <div class="w-full mt-4">
            <app-shooter-position id="ind-plshooting"
                                  [goalConnectionCounter]="goalConnection$ | async | shotTransform"
                                  class="w-full">
            </app-shooter-position>
          </div>
        </div>
      </div>
    </app-dashboard-accordion>


    <div style="visibility: hidden;position: absolute; left: -1999px; display: flex; height: 400px;" class="w-full mt-4">
      <ng-container *ngFor="let position of availablePositions">
        <app-shooter-position id="ind-plshooting-gk"
                              [position]="position"
                              [goalConnectionCounter]="goalConnection$ | async | shotTransform"
                              class="w-full">
        </app-shooter-position>
      </ng-container>
    </div>

    <app-dashboard-accordion
        [title]="'INDIVIDUAL EVENTS' | translate"
        [description]="'(All the consolidated figures from Offense and Defense Events)' | translate"
        [isOpen]="indEventsAccordionOpen"
        (toggle)="onAccordionIndEventsToggle($event)"
    >
      <div class="grid-col w-full">
        <div *ngIf="indEventsAccordionOpen" class="flex flex-col md:flex-row w-full mt-2" id="ind-events">
          <div class=" flex flex-col w-full md:w-1/2 pl-2 individual-table">
            <ngx-datatable
                style="width: 100%; box-shadow: none"
                class="material"
                [rows]="individualAggregatedTbl3$ | async"
                [columns]="totalAvg"
                [columnMode]="'flex'"
                [limit]="100"
                rowHeight="auto"
                [scrollbarH]="!core.isMobileDevice"
                [footerHeight]="false"
                [headerHeight]="50"
                [externalSorting]="true"
                (sort)="onSort($event, 'individualAggregatedTbl3$Last', 'individualAggregatedTbl3$')"
            >
              <ngx-datatable-column *ngFor="let col of totalAvg"
                                    [prop]="col.prop"
                                    [flexGrow]="col.flexGrow"
                                    [sortable]="col.sortable"
                                    [resizeable]="col.resizeable"
                                    [headerClass]="col.frozenLeft ? 'column-left': ''">
                <ng-template ngx-datatable-header-template>
                  <ng-container  *ngIf="col.frozenLeft">{{col.name}}</ng-container>
                  <span *ngIf="!col.frozenLeft">{{col.name}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <ng-container  *ngIf="col.frozenLeft">{{value}}</ng-container>
                  <span *ngIf="!col.frozenLeft">{{value}}</span>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
          <div class=" flex flex-col w-full md:w-1/2 pl-2 individual-table">
            <ngx-datatable
                style="width: 100%; box-shadow: none"
                class="material"
                [rows]="individualAggregatedTbl4$ | async"
                [columns]="totalAvg"
                [columnMode]="'flex'"
                [limit]="100"
                rowHeight="auto"
                [scrollbarH]="!core.isMobileDevice"
                [footerHeight]="false"
                [headerHeight]="50"
                [externalSorting]="true"
                (sort)="onSort($event, 'individualAggregatedTbl4$Last', 'individualAggregatedTbl4$')"
            >
              <ngx-datatable-column *ngFor="let col of totalAvg"
                                    [prop]="col.prop"
                                    [flexGrow]="col.flexGrow"
                                    [sortable]="col.sortable"
                                    [resizeable]="col.resizeable"
                                    [headerClass]="col.frozenLeft ? 'column-left': ''">
                <ng-template ngx-datatable-header-template>
                  <ng-container  *ngIf="col.frozenLeft">{{col.name}}</ng-container>
                  <span *ngIf="!col.frozenLeft">{{col.name}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <ng-container  *ngIf="col.frozenLeft">{{value}}</ng-container>
                  <span *ngIf="!col.frozenLeft">{{value}}</span>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </app-dashboard-accordion>

    <app-dashboard-accordion
        [title]="'LAST GAMES' | translate"
        [description]="'(All the consolidated figures from that player during the selected games)' | translate"
        [isOpen]="lastGamesAccordionOpen"
        (toggle)="onAccordionLastGamesToggle($event)"
    >
      <div *ngIf="lastGamesAccordionOpen" class="grid-col w-full" id="ind-lastgames">
        <div *ngIf="!core.isMobileDevice" style="display: flex; justify-content: space-between">
          <h2 style="visibility: hidden" class="select-label-right">{{ "Last Games" | translate }}</h2>
          <div style="width: 48%; display: flex; justify-content: end">
            <button
                (click)="personalizeColumnsClick()"
                class="personalize-cols-button
              focus:outline-none focus:ring focus:border-blue-400
              active:bg-blue-800
              rounded-xl
              capitalize px-8 py-3
            "
            >
              {{ "Personalize Columns" | translate }}
            </button>
            <button
                *ngIf="
              (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('view_game')
              && (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('scouting')
              && (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('dashboard')
              && (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('create_game')
              && (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('manage_teams')"
                (click)="onDownloadLastGamesCSVClick()"
                class="personalize-cols-button rounded-xl px-8 py-3"
            >
              {{ "Download CSV" | translate }}
            </button>
          </div>
        </div>
        <h2 *ngIf="core.isMobileDevice" class="select-label">{{ "Last Games" | translate }}</h2>

        <hr class="separator">

        <div *ngIf="core.isMobileDevice" class="expand-toggle">
          <ion-toggle (ionChange)="onExpandLastGamesTableColumnsClick()"></ion-toggle>
        </div>

        <div class="grid w-full mt-2">
          <ngx-datatable
              style="width: 100%; box-shadow: none"
              class="material w-full ml-1"
              [rows]="(individualAggregated$|async).playerPosition !== 'gk' ? (lastGames$ | async) : (gkLastGames$ | async)"
              [columnMode]="'force'"
              [limit]="100"
              rowHeight="auto"
              [scrollbarH]="true"
              [footerHeight]="50"
              [headerHeight]="50"
              [externalSorting]="true"
              (sort)="onSort($event, selectedPlayerPosition !== 'gk' ? 'lastGames$Last' : 'gkLastGames$Last', selectedPlayerPosition !== 'gk' ? 'lastGames$' : 'gkLastGames$')"
          >
            <ng-container *ngFor="let column of selectedLastGamesColumns">
              <ngx-datatable-column *ngIf="((column.prop === 'timePlayed' || column.prop === 'playedPoses') && selectedType === 'COMPLETE_MODE') || (column.prop !== 'timePlayed' && column.prop !== 'playedPoses')"
                                    [prop]="column.prop" [width]="column.width ? column.width : 50" [headerClass]="column.headerClass">
                <ng-template let-column="column" ngx-datatable-header-template>
                  {{ column.name | translate}}
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                <span *ngIf="column.prop === 'gameDate'">
                  <span *ngIf="value !== 'Total'">
                    {{value | date:'mediumDate'}}
                  </span>
                  <span *ngIf="value === 'Total'">
                    {{value}}
                  </span>
                </span>
                  <span *ngIf="column.prop === 'playerScore'">
                  {{ value | number: '1.0-1' }}
                </span>
                  <span *ngIf="column.prop === 'link'">
                  <a href="{{value}}">{{'Go to game' | translate}}</a>
                </span>
                  <span *ngIf="column.prop !== 'gameDate' && column.prop !== 'playerScore' && column.prop !== 'link'">
                   {{value}}
                </span>
                </ng-template>
              </ngx-datatable-column>
            </ng-container>
          </ngx-datatable>
        </div >
        <ng-template #gkLastGames>
          <div class="grid w-full mt-2">
            <ngx-datatable
                style="width: 100%; box-shadow: none"
                class="material w-full ml-1"
                [rows]="gkLastGames$ | async"
                [columnMode]="'force'"
                [limit]="100"
                rowHeight="auto"
                [scrollbarH]="true"
                [footerHeight]="50"
                [headerHeight]="50"
            >
              <ngx-datatable-column prop="gameDate" [width]="80" [headerClass]="'column-left'">
                <ng-template let-column="column" ngx-datatable-header-template>
                  {{'Game Date' | translate}}
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  {{value | date:'mediumDate'}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="teamsName" [width]="160" [headerClass]="'column-left'">
                <ng-template let-column="column" ngx-datatable-header-template>
                  {{'Teams Name' | translate}}
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  {{value}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="timePlayed" [width]="50"  *ngIf="selectedType === 'COMPLETE_MODE'">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'Time Played hh:mm:ss' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <span>{{value}}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="playedPoses" [width]="50" *ngIf="selectedType === 'COMPLETE_MODE'">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'Played Poses' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <span>{{value}}</span>
                </ng-template>
              </ngx-datatable-column> <ngx-datatable-column prop="possessionEvents" [width]="50">
              <ng-template let-column="column" ngx-datatable-header-template>
                <span>{{'Possession Events' | translate}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span>{{value}}</span>
              </ng-template>
            </ngx-datatable-column>
              <ngx-datatable-column prop="goals" [width]="50">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'Goals' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <span>{{value}}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="goalsReceived" [width]="50">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'Goals Received' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <span>{{value}}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="saves" [width]="50">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'Saves' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <span>{{value}}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="lostBalls" [width]="50">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'Lost Balls' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <span>{{value}}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="saveEff" [width]="50">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'Save Eff' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <span>{{value}}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="playerScore" [width]="50">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'Player Score' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <span>{{ value | number: '1.0-1' }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="link" [width]="80">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'Link to the Game' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <span><a href="{{value}}">{{'Go to game' | translate}}</a></span>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div >
        </ng-template>

      </div>
    </app-dashboard-accordion>
    <app-dashboard-accordion
        [title]="'GAME SYSTEM' | translate"
        [description]="'(Which action/s are the most successful during the games)' | translate"
        [isOpen]="gameSystemAccordionOpen"
        (toggle)="onAccordionGameSystemToggle($event)"
        *ngIf="isGameSystemEnabled"
    >
      <div *ngIf="gameSystemAccordionOpen" class="grid-col w-full">
        <div class="grid-col w-full">
          <div *ngIf="core.isMobileDevice" class="expand-toggle">
            <ion-toggle (ionChange)="onExpandGameSystemTableColumnsClick()"></ion-toggle>
          </div>
          <div class="grid w-full mt-2">
            <ngx-datatable
                class="material"
                style="width: 100%; box-shadow: none"
                [rows]="gameSystemStatsView | async"
                [columns]="gameSystemColumns"
                rowHeight="auto"
                [scrollbarH]="true"
                [footerHeight]="50"
                [headerHeight]="50"
                [columnMode]="'force'"
                [externalSorting]="true"
                (sort)="onSort($event, 'gameSystemStatsViewData', 'gameSystemStatsView')"
            >
              <ngx-datatable-column prop="actionDisplayName"
                                    [frozenLeft]="true"
                                    [sortable]="true"
                                    [resizeable]="false"
                                    [cellClass]="[getRowClass, 'bold-column']"
                                    [headerClass]="'column-left'"
                                    width="160">
                <ng-template let-column="column" ngx-datatable-header-template>
                  {{'System' | translate}}
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template >
                  {{row.categoryName}} - {{row.actionDisplayName}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="numberOfActionsPlayed"
                                    width="70"
                                    [sortable]="true"
                                    [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'TIMES PLAYED' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template >
                <span>
                  {{value}}
                </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="numberOfGoals"
                                    width="70"
                                    [sortable]="true"
                                    [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'N° GOALS' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template >
                <span>
                  {{value}}
                </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="numberOfSaves"
                                    width="70"
                                    [sortable]="true"
                                    [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'N° SAVES' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template >
                <span>
                  {{value}}
                </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableAllColsShown" prop="numberOfPostOuts"
                                    width="70"
                                    [sortable]="true"
                                    [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'N° POST / OUT' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template >
                <span>
                  {{value}}
                </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableAllColsShown" prop="numberOfActionsPlayedWith7Meters"
                                    width="70"
                                    [sortable]="true"
                                    [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'N° 7 METERS' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template >
                <span>
                  {{value}}
                </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableAllColsShown" prop="numberOfFault"
                                    width="70"
                                    [sortable]="true"
                                    [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'N° FAULT'|translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template >
                <span>
                  {{value}}
                </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableAllColsShown" prop="numberOfSuspension"
                                    width="70"
                                    [sortable]="true"
                                    [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'N° SUSPENSION'|translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template >
                <span>
                  {{value}}
                </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableAllColsShown" prop="numberOfLostBall"
                                    width="70"
                                    [sortable]="true"
                                    [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'N° LOST BALL'|translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template >
                <span>
                  {{value}}
                </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableAllColsShown" prop="percentageGoals"
                                    width="70"
                                    [sortable]="true"
                                    [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'GOALS %' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template >
                <span>
                  {{value | number: '1.0-0'}} %
                </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableAllColsShown" prop="percentageNoGoals"
                                    width="70"
                                    [sortable]="true"
                                    [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{'NO GOALS %' | translate}}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template >
                <span>
                  {{value | number: '1.0-0'}} %
                </span>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </app-dashboard-accordion>
    <ionic-selectable id="columns" #selectableColumns item-content [searchPlaceholder]="'Search'|translate" [items]="lastGamesColumnsSorted" [isMultiple]="true"
                      itemValueField="prop" itemTextField="name" [(ngModel)]="selectedLastGamesColumns" (onChange)="onSelectColumns()" [canSearch]="true" [placeholder]="'Select Columns'|translate"
    >
    </ionic-selectable>
  </ng-container>
  <ng-template #headerTemplate></ng-template>
  <ng-template #valueTemplate let-ports="value">
    <div class="ionic-selectable-value-item">
      {{ports.length}}
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <ion-toolbar>
      <ion-item>
        <ion-button (click)="selectableColumns.confirm(); selectableColumns.close()" [color]="'secondary'" style="width: 100%;">{{'OK'}}</ion-button>
      </ion-item>
    </ion-toolbar>
  </ng-template>
  <ng-template #itemTemplate let-port="item" let-isPortSelected="isItemSelected">
    {{port.name ? port.name : ''}}
  </ng-template>
</div>
