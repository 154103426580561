import { GoalConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/goal/goal-consumer.service';
import { Observable } from 'rxjs';
import { OverviewConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/overview/overview-consumer.service';
import { TimelineConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/timeline/timeline-consumer.service';

export class QuickStatsStateHelper {

    private _quickStatsStateMap = new Map<string, Observable<any>>();

    constructor(
        private readonly goalConsumerService: GoalConsumerService,
        private readonly overviewConsumerService: OverviewConsumerService,
        private readonly timeLineConsumerService: TimelineConsumerService,
    ) {
        this._quickStatsStateMap.set('homeGoalPositionList', this.goalConsumerService.homeGoalPositionList$);
        this._quickStatsStateMap.set('visitorGoalPositionList', this.goalConsumerService.visitorGoalPositionList$);
        this._quickStatsStateMap.set('quickStatsViewModelList', this.overviewConsumerService.quickStatsViewModelList$);
        this._quickStatsStateMap.set('homeGoalsConnectionsPlPosModel', this.overviewConsumerService.homeConnectionModel$);
        this._quickStatsStateMap.set('visitorGoalsConnectionsPlPosModel', this.overviewConsumerService.visitorConnectionModel$);
        this._quickStatsStateMap.set('homeGoalCounter', this.goalConsumerService.homeTeamCounters$);
        this._quickStatsStateMap.set('visitorGoalCounter', this.goalConsumerService.visitorTeamCounters$);
        this._quickStatsStateMap.set('victoryPercentage', this.timeLineConsumerService.timeLineSemaphor$);
        this._quickStatsStateMap.set('timelineStatsAggregatedModel', this.overviewConsumerService.timelineStatsAggregatedModel$);
    }

    public getObservableByName(name: string): Observable<any> {
        return this._quickStatsStateMap.get(name);
    }

}
