/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GameFilterDto { 
    /**
     * game id
     */
    id: number;
    /**
     * folder id
     */
    folderId: number;
    /**
     * user id
     */
    accountEquipoId: number;
    /**
     * Home Team Name
     */
    home: string;
    /**
     * Visitor Team Name
     */
    visitor: string;
    /**
     * Home Team Id
     */
    homeId: number;
    /**
     * Visitor Team Id
     */
    visitorId: number;
    /**
     * Home Team Goals
     */
    goalsHome: number;
    /**
     * Visitor Team Goals
     */
    goalsVisitor: number;
    /**
     * Date of the Game
     */
    date: string;
    /**
     * Game share link hash
     */
    accessHash: string;
    /**
     * Url of the video
     */
    videoUrl?: string;
    /**
     * Delete flag
     */
    deleted: boolean;
    /**
     * Complete or Lite Mode
     */
    gameType: string;
    /**
     * Game status (scheduled, started, ended)
     */
    gameStatus: string;
    /**
     * Game hosted video status
     */
    videoStatus: string;
    /**
     * Live mode or video mode
     */
    gameMode: string;
    /**
     * UserId of person who tracked the game
     */
    trackedBy?: number;
}

