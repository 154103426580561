<div class="display-flex-space-between">
  <div>
    <div id="allSystemSection" *ngIf="selection">
      <div id="allSystemTitle">
        <div class="checkbox-wrapper" (click)="fireChanges('allSystem')">
          <ion-label class="label labelTitle">{{ 'All systems' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.allSystem"></ion-checkbox>
        </div>
        <div class="checkbox-wrapper" (click)="fireChanges('important')">
          <ion-label class="label labelTitle">{{ 'Show only Important' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.important"></ion-checkbox>
        </div>
      </div>
      <div class="checkboxList">
        <section id="equality">
          <div class="checkbox-wrapper" (click)="fireChanges('equality')">
            <ion-label class="label labelTitle">{{ 'All Equality' | translate }}</ion-label>
            <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.equality"></ion-checkbox>
          </div>
          <div *ngIf="availableOffenseSystem.includes('EQUALITY')" class="checkbox-wrapper" (click)="fireChanges('eqef')">
            <ion-label class="label">{{ 'Equality' | translate }}</ion-label>
            <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.eqef"></ion-checkbox>
          </div>
          <div *ngIf="availableOffenseSystem.includes('EQUALITY-5+1vs6')" class="checkbox-wrapper" (click)="fireChanges('eq51vs6')">
            <ion-label class="label">{{ 'EQ 5+1vs6' | translate }}</ion-label>
            <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.eq51vs6"></ion-checkbox>
          </div>
          <div *ngIf="availableOffenseSystem.includes('EQUALITY-6vs5+1')" class="checkbox-wrapper" (click)="fireChanges('eq6vs51')">
            <ion-label class="label">{{ 'EQ 6vs5+1' | translate }}</ion-label>
            <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.eq6vs51"></ion-checkbox>
          </div>
        </section>
        <section id="superiority">
          <div *ngIf="availableOffenseSystem.includes('SUPERIORITY-6vs5') || availableOffenseSystem.includes('SUPERIORITY-7vs6') ||
                      availableOffenseSystem.includes('SUPERIORITY-OTHER')"
               class="checkbox-wrapper" (click)="fireChanges('superiority')">
            <ion-label class="label labelTitle">{{ 'All Superiority' | translate }}</ion-label>
            <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.superiority"></ion-checkbox>
          </div>
          <div *ngIf="availableOffenseSystem.includes('SUPERIORITY-6vs5')" class="checkbox-wrapper" (click)="fireChanges('sup6vs5')">
            <ion-label class="label">{{ 'SUP 6vs5' | translate }}</ion-label>
            <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.sup6vs5"></ion-checkbox>
          </div>
          <div *ngIf="availableOffenseSystem.includes('SUPERIORITY-7vs6')" class="checkbox-wrapper" (click)="fireChanges('sup7vs6')">
            <ion-label class="label mr-1">{{ 'SUP 7vs6' | translate }}</ion-label>
            <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.sup7vs6"></ion-checkbox>
          </div>
          <div *ngIf="availableOffenseSystem.includes('SUPERIORITY-OTHER')" class="checkbox-wrapper" (click)="fireChanges('supOther')">
            <ion-label class="label">{{ 'SUP Other' | translate }}</ion-label>
            <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.supOther"></ion-checkbox>
          </div>
        </section>
        <section id="inferiority">
          <div *ngIf="availableOffenseSystem.includes('INFERIORITY-5vs6') || availableOffenseSystem.includes('INFERIORITY-6vs7') ||
                      availableOffenseSystem.includes('INFERIORITY-OTHER')" class="checkbox-wrapper" (click)="fireChanges('inferiority')">
            <ion-label class="label labelTitle">{{ 'All Inferiority' | translate }}</ion-label>
            <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.inferiority"></ion-checkbox>
          </div>
          <div *ngIf="availableOffenseSystem.includes('INFERIORITY-5vs6')" class="checkbox-wrapper" (click)="fireChanges('inf5vs6')">
            <ion-label class="label">{{ 'INF 5vs6' | translate }}</ion-label>
            <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.inf5vs6"></ion-checkbox>
          </div>
          <div *ngIf="availableOffenseSystem.includes('INFERIORITY-6vs7')" class="checkbox-wrapper" (click)="fireChanges('inf6vs7')">
            <ion-label class="label">{{ 'INF 6vs7' | translate }}</ion-label>
            <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.inf6vs7"></ion-checkbox>
          </div>
          <div *ngIf="availableOffenseSystem.includes('INFERIORITY-OTHER')" class="checkbox-wrapper" (click)="fireChanges('infOther')">
            <ion-label class="label">{{ 'INF Other' | translate }}</ion-label>
            <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.infOther"></ion-checkbox>
          </div>
        </section>
      </div>
    </div>

    <div class="checkboxList" *ngIf="selection">
      <section id="phases">
        <div class="checkbox-wrapper" (click)="fireChanges('allPhases')">
          <ion-label class="label labelTitle">{{ 'All phases' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.allPhases"></ion-checkbox>
        </div>
        <div *ngIf="availablePhases.includes('OFFENSE_POSITIONAL')" class="checkbox-wrapper" (click)="fireChanges('positionalOffense')">
          <ion-label class="label mr-1">{{ 'Offense Positional' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.positionalOffense"></ion-checkbox>
        </div>

        <div *ngIf="availablePhases.includes('FAST_BREAK')" class="checkbox-wrapper" (click)="fireChanges('fastBreak')">
          <ion-label class="label">{{ 'Fastbreak' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.fastBreak"></ion-checkbox>
        </div>

        <div *ngIf="availablePhases.includes('COUNTER_GOAL')" class="checkbox-wrapper" (click)="fireChanges('counterGoal')">
          <ion-label class="label">{{ 'Countergoal' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.counterGoal"></ion-checkbox>
        </div>
      </section>

      <section id="deffenseCheckbox">
        <div class="checkbox-wrapper" (click)="fireChanges('allDeffenseSystems')">
          <ion-label class="label labelTitle">{{ 'Deffense System' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.allDeffenseSystems"></ion-checkbox>
        </div>
        <div *ngIf="availableDefenseSystem.includes('6:0')" class="checkbox-wrapper" (click)="fireChanges('against60')">
          <ion-label class="label">{{ 'Against 6:0' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.against60"></ion-checkbox>
        </div>
        <div *ngIf="availableDefenseSystem.includes('5:1')" class="checkbox-wrapper" (click)="fireChanges('against51')">
          <ion-label class="label">{{ 'Against 5:1' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.against51"></ion-checkbox>
        </div>
        <div *ngIf="availableDefenseSystem.includes('3:2:1')" class="checkbox-wrapper" (click)="fireChanges('against321')">
          <ion-label class="label">{{ 'Against 3:2:1' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.against321"></ion-checkbox>
        </div>
        <div *ngIf="availableDefenseSystem.includes('4:2')" class="checkbox-wrapper" (click)="fireChanges('against42')">
          <ion-label class="label">{{ 'Against 4:2' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.against42"></ion-checkbox>
        </div>
        <div *ngIf="availableDefenseSystem.includes('OTHER')" class="checkbox-wrapper" (click)="fireChanges('againstOthers')">
          <ion-label class="label">{{ 'Others' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selection.againstOthers"></ion-checkbox>
        </div>
      </section>
    </div>
  </div>

  <div class="checkboxEventsList" *ngIf="showOffenseDefenseFilter">
    <section id="offense">
      <div class="checkbox-wrapper" (click)="fireOffenseChanges('allOffenseEvents')">
        <ion-label class="label labelTitle">{{ 'All Offense Events' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionOffenseEvents.allOffenseEvents"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('ATTACK_FAULT_COMMIT')" class="checkbox-wrapper" (click)="fireOffenseChanges('totalAttackFaultCommit')">
        <ion-label class="label">{{ 'Attack Fault Commit' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionOffenseEvents.totalAttackFaultCommit"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('TECHNICAL_MISTAKE_COMMIT')" class="checkbox-wrapper" (click)="fireOffenseChanges('totalTechnicalMistakeCommit')">
        <ion-label class="label">{{ 'Technical Mistake Commit' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionOffenseEvents.totalTechnicalMistakeCommit"></ion-checkbox>
      </div>

      <div *ngIf="availableEvents.includes('LOST_BALL')" class="checkbox-wrapper" (click)="fireOffenseChanges('totalLostBall')">
        <ion-label class="label">{{ 'Lost Balls Commit' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionOffenseEvents.totalLostBall"></ion-checkbox>
      </div>

      <div *ngIf="availableEvents.includes('2MIN_PROVOKE')" class="checkbox-wrapper" (click)="fireOffenseChanges('totalTwoMinProvoke')">
        <ion-label class="label">{{ '2 Minutes Provoke' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionOffenseEvents.totalTwoMinProvoke"></ion-checkbox>
      </div>

      <div *ngIf="availableEvents.includes('7M_PROVOKE')" class="checkbox-wrapper" (click)="fireOffenseChanges('totalSevenMetersProvoke')">
        <ion-label class="label">{{ '7 Meters Provoke' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionOffenseEvents.totalSevenMetersProvoke"></ion-checkbox>
      </div>

      <div *ngIf="availableEvents.includes('7M_PROVOKE_SUSPENSION')" class="checkbox-wrapper" (click)="fireOffenseChanges('totalSevenMSuspProvoke')">
        <ion-label class="label">{{ '7 Meters Provoke + Suspensions' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionOffenseEvents.totalSevenMSuspProvoke"></ion-checkbox>
      </div>

      <div *ngIf="availableEvents.includes('1-1_WON')" class="checkbox-wrapper" (click)="fireOffenseChanges('totalOneAndOneWon')">
        <ion-label class="label">{{ '1 & 1 Won' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionOffenseEvents.totalOneAndOneWon"></ion-checkbox>
      </div>

      <div *ngIf="availableEvents.includes('FOUL_RECEIVE')" class="checkbox-wrapper" (click)="fireOffenseChanges('totalFoulReceive')">
        <ion-label class="label">{{ 'Fault Receive' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionOffenseEvents.totalFoulReceive"></ion-checkbox>
      </div>

      <div *ngIf="availableEvents.includes('SHOT_BLOCKED')" class="checkbox-wrapper" (click)="fireOffenseChanges('totalShotBlocked')">
        <ion-label class="label">{{ 'Shot Blocked' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionOffenseEvents.totalShotBlocked"></ion-checkbox>
      </div>

      <div *ngIf="availableEvents.includes('GOAL')" class="checkbox-wrapper" (click)="fireOffenseChanges('totalGoals')">
        <ion-label class="label">{{ 'Goals' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionOffenseEvents.totalGoals"></ion-checkbox>
      </div>

      <div *ngIf="availableEvents.includes('POST_OUT')" class="checkbox-wrapper" (click)="fireOffenseChanges('totalPostOut')">
        <ion-label class="label">{{ 'Post/Out Commited' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionOffenseEvents.totalPostOut"></ion-checkbox>
      </div>

      <div *ngIf="availableEvents.includes('SAVE')" class="checkbox-wrapper" (click)="fireOffenseChanges('totalFailedShots')">
        <ion-label class="label">{{ 'Failed Shots' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionOffenseEvents.totalFailedShots"></ion-checkbox>
      </div>

      <div class="toggle-wrapper">
        <ion-toggle [checked]="showTimeEvents" (ionChange)="onToggleShowTimeEvents()"></ion-toggle>
        <ion-label style="margin-left: 10px" class="label">{{ 'Time Events' | translate }}</ion-label>
      </div>

      <ng-container *ngIf="showTimeEvents">
        <div *ngIf="availableEvents.includes('FIRST_HALF_START')" class="checkbox-wrapper" (click)="fireTimeEventsChanges('firstHalfStart')">
          <ion-label class="label">{{ 'First Half Start' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionTimeEvents.firstHalfStart"></ion-checkbox>
        </div>

        <div *ngIf="availableEvents.includes('FIRST_HALF_END')" class="checkbox-wrapper" (click)="fireTimeEventsChanges('firstHalfEnd')">
          <ion-label class="label">{{ 'First Half End' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionTimeEvents.firstHalfEnd"></ion-checkbox>
        </div>

        <div *ngIf="availableEvents.includes('FIRST_HALF_END')" class="checkbox-wrapper" (click)="fireTimeEventsChanges('secondHalfStart')">
          <ion-label class="label">{{ 'Second Half Start' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionTimeEvents.secondHalfStart"></ion-checkbox>
        </div>

        <div *ngIf="availableEvents.includes('SECOND_HALF_END')" class="checkbox-wrapper" (click)="fireTimeEventsChanges('secondHalfEnd')">
          <ion-label class="label">{{ 'Second Half End' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionTimeEvents.secondHalfEnd"></ion-checkbox>
        </div>

        <div *ngIf="availableEvents.includes('TIME_OUT')" class="checkbox-wrapper" (click)="fireTimeEventsChanges('timeOut')">
          <ion-label class="label">{{ 'Time Out' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionTimeEvents.timeOut"></ion-checkbox>
        </div>
      </ng-container>
    </section>

    <section id="defense">
      <!--<p>{{ 'Defense Events' | translate }}</p>-->
      <div class="checkbox-wrapper" (click)="fireDefenseChanges('allDefenseEvents')">
        <ion-label class="label labelTitle">{{ 'All Defense Events' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionDefenseEvents.allDefenseEvents"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('ATTACK_FAULT_COMMIT')" class="checkbox-wrapper" (click)="fireDefenseChanges('totalAttackFaultProvoke')">
        <ion-label class="label">{{ 'Attack Fault Provoke' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionDefenseEvents.totalAttackFaultProvoke"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('TECHNICAL_MISTAKE_COMMIT')" class="checkbox-wrapper" (click)="fireDefenseChanges('totalTechnicalMistakeProvoke')">
        <ion-label class="label">{{ 'Technical Mistake Provoke' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionDefenseEvents.totalTechnicalMistakeProvoke"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('LOST_BALL')" class="checkbox-wrapper" (click)="fireDefenseChanges('totalLostBallProvoke')">
        <ion-label class="label">{{ 'Lost Balls Provoke' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionDefenseEvents.totalLostBallProvoke"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('2MIN_PROVOKE')" class="checkbox-wrapper" (click)="fireDefenseChanges('totalTwoMinCommit')">
        <ion-label class="label">{{ '2 Minutes Commit' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionDefenseEvents.totalTwoMinCommit"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('7M_PROVOKE')" class="checkbox-wrapper" (click)="fireDefenseChanges('totalSevenMetersCommit')">
        <ion-label class="label">{{ '7 Meters Commit' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionDefenseEvents.totalSevenMetersCommit"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('7M_PROVOKE_SUSPENSION')" class="checkbox-wrapper" (click)="fireDefenseChanges('totalSevenMSuspCommit')">
        <ion-label class="label">{{ '7 Meters Commit + Suspensions' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionDefenseEvents.totalSevenMSuspCommit"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('1-1_WON')" class="checkbox-wrapper" (click)="fireDefenseChanges('totalOneAndOneLost')">
        <ion-label class="label">{{ '1 & 1 Lost' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionDefenseEvents.totalOneAndOneLost"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('FOUL_RECEIVE')" class="checkbox-wrapper" (click)="fireDefenseChanges('totalFoulCommit')">
        <ion-label class="label">{{ 'Fault Commit' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionDefenseEvents.totalFoulCommit"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('SHOT_BLOCKED')" class="checkbox-wrapper" (click)="fireDefenseChanges('totalShotBlock')">
        <ion-label class="label">{{ 'Block' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionDefenseEvents.totalShotBlock"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('GOAL')" class="checkbox-wrapper" (click)="fireDefenseChanges('totalReceivedGoals')">
        <ion-label class="label">{{ 'Goals Received' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionDefenseEvents.totalReceivedGoals"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('POST_OUT')" class="checkbox-wrapper" (click)="fireDefenseChanges('totalPostOutReceived')">
        <ion-label class="label">{{ 'Post/Out Received' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionDefenseEvents.totalPostOutReceived"></ion-checkbox>
      </div>
      <div *ngIf="availableEvents.includes('SAVE')" class="checkbox-wrapper" (click)="fireDefenseChanges('totalSaves')">
        <ion-label class="label">{{ 'Saves' | translate }}</ion-label>
        <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionDefenseEvents.totalSaves"></ion-checkbox>
      </div>

      <ng-container *ngIf="showTimeEvents">
        <div *ngIf="availableEvents.includes('EXTRA_TIME_ONE_START')" style="margin-top: 60px;" class="checkbox-wrapper" (click)="fireTimeEventsChanges('firstHalfETStart')">
          <ion-label class="label">{{ 'First Half ET Start' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionTimeEvents.firstHalfETStart"></ion-checkbox>
        </div>

        <div *ngIf="availableEvents.includes('EXTRA_TIME_ONE_START')" class="checkbox-wrapper" (click)="fireTimeEventsChanges('firstHalfETEnd')">
          <ion-label class="label">{{ 'First Half ET End' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionTimeEvents.firstHalfETEnd"></ion-checkbox>
        </div>

        <div *ngIf="availableEvents.includes('EXTRA_TIME_TWO_START')" class="checkbox-wrapper" (click)="fireTimeEventsChanges('secondHalfETStart')">
          <ion-label class="label">{{ 'Second Half ET Start' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionTimeEvents.secondHalfETStart"></ion-checkbox>
        </div>

        <div *ngIf="availableEvents.includes('EXTRA_TIME_TWO_END')" class="checkbox-wrapper" (click)="fireTimeEventsChanges('secondHalfETEnd')">
          <ion-label class="label">{{ 'Second Half ET End' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionTimeEvents.secondHalfETEnd"></ion-checkbox>
        </div>

        <div *ngIf="availableEvents.includes('PENALTIES_START')" class="checkbox-wrapper" (click)="fireTimeEventsChanges('penaltiesStart')">
          <ion-label class="label">{{ 'Penalties Start' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionTimeEvents.penaltiesStart"></ion-checkbox>
        </div>

        <div *ngIf="availableEvents.includes('PENALTIES_END')" class="checkbox-wrapper" (click)="fireTimeEventsChanges('penaltiesEnd')">
          <ion-label class="label">{{ 'Penalties End' | translate }}</ion-label>
          <ion-checkbox color="primary" (click)="$event.preventDefault()" [checked]="selectionTimeEvents.penaltiesEnd"></ion-checkbox>
        </div>
      </ng-container>
    </section>
  </div>

</div>
