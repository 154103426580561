/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateUserDto { 
    /**
     * user id
     */
    id: number;
    /**
     * E-Mail
     */
    email: string;
    /**
     * First name
     */
    firstName?: string;
    /**
     * Last name
     */
    lastName?: string;
    /**
     * Street Name
     */
    street?: string;
    /**
     * City
     */
    city?: string;
    /**
     * Postal Code
     */
    postalCode?: string;
    /**
     * Country
     */
    country?: string;
    /**
     * Accept newsletter
     */
    receiveNewsletter?: boolean;
    /**
     * Language Code
     */
    lang: string;
    /**
     * Number of expired accounts
     */
    numExpiredAccounts?: number;
    /**
     * Swap Field at Halftime
     */
    swapFieldHalftime?: boolean;
    /**
     * Receive end game notifications
     */
    endGameNotifications?: boolean;
    /**
     * Image URL
     */
    imageUrl?: string;
}

