import { Component, Input, OnInit } from '@angular/core';
import { QuickStatsTimelineModel } from '@handballai/stats-calculation';

@Component({
  selector: 'app-quick-stats-timeline',
  templateUrl: './quick-stats-timeline.component.html',
  styleUrls: ['./quick-stats-timeline.component.scss'],
})
export class QuickStatsTimelineComponent  implements OnInit {

  @Input() homeTeam: string;
  @Input() visitorTeam: string;

  public timelineStatsHome: QuickStatsTimelineModel;
  public timelineStatsVisitor: QuickStatsTimelineModel;

  @Input()
  set timelineCalculationStats(timelineStats: QuickStatsTimelineModel[]) {
    if (timelineStats?.length) {
      this.timelineStatsHome = timelineStats[0];
      this.timelineStatsVisitor =  timelineStats[1];
    }
  }


  public currentHalf = 'first';

  constructor() { }

  ngOnInit() {
  }

  calculateBarWidth(value: number, possessions: number): string {
    return ((value / possessions) * 100) + '%';
  }

  onFirstHalfClick() {
    this.currentHalf = 'first';
  }

  onSecondHalf() {
    this.currentHalf = 'second';
  }

}
