import { Pipe, PipeTransform } from '@angular/core';
import { CounterModel } from '@handballai/stats-calculation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'shotTransform'
})
export class ShotTransformPipe implements PipeTransform {

  transform(value: CounterModel[], ...args: unknown[]): CounterModel[] {
    return value.map(
      co => co.counterType.includes('_G_')
        ? new CounterModel(`${co.counterType}X`, co.counter)
        : new CounterModel(`${co.counterType}Y`, co.counter)
    );
  }

}
