/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GameListFilterDto { 
    /**
     * homeTeamName
     */
    homeTeam: string;
    /**
     * visitorTeamName
     */
    visitorTeam: string;
    /**
     * goalsHome
     */
    goalsHome: number;
    /**
     * goalsVisitor
     */
    goalsVisitor: number;
    /**
     * gameId
     */
    gameId: number;
    /**
     * Date of the Game
     */
    date: string;
}

