import { TeamStatsPlayerModel } from '@handballai/stats-calculation';
import { TGameSystemPbp } from 'src/app/shared-services/game-system/game-system.model';

export const transformGameOpening = (gameOpening: string): TGameSystemPbp[] => {
    const gs = JSON.parse(gameOpening);
    if (Array.isArray(gs)) {
        return gs;
    } else {
        return [gs];
    }
};

export const mapGameSystemToString = (gs: TGameSystemPbp): string => `${gs.categoryName}:${gs.actionDisplayName}`;

export const getBackNumbersForTeam = (players: TeamStatsPlayerModel[]): string => {
    return players.map(p => p.backNumber).join(', ');
  }

export const transformGameSystem = (serializedGameSystem: string): string => {
    return serializedGameSystem ? transformGameOpening(serializedGameSystem)
        .map(gs => mapGameSystemToString(gs)).join(', ') : '';
}
