import { TimerModel } from './timer-model';
import { DateTime } from 'luxon';
import { PlayByPlayModel } from '@handballai/stats-calculation';
import { getHomeTeam, getVisitorTeam } from 'src/app/shared-services/download/csv-export.helper';
import { TGameSystemPbp } from 'src/app/shared-services/game-system/game-system.model';
import { mapGameSystemToString, transformGameOpening } from 'src/app/shared-services/helper/game-system.helper';

export const calculateNextTimerTick = (timerModel: TimerModel, isGameRunning: boolean): TimerModel => {
    let sec: number, min: number, counterSecSinceStart: number;
    if (isGameRunning) {
        sec = timerModel.seconds === 59 ? 0 : timerModel.seconds + 1;
        min = sec === 0 ? timerModel.minutes + 1 : timerModel.minutes;
        counterSecSinceStart = timerModel.counterSecSinceStart + 1;
    } else {
        sec = timerModel.seconds;
        min = timerModel.minutes;
        counterSecSinceStart = timerModel.counterSecSinceStart;
    }

    return {
        seconds: sec,
        minutes: min,
        counterSecSinceStart: counterSecSinceStart,
        gameCounter: `${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`,
        halfTime: timerModel.halfTime,
        wallClock: DateTime.now()
    };
};

export const formatDateTimeForCsv = (start: DateTime, end: DateTime): string => {
    if (start >= end) {
        return '00:00:00';
    }
    const dur = end
        .diff(start, ['hours', 'minutes', 'seconds', 'milliseconds']).toObject();

    return `${dur.hours.toString().padStart(2, '0')}:` +
        `${dur.minutes.toString().padStart(2, '0')}:` +
        `${dur.seconds.toString().padStart(2, '0')}`;
};

export const formatDateTimeForCsv2 = (start: DateTime, end: DateTime): string => {
    const startEpoc = Math.floor(start.toSeconds());
    const endEpoc = Math.floor(end.toSeconds());
    if (startEpoc >= endEpoc) {
        return '00:00:00';
    }
    const diff = endEpoc - startEpoc;
    const hours = Math.floor(diff / 3600);
    const remainingSecFromHours = diff - (hours * 3600);
    const minutes = Math.floor(remainingSecFromHours / 60);
    const sec = remainingSecFromHours - (minutes * 60);

    return `${hours.toString().padStart(2, '0')}:` +
        `${minutes.toString().padStart(2, '0')}:` +
        `${sec.toString().padStart(2, '0')}`;

};

export const formatDateTimeForDiffInSeconds = (
    startSeconds: number,
    endSeconds: number
): string => {
    startSeconds = Math.round(startSeconds);
    endSeconds = Math.round(endSeconds);
    if (startSeconds >= endSeconds) {
        return '00:00:00';
    }
    const diff = endSeconds - startSeconds;
    const hours = Math.floor(diff / 3600);
    const remainingSecFromHours = diff - (hours * 3600);
    const minutes = Math.floor(remainingSecFromHours / 60);
    const sec = remainingSecFromHours - (minutes * 60);

    return `${hours.toString().padStart(2, '0')}:` +
        `${minutes.toString().padStart(2, '0')}:` +
        `${sec.toString().padStart(2, '0')}`;
};

export type CsvRowFn = (
    fileContent: string,
    curr: Partial<PlayByPlayModel>,
    index: number,
    orig: PlayByPlayModel[]
) => string;

export const csvRowFactoryFn = (
    gameDescription: string,
    offsetTimeSec: number,
): CsvRowFn => (
    fileContent: string,
    curr: Partial<PlayByPlayModel>,
    index: number,
    orig: PlayByPlayModel[]
) => {
    fileContent = fileContent + `${gameDescription}\t `;
    if (index === 0) {
        fileContent = fileContent + `${formatDateTimeForDiffInSeconds(0, offsetTimeSec)}\t `;
        fileContent = fileContent + `${formatDateTimeForDiffInSeconds(0, curr.eventTime.secondsSinceStartOfGame)}\t `;
    } else {
        fileContent = fileContent +
            `${formatDateTimeForDiffInSeconds(0, orig[index - 1].eventTime.secondsSinceStartOfGame)}\t `;
        fileContent = fileContent + `${formatDateTimeForDiffInSeconds(0, curr.eventTime.secondsSinceStartOfGame)}\t `;
    }
    fileContent = fileContent + `${curr.eventTime.halftime}\t `;
    fileContent = fileContent + `${curr.teamName}\t `;
    fileContent = fileContent + `${curr.event}\t `;
    fileContent = fileContent + `${curr.phase}\t `;
    fileContent = fileContent + `${curr.offenseSystem}\t `;
    fileContent = fileContent + `${curr.defenseSystem}\t `;
    fileContent = fileContent + `${curr.assistantPlayer ? curr.assistantPlayer.name : ''}\t `;
    fileContent = fileContent + `${curr.offensePlayer ? curr.offensePlayer.backNumber : ''}\t `;
    fileContent = fileContent + `${curr.offensePlayer ? curr.offensePlayer.name : ''}\t `;
    fileContent = fileContent + `${curr.offensePlayer ? curr.offensePlayer.position : ''}\t `;
    fileContent = fileContent + `${curr.defensePlayer ? curr.defensePlayer.backNumber : ''}\t `;
    fileContent = fileContent + `${curr.defensePlayer ? curr.defensePlayer.name : ''}\t `;
    fileContent = fileContent + `${curr.defensePlayer ? curr.defensePlayer.position : ''}\t `;
    fileContent = fileContent + `${curr.executionPosition ? curr.executionPosition : ''}\t `;
    fileContent = fileContent + `${curr.shotLocation ? curr.shotLocation : ''}\t `;
    fileContent = fileContent + `${getHomeTeam(curr.teamMarker, curr.offense, curr.defense)}\t `;
    fileContent = fileContent + `${getVisitorTeam(curr.teamMarker, curr.offense, curr.defense)}\t `;
    fileContent = fileContent + `${curr.possessions}\t`;
    fileContent = fileContent + `${curr.important}\t`;
    fileContent = fileContent + `${curr.gameSystem ? transformGameOpening(curr.gameSystem)
        .map(gs => mapGameSystemToString(gs)).join(', ') : ''}\n`;
    return fileContent;
};
