import { UiBaseCommand, UiCommandExecutionContext } from 'src/app/shared-services/ui-event-dispatcher/commands/ui-base-command';
import {
    DoubleEventSuspensionToBenchSavedEvent, DoubleEventSuspensionToBenchSavedLiteEvent, PlayerSelectEvent, SuspensionSelectedEvent,
    SuspensionToBenchSelectedEvent, TimeoutEvent
} from 'src/app/shared-services/ui-event-dispatcher/events/ui-events';
import {
    eventChainFinished,
    extractTeamFromMarker,
    handlePenalty
} from 'src/app/shared-services/ui-event-dispatcher/commands/command-helper';
import {
    DoubleEventPopoverContainerComponent
} from 'src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/double-event-popover/double-event-popover-container.component';
import { PlayerEvent, SuspensionActionTypes } from '@handballai/stats-calculation';
import {
    PlayerSuspensionUndoEvent,
    PlayerSuspensionUndoLiteEvent, TimeoutUndoEvent
} from 'src/app/shared-services/ui-event-dispatcher/buffer/event-buffer.model';
import {
    LiteDoubleEventPopoverContainerComponent
} from 'src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/lite-double-event-popover/lite-double-event-popover-container.component';
import { SuspensionToBenchActionTypes } from 'src/app/shared-services/actions/action-types';
import { GameDataService } from '../../game-data/game-data.service';

const isGameSystemEnabled = (gameDataService: GameDataService): string =>
    gameDataService.gameSystemEnabled$.value && gameDataService.gameSystems$.value.length > 0
        ? ' game-system'
        : '';

const transformSuspendToBenchToPlayerSuspensionEvent = (suspensionToBenchAction: SuspensionToBenchActionTypes): SuspensionActionTypes => {
    if (suspensionToBenchAction === 'SUSPENSION_TO_BENCH_2_MIN') {
        return '2_MIN';
    } else if (suspensionToBenchAction === 'SUSPENSION_TO_BENCH_RED_CARD') {
        return '2_MIN'; // RED CARD
    } else {
        return '2_MIN'; // BLUE CARD
    }
};

export class SuspensionToBenchCommand implements UiBaseCommand<SuspensionToBenchSelectedEvent> {
    public async execute(actualEvent: SuspensionToBenchSelectedEvent, eventContext: UiCommandExecutionContext): Promise<void> {
        eventContext.logger.debug('SuspensionToBenchCommand.execute: ', actualEvent);
        await eventContext.eventBufferService.openTransaction();
        eventContext.actionService.enableAllButtons();
        const teamMarker = eventContext.eventDispatcher.extraSelectEvent.payload.teamMarker;
        const selectedTeam = teamMarker === 'HOME'
            ? extractTeamFromMarker('HOME', eventContext.gameService.gameModel)
            : extractTeamFromMarker('VISITOR', eventContext.gameService.gameModel);

        if (actualEvent.payload.suspensionSelected === 'SUSPENSION_TO_BENCH_YELLOW_CARD') {
            eventContext.eventBufferService.addPlayByPlayEvent(
                teamMarker,
                {
                    eventType: 'SUSPENSION_TO_BENCH_YELLOW_CARD',
                    eventTime: actualEvent.timestamp,
                    phase: 'OFFENSE_POSITIONAL',
                } as PlayerEvent,
                teamMarker === 'HOME'
                    ? eventContext.gameService.gameModel.home.offenseSystem
                    : eventContext.gameService.gameModel.visitor.offenseSystem,
            );

            eventContext.eventBufferService.closeTransaction();

            eventChainFinished(
                eventContext.eventDispatcher,
                eventContext.actionService,
                eventContext.gameService
            );
            return;
        }

        let popover: HTMLIonPopoverElement;
        if (eventContext.eventDispatcher.extraSelectEvent.payload.gameType === 'COMPLETE_MODE') {
            popover = await eventContext.popoverController.create({
                component: DoubleEventPopoverContainerComponent,
                cssClass: 'global__popover global__popover--doubleEvent',
                // translucent: true,
                backdropDismiss: false,
                componentProps: {
                    requirePlayerSelect: true,
                    isSuspensionToTheBench: true,
                    fieldPlayers: selectedTeam.currentField,
                    opponentTeam: selectedTeam,
                    teamMarker: teamMarker,
                    sourceSuspensionEvent: actualEvent.payload.suspensionSelected,
                    videoSrc: actualEvent.payload.videoSrc,
                    videoCurrentTime: actualEvent.payload.videoCurrentTime
                }
            });
        } else {
            popover = await eventContext.popoverController.create({
                component: LiteDoubleEventPopoverContainerComponent,
                cssClass: 'global__popover global__popover--doubleEvent',
                // translucent: true,
                backdropDismiss: false,
                componentProps: {
                    requirePlayerSelect: true,
                    isSuspensionToTheBench: true,
                    fieldPlayers: selectedTeam.currentField,
                    opponentTeam: selectedTeam,
                    teamMarker: teamMarker,
                    sourceSuspensionEvent: actualEvent.payload.suspensionSelected,
                    videoSrc: actualEvent.payload.videoSrc,
                    videoCurrentTime: actualEvent.payload.videoCurrentTime
                }
            });
        }
        await popover.present();
    }
}

export class DoubleEventSuspensionToBenchSavedCommand implements UiBaseCommand<DoubleEventSuspensionToBenchSavedEvent> {
    public async execute(actualEvent: DoubleEventSuspensionToBenchSavedEvent, eventContext: UiCommandExecutionContext): Promise<void> {
        eventContext.logger.debug('DoubleEventSuspensionToBenchSavedCommand: ', actualEvent);

        const teamMarker = eventContext.eventDispatcher.extraSelectEvent.payload.teamMarker;
        const eventTime = eventContext.eventDispatcher.extraSelectEvent.timestamp;
        const offensePlayer = actualEvent.payload.suspendedPlayer;
        eventContext.eventBufferService.addPlayByPlayEvent(
            teamMarker,
            {
                eventType: actualEvent.payload.suspensionSelected,
                eventTime: eventTime,
                phase: 'OFFENSE_POSITIONAL',
            } as PlayerEvent,
            teamMarker === 'HOME'
            ? eventContext.gameService.gameModel.home.offenseSystem
                : eventContext.gameService.gameModel.visitor.offenseSystem,
            offensePlayer,
            undefined,
            undefined,
            actualEvent.payload.executionPosition,
        );

        const playerSuspensionEvent = transformSuspendToBenchToPlayerSuspensionEvent(actualEvent.payload.suspensionSelected);
        const suspensionSelectedEvent = new SuspensionSelectedEvent({
            suspensionSelected: playerSuspensionEvent
        });
        const selectedPlayerEvent = new PlayerSelectEvent(
            {
                playerModel: actualEvent.payload.suspendedPlayer,
                teamMarker: teamMarker
            },
            eventTime
        );
        handlePenalty(suspensionSelectedEvent, eventContext, selectedPlayerEvent);

        eventContext.eventBufferService.addUndoEvent(new PlayerSuspensionUndoEvent({
            teamMarker: teamMarker,
            playerBeforeInGame: offensePlayer,
            suspension: playerSuspensionEvent
        }));
        eventContext.eventBufferService.addPlayTimeEvent(
            offensePlayer.id,
            'PLAY_TIME_END',
            eventTime
        );

        eventContext.eventBufferService.closeTransaction();

        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class DoubleEventSuspensionToBenchSavedLiteCommand implements UiBaseCommand<DoubleEventSuspensionToBenchSavedLiteEvent> {
    public async execute(actualEvent: DoubleEventSuspensionToBenchSavedLiteEvent, eventContext: UiCommandExecutionContext): Promise<void> {
        eventContext.logger.debug('DoubleEventSuspensionToBenchSavedLiteCommand: ', actualEvent);
        const teamMarker = eventContext.eventDispatcher.extraSelectEvent.payload.teamMarker;
        const eventTime = eventContext.eventDispatcher.extraSelectEvent.timestamp;
        const offensePlayer = actualEvent.payload.suspendedPlayer;

        eventContext.eventBufferService.addPlayByPlayEvent(
            teamMarker,
            {
                eventType: actualEvent.payload.suspensionSelected,
                eventTime: eventTime,
                phase: 'OFFENSE_POSITIONAL',
            } as PlayerEvent,
            teamMarker === 'HOME'
                ? eventContext.gameService.gameModel.home.offenseSystem
                : eventContext.gameService.gameModel.visitor.offenseSystem,
            offensePlayer,
            undefined,
            undefined,
            actualEvent.payload.executionPosition
        );

        const playerSuspensionEvent = transformSuspendToBenchToPlayerSuspensionEvent(actualEvent.payload.suspensionSelected);

        if (teamMarker === 'HOME') {
            eventContext.gameService.putPlayerOnBenchHomeLite(
                offensePlayer,
                playerSuspensionEvent
            );
        } else {
            eventContext.gameService.putPlayerOnBenchVisitorLite(
                offensePlayer,
                playerSuspensionEvent
            );
        }

        eventContext.eventBufferService.addUndoEvent(new PlayerSuspensionUndoLiteEvent({
            suspension: playerSuspensionEvent,
            teamMarker: teamMarker,
            playerBeforeInGame: offensePlayer
        }));

        eventContext.eventBufferService.closeTransaction();
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class TimeoutEventCommand implements UiBaseCommand<TimeoutEvent> {
    async execute(actualEvent: TimeoutEvent, eventContext: UiCommandExecutionContext): Promise<void> {
        eventContext.logger.debug('TimeoutEventCommand: ', actualEvent);
        await eventContext.eventBufferService.openTransaction();

        const timeoutEvent = actualEvent.payload.timeOutEvent;
        const timestamp = actualEvent.timestamp;

        eventContext.eventBufferService.addPlayByPlayEvent(
            timeoutEvent.teamMarker,
            {
                eventType: 'TIME_OUT',
                eventTime: timestamp,
                phase: 'OFFENSE_POSITIONAL',
            } as PlayerEvent,
            timeoutEvent.teamMarker === 'HOME'
                ? eventContext.gameService.gameModel.home.offenseSystem
                : eventContext.gameService.gameModel.visitor.offenseSystem,
        );

        eventContext.eventBufferService.addUndoEvent(new TimeoutUndoEvent({
            timeOutEvent: timeoutEvent
        }));

        eventContext.eventBufferService.closeTransaction();
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );

    }
}
