import { CounterModel } from '@handballai/stats-calculation';
import { PossessionCounterWithFoulsModel } from '@handballai/stats-calculation';
import { PossessionCounterModel } from '@handballai/stats-calculation';

export const populateCounterByTypes = (
    types: string[],
    initialMap = new Map<string, CounterModel>()
): Map<string, CounterModel> => types.reduce((counterMap, t) => {
    counterMap.set(t, new CounterModel(t));
    return counterMap;
}, initialMap);

export const populatePossessionsCounterWithFoulsByTypes = (
    types: string[],
    initialMap = new Map<string, PossessionCounterWithFoulsModel>()
): Map<string, PossessionCounterWithFoulsModel> =>
    types.reduce((counterMap, t) => {
        counterMap.set(t, new PossessionCounterWithFoulsModel(t));
        return counterMap;
    }, initialMap);

export const populatePossCounterByTypes = (
    types: string[],
    initialMap = new Map<string, PossessionCounterModel>()
): Map<string, PossessionCounterModel> => types.reduce((counterMap, t) => {
    counterMap.set(t, new PossessionCounterModel(t));
    return counterMap;
}, initialMap);
