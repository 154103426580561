
<h2 class="select-label">
    {{ "TEAM STATS" | translate }}
</h2>
  <hr class="separator">
<div class="grid-col">
    <table>
        <thead>
            <tr>
                <th [ngClass]="{'single-label':true}"><span>Stats</span></th>
                <th  [ngClass]="{'single-row':true}"><span>total</span></th>
            </tr>

        </thead>
        <tbody>
            <tr>
                <td class="label" [ngClass]="{'single-label':true}">{{'OFFENSE POSITIONAL %'|translate}}</td>
                <td class="value" [ngClass]="{'single-row':true}">{{ calculatePercentageItem(quickOverviewModel?.offensePositionalGoals, quickOverviewModel?.offensePositionalEffectivePossession)| number: '1.0-1' }} %</td> <!---->
            </tr>
            <tr>
                <td class="label" [ngClass]="{'single-label':true}">{{'COUNTER GOAL %'|translate}}</td>
                <td class="value" [ngClass]="{'single-row':true}">{{ calculatePercentageItem(quickOverviewModel?.counterGoalGoals, quickOverviewModel?.counterGoalEffectivePossession) | number: '1.0-1' }} %</td>
            </tr>
            <tr>
                <td class="label" [ngClass]="{'single-label':true}">{{'FASTBREAK %'|translate}}</td>
                <td class="value" [ngClass]="{'single-row':true}">{{ calculatePercentageItem(quickOverviewModel?.fastBreakGoals, quickOverviewModel?.fastBreakEffectivePossession) | number: '1.0-1' }} %</td>
            </tr>
            <tr>
                <td class="label" [ngClass]="{'single-label':true}">{{'LOST BALLS'|translate}}</td>
                <td class="value" [ngClass]="{'single-row':true}">{{ quickOverviewModel?.totalLostBalls | number: '1.0-1' }}</td>
            </tr>
            <tr>
                <td class="label" [ngClass]="{'single-label':true}">{{'LOST BALLS %'|translate}}</td>
                <td class="value" [ngClass]="{'single-row':true}">{{ calculatePercentageItem(quickOverviewModel?.totalLostBalls, quickOverviewModel?.totalPossessions) | number: '1.0-1' }} %</td>
            </tr>
            <tr>
                <td class="label" [ngClass]="{'single-label':true}">{{'7M GOAL'|translate}}</td>
                <td class="value" [ngClass]="{'single-row':true}">{{ quick7MetersGoals | number: '1.0-1' }} </td>
            </tr>
            <tr><td class="label" [ngClass]="{'single-label':true}">{{'GOALS'|translate}}</td>
                <td class="value" [ngClass]="{'single-row':true}">{{ quickOverviewModel?.totalGoals | number: '1.0-1' }}</td>
            </tr>
            <tr>
                <td class="label" [ngClass]="{'single-label':true}">{{'SAVES'|translate}}</td>
                <td class="value" [ngClass]="{'single-row':true}">{{ quickOverviewModel?.totalSaves | number: '1.0-1' }}</td>
            </tr>
            <tr>
                <td class="label" [ngClass]="{'single-label':true}">{{'TEAM POSESSIONS'|translate}}</td>
                <td class="value" [ngClass]="{'single-row':true}">{{ calculateTotalPossesions(quickOverviewModel?.totalPossessions, quickOverviewModel?.offensePositionalPossession, quickOverviewModel?.fastBreakPossession, quickOverviewModel?.counterGoalPossession)}}</td>
            </tr>
            <tr>
                <td class="label" [ngClass]="{'single-label':true}">{{'SUPERIORITY %'|translate}}</td>
                <td class="value" [ngClass]="{'single-row':true}">{{ calculatePercentageItem(quickOverviewModel?.superiorityGoals, quickOverviewModel?.superiorityPossessions) | number: '1.0-1' }} %</td>
            </tr>
            <tr>
                <td class="label" [ngClass]="{'single-label':true}">{{'EQUALITY'|translate}}</td>
                <td class="value" [ngClass]="{'single-row':true}">{{ calculatePercentageItem(quickOverviewModel?.equalityGoals, quickOverviewModel?.equalityPossessions) | number: '1.0-1' }} %</td>
            </tr>
            <tr>
                <td class="label" [ngClass]="{'single-label':true}">{{'EFF. DEFFENSE %'|translate}}</td>
                <td class="value" [ngClass]="{'single-row':true}">{{ (100-calculatePercentageItem(quickOverviewModelOpponent?.totalGoals, quickOverviewModelOpponent?.totalPossessions)) | number: '1.0-1' }} %</td>
            </tr>
            <tr>
                <td class="label" [ngClass]="{'single-label':true}">{{'EFF. OFFENSE %'|translate}}</td>
                <td class="value" [ngClass]="{'single-row':true}">{{ calculatePercentageItem(quickOverviewModel?.totalGoals, quickOverviewModel?.totalPossessions) | number: '1.0-1' }} %</td>
            </tr>
        </tbody>

    </table>
</div>