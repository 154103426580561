import { Injectable } from "@angular/core";
import { SvgIconRegistry } from "@ngneat/svg-icon";

import { trackGame } from "src/assets/svg/track-game";
import { contact } from "src/assets/svg/contact";
import { dashboard } from "src/assets/svg/dashboard";
import { faqs } from "src/assets/svg/faqs";
import { gameSystem } from "src/assets/svg/game-system";
import { logout } from "src/assets/svg/logout";
import { manageTeams } from "src/assets/svg/manage-teams";
import { myOrganizations } from "src/assets/svg/my-orgfanizations";
import { newGame } from "src/assets/svg/new-game";
import { pastGames } from "src/assets/svg/past-games";
import { scheduleGame } from "src/assets/svg/schedule-game";
import { superAdmin } from "src/assets/svg/super-admin";
import { openMenu } from "src/assets/svg/open-menu";
import { closeMenu } from "src/assets/svg/close-menu";
import { nameIcon } from "src/assets/svg/name-icon";
import { pazzle } from "src/assets/svg/pazzle";
import { newTeam } from "src/assets/svg/new-team";
import { editTeam } from "src/assets/svg/edit-team";
import { managePlayers } from "src/assets/svg/manage-players";
import { link } from "src/assets/svg/link";
import { date } from "src/assets/svg/date";
import { allGames } from "src/assets/svg/all-games";
import { players } from '../../../assets/svg/players';

interface Icon {
  name: string;
  data: string;
}

@Injectable({ providedIn: "root" })
export class IconService {
  private _externalIcons: Icon[] = [
    { name: "track_game", data: trackGame },
    { name: "contact", data: contact },
    { name: "dashboard", data: dashboard },
    { name: "faqs", data: faqs },
    { name: "game_system", data: gameSystem },
    { name: "logout", data: logout },
    { name: "manage_teams", data: manageTeams },
    { name: "my_organizations", data: myOrganizations },
    { name: "new_game", data: newGame },
    { name: "past_games", data: pastGames },
    { name: "schedule_game", data: scheduleGame },
    { name: "super_admin", data: superAdmin },
    { name: "open_menu", data: openMenu },
    { name: "close_menu", data: closeMenu },
    { name: "name_icon", data: nameIcon },
    { name: "pazzle", data: pazzle },
    { name: "new_team", data: newTeam },
    { name: "edit_team", data: editTeam },
    { name: "manage_my_team", data: managePlayers },
    { name: "link", data: link },
    { name: "date", data: date },
    { name: "all_games", data: allGames },
    { name: "players", data: players }
  ];

  constructor(private registry: SvgIconRegistry) {}

  registerExternalIcons() {
    this.registry.register(this._externalIcons);
  }
}
