import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from '../../../../../shared-services/core.service';
import { PopoverController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyTeamFileDto, MyTeamFilesDto } from '../../../../../api/hai-players-api';
import { ItemReorderEventDetail } from '@ionic/angular';
import { VideoFolderPopupComponent } from '../video-popup/video-folder-popup.component';

@Component({
  selector: 'app-upload-files-rename-modal',
  templateUrl: './upload-files-rename-modal.component.html',
  styleUrls: ['./upload-files-rename-modal.component.scss'],
})
export class UploadFilesRenameModalComponent implements OnInit {

  public formGroup: FormGroup;
  @Input() files: any[] = [];
  filesDto: MyTeamFilesDto = { join: false, joinedVideoName: '', files: this.files };

  constructor(private readonly fb: FormBuilder,
              private readonly core: CoreService,
              private readonly popoverController: PopoverController
  ) { }

  ngOnInit() {
    this.initForm();
    this.assignOrderIdToFiles();
  }

  public handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    const fromIndex = ev.detail.from;
    const toIndex = ev.detail.to;

    const reorderedFiles = [...this.files];
    const [movedItem] = reorderedFiles.splice(fromIndex, 1); // Remove the item from the 'from' index
    reorderedFiles.splice(toIndex, 0, movedItem); // Insert the item at the 'to' index

    this.files = reorderedFiles;

    this.assignOrderIdToFiles();

    ev.detail.complete();
  }

  public assignOrderIdToFiles() {
    this.files.forEach((file, i) => file.orderId = i + 1);
  }

  public async onBackArrowClick() {
    await this.popoverController.dismiss({backArrow: true});
  }

  public async playSelectedVideo(video: MyTeamFileDto) {
    const popover = await this.popoverController.create({
      component: VideoFolderPopupComponent,
      cssClass: 'global__popover global__popover--game-system',
      backdropDismiss: false,
      componentProps: {
        src: video.s3Url,
        videoCurrentTime: 0,
      },
    });
    await popover.present();
  }

  public async onCancel(): Promise<void> {
    await this.popoverController.dismiss();
  }

  public async onConfirm(): Promise<void> {
    this.filesDto.files = this.files.map(file => ({ ...file, id: undefined }));
    await this.popoverController.dismiss(this.filesDto);
  }

  private initForm(): void {
    this.formGroup = this.fb.group({});
    this.files.forEach((file, index) => {
      this.formGroup.addControl('fileName' + index, this.fb.control(file.name, Validators.required));
    });
  }

  updateName(index: number, newName: string) {
    this.files[index].name = newName;
  }

}
