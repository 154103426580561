import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { SeasonDto } from 'src/app/api/hai-api';
import { CoreService } from 'src/app/shared-services/core.service';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
    selector: 'app-seasons-select',
    templateUrl: './seasons-select.component.html',
    styleUrls: ['./seasons-select.component.scss'],
    standalone: false
})
export class SeasonsSelectComponent implements OnInit, AfterViewInit {

  private seasonsData: SeasonDto|SeasonDto[];
  @ViewChild('itemTemplate', { static: true }) itemTemplate!: TemplateRef<any>;
  @ViewChild('valueTemplate', { static: true }) valueTemplate!: TemplateRef<any>;
  @ViewChild('footerTemplate', { static: true }) footerTemplate!: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate!: TemplateRef<any>;
  @ViewChild('seasonsSelect', { static: false }) seasonsSelect!: IonicSelectableComponent;
  @Input()
  multiple = true;

  @Input()
  set seasons(value: SeasonDto|SeasonDto[]) {
    if (this.seasonsData != value) {
      this.seasonsData = value;
      this.seasonsChange.emit(this.seasonsData);
    }
  }
  get seasons() { return this.seasonsData; }

  @Output()
  seasonsChange = new EventEmitter<SeasonDto|SeasonDto[]>();
  @Output()
  onChange = new EventEmitter<SeasonDto|SeasonDto[]>();

  seasons$: Observable<SeasonDto[]>;

  constructor(public core: CoreService) {
    this.seasons$ = core.seasonService.seasons$;
  }

  ngOnInit() {
    this.core.seasonService.seasons$?.subscribe(seasons => {
      if (seasons?.length === 1 && !this.seasonsData) {
        this.seasonsData = seasons;
        this.seasonsChange.emit(this.seasonsData);
      }
    });
  }

  public ngAfterViewInit() {
    if (this.seasonsSelect && this.valueTemplate && this.footerTemplate && this.itemTemplate && this.headerTemplate) {
      this.seasonsSelect.valueTemplate = this.valueTemplate;
      this.seasonsSelect.footerTemplate = this.footerTemplate;
      this.seasonsSelect.itemTemplate = this.itemTemplate;
      this.seasonsSelect.headerTemplate = this.headerTemplate;
    }
  }

  onSeasonSelect() {
    this.onChange.emit(this.seasonsData);
  }

  async changeCurrentSeason(newCurrentSeason: SeasonDto) {
    for (const s of this.core.seasonService.seasons$.getValue()) if (s.isCurrent) {
      await this.core.seasonService.updateSeason({...s, isCurrent: false});
    }
    await this.core.seasonService.updateSeason({...newCurrentSeason, isCurrent: true});
  }

}
