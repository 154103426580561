import { GameSystemEvents, IGameSystemCounterHolder } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-stats.model';

interface IGameSystemModel {
    categoryName: string;
    actionName: string;
    actionDisplayName: string;
    colorCode: string;
    numberOfActionsPlayed: number;
    numberOfFault: number;
    numberOfSuspension: number;
    numberOfLostBall: number;
    numberOfGoals: number;
    numberOfSaves: number;
    numberOfPostOuts: number;
    numberOfActionsPlayedWith7Meters: number;
}
export interface GameSystemStatsViewModel extends IGameSystemModel {
    percentageGoals: number;
    percentageNoGoals: number;
    percentage7MetersGoals: number;
}


export interface GameSystemStatsWrapper {
    teamName: string;
    gameSystemStatsView: GameSystemStatsViewModel[];
}

export interface GameSystemPlayerStatsViewModel extends GameSystemStatsViewModel {
    playerDescription?: string;
}

export interface GameSystemPlayerStatsWrapper {
    teamName: string;
    gameSystemPlayerStatsView: GameSystemPlayerStatsViewModel[];
}

export class GameSystemViewModelAggregationHelper implements IGameSystemModel {
    private keyMapper = new Map<GameSystemEvents, string>([
        ['FAULT', 'numberOfFault'],
        ['SUSPENSION', 'numberOfSuspension'],
        ['LOST_BALL', 'numberOfLostBall'],
        ['GOAL', 'numberOfGoals'],
        ['SAVE', 'numberOfSaves'],
        ['POST_OUT', 'numberOfPostOuts'],
        ['SEVEN_METERS_GOAL', 'numberOfGoals7Meters'],
        ['SEVEN_METERS_SAVE', 'numberOfSaves7Meters'],
        ['SEVEN_METERS_POST_OUT', 'numberOfPostOut7Meters'],
    ]);
    constructor(
        public categoryName: string,
        public actionName: string,
        public actionDisplayName: string,
        public colorCode: string,
        public numberOfActionsPlayed: number = 0,
        public numberOfFault: number = 0,
        public numberOfSuspension: number = 0,
        public numberOfLostBall: number = 0,
        public numberOfGoals: number = 0,
        public numberOfSaves: number = 0,
        public numberOfPostOuts: number = 0,
        public numberOfActionsPlayedWith7Meters: number = 0,
        public numberOfGoals7Meters: number = 0,
        public numberOfSaves7Meters: number = 0,
        public numberOfPostOut7Meters: number = 0,
    ) {}

    public addGameSystemEventKey(counterHolder: IGameSystemCounterHolder) {
        const event = counterHolder.gameSystemKey.eventType;
        const counterValue = counterHolder.counter.counter;
        this[this.keyMapper.get(event)] = this[this.keyMapper.get(event)] + counterValue;
        if (event.includes('SEVEN_METERS')) {
            this.numberOfActionsPlayedWith7Meters = this.numberOfActionsPlayedWith7Meters + counterValue;
        } else {
            this.numberOfActionsPlayed = this.numberOfActionsPlayed + counterValue;
        }
    }
}
