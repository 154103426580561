import { GameSystemPopupComponent } from "src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/game-system-popup/game-system-popup.component";
import { ShowGameSystemPopupEvent } from "../events/ui-events";
import { UiBaseCommand, UiCommandExecutionContext } from "./ui-base-command";

export class GameSystemPopupCommand implements UiBaseCommand<any> {

    async execute(actualEvent: ShowGameSystemPopupEvent, eventContext: UiCommandExecutionContext): Promise<void> {
        const popover = await eventContext.popoverController.create({
            component: GameSystemPopupComponent,
            cssClass: 'global__popover global__popover--game-system',
            // translucent: true,
            backdropDismiss: false,
            componentProps: {
                videoSrc: actualEvent.payload.src,
                videoCurrentTime: actualEvent.payload.videoCurrentTime
            }
        });
        await popover.present();
    }
}
