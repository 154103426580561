// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'beta',
  production: false,
  // appPath: 'http://localhost:4200', // ! important, leave commented on commit
  appPath: 'https://app.beta.handball.ai',
  // apiPath: 'https://api.handball.ai', // ! important, leave commented on commit
  // apiPath: 'http://localhost:3000', // ! important, leave commented on commit
  // apiPath: 'https://node2-mbp.local.arsa.dev', // ! important, leave commented on commit
  apiPath: 'https://api.beta.handball.ai',
  playersApiPath: 'https://players-api.beta.handball.ai',
  // playersApiPath: 'http://localhost:3003',
  // wssPath: 'http://localhost:3001', // ! important, leave commented on commit
  wssPath: 'https://realtime.beta.handball.ai',
  imageApiPath: 'https://images.beta.handball.ai',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
