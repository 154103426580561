import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { PlayerModel, TeamSearchModel } from "../model/team.model";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { ToastController } from "@ionic/angular";
import { loggerFn } from "../helper/http-helper";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { ExternalAccountEquiposService, TeamDto } from "../../api/hai-api";
import { convertTeamDtoToModel, convertTeamModelToDto } from "./team-helper";
import { CoreService } from "../core.service";
import { GameType } from 'src/app/shared-services/game/game-type.model';
import { TeamModel } from "@handballai/stats-calculation";

export function sortFn(a,b, prop: string = 'name') {
  if (a==b) return 0;
  return a[prop].toLowerCase().replace("á", "a").replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u") >
    b[prop].toLowerCase().replace("á", "a").replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u")
    ? 1
    : -1;
};

@Injectable({
  providedIn: "root",
})
export class TeamService {
  constructor(
    private readonly logger: NGXLogger,
    private readonly toastCntl: ToastController,
    private readonly externalAccountEquiposService: ExternalAccountEquiposService
  ) {}

  get team$(): BehaviorSubject<TeamModel> {
    return this._team$;
  }

  get teamSearchModel$(): BehaviorSubject<TeamSearchModel[]> {
    return this._teamSearchModel$;
  }
  public static HOME_TEAM_KEY = "HAI_HOME_TEAM";
  public static VISITOR_TEAM_KEY = "HAI_VISITOR_TEAM";
  private _core: CoreService;

  private _teamsMap = new Map<number, TeamModel>();
  private _team$ = new BehaviorSubject<TeamModel>(null);
  private _trackers$ = new BehaviorSubject<Array<TeamDto>>([]);
  private _teamSearchModel$ = new BehaviorSubject<TeamSearchModel[]>([]);
  private _players$ = new BehaviorSubject<PlayerModel[]>([]);
  private _teams$ = new BehaviorSubject<TeamModel[]>([]);
  private _isInitialized = false;
  private teamKey: string;
  private userId: number;
  initCore(core: CoreService) {
    this._core = core;
  }

  get teamsMap() {
    return this._teamsMap;
  }

  get teams$() {
    return this._teams$;
  }

  get trackers$(){
    return this._trackers$;
  }

  public getTeamByTeamId(internalId: number): TeamModel {
    return this._teamsMap.get(internalId);
  }

  public findTeamByTeamId(internalId: number): void {
    this.logger.debug("TeamService.findTeamByTeamId", internalId);
    this._team$.next({ ...this._teamsMap.get(internalId) });
  }

  public getSelectedTeamById(internalId: number): TeamModel {
    return this._teamsMap.get(internalId);
  }

  public getTeamByExternalId(externalId: number): TeamModel {
    return Array.from(this._teamsMap.values()).filter(
      (team) => team._id === externalId
    )[0];
  }

  public getAllTeamsForSearch(): void {
    this._teamSearchModel$.next(
      Array.from(this._teamsMap.values()).map(
        (team) =>
          ({
            internalTeamId: team.internalTeamId,
            imageUrl: team.shieldUrl,
            name: team.teamname,
          } as TeamSearchModel)
      )
    );
  }

  public findPlayersByTeamId(internalId: number): void {
    this._players$.next(this._teamsMap.get(internalId).players);
  }

  // this could be removed later - no longer needed...
  public async initModelFromStorage(): Promise<void> {
    if (this._isInitialized) {
      return;
    }
    const user = await this._core.storageService.getUserAsPromise();
    if (!user) {
      this.logger.fatal(
        "TeamService.initModelFromStorage: Something went horribly wrong, we have no user!"
      );
      return;
    }
    this.userId = user.id;
    this.teamKey = `team-storage-key-${user.id}`;
    const teamsMap = await this._core.storageService.storage.get(this.teamKey);
    if (!teamsMap) {
      this.logger.info(
        "TeamService.initModelFromStorage - First time entering the Coaching Zone for user: ",
        user.id,
        user.lastName
      );
      await this.syncTeamMap();
    } else {
      this._teamsMap = teamsMap;
      this._teams$.next(Array.from(teamsMap.values()));
      this.getAllTeamsForSearch();
    }
    this._isInitialized = true;
  }

  public async upsertTeam(team: TeamModel): Promise<void> {
    await this.upsertTeamExternal(team);
  }

  public async initializeModelFromRemote(userId: number = null): Promise<void> {
    // We will always do a fresh setup
    if (userId) this.userId = userId;
    this.teamKey = `team-storage-key-${userId}`;

    const aid =
      await this._core.storageService.getSelectedAccountEquipoIdAsPromise();

    return new Promise((ok, ko) => {
      this.externalAccountEquiposService
        .accountEquipoHandlerControllerGetTeams(
          this.userId.toString(),
          aid.toString()
        )
        .pipe(
          loggerFn(this.logger, "TeamService.initializeModelFromRemote"),
          map((teams) => teams.map((team) => convertTeamDtoToModel(team))),
          tap(async (teams: TeamModel[]) => {
            this._teamsMap = teams
              .filter((itm) => !itm.deleted)
              .reduce((accu, curr) => {
                accu.set(curr.internalTeamId, curr);
                return accu;
              }, new Map<number, TeamModel>());
            this._teams$.next(Array.from(this._teamsMap.values()));
            await this.syncTeamMap();
            this.getAllTeamsForSearch();
            const toast = await this.toastCntl.create({
              color: "success",
              icon: "checkmark-circle-outline",
              message: `Successfully loaded teams from server!`,
              duration: 1000,
            });
            await toast.present();
            ok();
          }),
          catchError((error) => {
            this.toastCntl
              .create({
                icon: 'close-outline',
                color: "danger",
                message: `A technical problem occurred while loading team, please try it later!`,
                duration: 10000,
              })
              .then((value) => value.present());
            ko(error);
            return throwError(error);
          })
        )
        .subscribe();
    });
  }

  public resetTeamModel(): void {
    this._team$.next(null);
    // Fix selection on coaching zone selected team
    this.getAllTeamsForSearch();
  }

  public validateTeams(teamIds: number[], gameMode: GameType): string[] {
    return teamIds.reduce((accu, curr) => {
      accu = [
        ...accu,
        ...this._core.teamValidationService.applyRules(
          this._teamsMap.get(curr), gameMode
        ),
      ];
      return accu;
    }, [] as string[]);
  }

  public hasTeams(): boolean {
    if (!this._teamsMap) {
      return false;
    } else if (this._teamsMap.size === 0) {
      return false;
    } else if (Object.keys(this._teamsMap.entries().next()).length === 0) {
      return false;
    }
    return true;
  }

  public async saveTeamForGame(key: string, internalId: number): Promise<void> {
    await this._core.storageService.storage.set(
      key,
      this._teamsMap.get(internalId)
    );
  }

  public async saveRandomTeamAsVisitor(): Promise<void> {
    //TODO: Ruben how to setup random team?
    // await this._core.storageService.storage.set(TeamsService.VISITOR_TEAM_KEY, {
    // init random team with...
    // } as TeamModel);
  }

  private getNextInternalId(): number {
    // TODO: Temporal workaround, if two calls are followed one by another the same value it's  triggered
    return Math.floor((Date.now() + Math.random() * 500) % 100000);
    return (
      Array.from(this._teamsMap.values()).reduce((accu, curr) => {
        if (curr.internalTeamId > accu) {
          accu = curr.internalTeamId;
        }
        return accu;
      }, 0) + 1
    );
  }

  private async syncTeamMap(): Promise<void> {
    await this._core.storageService.storage.set(this.teamKey, this._teamsMap);
  }

  private async upsertTeamExternal(team: TeamModel): Promise<void> {
    return new Promise<void>(async resolve => {
      let teamDto$: Observable<TeamDto>;
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      team.accountEquipoId = team.accountEquipoId ?? aid.toString();
      await this._core.loadingService.present();
      if (team.internalTeamId) {
        teamDto$ =
          this.externalAccountEquiposService.accountEquipoHandlerControllerUpdateTeam(
            this.userId.toString(),
            aid.toString(),
            team._id.toString(),
            convertTeamModelToDto(team)
          );
      } else {
        team.internalTeamId = this.getNextInternalId();
        teamDto$ =
          this.externalAccountEquiposService.accountEquipoHandlerControllerCreateTeam(
            this.userId.toString(),
            aid.toString(),
            convertTeamModelToDto(team)
          );
      }
      teamDto$
        .pipe(
          loggerFn(this.logger, "TeamService.upsertTeamExternal"),
          map((dto) => convertTeamDtoToModel(dto)),
          tap(async (team: TeamModel) => {
            if (team.deleted) {
              if (this._teamsMap.has(team.internalTeamId))
                this._teamsMap.delete(team.internalTeamId);
            } else {
              this._teamsMap.set(team.internalTeamId, team);
            }
            await this.syncTeamMap();
            this.getAllTeamsForSearch();
            this.findTeamByTeamId(team.internalTeamId);
            const toast = await this.toastCntl.create({
              icon: "checkmark-circle-outline",
              color: "success",
              message: `Successfully saved Team: ${team.teamname}`,
              duration: 1000,
            });
            await toast.present();
          }),
          catchError((error) => {
            this.toastCntl
              .create({
                icon: 'close-outline',
                color: "danger",
                message: `A technical problem occurred while saving team, please try it later!`,
                duration: 10000,
              })
              .then((value) => value.present());

            return throwError(error);
          }),
          finalize(async () => {
            await this._core.loadingService.dismiss();
            resolve();
          })
        )
        .subscribe();
    });
  }

  async getTeamTrackers() {
    const aid = await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    this.externalAccountEquiposService.accountEquipoHandlerControllerGetTrackers(
      this.userId.toString(),
      aid.toString()
    ).subscribe((trackers) => {
      this._trackers$.next(trackers);
    });
  }

  async changePlayerTeam(pid: string, tid: string): Promise<void> {
    const aid = await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    return this.externalAccountEquiposService.accountEquipoHandlerControllerChangePlayerTeam(
      this.userId.toString(),
      aid.toString(),
      pid,
      tid
    ).toPromise();
  }
}
