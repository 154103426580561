import { Injectable } from '@angular/core';
import { CounterModel } from '@handballai/stats-calculation';
import { BehaviorSubject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { CoreService } from 'src/app/shared-services/core.service';
import { LostBallConsumerCore } from '@handballai/stats-calculation';

@Injectable({
  providedIn: 'root'
})
export class LostBallConsumerService {

  private _lostBallConsumerCore: LostBallConsumerCore = null;

  constructor(
    private readonly logger: NGXLogger,
  ) { }

  get selectedPlayerCounters$(): BehaviorSubject<CounterModel[]> {
    return this._selectedPlayerCounters$;
  }
  private _core: CoreService;

  private _selectedPlayerCounters$ = new BehaviorSubject<CounterModel[]>([]);

  initCore(core: CoreService) {
    this._core = core;
    this.subscribeToPassiveEvent();
    this.subscribeToAttackFaultEvent();
    this.subscribeToTechnicalMistakeEvent();
    this.subscribeToLostBallEvent();
  }

  public init(): void {
    this._lostBallConsumerCore = new LostBallConsumerCore(
      this._core.gameService.gameModel.home.players,
      this._core.gameService.gameModel.visitor.players
    );
  }

  public selectPlayer(id: number): void {
    this._selectedPlayerCounters$
        .next([...Array.from(this._lostBallConsumerCore.playerCounterMap.get(id).values())]);
  }

  public deSelectPlayer(): void {
    this._selectedPlayerCounters$.next([]);
  }


  private subscribeToPassiveEvent(): void {
    this._core.playByPlayProducerService.on(['PASSIVE'], pbp => {
      this.logger.debug('LostBallConsumerService.passiveSubscription: ', pbp);
      this._lostBallConsumerCore.incrementTeamCounter(pbp.teamMarker, `${pbp.event}-${pbp.phase}`);
      this._lostBallConsumerCore.incrementTeamCounter(pbp.teamMarker, 'ALL_LOST_BALL');
    });
  }

  private subscribeToAttackFaultEvent(): void {
    this._core.playByPlayProducerService.on(['ATTACK_FAULT_COMMIT'], pbp => {
      this.logger.debug('LostBallConsumerService.attackFaultSubscription: ', pbp);

      this._lostBallConsumerCore.incrementTeamCounter(pbp.teamMarker, `${pbp.event}-${pbp.phase}`);
      this._lostBallConsumerCore.incrementPlayerCounter(pbp.offensePlayer.id, `${pbp.event}-${pbp.phase}`);
      this._lostBallConsumerCore.incrementTeamCounter(pbp.teamMarker, 'ALL_LOST_BALL');

      this._core.playerEventListConsumerService.addPlayerEvent({
        eventTime: pbp.eventTime,
        phase: pbp.phase,
        eventType: pbp.event,
        playerId: pbp.offensePlayer.id
      });

      if (pbp.defensePlayer) {
        this._lostBallConsumerCore.incrementPlayerCounter(pbp.defensePlayer.id, `ATTACK_FAULT_PROVOKE`);
        this._core.playerEventListConsumerService.addPlayerEvent({
            eventTime: pbp.eventTime,
            phase: pbp.phase,
            eventType: 'ATTACK_FAULT_PROVOKE',
            playerId: pbp.defensePlayer.id
        });
      }
    });
  }

  private subscribeToTechnicalMistakeEvent(): void {
    this._core.playByPlayProducerService.on(['TECHNICAL_MISTAKE_COMMIT'], pbp => {
      this.logger.debug('LostBallConsumerService.technicalMistakeSubscription: ', pbp);
      this._lostBallConsumerCore.incrementTeamCounter(pbp.teamMarker, `${pbp.event}-${pbp.phase}`);
      this._lostBallConsumerCore.incrementPlayerCounter(pbp.offensePlayer.id, `${pbp.event}-${pbp.phase}`);
      this._lostBallConsumerCore.incrementTeamCounter(pbp.teamMarker, 'ALL_LOST_BALL');

      this._core.playerEventListConsumerService.addPlayerEvent({
        eventTime: pbp.eventTime,
        phase: pbp.phase,
        eventType: pbp.event,
        playerId: pbp.offensePlayer.id
      });

      if (pbp.defensePlayer) {
        this._lostBallConsumerCore.incrementPlayerCounter(pbp.defensePlayer.id, 'TECHNICAL_MISTAKE_PROVOKE');
        this._core.playerEventListConsumerService.addPlayerEvent({
          eventTime: pbp.eventTime,
          phase: pbp.phase,
          eventType: 'TECHNICAL_MISTAKE_PROVOKE',
          playerId: pbp.defensePlayer.id
        });
      }
    });
  }

  private subscribeToLostBallEvent(): void {
    this._core.playByPlayProducerService.on(['LOST_BALL'], pbp => {
      this.logger.debug('LostBallConsumerService.lostBallSubscription: ', pbp);
      this._lostBallConsumerCore.incrementTeamCounter(pbp.teamMarker, `${pbp.event}-${pbp.phase}`);
      this._lostBallConsumerCore.incrementPlayerCounter(pbp.offensePlayer.id, `${pbp.event}-${pbp.phase}`);
      this._lostBallConsumerCore.incrementTeamCounter(pbp.teamMarker, 'ALL_LOST_BALL');

      this._core.playerEventListConsumerService.addPlayerEvent({
        eventTime: pbp.eventTime,
        phase: pbp.phase,
        eventType: pbp.event,
        playerId: pbp.offensePlayer.id
      });

      if (pbp.defensePlayer) {
        this._lostBallConsumerCore.incrementPlayerCounter(pbp.defensePlayer.id, 'LOST_BALL_PROVOKE');
        this._core.playerEventListConsumerService.addPlayerEvent({
          eventTime: pbp.eventTime,
          phase: pbp.phase,
          eventType: 'LOST_BALL_PROVOKE',
          playerId: pbp.defensePlayer.id
        });
      }
    });
  }
}
