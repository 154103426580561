import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { GamePlayerModel } from 'src/app/shared-services/model/game.model';
import { GoalZoneTypes, PostOutZoneTypes, ExecutionPositionTypes, SuspensionActionTypes } from 'src/app/shared-services/actions/action-types';
import { EventTime, PlayerEvent, PlayerEventModel } from '@handballai/stats-calculation';
import { GameService } from 'src/app/shared-services/game/game.service';
import { TimeOutEvent } from 'src/app/shared-services/game/events/time-out-event';

export interface PbpEventBufferModel {
    teamMarker: TeamMarker;
    event: PlayerEvent;
    offenseSystem: string;
    offensePlayer?: GamePlayerModel;
    defensePlayer?: GamePlayerModel;
    assistant?: GamePlayerModel;
    executionPosition?: ExecutionPositionTypes;
    shotLocation?: GoalZoneTypes | PostOutZoneTypes;
    important?: boolean;
    gameSystem?: string;
}

export interface PlayTimeBufferModel {
    id: number;
    event: PlayerEventModel;
    eventTime: EventTime;
}

export type UiUndoEventType = 'PLAYER_EXCHANGE_UNDO'
    | 'PLAYER_SUSPENSION_UNDO'
    | 'GAME_GOAL_UNDO'
    | 'POS1_SUSPENSION_UNDO'
    | 'POS1_SUSPENSION_SUBSTITUTE_UNDO'
    | 'PLAYER_BACK_IN_GAME_FROM_SUSPENSION_UNDO'
    | 'PLAYER_SUSPENSION_UNDO_LITE'
    | 'BRING_BACK_SUSPENSION_UNDO_LITE'
    | 'POS1_SUSPENSION_UNDO_LITE'
    | 'TIME_OUT_UNDO'
    ;

export interface UiUndoBaseEvent<T> {
    eventType: UiUndoEventType;
    payload?: T;
}


export interface PlayerExchangeUndoPayload {
    playerBeforeInGame: GamePlayerModel;
    playerBeforeOnBench: GamePlayerModel;
    teamMarker: TeamMarker;
}

export interface PlayerSuspensionUndoWithTypePayload {
    playerBeforeInGame: GamePlayerModel;
    teamMarker: TeamMarker;
    suspension: SuspensionActionTypes;
}

export interface PlayerSuspensionUndoPayload {
    playerBeforeInGame: GamePlayerModel;
    teamMarker: TeamMarker;
}

export interface GameGoalUndoPayload {
    teamMarker: TeamMarker;
}

export interface PlayerBackInGameUndoWithTypePayload {
    playerBeforeInSuspension: GamePlayerModel;
    playerSelectedToGoInField: GamePlayerModel;
    teamMarker: TeamMarker;
    suspension: SuspensionActionTypes;
}

export interface TimeoutUndoPayload {
    timeOutEvent: TimeOutEvent;
}

export class PlayerExchangeUndoEvent implements UiUndoBaseEvent<PlayerExchangeUndoPayload> {
    eventType: UiUndoEventType;
    payload: PlayerExchangeUndoPayload;
    constructor(payload: PlayerExchangeUndoPayload) {
        this.eventType = 'PLAYER_EXCHANGE_UNDO';
        this.payload = payload;
    }
}

export class PlayerSuspensionUndoEvent implements UiUndoBaseEvent<PlayerSuspensionUndoWithTypePayload> {
    eventType: UiUndoEventType;
    payload: PlayerSuspensionUndoWithTypePayload;
    constructor(payload: PlayerSuspensionUndoWithTypePayload) {
        this.eventType = 'PLAYER_SUSPENSION_UNDO';
        this.payload = payload;
    }
}

export class GameGoalUndoEvent implements UiUndoBaseEvent<GameGoalUndoPayload> {
    eventType: UiUndoEventType;
    payload: GameGoalUndoPayload;
    constructor(payload: GameGoalUndoPayload) {
        this.eventType = 'GAME_GOAL_UNDO';
        this.payload = payload;
    }
}

export class Pos1SuspensionUndoEvent implements UiUndoBaseEvent<PlayerSuspensionUndoWithTypePayload> {
    eventType: UiUndoEventType;
    payload: PlayerSuspensionUndoWithTypePayload;
    constructor(payload: PlayerSuspensionUndoWithTypePayload) {
        this.eventType = 'POS1_SUSPENSION_UNDO';
        this.payload = payload;
    }
}

export class Pos1SuspensionSubstituteUndoEvent implements UiUndoBaseEvent<PlayerSuspensionUndoPayload> {
    eventType: UiUndoEventType;
    payload: PlayerSuspensionUndoPayload;
    constructor(payload: PlayerSuspensionUndoPayload) {
        this.eventType = 'POS1_SUSPENSION_SUBSTITUTE_UNDO';
        this.payload = payload;
    }
}

export class PlayerBackInGameWithTypeUndoEvent implements UiUndoBaseEvent<PlayerBackInGameUndoWithTypePayload> {
    eventType: UiUndoEventType;
    payload: PlayerBackInGameUndoWithTypePayload;
    constructor(payload: PlayerBackInGameUndoWithTypePayload) {
        this.eventType = 'PLAYER_BACK_IN_GAME_FROM_SUSPENSION_UNDO';
        this.payload = payload;
    }
}

export class PlayerSuspensionUndoLiteEvent implements UiUndoBaseEvent<PlayerSuspensionUndoWithTypePayload> {
    eventType: UiUndoEventType;
    payload: PlayerSuspensionUndoWithTypePayload;
    constructor(payload: PlayerSuspensionUndoWithTypePayload) {
        this.eventType = 'PLAYER_SUSPENSION_UNDO_LITE';
        this.payload = payload;
    }
}

export class BringBackSuspensionUndoLiteEvent implements UiUndoBaseEvent<PlayerBackInGameUndoWithTypePayload> {
    eventType: UiUndoEventType;
    payload: PlayerBackInGameUndoWithTypePayload;
    constructor(payload: PlayerBackInGameUndoWithTypePayload) {
        this.eventType = 'BRING_BACK_SUSPENSION_UNDO_LITE';
        this.payload = payload;
    }
}

export class Pos1SuspensionUndoLiteEvent implements UiUndoBaseEvent<PlayerBackInGameUndoWithTypePayload> {
    eventType: UiUndoEventType;
    payload: PlayerBackInGameUndoWithTypePayload;

    constructor(payload: PlayerBackInGameUndoWithTypePayload) {
        this.eventType = 'POS1_SUSPENSION_UNDO_LITE';
        this.payload = payload;
    }
}

export class TimeoutUndoEvent implements UiUndoBaseEvent<TimeoutUndoPayload> {
    eventType: UiUndoEventType;
    payload: TimeoutUndoPayload;

    constructor(payload: TimeoutUndoPayload) {
        this.eventType = 'TIME_OUT_UNDO';
        this.payload = payload;
    }
}

export interface UiUndoBaseCommand<T> {
    execute(event: T, gameService: GameService): void;
}

export class PlayerExchangeUndoCommand implements UiUndoBaseCommand<PlayerExchangeUndoEvent> {
    execute(event: PlayerExchangeUndoEvent, gameService: GameService): void {
        const teamMarker = event.payload.teamMarker;
        const playerBeforeOnBench = event.payload.playerBeforeOnBench;
        const playerBeforeInGame = event.payload.playerBeforeInGame;
        playerBeforeOnBench.disabled = true;
        playerBeforeInGame.disabled = false;
        if (teamMarker === 'HOME') {
            gameService.exchangePlayerHome(playerBeforeInGame, playerBeforeOnBench);
        } else {
            gameService.exchangePlayerVisitor(playerBeforeInGame, playerBeforeOnBench);
        }
    }
}

export class PlayerSuspensionUndoCommand implements UiUndoBaseCommand<PlayerSuspensionUndoEvent> {
    execute(event: PlayerSuspensionUndoEvent, gameService: GameService): void {
        const teamMarker = event.payload.teamMarker;
        const playerBeforeInGame = event.payload.playerBeforeInGame;
        const eventType = event.payload.suspension;
        playerBeforeInGame.disabled = false;
        if (teamMarker === 'HOME') {
            gameService.undoPlayerAfterSuspensionHome(playerBeforeInGame, eventType);
        } else {
            gameService.undoPlayerAfterSuspensionVisitor(playerBeforeInGame, eventType);
        }
    }
}

export class GameGoalUndoCommand implements UiUndoBaseCommand<GameGoalUndoEvent> {
    execute(event: GameGoalUndoEvent, gameService: GameService): void {
        const teamMarker = event.payload.teamMarker;
        if (teamMarker === 'HOME') {
            gameService.decrementHomeScore();
        } else {
            gameService.decrementVisitorScore();
        }
    }
}

export class Pos1SuspensionUndoCommand implements UiUndoBaseCommand<Pos1SuspensionUndoEvent> {
    execute(event: Pos1SuspensionUndoEvent, gameService: GameService): void {
        const teamMarker = event.payload.teamMarker;
        const playerBeforeInGame = event.payload.playerBeforeInGame;
        const eventType = event.payload.suspension;
        playerBeforeInGame.disabled = false;
        if (teamMarker === 'HOME') {
            gameService.undoPos1AfterSuspensionHome(playerBeforeInGame, eventType);
        } else {
            gameService.undoPos1AfterSuspensionVisitor(playerBeforeInGame, eventType);
        }
    }
}

export class Pos1SuspensionSubstituteUndoCommand implements UiUndoBaseCommand<Pos1SuspensionSubstituteUndoEvent> {
    execute(event: Pos1SuspensionSubstituteUndoEvent, gameService: GameService): void {
        const teamMarker = event.payload.teamMarker;
        const playerBeforeInGame = event.payload.playerBeforeInGame;
        playerBeforeInGame.disabled = false;
        if (teamMarker === 'HOME') {
            gameService.undoPos1SuspensionSubstituteHome(playerBeforeInGame);
        } else {
            gameService.undoPos1SuspensionSubstituteVisitor(playerBeforeInGame);
        }
    }
}

export class PlayerBackInGameWithTypeUndoCommand implements UiUndoBaseCommand<PlayerBackInGameWithTypeUndoEvent> {
    execute(event: PlayerBackInGameWithTypeUndoEvent, gameService: GameService): void {
        const teamMarker = event.payload.teamMarker;
        const playerBeforeInSuspension = event.payload.playerBeforeInSuspension;
        const playerSelectedToGoInField = event.payload.playerSelectedToGoInField;
        const actionType = event.payload.suspension;
        if (teamMarker === 'HOME') {
            gameService.undoPlayerInGameHomeAfterPenalty(
                playerBeforeInSuspension,
                playerSelectedToGoInField,
                actionType
            );
        } else {
            gameService.undoPlayerInGameVisitorAfterPenalty(
                playerBeforeInSuspension,
                playerSelectedToGoInField,
                actionType
            );
        }
    }
}

export class LitePlayerSuspensionUndoCommand implements UiUndoBaseCommand<PlayerSuspensionUndoLiteEvent> {
    execute(event: PlayerSuspensionUndoLiteEvent, gameService: GameService): void {
        const teamMarker = event.payload.teamMarker;
        const playerBeforeInGame = event.payload.playerBeforeInGame;
        const eventType = event.payload.suspension;
        // playerBeforeInGame.disabled = false;
        if (teamMarker === 'HOME') {
            gameService.undoPlayerAfterSuspensionHome(playerBeforeInGame, eventType);
        } else {
            gameService.undoPlayerAfterSuspensionVisitor(playerBeforeInGame, eventType);
        }
    }
}

export class LiteBringBackSuspensionUndoCommand implements UiUndoBaseCommand<BringBackSuspensionUndoLiteEvent> {
    execute(event: BringBackSuspensionUndoLiteEvent, gameService: GameService): void {
        const teamMarker = event.payload.teamMarker;
        const playerBeforeInGame = event.payload.playerBeforeInSuspension;
        const eventType = event.payload.suspension;
        // playerBeforeInGame.disabled = false;
        if (teamMarker === 'HOME') {
            gameService.undoPlayerInGameHomeAfterPenalty(playerBeforeInGame, playerBeforeInGame, eventType);
        } else {
            gameService.undoPlayerInGameVisitorAfterPenalty(playerBeforeInGame, playerBeforeInGame, eventType);
        }
    }
}

export class LitePos1SuspensionUndoCommand implements UiUndoBaseCommand<Pos1SuspensionUndoLiteEvent> {
    execute(event: Pos1SuspensionUndoLiteEvent, gameService: GameService): void {
        const teamMarker = event.payload.teamMarker;
        const playerOnSuspension = event.payload.playerBeforeInSuspension;
        const playerSelectedToGoBack = event.payload.playerSelectedToGoInField;
        const suspensionType = event.payload.suspension;
        if (teamMarker === 'HOME') {
            gameService.undoPos1PlayerSuspensionLiteHome(
                playerOnSuspension,
                playerSelectedToGoBack,
                suspensionType
            );
        } else {
            gameService.undoPos1PlayerSuspensionLiteVisitor(
                playerOnSuspension,
                playerSelectedToGoBack,
                suspensionType
            );
        }
    }
}

export class TimeoutUndoEventCommand implements UiUndoBaseCommand<TimeoutUndoEvent>{
    execute(event: TimeoutUndoEvent, gameService: GameService): void {
        gameService.undoTimeOut(event.payload.timeOutEvent);
    }
}

export const undoEventCommandMap = [
    ['PLAYER_EXCHANGE_UNDO', new PlayerExchangeUndoCommand()],
    ['PLAYER_SUSPENSION_UNDO', new PlayerSuspensionUndoCommand()],
    ['GAME_GOAL_UNDO', new GameGoalUndoCommand()],
    ['POS1_SUSPENSION_UNDO', new Pos1SuspensionUndoCommand()],
    ['POS1_SUSPENSION_SUBSTITUTE_UNDO', new Pos1SuspensionSubstituteUndoCommand()],
    ['PLAYER_BACK_IN_GAME_FROM_SUSPENSION_UNDO', new PlayerBackInGameWithTypeUndoCommand()],
    ['PLAYER_SUSPENSION_UNDO_LITE', new LitePlayerSuspensionUndoCommand()],
    ['BRING_BACK_SUSPENSION_UNDO_LITE', new LiteBringBackSuspensionUndoCommand()],
    ['POS1_SUSPENSION_UNDO_LITE', new LitePos1SuspensionUndoCommand()],
    ['TIME_OUT_UNDO', new TimeoutUndoEventCommand()],
];

export class UiUndoCommandExecutor {
    // @ts-ignore
    private undoEventCommandMap = new Map<UiUndoEventType, UiUndoBaseCommand<any>>(undoEventCommandMap);

    public executeUndoCommand<T>(event: UiUndoBaseEvent<T>, gameService: GameService): void {
        this.undoEventCommandMap.get(event.eventType).execute(event, gameService);
    }
}
