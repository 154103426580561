import { GamePlayerModel } from '../model/game.model';
import { TimeOutEvent } from 'src/app/shared-services/game/events/time-out-event';
import { BehaviorSubject } from 'rxjs';
import { Timeout } from 'src/app/shared-services/game/timeout';

export const toggleDisablePlayerList = (gamePlayerList: GamePlayerModel[], toggleValue: boolean): GamePlayerModel[] =>
    gamePlayerList.reduce((field, player) =>
    {
        player.disabled = toggleValue;
        field.push(player);
        return field;
    }, [] as GamePlayerModel[]);

export const togglePlayerListExceptSelectedPlayer = (
    gamePlayerList: GamePlayerModel[],
    selectedPlayer: GamePlayerModel
): GamePlayerModel[] =>
    gamePlayerList.reduce((field, player) =>
    {
        if (player.id === selectedPlayer.id) {
            player.disabled = false;
        } else {
            player.disabled = true;
        }
        field.push(player);
        return field;
    }, [] as GamePlayerModel[]);

export const initGamePlayerSlotIds = (rawField: GamePlayerModel[]): GamePlayerModel[] => {
    let counter = 1;
    let goalKeeper = rawField.filter(pl => pl.position === 'gk')[0];
    if (!goalKeeper) goalKeeper = rawField[0]; // On continue tracking if goalKeeper is not in field, just put any player on goalKeeper position
    goalKeeper.slotId = counter;
    counter++;
    return [
        goalKeeper,
        ...rawField.filter(pl => pl.id !== goalKeeper.id).map(pl => {
            pl.slotId = counter;
            counter++;
            return pl;
        })
    ];
};

/**
 * Slots are going to filled
 * @param rawField
 */
export const initGamePlayerSlotIdsForLite = (rawField: GamePlayerModel[]): GamePlayerModel[] => {
    let counter = 1;
    let goalKeepers = rawField.filter(pl => pl.position === 'gk');
    // Disable the non first goalkeeper
    if (goalKeepers[1]) {
        goalKeepers[1].disabled = true;
    }
    if (goalKeepers[2]) {
        goalKeepers[2].disabled = true;
    }
    goalKeepers = goalKeepers.map(gk => {
        gk.slotId = counter;
        counter++;
        return gk;
    });
    return [
        ...goalKeepers,
        ...rawField.filter(pl => pl.position !== 'gk').map(pl => {
            pl.slotId = counter;
            counter++;
            return pl;
        })
    ];
};

export const applyTimeOut = (
    timeOutEvent: TimeOutEvent,
    homeTimeOuts$: BehaviorSubject<Timeout[]>,
    visitorTimeOuts$: BehaviorSubject<Timeout[]>,
): void => {
    if (timeOutEvent.teamMarker === 'HOME') {
        if (homeTimeOuts$.value[timeOutEvent.index] != 'disabled') {
            homeTimeOuts$.value[timeOutEvent.index] = 'taken';
            homeTimeOuts$.next([...homeTimeOuts$.value]);
        }
    } else {
        if (visitorTimeOuts$.value[timeOutEvent.index] != 'disabled') {
            visitorTimeOuts$.value[timeOutEvent.index] = 'taken';
            visitorTimeOuts$.next([...visitorTimeOuts$.value]);
        }
    }
};

export const undoTimeOut = (
    timeOutEvent: TimeOutEvent,
    homeTimeOuts$: BehaviorSubject<Timeout[]>,
    visitorTimeOuts$: BehaviorSubject<Timeout[]>,
): void => {
    if (timeOutEvent.teamMarker === 'HOME') {
        homeTimeOuts$.value[timeOutEvent.index] = 'open';
        homeTimeOuts$.next([...homeTimeOuts$.value]);
    } else {
        visitorTimeOuts$.value[timeOutEvent.index] = 'open';
        visitorTimeOuts$.next([...visitorTimeOuts$.value]);
    }
};

export const enableAllowedTimeOuts = (
    homeTimeOuts$: BehaviorSubject<Timeout[]>,
    visitorTimeOuts$: BehaviorSubject<Timeout[]>,
): void => {
    if (homeTimeOuts$.value[0] == 'taken' &&
        homeTimeOuts$.value[2] == 'disabled'
    ) {
        homeTimeOuts$.value[2] = 'open';
        homeTimeOuts$.next([...homeTimeOuts$.value]);
    }
    if (visitorTimeOuts$.value[0] == 'taken' &&
        visitorTimeOuts$.value[2] == 'disabled'
    ) {
        visitorTimeOuts$.value[2] = 'open';
        visitorTimeOuts$.next([...visitorTimeOuts$.value]);
    }
};


export const resetTimeOut = (
    homeTimeOuts$: BehaviorSubject<Timeout[]>,
    visitorTimeOuts$: BehaviorSubject<Timeout[]>,
): void => {
    homeTimeOuts$.next(['open', 'open', 'disabled']);
    visitorTimeOuts$.next(['open', 'open', 'disabled']);
};
