import { Component, Input, OnInit } from '@angular/core';
import { ScoutingFolderNamePopupComponent } from '../../../components/scouting-folder-name-popup/scouting-folder-name-popup.component';
import { ScoutingPlaylistDto, WatchlistDto, WatchlistFolderDto } from '../../../../../../../../api/hai-api';
import { CoreService } from '../../../../../../../../shared-services/core.service';
import { ScoutingService } from '../../../scouting.service';
import { PlayListOptionsPopoverComponent } from '../playList-options-popover/playList-options-popover.component';
import { PopoverController } from '@ionic/angular';
import { ConfirmationModalComponent } from '../../pageComponents/confirmation-modal/confirmation-modal';
import {
  PlayListSelectionPopoverComponent
} from '../../../components/your-search/video-modal/play-list-selection-popover/play-list-selection-popover.component';

@Component({
    selector: 'app-scouting-folder-popover',
    templateUrl: './scouting-folder-popover.component.html',
    styleUrls: ['./scouting-folder-popover.component.scss'],
    standalone: false
})
export class ScoutingFolderPopoverComponent implements OnInit {
  @Input() playListFolders: ScoutingPlaylistDto[] = [];
  @Input() watchListFolders: WatchlistFolderDto[] = [];
  @Input() selectedPlaylistFolder: ScoutingPlaylistDto;
  @Input() selectedWatchlistFolder: WatchlistFolderDto;
  @Input() isWatchList: boolean = false;
  constructor(public core: CoreService,
              public scoutingService: ScoutingService,
              private popoverCntl: PopoverController,
              ) { }

  ngOnInit() {

  }

  public async selectFolderPlaylist(playListFolder: ScoutingPlaylistDto) {
    this.selectedPlaylistFolder = playListFolder;
    this.scoutingService.videoFilter$.next(
        playListFolder.videos.filter((playList) => !playList.deleted).sort((a, b) => a.id - b.id)
    );
    await this.popoverCntl.dismiss(playListFolder);
  }

  public async selectFolderWatchlist(watchListFolder?: WatchlistFolderDto) {
    this.selectedWatchlistFolder = watchListFolder;
    this.scoutingService.watchLists$.next(
        watchListFolder.watchlists.filter((playList) => !playList.deleted).sort((a, b) => a.id - b.id)
    );
    await this.popoverCntl.dismiss(watchListFolder);
  }

  async addPlaylistFolder() {
    const popover = await this.core.popoverCtrl.create({
      component: ScoutingFolderNamePopupComponent,
      cssClass: 'confirm-popover',
      mode: 'ios',
      componentProps: {
        modalTitle: 'Create Playlist',
        message: 'Type the new Playlist name',
      },
    });
    popover.onDidDismiss().then(async (modalData: any) => {
      if (modalData.data) {
        const folder: ScoutingPlaylistDto = {
          id: undefined,
          name: modalData.data.name,
          deleted: false,
          videos: [],
        };
        await this.scoutingService.createPlayList(folder);
      }
    });
    await popover.present();
  }

  async addWatchlistFolder(folder?: WatchlistFolderDto) {
    const popover = await this.core.popoverCtrl.create({
      component: ScoutingFolderNamePopupComponent,
      cssClass: 'confirm-popover',
      mode: 'ios',
      componentProps: {
        modalTitle: 'Create Watchlist',
        message: 'Type the new Watchlist name',
        currentName: folder ? folder.name : '',
      },
    });

    popover.onDidDismiss().then(async (modalData: any) => {
      if (modalData.data) {
        if (folder) {
          folder.name = modalData.data.name;
          this.scoutingService.editWatchlistFolder(folder);
        } else {
          const newFolder = {
            name: modalData.data.name,
            deleted: false,
            watchlists: [],
          };

          this._createWatchlistFolder(newFolder);
        }
      }
    });
    await popover.present();
  }

  private async _createWatchlistFolder(newFolder) {
    await this.core.externalScoutingService
        .scoutingControllerPostWatchlistFolder(String(this.scoutingService.currentUser.id), String(this.scoutingService.selectedEquipo.id), newFolder)
        .subscribe((res) => {
          this.scoutingService.getWatchListData();
        });
  }

  async openVideoOptions(event, item, elementType) {
    const popover = await this.popoverCntl.create({
      component: PlayListOptionsPopoverComponent,
      event: event,
      componentProps: {
        hasMoveOption: elementType === 'video',
      },
    });
    await popover.present();

    popover.onDidDismiss().then(async (selectedOption) => {
      if (!selectedOption.data) {
        return;
      } else {
        if (selectedOption.data === 'edit') this.updatePlaylist(item, elementType);
        if (selectedOption.data === 'delete') this.deletePlaylist(item, elementType);
        if (selectedOption.data === 'move') this._moveVideoFromPlaylist(item);
      }
    });
  }

  public removeWatchlistfromFolder(watchlist: WatchlistDto) {
    ConfirmationModalComponent.Open(
        this.core.popoverCtrl,
        {
          title: `Are you sure you want to delete "${watchlist.name}" watchlist?`,
        },
        async (d) => {
          if (d.data) {
            this.selectedWatchlistFolder.watchlists = this.selectedWatchlistFolder.watchlists.filter(
                (selected) => selected.id !== watchlist.id
            );
            watchlist.deleted = true;
            this.selectedWatchlistFolder.watchlists.push(watchlist);
            await this.scoutingService.editWatchlistFolder(this.selectedWatchlistFolder);
            this.selectFolderWatchlist(this.selectedWatchlistFolder);
          }
        }
    );
  }

  async updatePlaylist(item, elementType: string) {
    const popover = await this.core.popoverCtrl.create({
      component: ScoutingFolderNamePopupComponent,
      cssClass: 'confirm-popover',
      mode: 'ios',
      componentProps: {
        modalTitle: elementType === 'video' ? 'Edit video name' : 'Create Playlist',
        message: 'Type the new video name',
        currentName: elementType === 'video' ? item.info : item.name,
      },
    });

    popover.onDidDismiss().then(async (modalData: any) => {
      if (modalData.data) {
        let itemToSave;
        if (elementType === 'folder') {
          itemToSave = item;
          itemToSave.name = modalData.data.name;
        } else {
          const videoToSave = {
            deleted: item.deleted,
            id: item.id,
            info: modalData.data.name,
            pbpId: item.pbpId,
          };
          itemToSave = this.selectedPlaylistFolder;
          itemToSave.videos = itemToSave.videos.filter((v) => v.pbpId !== item.pbpId);
          itemToSave.videos.push(videoToSave);
        }

        await this.scoutingService.editPlayList(itemToSave);
        this.selectFolderPlaylist(this.selectedPlaylistFolder);
      }
    });
    await popover.present();
  }

  public async deletePlaylist(item, elementType) {
    ConfirmationModalComponent.Open(
        this.core.popoverCtrl,
        {
          title: `Are you sure you want to delete "${item.name || item.info}"  ${elementType}?`,
        },
        async (d) => {
          if (d.data) {
            item.deleted = true;
            if (elementType === 'folder') {
              await this.scoutingService.editPlayList(item);
            } else {
              let itemToSave = this.selectedPlaylistFolder;
              itemToSave.videos = itemToSave.videos.filter((v) => v.pbpId !== item.pbpId);
              itemToSave.videos.push(item);
              await this.scoutingService.editPlayList(itemToSave);
              this.selectFolderPlaylist(this.selectedPlaylistFolder);
            }
          }
        }
    );
  }

  //move a video from a playlist to another
  private async _moveVideoFromPlaylist(video) {
    const popover = await this.core.popoverCtrl.create({
      component: PlayListSelectionPopoverComponent,
      cssClass: 'confirm-popover',
      mode: 'ios',
      componentProps: {
        selectedVideos: [{ ...video }],
        originalPlaylist: this.selectedPlaylistFolder,
      },
    });

    popover.onDidDismiss().then(async (modalData: any) => {
      if (modalData.data) {
        if (modalData.data.id !== this.selectedPlaylistFolder.id) {
          await this.scoutingService.editPlayList(modalData.data);

          video.deleted = true;
          let itemToSave = this.selectedPlaylistFolder;
          itemToSave.videos = itemToSave.videos.filter((v) => v.pbpId !== video.pbpId);
          itemToSave.videos.push(video);
          await this.scoutingService.editPlayList(itemToSave);
          this.selectFolderPlaylist(this.selectedPlaylistFolder);

          this.core.toastCtrl
              .create({
                icon: "checkmark-circle-outline",
                color: 'success',
                message: `Successfully saved video on selected Playlist!`,
                duration: 5000,
              })
              .then((value) => value.present());
        } else {
          this.core.toastCtrl
              .create({
                icon: 'close-outline',
                color: 'danger',
                message: `Choose a different Playlist`,
                duration: 5000,
              })
              .then((value) => value.present());
        }
      }
    });
    await popover.present();
  }

}
