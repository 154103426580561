<div class="video-container">
    <div class="video-content">
        <div class="video-header">
            {{'Review last event' | translate}}
        </div>
        <video id='video' #videoPlayer width="100%" controls [muted]="true" disablepictureinpicture controlslist="nodownload noplaybackrate nofullscreen">
            <source [src]="src|safe:'resourceUrl'" type="video/mp4">
            </video>
    </div>

    <div class="video-actions w-100">
        <ion-button class="button button-block" [fill]="'outline'" (click)="cancel()">
            <ion-label>{{'Cancel'|translate}}</ion-label>
        </ion-button>
    </div>
</div>
