import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { TimerModel } from './timer-model';
import { NGXLogger } from 'ngx-logger';
import { calculateNextTimerTick } from './timer-helper';
import { DateTime } from 'luxon';
import { CoreService } from '../core.service';
import { PlayerEvent, TeamMarker } from '@handballai/stats-calculation';
import { TeamSelectPopupComponent } from 'src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/team-select-popup/team-select-popup.component';

@Injectable({
  providedIn: 'root'
})
export class HandballTimerService {

  constructor(
    private readonly logger: NGXLogger,
  ) {
    // temp initialize start time
    this._startTimeOfGame = DateTime.now();
  }

  get gameCounter$(): BehaviorSubject<TimerModel> {
    return this._gameCounter$;
  }
  get gameEnded$(): BehaviorSubject<boolean> {
    return this._gameEnded$;
  }
  get gameRunning$(): BehaviorSubject<boolean> {
    return this._gameRunning$;
  }

  get isGameRunning(): boolean {
    return this._isGameRunning;
  }

  get isFirstHalftimeNotStarted(): boolean {
    return this._isFirstHalftimeNotStarted;
  }

  get isSecondHalfTimeNotStarted(): boolean {
    return this._isSecondHalfTimeNotStarted;
  }

  get startTimeOfGame(): DateTime {
    return this._startTimeOfGame;
  }
  private _core: CoreService;

  private _gameRunning$ = new BehaviorSubject<boolean>(false);
  private _gameEnded$ = new BehaviorSubject<boolean>(true);
  private _isFirstHalftimeETStarted = false;
  private _isSecondHalfTimeETStarted = false;
  private _penaltiesStarted = false;
  private _swappedTeams = false;
  private _gameCounter$ = new BehaviorSubject<TimerModel>({
    counterSecSinceStart: 0,
    gameCounter: '00:00',
    seconds: 0,
    minutes: 0,
    halfTime: 'T1',
    wallClock: DateTime.now()
  });

  get isFirstHalftimeETStarted(): boolean {
    return this._isFirstHalftimeETStarted;
  }

  get isSecondHalfTimeETStarted(): boolean {
    return this._isSecondHalfTimeETStarted;
  }

  get penaltiesStarted(): boolean {
    return this._penaltiesStarted;
  }


  private _startingTeam: TeamMarker;
  private _timerSource$: Observable<number>;
  private timerSubscription: Subscription;
  private _isGameRunning = false;
  private _isFirstHalftimeNotStarted = true;
  private _isSecondHalfTimeNotStarted = true;
  private readonly _startTimeOfGame: DateTime;
  initCore(core: CoreService) {
      this._core = core;
  }


  public async endFirstHalf(extraTime?: boolean): Promise<void> {
    this.stopGameTimer();
    this._isGameRunning = false;
    this._gameRunning$.next(this._isGameRunning);
    if (extraTime) {
      this._isSecondHalfTimeETStarted = true;
    }
    this._isFirstHalftimeNotStarted = false;
    // this._isSecondHalfTimeNotStarted = false;
    await this._core.eventBufferService.closeTransactionNow();
    const teamMarker = this._core.playByPlayProducerService.pBpRecords$.value[this._core.playByPlayProducerService.pBpRecords$.value.length-1].teamMarker;
    this._core.eventBufferService.addPlayByPlayEvent(
      // Last event teamMarker
      teamMarker,
      {
          eventType: !extraTime ? 'FIRST_HALF_END' : 'EXTRA_TIME_ONE_END',
          eventTime: {
            timestamp: this.gameCounter$.value.wallClock,
            halftime: this.gameCounter$.value.halfTime,
            secondsSinceHalftime: this.gameCounter$.value.seconds,
            minutesSinceHalftime: this.gameCounter$.value.minutes,
            secondsSinceStartOfGame: this.gameCounter$.value.counterSecSinceStart
          },
          phase: 'OFFENSE_POSITIONAL',
      } as PlayerEvent,
      this._core.gameService.gameModel[teamMarker=='HOME'?'home':'visitor'].offenseSystem,
    );
    this._gameCounter$.next({
      counterSecSinceStart: this._gameCounter$.value.counterSecSinceStart,
      gameCounter: '00:00',
      seconds: 0,
      minutes: 0,
      halfTime: extraTime ? 'ET2' : 'T2',
      wallClock: DateTime.now()
    });
    this._core.gameService.enableAllowedTimeOuts();
  }

  public async endSecondHalf(extraTime?: boolean): Promise<void> {
    this.stopGameTimer();
    this._isGameRunning = false;
    this._gameRunning$.next(this._isGameRunning);
    if (!extraTime) {
      this._isFirstHalftimeETStarted = true;
    } else {
      this._penaltiesStarted = true;
    }
    this._isFirstHalftimeNotStarted = false;
    // this._isSecondHalfTimeNotStarted = false;
    await this._core.eventBufferService.closeTransactionNow();
    const teamMarker = this._core.playByPlayProducerService.pBpRecords$.value[this._core.playByPlayProducerService.pBpRecords$.value.length-1].teamMarker;
    this._core.eventBufferService.addPlayByPlayEvent(
        // Last event teamMarker
        teamMarker,
        {
          eventType: !extraTime ? 'SECOND_HALF_END' : 'EXTRA_TIME_TWO_END',
          eventTime: {
            timestamp: this.gameCounter$.value.wallClock,
            halftime: this.gameCounter$.value.halfTime,
            secondsSinceHalftime: this.gameCounter$.value.seconds,
            minutesSinceHalftime: this.gameCounter$.value.minutes,
            secondsSinceStartOfGame: this.gameCounter$.value.counterSecSinceStart
          },
          phase: 'OFFENSE_POSITIONAL',
        } as PlayerEvent,
        this._core.gameService.gameModel[teamMarker=='HOME'?'home':'visitor'].offenseSystem,
    );
    this._gameCounter$.next({
      counterSecSinceStart: this._gameCounter$.value.counterSecSinceStart,
      gameCounter: '00:00',
      seconds: 0,
      minutes: 0,
      halfTime: extraTime ? 'P' : 'ET1',
      wallClock: DateTime.now()
    });
    this._core.gameService.enableAllowedTimeOuts();
  }

  public async endGame(): Promise<void> {
    this._isGameRunning = false;
    this._gameRunning$.next(this._isGameRunning);
    this.stopGameTimer();
    await this._core.eventBufferService.closeTransactionNow();
    // Last event teamMarker
    const teamMarker = this._core.playByPlayProducerService.pBpRecords$.value[this._core.playByPlayProducerService.pBpRecords$.value.length-1].teamMarker;
    this._core.eventBufferService.addPlayByPlayEvent(
      teamMarker,
      {
          eventType: !this._isFirstHalftimeETStarted ? 'SECOND_HALF_END' : !this.penaltiesStarted ? 'EXTRA_TIME_TWO_END' : 'PENALTIES_END',
          eventTime: {
            timestamp: this.gameCounter$.value.wallClock,
            halftime: this.gameCounter$.value.halfTime,
            secondsSinceHalftime: this.gameCounter$.value.seconds,
            minutesSinceHalftime: this.gameCounter$.value.minutes,
            secondsSinceStartOfGame: this.gameCounter$.value.counterSecSinceStart
          },
          phase: 'OFFENSE_POSITIONAL',
      } as PlayerEvent,
      this._core.gameService.gameModel[teamMarker=='HOME'?'home':'visitor'].offenseSystem,
    );
    this._isFirstHalftimeNotStarted = false;
    this._isSecondHalfTimeNotStarted = false;
    this._gameEnded$.next(true);
  }


  public interruptGame(): void {
    this._isGameRunning = false;
    this._gameRunning$.next(this._isGameRunning);
  }

  public async resumeGame(): Promise<void> {
    this._isGameRunning = true;
    this._gameRunning$.next(this._isGameRunning);
    await this.startGameTimer();
    this._core.gameDataService.startGamePushing();
  }

  public resetGame(): void {
    this._isGameRunning = false;
    this._gameRunning$.next(this._isGameRunning);
    this._isFirstHalftimeNotStarted = true;
    this._isSecondHalfTimeNotStarted = true;
    this._gameEnded$.next(false);
    this.stopGameTimer();
    this._gameCounter$.next({
      counterSecSinceStart: 0,
      gameCounter: '00:00',
      seconds: 0,
      minutes: 0,
      halfTime: 'T1',
      wallClock: DateTime.now()
    });
    this._core.gameService.resetTimeOut();
    this.gameEnded$.next(false);
  }
  private async startGameTimer(): Promise<void> {
    // TODO: Move all the game control events to ScoreBoardComponent (together with PlayTimeSlots) using addGameControlEvent
    if (this._isFirstHalftimeNotStarted) {
      this._isFirstHalftimeNotStarted = false;
      this._core.gameService.gameModel.startDate = DateTime.now();
      this.gameCounter$.next({...this.gameCounter$.value, wallClock: DateTime.now()});
      const ev = {
        eventType: 'FIRST_HALF_START',
        eventTime: {
          timestamp: this.gameCounter$.value.wallClock,
          halftime: this.gameCounter$.value.halfTime,
          secondsSinceHalftime: this.gameCounter$.value.seconds,
          minutesSinceHalftime: this.gameCounter$.value.minutes,
          secondsSinceStartOfGame: this.gameCounter$.value.counterSecSinceStart
        },
        phase: 'OFFENSE_POSITIONAL',
      } as PlayerEvent;
      this._core.popoverCtrl.create({
        component: TeamSelectPopupComponent,
        cssClass: 'global__popover global__popover--doubleEvent',
        // translucent: true,
        backdropDismiss: false,
      }).then(p => {
        p.onDidDismiss().then((d) => {
          this._startingTeam = d.data.data;
          this._core.eventBufferService.addPlayByPlayEvent(
            this._startingTeam,
            ev,
            this._core.gameService.gameModel[this._startingTeam=='HOME'?'home':'visitor'].offenseSystem
          );
        });
        p.present();
      });
      // Next condition is not correct at all, not triggered everytime
    } else if (this.gameCounter$.value.halfTime == 'T2' && this._isSecondHalfTimeNotStarted) {
      this._isSecondHalfTimeNotStarted = false;
      this.gameCounter$.next({...this.gameCounter$.value, wallClock: DateTime.now()});
      this._core.eventBufferService.addPlayByPlayEvent(
        this._startingTeam == 'HOME' ? 'VISITOR':'HOME',
        {
            eventType: 'SECOND_HALF_START',
            eventTime: {
              timestamp: this.gameCounter$.value.wallClock,
              halftime: this.gameCounter$.value.halfTime,
              secondsSinceHalftime: this.gameCounter$.value.seconds,
              minutesSinceHalftime: this.gameCounter$.value.minutes,
              secondsSinceStartOfGame: this.gameCounter$.value.counterSecSinceStart
            },
            phase: 'OFFENSE_POSITIONAL',
        } as PlayerEvent,
        this._core.gameService.gameModel[this._startingTeam!='HOME'?'home':'visitor'].offenseSystem,
      );
    } else if (this._isFirstHalftimeETStarted && !this._isSecondHalfTimeETStarted) {
      this._isFirstHalftimeNotStarted = false;
      // this._core.gameService.gameModel.startDate = DateTime.now();
      this.gameCounter$.next({...this.gameCounter$.value, wallClock: DateTime.now()});
      const ev = {
        eventType: 'EXTRA_TIME_ONE_START',
        eventTime: {
          timestamp: this.gameCounter$.value.wallClock,
          halftime: this.gameCounter$.value.halfTime,
          secondsSinceHalftime: this.gameCounter$.value.seconds,
          minutesSinceHalftime: this.gameCounter$.value.minutes,
          secondsSinceStartOfGame: this.gameCounter$.value.counterSecSinceStart
        },
        phase: 'OFFENSE_POSITIONAL',
      } as PlayerEvent;
      this._core.popoverCtrl.create({
        component: TeamSelectPopupComponent,
        cssClass: 'global__popover global__popover--doubleEvent',
        // translucent: true,
        backdropDismiss: false,
      }).then(p => {
        p.onDidDismiss().then((d) => {
          this._startingTeam = d.data.data;
          this._core.eventBufferService.addPlayByPlayEvent(
              this._startingTeam,
              ev,
              this._core.gameService.gameModel[this._startingTeam=='HOME'?'home':'visitor'].offenseSystem
          );
        });
        p.present();
      });
    } else if (this._isSecondHalfTimeETStarted && !this._penaltiesStarted) {
      this.gameCounter$.next({...this.gameCounter$.value, wallClock: DateTime.now()});
      this._core.eventBufferService.addPlayByPlayEvent(
          this._startingTeam == 'HOME' ? 'VISITOR':'HOME',
          {
            eventType: 'EXTRA_TIME_TWO_START',
            eventTime: {
              timestamp: this.gameCounter$.value.wallClock,
              halftime: this.gameCounter$.value.halfTime,
              secondsSinceHalftime: this.gameCounter$.value.seconds,
              minutesSinceHalftime: this.gameCounter$.value.minutes,
              secondsSinceStartOfGame: this.gameCounter$.value.counterSecSinceStart
            },
            phase: 'OFFENSE_POSITIONAL',
          } as PlayerEvent,
          this._core.gameService.gameModel[this._startingTeam!='HOME'?'home':'visitor'].offenseSystem,
      );
    } else if (this._penaltiesStarted) {
      this.gameCounter$.next({...this.gameCounter$.value, wallClock: DateTime.now()});
      this._core.eventBufferService.addPlayByPlayEvent(
          this._startingTeam == 'HOME' ? 'VISITOR':'HOME',
          {
            eventType: 'PENALTIES_START',
            eventTime: {
              timestamp: this.gameCounter$.value.wallClock,
              halftime: this.gameCounter$.value.halfTime,
              secondsSinceHalftime: this.gameCounter$.value.seconds,
              minutesSinceHalftime: this.gameCounter$.value.minutes,
              secondsSinceStartOfGame: this.gameCounter$.value.counterSecSinceStart
            },
            phase: 'OFFENSE_POSITIONAL',
          } as PlayerEvent,
          this._core.gameService.gameModel[this._startingTeam!='HOME'?'home':'visitor'].offenseSystem,
      );
    }

    if (!this._timerSource$) {
      this._timerSource$ = interval(1000).pipe(
          // tap(() => this.logger.debug('HandballTimerService.nextTick...'))
      );
    }
    if (!this.timerSubscription) {
      this.timerSubscription = this._timerSource$.subscribe(() => {
        const nextTick = calculateNextTimerTick(this._gameCounter$.value, this.isGameRunning);
        // this.logger.debug('HandballTimerService.nextValue: ', nextTick);
        this._gameCounter$.next(nextTick);
      });
    }
  }

  private stopGameTimer(): void {
    this.timerSubscription?.unsubscribe();
    this.timerSubscription = undefined;
    this._timerSource$ = undefined;
  }
}
