import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { GameDto } from 'src/app/api/hai-api';
import { CoreService } from 'src/app/shared-services/core.service';
import { GameService } from 'src/app/shared-services/game/game.service';
import { AdvancedStatPlayerModel } from '@handballai/stats-calculation';
import { PlayByPlayModel } from '@handballai/stats-calculation';
import { IndividualStatsStateHelper } from 'src/app/shared-services/statistics/common/state-helper/individual-stats-state-helper';
import { OverviewStatsStateHelper } from 'src/app/shared-services/statistics/common/state-helper/overview-stats-state-helper';
import { ShotStatsStateHelper } from 'src/app/shared-services/statistics/common/state-helper/shot-stats-state-helper';
import { TeamOverviewStatsStateHelper } from 'src/app/shared-services/statistics/common/state-helper/team-overview-stats-state-helper';
import { TimelineStatsStateHelper } from 'src/app/shared-services/statistics/common/state-helper/timeline-stats-state-helper';
import { GameSeedService } from 'src/app/shared-services/statistics/game-seed/game-seed.service';
import { PlayTimeService } from 'src/app/shared-services/statistics/play-time/play-time.service';
import { EasyStatsService } from 'src/app/shared-services/statistics/playbyplay/consumer/easy-stats/easy-stats.service';
import { GoalConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/goal/goal-consumer.service';
import { GoalkeeperEfficiencyConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/goalkeeper-efficiency/goalkeeper-efficiency-consumer.service';
import { LostBallConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/lost-ball/lost-ball-consumer.service';
import { MiscEventConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/misc-event/misc-event-consumer.service';
import { OverviewConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/overview/overview-consumer.service';
import { PlayerEventListConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/player-events/player-event-list-consumer.service';
import { PossessionsConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/possessions/possessions-consumer.service';
import { SuspensionConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/suspension/suspension-consumer.service';
import { TimelineConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/timeline/timeline-consumer.service';
import { TeamOverviewSubConsumerService } from 'src/app/shared-services/statistics/playbyplay/sub-consumer/team-overview/team-overview-sub-consumer.service';
import { HandballTimerService } from 'src/app/shared-services/timer/handball-timer.service';
import { TimerModel } from 'src/app/shared-services/timer/timer-model';
import { GameSystemStatsStateHelper } from 'src/app/shared-services/statistics/common/state-helper/game-system-stats-state-helper';
import { GameSystemConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/game-system-consumer.service';
import { GameStateHelper } from '../../pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/helper/game-state-helper';
import { TeamStatsStateHelper } from '../../pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/helper/team-stats-state-helper';

@Component({
  selector: 'app-advanced-stats',
  templateUrl: './advanced-stats.component.html',
  styleUrls: ['./advanced-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedStatsComponent implements OnInit {

  public selectedTab = 'OVERVIEW';
  private readonly _gameTimer$: Observable<TimerModel>;
  private readonly _gameStateHelper: GameStateHelper;
  private readonly _players$: Observable<AdvancedStatPlayerModel[]>;
  private readonly _playByPlayRecords$: Observable<PlayByPlayModel[]>;
  // New Stats model
  private readonly _individualStatsStateHelper: IndividualStatsStateHelper;
  private readonly _shotsStatsStateHelper: ShotStatsStateHelper;
  private readonly _timelineStatsStateHelper: TimelineStatsStateHelper;
  private readonly _teamOverviewStatsStateHelper: TeamOverviewStatsStateHelper;
  private readonly _overviewStatsStateHelper: OverviewStatsStateHelper;
  private readonly _gameSystemStatsHelper: GameSystemStatsStateHelper;

  private _game: GameDto = null;
  private _editable = true;
  private _isPublic = false;
  private _isGameTracker = false;

  // Required by overview - will go out soon
  public readonly _teamStatsStateHelper: TeamStatsStateHelper;
  showPlayByPlay$ = new BehaviorSubject<boolean>(false);


  constructor(
      private readonly _gameService: GameService,
      public readonly core: CoreService,
      private readonly logger: NGXLogger,
      public readonly timerService: HandballTimerService,
      private readonly easyStatsService: EasyStatsService,
      private readonly goalConsumerService: GoalConsumerService,
      private readonly lostBallConsumerService: LostBallConsumerService,
      private readonly miscEventConsumerService: MiscEventConsumerService,
      private readonly playerEventListConsumerService: PlayerEventListConsumerService,
      private readonly possessionConsumerService: PossessionsConsumerService,
      private readonly suspensionConsumerService: SuspensionConsumerService,
      private readonly gameSeedService: GameSeedService,
      private readonly playTimeService: PlayTimeService,
      private readonly goalKeeperEfficiencyService: GoalkeeperEfficiencyConsumerService,
      private readonly timelineConsumerService: TimelineConsumerService,
      private readonly teamOverviewSubConsumerService: TeamOverviewSubConsumerService,
      private readonly overviewConsumerService: OverviewConsumerService,
      private readonly gameSystemConsumerService: GameSystemConsumerService,
  ) {
    this.showPlayByPlay$.next(this.core.userService.checkForGrant('manage_teams', true, false));
    this._gameTimer$ = this.timerService.gameCounter$;
    this._gameStateHelper = new GameStateHelper(this._gameService, this.logger);
    this._players$ = this.gameSeedService.advancedStatsPlayerModel$;

    this._teamStatsStateHelper = new TeamStatsStateHelper(
        this.easyStatsService,
        this.goalConsumerService
    );
    this._playByPlayRecords$ = this.core.playByPlayProducerService.pBpRecords$;

    this._individualStatsStateHelper = new IndividualStatsStateHelper(
        goalConsumerService,
        lostBallConsumerService,
        miscEventConsumerService,
        playerEventListConsumerService,
        possessionConsumerService,
        suspensionConsumerService,
        playTimeService
    );

    this._shotsStatsStateHelper = new ShotStatsStateHelper(
        goalConsumerService,
        goalKeeperEfficiencyService
    );

    this._timelineStatsStateHelper = new TimelineStatsStateHelper(timelineConsumerService);
    this._teamOverviewStatsStateHelper = new TeamOverviewStatsStateHelper(teamOverviewSubConsumerService);
    this._overviewStatsStateHelper = new OverviewStatsStateHelper(overviewConsumerService);
    this._gameSystemStatsHelper = new GameSystemStatsStateHelper(gameSystemConsumerService);
  }

  ngOnInit() {
    this.logger.debug('AdvancedStatsComponent.ngOnInit');
  }

  ionViewWillEnter() {
    this.logger.debug('AdvancedStatsComponent.ionViewWillEnter');
  }

  @Input()
  set isPublic(value: boolean) {
    this._isPublic = value;
  }

  get isPublic(): boolean {
    return this._isPublic;
  }

  @Input()
  set isGameTracker(value: boolean) {
    this._isGameTracker = value;
  }

  get isGameTracker(): boolean {
    return this._isGameTracker;
  }

  @Input()
  set game(value: GameDto) {
    this._game = value;
  }

  get game(): GameDto {
    return this._game;
  }

  @Input()
  set editable(value: boolean) {
    this._editable = value;
  }

  get editable(): boolean {
    return this._editable;
  }


  get playByPlayRecords$(): Observable<PlayByPlayModel[]> {
    return this._playByPlayRecords$;
  }

  get gameTimer$(): Observable<TimerModel> {
    return this._gameTimer$;
  }

  get gameStateHelper(): GameStateHelper {
    return this._gameStateHelper;
  }

  get players$(): Observable<AdvancedStatPlayerModel[]> {
    return this._players$;
  }

  get gameService(): GameService {
    return this._gameService;
  }

  get individualStatsStateHelper(): IndividualStatsStateHelper {
    return this._individualStatsStateHelper;
  }

  get shotsStatsStateHelper(): ShotStatsStateHelper {
    return this._shotsStatsStateHelper;
  }

  get timelineStatsStateHelper(): TimelineStatsStateHelper {
    return this._timelineStatsStateHelper;
  }

  get teamOverviewStatsStateHelper(): TeamOverviewStatsStateHelper {
    return this._teamOverviewStatsStateHelper;
  }

  get overviewStatsStateHelper(): OverviewStatsStateHelper {
    return this._overviewStatsStateHelper;
  }


  get gameSystemStatsHelper(): GameSystemStatsStateHelper {
    return this._gameSystemStatsHelper;
  }

  public onPlayerSelected(playerId: number): void {
    this.logger.debug('AdvancedStatsComponent.onPlayerSelected', playerId);
    if (playerId) {
      this._individualStatsStateHelper.selectPlayer(playerId);
    } else {
      this._individualStatsStateHelper.deSelectPlayer();
    }
  }
}

