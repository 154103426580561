<div class="accordion-container" (click)="toggleAccordion()" [ngClass]="isListItemOpened ? 'open' : ''">
  <h1>
    {{ name }}
    <div class="arrow"></div>
  </h1>

  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
