<div class="quick-stats-team">
    <h2 class="select-label">
        {{'PLAYER PERFORMANCE'|translate}}
    </h2>
    <hr class="separator">
    <div *ngIf="core.isMobileDevice" class="expand-toggle">
        <ion-toggle (ionChange)="onExpandPlayerPerformanceTableColumnsClick()"></ion-toggle>
    </div>
    <!-- Field Player Table -->
    <ngx-datatable class="material" [rows]="simplifiedFieldPlayerModels" [columns]="playerTableColumns" rowHeight="auto"
        [footerHeight]="false" [headerHeight]="50" [columnMode]="'force'" [scrollbarH]="true">
        <ngx-datatable-column [prop]="playerTableColumns[0].prop" [name]="playerTableColumns[0].name | translate" [sortable]="false"
            [resizeable]="false" [width]="80" [frozenLeft]="true"
            [cellClass]="'first-column'">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <span class="text-start">{{value}}</span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngFor="let playerTableColumn of (playerTableColumns | slice:1); let i = index"
            [prop]="playerTableColumn.prop" [name]="playerTableColumn.name | translate" [sortable]="false" [resizeable]="false"
            [width]="i >= 10 ? 50 : 50" [frozenLeft]="(i >= 10) && (screenWidth$ | async) < 930" [cellClass]="getCellClassPlayerTable"
            [headerClass]="getCellClassPlayerTable">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div *ngIf="!isNumber(value)">
                    <div *ngFor="let symbol of playerTableLegendMap; let i = index" style="display:inline;">
                        <ng-container *ngIf="value[symbol.prop] > 0">
                            <img *ngFor="let item of [].constructor(value[symbol.prop])"
                                src="/assets/playerstatsIcons/{{symbol.img}}"
                                style="max-height: 14px; max-width: 14px; display:inline;">
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="isNumber(value)" style="display: inline-flex;"><span>&nbsp;{{value | number: '1.0-1' }}</span><span
                        *ngIf="playerTableColumn.name.includes('%')">%</span></div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <!-- Goal Keeper Table -->
    <ngx-datatable class="material mt-4" [rows]="simplifiedGoalkeeperPlayerModels" [columns]="goalKeeperTableColumns"
        rowHeight="auto" [footerHeight]="false" [headerHeight]="50" [columnMode]="'force'" [scrollbarH]="true">
        <ngx-datatable-column [prop]="goalKeeperTableColumns[0].prop" [name]="goalKeeperTableColumns[0].name | translate" [width]="80"
            [sortable]="false" [resizeable]="false"
            [frozenLeft]="true" [cellClass]="'first-column'">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <span class="text-start">{{value}}</span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngFor="let goalKeeperColumn of (goalKeeperTableColumns | slice:1); let i = index"
                              [prop]="goalKeeperColumn.prop" [name]="goalKeeperColumn.name | translate" [sortable]="false" [resizeable]="false"
                              [width]="i >= 10 ? 50 : 50" [frozenLeft]="(i >= 10) && (screenWidth$ | async) < 930"
                              [cellClass]="getCellClassPlayerTable" [headerClass]="getCellClassPlayerTable">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div *ngIf="!isNumber(value)">
                    <div *ngFor="let symbol of goalKeeperTableLegendMap; let i = index" style="display:inline;">
                        <ng-container *ngIf="value[symbol.prop] > 0 && symbol.img !== '1x1.png'">
                            <img *ngFor="let item of [].constructor(value[symbol.prop])"
                                src="/assets/playerstatsIcons/{{symbol.img}}"
                                style="max-height: 14px; max-width: 14px; display:inline;">
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="isNumber(value)" style="display: inline-flex;"><span>&nbsp;{{value | number: '1.0-1' }}</span><span
                        *ngIf="goalKeeperColumn.name.includes('%')">%</span></div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <div class="w-100 pt-2" style="text-align: center;">
        <div *ngFor="let symbol of playerTableForLegendDisplay; let i = index" style="display:inline;">
            <div style="display: inline-flex; font-size: 12px; flex-wrap: nowrap; line-height: 18px; text-align: center;">
                <img src="/assets/playerstatsIcons/{{symbol.img}}"
                    style="margin-left: 4px; margin-right: 4px;max-height: 18px; max-width: 18px; display:inline;" />
                {{symbol.name | translate}}
            </div>
        </div>
    </div>
    <!-- Goal By Goal Table -->
    <ng-container *ngIf="isNumber(teamGoalByGoalRows[0][1]) || isNumber(teamGoalByGoalRows[1][1])">

        <h2 class="select-label">
            {{'GOAL BY GOAL'|translate}}
        </h2>
          <hr class="separator">
        <ngx-datatable class="material" [rows]="teamGoalByGoalRows" [columns]="teamGoalByGoalColumns" [columnMode]="'force'"
            rowHeight="auto" [footerHeight]="false" [scrollbarH]="true" [headerHeight]="false">
            <ngx-datatable-column [prop]="0" [name]="teamGoalByGoalColumns[0].name"  [width]="140" [sortable]="false"
                [resizeable]="false" [cellClass]="getCellClass" [frozenLeft]="true">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span class="text-start font-weight: bold;">{{value}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let teamGoalByGoalColumn of (teamGoalByGoalColumns | slice:1:51); let i = index"
                [prop]="i+1" name="" [width]="25" [sortable]="false" [resizeable]="false" [cellClass]="getCellClass">
                <!-- <ng-template let-value="value" ngx-datatable-header-template>
                    <span>{{i+1}}</span>
                </ng-template> -->
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span>{{value}}</span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>

        <ngx-datatable *ngIf="isNumber(teamGoalByGoalRows[0][51]) || isNumber(teamGoalByGoalRows[1][51])" class="material"
            [rows]="teamGoalByGoalRows" [columns]="(teamGoalByGoalColumns | slice:51)" [columnMode]="'force'"
            rowHeight="auto" [footerHeight]="false" [headerHeight]="false" [rowClass]="getRowClass" style="padding-top: 7px;" [scrollbarH]="true">
            <ngx-datatable-column [prop]="0" [name]="teamGoalByGoalColumns[0].name" [width]="140" [sortable]="false"
                [resizeable]="false" [cellClass]="getCellClass" [frozenLeft]="true">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span style="font-weight: bold; padding-left: 7px; padding-right: 7px;">{{value}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let teamGoalByGoalColumn of (teamGoalByGoalColumns | slice:1:51); let i = index"
                [prop]="i+51" name="" [width]="25" [sortable]="false" [resizeable]="false" [cellClass]="getCellClass">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span>{{value}}</span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </ng-container>
</div>
