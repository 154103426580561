import { Observable } from 'rxjs';
import { TimelineConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/timeline/timeline-consumer.service';

export class TimelineStatsStateHelper {

    private _teamStatsStateMap = new Map<string, Observable<any>>();

    constructor(
        private readonly timelineConsumerService: TimelineConsumerService,
    ) {
        this._teamStatsStateMap.set('goalsFirstHalf', this.timelineConsumerService.timeLineStatsGoalsFirstHalf$);
        this._teamStatsStateMap.set('goalsSecondHalf', this.timelineConsumerService.timeLineStatsGoalsSecondHalf$);
        this._teamStatsStateMap.set('goalPossessionOverview', this.timelineConsumerService.timeLineStatsGoalPossessionOverview$);
        this._teamStatsStateMap.set('xGoalGraph', this.timelineConsumerService.timeLineStatsXGoalGraph$);
        this._teamStatsStateMap.set('accumulatedCounterList', this.timelineConsumerService.accumulatedCounterList$);
        this._teamStatsStateMap.set('semaphor', this.timelineConsumerService.timeLineSemaphor$);
    }

    public getObservableByName(name: string): Observable<any> {
        return this._teamStatsStateMap.get(name);
    }

}
