import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/shared-services/core.service';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-sync-popver',
    templateUrl: './sync-popver.component.html',
    styleUrls: ['./sync-popver.component.scss'],
    standalone: false
})
export class SyncPopverComponent implements OnInit {

  private _syncStarted = false;

  constructor(
      private readonly core: CoreService,
      private readonly popoverController: PopoverController,
  ) { }

  ngOnInit(): void {
  }

  public async onStartSync(): Promise<void> {
    this._syncStarted = true;
    await this.core.houseKeepingService.processPendingGame();
    const popover = await this.popoverController.getTop();
    if (popover) {
      await this.popoverController.dismiss();
    }
  }

  public async dismiss(): Promise<void> {
    if (!this.syncStarted) {
      await this.popoverController.dismiss();
    }
  }

  get syncStarted(): boolean {
    return this._syncStarted;
  }
}
