import { EventEmitter, Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { environment } from "src/environments/environment";
import { CoreService } from "../core.service";
import { NGXLogger } from "ngx-logger";
import { DateTime } from "luxon";
import { PlayByPlayDto, PlayTimeDto } from "src/app/api/hai-api";
import { transformPlayByPlayToServerModel, transformPlayTimeEventsToServerModel } from "./game-data.helper";

@Injectable({
  providedIn: "root",
})
export class WebSocketService {
  core: CoreService = null;
  gamePushSyncTickWorking = false;
  ackEvents: EventEmitter<string> = new EventEmitter<string>();

  socket?: Socket;

  constructor(private readonly logger: NGXLogger) {}

  public initCore(core: CoreService) {
    this.core = core;
  }

  async connectGamePush() {
    this.socket = new Socket({
      url: environment.wssPath + "/gamepush/" + this.core.gameDataService.getGameIdRunning(),
      options: {
        extraHeaders: {
          Authorization: `Bearer ${await this.core.storageService.getAccessTokenAsPromise()}`,
        },
        // query: {
        //   payload: cookieService.get('user')
        // }
      },
    });
    this.socket.on("pbp-ack", (ack: 'ok'|'ko', orderId: number) => {
      console.log('PBP:ACK', ack, orderId);
      if (ack == 'ok') {
        this.core.gameDataService.playByPlayUploadQueue.delete(orderId);
      }
    });
    this.socket.on("ptm-ack", (ack: 'ok'|'ko', orderId: number) => {
      console.log('PTM:ACK', ack, orderId);
      if (ack == 'ok') {
        this.core.gameDataService.playTimeRecordUploadQueue.delete(orderId);
      }
    });
  }

  disconnect() {
    this.socket?.disconnect();
    this.socket = undefined;
  }

  gamePushSyncTick() {
    if (this.gamePushSyncTickWorking) {
      return;
    }
    try {
      this.gamePushSyncTickWorking = true;

      this.logger.debug(
        `GameDataService update trigger fired for ${this.core.gameDataService.playByPlayUploadQueue.size} pbp entries and ${this.core.gameDataService.playTimeRecordUploadQueue.size} pbt entries`,
        DateTime.now()
      );
      if (this.core.gameDataService.playByPlayUploadQueue.size > 0) {
        const tempPbpContent = Array.from(
          this.core.gameDataService.playByPlayUploadQueue.values()
        );
        try {
          this.sendPbp(transformPlayByPlayToServerModel(tempPbpContent));
          // TODO: Store server ids or boolean flag to related indexedDB elements (not functional stuff)
        } catch (exc) {
          this.logger.error(
            "GameDataService - Error while sending playByPlayRecord to wss server",
            exc
          );
        }
      }
      if (this.core.gameDataService.playTimeRecordUploadQueue.size > 0) {
        const tempPltContent = Array.from(
          this.core.gameDataService.playTimeRecordUploadQueue.values()
        );
        try {
          this.sendPtm(transformPlayTimeEventsToServerModel(tempPltContent));
          // TODO: Store server ids or boolean flag to related indexedDB elements (not functional stuff)
        } catch (exc) {
          this.logger.error(
            "GameDataService - Error while sending playTimeRecord to wss server",
            exc
          );
        }
      }
    } catch (err) {
      console.error('WSS Sync error', err);
    } finally {
      this.gamePushSyncTickWorking = false;
    }
  }

  public sendPbp(data: PlayByPlayDto[]) {
    this.socket!.emit("pbp", data);
  }
  public sendPtm(data: PlayTimeDto[]) {
    this.socket!.emit("ptm", data);
  }
}
