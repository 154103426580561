import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "app-video-popup",
  templateUrl: "./video-folder-popup.component.html",
  styleUrls: ["./video-folder-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoFolderPopupComponent implements AfterViewInit {
  @ViewChild("videoPlayer") videoPlayer: ElementRef<HTMLVideoElement>;
  @Input() src: string;
  @Input() videoCurrentTime: number;
  @Input() fromFolder?: boolean;

  constructor(private readonly popoverController: PopoverController) {}


  ngAfterViewInit(): void {
    this.videoPlayer.nativeElement.currentTime = this.videoCurrentTime;
    this.videoPlayer.nativeElement.play();
    this.videoPlayer.nativeElement.muted = true;
  }

  public async close(): Promise<void> {
    await this.popoverController.dismiss();
  }

  public async cancel(): Promise<void> {
    await this.popoverController.dismiss();
  }
}
