import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import {
    EventTime,
    GameModel,
    HalftimeTypes,
    PlayByPlayModel,
    PlayerEvent,
    PlayerEventModel,
    PlaytimeEventModel
} from '@handballai/stats-calculation';
import { BehaviorSubject } from 'rxjs';
import { CoreService } from 'src/app/shared-services/core.service';
import { DateTime } from 'luxon';
import {
    calculateNextTimerModelFromVideoTimerTick,
    calculateScore,
    ClockEvent,
    ClockModeType,
    ExtendedTimerModel,
    GameClockTick, HalftimeTypesExtended,
    PossessionCounterHolder,
    TimeContainer,
    VideoPlayByPlay,
    VideoPlayTimeContainer, VideoPlayTimeCounter,
    VideoPlayTimeEvent,
    VideoTrackerContainer
} from 'src/app/shared-services/statistics/video/model/video-tracker.util';
import {
    createPbpRecord,
    createVideoPlayTimeEventFromGamePlayerModel
} from 'src/app/shared-services/statistics/video/model/video-tracker-conversion.helper';
import { db } from 'src/app/db';
import { transformPlayByPlayToServerModel, transformPlayTimeEventsToServerModel } from 'src/app/shared-services/game-data/game-data.helper';
import { GamePlayerModel } from 'src/app/shared-services/model/game.model';
import {
    eventToPossessionMapper,
    populateGamePlayerMap,
    populateVideoPlayTimeMap
} from 'src/app/shared-services/statistics/ingest/event-feed.util';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { GameDto, PlayByPlayDto, PlayTimeDto } from 'src/app/api/hai-api';
import { GameEndStorageModel } from 'src/app/shared-services/storage-service/model/game-end-storage.model';
import { PopoverController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import {
    TMinimalTeamOverviewStatsModel
} from 'src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/field-player/model/minimal-team-stats.model';
import { calculateMinimalOverviewStatsModel } from 'src/app/shared-services/statistics/video/model/minimal-stats-calculation.helper';
import { deleteEventHandler } from 'src/app/shared-services/statistics/video/model/edit-event.helper';
import { TeamSelectPopupComponent } from 'src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/team-select-popup/team-select-popup.component';
import { HalfTime } from '../../timer/timer-model';

const updateEvents = <T>(events: T[], event: T, orderKey: keyof T): T[] => events.reduce((accu, curr) => {
    if (curr[orderKey] !== event[orderKey]) {
        accu.push(curr);
    } else {
        accu.push(event);
    }
    return accu;
}, []);

const deleteEvents = <T extends Record<K, number>, K extends keyof T>(events: T[], event: T, orderKey: K): T[] => events
    .filter(ev => ev[orderKey] !== event[orderKey])
    .reduce((accu, curr, indx) => {
        (<number> curr[orderKey]) = indx - 1;
        accu.push(curr);
        return accu;
    }, []);

@Injectable({
    providedIn: 'root'
})
export class VideoTrackerService {

    // Remember to reset all variables added on init
    private _videoPlayByPlayEvents: VideoPlayByPlay[] = [];
    private _clockEvents: ClockEvent[] = [];
    private _videoPlayTimeEvents: VideoPlayTimeEvent[] = [];
    private _videoPlayTimeCounter = new VideoPlayTimeCounter();
    private _videoPlayTimeMap: Map<number, VideoPlayTimeContainer>;
    private _maxVideoCounter = 0;
    private _counterPlayByPlay = 1;
    private _videoPlayByPlayEvents$ = new BehaviorSubject<VideoPlayByPlay[]>([]);
    private _clockEvents$ = new BehaviorSubject<ClockEvent[]>([]);
    private _videoPlayTimeEvents$ = new BehaviorSubject<VideoPlayTimeEvent[]>([]);
    private _core: CoreService;
    private _currentVideoTime = 0;
    private _isGameRunning = false;
    private _gameRunning$ = new BehaviorSubject<boolean>(false);
    private _gameEnded$ = new BehaviorSubject<boolean>(false);
    private _isFirstHalftimeNotStarted = true;
    private _isSecondHalfTimeNotStarted = true;
    private _isFirstHalftimeETStarted = false;
    private _isSecondHalfTimeETStarted = false;
    private _penaltiesStarted = false;
    private _startTime: DateTime = DateTime.now();
    private _offsetGameStart = 0;
    private _offsetSecondHalfGameStart = 0;
    private _firstHalfEnd = 0;
    private _offsetSecondHalf = Number.MAX_VALUE;
    private _offsetETFirstHalf = Number.MAX_VALUE;
    private _offsetETSecondHalf = Number.MAX_VALUE;
    private _offsetPenalties = Number.MAX_VALUE;
    private _homeScore$ = new BehaviorSubject<number>(0);
    private _visitorScore$ = new BehaviorSubject<number>(0);
    private _gameModel: GameModel;
    private _gameId: number;
    private gamePlayerMap = new Map<number, GamePlayerModel>();
    private _currentPossessionOwner: TeamMarker | 'INITIAL_OWNER' = 'INITIAL_OWNER';
    private _currentPossessionId = 0;
    private _currentPossessionMap = new Map<TeamMarker, number>([
        ['HOME', 0],
        ['VISITOR', 0]
    ]);
    private _startingTeam: TeamMarker;

    private _homeOverviewStatsModel$ = new BehaviorSubject<TMinimalTeamOverviewStatsModel[]>([]);
    private _visitorOverviewStatsModel$ = new BehaviorSubject<TMinimalTeamOverviewStatsModel[]>([]);

    private _gameDto: GameDto;
    private _gameCounter$ = new BehaviorSubject<ExtendedTimerModel>({
        counterSecSinceStart: 0,
        gameCounter: '00:00',
        seconds: 0,
        minutes: 0,
        halfTime: 'T1',
        wallClock: this._startTime,
        currentClockMode: 'STOP'
    });

    private _currentVideoTime$ = new BehaviorSubject<number>(0);
    private _videoTrackerContainerFirstHalf: VideoTrackerContainer;
    private _videoTrackerContainerSecondHalf: VideoTrackerContainer;
    private _videoTrackerContainerET1: VideoTrackerContainer;
    private _videoTrackerContainerET2: VideoTrackerContainer;
    private _videoTrackerContainerP: VideoTrackerContainer;
    public videoTimeOnLoad?: number;
    public lastGameSave$ = new BehaviorSubject<Date|false>(undefined);

    constructor(
        private readonly logger: NGXLogger,
        private readonly toastCntl: ToastController,
        private readonly router: Router,
        private readonly popoverController: PopoverController,
    ) {
    }


    public initCore(coreService: CoreService): void {
        this._core = coreService;
        this.resetSubjects();
    }

    // TODO Initialize DateTime
    public init(
        startDateTime: DateTime,
        gameModel: GameModel,
        gameDto: GameDto,
        isContinueGame: boolean = false,
    ): void {
        this._counterPlayByPlay = 1;
        this._currentPossessionOwner = 'INITIAL_OWNER';
        this._currentPossessionId = 0;
        this._videoPlayTimeCounter = new VideoPlayTimeCounter();
        this._maxVideoCounter = 0;
        this._currentPossessionMap = new Map<TeamMarker, number>([
            ['HOME', 0],
            ['VISITOR', 0]
        ]);
        this._startTime = startDateTime;
        this._gameModel = gameModel;
        this._gameDto = gameDto;
        this._gameId = gameDto.id;
        this.resetSubjects();
        this.resetGame();
        this.createGamePlayerMap();
        // Moved to first half start:
        // if (!isContinueGame) {
        //     this.addPlayerEvents('T1', 'PLAY_TIME_START', 0);
        // }
    }

    get videoPlayByPlayEvents$(): BehaviorSubject<VideoPlayByPlay[]> {
        return this._videoPlayByPlayEvents$;
    }

    get clockEvents$(): BehaviorSubject<ClockEvent[]> {
        return this._clockEvents$;
    }

    get videoPlayTimeEvents$(): BehaviorSubject<PlaytimeEventModel[]> {
        return this._videoPlayTimeEvents$;
    }

    get gameCounter$(): BehaviorSubject<ExtendedTimerModel> {
        return this._gameCounter$;
    }

    get gameRunning$(): BehaviorSubject<boolean> {
        return this._gameRunning$;
    }

    get gameEnded$(): BehaviorSubject<boolean> {
        return this._gameEnded$;
    }

    get offsetGameStart(): number {
        return this._offsetGameStart;
    }
    get offsetSecondHalfGameStart(): number {
        return this._offsetSecondHalfGameStart;
    }

    get currentVideoTime$(): BehaviorSubject<number> {
        return this._currentVideoTime$;
    }

    get currentVideoTime(): number {
        return this._currentVideoTime;
    }


    get isSecondHalfTimeNotStarted(): boolean {
        return this._isSecondHalfTimeNotStarted;
    }


    get isFirstHalftimeNotStarted(): boolean {
        return this._isFirstHalftimeNotStarted;
    }

    get isFirstHalftimeETStarted(): boolean {
        return this._isFirstHalftimeETStarted;
    }

    get isSecondHalfTimeETStarted(): boolean {
        return this._isSecondHalfTimeETStarted;
    }

    get penaltiesStarted(): boolean {
        return this._penaltiesStarted;
    }

    get homeScore$(): BehaviorSubject<number> {
        return this._homeScore$;
    }

    get visitorScore$(): BehaviorSubject<number> {
        return this._visitorScore$;
    }

    get homeOverviewStatsModel$(): BehaviorSubject<TMinimalTeamOverviewStatsModel[]> {
        return this._homeOverviewStatsModel$;
    }

    get visitorOverviewStatsModel$(): BehaviorSubject<TMinimalTeamOverviewStatsModel[]> {
        return this._visitorOverviewStatsModel$;
    }

    public addClockEvent(
        clockModeType: ClockModeType,
        videoTimestamp: number,
        gameClockTimeStamp: number,
    ): void {
        if (this._gameDto.gameType == 'TAGGING_MODE') {
            // On tagging mode we do not use time tracking (game clock should be everytime 0)
            clockModeType = 'STOP';
        }

        const currVideoTimeRounded = Math.round(this._currentVideoTime$.value);
        if (this._isSecondHalfTimeNotStarted) {
            if (!this._isGameRunning) {
                // Initialise first half
                this._maxVideoCounter = currVideoTimeRounded;
                this._offsetGameStart = currVideoTimeRounded;
                this._isFirstHalftimeNotStarted = false;
                this._isGameRunning = true;
                this._gameRunning$.next(this._isGameRunning);
                const ev = {
                    eventType: 'FIRST_HALF_START',
                    eventTime: {
                      timestamp: this.gameCounter$.value.wallClock,
                      halftime: 'T1',
                      secondsSinceHalftime: this.gameCounter$.value.seconds,
                      minutesSinceHalftime: this.gameCounter$.value.minutes,
                      secondsSinceStartOfGame: currVideoTimeRounded
                    },
                    phase: 'OFFENSE_POSITIONAL',
                } as PlayerEvent;
                this._core.popoverCtrl.create({
                    component: TeamSelectPopupComponent,
                    cssClass: 'global__popover global__popover--doubleEvent',
                    // translucent: true,
                    backdropDismiss: false,
                }).then(p => {
                    p.onDidDismiss().then((d) => {
                      this._startingTeam = d.data.data;
                      this._core.eventBufferService.addPlayByPlayEvent(
                        this._startingTeam,
                        ev,
                        this._core.gameService.gameModel[this._startingTeam=='HOME'?'home':'visitor'].offenseSystem
                      );
                      this._core.eventBufferService.closeTransactionNow();
                    });
                    p.present();
                });
                this.addPlayerEvents('T1', 'PLAY_TIME_START', 0);
            }
        } else if (!this._isSecondHalfTimeNotStarted && !this._isFirstHalftimeETStarted) {
            if (!this._isGameRunning) {
                // Initialise second half
                this._maxVideoCounter = currVideoTimeRounded;
                this._offsetSecondHalfGameStart = currVideoTimeRounded;
                this._isSecondHalfTimeNotStarted = false;
                this._isGameRunning = true;
                this._gameRunning$.next(this._isGameRunning);
                gameClockTimeStamp = 0;
                this._gameModel = this._core.gameService.gameModel;

                this._core.eventBufferService.addPlayByPlayEvent(
                    this._startingTeam == 'HOME' ? 'VISITOR':'HOME',
                    {
                        eventType: 'SECOND_HALF_START',
                        eventTime: {
                            timestamp: this.gameCounter$.value.wallClock,
                            halftime: 'T2',
                            secondsSinceHalftime: this.gameCounter$.value.seconds,
                            minutesSinceHalftime: this.gameCounter$.value.minutes,
                            secondsSinceStartOfGame: currVideoTimeRounded
                        },
                        phase: 'OFFENSE_POSITIONAL',
                    } as PlayerEvent,
                    this._core.gameService.gameModel[this._startingTeam!='HOME'?'home':'visitor'].offenseSystem,
                );
                this._core.eventBufferService.closeTransactionNow();
                this.addPlayerEvents('T2', 'PLAY_TIME_START', currVideoTimeRounded);
            }
        } else {
            if (!this._isGameRunning) {
                let eventType;
                let halfTime: HalftimeTypes;
                if (this._isFirstHalftimeETStarted && !this._isSecondHalfTimeETStarted) {
                    eventType = 'EXTRA_TIME_ONE_START';
                    halfTime = 'ET1';
                } else if (this._isSecondHalfTimeETStarted && !this._penaltiesStarted) {
                    eventType = 'EXTRA_TIME_TWO_START';
                    halfTime = 'ET2';
                } else {
                    eventType = 'PENALTIES_START';
                    halfTime = 'P';
                }
                this._isGameRunning = true;
                this._gameRunning$.next(this._isGameRunning);
                gameClockTimeStamp = 0;
                this._gameModel = this._core.gameService.gameModel;

                this._core.eventBufferService.addPlayByPlayEvent(
                    this._startingTeam == 'HOME' ? 'VISITOR' : 'HOME',
                    {
                        eventType: eventType,
                        eventTime: {
                            timestamp: this.gameCounter$.value.wallClock,
                            halftime: halfTime,
                            secondsSinceHalftime: this.gameCounter$.value.seconds,
                            minutesSinceHalftime: this.gameCounter$.value.minutes,
                            secondsSinceStartOfGame: currVideoTimeRounded
                        },
                        phase: 'OFFENSE_POSITIONAL',
                    } as PlayerEvent,
                    this._core.gameService.gameModel[this._startingTeam!='HOME'?'home':'visitor'].offenseSystem,
                );
                this._core.eventBufferService.closeTransactionNow();
                this._maxVideoCounter = currVideoTimeRounded;
                this.addPlayerEvents(halfTime, 'PLAY_TIME_START', currVideoTimeRounded);
            }
        }

        if (videoTimestamp <= this._offsetSecondHalf) {
            this._videoTrackerContainerFirstHalf.addNode(clockModeType, videoTimestamp, gameClockTimeStamp);
        } else if (videoTimestamp > this._offsetSecondHalf && !this._isFirstHalftimeETStarted) {
            this._videoTrackerContainerSecondHalf.addNode(clockModeType, videoTimestamp, gameClockTimeStamp);
        } else if (this._isFirstHalftimeETStarted && !this._isSecondHalfTimeETStarted) {
            this._videoTrackerContainerET1.addNode(clockModeType, videoTimestamp, gameClockTimeStamp);
        } else if (this._isSecondHalfTimeETStarted && !this._penaltiesStarted) {
            this._videoTrackerContainerET2.addNode(clockModeType, videoTimestamp, gameClockTimeStamp);
        } else {
            this._videoTrackerContainerP.addNode(clockModeType, videoTimestamp, gameClockTimeStamp);
        }

    }

    public findPreviousEvent(videoPlayByPlay: VideoPlayByPlay): VideoPlayByPlay {
        // Candidate is the event in the same half with greater secondsSinceStartOfGame (lower than current event)
        const candidate = this._videoPlayByPlayEvents.filter(itm => (
            itm.eventTime.secondsSinceStartOfGame < videoPlayByPlay.eventTime.secondsSinceStartOfGame &&
            itm.eventTime.halftime == videoPlayByPlay.eventTime.halftime
        )).sort(
            (a, b) => b.eventTime.secondsSinceStartOfGame - a.eventTime.secondsSinceStartOfGame
        )[0] ?? null;
        return candidate;
    }

    public addPlayByPlayEvent(videoPlayByPlay: VideoPlayByPlay): void {
        videoPlayByPlay.orderId = this._counterPlayByPlay;
        this._counterPlayByPlay++;
        this._videoPlayByPlayEvents = [...this._videoPlayByPlayEvents, videoPlayByPlay];
        this._videoPlayByPlayEvents$.next([...this._videoPlayByPlayEvents]);
        this.calculateScore();
        this.recalculateMinimalOverviewStats();
    }

    public updatePlayByPlayEvent(videoPlayByPlay: VideoPlayByPlay): void {
        this._videoPlayByPlayEvents = [...updateEvents(this._videoPlayByPlayEvents, videoPlayByPlay, 'orderId')];
        this._videoPlayByPlayEvents$.next(this._videoPlayByPlayEvents);
        this.calculateScore();
        this.recalculateMinimalOverviewStats();
    }

    public async deletePlayByPlayEvent(videoPlayByPlay: VideoPlayByPlay): Promise<void> {
        const res  = await deleteEventHandler(
            this.popoverController,
            this.gamePlayerMap,
            this._core.gameService,
            videoPlayByPlay,
            this._videoPlayByPlayEvents,
            this._videoTrackerContainerFirstHalf,
            this._videoTrackerContainerSecondHalf
        );
        this._videoPlayByPlayEvents = res.eventList;
        this._videoTrackerContainerFirstHalf = res.videoContainerFirstHalf;
        this._videoTrackerContainerSecondHalf = res.videoContainerSecondHalf;

        if (res.createTimerEvent) {
            this.addPlayTimeEvent({
                eventType: 'PLAY_TIME_START',
                tempOrderId: -1,
                eventTime: videoPlayByPlay.eventTime,
                seq: -1,
                playerId: videoPlayByPlay.offensePlayer.id,
                timestamp: 0,
                indexedDbId: 0
            });
        }
        this._videoPlayByPlayEvents$.next([...this._videoPlayByPlayEvents]);
        this.calculateScore();
        this.recalculateMinimalOverviewStats();
    }

    public addPlayTimeEvent(videoPlayTimeEvent: VideoPlayTimeEvent, allowWithoutGameRunning = false): void {
        if (this._isGameRunning || allowWithoutGameRunning) {
            this.logger.debug('VideoTrackerService.addPlayTimeEvent() - video playtime event: ', videoPlayTimeEvent);
            videoPlayTimeEvent.tempOrderId = this._videoPlayTimeCounter.increment();
            this._videoPlayTimeMap.set(
                videoPlayTimeEvent.playerId,
                this._videoPlayTimeMap.get(videoPlayTimeEvent.playerId)
                    .addPlayTimeEvent(
                        videoPlayTimeEvent,
                        allowWithoutGameRunning
                            // If we are not in game running mode, (halfs start/end events) we want to ensure exact time from half start/end instead of _maxVideoCounter from video progress
                            ? videoPlayTimeEvent.eventTime.secondsSinceStartOfGame
                            : this._maxVideoCounter
                    )
            );
        } else {
            this.logger.debug('VideoTrackerService.addPlayTimeEvent() - video playtime event prevented add since !gameIsRunning: ', videoPlayTimeEvent);
        }
    }

    public restoreGameTrackState(pbps: VideoPlayByPlay[], ptms: VideoPlayTimeEvent[]) {
        this._gameEnded$.next(false);
        let offsetGameStart = -1;
        let offsetSecondHalf = -1;
        let offsetETFirstHalf = -1;
        let offsetETSecondHalf = -1;
        let offsetPenalties = -1;
        const firstEventOfHalf: {[key in HalftimeTypesExtended]: VideoPlayByPlay|null} = {T1: null, T2: null, ET1: null, ET2: null, P: null};
        const timeoutIndexes = { HOME: 0, VISITOR: 0 };
        for(const pbp of pbps) {
            this._isGameRunning = true;
            if (pbp.event == 'FIRST_HALF_START') {
                this._isFirstHalftimeNotStarted = false;
            }
            if (pbp.event == 'FIRST_HALF_END') {
                this._isSecondHalfTimeNotStarted = false;
                // To confirm at all that this is correct
                this._firstHalfEnd = pbp.videoTimestamp;
                this._offsetSecondHalf = offsetSecondHalf = pbp.videoTimestamp+1;
                this._isGameRunning = false;

                // // Easy solution without having full video tracking time events
                // this._videoTrackerContainerFirstHalf.addNode('STOP', this._offsetSecondHalf, this.secsFromHalfTimeFromWallClock(pbp.eventTime));
                // this._videoTrackerContainerFirstHalf.endContainer(this._offsetSecondHalf, this.secsFromHalfTimeFromWallClock(pbp.eventTime), false)
            }
            if (pbp.event == 'SECOND_HALF_END') {
                this._isGameRunning = false;
                this._isFirstHalftimeETStarted = true;
                this._offsetETFirstHalf = offsetETFirstHalf = pbp.videoTimestamp+1;
            }
            if (pbp.event == 'EXTRA_TIME_ONE_END') {
                this._isSecondHalfTimeETStarted = true;
                this._isGameRunning = false;
                this._offsetETSecondHalf = offsetETSecondHalf = pbp.videoTimestamp+1;
            }
            if (pbp.event == 'EXTRA_TIME_TWO_END') {
                this._penaltiesStarted = true;
                this._isGameRunning = false;
                this._offsetPenalties = offsetPenalties = pbp.videoTimestamp+1;
            }
            if (pbp.event == 'PENALTIES_END') {
                this._isGameRunning = false;
            }

            // First event of first half (should be FIRST_HALF_START)
            if (firstEventOfHalf.T1==null && pbp.eventTime.halftime == 'T1') {
                firstEventOfHalf.T1 = pbp;
                this._offsetGameStart = offsetGameStart = pbp.videoTimestamp - this.secsFromHalfTimeFromWallClock(pbp.eventTime);
                this._startingTeam = pbp.teamMarker;
                // Maybe enable in future if we plan to allow go back of last pbp event of restore game track state
                // this._videoTrackerContainerFirstHalf.addNode('START', pbp.videoTimestamp, this.secsFromHalfTimeFromWallClock(pbp.eventTime));
            }
            // First event of second half (should be SECOND_HALF_START)
            if (firstEventOfHalf.T2==null && pbp.eventTime.halftime == 'T2') {
                firstEventOfHalf.T2 = pbp;
                // this._offsetSecondHalf = offsetSecondHalf = pbp.videoTimestamp - this.secsFromHalfTimeFromWallClock(pbp.eventTime);
                // Maybe enable in future if we plan to allow go back of last pbp event of restore game track state
                // this._videoTrackerContainerSecondHalf.addNode('START', pbp.videoTimestamp, this.secsFromHalfTimeFromWallClock(pbp.eventTime));
                this._core.gameService.enableAllowedTimeOuts();
            }
            // Other half times enable timeouts
            if (['ET1', 'ET2', 'P'].includes(pbp.eventTime.halftime) && firstEventOfHalf[pbp.eventTime.halftime]==null) {
                firstEventOfHalf[pbp.eventTime.halftime] = pbp;
                this._core.gameService.enableAllowedTimeOuts();
            }

            // Apply consumed timeouts
            if (pbp.event == 'TIME_OUT') {
                this._core.gameService.applyTimeOut({teamMarker: pbp.teamMarker, index: timeoutIndexes[pbp.teamMarker]++});
            }

            // Apply defense sytem change
            this._core.gameService.changeDefenseSystem({teamMarker: pbp.teamMarker == 'HOME'?'VISITOR':'HOME', defenseSystem: pbp.defenseSystem});
        }

        if (pbps.length) {
            const lastPbp = pbps[pbps.length-1];
            console.log('LAST PBP', lastPbp);

            const fieldPlayers = {
                HOME: (lastPbp.teamMarker == 'HOME' ? lastPbp.offense:lastPbp.defense).map(pl => pl.id),
                VISITOR: (lastPbp.teamMarker == 'HOME' ? lastPbp.defense:lastPbp.offense).map(pl => pl.id),
            }

            // Load last moment field players
            this._core.gameService.replaceFieldPlayers(
                fieldPlayers.HOME,
                fieldPlayers.VISITOR,
            );

            const lastSuspendedPlayers: {HOME: VideoPlayByPlay[], VISITOR: VideoPlayByPlay[]} = {HOME:[], VISITOR:[]};
            for (const team of ['HOME', 'VISITOR'] as TeamMarker[]) {
                if (fieldPlayers[team].length < 7) { // This only can happen on COMPLETE_MODE
                    // Should be suspended players to mark them as not available, how many?
                    const suspendedPlayers = 7 - fieldPlayers[team].length;

                    // Find last suspended players
                    lastSuspendedPlayers[team] = pbps.filter(pbp => ['2_MIN', 'RED_CARD', 'BLUE_CARD', 'SUSPENSION_TO_BENCH_2_MIN', 'SUSPENSION_TO_BENCH_RED_CARD', 'SUSPENSION_TO_BENCH_BLUE_CARD'].includes(pbp.event) && pbp.teamMarker == team).slice(-suspendedPlayers);

                    // Mark them as suspended
                    for (const suspendedPlayer of lastSuspendedPlayers[team]) {
                        const suspendedPlayerModel: GamePlayerModel = this._core.gameService[team=='HOME' ? 'homeBench$':'visitorBench$'].value.find(pl => pl.id == suspendedPlayer.offensePlayer.id);
                        suspendedPlayerModel.suspensionType = ['RED_CARD', 'BLUE_CARD'].includes(suspendedPlayer.event) ? 'GAME_SUSPENSION':'REGULAR_SUSPENSION';
                        this._core.gameService.continueTrackingSetPlayerPenalty(suspendedPlayerModel, team);
                    }
                }

            }
            // Remove RED_CARD, BLUE_CARD players that are not on penaltyTime from bench
            pbps.filter(pbp => ['RED_CARD', 'BLUE_CARD'].includes(pbp.event)).forEach(pbp => {
                const player = pbp.offensePlayer;
                if (player && lastSuspendedPlayers[pbp.teamMarker].findIndex(p => p.offensePlayer.id == player.id) == -1) {
                    this._core.gameService.continueTrackingRemoveGameSuspendedPlayer(player.id, pbp.teamMarker);
                }
            });


            const videoTrackerContiner = {
                'T1': '_videoTrackerContainerFirstHalf',
                'T2': '_videoTrackerContainerSecondHalf',
                'ET1': '_videoTrackerContainerET1',
                'ET2': '_videoTrackerContainerET2',
                'P': '_videoTrackerContainerP',
            }[lastPbp.eventTime.halftime];

            if (lastPbp.event == 'TIME_OUT') {
                // If we have stopped by timeout, initialialise linear function 1sec before and set a const function from the event time
                // this[lastPbp.eventTime.halftime=='T1' ? '_videoTrackerContainerFirstHalf':'_videoTrackerContainerSecondHalf'].addNode('START', lastPbp.videoTimestamp-1, lastPbp.eventTime.secondsSinceStartOfGame-1);
                this[videoTrackerContiner].addNode('STOP', lastPbp.videoTimestamp, this.secsFromHalfTimeFromWallClock(lastPbp.eventTime)); // .eventTime.secondsSinceHalftime
            } else {
                // Initialialise linear function from the event+video time of lastpbp (requires not possible to go back)
                this[videoTrackerContiner].addNode('START', lastPbp.videoTimestamp, this.secsFromHalfTimeFromWallClock(lastPbp.eventTime));
            }

            // Buffer video at latest event on load
            this.videoTimeOnLoad = lastPbp.videoTimestamp;
        }
        this._gameRunning$.next(this._isGameRunning);

        // this._gameCounter$.next({
        //     counterSecSinceStart: 0,
        //     gameCounter: '00:00',
        //     seconds: 0,
        //     minutes: 0,
        //     halfTime: 'T1',
        //     wallClock: DateTime.now(),
        //     currentClockMode: 'STOP'
        // });
        // this._core.gameService.resetTimeOut();

        // Debug PTM records restore & calculations
        // console.log('PTMs Original', transformPlayTimeEventsToServerModel(ptms))
        // const firstHalfTimeLength = this._videoTrackerContainerFirstHalf.trackerList.length ? this._videoTrackerContainerFirstHalf.lengthOfHalftimeGameTime : 0;
        // console.log('FHL', firstHalfTimeLength)
        // this.recalculatePlayTimeEventsAndReplaceAtLocalDb(firstHalfTimeLength).then(ptms => {
        //     console.log('PTMs Changed', transformPlayTimeEventsToServerModel(ptms))
        // });
    }

    public updatePlayTimeEvent(videoPlayTimeEvent: VideoPlayTimeEvent): void {
        this._videoPlayTimeEvents = [...updateEvents(this._videoPlayTimeEvents, videoPlayTimeEvent, 'tempOrderId')];
        this._videoPlayTimeEvents$.next(this._videoPlayTimeEvents);
    }

    public deletePlayTimeEvent(videoPlayTimeEvent: VideoPlayTimeEvent): void {
        this._videoPlayTimeEvents = [...deleteEvents(this._videoPlayTimeEvents, videoPlayTimeEvent, 'tempOrderId')];
        this._videoPlayTimeEvents$.next(this._videoPlayTimeEvents);
    }

    public onVideoProgress(videoTimeSec: number): void {
        this.logger.debug('VideoTrackerService.onVideoProgress', videoTimeSec);
        if (videoTimeSec >= this._maxVideoCounter) {
            this._maxVideoCounter = videoTimeSec;
        }
        this._currentVideoTime$.next(this._currentVideoTime = videoTimeSec);
        this.createGameCounterTickBasedOnVideoTime();
    }
    private createGameCounterTickBasedOnVideoTime(): void {
        const videoTimeSec = this._currentVideoTime$.value;
        let res: GameClockTick;
        // FIXME: Refactor next if (keeping only body of this.isFirstHalftimeETStarted==true) once extra-times games are well tested
        if (this.isFirstHalftimeETStarted) {
            if (videoTimeSec <= this._offsetSecondHalf) {
                res = this._videoTrackerContainerFirstHalf.calculateY(videoTimeSec);// check timer calculation
                this._gameCounter$.next(calculateNextTimerModelFromVideoTimerTick(
                    this._startTime,
                    res.gameSeconds,
                    res.clockMode,
                    'T1',
                    Math.round(videoTimeSec)
                ));
            } else if (videoTimeSec > this._offsetSecondHalf && videoTimeSec <= this._offsetETFirstHalf) {
                res = this._videoTrackerContainerSecondHalf.calculateY(videoTimeSec);
                this._gameCounter$.next(calculateNextTimerModelFromVideoTimerTick(
                    this._startTime,
                    res.gameSeconds,
                    res.clockMode,
                    'T2',
                    Math.round(videoTimeSec)
                ));
            } else if (videoTimeSec > this._offsetETFirstHalf && videoTimeSec <= this._offsetETSecondHalf) {
                res = this._videoTrackerContainerET1.calculateY(videoTimeSec);
                this._gameCounter$.next(calculateNextTimerModelFromVideoTimerTick(
                    this._startTime,
                    res.gameSeconds,
                    res.clockMode,
                    'ET1',
                    Math.round(videoTimeSec)
                ));
            } else if (videoTimeSec > this._offsetETSecondHalf && videoTimeSec <= this._offsetPenalties) {
                res = this._videoTrackerContainerET2.calculateY(videoTimeSec);
                this._gameCounter$.next(calculateNextTimerModelFromVideoTimerTick(
                    this._startTime,
                    res.gameSeconds,
                    res.clockMode,
                    'ET2',
                    Math.round(videoTimeSec)
                ));
            } else {
                res = this._videoTrackerContainerP.calculateY(videoTimeSec);
                this._gameCounter$.next(calculateNextTimerModelFromVideoTimerTick(
                    this._startTime,
                    res.gameSeconds,
                    res.clockMode,
                    'P',
                    Math.round(videoTimeSec)
                ));
            }
        } else {
            if (videoTimeSec <= this._offsetSecondHalf) {
                res = this._videoTrackerContainerFirstHalf.calculateY(videoTimeSec);
                this._gameCounter$.next(calculateNextTimerModelFromVideoTimerTick(
                    this._startTime,
                    res.gameSeconds,
                    res.clockMode,
                    'T1',
                    Math.round(videoTimeSec)
                ));
            } else {
                res = this._videoTrackerContainerSecondHalf.calculateY(videoTimeSec);
                this._gameCounter$.next(calculateNextTimerModelFromVideoTimerTick(
                    this._startTime,
                    res.gameSeconds,
                    res.clockMode,
                    'T2',
                    Math.round(videoTimeSec)
                ));

            }
        }
    }


    public resetGame(): void {
        this._isGameRunning = false;
        this._gameRunning$.next(this._isGameRunning);
        this._isFirstHalftimeNotStarted = true;
        this._isSecondHalfTimeNotStarted = true;
        this._isFirstHalftimeETStarted = false;
        this._isSecondHalfTimeETStarted = false;
        this._penaltiesStarted = false;
        this._gameEnded$.next(false);
        this._gameCounter$.next({
            counterSecSinceStart: 0,
            gameCounter: '00:00',
            seconds: 0,
            minutes: 0,
            halfTime: 'T1',
            wallClock: DateTime.now(),
            currentClockMode: 'STOP'
        });
        this._core.gameService.resetTimeOut();
        this.gameEnded$.next(false);
        this._offsetGameStart = 0;
        this._offsetSecondHalf = Number.MAX_VALUE;
        this._offsetETFirstHalf = Number.MAX_VALUE;
        this._offsetETSecondHalf = Number.MAX_VALUE;
        this._offsetPenalties = Number.MAX_VALUE;
        this._firstHalfEnd = 0;
        this._videoTrackerContainerFirstHalf = new VideoTrackerContainer(this.logger);
        this._videoTrackerContainerSecondHalf = new VideoTrackerContainer(this.logger);
        this._videoTrackerContainerET1 = new VideoTrackerContainer(this.logger);
        this._videoTrackerContainerET2 = new VideoTrackerContainer(this.logger);
        this._videoTrackerContainerP = new VideoTrackerContainer(this.logger);
        this.videoTimeOnLoad = undefined;
    }

    public endFirstHalf(
        currentVideoTimestamp: number,
        gameClock: number,
        extraTime?: boolean
    ): void {
        this._isGameRunning = false;
        this._gameRunning$.next(this._isGameRunning);
        this._firstHalfEnd = this._currentVideoTime$.value;
        if (extraTime) {
            this._isSecondHalfTimeETStarted = true;
            this._offsetETSecondHalf = Math.round(this._currentVideoTime$.value)+1;
        }
        this._isFirstHalftimeNotStarted = false;
        this._isSecondHalfTimeNotStarted = false;
        if (!extraTime) {
            this._offsetSecondHalf = Math.round(this._currentVideoTime$.value)+1;
        }
        console.log('setting up this._offsetSecondHalf', this._offsetSecondHalf)

        let res = null;
        if (extraTime) {
            res = this._videoTrackerContainerET1.calculateY(currentVideoTimestamp+1); // +1 to ensure current second is bound inside
            this._videoTrackerContainerET1.endContainer(currentVideoTimestamp+1, gameClock); // +1 to ensure current second is bound inside
        } else {
            res = this._videoTrackerContainerFirstHalf.calculateY(currentVideoTimestamp+1); // +1 to ensure current second is bound inside
            this._videoTrackerContainerFirstHalf.endContainer(currentVideoTimestamp+1, gameClock); // +1 to ensure current second is bound inside
        }

        this._gameCounter$.next(calculateNextTimerModelFromVideoTimerTick(
            this._startTime,
            res.gameSeconds,
            'STOP',
            extraTime ? 'ET2' : 'T1',
            this._currentVideoTime$.value
        ));
        const teamMarker = this._videoPlayByPlayEvents.sort(
            (a, b) => b.eventTime.secondsSinceStartOfGame - a.eventTime.secondsSinceStartOfGame
        )[0].teamMarker;
        const currentVideoTimestampRounded = Math.round(this._currentVideoTime);
        this._core.eventBufferService.addPlayByPlayEvent(
            // Last event teamMarker
            teamMarker,
            {
                eventType: !extraTime ? 'FIRST_HALF_END' : 'EXTRA_TIME_ONE_END',
                eventTime: {
                  timestamp: this.gameCounter$.value.wallClock,
                  halftime: !extraTime ? 'T1' : 'ET1',
                  secondsSinceHalftime: this.gameCounter$.value.seconds,
                  minutesSinceHalftime: this.gameCounter$.value.minutes,
                  secondsSinceStartOfGame: currentVideoTimestampRounded,
                },
                phase: 'OFFENSE_POSITIONAL',
            } as PlayerEvent,
            this._core.gameService.gameModel[teamMarker=='HOME'?'home':'visitor'].offenseSystem,
        );
        new Promise(resolve => setTimeout(resolve, 15000));
        this.addPlayerEvents(extraTime ? 'ET1' : 'T1', 'PLAY_TIME_END', currentVideoTimestampRounded);
        this._core.eventBufferService.closeTransactionNow();
        this._gameModel = this._core.gameService.gameModel;
        this._core.gameService.enableAllowedTimeOuts();
    }

    public endSecondHalf(
        currentVideoTimestamp: number,
        gameClock: number,
        extraTime?: boolean
    ): void {
        this._isGameRunning = false;
        this._gameRunning$.next(this._isGameRunning);
        if (!extraTime) {
            this._isFirstHalftimeETStarted = true;
            this._offsetETFirstHalf = Math.round(this._currentVideoTime$.value)+1;
        } else {
            this._penaltiesStarted = true;
            this._offsetPenalties = Math.round(this._currentVideoTime$.value)+1;
        }
       // this._firstHalfEnd = this._currentVideoTime$.value;

       // this._isFirstHalftimeNotStarted = false;
      //  this._isSecondHalfTimeNotStarted = false;
      //  this._offsetSecondHalf = Math.round(this._currentVideoTime$.value)+1;
        console.log('setting up this._offsetSecondHalf', this._offsetSecondHalf)

        let res = null;
        if (extraTime) {
            res = this._videoTrackerContainerET2.calculateY(currentVideoTimestamp+1); // +1 to ensure current second is bound inside
            this._videoTrackerContainerET2.endContainer(currentVideoTimestamp+1, gameClock); // +1 to ensure current second is bound inside
        } else {
            res = this._videoTrackerContainerSecondHalf.calculateY(currentVideoTimestamp+1); // +1 to ensure current second is bound inside
            this._videoTrackerContainerSecondHalf.endContainer(currentVideoTimestamp+1, gameClock); // +1 to ensure current second is bound inside
        }
        this._gameCounter$.next(calculateNextTimerModelFromVideoTimerTick(
            this._startTime,
            res.gameSeconds,
            'STOP',
            extraTime ? 'P' : 'ET1',
            this._currentVideoTime$.value
        ));
        const teamMarker = this._videoPlayByPlayEvents.sort(
            (a, b) => b.eventTime.secondsSinceStartOfGame - a.eventTime.secondsSinceStartOfGame
        )[0].teamMarker;
        this._core.eventBufferService.addPlayByPlayEvent(
            // Last event teamMarker
            teamMarker,
            {
                eventType: !extraTime ? 'SECOND_HALF_END' : 'EXTRA_TIME_TWO_END',
                eventTime: {
                    timestamp: this.gameCounter$.value.wallClock,
                    halftime: !extraTime ? 'T2' : 'ET2',
                    secondsSinceHalftime: this.gameCounter$.value.seconds,
                    minutesSinceHalftime: this.gameCounter$.value.minutes,
                    secondsSinceStartOfGame: Math.round(this._currentVideoTime),
                },
                phase: 'OFFENSE_POSITIONAL',
            } as PlayerEvent,
            this._core.gameService.gameModel[teamMarker=='HOME'?'home':'visitor'].offenseSystem,
        );
        this.addPlayerEvents(extraTime ? 'ET2' : 'T2', 'PLAY_TIME_END', Math.round(this._currentVideoTime));
        this._core.eventBufferService.closeTransactionNow();
        this._gameModel = this._core.gameService.gameModel;
        this._core.gameService.enableAllowedTimeOuts();
    }

    public async endGame(
        currentVideoTimestamp: number,
        gameClock: number,
    ): Promise<void> {
        const halfTime = this.isSecondHalfTimeETStarted ? (this.penaltiesStarted ? "P" : "ET2") : "T2";
        const currentVideoTimestampRounded = Math.round(currentVideoTimestamp);
        const maxVideoCounterRounded = Math.round(this._maxVideoCounter);
        currentVideoTimestamp = currentVideoTimestampRounded < maxVideoCounterRounded
            ? maxVideoCounterRounded
            : currentVideoTimestampRounded;
        this._isGameRunning = false;
        this._gameRunning$.next(this._isGameRunning);

        let res = null;
        if (halfTime === 'ET2') {
            res = this._videoTrackerContainerET2.calculateY(currentVideoTimestamp);
            this._videoTrackerContainerET2.endContainer(currentVideoTimestamp, gameClock, true);
        } else if (halfTime === 'P') {
            res = this._videoTrackerContainerP.calculateY(currentVideoTimestamp);
            this._videoTrackerContainerP.endContainer(currentVideoTimestamp, gameClock, true);
        } else { // T2
            res = this._videoTrackerContainerSecondHalf.calculateY(currentVideoTimestamp);
            this._videoTrackerContainerSecondHalf.endContainer(currentVideoTimestamp, gameClock, true);
        }
        this._gameCounter$.next(calculateNextTimerModelFromVideoTimerTick(
            this._startTime,
            res.gameSeconds,
            'STOP',
            halfTime,
            this._currentVideoTime$.value
        ));
        // Last event teamMarker
        const teamMarker = this._videoPlayByPlayEvents.sort(
            (a, b) => b.eventTime.secondsSinceStartOfGame - a.eventTime.secondsSinceStartOfGame
        )[0].teamMarker;
        this._core.eventBufferService.addPlayByPlayEvent(
            teamMarker,
            {
                eventType: halfTime === 'T2' ? 'SECOND_HALF_END' : (halfTime === 'ET2' ? 'EXTRA_TIME_TWO_END' : 'PENALTIES_END'),
                eventTime: {
                    halftime: halfTime,
                    timestamp: this.gameCounter$.value.wallClock,
                    secondsSinceHalftime: this.gameCounter$.value.seconds,
                    minutesSinceHalftime: this.gameCounter$.value.minutes,
                    secondsSinceStartOfGame: Math.round(this._currentVideoTime),
                },
                phase: 'OFFENSE_POSITIONAL',
            } as PlayerEvent,
            this._core.gameService.gameModel[teamMarker=='HOME'?'home':'visitor'].offenseSystem,
        );
        await this._core.eventBufferService.closeTransactionNow();
        this._isFirstHalftimeNotStarted = false;
        this._isSecondHalfTimeNotStarted = false;
        this._gameEnded$.next(true);
        await this.closeGame();
    }

    public async saveGame() {
        if (this.lastGameSave$.getValue() === null) return;
        this.lastGameSave$.next(null);
        // await this._core.gameLoadingService.presentVideoSaveSpinner();
        // this._core.gameStatsHandlerService.init(
        //     this._gameDto
        // );
        this._gameModel = this._core.gameService.gameModel;
        // this.addPlayerEvents('T2', 'PLAY_TIME_END', Math.round(this._currentVideoTime$.value));
        const firstHalfTimeLength = this._videoTrackerContainerFirstHalf?.trackerList?.length ? this._videoTrackerContainerFirstHalf.lengthOfHalftimeGameTime : 0;
        const recalculatedPlayTimeEvents = await this.recalculatePlayTimeEventsAndReplaceAtLocalDb(firstHalfTimeLength);
        const recalculatedPlayByPlay = await this.recalculatePlaByPlayEventsAndAtToLocalDb(firstHalfTimeLength);
        const pbpDtos = transformPlayByPlayToServerModel(recalculatedPlayByPlay);
        const playTimeDtos = transformPlayTimeEventsToServerModel(recalculatedPlayTimeEvents);
        this._core.timerWrapperService.initTimeWrapperService(false);
        this._core.playTimeService.initFromExternalModel(playTimeDtos);

        // recalculatedPlayByPlay.forEach(pbp => this._core.eventFeedService.addExternalPlayByPlayPEvent(pbp));
        const gameEndStorageModel = this.prepareEndGameStorageModel(
            playTimeDtos,
            pbpDtos,
            true // Is save option instead of game end
        );

        try {
            // First update game on the local db to have the result stored
            await db.game.where('srvId').equals(this._gameId).modify({
                goalsHome: gameEndStorageModel.updateGameResultDto.goalsHome,
                goalsVisitor: gameEndStorageModel.updateGameResultDto.goalsVisitor
            });
            await this._core.gameDataService.updateGame(gameEndStorageModel.gameId, gameEndStorageModel.updateGameResultDto);
            this.lastGameSave$.next(new Date());
            await db.game.where('srvId').equals(this._gameId).modify({isUploadPending: 0}); // Upload is now not pending but we are saving entries also locally
        } catch (err) {
            this.lastGameSave$.next(false);
            this.logger.error('VideoTrackerService.closeGame() - Error updating the Game!', err);
        } finally {
            // this._core.houseKeepingService.checkForPendingUploads();
            // await this._core.gameLoadingService.dismiss();

            // TODO: Disable preventExit guard and go out of game only if set by user (save and exit option)
            // await this.router
            //     .navigate(["/account/"+this._core.accountEquipoService.selectedAccountEquipo$.getValue().id+"/game/search"]);
        }
    }

    private resetSubjects(): void {
        this._videoPlayTimeEvents = [];
        this._videoPlayTimeEvents$.next(this._videoPlayTimeEvents);
        this._clockEvents = [];
        this._clockEvents$.next(this._clockEvents);
        this._videoPlayByPlayEvents = [];
        this._videoPlayByPlayEvents$.next(this._videoPlayByPlayEvents);
        this._currentVideoTime = 0;
        this._currentVideoTime$.next(this._currentVideoTime);
        this._isGameRunning = false;
        this._gameRunning$.next(this._isGameRunning);
        this._gameEnded$.next(false);
        this._gameCounter$.next({
            counterSecSinceStart: 0,
            gameCounter: '00:00',
            seconds: 0,
            minutes: 0,
            halfTime: 'T1',
            wallClock: DateTime.now(),
            currentClockMode: 'STOP'
        });
        this._homeScore$.next(0);
        this._visitorScore$.next(0);
        this._homeOverviewStatsModel$.next([]);
        this._visitorOverviewStatsModel$.next([]);
    }

    private calculateScore(): void {
        const helper = calculateScore(this._videoPlayByPlayEvents);
        this._homeScore$.next(helper.homeScore);
        this._visitorScore$.next(helper.visitorScore);
    }

    private addPlayerEvents(
        halftime: any,
        playerEventModel: PlayerEventModel,
        secondsSinceStartOfGame: number,
    ): void {
        [...this._gameModel.home.currentField, ...this._gameModel.visitor.currentField].forEach((pl, index) => {
            this.addPlayTimeEvent(createVideoPlayTimeEventFromGamePlayerModel(
                pl,
                this._startTime,
                halftime,
                playerEventModel,
                secondsSinceStartOfGame
            ), true);
        });
    }

    private async closeGame(): Promise<void> {
        await this._core.gameLoadingService.presentVideoSaveSpinner();
        this._core.gameStatsHandlerService.init({
            ...this._gameDto,
            // AR: After a long investigation, we've decided to implement this patch to avoid the app to got stuck on game end when for an unknown reason the game playTimeDtos
            // are from this game but playerId property of them contains playerIds of other games. Anyway we are calling playTimeService.initFromExternalModel some lines after
            // so it should not be an issue to never initialise gameStatsHandlerService with empty playTimeDtos. Doing the same with playByPlayDtos just in case. This behaviour
            // is not easily reproducible since does not happen everytime.
            playByPlayDto: [],
            playTimeDto: [],
        });
        this._gameModel = this._core.gameService.gameModel;
        const halftime = this.isSecondHalfTimeETStarted ? (this.penaltiesStarted ? "P" : "ET2") : "T2";
        this.addPlayerEvents(halftime, 'PLAY_TIME_END', Math.round(this._currentVideoTime$.value));
        const firstHalfTimeLength = this._videoTrackerContainerFirstHalf.lengthOfHalftimeGameTime;
        const recalculatedPlayTimeEvents = await this.recalculatePlayTimeEventsAndReplaceAtLocalDb(firstHalfTimeLength);
        const recalculatedPlayByPlay = await this.recalculatePlaByPlayEventsAndAtToLocalDb(firstHalfTimeLength);
        const pbpDtos = transformPlayByPlayToServerModel(recalculatedPlayByPlay);
        const playTimeDtos = transformPlayTimeEventsToServerModel(recalculatedPlayTimeEvents);
        this._core.timerWrapperService.initTimeWrapperService(false);
        this._core.playTimeService.initFromExternalModel(playTimeDtos);

        recalculatedPlayByPlay.forEach(pbp => this._core.eventFeedService.addExternalPlayByPlayPEvent(pbp));
        const gameEndStorageModel = this.prepareEndGameStorageModel(
            playTimeDtos,
            pbpDtos
        );

        try {
            // First update game on the local db to have the result stored
            await db.game.where('srvId').equals(this._gameId).modify({
                goalsHome: gameEndStorageModel.updateGameResultDto.goalsHome,
                goalsVisitor: gameEndStorageModel.updateGameResultDto.goalsVisitor
            });
            await this._core.gameDataService.updateGame(gameEndStorageModel.gameId, gameEndStorageModel.updateGameResultDto);
            const successToast = await this.toastCntl
                .create({
                    icon: "checkmark-circle-outline",
                    color: 'success',
                    message: `Successfully saved Game on server!`,
                    duration: 5000,
                });
            await successToast.present();
            await db.game.where('srvId').equals(this._gameId).modify({isUploadPending: 0});
        } catch (err) {
            this.logger.error('VideoTrackerService.closeGame() - Error updating the Game!', err);
            const errorToast = await this.toastCntl
                .create({
                    icon: 'close-outline',
                    color: 'danger',
                    message: `The game has not been saved on the server, therefore it has been stored locally to be uploaded later.`,
                    duration: 5000,
                });
            await errorToast.present();
        } finally {
          this._core.houseKeepingService.checkForPendingUploads();
          await this._core.gameLoadingService.dismiss();
          window.onbeforeunload = null;

          if (this._core.gameHandlerService.debugReplayInterval) {
              clearInterval(this._core.gameHandlerService.debugReplayInterval.interval);
              this._core.gameHandlerService.debugReplayInterval.replay.stop();
              this._core.gameHandlerService.debugReplayInterval = undefined;
          }

          await this.router
              .navigate(["/account/"+this._core.accountEquipoService.selectedAccountEquipo$.getValue().id+"/game/search"]);
        }
    }

    secsFromHalfTimeFromWallClock = (eventTime: EventTime) => eventTime.secondsSinceHalftime + (eventTime.minutesSinceHalftime*60);
    public calculateClockContainerFromVideoSignal(originalEventTime: EventTime, videoTimestamp: number): TimeContainer {
        // console.log('this._offsetSecondHalf', this._offsetSecondHalf)
        let gct: GameClockTick;
        let halfTime: HalftimeTypes;

        if (this.videoTimeOnLoad && videoTimestamp < this.videoTimeOnLoad) {
            gct = {
                clockMode: 'START',
                gameSeconds: this.secsFromHalfTimeFromWallClock(originalEventTime),
            };
            // halfTime = originalEventTime.halftime;
            // OLD: halfTime = videoTimestamp <= this._offsetSecondHalf ? 'T1' : 'T2';
        } else {
            const timeContainers: {[key in HalftimeTypes]: VideoTrackerContainer} = {
                T1: this._videoTrackerContainerFirstHalf,
                T2: this._videoTrackerContainerSecondHalf,
                ET1: this._videoTrackerContainerET1,
                ET2: this._videoTrackerContainerET2,
                P: this._videoTrackerContainerP,
            }
            gct = timeContainers[originalEventTime.halftime].calculateY(videoTimestamp);
        }

        //#region Old implementation (with two pending fixmes)
        // FIXME: Refactor next if (keeping only body of this.isFirstHalftimeETStarted==true) once extra-times games are well tested
        // FIXME: Also remove all halftime commented calculations once this behaviour is well tested
        // if (this.isFirstHalftimeETStarted) {
        //     if (this.videoTimeOnLoad && videoTimestamp < this.videoTimeOnLoad) {
        //         gct = {
        //             clockMode: 'START',
        //             gameSeconds: this.secsFromHalfTimeFromWallClock(originalEventTime),
        //         };
        //         // halfTime = originalEventTime.halftime;
        //         // OLD: halfTime = videoTimestamp <= this._offsetSecondHalf ? 'T1' : 'T2';
        //     } else if (videoTimestamp <= this._offsetSecondHalf) {
        //         gct = this._videoTrackerContainerFirstHalf.calculateY(videoTimestamp);
        //         // halfTime = 'T1';
        //     } else if (videoTimestamp > this._offsetSecondHalf && videoTimestamp <= this._offsetETFirstHalf) {
        //         gct = this._videoTrackerContainerSecondHalf.calculateY(videoTimestamp);
        //         // halfTime = 'T2';
        //     } else if (videoTimestamp > this._offsetETFirstHalf && videoTimestamp <= this._offsetETSecondHalf) {
        //         gct = this._videoTrackerContainerET1.calculateY(videoTimestamp);
        //         // halfTime = 'ET1';
        //     } else if (videoTimestamp > this._offsetETSecondHalf && videoTimestamp <= this._offsetPenalties) {
        //         gct = this._videoTrackerContainerET2.calculateY(videoTimestamp);
        //         // halfTime = 'ET2';
        //     } else {
        //         gct = this._videoTrackerContainerP.calculateY(videoTimestamp);
        //         // halfTime = 'P';
        //     }
        // } else {
        //     if (this.videoTimeOnLoad && videoTimestamp < this.videoTimeOnLoad) {
        //         // Prevent override pbp of already saved, since timeContainers are set from lastPbp entry, but only usable at pbp, ptr requires extended checking since doesn't have availability to restore videoTimestamp
        //         gct = {
        //             clockMode: 'START',
        //             gameSeconds: this.secsFromHalfTimeFromWallClock(originalEventTime),
        //         };
        //         // halfTime = originalEventTime.halftime;
        //         // OLD: halfTime = videoTimestamp <= this._offsetSecondHalf ? 'T1' : 'T2';
        //     } else if (videoTimestamp <= this._offsetSecondHalf) {
        //         gct = this._videoTrackerContainerFirstHalf.calculateY(videoTimestamp);
        //         // halfTime = 'T1';
        //     } else {
        //         gct = this._videoTrackerContainerSecondHalf.calculateY(videoTimestamp);
        //         // halfTime = 'T2';
        //     }
        // }
        //#endregion

        const minutes = Math.floor(gct.gameSeconds / 60);
        const remainingSeconds = gct.gameSeconds - (minutes * 60);
        const realSecondsOfTheGame = videoTimestamp + this._offsetGameStart;
        return {
            secondsSinceStartOfGame: gct.gameSeconds,
            halftime: originalEventTime.halftime,
            minutesSinceHalftime: minutes,
            secondsSinceHalftime: remainingSeconds,
            videoSeconds: videoTimestamp,
            timestamp: this._startTime.plus({seconds: realSecondsOfTheGame})
        };
    }

    public getVideoPlayTimeEvents(): VideoPlayTimeEvent[] {
        return Array.from(this._videoPlayTimeMap.values())//
            .reduce((eventList, container) => {
                eventList = [...eventList, ...container.videoPlayTimeEvents];
                return eventList;
            }, [] as VideoPlayTimeEvent[]);
    }

    private async recalculatePlayTimeEventsAndReplaceAtLocalDb(offsetSecondHalf: number): Promise<PlaytimeEventModel[]> {
        // console.log('Calculating with offsetSecondHalf', offsetSecondHalf)
        this._videoPlayTimeEvents = this.getVideoPlayTimeEvents();
        this.logger.debug(
            'VideoTrackerService.recalculatePlayTimeEventsAndReplaceAtLocalDb() videoContainer, playtimenodes: ',
            this._videoTrackerContainerFirstHalf?.trackerList,
            this._videoTrackerContainerSecondHalf?.trackerList,
            this._videoPlayTimeEvents
        );
        const sortedPlayTimeEvents = this._videoPlayTimeEvents
            .sort((a, b) => a.tempOrderId - b.tempOrderId);

        // Maybe we should order by eventTime.secondsSinceStartOfGame instead of tempOrderId for this function
        // console.warn('PTM-halfOffsets-precalculate', JSON.stringify(sortedPlayTimeEvents));
        // const halfOffsets = this.calculateHalfTimeOffsets(sortedPlayTimeEvents);
        const sortedPlayByPlayEvents = this._videoPlayByPlayEvents.sort(
            (a, b) => a.eventTime.secondsSinceStartOfGame - b.eventTime.secondsSinceStartOfGame
        );
        const halfOffsets = this.calculateHalfTimeOffsets(sortedPlayByPlayEvents);
        // console.warn('PTM-sortedPlayTimeEvents', JSON.stringify(sortedPlayByPlayEvents));
        // console.warn('PTM-halfOffsets', halfOffsets);

        const convertedPlayTimeEvents = sortedPlayTimeEvents
            .reduce((pte, curr, index) => {
                // Prevent calculating timerContainer for events that have secondsSinceStartOfGame before continue tracking videoTimeOnLoad
                // const timerContainer = (curr.eventTime.secondsSinceStartOfGame < this.videoTimeOnLoad)
                //     // TODO: Add other possible half times (and also initalise offsets for new halfs on restoreGameTrackState function)
                //     ? {...curr.eventTime, secondsSinceStartOfGame: (curr.eventTime.halftime === 'T2' ? curr.eventTime.secondsSinceStartOfGame-offsetSecondHalf : 0)} as TimeContainer
                //     : this.calculateClockContainerFromVideoSignal(curr.eventTime, curr.eventTime.secondsSinceStartOfGame);
                const videoTime = curr.eventTime.secondsSinceStartOfGame;
                const timerContainer = this.calculateClockContainerFromVideoSignal(curr.eventTime, videoTime);
                const newCurr = {
                    eventTime: {
                        halftime: timerContainer.halftime,
                        secondsSinceStartOfGame: timerContainer.secondsSinceStartOfGame + halfOffsets[timerContainer.halftime].offset,
                        timestamp: timerContainer.timestamp,
                        secondsSinceHalftime: timerContainer.secondsSinceHalftime,
                        minutesSinceHalftime: timerContainer.minutesSinceHalftime
                    },
                    videoTimestamp: curr.videoTimestamp,
                    eventType: curr.eventType,
                    playerId: curr.playerId,
                    seq: index + 1,
                } as PlaytimeEventModel
                // console.log('PTE-'+curr.tempOrderId, curr, newCurr)
                pte.push(newCurr);
                return pte;
            }, [] as PlaytimeEventModel[]);

        // console.warn('PTM-convertedPlayTimeEvents', JSON.stringify(convertedPlayTimeEvents));

        this.logger
            .debug('VideoTrackerService.recalculatePlayTimeEventsAndReplaceAtLocalDb() - convertedPlayTimeEvents', convertedPlayTimeEvents);
        // Delete the already existing playTime
        await db.playTime.where('gameSrvId').equals(this._gameId).delete();
        const ptDtos = transformPlayTimeEventsToServerModel(convertedPlayTimeEvents);
        await db.playTime.bulkAdd(ptDtos.map(dto =>
            ({
                srvId: false,
                gameSrvId: this._gameId,
                ...dto,
            })
        ));
        return convertedPlayTimeEvents;
    }

    private async recalculatePlaByPlayEventsAndAtToLocalDb(offsetSecondHalf: number): Promise<PlayByPlayModel[]> {
        this.logger.debug(
            'VideoTrackerService.recalculatePlayTimeEventsAndReplaceAtLocalDb() videoContainer, playtime nodes: ',
            this._videoTrackerContainerFirstHalf?.trackerList,
            this._videoTrackerContainerSecondHalf?.trackerList,
            this._videoPlayTimeEvents
        );
        this._currentPossessionId = 0;
        this._currentPossessionOwner = 'INITIAL_OWNER';
        this._currentPossessionMap = new Map<TeamMarker, number>([
            ['HOME', 0],
            ['VISITOR', 0]
        ]);
        const sortedPlayByPlayEvents = this._videoPlayByPlayEvents.sort(
            (a, b) => a.eventTime.secondsSinceStartOfGame - b.eventTime.secondsSinceStartOfGame
        );
        const halfOffsets = this.calculateHalfTimeOffsets(sortedPlayByPlayEvents);
        // console.warn('PBP-halfOffsets', halfOffsets);

        const convertedPlayByPlayEvents = sortedPlayByPlayEvents
            .reduce((pbp, curr, index) => {
                const videoTime = curr.eventTime.secondsSinceStartOfGame;
                curr.orderId = index + 1;
                const timerContainer = this.calculateClockContainerFromVideoSignal(curr.eventTime, videoTime);
                const possessionHolder = this.calculatePossession(curr.teamMarker, curr.event);
                curr.possessions = possessionHolder.possessions;
                curr.possessionId = possessionHolder.possessionId;
                pbp.push(createPbpRecord(curr, timerContainer, halfOffsets, videoTime));
                return pbp;
            }, [] as PlayByPlayModel[]);


        this.logger
            .debug('VideoTrackerService.recalculatePlaByPlayEventsAndAtToLocalDb() - convertedPlayByPlayEvents', convertedPlayByPlayEvents);
        const pbpDtos = transformPlayByPlayToServerModel(convertedPlayByPlayEvents);
        await db.playByPlay.bulkAdd(pbpDtos.map(dto =>
            ({
                srvId: false,
                gameSrvId: this._gameId,
                ...dto,
            })
        ));
        return convertedPlayByPlayEvents;
    }

    private calculateHalfTimeOffsets(sortedPlayByPlayEvents: VideoPlayByPlay[]|VideoPlayTimeEvent[]): {[key in HalftimeTypes]: {offset: number, lastEvent: VideoPlayByPlay}} {
        const halfOffsets: {[key in HalftimeTypes]: {offset: number, lastEvent: VideoPlayByPlay}} = {
            T1: {lastEvent: null, offset: 0 }, // No offset for first half
            T2: {lastEvent: null, offset: 0},
            ET1: {lastEvent: null, offset: 0},
            ET2: {lastEvent: null, offset: 0},
            P: {lastEvent: null, offset: 0},
        }
        sortedPlayByPlayEvents.forEach(pbp => { halfOffsets[pbp.eventTime.halftime].lastEvent = pbp; });
        if (halfOffsets.T1.lastEvent) {
            const timeContainer = this.calculateClockContainerFromVideoSignal(halfOffsets.T1.lastEvent.eventTime, halfOffsets.T1.lastEvent.eventTime.secondsSinceStartOfGame);
            halfOffsets.T2.offset = timeContainer.minutesSinceHalftime*60 + timeContainer.secondsSinceHalftime;
        }
        if (halfOffsets.T2.lastEvent) {
            const timeContainer = this.calculateClockContainerFromVideoSignal(halfOffsets.T2.lastEvent.eventTime, halfOffsets.T2.lastEvent.eventTime.secondsSinceStartOfGame);
            halfOffsets.ET1.offset = halfOffsets.T2.offset + timeContainer.minutesSinceHalftime*60 + timeContainer.secondsSinceHalftime;
        }
        if (halfOffsets.ET1.lastEvent) {
            const timeContainer = this.calculateClockContainerFromVideoSignal(halfOffsets.ET1.lastEvent.eventTime, halfOffsets.ET1.lastEvent.eventTime.secondsSinceStartOfGame);
            halfOffsets.ET2.offset = halfOffsets.ET1.offset + timeContainer.minutesSinceHalftime*60 + timeContainer.secondsSinceHalftime;
        }
        if (halfOffsets.ET2.lastEvent) {
            const timeContainer = this.calculateClockContainerFromVideoSignal(halfOffsets.ET2.lastEvent.eventTime, halfOffsets.ET2.lastEvent.eventTime.secondsSinceStartOfGame);
            halfOffsets.P.offset = halfOffsets.ET2.offset + timeContainer.minutesSinceHalftime*60 + timeContainer.secondsSinceHalftime;
        }
        return halfOffsets;
    }

    private createGamePlayerMap(): void {
        this.gamePlayerMap = populateGamePlayerMap(this._gameModel);
        // this._videoPlayTimeMap = new Map<number, VideoPlayTimeContainer>([]);
        this._videoPlayTimeMap = populateVideoPlayTimeMap(this._videoPlayTimeCounter, this.logger, this._gameModel);
    }

    private isRelevantEvent(eventType: PlayerEventModel): boolean {
        return eventToPossessionMapper(eventType);
    }

    private calculatePossession(
        teamMarker: TeamMarker,
        eventType: PlayerEventModel
    ): PossessionCounterHolder {
        if (this.isRelevantEvent(eventType)) {
            if (this._currentPossessionOwner !== teamMarker || eventType === 'SECOND_HALF_START') {
                this._currentPossessionOwner = teamMarker;
                this._currentPossessionId = ++this._currentPossessionId;
                this._currentPossessionMap.set(teamMarker, this._currentPossessionMap.get(teamMarker) + 1);
            }
        }
        return {
            possessions: this._currentPossessionMap.get(teamMarker),
            possessionId: this._currentPossessionId
        };
    }

    private prepareEndGameStorageModel(
        fullPlayerEvents: PlayTimeDto[],
        fullPlayByPlay: PlayByPlayDto[],
        isSave: boolean = false,
    ): GameEndStorageModel {
        return {
            gameId: this._gameId,
            updateGameResultDto: {
                firstHalfEnded: this._firstHalfEnd ? true : false,
                secondHalfEnded: isSave ? false : true,
                gameEnded: isSave ? false : true,
                gameStatus: isSave ? 'saved' : 'ended',
                goalsHome: this._homeScore$.value,
                goalsVisitor: this._visitorScore$.value,
                playerStatsDto: isSave ? [] : this._core.goalConsumerService
                    .transformToPlayerStatisticsDto(this._core.teamOverviewSubConsumerService.getPlayerStatisticsDto()),
                teamStatsDto: isSave ? [] : this._core.goalConsumerService
                    .transformToTeamStatisticsDto(this._core.overviewConsumerService.generateTeamStatsDto()),
                lineupStatsDto: isSave ? [] : this._core.goalConsumerService.transformToLineupStatisticsDto(),
                playByPlayDto: fullPlayByPlay,
                playTimeDto: fullPlayerEvents,
                gameDateTime: this._startTime.toISO(),
            },
        } as GameEndStorageModel;
    }

    private recalculateMinimalOverviewStats(): void {
        const res = calculateMinimalOverviewStatsModel(
            this._videoPlayByPlayEvents,
            this._gameModel,
        );
        this._homeOverviewStatsModel$.next([...res.homeStats]);
        this._visitorOverviewStatsModel$.next([...res.visitorStats]);
    }
}
