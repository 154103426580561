export * from './auth.service';
import { AuthService } from './auth.service';
export * from './health.service';
import { HealthService } from './health.service';
export * from './myTeamFiles.service';
import { MyTeamFilesService } from './myTeamFiles.service';
export * from './myTeamFolders.service';
import { MyTeamFoldersService } from './myTeamFolders.service';
export * from './myTeamPlayers.service';
import { MyTeamPlayersService } from './myTeamPlayers.service';
export * from './playersAppPlayers.service';
import { PlayersAppPlayersService } from './playersAppPlayers.service';
export const APIS = [AuthService, HealthService, MyTeamFilesService, MyTeamFoldersService, MyTeamPlayersService, PlayersAppPlayersService];
