/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PlayTimeDto { 
    /**
     * playTimeId id
     */
    id?: number;
    /**
     * playTime order id
     */
    orderId: number;
    /**
     * PlayTime Event
     */
    event: string;
    /**
     * Seconds since start of game
     */
    secondsSinceStartOfGame: number;
    /**
     * Half time
     */
    halftime: string;
    /**
     * Minutes since Half Time
     */
    minutesSinceHalftime: number;
    /**
     * Seconds since Half Time
     */
    secondsSinceHalftime: number;
    /**
     * Event time stamp
     */
    timestamp: string;
    /**
     * Event time stamp in seconds of the video
     */
    videoTimestamp?: number;
    /**
     * Player Id
     */
    playerId: number;
}

