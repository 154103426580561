import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

export interface ILanguageSelectorItem {
  code: string;
  label: string;
}

/**
 * We will store user_lang alongside other user settings at central db.
 */
@Injectable({
  providedIn: 'root'
})
export class I18nService {

  private _languageSelectorItems = [
    {code: 'en', label: 'EN'},
    {code: 'es', label: 'ES'},
    {code: 'fr', label: 'FR'},
    // {code: 'de', label: 'DE'},
  ] as ILanguageSelectorItem[];

  private _currLang$ = new BehaviorSubject<ILanguageSelectorItem>({code: 'en', label: 'EN'});

  constructor(
    private readonly translate: TranslateService
  ) {
    try {
      const langCode = localStorage.getItem('langCode');
      const lang = this._languageSelectorItems.filter(itm => itm.code == langCode)[0];

      if (lang) { this._currLang$.next(lang); }
    } catch (e) {
      console.log(e);
    }
    this.translate.use(this._currLang$.value.code);
  }

  tg(def:string, skipLangs: string[] = []): Promise<string> {
    return (skipLangs.includes(this._currLang$.value.code))
      ? Promise.resolve(def)
      : this.translate.get(def).toPromise()
    ;
  }

  get currLang$(): BehaviorSubject<ILanguageSelectorItem> {
    return this._currLang$;
  }

  set curLangCode(value: ILanguageSelectorItem) {
    try {
      localStorage.setItem('langCode', value.code);
      this.translate.use(value.code);
      this._currLang$.next(value);
    } catch (e) {
      console.log(e);
    }
  }

  get languageSelectorItems(): ILanguageSelectorItem[] {
    return this._languageSelectorItems;
  }
}
