<div class="w-full play-by-play-grid__wrapper play-by-play-table" [ngClass]="{'modal-table': modalTable}">
    <div *ngIf="core.isMobileDevice" class="expand-toggle">
        <ion-toggle (ionChange)="onExpandTableColumnsClick()"></ion-toggle>
    </div>
    <ngx-datatable class="material"
               [rows]="playByPlayRecords"
               [columns]="columns"
               [limit]="8"
               [columnMode]="'force'"
               rowHeight="auto"
               [footerHeight]="50"
               [headerHeight]="50"
               [scrollbarH]="true">
    <ngx-datatable-column name="teamName"
                          [sortable]="false"
                          [resizeable]="false" [width]="170" [cellClass]="getRowClass">
        <ng-template let-column="column" ngx-datatable-header-template>
            {{'Team'|translate}}
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template >
            {{value}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="possessions"
                          [sortable]="false"
                          [resizeable]="false"
                          [width]="50">
        <ng-template let-column="column" ngx-datatable-header-template>
            <span>POSS</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="event"
                          [sortable]="false"
                          [resizeable]="false"
                          [width]="75">
        <ng-template let-column="column" ngx-datatable-header-template>
            <span>{{'Event'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
            <span class="break-all">{{removeUnderscore(value)}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="eventTime"  [width]="80" [sortable]="false" [resizeable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
            <span>{{'Time'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
            <span>
                {{value.halftime}}-{{value.minutesSinceHalftime | number: '2.0-0'}}:{{value.secondsSinceHalftime  | number: '2.0-0'}}-{{value.timestamp | date: 'HH:mm:ss' }}
            </span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || allTableColsShown" name="phase"
                          [sortable]="false"
                          [resizeable]="false"
                          [width]="150">
        <ng-template let-column="column" ngx-datatable-header-template>
            <span>{{'Phase'|translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{value}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || allTableColsShown" name="defenseSystem"  [sortable]="false" [resizeable]="false" [width]="50">
        <ng-template let-column="column" ngx-datatable-header-template>
            <span>DefS</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span>{{value}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || allTableColsShown" name="offenseSystem"
                          [sortable]="false"
                          [resizeable]="false" [width]="80">
        <ng-template let-column="column" ngx-datatable-header-template>
            <span>OffS</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value}}</span>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column *ngIf="!core.isMobileDevice || allTableColsShown" name="offensePlayer"

                          [sortable]="false"
                          [resizeable]="false" [width]="55">
        <ng-template let-column="column" ngx-datatable-header-template>
            <span>OffPN</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span>{{value.backNumber}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || allTableColsShown" name="assistantPlayer"

                          [sortable]="false"
                          [resizeable]="false" [width]="55">
        <ng-template let-column="column" ngx-datatable-header-template>
            <span>AssPN</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span>{{value.backNumber}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || allTableColsShown" name="defensePlayer"

                          [sortable]="false"
                          [resizeable]="false" [width]="55">
        <ng-template let-column="column" ngx-datatable-header-template>
            <span>DeffPN</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span>{{value.backNumber}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || allTableColsShown" name="executionPosition"

                          [sortable]="false"
                          [resizeable]="false" [width]="75">
        <ng-template let-column="column" ngx-datatable-header-template>
            <span>SHOTPOSI</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span>{{value | translate}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || allTableColsShown" name="shotLocation"

                          [sortable]="false"
                          [resizeable]="false" [width]="70">
        <ng-template let-column="column" ngx-datatable-header-template>
            <span>SHOTLOC</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span>{{value}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || allTableColsShown" name="offense"

                          [sortable]="false"
                          [resizeable]="false" [width]="180">
        <ng-template let-column="column" ngx-datatable-header-template>
            {{'Offense'|translate}}
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
            {{getBackNumbersForTeam(value)}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || allTableColsShown" name="defense"

                          [sortable]="false"
                          [resizeable]="false" [width]="180">
        <ng-template let-column="column" ngx-datatable-header-template>
            {{'Defense'|translate}}
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
            {{getBackNumbersForTeam(value)}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || allTableColsShown" name="gameSystem"

                          [sortable]="false"
                          [resizeable]="false" [width]="170">
        <ng-template let-column="column" ngx-datatable-header-template>
            {{'GameSystem' | translate}}
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
            {{transformGameSystem(value)}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!core.isMobileDevice || allTableColsShown" name="important"

                          [sortable]="false"
                          [resizeable]="false" [width]="60">
        <ng-template let-column="column" ngx-datatable-header-template>
            <span>{{'Important'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
            <span>{{value ? ('yes'|translate) : ('no'|translate)}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="editable && (!core.isMobileDevice || allTableColsShown)"
                          name="actions"
                          [sortable]="false"
                          [resizeable]="false" [width]="modalTable ? 120 : 70">
        <ng-template let-column="column" ngx-datatable-header-template>
            <span>{{'Actions'|translate}}</span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="buttonsAvatar">
                <ion-button (click)="onPBPSelectChange(row)">
                    <ion-icon name="create-outline"></ion-icon>
                </ion-button>
                <ion-button *ngIf="modalTable" color="danger" (click)="onPBPDeleteClick(row)">
                    <ion-icon name="trash-outline"></ion-icon>
                </ion-button>

            </div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
</div>
