import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PopoverController } from "@ionic/angular";
import { CoreService } from "src/app/shared-services/core.service";

@Component({
  selector: "app-account-rename-popup",
  templateUrl: "./account-rename-popup.component.html",
  styleUrls: ["./account-rename-popup.component.scss"],
})
export class AccountRenamePopupComponent implements OnInit {
  private _account;
  private _fg: FormGroup;

  @Input()
  set currentName(value: string) {
    if (value) {
      this.initForm(value);
    }
  }

  constructor(private readonly fb: FormBuilder, private readonly core: CoreService, private readonly popoverController: PopoverController) {}

  ngOnInit(name?: string) {
    this.initForm();
  }

  get fg(): FormGroup {
    return this._fg;
  }

  get account(): any {
    return this._account;
  }

  public async onCancel(): Promise<void> {
    await this.popoverController.dismiss();
  }

  public async onConfirmName(name): Promise<void> {
    await this.popoverController.dismiss({ name });
  }

  private initForm(name?: string): void {
    if (!this._fg) {
      this._fg = this.fb.group({
        nameFc: [name ?? ""],
      });
    }
  }
}
