import { UiBaseEvent, UiEventType } from '../events/ui-base-event';
import { UiBaseCommand, UiCommandExecutionContext } from './ui-base-command';
import { GoalPopupCanceledCommand, GoalPopupSavedCommand, ShowGoalPopupCommand } from './ui-goal-commands';
import {
    GoalkeeperDeSelectLiteCommand,
    GoalkeeperSelectLiteCommand, GoalkeeperSwapLiteCommand,
    PlayerDeSelectCommand, PlayerDeSelectLiteCommand,
    PlayerExchangeCommand,
    PlayerSelectCommand, PlayerSelectLiteCommand,
    PlayerSwapCommand, PlayerSwapLiteCommand,
    SubstituteGoalKeeperPopupSavedCommand, SwapPlayerForFreeSlotLiteCommand
} from './ui-player-commands';
import {
    CancelDoubleEventPopupCommand,
    CancelLiteDoubleEventPopupCommand,
    CancelSubstitutePopupEventCommand, LiteBringBackSuspensionEventCommand,
    LiteSuspensionSelectedEventCommand,
    PassiveButtonSelectedEventCommand,
    SavedDoubleEventPopupCommand,
    SavedLiteDoubleEventPopupCommand,
    ShowDoubleEventPopupCommand,
    ShowLiteDoubleEventPopupCommand,
    ShowSubstitutePopupEventCommand,
    SubstitutePopupSavedEventCommand,
    SuspensionSelectedEventCommand
} from './ui-action-commands';
import {
    DoubleEventSuspensionToBenchSavedCommand, DoubleEventSuspensionToBenchSavedLiteCommand,
    SuspensionToBenchCommand, TimeoutEventCommand
} from 'src/app/shared-services/ui-event-dispatcher/commands/ui-extra-commands';
import { ShowVideoPopupCommand } from './ui-video-commands';
import { GameSystemPopupCommand } from './game-system-commands';


const eventCommandMap = [
    ['PLAYER_SELECTED', new PlayerSelectCommand()],
    ['PLAYER_DESELECTED', new PlayerDeSelectCommand()],
    ['SHOW_GOAL_POPUP', new ShowGoalPopupCommand()],
    ['SHOW_VIDEO_POPUP', new ShowVideoPopupCommand()],
    ['SHOW_GAME_SYSTEM_POPUP', new GameSystemPopupCommand()],
    ['PLAYER_EXCHANGED', new PlayerExchangeCommand()],
    ['GOAL_POPUP_CANCELED', new GoalPopupCanceledCommand()],
    ['GOAL_POPUP_SAVED', new GoalPopupSavedCommand()],
    ['SHOW_DOUBLE_EVENT_POPUP', new ShowDoubleEventPopupCommand()],
    ['DOUBLE_EVENT_POPUP_CANCELED', new CancelDoubleEventPopupCommand()],
    ['DOUBLE_EVENT_POPUP_SAVED', new SavedDoubleEventPopupCommand()],
    ['SUSPENSION_EVENT_SELECTED', new SuspensionSelectedEventCommand()],
    ['SHOW_SUBSTITUTE_POPUP', new ShowSubstitutePopupEventCommand()],
    ['SUBSTITUTE_POPUP_SAVED', new SubstitutePopupSavedEventCommand()],
    ['PASSIVE_BUTTON_SELECTED', new PassiveButtonSelectedEventCommand()],
    ['PLAYER_SWAPPED', new PlayerSwapCommand()],
    ['POS_1_SUSPENSION_POPUP_SAVED', new SubstituteGoalKeeperPopupSavedCommand()],
    ['CANCEL_SUBSTITUTE_POPUP', new CancelSubstitutePopupEventCommand()],
    ['PLAYER_SELECTED_LITE', new PlayerSelectLiteCommand()],
    ['PLAYER_DESELECTED_LITE', new PlayerDeSelectLiteCommand()],
    ['GOALKEEPER_SELECTED_LITE', new GoalkeeperSelectLiteCommand()],
    ['GOALKEEPER_DESELECTED_LITE', new GoalkeeperDeSelectLiteCommand()],
    ['PLAYER_SWAPPED_LITE', new PlayerSwapLiteCommand()],
    ['GOALKEEPER_SWAPPED_LITE', new GoalkeeperSwapLiteCommand()],
    ['SHOW_DOUBLE_EVENT_POPUP_LITE', new ShowLiteDoubleEventPopupCommand()],
    ['DOUBLE_EVENT_POPUP_CANCELED_LITE', new CancelLiteDoubleEventPopupCommand()],
    ['DOUBLE_EVENT_POPUP_SAVED_LITE', new SavedLiteDoubleEventPopupCommand()],
    ['SUSPENSION_EVENT_SELECTED_LITE', new LiteSuspensionSelectedEventCommand()],
    ['BRING_BACK_SUSPENSION_EVENT_LITE', new LiteBringBackSuspensionEventCommand()],
    ['SWAP_PLAYER_FOR_FREE_SLOT_EVENT_LITE', new SwapPlayerForFreeSlotLiteCommand()],
    ['SUSPENSION_TO_BENCH_EVENT_SELECTED', new SuspensionToBenchCommand()],
    ['DOUBLE_EVENT_POPUP_SUSPENSION_TO_BENCH_SAVED', new DoubleEventSuspensionToBenchSavedCommand()],
    ['DOUBLE_EVENT_POPUP_SUSPENSION_TO_BENCH_SAVED_LITE', new DoubleEventSuspensionToBenchSavedLiteCommand()],
    ['TIMEOUT_EVENT', new TimeoutEventCommand()],
];
export class UiCommandExecutor {
    // @ts-ignore
    private eventCommandMap = new Map<UiEventType, UiBaseCommand<any>>(eventCommandMap);

    public executeCommand<T>(actualEvent: UiBaseEvent<T>, eventContext: UiCommandExecutionContext): void {
        this.eventCommandMap.get(actualEvent.eventType).execute(actualEvent, eventContext);
    }
}
