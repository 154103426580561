import { Component, Input, OnInit } from '@angular/core';
import { ManageAccountDto } from '../../../../../../api/hai-api';
import { CreateAccountPopupComponent } from '../../../components/create-account-popup/create-account-popup.component';
import { PopoverController } from '@ionic/angular';
import { CoreService } from '../../../../../../shared-services/core.service';
import { OrganizationOptionsPopoverComponent } from '../organization-options-popover/organization-options-popover.component';
import { OrganizationOptionsPopoverData } from '../organization-options-popover/organization-options-popover-data.model';
import { AccountRenamePopupComponent } from '../../../components/account-rename-popup/account-rename-popup.component';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-organization-select-popover',
  templateUrl: './organization-select-popover.component.html',
  styleUrls: ['./organization-select-popover.component.scss'],
})
export class OrganizationSelectPopoverComponent  implements OnInit {

  @Input() accounts: ManageAccountDto[] = [];
  @Input() selectedAccount;

  constructor(private popoverCtrl: PopoverController,
              private core: CoreService,
              private logger: NGXLogger,
  ) { }

  ngOnInit() {
  }

  public async onCreateAccount(): Promise<void>{
    const popover = await this.popoverCtrl.create({
      component: CreateAccountPopupComponent,
      cssClass: "confirm-popover",
      mode: "ios",
    });
    popover.onDidDismiss().then((d: any) => {
      if (d.data) {
        this.core.accountService.createAccount({
          name: d.data.name,
          plan: d.data.plan
        }).then((acc) => {
          this.accounts.push(acc);
          this.core.accountService.getAccounts();
        });
      }

    });
    await popover.present();
  }

  isExpiredDate(row) {
    return row.renewalPrice != 0 && (new Date(row.renewalDate)).getTime() < Date.now();
  }

  public async openOrganizationOptions($event, row: ManageAccountDto) {
    $event.preventDefault();
    $event.stopPropagation();
    const popover = await this.core.popoverCtrl.create({
      component: OrganizationOptionsPopoverComponent,
      event: $event,
      // translucent: true,
      componentProps: {
        player: row,
      },
    });

    popover.onDidDismiss().then(async (result) => {
      if (!result.data) {
        return;
      } else {
        const GameOptionsPopoverData: OrganizationOptionsPopoverData = result.data;
        if (GameOptionsPopoverData.accountRename != undefined) {
          this.onAccountRename(row);
          return;
        }
        if (GameOptionsPopoverData.changePlan != undefined) {
          this.onChangePlan(row);
          return;
        }
        if (GameOptionsPopoverData.subscriptionChange != undefined) {
          this.core.accountService.onSubscriptionChange(`${row.id}`);
          return;
        }
      }
    });
    await popover.present();
  }

  public async onAccountRename(account: ManageAccountDto): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: AccountRenamePopupComponent,
      cssClass: "confirm-popover",
      mode: "ios",
      componentProps: {
        currentName: account.name,
      }
    });
    popover.onDidDismiss().then(async (d:any) => {
      if (d.data){
        await this.core.accountService.saveAccountName(account.id, d.data.name).then(() => {
          account.name = d.data.name;
        });
        await this.core.accountService.getAccounts();
      }
    });
    await popover.present();
  }

  public async onAccountSelected(accountId: number) {
    this.logger.debug('AccountManagerPage.onAccountSelected: ', accountId);
    this.selectedAccount = accountId;
    this.core.accountService.getAccountEquipos(accountId);
    await this.popoverCtrl.dismiss(accountId);
  }

  public async onChangePlan(account: ManageAccountDto): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: CreateAccountPopupComponent,
      cssClass: "confirm-popover",
      mode: "ios",
      componentProps: {
        isChangePlan: true,
        isSelectPlanPart: true,
      }
    });
    popover.onDidDismiss().then((d:any) => {
      if (d.data) {
        this.core.accountService.changeAccountPlan(account, d.data.plan)
            .then(() => this.core.accountService.getAccounts());
      }
    });
    await popover.present();
  }

}
