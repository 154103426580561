import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountEquipoService, TManageAccountEquipoGrants } from 'src/app/shared-services/account-equipo/account-equipo.service';
import {
  EventCountersModel,
  IndividualStatsEventModelTable,
  TeamOverviewStatsModel
} from '@handballai/stats-calculation';
import { formatTimeToHoursMinutesAndSeconds, timerFormatHelper } from 'src/app/shared-services/helper/timer-format.helper';
import { DecimalPipe } from '@angular/common';
import { CoreService } from 'src/app/shared-services/core.service';
import {
  eventTableLeftMap,
  eventTableRightMap,
} from '../../pages/aehandler-module/pages/dashboard-module/dashboard-handler';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { EditPositionModalComponent } from '../../pages/players-module/components/edit-position-modal/edit-position-modal.component';
import { GameDto, UpdateGameResultDto } from '../../../api/hai-api';

@Component({
    selector: 'app-team-stats',
    templateUrl: './team-stats.component.html',
    styleUrls: ['./team-stats.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TeamStatsComponent implements OnInit {

  @Input() gameId: number;

  public translationsInited$ = new BehaviorSubject<boolean>(false);

  private _homeTeamOverStats: TeamOverviewStatsModel[] = [];
  private _visitorTeamOverStats: TeamOverviewStatsModel[] = [];
  private _homeTeamOverStatsMax: TeamOverviewStatsModel = null;
  private _visitorTeamOverStatsMax: TeamOverviewStatsModel = null;

  private readonly _selectedAccountEquipo$: Observable<TManageAccountEquipoGrants> = null;
  private _isPublic = false;
  public tablesAllColsShown = false;

  public selectedTeam = {
    code: 'home',
    name: this.homeTeamName,
  };
  public selectedTable: string;


  constructor(
    private readonly logger: NGXLogger,
    private readonly accountEquipoService: AccountEquipoService,
    private readonly decimalPipe: DecimalPipe,
    public readonly core: CoreService,
  ) {
    this._selectedAccountEquipo$ = accountEquipoService.selectedAccountEquipo$;
  }

  homeEventsTblLeft$ = new BehaviorSubject<any[]>([]);
  homeEventsTblRight$ = new BehaviorSubject<any[]>([]);
  visitorEventsTblLeft$ = new BehaviorSubject<any[]>([]);
  visitorEventsTblRight$ = new BehaviorSubject<any[]>([]);
  totalAvg = [];

  columns = [];

  plEventsColumns = [];

  async ngOnInit(): Promise<void> {
    this.totalAvg = [
      { prop: 'template', name: '', resizable: false, sortable: false, frozenLeft: true, flexGrow: 1 },
      { prop: 'total', name: await this.tg(_('Total')), flexGrow: 1 },
      // { prop: 'average', name: 'Average', flexGrow: 1 },
    ];

    this.columns = [
      { prop: 'playerName', name: await this.tg(_('Player Name')), },
      { prop: 'playedPossessions', name: await this.tg(_('Played Posses')), flexGrow: 1 },
      { prop: 'possessionEvents', name: await this.tg(_('PossessionEvents')), flexGrow: 1 },
      { prop: 'totalMinutes', name: await this.tg(_('Total Minutes')), flexGrow: 1 },
      { prop: 'goals', name: await this.tg(_('Total Goals')), flexGrow: 1 },
      { prop: 'failedShots', name: await this.tg(_('Failed Shots')), flexGrow: 1 },
      { prop: 'goalsReceived', name: await this.tg(_('Goals Received')), flexGrow: 1 },
      { prop: 'saves', name: await this.tg(_('Saves')), flexGrow: 1 },
      { prop: 'postOuts', name: await this.tg(_('Post Outs')), flexGrow: 1 },
      { prop: 'lostBalls', name: await this.tg(_('Lost Balls')), flexGrow: 1 },
      { prop: 'percentageLostBallsPossEvents', name: await this.tg(_('% Lost Balls')), flexGrow: 1 },
      { prop: 'percentageEfficiencyPossEvent', name: await this.tg(_('% Eff')), flexGrow: 1 },
      { prop: 'percentageShotEfficiency', name: await this.tg(_('% Shoot Eff')), flexGrow: 1 },
      { prop: 'playerScore', name: await this.tg(_('Score')), flexGrow: 1 },
    ];

    this.plEventsColumns = [
      { prop: 'playerName', name: await this.tg(_('Player Name'))},
      { prop: 'totalMinutes', name: await this.tg(_('Total Minutes')), flexGrow: 1 }, // TM
      { prop: 'attackFaultProvoke', name: await this.tg(_('Attack Fault Provoke')), flexGrow: 1 }, // AF Prov
      { prop: 'attackFaultCommit', name: await this.tg(_('Attack Fault Commit')), flexGrow: 1 }, // AF Com
      { prop: 'technicalMistakeProvoke', name: await this.tg(_('Tech Mistake Provoke')), flexGrow: 1 }, // TeM Prov
      { prop: 'technicalMistakeCommit', name: await this.tg(_('Tech Mistake Commit')), flexGrow: 1 }, // TeM Com
      { prop: 'lostBallProvoke', name: await this.tg(_('Lost Ball Provoke')), flexGrow: 1 }, // LB
      { prop: 'lostBallCommit', name: await this.tg(_('Lost Ball Commit')), flexGrow: 1 }, // LB
      { prop: 'twoMinProvoke', name: await this.tg(_('2 min Provoke')), flexGrow: 1 }, // 2m Prov
      { prop: 'twoMinCommit', name: await this.tg(_('2 min Commit')), flexGrow: 1 }, // 2m Com
      { prop: 'sevenMetersProvoke', name: await this.tg(_('7 meters Provoke')), flexGrow: 1 }, // 7m Prov
      { prop: 'sevenMetersCommit', name: await this.tg(_('7 meters Commit')), flexGrow: 1 }, // 7m Com
      { prop: 'sevenMSuspProvoke', name: await this.tg(_('7m + Susp Provoke')), flexGrow: 1 }, // 7mS Prov
      { prop: 'sevenMSuspCommit', name: await this.tg(_('7m + Susp Commit')), flexGrow: 1 }, // 7mS Com
      { prop: 'oneAndOneLost', name: await this.tg(_('1&1 Lost')), flexGrow: 1 }, // 1&1 Lost
      { prop: 'oneAndOneWon', name: await this.tg(_('1&1 Won')), flexGrow: 1 }, // 1&1 Won
      { prop: 'foulReceive', name: await this.tg(_('Foul Receive')), flexGrow: 1 }, // F Rec
      { prop: 'foulCommit', name: await this.tg(_('Foul Commit')), flexGrow: 1 }, // F Com
      { prop: 'shotBlock', name: await this.tg(_('Shot Block')), flexGrow: 1 }, // SB
      { prop: 'shotBlocked', name: await this.tg(_('Shot Blocked')), flexGrow: 1 }, // SBd
    ];
    this.selectedTable = await this.tg(_('Players Table'));
    this.translationsInited$.next(true);
  }

  tg = (def: string, skipLangs: string[] = []) => {
    return this.core.i18nService.tg(def, skipLangs);
  };

  get selectedAccountEquipo$() {
    return this._selectedAccountEquipo$;
  }

  @Input()
  set isPublic(value: boolean) {
    this._isPublic = value;
  }

  get isPublic(): boolean {
    return this._isPublic;
  }

  get gameMode() {
    return this.core.gameService.gameMode$.value;
  }
  private async calculateMax(value: TeamOverviewStatsModel[]): Promise<TeamOverviewStatsModel> {
    const res: TeamOverviewStatsModel = {
      teamName: value[0].teamName,
      teamId: value[0].teamId,
      playerId: 0,
      playerName: await this.tg(_('All players')),
      playerPosition: 'gk',
      goals: 0,
      playedPossessions: 0,
      totalMinutes: 0,
      saves: 0,
      failedShots: 0,
      postOuts: 0,
      lostBalls: 0,
      percentageLostBalls: 0,
      percentageEfficiency: 0,
      percentageShotEfficiency: 0,
      scoreIndex: 0,
      plusMinusHpi2: '',
      goalsOpponent: 0,
      goalsReceived: 0,
      possessionEvents: 0,
      percentageEfficiencyPossEvent: 0,
      percentageLostBallsPossEvents: 0,
      playerScore: 0,
      scoreAttack: 0,
      scoreDef: 0,
      scoreNeg: 0,
      scorePos: 0
    };

    for(const pl of value) {
      if (res.goals < pl.goals) res.goals = pl.goals;
      if (res.playedPossessions < pl.playedPossessions) res.playedPossessions = pl.playedPossessions;
      if (res.totalMinutes < pl.totalMinutes) res.totalMinutes = pl.totalMinutes;
      if (res.saves < pl.saves) res.saves = pl.saves;
      if (res.failedShots < pl.failedShots) res.failedShots = pl.failedShots;
      if (res.postOuts < pl.postOuts) res.postOuts = pl.postOuts;
      if (res.lostBalls < pl.lostBalls) res.lostBalls = pl.lostBalls;
      if (res.percentageLostBalls < pl.percentageLostBalls) res.percentageLostBalls = pl.percentageLostBalls;
      if (res.percentageEfficiency < pl.percentageEfficiency) res.percentageEfficiency = pl.percentageEfficiency;
      if (res.percentageShotEfficiency < pl.percentageShotEfficiency) res.percentageShotEfficiency = pl.percentageShotEfficiency;
      if (res.scoreIndex < pl.scoreIndex) res.scoreIndex = pl.scoreIndex;
      if (res.plusMinusHpi2 < pl.plusMinusHpi2) res.plusMinusHpi2 = pl.plusMinusHpi2;
      if (res.goalsOpponent < pl.goalsOpponent) res.goalsOpponent = pl.goalsOpponent;
      if (res.goalsReceived < pl.goalsReceived) res.goalsReceived = pl.goalsReceived;
      if (res.possessionEvents < pl.possessionEvents) res.possessionEvents = pl.possessionEvents;
      if (res.percentageLostBallsPossEvents < pl.percentageLostBallsPossEvents) res.percentageLostBallsPossEvents = pl.percentageLostBallsPossEvents;
      if (res.percentageEfficiencyPossEvent < pl.percentageEfficiencyPossEvent) res.percentageEfficiencyPossEvent = pl.percentageEfficiencyPossEvent;
      if (res.playerScore < pl.playerScore) res.playerScore = pl.playerScore;
    }

    return res;
  }


  get homeTeamOverStatsMax(): TeamOverviewStatsModel {
    return this._homeTeamOverStatsMax;
  }
  get homeTeamOverStats(): TeamOverviewStatsModel[] {
    return this._homeTeamOverStats;
  }

  public onExpandTableColumnsClick() {
    this.tablesAllColsShown = !this.tablesAllColsShown;
  }

  @Input()
  set homeTeamOverStats(value: TeamOverviewStatsModel[]) {
    this.logger.debug('TeamStatsComponent.homeTeamOverStats', value);
    if (this.isPublic || this.accountEquipoService.selectedAccountEquipo$.value.account?.permissions.playerScore) {
      this._homeTeamOverStats = value
          .sort((a, b) => b.totalMinutes - a.totalMinutes);
      this.calculateMax(value).then(s => this._homeTeamOverStatsMax = s);
    } else {
      this._homeTeamOverStats = [];
    }
  }

  get visitorTeamOverStatsMax(): TeamOverviewStatsModel {
    return this._visitorTeamOverStatsMax;
  }
  get visitorTeamOverStats(): TeamOverviewStatsModel[] {
    return this._visitorTeamOverStats;
  }

  @Input()
  set visitorTeamOverStats(value: TeamOverviewStatsModel[]) {
    this.logger.debug('TeamStatsComponent.visitorTeamOverStats', value);
    if (this.isPublic || this.accountEquipoService.selectedAccountEquipo$.value.account?.permissions.playerScore) {
      this._visitorTeamOverStats = value
          .sort((a, b) => b.totalMinutes - a.totalMinutes);
      this.calculateMax(value).then(s => this._visitorTeamOverStatsMax = s);
    } else {
      this._visitorTeamOverStats = [];
    }
  }


  @Input()
  set homeTeamEventCounters(value: EventCountersModel) {
    this.logger.debug('TeamStatsComponent.homeTeamEventCounters', value);
    if (this.isPublic || this.accountEquipoService.selectedAccountEquipo$.value.account?.permissions.playerScore) {
      eventTableLeftMap(value, t => this.tg(t)).then(e => this.homeEventsTblLeft$.next(e));
      eventTableRightMap(value, t => this.tg(t)).then(e => this.homeEventsTblRight$.next(e));
    } else {
      this.homeEventsTblLeft$.next([]);
      this.homeEventsTblRight$.next([]);
    }
  }

  @Input()
  set visitorTeamEventCounters(value: EventCountersModel) {
    this.logger.debug('TeamStatsComponent.visitorTeamEventCounters', value);
    if (this.isPublic || this.accountEquipoService.selectedAccountEquipo$.value.account?.permissions.playerScore) {
      eventTableLeftMap(value, t => this.tg(t)).then(e => this.visitorEventsTblLeft$.next(e));
      eventTableRightMap(value, t => this.tg(t)).then(e => this.visitorEventsTblRight$.next(e));
    } else {
      this.visitorEventsTblLeft$.next([]);
      this.visitorEventsTblRight$.next([]);
    }
  }

  @Input()
  homePlayerEventsTbl$: Observable<IndividualStatsEventModelTable[]>;
  @Input()
  visitorPlayerEventsTbl$: Observable<IndividualStatsEventModelTable[]>;

  get homeTeamName(): string {
    return this._homeTeamOverStats.length > 0 ? this._homeTeamOverStats[0].teamName : '';
  }

  get visitorName(): string {
    return this._visitorTeamOverStats.length > 0 ? this._visitorTeamOverStats[0].teamName : '';
  }

  public formatPlaytime(playtime: number): string {
    return formatTimeToHoursMinutesAndSeconds(playtime);
  }

  public calculateEfficiency(row: TeamOverviewStatsModel): string {
    if (row.playerName.includes(' - gk')) {
      return 'n/a';
    }
    return `${this.decimalPipe.transform(row.percentageEfficiencyPossEvent, '1.0-0')}%`;
  }

  public calculateSaves(row: TeamOverviewStatsModel): string {
    if (row.playerName.includes(' - gk')) {
      return row.saves.toString();
    }
    return 'n/a';
  }

  public calculateReceivedGoals(row: TeamOverviewStatsModel): string {
    if (row.playerName.includes(' - gk')) {
      return row.goalsReceived.toString();
    }
    return 'n/a';
  }

  public calculateShotEff(row: TeamOverviewStatsModel): string {
    if (row.playerName.includes(' - gk')) {
      return `${this.decimalPipe.transform(row.percentageShotEfficiency, '1.0-0')}% [Gk Saving]`;
    }
    return `${this.decimalPipe.transform(row.percentageShotEfficiency, '1.0-0')}%`;
  }

  public async onEditPositionClick(row: TeamOverviewStatsModel) {
    const popover = await this.core.popoverCtrl.create({
      component: EditPositionModalComponent,
      cssClass: 'select-folder-popover',
      mode: 'ios',
      componentProps: {
        playerName: row.playerName,
        currentPosition: row.playerPosition
      },
    });
    popover.onDidDismiss().then(async (res: any) => {
      if (res.data) {
        await this.core.loadingService.present();
        try {
          await this.updatePlayerPositionInGameSnapshot(row.playerId, res.data.position);

          row.playerPosition = res.data.position;
          const playerNameAndNumber = row.playerName.split(' - ');
          row.playerName = `${playerNameAndNumber[0]} - ${playerNameAndNumber[1]} - ${res.data.position}`;
          const player = this._homeTeamOverStats.find(stats => stats.playerId === row.playerId);
          if (player) {
            player.playerName = row.playerName;
            player.playerPosition = row.playerPosition;
            this._homeTeamOverStats = [...this._homeTeamOverStats];
          }
          await this.core.gameDataService.readGame(this.gameId, null, false, undefined, true);
          await this.core.gameDataService.updateGameStatistics();
        } catch (err) {
          console.error('Error updating player position in game snapshot', err);
        } finally {
          this.core.loadingService.dismiss();
        }
      }
    });
    await popover.present();
  }

  async updatePlayerPositionInGameSnapshot(playerId: number, newPosition: string) {
    const game: GameDto = this.core.gameDataService.game$.value;
    const gameModelSnapshot = JSON.parse(game.gameModelSnapshot);

    let player = gameModelSnapshot.home.players.find(player => player.id === playerId);
    let playerInField = gameModelSnapshot.home.currentField.find(player => player.id === playerId);
    let playerInBench = gameModelSnapshot.home.currentBench.find(player => player.id === playerId);

    if (player && (playerInBench || playerInField)) {
      player.position = newPosition;
      if (playerInBench) {
        playerInBench.position = newPosition;
      } else if (playerInField) {
        playerInField.position = newPosition;
      }
    } else {
      player = gameModelSnapshot.visitor.players.find(player => player.id === playerId);
      playerInField = gameModelSnapshot.visitor.currentField.find(player => player.id === playerId);
      playerInBench = gameModelSnapshot.visitor.currentBench.find(player => player.id === playerId);
      if (player && (playerInBench || playerInField)) {
        player.position = newPosition;
        if (playerInBench) {
          playerInBench.position = newPosition;
        } else if (playerInField) {
          playerInField.position = newPosition;
        }
      }
    }
    game.gameModelSnapshot = JSON.stringify(gameModelSnapshot);
    const updateGameResultDto: UpdateGameResultDto = {
      folderId: game.folderId,
      goalsHome: game.goalsHome,
      goalsVisitor: game.goalsVisitor,
      firstHalfEnded: game.firstHalfEnded,
      secondHalfEnded: game.secondHalfEnded,
      gameStatus: game.gameStatus,
      gameEnded: game.gameEnded,
      gameDateTime: game.date,
      playByPlayDto: [],
      playTimeDto: [],
      playerStatsDto: [],
      lineupStatsDto: [],
      teamStatsDto: [],
      gameModelSnapshot: game.gameModelSnapshot
    };

    await this.core.gameDataService.updateGame(this.gameId, updateGameResultDto);
  }

  public setTeamSelected(team){
    if(team === this.visitorName){
      this.selectedTeam.code = "visitor";
      this.selectedTeam.name = this.visitorName;
    }else{
      this.selectedTeam.code = "home";
      this.selectedTeam.name = this.homeTeamName;
    }
    return this.selectedTeam;
  }

  public setTableSelected(table){
    this.selectedTable = table;
    this.tablesAllColsShown = false;
    // this.selectedTable.name = table;
    // table = table.toUpperCase();
    // switch (table) {
    //   case 'PLAYERS TABLE':
    //     this.selectedTable.code = "players";
    //     break;
    //   case 'PLAYER EVENTS TABLE':
    //     this.selectedTable.code = "player-events";
    //     break;
    //   case 'TEAM EVENTS TABLE':
    //     this.selectedTable.code = "team-events";
    //     break;

    //   default:
    //     break;
    // }
    return this.selectedTable;
  }


}
