import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { LoadingController, ModalController, PopoverController, ToastController } from "@ionic/angular";
import { HandballTimerService } from "src/app/shared-services/timer/handball-timer.service";
import { GameDataService } from "src/app/shared-services/game-data/game-data.service";
import { GameService } from "src/app/shared-services/game/game.service";
import { PlayTimeService } from "src/app/shared-services/statistics/play-time/play-time.service";
import { Router } from "@angular/router";
import { PlaytimeEventModel } from "@handballai/stats-calculation";
import { TeamOverviewSubConsumerService } from "src/app/shared-services/statistics/playbyplay/sub-consumer/team-overview/team-overview-sub-consumer.service";
import { GoalConsumerService } from "src/app/shared-services/statistics/playbyplay/consumer/goal/goal-consumer.service";
import { OverviewConsumerService } from "src/app/shared-services/statistics/playbyplay/consumer/overview/overview-consumer.service";
import { NGXLogger } from "ngx-logger";
import { GameEndStorageModel } from "src/app/shared-services/storage-service/model/game-end-storage.model";
import { StorageService } from "src/app/shared-services/storage-service/storage.service";
import { EventBufferService } from "src/app/shared-services/ui-event-dispatcher/buffer/event-buffer.service";
import { BehaviorSubject, Observable } from "rxjs";
import { CoreService } from "src/app/shared-services/core.service";
import { GameFilterTableModel } from "src/app/shared-services/game-data/model/game-filter-table.model";
import { db } from "src/app/db";
import { PlayByPlayDto, PlayTimeDto} from "src/app/api/hai-api";
import { PdfReportComponent } from "../../../../../game-module/components/pdf-report/pdf-report.component";
import { NetworkErrorPopoverComponent } from "../network-error-popover/network-error-popover.component";
import { AccountEquipoService } from "src/app/shared-services/account-equipo/account-equipo.service";

@Component({
  selector: "app-timer-popover",
  templateUrl: "./timer-popover.component.html",
  styleUrls: ["./timer-popover.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimerPopoverComponent implements OnInit {
  public gameEnded$: Observable<boolean>;
  public asobalActaRemainder$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private endGameStorageModel: GameEndStorageModel = null;

  @Input() closePlayTimeSlots: PlaytimeEventModel[];

  constructor(
    public readonly _core: CoreService,
    private readonly popoverCntl: PopoverController,
    private readonly timerService: HandballTimerService,
    private readonly gameDataService: GameDataService,
    private readonly gameService: GameService,
    private readonly teamOverviewSubConsumerService: TeamOverviewSubConsumerService,
    private readonly goalConsumerService: GoalConsumerService,
    private readonly overviewConsumerService: OverviewConsumerService,
    private readonly playTimeService: PlayTimeService,
    private readonly router: Router,
    private readonly logger: NGXLogger,
    private readonly accountEquipoService: AccountEquipoService,
    private readonly storageService: StorageService,
    private readonly toastCntl: ToastController,
    private readonly eventBufferService: EventBufferService,
    private readonly modalCtrl: ModalController,
    private readonly loadingCtrl: LoadingController
  ) {
    this.gameEnded$ = this.timerService.gameEnded$;
  }

  ngOnInit() {}

  public async onEndGameConfirm(): Promise<void> {
    await this.timerService.endGame();

    // Important push all events in undo queue through
    await this.eventBufferService.openTransaction();

    // Stop queue
    await this.gameDataService.stopGamePushing();

    // TODO: This is duplicated code from ScoreBoardComponent, maybe move to handler or service
    // Store close time slots at the end of game
    await this.gameDataService.addPlayTimeRecord(this.gameService.gameId, this.closePlayTimeSlots);
    // Get full play by play and play time events from indexedDB
    const fullPlayByPlay: PlayByPlayDto[] = await db.playByPlay.where('gameSrvId').equals(this.gameService.gameId).toArray();
    const fullPlayerEvents: PlayTimeDto[] = await db.playTime.where('gameSrvId').equals(this.gameService.gameId).toArray();

    // Update local storage
    await db.game.where('srvId').equals(this.gameService.gameId).modify({
      firstHalfEnded: true,
      secondHalfEnded: true,
      gameEnded: true,
      gameStatus: 'ended',
      goalsHome: this.gameService.gameModel.scoreHome,
      goalsVisitor: this.gameService.gameModel.scoreVisitor,
      gameDateTime: this.gameService.gameModel.startDate.toISO(),
    });

    this.endGameStorageModel = {
      gameId: this.gameService.gameId,
      updateGameResultDto: {
        firstHalfEnded: true,
        secondHalfEnded: true,
        gameEnded: true,
        gameStatus: 'ended',
        goalsHome: this.gameService.gameModel.scoreHome,
        goalsVisitor: this.gameService.gameModel.scoreVisitor,
        playerStatsDto: this.goalConsumerService
            .transformToPlayerStatisticsDto(this.teamOverviewSubConsumerService.getPlayerStatisticsDto()),
        teamStatsDto: this.goalConsumerService.transformToTeamStatisticsDto(this.overviewConsumerService.generateTeamStatsDto()),
        lineupStatsDto: this.goalConsumerService.transformToLineupStatisticsDto(),
        playByPlayDto: fullPlayByPlay,
        playTimeDto: fullPlayerEvents,
        gameDateTime: this.gameService.gameModel.startDate.toISO(),
      },
    } as GameEndStorageModel;

    try {
      await this.gameDataService.updateGame(this.endGameStorageModel.gameId, this.endGameStorageModel.updateGameResultDto);
      this.toastCntl
        .create({
          icon: "checkmark-circle-outline",
          color: "success",
          message: `Successfully saved Game on server!`,
          duration: 1000,
        })
        .then((value) => value.present());
      await db.game.where('srvId').equals(this.gameService.gameId).modify({ isUploadPending: 0 });
    } catch (err) {
      this.logger.error("TimerPopoverComponent.onEndGameConfirm Error updating the Game writing to Local Storage", err);
      // This is no longer needed
      // We will read the game from storage and produce the stats on the fly
      //await this.storageService.saveGameEndResult(endGameStorageModel);
      await this.presentAlert();
      if (this._core.userService.checkForBoolPermission('pastGames') && this._core.userService.checkForGrant('view_game')) {
        this.router.navigate(["/account/"+this._core.accountEquipoService.selectedAccountEquipo$.getValue().id+"/game"]);
      } else {
        this.router.navigate(["/account/"+this._core.accountEquipoService.selectedAccountEquipo$.getValue().id+"/team"]);
      }
    } finally {
      this._core.houseKeepingService.checkForPendingUploads();
      window.onbeforeunload = null;
    }
  }

  public async pdfDownlaod() {
    await this.openPdf();
    await this.noticeOrDismiss();
  }

  public async openPdf(): Promise<void> {
    if (!this.endGameStorageModel) {
      this.logger.error('Trying to generate PDF without generating first GameEndStorageModel')
      return;
    }

    const game: GameFilterTableModel = {
      id: this._core.gameService.gameId,
      home: this._core.gameService.gameModel.home.name,
      homeId: this._core.gameService.gameModel.home.id,
      visitor: this._core.gameService.gameModel.visitor.name,
      visitorId: this._core.gameService.gameModel.visitor.id,
      folderId: null,
      date: "First Half " + new Date().toDateString(),
      accessHash: "",
      result: `${this._core.gameService.gameModel.scoreHome}:${this._core.gameService.gameModel.scoreVisitor}`,
      gameType: this._core.gameService.gameMode$.value,
      gameMode: this._core.gameService.liveMode,
      gameStatus: 'started',
      videoStatus: 'absent',
    };
    this.logger.debug("SearchGameTableComponent.openPdf - id: ", game.id);

    this.loadingCtrl.create({ message: this._core.trans.instant('Generating PDF. Please wait') }).then((loading) => {
      loading.present();
      this.modalCtrl
        .create({
          component: PdfReportComponent,
          componentProps: {
            localGame: {
              date: game.date,
              homeId: game.homeId,
              visitorId: game.visitorId,
              homeName: game.home,
              visitorName: game.visitor,
              gameType: game.gameType,
              endGameStorageModel: this.endGameStorageModel,
            },
          },
          cssClass: 'modal-invisible',
        })
        .then((m) => {
          m.onDidDismiss().then(() => loading.dismiss());
          m.present();
        });
    });
  }

  dismiss = async () => await this.popoverCntl.dismiss();
  public async endDismiss() {
    await this.dismiss();
    this.router.navigate(["/account/"+this._core.accountEquipoService.selectedAccountEquipo$.getValue().id+"/game"]);
  }

  noticeOrDismiss() {
    let notice = this.accountEquipoService.selectedAccountEquipo$.getValue().account?.permissions.asobalRemainder ? 'Finalizar Acta': null;
    // notice = notice.replace('{idpartidohandballai}', `${this.gameService.gameId}`);
    if (notice) {
      this.asobalActaRemainder$.next(notice);
    } else {
      this.endDismiss();
    }
  }

  openAsobalLink() {
    location.href = 'https://estadistico.asobal.es/actahai.php?id='+this.gameService.gameId;
  }

  public async presentAlert(): Promise<void> {
    await this.popoverCntl.dismiss();
    const popover = await this.popoverCntl.create({
      component: NetworkErrorPopoverComponent,
      cssClass: "global__team-verification-popover",
      // translucent: true,
      backdropDismiss: false,
      mode: "ios",
    });
    await popover.present();
  }

}
