/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PlanPermsDto { 
    /**
     * MaxUsers
     */
    maxUsers: number;
    /**
     * MaxAccountEquipos
     */
    maxAccountEquipos: number;
    /**
     * MaxTeams
     */
    maxTeams: number;
    /**
     * MaxTeamsPlayers
     */
    maxTeamsPlayers: number;
    /**
     * MaxGames
     */
    maxGames: number;
    /**
     * PdfDownload
     */
    pdfDownload: boolean;
    /**
     * CsvUpload
     */
    csvUpload: boolean;
    /**
     * Dashboard
     */
    dashboard: boolean;
    /**
     * PastGames
     */
    pastGames: boolean;
    /**
     * PlayerScore
     */
    playerScore: boolean;
    /**
     * PlusMinus
     */
    plusMinus: boolean;
    /**
     * ShareLink
     */
    shareLink: boolean;
    /**
     * Access PlayByPlay
     */
    accessPlayByPlay: boolean;
    /**
     * Download PlayByPlay
     */
    askDownloadPlayByPlay: boolean;
    /**
     * Schedule Games
     */
    scheduleGames: boolean;
    /**
     * AppsIntegration
     */
    appsIntegration: boolean;
    /**
     * AsobalRemainder
     */
    asobalRemainder: boolean;
    /**
     * XPSRemainder
     */
    xpsReminder: boolean;
    /**
     * HAI ProAccess
     */
    haiProAccess: boolean;
    /**
     * AccessScouting
     */
    accessScouting: boolean;
    /**
     * Disallow ShareMyTeam
     */
    disallowShareMyTeam: boolean;
    /**
     * Disallow ShareMyTeam
     */
    disallowContinueTracking: boolean;
    /**
     * AccessMyTeam
     */
    accessMyTeam: boolean;
    /**
     * ScoutingConnect
     */
    scoutingConnect: boolean;
    /**
     * Plan constant
     */
    basePlan: string;
    /**
     * Plan price
     */
    price?: number;
}

