<ionic-selectable
  #seasonsSelect
  item-content
  name="seasons"
  [(ngModel)]="seasons"
  [items]="seasons$ | async"
  [itemTextField]="'seasonName'"
  [isMultiple]="multiple"
  (onChange)="onSeasonSelect()"
  [placeholder]="'Select seasons' | translate"
>
  <ng-template ionicSelectableHeaderTemplate> </ng-template>
  <ng-template ionicSelectableItemTemplate let-port="item" let-isPortSelected="isItemSelected">
    {{ port.seasonName }}
    <ion-badge *ngIf="port.isCurrent" color="success" style="margin-left: 10px">
      {{ "CURRENT" | translate }}
    </ion-badge>
  </ng-template>
  <ng-template ionicSelectableFooterTemplate>
    <ion-toolbar>
      <ion-row>
        <ion-col *ngIf="seasonsSelect.canClear">
          <ion-button expand="full" (click)="seasonsSelect.clear()" [disabled]="!seasonsSelect.itemsToConfirm.length">
            {{ seasonsSelect.clearButtonText }}
          </ion-button>
        </ion-col>
        <ion-col *ngIf="
        (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('create_game') &&
        (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('manage_teams')">
          <ion-button expand="full"
            [disabled]="!(seasonsSelect._selectedItems.length == 1 && !seasonsSelect._selectedItems[0].isCurrent)"
            (click)="changeCurrentSeason(seasonsSelect._selectedItems[0])">
            {{ "Set as current" | translate }}
          </ion-button>
        </ion-col>
        <ion-col *ngIf="seasonsChange.observers.length || onChange.observers.length">
          <ion-button expand="full" (click)="seasonsSelect._confirm()">
            {{ seasonsSelect.confirmButtonText }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ng-template>
  <ng-template ionicSelectableValueTemplate let-ports="value">
    <div class="ionic-selectable-value-item" *ngIf="multiple">{{ ports.length }} {{ "Seasons" | translate }}</div>
    <div class="ionic-selectable-value-item" *ngIf="!multiple">{{ $any(seasons).seasonName }}</div>
  </ng-template>
</ionic-selectable>
<ng-template #valueTemplate let-ports="value">
  <div class="ionic-selectable-value-item" *ngIf="multiple">{{ ports.length }} {{ "Seasons" | translate }}</div>
  <div class="ionic-selectable-value-item" *ngIf="!multiple">{{ $any(seasons).seasonName }}</div>
</ng-template>
<ng-template #footerTemplate>
  <ion-toolbar>
    <ion-row>
      <ion-col *ngIf="seasonsSelect.canClear">
        <ion-button expand="full" (click)="seasonsSelect.clear()" [disabled]="!seasonsSelect.itemsToConfirm.length">
          {{ seasonsSelect.clearButtonText }}
        </ion-button>
      </ion-col>
      <ion-col *ngIf="
        (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('create_game') &&
        (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('manage_teams')">
        <ion-button expand="full"
                    [disabled]="!(seasonsSelect._selectedItems.length == 1 && !seasonsSelect._selectedItems[0].isCurrent)"
                    (click)="changeCurrentSeason(seasonsSelect._selectedItems[0])">
          {{ "Set as current" | translate }}
        </ion-button>
      </ion-col>
      <ion-col *ngIf="seasonsChange.observers.length || onChange.observers.length">
        <ion-button expand="full" (click)="seasonsSelect._confirm()">
          {{ seasonsSelect.confirmButtonText }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ng-template>
<ng-template #itemTemplate let-port="item" let-isPortSelected="isItemSelected">
  {{ port.seasonName }}
  <ion-badge *ngIf="port.isCurrent" color="success" style="margin-left: 10px">
    {{ "CURRENT" | translate }}
  </ion-badge>
</ng-template>
<ng-template #headerTemplate></ng-template>
