import { Component, Input, OnInit } from '@angular/core';
import { CounterModel, QuickStatsColumnModel, QuickStatsColumnModelList } from '@handballai/stats-calculation';

@Component({
    selector: 'app-quick-stats-team-team-stats',
    templateUrl: './quick-stats-team-team-stats.component.html',
    styleUrls: ['./quick-stats-team-team-stats.component.scss'],
    standalone: false
})
export class QuickStatsTeamTeamStatsComponent implements OnInit {
  private _quickOverviewModel: QuickStatsColumnModel | undefined = undefined;
  private _quickOverviewModelOpponent: QuickStatsColumnModel | undefined = undefined;
  private _quick7MetersGoals = 0;
  private _isHomeTeam = true;

  constructor() { }

  ngOnInit(): void {
  }

  get quickOverviewModel(): QuickStatsColumnModel | undefined {
    if(this._isHomeTeam){
      return this._quickOverviewModel;
    }else{
      return this._quickOverviewModelOpponent;
    }
  }

  get quickOverviewModelOpponent(): QuickStatsColumnModel | undefined {
    if(this._isHomeTeam){
      return this._quickOverviewModelOpponent;
    }else{
      return this._quickOverviewModel;
    }
  }

  get quick7MetersGoals(): number {
    return this._quick7MetersGoals;
  }

  @Input()
  set quickGoalCounters(value: CounterModel[]) {
    if (value.length > 1) {
      this._quick7MetersGoals = value.filter(c => c.counterType === 'GOAL-7_METERS')[0].counter;
    }
  }

  @Input()
  set isHomeTeam(value: boolean) {
      this._isHomeTeam = value;
  }

  @Input()
  set quickStatsColumnModelList(value: QuickStatsColumnModelList) {
    if (value && value.length > 1) {
      this._quickOverviewModel = value[0];
      this._quickOverviewModelOpponent = value[1];
    }
  }

  public calculatePercentageItem(dividend: number, divisor: number): number {
    if (dividend && divisor) {
      return (divisor === 0 ? 0 : 100 * (dividend / divisor));
    }
    return 0;
  }

  public calculateTotalPossesions(totalPossessions: number, possessionsOffensePositional: number, possessionsFastBreak: number, possessionsCounterGoal: number): string {
    const phasePossessionsTotal = (possessionsOffensePositional + possessionsFastBreak + possessionsCounterGoal);
    if(phasePossessionsTotal > totalPossessions){
      return totalPossessions + " + " + (phasePossessionsTotal - totalPossessions);
    }
    return totalPossessions ? totalPossessions.toFixed(0) : '0';
  }

}
