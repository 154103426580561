/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PlayerStatsDto { 
    /**
     * id
     */
    id?: number;
    /**
     * player id
     */
    playerId: number;
    /**
     * Game descr
     */
    gameDesc?: string;
    /**
     * Game id
     */
    gameId?: number;
    /**
     * player name
     */
    playerName: string;
    /**
     * player position
     */
    playerPosition: string;
    /**
     * player back number
     */
    playerBackNumber: number;
    /**
     * team id
     */
    teamId: number;
    /**
     * team name
     */
    teamName: string;
    /**
     * time played
     */
    timePlayed: number;
    /**
     * possessions played
     */
    possessionsPlayed: number;
    /**
     * goals
     */
    goals: number;
    /**
     * saves
     */
    saves: number;
    /**
     * lostBalls
     */
    lostBalls: number;
    /**
     * lostBallsOffensePositional
     */
    lostBallsOffensePositional: number;
    /**
     * lostBallsFastBreak
     */
    lostBallsFastBreak: number;
    /**
     * lostBallsCounterGoal
     */
    lostBallsCounterGoal: number;
    /**
     * attackFaultProvoke
     */
    attackFaultProvoke: number;
    /**
     * attackFaultCommit
     */
    attackFaultCommit: number;
    /**
     * technicalMistakeProvoke
     */
    technicalMistakeProvoke: number;
    /**
     * technicalMistakeCommit
     */
    technicalMistakeCommit: number;
    /**
     * lostBallProvoke
     */
    lostBallProvoke: number;
    /**
     * lostBall
     */
    lostBall: number;
    /**
     * twoMinCommit
     */
    twoMinCommit: number;
    /**
     * twoMinProvoke
     */
    twoMinProvoke: number;
    /**
     * saves
     */
    sevenMetersCommit: number;
    /**
     * sevenMetersProvoke
     */
    sevenMetersProvoke: number;
    /**
     * sevenMSuspCommit
     */
    sevenMSuspCommit: number;
    /**
     * sevenMSuspProvoke
     */
    sevenMSuspProvoke: number;
    /**
     * foulCommit
     */
    foulCommit: number;
    /**
     * foulReceive
     */
    foulReceive: number;
    /**
     * oneAndOneLost
     */
    oneAndOneLost: number;
    /**
     * oneAndOneWon
     */
    oneAndOneWon: number;
    /**
     * block
     */
    block: number;
    /**
     * shotBlocked
     */
    shotBlocked: number;
    /**
     * efficiency
     */
    efficiency: number;
    /**
     * shotEfficiency
     */
    shotEfficiency: number;
    /**
     * lostBallPerc
     */
    lostBallPerc: number;
    /**
     * score
     */
    score: number;
    /**
     * score
     */
    score5: number;
    /**
     * scorePos
     */
    scorePos: number;
    /**
     * scoreNeg
     */
    scoreNeg: number;
    /**
     * scoreAttack
     */
    scoreAttack: number;
    /**
     * scoreDef
     */
    scoreDef: number;
    /**
     * plusMinus
     */
    plusMinus: number;
    /**
     * goalsOffensePositional
     */
    goalsOffensePositional: number;
    /**
     * goalsFastBreak
     */
    goalsFastBreak: number;
    /**
     * goalsCounterGoal
     */
    goalsCounterGoal: number;
    /**
     * savesOffensePositional
     */
    savesOffensePositional: number;
    /**
     * savesFastBreak
     */
    savesFastBreak: number;
    /**
     * savesCounterGoal
     */
    savesCounterGoal: number;
    /**
     * goalsLeftWing
     */
    goalsLeftWing: number;
    /**
     * goalsLeft6m
     */
    goalsLeft6m: number;
    /**
     * goalsCenter6m
     */
    goalsCenter6m: number;
    /**
     * goalsRight6m
     */
    goalsRight6m: number;
    /**
     * goalsRightWing
     */
    goalsRightWing: number;
    /**
     * goalsLeft9m
     */
    goalsLeft9m: number;
    /**
     * goalsCenter9m
     */
    goalsCenter9m: number;
    /**
     * goalsRight9m
     */
    goalsRight9m: number;
    /**
     * goals7Meters
     */
    goals7Meters: number;
    /**
     * goalsOppositeField
     */
    goalsOppositeField: number;
    /**
     * savesLeftWing
     */
    savesLeftWing: number;
    /**
     * savesLeft6m
     */
    savesLeft6m: number;
    /**
     * savesCenter6m
     */
    savesCenter6m: number;
    /**
     * savesRight6m
     */
    savesRight6m: number;
    /**
     * savesRightWing
     */
    savesRightWing: number;
    /**
     * savesLeft9m
     */
    savesLeft9m: number;
    /**
     * savesCenter9m
     */
    savesCenter9m: number;
    /**
     * savesRight9m
     */
    savesRight9m: number;
    /**
     * saves7Meters
     */
    saves7Meters: number;
    /**
     * savesOppositeField
     */
    savesOppositeField: number;
    /**
     * goals1
     */
    goals1: number;
    /**
     * goals2
     */
    goals2: number;
    /**
     * goals3
     */
    goals3: number;
    /**
     * goals4
     */
    goals4: number;
    /**
     * goals5
     */
    goals5: number;
    /**
     * goals6
     */
    goals6: number;
    /**
     * goals7
     */
    goals7: number;
    /**
     * goals8
     */
    goals8: number;
    /**
     * goals9
     */
    goals9: number;
    /**
     * saves1
     */
    saves1: number;
    /**
     * saves2
     */
    saves2: number;
    /**
     * saves3
     */
    saves3: number;
    /**
     * saves4
     */
    saves4: number;
    /**
     * saves5
     */
    saves5: number;
    /**
     * saves6
     */
    saves6: number;
    /**
     * saves7
     */
    saves7: number;
    /**
     * saves8
     */
    saves8: number;
    /**
     * saves9
     */
    saves9: number;
    /**
     * failedShotsOffensePositional
     */
    failedShotsOffensePositional: number;
    /**
     * failedShotsFastBreak
     */
    failedShotsFastBreak: number;
    /**
     * failedShotsCounterGoal
     */
    failedShotsCounterGoal: number;
    /**
     * postOut
     */
    postOut: number;
    /**
     * receivedGoals
     */
    receivedGoals: number;
    /**
     * postOutCenter6m
     */
    postOutCenter6m: number;
    /**
     * postOutCenter9m
     */
    postOutCenter9m: number;
    /**
     * postOutLeft6m
     */
    postOutLeft6m: number;
    /**
     * postOutLeft9m
     */
    postOutLeft9m: number;
    /**
     * postOutLeftWing
     */
    postOutLeftWing: number;
    /**
     * postOutOppositeField
     */
    postOutOppositeField: number;
    /**
     * postOutRight6m
     */
    postOutRight6m: number;
    /**
     * postOutRight9m
     */
    postOutRight9m: number;
    /**
     * postOutRightWing
     */
    postOutRightWing: number;
    /**
     * postOut7Meters
     */
    postOut7Meters: number;
    /**
     * postOut0
     */
    postOut0: number;
    /**
     * postOut10
     */
    postOut10: number;
    /**
     * postOut11
     */
    postOut11: number;
    /**
     * goalsLeftWing0
     */
    goalsLeftWing0?: number;
    /**
     * goalsLeftWing1
     */
    goalsLeftWing1?: number;
    /**
     * goalsLeftWing2
     */
    goalsLeftWing2?: number;
    /**
     * goalsLeftWing3
     */
    goalsLeftWing3?: number;
    /**
     * goalsLeftWing4
     */
    goalsLeftWing4?: number;
    /**
     * goalsLeftWing5
     */
    goalsLeftWing5?: number;
    /**
     * goalsLeftWing6
     */
    goalsLeftWing6?: number;
    /**
     * goalsLeftWing7
     */
    goalsLeftWing7?: number;
    /**
     * goalsLeftWing8
     */
    goalsLeftWing8?: number;
    /**
     * goalsLeftWing9
     */
    goalsLeftWing9?: number;
    /**
     * goalsLeftWing10
     */
    goalsLeftWing10?: number;
    /**
     * goalsLeftWing11
     */
    goalsLeftWing11?: number;
    /**
     * goalsLeft6m0
     */
    goalsLeft6m0?: number;
    /**
     * goalsLeft6m1
     */
    goalsLeft6m1?: number;
    /**
     * goalsLeft6m2
     */
    goalsLeft6m2?: number;
    /**
     * goalsLeft6m3
     */
    goalsLeft6m3?: number;
    /**
     * goalsLeft6m4
     */
    goalsLeft6m4?: number;
    /**
     * goalsLeft6m5
     */
    goalsLeft6m5?: number;
    /**
     * goalsLeft6m6
     */
    goalsLeft6m6?: number;
    /**
     * goalsLeft6m7
     */
    goalsLeft6m7?: number;
    /**
     * goalsLeft6m8
     */
    goalsLeft6m8?: number;
    /**
     * goalsLeft6m9
     */
    goalsLeft6m9?: number;
    /**
     * goalsLeft6m10
     */
    goalsLeft6m10?: number;
    /**
     * goalsLeft6m11
     */
    goalsLeft6m11?: number;
    /**
     * goalsCenter6m0
     */
    goalsCenter6m0?: number;
    /**
     * goalsCenter6m1
     */
    goalsCenter6m1?: number;
    /**
     * goalsCenter6m2
     */
    goalsCenter6m2?: number;
    /**
     * goalsCenter6m3
     */
    goalsCenter6m3?: number;
    /**
     * goalsCenter6m4
     */
    goalsCenter6m4?: number;
    /**
     * goalsCenter6m5
     */
    goalsCenter6m5?: number;
    /**
     * goalsCenter6m6
     */
    goalsCenter6m6?: number;
    /**
     * goalsCenter6m7
     */
    goalsCenter6m7?: number;
    /**
     * goalsCenter6m8
     */
    goalsCenter6m8?: number;
    /**
     * goalsCenter6m9
     */
    goalsCenter6m9?: number;
    /**
     * goalsCenter6m10
     */
    goalsCenter6m10?: number;
    /**
     * goalsCenter6m11
     */
    goalsCenter6m11?: number;
    /**
     * goalsRight6m0
     */
    goalsRight6m0?: number;
    /**
     * goalsRight6m1
     */
    goalsRight6m1?: number;
    /**
     * goalsRight6m2
     */
    goalsRight6m2?: number;
    /**
     * goalsRight6m3
     */
    goalsRight6m3?: number;
    /**
     * goalsRight6m4
     */
    goalsRight6m4?: number;
    /**
     * goalsRight6m5
     */
    goalsRight6m5?: number;
    /**
     * goalsRight6m6
     */
    goalsRight6m6?: number;
    /**
     * goalsRight6m7
     */
    goalsRight6m7?: number;
    /**
     * goalsRight6m8
     */
    goalsRight6m8?: number;
    /**
     * goalsRight6m9
     */
    goalsRight6m9?: number;
    /**
     * goalsRight6m10
     */
    goalsRight6m10?: number;
    /**
     * goalsRight6m11
     */
    goalsRight6m11?: number;
    /**
     * goalsRightWing0
     */
    goalsRightWing0?: number;
    /**
     * goalsRightWing1
     */
    goalsRightWing1?: number;
    /**
     * goalsRightWing2
     */
    goalsRightWing2?: number;
    /**
     * goalsRightWing3
     */
    goalsRightWing3?: number;
    /**
     * goalsRightWing4
     */
    goalsRightWing4?: number;
    /**
     * goalsRightWing5
     */
    goalsRightWing5?: number;
    /**
     * goalsRightWing6
     */
    goalsRightWing6?: number;
    /**
     * goalsRightWing7
     */
    goalsRightWing7?: number;
    /**
     * goalsRightWing8
     */
    goalsRightWing8?: number;
    /**
     * goalsRightWing9
     */
    goalsRightWing9?: number;
    /**
     * goalsRightWing10
     */
    goalsRightWing10?: number;
    /**
     * goalsRightWing11
     */
    goalsRightWing11?: number;
    /**
     * goalsLeft9m0
     */
    goalsLeft9m0?: number;
    /**
     * goalsLeft9m1
     */
    goalsLeft9m1?: number;
    /**
     * goalsLeft9m2
     */
    goalsLeft9m2?: number;
    /**
     * goalsLeft9m3
     */
    goalsLeft9m3?: number;
    /**
     * goalsLeft9m4
     */
    goalsLeft9m4?: number;
    /**
     * goalsLeft9m5
     */
    goalsLeft9m5?: number;
    /**
     * goalsLeft9m6
     */
    goalsLeft9m6?: number;
    /**
     * goalsLeft9m7
     */
    goalsLeft9m7?: number;
    /**
     * goalsLeft9m8
     */
    goalsLeft9m8?: number;
    /**
     * goalsLeft9m9
     */
    goalsLeft9m9?: number;
    /**
     * goalsLeft9m10
     */
    goalsLeft9m10?: number;
    /**
     * goalsLeft9m11
     */
    goalsLeft9m11?: number;
    /**
     * goalsCenter9m0
     */
    goalsCenter9m0?: number;
    /**
     * goalsCenter9m1
     */
    goalsCenter9m1?: number;
    /**
     * goalsCenter9m2
     */
    goalsCenter9m2?: number;
    /**
     * goalsCenter9m3
     */
    goalsCenter9m3?: number;
    /**
     * goalsCenter9m4
     */
    goalsCenter9m4?: number;
    /**
     * goalsCenter9m5
     */
    goalsCenter9m5?: number;
    /**
     * goalsCenter9m6
     */
    goalsCenter9m6?: number;
    /**
     * goalsCenter9m7
     */
    goalsCenter9m7?: number;
    /**
     * goalsCenter9m8
     */
    goalsCenter9m8?: number;
    /**
     * goalsCenter9m9
     */
    goalsCenter9m9?: number;
    /**
     * goalsCenter9m10
     */
    goalsCenter9m10?: number;
    /**
     * goalsCenter9m11
     */
    goalsCenter9m11?: number;
    /**
     * goalsRight9m0
     */
    goalsRight9m0?: number;
    /**
     * goalsRight9m1
     */
    goalsRight9m1?: number;
    /**
     * goalsRight9m2
     */
    goalsRight9m2?: number;
    /**
     * goalsRight9m3
     */
    goalsRight9m3?: number;
    /**
     * goalsRight9m4
     */
    goalsRight9m4?: number;
    /**
     * goalsRight9m5
     */
    goalsRight9m5?: number;
    /**
     * goalsRight9m6
     */
    goalsRight9m6?: number;
    /**
     * goalsRight9m7
     */
    goalsRight9m7?: number;
    /**
     * goalsRight9m8
     */
    goalsRight9m8?: number;
    /**
     * goalsRight9m9
     */
    goalsRight9m9?: number;
    /**
     * goalsRight9m10
     */
    goalsRight9m10?: number;
    /**
     * goalsRight9m11
     */
    goalsRight9m11?: number;
    /**
     * goals7Meters0
     */
    goals7Meters0?: number;
    /**
     * goals7Meters1
     */
    goals7Meters1?: number;
    /**
     * goals7Meters2
     */
    goals7Meters2?: number;
    /**
     * goals7Meters3
     */
    goals7Meters3?: number;
    /**
     * goals7Meters4
     */
    goals7Meters4?: number;
    /**
     * goals7Meters5
     */
    goals7Meters5?: number;
    /**
     * goals7Meters6
     */
    goals7Meters6?: number;
    /**
     * goals7Meters7
     */
    goals7Meters7?: number;
    /**
     * goals7Meters8
     */
    goals7Meters8?: number;
    /**
     * goals7Meters9
     */
    goals7Meters9?: number;
    /**
     * goals7Meters10
     */
    goals7Meters10?: number;
    /**
     * goals7Meters11
     */
    goals7Meters11?: number;
    /**
     * goalsOppositeField0
     */
    goalsOppositeField0?: number;
    /**
     * goalsOppositeField1
     */
    goalsOppositeField1?: number;
    /**
     * goalsOppositeField2
     */
    goalsOppositeField2?: number;
    /**
     * goalsOppositeField3
     */
    goalsOppositeField3?: number;
    /**
     * goalsOppositeField4
     */
    goalsOppositeField4?: number;
    /**
     * goalsOppositeField5
     */
    goalsOppositeField5?: number;
    /**
     * goalsOppositeField6
     */
    goalsOppositeField6?: number;
    /**
     * goalsOppositeField7
     */
    goalsOppositeField7?: number;
    /**
     * goalsOppositeField8
     */
    goalsOppositeField8?: number;
    /**
     * goalsOppositeField9
     */
    goalsOppositeField9?: number;
    /**
     * goalsOppositeField10
     */
    goalsOppositeField10?: number;
    /**
     * goalsOppositeField11
     */
    goalsOppositeField11?: number;
    /**
     * shootsLeftWing0
     */
    shootsLeftWing0?: number;
    /**
     * shootsLeftWing1
     */
    shootsLeftWing1?: number;
    /**
     * shootsLeftWing2
     */
    shootsLeftWing2?: number;
    /**
     * shootsLeftWing3
     */
    shootsLeftWing3?: number;
    /**
     * shootsLeftWing4
     */
    shootsLeftWing4?: number;
    /**
     * shootsLeftWing5
     */
    shootsLeftWing5?: number;
    /**
     * shootsLeftWing6
     */
    shootsLeftWing6?: number;
    /**
     * shootsLeftWing7
     */
    shootsLeftWing7?: number;
    /**
     * shootsLeftWing8
     */
    shootsLeftWing8?: number;
    /**
     * shootsLeftWing9
     */
    shootsLeftWing9?: number;
    /**
     * shootsLeftWing10
     */
    shootsLeftWing10?: number;
    /**
     * shootsLeftWing11
     */
    shootsLeftWing11?: number;
    /**
     * shootsLeft6m0
     */
    shootsLeft6m0?: number;
    /**
     * shootsLeft6m1
     */
    shootsLeft6m1?: number;
    /**
     * shootsLeft6m2
     */
    shootsLeft6m2?: number;
    /**
     * shootsLeft6m3
     */
    shootsLeft6m3?: number;
    /**
     * shootsLeft6m4
     */
    shootsLeft6m4?: number;
    /**
     * shootsLeft6m5
     */
    shootsLeft6m5?: number;
    /**
     * shootsLeft6m6
     */
    shootsLeft6m6?: number;
    /**
     * shootsLeft6m7
     */
    shootsLeft6m7?: number;
    /**
     * shootsLeft6m8
     */
    shootsLeft6m8?: number;
    /**
     * shootsLeft6m9
     */
    shootsLeft6m9?: number;
    /**
     * shootsLeft6m10
     */
    shootsLeft6m10?: number;
    /**
     * shootsLeft6m11
     */
    shootsLeft6m11?: number;
    /**
     * shootsCenter6m0
     */
    shootsCenter6m0?: number;
    /**
     * shootsCenter6m1
     */
    shootsCenter6m1?: number;
    /**
     * shootsCenter6m2
     */
    shootsCenter6m2?: number;
    /**
     * shootsCenter6m3
     */
    shootsCenter6m3?: number;
    /**
     * shootsCenter6m4
     */
    shootsCenter6m4?: number;
    /**
     * shootsCenter6m5
     */
    shootsCenter6m5?: number;
    /**
     * shootsCenter6m6
     */
    shootsCenter6m6?: number;
    /**
     * shootsCenter6m7
     */
    shootsCenter6m7?: number;
    /**
     * shootsCenter6m8
     */
    shootsCenter6m8?: number;
    /**
     * shootsCenter6m9
     */
    shootsCenter6m9?: number;
    /**
     * shootsCenter6m10
     */
    shootsCenter6m10?: number;
    /**
     * shootsCenter6m11
     */
    shootsCenter6m11?: number;
    /**
     * shootsRight6m0
     */
    shootsRight6m0?: number;
    /**
     * shootsRight6m1
     */
    shootsRight6m1?: number;
    /**
     * shootsRight6m2
     */
    shootsRight6m2?: number;
    /**
     * shootsRight6m3
     */
    shootsRight6m3?: number;
    /**
     * shootsRight6m4
     */
    shootsRight6m4?: number;
    /**
     * shootsRight6m5
     */
    shootsRight6m5?: number;
    /**
     * shootsRight6m6
     */
    shootsRight6m6?: number;
    /**
     * shootsRight6m7
     */
    shootsRight6m7?: number;
    /**
     * shootsRight6m8
     */
    shootsRight6m8?: number;
    /**
     * shootsRight6m9
     */
    shootsRight6m9?: number;
    /**
     * shootsRight6m10
     */
    shootsRight6m10?: number;
    /**
     * shootsRight6m11
     */
    shootsRight6m11?: number;
    /**
     * shootsRightWing0
     */
    shootsRightWing0?: number;
    /**
     * shootsRightWing1
     */
    shootsRightWing1?: number;
    /**
     * shootsRightWing2
     */
    shootsRightWing2?: number;
    /**
     * shootsRightWing3
     */
    shootsRightWing3?: number;
    /**
     * shootsRightWing4
     */
    shootsRightWing4?: number;
    /**
     * shootsRightWing5
     */
    shootsRightWing5?: number;
    /**
     * shootsRightWing6
     */
    shootsRightWing6?: number;
    /**
     * shootsRightWing7
     */
    shootsRightWing7?: number;
    /**
     * shootsRightWing8
     */
    shootsRightWing8?: number;
    /**
     * shootsRightWing9
     */
    shootsRightWing9?: number;
    /**
     * shootsRightWing10
     */
    shootsRightWing10?: number;
    /**
     * shootsRightWing11
     */
    shootsRightWing11?: number;
    /**
     * shootsLeft9m0
     */
    shootsLeft9m0?: number;
    /**
     * shootsLeft9m1
     */
    shootsLeft9m1?: number;
    /**
     * shootsLeft9m2
     */
    shootsLeft9m2?: number;
    /**
     * shootsLeft9m3
     */
    shootsLeft9m3?: number;
    /**
     * shootsLeft9m4
     */
    shootsLeft9m4?: number;
    /**
     * shootsLeft9m5
     */
    shootsLeft9m5?: number;
    /**
     * shootsLeft9m6
     */
    shootsLeft9m6?: number;
    /**
     * shootsLeft9m7
     */
    shootsLeft9m7?: number;
    /**
     * shootsLeft9m8
     */
    shootsLeft9m8?: number;
    /**
     * shootsLeft9m9
     */
    shootsLeft9m9?: number;
    /**
     * shootsLeft9m10
     */
    shootsLeft9m10?: number;
    /**
     * shootsLeft9m11
     */
    shootsLeft9m11?: number;
    /**
     * shootsCenter9m0
     */
    shootsCenter9m0?: number;
    /**
     * shootsCenter9m1
     */
    shootsCenter9m1?: number;
    /**
     * shootsCenter9m2
     */
    shootsCenter9m2?: number;
    /**
     * shootsCenter9m3
     */
    shootsCenter9m3?: number;
    /**
     * shootsCenter9m4
     */
    shootsCenter9m4?: number;
    /**
     * shootsCenter9m5
     */
    shootsCenter9m5?: number;
    /**
     * shootsCenter9m6
     */
    shootsCenter9m6?: number;
    /**
     * shootsCenter9m7
     */
    shootsCenter9m7?: number;
    /**
     * shootsCenter9m8
     */
    shootsCenter9m8?: number;
    /**
     * shootsCenter9m9
     */
    shootsCenter9m9?: number;
    /**
     * shootsCenter9m10
     */
    shootsCenter9m10?: number;
    /**
     * shootsCenter9m11
     */
    shootsCenter9m11?: number;
    /**
     * shootsRight9m0
     */
    shootsRight9m0?: number;
    /**
     * shootsRight9m1
     */
    shootsRight9m1?: number;
    /**
     * shootsRight9m2
     */
    shootsRight9m2?: number;
    /**
     * shootsRight9m3
     */
    shootsRight9m3?: number;
    /**
     * shootsRight9m4
     */
    shootsRight9m4?: number;
    /**
     * shootsRight9m5
     */
    shootsRight9m5?: number;
    /**
     * shootsRight9m6
     */
    shootsRight9m6?: number;
    /**
     * shootsRight9m7
     */
    shootsRight9m7?: number;
    /**
     * shootsRight9m8
     */
    shootsRight9m8?: number;
    /**
     * shootsRight9m9
     */
    shootsRight9m9?: number;
    /**
     * shootsRight9m10
     */
    shootsRight9m10?: number;
    /**
     * shootsRight9m11
     */
    shootsRight9m11?: number;
    /**
     * shoots7Meters0
     */
    shoots7Meters0?: number;
    /**
     * shoots7Meters1
     */
    shoots7Meters1?: number;
    /**
     * shoots7Meters2
     */
    shoots7Meters2?: number;
    /**
     * shoots7Meters3
     */
    shoots7Meters3?: number;
    /**
     * shoots7Meters4
     */
    shoots7Meters4?: number;
    /**
     * shoots7Meters5
     */
    shoots7Meters5?: number;
    /**
     * shoots7Meters6
     */
    shoots7Meters6?: number;
    /**
     * shoots7Meters7
     */
    shoots7Meters7?: number;
    /**
     * shoots7Meters8
     */
    shoots7Meters8?: number;
    /**
     * shoots7Meters9
     */
    shoots7Meters9?: number;
    /**
     * shoots7Meters10
     */
    shoots7Meters10?: number;
    /**
     * shoots7Meters11
     */
    shoots7Meters11?: number;
    /**
     * shootsOppositeField0
     */
    shootsOppositeField0?: number;
    /**
     * shootsOppositeField1
     */
    shootsOppositeField1?: number;
    /**
     * shootsOppositeField2
     */
    shootsOppositeField2?: number;
    /**
     * shootsOppositeField3
     */
    shootsOppositeField3?: number;
    /**
     * shootsOppositeField4
     */
    shootsOppositeField4?: number;
    /**
     * shootsOppositeField5
     */
    shootsOppositeField5?: number;
    /**
     * shootsOppositeField6
     */
    shootsOppositeField6?: number;
    /**
     * shootsOppositeField7
     */
    shootsOppositeField7?: number;
    /**
     * shootsOppositeField8
     */
    shootsOppositeField8?: number;
    /**
     * shootsOppositeField9
     */
    shootsOppositeField9?: number;
    /**
     * shootsOppositeField10
     */
    shootsOppositeField10?: number;
    /**
     * shootsOppositeField11
     */
    shootsOppositeField11?: number;
    /**
     * totalShots
     */
    totalShots: number;
    /**
     * possessionsOffensePositional
     */
    possessionsOffensePositional: number;
    /**
     * possessionsCounterGoal
     */
    possessionsCounterGoal: number;
    /**
     * possessionsFastBreak
     */
    possessionsFastBreak: number;
    /**
     * goalsSuperiority
     */
    goalsSuperiority: number;
    /**
     * goalsEquality
     */
    goalsEquality: number;
    /**
     * possessionsSuperiority
     */
    possessionsSuperiority: number;
    /**
     * possessionsEquality
     */
    possessionsEquality: number;
    /**
     * shots7Meters
     */
    shots7Meters: number;
    /**
     * gameDate
     */
    gameDate?: string;
    /**
     * assist
     */
    assist: number;
    /**
     * gkPostOut
     */
    gkPostOut: number;
    /**
     * gkReceivedGoals7Meters
     */
    gkReceivedGoals7Meters: number;
    /**
     * gkSaves7Meters
     */
    gkSaves7Meters: number;
    /**
     * defensePossOffensePositional
     */
    defensePossOffensePositional: number;
    /**
     * defensePossFastBreak
     */
    defensePossFastBreak: number;
    /**
     * defensePossCounterGoal
     */
    defensePossCounterGoal: number;
    /**
     * defenseReceivedGoalsOffensePositional
     */
    defenseReceivedGoalsOffensePositional: number;
    /**
     * defenseReceivedGoalsFastBreak
     */
    defenseReceivedGoalsFastBreak: number;
    /**
     * defenseReceivedGoalsCounterGoal
     */
    defenseReceivedGoalsCounterGoal: number;
    /**
     * playerScore
     */
    playerScore: number;
    /**
     * lostBallsEquality
     */
    lostBallsEquality: number;
    /**
     * lostBallsSuperiority
     */
    lostBallsSuperiority: number;
    /**
     * postOutOffensePositional
     */
    postOutOffensePositional: number;
    /**
     * postOutFastBreak
     */
    postOutFastBreak: number;
    /**
     * postOutCounterGoal
     */
    postOutCounterGoal: number;
    /**
     * postOutEquality
     */
    postOutEquality: number;
    /**
     * postOutSuperiority
     */
    postOutSuperiority: number;
    /**
     * failedShotsEquality
     */
    failedShotsEquality: number;
    /**
     * failedShotsSuperiority
     */
    failedShotsSuperiority: number;
    /**
     * eventsFastBreak
     */
    eventsFastBreak: number;
    /**
     * eventsCounterGoal
     */
    eventsCounterGoal: number;
    /**
     * eventsOffensePositional
     */
    eventsOffensePositional: number;
    /**
     * eventsSuperiority7vs6
     */
    eventsSuperiority7vs6: number;
    /**
     * eventsSuperiority6vs5
     */
    eventsSuperiority6vs5: number;
    /**
     * eventsSuperiorityOthers
     */
    eventsSuperiorityOthers: number;
    /**
     * eventsEquality
     */
    eventsEquality: number;
    /**
     * eventsInferiority
     */
    eventsInferiority: number;
    /**
     * eventsDefense
     */
    eventsDefense: number;
    /**
     * eventsOffense
     */
    eventsOffense: number;
    /**
     * playedTimeOffense
     */
    playedTimeOffense: number;
    /**
     * playedTimeDefense
     */
    playedTimeDefense: number;
    /**
     * playedTimeOffenseInferiority
     */
    playedTimeOffenseInferiority: number;
    /**
     * playedTimeDefenseInferiority
     */
    playedTimeDefenseInferiority: number;
    /**
     * playedTimeOffenseSuperiority
     */
    playedTimeOffenseSuperiority: number;
    /**
     * playedTimeDefenseSuperiority
     */
    playedTimeDefenseSuperiority: number;
    /**
     * playedTimeOffenseEquality
     */
    playedTimeOffenseEquality: number;
    /**
     * playedTimeDefenseEquality
     */
    playedTimeDefenseEquality: number;
    /**
     * suspTwoMin
     */
    suspTwoMin: number;
    /**
     * yellowCard
     */
    yellowCard: number;
    /**
     * blueCard
     */
    blueCard: number;
    /**
     * redCard
     */
    redCard: number;
}

