import { ApplicationRef, Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { AccountEquipoService } from './account-equipo/account-equipo.service';
import { AccountService } from './account/account.service';
import { ActionService } from './actions/action.service';
import { BasicDataService } from './country/basic-data.service';
import { DashboardsService } from './dashboards/dashboards.service';
import { CsvDownloadService } from './download/csv-download.service';
import { GameDataService } from './game-data/game-data.service';
import { GameHandlerService } from './game/game-handler.service';
import { GameStatsHandlerService } from './game/game-stats-handler.service';
import { GameService } from './game/game.service';
import { LoadingService } from './loading/loading.service';
import { GameSeedService } from './statistics/game-seed/game-seed.service';
import { EventFeedService } from './statistics/ingest/event-feed.service';
import { PlayTimeService } from './statistics/play-time/play-time.service';
import { EasyStatsService } from './statistics/playbyplay/consumer/easy-stats/easy-stats.service';
import { GoalConsumerService } from './statistics/playbyplay/consumer/goal/goal-consumer.service';
import { GoalkeeperEfficiencyConsumerService } from './statistics/playbyplay/consumer/goalkeeper-efficiency/goalkeeper-efficiency-consumer.service';
import { LostBallConsumerService } from './statistics/playbyplay/consumer/lost-ball/lost-ball-consumer.service';
import { MiscEventConsumerService } from './statistics/playbyplay/consumer/misc-event/misc-event-consumer.service';
import { OverviewConsumerService } from './statistics/playbyplay/consumer/overview/overview-consumer.service';
import { PlayerEventListConsumerService } from './statistics/playbyplay/consumer/player-events/player-event-list-consumer.service';
import { PossessionsConsumerService } from './statistics/playbyplay/consumer/possessions/possessions-consumer.service';
import { SuspensionConsumerService } from './statistics/playbyplay/consumer/suspension/suspension-consumer.service';
import { TimelineConsumerService } from './statistics/playbyplay/consumer/timeline/timeline-consumer.service';
import { PlayByPlayProducerService } from './statistics/playbyplay/producer/play-by-play-producer.service';
import { TeamOverviewSubConsumerService } from './statistics/playbyplay/sub-consumer/team-overview/team-overview-sub-consumer.service';
import { StorageService } from './storage-service/storage.service';
import { TeamValidationService } from './team/team-validation.service';
import { TeamService } from './team/team.service';
import { HandballTimerService } from './timer/handball-timer.service';
import { UiEventDispatcherService } from './ui-event-dispatcher/ui-event-dispatcher.service';
import { UserService } from './user-service/user.service';
import { VersionServiceProvider } from './version-service';
import { TimerWrapperService } from 'src/app/shared-services/timer/timer-wrapper.service';
import { EventBufferService } from 'src/app/shared-services/ui-event-dispatcher/buffer/event-buffer.service';
import { I18nService } from '../i18n/i18n.service';
import { ConnectionGoals } from '@handballai/stats-calculation';
import { HouseKeepingService } from 'src/app/shared-services/housekeeping/house-keeping.service';
import { GameLoadingService } from 'src/app/shared-services/loading/game-loading.service';
import { GameSystemConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/game-system-consumer.service';
import { VideoTrackerService } from 'src/app/shared-services/statistics/video/video-tracker.service';
import { GameSystemService } from '../main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/game-system/game-system.service';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { ElectronService } from './electron.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ExternalScoutingService } from '../api/hai-api';
import { HttpClient } from '@angular/common/http';
import { RealtimeGameDataService } from './game-data/realtime-game-data.service';
import { Observable } from 'rxjs';
import { WebSocketService } from './game-data/web-socket.service';
import { SeasonService } from './season/season.service';
import { MyTeamFilesService, MyTeamFoldersService, MyTeamPlayersService } from '../api/hai-players-api';

export interface PositionXY {
  x: string;
  y: string;
}
export interface AbsolutePositionXY {
  x: number;
  y: number;
}
export type HaiDeviceOptions = 'ios' | 'android' | 'pwa' | 'mac' | 'windows';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  public stable = false;
  public appIsStable: Observable<boolean>;
  public device: HaiDeviceOptions = 'pwa';
  private _isMobileDevice: boolean;
  private _isMobileMenuOpen = false;

  constructor(
    // Ionic services
    private platform: Platform,
    private appRef: ApplicationRef,
    public readonly sanitizer: DomSanitizer,
    public readonly navCtrl: NavController,
    public readonly modalCtrl: ModalController,
    public readonly loadingCtrl: LoadingController,
    public readonly popoverCtrl: PopoverController,
    public readonly toastCtrl: ToastController,
    public readonly http: HttpClient,
    // App services
    public readonly trans: TranslateService,
    public readonly electron: ElectronService,
    public readonly version: VersionServiceProvider,
    public readonly accountService: AccountService,
    public readonly accountEquipoService: AccountEquipoService,
    public readonly seasonService: SeasonService,
    public readonly actionService: ActionService,
    public readonly basicDataService: BasicDataService,
    public readonly csvDownloadService: CsvDownloadService,
    public readonly gameHandlerService: GameHandlerService,
    public readonly gameStatsHandlerService: GameStatsHandlerService,
    public readonly gameService: GameService,
    public readonly gameDataService: GameDataService,
    public readonly websocketService: WebSocketService,
    public readonly realtimeGameDataService: RealtimeGameDataService,
    public readonly loadingService: LoadingService,
    public readonly gameLoadingService: GameLoadingService,
    public readonly gameSeedService: GameSeedService,
    public readonly eventFeedService: EventFeedService,
    public readonly playTimeService: PlayTimeService,
    public readonly easyStatsService: EasyStatsService,
    public readonly goalConsumerService: GoalConsumerService,
    public readonly goalkeeperEfficiencyConsumerService: GoalkeeperEfficiencyConsumerService,
    public readonly lostBallConsumerService: LostBallConsumerService,
    public readonly miscEventConsumerService: MiscEventConsumerService,
    public readonly overviewConsumerService: OverviewConsumerService,
    public readonly playerEventListConsumerService: PlayerEventListConsumerService,
    public readonly possessionsConsumerService: PossessionsConsumerService,
    public readonly suspensionConsumerService: SuspensionConsumerService,
    public readonly timelineConsumerService: TimelineConsumerService,
    public readonly playByPlayProducerService: PlayByPlayProducerService,
    public readonly teamOverviewSubConsumerService: TeamOverviewSubConsumerService,
    public readonly storageService: StorageService,
    public readonly teamValidationService: TeamValidationService,
    public readonly teamService: TeamService,
    public readonly handballTimerService: HandballTimerService,
    public readonly uiEventDispatcherService: UiEventDispatcherService,
    public readonly userService: UserService,
    public readonly dashboardsService: DashboardsService,
    public readonly timerWrapperService: TimerWrapperService,
    public readonly eventBufferService: EventBufferService,
    public readonly i18nService: I18nService,
    public readonly houseKeepingService: HouseKeepingService,
    public readonly gameSystemConsumerService: GameSystemConsumerService,
    public readonly videoTrackerService: VideoTrackerService,
    public readonly gameSystemService: GameSystemService,
    public readonly externalScoutingService: ExternalScoutingService,
    public readonly myTeamFilesService: MyTeamFilesService,
    public readonly myTeamFoldersService: MyTeamFoldersService,
    public readonly myTeamPlayersService: MyTeamPlayersService,
    //public readonly playersService: PlayersService
    //  public readonly cdr: ChangeDetectorRef
  ) {
    this.appIsStable = this.appRef.isStable.pipe(
      first((isStable) => {
        if (isStable === true) {
          this.stable = true;
          return true;
        } else {
          return false;
        }
      })
    );
    let haiDevice: HaiDeviceOptions = this.platform.platforms().includes('ios')
      ? 'ios'
      : this.platform.platforms().includes('android')
      ? 'android'
      : 'pwa';
    if (this.platform.is('mobileweb')) haiDevice = 'pwa';
    this.device = haiDevice;
    if (this.device == 'pwa' && this.electron.isElectron) {
      if (this.electron.platform == 'darwin') this.device = 'mac';
      if (this.electron.platform == 'win32') this.device = 'windows';
    }
    version.init(this);
    easyStatsService.initCore(this);
    accountService.initCore(this);
    accountEquipoService.initCore(this);
    actionService.initCore(this);
    basicDataService.initCore(this);
    csvDownloadService.initCore(this);
    gameHandlerService.initCore(this);
    gameStatsHandlerService.initCore(this);
    gameService.initCore(this);
    gameDataService.initCore(this);
    websocketService.initCore(this);
    realtimeGameDataService.initCore(this);
    loadingService.initCore(this);
    gameLoadingService.initCore(this);
    gameSeedService.initCore(this);
    eventFeedService.initCore(this);
    playTimeService.initCore(this);
    goalConsumerService.initCore(this);
    goalkeeperEfficiencyConsumerService.initCore(this);
    lostBallConsumerService.initCore(this);
    miscEventConsumerService.initCore(this);
    overviewConsumerService.initCore(this);
    playerEventListConsumerService.initCore(this);
    possessionsConsumerService.initCore(this);
    suspensionConsumerService.initCore(this);
    timelineConsumerService.initCore(this);
    playByPlayProducerService.initCore(this);
    teamOverviewSubConsumerService.initCore(this);
    gameSystemConsumerService.initCore(this);
    storageService.initCore(this);
    teamValidationService.initCore(this);
    teamService.initCore(this);
    handballTimerService.initCore(this);
    uiEventDispatcherService.initCore(this);
    userService.initCore(this);
    seasonService.initCore(this);
    dashboardsService.initCore(this);
    timerWrapperService.initCore(this);
    eventBufferService.initCore(this);
    houseKeepingService.initCore(this);
    videoTrackerService.initCore(this);
  }

  public get isMobileDevice() {
    return this.platform.width() < 768;
  }

  public get isMobileMenuOpen() {
    return this._isMobileMenuOpen;
  }

  public navigateToUserAllowedPlace() {
    if (this.userService.checkForGrant('manage_teams', true, false)) {
      this.navCtrl.navigateRoot(
        '/account/' + this.accountEquipoService.selectedAccountEquipo$.getValue().id + '/team/select'
      );
    } else if (
      this.userService.checkForBoolPermission('pastGames', true, false) &&
      this.userService.checkForGrant('view_game', true, false)
    ) {
      this.navCtrl.navigateRoot(
        '/account/' + this.accountEquipoService.selectedAccountEquipo$.getValue().id + '/game/search'
      );
    } else if (this.userService.checkForGrant('create_game', true, false)) {
      this.navCtrl.navigateRoot(
        '/account/' + this.accountEquipoService.selectedAccountEquipo$.getValue().id + '/game/start'
      );
    } else if (
      this.userService.checkForBoolPermission('scheduleGames', true, false) &&
      this.userService.checkForGrant('track_game', true, false)
    ) {
      this.navCtrl.navigateRoot(
        '/account/' + this.accountEquipoService.selectedAccountEquipo$.getValue().id + '/game/track'
      );
    } else if (
      this.userService.checkForBoolPermission('accessScouting', true, false) &&
      this.userService.checkForGrant('scouting', true, false)
    ) {
      this.navCtrl.navigateRoot(
        '/account/' + this.accountEquipoService.selectedAccountEquipo$.getValue().id + '/scouting'
      );
    } else if (
      this.userService.checkForBoolPermission('dashboard', true, false) &&
      this.userService.checkForGrant('dashboard', true, false)
    ) {
      this.navCtrl.navigateRoot(
        '/account/' + this.accountEquipoService.selectedAccountEquipo$.getValue().id + '/my-dashboards'
      );
    } else if (this.accountEquipoService.selectedAccountEquipo$.getValue()) {
      this.navCtrl.navigateRoot(
        '/user/profile'
      );
    } else {
      this.navCtrl.navigateRoot('/auth/login');
    }
  }

  public getDateFromTimestamp(date: string) {
    let t = date.split(/[- :]/);
    return new Date(
      Date.UTC(parseInt(t[0]), parseInt(t[1]) - 1, parseInt(t[2]), parseInt(t[3]), parseInt(t[4]), parseInt(t[5]))
    );
  }

  public getShortTooltip(short: string) {
    return (
      {
        G: 'Goals',
        PLPOS: 'Played Possessions',
        TM: 'Total Minutes',
        TG: 'Total Goals',
        RG: 'Received Goals',
        FS: 'Failed Shot',
        S: 'Saves',
        PO: 'Post Outs',
        LB: 'Lost Balls',
        'LB%': 'Lost Balls %',
        'EFF%': 'Efficiency %',
        'SHOTEF%': 'Shooting Eff %',
        POSS: 'Possessions',
        POSE: 'Possession Events',
        'EQEF%': 'Equality Eff %',
        'SUPEF%': 'Superiority Eff %',
        'INFEF%': 'Inferiority Eff %',
        POSSNF: 'Poss without Fouls',
        POSSWF: 'Poss with Fouls',
        'EQ%5+1v6': 'Equality Eff 5+1vs6 %',
        'EQ%6v5+1': 'Equality Eff 6vs5+1 %',
        'SUP%6v5': 'Superiority Eff 6vs5 %',
        'SUP%7v6': 'Superiority Eff 7vs6 %',
        'SUP%Other': 'Superiority Eff Other %',
        'INF%5vs6': 'Inferiority Eff 5vs6 %',
        'INF%6vs7': 'Inferiority Eff 6vs7 %',
        'INF%Other': 'Inferiority Eff Other %',
      }[short] ?? null
    );
  }

  // SVG Field graphs functions

  public readonly positionMap = {
    // Player possitions
    gk: { x: '51%', y: '92%' },
    lw: { x: '8%', y: '11%' },
    rw: { x: '93%', y: '11%' },
    lb: { x: '15%', y: '57%' },
    rb: { x: '85%', y: '57%' },
    cb: { x: '49%', y: '72%' },
    lp: { x: '50%', y: '35%' },

    // Shooting locations
    LeftWing: { x: '8%', y: '11%' },
    RightWing: { x: '93%', y: '11%' },
    Center6m: { x: '50%', y: '35%' },
    Left6m: { x: '20%', y: '27%' },
    Right6m: { x: '80%', y: '27%' },
    Center9m: { x: '50%', y: '60%' },
    Left9m: { x: '15%', y: '57%' },
    Right9m: { x: '85%', y: '57%' },
    '7Meters': { x: '25%', y: '82%' },
    OppositeField: { x: '75%', y: '82%' },

    '7MetersLabel': { x: '25%', y: '87%' },
    OppositeFieldLabel: { x: '75%', y: '87%' },
  };
  public getSvgArrowBySizeAndPositions(
    size: number = 1,
    initPos: PositionXY,
    finishPos: PositionXY,
    canvasWidth: number,
    canvasHeight: number
  ) {
    const startPos: AbsolutePositionXY = {
      x: initPos.x.includes('%') ? (+initPos.x.replace('%', '') / 100) * canvasWidth : +initPos.x,
      y: initPos.y.includes('%') ? (+initPos.y.replace('%', '') / 100) * canvasHeight : +initPos.y,
    };
    const endPos: AbsolutePositionXY = {
      x: finishPos.x.includes('%') ? (+finishPos.x.replace('%', '') / 100) * canvasWidth : +finishPos.x,
      y: finishPos.y.includes('%') ? (+finishPos.y.replace('%', '') / 100) * canvasHeight : +finishPos.y,
    };
    const midPos: AbsolutePositionXY = {
      x: (startPos.x + endPos.x) / 2,
      y: (startPos.y + endPos.y) / 2,
    };
    const posDistance = Math.sqrt(Math.pow(endPos.x - startPos.x, 2) + Math.pow(endPos.y - startPos.y, 2));
    const curve = endPos.y - endPos.x > startPos.y - startPos.x ? -15 : 15;

    return `\n <!-- D:${posDistance}  -->
        <g xmlns="http://www.w3.org/2000/svg">
          <path
            d="M ${startPos.x} ${startPos.y} S ${startPos.x} ${startPos.y}, ${midPos.x} ${midPos.y + curve} S ${
      endPos.x
    } ${endPos.y + curve}, ${endPos.x} ${endPos.y}"
            fill="none"
            stroke="#666666"
            marker-mid="url(#markerArrow)"
            stroke-width="${size}"
            stroke-miterlimit="10"
          />
        </g>`;

    // <path d="M 4 128 Q 14 18 104.64 9.76" fill="none" stroke="rgba(0, 0, 0, 1)" stroke-width="${size}" stroke-miterlimit="10"/>
    // <path d="M 115.09 8.81 L 101.78 17.05 L 104.64 9.76 L 100.52 3.11 Z" fill="rgba(0, 0, 0, 1)" stroke="rgba(0, 0, 0, 1)" stroke-width="${size}" stroke-miterlimit="10"/>
  }
  public getShootingFieldImg(
    data: ConnectionGoals,
    circleColor = '#2aacd3',
    labelColor = 'black',
    test: 'a' | 'b' = null
  ) {
    // const circleColor = this.homeTeamBgColor;
    // const pathRealWidth = 775;
    // const pathRealHeight = 755;
    const rectWidth = 775;
    const rectHeight = 755;

    const maxGoalWeight = Math.max(...data.goals.map((g) => g.weight));

    const testKeys = !test
      ? []
      : test == 'a'
      ? ['gk', 'lw', 'rw', 'lb', 'rb', 'cb', 'lp']
      : [
          'LeftWing',
          'RightWing',
          'Center6m',
          'Left6m',
          'Right6m',
          'Center9m',
          'Left9m',
          'Right9m',
          '7Meters',
          'OppositeField',
          '7MetersLabel',
          'OppositeFieldLabel',
        ];

    var str: string = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 ${rectWidth} ${rectHeight}">
    <defs>
      <marker xmlns="http://www.w3.org/2000/svg" id="markerArrow" markerWidth="13" markerHeight="13" refX="2" refY="6" orient="auto">
        <path d="M2,4 L2,8 L8,6 L2,4" style="fill: #666666;"/>
      </marker>
      <clipPath id="clip-Web_1920_1">
        <rect width="775" height="755"/>
      </clipPath>
    </defs>
    <g id="Web_1920_1" clip-path="url(#clip-Web_1920_1)">
      <rect width="775" height="755" fill="#fff"/>
      <g id="Group_1" data-name="Group 1" transform="translate(-44 -31.381)">
        <path id="field" d="M0,776.616H757.619V0H0Z" transform="translate(819.552 31.381) rotate(90)" fill="#2aacd3" stroke="#fff" stroke-width="2"/>
        <path id="Path_2" data-name="Path 2" d="M218.977,585.168S98.064,590.144,24.02,463.194c0,0-26.363-47.285-23.851-85.865L0,218.027S1.425,148.336,31.545,108.5c0,0,48.947-69.691,110.471-92.087,0,0,43.509-18.676,76.98-16.184Z" transform="translate(141.316 254.768) rotate(-90)" fill="#427dc1" stroke="#fff" stroke-width="1"/>
        <path id="Path_3" data-name="Path 3" d="M223.47,587.01S100.083,592,24.5,464.661c0,0-26.9-47.446-24.332-86.148L0,218.708s1.447-69.872,32.19-109.867c0,0,49.957-69.87,112.718-92.38,0,0,44.377-18.733,78.557-16.231Z" transform="translate(139.474 254.851) rotate(-90)" fill="#427dc1" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
        <path id="Path_5" data-name="Path 5" d="M315.283,852.4C268.4,848.956,134.5,825.684,46.1,675.156L36.051,658.947,16.818,618.525,6.637,576s-2.613-20.187-1.957-30.14c0,0-3.857-51.681-4.608-103.873S4.68,325.626,4.68,325.626s8.262-109.462,52.441-167.513c0,0,71.835-101.6,162.09-134.249,0,0,54.593-23.272,101.652-23.864" transform="translate(0 363.555) rotate(-90)" fill="rgba(0,0,0,0)" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" stroke-dasharray="11.95 11.95"/>
        <line id="Line_11" data-name="Line 11" y1="38.935" transform="translate(446.984 198.03) rotate(90)" fill="none" stroke="#fff" stroke-width="1"/>
      </g>
    </g>

    <!-- Calibration dots -->
    <g><circle cx="0%" cy="0%" r="2" fill="red" /></g>
    <g><circle cx="0%" cy="100%" r="2" fill="red" /></g>
    <g><circle cx="100%" cy="0%" r="2" fill="red" /></g>
    <g><circle cx="100%" cy="100%" r="2" fill="red" /></g>

    <!-- Sign -->
    <g><text text-anchor="end" x="88%" y="98%" font-family="'Open Sans', sans-serif" font-size="32">Handball.AI</text></g>`;

    // Print test positions
    for (const key of testKeys) {
      const p = this.positionMap[key];
      if (p) {
        str += `\n<!-- ${key} -->\n<g><circle cx="${p.x}" cy="${p.y}" r="20" fill="${circleColor}" /></g>\n`;
        str += `<g><text text-anchor="middle" x="${p.x}" y="${p.y}" font-family="'Open Sans', sans-serif" font-size="32">${key}</text></g>\n`;
      } else console.log(key);
    }

    // Print arrows
    for (const conn of data.connections) {
      const sp = this.positionMap[conn.startPos];
      const ep = this.positionMap[conn.endPos];
      if (sp && ep) {
        if (conn.weight)
          str += this.getSvgArrowBySizeAndPositions(
            (conn.weight / maxGoalWeight) * 5 * 4 + 5,
            sp,
            ep,
            rectWidth,
            rectHeight
          );
      } else console.error('SVG position invalid for ', conn);
    }

    // Print goals (circles)
    for (const goal of data.goals) {
      const p = this.positionMap[goal.position];
      if (p) {
        if (goal.weight) {
          str += `\n<!-- ${goal.label} W:${goal.weight}/${maxGoalWeight}*15 -->\n<g><circle cx="${p.x}" cy="${
            p.y
          }" r="${(goal.weight / maxGoalWeight) * 15 * 4 + 15}" fill="${circleColor}" /></g>\n`;
        }
        // str += `<g><text text-anchor="middle" x="${p.x}" y="${p.y}" font-family="'Open Sans', sans-serif" font-size="32">${goal.label}</text></g>\n`;
      } else console.error('SVG position invalid for ', goal);
    }

    // Print goals (labels)
    for (const goal of data.goals) {
      const p = this.positionMap[goal.position];
      if (p) {
        // str += `\n<!-- ${goal.label} W:${goal.weight}/${maxGoalWeight}*15 -->\n<g><circle cx="${p.x}" cy="${p.y}" r="${goal.weight/maxGoalWeight*15}" fill="${circleColor}" /></g>\n`;
        str += `<g><text text-anchor="middle" x="${p.x}" y="${
          (+p.y.replace('%', '') / 100) * rectHeight + 3 * 4
        }" font-family="'Open Sans', sans-serif" font-size="32" fill="${labelColor}" >${goal.label}</text></g>\n`;
      } else console.error('SVG position invalid for ', goal);
    }

    // if (showArrow) {
    //   str += `<g><path transform="rotate(0, 8, 5)" fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/></g>`;
    // }
    str += `</svg>`;
    // console.log('[SVG Field 1]', str)
    return str;
  }

  public toggleMobileDevice() {
    this._isMobileMenuOpen = !this._isMobileMenuOpen;
  }
}
