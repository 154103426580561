import { UiBaseCommand, UiCommandExecutionContext } from './ui-base-command';
import {
    BringBackSuspensionLiteEvent,
    CancelSubstitutePopupEvent,
    DoubleEventPopupCanceledEvent, DoubleEventPopupCanceledLiteEvent,
    DoubleEventPopupSavedEvent, DoubleEventPopupSavedLiteEvent, PassiveButtonSelectedEvent,
    PhaseSelectedEvent,
    PlayerSelectEvent, ShowDoubleEventPopupEvent, ShowDoubleEventPopupLiteEvent,
    ShowSubstitutePopupEvent,
    SubstitutePopupSavedEvent,
    SuspensionSelectedEvent, SuspensionSelectedLiteEvent
} from '../events/ui-events';
import {
    eventChainFinished,
    extractTeamFromMarker, handle2min,
    handlePenalty,
    handleSubstitutionOfSuspendedPlayer
} from './command-helper';
import { EventTime, GamePlayerModel, GameTeamModel, PlayerEvent } from '@handballai/stats-calculation';
import { getOpponentTeamMarker } from 'src/app/shared-services/helper/statistics-helper';
import {
    BringBackSuspensionUndoLiteEvent,
    PlayerBackInGameWithTypeUndoEvent,
    PlayerSuspensionUndoEvent, PlayerSuspensionUndoLiteEvent,
    Pos1SuspensionUndoEvent, Pos1SuspensionUndoLiteEvent
} from 'src/app/shared-services/ui-event-dispatcher/buffer/event-buffer.model';
import * as Sentry from '@sentry/browser';
import {
    DoubleEventPopoverContainerComponent
} from 'src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/double-event-popover/double-event-popover-container.component';
import {
    ExchangePlayerForGoalkeeperContainerComponent
} from 'src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/exchange-player-for-goalkeeper-popover/exchange-player-for-goalkeeper-container.component';
import {
    ExchangePlayerPopoverContainerComponent
} from 'src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/exchange-player-popover/exchange-player-popover-container.component';
import {
    LiteDoubleEventPopoverContainerComponent
} from 'src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/lite-double-event-popover/lite-double-event-popover-container.component';
import { GameDataService } from '../../game-data/game-data.service';

const isGameSystemEnabled = (gameDataService: GameDataService): string =>
    gameDataService.gameSystemEnabled$.value && gameDataService.gameSystems$.value.length > 0
        ? ' game-system'
        : '';

export class ShowDoubleEventPopupCommand implements UiBaseCommand<ShowDoubleEventPopupEvent> {
    async execute(
        actualEvent: ShowDoubleEventPopupEvent,
        eventContext: UiCommandExecutionContext
    ): Promise<void> {
        eventContext.logger.debug('ShowDoubleEventPopupCommand.execute: ', actualEvent);

        const teamMarker = eventContext.eventDispatcher.playerSelectEvent.payload.teamMarker;
        const opponentTeam = teamMarker === 'HOME' ?
            extractTeamFromMarker('VISITOR', eventContext.gameService.gameModel)
        : extractTeamFromMarker('HOME', eventContext.gameService.gameModel);

        const popover = await eventContext.popoverController.create({
            component: DoubleEventPopoverContainerComponent,
            cssClass: 'global__popover global__popover--goal global__popover--doubleEvent'+isGameSystemEnabled(eventContext.gameDataService),
            // translucent: true,
            backdropDismiss: false,
            componentProps: {
                fieldPlayers: opponentTeam.currentField,
                opponentTeam: opponentTeam,
                teamMarker: teamMarker === 'HOME' ? 'VISITOR' : 'HOME',
                requirePlayerSelect: actualEvent.payload.requirePlayerSelect,
                sourceEvent: actualEvent.payload.actionType,
                videoSrc: actualEvent.payload.videoSrc,
                videoCurrentTime: actualEvent.payload.videoCurrentTime
            }
        });
        await popover.present();
    }
}

export class CancelDoubleEventPopupCommand implements UiBaseCommand<DoubleEventPopupCanceledEvent> {
    execute(actualEvent: DoubleEventPopupCanceledEvent, eventContext: UiCommandExecutionContext): void {
        eventContext.logger.debug('CancelDoubleEventPopupCommand.execute: ', actualEvent);
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class SavedDoubleEventPopupCommand implements UiBaseCommand<DoubleEventPopupSavedEvent> {
    async execute(actualEvent: DoubleEventPopupSavedEvent, eventContext: UiCommandExecutionContext): Promise<void> {
        eventContext.logger.debug('SavedDoubleEventPopupCommand.execute: ', actualEvent);
        const playerSelectEvent = eventContext.eventDispatcher.playerSelectEvent;
        const phaseSelectedEvent = eventContext.eventDispatcher.phasesSelectEvent;

        // New event handling
        eventContext.eventBufferService.addPlayByPlayEvent(
            playerSelectEvent.payload.teamMarker,
            {
                eventType: actualEvent.payload.sourceAction,
                phase: phaseSelectedEvent?.payload?.phaseSelected ? phaseSelectedEvent.payload.phaseSelected : 'OFFENSE_POSITIONAL',
                eventTime: playerSelectEvent.timestamp
            } as PlayerEvent,
            playerSelectEvent.payload.teamMarker === 'HOME' ?
                eventContext.gameService.gameModel.home.offenseSystem :
                eventContext.gameService.gameModel.visitor.offenseSystem,
            playerSelectEvent.payload.playerModel,
            actualEvent.payload.opponentPlayerSelected,
            undefined,
            actualEvent.payload.executionPosition,
            undefined,
            undefined,
            actualEvent.payload.gameSystem,
        );

        // In case of 7M_PROVOKE_SUSPENSION or 2MIN_PROVOKE a special handling is required
        if (actualEvent.payload.sourceAction === '7M_PROVOKE_SUSPENSION' || actualEvent.payload.sourceAction === '2MIN_PROVOKE') {
            // Create Suspension Events
            const suspensionSelectedEvent = new SuspensionSelectedEvent({ suspensionSelected: '2_MIN' });
            const selectedPlayerEvent = new PlayerSelectEvent(
                {
                playerModel: actualEvent.payload.opponentPlayerSelected,
                teamMarker: getOpponentTeamMarker(playerSelectEvent.payload.teamMarker)
            },
                playerSelectEvent.timestamp
            );
            if (!selectedPlayerEvent.payload.playerModel) {
                Sentry.captureMessage('selectedPlayerEvent.payload where playerModel is null: ' + JSON.stringify(selectedPlayerEvent));
            }
            if (selectedPlayerEvent.payload.playerModel.slotId === 1) {
                await this.handlePos1Substitution(
                    suspensionSelectedEvent,
                    eventContext,
                    selectedPlayerEvent,
                    phaseSelectedEvent
                );
            } else {
                // Handle 2 Minutes
                handle2min(suspensionSelectedEvent, eventContext, selectedPlayerEvent);

                // Add Suspension Event
                eventContext.eventBufferService.addPlayByPlayEvent(
                    getOpponentTeamMarker(playerSelectEvent.payload.teamMarker),
                    {
                        eventType: '2_MIN',
                        phase: phaseSelectedEvent?.payload?.phaseSelected ? phaseSelectedEvent.payload.phaseSelected : 'OFFENSE_POSITIONAL',
                        eventTime: playerSelectEvent.timestamp
                    } as PlayerEvent,
                    playerSelectEvent.payload.teamMarker === 'HOME' ?
                        eventContext.gameService.gameModel.home.offenseSystem :
                        eventContext.gameService.gameModel.visitor.offenseSystem,
                    actualEvent.payload.opponentPlayerSelected,
                    undefined,
                    undefined,
                    actualEvent.payload.executionPosition
                );
                eventContext.eventBufferService.addUndoEvent(new PlayerSuspensionUndoEvent({
                    teamMarker: getOpponentTeamMarker(playerSelectEvent.payload.teamMarker),
                    playerBeforeInGame: actualEvent.payload.opponentPlayerSelected,
                    suspension: '2_MIN'
                }));
                // Add Playtime Event
                eventContext.eventBufferService.addPlayTimeEvent(
                    selectedPlayerEvent.payload.playerModel.id,
                    'PLAY_TIME_END',
                    playerSelectEvent.timestamp
                );
            }
        }

        eventContext.eventBufferService.closeTransaction();
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }

    private async handlePos1Substitution(
        actualEvent: SuspensionSelectedEvent,
        eventContext: UiCommandExecutionContext,
        selectedPlayerEvent: PlayerSelectEvent,
        phaseSelectedEvent: PhaseSelectedEvent,
    ): Promise<void> {

        let field: GamePlayerModel[];
        let team: GameTeamModel;

        eventContext.eventBufferService.addPlayByPlayEvent(
            selectedPlayerEvent.payload.teamMarker,
            {
                eventType: actualEvent.payload.suspensionSelected,
                phase: phaseSelectedEvent?.payload?.phaseSelected ? phaseSelectedEvent.payload.phaseSelected : 'OFFENSE_POSITIONAL',
                eventTime: selectedPlayerEvent.timestamp
            } as PlayerEvent,
            selectedPlayerEvent.payload.teamMarker === 'HOME' ?
                eventContext.gameService.gameModel.home.offenseSystem :
                eventContext.gameService.gameModel.visitor.offenseSystem,
            eventContext.eventDispatcher.playerSelectEvent.payload.playerModel,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            actualEvent.payload.gameSystem
        );

        if (actualEvent.payload.suspensionSelected !== 'YELLOW_CARD') {
            let newPlayer: GamePlayerModel;

            if (selectedPlayerEvent.payload.teamMarker === 'HOME') {
                field = eventContext.gameService.gameModel.home.currentField;
                team = eventContext.gameService.gameModel.home;
                newPlayer = eventContext.gameService
                    .putPos1PlayerOnBenchHome(selectedPlayerEvent.payload.playerModel, actualEvent.payload.suspensionSelected);
            } else {
                field = eventContext.gameService.gameModel.visitor.currentField;
                team = eventContext.gameService.gameModel.visitor;
                newPlayer = eventContext.gameService
                    .putPos1PlayerOnBenchVisitor(selectedPlayerEvent.payload.playerModel, actualEvent.payload.suspensionSelected);
            }
            eventContext.eventBufferService.addUndoEvent(new Pos1SuspensionUndoEvent({
                teamMarker: selectedPlayerEvent.payload.teamMarker,
                playerBeforeInGame: selectedPlayerEvent.payload.playerModel,
                suspension: '2_MIN'
            }));

            eventContext.eventBufferService.addPlayTimeEvent(
                selectedPlayerEvent.payload.playerModel.id,
                'PLAY_TIME_END',
                selectedPlayerEvent.timestamp
            );
            eventContext.eventBufferService.addPlayTimeEvent(
                newPlayer.id,
                'PLAY_TIME_START',
                selectedPlayerEvent.timestamp
            );
            const popover = await eventContext.popoverController.create({
                component: ExchangePlayerForGoalkeeperContainerComponent,
                cssClass: 'global__popover global__popover--doubleEvent',
                // translucent: true,
                backdropDismiss: false,
                componentProps: {
                    team: team,
                    eventTime: selectedPlayerEvent.timestamp,
                    teamMarker: selectedPlayerEvent.payload.teamMarker,
                    fieldPlayers: field,
                    selectedPlayer: selectedPlayerEvent.payload.playerModel
                }
            });
            await popover.present();
        }
    }

}

export class SuspensionSelectedEventCommand implements UiBaseCommand<SuspensionSelectedEvent> {

    async execute(actualEvent: SuspensionSelectedEvent, eventContext: UiCommandExecutionContext): Promise<void> {
        eventContext.logger.debug('SuspensionSelectedEventCommand.execute: ', actualEvent);
        const playerSelectEvent = eventContext.eventDispatcher.playerSelectEvent;
        const phaseSelectedEvent = eventContext.eventDispatcher.phasesSelectEvent;

        if (!playerSelectEvent.payload.playerModel) {
            Sentry.captureMessage('playerSelectEvent.payload.playerModel null: ' + JSON.stringify(playerSelectEvent));
        }
        // Decide if we have a special treatment for Goalkeeper and Additional player
        if (playerSelectEvent.payload.playerModel.slotId === 1) {
            await this.handlePos1Substitution(
                actualEvent,
                eventContext,
                playerSelectEvent,
                phaseSelectedEvent
            );

        } else {

            handlePenalty(actualEvent, eventContext, playerSelectEvent);

            // New Event handling
            eventContext.eventBufferService.addPlayByPlayEvent(
                playerSelectEvent.payload.teamMarker,
                {
                    eventType: actualEvent.payload.suspensionSelected,
                    phase: phaseSelectedEvent?.payload?.phaseSelected ? phaseSelectedEvent.payload.phaseSelected : 'OFFENSE_POSITIONAL',
                    eventTime: playerSelectEvent.timestamp,
                } as PlayerEvent,
                playerSelectEvent.payload.teamMarker === 'HOME' ?
                    eventContext.gameService.gameModel.home.offenseSystem :
                    eventContext.gameService.gameModel.visitor.offenseSystem,
                eventContext.eventDispatcher.playerSelectEvent.payload.playerModel,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                actualEvent.payload.gameSystem
            );
            if (actualEvent.payload.suspensionSelected !== 'YELLOW_CARD') {
                const playerSuspensionUndoEvent = new PlayerSuspensionUndoEvent({
                    teamMarker: playerSelectEvent.payload.teamMarker,
                    playerBeforeInGame: playerSelectEvent.payload.playerModel,
                    suspension: actualEvent.payload.suspensionSelected
                });
                eventContext.eventBufferService.addUndoEvent(playerSuspensionUndoEvent);
                eventContext.eventBufferService.addPlayTimeEvent(
                    playerSelectEvent.payload.playerModel.id,
                    'PLAY_TIME_END',
                    playerSelectEvent.timestamp
                );
            }

            eventContext.eventBufferService.closeTransaction();
            eventChainFinished(
                eventContext.eventDispatcher,
                eventContext.actionService,
                eventContext.gameService
            );
        }
    }

    private async handlePos1Substitution(
        actualEvent: SuspensionSelectedEvent,
        eventContext: UiCommandExecutionContext,
        selectedPlayerEvent: PlayerSelectEvent,
        phaseSelectedEvent: PhaseSelectedEvent,
    ): Promise<void> {

        let field: GamePlayerModel[];
        let team: GameTeamModel;

        eventContext.eventBufferService.addPlayByPlayEvent(
            selectedPlayerEvent.payload.teamMarker,
            {
                eventType: actualEvent.payload.suspensionSelected,
                phase: phaseSelectedEvent?.payload?.phaseSelected ? phaseSelectedEvent.payload.phaseSelected : 'OFFENSE_POSITIONAL',
                eventTime: selectedPlayerEvent.timestamp
            } as PlayerEvent,
            selectedPlayerEvent.payload.teamMarker === 'HOME' ?
                eventContext.gameService.gameModel.home.offenseSystem :
                eventContext.gameService.gameModel.visitor.offenseSystem,
            eventContext.eventDispatcher.playerSelectEvent.payload.playerModel,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            actualEvent.payload.gameSystem
        );

        if (actualEvent.payload.suspensionSelected !== 'YELLOW_CARD') {
            let newPlayer: GamePlayerModel;

            if (selectedPlayerEvent.payload.teamMarker === 'HOME') {
                field = eventContext.gameService.gameModel.home.currentField;
                team = eventContext.gameService.gameModel.home;
                newPlayer = eventContext.gameService
                    .putPos1PlayerOnBenchHome(selectedPlayerEvent.payload.playerModel, actualEvent.payload.suspensionSelected);
            } else {
                field = eventContext.gameService.gameModel.visitor.currentField;
                team = eventContext.gameService.gameModel.visitor;
                newPlayer = eventContext.gameService
                    .putPos1PlayerOnBenchVisitor(selectedPlayerEvent.payload.playerModel, actualEvent.payload.suspensionSelected);
            }
            eventContext.eventBufferService.addPlayTimeEvent(
                selectedPlayerEvent.payload.playerModel.id,
                'PLAY_TIME_END',
                selectedPlayerEvent.timestamp
            );
            eventContext.eventBufferService.addPlayTimeEvent(
                newPlayer.id,
                'PLAY_TIME_START',
                selectedPlayerEvent.timestamp
            );
            eventContext.eventBufferService.addUndoEvent(new Pos1SuspensionUndoEvent({
                teamMarker: selectedPlayerEvent.payload.teamMarker,
                playerBeforeInGame: selectedPlayerEvent.payload.playerModel,
                suspension: actualEvent.payload.suspensionSelected
            }));
            const popover = await eventContext.popoverController.create({
                component: ExchangePlayerForGoalkeeperContainerComponent,
                cssClass: 'global__popover global__popover--doubleEvent',
                // translucent: true,
                backdropDismiss: false,
                componentProps: {
                    team: team,
                    eventTime: selectedPlayerEvent.timestamp,
                    teamMarker: selectedPlayerEvent.payload.teamMarker,
                    fieldPlayers: field,
                    selectedPlayer: selectedPlayerEvent.payload.playerModel
                }
            });
            await popover.present();
        }
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class ShowSubstitutePopupEventCommand implements UiBaseCommand<ShowSubstitutePopupEvent> {

    async execute(actualEvent: ShowSubstitutePopupEvent, eventContext: UiCommandExecutionContext): Promise<void> {
        eventContext.logger.debug(
            'ShowSubstitutePopupEventCommand.execute: ',
            actualEvent
        );
        await eventContext.eventBufferService.openTransaction();
        let team: GameTeamModel;
        if (actualEvent.payload.teamMarker === 'HOME') {
            eventContext.gameService.substitutePlayerFromBenchHome(
                actualEvent.payload.playerModel,
                actualEvent.payload.suspensionAction
            );
            team = eventContext.gameService.gameModel.home;
        } else {
            eventContext.gameService.substitutePlayerFromBenchVisitor(
                actualEvent.payload.playerModel,
                actualEvent.payload.suspensionAction
            );
            team = eventContext.gameService.gameModel.visitor;
        }

        const popover = await eventContext.popoverController.create({
            component: ExchangePlayerPopoverContainerComponent,
            cssClass: 'global__popover global__popover--doubleEvent',
            // translucent: true,
            backdropDismiss: false,
            componentProps: {
                team: team,
                eventTime: actualEvent.timestamp,
                teamMarker: actualEvent.payload.teamMarker,
                suspensionAction: actualEvent.payload.suspensionAction,
                selectedSuspendedPlayer: actualEvent.payload.playerModel,
                isRegularMode: true
            }
        });
        await popover.present();
    }
}

export class CancelSubstitutePopupEventCommand implements UiBaseCommand<CancelSubstitutePopupEvent> {
    execute(actualEvent: CancelSubstitutePopupEvent, eventContext: UiCommandExecutionContext): void {
        eventContext.logger.debug(
            'SubstitutePopupSavedEventCommand.execute: ',
            actualEvent
        );
        const suspendedPlayer = actualEvent.payload.playerModel;
        const suspensionType = actualEvent.payload.suspensionAction;
        const teamMarker = actualEvent.payload.teamMarker;
        if (teamMarker === 'HOME') {
            eventContext.gameService.cancelPlayerSubstitutionPopupHome(suspendedPlayer, suspensionType);
        } else {
            eventContext.gameService.cancelPlayerSubstitutionPopupVisitor(suspendedPlayer, suspensionType);
        }
        // We have to put the player back on suspension
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class SubstitutePopupSavedEventCommand implements UiBaseCommand<SubstitutePopupSavedEvent> {

    execute(actualEvent: SubstitutePopupSavedEvent, eventContext: UiCommandExecutionContext): void {
        eventContext.logger.debug(
            'SubstitutePopupSavedEventCommand.execute: ',
            actualEvent
        );
        handleSubstitutionOfSuspendedPlayer(actualEvent, eventContext);

        // New Event Handling
        eventContext.eventBufferService.addPlayTimeEvent(
            actualEvent.payload.playerModel.id,
            'PLAY_TIME_START',
            {
                timestamp: actualEvent.timestamp.timestamp,
                halftime: actualEvent.timestamp.halftime,
                secondsSinceStartOfGame: actualEvent.timestamp.secondsSinceStartOfGame,
                secondsSinceHalftime: actualEvent.timestamp.secondsSinceHalftime,
                minutesSinceHalftime: actualEvent.timestamp.minutesSinceHalftime
            } as EventTime,
        );

        eventContext.eventBufferService.addUndoEvent(new PlayerBackInGameWithTypeUndoEvent({
            suspension: actualEvent.payload.suspensionAction,
            playerSelectedToGoInField: actualEvent.payload.playerModel,
            playerBeforeInSuspension: actualEvent.payload.suspendedPlayer,
            teamMarker: actualEvent.payload.teamMarker
        }));
        eventContext.eventBufferService.closeTransaction();
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class PassiveButtonSelectedEventCommand implements UiBaseCommand<PassiveButtonSelectedEvent> {

    execute(actualEvent: PassiveButtonSelectedEvent, eventContext: UiCommandExecutionContext): void {
        eventContext.logger.debug(
            'PassiveButtonSelectedEventCommand.execute: ',
            actualEvent
        );
        const playerSelectEvent = eventContext.eventDispatcher.playerSelectEvent;
        const phaseSelectedEvent = eventContext.eventDispatcher.phasesSelectEvent;

        // New Passive Event Handling
        eventContext.eventBufferService.addPlayByPlayEvent(
            playerSelectEvent.payload.teamMarker,
            {
                eventType: 'PASSIVE',
                phase: phaseSelectedEvent?.payload?.phaseSelected ? phaseSelectedEvent.payload.phaseSelected : 'OFFENSE_POSITIONAL',
                eventTime: playerSelectEvent.timestamp
            } as PlayerEvent,
            playerSelectEvent.payload.teamMarker === 'HOME' ?
                eventContext.gameService.gameModel.home.offenseSystem :
                eventContext.gameService.gameModel.visitor.offenseSystem
        );
        eventContext.eventBufferService.closeTransaction();
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class ShowLiteDoubleEventPopupCommand implements UiBaseCommand<ShowDoubleEventPopupLiteEvent> {
    async execute(
        actualEvent: ShowDoubleEventPopupLiteEvent,
        eventContext: UiCommandExecutionContext
    ): Promise<void> {
        eventContext.logger.debug('ShowLiteDoubleEventPopupCommand.execute: ', actualEvent);

        const teamMarker = eventContext.eventDispatcher.playerSelectEvent.payload.teamMarker;
        const opponentTeam = teamMarker === 'HOME' ?
            extractTeamFromMarker('VISITOR', eventContext.gameService.gameModel)
            : extractTeamFromMarker('HOME', eventContext.gameService.gameModel);

        const popover = await eventContext.popoverController.create({
            component: LiteDoubleEventPopoverContainerComponent,
            cssClass: 'global__popover global__popover--goal global__popover--doubleEvent'+isGameSystemEnabled(eventContext.gameDataService),
            // translucent: true,
            backdropDismiss: false,
            componentProps: {
                fieldPlayers: opponentTeam.currentField,
                opponentTeam: opponentTeam,
                teamMarker: teamMarker === 'HOME' ? 'VISITOR' : 'HOME',
                sourceEvent: actualEvent.payload.actionType,
                requirePlayerSelect: actualEvent.payload.requirePlayerSelect,
                videoSrc: actualEvent.payload.videoSrc,
                videoCurrentTime: actualEvent.payload.videoCurrentTime
            }
        });
        await popover.present();
    }
}

export class CancelLiteDoubleEventPopupCommand implements UiBaseCommand<DoubleEventPopupCanceledLiteEvent> {
    execute(actualEvent: DoubleEventPopupCanceledLiteEvent, eventContext: UiCommandExecutionContext): void {
        eventContext.logger.debug('CancelLiteDoubleEventPopupCommand.execute: ', actualEvent);
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class SavedLiteDoubleEventPopupCommand implements UiBaseCommand<DoubleEventPopupSavedLiteEvent> {
    execute(actualEvent: DoubleEventPopupSavedLiteEvent, eventContext: UiCommandExecutionContext): void {
        eventContext.logger.debug('SavedLiteDoubleEventPopupCommand.execute: ', actualEvent);
        const playerSelectEvent = eventContext.eventDispatcher.playerSelectEvent;
        const phaseSelectedEvent = eventContext.eventDispatcher.phasesSelectEvent;
        let gamePlayerToReplace: GamePlayerModel;


        eventContext.eventBufferService.addPlayByPlayEvent(
            playerSelectEvent.payload.teamMarker,
            {
                eventType: actualEvent.payload.sourceAction,
                phase: phaseSelectedEvent?.payload?.phaseSelected ? phaseSelectedEvent.payload.phaseSelected : 'OFFENSE_POSITIONAL',
                eventTime: playerSelectEvent.timestamp
            } as PlayerEvent,
            playerSelectEvent.payload.teamMarker === 'HOME' ?
                eventContext.gameService.gameModel.home.offenseSystem :
                eventContext.gameService.gameModel.visitor.offenseSystem,
            playerSelectEvent.payload.playerModel,
            actualEvent.payload.opponentPlayerSelected,
            undefined,
            actualEvent.payload.executionPosition,
            undefined,
            undefined,
            actualEvent.payload.gameSystem
        );

        if (actualEvent.payload.sourceAction === '7M_PROVOKE_SUSPENSION' || actualEvent.payload.sourceAction === '2MIN_PROVOKE') {

            if (getOpponentTeamMarker(playerSelectEvent.payload.teamMarker) === 'HOME') {
                if (playerSelectEvent.payload.playerModel.slotId === 1) {
                    gamePlayerToReplace = eventContext.gameService.putPos1PlayerOnBenchHome(
                        actualEvent.payload.opponentPlayerSelected,
                        '2_MIN'
                    );
                } else {

                    eventContext.gameService.putPlayerOnBenchHomeLite(
                        actualEvent.payload.opponentPlayerSelected,
                        '2_MIN'
                    );
                }
            } else {
                if (playerSelectEvent.payload.playerModel.slotId === 1) {
                    gamePlayerToReplace = eventContext.gameService.putPos1PlayerOnBenchVisitor(
                        actualEvent.payload.opponentPlayerSelected,
                        '2_MIN'
                    );
                } else {
                    eventContext.gameService.putPlayerOnBenchVisitorLite(
                        actualEvent.payload.opponentPlayerSelected,
                        '2_MIN'
                    );
                }
            }
            if (playerSelectEvent.payload.playerModel.slotId === 1) {
                eventContext.eventBufferService.addUndoEvent(new Pos1SuspensionUndoLiteEvent({
                    suspension: '2_MIN',
                    playerBeforeInSuspension: actualEvent.payload.opponentPlayerSelected,
                    teamMarker: getOpponentTeamMarker(playerSelectEvent.payload.teamMarker),
                    playerSelectedToGoInField: gamePlayerToReplace
                }));
            } else {
                eventContext.eventBufferService.addUndoEvent(new PlayerSuspensionUndoLiteEvent({
                    suspension: '2_MIN',
                    teamMarker: getOpponentTeamMarker(playerSelectEvent.payload.teamMarker),
                    playerBeforeInGame: actualEvent.payload.opponentPlayerSelected
                }));
            }
            eventContext.eventBufferService.addPlayByPlayEvent(getOpponentTeamMarker(playerSelectEvent.payload.teamMarker),
                {
                    eventType: '2_MIN',
                    phase: phaseSelectedEvent?.payload?.phaseSelected ? phaseSelectedEvent.payload.phaseSelected : 'OFFENSE_POSITIONAL',
                    eventTime: playerSelectEvent.timestamp
                } as PlayerEvent,
                getOpponentTeamMarker(playerSelectEvent.payload.teamMarker) === 'HOME' ?
                    eventContext.gameService.gameModel.home.offenseSystem :
                    eventContext.gameService.gameModel.visitor.offenseSystem,
                actualEvent.payload.opponentPlayerSelected,
                undefined,
                undefined,
                actualEvent.payload.executionPosition
            );

        }
        // New event handling
        eventContext.eventBufferService.closeTransaction();
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );

    }
}

export class LiteSuspensionSelectedEventCommand implements UiBaseCommand<SuspensionSelectedLiteEvent> {
    execute(actualEvent: SuspensionSelectedLiteEvent, eventContext: UiCommandExecutionContext): void {
        eventContext.logger.debug('LiteSuspensionSelectedEventCommand.execute: ', actualEvent);
        const playerSelectEvent = eventContext.eventDispatcher.playerSelectEvent;
        const phaseSelectedEvent = eventContext.eventDispatcher.phasesSelectEvent;
        let gamePlayerToReplace: GamePlayerModel;
        if (actualEvent.payload.suspensionSelected !== 'YELLOW_CARD') {

            if (playerSelectEvent.payload.teamMarker === 'HOME') {
                if (playerSelectEvent.payload.playerModel.slotId === 1) {
                    gamePlayerToReplace = eventContext.gameService.putPos1PlayerOnBenchHome(
                        playerSelectEvent.payload.playerModel,
                        actualEvent.payload.suspensionSelected
                    );
                } else {

                    eventContext.gameService.putPlayerOnBenchHomeLite(
                        playerSelectEvent.payload.playerModel,
                        actualEvent.payload.suspensionSelected
                    );
                }
            } else {
                if (playerSelectEvent.payload.playerModel.slotId === 1) {
                    gamePlayerToReplace = eventContext.gameService.putPos1PlayerOnBenchVisitor(
                        playerSelectEvent.payload.playerModel,
                        actualEvent.payload.suspensionSelected
                    );
                } else {
                    eventContext.gameService.putPlayerOnBenchVisitorLite(
                        playerSelectEvent.payload.playerModel,
                        actualEvent.payload.suspensionSelected
                    );
                }
            }
            if (playerSelectEvent.payload.playerModel.slotId === 1) {
                eventContext.eventBufferService.addUndoEvent(new Pos1SuspensionUndoLiteEvent({
                    suspension: actualEvent.payload.suspensionSelected,
                    playerBeforeInSuspension: playerSelectEvent.payload.playerModel,
                    teamMarker: playerSelectEvent.payload.teamMarker,
                    playerSelectedToGoInField: gamePlayerToReplace
                }));
            } else {
                eventContext.eventBufferService.addUndoEvent(new PlayerSuspensionUndoLiteEvent({
                    suspension: actualEvent.payload.suspensionSelected,
                    teamMarker: playerSelectEvent.payload.teamMarker,
                    playerBeforeInGame: playerSelectEvent.payload.playerModel
                }));
            }
        }
        eventContext.eventBufferService.addPlayByPlayEvent(playerSelectEvent.payload.teamMarker,
            {
                eventType: actualEvent.payload.suspensionSelected,
                phase: phaseSelectedEvent?.payload?.phaseSelected ? phaseSelectedEvent.payload.phaseSelected : 'OFFENSE_POSITIONAL',
                eventTime: playerSelectEvent.timestamp
            } as PlayerEvent,
            playerSelectEvent.payload.teamMarker === 'HOME' ?
                eventContext.gameService.gameModel.home.offenseSystem :
                eventContext.gameService.gameModel.visitor.offenseSystem,
            playerSelectEvent.payload.playerModel
        );
        eventContext.eventBufferService.closeTransaction();
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );

    }
}

export class LiteBringBackSuspensionEventCommand implements UiBaseCommand<BringBackSuspensionLiteEvent> {
    execute(actualEvent: BringBackSuspensionLiteEvent, eventContext: UiCommandExecutionContext): void {
        eventContext.logger.debug(
            'LiteBringBackSuspensionEventCommand.execute: ', actualEvent, eventContext.eventDispatcher.uiEventState$.value
        );
        const playerSelectEvent = eventContext.eventDispatcher.playerSelectEvent;
        if (playerSelectEvent.payload.teamMarker === 'HOME') {
            eventContext.gameService.bringBackPlayerAfterSuspensionHome(playerSelectEvent.payload.playerModel);
        } else {
            eventContext.gameService.bringBackPlayerAfterSuspensionVisitor(playerSelectEvent.payload.playerModel);
        }
        eventContext.eventBufferService.addUndoEvent(new BringBackSuspensionUndoLiteEvent({
            suspension: actualEvent.payload.suspensionSelected,
            teamMarker: playerSelectEvent.payload.teamMarker,
            playerBeforeInSuspension: playerSelectEvent.payload.playerModel,
            playerSelectedToGoInField: playerSelectEvent.payload.playerModel
        }));
        eventContext.eventBufferService.closeTransaction();
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );

    }

}
