import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { ExternalAccountEquiposService, AdminAccountEquipoDto, BasicAccountEquipoDto } from 'src/app/api/hai-api';
import { CoreService } from '../core.service';
import { StorageService } from '../storage-service/storage.service';

export type TManageAccountEquipoGrants = {grants: string[]} & AdminAccountEquipoDto;
export type TManageAccountEquiposGrantMap = { [k: string]: TManageAccountEquipoGrants };

@Injectable({
  providedIn: 'root'
})
export class AccountEquipoService {
  get selectedAccountEquipo$() {
    return this._selectedAccountEquipo$;
  }
  get accountEquipos$() {
    return this._accountEquipos$;
  }
  get accountEquiposMap$() {
    return this._accountEquiposMap$;
  }
  get accountEquipoGrants$() {
    return this._accountEquipoGrants$;
  }
  get lastAccountEquipos$(){
    return this._lastAccountEquipos$;
  }

  get extraTimeEnabled(): boolean {
    return !!this._core.accountEquipoService.selectedAccountEquipo$?.value?.enabledExtraTimeAndPenalties;
  }

  get isProVersion(): boolean {
    return !!this._core.accountEquipoService.selectedAccountEquipo$.value?.account?.permissions?.haiProAccess;
  }

  get isTrackExecutionPositionEnabled(): boolean {
    return this.isProVersion || this._core.accountEquipoService.selectedAccountEquipo$?.value?.extendedCoachTracking;
  }

  constructor(
    private logger: NGXLogger,
    private readonly externalAccountEquipoService: ExternalAccountEquiposService
  ) {}
  private _core: CoreService;

  private _selectedAccountEquipo$ = new BehaviorSubject<TManageAccountEquipoGrants>(null);

  private _accountEquipos$ = new BehaviorSubject<TManageAccountEquipoGrants[]>(null);
  private _accountEquiposMap$ = new BehaviorSubject<TManageAccountEquiposGrantMap>({});

  private _accountEquipoGrants$ = new BehaviorSubject<string[]>([]);

  private _lastAccountEquipos$ = new BehaviorSubject<BasicAccountEquipoDto[]>(null);
  initCore(core: CoreService) {
      this._core = core;
  }

  async fireAccountEquipoChange(id: number= null) {
    if (id && this.accountEquiposMap$.value[id]) {
      this.logger.debug('Account-Equipo permissions', this.accountEquiposMap$.value[id]?.account?.permissions);
      this._selectedAccountEquipo$.next(this.accountEquiposMap$.value[id]);
    } else {
      this._selectedAccountEquipo$.next(null);
    }
  }

  async getAccountEquiposByUserId(uid: string) {
    return new Promise<BasicAccountEquipoDto[]>((ok, ko) => {
      this._core.storageService.getAccessTokenAsObject().then((jwt) => {
        this.externalAccountEquipoService.accountEquipoHandlerControllerGetAccountEquipos(uid)
          .subscribe(ae => {
            this.logger.debug('AccountService.GetAccountEquipos() - account equipos read successfully');
            this._lastAccountEquipos$.next(ae);
            ok(this._lastAccountEquipos$.value);
          },
          error => {
            this.logger.error('AccountService.GetAccountEquipos() - error reading account equipos!', error);
            ko(error);
          });
      });
    });
  }

  getAccountEquipos() {
    return new Promise<TManageAccountEquipoGrants[]>((ok, ko ) => {
      this._core.storageService.getAccessTokenAsObject().then((val) => {
        if (val) {
          const grantsMap = val.accountEquipos.reduce((g, str) => {
            g[str.split(':')[0]] = str.split(':')[1].split(',');
            return g;
          }, {});
          this.externalAccountEquipoService.accountEquipoHandlerControllerGetAccountEquipos(val.sub.toString())
            .subscribe({
              next: ae => {
                this.logger.debug('AccountEquipoService.GetAccountEquipos() - account equipos read successfully', ae);
                if (ae) {
                  this._accountEquipos$.next(
                    ae.map<TManageAccountEquipoGrants>(a => {
                      (<TManageAccountEquipoGrants> a).grants = grantsMap[a.id];
                      return (<TManageAccountEquipoGrants> a);
                    })
                  );
                } else {
                  this._accountEquipos$.next([]);
                }
                this._accountEquiposMap$.next(this.accountEquipos$.value.reduce<TManageAccountEquiposGrantMap>((itm, base) => {
                  itm[base.id] = base;
                  return itm;
                }, {}));
                ok(this.accountEquipos$.value);
              },
              error: error => {
                this.logger.error('AccountEquipoService.GetAccountEquipos() - error reading account equipos!', error);
                ko(error);
              }
            });
        }
      });
    }).catch(err => {
      this.logger.error(err);
    });
  }

  getAccountEquipoGrants(key) {
    // this._core.storageService.getAccessTokenAsObject().then((val) => {
    //   this._accountEquipoGrants$.next(
    //     val.accountEquipos.reduce((aePart, str) => {
    //       aePart[str.split(":")[0]] = str.split(":")[1].split(",");
    //       return aePart;
    //     }, {} as TAccountEquipoGrant)[key]
    //   );
    // });
  }

}
