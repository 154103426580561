/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TeamStatsAggregateDto { 
    /**
     * totalPossessions
     */
    totalPossessions: string;
    /**
     * avgPossessions
     */
    avgPossessions: string;
    /**
     * totalGoals
     */
    totalGoals: string;
    /**
     * avgGoals
     */
    avgGoals: string;
    /**
     * totalSaves
     */
    totalSaves: string;
    /**
     * avgSaves
     */
    avgSaves: string;
    /**
     * totalLostBalls
     */
    totalLostBalls: string;
    /**
     * avgTotalBalls
     */
    avgTotalBalls: string;
    /**
     * totalShotBlock
     */
    totalShotBlock: string;
    /**
     * avgShotBlock
     */
    avgShotBlock: string;
    /**
     * totalShotBlocked
     */
    totalShotBlocked: string;
    /**
     * avgShotBlocked
     */
    avgShotBlocked: string;
    /**
     * totalOneAndOneWon
     */
    totalOneAndOneWon: string;
    /**
     * avgOneAndOneWon
     */
    avgOneAndOneWon: string;
    /**
     * totalOneAndOneLost
     */
    totalOneAndOneLost: string;
    /**
     * avgOneAndOneLost
     */
    avgOneAndOneLost: string;
    /**
     * totalFoulReceive
     */
    totalFoulReceive: string;
    /**
     * avgFoulReceive
     */
    avgFoulReceive: string;
    /**
     * totalFoulCommit
     */
    totalFoulCommit: string;
    /**
     * avgFoulCommit
     */
    avgFoulCommit: string;
    /**
     * totalSevenMSuspProvoke
     */
    totalSevenMSuspProvoke: string;
    /**
     * avgSevenMSuspProvoke
     */
    avgSevenMSuspProvoke: string;
    /**
     * totalSevenMSuspCommit
     */
    totalSevenMSuspCommit: string;
    /**
     * avgSevenMSuspCommit
     */
    avgSevenMSuspCommit: string;
    /**
     * totalSevenMetersProvoke
     */
    totalSevenMetersProvoke: string;
    /**
     * avgSevenMetersProvoke
     */
    avgSevenMetersProvoke: string;
    /**
     * totalSevenMetersCommit
     */
    totalSevenMetersCommit: string;
    /**
     * avgSevenMetersCommit
     */
    avgSevenMetersCommit: string;
    /**
     * totalTwoMinProvoke
     */
    totalTwoMinProvoke: string;
    /**
     * avgTwoMinProvoke
     */
    avgTwoMinProvoke: string;
    /**
     * totalTwoMinCommit
     */
    totalTwoMinCommit: string;
    /**
     * avgTwoMinCommit
     */
    avgTwoMinCommit: string;
    /**
     * totalLostBall
     */
    totalLostBall: string;
    /**
     * avgLostBall
     */
    avgLostBall: string;
    /**
     * totalLostBallProvoke
     */
    totalLostBallProvoke: string;
    /**
     * avgLostBallProvoke
     */
    avgLostBallProvoke: string;
    /**
     * totalTechnicalMistakeCommit
     */
    totalTechnicalMistakeCommit: string;
    /**
     * avgTechnicalMistakeCommit
     */
    avgTechnicalMistakeCommit: string;
    /**
     * totalTechnicalMistakeProvoke
     */
    totalTechnicalMistakeProvoke: string;
    /**
     * avgTechnicalMistakeProvoke
     */
    avgTechnicalMistakeProvoke: string;
    /**
     * totalAttackFaultCommit
     */
    totalAttackFaultCommit: string;
    /**
     * avgAttackFaultCommit
     */
    avgAttackFaultCommit: string;
    /**
     * totalAttackFaultProvoke
     */
    totalAttackFaultProvoke: string;
    /**
     * avgAttackFaultProvoke
     */
    avgAttackFaultProvoke: string;
    /**
     * avgEfficiency
     */
    avgEfficiency: string;
    /**
     * avgShotEfficiency
     */
    avgShotEfficiency: string;
    /**
     * avgLostBallPerc
     */
    avgLostBallPerc: string;
    /**
     * totalGoalsOffensePositional
     */
    totalGoalsOffensePositional: string;
    /**
     * totalGoalsFastBreak
     */
    totalGoalsFastBreak: string;
    /**
     * totalGoalsCounterGoals
     */
    totalGoalsCounterGoals: string;
    /**
     * avgGoalsOffensePositional
     */
    avgGoalsOffensePositional: string;
    /**
     * avgGoalsFastBreak
     */
    avgGoalsFastBreak: string;
    /**
     * avgGoalsCounterGoals
     */
    avgGoalsCounterGoals: string;
    /**
     * totalSavesOffensePositional
     */
    totalSavesOffensePositional: string;
    /**
     * totalSavesFastBreak
     */
    totalSavesFastBreak: string;
    /**
     * totalSavesCounterGoals
     */
    totalSavesCounterGoals: string;
    /**
     * totalLostBallsOffensePositional
     */
    totalLostBallsOffensePositional: string;
    /**
     * totalLostBallsFastBreak
     */
    totalLostBallsFastBreak: string;
    /**
     * totalLostBallsCounterGoals
     */
    totalLostBallsCounterGoals: string;
    /**
     * totalGoals1
     */
    totalGoals1: string;
    /**
     * totalGoals2
     */
    totalGoals2: string;
    /**
     * totalGoals3
     */
    totalGoals3: string;
    /**
     * totalGoals4
     */
    totalGoals4: string;
    /**
     * totalGoals5
     */
    totalGoals5: string;
    /**
     * totalGoals6
     */
    totalGoals6: string;
    /**
     * totalGoals7
     */
    totalGoals7: string;
    /**
     * totalGoals8
     */
    totalGoals8: string;
    /**
     * totalGoals9
     */
    totalGoals9: string;
    /**
     * totalSaves1
     */
    totalSaves1: string;
    /**
     * totalSaves2
     */
    totalSaves2: string;
    /**
     * totalSaves3
     */
    totalSaves3: string;
    /**
     * totalSaves4
     */
    totalSaves4: string;
    /**
     * totalSaves5
     */
    totalSaves5: string;
    /**
     * totalSaves6
     */
    totalSaves6: string;
    /**
     * totalSaves7
     */
    totalSaves7: string;
    /**
     * totalSaves8
     */
    totalSaves8: string;
    /**
     * totalSaves9
     */
    totalSaves9: string;
    /**
     * totalGoalsLeftWing
     */
    totalGoalsLeftWing: string;
    /**
     * totalGoalsLeft9m
     */
    totalGoalsLeft9m: string;
    /**
     * totalGoalsLeft6m
     */
    totalGoalsLeft6m: string;
    /**
     * totalGoalsCenter6m
     */
    totalGoalsCenter6m: string;
    /**
     * totalGoalsCenter9m
     */
    totalGoalsCenter9m: string;
    /**
     * totalGoalsRightWing
     */
    totalGoalsRightWing: string;
    /**
     * totalGoalsRight9m
     */
    totalGoalsRight9m: string;
    /**
     * totalGoalsRight6m
     */
    totalGoalsRight6m: string;
    /**
     * totalGoals7Meters
     */
    totalGoals7Meters: string;
    /**
     * avgGoals7Meters
     */
    avgGoals7Meters: string;
    /**
     * totalGoalsOppositeField
     */
    totalGoalsOppositeField: string;
    /**
     * totalSavesLeftWing
     */
    totalSavesLeftWing: string;
    /**
     * totalSavesLeft9m
     */
    totalSavesLeft9m: string;
    /**
     * totalSavesLeft6m
     */
    totalSavesLeft6m: string;
    /**
     * totalSavesCenter6m
     */
    totalSavesCenter6m: string;
    /**
     * totalSavesCenter9m
     */
    totalSavesCenter9m: string;
    /**
     * totalSavesRightWing
     */
    totalSavesRightWing: string;
    /**
     * totalSavesRight9m
     */
    totalSavesRight9m: string;
    /**
     * totalSavesRight6m
     */
    totalSavesRight6m: string;
    /**
     * totalSaves7Meters
     */
    totalSaves7Meters: string;
    /**
     * totalSavesOppositeField
     */
    totalSavesOppositeField: string;
    /**
     * avgPace
     */
    avgPace: string;
    /**
     * totalDefenseSixToZero
     */
    totalDefenseSixToZero: string;
    /**
     * totalDefenseFiveToOne
     */
    totalDefenseFiveToOne: string;
    /**
     * totalDefenseThreeToTwoToOne
     */
    totalDefenseThreeToTwoToOne: string;
    /**
     * totalDefenseFourToTwo
     */
    totalDefenseFourToTwo: string;
    /**
     * totalDefenseOther
     */
    totalDefenseOther: string;
    /**
     * totalPossOffensePositional
     */
    totalPossOffensePositional: string;
    /**
     * totalPossFastBreak
     */
    totalPossFastBreak: string;
    /**
     * totalPossCounterGoal
     */
    totalPossCounterGoal: string;
    /**
     * avgPossOffensePositional
     */
    avgPossOffensePositional: string;
    /**
     * avgPossFastBreak
     */
    avgPossFastBreak: string;
    /**
     * avgPossCounterGoal
     */
    avgPossCounterGoal: string;
    /**
     * totalEffectivePossOffensePositional
     */
    totalEffectivePossOffensePositional: string;
    /**
     * totalEffectivePossFastBreak
     */
    totalEffectivePossFastBreak: string;
    /**
     * totalEffectivePossCounterGoal
     */
    totalEffectivePossCounterGoal: string;
    /**
     * avgEffectivePossOffensePositional
     */
    avgEffectivePossOffensePositional: string;
    /**
     * avgEffectivePossFastBreak
     */
    avgEffectivePossFastBreak: string;
    /**
     * avgEffectivePossCounterGoal
     */
    avgEffectivePossCounterGoal: string;
    /**
     * avgEffOffensePositionalEquality
     */
    avgEffOffensePositionalEquality: string;
    /**
     * avgEffFastBreakEquality
     */
    avgEffFastBreakEquality: string;
    /**
     * avgEffCounterGoalEquality
     */
    avgEffCounterGoalEquality: string;
    /**
     * avgEffOffensePositionalSuperiority
     */
    avgEffOffensePositionalSuperiority: string;
    /**
     * avgEffFastBreakSuperiority
     */
    avgEffFastBreakSuperiority: string;
    /**
     * avgEffCounterGoalSuperiority
     */
    avgEffCounterGoalSuperiority: string;
    /**
     * avgEffOffensePositionalInferiority
     */
    avgEffOffensePositionalInferiority: string;
    /**
     * avgEffFastBreakInferiority
     */
    avgEffFastBreakInferiority: string;
    /**
     * avgEffCounterGoalInferiority
     */
    avgEffCounterGoalInferiority: string;
    /**
     * avgEffAgainst60
     */
    avgEffAgainst60: string;
    /**
     * avgEffAgainst51
     */
    avgEffAgainst51: string;
    /**
     * avgEffAgainst321
     */
    avgEffAgainst321: string;
    /**
     * avgEffAgainst42
     */
    avgEffAgainst42: string;
    /**
     * avgEffAgainstOther
     */
    avgEffAgainstOther: string;
    /**
     * totalPostOutOffensePositional
     */
    totalPostOutOffensePositional: string;
    /**
     * totalPostOutFastBreak
     */
    totalPostOutFastBreak: string;
    /**
     * totalPostOutCounterGoal
     */
    totalPostOutCounterGoal: string;
    /**
     * totalPostOutOffensePositional
     */
    avgPostOutOffensePositional: string;
    /**
     * avgPostOutFastBreak
     */
    avgPostOutFastBreak: string;
    /**
     * avgPostOutCounterGoal
     */
    avgPostOutCounterGoal: string;
    /**
     * avgFailedShotsOffensePositional
     */
    totalFailedShotsOffensePositional: string;
    /**
     * totalFailedShotsFastBreak
     */
    totalFailedShotsFastBreak: string;
    /**
     * totalFailedShotsCounterGoal
     */
    totalFailedShotsCounterGoal: string;
    /**
     * Avg of FailedShotsOffensePositional
     */
    avgFailedShotsOffensePositional?: string;
    /**
     * Avg of FailedShotsFastBreak
     */
    avgFailedShotsFastBreak: string;
    /**
     * Avg of FailedShotsCounterGoal
     */
    avgFailedShotsCounterGoal: string;
    /**
     * avgEffSuperiority
     */
    avgEffSuperiority: string;
    /**
     * avgEff7vs6
     */
    avgEff7vs6: string;
    /**
     * avgEffDefense
     */
    avgEffDefense: string;
    /**
     * totalPossMin5
     */
    totalPossMin5: string;
    /**
     * totalPossMin10
     */
    totalPossMin10: string;
    /**
     * totalPossMin15
     */
    totalPossMin15: string;
    /**
     * totalPossMin20
     */
    totalPossMin20: string;
    /**
     * totalPossMin25
     */
    totalPossMin25: string;
    /**
     * totalPossMin30
     */
    totalPossMin30: string;
    /**
     * totalPossMin35
     */
    totalPossMin35: string;
    /**
     * totalPossMin40
     */
    totalPossMin40: string;
    /**
     * totalPossMin45
     */
    totalPossMin45: string;
    /**
     * totalPossMin50
     */
    totalPossMin50: string;
    /**
     * totalPossMin55
     */
    totalPossMin55: string;
    /**
     * totalPossMin60
     */
    totalPossMin60: string;
    /**
     * totalLostBallMin5
     */
    totalLostBallMin5: string;
    /**
     * totalLostBallMin10
     */
    totalLostBallMin10: string;
    /**
     * totalLostBallMin15
     */
    totalLostBallMin15: string;
    /**
     * totalLostBallMin20
     */
    totalLostBallMin20: string;
    /**
     * totalLostBallMin25
     */
    totalLostBallMin25: string;
    /**
     * totalLostBallMin30
     */
    totalLostBallMin30: string;
    /**
     * totalLostBallMin35
     */
    totalLostBallMin35: string;
    /**
     * totalLostBallMin40
     */
    totalLostBallMin40: string;
    /**
     * totalLostBallMin45
     */
    totalLostBallMin45: string;
    /**
     * totalLostBallMin50
     */
    totalLostBallMin50: string;
    /**
     * totalLostBallMin55
     */
    totalLostBallMin55: string;
    /**
     * totalLostBallMin60
     */
    totalLostBallMin60: string;
    /**
     * totalGoalMin5
     */
    totalGoalMin5: string;
    /**
     * totalGoalMin10
     */
    totalGoalMin10: string;
    /**
     * totalGoalMin15
     */
    totalGoalMin15: string;
    /**
     * totalGoalMin20
     */
    totalGoalMin20: string;
    /**
     * totalGoalMin25
     */
    totalGoalMin25: string;
    /**
     * totalGoalMin30
     */
    totalGoalMin30: string;
    /**
     * totalGoalMin35
     */
    totalGoalMin35: string;
    /**
     * totalGoalMin40
     */
    totalGoalMin40: string;
    /**
     * totalGoalMin45
     */
    totalGoalMin45: string;
    /**
     * totalGoalMin50
     */
    totalGoalMin50: string;
    /**
     * totalGoalMin55
     */
    totalGoalMin55: string;
    /**
     * totalGoalMin60
     */
    totalGoalMin60: string;
    /**
     * totalSavesMin5
     */
    totalSavesMin5: string;
    /**
     * totalSavesMin10
     */
    totalSavesMin10: string;
    /**
     * totalSavesMin15
     */
    totalSavesMin15: string;
    /**
     * totalSavesMin20
     */
    totalSavesMin20: string;
    /**
     * totalSavesMin25
     */
    totalSavesMin25: string;
    /**
     * totalSavesMin30
     */
    totalSavesMin30: string;
    /**
     * totalSavesMin35
     */
    totalSavesMin35: string;
    /**
     * totalSavesMin40
     */
    totalSavesMin40: string;
    /**
     * totalSavesMin45
     */
    totalSavesMin45: string;
    /**
     * totalSavesMin50
     */
    totalSavesMin50: string;
    /**
     * totalSavesMin55
     */
    totalSavesMin55: string;
    /**
     * totalSavesMin60
     */
    totalSavesMin60: string;
    /**
     * totalAssistGkLw
     */
    totalAssistGkLw: string;
    /**
     * totalAssistGkRw
     */
    totalAssistGkRw: string;
    /**
     * totalAssistGkLb
     */
    totalAssistGkLb: string;
    /**
     * totalAssistGkRb
     */
    totalAssistGkRb: string;
    /**
     * totalAssistGkCb
     */
    totalAssistGkCb: string;
    /**
     * totalAssistGkLp
     */
    totalAssistGkLp: string;
    /**
     * totalAssistLwGk
     */
    totalAssistLwGk: string;
    /**
     * totalAssistLwRw
     */
    totalAssistLwRw: string;
    /**
     * totalAssistLwLb
     */
    totalAssistLwLb: string;
    /**
     * totalAssistLwRb
     */
    totalAssistLwRb: string;
    /**
     * totalAssistLwCb
     */
    totalAssistLwCb: string;
    /**
     * totalAssistLwLp
     */
    totalAssistLwLp: string;
    /**
     * totalAssistRwGk
     */
    totalAssistRwGk: string;
    /**
     * totalAssistRwLw
     */
    totalAssistRwLw: string;
    /**
     * totalAssistRwLb
     */
    totalAssistRwLb: string;
    /**
     * totalAssistRwRb
     */
    totalAssistRwRb: string;
    /**
     * totalAssistRwCb
     */
    totalAssistRwCb: string;
    /**
     * totalAssistRwLp
     */
    totalAssistRwLp: string;
    /**
     * totalAssistLbGk
     */
    totalAssistLbGk: string;
    /**
     * totalAssistLbLw
     */
    totalAssistLbLw: string;
    /**
     * totalAssistLbRw
     */
    totalAssistLbRw: string;
    /**
     * totalAssistLbRb
     */
    totalAssistLbRb: string;
    /**
     * totalAssistLbCb
     */
    totalAssistLbCb: string;
    /**
     * totalAssistLbLp
     */
    totalAssistLbLp: string;
    /**
     * totalAssistRbGk
     */
    totalAssistRbGk: string;
    /**
     * totalAssistRbLw
     */
    totalAssistRbLw: string;
    /**
     * totalAssistRbRw
     */
    totalAssistRbRw: string;
    /**
     * totalAssistRbLb
     */
    totalAssistRbLb: string;
    /**
     * totalAssistRbCb
     */
    totalAssistRbCb: string;
    /**
     * totalAssistRbLp
     */
    totalAssistRbLp: string;
    /**
     * totalAssistCbGk
     */
    totalAssistCbGk: string;
    /**
     * totalAssistCbLw
     */
    totalAssistCbLw: string;
    /**
     * totalAssistCbRw
     */
    totalAssistCbRw: string;
    /**
     * totalAssistCbLb
     */
    totalAssistCbLb: string;
    /**
     * totalAssistCbRb
     */
    totalAssistCbRb: string;
    /**
     * totalAssistCbLp
     */
    totalAssistCbLp: string;
    /**
     * totalAssistLpGk
     */
    totalAssistLpGk: string;
    /**
     * totalAssistLpLw
     */
    totalAssistLpLw: string;
    /**
     * totalAssistLpRw
     */
    totalAssistLpRw: string;
    /**
     * totalAssistLpLb
     */
    totalAssistLpLb: string;
    /**
     * totalAssistLpRb
     */
    totalAssistLpRb: string;
    /**
     * totalAssistLpCb
     */
    totalAssistLpCb: string;
    /**
     * avgAssistGkLw
     */
    avgAssistGkLw: string;
    /**
     * avgAssistGkRw
     */
    avgAssistGkRw: string;
    /**
     * avgAssistGkLb
     */
    avgAssistGkLb: string;
    /**
     * avgAssistGkRb
     */
    avgAssistGkRb: string;
    /**
     * avgAssistGkCb
     */
    avgAssistGkCb: string;
    /**
     * avgAssistGkLp
     */
    avgAssistGkLp: string;
    /**
     * avgAssistLwGk
     */
    avgAssistLwGk: string;
    /**
     * avgAssistLwRw
     */
    avgAssistLwRw: string;
    /**
     * avgAssistLwLb
     */
    avgAssistLwLb: string;
    /**
     * avgAssistLwRb
     */
    avgAssistLwRb: string;
    /**
     * avgAssistLwCb
     */
    avgAssistLwCb: string;
    /**
     * avgAssistLwLp
     */
    avgAssistLwLp: string;
    /**
     * avgAssistRwGk
     */
    avgAssistRwGk: string;
    /**
     * avgAssistRwLw
     */
    avgAssistRwLw: string;
    /**
     * avgAssistRwLb
     */
    avgAssistRwLb: string;
    /**
     * avgAssistRwRb
     */
    avgAssistRwRb: string;
    /**
     * avgAssistRwCb
     */
    avgAssistRwCb: string;
    /**
     * avgAssistRwLp
     */
    avgAssistRwLp: string;
    /**
     * avgAssistLbGk
     */
    avgAssistLbGk: string;
    /**
     * avgAssistLbLw
     */
    avgAssistLbLw: string;
    /**
     * avgAssistLbRw
     */
    avgAssistLbRw: string;
    /**
     * avgAssistLbRb
     */
    avgAssistLbRb: string;
    /**
     * avgAssistLbCb
     */
    avgAssistLbCb: string;
    /**
     * avgAssistLbLp
     */
    avgAssistLbLp: string;
    /**
     * avgAssistRbGk
     */
    avgAssistRbGk: string;
    /**
     * avgAssistRbLw
     */
    avgAssistRbLw: string;
    /**
     * avgAssistRbRw
     */
    avgAssistRbRw: string;
    /**
     * avgAssistRbLb
     */
    avgAssistRbLb: string;
    /**
     * avgAssistRbCb
     */
    avgAssistRbCb: string;
    /**
     * avgAssistRbLp
     */
    avgAssistRbLp: string;
    /**
     * avgAssistCbGk
     */
    avgAssistCbGk: string;
    /**
     * avgAssistCbLw
     */
    avgAssistCbLw: string;
    /**
     * avgAssistCbRw
     */
    avgAssistCbRw: string;
    /**
     * avgAssistCbLb
     */
    avgAssistCbLb: string;
    /**
     * avgAssistCbRb
     */
    avgAssistCbRb: string;
    /**
     * avgAssistCbLp
     */
    avgAssistCbLp: string;
    /**
     * avgAssistLpGk
     */
    avgAssistLpGk: string;
    /**
     * avgAssistLpLw
     */
    avgAssistLpLw: string;
    /**
     * avgAssistLpRw
     */
    avgAssistLpRw: string;
    /**
     * avgAssistLpLb
     */
    avgAssistLpLb: string;
    /**
     * avgAssistLpRb
     */
    avgAssistLpRb: string;
    /**
     * avgAssistLpCb
     */
    avgAssistLpCb: string;
    /**
     * avgSixZeroSuperiorityEfficiency
     */
    avgSixZeroSuperiorityEfficiency: string;
    /**
     * avgFiveOneSuperiorityEfficiency
     */
    avgFiveOneSuperiorityEfficiency: string;
    /**
     * avgThreeTwoOneSuperiorityEfficiency
     */
    avgThreeTwoOneSuperiorityEfficiency: string;
    /**
     * avgFourTwoSuperiorityEfficiency
     */
    avgFourTwoSuperiorityEfficiency: string;
    /**
     * avgOtherSuperiorityEfficiency
     */
    avgOtherSuperiorityEfficiency: string;
    /**
     * avgSixZeroInferiorityEfficiency
     */
    avgSixZeroInferiorityEfficiency: string;
    /**
     * avgFiveOneInferiorityEfficiency
     */
    avgFiveOneInferiorityEfficiency: string;
    /**
     * avgThreeTwoOneInferiorityEfficiency
     */
    avgThreeTwoOneInferiorityEfficiency: string;
    /**
     * avgFourTwoInferiorityEfficiency
     */
    avgFourTwoInferiorityEfficiency: string;
    /**
     * avgOtherInferiorityEfficiency
     */
    avgOtherInferiorityEfficiency: string;
    /**
     * avgSixZeroEqualityEfficiency
     */
    avgSixZeroEqualityEfficiency: string;
    /**
     * avgFiveOneEqualityEfficiency
     */
    avgFiveOneEqualityEfficiency: string;
    /**
     * avgThreeTwoOneEqualityEfficiency
     */
    avgThreeTwoOneEqualityEfficiency: string;
    /**
     * avgFourTwoEqualityEfficiency
     */
    avgFourTwoEqualityEfficiency: string;
    /**
     * avgOtherEqualityEfficiency
     */
    avgOtherEqualityEfficiency: string;
    /**
     * totalGkSaveOffensePositional
     */
    totalGkSaveOffensePositional: string;
    /**
     * totalGkSaveFastBreak
     */
    totalGkSaveFastBreak: string;
    /**
     * totalGkSaveCounterGoal
     */
    totalGkSaveCounterGoal: string;
    /**
     * avgSuperiorityEfficiency
     */
    avgSuperiorityEfficiency: string;
    /**
     * avgEqualityEfficiency
     */
    avgEqualityEfficiency: string;
    /**
     * avgInferiorityEfficiency
     */
    avgInferiorityEfficiency: string;
    /**
     * totalPostOutCenter6m
     */
    totalPostOutCenter6m: string;
    /**
     * totalPostOutCenter9m
     */
    totalPostOutCenter9m: string;
    /**
     * totalPostOutLeft6m
     */
    totalPostOutLeft6m: string;
    /**
     * totalPostOutLeft9m
     */
    totalPostOutLeft9m: string;
    /**
     * totalPostOutLeftWing
     */
    totalPostOutLeftWing: string;
    /**
     * totalPostOutOppositeField
     */
    totalPostOutOppositeField: string;
    /**
     * totalPostOutRight6m
     */
    totalPostOutRight6m: string;
    /**
     * totalPostOutRight9m
     */
    totalPostOutRight9m: string;
    /**
     * totalPostOutRightWing
     */
    totalPostOutRightWing: string;
    /**
     * totalPostOut7Meters
     */
    totalPostOut7Meters: string;
    /**
     * totalPostOut0
     */
    totalPostOut0: string;
    /**
     * totalPostOut10
     */
    totalPostOut10: string;
    /**
     * totalPostOut11
     */
    totalPostOut11: string;
    /**
     * totalGoalsLeftWing0
     */
    totalGoalsLeftWing0: string;
    /**
     * totalGoalsLeftWing1
     */
    totalGoalsLeftWing1: string;
    /**
     * totalGoalsLeftWing2
     */
    totalGoalsLeftWing2: string;
    /**
     * totalGoalsLeftWing3
     */
    totalGoalsLeftWing3: string;
    /**
     * totalGoalsLeftWing4
     */
    totalGoalsLeftWing4: string;
    /**
     * totalGoalsLeftWing5
     */
    totalGoalsLeftWing5: string;
    /**
     * totalGoalsLeftWing6
     */
    totalGoalsLeftWing6: string;
    /**
     * totalGoalsLeftWing7
     */
    totalGoalsLeftWing7: string;
    /**
     * totalGoalsLeftWing8
     */
    totalGoalsLeftWing8: string;
    /**
     * totalGoalsLeftWing9
     */
    totalGoalsLeftWing9: string;
    /**
     * totalGoalsLeftWing10
     */
    totalGoalsLeftWing10: string;
    /**
     * totalGoalsLeftWing11
     */
    totalGoalsLeftWing11: string;
    /**
     * totalGoalsLeft6m0
     */
    totalGoalsLeft6m0: string;
    /**
     * totalGoalsLeft6m1
     */
    totalGoalsLeft6m1: string;
    /**
     * totalGoalsLeft6m2
     */
    totalGoalsLeft6m2: string;
    /**
     * totalGoalsLeft6m3
     */
    totalGoalsLeft6m3: string;
    /**
     * totalGoalsLeft6m4
     */
    totalGoalsLeft6m4: string;
    /**
     * totalGoalsLeft6m5
     */
    totalGoalsLeft6m5: string;
    /**
     * totalGoalsLeft6m6
     */
    totalGoalsLeft6m6: string;
    /**
     * totalGoalsLeft6m7
     */
    totalGoalsLeft6m7: string;
    /**
     * totalGoalsLeft6m8
     */
    totalGoalsLeft6m8: string;
    /**
     * totalGoalsLeft6m9
     */
    totalGoalsLeft6m9: string;
    /**
     * totalGoalsLeft6m10
     */
    totalGoalsLeft6m10: string;
    /**
     * totalGoalsLeft6m11
     */
    totalGoalsLeft6m11: string;
    /**
     * totalGoalsCenter6m0
     */
    totalGoalsCenter6m0: string;
    /**
     * totalGoalsCenter6m1
     */
    totalGoalsCenter6m1: string;
    /**
     * totalGoalsCenter6m2
     */
    totalGoalsCenter6m2: string;
    /**
     * totalGoalsCenter6m3
     */
    totalGoalsCenter6m3: string;
    /**
     * totalGoalsCenter6m4
     */
    totalGoalsCenter6m4: string;
    /**
     * totalGoalsCenter6m5
     */
    totalGoalsCenter6m5: string;
    /**
     * totalGoalsCenter6m6
     */
    totalGoalsCenter6m6: string;
    /**
     * totalGoalsCenter6m7
     */
    totalGoalsCenter6m7: string;
    /**
     * totalGoalsCenter6m8
     */
    totalGoalsCenter6m8: string;
    /**
     * totalGoalsCenter6m9
     */
    totalGoalsCenter6m9: string;
    /**
     * totalGoalsCenter6m10
     */
    totalGoalsCenter6m10: string;
    /**
     * totalGoalsCenter6m11
     */
    totalGoalsCenter6m11: string;
    /**
     * totalGoalsRight6m0
     */
    totalGoalsRight6m0: string;
    /**
     * totalGoalsRight6m1
     */
    totalGoalsRight6m1: string;
    /**
     * totalGoalsRight6m2
     */
    totalGoalsRight6m2: string;
    /**
     * totalGoalsRight6m3
     */
    totalGoalsRight6m3: string;
    /**
     * totalGoalsRight6m4
     */
    totalGoalsRight6m4: string;
    /**
     * totalGoalsRight6m5
     */
    totalGoalsRight6m5: string;
    /**
     * totalGoalsRight6m6
     */
    totalGoalsRight6m6: string;
    /**
     * totalGoalsRight6m7
     */
    totalGoalsRight6m7: string;
    /**
     * totalGoalsRight6m8
     */
    totalGoalsRight6m8: string;
    /**
     * totalGoalsRight6m9
     */
    totalGoalsRight6m9: string;
    /**
     * totalGoalsRight6m10
     */
    totalGoalsRight6m10: string;
    /**
     * totalGoalsRight6m11
     */
    totalGoalsRight6m11: string;
    /**
     * totalGoalsRightWing0
     */
    totalGoalsRightWing0: string;
    /**
     * totalGoalsRightWing1
     */
    totalGoalsRightWing1: string;
    /**
     * totalGoalsRightWing2
     */
    totalGoalsRightWing2: string;
    /**
     * totalGoalsRightWing3
     */
    totalGoalsRightWing3: string;
    /**
     * totalGoalsRightWing4
     */
    totalGoalsRightWing4: string;
    /**
     * totalGoalsRightWing5
     */
    totalGoalsRightWing5: string;
    /**
     * totalGoalsRightWing6
     */
    totalGoalsRightWing6: string;
    /**
     * totalGoalsRightWing7
     */
    totalGoalsRightWing7: string;
    /**
     * totalGoalsRightWing8
     */
    totalGoalsRightWing8: string;
    /**
     * totalGoalsRightWing9
     */
    totalGoalsRightWing9: string;
    /**
     * totalGoalsRightWing10
     */
    totalGoalsRightWing10: string;
    /**
     * totalGoalsRightWing11
     */
    totalGoalsRightWing11: string;
    /**
     * totalGoalsLeft9m0
     */
    totalGoalsLeft9m0: string;
    /**
     * totalGoalsLeft9m1
     */
    totalGoalsLeft9m1: string;
    /**
     * totalGoalsLeft9m2
     */
    totalGoalsLeft9m2: string;
    /**
     * totalGoalsLeft9m3
     */
    totalGoalsLeft9m3: string;
    /**
     * totalGoalsLeft9m4
     */
    totalGoalsLeft9m4: string;
    /**
     * totalGoalsLeft9m5
     */
    totalGoalsLeft9m5: string;
    /**
     * totalGoalsLeft9m6
     */
    totalGoalsLeft9m6: string;
    /**
     * totalGoalsLeft9m7
     */
    totalGoalsLeft9m7: string;
    /**
     * totalGoalsLeft9m8
     */
    totalGoalsLeft9m8: string;
    /**
     * totalGoalsLeft9m9
     */
    totalGoalsLeft9m9: string;
    /**
     * totalGoalsLeft9m10
     */
    totalGoalsLeft9m10: string;
    /**
     * totalGoalsLeft9m11
     */
    totalGoalsLeft9m11: string;
    /**
     * totalGoalsCenter9m0
     */
    totalGoalsCenter9m0: string;
    /**
     * totalGoalsCenter9m1
     */
    totalGoalsCenter9m1: string;
    /**
     * totalGoalsCenter9m2
     */
    totalGoalsCenter9m2: string;
    /**
     * totalGoalsCenter9m3
     */
    totalGoalsCenter9m3: string;
    /**
     * totalGoalsCenter9m4
     */
    totalGoalsCenter9m4: string;
    /**
     * totalGoalsCenter9m5
     */
    totalGoalsCenter9m5: string;
    /**
     * totalGoalsCenter9m6
     */
    totalGoalsCenter9m6: string;
    /**
     * totalGoalsCenter9m7
     */
    totalGoalsCenter9m7: string;
    /**
     * totalGoalsCenter9m8
     */
    totalGoalsCenter9m8: string;
    /**
     * totalGoalsCenter9m9
     */
    totalGoalsCenter9m9: string;
    /**
     * totalGoalsCenter9m10
     */
    totalGoalsCenter9m10: string;
    /**
     * totalGoalsCenter9m11
     */
    totalGoalsCenter9m11: string;
    /**
     * totalGoalsRight9m0
     */
    totalGoalsRight9m0: string;
    /**
     * totalGoalsRight9m1
     */
    totalGoalsRight9m1: string;
    /**
     * totalGoalsRight9m2
     */
    totalGoalsRight9m2: string;
    /**
     * totalGoalsRight9m3
     */
    totalGoalsRight9m3: string;
    /**
     * totalGoalsRight9m4
     */
    totalGoalsRight9m4: string;
    /**
     * totalGoalsRight9m5
     */
    totalGoalsRight9m5: string;
    /**
     * totalGoalsRight9m6
     */
    totalGoalsRight9m6: string;
    /**
     * totalGoalsRight9m7
     */
    totalGoalsRight9m7: string;
    /**
     * totalGoalsRight9m8
     */
    totalGoalsRight9m8: string;
    /**
     * totalGoalsRight9m9
     */
    totalGoalsRight9m9: string;
    /**
     * totalGoalsRight9m10
     */
    totalGoalsRight9m10: string;
    /**
     * totalGoalsRight9m11
     */
    totalGoalsRight9m11: string;
    /**
     * totalGoals7Meters0
     */
    totalGoals7Meters0: string;
    /**
     * totalGoals7Meters1
     */
    totalGoals7Meters1: string;
    /**
     * totalGoals7Meters2
     */
    totalGoals7Meters2: string;
    /**
     * totalGoals7Meters3
     */
    totalGoals7Meters3: string;
    /**
     * totalGoals7Meters4
     */
    totalGoals7Meters4: string;
    /**
     * totalGoals7Meters5
     */
    totalGoals7Meters5: string;
    /**
     * totalGoals7Meters6
     */
    totalGoals7Meters6: string;
    /**
     * totalGoals7Meters7
     */
    totalGoals7Meters7: string;
    /**
     * totalGoals7Meters8
     */
    totalGoals7Meters8: string;
    /**
     * totalGoals7Meters9
     */
    totalGoals7Meters9: string;
    /**
     * totalGoals7Meters10
     */
    totalGoals7Meters10: string;
    /**
     * totalGoals7Meters11
     */
    totalGoals7Meters11: string;
    /**
     * totalGoalsOppositeField0
     */
    totalGoalsOppositeField0: string;
    /**
     * totalGoalsOppositeField1
     */
    totalGoalsOppositeField1: string;
    /**
     * totalGoalsOppositeField2
     */
    totalGoalsOppositeField2: string;
    /**
     * totalGoalsOppositeField3
     */
    totalGoalsOppositeField3: string;
    /**
     * totalGoalsOppositeField4
     */
    totalGoalsOppositeField4: string;
    /**
     * totalGoalsOppositeField5
     */
    totalGoalsOppositeField5: string;
    /**
     * totalGoalsOppositeField6
     */
    totalGoalsOppositeField6: string;
    /**
     * totalGoalsOppositeField7
     */
    totalGoalsOppositeField7: string;
    /**
     * totalGoalsOppositeField8
     */
    totalGoalsOppositeField8: string;
    /**
     * totalGoalsOppositeField9
     */
    totalGoalsOppositeField9: string;
    /**
     * totalGoalsOppositeField10
     */
    totalGoalsOppositeField10: string;
    /**
     * totalGoalsOppositeField11
     */
    totalGoalsOppositeField11: string;
    /**
     * totalShootsLeftWing0
     */
    totalShootsLeftWing0: string;
    /**
     * totalShootsLeftWing1
     */
    totalShootsLeftWing1: string;
    /**
     * totalShootsLeftWing2
     */
    totalShootsLeftWing2: string;
    /**
     * totalShootsLeftWing3
     */
    totalShootsLeftWing3: string;
    /**
     * totalShootsLeftWing4
     */
    totalShootsLeftWing4: string;
    /**
     * totalShootsLeftWing5
     */
    totalShootsLeftWing5: string;
    /**
     * totalShootsLeftWing6
     */
    totalShootsLeftWing6: string;
    /**
     * totalShootsLeftWing7
     */
    totalShootsLeftWing7: string;
    /**
     * totalShootsLeftWing8
     */
    totalShootsLeftWing8: string;
    /**
     * totalShootsLeftWing9
     */
    totalShootsLeftWing9: string;
    /**
     * totalShootsLeftWing10
     */
    totalShootsLeftWing10: string;
    /**
     * totalShootsLeftWing11
     */
    totalShootsLeftWing11: string;
    /**
     * totalShootsLeft6m0
     */
    totalShootsLeft6m0: string;
    /**
     * totalShootsLeft6m1
     */
    totalShootsLeft6m1: string;
    /**
     * totalShootsLeft6m2
     */
    totalShootsLeft6m2: string;
    /**
     * totalShootsLeft6m3
     */
    totalShootsLeft6m3: string;
    /**
     * totalShootsLeft6m4
     */
    totalShootsLeft6m4: string;
    /**
     * totalShootsLeft6m5
     */
    totalShootsLeft6m5: string;
    /**
     * totalShootsLeft6m6
     */
    totalShootsLeft6m6: string;
    /**
     * totalShootsLeft6m7
     */
    totalShootsLeft6m7: string;
    /**
     * totalShootsLeft6m8
     */
    totalShootsLeft6m8: string;
    /**
     * totalShootsLeft6m9
     */
    totalShootsLeft6m9: string;
    /**
     * totalShootsLeft6m10
     */
    totalShootsLeft6m10: string;
    /**
     * totalShootsLeft6m11
     */
    totalShootsLeft6m11: string;
    /**
     * totalShootsCenter6m0
     */
    totalShootsCenter6m0: string;
    /**
     * totalShootsCenter6m1
     */
    totalShootsCenter6m1: string;
    /**
     * totalShootsCenter6m2
     */
    totalShootsCenter6m2: string;
    /**
     * totalShootsCenter6m3
     */
    totalShootsCenter6m3: string;
    /**
     * totalShootsCenter6m4
     */
    totalShootsCenter6m4: string;
    /**
     * totalShootsCenter6m5
     */
    totalShootsCenter6m5: string;
    /**
     * totalShootsCenter6m6
     */
    totalShootsCenter6m6: string;
    /**
     * totalShootsCenter6m7
     */
    totalShootsCenter6m7: string;
    /**
     * totalShootsCenter6m8
     */
    totalShootsCenter6m8: string;
    /**
     * totalShootsCenter6m9
     */
    totalShootsCenter6m9: string;
    /**
     * totalShootsCenter6m10
     */
    totalShootsCenter6m10: string;
    /**
     * totalShootsCenter6m11
     */
    totalShootsCenter6m11: string;
    /**
     * totalShootsRight6m0
     */
    totalShootsRight6m0: string;
    /**
     * totalShootsRight6m1
     */
    totalShootsRight6m1: string;
    /**
     * totalShootsRight6m2
     */
    totalShootsRight6m2: string;
    /**
     * totalShootsRight6m3
     */
    totalShootsRight6m3: string;
    /**
     * totalShootsRight6m4
     */
    totalShootsRight6m4: string;
    /**
     * totalShootsRight6m5
     */
    totalShootsRight6m5: string;
    /**
     * totalShootsRight6m6
     */
    totalShootsRight6m6: string;
    /**
     * totalShootsRight6m7
     */
    totalShootsRight6m7: string;
    /**
     * totalShootsRight6m8
     */
    totalShootsRight6m8: string;
    /**
     * totalShootsRight6m9
     */
    totalShootsRight6m9: string;
    /**
     * totalShootsRight6m10
     */
    totalShootsRight6m10: string;
    /**
     * totalShootsRight6m11
     */
    totalShootsRight6m11: string;
    /**
     * totalShootsRightWing0
     */
    totalShootsRightWing0: string;
    /**
     * totalShootsRightWing1
     */
    totalShootsRightWing1: string;
    /**
     * totalShootsRightWing2
     */
    totalShootsRightWing2: string;
    /**
     * totalShootsRightWing3
     */
    totalShootsRightWing3: string;
    /**
     * totalShootsRightWing4
     */
    totalShootsRightWing4: string;
    /**
     * totalShootsRightWing5
     */
    totalShootsRightWing5: string;
    /**
     * totalShootsRightWing6
     */
    totalShootsRightWing6: string;
    /**
     * totalShootsRightWing7
     */
    totalShootsRightWing7: string;
    /**
     * totalShootsRightWing8
     */
    totalShootsRightWing8: string;
    /**
     * totalShootsRightWing9
     */
    totalShootsRightWing9: string;
    /**
     * totalShootsRightWing10
     */
    totalShootsRightWing10: string;
    /**
     * totalShootsRightWing11
     */
    totalShootsRightWing11: string;
    /**
     * totalShootsLeft9m0
     */
    totalShootsLeft9m0: string;
    /**
     * totalShootsLeft9m1
     */
    totalShootsLeft9m1: string;
    /**
     * totalShootsLeft9m2
     */
    totalShootsLeft9m2: string;
    /**
     * totalShootsLeft9m3
     */
    totalShootsLeft9m3: string;
    /**
     * totalShootsLeft9m4
     */
    totalShootsLeft9m4: string;
    /**
     * totalShootsLeft9m5
     */
    totalShootsLeft9m5: string;
    /**
     * totalShootsLeft9m6
     */
    totalShootsLeft9m6: string;
    /**
     * totalShootsLeft9m7
     */
    totalShootsLeft9m7: string;
    /**
     * totalShootsLeft9m8
     */
    totalShootsLeft9m8: string;
    /**
     * totalShootsLeft9m9
     */
    totalShootsLeft9m9: string;
    /**
     * totalShootsLeft9m10
     */
    totalShootsLeft9m10: string;
    /**
     * totalShootsLeft9m11
     */
    totalShootsLeft9m11: string;
    /**
     * totalShootsCenter9m0
     */
    totalShootsCenter9m0: string;
    /**
     * totalShootsCenter9m1
     */
    totalShootsCenter9m1: string;
    /**
     * totalShootsCenter9m2
     */
    totalShootsCenter9m2: string;
    /**
     * totalShootsCenter9m3
     */
    totalShootsCenter9m3: string;
    /**
     * totalShootsCenter9m4
     */
    totalShootsCenter9m4: string;
    /**
     * totalShootsCenter9m5
     */
    totalShootsCenter9m5: string;
    /**
     * totalShootsCenter9m6
     */
    totalShootsCenter9m6: string;
    /**
     * totalShootsCenter9m7
     */
    totalShootsCenter9m7: string;
    /**
     * totalShootsCenter9m8
     */
    totalShootsCenter9m8: string;
    /**
     * totalShootsCenter9m9
     */
    totalShootsCenter9m9: string;
    /**
     * totalShootsCenter9m10
     */
    totalShootsCenter9m10: string;
    /**
     * totalShootsCenter9m11
     */
    totalShootsCenter9m11: string;
    /**
     * totalShootsRight9m0
     */
    totalShootsRight9m0: string;
    /**
     * totalShootsRight9m1
     */
    totalShootsRight9m1: string;
    /**
     * totalShootsRight9m2
     */
    totalShootsRight9m2: string;
    /**
     * totalShootsRight9m3
     */
    totalShootsRight9m3: string;
    /**
     * totalShootsRight9m4
     */
    totalShootsRight9m4: string;
    /**
     * totalShootsRight9m5
     */
    totalShootsRight9m5: string;
    /**
     * totalShootsRight9m6
     */
    totalShootsRight9m6: string;
    /**
     * totalShootsRight9m7
     */
    totalShootsRight9m7: string;
    /**
     * totalShootsRight9m8
     */
    totalShootsRight9m8: string;
    /**
     * totalShootsRight9m9
     */
    totalShootsRight9m9: string;
    /**
     * totalShootsRight9m10
     */
    totalShootsRight9m10: string;
    /**
     * totalShootsRight9m11
     */
    totalShootsRight9m11: string;
    /**
     * totalShoots7Meters0
     */
    totalShoots7Meters0: string;
    /**
     * totalShoots7Meters1
     */
    totalShoots7Meters1: string;
    /**
     * totalShoots7Meters2
     */
    totalShoots7Meters2: string;
    /**
     * totalShoots7Meters3
     */
    totalShoots7Meters3: string;
    /**
     * totalShoots7Meters4
     */
    totalShoots7Meters4: string;
    /**
     * totalShoots7Meters5
     */
    totalShoots7Meters5: string;
    /**
     * totalShoots7Meters6
     */
    totalShoots7Meters6: string;
    /**
     * totalShoots7Meters7
     */
    totalShoots7Meters7: string;
    /**
     * totalShoots7Meters8
     */
    totalShoots7Meters8: string;
    /**
     * totalShoots7Meters9
     */
    totalShoots7Meters9: string;
    /**
     * totalShoots7Meters10
     */
    totalShoots7Meters10: string;
    /**
     * totalShoots7Meters11
     */
    totalShoots7Meters11: string;
    /**
     * totalShootsOppositeField0
     */
    totalShootsOppositeField0: string;
    /**
     * totalShootsOppositeField1
     */
    totalShootsOppositeField1: string;
    /**
     * totalShootsOppositeField2
     */
    totalShootsOppositeField2: string;
    /**
     * totalShootsOppositeField3
     */
    totalShootsOppositeField3: string;
    /**
     * totalShootsOppositeField4
     */
    totalShootsOppositeField4: string;
    /**
     * totalShootsOppositeField5
     */
    totalShootsOppositeField5: string;
    /**
     * totalShootsOppositeField6
     */
    totalShootsOppositeField6: string;
    /**
     * totalShootsOppositeField7
     */
    totalShootsOppositeField7: string;
    /**
     * totalShootsOppositeField8
     */
    totalShootsOppositeField8: string;
    /**
     * totalShootsOppositeField9
     */
    totalShootsOppositeField9: string;
    /**
     * totalShootsOppositeField10
     */
    totalShootsOppositeField10: string;
    /**
     * totalShootsOppositeField11
     */
    totalShootsOppositeField11: string;
    /**
     * avgTotalShots
     */
    avgTotalShots: string;
    /**
     * avgGoalsSuperiority
     */
    avgGoalsSuperiority: string;
    /**
     * avgGoalsEquality
     */
    avgGoalsEquality: string;
    /**
     * avgPossessionsSuperiority
     */
    avgPossessionsSuperiority: string;
    /**
     * avgPossessionsEquality
     */
    avgPossessionsEquality: string;
    /**
     * avgShots7Meters
     */
    avgShots7Meters: string;
    /**
     * totalTotalShots
     */
    totalTotalShots: string;
    /**
     * totalGoalsSuperiority
     */
    totalGoalsSuperiority: string;
    /**
     * totalGoalsEquality
     */
    totalGoalsEquality: string;
    /**
     * totalPossessionsSuperiority
     */
    totalPossessionsSuperiority: string;
    /**
     * totalPossessionsEquality
     */
    totalPossessionsEquality: string;
    /**
     * totalShots7Meters
     */
    totalShots7Meters: string;
    /**
     * totalPossessionsEquality6vs6
     */
    totalPossessionsEquality6vs6: number;
    /**
     * totalGoalsEquality6vs6
     */
    totalGoalsEquality6vs6: number;
    /**
     * totalSavesEquality6vs6
     */
    totalSavesEquality6vs6: number;
    /**
     * totalLostBallsEquality6vs6
     */
    totalLostBallsEquality6vs6: number;
    /**
     * totalPostOutEquality6vs6
     */
    totalPostOutEquality6vs6: number;
    /**
     * totalShotsEquality6vs6
     */
    totalShotsEquality6vs6: number;
    /**
     * totalPossessionsEquality5p1vs6
     */
    totalPossessionsEquality5p1vs6: number;
    /**
     * totalGoalsEquality5p1vs6
     */
    totalGoalsEquality5p1vs6: number;
    /**
     * totalSavesEquality5p1vs6
     */
    totalSavesEquality5p1vs6: number;
    /**
     * totalLostBallsEquality5p1vs6
     */
    totalLostBallsEquality5p1vs6: number;
    /**
     * totalPostOutEquality5p1vs6
     */
    totalPostOutEquality5p1vs6: number;
    /**
     * totalShotsEquality5p1vs6
     */
    totalShotsEquality5p1vs6: number;
    /**
     * totalPossessionsSuperiority6vs5
     */
    totalPossessionsSuperiority6vs5: number;
    /**
     * totalGoalsSuperiority6vs5
     */
    totalGoalsSuperiority6vs5: number;
    /**
     * totalSavesSuperiority6vs5
     */
    totalSavesSuperiority6vs5: number;
    /**
     * totalLostBallsSuperiority6vs5
     */
    totalLostBallsSuperiority6vs5: number;
    /**
     * totalPostOutSuperiority6vs5
     */
    totalPostOutSuperiority6vs5: number;
    /**
     * totalShotsSuperiority6vs5
     */
    totalShotsSuperiority6vs5: number;
    /**
     * totalPossessionsSuperiority7vs6
     */
    totalPossessionsSuperiority7vs6: number;
    /**
     * totalGoalsSuperiority7vs6
     */
    totalGoalsSuperiority7vs6: number;
    /**
     * totalSavesSuperiority7vs6
     */
    totalSavesSuperiority7vs6: number;
    /**
     * totalLostBallsSuperiority7vs6
     */
    totalLostBallsSuperiority7vs6: number;
    /**
     * totalPostOutSuperiority7vs6
     */
    totalPostOutSuperiority7vs6: number;
    /**
     * totalShotsSuperiority7vs6
     */
    totalShotsSuperiority7vs6: number;
    /**
     * totalGkReceivedOffensePositional
     */
    totalGkReceivedOffensePositional: string;
    /**
     * totalGkReceivedCounterGoal
     */
    totalGkReceivedCounterGoal: string;
    /**
     * totalGkReceivedFastBreak
     */
    totalGkReceivedFastBreak: string;
    /**
     * teamId
     */
    teamId: string;
    /**
     * teamName
     */
    teamName: string;
    /**
     * totalDefenseReceivedGoals
     */
    totalDefenseReceivedGoals: string;
    /**
     * totalDefensePossessions
     */
    totalDefensePossessions: string;
    /**
     * totalOpponentFailedShots
     */
    totalOpponentFailedShots: string;
    /**
     * totalPlayedTimeOffense
     */
    totalPlayedTimeOffense: string;
    /**
     * avgPlayedTimeOffense
     */
    avgPlayedTimeOffense: string;
    /**
     * totalPlayedTimeDefense
     */
    totalPlayedTimeDefense: string;
    /**
     * avgPlayedTimeDefense
     */
    avgPlayedTimeDefense: string;
    /**
     * totalPlayedTimeOffenseInferiority
     */
    totalPlayedTimeOffenseInferiority: string;
    /**
     * avgPlayedTimeOffenseInferiority
     */
    avgPlayedTimeOffenseInferiority: string;
    /**
     * totalPlayedTimeDefenseInferiority
     */
    totalPlayedTimeDefenseInferiority: string;
    /**
     * avgPlayedTimeDefenseInferiority
     */
    avgPlayedTimeDefenseInferiority: string;
    /**
     * totalPlayedTimeOffenseSuperiority
     */
    totalPlayedTimeOffenseSuperiority: string;
    /**
     * avgPlayedTimeOffenseSuperiority
     */
    avgPlayedTimeOffenseSuperiority: string;
    /**
     * totalPlayedTimeDefenseSuperiority
     */
    totalPlayedTimeDefenseSuperiority: string;
    /**
     * avgPlayedTimeDefenseSuperiority
     */
    avgPlayedTimeDefenseSuperiority: string;
    /**
     * totalPlayedTimeOffenseEquality
     */
    totalPlayedTimeOffenseEquality: string;
    /**
     * avgPlayedTimeOffenseEquality
     */
    avgPlayedTimeOffenseEquality: string;
    /**
     * totalPlayedTimeDefenseEquality
     */
    totalPlayedTimeDefenseEquality: string;
    /**
     * avgPlayedTimeDefenseEquality
     */
    avgPlayedTimeDefenseEquality: string;
}

