import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { BehaviorSubject } from 'rxjs';
import { possessionsSubscriptionModel } from '@handballai/stats-calculation';
import { PossessionCounterModel } from '@handballai/stats-calculation';
import { PossessionCounterListModel } from '@handballai/stats-calculation';
import { CoreService } from 'src/app/shared-services/core.service';
import { PossessionConsumerCore } from '@handballai/stats-calculation';

@Injectable({
  providedIn: 'root'
})
export class PossessionsConsumerService {

  constructor(
      private readonly logger: NGXLogger,
  ) { }

  get homeTeamPossessionsCounter$(): BehaviorSubject<PossessionCounterModel> {
    return this._homeTeamPossessionsCounter$;
  }

  get visitorTeamPossessionsCounter$(): BehaviorSubject<PossessionCounterModel> {
    return this._visitorTeamPossessionsCounter$;
  }

  get selectedPlayerPossessionCounter$(): BehaviorSubject<PossessionCounterListModel[]> {
    return this._selectedPlayerPossessionCounter$;
  }

  get teamCounterMap(): Map<TeamMarker, PossessionCounterModel> {
    return this._possessionCounterCore.teamCounterMap;
  }

  get playerCounterMap(): Map<number, PossessionCounterModel> {
    return this._possessionCounterCore.playerCounterMap;
  }

  private _core: CoreService;

  private _homeTeamPossessionsCounter$ = new BehaviorSubject<PossessionCounterModel>(null);
  private _visitorTeamPossessionsCounter$ = new BehaviorSubject<PossessionCounterModel>(null);
  private _selectedPlayerPossessionCounter$ = new BehaviorSubject<PossessionCounterListModel[]>([]);
  private _allPlayerPossessionsCounter$ = new BehaviorSubject<PossessionCounterListModel[]>([]);
  private _possessionCounterCore: PossessionConsumerCore;
  initCore(core: CoreService) {
    this._core = core;
    this.subscribeToPlayByPlayEvent();
  }

  public init(): void {
    this.logger.debug('PossessionsConsumerService.init called');
    this._possessionCounterCore = new PossessionConsumerCore(
        [...this._core.gameService.gameModel.home.currentField, ...this._core.gameService.gameModel.home?.currentBench],
        [...this._core.gameService.gameModel.visitor.currentField, ...this._core.gameService.gameModel.visitor?.currentBench],
    );

    this.updatePlayerPossessionsCounter();
    this._homeTeamPossessionsCounter$.next(null);
    this._visitorTeamPossessionsCounter$.next(null);
  }

  public selectPlayerPossessions(playerId: number): void {
    const possCounter = this._possessionCounterCore.playerCounterMap.get(playerId);
    this._selectedPlayerPossessionCounter$.next([
      {
        playerId: playerId,
        possessionCounter: possCounter
      } as PossessionCounterListModel
    ]);
  }

  public deSelectPlayerPossessions(): void {
    this._selectedPlayerPossessionCounter$.next([]);
  }

  private subscribeToPlayByPlayEvent(): void {
    this._core.playByPlayProducerService.on(possessionsSubscriptionModel, pbp => {
      this.logger.debug('PossessionsConsumerService.allEventSubscription: ', pbp);

      this._possessionCounterCore.addPossessions(
          pbp.teamMarker,
          pbp.possessionId,
          pbp.offense,
          pbp.defense,
          pbp.phase,
          pbp.offenseSystem,
          this._core.teamOverviewSubConsumerService
      );
      this.logger.debug('Possession Player Counter Map', this._possessionCounterCore.playerCounterMap);
      this._homeTeamPossessionsCounter$.next(this._possessionCounterCore.teamCounterMap.get('HOME'));
      this._visitorTeamPossessionsCounter$.next(this._possessionCounterCore.teamCounterMap.get('VISITOR'));
      this.updatePlayerPossessionsCounter();
    });
  }

  private updatePlayerPossessionsCounter(): void {
    this._allPlayerPossessionsCounter$.next(Array.from(this._possessionCounterCore.playerCounterMap.entries()).map((entry) => ({
      playerId: entry[0],
      possessionCounter: entry[1]
    } as PossessionCounterListModel)));
  }
}
