import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdvancedStatPlayerModel } from '@handballai/stats-calculation';
import { GamePlayerModel } from 'src/app/shared-services/model/game.model';
import { CoreService } from '../../core.service';

const mapGamePlayer = (team: string, player: GamePlayerModel): AdvancedStatPlayerModel => ({
  id: player.id,
  name: player.name,
  backNumber: player.backNumber,
  position: player.position,
  teamName: team
});
@Injectable({
  providedIn: 'root'
})
export class GameSeedService {

  constructor() { }

  get advancedStatsPlayerModel$(): BehaviorSubject<AdvancedStatPlayerModel[]> {
    return this._advancedStatsPlayerModel$;
  }
  private _core: CoreService;

  private _advancedStatsPlayerModel$ =  new BehaviorSubject<AdvancedStatPlayerModel[]>([]);
  initCore(core: CoreService) {
      this._core = core;
  }

  public init(
      homeTeamName: string,
      visitorTeamName: string,
      homeGamePlayers: GamePlayerModel[],
      visitorGamePlayers: GamePlayerModel[]
  ): void {
      this._advancedStatsPlayerModel$.next([
          ...homeGamePlayers.map(pl => mapGamePlayer(homeTeamName, pl)),
          ...visitorGamePlayers.map(pl => mapGamePlayer(visitorTeamName, pl))
          ]);
  }
}
