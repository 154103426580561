import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CounterModel, QuickStatsColumnModel } from '@handballai/stats-calculation';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';
import { PlayerStatsAggregateDto } from 'src/app/api/hai-api';
import { CoreService } from 'src/app/shared-services/core.service';
import { GameSystemStatsViewModel } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-view.model';
import { createPdf, ExtraPlayerData } from '../../pages/aehandler-module/pages/dashboard-module/components/individual-performance/individual-pdf.helper';
import {
  eventTableLeftMap,
  eventTableRightMap
} from '../../pages/aehandler-module/pages/dashboard-module/dashboard-handler';
import { IndividualLastGameModel, GkLastGameModel } from '../../pages/aehandler-module/pages/dashboard-module/dashboard.model';
import { transformGoalConnectionCounter } from '../../pages/aehandler-module/pages/dashboard-module/goal-connection-helper';
import { transformAvgPlayerQuickStats, transformTotalPlayerQuickStats, transformPlayerShotStatistic, transformPlayerGoalByPhaseStats, transformGkSavesTotalStatistic, transformGkSavesAvgStatistics, transformFieldPlayerLastGamesStats, transformGkLastGamesStats } from '../../pages/aehandler-module/pages/dashboard-module/individual-quick-stats.helper';
import { gameSystemColumns, transformPlayByPlayToViewModel } from '../../pages/aehandler-module/pages/dashboard-module/team-quick-stats.helper';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { toUpperCase } from '../../pages/aehandler-module/pages/game-module/components/pdf-report/pdf-report.component';
import { IonicSelectableComponent } from 'ionic-selectable';
import { CsvDownloadService } from '../../../shared-services/download/csv-download.service';
import { secondsToTime, timeToSeconds } from 'src/app/shared-services/helper/timer-format.helper';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  DoughnutController
} from 'chart.js';

Chart.register(
    ArcElement,
    Tooltip,
    Legend,
    Title,
    ChartDataLabels,
    DoughnutController
);


export interface DashIndividualConf {
  mode: 'COMPLETE_MODE'|'LITE_MODE';
  aeOverride?: number;
}

export interface IndividualPlayerPdfInfo {
  title: string;
  author: string;
  subject: string;
}

@Component({
    selector: 'app-dash-individual-stats',
    templateUrl: './dash-individual-stats.component.html',
    styleUrls: ['./dash-individual-stats.component.scss'],
    standalone: false
})
export class DashIndividualStatsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('doughnut1Canvas', { static: false }) doughnut1Canvas: any;
  @ViewChild('doughnut2Canvas', { static: false }) doughnut2Canvas: any;
  @ViewChild('doughnut1CanvasPDF', { static: false }) doughnut1CanvasPDF: any;
  @ViewChild('doughnut2CanvasPDF', { static: false }) doughnut2CanvasPDF: any;
  doughnut1Chart: any;
  doughnut2Chart: any;
  doughnut1ChartPDF: any;
  doughnut2ChartPDF: any;

  @ViewChild('doughnut1GkCanvas', { static: false }) doughnut1GkCanvas: any;
  @ViewChild('doughnut2GkCanvas', { static: false }) doughnut2GkCanvas: any;
  doughnut1GkChart: any;
  doughnut2GkChart: any;

  confData: DashIndividualConf;
  @Input() printData: any;
  @Input() set conf(value: DashIndividualConf) {
    if (!value) return;
    this.confData = value;
    // TODO: Maybe we should move here service method calls to get new subject on every conf change
  }
  @Input() set printConf(value: any) {
    if (!value) return;
    this.printPdf(value);
  }

  @ViewChild('itemTemplate', { static: true }) itemTemplate!: TemplateRef<any>;
  @ViewChild('valueTemplate', { static: true }) valueTemplate!: TemplateRef<any>;
  @ViewChild('footerTemplate', { static: true }) footerTemplate!: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: true }) headerTemplate!: TemplateRef<any>;

  public printPdf(value) {
    if (
        this.individualAggregatedTbl3$Last == undefined ||
        this.individualAggregatedTbl4$Last == undefined ||
        this.lastGames$Last == undefined ||
        this.gkLastGames$Last == undefined ||
        this.totalQuickStats$Last == undefined ||
        this.totalQuickStats$Last == undefined ||
        this.total7MetersGoals$Last == undefined ||
        this.total7MetersFailedShoots$Last == undefined ||
        this.avgQuickStats$Last == undefined ||
        this.avg7MetersGoals$Last == undefined ||
        this.avg7MetersFailedShoots$Last == undefined
    ) return;

    this.overviewAccordionOpen = true;

    const printOptions = {
      individualAggregatedTbl3: (this.individualAggregatedTbl3$Last),
      individualAggregatedTbl4: (this.individualAggregatedTbl4$Last),
      lastGames: (this.lastGames$Last),
      gkLastGames: (this.gkLastGames$Last),
      totalQuickStats: (this.totalQuickStats$Last),
      quickStats: {
        totalQuickStats: (this.totalQuickStats$Last) || null,
        total7MetersGoals:  (this.total7MetersGoals$Last) || 0,
        total7MetersFailedShoots:  (this.total7MetersFailedShoots$Last) || 0,
        avgQuickStats:  (this.avgQuickStats$Last) || null,
        avg7MetersGoals:  (this.avg7MetersGoals$Last) || 0,
        avg7MetersFailedShoots:  (this.avg7MetersFailedShoots$Last) || 0
      },
      selectedGames: value['selectedGames'] || [],
      gameSystemTbl: this.gameSystemStatsViewData || [],
      goalkeeperReport: value.goalkeeperReport
    };

    const {playerImageURL, teamName} = value;

    createPdf(
        printOptions,
        this.loadingCtrl,
        this.translateService,
        value.selectedPlayerPosition,
        this.datePipe,
        value.entityFilterDtoMap,
        value.selectedPlayer,
        this.modalCtrl,
        this.core,
        {
          ...this.extraPlayerData,
          playerImageURL,
          teamName
        }
    );
  }

  @ViewChild('selectableColumns', { static: false }) selectableColumns: IonicSelectableComponent;

  tg = (def: string, skipLangs: string[] = []) => {
    return this.core.i18nService.tg(def, skipLangs);
  };
  individualPlayerPdfInfo: IndividualPlayerPdfInfo;
  extraPlayerData: ExtraPlayerData;
  availablePositions = [
    'CONNECT_G_LEFT_WING:CONNECT_S_LEFT_WING',
    'CONNECT_G_RIGHT_WING:CONNECT_S_RIGHT_WING',
    'CONNECT_G_CENTER_6M:CONNECT_S_CENTER_6M',
    'CONNECT_G_LEFT_6M:CONNECT_S_LEFT_6M',
    'CONNECT_G_RIGHT_6M:CONNECT_S_RIGHT_6M',
    'CONNECT_G_CENTER_9M:CONNECT_S_CENTER_9M',
    'CONNECT_G_LEFT_9M:CONNECT_S_LEFT_9M',
    'CONNECT_G_RIGHT_9M:CONNECT_S_RIGHT_9M',
    'CONNECT_G_7_METERS:CONNECT_S_7_METERS',
    'CONNECT_G_OPPOSITE_FIELD:CONNECT_S_OPPOSITE_FIELD'
  ];

  individualAggregated$: BehaviorSubject<PlayerStatsAggregateDto>;
  goalConnection$: Observable<CounterModel[]>;
  playerShotStats$: Observable<number[]>;
  goalsPerPhaseStats$: Observable<number[]>;
  gkSaveStatsTotal$: Observable<number[]>;
  gkSaveStatsAvg$: Observable<number[]>

  lastGames$ = new BehaviorSubject<IndividualLastGameModel[]>([]);
  lastGames$Last: IndividualLastGameModel[];
  gkLastGames$ = new BehaviorSubject<GkLastGameModel[]>([]);
  gkLastGames$Last: GkLastGameModel[];
  individualAggregatedTbl3$ = new BehaviorSubject<any>([]);
  individualAggregatedTbl3$Last: any;
  individualAggregatedTbl4$ = new BehaviorSubject<any>([]);
  individualAggregatedTbl4$Last: any;
  totalQuickStats$: Observable<QuickStatsColumnModel>;
  totalQuickStats$Last: QuickStatsColumnModel;
  avgQuickStats$: Observable<QuickStatsColumnModel>;
  avgQuickStats$Last: QuickStatsColumnModel;
  total7MetersGoals$: Observable<number>;
  total7MetersGoals$Last: number;
  total7MetersFailedShoots$: Observable<number>;
  total7MetersFailedShoots$Last: number;
  avg7MetersGoals$: Observable<number>;
  avg7MetersGoals$Last: number;
  avg7MetersFailedShoots$: Observable<number>;
  avg7MetersFailedShoots$Last: number;

  gameSystemStatsView = new BehaviorSubject<GameSystemStatsViewModel[]>([]);
  public gameSystemColumns = [];
  public selectedPlayerPosition;

  gameSystemStatsViewData;

  public overviewAccordionOpen = true;
  public indEventsAccordionOpen = false;
  public lastGamesAccordionOpen = false;
  public gameSystemAccordionOpen = false;


  public chartOptionsPDF: any = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: 'GOALS / FAILED SHOTS %',
        font: {
          size: 14
        },
        color: '#0e375f'
      },
      legend: {
        display: false
      },
      datalabels: {
        color: '#fff',
        font: {
          weight: 'bold',
          size: 48
        },
        clamp: true,
        formatter: (value: number, context: any) => {
          const dataset = context.dataset;
          const total = dataset.data.reduce((acc: number, data: number) => acc + data, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value} [${percentage}%]`;
        },
        anchor: 'center',
        align: 'center',
        offset: 0
      }
    }
  };

  public chartOptions2PDF: any = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: 'GOALS PER PHASE %',
        font: {
          size: 14
        },
        color: '#0e375f'
      },
      legend: {
        display: false
      },
      datalabels: {
        color: '#fff',
        font: {
          weight: 'bold',
          size: 48
        },
        clamp: true,
        formatter: (value: number, context: any) => {
          const dataset = context.dataset;
          const total = dataset.data.reduce((acc: number, data: number) => acc + data, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value} [${percentage}%]`;
        },
        anchor: 'center',
        align: 'center',
        offset: 0
      }
    }
  };


  d1ChartOptions: any = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'GOALS / FAILED SHOTS %',
        font: {
          size: 20,
        },
        color: '#0e375f'
      },
      datalabels: {
        color: '#0e375f',
        display: true,
        font: {
          size: 14
        },
        formatter: (value: number, context: any) => {
          const dataset = context.dataset;
          const total = dataset.data.reduce((acc: number, data: number) => acc + data, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value} [${percentage}%]`;
        },
        anchor: 'center',
        align: 'center',
        offset: 0
      },
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw;
            const percentage = ((value / context.dataset.data.reduce((a: number, b: number) => a + b, 0)) * 100).toFixed(1);
            return `${value} [${percentage}%]`;
          }
        }
      }
    }
  };

  d2ChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'GOALS PER PHASE %',
        font: {
          size: 20,
        },
        color: '#0e375f'
      },
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw;
            const percentage = ((value / context.dataset.data.reduce((a: number, b: number) => a + b, 0)) * 100).toFixed(1);
            return `${value} [${percentage}%]`;
          }
        }
      },
      datalabels: {
        color: '#0e375f',
        font: {
          size: 14
        },
        formatter: (value: number, context: any) => {
          const dataset = context.dataset;
          const total = dataset.data.reduce((acc: number, data: number) => acc + data, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value} [${percentage}%]`;
        }
      }
    }
  };

  public chartOptionsGk: any = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'SAVES / FAILED SHOTS %',
        font: {
          size: 20
        },
        color: '#0e375f'
      },
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw;
            const percentage = ((value / context.dataset.data.reduce((a: number, b: number) => a + b, 0)) * 100).toFixed(1);
            return `${context.label}: ${value} [${percentage}%]`;
          }
        }
      },
      datalabels: {
        color: '#0e375f',
        font: {
          size: 14
        },
        formatter: (value: number, context: any) => {
          const dataset = context.dataset;
          const total = dataset.data.reduce((acc: number, data: number) => acc + data, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value} [${percentage}%]`;
        }
      }
    }
  };

  public chartOptionsGk2: any = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'SAVES PER PHASE %',
        font: {
          size: 20
        },
        color: '#0e375f'
      },
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw;
            const total = context.dataset.data.reduce((a: number, b: number) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(0);
            return `${context.label}: ${value} [${percentage}%]`;
          }
        }
      },
      datalabels: {
        color: '#0e375f',
        font: {
          size: 14
        },
        formatter: (value: number, context: any) => {
          const dataset = context.dataset;
          const total = dataset.data.reduce((acc: number, data: number) => acc + data, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value} [${percentage}%]`;
        }
      }
    }
  };

  public lastGamesTableAllColsShown = false;
  public gameSystemTableAllColsShown = false;

  public circleColors: any[] = [{ backgroundColor: ['#2EB67D', '#ECB22E' ] }];
  public circleColors2: any[] = [{ backgroundColor: ['#2EB67D', '#ECB22E', '#B67D2E' ] }];

  public circleColorsPDF: any[] = [{ backgroundColor: ['#36ABE0', '#0E375F'] }];
  public circleColors2PDF: any[] = [{ backgroundColor: ['#36ABE0', '#0E375F', '#CBE6F5'] }];

  public totalAvg = [];
  public lastGamesColumnsSorted = [];
  public lastGamesColumns = [
    {
      name: 'Game Date',
      prop: 'gameDate',
      width: 80,
      headerClass: 'column-left'
    },
    {
      name: 'Teams Name',
      prop: 'teamsName',
      width: 160,
      headerClass: 'column-left'
    },
    {
      name: 'Time Played hh:mm:ss',
      prop: 'timePlayed',
      headerClass: 'center-column'
    },
    {
      name: 'Played Poses',
      prop: 'playedPoses',
    },
    {
      name: 'Possession Events',
      prop: 'possessionEvents',
    },
    {
      name: 'Goals',
      prop: 'goals',
    },
    {
      name: 'Assists',
      prop: 'assists',
    },
    {
      name: 'Failed Shots',
      prop: 'failedShots',
    },
    {
      name: 'Post Outs',
      prop: 'postOuts',
    },
    {
      name: 'Lost Balls',
      prop: 'lostBalls',
    },
    {
      name: 'Lost Balls Perc',
      prop: 'lostBallsPerc',
    },
    {
      name: 'Eff',
      prop: 'eff',
    },
    {
      name: 'Shoot Eff',
      prop: 'shootEff',
    },
    {
      name: 'Player Score',
      prop: 'playerScore',
    },
    {
      name: 'Link to the Game',
      prop: 'link',
      width: 80
    },
    {
      name: 'Lost Balls Equality',
      prop: 'lostBallsEquality',
    },
    {
      name: 'Lost Balls Superiority',
      prop: 'lostBallsSuperiority',
    },
    {
      name: 'Post Out Offense Positional',
      prop: 'postOutOffensePositional',
    },
    {
      name: 'Post Out Fast Break',
      prop: 'postOutFastBreak',
    },
    {
      name: 'Post Out Counter Goal',
      prop: 'postOutCounterGoal',
    },
    {
      name: 'Post Out Equality',
      prop: 'postOutEquality',
    },
    {
      name: 'Post Out Superiority',
      prop: 'postOutSuperiority',
    },
    {
      name: 'Failed Shots Equality',
      prop: 'failedShotsEquality',
    },
    {
      name: 'Played Time Offense',
      prop: 'playedTimeOffense',
    },
    {
      name: 'Played Time Defense',
      prop: 'playedTimeDefense',
    },
    {
      name: 'PlayerId',
      prop: 'playerId',
    },
    {
      name: 'Player Name',
      prop: 'playerName',
    },
    {
      name: 'Player Back Number',
      prop: 'playerBackNumber',
    },
    {
      name: 'Team Id',
      prop: 'teamId',
    },
    {
      name: 'Team Name',
      prop: 'teamName',
    },
    {
      name: 'Lost Balls Offense Positional',
      prop: 'lostBallsOffensePositional',
    },
    {
      name: 'Lost Balls Fast Break',
      prop: 'lostBallsFastBreak',
    },
    {
      name: 'Lost Balls Counter Goal',
      prop: 'lostBallsCounterGoal',
    },
    {
      name: 'Attack Fault Provoke',
      prop: 'attackFaultProvoke',
    },
    {
      name: 'Attack Fault Commit',
      prop: 'attackFaultCommit',
    },
    {
      name: 'Technical Mistake Provoke',
      prop: 'technicalMistakeProvoke',
    },
    {
      name: 'Technical Mistake Commit',
      prop: 'technicalMistakeCommit',
    },
    {
      name: 'Lost Ball Provoke',
      prop: 'lostBallProvoke',
    },
    {
      name: 'Lost Ball',
      prop: 'lostBall',
    },
    {
      name: 'Two Min Commit',
      prop: 'twoMinCommit',
    },
    {
      name: 'Two Min Provoke',
      prop: 'twoMinProvoke',
    },
    {
      name: 'Seven Meters Commit',
      prop: 'sevenMetersCommit',
    },
    {
      name: 'Seven Meters Provoke',
      prop: 'sevenMetersProvoke',
    },
    {
      name: 'Seven M Susp Commit',
      prop: 'sevenMSuspCommit',
    },
    {
      name: 'Seven M Susp Provoke',
      prop: 'sevenMSuspProvoke',
    },
    {
      name: 'Foul Commit',
      prop: 'foulCommit',
    },
    {
      name: 'Foul Receive',
      prop: 'foulReceive',
    },
    {
      name: 'One And One Lost',
      prop: 'oneAndOneLost',
    },
    {
      name: 'One And One Won',
      prop: 'oneAndOneWon',
    },
    {
      name: 'Block',
      prop: 'block',
    },
    {
      name: 'Shot Blocked',
      prop: 'shotBlocked',
    },
    {
      name: 'Efficiency',
      prop: 'efficiency',
    },
    {
      name: 'Shot Efficiency',
      prop: 'shotEfficiency',
    },
    {
      name: 'Lost Ball Perc',
      prop: 'lostBallPerc',
    },
    {
      name: 'Goals Offense Positional',
      prop: 'goalsOffensePositional',
    },
    {
      name: 'Total Shots',
      prop: 'totalShots',
    },
    {
      name: 'Shots 7 Meters',
      prop: 'shots7Meters',
    },
    {
      name: 'Gk Post Out',
      prop: 'gkPostOut',
    },
    {
      name: 'Defense Poss Offense Positional',
      prop: 'defensePossOffensePositional',
    },
    {
      name: 'Defense Poss Fast Break',
      prop: 'defensePossFastBreak',
    },
    {
      name: 'Defense Poss Counter Goal',
      prop: 'defensePossCounterGoal',
    },
    {
      name: 'Defense Received Goals Offense Positional',
      prop: 'defenseReceivedGoalsOffensePositional',
    },
    {
      name: 'Defense Received Goals Fast Break',
      prop: 'defenseReceivedGoalsFastBreak',
    },
    {
      name: 'Defense Received Goals Counter Goal',
      prop: 'defenseReceivedGoalsCounterGoal',
    },
    {
      name: 'Played Time Offense Inferiority',
      prop: 'playedTimeOffenseInferiority',
    },
    {
      name: 'Played Time Defense Inferiority',
      prop: 'playedTimeDefenseInferiority',
    },
    {
      name: 'Played Time Offense Superiority',
      prop: 'playedTimeOffenseSuperiority',
    },
    {
      name: 'Played Time Defense Superiority',
      prop: 'playedTimeDefenseSuperiority',
    },
    {
      name: 'Played Time Offense Equality',
      prop: 'playedTimeOffenseEquality',
    },
    {
      name: 'Played Time Defense Equality',
      prop: 'playedTimeDefenseEquality',
    }
  ];

  public gkColumns = [
    {
      name: 'Game Date',
      prop: 'gameDate',
      width: 80,
      headerClass: 'column-left'
    },
    {
      name: 'Teams Name',
      prop: 'teamsName',
      width: 160,
      headerClass: 'column-left'
    },
    {
      name: 'Time Played hh:mm:ss',
      prop: 'timePlayed',
      headerClass: 'center-column'
    },
    {
      name: 'Played Poses',
      prop: 'playedPoses',
    },
    {
      name: 'Possession Events',
      prop: 'possessionEvents',
    },
    {
      name: 'Goals',
      prop: 'goals',
    },
    {
      name: 'Goals Received',
      prop: 'goalsReceived',
    },
    {
      name: 'Saves',
      prop: 'saves',
    },
    {
      name: 'Save Eff',
      prop: 'saveEff',
    },
    {
      name: 'Lost Balls',
      prop: 'lostBalls',
    },
    {
      name: 'Player Score',
      prop: 'playerScore',
    },
    {
      name: 'Gk Saves 7 Meters',
      prop: 'gkSaves7Meters',
    },
    {
      name: 'Gk Received Goals 7 Meters',
      prop: 'gkReceivedGoals7Meters',
    },
    {
      name: 'Link to the Game',
      prop: 'link',
      width: 80
    }
  ];
  public selectedLastGamesColumns = [];

  @HostListener('window:resize', ['$event'])
  onResize() {
    setTimeout(() => {
      if (this.core.isMobileDevice) {
        this.selectedLastGamesColumns = this.printData?.selectedPlayerPosition === 'gk' ? [...this.gkColumns.slice(0, 4)] : [...this.lastGamesColumns.slice(0, 4)];
        this.lastGamesTableAllColsShown = false;
      } else {
        this.selectedLastGamesColumns = this.printData?.selectedPlayerPosition === 'gk' ? [...this.gkColumns] : [...this.lastGamesColumns.slice(0, 15)];
      }
    }, 100);
  }

  subscriptions: Subscription[] = [];
  constructor(
    public readonly core: CoreService,
    private readonly loadingCtrl: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly datePipe: DatePipe,
    private readonly translateService: TranslateService,
    private csvDownloadService: CsvDownloadService,
    public cdr: ChangeDetectorRef
  ) {
    core.dashboardsService.resetAll();
    const copyColumns = JSON.parse(JSON.stringify(this.lastGamesColumns));
    this.lastGamesColumnsSorted = [...copyColumns.sort((a, b) => a.prop.localeCompare(b.prop))];
    if (this.core.isMobileDevice) {
      this.selectedLastGamesColumns = [...this.lastGamesColumns.slice(0, 4)];
      this.lastGamesTableAllColsShown = false;
    } else {
      this.selectedLastGamesColumns = [...this.lastGamesColumns.slice(0, 15)];
    }
    this.individualAggregated$ = core.dashboardsService.individualAggregated$;
    this.goalConnection$ = core.dashboardsService.individualAggregated$.pipe(map(dto => transformGoalConnectionCounter(dto)));
    this.avgQuickStats$ = core.dashboardsService.individualAggregated$.pipe(map(
        dto => transformAvgPlayerQuickStats(dto, this.selectedType === 'LITE_MODE')
    ));
    this.totalQuickStats$ = core.dashboardsService.individualAggregated$.pipe(map(
        dto => transformTotalPlayerQuickStats(dto, this.selectedType === 'LITE_MODE')
    ));
    this.total7MetersGoals$ = core.dashboardsService.individualAggregated$.pipe(map(dto => +dto?.totalGoals7Meters));
    this.total7MetersFailedShoots$ = core.dashboardsService.individualAggregated$.pipe(map(
        dto => +dto?.totalShots7Meters - +dto?.totalGoals7Meters
    ));
    this.avg7MetersGoals$ = core.dashboardsService.individualAggregated$.pipe(map(dto => +dto?.avgGoals7Meters));
    this.avg7MetersFailedShoots$ = core.dashboardsService.individualAggregated$.pipe(map(
        dto => +dto?.avgShots7Meters - +dto?.avgGoals7Meters
    ));

    this.playerShotStats$ = core.dashboardsService.individualAggregated$.pipe(map( dto => transformPlayerShotStatistic(dto)));
    this.goalsPerPhaseStats$ = core.dashboardsService.individualAggregated$.pipe(map(dto => transformPlayerGoalByPhaseStats(dto)));
    this.gkSaveStatsTotal$ = core.dashboardsService.individualAggregated$.pipe(map( dto => transformGkSavesTotalStatistic(dto)));
    this.gkSaveStatsAvg$ = core.dashboardsService.individualAggregated$.pipe(map(dto => transformGkSavesAvgStatistics(dto)));
    core.dashboardsService.individualAggregated$.pipe(concatMap(i => eventTableLeftMap(i, this.tg))).subscribe(res => this.individualAggregatedTbl3$.next(res));
    core.dashboardsService.individualAggregated$.pipe(concatMap(i => eventTableRightMap(i, this.tg))).subscribe(res => this.individualAggregatedTbl4$.next(res));

    this.core.dashboardsService.individual$.pipe(
        map(individual => {
          const transformedData = individual
              .map(itm =>
                  transformFieldPlayerLastGamesStats(
                      this.selectedType,
                      itm,
                      (this.confData?.aeOverride ?? this.core.accountEquipoService.selectedAccountEquipo$.getValue().id)
                  )
              )
              .sort((a, b) =>
                  DateTime.fromISO(b.gameDate).toMillis() - DateTime.fromISO(a.gameDate).toMillis()
              );

          const avgKeys = [];
          const totals: IndividualLastGameModel = transformedData.reduce((acc, row, index, array) => {
            Object.keys(row).forEach(key => {
              if (typeof row[key] === 'number' && !['playerId', 'playerBackNumber', 'teamId'].includes(key)) {
                acc[key] = (acc[key] || 0) + row[key];
              } else if (key === 'timePlayed' || key.includes('playedTime')) {
                const timeInSeconds = timeToSeconds(row[key]);
                acc[key] = ((Number(acc[key]) || 0) + timeInSeconds).toString();
              } else if (typeof row[key] === 'string' && row[key].includes('%')) {
                const percentageValue = parseFloat(row[key].replace('%', '').trim());
                acc[key] = ((acc[key] || 0) * index + percentageValue) / (index + 1);
                avgKeys.push(key);
              } else {
                acc[key] = acc[key] || '-';
              }
            });
            return acc;
          }, {} as IndividualLastGameModel);

          Object.keys(totals).forEach(key => {
            if (key === 'timePlayed' || key.includes('playedTime')) {
              totals[key] = secondsToTime(Number(totals[key]));
            } else if (avgKeys.includes(key)) {
              totals[key] = `${totals[key].toFixed(1)} %`;
            }
          });

          const totalRow = {
            ...totals,
            gameDate: 'Total',
          };

          return !transformedData.length ? [] : [...transformedData, totalRow];
        })
    ).subscribe(res => this.lastGames$.next(res));

    core.dashboardsService.individual$.pipe(
        map(individual => {
          const transformedData = individual
              .map(
                  itm => transformGkLastGamesStats(this.selectedType, itm, (this.confData?.aeOverride ?? this.core.accountEquipoService.selectedAccountEquipo$.getValue().id))
              )
              .sort((a, b) =>
                  DateTime.fromISO(b.gameDate).toMillis() - DateTime.fromISO(a.gameDate).toMillis());

          const avgKeys = [];
          const totals: GkLastGameModel = transformedData.reduce((acc, row, index, array) => {
            Object.keys(row).forEach(key => {
              if (typeof row[key] === 'number') {
                acc[key] = (acc[key] || 0) + row[key];
              } else if (key === 'timePlayed') {
                const timeInSeconds = timeToSeconds(row[key]);
                acc[key] = ((Number(acc[key]) || 0) + timeInSeconds).toString();
              } else if (typeof row[key] === 'string' && row[key].includes('%')) {
                const percentageValue = parseFloat(row[key].replace('%', '').trim());
                acc[key] = ((acc[key] || 0) * index + percentageValue) / (index + 1);
                avgKeys.push(key);
              } else {
                acc[key] = acc[key] || '-';
              }
            });
            return acc;
          }, {} as GkLastGameModel);

          Object.keys(totals).forEach(key => {
            if (key === 'timePlayed' || key.includes('playedTime')) {
              totals[key] = secondsToTime(Number(totals[key]));
            } else if (avgKeys.includes(key)) {
              totals[key] = `${totals[key].toFixed(1)} %`;
            }
          });

          const totalRow = {
            ...totals,
            gameDate: 'Total',
          };

          return !transformedData.length ? [] : [...transformedData, totalRow];
        })
    ).subscribe(res => this.gkLastGames$.next(res));
    core.dashboardsService.filteredPlayByPlayByPlayer$.pipe(map(itm => {
      const transformedData = transformPlayByPlayToViewModel(itm);

      const totals: GameSystemStatsViewModel = transformedData.reduce((acc, row, index) => {
        Object.keys(row).forEach(key => {
          if (typeof row[key] === 'number' && !key.includes('percentage')) {
            acc[key] = (acc[key] || 0) + row[key];
          } else if (key.includes('percentage')) {
            const percentageValue = row[key];
            acc[key] = ((acc[key] || 0) * index + percentageValue) / (index + 1);
          } else {
            acc[key] = acc[key] || '-';
          }
        });
        return acc;
      }, {} as GameSystemStatsViewModel);

      const totalRow = {
        ...totals,
        categoryName: 'Total'
      };

      return !transformedData.length ? [] : [...transformedData, totalRow];
    })).subscribe(res => this.gameSystemStatsView.next(res));

    this.subscriptions = [
      this.lastGames$.subscribe(d => this.lastGames$Last=d),
      this.gkLastGames$.subscribe(d => this.gkLastGames$Last=d),
      this.individualAggregatedTbl3$.subscribe(d => this.individualAggregatedTbl3$Last=d),
      this.individualAggregatedTbl4$.subscribe(d => this.individualAggregatedTbl4$Last=d),
      this.totalQuickStats$.subscribe(d => this.totalQuickStats$Last=d),
      this.avgQuickStats$.subscribe(d => this.avgQuickStats$Last=d),
      this.total7MetersGoals$.subscribe(d => this.total7MetersGoals$Last=d),
      this.total7MetersFailedShoots$.subscribe(d => this.total7MetersFailedShoots$Last=d),
      this.avg7MetersGoals$.subscribe(d => this.avg7MetersGoals$Last=d),
      this.avg7MetersFailedShoots$.subscribe(d => this.avg7MetersFailedShoots$Last=d),
      this.individualAggregated$.subscribe(data => {
        if (data) {
          this.selectedPlayerPosition = data.playerPosition;
          const copyColumns = JSON.parse(JSON.stringify(this.selectedPlayerPosition !== 'gk' ? this.lastGamesColumns : this.gkColumns));
          this.lastGamesColumnsSorted = [...copyColumns.sort((a, b) => a.prop.localeCompare(b.prop))];
          if (this.core.isMobileDevice) {
            this.selectedLastGamesColumns = this.printData?.selectedPlayerPosition === 'gk' ? [...this.gkColumns.slice(0, 4)] : [...this.lastGamesColumns.slice(0, 4)];
            this.lastGamesTableAllColsShown = false;
          } else {
            this.selectedLastGamesColumns = this.selectedPlayerPosition === 'gk' ? [...this.gkColumns] :  [...this.lastGamesColumns.slice(0, 15)];
          }
        }
      }),
      this.gameSystemStatsView.subscribe(d => this.gameSystemStatsViewData = d),
    ];
  }

  async createD2PDFChart(data: number[]) {
    if (!this.doughnut2CanvasPDF) return;
    const ctx = this.doughnut2CanvasPDF.nativeElement.getContext('2d');

    if (this.doughnut2ChartPDF) {
      this.doughnut2ChartPDF.destroy();
    }

    this.doughnut2ChartPDF = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: [await this.tg(_('Offense Positional')), await this.tg(_('Fastbreak')), await this.tg(_('Counter Goal'))],
        datasets: [{
          data: data,
          backgroundColor: this.circleColors2PDF[0].backgroundColor,
          borderColor: this.circleColors2PDF[0].borderColor,
          borderWidth: 1
        }]
      },
      options: this.chartOptions2PDF
    });
  }

  async createD1PDFChart(data: number[]) {
    if (!this.doughnut1CanvasPDF) return;
    const ctx = this.doughnut1CanvasPDF.nativeElement.getContext('2d');

    if (this.doughnut1ChartPDF) {
      this.doughnut1ChartPDF.destroy();
    }

    this.doughnut1ChartPDF = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: [await this.tg(_('GOALS')), await this.tg(_('FAILED SHOTS'))],
        datasets: [{
          data: data,
          backgroundColor: this.circleColorsPDF[0].backgroundColor,
          borderColor: this.circleColorsPDF[0].borderColor,
          borderWidth: 1
        }]
      },
      options: this.chartOptionsPDF
    });
  }

  async createD2Chart(data: number[]) {
    if (!this.doughnut2Canvas) return;
    const ctx = this.doughnut2Canvas.nativeElement.getContext('2d');

    if (this.doughnut2Chart) {
      this.doughnut2Chart.destroy();
    }

    this.doughnut2Chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: [await this.tg(_('Offense Positional')), await this.tg(_('Fastbreak')), await this.tg(_('Counter Goal'))],
        datasets: [{
          data: data,
          backgroundColor: this.circleColors2[0].backgroundColor,
          borderColor: this.circleColors2[0].borderColor,
          borderWidth: 1
        }]
      },
      options: this.d2ChartOptions
    });
  }

  async createD1Chart(data: number[]) {
    if (!this.doughnut1Canvas) return;
    const ctx = this.doughnut1Canvas.nativeElement.getContext('2d');

    if (this.doughnut1Chart) {
      this.doughnut1Chart.destroy();
    }

    this.doughnut1Chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: [await this.tg(_('GOALS')), await this.tg(_('FAILED SHOTS'))],
        datasets: [{
          data: data,
          backgroundColor: this.circleColors[0].backgroundColor,
          borderColor: this.circleColors[0].borderColor,
          borderWidth: 1
        }]
      },
      options: this.d1ChartOptions
    });
  }

  createD2GkChart(data: number[]) {
    if (!this.doughnut2GkCanvas) return;
    const ctx = this.doughnut2GkCanvas.nativeElement.getContext('2d');

    if (this.doughnut2GkChart) {
      this.doughnut2GkChart.destroy();
    }

    this.doughnut2GkChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Offense Positional', 'Fastbreak', 'Counter Goal'],
        datasets: [{
          data: data,
          backgroundColor: this.circleColors[0].backgroundColor,
          borderColor: this.circleColors[0].borderColor,
          borderWidth: 1
        }]
      },
      options: this.chartOptionsGk2
    });
  }

  createD1GkChart(data: number[]) {
    if (!this.doughnut1GkCanvas) return;
    const ctx = this.doughnut1GkCanvas.nativeElement.getContext('2d');

    if (this.doughnut1GkChart) {
      this.doughnut1GkChart.destroy();
    }

    this.doughnut1GkChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['GOALS', 'FAILED SHOTS'],
        datasets: [{
          data: data,
          backgroundColor: this.circleColors[0].backgroundColor,
          borderColor: this.circleColors[0].borderColor,
          borderWidth: 1
        }]
      },
      options: this.chartOptionsGk
    });
  }

  public onAccordionOverviewToggle(isOpen: boolean): void {
    this.overviewAccordionOpen = isOpen;
  }

  public onAccordionIndEventsToggle(isOpen: boolean): void {
    this.indEventsAccordionOpen = isOpen;
  }

  public onAccordionLastGamesToggle(isOpen: boolean): void {
    this.lastGamesAccordionOpen = isOpen;
    if (!this.selectableColumns?.headerTemplate) {
      this.initializeIonicSelectableTemplates();
    }
  }

  public onAccordionGameSystemToggle(isOpen: boolean): void {
    this.gameSystemAccordionOpen = isOpen;
  }

  public onExpandAllClick() {
    this.overviewAccordionOpen = true;
    this.indEventsAccordionOpen = true;
    this.lastGamesAccordionOpen = true;
    this.gameSystemAccordionOpen = true;
  }

  public onCollapseAllClick() {
    this.overviewAccordionOpen = false;
    this.indEventsAccordionOpen = false;
    this.lastGamesAccordionOpen = false;
    this.gameSystemAccordionOpen = false;
  }

  public print() {
    if (this.printData) {
      const value = {
        teamName: this.printData.teamName,
        playerImageURL: this.printData.playerImageURL,
        selectedPlayer: this.printData.selectedPlayer,
        selectedPlayerPosition: this.printData.selectedPlayerPosition,
        entityFilterDtoMap: this.printData.entityFilterDtoMap,
        selectedGames: this.printData.selectedGames
      };
      this.printPdf(value);
    }

  }

  public printGkReport() {
    if (this.printData) {
      const value = {
        teamName: this.printData.teamName,
        playerImageURL: this.printData.playerImageURL,
        selectedPlayer: this.printData.selectedPlayer,
        selectedPlayerPosition: this.printData.selectedPlayerPosition,
        entityFilterDtoMap: this.printData.entityFilterDtoMap,
        selectedGames: this.printData.selectedGames,
        goalkeeperReport: true
      };
      this.printPdf(value);
    }
  }

  public onSort(event: { sorts: { prop: string; dir: string }[] }, field: string, observableField?: string): void {
    let rows: any[] = [...this[field]];
    const sort = event.sorts[0];
    const { prop, dir } = sort;
    const totalRow = rows.find(row => Object.values(row)[0] === 'Total' || Object.values(row)[1] === 'Total');
    const naRows = [...rows].filter(row => row[prop] === 'n/a');
    rows = [...rows]
        .filter(row => Object.values(row)[0] !== 'Total' && Object.values(row)[1] !== 'Total' && row[prop] !== 'n/a')
        .sort((a, b) => {
          let aValue = a[prop];
          let bValue = b[prop];

          if (aValue == null && bValue == null) return 0;
          if (aValue == null) return dir === 'asc' ? -1 : 1;
          if (bValue == null) return dir === 'asc' ? 1 : -1;

          const isAPercentage = typeof aValue === 'string' && aValue.trim().endsWith('%');
          const isBPercentage = typeof bValue === 'string' && bValue.trim().endsWith('%');

          if (isAPercentage && isBPercentage) {
            aValue = parseFloat(aValue.replace('%', '').trim());
            bValue = parseFloat(bValue.replace('%', '').trim());
          } else if (isAPercentage) {
            return dir === 'asc' ? 1 : -1;
          } else if (isBPercentage) {
            return dir === 'asc' ? -1 : 1;
          }

          if (
              typeof aValue === 'string' &&
              typeof bValue === 'string' &&
              !isNaN(Number(aValue)) &&
              !isNaN(Number(bValue))
          ) {
            aValue = Number(aValue);
            bValue = Number(bValue);
          }

          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return dir === 'asc'
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
          }

          if (typeof aValue === 'number' && typeof bValue === 'number') {
            return dir === 'asc' ? aValue - bValue : bValue - aValue;
          }

          if (aValue < bValue) return dir === 'asc' ? -1 : 1;
          if (aValue > bValue) return dir === 'asc' ? 1 : -1;

          return 0;
        });

    rows = [...rows, ...naRows];

    if (totalRow) {
      rows.push(totalRow);
    }
    this[field] = [...rows];

    if (observableField) {
      this[observableField].next([...rows]);
    }
  }

  public onDownloadLastGamesCSVClick() {
    const columnsToExport = [];
    this.lastGames$Last.forEach(game => {
      const gameForExport = {};
      this.selectedLastGamesColumns.forEach(col => {
        gameForExport[col.prop] = game[col.prop];
      });
      columnsToExport.push(gameForExport);
    });
    this.csvDownloadService.exportArrayOfObjectsToCSV(columnsToExport.filter(col => col.gameDate !== 'Total'), 'Last Games');
  }

  async ngOnInit(): Promise<void> {
    this.individualPlayerPdfInfo = {
      title: await this.tg(_('PDF Report')),
      author: 'Handball.AI',
      subject: await this.tg(_('Game pdf report')),
    }

    this.extraPlayerData = {
      individualPlayerPdfInfo: this.individualPlayerPdfInfo,
      pageTitle: `${ toUpperCase(await this.tg(_('OVERVIEW'))) }`
    }

    this.d1ChartOptions.plugins.title.text = await this.tg(_('GOALS / FAILED SHOTS %'));
    this.chartOptionsPDF.plugins.title.text = await this.tg(_('GOALS / FAILED SHOTS %'));

    this.d2ChartOptions.plugins.title.text = await this.tg(_('GOALS PER PHASE %'));
    this.chartOptions2PDF.plugins.title.text = await this.tg(_('GOALS PER PHASE %'));

    this.chartOptionsGk.plugins.title.text = await this.tg(_('SAVES / FAILED SHOTS %'));
    this.chartOptionsGk2.plugins.title.text = await this.tg(_('SAVES PER PHASE %'));

    this.totalAvg = [
      {
        prop: 'template',
        name: await this.tg(_('Events')),
        resizable: false,
        sortable: true,
        flexGrow: 3,
        frozenLeft: true
      },
      { prop: 'total', name: await this.tg(_('Total')),  flexGrow: 1, resizable: false, sortable: true,  frozenLeft: false },
      { prop: 'average', name: await this.tg(_('Average')), flexGrow: 2, resizable: false, sortable: true, frozenLeft: false },
    ];
    this.gameSystemColumns = await gameSystemColumns(this.tg);
  }


  public ngAfterViewInit() {
    this.initializeIonicSelectableTemplates();
    combineLatest([
      this.individualAggregated$,
      this.playerShotStats$,
      this.goalsPerPhaseStats$
    ]).pipe(
        filter(([aggregated, playerShotStats, goalsPerPhaseStats]) =>
            !!aggregated &&
            aggregated.playerPosition !== 'gk'
        )
    ).subscribe(([_, playerShotStats, goalsPerPhaseStats]) => {
      setTimeout(() => {
        this.createD1Chart(playerShotStats);
        this.createD2Chart(goalsPerPhaseStats);

        this.createD1PDFChart(playerShotStats);
        this.createD2PDFChart(goalsPerPhaseStats);
      }, 100);
    });

    combineLatest([
      this.individualAggregated$,
      this.gkSaveStatsTotal$,
      this.gkSaveStatsAvg$
    ]).pipe(
        filter(([aggregated, gkSaveStatsTotal, gkSaveStatsAvg]) =>
            !!aggregated &&
            aggregated.playerPosition === 'gk'
        )
    ).subscribe(([_, gkSaveStatsTotal, gkSaveStatsAvg]) => {
      setTimeout(() => {
        this.createD1GkChart(gkSaveStatsTotal);
        this.createD2GkChart(gkSaveStatsAvg);
      }, 100);
    });
  }

  public initializeIonicSelectableTemplates() {
    if (this.selectableColumns && this.valueTemplate && this.footerTemplate && this.itemTemplate && this.headerTemplate) {
      this.selectableColumns.valueTemplate = this.valueTemplate;
      this.selectableColumns.footerTemplate = this.footerTemplate;
      this.selectableColumns.itemTemplate = this.itemTemplate;
      this.selectableColumns.headerTemplate = this.headerTemplate;
    }
  }

  public personalizeColumnsClick() {
    this.selectableColumns?.open();
  }

  public onExpandLastGamesTableColumnsClick() {
    this.lastGamesTableAllColsShown = !this.lastGamesTableAllColsShown;
    if (!this.lastGamesTableAllColsShown) {
      this.selectedLastGamesColumns = [...this.selectedLastGamesColumns.slice(0, 4)];
    } else {
      this.selectedLastGamesColumns = this.selectedPlayerPosition === 'gk' ? [...this.gkColumns] :  [...this.lastGamesColumns.slice(0, 15)];
    }
  }


  public onExpandGameSystemTableColumnsClick() {
    this.gameSystemTableAllColsShown = !this.gameSystemTableAllColsShown;
  }

  public onSelectColumns() {
    if (this.core.isMobileDevice && !this.lastGamesTableAllColsShown && this.selectedLastGamesColumns.length > 4) {
      this.lastGamesTableAllColsShown = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
    if (this.doughnut1Chart) {
      this.doughnut1Chart.destroy();
    }
  }

  public get isGameSystemEnabled(): boolean {
    return this.core.gameDataService.gameSystems$.value.length > 0 && this.core.gameDataService.gameSystemEnabled$.value;
  }

  public get selectedType() {
    return this.confData ? this.confData.mode : undefined;
  }

  public getRowClass = (row: any) => {
    if (row.row.colorCode === 'red') {
      return {
        'red-row-color': true
      };
    } else if (row.row.colorCode === 'blue') {
      return {
        'blue-row-color': true
      };
    } else if (row.row.colorCode === 'yellow') {
      return {
        'yellow-row-color': true
      };
    } else {
      return {
        'green-row-color': true
      };
    }
  }
}
