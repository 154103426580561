import { Component, Input, OnInit } from '@angular/core';
import {
  GameSystemStatsViewModel
} from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-view.model';
import { NGXLogger } from 'ngx-logger';
import { sortByCategoryAndNumberOfActions } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/game-system.helper';
import { CoreService } from '../../../shared-services/core.service';

@Component({
    selector: 'app-game-system-stats',
    templateUrl: './game-system-stats.component.html',
    styleUrls: ['./game-system-stats.component.scss'],
    standalone: false
})
export class GameSystemStatsComponent implements OnInit {

  private _homeTeamName = '';
  private _homeGameSystemStatsView: GameSystemStatsViewModel[] = [];
  private _visitorTeamName = '';
  private _visitorGameSystemStatsView: GameSystemStatsViewModel[] = [];
  private _columns1 = [
    { prop: 'actionDisplayName', name: 'Action Name'},
    { prop: 'categoryName', name: 'Category'},
    { prop: 'colorCode', name: 'Saves' },
    { prop: 'numberOfGoals', name: 'N° GOALS' },
    { prop: 'numberOfSaves', name: 'N° SAVES' },
    { prop: 'numberOfPostOuts', name: 'N° POST / OUT' },
    { prop: 'numberOfActionsPlayedWith7Meters', name: 'N° 7 METERS' },
    { prop: 'numberOfActionsPlayed', name: 'TIMES PLAYED' },
    { prop: 'numberOfFault', name: 'N° Fault' },
    { prop: 'numberOfSuspension', name: 'N° Suspension' },
    { prop: 'numberOfLostBall', name: 'N° LostBall' },
    { prop: 'percentageGoals', name: '% GOALS' },
    { prop: 'percentageNoGoals', name: '% NO GOALS' },
    { prop: 'percentage7MetersGoals', name: '% GOALS 7 METERS' },
  ];
  public tablesAllColsShown = false;
  public tableVisitorAllColsShown = false;

  constructor(private readonly logger: NGXLogger,
              public core: CoreService
  ) { }

  ngOnInit() {}


  public onExpandTableColumnsClick() {
    this.tablesAllColsShown = !this.tablesAllColsShown;
  }

  public onExpandVisitorTableColumnsClick() {
    this.tableVisitorAllColsShown = !this.tableVisitorAllColsShown;
  }

  get homeTeamName(): string {
    return this._homeTeamName;
  }

  @Input()
  set homeTeamName(value: string) {
    this._homeTeamName = value;
  }

  get homeGameSystemStatsView(): GameSystemStatsViewModel[] {
    return this._homeGameSystemStatsView;
  }

  @Input()
  set homeGameSystemStatsView(value: GameSystemStatsViewModel[]) {
    this._homeGameSystemStatsView = sortByCategoryAndNumberOfActions(value);
  }

  get visitorTeamName(): string {
    return this._visitorTeamName;
  }

  @Input()
  set visitorTeamName(value: string) {
    this._visitorTeamName = value;
  }

  get visitorGameSystemStatsView(): GameSystemStatsViewModel[] {
    return this._visitorGameSystemStatsView;
  }

  @Input()
  set visitorGameSystemStatsView(value: GameSystemStatsViewModel[]) {
    this._visitorGameSystemStatsView = sortByCategoryAndNumberOfActions(value);

  }



  get columns1() {
    return this._columns1;
  }

  public createRowName(row: GameSystemStatsViewModel): string {
    return `${row.categoryName} - ${row.actionDisplayName}`;
  }

  public getRowClass = (row: any) => {
    if (row.row.colorCode === 'red') {
      return {
        'red-row-color': true
      };
    } else if (row.row.colorCode === 'blue') {
      return {
        'blue-row-color': true
      };
    } else if (row.row.colorCode === 'yellow') {
      return {
        'yellow-row-color': true
      };
    } else {
      return {
        'green-row-color': true
      };
    }
  }
}
