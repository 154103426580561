import { PlayerStatsDto } from 'src/app/api/hai-api';
import {
    TeamOverviewViewCounterModel
} from '@handballai/stats-calculation';

export type PlayerStatsDtoPart = Omit<PlayerStatsDto,
    'savesOffensePositional'
    | 'savesFastBreak'
    | 'savesCounterGoal'
    | 'goalsLeftWing'
    | 'goalsLeft6m'
    | 'goalsCenter6m'
    | 'goalsRight6m'
    | 'goalsRightWing'
    | 'goalsLeft9m'
    | 'goalsCenter9m'
    | 'goalsRight9m'
    | 'goals7Meters'
    | 'goalsOppositeField'
    | 'savesLeftWing'
    | 'savesLeft6m'
    | 'savesCenter6m'
    | 'savesRight6m'
    | 'savesRightWing'
    | 'savesLeft9m'
    | 'savesCenter9m'
    | 'savesRight9m'
    | 'saves7Meters'
    | 'savesOppositeField'
    | 'goals1'
    | 'goals2'
    | 'goals3'
    | 'goals4'
    | 'goals5'
    | 'goals6'
    | 'goals7'
    | 'goals8'
    | 'goals9'
    | 'saves1'
    | 'saves2'
    | 'saves3'
    | 'saves4'
    | 'saves5'
    | 'saves6'
    | 'saves7'
    | 'saves8'
    | 'saves9'
    | 'postOutCenter6m'
    | 'postOutCenter9m'
    | 'postOutLeft6m'
    | 'postOutLeft9m'
    | 'postOutLeftWing'
    | 'postOutOppositeField'
    | 'postOutRight6m'
    | 'postOutRight9m'
    | 'postOutRightWing'
    | 'postOut7Meters'
    | 'postOut0'
    | 'postOut10'
    | 'postOut11'
    | 'goalsLeftWing0'
    | 'goalsLeftWing1'
    | 'goalsLeftWing2'
    | 'goalsLeftWing3'
    | 'goalsLeftWing4'
    | 'goalsLeftWing5'
    | 'goalsLeftWing6'
    | 'goalsLeftWing7'
    | 'goalsLeftWing8'
    | 'goalsLeftWing9'
    | 'goalsLeftWing10'
    | 'goalsLeftWing11'
    | 'goalsLeft6m0'
    | 'goalsLeft6m1'
    | 'goalsLeft6m2'
    | 'goalsLeft6m3'
    | 'goalsLeft6m4'
    | 'goalsLeft6m5'
    | 'goalsLeft6m6'
    | 'goalsLeft6m7'
    | 'goalsLeft6m8'
    | 'goalsLeft6m9'
    | 'goalsLeft6m10'
    | 'goalsLeft6m11'
    | 'goalsCenter6m0'
    | 'goalsCenter6m1'
    | 'goalsCenter6m2'
    | 'goalsCenter6m3'
    | 'goalsCenter6m4'
    | 'goalsCenter6m5'
    | 'goalsCenter6m6'
    | 'goalsCenter6m7'
    | 'goalsCenter6m8'
    | 'goalsCenter6m9'
    | 'goalsCenter6m10'
    | 'goalsCenter6m11'
    | 'goalsRight6m0'
    | 'goalsRight6m1'
    | 'goalsRight6m2'
    | 'goalsRight6m3'
    | 'goalsRight6m4'
    | 'goalsRight6m5'
    | 'goalsRight6m6'
    | 'goalsRight6m7'
    | 'goalsRight6m8'
    | 'goalsRight6m9'
    | 'goalsRight6m10'
    | 'goalsRight6m11'
    | 'goalsRightWing0'
    | 'goalsRightWing1'
    | 'goalsRightWing2'
    | 'goalsRightWing3'
    | 'goalsRightWing4'
    | 'goalsRightWing5'
    | 'goalsRightWing6'
    | 'goalsRightWing7'
    | 'goalsRightWing8'
    | 'goalsRightWing9'
    | 'goalsRightWing10'
    | 'goalsRightWing11'
    | 'goalsLeft9m0'
    | 'goalsLeft9m1'
    | 'goalsLeft9m2'
    | 'goalsLeft9m3'
    | 'goalsLeft9m4'
    | 'goalsLeft9m5'
    | 'goalsLeft9m6'
    | 'goalsLeft9m7'
    | 'goalsLeft9m8'
    | 'goalsLeft9m9'
    | 'goalsLeft9m10'
    | 'goalsLeft9m11'
    | 'goalsCenter9m0'
    | 'goalsCenter9m1'
    | 'goalsCenter9m2'
    | 'goalsCenter9m3'
    | 'goalsCenter9m4'
    | 'goalsCenter9m5'
    | 'goalsCenter9m6'
    | 'goalsCenter9m7'
    | 'goalsCenter9m8'
    | 'goalsCenter9m9'
    | 'goalsCenter9m10'
    | 'goalsCenter9m11'
    | 'goalsRight9m0'
    | 'goalsRight9m1'
    | 'goalsRight9m2'
    | 'goalsRight9m3'
    | 'goalsRight9m4'
    | 'goalsRight9m5'
    | 'goalsRight9m6'
    | 'goalsRight9m7'
    | 'goalsRight9m8'
    | 'goalsRight9m9'
    | 'goalsRight9m10'
    | 'goalsRight9m11'
    | 'goals7Meters0'
    | 'goals7Meters1'
    | 'goals7Meters2'
    | 'goals7Meters3'
    | 'goals7Meters4'
    | 'goals7Meters5'
    | 'goals7Meters6'
    | 'goals7Meters7'
    | 'goals7Meters8'
    | 'goals7Meters9'
    | 'goals7Meters10'
    | 'goals7Meters11'
    | 'goalsOppositeField0'
    | 'goalsOppositeField1'
    | 'goalsOppositeField2'
    | 'goalsOppositeField3'
    | 'goalsOppositeField4'
    | 'goalsOppositeField5'
    | 'goalsOppositeField6'
    | 'goalsOppositeField7'
    | 'goalsOppositeField8'
    | 'goalsOppositeField9'
    | 'goalsOppositeField10'
    | 'goalsOppositeField11'
    | 'shootsLeftWing0'
    | 'shootsLeftWing1'
    | 'shootsLeftWing2'
    | 'shootsLeftWing3'
    | 'shootsLeftWing4'
    | 'shootsLeftWing5'
    | 'shootsLeftWing6'
    | 'shootsLeftWing7'
    | 'shootsLeftWing8'
    | 'shootsLeftWing9'
    | 'shootsLeftWing10'
    | 'shootsLeftWing11'
    | 'shootsLeft6m0'
    | 'shootsLeft6m1'
    | 'shootsLeft6m2'
    | 'shootsLeft6m3'
    | 'shootsLeft6m4'
    | 'shootsLeft6m5'
    | 'shootsLeft6m6'
    | 'shootsLeft6m7'
    | 'shootsLeft6m8'
    | 'shootsLeft6m9'
    | 'shootsLeft6m10'
    | 'shootsLeft6m11'
    | 'shootsCenter6m0'
    | 'shootsCenter6m1'
    | 'shootsCenter6m2'
    | 'shootsCenter6m3'
    | 'shootsCenter6m4'
    | 'shootsCenter6m5'
    | 'shootsCenter6m6'
    | 'shootsCenter6m7'
    | 'shootsCenter6m8'
    | 'shootsCenter6m9'
    | 'shootsCenter6m10'
    | 'shootsCenter6m11'
    | 'shootsRight6m0'
    | 'shootsRight6m1'
    | 'shootsRight6m2'
    | 'shootsRight6m3'
    | 'shootsRight6m4'
    | 'shootsRight6m5'
    | 'shootsRight6m6'
    | 'shootsRight6m7'
    | 'shootsRight6m8'
    | 'shootsRight6m9'
    | 'shootsRight6m10'
    | 'shootsRight6m11'
    | 'shootsRightWing0'
    | 'shootsRightWing1'
    | 'shootsRightWing2'
    | 'shootsRightWing3'
    | 'shootsRightWing4'
    | 'shootsRightWing5'
    | 'shootsRightWing6'
    | 'shootsRightWing7'
    | 'shootsRightWing8'
    | 'shootsRightWing9'
    | 'shootsRightWing10'
    | 'shootsRightWing11'
    | 'shootsLeft9m0'
    | 'shootsLeft9m1'
    | 'shootsLeft9m2'
    | 'shootsLeft9m3'
    | 'shootsLeft9m4'
    | 'shootsLeft9m5'
    | 'shootsLeft9m6'
    | 'shootsLeft9m7'
    | 'shootsLeft9m8'
    | 'shootsLeft9m9'
    | 'shootsLeft9m10'
    | 'shootsLeft9m11'
    | 'shootsCenter9m0'
    | 'shootsCenter9m1'
    | 'shootsCenter9m2'
    | 'shootsCenter9m3'
    | 'shootsCenter9m4'
    | 'shootsCenter9m5'
    | 'shootsCenter9m6'
    | 'shootsCenter9m7'
    | 'shootsCenter9m8'
    | 'shootsCenter9m9'
    | 'shootsCenter9m10'
    | 'shootsCenter9m11'
    | 'shootsRight9m0'
    | 'shootsRight9m1'
    | 'shootsRight9m2'
    | 'shootsRight9m3'
    | 'shootsRight9m4'
    | 'shootsRight9m5'
    | 'shootsRight9m6'
    | 'shootsRight9m7'
    | 'shootsRight9m8'
    | 'shootsRight9m9'
    | 'shootsRight9m10'
    | 'shootsRight9m11'
    | 'shoots7Meters0'
    | 'shoots7Meters1'
    | 'shoots7Meters2'
    | 'shoots7Meters3'
    | 'shoots7Meters4'
    | 'shoots7Meters5'
    | 'shoots7Meters6'
    | 'shoots7Meters7'
    | 'shoots7Meters8'
    | 'shoots7Meters9'
    | 'shoots7Meters10'
    | 'shoots7Meters11'
    | 'shootsOppositeField0'
    | 'shootsOppositeField1'
    | 'shootsOppositeField2'
    | 'shootsOppositeField3'
    | 'shootsOppositeField4'
    | 'shootsOppositeField5'
    | 'shootsOppositeField6'
    | 'shootsOppositeField7'
    | 'shootsOppositeField8'
    | 'shootsOppositeField9'
    | 'shootsOppositeField10'
    | 'shootsOppositeField11'
    | 'gkSaves7Meters'
    | 'gkReceivedGoals7Meters'
    | 'eventsFastBreak'
    | 'eventsCounterGoal'
    | 'eventsOffensePositional'
    | 'eventsSuperiority7vs6'
    | 'eventsSuperiority6vs5'
    | 'eventsSuperiorityOthers'
    | 'eventsEquality'
    | 'eventsInferiority'
    | 'eventsDefense'
    | 'eventsOffense'
    | 'playedTimeOffense'
    | 'playedTimeDefense'
    | 'playedTimeOffenseInferiority'
    | 'playedTimeDefenseInferiority'
    | 'playedTimeOffenseSuperiority'
    | 'playedTimeDefenseSuperiority'
    | 'playedTimeOffenseEquality'
    | 'playedTimeDefenseEquality'
    | 'yellowCard'
    | 'blueCard'
    | 'redCard'
>;

export const transformToPlayerStatisticsDto = (
    teamOverviewCounterModel: TeamOverviewViewCounterModel,
    goalsOpponent: number
): PlayerStatsDtoPart => {
    return {
        playerId: teamOverviewCounterModel.player.id,
        playerName: teamOverviewCounterModel.player.name,
        playerPosition: teamOverviewCounterModel.player.position,
        playerBackNumber: teamOverviewCounterModel.player.backNumber,
        teamName: teamOverviewCounterModel.player.teamName,
        teamId: teamOverviewCounterModel.player.teamId,
        timePlayed: teamOverviewCounterModel.overallPlayTimeSec,
        possessionsPlayed: teamOverviewCounterModel.counterMap.get('POSSESSIONS').counter,
        goals: teamOverviewCounterModel.counterMap.get('GOALS').counter,
        saves: teamOverviewCounterModel.counterMap.get('SAVES').counter,
        lostBalls: teamOverviewCounterModel.counterMap.get('LOST_BALLS').counter,
        lostBallsOffensePositional: teamOverviewCounterModel.counterMap.get('LOST_BALLS-OFFENSE_POSITIONAL').counter,
        lostBallsFastBreak: teamOverviewCounterModel.counterMap.get('LOST_BALLS-FAST_BREAK').counter,
        lostBallsEquality: teamOverviewCounterModel.counterMap.get('LOST_BALLS-EQUALITY').counter,
        lostBallsSuperiority: teamOverviewCounterModel.counterMap.get('LOST_BALLS-SUPERIORITY').counter,
        lostBallsCounterGoal: teamOverviewCounterModel.counterMap.get('LOST_BALLS-COUNTER_GOAL').counter,
        attackFaultProvoke: teamOverviewCounterModel.counterMap.get('ATTACK_FAULT_PROVOKE').counter,
        attackFaultCommit: teamOverviewCounterModel.counterMap.get('ATTACK_FAULT_COMMIT').counter,
        technicalMistakeProvoke: teamOverviewCounterModel.counterMap.get('TECHNICAL_MISTAKE_PROVOKE').counter,
        technicalMistakeCommit: teamOverviewCounterModel.counterMap.get('TECHNICAL_MISTAKE_COMMIT').counter,
        lostBallProvoke: teamOverviewCounterModel.counterMap.get('LOST_BALL_PROVOKE').counter,
        lostBall: teamOverviewCounterModel.counterMap.get('LOST_BALL').counter,
        twoMinCommit: teamOverviewCounterModel.counterMap.get('2MIN_COMMIT').counter,
        twoMinProvoke: teamOverviewCounterModel.counterMap.get('2MIN_PROVOKE').counter,
        suspTwoMin: teamOverviewCounterModel.counterMap.get('2_MIN').counter,
        sevenMetersCommit: teamOverviewCounterModel.counterMap.get('7M_COMMIT').counter,
        sevenMetersProvoke: teamOverviewCounterModel.counterMap.get('7M_PROVOKE').counter,
        sevenMSuspCommit: teamOverviewCounterModel.counterMap.get('7M_SUSPENSION_COMMIT').counter,
        sevenMSuspProvoke: teamOverviewCounterModel.counterMap.get('7M_PROVOKE_SUSPENSION').counter,
        foulCommit: teamOverviewCounterModel.counterMap.get('FOUL_COMMIT').counter,
        foulReceive: teamOverviewCounterModel.counterMap.get('FOUL_RECEIVE').counter,
        oneAndOneLost: teamOverviewCounterModel.counterMap.get('1-1_LOST').counter,
        oneAndOneWon: teamOverviewCounterModel.counterMap.get('1-1_WON').counter,
        block: teamOverviewCounterModel.counterMap.get('BLOCK').counter,
        shotBlocked: teamOverviewCounterModel.counterMap.get('SHOT_BLOCKED').counter,
        efficiency: teamOverviewCounterModel.calculatePercentageEfficiency(),
        shotEfficiency: teamOverviewCounterModel.calculatePercentageShotEfficiency(goalsOpponent),
        lostBallPerc: teamOverviewCounterModel.calculatePercentageLostBalls(),
        score: 100  + teamOverviewCounterModel.hpi2OverviewModel.hpi2Plus + teamOverviewCounterModel.hpi2OverviewModel.hpi2Minus,
        plusMinus: teamOverviewCounterModel.hpi2OverviewModel.hpi2Plus + teamOverviewCounterModel.hpi2OverviewModel.hpi2Minus,
        failedShotsOffensePositional: teamOverviewCounterModel.counterMap.get('FAILED_SHOTS-OFFENSE_POSITIONAL').counter,
        failedShotsCounterGoal: teamOverviewCounterModel.counterMap.get('FAILED_SHOTS-COUNTER_GOAL').counter,
        failedShotsFastBreak: teamOverviewCounterModel.counterMap.get('FAILED_SHOTS-FAST_BREAK').counter,
        postOut: teamOverviewCounterModel.counterMap.get('POST_OUT').counter,
        receivedGoals: teamOverviewCounterModel.counterMap.get('RECEIVED_GOALS').counter,
        totalShots: teamOverviewCounterModel.counterMap.get('GOALS').counter
            + teamOverviewCounterModel.counterMap.get('FAILED_SHOTS').counter
            + teamOverviewCounterModel.counterMap.get('POST_OUT').counter,
        possessionsOffensePositional: teamOverviewCounterModel.counterMap.get('POSSESSIONS-OFFENSE_POSITIONAL').counter,
        possessionsCounterGoal: teamOverviewCounterModel.counterMap.get('POSSESSIONS-COUNTER_GOAL').counter,
        possessionsFastBreak: teamOverviewCounterModel.counterMap.get('POSSESSIONS-FAST_BREAK').counter,
        goalsSuperiority: teamOverviewCounterModel.counterMap.get('GOALS-SUPERIORITY').counter,
        goalsEquality: teamOverviewCounterModel.counterMap.get('GOALS-EQUALITY').counter,
        possessionsSuperiority: teamOverviewCounterModel.counterMap.get('POSSESSIONS-SUPERIORITY').counter,
        possessionsEquality: teamOverviewCounterModel.counterMap.get('POSSESSIONS-EQUALITY').counter,
        shots7Meters: teamOverviewCounterModel.counterMap
            .get('7METERS_FAILED_SHOT').counter + teamOverviewCounterModel.counterMap.get('7METERS_POST_OUT').counter,
        assist: teamOverviewCounterModel.counterMap.get('ASSIST').counter,
        gkPostOut: teamOverviewCounterModel.counterMap.get('RECEIVED_PO').counter,

        defensePossOffensePositional: teamOverviewCounterModel.counterMap.get('DEF_POSS-OFFENSE_POSITIONAL').counter,
        defensePossFastBreak: teamOverviewCounterModel.counterMap.get('DEF_POSS-FAST_BREAK').counter,
        defensePossCounterGoal: teamOverviewCounterModel.counterMap.get('DEF_POSS-COUNTER_GOAL').counter,
        defenseReceivedGoalsOffensePositional: teamOverviewCounterModel.counterMap.get('RECEIVED_GOALS-OFFENSE_POSITIONAL').counter,
        defenseReceivedGoalsFastBreak: teamOverviewCounterModel.counterMap.get('RECEIVED_GOALS-FAST_BREAK').counter,
        defenseReceivedGoalsCounterGoal: teamOverviewCounterModel.counterMap.get('RECEIVED_GOALS-COUNTER_GOAL').counter,

        playerScore: teamOverviewCounterModel.playerScoreViewModel.score,
        score5: 0,
        scorePos: teamOverviewCounterModel.playerScoreViewModel.scorePos,
        scoreNeg: teamOverviewCounterModel.playerScoreViewModel.scoreNeg,
        scoreAttack: teamOverviewCounterModel.playerScoreViewModel.scoreAttack,
        scoreDef: teamOverviewCounterModel.playerScoreViewModel.scoreDef,

        postOutOffensePositional: teamOverviewCounterModel.counterMap.get('POST_OUT-OFFENSE_POSITIONAL').counter,
        postOutFastBreak: teamOverviewCounterModel.counterMap.get('POST_OUT-FAST_BREAK').counter,
        postOutCounterGoal: teamOverviewCounterModel.counterMap.get('POST_OUT-COUNTER_GOAL').counter,
        postOutEquality: teamOverviewCounterModel.counterMap.get('POST_OUT-EQUALITY').counter,
        postOutSuperiority: teamOverviewCounterModel.counterMap.get('POST_OUT-SUPERIORITY').counter,
        failedShotsEquality: teamOverviewCounterModel.counterMap.get('FAILED_SHOTS-EQUALITY').counter,
        failedShotsSuperiority: teamOverviewCounterModel.counterMap.get('FAILED_SHOTS-SUPERIORITY').counter,
        goalsCounterGoal: teamOverviewCounterModel.counterMap.get('GOALS-COUNTER_GOAL').counter,
        goalsFastBreak: teamOverviewCounterModel.counterMap.get('GOALS-FAST_BREAK').counter,
        goalsOffensePositional: teamOverviewCounterModel.counterMap.get('GOALS-OFFENSE_POSITIONAL').counter,
    };
};


export const getPlayerScoreStatisticsDto = (
    teamOverviewCounterModel: TeamOverviewViewCounterModel
): Map<string, number> => {
    const scoreMap = teamOverviewCounterModel.playerScoreViewModel.elementValues;
    scoreMap.set(<any> 'score', teamOverviewCounterModel.playerScoreViewModel.score);
    scoreMap.set(<any> 'scorePos', teamOverviewCounterModel.playerScoreViewModel.scorePos);
    scoreMap.set(<any> 'scoreNeg', teamOverviewCounterModel.playerScoreViewModel.scoreNeg);
    scoreMap.set(<any> 'scoreAttack', teamOverviewCounterModel.playerScoreViewModel.scoreAttack);
    scoreMap.set(<any> 'scoreDef', teamOverviewCounterModel.playerScoreViewModel.scoreDef);
    return scoreMap;
};
