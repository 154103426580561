import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { GamePlayerModel } from 'src/app/shared-services/model/game.model';
import { GoalZoneTypes, PostOutZoneTypes, ExecutionPositionTypes } from 'src/app/shared-services/actions/action-types';
import { EventTime, PlayByPlayModel, PlayerEvent } from '@handballai/stats-calculation';
import {
  getDefenseSystemByTeamMarker, getOpponentTeamMarker, getTeamByTeamMarker, getTeamIdByTeamMarker,
  getTeamNameByTeamMarker,
  mapGamePlayerToTeamStatsPlayer
} from 'src/app/shared-services/helper/statistics-helper';
import { PlayerEventModel } from '@handballai/stats-calculation';
import { PlaytimeEventModel } from '@handballai/stats-calculation';
import { CoreService } from '../../core.service';
import { eventToPossessionMapper } from 'src/app/shared-services/statistics/ingest/event-feed.util';

@Injectable({
  providedIn: 'root'
})
export class EventFeedService {

  constructor(
      private readonly logger: NGXLogger,
  ) { }
  private _core: CoreService;

  private _currentPossessionOwner: TeamMarker | 'INITIAL_OWNER' = 'INITIAL_OWNER';
  private _currentPossessionId = 0;
  private _currentPbPId = 0;
  private _currentPossessionMap = new Map<TeamMarker, number>([
    ['HOME', 0],
    ['VISITOR', 0]
  ]);

  initCore(core: CoreService) {
    this._core = core;
  }

  public init(): void {
    this._currentPossessionOwner = 'INITIAL_OWNER';
    this._currentPossessionId = 0;
    this._currentPbPId = 0;
    this._currentPossessionMap = new Map<TeamMarker, number>([
      ['HOME', 0],
      ['VISITOR', 0]
    ]);
  }

  public async addPlayTimeEvent(
      id: number,
      event: PlayerEventModel,
      eventTime: EventTime
  ): Promise<void> {
    this.logger.debug('EventFeedService.addPlayTimeEvent: player, event', id, event);
    const ev = {
      eventTime: eventTime,
      eventType: event,
      playerId: id
    } as PlaytimeEventModel;

    await this._core.playTimeService.addPlayTimeEvent([ev]);
  }

  public async addPlayByPlayEvent(
      teamMarker: TeamMarker,
      event: PlayerEvent,
      offenseSystem: string,
      offensePlayer?: GamePlayerModel,
      defensePlayer?: GamePlayerModel,
      assistant?: GamePlayerModel,
      executionPosition?: ExecutionPositionTypes,
      shotLocation?: GoalZoneTypes | PostOutZoneTypes,
      important: boolean = false,
      gameSystem?: string
  ): Promise<void> {

    this.logger.debug('EventFeedService.addPlayByPlayEvent:', {
      teamMarker,
      event,
      offensePlayer,
      defensePlayer,
      assistant,
      executionPosition,
      shotLocation
    });

    this.calculatePossession(teamMarker, event.eventType);

    const pbp: PlayByPlayModel = {
      event: event.eventType,
      eventTime: event.eventTime,
      orderId: ++this._currentPbPId,
      teamMarker: teamMarker,
      teamId: getTeamIdByTeamMarker(teamMarker, this._core.gameService.gameModel),
      teamName: getTeamNameByTeamMarker(teamMarker, this._core.gameService.gameModel),
      possessions: this._currentPossessionMap.get(teamMarker),
      possessionId: this._currentPossessionId,
      phase: event.phase,
      offensePlayer: offensePlayer ? mapGamePlayerToTeamStatsPlayer(offensePlayer) : undefined,
      defensePlayer: defensePlayer ? mapGamePlayerToTeamStatsPlayer(defensePlayer) : undefined,
      assistantPlayer: assistant ? mapGamePlayerToTeamStatsPlayer(assistant) : undefined,
      offense: getTeamByTeamMarker(teamMarker, this._core.gameService.gameModel),
      defense: getTeamByTeamMarker(getOpponentTeamMarker(teamMarker), this._core.gameService.gameModel),
      defenseSystem: getDefenseSystemByTeamMarker(getOpponentTeamMarker(teamMarker), this._core.gameService.gameModel),
      executionPosition: executionPosition,
      shotLocation: shotLocation,
      offenseSystem: offenseSystem,
      important: important,
      gameSystem: gameSystem ? gameSystem : undefined,
    };

    this.logger.debug('EventFeedService.addPlayByPlayEvent - calculated PlayByPlay', pbp);
    this._core.playByPlayProducerService.addPbpRecord(pbp);
    await this._core.gameDataService.addPlayPlayByPlayToGame(this._core.gameService.gameId, [pbp]);
  }

  public addExternalPlayByPlayPEvent(pbp: PlayByPlayModel): void {
    this.logger.debug('EventFeedService.addExternalPlayByPlayPEvent - external PlayByPlay', pbp);
    this._core.timerWrapperService.setCurrentSeconds(pbp.eventTime.secondsSinceStartOfGame);
    this._core.playByPlayProducerService.addPbpRecord(pbp);
  }

  private calculatePossession(
      teamMarker: TeamMarker,
      eventType: PlayerEventModel
  ): void {
    if (this.isRelevantEvent(eventType)) {
      if (this._currentPossessionOwner !== teamMarker || eventType === 'SECOND_HALF_START') {
        this._currentPossessionOwner = teamMarker;
        this._currentPossessionId = ++this._currentPossessionId;
        this._currentPossessionMap.set(teamMarker, this._currentPossessionMap.get(teamMarker) + 1);
      }
    }
  }

  private isRelevantEvent(eventType: PlayerEventModel): boolean {
    return eventToPossessionMapper(eventType);
  }

}
