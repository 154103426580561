<div class="confirm-modal-wrapper my-8">
  <div class="py-4 w-full">
    <div style="width: 100%" class="display-flex-space-between">
      <span></span>
      <span class="span-with-add-btn">{{ 'Select a Playlist' | translate }}</span>
      <ion-button style="margin-right: 30px" [tooltip]="'Add new Playlist' | translate" (click)="createPlaylist()" color="secondary">
        <ion-icon style="padding: 0 4px;" name="add-outline"></ion-icon>
      </ion-button>
    </div>
    <hr class="mx-auto my-2" />
    <span class="explanation-text">{{ 'Select the playlist where you want to add the videos' | translate }}</span>
  </div>
  <div class="input-group text-black mt-2">
      <ion-item *ngFor="let playlist of scoutingService.playListFolders$ | async" (click)="onPlaylistChange(playlist)"
                lines="full" class="item-default clickAllowedSource">
          <div class="folder-item" style="display: flex; width: 100%; cursor: pointer">
            <ion-label class="clickAllowedSource"> {{ playlist.name }} </ion-label>
          </div>
      </ion-item>
  </div>
  <div class="button-wrapper mt-6">
    <span (click)="onCancel()" class="close-btn">{{ 'Close' | translate }}</span>
  </div>
</div>
