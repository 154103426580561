import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CoreService } from 'src/app/shared-services/core.service';
import { ModalController } from '@ionic/angular';
import { GamePlayerModel, PlayByPlayModel } from '@handballai/stats-calculation';
import { BehaviorSubject, Observable } from 'rxjs';
import { GameType } from 'src/app/shared-services/game/game-type.model';
import { GameSystemCategoryDto, GameSystemDto } from 'src/app/api/hai-api';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-play-by-play-edit',
  templateUrl: './play-by-play-edit.component.html',
  styleUrls: ['./play-by-play-edit.component.scss']
})
export class PlayByPlayEditComponent implements OnInit {

  readonly shotEntriesEventTypes = ['GOAL', 'SAVE', 'POST_OUT'];
  readonly goalSaveShotLocations = ['1','2','3','4','5','6','7','8','9'];
  readonly postOutShotLocations = ['0','10','11'];
  readonly offenseSystemOpts = ['EQUALITY','EQUALITY-5+1vs6','EQUALITY-6vs5+1','SUPERIORITY-6vs5','SUPERIORITY-7vs6','SUPERIORITY-OTHER','INFERIORITY-5vs6','INFERIORITY-6vs7','INFERIORITY-OTHER'];

  pbpEntryData: PlayByPlayModel = null;
  gameSystemDto;

  gameSystemsSelectedArray = {
    "Crossing": [],
    "Pressing": [],
    "Transition Game": [],
    "Type of Shot": []
  }

  @Input() hideHeader?: boolean = false;

  @Input('pbpEntry')
  set pbpEntry(value: PlayByPlayModel) {
    this.pbpEntryData = JSON.parse(JSON.stringify(value));
    this.logger.debug('Editing pbpEntry', this.pbpEntryData);
    if (this.pbpEntryData.teamMarker === 'HOME') {
      this._offensePlayers$.next([...this.core.gameService.homePlayers$.value]);
      this._defensePlayers$.next([...this.core.gameService.visitorPlayers$.value]);
    } else {
      this._offensePlayers$.next([...this.core.gameService.visitorPlayers$.value]);
      this._defensePlayers$.next([...this.core.gameService.homePlayers$.value]);
    }
    if (this.pbpEntryData.gameSystem) {
      this.pbpEntryData.gameSystem = JSON.parse(this.pbpEntryData.gameSystem || '{}');
    }
  }

  @Input('readonlyFields') readonlyFields: string[] = [];

  @Output() pbpEntryDataSave: EventEmitter<any> = new EventEmitter();


  get gameMode$(): Observable<GameType> {
    return this._gameMode$;
  }

  get offensePlayers$(): Observable<GamePlayerModel[]> {
    return this._offensePlayers$;
  }

  get defensePlayers$(): Observable<GamePlayerModel[]> {
    return this._defensePlayers$;
  }

  private readonly _gameMode$: BehaviorSubject<GameType>;
  private _offensePlayers$ = new BehaviorSubject<GamePlayerModel[]>([]);
  private _defensePlayers$ = new BehaviorSubject<GamePlayerModel[]>([]);


  constructor(
    public readonly core: CoreService,
    private readonly modalController: ModalController,
    private readonly logger: NGXLogger,
  ) {
    this._gameMode$ = this.core.gameService.gameMode$;
  }

  async ngOnInit(): Promise<void> {
    //We need to load the gameSystems before to subscribe to gameSystem$ from core
    const gameSystemCategories = await this.core.gameDataService.readGameSystems();
    let auxArray = [];
    gameSystemCategories?.forEach((gameSystemCategory: GameSystemCategoryDto) => {
      gameSystemCategory.gameSystems?.forEach((gameSystem: GameSystemDto) => {
        auxArray.push({
          "categoryName": gameSystemCategory.categoryName,
          "actionDisplayName": gameSystem.actionDisplayName,
          "colorCode": gameSystemCategory.colorCode,
          "actionName": gameSystem.actionName
        });
      });
    });
    this.gameSystemDto = auxArray;
  }

  dismiss(save= true) {
    this.pbpEntryData.gameSystem = this.pbpEntryData.gameSystem ? JSON.stringify(this.pbpEntryData.gameSystem) : null;
    this.modalController.dismiss(save ? this.pbpEntryData : null);
  }

  save() {
    this.pbpEntryData.gameSystem = this.pbpEntryData.gameSystem ? JSON.stringify(this.pbpEntryData.gameSystem) : null;
    this.pbpEntryDataSave.emit(this.pbpEntryData);
  }

}
