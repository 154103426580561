<ion-header [style.padding-bottom]="core.isMobileDevice ? '10px' : ''">
  <div style="margin-top: 4px; cursor: pointer" class="ml-4" *ngIf="core.isMobileDevice">
      <ion-icon name="menu-outline" class="arrow-back" (click)="onMenuClick()" [color]="'secondary'"></ion-icon>
  </div>
  <div class="ml-4" *ngIf="showGoBackButton && (!core.isMobileDevice || !headerTitle)">
    <ion-icon name="arrow-back-circle-outline" class="arrow-back" (click)="goBack()" [color]="'secondary'"></ion-icon>
  </div>
  <ion-toolbar>
    <ng-container [ngTemplateOutlet]="headerTitleTemplate ? headerTitleTemplate:headerTitleFallbackTemplate" [ngTemplateOutletContext]="{headerTitle:headerTitle}"></ng-container>
    <ng-template #headerTitleFallbackTemplate>
      <ion-title slot="start" class="ion-text-start ion-color-blue-header header-title-text">
        {{headerTitle}}
      </ion-title>
    </ng-template>

    <!-- <ion-chip slot="end" color="transparend">
      <ion-icon size="medium" name="notifications"></ion-icon>
      <ion-chip
        *ngIf="user$ | async as user"
        color="transparend"
        (click)="toggleUserMenu()"
        class="user-name-logo-wrapper"
      >
        <span class="userName">{{ user.firstName }} {{ user.lastName }}</span>
        <ion-avatar>
          <img
            src="/assets/avatar.svg"
          />
        </ion-avatar>
        <ion-icon
          ngClass="{'rotated': isOpen}"
          name="chevron-down-outline"
        ></ion-icon>
      </ion-chip>
    </ion-chip> -->
    <!-- <ion-item lines="none" color="transparend"
      ><ion-label>{{ "Select account" | translate }}</ion-label></ion-item
    > -->

    <!-- <ion-chip class="ae-selector" lines="none"> -->
    <!-- </ion-chip> -->
  </ion-toolbar>

  <div *ngIf="showSync" class="button-container">
    <ion-button (click)="onSynchronize()" *ngIf="hasUploadPending" color="danger">
        <ion-icon name="cloud-upload-outline" class="pr-1"></ion-icon>
        <ion-label>
          {{'Sync Data' | translate}}
        </ion-label>
    </ion-button>
  </div>

  <span class="select-wrapper">
    <ion-label *ngIf="!core.isMobileDevice" class="select-acount-label">{{ "Select account" | translate }}</ion-label>
    <div class="folder-icon-abs">
      <ion-icon style="font-size: 27px" *ngIf="core.isMobileDevice && showFolderIcon"
                (click)="onSelectFolderClick()"
                name="folder-open-outline"></ion-icon>
    </div>
    <div class="folder-icon-abs">
      <ion-icon style="font-size: 27px" *ngIf="core.isMobileDevice && showOrgIcon"
                (click)="onSelectOrganizationClick()"
                name="briefcase-outline"></ion-icon>
    </div>
    <div class="icon-abs">
      <ion-icon style="font-size: 27px" *ngIf="core.isMobileDevice" (click)="onSelectAccountClick()" name="albums-outline"></ion-icon>
    </div>
    <ng-select
      #ngSelectSelector
      id="ngSelect"
      *ngIf="accountEquipos$ | async; let aes"
      [placeholder]="'Type to search...' | translate"
      maxSelectedItems="1"
      [searchable]="true"
      [disabled]="!(gameEnded$ | async)"
      [clearable]="false"
      [ngModel]="(selectedAccountEquipo$ | async)"
      (change)="onAccountEquipoSelectChange($event)"
      (close)="onMenuClose()"
    >
      <ng-option *ngFor="let ae of aes" [value]="ae">
        <span class="select-options-text">
          {{ ae.name }} - {{ ae.account?.name }}
        </span>
      </ng-option>
    </ng-select>
  </span>

</ion-header>
