import {
  Component,
  ContentChildren,
  OnInit,
  QueryList,
  EventEmitter,
  Output,
  Input,
} from "@angular/core";
import { CustomTabComponent } from "./custom-tab/custom-tab.component";

@Component({
    selector: " app-custom-tabs",
    templateUrl: "./custom-tabs.component.html",
    styleUrls: ["./custom-tabs.component.scss"],
    standalone: false
})
export class CustomTabsComponent implements OnInit {
  @Output() selection = new EventEmitter<string>();
  @ContentChildren(CustomTabComponent) tabs: QueryList<CustomTabComponent>;
  @Input() type: string;

  ngOnInit(): void {
    console.log(this.type);
  }

  onSelect(tab: CustomTabComponent) {
    this.selection.emit(tab.key ?? tab.label);
  }
}
