/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaceAndPhaseDto { 
    /**
     * pace
     */
    pace: number;
    /**
     * effOffensePositional
     */
    effOffensePositional: number;
    /**
     * effFastBreak
     */
    effFastBreak: number;
    /**
     * effCounterGoal
     */
    effCounterGoal: number;
    /**
     * efficiency
     */
    efficiency: number;
    /**
     * shootingEfficiency
     */
    shootingEfficiency: number;
    /**
     * lostBallEfficiency
     */
    lostBallEfficiency: number;
    /**
     * gameId
     */
    gameId: number;
    /**
     * Date of the game
     */
    gameDate: string;
    /**
     * Home Team
     */
    homeTeam: string;
    /**
     * Visitor Team
     */
    visitorTeam: string;
    /**
     * result
     */
    result: string;
    /**
     * totalGoals
     */
    totalGoals: number;
    /**
     * totalGoals
     */
    totalSaves: number;
    /**
     * totalGoals
     */
    totalLostBalls: number;
}

