import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { db, GameLocalEntity } from 'src/app/db';
import { NGXLogger } from 'ngx-logger';
import { CoreService } from 'src/app/shared-services/core.service';
import { GameDto, PlayByPlayDto, PlayTimeDto } from 'src/app/api/hai-api';
import { GameEndStorageModel } from 'src/app/shared-services/storage-service/model/game-end-storage.model';
import { ToastController } from '@ionic/angular';
import { LoadingService } from '../loading/loading.service';

@Injectable({
    providedIn: 'root'
})
export class HouseKeepingService {

    private _core: CoreService;
    private _hasPendingUploads$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly logger: NGXLogger,
        private readonly toastCntl: ToastController,
        private readonly loadingService: LoadingService
    ) {
    }

    public initCore(core: CoreService) {
        this._core = core;
        this.checkForPendingUploads();
    }

    get hasPendingUploads$(): BehaviorSubject<boolean> {
        return this._hasPendingUploads$;
    }

    public checkForPendingUploads(): void {
        db.game.where('isUploadPending').equals(1).and(f => {
            const aeIds = this._core.accountEquipoService.accountEquipos$.value?.filter(row => !row.deleted).map(ae => ae.id);
            return aeIds && aeIds.includes(f.accountEquipoId);
        }).toArray().then((games) => {
            this.logger.debug('HouseKeepingService.checkForPendingUploads - number of pending games', games.length);
            if (games.length > 0) {
                this._hasPendingUploads$.next(true);
            } else {
                this._hasPendingUploads$.next(false);
            }
        });
    }

    public async processPendingGame(): Promise<void> {
        await this.loadingService.present();
        const accountEquipos = this._core.accountEquipoService.accountEquipos$.value;
        const pendingGames = accountEquipos ? await db.game.where('isUploadPending').equals(1).and(f => {
            const aeIds = this._core.accountEquipoService.accountEquipos$.value?.filter(row => !row.deleted).map(ae => ae.id);
            return aeIds && aeIds.includes(f.accountEquipoId);
        }).toArray() : [];
        for (const gameLocalEntity of pendingGames) {
            try {
                const gameEndModel = await this.preparePendingGame(gameLocalEntity);
                await this.saveGame(gameEndModel, gameLocalEntity.accountEquipoId);
                const gameDto = await this.readGameFromServer(gameEndModel.gameId, gameLocalEntity.accountEquipoId);
                this.recalculateStats(gameDto);
                const newGameEndModelWithStats = this.prepareGameEndModelWithStats(
                    gameDto,
                    gameEndModel.updateGameResultDto.playByPlayDto,
                    gameEndModel.updateGameResultDto.playTimeDto);
                await this.saveGame(newGameEndModelWithStats, gameLocalEntity.accountEquipoId);
                await db.game.where('srvId').equals(gameLocalEntity.srvId).modify({
                    isUploadPending: 0
                });
            } catch (err) {
                this.toastCntl
                    .create({
                        icon: 'close-outline',
                        color: 'warning',
                        message: `Statistics cannot be saved to the server, restore network connection and retry`,
                        duration: 5000,
                    })
                    .then((value) => value.present());
                this.logger.error('HouseKeeping.processPendingGame Error updating the Game writing to Local Storage', err);
            } finally {
                await this.checkForPendingUploads();
            }
        }
        await this.loadingService.dismiss();
    }

    private async preparePendingGame(gameLocalEntity: GameLocalEntity): Promise<GameEndStorageModel> {
        const fullPlayByPlay: PlayByPlayDto[] = await db.playByPlay.where('gameSrvId').equals(gameLocalEntity.srvId).toArray();
        const fullPlayerEvents: PlayTimeDto[] = await db.playTime.where('gameSrvId').equals(gameLocalEntity.srvId).toArray();
        return {
            gameId: gameLocalEntity.srvId,
            updateGameResultDto: {
                gameEnded: gameLocalEntity.gameEnded,
                goalsHome: gameLocalEntity.goalsHome,
                goalsVisitor: gameLocalEntity.goalsVisitor,
                playerStatsDto: [],
                teamStatsDto: [],
                lineupStatsDto: [],
                playByPlayDto: fullPlayByPlay,
                playTimeDto: fullPlayerEvents,
                gameDateTime: gameLocalEntity.date,
            }
        } as GameEndStorageModel;
    }

    private prepareGameEndModelWithStats(
        gameDto: GameDto,
        fullPlayByPlay: PlayByPlayDto[],
        fullPlayerEvents: PlayTimeDto[]
    ): GameEndStorageModel {
        return {
            gameId: gameDto.id,
            updateGameResultDto: {
                firstHalfEnded: true,
                secondHalfEnded: true,
                gameEnded: true,
                gameStatus: 'ended',
                goalsHome: gameDto.goalsHome, // TODO recalculate the final result
                goalsVisitor: gameDto.goalsVisitor,
                playerStatsDto: this._core.goalConsumerService
                    .transformToPlayerStatisticsDto(this._core.teamOverviewSubConsumerService.getPlayerStatisticsDto()),
                teamStatsDto: this._core.goalConsumerService
                    .transformToTeamStatisticsDto(this._core.overviewConsumerService.generateTeamStatsDto()),
                lineupStatsDto: this._core.goalConsumerService.transformToLineupStatisticsDto(),
                playByPlayDto: fullPlayByPlay,
                playTimeDto: fullPlayerEvents,
                gameDateTime: gameDto.date,
            },
        } as GameEndStorageModel;
    }

    private async saveGame(gameEndModel: GameEndStorageModel, accountEquipo: number): Promise<void> {
        await this._core.gameDataService.updateGame(gameEndModel.gameId, gameEndModel.updateGameResultDto, accountEquipo);
    }

    private async readGameFromServer(gameId: number, accountEquipo: number): Promise<GameDto> {
        await this._core.gameDataService.readGameSystems(false);
        await this._core.gameDataService.getGameSystemEnabledFlag(false);
        return await this._core.gameDataService.readGame(gameId, accountEquipo, false);
    }

    private recalculateStats(gameDto: GameDto): void {
        this._core.gameStatsHandlerService.init(gameDto);
        gameDto.playByPlayDto.sort((a, b) => a.orderId - b.orderId)
            .forEach(pbp => this._core.gameStatsHandlerService.addPlayByPlayEvent(pbp));

    }
}
