import { PlayByPlayDto } from 'src/app/api/hai-api';
import { GameSystemStatsViewModel } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-view.model';
import { transformPlayByPlayToViewModel } from 'src/app/main/pages/aehandler-module/pages/dashboard-module/team-quick-stats.helper';

export const convertPlayByPlayToGameSystem = (playByPlayRows: PlayByPlayDto[]): GameSystemStatsViewModel[] =>
    transformPlayByPlayToViewModel(playByPlayRows.filter(pbp => pbp.gameSystem));

export const getBackgroundColor = (colorCode: string): string => {
    if (colorCode === 'red') {
        return '#eb445a';
    } else if (colorCode === 'blue') {
        return '#2aacd3';
    } else if (colorCode === 'yellow') {
        return '#ECB22E';
    } else {
        return '#2EB67D';
    }
};
