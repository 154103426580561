export const pdfColors = {
  bgBaseLightGrey: '#f1f5f8',
  bgRectTableLightBlue: '#CBE6F5',
  bgRectTableDarktBlue: '#0E375F',
  bgRectTableBlue: '#36ABE0',
  bgRectTableWhite: '#FFFFFF'
}

// CBE6F5
// 36ABE0
// 1A375C