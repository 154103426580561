import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CoreService } from 'src/app/shared-services/core.service';
import {
  OverviewStatsAggregatedViewModel,
  OverviewStatsByPhaseModelList, OverviewStatsExtendedOffenseSystemByPhaseModelList, OverviewStatsOffenseSystemByPhaseModelList
} from '@handballai/stats-calculation';

type TeamSelector = 'home'|'away';

type DefenseSelector = '6_0'|'5_1'|'3_2_1'|'4_2'|'OTHER';

@Component({
  selector: 'app-overview-stats',
  templateUrl: './overview-stats.component.html',
  styleUrls: ['./overview-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewStatsComponent implements OnInit {

  private _overviewStatsAggregatedViewModel: OverviewStatsAggregatedViewModel[] = [];

  private _homeOverviewStatsByPhaseModelList: OverviewStatsByPhaseModelList[] = [];
  private _visitorOverviewStatsByPhaseModelList: OverviewStatsByPhaseModelList[] = [];
  private _selectedHomeOverviewStatsByPhaseModelList: OverviewStatsByPhaseModelList = [];
  private _selectedVisitorOverviewStatsByPhaseModelList: OverviewStatsByPhaseModelList = [];

  private _homeOverStatsOffenseSystemModelList: OverviewStatsExtendedOffenseSystemByPhaseModelList[] = [];
  private _visitorOverStatsOffenseSystemModelList: OverviewStatsExtendedOffenseSystemByPhaseModelList[] = [];
  private _selectedHomeOverStatsOffenseSystemModelList: OverviewStatsExtendedOffenseSystemByPhaseModelList = [];
  private _selectedVisitorOverStatsOffenseSystemModelList: OverviewStatsExtendedOffenseSystemByPhaseModelList = [];

  private _teamSelected: TeamSelector = 'home';
  private _selectedDefenseSystem: DefenseSelector = '6_0';
  private _selectedDefenseIndex = 0;

  public teamsAllColsShown = false;
  public defenseSystemAllColsShown = false;

  columns = [
    { prop: 'teamName', name: '', sortable: false, frozenLeft: true },
    { prop: 'goals', name: 'Goals' },
    { prop: 'saves', name: 'Saves' },
    { prop: 'gkSaves', name: 'Saves' },
    { prop: 'postOuts', name: 'Post Outs' },
    { prop: 'lostBalls', name: 'Lost Balls' },
    { prop: 'possessions', name: 'Possessions' },
    { prop: 'efficiency', name: 'Efficiency' },
    { prop: 'shootingEfficiency', name: 'Shooting Efficiency' },
    { prop: 'lostBallEfficiency', name: '% Lost Balls' },
    { prop: 'equalityEfficiency', name: 'Equality Efficiency' },
    { prop: 'superiorityEfficiency', name: 'Superiority Efficiency' },
    { prop: 'inferiorityEfficiency', name: 'Inferiority Efficiency' },
    { prop: 'percent', name: 'Victory %' },
  ];

  teamColumnsT1 = [
    { prop: 'phase', name: '', sortable: false, frozenLeft: true },
    { prop: 'goals', name: 'Goals' },
    { prop: 'saves', name: 'Saves' },
    { prop: 'gkSaves', name: 'Saves' },
    { prop: 'postOuts', name: 'Posts/Outs' },
    { prop: 'lostBalls', name: 'Lost Balls' },
    { prop: 'possessions', name: 'Possessions' },
    { prop: 'efficiency', name: 'Efficiency' },
    { prop: 'shootingEfficiency', name: 'Shooting Efficiency' },
    { prop: 'lostBallEfficiency', name: '% Lost Balls' },
    { prop: 'possessionsWithoutFoul', name: 'Possessions without Fouls' },
    { prop: 'possessionsWithFoul', name: 'Possessions with Foul(s)' },
  ];
  teamColumnsT2 = [
    { prop: 'phase', name: '', sortable: false, frozenLeft: true },
    { prop: 'equalityEfficiency', name: 'Equality Eff %' },
    { prop: 'equalityFivePlusOneVsSixEfficiency', name: 'Equality 5+1vs6 Eff %' },
    { prop: 'equalitySixVsFivePlusOneEfficiency', name: 'Equality 6vs5+1 Eff %'  },
    { prop: 'superioritySixVsFiveEfficiency', name: 'Superiority 6vs5 Eff %' },
    { prop: 'superioritySevenVsSixEfficiency', name: 'Superiority 7vs6 Eff %' },
    { prop: 'superiorityOtherEfficiency', name: 'Superiority Other Eff %'  },
    { prop: 'inferiorityFiveVsSixEfficiency', name: 'Inferiority 5vs6 Eff %' },
    { prop: 'inferioritySixVsSevenEfficiency', name: 'Inferiority 6vs7 Eff %' },
    { prop: 'inferiorityOtherEfficiency', name: 'Inferiority Other Eff %'  },
  ];

  // Definitions
  defenseSystems = [
    { code: '6_0', desc: 'Against 6:0', index: 0 },
    { code: '5_1', desc: 'Against 5:1', index: 1 },
    { code: '3_2_1', desc: 'Against 3:2:1', index: 2 },
    { code: '4_2', desc: 'Against 4:2', index: 3 },
    { code: 'OTHER', desc: 'Against OTHER', index: 4 },
  ];

  public getRowClass = (row: any) => {
    return row.row.teamName === this.homeTeamName ? {
      'home-row-color': true
    } : {
      'visitor-row-color': true
    };
  }


  constructor(
    private readonly logger: NGXLogger,
    public readonly core: CoreService,
  ) { }

  ngOnInit(): void {
  }


  get overviewStatsAggregatedViewModel(): OverviewStatsAggregatedViewModel[] {
    return this._overviewStatsAggregatedViewModel;
  }

  @Input()
  set overviewStatsAggregatedViewModel(value: OverviewStatsAggregatedViewModel[]) {
    this.logger.debug('OverviewStatsComponent.overviewStatsAggregatedViewModel', value);
    if (value) {
      this._overviewStatsAggregatedViewModel = value;
    }
  }


  @Input()
  set homeOverviewStatsByPhaseModelList(value: OverviewStatsByPhaseModelList[]) {
    this.logger.debug('OverviewStatsComponent.homeOverviewStatsByPhaseModelList', value);
    if (value) {
      this._homeOverviewStatsByPhaseModelList = value;
      this.assignOverviewStatsByPhaseModelList();
    }
  }

  @Input()
  set visitorOverviewStatsByPhaseModelList(value: OverviewStatsByPhaseModelList[]) {
    this.logger.debug('OverviewStatsComponent.visitorOverviewStatsByPhaseModelList', value);
    if (value) {
      this._visitorOverviewStatsByPhaseModelList = value;
      this.assignOverviewStatsByPhaseModelList();
    }
  }

  get selectedHomeOverviewStatsByPhaseModelList(): OverviewStatsByPhaseModelList {
    return this._selectedHomeOverviewStatsByPhaseModelList;
  }


  get selectedVisitorOverviewStatsByPhaseModelList(): OverviewStatsByPhaseModelList {
    return this._selectedVisitorOverviewStatsByPhaseModelList;
  }

  public onExpandTeamsTableColumnsClick() {
    this.teamsAllColsShown = !this.teamsAllColsShown;
  }

  public onExpandDefenseSystemTableColumnsClick() {
    this.defenseSystemAllColsShown = !this.defenseSystemAllColsShown;
  }

  @Input()
  set homeOverStatsOffenseSystemModelList(value: OverviewStatsExtendedOffenseSystemByPhaseModelList[]) {
    this.logger.debug('OverviewStatsComponent.homeOverStatsOffenseSystemModelList', value);
    if (value) {
      this._homeOverStatsOffenseSystemModelList = value;
      this.assignOverviewStatsByPhaseModelList();
    }
  }

  @Input()
  set visitorOverStatsOffenseSystemModelList(value: OverviewStatsExtendedOffenseSystemByPhaseModelList[]) {
    this.logger.debug('OverviewStatsComponent.visitorOverStatsOffenseSystemModelList', value);
    if (value) {
      this._visitorOverStatsOffenseSystemModelList = value;
      this.assignOverviewStatsByPhaseModelList();
    }
  }


  get selectedHomeOverStatsOffenseSystemModelList(): OverviewStatsExtendedOffenseSystemByPhaseModelList {
    return this._selectedHomeOverStatsOffenseSystemModelList;
  }

  get selectedVisitorOverStatsOffenseSystemModelList(): OverviewStatsExtendedOffenseSystemByPhaseModelList {
    return this._selectedVisitorOverStatsOffenseSystemModelList;
  }

  get selectedTeam(): TeamSelector {
    return this._teamSelected;
  }

  get selectedDefenseSystem(): DefenseSelector {
    return this._selectedDefenseSystem;
  }

  get homeTeamName(): string {
    if (this._overviewStatsAggregatedViewModel && this._overviewStatsAggregatedViewModel.length === 2) {
      return this._overviewStatsAggregatedViewModel[0].teamName;
    }
    return 'HOME TEAM';
  }

  get visitorTeamName(): string {
    if (this._overviewStatsAggregatedViewModel && this._overviewStatsAggregatedViewModel.length === 2) {
      return this._overviewStatsAggregatedViewModel[1].teamName;
    }
    return 'AWAY TEAM';
  }

  public onSelectTeam(selector: TeamSelector): void {
      this._teamSelected = selector;
      this.assignOverviewStatsByPhaseModelList();
  }

  public onSelectDefenseSystem(selector: any): void {
    this._selectedDefenseSystem = selector;
    switch (selector) {
      case 'Against 5:1': this._selectedDefenseIndex = 1; break;
      case 'Against 4:2': this._selectedDefenseIndex = 3; break;
      case 'Against 3:2:1': this._selectedDefenseIndex = 2; break;
      case 'Against OTHER': this._selectedDefenseIndex = 4; break;
      default: this._selectedDefenseIndex = 0; break;
    }
    this.assignOverviewStatsByPhaseModelList();
  }

  private assignOverviewStatsByPhaseModelList(): void {
    if (this._homeOverviewStatsByPhaseModelList && this._homeOverviewStatsByPhaseModelList.length === 5) {
      this._selectedHomeOverviewStatsByPhaseModelList = [...this._homeOverviewStatsByPhaseModelList[this._selectedDefenseIndex]].filter(stats => stats.phase !== 'PHASE_UNDEFINED');
    }
    if (this._homeOverStatsOffenseSystemModelList && this._homeOverStatsOffenseSystemModelList.length === 5) {
      this._selectedHomeOverStatsOffenseSystemModelList = [...this._homeOverStatsOffenseSystemModelList[this._selectedDefenseIndex]].filter(stats => stats.phase !== 'PHASE_UNDEFINED');
    }
    if (this._visitorOverviewStatsByPhaseModelList && this._visitorOverviewStatsByPhaseModelList.length === 5) {
      this._selectedVisitorOverviewStatsByPhaseModelList = [...this._visitorOverviewStatsByPhaseModelList[this._selectedDefenseIndex]].filter(stats => stats.phase !== 'PHASE_UNDEFINED');
    }
    if (this._visitorOverStatsOffenseSystemModelList && this._visitorOverStatsOffenseSystemModelList.length === 5) {
      this._selectedVisitorOverStatsOffenseSystemModelList = [...this._visitorOverStatsOffenseSystemModelList[this._selectedDefenseIndex]].filter(stats => stats.phase !== 'PHASE_UNDEFINED');
    }
  }
}
