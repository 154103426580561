import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from '../../../../../shared-services/core.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-edit-position-modal',
  templateUrl: './edit-position-modal.component.html',
  styleUrls: ['./edit-position-modal.component.scss'],
})
export class EditPositionModalComponent  implements OnInit {

  @Input() playerName: string;
  @Input() currentPosition: string;

  startingPositions = [
    { name: "LP (lineplayer)", code: "lp" },
    { name: "LW (leftwing)", code: "lw" },
    { name: "LB (leftback)", code: "lb" },
    { name: "CB (centerback)", code: "cb" },
    { name: "RB (rightback)", code: "rb" },
    { name: "RW (rightwing)", code: "rw" },
    { name: "GK (goalkeeper)", code: "gk" },
  ];

  constructor(private readonly core: CoreService,
              private readonly popoverController: PopoverController
  ) { }

  ngOnInit() {}

  public async onCancel(): Promise<void> {
    await this.popoverController.dismiss();
  }

  public async onConfirm(): Promise<void> {
    await this.popoverController.dismiss({ position: this.currentPosition });
  }

}
