/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LineupStatsDto { 
    /**
     * id
     */
    id?: number;
    /**
     * team id
     */
    teamId: number;
    /**
     * team name
     */
    teamName: string;
    /**
     * Lineup Setup
     */
    lineup: string;
    /**
     * possessions played
     */
    possessionsPlayed: number;
    /**
     * possessions offense
     */
    possessionsOffense: number;
    /**
     * possessions defense
     */
    possessionsDefense: number;
    /**
     * goals
     */
    goals: number;
    /**
     * saves
     */
    saves: number;
    /**
     * lostBalls
     */
    lostBalls: number;
    /**
     * efficiencyDefense
     */
    efficiencyDefense: number;
    /**
     * efficiencyOffense
     */
    efficiencyOffense: number;
    /**
     * noGoals
     */
    noGoals: number;
}

