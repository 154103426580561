/**
 * players-api
 * players-api API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MyTeamPlayerDto { 
    id?: number;
    firstName: string;
    lastName: string;
    email: string;
    tempPassword?: boolean;
    linkedViaFolder?: string;
    imageUrl?: string;
    position?: string;
    dateOfBirth?: string;
    password?: string;
}

