<ion-content class="background">
  <ion-card color="primary" class="background">
    <ion-card-header>
      <div class="w-full ion-text-center">
        <ion-card-subtitle>
          <ion-icon name="time-outline" class="global__icon-text timer-popover__headline mr-3"></ion-icon>
          <span class="global__icon-text timer-popover__headline">{{'Game control' | translate}}</span>
        </ion-card-subtitle>
      </div>
    </ion-card-header>
    <ion-card-content>
      <div class="flex flex-row w-full mt-4 mb-4">
        <button class="timer-popover__button focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full p-1 rounded-3xl mr-0.5"
                (click)="onExtraTimeConfirm()">
          <div class="flex items-center">
            <div class="w-1/5">
              <ion-icon name="play-circle-outline" class="global__icon-text"></ion-icon>
            </div>
            <div *ngIf="!_core.videoTrackerService.isSecondHalfTimeETStarted && !_core.handballTimerService.isSecondHalfTimeETStarted" class="global__icon-text w-4/5">{{'Extra Time' | translate}}</div>
            <div *ngIf="_core.videoTrackerService.isSecondHalfTimeETStarted || _core.handballTimerService.isSecondHalfTimeETStarted" class="global__icon-text w-4/5">{{'Penalties (7M)' | translate}}</div>
          </div>
        </button>
        <button class="timer-popover__button danger focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full p-1 rounded-3xl mr-0.5"
                (click)="onEndGameConfirm()">
          <div class="flex items-center">
            <div class="w-1/5">
              <ion-icon name="exit-outline" class="global__icon-text"></ion-icon>
            </div>
            <div class="global__icon-text w-4/5">{{'End Game' | translate}}</div>
          </div>
        </button>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>
