/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { EntityFilterDto } from '../model/models';
import { GameListFilterDto } from '../model/models';
import { PaceAndPhaseDto } from '../model/models';
import { PlayByPlayDto } from '../model/models';
import { PlayerScoreStatsDto } from '../model/models';
import { PlayerStatsAggregateDto } from '../model/models';
import { PlayerStatsDto } from '../model/models';
import { ScoutingPlayByPlayDto } from '../model/models';
import { TeamStatsAggregateDto } from '../model/models';
import { TeamsStatsDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ExternalStatsService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param uid 
     * @param aid 
     * @param teamIds list of teams ids
     * @param seasonIds list of seasons ids
     * @param gameType game type LITE_MODE or COMPLETE_MODE
     * @param isScouting check for filter scouting games
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetGamesDescByTeam(uid: string, aid: string, teamIds: string, seasonIds: string, gameType: string, isScouting: boolean, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GameListFilterDto>>;
    public statsHandlerControllerGetGamesDescByTeam(uid: string, aid: string, teamIds: string, seasonIds: string, gameType: string, isScouting: boolean, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GameListFilterDto>>>;
    public statsHandlerControllerGetGamesDescByTeam(uid: string, aid: string, teamIds: string, seasonIds: string, gameType: string, isScouting: boolean, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GameListFilterDto>>>;
    public statsHandlerControllerGetGamesDescByTeam(uid: string, aid: string, teamIds: string, seasonIds: string, gameType: string, isScouting: boolean, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetGamesDescByTeam.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetGamesDescByTeam.');
        }
        if (teamIds === null || teamIds === undefined) {
            throw new Error('Required parameter teamIds was null or undefined when calling statsHandlerControllerGetGamesDescByTeam.');
        }
        if (seasonIds === null || seasonIds === undefined) {
            throw new Error('Required parameter seasonIds was null or undefined when calling statsHandlerControllerGetGamesDescByTeam.');
        }
        if (gameType === null || gameType === undefined) {
            throw new Error('Required parameter gameType was null or undefined when calling statsHandlerControllerGetGamesDescByTeam.');
        }
        if (isScouting === null || isScouting === undefined) {
            throw new Error('Required parameter isScouting was null or undefined when calling statsHandlerControllerGetGamesDescByTeam.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (teamIds !== undefined && teamIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>teamIds, 'teamIds');
        }
        if (seasonIds !== undefined && seasonIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>seasonIds, 'seasonIds');
        }
        if (gameType !== undefined && gameType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameType, 'gameType');
        }
        if (isScouting !== undefined && isScouting !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isScouting, 'isScouting');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<GameListFilterDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/team/games`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param tid 
     * @param gameType game type LITE_MODE or COMPLETE_MODE
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetLast6TeamEssentialsForPhaseAndPace(uid: string, aid: string, tid: string, gameType: string, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PaceAndPhaseDto>>;
    public statsHandlerControllerGetLast6TeamEssentialsForPhaseAndPace(uid: string, aid: string, tid: string, gameType: string, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PaceAndPhaseDto>>>;
    public statsHandlerControllerGetLast6TeamEssentialsForPhaseAndPace(uid: string, aid: string, tid: string, gameType: string, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PaceAndPhaseDto>>>;
    public statsHandlerControllerGetLast6TeamEssentialsForPhaseAndPace(uid: string, aid: string, tid: string, gameType: string, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetLast6TeamEssentialsForPhaseAndPace.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetLast6TeamEssentialsForPhaseAndPace.');
        }
        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling statsHandlerControllerGetLast6TeamEssentialsForPhaseAndPace.');
        }
        if (gameType === null || gameType === undefined) {
            throw new Error('Required parameter gameType was null or undefined when calling statsHandlerControllerGetLast6TeamEssentialsForPhaseAndPace.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameType !== undefined && gameType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameType, 'gameType');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<PaceAndPhaseDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/team/${encodeURIComponent(String(tid))}/essential-games`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gameIds list of game ids
     * @param isScouting check for filter scouting games
     * @param teamIds list of teams ids
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetMultiplePlayerAggregateStatsForTotals(uid: string, aid: string, gameIds: string, isScouting: boolean, teamIds: string, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PlayerStatsAggregateDto>>;
    public statsHandlerControllerGetMultiplePlayerAggregateStatsForTotals(uid: string, aid: string, gameIds: string, isScouting: boolean, teamIds: string, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PlayerStatsAggregateDto>>>;
    public statsHandlerControllerGetMultiplePlayerAggregateStatsForTotals(uid: string, aid: string, gameIds: string, isScouting: boolean, teamIds: string, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PlayerStatsAggregateDto>>>;
    public statsHandlerControllerGetMultiplePlayerAggregateStatsForTotals(uid: string, aid: string, gameIds: string, isScouting: boolean, teamIds: string, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetMultiplePlayerAggregateStatsForTotals.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetMultiplePlayerAggregateStatsForTotals.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetMultiplePlayerAggregateStatsForTotals.');
        }
        if (isScouting === null || isScouting === undefined) {
            throw new Error('Required parameter isScouting was null or undefined when calling statsHandlerControllerGetMultiplePlayerAggregateStatsForTotals.');
        }
        if (teamIds === null || teamIds === undefined) {
            throw new Error('Required parameter teamIds was null or undefined when calling statsHandlerControllerGetMultiplePlayerAggregateStatsForTotals.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (isScouting !== undefined && isScouting !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isScouting, 'isScouting');
        }
        if (teamIds !== undefined && teamIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>teamIds, 'teamIds');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<PlayerStatsAggregateDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/multiplayertotal`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gameIds list of game ids
     * @param isScouting check for filter scouting games
     * @param teamIds list of teams ids
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetMultipleTeamsAggregateStatsForTotals(uid: string, aid: string, gameIds: string, isScouting: boolean, teamIds: string, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<TeamStatsAggregateDto>>;
    public statsHandlerControllerGetMultipleTeamsAggregateStatsForTotals(uid: string, aid: string, gameIds: string, isScouting: boolean, teamIds: string, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<TeamStatsAggregateDto>>>;
    public statsHandlerControllerGetMultipleTeamsAggregateStatsForTotals(uid: string, aid: string, gameIds: string, isScouting: boolean, teamIds: string, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<TeamStatsAggregateDto>>>;
    public statsHandlerControllerGetMultipleTeamsAggregateStatsForTotals(uid: string, aid: string, gameIds: string, isScouting: boolean, teamIds: string, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetMultipleTeamsAggregateStatsForTotals.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetMultipleTeamsAggregateStatsForTotals.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetMultipleTeamsAggregateStatsForTotals.');
        }
        if (isScouting === null || isScouting === undefined) {
            throw new Error('Required parameter isScouting was null or undefined when calling statsHandlerControllerGetMultipleTeamsAggregateStatsForTotals.');
        }
        if (teamIds === null || teamIds === undefined) {
            throw new Error('Required parameter teamIds was null or undefined when calling statsHandlerControllerGetMultipleTeamsAggregateStatsForTotals.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (isScouting !== undefined && isScouting !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isScouting, 'isScouting');
        }
        if (teamIds !== undefined && teamIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>teamIds, 'teamIds');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<TeamStatsAggregateDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/multiteamstotal`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param tid 
     * @param gameIds list of game ids
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetPlayByPlayForGameSystem(uid: string, aid: string, tid: string, gameIds: string, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PlayByPlayDto>>;
    public statsHandlerControllerGetPlayByPlayForGameSystem(uid: string, aid: string, tid: string, gameIds: string, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PlayByPlayDto>>>;
    public statsHandlerControllerGetPlayByPlayForGameSystem(uid: string, aid: string, tid: string, gameIds: string, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PlayByPlayDto>>>;
    public statsHandlerControllerGetPlayByPlayForGameSystem(uid: string, aid: string, tid: string, gameIds: string, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetPlayByPlayForGameSystem.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetPlayByPlayForGameSystem.');
        }
        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling statsHandlerControllerGetPlayByPlayForGameSystem.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetPlayByPlayForGameSystem.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<PlayByPlayDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/team/${encodeURIComponent(String(tid))}/pbp-game-system`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param pid 
     * @param gameIds list of game ids
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetPlayByPlayForGameSystemByPlayer(uid: string, aid: string, pid: string, gameIds: string, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PlayByPlayDto>>;
    public statsHandlerControllerGetPlayByPlayForGameSystemByPlayer(uid: string, aid: string, pid: string, gameIds: string, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PlayByPlayDto>>>;
    public statsHandlerControllerGetPlayByPlayForGameSystemByPlayer(uid: string, aid: string, pid: string, gameIds: string, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PlayByPlayDto>>>;
    public statsHandlerControllerGetPlayByPlayForGameSystemByPlayer(uid: string, aid: string, pid: string, gameIds: string, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetPlayByPlayForGameSystemByPlayer.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetPlayByPlayForGameSystemByPlayer.');
        }
        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling statsHandlerControllerGetPlayByPlayForGameSystemByPlayer.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetPlayByPlayForGameSystemByPlayer.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<PlayByPlayDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/player/${encodeURIComponent(String(pid))}/pbp-game-system`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gameIds list of game ids
     * @param returnEventsWithoutVideo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetPlayByPlayForScoutingByIds(uid: string, aid: string, gameIds: string, returnEventsWithoutVideo: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ScoutingPlayByPlayDto>>;
    public statsHandlerControllerGetPlayByPlayForScoutingByIds(uid: string, aid: string, gameIds: string, returnEventsWithoutVideo: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ScoutingPlayByPlayDto>>>;
    public statsHandlerControllerGetPlayByPlayForScoutingByIds(uid: string, aid: string, gameIds: string, returnEventsWithoutVideo: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ScoutingPlayByPlayDto>>>;
    public statsHandlerControllerGetPlayByPlayForScoutingByIds(uid: string, aid: string, gameIds: string, returnEventsWithoutVideo: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByIds.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByIds.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByIds.');
        }
        if (returnEventsWithoutVideo === null || returnEventsWithoutVideo === undefined) {
            throw new Error('Required parameter returnEventsWithoutVideo was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByIds.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (returnEventsWithoutVideo !== undefined && returnEventsWithoutVideo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>returnEventsWithoutVideo, 'returnEventsWithoutVideo');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ScoutingPlayByPlayDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/pbp-scouting`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param pid 
     * @param gameIds list of game ids
     * @param returnEventsWithoutVideo 
     * @param invertTarget invert search target
     * @param disableTeamFilter disable team filter
     * @param requireDefensePlayer disable team filter
     * @param targetAssist invert search target
     * @param eventNames list of event names
     * @param defenseSystem filter by defense system
     * @param offenseSystem filter by offense system
     * @param phase filter by phase
     * @param executionPosition filter by executionPosition
     * @param shotLocation filter by shotLocation
     * @param gameSystem filter by gameSystem
     * @param minSecondsSinceStartOfGame minimun seconds since start of game
     * @param maxSecondsSinceStartOfGame maximun seconds since start of game
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetPlayByPlayForScoutingByPlayer(uid: string, aid: string, pid: string, gameIds: string, returnEventsWithoutVideo: boolean, invertTarget?: boolean, disableTeamFilter?: boolean, requireDefensePlayer?: boolean, targetAssist?: boolean, eventNames?: string, defenseSystem?: string, offenseSystem?: string, phase?: string, executionPosition?: string, shotLocation?: string, gameSystem?: string, minSecondsSinceStartOfGame?: number, maxSecondsSinceStartOfGame?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ScoutingPlayByPlayDto>>;
    public statsHandlerControllerGetPlayByPlayForScoutingByPlayer(uid: string, aid: string, pid: string, gameIds: string, returnEventsWithoutVideo: boolean, invertTarget?: boolean, disableTeamFilter?: boolean, requireDefensePlayer?: boolean, targetAssist?: boolean, eventNames?: string, defenseSystem?: string, offenseSystem?: string, phase?: string, executionPosition?: string, shotLocation?: string, gameSystem?: string, minSecondsSinceStartOfGame?: number, maxSecondsSinceStartOfGame?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ScoutingPlayByPlayDto>>>;
    public statsHandlerControllerGetPlayByPlayForScoutingByPlayer(uid: string, aid: string, pid: string, gameIds: string, returnEventsWithoutVideo: boolean, invertTarget?: boolean, disableTeamFilter?: boolean, requireDefensePlayer?: boolean, targetAssist?: boolean, eventNames?: string, defenseSystem?: string, offenseSystem?: string, phase?: string, executionPosition?: string, shotLocation?: string, gameSystem?: string, minSecondsSinceStartOfGame?: number, maxSecondsSinceStartOfGame?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ScoutingPlayByPlayDto>>>;
    public statsHandlerControllerGetPlayByPlayForScoutingByPlayer(uid: string, aid: string, pid: string, gameIds: string, returnEventsWithoutVideo: boolean, invertTarget?: boolean, disableTeamFilter?: boolean, requireDefensePlayer?: boolean, targetAssist?: boolean, eventNames?: string, defenseSystem?: string, offenseSystem?: string, phase?: string, executionPosition?: string, shotLocation?: string, gameSystem?: string, minSecondsSinceStartOfGame?: number, maxSecondsSinceStartOfGame?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByPlayer.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByPlayer.');
        }
        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByPlayer.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByPlayer.');
        }
        if (returnEventsWithoutVideo === null || returnEventsWithoutVideo === undefined) {
            throw new Error('Required parameter returnEventsWithoutVideo was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByPlayer.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (invertTarget !== undefined && invertTarget !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>invertTarget, 'invertTarget');
        }
        if (disableTeamFilter !== undefined && disableTeamFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>disableTeamFilter, 'disableTeamFilter');
        }
        if (requireDefensePlayer !== undefined && requireDefensePlayer !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requireDefensePlayer, 'requireDefensePlayer');
        }
        if (targetAssist !== undefined && targetAssist !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>targetAssist, 'targetAssist');
        }
        if (eventNames !== undefined && eventNames !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>eventNames, 'eventNames');
        }
        if (defenseSystem !== undefined && defenseSystem !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>defenseSystem, 'defenseSystem');
        }
        if (offenseSystem !== undefined && offenseSystem !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offenseSystem, 'offenseSystem');
        }
        if (phase !== undefined && phase !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>phase, 'phase');
        }
        if (executionPosition !== undefined && executionPosition !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>executionPosition, 'executionPosition');
        }
        if (shotLocation !== undefined && shotLocation !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>shotLocation, 'shotLocation');
        }
        if (gameSystem !== undefined && gameSystem !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameSystem, 'gameSystem');
        }
        if (minSecondsSinceStartOfGame !== undefined && minSecondsSinceStartOfGame !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>minSecondsSinceStartOfGame, 'minSecondsSinceStartOfGame');
        }
        if (maxSecondsSinceStartOfGame !== undefined && maxSecondsSinceStartOfGame !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>maxSecondsSinceStartOfGame, 'maxSecondsSinceStartOfGame');
        }
        if (returnEventsWithoutVideo !== undefined && returnEventsWithoutVideo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>returnEventsWithoutVideo, 'returnEventsWithoutVideo');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ScoutingPlayByPlayDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/player/${encodeURIComponent(String(pid))}/pbp-scouting`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param tid 
     * @param gameIds list of game ids
     * @param returnEventsWithoutVideo 
     * @param invertTarget invert search target
     * @param disableTeamFilter disable team filter
     * @param requireDefensePlayer disable team filter
     * @param targetAssist invert search target
     * @param eventNames list of event names
     * @param defenseSystem filter by defense system
     * @param offenseSystem filter by offense system
     * @param phase filter by phase
     * @param executionPosition filter by executionPosition
     * @param shotLocation filter by shotLocation
     * @param gameSystem filter by gameSystem
     * @param minSecondsSinceStartOfGame minimun seconds since start of game
     * @param maxSecondsSinceStartOfGame maximun seconds since start of game
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetPlayByPlayForScoutingByTeam(uid: string, aid: string, tid: string, gameIds: string, returnEventsWithoutVideo: boolean, invertTarget?: boolean, disableTeamFilter?: boolean, requireDefensePlayer?: boolean, targetAssist?: boolean, eventNames?: string, defenseSystem?: string, offenseSystem?: string, phase?: string, executionPosition?: string, shotLocation?: string, gameSystem?: string, minSecondsSinceStartOfGame?: number, maxSecondsSinceStartOfGame?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ScoutingPlayByPlayDto>>;
    public statsHandlerControllerGetPlayByPlayForScoutingByTeam(uid: string, aid: string, tid: string, gameIds: string, returnEventsWithoutVideo: boolean, invertTarget?: boolean, disableTeamFilter?: boolean, requireDefensePlayer?: boolean, targetAssist?: boolean, eventNames?: string, defenseSystem?: string, offenseSystem?: string, phase?: string, executionPosition?: string, shotLocation?: string, gameSystem?: string, minSecondsSinceStartOfGame?: number, maxSecondsSinceStartOfGame?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ScoutingPlayByPlayDto>>>;
    public statsHandlerControllerGetPlayByPlayForScoutingByTeam(uid: string, aid: string, tid: string, gameIds: string, returnEventsWithoutVideo: boolean, invertTarget?: boolean, disableTeamFilter?: boolean, requireDefensePlayer?: boolean, targetAssist?: boolean, eventNames?: string, defenseSystem?: string, offenseSystem?: string, phase?: string, executionPosition?: string, shotLocation?: string, gameSystem?: string, minSecondsSinceStartOfGame?: number, maxSecondsSinceStartOfGame?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ScoutingPlayByPlayDto>>>;
    public statsHandlerControllerGetPlayByPlayForScoutingByTeam(uid: string, aid: string, tid: string, gameIds: string, returnEventsWithoutVideo: boolean, invertTarget?: boolean, disableTeamFilter?: boolean, requireDefensePlayer?: boolean, targetAssist?: boolean, eventNames?: string, defenseSystem?: string, offenseSystem?: string, phase?: string, executionPosition?: string, shotLocation?: string, gameSystem?: string, minSecondsSinceStartOfGame?: number, maxSecondsSinceStartOfGame?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByTeam.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByTeam.');
        }
        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByTeam.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByTeam.');
        }
        if (returnEventsWithoutVideo === null || returnEventsWithoutVideo === undefined) {
            throw new Error('Required parameter returnEventsWithoutVideo was null or undefined when calling statsHandlerControllerGetPlayByPlayForScoutingByTeam.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (invertTarget !== undefined && invertTarget !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>invertTarget, 'invertTarget');
        }
        if (disableTeamFilter !== undefined && disableTeamFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>disableTeamFilter, 'disableTeamFilter');
        }
        if (requireDefensePlayer !== undefined && requireDefensePlayer !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requireDefensePlayer, 'requireDefensePlayer');
        }
        if (targetAssist !== undefined && targetAssist !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>targetAssist, 'targetAssist');
        }
        if (eventNames !== undefined && eventNames !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>eventNames, 'eventNames');
        }
        if (defenseSystem !== undefined && defenseSystem !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>defenseSystem, 'defenseSystem');
        }
        if (offenseSystem !== undefined && offenseSystem !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offenseSystem, 'offenseSystem');
        }
        if (phase !== undefined && phase !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>phase, 'phase');
        }
        if (executionPosition !== undefined && executionPosition !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>executionPosition, 'executionPosition');
        }
        if (shotLocation !== undefined && shotLocation !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>shotLocation, 'shotLocation');
        }
        if (gameSystem !== undefined && gameSystem !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameSystem, 'gameSystem');
        }
        if (minSecondsSinceStartOfGame !== undefined && minSecondsSinceStartOfGame !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>minSecondsSinceStartOfGame, 'minSecondsSinceStartOfGame');
        }
        if (maxSecondsSinceStartOfGame !== undefined && maxSecondsSinceStartOfGame !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>maxSecondsSinceStartOfGame, 'maxSecondsSinceStartOfGame');
        }
        if (returnEventsWithoutVideo !== undefined && returnEventsWithoutVideo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>returnEventsWithoutVideo, 'returnEventsWithoutVideo');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ScoutingPlayByPlayDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/team/${encodeURIComponent(String(tid))}/pbp-scouting`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param tid 
     * @param gameType game type LITE_MODE or COMPLETE_MODE
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetPlayerNames(uid: string, aid: string, tid: string, gameType: string, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<EntityFilterDto>>;
    public statsHandlerControllerGetPlayerNames(uid: string, aid: string, tid: string, gameType: string, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<EntityFilterDto>>>;
    public statsHandlerControllerGetPlayerNames(uid: string, aid: string, tid: string, gameType: string, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<EntityFilterDto>>>;
    public statsHandlerControllerGetPlayerNames(uid: string, aid: string, tid: string, gameType: string, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetPlayerNames.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetPlayerNames.');
        }
        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling statsHandlerControllerGetPlayerNames.');
        }
        if (gameType === null || gameType === undefined) {
            throw new Error('Required parameter gameType was null or undefined when calling statsHandlerControllerGetPlayerNames.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameType !== undefined && gameType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameType, 'gameType');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<EntityFilterDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/team/${encodeURIComponent(String(tid))}/players`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gameIds list of game ids
     * @param isScouting check for filter scouting games
     * @param playerIds list of player ids
     * @param playerPosition filter by playerPosition
     * @param minutesPlayed filter by minutesPlayed
     * @param gamesPlayed filter by gamesPlayed
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetPlayerScoreStatsByGames(uid: string, aid: string, gameIds: string, isScouting: boolean, playerIds?: string, playerPosition?: string, minutesPlayed?: number, gamesPlayed?: number, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PlayerScoreStatsDto>>;
    public statsHandlerControllerGetPlayerScoreStatsByGames(uid: string, aid: string, gameIds: string, isScouting: boolean, playerIds?: string, playerPosition?: string, minutesPlayed?: number, gamesPlayed?: number, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PlayerScoreStatsDto>>>;
    public statsHandlerControllerGetPlayerScoreStatsByGames(uid: string, aid: string, gameIds: string, isScouting: boolean, playerIds?: string, playerPosition?: string, minutesPlayed?: number, gamesPlayed?: number, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PlayerScoreStatsDto>>>;
    public statsHandlerControllerGetPlayerScoreStatsByGames(uid: string, aid: string, gameIds: string, isScouting: boolean, playerIds?: string, playerPosition?: string, minutesPlayed?: number, gamesPlayed?: number, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetPlayerScoreStatsByGames.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetPlayerScoreStatsByGames.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetPlayerScoreStatsByGames.');
        }
        if (isScouting === null || isScouting === undefined) {
            throw new Error('Required parameter isScouting was null or undefined when calling statsHandlerControllerGetPlayerScoreStatsByGames.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (playerIds !== undefined && playerIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerIds, 'playerIds');
        }
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (playerPosition !== undefined && playerPosition !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerPosition, 'playerPosition');
        }
        if (minutesPlayed !== undefined && minutesPlayed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>minutesPlayed, 'minutesPlayed');
        }
        if (gamesPlayed !== undefined && gamesPlayed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gamesPlayed, 'gamesPlayed');
        }
        if (isScouting !== undefined && isScouting !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isScouting, 'isScouting');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<PlayerScoreStatsDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/playerscorestats`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param tid 
     * @param gameIds list of game ids
     * @param isScouting check for filter scouting games
     * @param playerPosition filter by playerPosition
     * @param minutesPlayed filter by minutesPlayed
     * @param gamesPlayed filter by gamesPlayed
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetPlayerStatisticsAggregatedByTeamAndGame(uid: string, aid: string, tid: string, gameIds: string, isScouting: boolean, playerPosition?: string, minutesPlayed?: number, gamesPlayed?: number, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PlayerStatsAggregateDto>>;
    public statsHandlerControllerGetPlayerStatisticsAggregatedByTeamAndGame(uid: string, aid: string, tid: string, gameIds: string, isScouting: boolean, playerPosition?: string, minutesPlayed?: number, gamesPlayed?: number, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PlayerStatsAggregateDto>>>;
    public statsHandlerControllerGetPlayerStatisticsAggregatedByTeamAndGame(uid: string, aid: string, tid: string, gameIds: string, isScouting: boolean, playerPosition?: string, minutesPlayed?: number, gamesPlayed?: number, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PlayerStatsAggregateDto>>>;
    public statsHandlerControllerGetPlayerStatisticsAggregatedByTeamAndGame(uid: string, aid: string, tid: string, gameIds: string, isScouting: boolean, playerPosition?: string, minutesPlayed?: number, gamesPlayed?: number, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetPlayerStatisticsAggregatedByTeamAndGame.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetPlayerStatisticsAggregatedByTeamAndGame.');
        }
        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling statsHandlerControllerGetPlayerStatisticsAggregatedByTeamAndGame.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetPlayerStatisticsAggregatedByTeamAndGame.');
        }
        if (isScouting === null || isScouting === undefined) {
            throw new Error('Required parameter isScouting was null or undefined when calling statsHandlerControllerGetPlayerStatisticsAggregatedByTeamAndGame.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (playerPosition !== undefined && playerPosition !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerPosition, 'playerPosition');
        }
        if (minutesPlayed !== undefined && minutesPlayed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>minutesPlayed, 'minutesPlayed');
        }
        if (gamesPlayed !== undefined && gamesPlayed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gamesPlayed, 'gamesPlayed');
        }
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (isScouting !== undefined && isScouting !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isScouting, 'isScouting');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<PlayerStatsAggregateDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/team/${encodeURIComponent(String(tid))}/teamsright`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gameIds list of game ids
     * @param isScouting check for filter scouting games
     * @param playerIds list of player ids
     * @param playerPosition filter by playerPosition
     * @param minutesPlayed filter by minutesPlayed
     * @param gamesPlayed filter by gamesPlayed
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetPlayerStatsByGames(uid: string, aid: string, gameIds: string, isScouting: boolean, playerIds?: string, playerPosition?: string, minutesPlayed?: number, gamesPlayed?: number, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PlayerStatsDto>>;
    public statsHandlerControllerGetPlayerStatsByGames(uid: string, aid: string, gameIds: string, isScouting: boolean, playerIds?: string, playerPosition?: string, minutesPlayed?: number, gamesPlayed?: number, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PlayerStatsDto>>>;
    public statsHandlerControllerGetPlayerStatsByGames(uid: string, aid: string, gameIds: string, isScouting: boolean, playerIds?: string, playerPosition?: string, minutesPlayed?: number, gamesPlayed?: number, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PlayerStatsDto>>>;
    public statsHandlerControllerGetPlayerStatsByGames(uid: string, aid: string, gameIds: string, isScouting: boolean, playerIds?: string, playerPosition?: string, minutesPlayed?: number, gamesPlayed?: number, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetPlayerStatsByGames.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetPlayerStatsByGames.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetPlayerStatsByGames.');
        }
        if (isScouting === null || isScouting === undefined) {
            throw new Error('Required parameter isScouting was null or undefined when calling statsHandlerControllerGetPlayerStatsByGames.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (playerIds !== undefined && playerIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerIds, 'playerIds');
        }
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (playerPosition !== undefined && playerPosition !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>playerPosition, 'playerPosition');
        }
        if (minutesPlayed !== undefined && minutesPlayed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>minutesPlayed, 'minutesPlayed');
        }
        if (gamesPlayed !== undefined && gamesPlayed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gamesPlayed, 'gamesPlayed');
        }
        if (isScouting !== undefined && isScouting !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isScouting, 'isScouting');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<PlayerStatsDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/playerstats`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param pid 
     * @param gameIds list of game ids
     * @param isScouting check for filter scouting games
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetPlayerStatsByGamesAggregate(uid: string, aid: string, pid: string, gameIds: string, isScouting: boolean, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PlayerStatsAggregateDto>;
    public statsHandlerControllerGetPlayerStatsByGamesAggregate(uid: string, aid: string, pid: string, gameIds: string, isScouting: boolean, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PlayerStatsAggregateDto>>;
    public statsHandlerControllerGetPlayerStatsByGamesAggregate(uid: string, aid: string, pid: string, gameIds: string, isScouting: boolean, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PlayerStatsAggregateDto>>;
    public statsHandlerControllerGetPlayerStatsByGamesAggregate(uid: string, aid: string, pid: string, gameIds: string, isScouting: boolean, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetPlayerStatsByGamesAggregate.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetPlayerStatsByGamesAggregate.');
        }
        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling statsHandlerControllerGetPlayerStatsByGamesAggregate.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetPlayerStatsByGamesAggregate.');
        }
        if (isScouting === null || isScouting === undefined) {
            throw new Error('Required parameter isScouting was null or undefined when calling statsHandlerControllerGetPlayerStatsByGamesAggregate.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (isScouting !== undefined && isScouting !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isScouting, 'isScouting');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PlayerStatsAggregateDto>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/player/${encodeURIComponent(String(pid))}/playerstatsaggregate`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gameType game type LITE_MODE or COMPLETE_MODE
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetTeamNames(uid: string, aid: string, gameType: string, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<EntityFilterDto>>;
    public statsHandlerControllerGetTeamNames(uid: string, aid: string, gameType: string, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<EntityFilterDto>>>;
    public statsHandlerControllerGetTeamNames(uid: string, aid: string, gameType: string, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<EntityFilterDto>>>;
    public statsHandlerControllerGetTeamNames(uid: string, aid: string, gameType: string, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetTeamNames.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetTeamNames.');
        }
        if (gameType === null || gameType === undefined) {
            throw new Error('Required parameter gameType was null or undefined when calling statsHandlerControllerGetTeamNames.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameType !== undefined && gameType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameType, 'gameType');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<EntityFilterDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/teams`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param tid 
     * @param gameIds list of game ids
     * @param isScouting check for filter scouting games
     * @param selectOpponent select opponent team instead
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetTeamStatisticsByGamesAggregate(uid: string, aid: string, tid: string, gameIds: string, isScouting: boolean, selectOpponent?: boolean, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TeamStatsAggregateDto>;
    public statsHandlerControllerGetTeamStatisticsByGamesAggregate(uid: string, aid: string, tid: string, gameIds: string, isScouting: boolean, selectOpponent?: boolean, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TeamStatsAggregateDto>>;
    public statsHandlerControllerGetTeamStatisticsByGamesAggregate(uid: string, aid: string, tid: string, gameIds: string, isScouting: boolean, selectOpponent?: boolean, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TeamStatsAggregateDto>>;
    public statsHandlerControllerGetTeamStatisticsByGamesAggregate(uid: string, aid: string, tid: string, gameIds: string, isScouting: boolean, selectOpponent?: boolean, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetTeamStatisticsByGamesAggregate.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetTeamStatisticsByGamesAggregate.');
        }
        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling statsHandlerControllerGetTeamStatisticsByGamesAggregate.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetTeamStatisticsByGamesAggregate.');
        }
        if (isScouting === null || isScouting === undefined) {
            throw new Error('Required parameter isScouting was null or undefined when calling statsHandlerControllerGetTeamStatisticsByGamesAggregate.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (selectOpponent !== undefined && selectOpponent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>selectOpponent, 'selectOpponent');
        }
        if (isScouting !== undefined && isScouting !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isScouting, 'isScouting');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<TeamStatsAggregateDto>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/team/${encodeURIComponent(String(tid))}/teamstatsaggregate`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param tid 
     * @param gameIds list of game ids
     * @param isScouting check for filter scouting games
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetTeamsStatisticsByTeamAndGames(uid: string, aid: string, tid: string, gameIds: string, isScouting: boolean, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<TeamsStatsDto>>;
    public statsHandlerControllerGetTeamsStatisticsByTeamAndGames(uid: string, aid: string, tid: string, gameIds: string, isScouting: boolean, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<TeamsStatsDto>>>;
    public statsHandlerControllerGetTeamsStatisticsByTeamAndGames(uid: string, aid: string, tid: string, gameIds: string, isScouting: boolean, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<TeamsStatsDto>>>;
    public statsHandlerControllerGetTeamsStatisticsByTeamAndGames(uid: string, aid: string, tid: string, gameIds: string, isScouting: boolean, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetTeamsStatisticsByTeamAndGames.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetTeamsStatisticsByTeamAndGames.');
        }
        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling statsHandlerControllerGetTeamsStatisticsByTeamAndGames.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetTeamsStatisticsByTeamAndGames.');
        }
        if (isScouting === null || isScouting === undefined) {
            throw new Error('Required parameter isScouting was null or undefined when calling statsHandlerControllerGetTeamsStatisticsByTeamAndGames.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (isScouting !== undefined && isScouting !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isScouting, 'isScouting');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<TeamsStatsDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/team/${encodeURIComponent(String(tid))}/team`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param tid 
     * @param gameIds list of game ids
     * @param iframeToken iframe token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsHandlerControllerGetTopTenPlayersByTeamAndGame(uid: string, aid: string, tid: string, gameIds: string, iframeToken?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<PlayerStatsAggregateDto>>;
    public statsHandlerControllerGetTopTenPlayersByTeamAndGame(uid: string, aid: string, tid: string, gameIds: string, iframeToken?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<PlayerStatsAggregateDto>>>;
    public statsHandlerControllerGetTopTenPlayersByTeamAndGame(uid: string, aid: string, tid: string, gameIds: string, iframeToken?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<PlayerStatsAggregateDto>>>;
    public statsHandlerControllerGetTopTenPlayersByTeamAndGame(uid: string, aid: string, tid: string, gameIds: string, iframeToken?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling statsHandlerControllerGetTopTenPlayersByTeamAndGame.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling statsHandlerControllerGetTopTenPlayersByTeamAndGame.');
        }
        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling statsHandlerControllerGetTopTenPlayersByTeamAndGame.');
        }
        if (gameIds === null || gameIds === undefined) {
            throw new Error('Required parameter gameIds was null or undefined when calling statsHandlerControllerGetTopTenPlayersByTeamAndGame.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameIds !== undefined && gameIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameIds, 'gameIds');
        }
        if (iframeToken !== undefined && iframeToken !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>iframeToken, 'iframeToken');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<PlayerStatsAggregateDto>>(`${this.configuration.basePath}/api/v1/stats/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/team/${encodeURIComponent(String(tid))}/top10players`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
