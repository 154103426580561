import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { applyRules, ValidationRule } from './team-validation';
import { TeamModel } from '../model/team.model';
import { CoreService } from '../core.service';
import { GameType } from 'src/app/shared-services/game/game-type.model';

@Injectable({
    providedIn: 'root'
})
export class TeamValidationService {

    constructor(private readonly logger: NGXLogger) {
      this.initRules();
    }
    private _core: CoreService;

    private validationRulesComplete: ValidationRule[];
    private validationRulesLite: ValidationRule[];

    initCore(core: CoreService) {
        this._core = core;
    }

    public applyRules(teamModel: TeamModel, gameMode: GameType): string[] {
      return gameMode === 'COMPLETE_MODE'
          ? applyRules(teamModel, this.validationRulesComplete)
          : applyRules(teamModel, this.validationRulesLite);
    }

    private initRules(): void {
        this.validationRulesComplete = [
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.status === 'playing' && !p.deleted).length < 7) {
                        return [`${teamModel.teamname} has not enough players with playing status!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.status === 'playing' && !p.deleted).length > 7) {
                        return [`${teamModel.teamname} has to many players in status playing: ${teamModel.players.filter(p => p.status === 'playing' && !p.deleted).length}!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.status !== 'out' && !p.deleted).length > 19) {
                        return [`${teamModel.teamname} cannot have more than 19 players with playing or bench status! Please move some of then to out status`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'gk' && p.status === 'playing' && !p.deleted).length === 0) {
                        return [`${teamModel.teamname} has no Goalkeeper with playing status!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'gk' && p.status === 'bench' && !p.deleted).length === 0) {
                        return [`${teamModel.teamname} has no Goalkeeper on bench!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'gk' && p.status === 'playing' && !p.deleted).length > 1) {
                        return [`${teamModel.teamname} has to many Goalkeeper with playing status!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'gk' && p.status === 'bench' && !p.deleted).length > 2) {
                        return [`${teamModel.teamname} has to many Goalkeeper on Bench!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'head_coach' && p.status === 'active' && !p.deleted).length > 1) {
                        return [`${teamModel.teamname} has to many Head Coachs!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'second_coach' && p.status === 'active' && !p.deleted).length > 1) {
                        return [`${teamModel.teamname} has to many Second Coachs!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'coaching_staff_1' && p.status === 'active' && !p.deleted).length > 1) {
                        return [`${teamModel.teamname} has to many Coaching Staff 1!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'coaching_staff_2' && p.status === 'active' && !p.deleted).length > 1) {
                        return [`${teamModel.teamname} has to many Coaching Staff 2!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'coaching_staff_3' && p.status === 'active' && !p.deleted).length > 1) {
                        return [`${teamModel.teamname} has to many Coaching Staff 3!`];
                    }
                    return [];
                }
            }
        ];
        this.validationRulesLite = [
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.status === 'playing' && !p.deleted).length < 7) {
                        return [`${teamModel.teamname} has not enough players with playing status!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.status === 'playing' && !p.deleted).length > 7) {
                        return [`${teamModel.teamname} has to many players in status playing: ${teamModel.players.filter(p => p.status === 'playing' && !p.deleted).length}!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.status !== 'out' && !p.deleted).length > 18) {
                        return [`${teamModel.teamname} cannot have more than 18 players with playing or bench status! Please move some of then to out status`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'gk' && p.status === 'playing' && !p.deleted).length === 0) {
                        return [`${teamModel.teamname} has no Goalkeeper with playing status!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'gk' && p.status === 'bench' && !p.deleted).length === 0) {
                        return [`${teamModel.teamname} has no Goalkeeper on bench!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'gk' && p.status === 'playing' && !p.deleted).length > 1) {
                        return [`${teamModel.teamname} has to many Goalkeeper with playing status!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'gk' && p.status === 'bench' && !p.deleted).length > 2) {
                        return [`${teamModel.teamname} has to many Goalkeeper on Bench!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'head_coach' && p.status === 'active' && !p.deleted).length > 1) {
                        return [`${teamModel.teamname} has to many Head Coachs!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'second_coach' && p.status === 'active' && !p.deleted).length > 1) {
                        return [`${teamModel.teamname} has to many Second Coachs!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'coaching_staff_1' && p.status === 'active' && !p.deleted).length > 1) {
                        return [`${teamModel.teamname} has to many Coaching Staff 1!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'coaching_staff_2' && p.status === 'active' && !p.deleted).length > 1) {
                        return [`${teamModel.teamname} has to many Coaching Staff 2!`];
                    }
                    return [];
                }
            },
            new class implements ValidationRule {
                fireRule(teamModel: TeamModel): string[] {
                    if (teamModel.players.filter(p => p.position === 'coaching_staff_3' && p.status === 'active' && !p.deleted).length > 1) {
                        return [`${teamModel.teamname} has to many Coaching Staff 3!`];
                    }
                    return [];
                }
            }
        ];
    }
}
