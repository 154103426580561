<ion-content class="background">
  <ion-card color="primary" class="background">
    <ion-card-header>
      <div class="w-full ion-text-center">
        <ion-card-subtitle>
          <ion-icon name="time-outline" class="global__icon-text timer-popover__headline mr-3"></ion-icon>
          <span class="global__icon-text timer-popover__headline">{{(asobalActaRemainder$|async) ? (asobalActaRemainder$|async) : 'Game control' | translate}}</span>
        </ion-card-subtitle>
      </div>
    </ion-card-header>
    <ion-card-content>
      <div class="flex flex-row w-full mt-4 mb-4">

        <ng-container *ngIf="!(gameEnded$|async)">
          <button class="timer-popover__button focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full p-1 rounded-3xl mr-0.5"
            (click)="onEndGameConfirm()">
            <div class="flex items-center">
              <div class="w-1/5">
                <ion-icon name="play-circle-outline" class="global__icon-text"></ion-icon>
              </div>
              <div class="global__icon-text w-4/5">{{'Confirm end game action' | translate}}</div>
            </div>
          </button>
          <button class="timer-popover__button danger focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full p-1 rounded-3xl mr-0.5"
            (click)="dismiss()">
            <div class="flex items-center">
              <div class="w-1/5">
                <ion-icon name="chevron-back-outline" class="global__icon-text"></ion-icon>
              </div>
              <div class="global__icon-text w-4/5">{{'Cancel' | translate}}</div>
            </div>
          </button>
        </ng-container>

        <ng-container *ngIf="(gameEnded$|async) && !(asobalActaRemainder$|async)">
          <h2 style="color: black;">{{'Do you want to download the full PDF now?' | translate}}</h2>

          <button class="timer-popover__button focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full p-1 rounded-3xl mr-0.5"
            (click)="pdfDownlaod()">
            <div class="flex items-center">
              <div class="w-1/5">
                <ion-icon name="code-download-outline" class="global__icon-text"></ion-icon>
              </div>
              <div class="global__icon-text w-4/5">{{'Yes' | translate}}</div>
            </div>
          </button>
          <button class="timer-popover__button danger focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full p-1 rounded-3xl mr-0.5"
            (click)="noticeOrDismiss()">
            <div class="flex items-center">
              <div class="w-1/5">
                <ion-icon name="hand-right-outline" class="global__icon-text"></ion-icon>
              </div>
              <div class="global__icon-text w-4/5">{{'No' | translate}}</div>
            </div>
          </button>
        </ng-container>

        <ng-container *ngIf="(gameEnded$|async) && (asobalActaRemainder$|async)">
          <!-- <h2 style="color: black;">{{asobalActaRemainder$|async}}</h2> -->

          <button class="timer-popover__button focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full p-1 rounded-3xl mr-0.5"
            (click)="openAsobalLink()">
            <div class="flex items-center">
              <!-- <div class="w-1/5">
                <ion-icon name="hand-right-outline" class="global__icon-text"></ion-icon>
              </div> -->
              <div class="global__icon-text w-4/5">{{'Continue' | translate}}</div>
            </div>
          </button>
          <button class="timer-popover__button danger focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full p-1 rounded-3xl mr-0.5"
            (click)="endDismiss()">
            <div class="flex items-center">
              <div class="w-1/5">
                <ion-icon name="hand-right-outline" class="global__icon-text"></ion-icon>
              </div>
              <div class="global__icon-text w-4/5">{{'Dismiss' | translate}}</div>
            </div>
          </button>
        </ng-container>

      </div>
    </ion-card-content>
  </ion-card>
</ion-content>
