<div #videoSection class="videos">
  <ion-item *ngIf="core.isMobileDevice">
    <h1 class="select-label" style="margin: 5px !important; padding: 0 !important">
      <button (click)="back()" class="back-button">
        <ion-icon name="arrow-back-circle-outline"></ion-icon>
      </button>
      <span>{{ this.modalTitle | translate }}</span>
      <ng-container *ngIf="showNumber && clips"> ({{ clips.length }}) </ng-container>
    </h1>
    <ion-buttons slot="end">
      <button style="margin-right: 15px" class="back-button"
              [tooltip]="sortByNameType === 'default' || sortByNameType === 'desc' ? 'Sort by name Asc' : 'Sort by name Desc'" (click)="onSortByNameClick()">
        <!-- <ion-icon size="small" name="bar-chart-outline"></ion-icon> -->
        <img style="max-width: 19px;" src="/assets/icons/sort.png" alt="sort" />
      </button>
      <button style="margin-right: 15px" class="back-button"
              [tooltip]="sortType === 'default' || sortType === 'desc' ? 'Sort by time Asc' : 'Sort by time Desc'" (click)="onSortClick()">
        <ion-icon size="small" name="cellular-outline"></ion-icon>
      </button>
      <button class="back-button" [tooltip]="'Select all'" (click)="onSelectAllClick()">
        <ion-icon size="small" name="checkmark-done-outline"></ion-icon>
      </button>
      <ion-button (click)="openExtraFilters($event)">
        <ion-icon color="secondary" name="funnel-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>
  <div class="videosList" *ngIf="!core.isMobileDevice">
    <ion-item>
      <h1 class="select-label" style="margin: 5px !important; padding: 0 !important">
        <button (click)="back()" class="back-button">
          <ion-icon name="arrow-back-circle-outline"></ion-icon>
        </button>
        <span>{{ this.modalTitle | translate }}</span>
        <ng-container *ngIf="showNumber && clips"> ({{ clips.length }}) </ng-container>
      </h1>
      <ion-buttons slot="end">
        <button style="margin-right: 15px" class="back-button"
                [tooltip]="sortByNameType === 'default' || sortByNameType === 'desc' ? 'Sort by name Asc' : 'Sort by name Desc'" (click)="onSortByNameClick()">
          <!-- <ion-icon size="small" name="bar-chart-outline"></ion-icon> -->
          <img style="max-width: 19px;" src="/assets/icons/sort.png" alt="sort" />
        </button>
        <button style="margin-right: 15px" class="back-button" [tooltip]="sortType === 'default' || sortType === 'desc' ? 'Sort by time Asc' : 'Sort by time Desc'" (click)="onSortClick()">
          <ion-icon size="small" name="cellular-outline"></ion-icon>
        </button>
        <button class="back-button" [tooltip]="'Select all'" (click)="onSelectAllClick()">
          <ion-icon size="small" name="checkmark-done-outline"></ion-icon>
        </button>
        <ion-button (click)="openExtraFilters($event)">
          <ion-icon color="secondary" name="funnel-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-item>

    <ion-list style="margin-left: 15px">
      <ion-reorder-group [disabled]="false" (ionItemReorder)="handleReorder($any($event))">
        <ng-container *ngIf="clips?.length">
          <div *ngFor="let video of clips" class="goalsButton">
            <div style="margin-right: 10px" *ngIf="isSelectionClicked" (click)="getSelectedVideos(video)">
              <ion-checkbox [checked]="allSelectedVideoIds.includes(video.id)"></ion-checkbox>
            </div>
            <button class="video-button" (click)="selectClip(video)">
              <ion-label class="videoName">
                <h2 class="event-label uppercase moreLinesText">
                  <ion-icon name="play-circle-outline" *ngIf="video.id == selectedClip?.id"></ion-icon>
                  <ion-text style="margin-left: 3px">
                    <span *ngIf="video.eventNumber"> {{ video.eventNumber + ' - ' }} </span>
                    {{ video.homeTeam }} VS {{ video.visitorTeam }}
                  </ion-text>
                </h2>
                <p class="moreLinesText">
                  {{ video.gameDate }} - {{ video.halftime }} {{ getMatchTime(video.secondsSinceStartOfGame) }},
                  {{ humanizeValue(video.event) }} / {{ humanizeValue(video.phase) }}
                </p>
              </ion-label>
            </button>
            <ion-reorder slot="end"></ion-reorder>
          </div>
        </ng-container>
        <ng-container *ngIf="events.length && !clips?.length">
          <div *ngFor="let video of events" class="goalsButton">
            <div style="margin-right: 10px" *ngIf="isSelectionClicked" (click)="getSelectedVideos(video)">
              <ion-checkbox [checked]="allSelectedVideoIds.includes(video.id)"></ion-checkbox>
            </div>
            <button class="video-button" (click)="selectClip(video)">
              <ion-label class="videoName">
                <h2 class="select-label uppercase moreLinesText">
                  <ion-icon name="play-circle-outline" *ngIf="video.id == selectedClip?.id"></ion-icon>
                  <ion-text style="margin-left: 3px">
                    <span *ngIf="video.eventNumber"> {{ video.eventNumber + ' - ' }} </span>
                    {{ video.gameHomeTeam }} VS {{ video.gameVisitorTeam }}
                  </ion-text>
                </h2>
                <p class="moreLinesText">
                  {{ video.gameDate }} - {{ video.halftime }} {{ getMatchTime(video.secondsSinceStartOfGame) }}
                  <br />
                  {{ humanizeValue(video.event) }} / {{ humanizeValue(video.phase) }}
                </p>
              </ion-label>
            </button>
            <ion-reorder slot="end"></ion-reorder>
          </div>
        </ng-container>
      </ion-reorder-group>
    </ion-list>
  </div>

  <div class="videoModal">
    <div id="buttonsList" *ngIf="!core.isMobileDevice">
      <button [disabled]="!allSelectedVideos.length || selectedEquipo.account.permissions.disallowShareMyTeam"
              [ngClass]="!allSelectedVideos.length || selectedEquipo.account.permissions.disallowShareMyTeam ? 'disabledBtn' : 'lightBtn pointer'"
          (click)="addVideosToFolderClick()"
          class="lightBtn event-button focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full rounded-3xl"
      >
        <div class="buttonContent">
          <ion-icon style="font-size: 1.5rem" name="folder-outline"></ion-icon>
          <ion-label class="nameLabel">{{ 'Share to' | translate }}</ion-label>
        </div>
      </button>

      <button
          (click)="addPlaylist()"
          [disabled]="!allSelectedVideos.length"
          [ngClass]="!allSelectedVideos.length ? 'disabledBtn' : 'lightBtn pointer'"
          class="lightBtn event-button focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full rounded-3xl"
      >
        <div class="buttonContent">
          <ion-icon style="font-size: 1.5rem" name="add-circle-outline"></ion-icon>
          <ion-label class="nameLabel">{{ 'Add to a Playlist' | translate }}</ion-label>
        </div>
      </button>
      <button
          (click)="downloadVideos()"
          [disabled]="!allSelectedVideos.length"
          [ngClass]="!allSelectedVideos.length ? 'disabledBtn' : 'lightBtn pointer'"
          class="lightBtn event-button pointer focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full rounded-3xl"
      >
        <div class="buttonContent">
          <ion-icon style="font-size: 1.5rem" name="cloud-download-outline"></ion-icon>
          <ion-label class="nameLabel">{{ 'Download Video' | translate }}</ion-label>
        </div>
      </button>
    </div>

    <div id="buttonsListRow1" *ngIf="core.isMobileDevice">
      <button [disabled]="!allSelectedVideos.length || selectedEquipo.account.permissions.disallowShareMyTeam"
              [ngClass]="!allSelectedVideos.length || selectedEquipo.account.permissions.disallowShareMyTeam ? 'disabledBtn' : 'lightBtn pointer'"
              (click)="addVideosToFolderClick()"
              class="lightBtn event-button focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full rounded-3xl"
      >
        <div class="buttonContent">
          <ion-icon style="font-size: 1.5rem" name="folder-outline"></ion-icon>
          <ion-label class="nameLabel">{{ 'Share to' | translate }}</ion-label>
        </div>
      </button>
      <button
          (click)="addPlaylist()"
          [disabled]="!allSelectedVideos.length"
          [ngClass]="!allSelectedVideos.length ? 'disabledBtn' : 'lightBtn pointer'"
          class="lightBtn event-button focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full rounded-3xl"
      >
        <div class="buttonContent">
          <ion-icon style="font-size: 1.5rem" name="add-circle-outline"></ion-icon>
          <ion-label class="nameLabel">{{ 'Add to a Playlist' | translate }}</ion-label>
        </div>
      </button>
    </div>
    <div id="buttonsListRow2" *ngIf="core.isMobileDevice">
      <button
          (click)="downloadVideos()"
          [disabled]="!allSelectedVideos.length"
          [ngClass]="!allSelectedVideos.length ? 'disabledBtn' : 'lightBtn pointer'"
          class="lightBtn event-button pointer focus:outline-none focus:ring focus:border-blue-400 active:bg-blue-800 w-full rounded-3xl"
      >
        <div class="buttonContent">
          <ion-icon style="font-size: 1.5rem" name="cloud-download-outline"></ion-icon>
          <ion-label class="nameLabel">{{ 'Download Video' | translate }}</ion-label>
        </div>
      </button>
    </div>

    <div id="videoSection">
      <video
          *ngIf="selectedClip"
          width="100%"
          controls
          [muted]="true"
          [autoplay]="true"
          disablepictureinpicture
          controlslist="nodownload noplaybackrate"
      >
        <source [src]="selectedClip.videoUrl | safe : 'resourceUrl'" type="video/mp4" />
      </video>
    </div>
  </div>
  <div class="videosList" *ngIf="core.isMobileDevice">
    <ion-list>
      <ng-container *ngIf="clips?.length">
        <ion-item *ngFor="let video of clips" class="goalsButton">
          <ion-checkbox [checked]="allSelectedVideoIds.includes(video.id)" *ngIf="this.isSelectionClicked" (ionChange)="getSelectedVideos(video)"></ion-checkbox>
          <button class="video-button" (click)="selectClip(video, videoSection)">
            <ion-label class="videoName">
              <h2 class="select-label uppercase moreLinesText">
                <ion-icon name="play-circle-outline" *ngIf="video.id == selectedClip?.id"></ion-icon>
                <ion-text style="margin-left: 3px">
                  <span *ngIf="video.eventNumber"> {{ video.eventNumber + ' - ' }} </span>
                  {{ video.homeTeam }} VS {{ video.visitorTeam }}
                </ion-text>
              </h2>
              <p class="moreLinesText">
                {{ video.gameDate }} - {{ video.halftime }} {{ getMatchTime(video.secondsSinceStartOfGame) }}
                <br />
                {{ humanizeValue(video.event) }} / {{ humanizeValue(video.phase) }}
              </p>
            </ion-label>
          </button>
        </ion-item>
      </ng-container>
      <ng-container *ngIf="events.length && !clips?.length">
        <ion-item *ngFor="let video of events" class="goalsButton">
          <ion-checkbox [checked]="allSelectedVideoIds.includes(video.id)" *ngIf="this.isSelectionClicked" (ionChange)="getSelectedVideos(video)"></ion-checkbox>
          <button class="video-button" (click)="selectClip(video)">
            <ion-label class="videoName">
              <h2 class="select-label uppercase moreLinesText">
                <ion-icon name="play-circle-outline" *ngIf="video.id == selectedClip?.id"></ion-icon>
                <ion-text style="margin-left: 3px">
                  <span *ngIf="video.eventNumber"> {{ video.eventNumber + ' - ' }} </span>
                  {{ video.gameHomeTeam }} VS {{ video.gameVisitorTeam }}
                </ion-text>
              </h2>
              <p class="moreLinesText">
                {{ video.gameDate }} - {{ video.halftime }} {{ getMatchTime(video.secondsSinceStartOfGame) }}
                <br />
                {{ humanizeValue(video.event) }} / {{ humanizeValue(video.phase) }}
              </p>
            </ion-label>
          </button>
        </ion-item>
      </ng-container>
    </ion-list>
  </div>
</div>
