import { Component, Input, OnInit } from '@angular/core';
import { ManageFolderAccessModalComponent } from '../manage-folder-access-modal/manage-folder-access-modal.component';
import { CoreService } from '../../../../../shared-services/core.service';
import { PopoverController } from '@ionic/angular';
import { MyTeamFolderDto } from '../../../../../api/hai-players-api';
import { AddFolderModalComponent } from '../add-folder-modal/add-folder-modal.component';
import { ConfirmModalComponent } from '../../../aehandler-module/pages/game-module/components/confirm-modal/confirm-modal';
import { PlayersService } from '../../players.service';

@Component({
    selector: 'app-my-team-folders-modal',
    templateUrl: './my-team-folders-modal.component.html',
    styleUrls: ['./my-team-folders-modal.component.scss'],
    standalone: false
})
export class MyTeamFoldersModalComponent  implements OnInit {

  @Input() folders: any[] = [];
  @Input() selectedFolder?: any;

  constructor(public core: CoreService,
              private popoverCntl: PopoverController,
              public playersService: PlayersService,
              public popoverController: PopoverController,
  ) { }

  ngOnInit() {
  }

  public async onFolderSelect(folder) {
    await this.popoverCntl.dismiss(folder);
  }

  public async onFolderMenuClick(folder: any, event: any) {
    event.preventDefault();
    event.stopPropagation();
  }


  public async onDeleteFolderClick(folder: MyTeamFolderDto) {
    this.popoverController.dismiss();
    ConfirmModalComponent.Open(this.core.popoverCtrl, {
      title: `Are you sure you want to delete folder ${folder.name}?`,
    }, async (d) => {
      if (d.data) {

        //await this.playersService.deletePlayer(player.id);
      }
    });
  }

  public async onFolderDetailsClick(folder: any, event: any) {
    this.popoverController.dismiss();
    event.stopPropagation();
    event.preventDefault();
    const modal = await this.core.modalCtrl.create({
      component: ManageFolderAccessModalComponent,
      backdropDismiss: true,
      id: 'folder-access-modal',
      componentProps: {
        folder
      },
    });
    modal.onDidDismiss().then(({data, role}) => {
      console.log(data);
    });
    modal.present();
  }

  public async addFolder(folder?: MyTeamFolderDto) {
    this.popoverController.dismiss();
    const popover = await this.core.popoverCtrl.create({
      component: AddFolderModalComponent,
      cssClass: 'add-player-popover',
    });
    popover.onDidDismiss().then(async res => {
      console.log(res);
      if (res.data) {
        const newFolder: MyTeamFolderDto = {
          ...res.data.folder,
          parentId: folder?.id ?? undefined
        };
        await this.playersService.addNewFolder(newFolder);
      }
    });
    await popover.present();
  }

  public onExpandClick(folder: any, event: any) {
    event.stopPropagation();
    event.preventDefault();
    folder.expanded = !folder.expanded;
    if (!folder.expanded) {
      folder.children.forEach(fld => fld.expanded = false);
    }
  }

}
