import { GoalConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/goal/goal-consumer.service';
import { Observable } from 'rxjs';
import {
    GoalkeeperEfficiencyConsumerService
} from 'src/app/shared-services/statistics/playbyplay/consumer/goalkeeper-efficiency/goalkeeper-efficiency-consumer.service';

export class ShotStatsStateHelper {

    private _teamStatsStateMap = new Map<string, Observable<any>>();

    constructor(
        private readonly goalConsumerService: GoalConsumerService,
        private readonly goalKeeperEfficiencyService: GoalkeeperEfficiencyConsumerService,
    ) {
        this._teamStatsStateMap.set('homeGoalCounter', this.goalConsumerService.homeTeamCounters$);
        this._teamStatsStateMap.set('visitorGoalCounter', this.goalConsumerService.visitorTeamCounters$);
        this._teamStatsStateMap.set('homeGoalkeeperEfficiency', this.goalKeeperEfficiencyService.homeGoalkeeperEfficiencyCounters$);
        this._teamStatsStateMap.set('visitorGoalkeeperEfficiency', this.goalKeeperEfficiencyService.visitorGoalkeeperEfficiencyCounters$);
        this._teamStatsStateMap.set('homeGoalConnectionCounter', this.goalConsumerService.homeGoalConnectionsCounters$);
        this._teamStatsStateMap.set('visitorGoalConnectionCounter', this.goalConsumerService.visitorGoalConnectionsCounters$);
    }

    public getObservableByName(name: string): Observable<any> {
        return this._teamStatsStateMap.get(name);
    }

}
