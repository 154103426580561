import { Component, HostBinding, Input, OnInit, SimpleChanges } from '@angular/core';


@Component({
    selector: 'app-custom-tab',
    templateUrl: './custom-tab.component.html',
    styleUrls: ['./custom-tab.component.scss'],
    standalone: false
})
export class CustomTabComponent implements OnInit {
  @Input() label: string;
  @Input() key?: string;
  @Input() iconName: string;
  @Input() set selected(v: string) {
    this.calculateIsActive(v);
  }
  @Input() defaultTab: boolean;
  @Input() fullHeight: boolean;
  @HostBinding('class.full-height') get transmitHeight() {
    return this.fullHeight && this.isActive;
  }

  get isActive(): boolean {
    return this.active;
  }

  private active: boolean;

  ngOnInit() {
    this.active = this.defaultTab;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes && changes.selected) {
      this.calculateIsActive(changes.selected.currentValue);
    }
  }

  calculateIsActive(value: string) {
    this.active = value === (this.key ?? this.label);
  }
}
