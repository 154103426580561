import { GamePlayerModel } from 'src/app/shared-services/model/game.model';
import {
    ExtendedGamePlayerModel
} from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/extended-game-player.model';
import {
    GameSystemPlayerStatsViewModel
} from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-view.model';

export const convertGamePlayerToExtendedGamePlayer = (teamName: string, gamePlayer: GamePlayerModel): ExtendedGamePlayerModel =>
    ({
        teamName: teamName,
        id: gamePlayer.id,
        name: gamePlayer.name,
        backNumber: gamePlayer.backNumber,
        isBench: gamePlayer.isBench,
        position: gamePlayer.position,
    });

export const createExtendedGamePlayerMap = (
    teamName: string,
    gamePlayers: GamePlayerModel[],
    currentMap: Map<number, ExtendedGamePlayerModel> = new Map<number, ExtendedGamePlayerModel>()): Map<number, ExtendedGamePlayerModel> =>
    gamePlayers.reduce((playersMap, player) => {
        playersMap.set(player.id, convertGamePlayerToExtendedGamePlayer(teamName, player));
        return playersMap;
    }, currentMap);


class GameSystemCategorySortModel {

    constructor(
        public categoryName: string,
        public numbersPerCategory: number,
        private _statsInCategory: GameSystemPlayerStatsViewModel[]
    ) {
    }

    get statsInCategory(): GameSystemPlayerStatsViewModel[] {
        return this._statsInCategory.sort(
            (a, b) =>
            b.numberOfActionsPlayed - a.numberOfActionsPlayed
        );

    }

    public addNoOfActionsPlayed(
        numbersPerCategory: number,
        statsViewModel: GameSystemPlayerStatsViewModel
    ): void {
        this.numbersPerCategory = this.numbersPerCategory + numbersPerCategory;
        this._statsInCategory = [...this._statsInCategory, statsViewModel];
    }
}

class GameSystemSortModel {

    constructor(
        public categoryMap: Map<string, GameSystemCategorySortModel> = new Map<string, GameSystemCategorySortModel>()
    ) {
    }

    public addViewModel(statsViewModel: GameSystemPlayerStatsViewModel): GameSystemSortModel {
        if (this.categoryMap.has(statsViewModel.categoryName)) {
            this.categoryMap.get(statsViewModel.categoryName).addNoOfActionsPlayed(statsViewModel.numberOfActionsPlayed, statsViewModel);
        } else {
            this.categoryMap.set(statsViewModel.categoryName, new GameSystemCategorySortModel(
                statsViewModel.categoryName,
                statsViewModel.numberOfActionsPlayed,
                [statsViewModel]
            ));
        }
        return this;
    }

    public getSortedStatsView(): GameSystemPlayerStatsViewModel[] {
        return Array.from(this.categoryMap.values())
            .sort(
                (a, b) => b.numbersPerCategory - a.numbersPerCategory
            )
            .reduce((accu, curr) =>
                [...accu, ...curr.statsInCategory], [] as GameSystemPlayerStatsViewModel[]);
    }
}

export const sortByCategoryAndNumberOfActions = (statsViewModels: GameSystemPlayerStatsViewModel[]): GameSystemPlayerStatsViewModel[] =>
    statsViewModels.reduce(
        (sortModel, statsModel) =>
            sortModel.addViewModel(statsModel), new GameSystemSortModel()
    ).getSortedStatsView();
