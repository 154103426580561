import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-handballai-logo',
  templateUrl: './handballai-logo.component.svg',
  styleUrls: ['./handballai-logo.component.scss'],
})
export class HandballaiLogoComponent implements OnInit {

  private _width: number;
  private _height: number;

  constructor() { }

  ngOnInit() {}

  get width(): number {
    return this._width;
  }

  @Input()
  set width(value: number) {
    this._width = value;
  }

  get height(): number {
    return this._height;
  }

  @Input()
  set height(value: number) {
    this._height = value;
  }
}
