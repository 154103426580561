// The simple calculation dividing by 4 is not correct
// simple assumption: if > 0 increment Counter by 1
// TODO calculate it here
import { ExternalGoalkeeperEfficiencyModel } from "@handballai/stats-calculation";
import { EfficiencyTableModel } from "src/app/main/components/shots-stats/shot-stats.model";

const calcTotalEff = (...numbers: number[]): number => {
  let divisor = 0;
  let accumulated = 0;
  for (let number of numbers) if (number > 0) {
    divisor++;
    accumulated += number;
  }
  return divisor == 0 ? 0 : accumulated/divisor;
};

export const transformExternalModelToTableModel = (
  externalGoalModel: ExternalGoalkeeperEfficiencyModel
): EfficiencyTableModel => {
  const divisorFH = calcTotalEff(
    externalGoalModel.min10eff,
    externalGoalModel.min20eff,
    externalGoalModel.min30eff,
    externalGoalModel.extraFHeff
  );
  const divisorT = calcTotalEff(
    externalGoalModel.min10eff,
    externalGoalModel.min20eff,
    externalGoalModel.min30eff,
    externalGoalModel.extraFHeff,
    externalGoalModel.min40eff,
    externalGoalModel.min50eff,
    externalGoalModel.min60eff,
    externalGoalModel.extraSHeff,
  );
  return {
    goalkeeperLabel: externalGoalModel.goalkeeperLabel,
    min10eff: externalGoalModel.min10eff,
    min20eff: externalGoalModel.min20eff,
    min30eff: externalGoalModel.min30eff,
    extraFHeff: externalGoalModel.extraFHeff,
    totalFH: divisorFH,
    min40eff: externalGoalModel.min40eff,
    min50eff: externalGoalModel.min50eff,
    min60eff: externalGoalModel.min60eff,
    extraSHeff: externalGoalModel.extraSHeff,
    total: divisorT,
  };
};
