import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { ManageAccountDto, PlanPermsDto } from 'src/app/api/hai-api';
import { CoreService } from 'src/app/shared-services/core.service';
import { AccountService } from '../../../../../shared-services/account/account.service';

@Component({
    selector: 'app-create-account-popup',
    templateUrl: './create-account-popup.component.html',
    styleUrls: ['./create-account-popup.component.scss'],
    standalone: false
})
export class CreateAccountPopupComponent implements OnInit {

  @Input() selectedPlan?: string;
  @Input() selectedAccount?: ManageAccountDto;
  private _account = {name:''};
  private _planSelected = null;
  public finalPlan = '';
  public selectedPlanPrice;
  private _fg: FormGroup;
  private _isSelectPlanPart: boolean = false;
  public myTeamOption: string;
  public renewalPeriodSelected: string;
  public plans: PlanPermsDto[] = [];
  public plansOptions = [
    { type: 'radio', value: 'team', label: 'Team', name: 'plan', isChecked: false },
    { type: 'radio', value: 'scouting', label: 'Scouting', name: 'plan', isChecked: false },
  ];

  public renewalPeriods = [
    { type: 'radio', value: 'Monthly', label: 'Monthly', name: 'plan', isChecked: false },
    { type: 'radio', value: 'Yearly', label: 'Yearly', name: 'plan', isChecked: false },
  ];

  public myTeamOptions = [
    { type: 'radio', value: 'yes', label: 'Yes', name: 'plan', isChecked: false },
    { type: 'radio', value: 'no', label: 'No', name: 'plan', isChecked: false },
  ];

  constructor(
    public readonly core: CoreService,
    private readonly fb: FormBuilder,
    private readonly popoverController: PopoverController,
    private readonly accountService: AccountService
  ) {

  }

  async ngOnInit() {
    this.initForm();
    this.plans = await this.accountService.getAvailablePlans();
    if (this.selectedAccount) {
      this.selectedPlan = 'scouting';
      this._planSelected = this.selectedPlan;
      const plan = this.plansOptions.find(plan => plan.value === this.selectedPlan);
      plan.isChecked = true;

      const period = this.renewalPeriods.find(period => period.value.toLowerCase() === this.selectedAccount.renewalPeriod.toLowerCase());
      period.isChecked = true;
      this.renewalPeriodSelected = period.value;

      const myTeam = this.myTeamOptions[0];
      myTeam.isChecked = true;
      this.myTeamOption = 'yes';

      this.finalPlan = this._planSelected + 'Myteam' + this.renewalPeriodSelected;
      this.selectedPlanPrice = this.plans.find(pl => pl.basePlan === this.finalPlan)?.price;

    } else {
      if (this.selectedPlan) {
        this._planSelected = this.selectedPlan;
        const plan = this.plansOptions.find(plan => plan.value === this.selectedPlan);
        plan.isChecked = true;
      }
    }
   }

  get fg(): FormGroup {
    return this._fg;
  }

  @Input()
  isChangePlan: boolean;

  @Input()
  set isSelectPlanPart(value: boolean) {
    this._isSelectPlanPart = value;
  }
  get isSelectPlanPart(): boolean {
    return this._isSelectPlanPart;
  }

  get account(): any {
    return this._account;
  }

  get planSelected(): any {
    return this._planSelected;
  }
  set planSelected(value: any) {
    this._planSelected = value;
  }

  public async onCancel(): Promise<void> {
    this._isSelectPlanPart = false;
    await this.popoverController.dismiss();
  }

  public async onConfirmPlan(): Promise<void> {
    let acc = {
      name: this.account.name,
      plan: this.finalPlan
    }
    this._account = acc;
    await this.popoverController.dismiss(this.account);

  }

  public onConfirm(){
    if (this._isSelectPlanPart) {
      this.onConfirmPlan();
    } else {
      const value = this.fg.get('nameFc').value;
      if(value.length > 0) {
        this.onConfirmName(value);
      }
    }
  }

  public async onConfirmName(name): Promise<void> {
    this._account = {name};
    this._isSelectPlanPart = true;
  }

  private initForm(): void {
    if (!this._fg) {
      this._fg = this.fb.group({
        nameFc: [''],
      });
    }
  }
  onSelectionPeriodChanged(period: any, changedFromLabel?: boolean) {
    if (!period.isChecked) {
      this.renewalPeriodSelected = period.value;
    } else {
      this.renewalPeriodSelected = undefined;
    }
    for (let index = 0; index < this.renewalPeriods.length; index++) {
      if (this.renewalPeriods[index]['value'] != period.value) {
        this.renewalPeriods[index]['isChecked'] = null;
      } else if (changedFromLabel) {
        if (!period.isChecked) {
          this.renewalPeriods[index]['isChecked'] = true;
        } else {
          this.renewalPeriods[index]['isChecked'] = null;
        }
      }
    }
    this.finalPlan = this._planSelected + (this.myTeamOption === 'yes' ? 'Myteam' : 'Nomyteam') + this.renewalPeriodSelected;
    this.selectedPlanPrice = this.plans.find(pl => pl.basePlan === this.finalPlan)?.price;
  }

  onSelectionMyTeamChanged(myTeamOption: any, changedFromLabel?: boolean){
    if (!myTeamOption.isChecked) {
      this.myTeamOption = myTeamOption.value;
    } else {
      this.myTeamOption = undefined;
    }

    for (let index = 0; index < this.myTeamOptions.length; index++) {
      if (this.myTeamOptions[index]['value'] != myTeamOption.value) {
        this.myTeamOptions[index]['isChecked'] = null;
      } else if (changedFromLabel) {
        if (!myTeamOption.isChecked) {
          this.myTeamOptions[index]['isChecked'] = true;
        } else {
          this.myTeamOptions[index]['isChecked'] = null;
        }
      }
    }
    this.finalPlan = this._planSelected + (this.myTeamOption === 'yes' ? 'Myteam' : 'Nomyteam') + this.renewalPeriodSelected;
    this.selectedPlanPrice = this.plans.find(pl => pl.basePlan === this.finalPlan)?.price;
  }

  onSelectionChanged(plan, changedFromLabel?: boolean) {
    if (!plan.isChecked) {
      this._planSelected = plan.value;
    } else {
      this._planSelected = undefined;
    }
    for (let index = 0; index < this.plansOptions.length; index++) {
      if (this.plansOptions[index]['value'] != plan.value) {
        this.plansOptions[index]['isChecked'] = null;
      } else if (changedFromLabel) {
        if (!plan.isChecked) {
          this.plansOptions[index]['isChecked'] = true;
        } else {
          this.plansOptions[index]['isChecked'] = null;
        }
      }
    }
    this.finalPlan = this._planSelected + (this.myTeamOption === 'yes' ? 'Myteam' : 'Nomyteam') + this.renewalPeriodSelected;
    this.selectedPlanPrice = this.plans.find(pl => pl.basePlan === this.finalPlan)?.price;
  }

}
