import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { createGameModel } from './game-convert.helper';
import { CoreService } from '../core.service';
import { GameType } from 'src/app/shared-services/game/game-type.model';
import { GameDto } from 'src/app/api/hai-api';
import { DateTime } from 'luxon';
import { GameMode } from './game-modes.model';
import { GameModel } from '@handballai/stats-calculation';
import { VideoPlayByPlay, VideoPlayTimeEvent } from '../statistics/video/model/video-tracker.util';
import { TeamService } from '../team/team.service';

@Injectable({
  providedIn: 'root'
})
export class GameHandlerService {
  private _core: CoreService;
  initCore(core: CoreService) {
      this._core = core;
  }

  constructor(
      private readonly logger: NGXLogger,
  ) { }


  // Probably here

  public async startGame(
      homeTeamId: number,
      visitorTeamId: number,
      gameType: GameType,
      gameMode: GameMode,
      gameDto?: GameDto,
  ): Promise<void> {
    window.onbeforeunload = function(e) {
      e = e || window.event;
      const confirmMsg = 'Are you sure that you want to exit? Game cannot be recovered';
      // For IE and Firefox prior to version 4
      if (e) e.returnValue = confirmMsg;
      // For Safari
      return confirmMsg;
    };
    this.logger.info('GameHandlerService.startGame', gameType, gameMode);
    this._core.uiEventDispatcherService.resetEventStateToInitial();
    this._core.handballTimerService.resetGame();
    this._core.timerWrapperService.initTimeWrapperService(true);
    const home = this._core.teamService.getSelectedTeamById(homeTeamId);
    const visitor = this._core.teamService.getSelectedTeamById(visitorTeamId);
    const gameModel = createGameModel(home, visitor, gameType);
    this._core.gameService.initGame(gameModel, gameType, gameMode);

    // Old behaviour set teams using storage
    await this._core.teamService.saveTeamForGame(TeamService.HOME_TEAM_KEY, homeTeamId);
    await this._core.teamService.saveTeamForGame(TeamService.VISITOR_TEAM_KEY, visitorTeamId);

    // Init game seed service
    this._core.gameSeedService.init(
        this._core.gameService.gameModel.home.name,
        this._core.gameService.gameModel.visitor.name,
        this._core.gameService.gameModel.home.players,
        this._core.gameService.gameModel.visitor.players
    );

    await this._core.gameDataService.readGameSystems();
    await this._core.gameDataService.getGameSystemEnabledFlag();
    // Init new services
    this._core.playTimeService.initFromInternalModel();
    // Add starting playtime events only for live mode (video mode requires first to seek video to first half start)
    if (gameMode !== 'POST_LIVE_VIDEO_MODE') this._core.playTimeService.addStartPlayTimeEvents();
    this._core.eventBufferService.init(gameMode);
    this._core.eventFeedService.init();
    this._core.goalConsumerService.init();
    this._core.lostBallConsumerService.init();
    this._core.miscEventConsumerService.init();
    this._core.playerEventListConsumerService.init();
    this._core.possessionsConsumerService.init();
    this._core.suspensionConsumerService.init();
    this._core.playByPlayProducerService.init();
    this._core.easyStatsService.init();
    this._core.goalkeeperEfficiencyConsumerService.init();
    this._core.timelineConsumerService.init();
    this._core.teamOverviewSubConsumerService.init();
    this._core.overviewConsumerService.init();
    this._core.gameSystemConsumerService.init();
    if (!gameDto) {
      gameDto = await this._core.gameDataService
        .saveEmptyGame(gameModel.startDate, gameModel, this._core.playTimeService.playtimeEvents, gameType, gameMode, gameMode==='POST_LIVE_VIDEO_MODE' ? 'saved' : 'started');
    } else {
      // TODO: Should I use gameModel.startDate for startDate or use the already present from current gameDto
      // (gameModel.startDate, gameModel, [], this._core.playTimeService.playtimeEvents, gameType, 'started', true)
      const newGameDto = this._core.gameDataService.transformToServerModel(0, gameModel.startDate, gameModel, [], this._core.playTimeService.playtimeEvents, false, false, gameType, gameMode, 'started', 'absent');
      const gameId = gameDto.id;

      // TODO: Maybe throw exception if update is not success
      await this._core.gameDataService.updateGame(gameId, {
        ...newGameDto,
        playerStatsDto:[], teamStatsDto:[], lineupStatsDto: [], gameDateTime: newGameDto.date,
      })
      gameDto = newGameDto;
      gameDto.id = gameId;
      // gameDto = await this._core.gameDataService.saveGame(gameModel.startDate, gameModel, [], this._core.playTimeService.playtimeEvents, gameType, 'started', true);
    }
    this._core.videoTrackerService.init(DateTime.now(), gameModel, gameDto);
    await this._core.gameDataService.startNewGame(gameDto, this._core.playTimeService.playtimeEvents);
    this._core.gameService.gameId = gameDto.id;
    this._core.gameService.gameHash = gameDto.accessHash;
  }

  public async continueGame(
      gameDto: GameDto,
  ): Promise<void> {
    window.onbeforeunload = function(e) {
      e = e || window.event;
      const confirmMsg = 'Are you sure that you want to exit? Game cannot be recovered';
      // For IE and Firefox prior to version 4
      if (e) e.returnValue = confirmMsg;
      // For Safari
      return confirmMsg;
    };
    this.logger.info('GameHandlerService.continueGame', gameDto);
    this._core.uiEventDispatcherService.resetEventStateToInitial();
    this._core.handballTimerService.resetGame();
    this._core.timerWrapperService.initTimeWrapperService(true);
    const gameModel = JSON.parse(gameDto.gameModelSnapshot) as GameModel;
    // const home = this._core.teamService.getSelectedTeamById(gameDto.homeId);
    // const visitor = this._core.teamService.getSelectedTeamById(gameDto.visitorId);

    this.logger.info('GameHandlerService.startGame.gameModel', gameModel);
    // const gameModel = createGameModel(home, visitor, gameDto.gameType as GameType);
    this._core.gameService.initGame(gameModel, gameDto.gameType as GameType, gameDto.gameMode as "LIVE_MODE" | "POST_LIVE_VIDEO_MODE");

    // Old behaviour set teams using storage
    await this._core.teamService.saveTeamForGame(TeamService.HOME_TEAM_KEY, gameDto.homeId); // Now working since internalTeamId is read as externalId
    await this._core.teamService.saveTeamForGame(TeamService.VISITOR_TEAM_KEY, gameDto.visitorId); // Now working since internalTeamId is read as externalId

    // Init game seed service
    this._core.gameSeedService.init(
        this._core.gameService.gameModel.home.name,
        this._core.gameService.gameModel.visitor.name,
        this._core.gameService.gameModel.home.players,
        this._core.gameService.gameModel.visitor.players
    );

    await this._core.gameDataService.readGameSystems();
    await this._core.gameDataService.getGameSystemEnabledFlag();
    // Init new services
    this._core.playTimeService.initFromInternalModel();
    this._core.eventBufferService.init(gameDto.gameMode as "LIVE_MODE" | "POST_LIVE_VIDEO_MODE");
    this._core.eventFeedService.init();
    this._core.goalConsumerService.init();
    this._core.lostBallConsumerService.init();
    this._core.miscEventConsumerService.init();
    this._core.playerEventListConsumerService.init();
    this._core.possessionsConsumerService.init();
    this._core.suspensionConsumerService.init();
    this._core.playByPlayProducerService.init();
    this._core.easyStatsService.init();
    this._core.goalkeeperEfficiencyConsumerService.init();
    this._core.timelineConsumerService.init();
    this._core.teamOverviewSubConsumerService.init();
    this._core.overviewConsumerService.init();
    this._core.gameSystemConsumerService.init();

    // (gameModel.startDate, gameModel, [], this._core.playTimeService.playtimeEvents, gameType, 'started', true)
    const newGameDto = this._core.gameDataService.transformToServerModel(
      gameDto.accountEquipoId,
      DateTime.fromISO(gameDto.date),
      gameModel,
      [],
      [],//this._core.playTimeService.playtimeEvents,
      gameDto.firstHalfEnded,
      gameDto.secondHalfEnded,
      gameDto.gameType as GameType,
      gameDto.gameMode as "LIVE_MODE" | "POST_LIVE_VIDEO_MODE",
      'saved',
      'absent'
    );
    const gameId = gameDto.id;

    // TODO: Maybe throw exception if update is not success
    await this._core.gameDataService.updateGame(gameId, {
      ...newGameDto,
      playerStatsDto:[], teamStatsDto:[], lineupStatsDto: [], gameDateTime: newGameDto.date,
    })
    const oldGameDto = gameDto;
    gameDto = newGameDto;
    gameDto.id = gameId;
    // gameDto = await this._core.gameDataService.saveGame(gameModel.startDate, gameModel, [], this._core.playTimeService.playtimeEvents, gameType, 'started', true);

    this._core.videoTrackerService.init(DateTime.now(), gameModel, gameDto, true);
    await this._core.gameDataService.startNewGame(gameDto, this._core.playTimeService.playtimeEvents);
    this._core.gameService.gameId = gameDto.id;
    this._core.gameService.gameHash = gameDto.accessHash;

    // Load pbp on VideoTrackerService
    if (oldGameDto.gameMode == 'POST_LIVE_VIDEO_MODE') {
      const pbps: VideoPlayByPlay[] = [];
      const ptms: VideoPlayTimeEvent[] = [];
      for (const pbp of oldGameDto.playByPlayDto) {
        const pbpModel: VideoPlayByPlay = {
          ...this._core.gameStatsHandlerService.convertPbpDtoToPbpModel(pbp),
          tempOrderId: pbp.orderId,
          videoTimestamp: pbp.videoTimestamp,
        };
        pbpModel.eventTime = {
          ...pbpModel.eventTime,
          secondsSinceStartOfGame: pbp.videoTimestamp,
        }
        pbps.push(pbpModel);
        this._core.videoTrackerService.addPlayByPlayEvent(pbpModel);
      }
      // Filter out playtime events older than last play by play event
      const lastPbp = pbps[pbps.length - 1];
      oldGameDto.playTimeDto = oldGameDto.playTimeDto.filter(pt => !pt.videoTimestamp || pt.videoTimestamp <= lastPbp.videoTimestamp);
      for (const pt of oldGameDto.playTimeDto) {
        const ptModel: VideoPlayTimeEvent = {
          ...this._core.playTimeService.convertPtmDtoToPtmModel(pt),
          tempOrderId: pt.orderId,
          timestamp: +pt.timestamp,
          videoTimestamp: pt.videoTimestamp,
        }
        ptModel.eventTime = {
          ...ptModel.eventTime,
          secondsSinceStartOfGame: pt.videoTimestamp ?? ptModel.eventTime.secondsSinceStartOfGame,
        }
        console.log('Converted PTM-'+ptModel.tempOrderId, pt, ptModel)
        ptms.push(ptModel);
        this._core.videoTrackerService.addPlayTimeEvent(ptModel, true);
      }
      this._core.videoTrackerService.restoreGameTrackState(pbps, ptms)
    }
  }

  public async scheduleGame(homeTeamId: number, visitorTeamId: number, gameType: GameType, gameMode: GameMode, scheduleDate: DateTime, trackers: number[]): Promise<GameDto> {
    const home = this._core.teamService.getSelectedTeamById(homeTeamId);
    const visitor = this._core.teamService.getSelectedTeamById(visitorTeamId);
    const gameModel = createGameModel(home, visitor, gameType, scheduleDate);
    return await this._core.gameDataService
      .saveEmptyGame(gameModel.startDate, gameModel, [], gameType, gameMode, 'scheduled', trackers);
  }
}
