<ion-button color="primary" (click)="createPDFReport()">Create PDF Report</ion-button>
<div class="chart-wrapper" class="ion-hida" style="width: 250px;">
  <canvas *ngIf="canvas1Values.length" baseChart #chart1 style="background-color: rgb(226, 224, 224); padding: 20px 5px;"
    width="3000px" height="3000px"
    [legend]="false"
    chartType="doughnut"
    [data]="canvas1Values"
    [labels]="['GOALS', 'SAVES']"
    [options]="cOptions"
    [colors]="circleColors">
  </canvas>

  <canvas *ngIf="canvas2Values.length" baseChart #chart2 style="background-color: rgb(226, 224, 224); padding: 20px 5px;"
    width="3000px" height="3000px"
    [legend]="false"
    chartType="doughnut"
    [data]="canvas2Values"
    [labels]="['GOALS', 'SAVES']"
    [options]="cOptions"
    [colors]="circleColors">
  </canvas>
</div>
<div style="display: block; width: 1000px;">
  <canvas #chart3 baseChart
    [datasets]="canvas3Values"
    [labels]="canvas3Labels"
    [options]="canvas3BarOptions"
    [legend]="true"
    [chartType]="'bar'"
    [colors]="canvas3BarColors"
    [plugins]="pluginsBar3">
  </canvas>

  <canvas #chart4 baseChart
    [datasets]="canvas4Values"
    [labels]="canvas4Labels"
    [options]="canvas3BarOptions"
    [legend]="true"
    [chartType]="'bar'"
    [colors]="canvas3BarColors"
    [plugins]="pluginsBar3">
  </canvas>

  <ng-container *ngFor="let team of ['home', 'visitor']">
    <div class="grid-col (1/2) vl" id="lastGames" *ngFor="let pl of globalData[team].goalkeepersShooting; let i=index;" [id]="'gk_shooting-'+team+'-'+i" style="width: 215px; display: block;">
      <h5 class="select-label mt-4" [style.color]="'black'">{{ pl.name }} - {{ pl.backNumber }}</h5>
      <hr />
      <div class="w-full mt-4">
        <app-shooter-position id="ind-plshooting"
                [goalConnectionCounter]="pl.counters | shotTransform"
                class="w-full">
        </app-shooter-position>
      </div>
    </div>
  </ng-container>


  <!-- <canvas baseChart #chart5
          [chartType]="'line'"
          [legend]="true"
          [datasets]="chartData"
          [labels]="chartLabels"
          [options]="{
          responsive: true,
          title: {display: true,
                  text: ['Goals and', 'Expected Goals']},
          tooltips: {
            enabled: true,
            backgroundColor: '#0000FF',
            mode: 'index',
            titleAlign: 'center',
            intersect: false,
            titleFontSize: 16,
            callbacks:{
                    }
            },
          hover:{mode: 'index',
          intersect: false},
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Goals'
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Minute'
        }
      }]
    },
    elements: {
      line: {
        fill: false,
        tension: 0
      }
    }}">
  </canvas> -->
</div>
