import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { GameButtonModel } from './game-button.model';
import {
    DefensePlayerActionTypes, GoalActionTypes,
    OffensePlayerActionTypes,
    PhasesActionTypes,
    SuspensionActionTypes
} from './action-types';
import { BehaviorSubject } from 'rxjs';
import { CoreService } from '../core.service';

@Injectable({
    providedIn: 'root'
})
export class ActionService {

    constructor(private readonly logger: NGXLogger) {
    }
    private _core: CoreService;

    private offensePlayerActionButtons = new Map<OffensePlayerActionTypes, GameButtonModel>([
        ['ATTACK_FAULT_COMMIT', {actionType: 'ATTACK_FAULT_COMMIT', disabled: true}],
        ['LOST_BALL', {actionType: 'LOST_BALL', disabled: true}],
        ['TECHNICAL_MISTAKE_COMMIT', {actionType: 'TECHNICAL_MISTAKE_COMMIT', disabled: true}],
        ['PASSIVE', {actionType: 'PASSIVE', disabled: true}]
    ]);

    private defensePlayerActionButtons = new Map<DefensePlayerActionTypes, GameButtonModel>([
        ['1-1_WON', {actionType: '1-1_WON', disabled: true}],
        ['FOUL_RECEIVE', {actionType: 'FOUL_RECEIVE', disabled: true}],
        ['SHOT_BLOCKED', {actionType: 'SHOT_BLOCKED', disabled: true}],
        ['2MIN_PROVOKE', {actionType: '2MIN_PROVOKE', disabled: true}],
        ['7M_PROVOKE', {actionType: '7M_PROVOKE', disabled: true}],
        ['7M_PROVOKE_SUSPENSION', {actionType: '7M_PROVOKE_SUSPENSION', disabled: true}],
    ]);

    private suspensionActionButtons = new Map<SuspensionActionTypes, GameButtonModel>([
        ['2_MIN', {actionType: '2_MIN', disabled: true}],
        ['YELLOW_CARD', {actionType: 'YELLOW_CARD', disabled: true}],
        ['RED_CARD', {actionType: 'RED_CARD', disabled: true}],
        ['BLUE_CARD', {actionType: 'BLUE_CARD', disabled: true}],
    ]);

    private goalActionButtons = new Map<GoalActionTypes, GameButtonModel>([
        ['GOAL', {actionType: 'GOAL', disabled: true}],
        ['SAVE', {actionType: 'SAVE', disabled: true}],
        ['POST_OUT', {actionType: 'POST_OUT', disabled: true}],
        ['LOST_BALL_POPUP', {actionType: 'LOST_BALL_POPUP', disabled: true}]
    ]);


    private _offensePlayerActionButtons$ = new BehaviorSubject<Map<OffensePlayerActionTypes, GameButtonModel>>(
        this.offensePlayerActionButtons
    );
    private _defensePlayerActionButtons$ = new BehaviorSubject<Map<DefensePlayerActionTypes, GameButtonModel>>(
        this.defensePlayerActionButtons
    );
    private _suspensionActionButtons$ = new BehaviorSubject<Map<SuspensionActionTypes, GameButtonModel>>(
        this.suspensionActionButtons
    );
    private _goalActionButtons$ = new BehaviorSubject<Map<GoalActionTypes, GameButtonModel>>(
        this.goalActionButtons
    );
    initCore(core: CoreService) {
        this._core = core;
    }

    public changeOffensePlayerActionButtons(disabled: boolean): void {
        this.offensePlayerActionButtons = Array.from(this.offensePlayerActionButtons.entries())
            .reduce((accu, curr) => {
                    accu.set(curr[0], {actionType: curr[0], disabled: disabled});
                    return accu;
                },
                new Map<OffensePlayerActionTypes, GameButtonModel>());
        this._offensePlayerActionButtons$.next(this.offensePlayerActionButtons);
    }

    public changeDefensePlayerActionButtons(disabled: boolean): void {
        this.defensePlayerActionButtons = Array.from(this.defensePlayerActionButtons.entries())
            .reduce((accu, curr) => {
                    accu.set(curr[0], {actionType: curr[0], disabled: disabled});
                    return accu;
                },
                new Map<DefensePlayerActionTypes, GameButtonModel>());
        this._defensePlayerActionButtons$.next(this.defensePlayerActionButtons);
    }

    public changeSuspensionActionButtons(disabled: boolean): void {
        this.suspensionActionButtons = Array.from(this.suspensionActionButtons.entries())
            .reduce((accu, curr) => {
                    accu.set(curr[0], {actionType: curr[0], disabled: disabled});
                    return accu;
                },
                new Map<SuspensionActionTypes, GameButtonModel>());
        this._suspensionActionButtons$.next(this.suspensionActionButtons);
    }

    public changeGoalActionButtons(disabled: boolean): void {
        this.goalActionButtons = Array.from(this.goalActionButtons.entries())
            .reduce((accu, curr) => {
                    accu.set(curr[0], {actionType: curr[0], disabled: disabled});
                    return accu;
                },
                new Map<GoalActionTypes, GameButtonModel>());
        this._goalActionButtons$.next(this.goalActionButtons);
    }

    public enableAllButtons(): void {
        this.changeGoalActionButtons(false);
        this.changeOffensePlayerActionButtons(false);
        this.changeDefensePlayerActionButtons(false);
        this.changeSuspensionActionButtons(false);
    }

    public disableAllButtons(): void {
        this.changeGoalActionButtons(true);
        this.changeOffensePlayerActionButtons(true);
        this.changeDefensePlayerActionButtons(true);
        this.changeSuspensionActionButtons(true);
    }

}
