/**
 * players-api
 * players-api API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { MyTeamFileDto } from '../model/models';
import { MyTeamFilesDto } from '../model/models';
import { PresignedUrlsDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class MyTeamFilesService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param uid 
     * @param aid 
     * @param fid 
     * @param myTeamFilesDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public myTeamFileControllerAddBulkFiles(uid: string, aid: string, fid: string, myTeamFilesDto: MyTeamFilesDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<MyTeamFilesDto>>;
    public myTeamFileControllerAddBulkFiles(uid: string, aid: string, fid: string, myTeamFilesDto: MyTeamFilesDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<MyTeamFilesDto>>>;
    public myTeamFileControllerAddBulkFiles(uid: string, aid: string, fid: string, myTeamFilesDto: MyTeamFilesDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<MyTeamFilesDto>>>;
    public myTeamFileControllerAddBulkFiles(uid: string, aid: string, fid: string, myTeamFilesDto: MyTeamFilesDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling myTeamFileControllerAddBulkFiles.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling myTeamFileControllerAddBulkFiles.');
        }
        if (fid === null || fid === undefined) {
            throw new Error('Required parameter fid was null or undefined when calling myTeamFileControllerAddBulkFiles.');
        }
        if (myTeamFilesDto === null || myTeamFilesDto === undefined) {
            throw new Error('Required parameter myTeamFilesDto was null or undefined when calling myTeamFileControllerAddBulkFiles.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (handball.ai) required
        credential = this.configuration.lookupCredential('handball.ai');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<MyTeamFilesDto>>(`${this.configuration.basePath}/user/${encodeURIComponent(String(uid))}/account/${encodeURIComponent(String(aid))}/my-team/files/folder/${encodeURIComponent(String(fid))}/bulk-save`,
            myTeamFilesDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param fid 
     * @param myTeamFileDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public myTeamFileControllerAddFile(uid: string, aid: string, fid: string, myTeamFileDto: MyTeamFileDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MyTeamFileDto>;
    public myTeamFileControllerAddFile(uid: string, aid: string, fid: string, myTeamFileDto: MyTeamFileDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MyTeamFileDto>>;
    public myTeamFileControllerAddFile(uid: string, aid: string, fid: string, myTeamFileDto: MyTeamFileDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MyTeamFileDto>>;
    public myTeamFileControllerAddFile(uid: string, aid: string, fid: string, myTeamFileDto: MyTeamFileDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling myTeamFileControllerAddFile.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling myTeamFileControllerAddFile.');
        }
        if (fid === null || fid === undefined) {
            throw new Error('Required parameter fid was null or undefined when calling myTeamFileControllerAddFile.');
        }
        if (myTeamFileDto === null || myTeamFileDto === undefined) {
            throw new Error('Required parameter myTeamFileDto was null or undefined when calling myTeamFileControllerAddFile.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (handball.ai) required
        credential = this.configuration.lookupCredential('handball.ai');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<MyTeamFileDto>(`${this.configuration.basePath}/user/${encodeURIComponent(String(uid))}/account/${encodeURIComponent(String(aid))}/my-team/files/folder/${encodeURIComponent(String(fid))}`,
            myTeamFileDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param fid 
     * @param myTeamFileDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public myTeamFileControllerConfirmFileUpload(uid: string, aid: string, fid: string, myTeamFileDto: MyTeamFileDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MyTeamFileDto>;
    public myTeamFileControllerConfirmFileUpload(uid: string, aid: string, fid: string, myTeamFileDto: MyTeamFileDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MyTeamFileDto>>;
    public myTeamFileControllerConfirmFileUpload(uid: string, aid: string, fid: string, myTeamFileDto: MyTeamFileDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MyTeamFileDto>>;
    public myTeamFileControllerConfirmFileUpload(uid: string, aid: string, fid: string, myTeamFileDto: MyTeamFileDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling myTeamFileControllerConfirmFileUpload.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling myTeamFileControllerConfirmFileUpload.');
        }
        if (fid === null || fid === undefined) {
            throw new Error('Required parameter fid was null or undefined when calling myTeamFileControllerConfirmFileUpload.');
        }
        if (myTeamFileDto === null || myTeamFileDto === undefined) {
            throw new Error('Required parameter myTeamFileDto was null or undefined when calling myTeamFileControllerConfirmFileUpload.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (handball.ai) required
        credential = this.configuration.lookupCredential('handball.ai');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<MyTeamFileDto>(`${this.configuration.basePath}/user/${encodeURIComponent(String(uid))}/account/${encodeURIComponent(String(aid))}/my-team/files/folder/${encodeURIComponent(String(fid))}/confirm-upload`,
            myTeamFileDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param fid 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public myTeamFileControllerDeleteFile(uid: string, aid: string, fid: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public myTeamFileControllerDeleteFile(uid: string, aid: string, fid: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public myTeamFileControllerDeleteFile(uid: string, aid: string, fid: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public myTeamFileControllerDeleteFile(uid: string, aid: string, fid: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling myTeamFileControllerDeleteFile.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling myTeamFileControllerDeleteFile.');
        }
        if (fid === null || fid === undefined) {
            throw new Error('Required parameter fid was null or undefined when calling myTeamFileControllerDeleteFile.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling myTeamFileControllerDeleteFile.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (handball.ai) required
        credential = this.configuration.lookupCredential('handball.ai');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/user/${encodeURIComponent(String(uid))}/account/${encodeURIComponent(String(aid))}/my-team/files/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param fid 
     * @param fileIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public myTeamFileControllerDeleteFiles(uid: string, aid: string, fid: string, fileIds: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public myTeamFileControllerDeleteFiles(uid: string, aid: string, fid: string, fileIds: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public myTeamFileControllerDeleteFiles(uid: string, aid: string, fid: string, fileIds: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public myTeamFileControllerDeleteFiles(uid: string, aid: string, fid: string, fileIds: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling myTeamFileControllerDeleteFiles.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling myTeamFileControllerDeleteFiles.');
        }
        if (fid === null || fid === undefined) {
            throw new Error('Required parameter fid was null or undefined when calling myTeamFileControllerDeleteFiles.');
        }
        if (fileIds === null || fileIds === undefined) {
            throw new Error('Required parameter fileIds was null or undefined when calling myTeamFileControllerDeleteFiles.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fileIds !== undefined && fileIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fileIds, 'fileIds');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (handball.ai) required
        credential = this.configuration.lookupCredential('handball.ai');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/user/${encodeURIComponent(String(uid))}/account/${encodeURIComponent(String(aid))}/my-team/files/bulk/delete`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param fid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public myTeamFileControllerGetAllFilesForFolder(uid: string, aid: string, fid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<MyTeamFileDto>>;
    public myTeamFileControllerGetAllFilesForFolder(uid: string, aid: string, fid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<MyTeamFileDto>>>;
    public myTeamFileControllerGetAllFilesForFolder(uid: string, aid: string, fid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<MyTeamFileDto>>>;
    public myTeamFileControllerGetAllFilesForFolder(uid: string, aid: string, fid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling myTeamFileControllerGetAllFilesForFolder.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling myTeamFileControllerGetAllFilesForFolder.');
        }
        if (fid === null || fid === undefined) {
            throw new Error('Required parameter fid was null or undefined when calling myTeamFileControllerGetAllFilesForFolder.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (handball.ai) required
        credential = this.configuration.lookupCredential('handball.ai');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<MyTeamFileDto>>(`${this.configuration.basePath}/user/${encodeURIComponent(String(uid))}/account/${encodeURIComponent(String(aid))}/my-team/files/folder/${encodeURIComponent(String(fid))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param fid 
     * @param fileIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public myTeamFileControllerGetFilePresignedDownloadUrl(uid: string, aid: string, fid: string, fileIds: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PresignedUrlsDto>;
    public myTeamFileControllerGetFilePresignedDownloadUrl(uid: string, aid: string, fid: string, fileIds: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PresignedUrlsDto>>;
    public myTeamFileControllerGetFilePresignedDownloadUrl(uid: string, aid: string, fid: string, fileIds: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PresignedUrlsDto>>;
    public myTeamFileControllerGetFilePresignedDownloadUrl(uid: string, aid: string, fid: string, fileIds: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling myTeamFileControllerGetFilePresignedDownloadUrl.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling myTeamFileControllerGetFilePresignedDownloadUrl.');
        }
        if (fid === null || fid === undefined) {
            throw new Error('Required parameter fid was null or undefined when calling myTeamFileControllerGetFilePresignedDownloadUrl.');
        }
        if (fileIds === null || fileIds === undefined) {
            throw new Error('Required parameter fileIds was null or undefined when calling myTeamFileControllerGetFilePresignedDownloadUrl.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fileIds !== undefined && fileIds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fileIds, 'fileIds');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (handball.ai) required
        credential = this.configuration.lookupCredential('handball.ai');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PresignedUrlsDto>(`${this.configuration.basePath}/user/${encodeURIComponent(String(uid))}/account/${encodeURIComponent(String(aid))}/my-team/files/folder/${encodeURIComponent(String(fid))}/presigned-urls`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param fid 
     * @param myTeamFilesDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public myTeamFileControllerSaveOrderForBulkFiles(uid: string, aid: string, fid: string, myTeamFilesDto: MyTeamFilesDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<MyTeamFilesDto>>;
    public myTeamFileControllerSaveOrderForBulkFiles(uid: string, aid: string, fid: string, myTeamFilesDto: MyTeamFilesDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<MyTeamFilesDto>>>;
    public myTeamFileControllerSaveOrderForBulkFiles(uid: string, aid: string, fid: string, myTeamFilesDto: MyTeamFilesDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<MyTeamFilesDto>>>;
    public myTeamFileControllerSaveOrderForBulkFiles(uid: string, aid: string, fid: string, myTeamFilesDto: MyTeamFilesDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling myTeamFileControllerSaveOrderForBulkFiles.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling myTeamFileControllerSaveOrderForBulkFiles.');
        }
        if (fid === null || fid === undefined) {
            throw new Error('Required parameter fid was null or undefined when calling myTeamFileControllerSaveOrderForBulkFiles.');
        }
        if (myTeamFilesDto === null || myTeamFilesDto === undefined) {
            throw new Error('Required parameter myTeamFilesDto was null or undefined when calling myTeamFileControllerSaveOrderForBulkFiles.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (handball.ai) required
        credential = this.configuration.lookupCredential('handball.ai');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<MyTeamFilesDto>>(`${this.configuration.basePath}/user/${encodeURIComponent(String(uid))}/account/${encodeURIComponent(String(aid))}/my-team/files/folder/${encodeURIComponent(String(fid))}/bulk-save-orders`,
            myTeamFilesDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param id 
     * @param fid 
     * @param myTeamFileDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public myTeamFileControllerUpdateFile(uid: string, aid: string, id: string, fid: string, myTeamFileDto: MyTeamFileDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MyTeamFileDto>;
    public myTeamFileControllerUpdateFile(uid: string, aid: string, id: string, fid: string, myTeamFileDto: MyTeamFileDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MyTeamFileDto>>;
    public myTeamFileControllerUpdateFile(uid: string, aid: string, id: string, fid: string, myTeamFileDto: MyTeamFileDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MyTeamFileDto>>;
    public myTeamFileControllerUpdateFile(uid: string, aid: string, id: string, fid: string, myTeamFileDto: MyTeamFileDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling myTeamFileControllerUpdateFile.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling myTeamFileControllerUpdateFile.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling myTeamFileControllerUpdateFile.');
        }
        if (fid === null || fid === undefined) {
            throw new Error('Required parameter fid was null or undefined when calling myTeamFileControllerUpdateFile.');
        }
        if (myTeamFileDto === null || myTeamFileDto === undefined) {
            throw new Error('Required parameter myTeamFileDto was null or undefined when calling myTeamFileControllerUpdateFile.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (handball.ai) required
        credential = this.configuration.lookupCredential('handball.ai');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<MyTeamFileDto>(`${this.configuration.basePath}/user/${encodeURIComponent(String(uid))}/account/${encodeURIComponent(String(aid))}/my-team/files/folder/${encodeURIComponent(String(fid))}/${encodeURIComponent(String(id))}`,
            myTeamFileDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
