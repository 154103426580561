import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  PlayerStatsAggregateDto,
  PlayerStatsDto,
  ExternalStatsService,
  TeamStatsAggregateDto,
  TeamsStatsDto,
  EntityFilterDto,
  GameListFilterDto,
  PaceAndPhaseDto, PlayByPlayDto, PlayerScoreStatsDto,
} from 'src/app/api/hai-api';
import { CoreService } from "../core.service";
import { GameSystemStatsViewModel } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-view.model';

export interface PdfTeamStats {
  teamId: number;
  teamStatsAggregate: Promise<TeamStatsAggregateDto>;
  getPlayerStatisticsAggregatedByTeamAndGame: Promise<PlayerStatsAggregateDto[]>;
  teamGameSystemModel?: Promise<GameSystemStatsViewModel[]>;
}

export interface TeamStats extends PdfTeamStats {
  getTopTenPlayersByTeamAndGame: Promise<PlayerStatsAggregateDto[]>;
  getTeamsStatisticsByTeamAndGames: Promise<TeamsStatsDto[]>;
  getLast6TeamEssentialsForPhaseAndPace: Promise<PaceAndPhaseDto[]>;
}

export interface PlayerStats {
  getPlayerStatsByGames: Promise<PlayerStatsDto[]>;
  getPlayerScoreStatsByGames: Promise<PlayerScoreStatsDto[]>;
  playerStatsAggregate: Promise<PlayerStatsAggregateDto>;
}

export interface GameListFilter extends GameListFilterDto {
  name: string;
}

@Injectable({
  providedIn: "root",
})
export class DashboardsService {
  private season: GameListFilter[] = [];
  private seasonOptions: GameListFilter[] = [];
  private seasonSecond: GameListFilter[] = [];
  private seasonsTotal: GameListFilter[] = [];
  private seasonOptionsSecond: GameListFilter[] = [];
  private seasonOptionsTotal: GameListFilter[] = [];

  get individualAggregated$() {
    return this._individualAggregated$;
  }
  get individual$() {
    return this._individual$;
  }
  get individualScore$() {
    return this._individualScore$;
  }

  get teamAggregated$() {
    return this._teamAggregated$;
  }
  get teamOpponentAggregated$() {
    return this._teamOpponentAggregated$;
  }
  get secondTeamAggregated$() {
    return this._secondTeamAggregated$;
  }
  get teamTop6$() {
    return this._teamTop6$;
  }
  get secondTeamTop6$() {
    return this._secondTeamTop6$;
  }
  get team$() {
    return this._team$;
  }
  get teamPlayers$() {
    return this._teamPlayers$;
  }
  get secondTeamPlayers$() {
    return this._secondTeamPlayers$;
  }
  get teamNames$() {
    return this._teamNames$;
  }
  get teamPlayerNames$() {
    return this._teamPlayerNames$;
  }
  get seasonOptions$() {
    return this._seasonOptions$;
  }
  get seasonOptionsSecond$() {
    return this._seasonOptionsSecond$;
  }
  get seasonOptionsTotal$() {
    return this._seasonOptionsTotal$;
  }
  get teamLast6$() {
    return this._teamLast6$;
  }

  get secondTeamLast6$(): BehaviorSubject<PaceAndPhaseDto[]> {
    return this._secondTeamLast6$;
  }

  get teamTotals$(): BehaviorSubject<TeamStatsAggregateDto[]> {
    return this._teamTotals$;
  }

  get playerTotals$(): BehaviorSubject<PlayerStatsAggregateDto[]> {
    return this._playerTotals$;
  }


  get filteredPlayByPlay$(): BehaviorSubject<PlayByPlayDto[]> {
    return this._filteredPlayByPlay$;
  }

  get secondFilteredPlayPlay$(): BehaviorSubject<PlayByPlayDto[]> {
    return this._secondFilteredPlayPlay$;
  }

  get filteredPlayByPlayByPlayer$(): BehaviorSubject<PlayByPlayDto[]> {
    return this._filteredPlayByPlayByPlayer$;
  }

  constructor(
    private logger: NGXLogger,
    private readonly externalStatsService: ExternalStatsService
  ) { }
  private _core: CoreService;

  private _individualAggregated$ = new BehaviorSubject<PlayerStatsAggregateDto>(
    null
  );
  private _individual$ = new BehaviorSubject<PlayerStatsDto[]>([]);
  private _individualScore$ = new BehaviorSubject<PlayerScoreStatsDto[]>([]);
  private _teamAggregated$ = new BehaviorSubject<TeamStatsAggregateDto>(null);
  private _teamOpponentAggregated$ = new BehaviorSubject<TeamStatsAggregateDto>(null);
  private _secondTeamAggregated$ = new BehaviorSubject<TeamStatsAggregateDto>(
    null
  );
  private _teamTop6$ = new BehaviorSubject<PlayerStatsAggregateDto[]>([]);
  private _secondTeamTop6$ = new BehaviorSubject<PlayerStatsAggregateDto[]>([]);
  private _team$ = new BehaviorSubject<TeamsStatsDto[]>([]);
  private _teamPlayers$ = new BehaviorSubject<PlayerStatsAggregateDto[]>([]);
  private _secondTeamPlayers$ = new BehaviorSubject<PlayerStatsAggregateDto[]>([]);
  private _teamNames$ = new BehaviorSubject<EntityFilterDto[]>([]);
  private _teamPlayerNames$ = new BehaviorSubject<EntityFilterDto[]>([]);
  private _seasonOptions$ = new BehaviorSubject<GameListFilter[]>([]);
  private _seasonOptionsSecond$ = new BehaviorSubject<GameListFilter[]>([]);
  private _seasonOptionsTotal$ = new BehaviorSubject<GameListFilter[]>([]);
  private _teamLast6$ = new BehaviorSubject<PaceAndPhaseDto[]>([]);
  private _secondTeamLast6$ = new BehaviorSubject<PaceAndPhaseDto[]>([]);
  private _teamTotals$ = new BehaviorSubject<TeamStatsAggregateDto[]>([]);
  private _playerTotals$ = new BehaviorSubject<PlayerStatsAggregateDto[]>([]);
  private _filteredPlayByPlay$ = new BehaviorSubject<PlayByPlayDto[]>([]);
  private _secondFilteredPlayPlay$ = new BehaviorSubject<PlayByPlayDto[]>([]);
  private _filteredPlayByPlayByPlayer$ = new BehaviorSubject<PlayByPlayDto[]>([]);

  initCore(core: CoreService) {
    this._core = core;
  }

  async generateSeason(
    teamId: string | number,
    isSecond = false,
    seasonIds: string = '',
    gameType: string = 'COMPLETE_MODE',
    onlyScouting: boolean = false,
    aeOverride: string|number = undefined,
    iframeToken: string = undefined
  ): Promise<GameListFilter[]>  {
    const val = await this._core.storageService.getAccessTokenAsObject();

    const seasonRes = await this.externalStatsService
      .statsHandlerControllerGetGamesDescByTeam(
        val?.sub.toString() ?? '-1',
        (aeOverride ?? this._core.accountEquipoService.selectedAccountEquipo$.getValue().id) + "",
        teamId + "",
        seasonIds,
        gameType,
        onlyScouting,
        iframeToken
      )
      .pipe(
        tap((res) => {
          this.logger.debug(
            'AccountEquipoService.generateSeason() - Team aggregated generate season',
              res
          );
          // this._teamAggregated$.next(res);
        })
      )
      .toPromise();
    const seasonOptions = seasonRes.map(itm => ({ ...itm, name: itm.homeTeam + ' vs ' + itm.visitorTeam }))
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    if (!isSecond) {
      this.seasonOptions = seasonOptions;
      this.season = [...this.seasonOptions];
      this._seasonOptions$.next(this.seasonOptions);
    } else {
      this.seasonOptionsSecond = seasonOptions;
      this.seasonSecond = [...this.seasonOptionsSecond];
      this._seasonOptionsSecond$.next(this.seasonOptionsSecond);
    }
    return seasonOptions;
  }

  async generateSeasonsTotal(
    teams,
    seasonIds,
    gameType: string = 'COMPLETE_MODE'
  ) {
    const val = await this._core.storageService.getAccessTokenAsObject();
    if (val) {
      let teamIds: string = ""
      teams.forEach((team, idx, array) => {
        teamIds += team.id;
        if (idx !== array.length - 1){
          teamIds += ",";
        }
      });

      const seasonRes = await this.externalStatsService
        .statsHandlerControllerGetGamesDescByTeam(
          val.sub.toString(),
          this._core.accountEquipoService.selectedAccountEquipo$.getValue().id +
          "",
          teamIds + "",
          seasonIds,
          gameType,
          false,
        )
        .pipe(
          tap((res) => {
            this.logger.debug(
              "AccountEquipoService.generateSeasonsTotal() - Total Team aggregated read successfully"
            );
            // this._teamAggregated$.next(res);
          })
        )
        .toPromise();

      this.seasonOptionsTotal = seasonRes.map(itm => ({ ...itm, name: itm.homeTeam + ' vs ' + itm.visitorTeam}))
          .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      this.seasonsTotal = [...this.seasonOptionsTotal];
      this._seasonOptionsTotal$.next(this.seasonOptionsTotal);
    }
  }

  /**
   * Set new season or empty array to setup default value
   * @param newSeason New season or empty array for default value
   * @param isSecond Is second team?
   */
  setSeason(newSeason: GameListFilter[], isSecond = false) {
    if (!isSecond) {
      if (newSeason.length) {
        this.season = newSeason;
      } else {
        this.season = [...this.seasonOptions];
      }
    } else {
      if (newSeason.length) {
        this.seasonSecond = newSeason;
      } else {
        this.seasonSecond = [...this.seasonOptionsSecond];
      }
    }
  }

  getSeasonFilter(override: string, isSecond = false) {
    const season = !isSecond ? this.season : this.seasonSecond;
    return override || season.map((gf) => gf.gameId).join(",");
  }

  getTeams(gameType: string = 'COMPLETE_MODE'): Promise<EntityFilterDto[]> {
    return new Promise<EntityFilterDto[]>((ok, ko) => {
      this._core.storageService.getAccessTokenAsObject().then(async (val) => {
        if (val) {
          try {
            await this.externalStatsService
              .statsHandlerControllerGetTeamNames(
                val.sub.toString(),
                this._core.accountEquipoService.selectedAccountEquipo$.getValue()
                  .id + "",
                gameType
              )
              .pipe(
                tap((res) => {
                  this.logger.debug(
                    "AccountEquipoService.getIndividualStats() - individual  read successfully"
                  );
                  this._teamNames$.next(res.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
                  ok(res);
                })
              )
              .toPromise();
          } catch (err) {
            ko(err);
          }
        } else {
          ko(new Error('Invalid access token'));
        }
      }).catch(ko);
    });
  }
  getTeamPlayers(
    teamId: number | string,
    gameType: string = 'COMPLETE_MODE'
  ): Promise<EntityFilterDto[]> {
    return new Promise<EntityFilterDto[]>((ok, ko) => {
      this._core.storageService.getAccessTokenAsObject().then(async (val) => {
        if (val) {
          try {
            await this.externalStatsService
              .statsHandlerControllerGetPlayerNames(
                val.sub.toString(),
                this._core.accountEquipoService.selectedAccountEquipo$.getValue().id + "",
                teamId + "",
                gameType ? gameType : 'COMPLETE_MODE'
              )
              .pipe(
                tap((res) => {
                  this.logger.debug(
                    "AccountEquipoService.getIndividualStats() - individual  read successfully"
                  );
                  this._teamPlayerNames$.next(res.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
                  ok(res);
                }),
                catchError((err) => {
                  this.logger.debug(err);
                  return of(null);
                })
              )
              .toPromise();
          } catch (err) {
            ko(err);
          }
        } else {
          ko(new Error('Invalid access token'));
        }
      }).catch(ko);
    });
  }

  getTotals(
    teamIds: string[],
    gameIds: string[],
    gameType: string = 'COMPLETE_MODE'
  ) {
    this._core.storageService.getAccessTokenAsObject().then(async (val) => {
      if (val) {
        this.externalStatsService.statsHandlerControllerGetMultipleTeamsAggregateStatsForTotals(
          val.sub.toString(),
          this._core.accountEquipoService.selectedAccountEquipo$.getValue().id + '',
          gameIds.join(','),
          false,
          teamIds.join(',')
        ).subscribe(val => this._teamTotals$.next(val));
        this.externalStatsService.statsHandlerControllerGetMultiplePlayerAggregateStatsForTotals(
          val.sub.toString(),
          this._core.accountEquipoService.selectedAccountEquipo$.getValue().id + '',
          gameIds.join(','),
          false,
          teamIds.join(',')
        ).subscribe(val => this._playerTotals$.next(val));
      }
    });
  }

  async getPlayByPlayForGameSystem(
    teamId: string,
    isSecond: boolean = false,
    seasonOverride: string = null,
    aeOverride: string|number = undefined,
    iframeToken: string = undefined
  ): Promise<PlayByPlayDto[]> {
    await this._core.loadingService.present();
    return new Promise<PlayByPlayDto[]>((resolve, reject) => {
      this._core.storageService.getAccessTokenAsObject().then(async (val) => {
        this.externalStatsService.statsHandlerControllerGetPlayByPlayForGameSystem(
          val?.sub.toString() ?? '-1',
          (aeOverride ?? this._core.accountEquipoService.selectedAccountEquipo$.getValue().id) + "",
          teamId,
          seasonOverride === null ? this.getSeasonFilter(null, isSecond) : seasonOverride,
          iframeToken
        ).subscribe(async value => {
          if (!isSecond) {
            this._filteredPlayByPlay$.next(value);
          } else {
            this._secondFilteredPlayPlay$.next(value);
          }
          await this._core.loadingService.dismiss();
          resolve(value);
        }, async error => {
          if (!isSecond) {
            this._filteredPlayByPlay$.next([]);
          } else {
            this._secondFilteredPlayPlay$.next([]);
          }
          resolve([]);
          await this._core.loadingService.dismiss();
        }); // we resolve to empty array in case of error

      });
    });
  }

  getPlayByPlayForGamesSystemByPlayer(
    playerId: string | number, seasonOverride: string = null, aeOverride: string|number = undefined, iframeToken: string = undefined
  ) {
    this._core.storageService.getAccessTokenAsObject().then(async (val) => {
      if (val) {
        this.externalStatsService.statsHandlerControllerGetPlayByPlayForGameSystemByPlayer(
          val?.sub.toString() ?? '-1',
          (aeOverride ?? this._core.accountEquipoService.selectedAccountEquipo$.getValue().id) + "",
          playerId + '',
          this.getSeasonFilter(seasonOverride),
          iframeToken
        ).subscribe(value => this._filteredPlayByPlayByPlayer$.next(value));
      }
    });
  }

  getIndividualStats(
    playerIds: string | number | undefined,
    seasonOverride: string = null,
    selection: {
      [key in keyof PlayerStats]?: boolean;
    } = {
      getPlayerStatsByGames: true,
      getPlayerScoreStatsByGames: true,
      playerStatsAggregate: true
    },
    onlyScouting: boolean = false,
    aeOverride: string|number = undefined,
    iframeToken: string = undefined,
    gameIds?: string,
    playerPosition?: string
  ) {
    return new Promise<PlayerStats>((ok, ko) => {
      this._core.storageService.getAccessTokenAsObject().then(async (val) => {
        const res = {
          getPlayerStatsByGames: !selection.getPlayerStatsByGames ? undefined:this.externalStatsService
            .statsHandlerControllerGetPlayerStatsByGames(
              val?.sub.toString() ?? '-1',
              (aeOverride ?? this._core.accountEquipoService.selectedAccountEquipo$.getValue().id) + "",
                gameIds ? gameIds : this.getSeasonFilter(seasonOverride),
              onlyScouting,
              playerIds + "",
                playerPosition,
              null,
              null,
              iframeToken
            )
            .pipe(
              tap((res) => {
                this.logger.debug(
                  "AccountEquipoService.getIndividualStats() - individual  read successfully"
                );
                this._individual$.next(res);
              })
            )
            .toPromise(),

          getPlayerScoreStatsByGames: !selection.getPlayerScoreStatsByGames ? undefined:this.externalStatsService
            .statsHandlerControllerGetPlayerScoreStatsByGames(
              val?.sub.toString() ?? '-1',
              (aeOverride ?? this._core.accountEquipoService.selectedAccountEquipo$.getValue().id) + "",
                gameIds ? gameIds : this.getSeasonFilter(seasonOverride),
              onlyScouting,
              playerIds + "",
                playerPosition,
              null,
              null,
              iframeToken
            )
            .pipe(
              tap((res) => {
                this.logger.debug(
                  "AccountEquipoService.getIndividualStats() - individual  read successfully"
                );
                this._individualScore$.next(res);
              })
            )
            .toPromise(),

          playerStatsAggregate: !selection.playerStatsAggregate ? undefined:this.externalStatsService
            .statsHandlerControllerGetPlayerStatsByGamesAggregate(
              val?.sub.toString() ?? '-1',
              (aeOverride ?? this._core.accountEquipoService.selectedAccountEquipo$.getValue().id) + "",
              playerIds + "",
              this.getSeasonFilter(seasonOverride),
              onlyScouting,
              iframeToken
            )
            .pipe(
              map((itm) => {
                for (const k in itm) {
                  if (k.startsWith("avg")) {
                    itm[k] = (+itm[k]).toFixed(1);
                  }
                }
                return itm;
              }),
              tap((res) => {
                this.logger.debug(
                  "AccountEquipoService.getIndividualStats() - individual aggregated read successfully"
                );
                this._individualAggregated$.next(res);
              }),
              catchError(err => {
                this.logger.error(err);
                return of(null);
              })
            )
            .toPromise()
        }

        ok(res);
      });
    });
  }

  getTeamOpponentsStats(
    teamId: string | number,
    isSecond = false,
    seasonOverride: string = null,
    gameType: string = 'COMPLETE_MODE',
    selection: {
      [key in keyof Omit<TeamStats, 'teamId'>]?: boolean;
    } = {
      teamStatsAggregate: true,
      getPlayerStatisticsAggregatedByTeamAndGame: true,
      getTopTenPlayersByTeamAndGame: true,
      getTeamsStatisticsByTeamAndGames: true,
      getLast6TeamEssentialsForPhaseAndPace: true,
    },
    onlyScouting: boolean = false,
    aeOverride: string|number = undefined,
    iframeToken: string = undefined,
    gameIds?: string,
  ): Promise<TeamStats> {
    return new Promise<TeamStats>((ok, ko) => {
      this._core.storageService.getAccessTokenAsObject().then(async (val) => {
        return this.externalStatsService
            .statsHandlerControllerGetTeamStatisticsByGamesAggregate(
              val?.sub.toString() ?? '-1',
              (aeOverride ?? this._core.accountEquipoService.selectedAccountEquipo$.getValue().id) + "",
              teamId + "",
                gameIds ? gameIds : this.getSeasonFilter(seasonOverride, isSecond),
              onlyScouting,
              true,
              iframeToken,
            )
            .pipe(
              map((itm) => {
                for (const k in itm) {
                  if (k.startsWith("avg")) {
                    itm[k] = (+itm[k]).toFixed(1);
                  }
                }
                return itm;
              }),
              tap((res) => {
                this.logger.debug(
                  "AccountEquipoService.getTeamOpponentsStats() - Team aggregated read successfully",
                  res
                );
                this._teamOpponentAggregated$.next(res);
              }),
                catchError(err => {
                  this.logger.error(err);
                  return of(null);
                })
            )
            .toPromise();
      });
    });
  }

  getTeamStats(
      teamId: string | number,
      isSecond = false,
      seasonOverride: string = null,
      gameType: string = 'COMPLETE_MODE',
      selection: {
        [key in keyof Omit<TeamStats, 'teamId'>]?: boolean;
      } = {
        teamStatsAggregate: true,
        getPlayerStatisticsAggregatedByTeamAndGame: true,
        getTopTenPlayersByTeamAndGame: true,
        getTeamsStatisticsByTeamAndGames: true,
        getLast6TeamEssentialsForPhaseAndPace: true,
      },
      onlyScouting: boolean = false,
      aeOverride: string|number = undefined,
      iframeToken: string = undefined,
      gameIds?: string,
      playerPosition?: string,
      minutesPlayed?: number,
      gamesPlayed?: number,
  ): Promise<TeamStats> {
    return new Promise<TeamStats>((ok, ko) => {
      this._core.storageService.getAccessTokenAsObject().then(async (val) => {
        const res = {
          teamId: +teamId,
          teamStatsAggregate: !selection.teamStatsAggregate ? undefined:this.externalStatsService
              .statsHandlerControllerGetTeamStatisticsByGamesAggregate(
                  val?.sub.toString() ?? '-1',
                  (aeOverride ?? this._core.accountEquipoService.selectedAccountEquipo$.getValue().id) + "",
                  teamId + "",
                  gameIds ? gameIds : this.getSeasonFilter(seasonOverride, isSecond),
                  onlyScouting,
                  false,
                  iframeToken,
              )
              .pipe(
                  map((itm) => {
                    for (const k in itm) {
                      if (k.startsWith("avg")) {
                        itm[k] = (+itm[k]).toFixed(1);
                      }
                    }
                    return itm;
                  }),
                  tap((res) => {
                    this.logger.debug(
                        "AccountEquipoService.getTeamStats() - Team aggregated read successfully",
                        res
                    );
                    if (!isSecond) {
                      this._teamAggregated$.next(res);
                    } else {
                      this._secondTeamAggregated$.next(res);
                    }
                  }),
                  catchError(err => {
                    this.logger.error(err);
                    return of(null);
                  })
              )
              .toPromise(),
          getPlayerStatisticsAggregatedByTeamAndGame: !selection.getPlayerStatisticsAggregatedByTeamAndGame ? undefined:this.externalStatsService
              .statsHandlerControllerGetPlayerStatisticsAggregatedByTeamAndGame(
                  val?.sub.toString() ?? '-1',
                  (aeOverride ?? this._core.accountEquipoService.selectedAccountEquipo$.getValue().id) + "",
                  teamId + "",
                  gameIds ? gameIds : this.getSeasonFilter(seasonOverride, isSecond),
                  onlyScouting,
                  playerPosition,
                  minutesPlayed,
                  gamesPlayed,
                  iframeToken
              )
              .pipe(
                  map((itms) => {
                    itms?.map((itm) => {
                      for (const k in itm) {
                        if (k.startsWith("avg")) {
                          itm[k] = (+itm[k]).toFixed(1);
                        }
                      }
                      return itm;
                    });
                    return itms;
                  }),
                  tap((res) => {
                    this.logger.debug(
                        "AccountEquipoService.getTeamStats() - Team players read successfully",
                        res
                    );
                    if (!isSecond) {
                      this._teamPlayers$.next(res);
                    } else {
                      this._secondTeamPlayers$.next(res);
                    }
                  }),
                  catchError(err => {
                    this.logger.error(err);
                    return of(null);
                  })
              )
              .toPromise(),
          getTopTenPlayersByTeamAndGame: !selection.getTopTenPlayersByTeamAndGame ? undefined:this.externalStatsService
              .statsHandlerControllerGetTopTenPlayersByTeamAndGame(
                  val?.sub.toString() ?? '-1',
                  (aeOverride ?? this._core.accountEquipoService.selectedAccountEquipo$.getValue().id) + "",
                  teamId + "",
                  this.getSeasonFilter(seasonOverride, isSecond),
                  iframeToken
              )
              .pipe(
                  map((itm) => {
                    itm.map((i) => {
                      for (const k in i) {
                        if (k.startsWith("avg")) {
                          i[k] = (+i[k]).toFixed(1);
                        }
                      }
                    });
                    return itm;
                  }),
                  tap((res) => {
                    this.logger.debug(
                        "AccountEquipoService.getTeamStats() - Team top6 players read successfully"
                    );
                    if (!isSecond) {
                      this._teamTop6$.next(res);
                    } else {
                      this._secondTeamTop6$.next(res);
                    }
                  }),
                  catchError(err => {
                    this.logger.error(err);
                    return of(null);
                  })
              )
              .toPromise(),
          getTeamsStatisticsByTeamAndGames: !selection.getTeamsStatisticsByTeamAndGames ? undefined:this.externalStatsService
              .statsHandlerControllerGetTeamsStatisticsByTeamAndGames(
                  val?.sub.toString() ?? '-1',
                  (aeOverride ?? this._core.accountEquipoService.selectedAccountEquipo$.getValue().id) + "",
                  teamId + "",
                  this.getSeasonFilter(seasonOverride, isSecond),
                  onlyScouting,
                  iframeToken
              )
              .pipe(
                  map((itms) => {
                    itms?.map((itm) => {
                      for (const k in itm) {
                        if (k.startsWith("avg")) {
                          itm[k] = (+itm[k]).toFixed(1);
                        }
                      }
                      return itm;
                    });
                    return itms;
                  }),
                  tap((res) => {
                    this.logger.debug(
                        "AccountEquipoService.getTeamStats() - Team read successfully"
                    );
                    this._team$.next(res);
                  })
              )
              .toPromise(),
          getLast6TeamEssentialsForPhaseAndPace: !selection.getLast6TeamEssentialsForPhaseAndPace ? undefined:this.externalStatsService
              .statsHandlerControllerGetLast6TeamEssentialsForPhaseAndPace(
                  val?.sub.toString() ?? '-1',
                  (aeOverride ?? this._core.accountEquipoService.selectedAccountEquipo$.getValue().id) + "",
                  teamId + "",
                  gameType,
                  iframeToken
              )
              .pipe(
                  map((itms) => {
                    itms?.map((itm) => {
                      for (const k in itm) {
                        if (k.startsWith("avg")) {
                          itm[k] = (+itm[k]).toFixed(1);
                        }
                      }
                      return itm;
                    });
                    return itms;
                  }),
                  tap((res) => {
                    this.logger.debug(
                        "AccountEquipoService.getTeamStats() - Team last 6 read successfully",
                        res
                    );
                    if (!isSecond) {
                      this._teamLast6$.next(res);
                    } else {
                      this._secondTeamLast6$.next(res);
                    }
                  })
              )
              .toPromise(),
        };

        ok(res);
      });
    });
  }

  public resetAll() {
    this.resetTeamsNames();
    this.resetSeasonOptions();
    this.resetTeamPlayerName();
    this.resetIndividualAggregated();
    this.resetIndividual();
    this.resetTeamAggregated();
    this.resetPlayByPlays();
  }

  public resetTeamsNames(): void {
    this._teamNames$.next([]);
  }

  public resetSeasonOptions(): void {
    this._seasonOptions$.next([]);
    this._seasonOptionsSecond$.next([]);
    this._seasonOptionsTotal$.next([]);
  }

  public resetTeamPlayerName(): void {
    this._teamPlayerNames$.next([]);
  }

  public resetIndividualAggregated(): void {
    this._individualAggregated$.next(null);
  }

  public resetIndividual(): void {
    this._individual$.next([]);
  }

  public resetTeamAggregated(): void {
    this._teamAggregated$.next(null);
    this._secondTeamAggregated$.next(null);
  }

  public resetPlayByPlays(): void {
    this._filteredPlayByPlay$.next([]);
    this._secondFilteredPlayPlay$.next([]);
    this._filteredPlayByPlayByPlayer$.next([]);
  }

  // getTeamTop6(
  //   teamId: string | number,
  //   isSecond = false,
  //   seasonOverride: string = null
  // ) {
  //   this._core.storageService.getAccessTokenAsObject().then(async (val) => {
  //     if (val) {
  //       this.externalStatsService
  //         .statsHandlerControllerGetPlayerStatisticsAggregatedByTeamAndGame(
  //           val.sub.toString(),
  //           this._core.accountEquipoService.selectedAccountEquipo$.getValue()
  //             .id + "",
  //           teamId + "",
  //           this.getSeasonFilter(seasonOverride)
  //         )
  //         .pipe(
  //           map((itms) => {
  //             itms?.map((itm) => {
  //               for (const k in itm) {
  //                 if (k.startsWith("avg")) {
  //                   itm[k] = (+itm[k]).toFixed(1);
  //                 }
  //               }
  //               return itm;
  //             });
  //             return itms;
  //           }),
  //           tap((res) => {
  //             this.logger.debug(
  //               "AccountEquipoService.getTeamTop6() - Team read successfully"
  //             );
  //             this._teamPlayers$.next(res);
  //           })
  //         )
  //         .toPromise();

  //       this.externalStatsService
  //         .statsHandlerControllerGetTeamsStatisticsByTeamAndGames(
  //           val.sub.toString(),
  //           this._core.accountEquipoService.selectedAccountEquipo$.getValue()
  //             .id + "",
  //           teamId + "",
  //           this.getSeasonFilter(seasonOverride)
  //         )
  //         .pipe(
  //           map((itms) => {
  //             itms?.map((itm) => {
  //               for (const k in itm) {
  //                 if (k.startsWith("avg")) {
  //                   itm[k] = (+itm[k]).toFixed(1);
  //                 }
  //               }
  //               return itm;
  //             });
  //             return itms;
  //           }),
  //           tap((res) => {
  //             this.logger.debug(
  //               "AccountEquipoService.getTeamStats() - Team read successfully"
  //             );
  //             this._team$.next(res);
  //           })
  //         )
  //         .toPromise();
  //     } else {
  //       this.logger.error("AccountEquipoService.getTeamStats() - no session");
  //     }
  //   });
  // }
}
