import { Component, Input, OnInit, EventEmitter, Output, TemplateRef, ViewChild, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { MenuController } from "@ionic/angular";
// import { AuthService } from '../../auth/auth.service'
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { NavController } from "@ionic/angular";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserService } from "src/app/shared-services/user-service/user.service";
import { Observable, Subject, takeUntil } from 'rxjs';
import { CommonUserModel } from "src/app/shared-services/model/common-user.model";
import {
  AccountEquipoService,
  TManageAccountEquipoGrants,
} from "src/app/shared-services/account-equipo/account-equipo.service";
import { HandballTimerService } from "src/app/shared-services/timer/handball-timer.service";
import { filter, map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { CoreService } from "src/app/shared-services/core.service";
import { NgSelectComponent } from '@ng-select/ng-select';
import { ManageAccountDto, ScoutingPlaylistDto, WatchlistFolderDto } from '../../../api/hai-api';
import {
  PlayListSelectionPopoverComponent
} from '../../pages/aehandler-module/pages/scouting-module/components/your-search/video-modal/play-list-selection-popover/play-list-selection-popover.component';
import {
  OrganizationSelectPopoverComponent
} from '../../pages/account-module/pages/account-select/organization-select-popover/organization-select-popover.component';
import {
  ScoutingFolderPopoverComponent
} from '../../pages/aehandler-module/pages/scouting-module/pages/scouting-play-list/scouting-folder-popover/scouting-folder-popover.component';
import { MyTeamFoldersModalComponent } from '../../pages/players-module/components/my-team-folders-modal/my-team-folders-modal.component';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() headerTitleTemplate: TemplateRef<any>;
  @Input() headerTitle: string;
  @Input() showGoBackButton: boolean = false;
  @Input() showFolderIcon: boolean = false;
  @Input() showOrgIcon: boolean = false;
  @Input() accounts: ManageAccountDto[] = [];
  @Input() playListFolders: ScoutingPlaylistDto[] = [];
  @Input() watchListFolders: WatchlistFolderDto[] = [];
  @Input() myTeamFolders: any[] = [];
  @Input() goBackActionOverride: EventEmitter<void>;
  @Input() selectedAccount: number;
  @Input() selectedPlaylistFolder: ScoutingPlaylistDto;
  @Input() selectedWatchlistFolder: WatchlistFolderDto;
  @Input() selectedMyTeamFolder: any;
  @Output() onAccountSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() onSelectFolder: EventEmitter<ScoutingPlaylistDto | WatchlistFolderDto> = new EventEmitter<ScoutingPlaylistDto | WatchlistFolderDto>();
  @ViewChild('ngSelectSelector') ngSelectSelector: NgSelectComponent;
  isOpenSelector = false;
  title = "handball.ai";
  showUserMenu = false;
  user$: Observable<CommonUserModel>;
  private _fg: FormGroup;
  private _currLang: string;
  private _langCodeSelected$ = new EventEmitter<string>();
  private _hasUploadPending = false;
  private _onSynchronize$ = new EventEmitter<void>();
  private _showSync: boolean;
  private ngUnsubscribe = new Subject<void>();
  public currentRoute = '';
  private _accountEquipos$ = new Observable<TManageAccountEquipoGrants[]>(null);
  get accountEquipos$() {
    return this._accountEquipos$;
  }

  private _gameEnded$ = new Observable<boolean>(null);
  get gameEnded$() {
    return this._gameEnded$;
  }

  private _selectedAccountEquipo$ = new Observable<TManageAccountEquipoGrants>(
    null
  );
  get selectedAccountEquipo$() {
    return this._selectedAccountEquipo$;
  }

  constructor(
    public menuController: MenuController,
    // private auth: AuthService,
    private navCtrl: NavController,
    public router: Router,
    private readonly fb: FormBuilder,
    public core: CoreService,
    private readonly accountEquipoService: AccountEquipoService,
    private readonly timerService: HandballTimerService,
    private location: Location,
    public route: ActivatedRoute
  ) {
    this.user$ = this.core.userService.user$;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.accounts?.currentValue) {
      const acc = changes.accounts.currentValue[0];
      setTimeout(() => {
        if (acc) {
          this.onAccountSelected.emit(acc.id);
        }
      }, 1000);
    }
  }

  ngOnInit() {
    if (!this._fg) {
      this.initForm();
    }
    this.currentRoute = this.router.routerState.snapshot.url;
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe)
    ).subscribe((route: NavigationEnd) => {
      this.currentRoute = route.url;
    });
    this._accountEquipos$ = this.accountEquipoService.accountEquipos$.pipe(
      map((itm) => {
        return itm?.filter((row) => !row.deleted).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      })
    );

    this._gameEnded$ = this.timerService.gameEnded$;

    this._selectedAccountEquipo$ =
      this.accountEquipoService.selectedAccountEquipo$;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @Input()
  set currLang(value: string) {
    if (value != null) {
      this._currLang = value;
      if (!this._fg) {
        this.initForm(value);
      } else {
        this._fg.controls["langSelectFc"].patchValue(value);
      }
    }
  }

  get fg(): FormGroup {
    return this._fg;
  }

  @Output()
  get langCodeSelected$(): EventEmitter<string> {
    return this._langCodeSelected$;
  }

  closeMenu() {
    this.menuController.close();
  }

  logout() {
    // this.auth.logout();
    this.router.navigate(["/auth/login"]);
  }

  get hasUploadPending(): boolean {
    return this._hasUploadPending;
  }

  @Input()
  set hasUploadPending(value: boolean) {
    this._hasUploadPending = value;
  }

  @Output()
  get onSynchronize$(): EventEmitter<void> {
    return this._onSynchronize$;
  }

  get showSync(): boolean {
    return this._showSync;
  }

  @Input()
  set showSync(value: boolean) {
    this._showSync = value;
  }

  private initForm(value = ""): void {
    this._fg = this.fb.group({
      langSelectFc: value,
    });
  }

  public langCodeChanged(): void {
    this._langCodeSelected$.emit(this._fg.controls.langSelectFc.value);
  }

  public onSynchronize(): void {
    this._onSynchronize$.emit();
  }

  public toggleUserMenu(): void {
    this.showUserMenu = !this.showUserMenu;
  }

  onAccountEquipoSelectChange(event) {
    this.core.userService.setAccountEquipo(event.id);
  }

  onMenuClose() {
    setTimeout(() => {
      this.isOpenSelector = false;
    }, 100);
  }

  onSelectAccountClick() {
    if (!this.isOpenSelector) {
      this.ngSelectSelector.open();
      this.isOpenSelector = true;
    } else {
      this.ngSelectSelector.close();
      this.isOpenSelector = false;
    }
  }

  async onSelectFolderClick() {
    let popover;
    if (!this.myTeamFolders.length) {
      popover = await this.core.popoverCtrl.create({
        component: ScoutingFolderPopoverComponent,
        cssClass: 'header-full-pagescreen',
        mode: 'ios',
        componentProps: {
          playListFolders: this.playListFolders,
          watchListFolders: this.watchListFolders,
          selectedWatchlistFolder: this.selectedWatchlistFolder,
          selectedPlaylistFolder: this.selectedPlaylistFolder,
          isWatchList: this.currentRoute.includes('watchlist')
        },
      });
    } else {
      popover = await this.core.popoverCtrl.create({
        component: MyTeamFoldersModalComponent,
        cssClass: 'header-full-pagescreen',
        mode: 'ios',
        componentProps: {
          folders: this.myTeamFolders,
          selectedFolder: this.selectedMyTeamFolder ?? this.myTeamFolders[0]
        },
      });
    }

    popover.onDidDismiss().then(async (modalData: any) => {
      if (modalData.data) {
        this.onSelectFolder.emit(modalData.data);
      }
    });
    await popover.present();
  }

  async onSelectOrganizationClick() {
    const popover = await this.core.popoverCtrl.create({
      component: OrganizationSelectPopoverComponent,
      cssClass: 'header-full-pagescreen',
      mode: 'ios',
      componentProps: {
        accounts: this.accounts,
        selectedAccount: this.selectedAccount
      },
    });

    popover.onDidDismiss().then(async (modalData: any) => {
      if (modalData.data) {
        this.onAccountSelected.emit(modalData.data);
      }
    });
    await popover.present();
  }

  onMenuClick() {
    this.core.toggleMobileDevice();
  }

  goBack() {
    if (this.goBackActionOverride && this.goBackActionOverride.observed) {
      this.goBackActionOverride.emit();
      return;
    } else {
      this.location.back();
    }
  }
}
