  <div class="confirm-modal-wrapper my-8">
    <span>{{title}}</span>
    <hr class="mx-auto my-3">
    <span class="explanation-text" *ngIf="!text && !pictureUrl && !noAndYesButtons">
        {{'This action canot be reverted' | translate}}
    </span>
    <span class="explanation-text" *ngIf="text">
          {{text}}
    </span>
    <span *ngIf="pictureUrl" class="explanation-text mt-2 mb-2">
          {{'In case you want to download multiple videos at once, you will need to allow it in your browser' | translate}}
      </span>
    <div style="text-align: center" *ngIf="pictureUrl">
      <img [src]="pictureUrl" class="popup-image" alt="img" />
    </div>
    <div class="button-wrapper mt-6">
      <span (click)="confirmFolderChange(false)" class="close-btn">
        {{ noAndYesButtons ? 'No' : 'Close' |translate }}
      </span>
      <ion-button (click)="confirmFolderChange(true)" color="primary">
        {{ noAndYesButtons ? 'Yes' : 'Confirm'|translate }}
      </ion-button>
    </div>
  </div>
