<div class="grid flex-auto content shots-stats">
  <div class="grid-col (1/2) pr-2 height-with-footer" style="display: flex; flex-direction: column;">
    <!-- <h3 class="text-center">{{homeTeamLabel}}</h3> -->
    <div class="legend">
      <h5>{{homeTeamLabel}}</h5>
    </div>
    <div class="card-container card-margins">
      <app-shooter-position [goalCounters]="homeGoalCounters"
                            [goalConnectionCounter]="homeGoalConnectionCounter">
      </app-shooter-position>
    </div>
    <div *ngIf="core.isMobileDevice" class="expand-toggle">
      <ion-toggle (ionChange)="onExpandTableColumnsClick()"></ion-toggle>
    </div>
    <ngx-datatable
      class="material"
      [rows]="homeEfficiencyTableModel"
      [columns]="columnsFH"
      [columnMode]="'force'"
      [limit]="5"
      rowHeight="auto"
      [scrollbarH]="true"
      [footerHeight]="false"
      [headerHeight]="50"
    >
      <ngx-datatable-column  prop="goalkeeperLabel" [sortable]="false" [width]="core.isMobileDevice ? 160 : 180" [cellClass]="'first-row'" >
        <ng-template let-column="column" ngx-datatable-header-template>
          {{'Goalkeeper'|translate}}
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{value}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column  prop="min10eff" [sortable]="false" [width]="70">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Min 10'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column  prop="min20eff"  [sortable]="false" [width]="70">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Min 20'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column  prop="min30eff" [sortable]="false" [width]="70">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Min 30'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="extraFHeff" [sortable]="false" [width]="90">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Extra Time'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="totalFH" [sortable]="false" [width]="80">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Total FH'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <ngx-datatable
      class="material"
      [rows]="homeEfficiencyTableModel"
      [columns]="columnsSH"
      [columnMode]="'force'"
      [limit]="5"
      rowHeight="auto"
      [scrollbarH]="true"
      [footerHeight]="false"
      [headerHeight]="50"
    >
      <ngx-datatable-column  prop="goalkeeperLabel" [sortable]="false" [width]="core.isMobileDevice ? 160 : 180" [cellClass]="'first-row'">
        <ng-template let-column="column" ngx-datatable-header-template>
          {{'Goalkeeper'|translate}}
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{value}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column  prop="min40eff"  [sortable]="false" [width]="70">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Min 40'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column  prop="min50eff" [sortable]="false" [width]="70">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Min 50'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column  prop="min60eff" [sortable]="false" [width]="70">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Min 60'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="extraSHeff" [sortable]="false" [width]="90">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Extra Time'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesAllColsShown" prop="total" [sortable]="false" [width]="80">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Total'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

    <div class="card-container-doughnut doughnut-outer-wrapper">
      <div class="doughnut-wrapper">
        <canvas baseChart
          [legend]="false"
          height="300px"
          chartType="doughnut"
          [data]="homeGoalSavesValues"
          [labels]="['GOALS'|translate, 'SAVES'|translate]"
          [options]="cOptions"
          [colors]="circleColors">
        </canvas>
        <div class="w-full p-2 doughnut-legend">
          {{'Saves of the Goalkeepers / Goals received'|translate}}
        </div>
      </div>
      <div class="doughnut-wrapper">
        <canvas baseChart
          [legend]="false"
          height="300px"
          chartType="doughnut"
          [data]="homeGoalNoGoalValues"
          [labels]="['GOALS'|translate, 'FAILED SHOTS'|translate]"
          [options]="c2Options"
          [colors]="circleColors2">
        </canvas>
        <div class="w-full p-2 doughnut-legend">
          {{'Saves and Post Outs / Goals received'|translate}}
        </div>
      </div>
    </div>
  </div>

  <div class="grid-col (1/2) pr-2 height-with-footer">
    <!-- <h3 class="text-center">{{visitorTeamLabel}}</h3> -->
    <div class="legend">
      <h5>{{visitorTeamLabel}}</h5>
    </div>
    <div class="card-container card-margins">
      <app-shooter-position [goalCounters]="visitorGoalCounters"
                            [goalConnectionCounter]="visitorGoalConnectionCounter">
      </app-shooter-position>
    </div>
    <div *ngIf="core.isMobileDevice" class="expand-toggle">
      <ion-toggle (ionChange)="onExpandGKTableColumnsClick()"></ion-toggle>
    </div>
    <ngx-datatable
      class="material"
      [rows]="visitorEfficiencyTableModel"
      [columns]="columnsFH"
      [columnMode]="'force'"
      [limit]="5"
      rowHeight="auto"
      [scrollbarH]="true"
      [footerHeight]="false"
      [headerHeight]="50"
    >
      <ngx-datatable-column [flexGrow]="2" prop="goalkeeperLabel" [sortable]="false" [width]="core.isMobileDevice ? 160 : 180" [cellClass]="'first-row'">
        <ng-template let-column="column" ngx-datatable-header-template>
          {{'Goalkeeper'|translate}}
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{value}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column  prop="min10eff" [sortable]="false" [width]="70">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Min 10'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column  prop="min20eff"  [sortable]="false" [width]="70">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Min 20'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column  prop="min30eff" [sortable]="false" [width]="70">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Min 30'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesGKAllColsShown" prop="extraFHeff" [sortable]="false" [width]="90">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Extra Time'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesGKAllColsShown" prop="totalFH" [sortable]="false" [width]="80">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Total FH'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <ngx-datatable
      class="material"
      [rows]="visitorEfficiencyTableModel"
      [columns]="columnsSH"
      [columnMode]="'force'"
      [limit]="5"
      rowHeight="auto"
      [scrollbarH]="true"
      [footerHeight]="false"
      [headerHeight]="50"
    >
      <ngx-datatable-column  prop="goalkeeperLabel" [sortable]="false" [width]="core.isMobileDevice ? 160 : 180" [cellClass]="'first-row'">
        <ng-template let-column="column" ngx-datatable-header-template>
          {{'Goalkeeper'|translate}}
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{value}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column  prop="min40eff"  [sortable]="false" [width]="70">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Min 40'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column  prop="min50eff" [sortable]="false" [width]="70">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Min 50'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column  prop="min60eff" [sortable]="false" [width]="70">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Min 60'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesGKAllColsShown" prop="extraSHeff" [sortable]="false" [width]="90">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Extra Time'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!core.isMobileDevice || tablesGKAllColsShown" prop="total" [sortable]="false" [width]="80">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>{{'Total'|translate}}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value  | number: '1.0-1'}} %</span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <div class="card-container-doughnut doughnut-outer-wrapper">
      <div class="doughnut-wrapper">
        <canvas baseChart
          [legend]="false"
          height="300px"
          chartType="doughnut"
          [data]="visitorGoalSavesValues"
          [labels]="['GOALS'|translate, 'SAVES'|translate]"
          [options]="cOptions"
          [colors]="circleColors">
        </canvas>
        <div class="w-full p-2 doughnut-legend">
          {{'Saves of the Goalkeepers / Goals received'|translate}}
        </div>
      </div>
      <div class="doughnut-wrapper">
        <canvas baseChart
          [legend]="false"
          height="300px"
          chartType="doughnut"
          [data]="visitorGoalNoGoalValues"
          [labels]="['GOALS'|translate, 'FAILED SHOTS'|translate]"
          [options]="c2Options"
          [colors]="circleColors2">
        </canvas>
        <div class="w-full p-2 doughnut-legend">
          {{'Saves and Post Outs / Goals received'|translate}}
        </div>
      </div>
    </div>
  </div>
</div>
