import { GameService } from 'src/app/shared-services/game/game.service';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

export class GameStateHelper {

    private _gameStateMap$: Map<string, Observable<any>>;

    constructor(
        private readonly gameService: GameService,
        private readonly logger: NGXLogger
    ) {
        this.logger.debug('GameStateHelper: ', [...Object.getOwnPropertyNames(GameService.prototype).filter(key => key.includes('$'))]);
        this._gameStateMap$ = Object.getOwnPropertyNames(GameService.prototype).filter(key => key.includes('$'))
            .reduce((accu, curr) => {
                let tempObservable: Observable<any> = this.gameService[curr];
                accu.set(curr, tempObservable);
                return accu;
            }, new Map<string, Observable<any>>());
    }


    public getObservableByName(name: string): Observable<any> {
        return this._gameStateMap$.get(name);
    }
}
