<div class="custom-tabs">
  <div class="custom-tabs-header">
    <div
      class="custom-tabs-label text-center lg:whitespace-nowrap w-full "
      [class]="type"
      *ngFor="let tab of tabs.toArray(); let i = index"
      [ngClass]="{'rounded-r-3xl': i == tabs.toArray().length -1, 'rounded-l-3xl': i == 0}"
      [class.is-active]="tab.active"
      (click)="onSelect(tab)"
    >
      <ion-icon
        [name]="tab.iconName"
        slot="start"
        *ngIf="tab.iconName"
        class="mr-2"
      ></ion-icon>
      <span class="capitalize">{{ tab.label }}</span>
      <div class="custom-tabs-label-bottom-line" *ngIf="tab.active"></div>
    </div>
  </div>
  <div class="custom-tabs-content">
    <ng-content></ng-content>
  </div>
</div>
