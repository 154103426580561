import { BehaviorSubject } from 'rxjs';
import { GameModel, GamePlayerModel } from 'src/app/shared-services/model/game.model';
import { DefenseSystem, TeamMarker } from '@handballai/stats-calculation';
import { Timeout } from 'src/app/shared-services/game/timeout';
import { TimeOutEvent } from 'src/app/shared-services/game/events/time-out-event';
import { SuspensionActionTypes } from 'src/app/shared-services/actions/action-types';
import { DefenseChangeEvent } from 'src/app/shared-services/game/events/defense-events';
import { NGXLogger } from 'ngx-logger';
import { CoreService } from 'src/app/shared-services/core.service';
import {
    applyTimeOut,
    enableAllowedTimeOuts,
    initGamePlayerSlotIds,
    resetTimeOut,
    toggleDisablePlayerList,
    undoTimeOut
} from 'src/app/shared-services/game/game-service-helper';
import { IGameInterface } from 'src/app/shared-services/game/game-interface';
import { TimerModel } from 'src/app/shared-services/timer/timer-model';
import * as Sentry from '@sentry/browser';

export class CompleteGameEngine implements IGameInterface {

    constructor(
        private readonly logger: NGXLogger
    ) {
    }

    get gameModel(): GameModel {
        return this._gameModel;
    }

    get gameId(): number {
        return this._gameId;
    }

    set gameId(value: number) {
        this._gameId = value;
    }

    get gameHash(): string {
        return this._gameHash;
    }

    get gameHash$() {
        return this._gameHash$;
    }

    set gameHash(value: string) {
        this._gameHash = value;
        this._gameHash$.next(this._gameHash);
    }

    get homeBench$(): BehaviorSubject<GamePlayerModel[]> {
        return this._homeBench$;
    }

    get homeDefenseSystem$(): BehaviorSubject<DefenseSystem> {
        return this._homeDefenseSystem$;
    }

    get homeField$(): BehaviorSubject<GamePlayerModel[]> {
        return this._homeField$;
    }

    get homePlayers$(): BehaviorSubject<GamePlayerModel[]> {
        return this._homePlayers$;
    }

    get homeGameSuspended$(): BehaviorSubject<GamePlayerModel[]> {
        return this._homeGameSuspended$;
    }

    get homeName$(): BehaviorSubject<string> {
        return this._homeName$;
    }

    get homeScore$(): BehaviorSubject<number> {
        return this._homeScore$;
    }

    get homeSuspended$(): BehaviorSubject<GamePlayerModel[]> {
        return this._homeSuspended$;
    }

    get homeTimeOuts$(): BehaviorSubject<Timeout[]> {
        return this._homeTimeOuts$;
    }

    get visitorBench$(): BehaviorSubject<GamePlayerModel[]> {
        return this._visitorBench$;
    }

    get visitorDefenseSystem$(): BehaviorSubject<DefenseSystem> {
        return this._visitorDefenseSystem$;
    }

    get visitorField$(): BehaviorSubject<GamePlayerModel[]> {
        return this._visitorField$;
    }

    get visitorPlayers$(): BehaviorSubject<GamePlayerModel[]> {
        return this._visitorPlayers$;
    }

    get visitorGameSuspended$(): BehaviorSubject<GamePlayerModel[]> {
        return this._visitorGameSuspended$;
    }

    get visitorName$(): BehaviorSubject<string> {
        return this._visitorName$;
    }

    get visitorScore$(): BehaviorSubject<number> {
        return this._visitorScore$;
    }

    get visitorSuspended$(): BehaviorSubject<GamePlayerModel[]> {
        return this._visitorSuspended$;
    }

    get visitorTimeOuts$(): BehaviorSubject<Timeout[]> {
        return this._visitorTimeOuts$;
    }

    private _gameHash: string;
    private _gameId: number;
    private _gameModel: GameModel;
    private _homeField$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _homePlayers$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _homeBench$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _homeSuspended$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _homeGameSuspended$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _visitorField$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _visitorPlayers$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _visitorBench$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _visitorSuspended$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _visitorGameSuspended$ = new BehaviorSubject<GamePlayerModel[]>([]);
    private _homeTimeOuts$ = new BehaviorSubject<Timeout[]>(['open', 'open', 'disabled']);
    private _visitorTimeOuts$ = new BehaviorSubject<Timeout[]>(['open', 'open', 'disabled']);
    private _homeName$ = new BehaviorSubject<string>(null);
    private _visitorName$ = new BehaviorSubject<string>(null);
    private _homeScore$ = new BehaviorSubject<number>(null);
    private _visitorScore$ = new BehaviorSubject<number>(null);
    private _homeDefenseSystem$ = new BehaviorSubject<DefenseSystem>('6:0');
    private _visitorDefenseSystem$ = new BehaviorSubject<DefenseSystem>('6:0');
    private _gameHash$ =  new BehaviorSubject<string>(null);
    private _core: CoreService;

    initCore(core: CoreService) {
        this._core = core;
    }

    applyTimeOut(timeOutEvent: TimeOutEvent): void {
        applyTimeOut(
            timeOutEvent,
            this._homeTimeOuts$,
            this._visitorTimeOuts$
        );
    }

    undoTimeOut(timeOutEvent: TimeOutEvent): void {
        undoTimeOut(
            timeOutEvent,
            this._homeTimeOuts$,
            this._visitorTimeOuts$
        );
    }

    bringBackPlayerAfterSuspensionHome(newFieldPlayer: GamePlayerModel): void {
        newFieldPlayer.slotId = this.getFirstFreeSlot(this._gameModel.home.currentField);
        this._gameModel.home.currentField = [...this._gameModel.home.currentField, newFieldPlayer];
        this._gameModel.home.currentBench = [...this._gameModel.home.currentBench
            .filter(p => p.id !== newFieldPlayer.id)];
        this._homeField$.next(this._gameModel.home.currentField);
        this._homeBench$.next(this._gameModel.home.currentBench);
    }

    bringBackPlayerAfterSuspensionVisitor(newFieldPlayer: GamePlayerModel): void {
        newFieldPlayer.slotId = this.getFirstFreeSlot(this._gameModel.visitor.currentField);
        this._gameModel.visitor.currentField = [...this._gameModel.visitor.currentField, newFieldPlayer];
        this._gameModel.visitor.currentBench = [...this._gameModel.visitor.currentBench
            .filter(p => p.id !== newFieldPlayer.id)];
        this._visitorField$.next(this._gameModel.visitor.currentField);
        this._visitorBench$.next(this._gameModel.visitor.currentBench);
    }

    bringBackSubstituteForGameSuspendedPlayerHome(playerSubstituted: GamePlayerModel, playerMovedOut: GamePlayerModel): void {
        playerSubstituted.slotId = this.getFirstFreeSlot(this._gameModel.home.currentField);
        this._gameModel.home.currentGameSuspensions = [
            ...this._gameModel.home.currentGameSuspensions.filter(p => p.id !== playerMovedOut.id)
        ];
        this._gameModel.home.currentBench = [
            ...this._gameModel.home.currentBench.filter(p => p.id !== playerSubstituted.id)
        ];
        this._gameModel.home.currentField = [
            ...this._gameModel.home.currentField,
            playerSubstituted
        ];
        this._homeField$.next(this._gameModel.home.currentField);
        this._homeBench$.next(this._gameModel.home.currentBench);
        this._homeGameSuspended$.next(this._gameModel.home.currentGameSuspensions);
    }

    bringBackSubstituteForGameSuspendedPlayerVisitor(playerSubstituted: GamePlayerModel, playerMovedOut: GamePlayerModel): void {
        playerSubstituted.slotId = this.getFirstFreeSlot(this._gameModel.visitor.currentField);
        this._gameModel.visitor.currentGameSuspensions = [
            ...this._gameModel.visitor.currentGameSuspensions.filter(p => p.id !== playerMovedOut.id)
        ];
        this._gameModel.visitor.currentBench = [
            ...this._gameModel.visitor.currentBench.filter(p => p.id !== playerSubstituted.id)
        ];
        this._gameModel.visitor.currentField = [
            ...this._gameModel.visitor.currentField,
            playerSubstituted
        ];
        this._visitorField$.next(this._gameModel.visitor.currentField);
        this._visitorBench$.next(this._gameModel.visitor.currentBench);
        this._visitorGameSuspended$.next(this._gameModel.visitor.currentGameSuspensions);
    }

    cancelPlayerSubstitutionPopupHome(playerOnSuspension: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        playerOnSuspension.disabled = false;
        if (suspensionType === '2_MIN') {
            this._gameModel.home.currentBench = [...this._gameModel.home.currentBench.filter(pl => pl.id !== playerOnSuspension.id)];
            this._gameModel.home.currentPenaltyTime = [...this._gameModel.home.currentPenaltyTime, playerOnSuspension];
        }
        this._homeBench$.next(this._gameModel.home.currentBench);
        this._homeSuspended$.next(this._gameModel.home.currentPenaltyTime);
        this._homeGameSuspended$.next(this._gameModel.home.currentGameSuspensions);

    }

    cancelPlayerSubstitutionPopupVisitor(playerOnSuspension: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        playerOnSuspension.disabled = false;
        if (suspensionType === '2_MIN') {
            this._gameModel.visitor.currentBench = [...this._gameModel.visitor.currentBench.filter(pl => pl.id !== playerOnSuspension.id)];
            this._gameModel.visitor.currentPenaltyTime = [...this._gameModel.visitor.currentPenaltyTime, playerOnSuspension];
        }
        this._visitorBench$.next(this._gameModel.visitor.currentBench);
        this._visitorSuspended$.next(this._gameModel.visitor.currentPenaltyTime);
        this._visitorGameSuspended$.next(this._gameModel.visitor.currentGameSuspensions);
    }

    changeDefenseSystem(defenseChangeEvent: DefenseChangeEvent): void {
        this.logger.debug('GameService.changeDefenseSystem: ', defenseChangeEvent);

        if (defenseChangeEvent.teamMarker === 'HOME') {
            const currDefenseSystem = this._homeDefenseSystem$.value;
            if (currDefenseSystem !== defenseChangeEvent.defenseSystem) {
                this._gameModel.home.defenseSystem = defenseChangeEvent.defenseSystem;
                this._homeDefenseSystem$.next(this._gameModel.home.defenseSystem);
            }
        } else {
            const currDefenseSystem = this._visitorDefenseSystem$.value;
            if (currDefenseSystem !== defenseChangeEvent.defenseSystem) {
                this._gameModel.visitor.defenseSystem = defenseChangeEvent.defenseSystem;
                this._visitorDefenseSystem$.next(this._gameModel.visitor.defenseSystem);
            }
        }
    }

    changeOffenseSystem(homeOffenseSystem: string, visitorOffenseSystem: string): void {
        if (!this._gameModel) {
            Sentry.captureMessage('Empty this._gameModel: ' + homeOffenseSystem + ', ' + visitorOffenseSystem +
                                  ', ' + this._gameId?.toString());
        }
        this._gameModel.home.offenseSystem = homeOffenseSystem;
        this._gameModel.visitor.offenseSystem = visitorOffenseSystem;
    }

    decrementHomeScore(): void {
        this._gameModel.scoreHome--;
        this._homeScore$.next(this._gameModel.scoreHome);
    }

    decrementVisitorScore(): void {
        this._gameModel.scoreVisitor--;
        this._visitorScore$.next(this._gameModel.scoreVisitor);
    }

    enableAllowedTimeOuts(): void {
        enableAllowedTimeOuts(
            this._homeTimeOuts$,
            this._visitorTimeOuts$
        );
    }

    exchangePlayerHome(newFieldPlayer: GamePlayerModel, newBenchPlayer: GamePlayerModel): void {
        newFieldPlayer.slotId = newBenchPlayer.slotId;
        newBenchPlayer.slotId = undefined;
        this._gameModel.home.currentField = [...this._gameModel.home.currentField
            .filter(p => p.id !== newBenchPlayer.id), newFieldPlayer];
        this._gameModel.home.currentBench = [...this._gameModel.home.currentBench
            .filter(p => p.id !== newFieldPlayer.id), newBenchPlayer];
        this._homeField$.next([...this._gameModel.home.currentField]);
        this._homeBench$.next([...this._gameModel.home.currentBench]);
    }

    exchangePlayerVisitor(newFieldPlayer: GamePlayerModel, newBenchPlayer: GamePlayerModel): void {
        newFieldPlayer.slotId = newBenchPlayer.slotId;
        newBenchPlayer.slotId = undefined;
        this._gameModel.visitor.currentField = [...this._gameModel.visitor.currentField
            .filter(p => p.id !== newBenchPlayer.id), newFieldPlayer];
        this._gameModel.visitor.currentBench = [...this._gameModel.visitor.currentBench
            .filter(p => p.id !== newFieldPlayer.id), newBenchPlayer];
        this._visitorField$.next([...this._gameModel.visitor.currentField]);
        this._visitorBench$.next([...this._gameModel.visitor.currentBench]);
    }

    incrementHomeScore(): void {
        this._gameModel.scoreHome++;
        this._homeScore$.next(this._gameModel.scoreHome);
    }

    incrementVisitorScore(): void {
        this._gameModel.scoreVisitor++;
        this._visitorScore$.next(this._gameModel.scoreVisitor);
    }

    initGame(gameModel: GameModel): void {
        this._gameModel = gameModel;
        this._gameModel.home.currentField = initGamePlayerSlotIds(this._gameModel.home.currentField);
        this._gameModel.home.currentPenaltyTime = [];
        this._gameModel.home.currentGameSuspensions = [];
        this._homeField$.next([...this._gameModel.home.currentField]);
        this._homeBench$.next([...this._gameModel.home.currentBench]);
        this._homePlayers$.next([...this._gameModel.home.currentField, ...this._gameModel.home.currentBench]);
        this._homeSuspended$.next([...this._gameModel.home.currentPenaltyTime]);
        this._homeGameSuspended$.next([...this._gameModel.home.currentGameSuspensions]);
        this._homeName$.next(this._gameModel.home.name);
        this._homeDefenseSystem$.next(this._gameModel.home.defenseSystem);
        this._gameModel.visitor.currentField = initGamePlayerSlotIds(this._gameModel.visitor.currentField);
        this._gameModel.visitor.currentPenaltyTime = [];
        this._gameModel.visitor.currentGameSuspensions = [];
        this._visitorField$.next([...this._gameModel.visitor.currentField]);
        this._visitorBench$.next([...this._gameModel.visitor.currentBench]);
        this._visitorPlayers$.next([...this._gameModel.visitor.currentField, ...this._gameModel.visitor.currentBench]);
        this._visitorSuspended$.next([...this._gameModel.visitor.currentPenaltyTime]);
        this._visitorGameSuspended$.next([...this._gameModel.visitor.currentGameSuspensions]);
        this._visitorName$.next(this._gameModel.visitor.name);
        this._visitorDefenseSystem$.next(this._gameModel.visitor.defenseSystem);
        this._homeScore$.next(this._gameModel.scoreHome);
        this._visitorScore$.next(this._gameModel.scoreVisitor);
    }

    putPlayerOnBenchHome(playerOnBench: GamePlayerModel): void {
        playerOnBench.slotId = undefined;
        this._gameModel.home.currentPenaltyTime = [...this._gameModel.home.currentPenaltyTime, playerOnBench];
        this._gameModel.home.currentField =
            [...this._gameModel.home.currentField.filter(p => p.id !== playerOnBench.id)];
        this._homeField$.next(this._gameModel.home.currentField);
        this._homeSuspended$.next(this._gameModel.home.currentPenaltyTime);
    }

    putPlayerOnBenchHomeWithoutPenalty(playerOnBench: GamePlayerModel): void {
        playerOnBench.slotId = undefined;
        this._gameModel.home.currentField =
            [...this._gameModel.home.currentField.filter(p => p.id !== playerOnBench.id)];
        this._gameModel.home.currentBench = [...this._gameModel.home.currentBench, playerOnBench];
        this._homeField$.next(this._gameModel.home.currentField);
        this._homeBench$.next(this._gameModel.home.currentBench);
    }

    putPlayerOnBenchVisitor(playerOnBench: GamePlayerModel): void {
        playerOnBench.slotId = undefined;
        this._gameModel.visitor.currentPenaltyTime = [...this._gameModel.visitor.currentPenaltyTime, playerOnBench];
        this._gameModel.visitor.currentField =
            [...this._gameModel.visitor.currentField.filter(p => p.id !== playerOnBench.id)];
        this._visitorField$.next(this._gameModel.visitor.currentField);
        this._visitorSuspended$.next(this._gameModel.visitor.currentPenaltyTime);
    }

    putPlayerOnBenchVisitorWithoutPenalty(playerOnBench: GamePlayerModel): void {
        playerOnBench.slotId = undefined;
        this._gameModel.visitor.currentField =
            [...this._gameModel.visitor.currentField.filter(p => p.id !== playerOnBench.id)];
        this._gameModel.visitor.currentBench = [...this._gameModel.visitor.currentBench, playerOnBench];
        this._visitorField$.next(this._gameModel.visitor.currentField);
        this._visitorBench$.next(this._gameModel.visitor.currentBench);
    }

    putPlayerOnGameSuspensionHome(playerOnGameSuspension: GamePlayerModel): void {
        playerOnGameSuspension.slotId = undefined;
        this._gameModel.home.currentGameSuspensions = [...this._gameModel.home.currentGameSuspensions, playerOnGameSuspension];
        this._gameModel.home.currentField =
            [...this._gameModel.home.currentField.filter(p => p.id !== playerOnGameSuspension.id)];
        this._homeField$.next(this._gameModel.home.currentField);
        this._homeGameSuspended$.next(this._gameModel.home.currentGameSuspensions);
    }

    putPlayerOnGameSuspensionVisitor(playerOnGameSuspension: GamePlayerModel): void {
        playerOnGameSuspension.slotId = undefined;
        this._gameModel.visitor.currentGameSuspensions = [...this._gameModel.visitor.currentGameSuspensions, playerOnGameSuspension];
        this._gameModel.visitor.currentField =
            [...this._gameModel.visitor.currentField.filter(p => p.id !== playerOnGameSuspension.id)];
        this._visitorField$.next(this._gameModel.visitor.currentField);
        this._visitorGameSuspended$.next(this._gameModel.visitor.currentGameSuspensions);
    }

    putPos1PlayerOnBenchHome(playerOnBench: GamePlayerModel, suspensionType: SuspensionActionTypes): GamePlayerModel {
        const gkFromBench = this._gameModel.home.currentBench.filter(pl => pl.position === 'gk')[0];
        gkFromBench.slotId = playerOnBench.slotId;
        playerOnBench.slotId = undefined;
        if (suspensionType === 'RED_CARD' || suspensionType === 'BLUE_CARD') {
            this._gameModel.home.currentGameSuspensions = [...this._gameModel.home.currentGameSuspensions, playerOnBench];
        } else {
            this._gameModel.home.currentPenaltyTime = [...this._gameModel.home.currentPenaltyTime, playerOnBench];
        }
        this._gameModel.home.currentField =
            [...this._gameModel.home.currentField.filter(p => p.id !== playerOnBench.id), gkFromBench];
        this._gameModel.home.currentBench = [...this._gameModel.home.currentBench.filter(pl => pl.id !== gkFromBench.id)];
        this._homeField$.next(this._gameModel.home.currentField);
        this._homeSuspended$.next(this._gameModel.home.currentPenaltyTime);
        return gkFromBench;
    }

    putPos1PlayerOnBenchVisitor(playerOnBench: GamePlayerModel, suspensionType: SuspensionActionTypes): GamePlayerModel {
        const gkFromBench = this._gameModel.visitor.currentBench.filter(pl => pl.position === 'gk')[0];
        gkFromBench.slotId = playerOnBench.slotId;
        playerOnBench.slotId = undefined;
        if (suspensionType === 'RED_CARD' || suspensionType === 'BLUE_CARD') {
            this._gameModel.visitor.currentGameSuspensions = [...this._gameModel.visitor.currentGameSuspensions, playerOnBench];
        } else {
            this._gameModel.visitor.currentPenaltyTime = [...this._gameModel.visitor.currentPenaltyTime, playerOnBench];
        }
        this._gameModel.visitor.currentField =
            [...this._gameModel.visitor.currentField.filter(p => p.id !== playerOnBench.id), gkFromBench];
        this._gameModel.visitor.currentBench = [...this._gameModel.visitor.currentBench.filter(pl => pl.id !== gkFromBench.id)];

        this._visitorField$.next(this._gameModel.visitor.currentField);
        this._visitorSuspended$.next(this._gameModel.visitor.currentPenaltyTime);
        return gkFromBench;
    }

    resetTimeOut(): void {
        resetTimeOut(
            this._homeTimeOuts$,
            this._visitorTimeOuts$
        );
    }

    substitutePlayerFromBenchHome(substitutePlayer: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        if (suspensionType === '2_MIN') {
            this._gameModel.home.currentPenaltyTime = [...this._gameModel.home.currentPenaltyTime
                .filter(p => p.id !== substitutePlayer.id)];
            this._gameModel.home.currentBench = [...this._gameModel.home.currentBench, substitutePlayer];
            this._homeBench$.next(this._gameModel.home.currentBench);
            this._homeSuspended$.next(this._gameModel.home.currentPenaltyTime);
        }
    }

    substitutePlayerFromBenchVisitor(substitutePlayer: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        if (suspensionType === '2_MIN') {
            this._gameModel.visitor.currentPenaltyTime = [...this._gameModel.visitor.currentPenaltyTime
                .filter(p => p.id !== substitutePlayer.id)];
            this._gameModel.visitor.currentBench = [...this._gameModel.visitor.currentBench, substitutePlayer];
            this._visitorBench$.next(this._gameModel.visitor.currentBench);
            this._visitorSuspended$.next(this._gameModel.visitor.currentPenaltyTime);
        }
    }

    continueTrackingSetPlayerPenalty(benchPlayer: GamePlayerModel, team: TeamMarker) {
        benchPlayer.disabled = false;
        const teamGameModel = team === 'HOME' ? this._gameModel.home : this._gameModel.visitor;
        const teamField$ = team === 'HOME' ? this._homeField$ : this._visitorField$;
        const teamBench$ = team === 'HOME' ? this._homeBench$ : this._visitorBench$;

        teamGameModel.currentField = [...teamGameModel.currentField, benchPlayer];
        teamGameModel.currentBench =
            [...teamGameModel.currentBench.filter(p => p.id !== benchPlayer.id)];
        teamField$.next(teamGameModel.currentField);
        teamBench$.next(teamGameModel.currentBench);
        if (benchPlayer.suspensionType === 'REGULAR_SUSPENSION') {
            this._core.gameService[team=='HOME' ? 'putPlayerOnBenchHome' : 'putPlayerOnBenchVisitor'](benchPlayer);
        } else {
            this._core.gameService[team=='HOME' ? 'putPlayerOnGameSuspensionHome' : 'putPlayerOnGameSuspensionVisitor'](benchPlayer);
        }
    }

    continueTrackingRemoveGameSuspendedPlayer(playerId: number, team: TeamMarker) {
        const teamGameModel = team === 'HOME' ? this._gameModel.home : this._gameModel.visitor;
        const teamBench$ = team === 'HOME' ? this._homeBench$ : this._visitorBench$;
        teamGameModel.currentBench =
            [...teamGameModel.currentBench.filter(p => p.id !== playerId)];
        teamBench$.next(teamGameModel.currentBench);
    }

    replaceFieldPlayers(homeFieldPlayerIds: number[], visitorFieldPlayerIds: number[]) {
        this._gameModel.home.currentField = initGamePlayerSlotIds(this._gameModel.home.players.filter(pl => homeFieldPlayerIds.includes(pl.id)));
        this._homeField$.next(this._gameModel.home.currentField);
        this._gameModel.home.currentBench = this._gameModel.home.players.filter(pl => !homeFieldPlayerIds.includes(pl.id));
        this._homeBench$.next(this._gameModel.home.currentBench);

        this._gameModel.visitor.currentField = initGamePlayerSlotIds(this._gameModel.visitor.players.filter(pl => visitorFieldPlayerIds.includes(pl.id)));
        this._visitorField$.next(this._gameModel.visitor.currentField);
        this._gameModel.visitor.currentBench = this._gameModel.visitor.players.filter(pl => !visitorFieldPlayerIds.includes(pl.id));
        this._visitorBench$.next(this._gameModel.visitor.currentBench);

        this.toggleBenchHome(true);
        this.toggleFieldHome(false);
        this.toggleSuspensionsHome(false);
        this.toggleFieldVisitor(false);
        this.toggleBenchVisitor(true);
        this.toggleSuspensionsVisitor(false);
    }

    swapFieldPlayerHome(firstPlayer: GamePlayerModel, secondPlayer: GamePlayerModel): void {
        const indexFirstPlayer = this._gameModel.home.currentField.findIndex(pl => pl.id === firstPlayer.id);
        const indexSecondPlayer = this._gameModel.home.currentField.findIndex(pl => pl.id === secondPlayer.id);
        const slotFirstPlayer = firstPlayer.slotId;
        const slotSecondPlayer = secondPlayer.slotId;
        firstPlayer.slotId = slotSecondPlayer;
        secondPlayer.slotId = slotFirstPlayer;
        this._gameModel.home.currentField[indexFirstPlayer] = secondPlayer;
        this._gameModel.home.currentField[indexSecondPlayer] = firstPlayer;
        this._homeField$.next(this._gameModel.home.currentField);
    }

    swapFieldPlayerVisitor(firstPlayer: GamePlayerModel, secondPlayer: GamePlayerModel): void {
        const indexFirstPlayer = this._gameModel.visitor.currentField.findIndex(pl => pl.id === firstPlayer.id);
        const indexSecondPlayer = this._gameModel.visitor.currentField.findIndex(pl => pl.id === secondPlayer.id);
        const slotFirstPlayer = firstPlayer.slotId;
        const slotSecondPlayer = secondPlayer.slotId;
        firstPlayer.slotId = slotSecondPlayer;
        secondPlayer.slotId = slotFirstPlayer;
        this._gameModel.visitor.currentField[indexFirstPlayer] = secondPlayer;
        this._gameModel.visitor.currentField[indexSecondPlayer] = firstPlayer;
        this._visitorField$.next(this._gameModel.visitor.currentField);
    }

    toggleBenchHome(disable: boolean): void {
        this._gameModel.home.currentBench = toggleDisablePlayerList(this._gameModel.home.currentBench, disable);
        this._homeBench$.next([...this._gameModel.home.currentBench]);
    }

    toggleBenchVisitor(disable: boolean): void {
        this._gameModel.visitor.currentBench = toggleDisablePlayerList(this._gameModel.visitor.currentBench, disable);
        this._visitorBench$.next([...this._gameModel.visitor.currentBench]);
    }

    toggleFieldHome(disable: boolean): void {
        this._gameModel.home.currentField = toggleDisablePlayerList(this._gameModel.home.currentField, disable);
        this._homeField$.next([...this._gameModel.home.currentField]);
    }

    toggleFieldHomeWithPlayer(player: GamePlayerModel): void {
        this._homeField$.next([...this._gameModel.home.currentField]);
    }

    toggleFieldVisitor(disable: boolean): void {
        this._gameModel.visitor.currentField = toggleDisablePlayerList(this._gameModel.visitor.currentField, disable);
        this._visitorField$.next([...this._gameModel.visitor.currentField]);
    }

    toggleFieldVisitorWithPlayer(player: GamePlayerModel): void {
        this._visitorField$.next([...this._gameModel.visitor.currentField]);
    }

    toggleSuspensionsHome(disable: boolean): void {
        this._gameModel.home.currentPenaltyTime = toggleDisablePlayerList(
            this._gameModel.home.currentPenaltyTime,
            disable
        );
        this._gameModel.home.currentGameSuspensions = toggleDisablePlayerList(
            this._gameModel.home.currentGameSuspensions,
            disable
        );
        this._homeSuspended$.next([...this._gameModel.home.currentPenaltyTime]);
        this._homeGameSuspended$.next([...this._gameModel.home.currentGameSuspensions]);
    }

    toggleSuspensionsVisitor(disable: boolean): void {
        this._gameModel.visitor.currentPenaltyTime = toggleDisablePlayerList(
            this._gameModel.visitor.currentPenaltyTime,
            disable
        );
        this._gameModel.visitor.currentGameSuspensions = toggleDisablePlayerList(
            this._gameModel.visitor.currentGameSuspensions,
            disable
        );
        this._visitorSuspended$.next([...this._gameModel.visitor.currentPenaltyTime]);
        this._visitorGameSuspended$.next([...this._gameModel.visitor.currentGameSuspensions]);
    }

    undoPlayerAfterSuspensionHome(newFieldPlayer: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        newFieldPlayer.slotId = this.getFirstFreeSlot(this._gameModel.home.currentField);
        this._gameModel.home.currentField = [...this._gameModel.home.currentField, newFieldPlayer];
        if (suspensionType === '2_MIN') {
            this._gameModel.home.currentPenaltyTime = [...this._gameModel.home.currentPenaltyTime
                .filter(p => p.id !== newFieldPlayer.id)];
        } else {
            this._gameModel.home.currentGameSuspensions = [...this._gameModel.home.currentGameSuspensions
                .filter(p => p.id !== newFieldPlayer.id)];
        }
        this._homeField$.next(this._gameModel.home.currentField);
        this._homeSuspended$.next(this._gameModel.home.currentPenaltyTime);
        this._homeGameSuspended$.next(this._gameModel.home.currentGameSuspensions);
    }

    undoPlayerAfterSuspensionVisitor(newFieldPlayer: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        newFieldPlayer.slotId = this.getFirstFreeSlot(this._gameModel.visitor.currentField);
        this._gameModel.visitor.currentField = [...this._gameModel.visitor.currentField, newFieldPlayer];
        if (suspensionType === '2_MIN') {
            this._gameModel.visitor.currentPenaltyTime = [...this._gameModel.visitor.currentPenaltyTime
                .filter(p => p.id !== newFieldPlayer.id)];
        } else {
            this._gameModel.visitor.currentGameSuspensions = [...this._gameModel.visitor.currentGameSuspensions
                .filter(p => p.id !== newFieldPlayer.id)];
        }
        this._visitorField$.next(this._gameModel.visitor.currentField);
        this._visitorSuspended$.next(this._gameModel.visitor.currentPenaltyTime);
        this._visitorGameSuspended$.next(this._gameModel.visitor.currentGameSuspensions);
    }

    undoPlayerInGameHomeAfterPenalty(
        playerOnSuspension: GamePlayerModel,
        playerSelectedToGoBack: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        playerSelectedToGoBack.slotId = undefined;
        playerSelectedToGoBack.disabled = false;
        this._gameModel.home.currentField =
            [...this._gameModel.home.currentField.filter(p => p.id !== playerSelectedToGoBack.id)];
        if (playerSelectedToGoBack.id === playerOnSuspension.id) {
            // the player in game is same as the player that had the suspension
            if (suspensionType === '2_MIN') {
                this._gameModel.home.currentPenaltyTime = [...this._gameModel.home.currentPenaltyTime, playerSelectedToGoBack];
            } else {
                this._gameModel.home.currentGameSuspensions = [...this._gameModel.home.currentGameSuspensions, playerSelectedToGoBack];
            }
        } else {
            // the player in game was a different one
            playerSelectedToGoBack.disabled = true;
            this._gameModel.home.currentBench = [...this._gameModel.home.currentBench
                .filter(pl => pl.id !== playerOnSuspension.id), playerSelectedToGoBack];
            playerOnSuspension.slotId = undefined;
            playerOnSuspension.disabled = false;
            if (suspensionType === '2_MIN') {
                this._gameModel.home.currentPenaltyTime = [...this._gameModel.home.currentPenaltyTime, playerOnSuspension];
            } else {
                this._gameModel.home.currentGameSuspensions = [...this._gameModel.home.currentGameSuspensions, playerOnSuspension];
            }

        }
        this._homeField$.next(this._gameModel.home.currentField);
        this._homeBench$.next(this._gameModel.home.currentBench);
        this._homeSuspended$.next(this._gameModel.home.currentPenaltyTime);
        this._homeGameSuspended$.next(this._gameModel.home.currentGameSuspensions);
    }

    undoPlayerInGameVisitorAfterPenalty(
        playerOnSuspension: GamePlayerModel,
        playerSelectedToGoBack: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        playerSelectedToGoBack.slotId = undefined;
        playerSelectedToGoBack.disabled = false;
        this._gameModel.visitor.currentField =
            [...this._gameModel.visitor.currentField.filter(p => p.id !== playerSelectedToGoBack.id)];
        if (playerSelectedToGoBack.id === playerOnSuspension.id) {
            // the player in game is same as the player that had the suspension
            if (suspensionType === '2_MIN') {
                this._gameModel.visitor.currentPenaltyTime = [...this._gameModel.visitor.currentPenaltyTime, playerSelectedToGoBack];
            } else {
                this._gameModel.visitor.currentGameSuspensions =
                    [...this._gameModel.visitor.currentGameSuspensions, playerSelectedToGoBack];
            }
        } else {
            // the player in game was a different one
            playerSelectedToGoBack.disabled = true;
            this._gameModel.visitor.currentBench = [...this._gameModel.visitor.currentBench
                .filter(pl => pl.id !== playerOnSuspension.id), playerSelectedToGoBack];
            playerOnSuspension.slotId = undefined;
            playerOnSuspension.disabled = false;
            if (suspensionType === '2_MIN') {
                this._gameModel.visitor.currentPenaltyTime = [...this._gameModel.visitor.currentPenaltyTime, playerOnSuspension];
            } else {
                this._gameModel.visitor.currentGameSuspensions = [...this._gameModel.visitor.currentGameSuspensions, playerOnSuspension];
            }

        }
        this._visitorField$.next(this._gameModel.visitor.currentField);
        this._visitorBench$.next(this._gameModel.visitor.currentBench);
        this._visitorSuspended$.next(this._gameModel.visitor.currentPenaltyTime);
        this._visitorGameSuspended$.next(this._gameModel.visitor.currentGameSuspensions);
    }

    undoPos1AfterSuspensionHome(suspendedPos1: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        const currentGk = this._gameModel.home.currentField.filter(pl => pl.position === 'gk')[0];
        currentGk.slotId = undefined;
        currentGk.disabled = true;
        suspendedPos1.slotId = 1;
        suspendedPos1.disabled = false;
        this._gameModel.home.currentField = [...this._gameModel.home.currentField.filter(pl => pl.position !== 'gk'), suspendedPos1];
        if (suspensionType === '2_MIN') {
            this._gameModel.home.currentPenaltyTime =
                [...this._gameModel.home.currentPenaltyTime.filter(pl => pl.id !== suspendedPos1.id)];
        } else {
            this._gameModel.home.currentGameSuspensions = [...this._gameModel.home.currentGameSuspensions
                .filter(p => p.id !== suspendedPos1.id)];
        }
        this._gameModel.home.currentBench = [...this._gameModel.home.currentBench, currentGk];

        this._homeField$.next(this._gameModel.home.currentField);
        this._homeBench$.next(this._gameModel.home.currentBench);
        this._homeSuspended$.next(this._gameModel.home.currentPenaltyTime);
        this._homeGameSuspended$.next(this._gameModel.home.currentGameSuspensions);
    }

    undoPos1AfterSuspensionVisitor(suspendedPos1: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        const currentGk = this._gameModel.visitor.currentField.filter(pl => pl.position === 'gk')[0];
        currentGk.slotId = undefined;
        currentGk.disabled = true;
        suspendedPos1.slotId = 1;
        suspendedPos1.disabled = false;
        this._gameModel.visitor.currentField = [...this._gameModel.visitor.currentField.filter(pl => pl.position !== 'gk'), suspendedPos1];
        if (suspensionType === '2_MIN') {
            this._gameModel.visitor.currentPenaltyTime =
                [...this._gameModel.visitor.currentPenaltyTime.filter(pl => pl.id !== suspendedPos1.id)];
        } else {
            this._gameModel.visitor.currentGameSuspensions = [...this._gameModel.visitor.currentGameSuspensions
                .filter(p => p.id !== suspendedPos1.id)];
        }
        this._gameModel.visitor.currentBench = [...this._gameModel.visitor.currentBench, currentGk];

        this._visitorField$.next(this._gameModel.visitor.currentField);
        this._visitorBench$.next(this._gameModel.visitor.currentBench);
        this._visitorSuspended$.next(this._gameModel.visitor.currentPenaltyTime);
        this._visitorGameSuspended$.next(this._gameModel.visitor.currentGameSuspensions);
    }

    undoPos1SuspensionSubstituteHome(suspendedSubstitute: GamePlayerModel): void {
        suspendedSubstitute.slotId = this.getFirstFreeSlot(this._gameModel.home.currentField);
        suspendedSubstitute.disabled = false;
        this._gameModel.home.currentField = [...this._gameModel.home.currentField, suspendedSubstitute];
        this._gameModel.home.currentBench = [...this._gameModel.home.currentBench.filter(pl => pl.id !== suspendedSubstitute.id)];
        this._homeField$.next(this._gameModel.home.currentField);
        this._homeBench$.next(this._gameModel.home.currentBench);
    }

    undoPos1SuspensionSubstituteVisitor(suspendedSubstitute: GamePlayerModel): void {
        suspendedSubstitute.slotId = this.getFirstFreeSlot(this._gameModel.visitor.currentField);
        suspendedSubstitute.disabled = false;
        this._gameModel.visitor.currentField = [...this._gameModel.visitor.currentField, suspendedSubstitute];
        this._gameModel.visitor.currentBench = [...this._gameModel.visitor.currentBench.filter(pl => pl.id !== suspendedSubstitute.id)];
        this._visitorField$.next(this._gameModel.visitor.currentField);
        this._visitorBench$.next(this._gameModel.visitor.currentBench);
    }

    swapGoalkeeperHome(firstPlayer: GamePlayerModel, secondPlayer: GamePlayerModel): void {
    }

    swapGoalkeeperVisitor(firstPlayer: GamePlayerModel, secondPlayer: GamePlayerModel): void {
    }

    toggleSecondGoalkeeperHome(): void {
    }

    toggleSecondGoalkeeperVisitor(): void {
    }

    putPlayerOnBenchHomeLite(playerOnBench: GamePlayerModel, suspensionType: SuspensionActionTypes) {
    }
    putPlayerOnBenchVisitorLite(playerOnBench: GamePlayerModel, suspensionType: SuspensionActionTypes) {
    }


    private getFirstFreeSlot(field: GamePlayerModel[]): number {
        const availableSlots = [1, 2, 3, 4, 5, 6, 7];
        let firstFreeSlot = -1;
        const slotIds = field.reduce((slotList, pl) => {
            if (pl.slotId) {
                slotList.push(pl.slotId);
            }
            return slotList;
        }, [] as number[]).sort();
        availableSlots.every((value, index) => {
            if (value !== slotIds[index]) {
                firstFreeSlot = value;
                return false; // breakout
            } else {
                return true;
            }
        });
        return firstFreeSlot;
    }

    lastTimerSec: number;
    performClockTickEvent(timer: TimerModel): void {
        if (timer.counterSecSinceStart !== 0 && this.lastTimerSec != timer.counterSecSinceStart) {
            this.lastTimerSec = timer.counterSecSinceStart;
            this._gameModel.home.currentField = this._gameModel.home.currentField.map(pl => {
                pl.playTime++;
                return pl;
            });
            this._gameModel.visitor.currentField = this._gameModel.visitor.currentField.map(pl => {
                pl.playTime++;
                return pl;
            });
            // Consider to reduce redraws
            // if ((timer.counterSecSinceStart % 2) === 0) {
            this._homeField$.next(this._gameModel.home.currentField);
            this._visitorField$.next(this._gameModel.visitor.currentField);
            // }
        }
    }

    // public performClockTickEvents(timer: TimerModel): void {
    //     // this._core.handballTimerService.gameCounter$.subscribe(timer => {
    //         if (timer.counterSecSinceStart !== 0) {
    //             this._gameModel.home.currentField = this._gameModel.home.currentField.map(pl => {
    //                 pl.playTime++;
    //                 return pl;
    //             });
    //             this._gameModel.visitor.currentField = this._gameModel.visitor.currentField.map(pl => {
    //                 pl.playTime++;
    //                 return pl;
    //             });
    //             // Consider to reduce redraws
    //             // if ((timer.counterSecSinceStart % 2) === 0) {
    //             this._homeField$.next(this._gameModel.home.currentField);
    //             this._visitorField$.next(this._gameModel.visitor.currentField);
    //             // }
    //         }
    //     // });
    // }

    swapPlayerForFreeSlotIdHome(player: GamePlayerModel, slotId: number): void {
    }

    swapPlayerForFreeSlotIdVisitor(player: GamePlayerModel, slotId: number): void {
    }

    undoPos1PlayerSuspensionLiteHome(
        playerOnSuspension: GamePlayerModel,
        playerSelectedToGoBack: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
    }

    undoPos1PlayerSuspensionLiteVisitor(
        playerOnSuspension: GamePlayerModel,
        playerSelectedToGoBack: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
    }

    homeEmptyGoal(): boolean {
        return false;
    }

    visitorEmptyGoal(): boolean {
        return false;
    }

    swapGoalAdditionalPlayerWithGoalkeeperOnBenchHome(): void {
    }

    swapGoalAdditionalPlayerWithGoalkeeperOnBenchVisitor(): void {
    }

    deleteVideoSuspensionEventHome(
        playerId: number,
        suspensionType: SuspensionActionTypes,
        suspendedPlayerId: number
    ): boolean {
        let suspendedPlayer: GamePlayerModel[];
        if (suspensionType === '2_MIN') {
            suspendedPlayer = this._gameModel.home.currentPenaltyTime.filter(pl => pl.id === suspendedPlayerId);
            this._gameModel.home.currentPenaltyTime = [...this._gameModel.home.currentPenaltyTime
                .filter(p => p.id !== suspendedPlayerId)];
        } else if (suspensionType === 'BLUE_CARD' || suspensionType === 'RED_CARD') {
            suspendedPlayer = this._gameModel.home.currentGameSuspensions.filter(pl => pl.id === suspendedPlayerId);
            if (suspendedPlayer.length === 0) {
                suspendedPlayer = this._gameModel.home.players.filter(pl => pl.id === suspendedPlayerId);
            } else {
                this._gameModel.home.currentGameSuspensions = [...this._gameModel.home.currentGameSuspensions
                    .filter(p => p.id !== suspendedPlayerId)];
            }
        }
        suspendedPlayer[0].disabled = true;
        this._gameModel.home.currentBench = [...this._gameModel.home.currentBench, suspendedPlayer[0]];
        const playerToComeIn = this._gameModel.home.currentBench.filter(pl => pl.id === playerId);
        this._gameModel.home.currentBench = this._gameModel.home.currentBench.filter(pl => pl.id !== playerId);
        playerToComeIn[0].slotId = this.getFirstFreeSlot(this._gameModel.home.currentField);
        playerToComeIn[0].disabled = false;
        this._gameModel.home.currentField = [...this._gameModel.home.currentField, playerToComeIn[0]];

        this._homeField$.next(this._gameModel.home.currentField);
        this._homeBench$.next(this._gameModel.home.currentBench);
        this._homeSuspended$.next(this._gameModel.home.currentPenaltyTime);
        this._homeGameSuspended$.next(this._gameModel.home.currentGameSuspensions);
        return true;
    }

    deleteVideoSuspensionEventVisitor(
        playerId: number,
        suspensionType: SuspensionActionTypes,
        suspendedPlayerId: number
    ): boolean {
        let suspendedPlayer: GamePlayerModel[];
        if (suspensionType === '2_MIN') {
            suspendedPlayer = this._gameModel.visitor.currentPenaltyTime.filter(pl => pl.id === suspendedPlayerId);
            this._gameModel.visitor.currentPenaltyTime = [...this._gameModel.visitor.currentPenaltyTime
                .filter(p => p.id !== suspendedPlayerId)];
        } else if (suspensionType === 'BLUE_CARD' || suspensionType === 'RED_CARD') {
            suspendedPlayer = this._gameModel.visitor.currentGameSuspensions.filter(pl => pl.id === suspendedPlayerId);
            if (suspendedPlayer.length === 0) {
                suspendedPlayer = this._gameModel.visitor.players.filter(pl => pl.id === suspendedPlayerId);
            } else {
                this._gameModel.visitor.currentGameSuspensions = [...this._gameModel.visitor.currentGameSuspensions
                    .filter(p => p.id !== suspendedPlayerId)];
            }
        }
        suspendedPlayer[0].disabled = true;
        this._gameModel.visitor.currentBench = [...this._gameModel.visitor.currentBench, suspendedPlayer[0]];
        const playerToComeIn = this._gameModel.visitor.currentBench.filter(pl => pl.id === playerId);
        this._gameModel.visitor.currentBench = this._gameModel.visitor.currentBench.filter(pl => pl.id !== playerId);
        playerToComeIn[0].slotId = this.getFirstFreeSlot(this._gameModel.visitor.currentField);
        playerToComeIn[0].disabled = false;
        this._gameModel.visitor.currentField = [...this._gameModel.visitor.currentField, playerToComeIn[0]];

        this._visitorField$.next(this._gameModel.visitor.currentField);
        this._visitorBench$.next(this._gameModel.visitor.currentBench);
        this._visitorSuspended$.next(this._gameModel.visitor.currentPenaltyTime);
        this._visitorGameSuspended$.next(this._gameModel.visitor.currentGameSuspensions);
        return true;
    }
}
