<ion-button color="primary" (click)="createPDFReport()">Create PDF Report</ion-button>
<div class="chart-wrapper" class="ion-hida" style="width: 250px;">
  <canvas *ngIf="canvas1Values.length" #chart1 style="background-color: rgb(226, 224, 224); padding: 20px 5px;"
          width="3000px" height="3000px">{{ doughnut1Chart }}</canvas>

  <canvas *ngIf="canvas2Values.length" #chart2 style="background-color: rgb(226, 224, 224); padding: 20px 5px;"
          width="3000px" height="3000px">{{ doughnut2Chart }}</canvas>
</div>
<div style="display: block; width: 1000px;">
  <canvas #chart3>{{ bar3Chart }}</canvas>
  <canvas #chart4>{{ bar4Chart }}</canvas>

  <ng-container *ngFor="let team of ['home', 'visitor']">
    <div class="grid-col (1/2) vl" id="lastGames" *ngFor="let pl of globalData[team].goalkeepersShooting; let i=index;" [id]="'gk_shooting-'+team+'-'+i" style="width: 215px; display: block;">
      <h5 class="select-label mt-4" [style.color]="'black'">{{ pl.name }} - {{ pl.backNumber }}</h5>
      <hr />
      <div class="w-full mt-4">
        <app-shooter-position id="ind-plshooting"
                [goalConnectionCounter]="pl.counters | shotTransform"
                class="w-full">
        </app-shooter-position>
      </div>
    </div>
  </ng-container>
</div>
