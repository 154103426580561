<div class="timeline-container" *ngIf="timelineStatsVisitor && timelineStatsHome">
  <div class="header-teams">
    <p class="home-team">
      {{ homeTeam }}
    </p>
    <p class="visitor-team">
      {{ visitorTeam }}
    </p>
  </div>

  <div class="first-second-half-line">
    <div style="cursor: pointer;" [style.visibility]="currentHalf === 'first' ? 'hidden' : 'visible'"
         [tooltip]="'First Half'" (click)="onFirstHalfClick()">
      <ion-icon name="arrow-back-circle-outline" class="arrow-back" [color]="'secondary'"></ion-icon>
    </div>
    <div style="cursor: pointer;" [style.visibility]="currentHalf === 'second' ? 'hidden' : 'visible'"
         [tooltip]="'Second Half'" (click)="onSecondHalf()">
      <ion-icon [tooltip]="'Second Half'" name="arrow-forward-circle-outline" class="arrow-back" [color]="'secondary'"></ion-icon>
    </div>
  </div>

  <ng-container *ngIf="currentHalf === 'first'; else secondHalf">
    <div class="timeline-line">
      <div class="minutes">
        <p class="minutes-txt">
          0-10'
        </p>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ (timelineStatsHome.goals10OP > 0 ? (timelineStatsHome.goals10OP /
              (timelineStatsHome.offensePositional10 ) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ (timelineStatsHome.goals10FB /
              (timelineStatsHome.fastBreak10 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ (timelineStatsHome.goals10CG /
              (timelineStatsHome.counterGoal10 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
      </div>

      <div style="border-right: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsHome.possessions10 }}
        </p>
        <div class="goals-timeline flex-end">
          <p *ngIf="timelineStatsHome.goals10CG > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals10CG, timelineStatsHome.possessions10)"
             class="counter-goal-num">
            {{ timelineStatsHome.goals10CG }}
          </p>
          <p *ngIf="timelineStatsHome.goals10FB > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals10FB, timelineStatsHome.possessions10)"
             class="fast-break-goal-num">
            {{ timelineStatsHome.goals10FB }}
          </p>
          <p *ngIf="timelineStatsHome.goals10OP > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals10OP, timelineStatsHome.possessions10)"
             class="offense-position-goal-num">
            {{ timelineStatsHome.goals10OP }}
          </p>
        </div>
        <div class="failed-shots-timeline flex-end">
          <p *ngIf="timelineStatsHome.failedShots10 > 0" [style.width]="calculateBarWidth(timelineStatsHome.failedShots10, timelineStatsHome.possessions10)" class="num-text blue">
            {{ timelineStatsHome.failedShots10 }}
          </p>
        </div>
        <div class="saves-timeline flex-end">
          <p *ngIf="timelineStatsHome.saves10 > 0" [style.width]="calculateBarWidth(timelineStatsHome.saves10, timelineStatsHome.possessions10)" class="num-text orange">
            {{ timelineStatsHome.saves10 }}
          </p>
        </div>
        <div class="lost-balls-timeline flex-end">
          <p *ngIf="timelineStatsHome.lostBalls10 > 0" [style.width]="calculateBarWidth(timelineStatsHome.lostBalls10, timelineStatsHome.possessions10)" class="num-text red">
            {{ timelineStatsHome.lostBalls10 }}
          </p>
        </div>
        <div class="two-min-timeline flex-end">
          <p *ngIf="timelineStatsHome.twoMins10 > 0" [style.width]="calculateBarWidth(timelineStatsHome.twoMins10, timelineStatsHome.possessions10)" class="num-text black">
            {{ timelineStatsHome.twoMins10 }}
          </p>
        </div>

      </div>

      <div class="events">
        <p class="events-txt">
          {{ 'Possessions' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Goals (CG/FB/OP)' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Failed Shots' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Saves' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Lost Balls' | translate }}
        </p>
        <p class="events-txt">
          {{ '2min' | translate }}
        </p>
      </div>

      <div style="border-left: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsVisitor.possessions10 }}
        </p>
        <div class="goals-timeline">
          <p *ngIf="timelineStatsVisitor.goals10OP > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals10OP, timelineStatsVisitor.possessions10)"
             class="offense-position-goal-num">
            {{ timelineStatsVisitor.goals10OP }}
          </p>
          <p *ngIf="timelineStatsVisitor.goals10FB > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals10FB, timelineStatsVisitor.possessions10)"
             class="fast-break-goal-num">
            {{ timelineStatsVisitor.goals10FB }}
          </p>
          <p *ngIf="timelineStatsVisitor.goals10CG > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals10CG, timelineStatsVisitor.possessions10)"
             class="counter-goal-num">
            {{ timelineStatsVisitor.goals10CG }}
          </p>
        </div>
        <div class="failed-shots-timeline">
          <p *ngIf="timelineStatsVisitor.failedShots10 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.failedShots10, timelineStatsVisitor.possessions10)" class="num-text blue">
            {{ timelineStatsVisitor.failedShots10 }}
          </p>
        </div>
        <div class="saves-timeline">
          <p *ngIf="timelineStatsVisitor.saves10 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.saves10, timelineStatsVisitor.possessions10)" class="num-text orange">
            {{ timelineStatsVisitor.saves10 }}
          </p>
        </div>
        <div class="lost-balls-timeline">
          <p *ngIf="timelineStatsVisitor.lostBalls10 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.lostBalls10, timelineStatsVisitor.possessions10)" class="num-text red">
            {{ timelineStatsVisitor.lostBalls10 }}
          </p>
        </div>
        <div class="two-min-timeline">
          <p *ngIf="timelineStatsVisitor.twoMins10 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.twoMins10, timelineStatsVisitor.possessions10)" class="num-text black">
            {{ timelineStatsVisitor.twoMins10 }}
          </p>
        </div>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ (timelineStatsVisitor.goals10OP > 0 ? (timelineStatsVisitor.goals10OP /
              (timelineStatsVisitor.offensePositional10 ) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ (timelineStatsVisitor.goals10FB > 0 ? (timelineStatsVisitor.goals10FB /
              (timelineStatsVisitor.fastBreak10 ) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ (timelineStatsVisitor.goals10CG > 0 ? (timelineStatsVisitor.goals10CG /
              (timelineStatsVisitor.counterGoal10 ) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
      </div>


      <div class="minutes">
        <p class="minutes-txt">
          0-10'
        </p>
      </div>
    </div>


    <!-- -->


    <div class="timeline-line">
      <div class="minutes">
        <p class="minutes-txt">
          10-20'
        </p>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ (timelineStatsHome.goals20OP > 0 ? (timelineStatsHome.goals20OP /
              (timelineStatsHome.offensePositional20) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ (timelineStatsHome.goals20FB > 0 ? (timelineStatsHome.goals20FB /
              (timelineStatsHome.fastBreak20) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ (timelineStatsHome.goals20CG > 0 ? (timelineStatsHome.goals20CG /
              (timelineStatsHome.counterGoal20) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
      </div>

      <div style="border-right: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsHome.possessions20 }}
        </p>
        <div class="goals-timeline flex-end">
          <p *ngIf="timelineStatsHome.goals20CG > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals20CG, timelineStatsHome.possessions20)"
             class="counter-goal-num">
            {{ timelineStatsHome.goals20CG }}
          </p>
          <p *ngIf="timelineStatsHome.goals20FB > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals20FB, timelineStatsHome.possessions20)"
             class="fast-break-goal-num">
            {{ timelineStatsHome.goals20FB }}
          </p>
          <p *ngIf="timelineStatsHome.goals20OP > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals20OP, timelineStatsHome.possessions20)"
             class="offense-position-goal-num">
            {{ timelineStatsHome.goals20OP }}
          </p>
        </div>
        <div class="failed-shots-timeline flex-end">
          <p *ngIf="timelineStatsHome.failedShots20 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.failedShots20, timelineStatsHome.possessions20)" class="num-text blue">
            {{ timelineStatsHome.failedShots20 }}
          </p>
        </div>
        <div class="saves-timeline flex-end">
          <p *ngIf="timelineStatsHome.saves20 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.saves20, timelineStatsHome.possessions20)" class="num-text orange">
            {{ timelineStatsHome.saves20 }}
          </p>
        </div>
        <div class="lost-balls-timeline flex-end">
          <p *ngIf="timelineStatsHome.lostBalls20 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.lostBalls20, timelineStatsHome.possessions20)" class="num-text red">
            {{ timelineStatsHome.lostBalls20 }}
          </p>
        </div>
        <div class="two-min-timeline flex-end">
          <p *ngIf="timelineStatsHome.twoMins20 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.twoMins20, timelineStatsHome.possessions20)" class="num-text black">
            {{ timelineStatsHome.twoMins20 }}
          </p>
        </div>

      </div>

      <div class="events">
        <p class="events-txt">
          {{ 'Possessions' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Goals (CG/FB/OP)' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Failed Shots' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Saves' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Lost Balls' | translate }}
        </p>
        <p class="events-txt">
          {{ '2min' | translate }}
        </p>
      </div>

      <div style="border-left: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsVisitor.possessions20 }}
        </p>
        <div class="goals-timeline">
          <p *ngIf="timelineStatsVisitor.goals20OP > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals20OP, timelineStatsVisitor.possessions20)"
             class="offense-position-goal-num">
            {{ timelineStatsVisitor.goals20OP }}
          </p>
          <p *ngIf="timelineStatsVisitor.goals20FB > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals20FB, timelineStatsVisitor.possessions20)"
             class="fast-break-goal-num">
            {{ timelineStatsVisitor.goals20FB }}
          </p>
          <p *ngIf="timelineStatsVisitor.goals20CG > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals20CG, timelineStatsVisitor.possessions20)"
             class="counter-goal-num">
            {{ timelineStatsVisitor.goals20CG }}
          </p>
        </div>
        <div class="failed-shots-timeline">
          <p *ngIf="timelineStatsVisitor.failedShots20 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.failedShots20, timelineStatsVisitor.possessions20)" class="num-text blue">
            {{ timelineStatsVisitor.failedShots20 }}
          </p>
        </div>
        <div class="saves-timeline">
          <p *ngIf="timelineStatsVisitor.saves20 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.saves20, timelineStatsVisitor.possessions20)" class="num-text orange">
            {{ timelineStatsVisitor.saves20 }}
          </p>
        </div>
        <div class="lost-balls-timeline">
          <p *ngIf="timelineStatsVisitor.lostBalls20 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.lostBalls20, timelineStatsVisitor.possessions20)" class="num-text red">
            {{ timelineStatsVisitor.lostBalls20 }}
          </p>
        </div>
        <div class="two-min-timeline">
          <p *ngIf="timelineStatsVisitor.twoMins20 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.twoMins20, timelineStatsVisitor.possessions20)" class="num-text black">
            {{ timelineStatsVisitor.twoMins20 }}
          </p>
        </div>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ (timelineStatsVisitor.goals20OP > 0 ? (timelineStatsVisitor.goals20OP /
              (timelineStatsVisitor.offensePositional20) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ (timelineStatsVisitor.goals20FB > 0 ? (timelineStatsVisitor.goals20FB /
              (timelineStatsVisitor.fastBreak20) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ (timelineStatsVisitor.goals20CG > 0 ? (timelineStatsVisitor.goals20CG /
              (timelineStatsVisitor.counterGoal20) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
      </div>


      <div class="minutes">
        <p class="minutes-txt">
          10-20'
        </p>
      </div>
    </div>


    <!-- -->

    <div class="timeline-last-line">
      <div class="minutes">
        <p class="minutes-txt">
          20-30'
        </p>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ (timelineStatsHome.goals30OP > 0 ? (timelineStatsHome.goals30OP /
              (timelineStatsHome.offensePositional30) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ (timelineStatsHome.goals30FB > 0 ? (timelineStatsHome.goals30FB /
              (timelineStatsHome.fastBreak30) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ (timelineStatsHome.goals30CG > 0 ? (timelineStatsHome.goals30CG /
              (timelineStatsHome.counterGoal30) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
      </div>

      <div style="border-right: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsHome.possessions30 }}
        </p>
        <div class="goals-timeline flex-end">
          <p *ngIf="timelineStatsHome.goals30CG > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals30CG, timelineStatsHome.possessions30)"
             class="counter-goal-num">
            {{ timelineStatsHome.goals30CG }}
          </p>
          <p *ngIf="timelineStatsHome.goals30FB > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals30FB, timelineStatsHome.possessions30)"
             class="fast-break-goal-num">
            {{ timelineStatsHome.goals30FB }}
          </p>
          <p *ngIf="timelineStatsHome.goals30OP > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals30OP, timelineStatsHome.possessions30)"
             class="offense-position-goal-num">
            {{ timelineStatsHome.goals30OP }}
          </p>
        </div>
        <div class="failed-shots-timeline flex-end">
          <p *ngIf="timelineStatsHome.failedShots30 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.failedShots30, timelineStatsHome.possessions30)" class="num-text blue">
            {{ timelineStatsHome.failedShots30 }}
          </p>
        </div>
        <div class="saves-timeline flex-end">
          <p *ngIf="timelineStatsHome.saves30 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.saves30, timelineStatsHome.possessions30)" class="num-text orange">
            {{ timelineStatsHome.saves30 }}
          </p>
        </div>
        <div class="lost-balls-timeline flex-end">
          <p *ngIf="timelineStatsHome.lostBalls30 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.lostBalls30, timelineStatsHome.possessions30)" class="num-text red">
            {{ timelineStatsHome.lostBalls30 }}
          </p>
        </div>
        <div class="two-min-timeline flex-end">
          <p *ngIf="timelineStatsHome.twoMins30 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.twoMins30, timelineStatsHome.possessions30)" class="num-text black">
            {{ timelineStatsHome.twoMins30 }}
          </p>
        </div>

      </div>

      <div class="events">
        <p class="events-txt">
          {{ 'Possessions' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Goals (CG/FB/OP)' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Failed Shots' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Saves' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Lost Balls' | translate }}
        </p>
        <p class="events-txt">
          {{ '2min' | translate }}
        </p>
      </div>

      <div style="border-left: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsVisitor.possessions30 }}
        </p>
        <div class="goals-timeline">
          <p *ngIf="timelineStatsVisitor.goals30OP > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals30OP, timelineStatsVisitor.possessions30)"
             class="offense-position-goal-num">
            {{ timelineStatsVisitor.goals30OP }}
          </p>
          <p *ngIf="timelineStatsVisitor.goals30FB > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals30FB, timelineStatsVisitor.possessions30)"
             class="fast-break-goal-num">
            {{ timelineStatsVisitor.goals30FB }}
          </p>
          <p *ngIf="timelineStatsVisitor.goals30CG > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals30CG, timelineStatsVisitor.possessions30)"
             class="counter-goal-num">
            {{ timelineStatsVisitor.goals30CG }}
          </p>

        </div>
        <div class="failed-shots-timeline">
          <p *ngIf="timelineStatsVisitor.failedShots30 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.failedShots30, timelineStatsVisitor.possessions30)" class="num-text blue">
            {{ timelineStatsVisitor.failedShots30 }}
          </p>
        </div>
        <div class="saves-timeline">
          <p *ngIf="timelineStatsVisitor.saves30 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.saves30, timelineStatsVisitor.possessions30)" class="num-text orange">
            {{ timelineStatsVisitor.saves30 }}
          </p>
        </div>
        <div class="lost-balls-timeline">
          <p *ngIf="timelineStatsVisitor.lostBalls30 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.lostBalls30, timelineStatsVisitor.possessions30)" class="num-text red">
            {{ timelineStatsVisitor.lostBalls30 }}
          </p>
        </div>
        <div class="two-min-timeline">
          <p *ngIf="timelineStatsVisitor.twoMins30 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.twoMins30, timelineStatsVisitor.possessions30)" class="num-text black">
            {{ timelineStatsVisitor.twoMins30 }}
          </p>
        </div>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ (timelineStatsVisitor.goals30OP > 0 ? (timelineStatsVisitor.goals30OP /
              (timelineStatsVisitor.offensePositional30) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ (timelineStatsVisitor.goals30FB > 0 ? (timelineStatsVisitor.goals30FB /
              (timelineStatsVisitor.fastBreak30) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ (timelineStatsVisitor.goals30CG > 0 ? (timelineStatsVisitor.goals30CG /
              (timelineStatsVisitor.counterGoal30) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
      </div>


      <div class="minutes">
        <p class="minutes-txt">
          20-30'
        </p>
      </div>
    </div>

    <!-- -->

    <div class="timeline-without-line">
      <div class="minutes">
        <p class="minutes-txt">
          Total 1st Half
        </p>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ ((timelineStatsHome.goals10OP + timelineStatsHome.goals20OP + timelineStatsHome.goals30OP) /
              (timelineStatsHome.offensePositional10 + timelineStatsHome.offensePositional20 + timelineStatsHome.offensePositional30 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ ((timelineStatsHome.goals10FB + timelineStatsHome.goals20FB + timelineStatsHome.goals30FB) /
              (timelineStatsHome.fastBreak10 + timelineStatsHome.fastBreak20 + timelineStatsHome.fastBreak30 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ ((timelineStatsHome.goals10CG + timelineStatsHome.goals20CG + timelineStatsHome.goals30CG) /
              (timelineStatsHome.counterGoal10 + timelineStatsHome.counterGoal20 + timelineStatsHome.counterGoal30 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
      </div>

      <div style="border-right: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ (timelineStatsHome.possessions10 + timelineStatsHome.possessions20 + timelineStatsHome.possessions30) }}
        </p>
        <div class="goals-timeline flex-end">
          <p *ngIf="(timelineStatsHome.goals10CG + timelineStatsHome.goals20CG + timelineStatsHome.goals30CG) > 0"
              [style.width]="calculateBarWidth((timelineStatsHome.goals10CG + timelineStatsHome.goals20CG + timelineStatsHome.goals30CG),
          (timelineStatsHome.possessions10 + timelineStatsHome.possessions20 + timelineStatsHome.possessions30))"
             class="counter-goal-num">
            {{ timelineStatsHome.goals10CG + timelineStatsHome.goals20CG + timelineStatsHome.goals30CG }}
          </p>
          <p *ngIf="(timelineStatsHome.goals10FB + timelineStatsHome.goals20FB + timelineStatsHome.goals30FB) > 0"
              [style.width]="calculateBarWidth((timelineStatsHome.goals10FB + timelineStatsHome.goals20FB + timelineStatsHome.goals30FB),
          (timelineStatsHome.possessions10 + timelineStatsHome.possessions20 + timelineStatsHome.possessions30))"
             class="fast-break-goal-num">
            {{ timelineStatsHome.goals10FB + timelineStatsHome.goals20FB + timelineStatsHome.goals30FB }}
          </p>
          <p *ngIf="(timelineStatsHome.goals10OP + timelineStatsHome.goals20OP + timelineStatsHome.goals30OP)"
              [style.width]="calculateBarWidth((timelineStatsHome.goals10OP + timelineStatsHome.goals20OP + timelineStatsHome.goals30OP),
          (timelineStatsHome.possessions10 + timelineStatsHome.possessions20 + timelineStatsHome.possessions30))"
             class="offense-position-goal-num">
            {{ timelineStatsHome.goals10OP + timelineStatsHome.goals20OP + timelineStatsHome.goals30OP }}
          </p>
        </div>
        <div class="failed-shots-timeline flex-end">
          <p [style.width]="calculateBarWidth((timelineStatsHome.failedShots10 + timelineStatsHome.failedShots20 + timelineStatsHome.failedShots30),
          (timelineStatsHome.possessions10 + timelineStatsHome.possessions20 + timelineStatsHome.possessions30))" class="num-text blue">
            {{ timelineStatsHome.failedShots10 + timelineStatsHome.failedShots20 + timelineStatsHome.failedShots30 }}
          </p>
        </div>
        <div class="saves-timeline flex-end">
          <p *ngIf="(timelineStatsHome.saves10 + timelineStatsHome.saves20 + timelineStatsHome.saves30) > 0"
             [style.width]="calculateBarWidth((timelineStatsHome.saves10 + timelineStatsHome.saves20 + timelineStatsHome.saves30),
          (timelineStatsHome.possessions10 + timelineStatsHome.possessions20 + timelineStatsHome.possessions30))" class="num-text orange">
            {{ timelineStatsHome.saves10 + timelineStatsHome.saves20 + timelineStatsHome.saves30 }}
          </p>
        </div>
        <div class="lost-balls-timeline flex-end">
          <p *ngIf="(timelineStatsHome.lostBalls10 + timelineStatsHome.lostBalls20 + timelineStatsHome.lostBalls30) > 0"
             [style.width]="calculateBarWidth((timelineStatsHome.lostBalls10 + timelineStatsHome.lostBalls20 + timelineStatsHome.lostBalls30),
          (timelineStatsHome.possessions10 + timelineStatsHome.possessions20 + timelineStatsHome.possessions30))" class="num-text red">
            {{ timelineStatsHome.lostBalls10 + timelineStatsHome.lostBalls20 + timelineStatsHome.lostBalls30 }}
          </p>
        </div>
        <div class="two-min-timeline flex-end">
          <p *ngIf="(timelineStatsHome.twoMins10 + timelineStatsHome.twoMins20 + timelineStatsHome.twoMins30) > 0"
             [style.width]="calculateBarWidth((timelineStatsHome.twoMins10 + timelineStatsHome.twoMins20 + timelineStatsHome.twoMins30),
          (timelineStatsHome.possessions10 + timelineStatsHome.possessions20 + timelineStatsHome.possessions30))" class="num-text black">
            {{ timelineStatsHome.twoMins10 + timelineStatsHome.twoMins20 + timelineStatsHome.twoMins30 }}
          </p>
        </div>

      </div>

      <div class="events">
        <p class="events-txt">
          {{ 'Possessions' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Goals (CG/FB/OP)' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Failed Shots' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Saves' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Lost Balls' | translate }}
        </p>
        <p class="events-txt">
          {{ '2min' | translate }}
        </p>
      </div>

      <div style="border-left: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsVisitor.possessions10 + timelineStatsVisitor.possessions20 + timelineStatsVisitor.possessions30 }}
        </p>
        <div class="goals-timeline">
          <p *ngIf="(timelineStatsVisitor.goals10OP + timelineStatsVisitor.goals20OP + timelineStatsVisitor.goals30OP) > 0"
              [style.width]="calculateBarWidth((timelineStatsVisitor.goals10OP + timelineStatsVisitor.goals20OP + timelineStatsVisitor.goals30OP),
           (timelineStatsVisitor.possessions10 + timelineStatsVisitor.possessions20 + timelineStatsVisitor.possessions30))"
             class="offense-position-goal-num">
            {{ timelineStatsVisitor.goals10OP + timelineStatsVisitor.goals20OP + timelineStatsVisitor.goals30OP }}
          </p>
          <p *ngIf="(timelineStatsVisitor.goals10FB + timelineStatsVisitor.goals20FB + timelineStatsVisitor.goals30FB) > 0"
              [style.width]="calculateBarWidth((timelineStatsVisitor.goals10FB + timelineStatsVisitor.goals20FB + timelineStatsVisitor.goals30FB),
          (timelineStatsVisitor.possessions10 + timelineStatsVisitor.possessions20 + timelineStatsVisitor.possessions30))"
             class="fast-break-goal-num">
            {{ timelineStatsVisitor.goals10FB + timelineStatsVisitor.goals20FB + timelineStatsVisitor.goals30FB }}
          </p>
          <p *ngIf="(timelineStatsVisitor.goals10CG + timelineStatsVisitor.goals20CG + timelineStatsVisitor.goals30CG) > 0"
              [style.width]="calculateBarWidth((timelineStatsVisitor.goals10CG + timelineStatsVisitor.goals20CG + timelineStatsVisitor.goals30CG),
           (timelineStatsVisitor.possessions10 + timelineStatsVisitor.possessions20 + timelineStatsVisitor.possessions30))"
             class="counter-goal-num">
            {{ timelineStatsVisitor.goals10CG + timelineStatsVisitor.goals20CG + timelineStatsVisitor.goals30CG }}
          </p>
        </div>
        <div class="failed-shots-timeline">
          <p *ngIf="(timelineStatsVisitor.failedShots10 + timelineStatsVisitor.failedShots20 + timelineStatsVisitor.failedShots30) > 0"
             [style.width]="calculateBarWidth((timelineStatsVisitor.failedShots10 + timelineStatsVisitor.failedShots20 + timelineStatsVisitor.failedShots30),
          (timelineStatsVisitor.possessions10 + timelineStatsVisitor.possessions20 + timelineStatsVisitor.possessions30))" class="num-text blue">
            {{ timelineStatsVisitor.failedShots10 + timelineStatsVisitor.failedShots20 + timelineStatsVisitor.failedShots30 }}
          </p>
        </div>
        <div class="saves-timeline">
          <p *ngIf="(timelineStatsVisitor.saves10 + timelineStatsVisitor.saves20 + timelineStatsVisitor.saves30) > 0"
             [style.width]="calculateBarWidth((timelineStatsVisitor.saves10 + timelineStatsVisitor.saves20 + timelineStatsVisitor.saves30),
          (timelineStatsVisitor.possessions10 + timelineStatsVisitor.possessions20 + timelineStatsVisitor.possessions30))" class="num-text orange">
            {{ timelineStatsVisitor.saves10 + timelineStatsVisitor.saves20 + timelineStatsVisitor.saves30 }}
          </p>
        </div>
        <div class="lost-balls-timeline">
          <p *ngIf="(timelineStatsVisitor.lostBalls10 + timelineStatsVisitor.lostBalls20 + timelineStatsVisitor.lostBalls30) > 0"
             [style.width]="calculateBarWidth((timelineStatsVisitor.lostBalls10 + timelineStatsVisitor.lostBalls20 + timelineStatsVisitor.lostBalls30),
          (timelineStatsVisitor.possessions10 + timelineStatsVisitor.possessions20 + timelineStatsVisitor.possessions30))" class="num-text red">
            {{ timelineStatsVisitor.lostBalls10 + timelineStatsVisitor.lostBalls20 + timelineStatsVisitor.lostBalls30 }}
          </p>
        </div>
        <div class="two-min-timeline">
          <p *ngIf="(timelineStatsVisitor.twoMins10 + timelineStatsVisitor.twoMins20 + timelineStatsVisitor.twoMins30) > 0"
             [style.width]="calculateBarWidth((timelineStatsVisitor.twoMins10 + timelineStatsVisitor.twoMins20 + timelineStatsVisitor.twoMins30),
          (timelineStatsVisitor.possessions10 + timelineStatsVisitor.possessions20 + timelineStatsVisitor.possessions30))" class="num-text black">
            {{ timelineStatsVisitor.twoMins10 + timelineStatsVisitor.twoMins20 + timelineStatsVisitor.twoMins30 }}
          </p>
        </div>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ ((timelineStatsVisitor.goals10OP + timelineStatsVisitor.goals20OP + timelineStatsVisitor.goals30OP) /
              (timelineStatsVisitor.offensePositional10 + timelineStatsVisitor.offensePositional20 + timelineStatsVisitor.offensePositional30 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ ((timelineStatsVisitor.goals10FB + timelineStatsVisitor.goals20FB + timelineStatsVisitor.goals30FB) /
              (timelineStatsVisitor.fastBreak10 + timelineStatsVisitor.fastBreak20 + timelineStatsVisitor.fastBreak30 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ ((timelineStatsVisitor.goals10CG + timelineStatsVisitor.goals20CG + timelineStatsVisitor.goals30CG) /
              (timelineStatsVisitor.counterGoal10 + timelineStatsVisitor.counterGoal20 + timelineStatsVisitor.counterGoal30 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
      </div>


      <div class="minutes">
        <p class="minutes-txt">
          Total 1st Half
        </p>
      </div>
    </div>

  </ng-container>
  <!-- SECOND HALF -->


  <ng-template #secondHalf>
    <div class="timeline-line">
      <div class="minutes">
        <p class="minutes-txt">
          30-40'
        </p>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ (timelineStatsHome.goals40OP > 0 ? (timelineStatsHome.goals40OP /
              (timelineStatsHome.offensePositional40) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ (timelineStatsHome.goals40FB > 0 ? (timelineStatsHome.goals40FB /
              (timelineStatsHome.fastBreak40) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ (timelineStatsHome.goals40CG > 0 ? (timelineStatsHome.goals40CG /
              (timelineStatsHome.counterGoal40) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
      </div>

      <div style="border-right: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsHome.possessions40 }}
        </p>
        <div class="goals-timeline flex-end">
          <p *ngIf="timelineStatsHome.goals40CG > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals40CG, timelineStatsHome.possessions40)"
             class="counter-goal-num">
            {{ timelineStatsHome.goals40CG }}
          </p>
          <p *ngIf="timelineStatsHome.goals40FB > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals40FB, timelineStatsHome.possessions40)"
             class="fast-break-goal-num">
            {{ timelineStatsHome.goals40FB }}
          </p>
          <p *ngIf="timelineStatsHome.goals40OP > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals40OP, timelineStatsHome.possessions40)"
             class="offense-position-goal-num">
            {{ timelineStatsHome.goals40OP }}
          </p>
        </div>
        <div class="failed-shots-timeline flex-end">
          <p *ngIf="timelineStatsHome.failedShots40 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.failedShots40, timelineStatsHome.possessions40)" class="num-text blue">
            {{ timelineStatsHome.failedShots40 }}
          </p>
        </div>
        <div class="saves-timeline flex-end">
          <p *ngIf="timelineStatsHome.saves40 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.saves40, timelineStatsHome.possessions40)" class="num-text orange">
            {{ timelineStatsHome.saves40 }}
          </p>
        </div>
        <div class="lost-balls-timeline flex-end">
          <p *ngIf="timelineStatsHome.lostBalls40 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.lostBalls40, timelineStatsHome.possessions40)" class="num-text red">
            {{ timelineStatsHome.lostBalls40 }}
          </p>
        </div>
        <div class="two-min-timeline flex-end">
          <p *ngIf="timelineStatsHome.twoMins40 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.twoMins40, timelineStatsHome.possessions40)" class="num-text black">
            {{ timelineStatsHome.twoMins40 }}
          </p>
        </div>

      </div>

      <div class="events">
        <p class="events-txt">
          {{ 'Possessions' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Goals (CG/FB/OP)' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Failed Shots' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Saves' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Lost Balls' | translate }}
        </p>
        <p class="events-txt">
          {{ '2min' | translate }}
        </p>
      </div>

      <div style="border-left: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsVisitor.possessions40 }}
        </p>
        <div class="goals-timeline">
          <p *ngIf="timelineStatsVisitor.goals40OP > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals40OP, timelineStatsVisitor.possessions40)"
             class="offense-position-goal-num">
            {{ timelineStatsVisitor.goals40OP }}
          </p>
          <p *ngIf="timelineStatsVisitor.goals40FB > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals40FB, timelineStatsVisitor.possessions40)"
             class="fast-break-goal-num">
            {{ timelineStatsVisitor.goals40FB }}
          </p>
          <p *ngIf="timelineStatsVisitor.goals40CG > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals40CG, timelineStatsVisitor.possessions40)"
             class="counter-goal-num">
            {{ timelineStatsVisitor.goals40CG }}
          </p>
        </div>
        <div class="failed-shots-timeline">
          <p *ngIf="timelineStatsVisitor.failedShots40 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.failedShots40, timelineStatsVisitor.possessions40)" class="num-text blue">
            {{ timelineStatsVisitor.failedShots40 }}
          </p>
        </div>
        <div class="saves-timeline">
          <p *ngIf="timelineStatsVisitor.saves40 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.saves40, timelineStatsVisitor.possessions40)" class="num-text orange">
            {{ timelineStatsVisitor.saves40 }}
          </p>
        </div>
        <div class="lost-balls-timeline">
          <p *ngIf="timelineStatsVisitor.lostBalls40 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.lostBalls40, timelineStatsVisitor.possessions40)" class="num-text red">
            {{ timelineStatsVisitor.lostBalls40 }}
          </p>
        </div>
        <div class="two-min-timeline">
          <p *ngIf="timelineStatsVisitor.twoMins40 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.twoMins40, timelineStatsVisitor.possessions40)" class="num-text black">
            {{ timelineStatsVisitor.twoMins40 }}
          </p>
        </div>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ (timelineStatsVisitor.goals40OP > 0 ? (timelineStatsVisitor.goals40OP /
              (timelineStatsVisitor.offensePositional40) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ (timelineStatsVisitor.goals40FB > 0 ? (timelineStatsVisitor.goals40FB /
              (timelineStatsVisitor.fastBreak40) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ (timelineStatsVisitor.goals40CG > 0 ? (timelineStatsVisitor.goals40CG /
              (timelineStatsVisitor.counterGoal40) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
      </div>


      <div class="minutes">
        <p class="minutes-txt">
          30-40'
        </p>
      </div>
    </div>


    <!-- -->


    <div class="timeline-line">
      <div class="minutes">
        <p class="minutes-txt">
          40-50'
        </p>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ (timelineStatsHome.goals50OP > 0 ? (timelineStatsHome.goals50OP /
              (timelineStatsHome.offensePositional50) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ (timelineStatsHome.goals50FB > 0 ? (timelineStatsHome.goals50FB /
              (timelineStatsHome.fastBreak50) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ (timelineStatsHome.goals50CG > 0 ? (timelineStatsHome.goals50CG /
              (timelineStatsHome.counterGoal50) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
      </div>

      <div style="border-right: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsHome.possessions50 }}
        </p>
        <div class="goals-timeline flex-end">
          <p *ngIf="timelineStatsHome.goals50CG > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals50CG, timelineStatsHome.possessions50)"
             class="counter-goal-num">
            {{ timelineStatsHome.goals50CG }}
          </p>
          <p *ngIf="timelineStatsHome.goals50FB > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals50FB, timelineStatsHome.possessions50)"
             class="fast-break-goal-num">
            {{ timelineStatsHome.goals50FB }}
          </p>
          <p *ngIf="timelineStatsHome.goals50OP > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals50OP, timelineStatsHome.possessions50)"
             class="offense-position-goal-num">
            {{ timelineStatsHome.goals50OP }}
          </p>
        </div>
        <div class="failed-shots-timeline flex-end">
          <p *ngIf="timelineStatsHome.failedShots50 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.failedShots50, timelineStatsHome.possessions50)" class="num-text blue">
            {{ timelineStatsHome.failedShots50 }}
          </p>
        </div>
        <div class="saves-timeline flex-end">
          <p *ngIf="timelineStatsHome.saves50 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.saves50, timelineStatsHome.possessions50)" class="num-text orange">
            {{ timelineStatsHome.saves50 }}
          </p>
        </div>
        <div class="lost-balls-timeline flex-end">
          <p *ngIf="timelineStatsHome.lostBalls50 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.lostBalls50, timelineStatsHome.possessions50)" class="num-text red">
            {{ timelineStatsHome.lostBalls50 }}
          </p>
        </div>
        <div class="two-min-timeline flex-end">
          <p *ngIf="timelineStatsHome.twoMins50 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.twoMins50, timelineStatsHome.possessions50)" class="num-text black">
            {{ timelineStatsHome.twoMins50 }}
          </p>
        </div>

      </div>

      <div class="events">
        <p class="events-txt">
          {{ 'Possessions' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Goals (CG/FB/OP)' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Failed Shots' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Saves' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Lost Balls' | translate }}
        </p>
        <p class="events-txt">
          {{ '2min' | translate }}
        </p>
      </div>

      <div style="border-left: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsVisitor.possessions50 }}
        </p>
        <div class="goals-timeline">
          <p *ngIf="timelineStatsVisitor.goals50OP > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals50OP, timelineStatsVisitor.possessions50)"
             class="offense-position-goal-num">
            {{ timelineStatsVisitor.goals50OP }}
          </p>
          <p *ngIf="timelineStatsVisitor.goals50FB > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals50FB, timelineStatsVisitor.possessions50)"
             class="fast-break-goal-num">
            {{ timelineStatsVisitor.goals50FB }}
          </p>
          <p *ngIf="timelineStatsVisitor.goals50CG > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals50CG, timelineStatsVisitor.possessions50)"
             class="counter-goal-num">
            {{ timelineStatsVisitor.goals50CG }}
          </p>
        </div>
        <div class="failed-shots-timeline">
          <p *ngIf="timelineStatsVisitor.failedShots50 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.failedShots50, timelineStatsVisitor.possessions50)" class="num-text blue">
            {{ timelineStatsVisitor.failedShots50 }}
          </p>
        </div>
        <div class="saves-timeline">
          <p *ngIf="timelineStatsVisitor.saves50 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.saves50, timelineStatsVisitor.possessions50)" class="num-text orange">
            {{ timelineStatsVisitor.saves50 }}
          </p>
        </div>
        <div class="lost-balls-timeline">
          <p *ngIf="timelineStatsVisitor.lostBalls50 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.lostBalls50, timelineStatsVisitor.possessions50)" class="num-text red">
            {{ timelineStatsVisitor.lostBalls50 }}
          </p>
        </div>
        <div class="two-min-timeline">
          <p *ngIf="timelineStatsVisitor.twoMins50 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.twoMins50, timelineStatsVisitor.possessions50)" class="num-text black">
            {{ timelineStatsVisitor.twoMins50 }}
          </p>
        </div>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ (timelineStatsVisitor.goals50OP > 0 ? (timelineStatsVisitor.goals50OP /
              (timelineStatsVisitor.offensePositional50) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ (timelineStatsVisitor.goals50FB > 0 ? (timelineStatsVisitor.goals50FB /
              (timelineStatsVisitor.fastBreak50) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ (timelineStatsVisitor.goals50CG > 0 ? (timelineStatsVisitor.goals50CG /
              (timelineStatsVisitor.counterGoal50) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
      </div>


      <div class="minutes">
        <p class="minutes-txt">
          40-50'
        </p>
      </div>
    </div>


    <!-- -->

    <div class="timeline-last-line">
      <div class="minutes">
        <p class="minutes-txt">
          50-60'
        </p>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ (timelineStatsHome.goals60OP > 0 ? (timelineStatsHome.goals60OP /
              (timelineStatsHome.offensePositional60) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ (timelineStatsHome.goals60FB > 0 ? (timelineStatsHome.goals60FB /
              (timelineStatsHome.fastBreak60) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ (timelineStatsHome.goals60CG > 0 ? (timelineStatsHome.goals60CG /
              (timelineStatsHome.counterGoal60) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
      </div>

      <div style="border-right: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsHome.possessions60 }}
        </p>
        <div class="goals-timeline flex-end">
          <p *ngIf="timelineStatsHome.goals60CG > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals60CG, timelineStatsHome.possessions60)"
             class="counter-goal-num">
            {{ timelineStatsHome.goals60CG }}
          </p>
          <p *ngIf="timelineStatsHome.goals60FB > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals60FB, timelineStatsHome.possessions60)"
             class="fast-break-goal-num">
            {{ timelineStatsHome.goals60FB }}
          </p>
          <p *ngIf="timelineStatsHome.goals60OP > 0" [style.width]="calculateBarWidth(timelineStatsHome.goals60OP, timelineStatsHome.possessions60)"
             class="offense-position-goal-num">
            {{ timelineStatsHome.goals60OP }}
          </p>
        </div>
        <div class="failed-shots-timeline flex-end">
          <p *ngIf="timelineStatsHome.failedShots60 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.failedShots60, timelineStatsHome.possessions60)" class="num-text blue">
            {{ timelineStatsHome.failedShots60 }}
          </p>
        </div>
        <div class="saves-timeline flex-end">
          <p *ngIf="timelineStatsHome.saves60 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.saves60, timelineStatsHome.possessions60)" class="num-text orange">
            {{ timelineStatsHome.saves60 }}
          </p>
        </div>
        <div class="lost-balls-timeline flex-end">
          <p *ngIf="timelineStatsHome.lostBalls60 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.lostBalls60, timelineStatsHome.possessions60)" class="num-text red">
            {{ timelineStatsHome.lostBalls60 }}
          </p>
        </div>
        <div class="two-min-timeline flex-end">
          <p *ngIf="timelineStatsHome.twoMins60 > 0"
             [style.width]="calculateBarWidth(timelineStatsHome.twoMins60, timelineStatsHome.possessions60)" class="num-text black">
            {{ timelineStatsHome.twoMins60 }}
          </p>
        </div>

      </div>

      <div class="events">
        <p class="events-txt">
          {{ 'Possessions' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Goals (CG/FB/OP)' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Failed Shots' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Saves' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Lost Balls' | translate }}
        </p>
        <p class="events-txt">
          {{ '2min' | translate }}
        </p>
      </div>

      <div style="border-left: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsVisitor.possessions60 }}
        </p>
        <div class="goals-timeline">
          <p *ngIf="timelineStatsVisitor.goals60OP > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals60OP, timelineStatsVisitor.possessions60)"
             class="offense-position-goal-num">
            {{ timelineStatsVisitor.goals60OP }}
          </p>
          <p *ngIf="timelineStatsVisitor.goals60FB > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals60FB, timelineStatsVisitor.possessions60)"
             class="fast-break-goal-num">
            {{ timelineStatsVisitor.goals60FB }}
          </p>
          <p *ngIf="timelineStatsVisitor.goals60CG > 0" [style.width]="calculateBarWidth(timelineStatsVisitor.goals60CG, timelineStatsVisitor.possessions60)"
             class="counter-goal-num">
            {{ timelineStatsVisitor.goals60CG }}
          </p>
        </div>
        <div class="failed-shots-timeline">
          <p *ngIf="timelineStatsVisitor.failedShots60 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.failedShots60, timelineStatsVisitor.possessions60)" class="num-text blue">
            {{ timelineStatsVisitor.failedShots60 }}
          </p>
        </div>
        <div class="saves-timeline">
          <p *ngIf="timelineStatsVisitor.saves60 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.saves60, timelineStatsVisitor.possessions60)" class="num-text orange">
            {{ timelineStatsVisitor.saves60 }}
          </p>
        </div>
        <div class="lost-balls-timeline">
          <p *ngIf="timelineStatsVisitor.lostBalls60 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.lostBalls60, timelineStatsVisitor.possessions60)" class="num-text red">
            {{ timelineStatsVisitor.lostBalls60 }}
          </p>
        </div>
        <div class="two-min-timeline">
          <p *ngIf="timelineStatsVisitor.twoMins60 > 0"
             [style.width]="calculateBarWidth(timelineStatsVisitor.twoMins60, timelineStatsVisitor.possessions60)" class="num-text black">
            {{ timelineStatsVisitor.twoMins60 }}
          </p>
        </div>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ (timelineStatsVisitor.goals60OP > 0 ? (timelineStatsVisitor.goals60OP /
              (timelineStatsVisitor.offensePositional60) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ (timelineStatsVisitor.goals60FB > 0 ? (timelineStatsVisitor.goals60FB /
              (timelineStatsVisitor.fastBreak60) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ (timelineStatsVisitor.goals60CG > 0 ? (timelineStatsVisitor.goals60CG /
              (timelineStatsVisitor.counterGoal60) * 100).toFixed(0) : '0') + '%' }}
          </p>
        </div>
      </div>


      <div class="minutes">
        <p class="minutes-txt">
          50-60'
        </p>
      </div>
    </div>

    <!-- -->

    <div class="timeline-without-line">
      <div class="minutes">
        <p class="minutes-txt">
          Total 2nd Half
        </p>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ ((timelineStatsHome.goals40OP + timelineStatsHome.goals50OP + timelineStatsHome.goals60OP) /
              (timelineStatsHome.offensePositional40 + timelineStatsHome.offensePositional50 + timelineStatsHome.offensePositional60 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ ((timelineStatsHome.goals40FB + timelineStatsHome.goals50FB + timelineStatsHome.goals60FB) /
              (timelineStatsHome.fastBreak40 + timelineStatsHome.fastBreak50 + timelineStatsHome.fastBreak60 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ ((timelineStatsHome.goals40CG + timelineStatsHome.goals50CG + timelineStatsHome.goals60CG) /
              (timelineStatsHome.counterGoal40 + timelineStatsHome.counterGoal50 + timelineStatsHome.counterGoal60 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
      </div>

      <div style="border-right: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ (timelineStatsHome.possessions40 + timelineStatsHome.possessions50 + timelineStatsHome.possessions60) }}
        </p>
        <div class="goals-timeline flex-end">
          <p *ngIf="(timelineStatsHome.goals40CG + timelineStatsHome.goals50CG + timelineStatsHome.goals60CG) > 0"
              [style.width]="calculateBarWidth((timelineStatsHome.goals40CG + timelineStatsHome.goals50CG + timelineStatsHome.goals60CG),
        (timelineStatsHome.possessions40 + timelineStatsHome.possessions50 + timelineStatsHome.possessions60))"
             class="counter-goal-num">
            {{ timelineStatsHome.goals40CG + timelineStatsHome.goals50CG + timelineStatsHome.goals60CG }}
          </p>
          <p *ngIf="(timelineStatsHome.goals40FB + timelineStatsHome.goals50FB + timelineStatsHome.goals60FB) > 0"
              [style.width]="calculateBarWidth((timelineStatsHome.goals40FB + timelineStatsHome.goals50FB + timelineStatsHome.goals60FB),
        (timelineStatsHome.possessions40 + timelineStatsHome.possessions50 + timelineStatsHome.possessions60))"
             class="fast-break-goal-num">
            {{ timelineStatsHome.goals40FB + timelineStatsHome.goals50FB + timelineStatsHome.goals60FB }}
          </p>
          <p *ngIf="(timelineStatsHome.goals40OP + timelineStatsHome.goals50OP + timelineStatsHome.goals60OP) > 0"
              [style.width]="calculateBarWidth((timelineStatsHome.goals40OP + timelineStatsHome.goals50OP + timelineStatsHome.goals60OP),
        (timelineStatsHome.possessions40 + timelineStatsHome.possessions50 + timelineStatsHome.possessions60))"
             class="offense-position-goal-num">
            {{ timelineStatsHome.goals40OP + timelineStatsHome.goals50OP + timelineStatsHome.goals60OP }}
          </p>
        </div>
        <div class="failed-shots-timeline flex-end">
          <p *ngIf="(timelineStatsHome.failedShots40 + timelineStatsHome.failedShots50 + timelineStatsHome.failedShots60) > 0"
              [style.width]="calculateBarWidth((timelineStatsHome.failedShots40 + timelineStatsHome.failedShots50 + timelineStatsHome.failedShots60),
        (timelineStatsHome.possessions40 + timelineStatsHome.possessions50 + timelineStatsHome.possessions60))" class="num-text blue">
            {{ timelineStatsHome.failedShots40 + timelineStatsHome.failedShots50 + timelineStatsHome.failedShots60 }}
          </p>
        </div>
        <div class="saves-timeline flex-end">
          <p *ngIf="(timelineStatsHome.saves40 + timelineStatsHome.saves50 + timelineStatsHome.saves60) > 0"
             [style.width]="calculateBarWidth((timelineStatsHome.saves40 + timelineStatsHome.saves50 + timelineStatsHome.saves60),
        (timelineStatsHome.possessions40 + timelineStatsHome.possessions50 + timelineStatsHome.possessions60))" class="num-text orange">
            {{ timelineStatsHome.saves40 + timelineStatsHome.saves50 + timelineStatsHome.saves60 }}
          </p>
        </div>
        <div class="lost-balls-timeline flex-end">
          <p *ngIf="(timelineStatsHome.lostBalls40 + timelineStatsHome.lostBalls50 + timelineStatsHome.lostBalls60) > 0"
             [style.width]="calculateBarWidth((timelineStatsHome.lostBalls40 + timelineStatsHome.lostBalls50 + timelineStatsHome.lostBalls60),
        (timelineStatsHome.possessions40 + timelineStatsHome.possessions50 + timelineStatsHome.possessions60))" class="num-text red">
            {{ timelineStatsHome.lostBalls40 + timelineStatsHome.lostBalls50 + timelineStatsHome.lostBalls60 }}
          </p>
        </div>
        <div class="two-min-timeline flex-end">
          <p *ngIf="(timelineStatsHome.twoMins40 + timelineStatsHome.twoMins50 + timelineStatsHome.twoMins60) > 0"
             [style.width]="calculateBarWidth((timelineStatsHome.twoMins40 + timelineStatsHome.twoMins50 + timelineStatsHome.twoMins60),
        (timelineStatsHome.possessions40 + timelineStatsHome.possessions50 + timelineStatsHome.possessions60))" class="num-text black">
            {{ timelineStatsHome.twoMins40 + timelineStatsHome.twoMins50 + timelineStatsHome.twoMins60 }}
          </p>
        </div>

      </div>

      <div class="events">
        <p class="events-txt">
          {{ 'Possessions' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Goals (CG/FB/OP)' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Failed Shots' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Saves' | translate }}
        </p>
        <p class="events-txt">
          {{ 'Lost Balls' | translate }}
        </p>
        <p class="events-txt">
          {{ '2min' | translate }}
        </p>
      </div>

      <div style="border-left: 1px solid #0D385F" class="timeline">
        <p class="possessions-num">
          {{ timelineStatsVisitor.possessions40 + timelineStatsVisitor.possessions50 + timelineStatsVisitor.possessions60 }}
        </p>
        <div class="goals-timeline">
          <p *ngIf="(timelineStatsVisitor.goals40OP + timelineStatsVisitor.goals50OP + timelineStatsVisitor.goals60OP) > 0"
              [style.width]="calculateBarWidth((timelineStatsVisitor.goals40OP + timelineStatsVisitor.goals50OP + timelineStatsVisitor.goals60OP),
         (timelineStatsVisitor.possessions40 + timelineStatsVisitor.possessions50 + timelineStatsVisitor.possessions60))"
             class="offense-position-goal-num">
            {{ timelineStatsVisitor.goals40OP + timelineStatsVisitor.goals50OP + timelineStatsVisitor.goals60OP }}
          </p>
          <p *ngIf="(timelineStatsVisitor.goals40FB + timelineStatsVisitor.goals50FB + timelineStatsVisitor.goals60FB) > 0"
              [style.width]="calculateBarWidth((timelineStatsVisitor.goals40FB + timelineStatsVisitor.goals50FB + timelineStatsVisitor.goals60FB),
        (timelineStatsVisitor.possessions40 + timelineStatsVisitor.possessions50 + timelineStatsVisitor.possessions60))"
             class="fast-break-goal-num">
            {{ timelineStatsVisitor.goals40FB + timelineStatsVisitor.goals50FB + timelineStatsVisitor.goals60FB }}
          </p>
          <p *ngIf="(timelineStatsVisitor.goals40CG + timelineStatsVisitor.goals50CG + timelineStatsVisitor.goals60CG) > 0"
              [style.width]="calculateBarWidth((timelineStatsVisitor.goals40CG + timelineStatsVisitor.goals50CG + timelineStatsVisitor.goals60CG),
         (timelineStatsVisitor.possessions40 + timelineStatsVisitor.possessions50 + timelineStatsVisitor.possessions60))"
             class="counter-goal-num">
            {{ timelineStatsVisitor.goals40CG + timelineStatsVisitor.goals50CG + timelineStatsVisitor.goals60CG }}
          </p>
        </div>
        <div class="failed-shots-timeline">
          <p *ngIf="(timelineStatsVisitor.failedShots40 + timelineStatsVisitor.failedShots50 + timelineStatsVisitor.failedShots60) > 0"
             [style.width]="calculateBarWidth((timelineStatsVisitor.failedShots40 + timelineStatsVisitor.failedShots50 + timelineStatsVisitor.failedShots60),
        (timelineStatsVisitor.possessions40 + timelineStatsVisitor.possessions50 + timelineStatsVisitor.possessions60))" class="num-text blue">
            {{ timelineStatsVisitor.failedShots40 + timelineStatsVisitor.failedShots50 + timelineStatsVisitor.failedShots60 }}
          </p>
        </div>
        <div class="saves-timeline">
          <p *ngIf="(timelineStatsVisitor.saves40 + timelineStatsVisitor.saves50 + timelineStatsVisitor.saves60) > 0"
             [style.width]="calculateBarWidth((timelineStatsVisitor.saves40 + timelineStatsVisitor.saves50 + timelineStatsVisitor.saves60),
        (timelineStatsVisitor.possessions40 + timelineStatsVisitor.possessions50 + timelineStatsVisitor.possessions60))" class="num-text orange">
            {{ timelineStatsVisitor.saves40 + timelineStatsVisitor.saves50 + timelineStatsVisitor.saves60 }}
          </p>
        </div>
        <div class="lost-balls-timeline">
          <p *ngIf="(timelineStatsVisitor.lostBalls40 + timelineStatsVisitor.lostBalls50 + timelineStatsVisitor.lostBalls60) > 0"
             [style.width]="calculateBarWidth((timelineStatsVisitor.lostBalls40 + timelineStatsVisitor.lostBalls50 + timelineStatsVisitor.lostBalls60),
        (timelineStatsVisitor.possessions60 + timelineStatsVisitor.possessions60 + timelineStatsVisitor.possessions60))" class="num-text red">
            {{ timelineStatsVisitor.lostBalls40 + timelineStatsVisitor.lostBalls50 + timelineStatsVisitor.lostBalls60 }}
          </p>
        </div>
        <div class="two-min-timeline">
          <p *ngIf="(timelineStatsVisitor.twoMins40 + timelineStatsVisitor.twoMins50 + timelineStatsVisitor.twoMins60) > 0"
             [style.width]="calculateBarWidth((timelineStatsVisitor.twoMins40 + timelineStatsVisitor.twoMins50 + timelineStatsVisitor.twoMins60),
        (timelineStatsVisitor.possessions40 + timelineStatsVisitor.possessions50 + timelineStatsVisitor.possessions60))" class="num-text black">
            {{ timelineStatsVisitor.twoMins40 + timelineStatsVisitor.twoMins50 + timelineStatsVisitor.twoMins60 }}
          </p>
        </div>
      </div>

      <div class="phases">
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'OP' | translate }} {{ ((timelineStatsVisitor.goals40OP + timelineStatsVisitor.goals50OP + timelineStatsVisitor.goals60OP) /
              (timelineStatsVisitor.offensePositional40 + timelineStatsVisitor.offensePositional50 + timelineStatsVisitor.offensePositional60 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-red">
            {{ 'FB' | translate }} {{ ((timelineStatsVisitor.goals40FB + timelineStatsVisitor.goals50FB + timelineStatsVisitor.goals60FB) /
              (timelineStatsVisitor.fastBreak40 + timelineStatsVisitor.fastBreak50 + timelineStatsVisitor.fastBreak60 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
        <div class="phase-box">
          <p class="phase-txt-green">
            {{ 'CG' | translate }} {{ ((timelineStatsVisitor.goals40CG + timelineStatsVisitor.goals50CG + timelineStatsVisitor.goals60CG) /
              (timelineStatsVisitor.counterGoal40 + timelineStatsVisitor.counterGoal50 + timelineStatsVisitor.counterGoal60 ) * 100).toFixed(0) + '%' }}
          </p>
        </div>
      </div>


      <div class="minutes">
        <p class="minutes-txt">
          Total 2nd Half
        </p>
      </div>
    </div>
  </ng-template>

</div>
