import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { GameFolderDto } from 'src/app/api/hai-api';

@Component({
  templateUrl: './playList-options-popover.component.html',
  styleUrls: ['./playList-options-popover.component.scss'],
})
export class PlayListOptionsPopoverComponent implements OnInit {
  @Input() hasMoveOption: boolean = true;
  @Input() hasShareOption: boolean = true;
  constructor(private popoverCntl: PopoverController) {}

  ngOnInit(): void {}

  dismiss() {
    this.popoverCntl.dismiss();
  }
  public optionSelected(option: string): void {
    this.popoverCntl.dismiss(option);
  }
}
