export * from './admin.service';
import { AdminService } from './admin.service';
export * from './externalAccountEquipos.service';
import { ExternalAccountEquiposService } from './externalAccountEquipos.service';
export * from './externalAccounts.service';
import { ExternalAccountsService } from './externalAccounts.service';
export * from './externalGames.service';
import { ExternalGamesService } from './externalGames.service';
export * from './externalScouting.service';
import { ExternalScoutingService } from './externalScouting.service';
export * from './externalStats.service';
import { ExternalStatsService } from './externalStats.service';
export * from './externalXps.service';
import { ExternalXpsService } from './externalXps.service';
export * from './registration.service';
import { RegistrationService } from './registration.service';
export const APIS = [AdminService, ExternalAccountEquiposService, ExternalAccountsService, ExternalGamesService, ExternalScoutingService, ExternalStatsService, ExternalXpsService, RegistrationService];
