import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { CoreService } from 'src/app/shared-services/core.service';

@Injectable({
  providedIn: 'root'
})
export class GameLoadingService {

  private overlay: HTMLIonLoadingElement;
  private _core: CoreService;
  constructor(private readonly loadingController: LoadingController) { }

  public initCore(core: CoreService) {
    this._core = core;
  }

  async present(): Promise<void> {
    this.overlay = await this.loadingController.create({message: this._core.trans.instant('Creating Game...'),  backdropDismiss: false});
    await this.overlay.present();
  }

  async presentVideoSaveSpinner(): Promise<void> {
    this.overlay = await this.loadingController.create({message: this._core.trans.instant('Your Game is processed...'),  backdropDismiss: false});
    await this.overlay.present();
  }

  async dismiss(): Promise<void> {
    await this.overlay?.dismiss();
  }
}
