import { UiBaseCommand, UiCommandExecutionContext } from './ui-base-command';
import {
    GoalkeeperSelectLiteEvent, GoalkeeperSwapLiteEvent,
    PlayerDeSelectEvent, PlayerDeSelectLiteEvent,
    PlayerExchangeEvent,
    PlayerSelectEvent, PlayerSelectLiteEvent,
    PlayerSwapEvent, PlayerSwapLiteEvent,
    SubstituteGoalKeeperPopupSavedEvent, SwapPlayerForFreeSlotLiteEvent
} from '../events/ui-events';
import { eventChainFinished, isReplacementPossible } from './command-helper';
import {
    PlayerExchangeUndoEvent,
    Pos1SuspensionSubstituteUndoEvent
} from 'src/app/shared-services/ui-event-dispatcher/buffer/event-buffer.model';
import { EventTime } from '@handballai/stats-calculation';
import * as Sentry from '@sentry/browser';

export class PlayerSelectCommand implements UiBaseCommand<PlayerSelectEvent> {
    public async execute(actualEvent: PlayerSelectEvent, eventContext: UiCommandExecutionContext): Promise<void> {
        eventContext.logger.debug('PlayerSelectCommand.execute: ', actualEvent);
        await eventContext.eventBufferService.openTransaction();
        eventContext.actionService.enableAllButtons();
        if (actualEvent.payload.teamMarker === 'HOME') {
            eventContext.gameService.toggleBenchHome(false);
            eventContext.gameService.toggleFieldHomeWithPlayer(actualEvent.payload.playerModel);
            eventContext.gameService.toggleSuspensionsHome(true);
            eventContext.gameService.toggleSuspensionsVisitor(true);
            eventContext.gameService.toggleFieldVisitor(true);
            eventContext.gameService.toggleBenchVisitor(true);
        } else {
            eventContext.gameService.toggleBenchHome(true);
            eventContext.gameService.toggleFieldHome(true);
            eventContext.gameService.toggleSuspensionsHome(true);
            eventContext.gameService.toggleSuspensionsVisitor(true);
            eventContext.gameService.toggleFieldVisitorWithPlayer(actualEvent.payload.playerModel);
            eventContext.gameService.toggleBenchVisitor(false);
        }
    }
}

export class PlayerDeSelectCommand implements UiBaseCommand<PlayerDeSelectEvent> {
    public execute(actualEvent: PlayerDeSelectEvent, eventContext: UiCommandExecutionContext): void {
        eventContext.logger.debug('PlayerDeSelectCommand.execute: ', actualEvent);
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class PlayerExchangeCommand implements UiBaseCommand<PlayerExchangeEvent> {
    public execute(actualEvent: PlayerExchangeEvent, eventContext: UiCommandExecutionContext): void {
        const teamMarker = actualEvent.payload.teamMarker;
        const selectedFieldPlayerEvent = eventContext.eventDispatcher.playerSelectEvent;
        eventContext.logger.debug('PlayerExchangeCommand.execute actual, selectedPlayer ', actualEvent, selectedFieldPlayerEvent);
        const playerExchangeUndoEvent = new PlayerExchangeUndoEvent({
            playerBeforeInGame: selectedFieldPlayerEvent.payload.playerModel,
            playerBeforeOnBench: actualEvent.payload.playerModel,
            teamMarker: teamMarker
        });
        // TODO this code duplication should be removed later
        if (teamMarker === 'HOME') {
            if (
                !isReplacementPossible(
                    eventContext,
                    actualEvent.payload.playerModel,
                    'HOME',
                    selectedFieldPlayerEvent.payload.playerModel
                )
            ) {
                return;
            }
            eventContext.gameService.exchangePlayerHome(
                actualEvent.payload.playerModel,
                selectedFieldPlayerEvent.payload.playerModel
            );
        } else {
            if (
                !isReplacementPossible(
                    eventContext,
                    actualEvent.payload.playerModel,
                    'VISITOR',
                    selectedFieldPlayerEvent.payload.playerModel
                )
            ) {
                return;
            }
            eventContext.gameService.exchangePlayerVisitor(
                actualEvent.payload.playerModel,
                selectedFieldPlayerEvent.payload.playerModel
            );
        }

        eventContext.eventBufferService.addUndoEvent(playerExchangeUndoEvent);
        // New playtime event chain
        eventContext.eventBufferService.addPlayTimeEvent(
            selectedFieldPlayerEvent.payload.playerModel.id,
            'PLAY_TIME_END',
            {
                timestamp: actualEvent.timestamp.timestamp,
                halftime: actualEvent.timestamp.halftime,
                secondsSinceStartOfGame: actualEvent.timestamp.secondsSinceStartOfGame,
                secondsSinceHalftime: actualEvent.timestamp.secondsSinceHalftime,
                minutesSinceHalftime: actualEvent.timestamp.minutesSinceHalftime
            } as EventTime
        );

        eventContext.eventBufferService.addPlayTimeEvent(
            actualEvent.payload.playerModel.id,
            'PLAY_TIME_START',
            {
                timestamp: actualEvent.timestamp.timestamp,
                halftime: actualEvent.timestamp.halftime,
                secondsSinceStartOfGame: actualEvent.timestamp.secondsSinceStartOfGame,
                secondsSinceHalftime: actualEvent.timestamp.secondsSinceHalftime,
                minutesSinceHalftime: actualEvent.timestamp.minutesSinceHalftime
            } as EventTime,
        );

        eventContext.eventBufferService.closeTransaction();
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class PlayerSwapCommand implements UiBaseCommand<PlayerSwapEvent> {

    execute(actualEvent: PlayerSwapEvent, eventContext: UiCommandExecutionContext): void {
        const teamMarker = actualEvent.payload.teamMarker;
        const selectedFieldPlayerEvent = eventContext.eventDispatcher.playerSelectEvent;
        eventContext.logger.debug('PlayerSwapCommand.execute actual, selectedPlayer ', actualEvent, selectedFieldPlayerEvent);

        if (selectedFieldPlayerEvent.payload.playerModel.position === 'gk' || actualEvent.payload.playerModel.position === 'gk') {
            eventContext.toastController.create({
                icon: 'close-outline',
                color: 'danger',
                message: `You cannot replace a Field-Player by Goalkeeper!`,
                duration: 5000
            }).then(value => value.present());

        } else {
            if (teamMarker === 'HOME') {
                eventContext.gameService.swapFieldPlayerHome(
                    selectedFieldPlayerEvent.payload.playerModel,
                    actualEvent.payload.playerModel
                );
            } else {
                eventContext.gameService.swapFieldPlayerVisitor(
                    selectedFieldPlayerEvent.payload.playerModel,
                    actualEvent.payload.playerModel
                );
            }
        }

        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class SubstituteGoalKeeperPopupSavedCommand implements UiBaseCommand<SubstituteGoalKeeperPopupSavedEvent> {
    execute(actualEvent: SubstituteGoalKeeperPopupSavedEvent, eventContext: UiCommandExecutionContext): void {
        const teamMarker = actualEvent.payload.teamMarker;
        eventContext.logger.debug('SubstituteGoalKeeperPopupSavedCommand.execute', actualEvent);

        if (teamMarker === 'HOME') {
            eventContext.gameService.putPlayerOnBenchHomeWithoutPenalty(actualEvent.payload.suspendedPlayer);
        } else {
            eventContext.gameService.putPlayerOnBenchVisitorWithoutPenalty(actualEvent.payload.suspendedPlayer);
        }

        eventContext.eventBufferService.addPlayTimeEvent(
            actualEvent.payload.suspendedPlayer.id,
            'PLAY_TIME_END',
            {
                timestamp: actualEvent.timestamp.timestamp,
                halftime: actualEvent.timestamp.halftime,
                secondsSinceStartOfGame: actualEvent.timestamp.secondsSinceStartOfGame,
                secondsSinceHalftime: actualEvent.timestamp.secondsSinceHalftime,
                minutesSinceHalftime: actualEvent.timestamp.minutesSinceHalftime
            } as EventTime
        );

        eventContext.eventBufferService.addUndoEvent(new Pos1SuspensionSubstituteUndoEvent({
            teamMarker: teamMarker,
            playerBeforeInGame: actualEvent.payload.suspendedPlayer
        }));
        eventContext.eventBufferService.closeTransaction();
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );

    }

}

export class PlayerSelectLiteCommand implements UiBaseCommand<PlayerSelectLiteEvent> {
    public async execute(actualEvent: PlayerSelectLiteEvent, eventContext: UiCommandExecutionContext): Promise<void> {
        eventContext.logger.debug('PlayerSelectLiteCommand.execute: ', actualEvent);
        await eventContext.eventBufferService.openTransaction();
        eventContext.actionService.enableAllButtons();
        if (actualEvent.payload.teamMarker === 'HOME') {
            eventContext.gameService.toggleFieldVisitor(true);
            eventContext.gameService.toggleFieldHomeWithPlayer(actualEvent.payload.playerModel);
        } else {
            eventContext.gameService.toggleFieldHome(true);
            eventContext.gameService.toggleFieldVisitorWithPlayer(actualEvent.payload.playerModel);

        }
    }
}

export class PlayerDeSelectLiteCommand implements UiBaseCommand<PlayerDeSelectLiteEvent> {
    public execute(actualEvent: PlayerDeSelectLiteEvent, eventContext: UiCommandExecutionContext): void {
        eventContext.logger.debug('PlayerDeSelectLiteCommand.execute: ', actualEvent);
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class GoalkeeperSelectLiteCommand implements UiBaseCommand<GoalkeeperSelectLiteEvent> {
    public async execute(actualEvent: GoalkeeperSelectLiteEvent, eventContext: UiCommandExecutionContext): Promise<void> {
        eventContext.logger.debug('GoalkeeperSelectLiteCommand.execute: ', actualEvent);
        await eventContext.eventBufferService.openTransaction();
        eventContext.actionService.enableAllButtons();
        if (actualEvent.payload.teamMarker === 'HOME') {
            eventContext.gameService.toggleFieldVisitor(true);
            if (actualEvent.payload.playerModel.slotId === 1 && actualEvent.payload.playerModel.position === 'gk') {
                eventContext.gameService.toggleSecondGoalkeeperHome();
            }
        } else {
            eventContext.gameService.toggleFieldHome(true);
            if (actualEvent.payload.playerModel.slotId === 1 && actualEvent.payload.playerModel.position === 'gk') {
                eventContext.gameService.toggleSecondGoalkeeperVisitor();
            }
        }
    }
}

export class GoalkeeperDeSelectLiteCommand implements UiBaseCommand<GoalkeeperDeSelectLiteCommand> {
    public execute(actualEvent: GoalkeeperDeSelectLiteCommand, eventContext: UiCommandExecutionContext): void {
        eventContext.logger.debug('GoalkeeperSelectLiteCommand.execute: ', actualEvent);
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class PlayerSwapLiteCommand implements UiBaseCommand<PlayerSwapLiteEvent> {

    execute(actualEvent: PlayerSwapLiteEvent, eventContext: UiCommandExecutionContext): void {
        const teamMarker = actualEvent.payload.teamMarker;
        const selectedFieldPlayerEvent = eventContext.eventDispatcher.playerSelectEvent;
        const secondPlayer = actualEvent.payload.playerModel;
        eventContext.logger.debug('PlayerSwapLiteCommand.execute actual, selectedPlayer ', actualEvent, selectedFieldPlayerEvent);

        if (teamMarker === 'HOME') {
            eventContext.gameService.swapFieldPlayerHome(
                selectedFieldPlayerEvent.payload.playerModel,
                actualEvent.payload.playerModel
            );
        } else {
            eventContext.gameService.swapFieldPlayerVisitor(
                selectedFieldPlayerEvent.payload.playerModel,
                actualEvent.payload.playerModel
            );
        }


        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class GoalkeeperSwapLiteCommand implements UiBaseCommand<GoalkeeperSwapLiteEvent> {

    execute(actualEvent: GoalkeeperSwapLiteEvent, eventContext: UiCommandExecutionContext): void {
        const teamMarker = actualEvent.payload.teamMarker;
        const selectedFieldPlayerEvent = eventContext.eventDispatcher.playerSelectEvent;
        eventContext.logger.debug('GoalkeeperSwapLiteCommand.execute actual, selectedPlayer ', actualEvent, selectedFieldPlayerEvent);

        if (!selectedFieldPlayerEvent || !actualEvent) {
            Sentry.captureMessage('selectedFieldPlayerEvent or actualEvent are nulls: ' + JSON.stringify(eventContext));
        }
        if (teamMarker === 'HOME') {
            eventContext.gameService.swapGoalkeeperHome(
                selectedFieldPlayerEvent.payload.playerModel,
                actualEvent.payload.playerModel
            );
        } else {
            eventContext.gameService.swapGoalkeeperVisitor(
                selectedFieldPlayerEvent.payload.playerModel,
                actualEvent.payload.playerModel
            );
        }

        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class SwapPlayerForFreeSlotLiteCommand implements UiBaseCommand<SwapPlayerForFreeSlotLiteEvent> {
    execute(actualEvent: SwapPlayerForFreeSlotLiteEvent, eventContext: UiCommandExecutionContext): void {
        const teamMarker = actualEvent.payload.teamMarker;
        const selectedFieldPlayerEvent = eventContext.eventDispatcher.playerSelectEvent;
        eventContext.logger
            .debug('SwapPlayerForFreeSlotLiteCommand.execute actual, selectedPlayer ', actualEvent, selectedFieldPlayerEvent);

        if (teamMarker === 'HOME') {
            eventContext.gameService
                .swapPlayerForFreeSlotIdHome(selectedFieldPlayerEvent.payload.playerModel, actualEvent.payload.slotId);
        } else {
            eventContext.gameService
                .swapPlayerForFreeSlotIdVisitor(selectedFieldPlayerEvent.payload.playerModel, actualEvent.payload.slotId);
        }

        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}
