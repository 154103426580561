import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Storage } from '@ionic/storage';
import { TokenDto } from '../../api/hai-api';
import { AccessToken, RefresherToken } from '../model/token.model';
import { from, Observable } from 'rxjs';
import { CommonUserModel } from '../model/common-user.model';
import { CoreService } from '../core.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
      private readonly _storage: Storage,
      private readonly logger: NGXLogger
  ) {
    this.initStorage();
  }

  get accessToken() {
    return (async () => await this._storage.get(StorageService.ACCESS_TOKEN_KEY))();
  }

  get refresherToken() {
    return (async () => await this._storage.get(StorageService.REFRESHER_TOKEN_KEY))();
  }

  get storage(): Storage {
    return this._storage;
  }

  get superAdminToken(): string {
    return this._superAdminToken;
  }

  get accountEquipoId(): number {
    return this._accountEquipoId;
  }

  private static ACCESS_TOKEN_KEY = 'HAI_ACCESS_TOKEN';
  private static REFRESHER_TOKEN_KEY = 'HAI_REFRESHER_TOKEN';
  private static SUPER_ADMIN_ACCESS_TOKEN_KEY = 'SUPER_ADMIN_HAI_ACCESS_TOKEN';
  private static SUPER_ADMIN_REFRESHER_TOKEN_KEY = 'SUPER_ADMIN_HAI_REFRESHER_TOKEN';
  private static USER_STORAGE_KEY = 'HAI_COMMON_USER';
  private static ACCOUNT_EQUIPO_STORAGE_KEY = 'HAI_COMMON_ACCOUNT_EQUIPO';
  private _superAdminToken: string;
  private _accountEquipoId: number;
  private _core: CoreService;
  initCore(core: CoreService) {
      this._core = core;
  }

  private initStorage() {
    this._storage.create()
        .then(() => this.logger.debug('Storage created successfully...'))
        .catch(reason => this.logger.error('Error opening the storage', reason));
  }

  public async saveToken(tokenDto: TokenDto): Promise<void> {
    this.logger.info('Saving new token', tokenDto);
    await this._storage.set(StorageService.ACCESS_TOKEN_KEY, tokenDto.accessToken);
    await this._storage.set(StorageService.REFRESHER_TOKEN_KEY, tokenDto.refresherToken);
  }

  public async saveSuperAdminToken(tokenDto: TokenDto): Promise<void> {
    this.logger.info('Saving Super Admin token', tokenDto);
    this._superAdminToken = tokenDto.accessToken;
    await this._storage.set(StorageService.SUPER_ADMIN_ACCESS_TOKEN_KEY, tokenDto.accessToken);
    await this._storage.set(StorageService.SUPER_ADMIN_REFRESHER_TOKEN_KEY, tokenDto.refresherToken);
  }

  public getAccessTokenAsPromise(): Promise<string> {
    return this._storage.get(StorageService.ACCESS_TOKEN_KEY);
  }
  public getRefresherTokenAsPromise(): Promise<string> {
    return this._storage.get(StorageService.REFRESHER_TOKEN_KEY);
  }
  public getSuperAdminAccessTokenAsPromise(): Promise<string> {
    return this._storage.get(StorageService.SUPER_ADMIN_ACCESS_TOKEN_KEY);
  }
  public getSuperAdminRefresherTokenAsPromise(): Promise<string> {
    return this._storage.get(StorageService.SUPER_ADMIN_REFRESHER_TOKEN_KEY);
  }
  public getAccessTokenAsObservable(): Observable<string> {
    return from(this._storage.get(StorageService.ACCESS_TOKEN_KEY));
  }
  public async getAccessTokenAsObject(): Promise<AccessToken> {
    const token = await this._storage.get(StorageService.ACCESS_TOKEN_KEY);
    return this.decodeToken<AccessToken>(token);
  }

  public async removeSelectedAccountEquipoId(): Promise<void> {
    this._accountEquipoId = null;
    await this._storage.remove(StorageService.ACCOUNT_EQUIPO_STORAGE_KEY);
  }

  public async getRefresherTokenAsObject(): Promise<RefresherToken> {
    return this.decodeToken<RefresherToken>(
        await this.refresherToken
    );
  }

  public async wipeOutSuperAdminTokens(): Promise<void> {
    await this._storage.remove(StorageService.SUPER_ADMIN_ACCESS_TOKEN_KEY);
    await this._storage.remove(StorageService.SUPER_ADMIN_REFRESHER_TOKEN_KEY);
    this._superAdminToken = null;
  }

  public async wipeOutTokens(): Promise<void> {
    this.logger.info('Wiping out tokens');
    await this._storage.remove(StorageService.REFRESHER_TOKEN_KEY);
    await this._storage.remove(StorageService.ACCESS_TOKEN_KEY);
    await this._storage.remove(StorageService.USER_STORAGE_KEY);
    await this._storage.remove(StorageService.SUPER_ADMIN_ACCESS_TOKEN_KEY);
    await this._storage.remove(StorageService.SUPER_ADMIN_REFRESHER_TOKEN_KEY);
    this._superAdminToken = null;
  }

  private urlBase64Decode(str: string) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        // tslint:disable-next-line:no-string-throw
        throw 'Illegal base64url string!';
    }
    return decodeURIComponent((<any> window).escape(window.atob(output)));
  }

  public decodeToken<T>(token: string): T {
    if (!token) { return null; }
    const parts = token.split('.');
    if (parts.length !== 3) {

      throw new Error('JWT must have 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }
    return <T> JSON.parse(decoded);
  }

  public async saveUser(user: CommonUserModel): Promise<void> {
    await this._storage.set(StorageService.USER_STORAGE_KEY, user);
  }

  public getUserAsObservable(): Observable<CommonUserModel> {
    return from(this._storage.get(StorageService.USER_STORAGE_KEY));
  }

  public async getUserAsPromise(): Promise<CommonUserModel> {
    return await this._storage.get(StorageService.USER_STORAGE_KEY);
  }

  public async saveSelectedAccountEquipoId(accountEquipoId: number): Promise<void> {
    this._accountEquipoId = accountEquipoId;
    await this._storage.set(StorageService.ACCOUNT_EQUIPO_STORAGE_KEY, accountEquipoId);
  }
  public async getSelectedAccountEquipoIdAsPromise(): Promise<number> {
    if (this.accountEquipoId) {
      return this.accountEquipoId;
    } else {
      const account = await this._storage.get(StorageService.ACCOUNT_EQUIPO_STORAGE_KEY);
      this._accountEquipoId = account;
      return account;
    }
  }

  public getSelectedAccountEquipoIdAsObservable(): Observable<number> {
    return from(this.getSelectedAccountEquipoIdAsPromise());
  }

}
