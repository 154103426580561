import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ExecutionPositionTypes,
  GoalZoneTypes,
  PhasesActionTypes,
  PostOutZoneTypes,
} from '@handballai/stats-calculation';
import { ItemReorderEventDetail, NavController, ToastController } from '@ionic/angular';
import { ScoutingPlaylistDto } from 'src/app/api/hai-api';
import { MyTeamFileDto, MyTeamFilesDto, MyTeamFolderDto } from 'src/app/api/hai-players-api';
import { TManageAccountEquipoGrants } from 'src/app/shared-services/account-equipo/account-equipo.service';
import { CoreService } from 'src/app/shared-services/core.service';
import { CommonUserModel } from 'src/app/shared-services/model/common-user.model';
import { environment } from 'src/environments/environment';
import { ScoutingService } from '../../../scouting.service';
import { GamesGoalYourSearchModel } from '../../models/yourSearch.model';
import { ScoutingFolderNamePopupComponent } from '../../scouting-folder-name-popup/scouting-folder-name-popup.component';
import { SystemPhaseSelection } from '../../selector-check-box-list/selector-check-box-list.component';
import { TimeOfEventsSelection } from '../../time-of-events/time-of-events.component';
import { TypePlayerTeamGamesSelection } from '../../type-player-team-game-selector/type-player-team-game-selector.component';
import { PlayListSelectionPopoverComponent } from './play-list-selection-popover/play-list-selection-popover.component';
import { VideoModalExtraFiltersPopoverComponent } from './video-modal-extra-filters-popover/video-modal-extra-filters-popover.component';
import { VideoDownloadService } from '../../../../../../../../shared-services/download/video-download.service';
import { ConfirmModalComponent } from '../../../../game-module/components/confirm-modal/confirm-modal';
import {
  MyTeamFoldersModalComponent
} from '../../../../../../players-module/components/my-team-folders-modal/my-team-folders-modal.component';
import {
  SelectFolderForUploadModalComponent
} from '../../../../../../players-module/components/select-folder-for-upload-modal/select-folder-for-upload-modal.component';
import { PlayersService } from '../../../../../../players-module/players.service';
import {
  UploadFilesRenameModalComponent
} from '../../../../../../players-module/components/upload-files-rename-modal/upload-files-rename-modal.component';
import { Router } from '@angular/router';

export type OffenseSystemTypes =
  | 'EQUALITY'
  | 'EQUALITY-5+1vs6'
  | 'EQUALITY-6vs5+1'
  | 'SUPERIORITY-6vs5'
  | 'SUPERIORITY-7vs6'
  | 'SUPERIORITY-OTHER'
  | 'INFERIORITY-5vs6'
  | 'INFERIORITY-6vs7'
  | 'INFERIORITY-OTHER';

export interface ScoutingVideoFilter {
  targetSelection: TypePlayerTeamGamesSelection;
  invertTarget?: boolean;
  targetAssist?: boolean;
  eventNames?: string[];
  scoutingFilters: ScoutingFilters;
}

export interface ScoutingFilters {
  disableTeamFilter?: boolean;
  requireDefensePlayer?: boolean;
  offenseSystem?: OffenseSystemTypes[];
  phase?: PhasesActionTypes[];
  executionPosition?: ExecutionPositionTypes[];
  shotLocation?: (PostOutZoneTypes | GoalZoneTypes)[];
  gameSystemAction?: string;
  minSecondsSinceStartOfGame?: number;
  maxSecondsSinceStartOfGame?: number;
}

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
})
export class VideoModalComponent implements OnInit {
  currentUser: CommonUserModel;
  selectedEquipo: TManageAccountEquipoGrants;

  showNumber = !environment.production;
  public videosReordered = false;

  public modalTitleData: string = 'Your Selection';

  @Input() scoutingPlaylist: ScoutingPlaylistDto;

  @Input()
  set modalTitle(value: string) {
    if (!value) return;
    this.modalTitleData = value;
  }
  get modalTitle() {
    return this.modalTitleData;
  }
  private originalClips: Array<GamesGoalYourSearchModel>;
  public clips: Array<GamesGoalYourSearchModel>;
  public selectedClip: GamesGoalYourSearchModel;
  public events: any[] = [];
  public sortType = 'default';

  @Input()
  set allClips(value: Array<GamesGoalYourSearchModel>) {
    if (!value) return;
    this.clips = value;
  }
  get allClips() {
    return this.clips;
  }

  public isSelectionClicked: boolean = true;
  public allSelectedVideos = [];
  public allSelectedVideoIds = [];

  @Input()
  set filter(filter: ScoutingVideoFilter) {
    if (Array.isArray(filter.targetSelection.team) && filter.targetSelection.team.length > 1 && !filter.targetSelection.player) {
      if (!this.clips) {
        this.clips = [];
      }
      filter.targetSelection.team.forEach(team => {
        this.core.gameDataService
            .getScoutingClipsForSelection({
              tid: team,
              pid: filter.targetSelection.player,
              gameList: filter.targetSelection.games,
              invertTarget: filter.invertTarget,
              targetAssist: filter.targetAssist,
              eventNames: filter.eventNames,
              scoutingFilters: filter.scoutingFilters,
            })
            .then((pbp) => {
              this.clips = this.originalClips = [...this.clips, ...pbp.map((clip) => ({
                id: clip.id,
                event: clip.event,
                phase: clip.phase,
                offenseSystem: clip.offenseSystem,
                homeTeam: clip.gameHomeTeam,
                visitorTeam: clip.gameVisitorTeam,
                gameDate: clip.gameDate,
                defenseSystem: clip.defenseSystem,
                secondsSinceStartOfGame: clip.secondsSinceStartOfGame,
                halftime: clip.halftime,
                videoUrl: clip.videoUrl,
                eventNumber: clip.orderId
              }))];
              this.selectedClip = this.clips[0];
            })
            .catch((err) => {
              console.error('Error loading clips', err);
            });
      });
    } else {
      this.core.gameDataService
          .getScoutingClipsForSelection({
            tid: filter.targetSelection.team,
            pid: filter.targetSelection.player,
            gameList: filter.targetSelection.games,
            invertTarget: filter.invertTarget,
            targetAssist: filter.targetAssist,
            eventNames: filter.eventNames,
            scoutingFilters: filter.scoutingFilters,
          })
          .then((pbp) => {
            this.clips = this.originalClips = pbp.map((clip) => ({
              id: clip.id,
              event: clip.event,
              phase: clip.phase,
              offenseSystem: clip.offenseSystem,
              homeTeam: clip.gameHomeTeam,
              visitorTeam: clip.gameVisitorTeam,
              gameDate: clip.gameDate,
              defenseSystem: clip.defenseSystem,
              secondsSinceStartOfGame: clip.secondsSinceStartOfGame,
              halftime: clip.halftime,
              videoUrl: clip.videoUrl,
              eventNumber: clip.orderId
            }));
            this.selectedClip = this.clips[0];
          })
          .catch((err) => {
            console.error('Error loading clips', err);
          });
    }
  }

  @Input()
  set allEvents(videos: any[]) {
    if (!videos) return;
    this.events = videos.sort((a, b) => {
      const dateA = new Date(a.gameDate);
      const dateB = new Date(b.gameDate);
      if (dateA.getTime() !== dateB.getTime()) {
        return dateB.getTime() - dateA.getTime();
      }
      const timeAInSeconds = this.convertTimeToSeconds(a.time);
      const timeBInSeconds = this.convertTimeToSeconds(b.time);

      return timeAInSeconds - timeBInSeconds;
    });
    if (videos.length) {
      setTimeout(() => {
        this.selectClip(this.events[0]);
      }, 250);
    }
  }

  @Input()
  set systemPhaseSelection(value: SystemPhaseSelection) {
    if (value) this.systemPhaseSelectionData = value;
  }

  get systemPhaseSelection(): SystemPhaseSelection {
    return this.systemPhaseSelectionData;
  }

  systemPhaseSelectionData: SystemPhaseSelection = {
    allSystem: true,
    superiority: true,
    sup6vs5: true,
    sup7vs6: true,
    supOther: true,
    equality: true,
    eqef: true,
    eq51vs6: true,
    eq6vs51: true,
    inferiority: true,
    inf5vs6: true,
    inf6vs7: true,
    infOther: true,

    important: false,

    allPhases: true,
    positionalOffense: true,
    fastBreak: true,
    counterGoal: true,

    allDeffenseSystems: true,
    against60: true,
    against51: true,
    against321: true,
    against42: true,
    againstOthers: true,
  };

  @Input()
  set timeOfEventsSelection(value: TimeOfEventsSelection) {
    if (value) this.timeOfEventsSelectionData = value;
  }

  get timeOfEventsSelection(): TimeOfEventsSelection {
    return this.timeOfEventsSelectionData;
  }

  timeOfEventsSelectionData: TimeOfEventsSelection = {
    minSecondsSinceStartOfGame: 0,
    maxSecondsSinceStartOfGame: 60 * 60,
  };

  constructor(
    public core: CoreService,
    private readonly nav: NavController,
    private scoutingService: ScoutingService,
    private videoDownloadService: VideoDownloadService,
    private playersService: PlayersService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.currentUser = this.core.userService.user$.getValue();
    this.selectedEquipo = this.core.accountEquipoService.selectedAccountEquipo$.getValue();
    if (this.selectedEquipo?.grants?.includes('manage_my_team') && this.selectedEquipo?.account?.permissions?.accessMyTeam) {
      await this.playersService.getMyTeamFoldersData();
    }
  }

  openExtraFilters(e: Event) {
    this.core.popoverCtrl
      .create({
        component: VideoModalExtraFiltersPopoverComponent,
        cssClass: 'scouting-videomodal-extrafilters',
        componentProps: {
          systemPhaseSelection: this.systemPhaseSelection,
          timeOfEventsSelection: this.timeOfEventsSelection,
          availableEvents: Array.from(new Set(this.events.length ? this.events.map(event => event.event) : this.originalClips?.map(event => event.event))),
          availableOffenseSystem: Array.from(new Set(this.events.length ? this.events.map(event => event.offenseSystem) : this.originalClips?.map(event => event.offenseSystem))),
          availablePhases: Array.from(new Set(this.events.length ? this.events.map(event => event.phase) : this.originalClips?.map(event => event.phase))),
          availableDefenseSystem: Array.from(new Set(this.events.length ? this.events.map(event => event.defenseSystem) : this.originalClips?.map(event => event.defenseSystem))),
        },
        event: e,
      })
      .then((p) => {
        p.onDidDismiss().then(() => {
          console.warn('ldata', this.systemPhaseSelection, this.timeOfEventsSelection);
          this.filterData();
        });
        p.present();
      });
  }

  filterData() {
    this.clips = this.originalClips?.filter((clip) => {
      let res = true;
      if (res && !this.systemPhaseSelection.allSystem) {
        res = false;

        if (this.systemPhaseSelection.sup6vs5 && clip.offenseSystem == 'SUPERIORITY-6vs5') res = true;
        if (this.systemPhaseSelection.sup7vs6 && clip.offenseSystem == 'SUPERIORITY-7vs6') res = true;
        if (this.systemPhaseSelection.supOther && clip.offenseSystem == 'SUPERIORITY-OTHER') res = true;
        if (this.systemPhaseSelection.eqef && clip.offenseSystem == 'EQUALITY') res = true;
        if (this.systemPhaseSelection.eq51vs6 && clip.offenseSystem == 'EQUALITY-5+1vs6') res = true;
        if (this.systemPhaseSelection.eq6vs51 && clip.offenseSystem == 'EQUALITY-6vs5+1') res = true;
        if (this.systemPhaseSelection.inf5vs6 && clip.offenseSystem == 'INFERIORITY-5vs6') res = true;
        if (this.systemPhaseSelection.inf6vs7 && clip.offenseSystem == 'INFERIORITY-6vs7') res = true;
        if (this.systemPhaseSelection.infOther && clip.offenseSystem == 'INFERIORITY-OTHER') res = true;
      }
      if (res && !this.systemPhaseSelection.allPhases) {
        res = false;
        if (this.systemPhaseSelection.positionalOffense && clip.phase == 'OFFENSE_POSITIONAL') res = true;
        if (this.systemPhaseSelection.fastBreak && clip.phase == 'FAST_BREAK') res = true;
        if (this.systemPhaseSelection.counterGoal && clip.phase == 'COUNTER_GOAL') res = true;
      }
      if (res && !this.systemPhaseSelection.allDeffenseSystems) {
        res = false;
        if (this.systemPhaseSelection.against60 && clip.defenseSystem == '6:0') res = true;
        if (this.systemPhaseSelection.against51 && clip.defenseSystem == '5:1') res = true;
        if (this.systemPhaseSelection.against321 && clip.defenseSystem == '3:2:1') res = true;
        if (this.systemPhaseSelection.against42 && clip.defenseSystem == '4:2') res = true;
        if (this.systemPhaseSelection.againstOthers && clip.defenseSystem == 'OTHER') res = true;
      }
      if (res) {
        if (clip.secondsSinceStartOfGame < this.timeOfEventsSelection.minSecondsSinceStartOfGame) res = false;
        if (
          this.timeOfEventsSelection.maxSecondsSinceStartOfGame != 60 * 60 &&
          clip.secondsSinceStartOfGame > this.timeOfEventsSelection.maxSecondsSinceStartOfGame
        )
          res = false;
      }
      return res;
    });
  }

  public handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    const fromIndex = ev.detail.from;
    const toIndex = ev.detail.to;

    const clipsOrEvents = this.clips?.length ? this.clips : (this.events?.length ? this.events : undefined);
    const clipsOrEventsKey = this.clips?.length ? 'clips' : (this.events?.length ? 'events' : undefined);
    if (clipsOrEvents) {
      this.videosReordered = true;
      const reorderedFiles = [...clipsOrEvents];
      const [movedItem] = reorderedFiles.splice(fromIndex, 1);
      reorderedFiles.splice(toIndex, 0, movedItem);

      this[clipsOrEventsKey] = reorderedFiles;

      if (this.allSelectedVideos.length) {
        const selectedVideoIds = [...this.allSelectedVideoIds];
        this.allSelectedVideos = [];
        this.allSelectedVideoIds = [];
        reorderedFiles.forEach(clip => {
          if (selectedVideoIds.includes(clip.id)) {
            this.getSelectedVideos(clip);
          }
        });
      }

      if (this.scoutingPlaylist?.videos.length) {
        const reorderedPlaylist = [...this.scoutingPlaylist.videos];
        const [movedItem] = reorderedPlaylist.splice(fromIndex, 1);
        reorderedPlaylist.splice(toIndex, 0, movedItem);

        this.scoutingPlaylist.videos = reorderedPlaylist;
        this.playersService.reorderedScoutingPlaylist = this.scoutingPlaylist;
      }
    }

    ev.detail.complete();
  }

  getMatchTime(seconds: number): string {
    const minutes = `${Math.floor(seconds / 60)}`.padStart(2, '0');
    const sec = `${seconds % 60}`.padStart(2, '0');
    return minutes + ':' + sec;
  }

  selectClip(clip: GamesGoalYourSearchModel, el?: HTMLElement): void {
    this.selectedClip = undefined;
    setTimeout(() => {
      this.selectedClip = clip;
      if (this.core.isMobileDevice && el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }

  back(): void {
    if (this.scoutingPlaylist?.videos.length && this.videosReordered) {
      this.scoutingPlaylist.videos.forEach((video, i) => {
        video.orderId = i + 1;
      });
      this.scoutingService.editPlayList(this.scoutingPlaylist);
    }
    this.core.popoverCtrl.dismiss();
  }

  public selection(): void {
    this.allSelectedVideos = [];
    this.allSelectedVideoIds = [];
    this.isSelectionClicked = !this.isSelectionClicked;
  }

  public async addVideosToFolderClick() {
    const accountEquipos = await this.core.accountEquipoService.accountEquipos$.getValue();
    const accountsWithManageMyTeamPermission = accountEquipos.filter(acc => acc.grants?.includes('manage_my_team') && acc.account.permissions.accessMyTeam);
    if (!accountsWithManageMyTeamPermission.length) {
      this.core.popoverCtrl.dismiss();
      this.router.navigate(['/my-team']);
    } else {
      const popover = await this.core.popoverCtrl.create({
        component: SelectFolderForUploadModalComponent,
        cssClass: 'select-folder-popover',
        mode: 'ios',
        componentProps: {
          accounts: accountsWithManageMyTeamPermission
        },
      });
      popover.onDidDismiss().then(async (modalData: any) => {
        if (modalData.data) {
          const selectedAccountEquipoId = modalData.data.accountEquipoId;
          const files: MyTeamFileDto[] = [];
          this.allSelectedVideos.forEach(video => {
            const event = this.events.find(cl => cl.id === video.pbpId);
            const clip = this.clips ? this.clips.find(cl => cl.id === video.pbpId) : this.events.find(cl => cl.id === video.pbpId);
            const file: MyTeamFileDto & { eventNumber?: number } = {
              name: (clip.homeTeam ? clip.homeTeam : clip.gameHomeTeam) + ' VS ' + (clip.visitorTeam ? clip.visitorTeam : clip.gameVisitorTeam),
              videoDateTime: clip.gameDate + ' - ' + clip.halftime + ' ' + this.getMatchTime(clip.secondsSinceStartOfGame),
              eventPosition: this.humanizeValue(clip.event) + ' / ' + this.humanizeValue(clip.phase),
              folderId: modalData.data.folder.id,
              s3Url: clip.videoUrl,
              type: 'Video',
              pbpId: video.pbpId,
              eventNumber: clip.eventNumber ?? event?.eventN
            };
            files.push(file);
          });
          const filesPopover = await this.core.popoverCtrl.create({
            component: UploadFilesRenameModalComponent,
            cssClass: 'select-folder-popover',
            mode: 'ios',
            componentProps: {
              files
            },
          });
          filesPopover.onDidDismiss().then(async (fileData: any) => {
            if (fileData.data) {
              const filesDto: MyTeamFilesDto = fileData.data;
              await this.playersService.addMultipleFiles(modalData.data.folder.id, filesDto, false, selectedAccountEquipoId);
            } else {
              await this.addVideosToFolderClick();
            }
          });
          await filesPopover.present();
        }
      });
      await popover.present();
    }
  }

  public onSortClick() {
    // Define the sort type
    if (this.sortType === 'default') {
      this.sortType = 'asc';
    } else if (this.sortType === 'asc') {
      this.sortType = 'desc';
    } else {
      this.sortType = 'asc';
    }

    // Perform sorting
    const clipsOrEvents = this.clips?.length ? this.clips : (this.events?.length ? this.events : undefined);
    if (clipsOrEvents) {
      if (this.sortType == 'asc') {
        clipsOrEvents.sort((a, b) => (a.secondsSinceStartOfGame > b.secondsSinceStartOfGame ? 1 : -1));
      } else if (this.sortType == 'desc') {
        clipsOrEvents.sort((a, b) => (b.secondsSinceStartOfGame > a.secondsSinceStartOfGame ? 1 : -1));
      }
    }
  }

  public onSelectAllClick() {
    const clipsOrEvents = this.clips?.length ? this.clips : (this.events?.length ? this.events : undefined);
    if (clipsOrEvents) {
      if (clipsOrEvents.length === this.allSelectedVideos.length) {
        this.allSelectedVideos = [];
        this.allSelectedVideoIds = [];
      } else {
        clipsOrEvents.forEach(video => {
          if (!this.allSelectedVideos.map(vd => vd.pbpId).includes(video.id)) {
            this.getSelectedVideos(video);
          }
        });
      }
    }
  }

  public getSelectedVideos(video) {
    if (!this.allSelectedVideos.some((selected) => selected.pbpId === video.id)) {
      const videoTitle =
          (video.homeTeam ? video.homeTeam : video.gameHomeTeam) +
        ' vs ' +
          (video.visitorTeam ? video.visitorTeam : video.gameVisitorTeam) +
        '\n(' +
        video.gameDate +
        ' - ' +
        this.getMatchTime(video.secondsSinceStartOfGame) +
        ')';
      this.allSelectedVideos.push({ pbpId: video.id, deleted: false, info: videoTitle });
      this.allSelectedVideoIds.push(video.id);
    } else {
      this.allSelectedVideos = this.allSelectedVideos.filter((selected) => selected.pbpId !== video.id);
      this.allSelectedVideoIds = this.allSelectedVideoIds.filter((selected) => selected !== video.id);
    }
  }

  public async createPlaylist() {
    const popover = await this.core.popoverCtrl.create({
      component: ScoutingFolderNamePopupComponent,
      cssClass: 'confirm-popover',
      mode: 'ios',
      componentProps: {
        modalTitle: 'Create Playlist',
        message: 'Type the new Playlist name',
      },
    });
    popover.onDidDismiss().then(async (modalData: any) => {
      if (modalData.data) {
        const playList: ScoutingPlaylistDto = {
          id: null,
          name: modalData.data.name,
          deleted: false,
          videos: this.allSelectedVideos,
        };
        this.scoutingService.getUserAndEquipo();
        await this.scoutingService.createPlayList(playList);
        this.back();
        // this.nav.navigateRoot(['/account/' + this.selectedEquipo.id + '/scouting/playlist']);
      }
    });
    await popover.present();
  }

  public async addPlaylist() {
    const popover = await this.core.popoverCtrl.create({
      component: PlayListSelectionPopoverComponent,
      cssClass: 'confirm-popover',
      mode: 'ios',
      componentProps: {
        selectedVideos: this.allSelectedVideos,
      },
    });

    popover.onDidDismiss().then(async (modalData: any) => {
      if (modalData.data) {
        this.scoutingService.editPlayList(modalData.data);
      }
    });
    await popover.present();
  }

  public downloadVideos(): void {
    if (this.allSelectedVideos.length > 1) {
      ConfirmModalComponent.Open(this.core.popoverCtrl, {
        title: 'Download Video',
        pictureUrl: 'assets/popup-warning.jpg'
      }, (d) => {
        if (d.data) {
          this.startDownloadingVideos();
        }
      });
    } else {
      this.startDownloadingVideos();
    }
  }

  public startDownloadingVideos() {
    if (this.clips?.length || this.events?.length) {
      const videos = this.clips ? this.clips.filter(video => this.allSelectedVideos.map(vid => vid.pbpId).includes(video.id)) :
            this.events.filter(video => this.allSelectedVideos.map(vid => vid.pbpId).includes(video.id));
      videos.forEach(video => {
        const link = document.createElement('a');
        link.href = video.videoUrl;
        link.target = '_blank';
        link.download = video.homeTeam + ' VS ' + video.visitorTeam + ' - ' + video.gameDate + '.mp4';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  }

  public humanizeValue(value: string) {
    return value?.replace(/_/g, ' ');
  }

  private convertTimeToSeconds(time: string): number {
    const [half, minutesAndSeconds] = time.split(' ');
    const [minutes, seconds] = minutesAndSeconds.split(':').map(Number);
    let totalSeconds = minutes * 60 + seconds;

    if (half === 'T2') {
      totalSeconds += 1800;
    }

    return totalSeconds;
  }

  public static Open(core: CoreService, filter: ScoutingVideoFilter, modalTitle?: string) {
    core.popoverCtrl
      .create({
        component: VideoModalComponent,
        componentProps: { filter, modalTitle },
        cssClass: 'scouting-video-popover wide',
        mode: 'ios',
      })
      .then((p) => p.present());
  }

  static OnSearch(
    core: CoreService,
    targetSelection: TypePlayerTeamGamesSelection,
    event?: string | string[],
    invert?: boolean,
    targetAssist?: boolean,
    modalTitle?: string,
    scoutingFilters?: ScoutingFilters
  ) {
    const filter = {
      targetSelection: targetSelection,
      invertTarget: invert,
      targetAssist: targetAssist,
      eventNames: Array.isArray(event) ? event : event ? [event] : undefined,
      scoutingFilters: scoutingFilters,
    };
    if (!filter.targetSelection.team) {
      console.warn('Prevent video open since no target is selected', filter.targetSelection);
      return;
    }
    console.log('Searching with filter: ', filter);
    VideoModalComponent.Open(core, filter, modalTitle ?? 'Your search');
  }
  public downloadSelection() {}
}
