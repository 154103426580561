import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'luxonDateResolver'
})
export class DateResolverPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    const convertDate = (d: string): string => DateTime.fromJSDate(new Date(d)).toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' });
    return convertDate(value)
  }
}
