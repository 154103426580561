
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { GameModel, GamePlayerModel } from 'src/app/shared-services/model/game.model';
import {
    DefenseSystem,
    GoalCounterPerShoots,
    PlayerEvent, executionPositionsAsStringList, shotLocationAsStringList,
    TeamEvent,
    TeamEventTypes, TeamsStatsCounterWithPhase,
    TeamStatsPlayerModel
} from '@handballai/stats-calculation';
import { ExecutionPositionTypes } from 'src/app/shared-services/actions/action-types';

class PlaytimeAggregationHelper {
    secCounter = 0;
    reversedStartList: PlayerEvent[];
    private indx = 0;

    constructor(playTimeList: PlayerEvent[]) {
        this.reversedStartList = playTimeList.reverse();
    }

    public addEndTime(playTimeEvent: PlayerEvent): PlaytimeAggregationHelper {
        const delta = (playTimeEvent.eventTime.secondsSinceStartOfGame
            - this.reversedStartList[this.indx].eventTime.secondsSinceStartOfGame);
        this.secCounter = this.secCounter + delta;
        this.indx++;
        return this;
    }
}

export const calculatePlaytimeSecWithCurrent = (
    currSecCounter: number,
    playTimeList: PlayerEvent[],
    currSec = 0
): number => {

    if (currSec === 0) { // we have either finished the game or it has not started
        return currSecCounter;
    }
    if (playTimeList[playTimeList.length - 1].eventType === 'PLAY_TIME_END') { // Game might run but player is not in game
        return currSecCounter;
    }
    // Player is in game so calculate the playtime based on the current seconds since the beginning
    return currSecCounter + (
        currSec - playTimeList[playTimeList.length - 1]
            .eventTime.
            secondsSinceStartOfGame
    );
};
export const calculateNewAggPlaytime = (
    currSec: number,
    playTimeList: PlayerEvent[],
    currEvent: PlayerEvent
): number => {
    if (currEvent.eventType === 'PLAY_TIME_START') {
        return currSec;
    }
    return currSec +
        (currEvent
            .eventTime
            .secondsSinceStartOfGame - playTimeList[playTimeList.length - 1]
            .eventTime.
            secondsSinceStartOfGame);
};

export const calculateSecOutOfPlayTime = (minutes: number, seconds: number): number => minutes * 60 + seconds;

export const mapGamePlayerToTeamStatsPlayer = (player: GamePlayerModel): TeamStatsPlayerModel => ({
    id: player.id,
    backNumber: player.backNumber,
    name: player.name,
    position: player.position
} as TeamStatsPlayerModel);

export const getTeamByTeamMarker = (teamMarker: TeamMarker, gameModel: GameModel): TeamStatsPlayerModel[] => {
    if (teamMarker === 'HOME') {
        return gameModel.home.currentField.map(mapGamePlayerToTeamStatsPlayer);
    } else {
        return gameModel.visitor.currentField.map(mapGamePlayerToTeamStatsPlayer);
    }
};

export const getGoalKeeperByTeamMarker = (teamMarker: TeamMarker, gameModel: GameModel, filterByGoalPosition: boolean = false): TeamStatsPlayerModel => {
    let gk: GamePlayerModel = getGoalKeeperByTeamMarkerAsGamePlayer(teamMarker, gameModel, filterByGoalPosition);
    return gk ? mapGamePlayerToTeamStatsPlayer(gk) : undefined;
};

export const getGoalKeeperByTeamMarkerAsGamePlayer = (teamMarker: TeamMarker, gameModel: GameModel, filterByGoalPosition: boolean = false): GamePlayerModel => {
    let gk: GamePlayerModel[];
    // TODO: Confirm this filterByGoalPosition for simple mode gk position filtering when not at goal implementation with claboran
    if (teamMarker === 'HOME') {
        gk = gameModel.home.currentField.filter(p => p.position === 'gk' && (!filterByGoalPosition || p.slotId==1));
    } else {
        gk = gameModel.visitor.currentField.filter(p => p.position === 'gk' && (!filterByGoalPosition || p.slotId==1));
    }
    return gk.length > 0 ? gk[0] : undefined;
};

export const getOpponentTeamMarker = (teamMarker: TeamMarker): TeamMarker => teamMarker === 'HOME' ? 'VISITOR' : 'HOME';

export const getTeamIdByTeamMarker = (teamMarker: TeamMarker, gameModel: GameModel): number =>
    teamMarker === 'HOME' ? gameModel.home.id : gameModel.visitor.id;

export const getTeamNameByTeamMarker = (teamMarker: TeamMarker, gameModel: GameModel): string =>
    teamMarker === 'HOME' ? gameModel.home.name : gameModel.visitor.name;

export const getDefenseSystemByTeamMarker = (teamMarker: TeamMarker, gameModel: GameModel): DefenseSystem =>
    teamMarker === 'HOME' ? gameModel.home.defenseSystem : gameModel.visitor.defenseSystem;

export const convertPlayerEventToTeamEvent = (playerEvent: PlayerEvent): TeamEvent => ({
    eventTime: playerEvent.eventTime,
    eventType: <TeamEventTypes> playerEvent.eventType,
    phase: playerEvent.phase,
    executionPosition: playerEvent.executionPosition
});

export const deepCopyExecutionPositionMap = (
    map: Map<ExecutionPositionTypes, GoalCounterPerShoots>
): Map<ExecutionPositionTypes, GoalCounterPerShoots> => Array
    .from(map.entries()).reduce((accu, curr) => {
        accu.set(curr[0], {...curr[1]});
        return accu;
        }, new Map<ExecutionPositionTypes, GoalCounterPerShoots>());

export const convertTeamsStatsCounterWithPhaseToArray = (
    teamStatsCounter: TeamsStatsCounterWithPhase,
    currAccu: number[]
): number[] => {
    if (!teamStatsCounter) {
        return [0, 0, 0, 0];
    }
    return Array.from(teamStatsCounter.counterMap.entries()).reduce((accu, curr) => {
        switch (curr[0]) {
            case 'OFFENSE_POSITIONAL':
                accu[0] = accu[0] + curr[1];
                break;
            case 'COUNTER_GOAL':
                accu[1] = accu[1] + curr[1];
                break;
            case 'FAST_BREAK':
                accu[2] = accu[2] + curr[1];
                break;
            default:
                accu[3] = accu[3] + curr[1];
        }
        return accu;
    }, currAccu);
};

export const populatePlayerGoalMap = (): Map<string, GoalCounterPerShoots> =>
    executionPositionsAsStringList.reduce(
        (accu, curr) => {
            shotLocationAsStringList.forEach(loc => {
                accu.set(`${curr}:${loc}:OFFENSE_POSITIONAL`, {goals: 0, shots: 0});
                accu.set(`${curr}:${loc}:COUNTER_GOAL`, {goals: 0, shots: 0});
                accu.set(`${curr}:${loc}:FAST_BREAK`, {goals: 0, shots: 0});
                accu.set(`${curr}:${loc}:PHASE_UNDEFINED`, {goals: 0, shots: 0});
            });
            return accu;
            }, new Map<string, GoalCounterPerShoots>()
    );
