import { VideoPlayByPlay } from 'src/app/shared-services/statistics/video/model/video-tracker.util';
import {
    TMinimalTeamOverviewStatsModel
} from 'src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/field-player/model/minimal-team-stats.model';
import { CounterModel, GameModel, GamePlayerModel, getOpponentTeamMarker, PositionCode, TeamMarker } from '@handballai/stats-calculation';

export class MinimalOverviewStatsHolder {
    constructor(
        public playerId: number,
        public position: PositionCode,
        public goals: CounterModel = new CounterModel('GOALS'),
        public saves: CounterModel = new CounterModel('SAVES'),
        public postOuts: CounterModel = new CounterModel('POST_OUTS'),
        public gkReceivedGoals: CounterModel = new CounterModel('GK_RECEIVED_GOALS'),
        public gkSaves: CounterModel = new CounterModel('GK_SAVES'),
        public lostBalls: CounterModel = new CounterModel('LOST_BALLS'),
    ) {
    }

    public addGoal(): void {
        this.goals = this.goals.increment();
    }

    public addSave(): void {
        this.saves = this.saves.increment();
    }

    public addPostOuts(): void {
        this.postOuts = this.postOuts.increment();
    }

    public addGkReceivedGoals(): void {
        this.gkReceivedGoals = this.gkReceivedGoals.increment();
    }

    public addGkSaves(): void {
        this.gkSaves = this.gkSaves.increment();
    }

    public addLostBalls(): void {
        this.lostBalls = this.lostBalls.increment();
    }

    transformToModel(): TMinimalTeamOverviewStatsModel {
        return {
            playerId: this.playerId,
            playerPosition: this.position,
            saves: this.position === 'gk' ? this.gkSaves.counter : this.saves.counter,
            goals: this.goals.counter,
            postOuts: this.postOuts.counter,
            goalsReceived: this.gkReceivedGoals.counter,
            failedShots: this.saves.counter + this.postOuts.counter,
            lostBalls: this.lostBalls.counter
        };
    }
}

export interface MinimalTeamOverviewStatsModelHolder {
    homeStats: TMinimalTeamOverviewStatsModel[];
    visitorStats: TMinimalTeamOverviewStatsModel[];
}

export interface PlayerModelStatsHolder {
    offenseStatsHolder: MinimalOverviewStatsHolder;
    defenseStatsHolder?: MinimalOverviewStatsHolder;
}

const createInitialMapForOneTeam = (teamMarker: TeamMarker, players: GamePlayerModel[]): Map<number, MinimalOverviewStatsHolder> =>
    players.reduce((teamMap, curr) => {
        teamMap.set(curr.id, new MinimalOverviewStatsHolder(curr.id, curr.position));
        return teamMap;
    }, new Map<number, MinimalOverviewStatsHolder>());

const processGoalEvent = (
    pbp: VideoPlayByPlay,
    statsHolder: PlayerModelStatsHolder
): PlayerModelStatsHolder => {
    statsHolder.offenseStatsHolder.addGoal();
    if (statsHolder.defenseStatsHolder) {
        statsHolder.defenseStatsHolder.addGkReceivedGoals();
    }
    return statsHolder;
};

const processSaveEvent = (
    pbp: VideoPlayByPlay,
    statsHolder: PlayerModelStatsHolder
): PlayerModelStatsHolder => {
    statsHolder.offenseStatsHolder.addSave();
    if (statsHolder.defenseStatsHolder) {
        statsHolder.defenseStatsHolder.addGkSaves();
    }
    return statsHolder;
};

const processLostBallEvent = (
    pbp: VideoPlayByPlay,
    statsHolder: PlayerModelStatsHolder
): PlayerModelStatsHolder => {
    statsHolder.offenseStatsHolder.addLostBalls();
    return statsHolder;
};

const processPostOutEvent = (
    pbp: VideoPlayByPlay,
    statsHolder: PlayerModelStatsHolder
): PlayerModelStatsHolder => {
    statsHolder.offenseStatsHolder.addPostOuts();
    return statsHolder;
};

export const calculateMinimalOverviewStatsModel = (
    pbp: VideoPlayByPlay[],
    gameModel: GameModel,
): MinimalTeamOverviewStatsModelHolder => {

    const counterModelMap = pbp
        .filter(p => p.event === 'GOAL' || p.event === 'SAVE' || p.event === 'POST_OUT' || p.event === 'LOST_BALL' || p.event === 'TECHNICAL_MISTAKE_COMMIT' || p.event === 'ATTACK_FAULT_COMMIT')
        .reduce((modelMap, curr) => {
            let res: PlayerModelStatsHolder;
            if (curr.offensePlayer) {
                if (curr.event === 'GOAL') {
                    res = processGoalEvent(curr, {
                        offenseStatsHolder: modelMap.get(curr.teamMarker).get(curr.offensePlayer.id),
                        defenseStatsHolder: curr.defensePlayer ? modelMap.get(getOpponentTeamMarker(curr.teamMarker))
                            .get(curr.defensePlayer.id) : undefined
                    });
                } else if (curr.event === 'SAVE') {
                    res = processSaveEvent(curr, {
                        offenseStatsHolder: modelMap.get(curr.teamMarker).get(curr.offensePlayer.id),
                        defenseStatsHolder: curr.defensePlayer ? modelMap.get(getOpponentTeamMarker(curr.teamMarker))
                            .get(curr.defensePlayer.id) : undefined
                    });
                } else if (curr.event === 'LOST_BALL' || curr.event === 'TECHNICAL_MISTAKE_COMMIT' || curr.event === 'ATTACK_FAULT_COMMIT') {
                    res = processLostBallEvent(curr, {
                        offenseStatsHolder: modelMap.get(curr.teamMarker).get(curr.offensePlayer.id),
                        defenseStatsHolder: curr.defensePlayer ? modelMap.get(getOpponentTeamMarker(curr.teamMarker))
                            .get(curr.defensePlayer.id) : undefined
                    });
                } else {
                    res = processSaveEvent(curr, {
                        offenseStatsHolder: modelMap.get(curr.teamMarker).get(curr.offensePlayer.id),
                        defenseStatsHolder: curr.defensePlayer ? modelMap.get(getOpponentTeamMarker(curr.teamMarker))
                            .get(curr.defensePlayer.id) : undefined
                    });
                }
                modelMap.set(curr.teamMarker, modelMap.get(curr.teamMarker).set(curr.offensePlayer.id, res.offenseStatsHolder));
                if (curr.defensePlayer) {
                    modelMap.set(
                        getOpponentTeamMarker(curr.teamMarker),
                        modelMap.get(getOpponentTeamMarker(curr.teamMarker)).set(curr.defensePlayer.id, res.defenseStatsHolder)
                    );
                }
            }
            return modelMap;
        }, new Map<TeamMarker, Map<number, MinimalOverviewStatsHolder>>([
            ['HOME', createInitialMapForOneTeam('HOME', gameModel.home.players)],
            ['VISITOR', createInitialMapForOneTeam('VISITOR', gameModel.visitor.players)],
        ]));
    return {
        homeStats: Array.from(counterModelMap.get('HOME').values()).map(entry => entry.transformToModel()),
        visitorStats: Array.from(counterModelMap.get('VISITOR').values()).map(entry => entry.transformToModel()),
    };
};
