import { Component, Input, OnInit } from '@angular/core';
import { GamePlayerModel, GameTeamModel } from 'src/app/shared-services/model/game.model';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import {
  DefensePlayerActionTypes,
  OffensePlayerActionTypes,
  SuspensionToBenchActionTypes
} from 'src/app/shared-services/actions/action-types';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GameSystemCategoryDto } from 'src/app/api/hai-api';
import { CoreService } from 'src/app/shared-services/core.service';
import { TGameSystemPbp } from 'src/app/shared-services/game-system/game-system.model';
import { GameType } from 'src/app/shared-services/game/game-type.model';

@Component({
  selector: 'app-double-event-popover-container',
  templateUrl: './double-event-popover-container.component.html',
  styleUrls: ['./double-event-popover-container.component.scss'],
})
export class DoubleEventPopoverContainerComponent implements OnInit {

  private _fieldPlayers: GamePlayerModel[];
  private _opponentTeam: GameTeamModel;
  private _requirePlayerSelect: boolean = false;
  private _teamMarker: TeamMarker;
  private _sourceEvent: OffensePlayerActionTypes | DefensePlayerActionTypes | undefined;
  private _sourceSuspensionEvent: SuspensionToBenchActionTypes | undefined;
  private readonly _gameSystem$: Observable<GameSystemCategoryDto[]>;
  private readonly _gameSystemEnabled$: Observable<boolean>;
  private readonly _gameMode$: Observable<GameType>;
  private _gameSystemSelected: TGameSystemPbp[];
  private _gameActionNameSelected: string[];

  private _subscriptions: Subscription[] = [];

  constructor(private core: CoreService) {
    this._gameSystem$ = this.core.gameDataService.gameSystems$;
    this._gameMode$ = this.core.gameService.gameMode$;
    this._gameSystemEnabled$ = this.core.gameDataService.gameSystemEnabled$;
  }

  ngOnInit() {
    this._subscriptions.push(this.core.gameSystemService.gameSystemSelected$
    .pipe(
      tap(gameSystemSelected => this._gameSystemSelected = gameSystemSelected)
    )
    .subscribe());

    this._subscriptions.push(this.core.gameSystemService.gameActionNameSelected$
    .pipe(
      tap(gameActionNameSelected => this._gameActionNameSelected = gameActionNameSelected)
    )
    .subscribe());
  }

  @Input() isSuspensionToTheBench: boolean;

  get fieldPlayers(): GamePlayerModel[] {
    return this._fieldPlayers;
  }

  @Input()
  set fieldPlayers(value: GamePlayerModel[]) {
    this._fieldPlayers = value;
  }

  get opponentTeam(): GameTeamModel {
    return this._opponentTeam;
  }

  @Input()
  set opponentTeam(value: GameTeamModel) {
    this._opponentTeam = value;
  }

  get requirePlayerSelect(): boolean {
    return this._requirePlayerSelect;
  }

  @Input()
  set requirePlayerSelect(value: boolean) {
    this._requirePlayerSelect = value;
  }

  get teamMarker(): TeamMarker {
    return this._teamMarker;
  }

  @Input()
  set teamMarker(value: TeamMarker) {
    this._teamMarker = value;
  }

  get sourceEvent(): OffensePlayerActionTypes | DefensePlayerActionTypes {
    return this._sourceEvent;
  }

  @Input()
  set sourceEvent(value: OffensePlayerActionTypes | DefensePlayerActionTypes) {
    this._sourceEvent = value;
  }

  get sourceSuspensionEvent(): SuspensionToBenchActionTypes | undefined {
    return this._sourceSuspensionEvent;
  }

  @Input()
  set sourceSuspensionEvent(value: SuspensionToBenchActionTypes | undefined) {
    this._sourceSuspensionEvent = value;
  }

  get gameSystem$(): Observable<GameSystemCategoryDto[]> {
    return this._gameSystem$;
  }

  get gameMode$(): Observable<GameType> {
    return this._gameMode$;
  }

  get gameSystemEnabled(): Observable<boolean> {
    return this._gameSystemEnabled$;
  }

  @Input() videoSrc: string;
  @Input() videoCurrentTime: number;

  get gameSystemSelected(): TGameSystemPbp[] {
    return this._gameSystemSelected;
  }

  get gameActionNameSelected(): string[] {
    return this._gameActionNameSelected;
  }

}
