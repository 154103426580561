import { PlayerModel, TeamModel } from '../model/team.model';
import { PlayerDto, TeamDto } from '../../api/hai-api';

export const convertPlayerModelToDto = (playerModel: PlayerModel): PlayerDto =>
    ({
        id: playerModel.id,
        name: playerModel.name,
        deleted: playerModel.deleted,
        position: playerModel.position,
        backNumber: playerModel.backNumber,
        imageUrl: playerModel.imageUrl,
        status: playerModel.status,
        // isPlaying: playerModel.isBench,
    } as PlayerDto);

export const convertTeamModelToDto = (teamModel: TeamModel): TeamDto =>
    ({
        internalTeamId: teamModel.internalTeamId,
        name: teamModel.teamname,
        country: teamModel.country,
        accountEquipoId: +teamModel.accountEquipoId,
        id: teamModel._id,
        gender: teamModel.gender,
        division: teamModel.division,
        shieldUrl: teamModel.shieldUrl,
        deleted: teamModel.deleted,
        primaryColor: teamModel.primaryColor,
        secondaryColor: teamModel.secondaryColor,
        players: teamModel.players.map(pl => convertPlayerModelToDto(pl))
    } as TeamDto);

export const convertPlayerDtoToModel = (playerDto: PlayerDto): PlayerModel =>
    ({
        id: playerDto.id,
        name: playerDto.name,
        deleted: playerDto.deleted,
        position: playerDto.position,
        backNumber: playerDto.backNumber,
        imageUrl: playerDto.imageUrl,
        status: playerDto.status,
        // isBench: playerDto.isPlaying,
    } as PlayerModel);

export const convertTeamDtoToModel = (teamDto: TeamDto): TeamModel => {
    return {
        _id: teamDto.id,
        // internalTeamId: teamDto.internalTeamId,
        internalTeamId: teamDto.id, // Started deprecation process for internalTeamId
        gender: teamDto.gender,
        division: teamDto.division,
        country: teamDto.country,
        accountEquipoId: teamDto.accountEquipoId.toString(),
        teamname: teamDto.name,
        shieldUrl: teamDto.shieldUrl,
        deleted: teamDto.deleted,
        primaryColor: teamDto.primaryColor,
        secondaryColor: teamDto.secondaryColor,
        players: teamDto.players.map(pl => convertPlayerDtoToModel(pl)),
    } as TeamModel;
};
