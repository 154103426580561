import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
  templateUrl: "./confirm-modal.html",
  styleUrls: ["./confirm-modal.scss"],
})
export class ConfirmModalComponent implements OnInit {
  @Input() title: string;

  @Input() text?: string | undefined;

  @Input() pictureUrl?: string | undefined;

  @Input() noAndYesButtons?: boolean | undefined;

  constructor(private readonly popoverCntl: PopoverController) {}

  ngOnInit(): void {}

  confirmFolderChange(confirm: boolean) {
    this.popoverCntl.dismiss(confirm);
  }

  public static async Open(popoverCntl: PopoverController, props: any, cb: Function) {
    const popover = await popoverCntl.create({
      component: ConfirmModalComponent,
      // translucent: true,
      cssClass: "confirm-popover",
      componentProps: props,
    });
    popover.onDidDismiss().then(d => cb(d));
    await popover.present();
  }

}
