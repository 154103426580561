import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss'],
    standalone: false
})
export class UserAvatarComponent implements OnInit {

  private _firstName: string;
  private _lastName: string;
  constructor() { }

  ngOnInit() {}

  get firstName(): string {
    return this._firstName;
  }

  @Input()
  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  @Input()
  set lastName(value: string) {
    this._lastName = value;
  }
}
