import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { CoreService } from 'src/app/shared-services/core.service';

@Component({
    selector: 'app-stacked-page',
    templateUrl: './stacked-page.component.html',
    styleUrls: ['./stacked-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StackedPageComponent<T> implements AfterViewInit {

  @Input()
  deletedFilterProp: string;
  @Input()
  keyProp: string;
  @Input()
  defaultItemImage: string = '/assets/avatar.svg';
  @Input()
  itemTemplate: TemplateRef<any>;
  @Input()
  itemTemplateExtra: TemplateRef<any>;
  @Input()
  items: Array<T>;
  @Input()
  foldersPage?: boolean;

  @ViewChild('headerSection')
  headerSection: ElementRef;
  @ViewChild('contentSection')
  contentSection: ElementRef;
  @ViewChild('footerSection')
  footerSection: ElementRef;

  contentMaxHeightOffset: number;

  @HostListener('window:resize')
  onResizeHandler(): void {
    const headerHeight = this.headerSection?.nativeElement?.offsetHeight;
    const footerHeight = this.footerSection?.nativeElement?.offsetHeight;
    this.contentMaxHeightOffset = headerHeight + footerHeight;
  }
  ngAfterViewInit() {
    this.onResizeHandler();
    setTimeout(() => this.onResizeHandler(), 500);
    setTimeout(() => this.onResizeHandler(), 1000);
  };

  @Output()
  itemClick: EventEmitter<T> = new EventEmitter<T>();
  @Output()
  selectedItemClick: EventEmitter<T> = new EventEmitter<T>();
  selectedItemData: T;
  @Output()
  selectedItemChange: EventEmitter<T> = new EventEmitter<T>();
  get selectedItem() { return this.selectedItemData; }
  @Input()
  set selectedItem(value: T) {
    this.selectedItemData = value;
    this.selectedItemChange.next(value);
  }
  @Output()
  selectedItemKeyChange: EventEmitter<any> = new EventEmitter<any>();
  get selectedItemKey() { return this.selectedItem?.[this.keyProp]; }
  @Input()
  set selectedItemKey(value: any) {
    this.selectedItem = this.items?.filter(itm => itm[this.keyProp] == value)[0];
    this.selectedItemKeyChange.next(value);
  }
  onItemClick(item: T, event: PointerEvent) {
    if (!(event.target as Element).classList.contains('clickAllowedSource')) return;
    if (this.selectedItem == item) {
      this.selectedItemClick.next(item);
    } else {
      this.selectedItem = item;
      this.itemClick.next(item);
    }
  }

  isSelected(item: T) {
    return (this.keyProp)
      ? this.selectedItem?.[this.keyProp] == item[this.keyProp]
      : this.selectedItem == item
    ;
  }

  constructor(
    private readonly logger: NGXLogger,
    public readonly core: CoreService,
  ) {
  }

}

