import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from '../../../../../../../../../shared-services/core.service';
import { GameDataService } from '../../../../../../../../../shared-services/game-data/game-data.service';
import { GameService } from '../../../../../../../../../shared-services/game/game.service';
import { PopoverController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HalfTime } from '../../../../../../../../../shared-services/timer/timer-model';
import { HalftimeTypesExtended } from '../../../../../../../../../shared-services/statistics/video/model/video-tracker.util';

@Component({
    selector: 'app-extra-time-penalty-popover',
    templateUrl: './extra-time-penalty-popover.component.html',
    styleUrls: ['./extra-time-penalty-popover.component.scss'],
    standalone: false
})
export class ExtraTimePenaltyPopoverComponent  implements OnInit {

  @Input() halfTime: HalftimeTypesExtended;

  constructor(
      public readonly _core: CoreService,
      private readonly gameDataService: GameDataService,
      private readonly gameService: GameService,
      private readonly toastCtrl: ToastController,
      private readonly router: Router,
      private readonly popoverCntl: PopoverController,
  ) { }

  ngOnInit() {}

  async onExtraTimeConfirm() {
    const data = {
      endGame: false
    };
    await this.popoverCntl.dismiss(data);
  }

  async onEndGameConfirm() {
    const data = {
      endGame: true
    };
    await this.popoverCntl.dismiss(data);
  }

}
