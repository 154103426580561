import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

type SystemPhaseSelectionProps =
  | 'allSystem'
  | 'superiority'
  | 'sup6vs5'
  | 'sup7vs6'
  | 'supOther'
  | 'equality'
  | 'eqef'
  | 'eq51vs6'
  | 'eq6vs51'
  | 'inferiority'
  | 'inf5vs6'
  | 'inf6vs7'
  | 'infOther'
  | 'allPhases'
  | 'positionalOffense'
  | 'fastBreak'
  | 'counterGoal'
  | 'allDeffenseSystems'
  | 'against60'
  | 'against51'
  | 'against321'
  | 'against42'
  | 'againstOthers'
  | 'important';
export type SystemPhaseSelection = {[key in SystemPhaseSelectionProps]: boolean};

@Component({
  selector: 'app-selector-check-box-list',
  templateUrl: './selector-check-box-list.component.html',
  styleUrls: ['./selector-check-box-list.component.scss'],
})
export class SelectorCheckBoxListComponent {

  @Input() showOffenseDefenseFilter = false;
  @Input() selectionOffenseEventsData: any;
  @Input() selectionDefenseEventsData: any;
  @Input() selectionTimeEventsData: any;
  @Input() availableEvents: string[] = [];
  @Input() availableOffenseSystem: string[] = [];
  @Input() availablePhases: string[] = [];
  @Input() availableDefenseSystem: string[] = [];

  public showTimeEvents = false;

  public selectionData: SystemPhaseSelection = {
    allSystem: true,
    superiority: true,
    sup6vs5: true,
    sup7vs6: true,
    supOther: true,
    equality: true,
    eqef: true,
    eq51vs6: true,
    eq6vs51: true,
    inferiority: true,
    inf5vs6: true,
    inf6vs7: true,
    infOther: true,

    important: false,

    allPhases: true,
    positionalOffense: true,
    fastBreak: true,
    counterGoal: true,

    allDeffenseSystems: true,
    against60: true,
    against51: true,
    against321: true,
    against42: true,
    againstOthers: true,
  };

  @Input()
  set selectionOffenseEvents(value: any) {
    if (!value) return;
    this.selectionOffenseEventsData = value;
  }
  get selectionOffenseEvents() {
    return this.selectionOffenseEventsData;
  }

  @Input()
  set selectionTimeEvents(value: any) {
    if (!value) return;
    this.selectionTimeEventsData = value;
  }
  get selectionTimeEvents() {
    return this.selectionTimeEventsData;
  }

  @Input()
  set selectionDefenseEvents(value: any) {
    if (!value) return;
    this.selectionDefenseEventsData = value;
  }
  get selectionDefenseEvents() {
    return this.selectionDefenseEventsData;
  }

  @Input()
  set selection(value: SystemPhaseSelection) {
    if (!value) return;
    this.selectionData = value;
  }
  get selection() {
    return this.selectionData;
  }
  @Output()
  selectionChange: EventEmitter<SystemPhaseSelection> = new EventEmitter<SystemPhaseSelection>();

  @Output() change: EventEmitter<SystemPhaseSelection> = new EventEmitter<SystemPhaseSelection>();

  constructor() {}

  public onToggleShowTimeEvents() {
    this.showTimeEvents = !this.showTimeEvents;
  }

  fireChanges(prop: SystemPhaseSelectionProps) {
    this.selection[prop] = !this.selection[prop];

    // Offense Systems
    if (['allSystem'].includes(prop)) {
      for (const p of ['superiority','sup6vs5','sup7vs6','supOther','equality','eqef','eq51vs6','eq6vs51','inferiority','inf5vs6','inf6vs7','infOther']) {
        this.selection[p] = this.selection[prop];
      }
    }
    if (['superiority'].includes(prop)) for (const p of ['sup6vs5','sup7vs6','supOther']) this.selection[p] = this.selection[prop];
    if (['equality'].includes(prop)) for (const p of ['eqef','eq51vs6','eq6vs51']) this.selection[p] = this.selection[prop];
    if (['inferiority'].includes(prop)) for (const p of ['inf5vs6','inf6vs7','infOther']) this.selection[p] = this.selection[prop];
    if ([
      'superiority','sup6vs5','sup7vs6','supOther','equality','eqef','eq51vs6','eq6vs51','inferiority','inf5vs6','inf6vs7','infOther',
    ].includes(prop)) this.selection.allSystem = false;
    if (['sup6vs5','sup7vs6','supOther'].includes(prop)) this.selection.superiority = false;
    if (['eqef','eq51vs6','eq6vs51'].includes(prop)) this.selection.equality = false;
    if (['inf5vs6','inf6vs7','infOther',].includes(prop)) this.selection.inferiority = false;

    // Phases
    if (['allPhases'].includes(prop))
      for (const p of ['positionalOffense', 'fastBreak', 'counterGoal']) this.selection[p] = this.selection[prop];
    if (['positionalOffense', 'fastBreak', 'counterGoal'].includes(prop)) this.selection.allPhases = false;

    // Deffense system
    if (['allDeffenseSystems'].includes(prop))
      for (const p of ['against60', 'against51', 'against321', 'against42', 'againstOthers']) this.selection[p] = this.selection[prop];
    if (['against60', 'against51', 'against321', 'against42', 'againstOthers'].includes(prop)) this.selection.allDeffenseSystems = false;

    if (this.selection['sup6vs5'] && this.selection['sup7vs6'] && this.selection['supOther']) {
      this.selection.superiority = true;
    }
    if (this.selection['eqef'] && this.selection['eq51vs6'] && this.selection['eq6vs51']) {
      this.selection.equality = true;
    }
    if (this.selection['inf5vs6'] && this.selection['inf6vs7'] && this.selection['infOther']) {
      this.selection.inferiority = true;
    }
    if (this.selection['positionalOffense'] && this.selection['fastBreak'] && this.selection['counterGoal']) {
      this.selection.allPhases = true;
    }
    if (this.selection['against60'] && this.selection['against51'] && this.selection['against321'] && this.selection['against42']
        && this.selection['againstOthers']) {
      this.selection.allDeffenseSystems = true;
    }
    if (this.selection['superiority'] && this.selection['equality'] && this.selection['inferiority']) {
      this.selection.allSystem = true;
    }

    this.selectionChange.next(this.selection);
    setTimeout(() => this.change.next({ ...this.selection }), 100);
  }

  fireTimeEventsChanges(prop: any) {
    this.selectionTimeEvents[prop] = !this.selectionTimeEvents[prop];
  }

  fireOffenseChanges(prop: any) {
    const offenseEvents = ['totalAttackFaultCommit','totalTechnicalMistakeCommit', 'totalLostBall', 'totalTwoMinProvoke', 'totalSevenMetersProvoke', 'totalSevenMSuspProvoke', 'totalOneAndOneWon','totalFoulReceive','totalShotBlocked','totalGoals','totalPostOut','totalFailedShots', 'totalAssist', 'firstHalfStart', 'firstHalfEnd', 'secondHalfStart', 'secondHalfEnd', 'timeOut'];
    this.selectionOffenseEvents[prop] = !this.selectionOffenseEvents[prop];
    if (['allOffenseEvents'].includes(prop)) {
      for (const p of offenseEvents) {
        this.selectionOffenseEvents[p] = this.selectionOffenseEvents[prop];
      }
    } else {
      if (!this.selectionOffenseEvents[prop]) {
        this.selectionOffenseEvents['allOffenseEvents'] = false;
      } else {
        let allTrue = true;
        for (const p of offenseEvents) {
          if (!this.selectionOffenseEvents[p]) {
            allTrue = false;
          }
        }
        if (allTrue) {
          this.selectionOffenseEvents['allOffenseEvents'] = true;
        }
      }
    }
  }

  fireDefenseChanges(prop: any) {
    this.selectionDefenseEvents[prop] = !this.selectionDefenseEvents[prop];
    const defenseEvents = ['totalAttackFaultProvoke', 'totalTechnicalMistakeProvoke', 'totalLostBallProvoke','totalTwoMinCommit','totalSevenMetersCommit','totalSevenMSuspCommit','totalOneAndOneLost','totalFoulCommit','totalShotBlock','totalReceivedGoals','totalPostOutReceived','totalSaves', 'firstHalfETStart', 'firstHalfETEnd', 'secondHalfETStart', 'secondHalfETEnd', 'penaltiesStart', 'penaltiesEnd'];
    if (['allDefenseEvents'].includes(prop)) {
      for (const p of defenseEvents) {
        this.selectionDefenseEvents[p] = this.selectionDefenseEvents[prop];
      }
    } else {
      if (!this.selectionDefenseEvents[prop]) {
        this.selectionDefenseEvents['allDefenseEvents'] = false;
      } else {
        let allTrue = true;
        for (const p of defenseEvents) {
          if (!this.selectionDefenseEvents[p]) {
            allTrue = false;
          }
        }
        if (allTrue) {
          this.selectionDefenseEvents['allDefenseEvents'] = true;
        }
      }
    }
  }
}
