/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BasicAccountEquipoDto } from '../model/models';
import { FilePartsConfirmDto } from '../model/models';
import { FilePropsDto } from '../model/models';
import { GameDto } from '../model/models';
import { GameFilterDto } from '../model/models';
import { GameFolderDto } from '../model/models';
import { GameSystemCategoryDto } from '../model/models';
import { GameSystemDto } from '../model/models';
import { GameSystemEnabledDto } from '../model/models';
import { GameTrackersDto } from '../model/models';
import { PlayByPlayDto } from '../model/models';
import { PlayTimeDto } from '../model/models';
import { SeasonDto } from '../model/models';
import { TeamDto } from '../model/models';
import { UpdateGameResultDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ExternalAccountEquiposService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param playByPlayDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerAddPlayByPlayRecords(uid: string, aid: string, gid: string, playByPlayDto: Array<PlayByPlayDto>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public accountEquipoHandlerControllerAddPlayByPlayRecords(uid: string, aid: string, gid: string, playByPlayDto: Array<PlayByPlayDto>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public accountEquipoHandlerControllerAddPlayByPlayRecords(uid: string, aid: string, gid: string, playByPlayDto: Array<PlayByPlayDto>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public accountEquipoHandlerControllerAddPlayByPlayRecords(uid: string, aid: string, gid: string, playByPlayDto: Array<PlayByPlayDto>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerAddPlayByPlayRecords.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerAddPlayByPlayRecords.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerAddPlayByPlayRecords.');
        }
        if (playByPlayDto === null || playByPlayDto === undefined) {
            throw new Error('Required parameter playByPlayDto was null or undefined when calling accountEquipoHandlerControllerAddPlayByPlayRecords.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}/pbp`,
            playByPlayDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param playTimeDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerAddPlayTimeRecords(uid: string, aid: string, gid: string, playTimeDto: Array<PlayTimeDto>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public accountEquipoHandlerControllerAddPlayTimeRecords(uid: string, aid: string, gid: string, playTimeDto: Array<PlayTimeDto>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public accountEquipoHandlerControllerAddPlayTimeRecords(uid: string, aid: string, gid: string, playTimeDto: Array<PlayTimeDto>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public accountEquipoHandlerControllerAddPlayTimeRecords(uid: string, aid: string, gid: string, playTimeDto: Array<PlayTimeDto>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerAddPlayTimeRecords.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerAddPlayTimeRecords.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerAddPlayTimeRecords.');
        }
        if (playTimeDto === null || playTimeDto === undefined) {
            throw new Error('Required parameter playTimeDto was null or undefined when calling accountEquipoHandlerControllerAddPlayTimeRecords.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}/ptm`,
            playTimeDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param pid 
     * @param tid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerChangePlayerTeam(uid: string, aid: string, pid: string, tid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public accountEquipoHandlerControllerChangePlayerTeam(uid: string, aid: string, pid: string, tid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public accountEquipoHandlerControllerChangePlayerTeam(uid: string, aid: string, pid: string, tid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public accountEquipoHandlerControllerChangePlayerTeam(uid: string, aid: string, pid: string, tid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerChangePlayerTeam.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerChangePlayerTeam.');
        }
        if (pid === null || pid === undefined) {
            throw new Error('Required parameter pid was null or undefined when calling accountEquipoHandlerControllerChangePlayerTeam.');
        }
        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling accountEquipoHandlerControllerChangePlayerTeam.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/player-transfer/${encodeURIComponent(String(pid))}/${encodeURIComponent(String(tid))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerCreateDefaultGameSystem(uid: string, aid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GameSystemCategoryDto>>;
    public accountEquipoHandlerControllerCreateDefaultGameSystem(uid: string, aid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerCreateDefaultGameSystem(uid: string, aid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerCreateDefaultGameSystem(uid: string, aid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerCreateDefaultGameSystem.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerCreateDefaultGameSystem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<GameSystemCategoryDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/default-game-system`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gscid 
     * @param gameSystemDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerCreateGameSystem(uid: string, aid: string, gscid: string, gameSystemDto: GameSystemDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GameSystemCategoryDto>>;
    public accountEquipoHandlerControllerCreateGameSystem(uid: string, aid: string, gscid: string, gameSystemDto: GameSystemDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerCreateGameSystem(uid: string, aid: string, gscid: string, gameSystemDto: GameSystemDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerCreateGameSystem(uid: string, aid: string, gscid: string, gameSystemDto: GameSystemDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerCreateGameSystem.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerCreateGameSystem.');
        }
        if (gscid === null || gscid === undefined) {
            throw new Error('Required parameter gscid was null or undefined when calling accountEquipoHandlerControllerCreateGameSystem.');
        }
        if (gameSystemDto === null || gameSystemDto === undefined) {
            throw new Error('Required parameter gameSystemDto was null or undefined when calling accountEquipoHandlerControllerCreateGameSystem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<GameSystemCategoryDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game-system-category/${encodeURIComponent(String(gscid))}/game-system`,
            gameSystemDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gameSystemCategoryDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerCreateGameSystemCategory(uid: string, aid: string, gameSystemCategoryDto: GameSystemCategoryDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GameSystemCategoryDto>>;
    public accountEquipoHandlerControllerCreateGameSystemCategory(uid: string, aid: string, gameSystemCategoryDto: GameSystemCategoryDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerCreateGameSystemCategory(uid: string, aid: string, gameSystemCategoryDto: GameSystemCategoryDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerCreateGameSystemCategory(uid: string, aid: string, gameSystemCategoryDto: GameSystemCategoryDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerCreateGameSystemCategory.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerCreateGameSystemCategory.');
        }
        if (gameSystemCategoryDto === null || gameSystemCategoryDto === undefined) {
            throw new Error('Required parameter gameSystemCategoryDto was null or undefined when calling accountEquipoHandlerControllerCreateGameSystemCategory.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<GameSystemCategoryDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game-system-category`,
            gameSystemCategoryDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param teamDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerCreateTeam(uid: string, aid: string, teamDto: TeamDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TeamDto>;
    public accountEquipoHandlerControllerCreateTeam(uid: string, aid: string, teamDto: TeamDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TeamDto>>;
    public accountEquipoHandlerControllerCreateTeam(uid: string, aid: string, teamDto: TeamDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TeamDto>>;
    public accountEquipoHandlerControllerCreateTeam(uid: string, aid: string, teamDto: TeamDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerCreateTeam.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerCreateTeam.');
        }
        if (teamDto === null || teamDto === undefined) {
            throw new Error('Required parameter teamDto was null or undefined when calling accountEquipoHandlerControllerCreateTeam.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<TeamDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/team`,
            teamDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerDeleteAllGameSystem(uid: string, aid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GameSystemCategoryDto>>;
    public accountEquipoHandlerControllerDeleteAllGameSystem(uid: string, aid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerDeleteAllGameSystem(uid: string, aid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerDeleteAllGameSystem(uid: string, aid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerDeleteAllGameSystem.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerDeleteAllGameSystem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<Array<GameSystemCategoryDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game-system`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerDeleteGame(uid: string, aid: string, gid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GameDto>;
    public accountEquipoHandlerControllerDeleteGame(uid: string, aid: string, gid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GameDto>>;
    public accountEquipoHandlerControllerDeleteGame(uid: string, aid: string, gid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GameDto>>;
    public accountEquipoHandlerControllerDeleteGame(uid: string, aid: string, gid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerDeleteGame.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerDeleteGame.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerDeleteGame.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<GameDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gscid 
     * @param gid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerDeleteGameSystem(uid: string, aid: string, gscid: string, gid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GameSystemCategoryDto>>;
    public accountEquipoHandlerControllerDeleteGameSystem(uid: string, aid: string, gscid: string, gid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerDeleteGameSystem(uid: string, aid: string, gscid: string, gid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerDeleteGameSystem(uid: string, aid: string, gscid: string, gid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerDeleteGameSystem.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerDeleteGameSystem.');
        }
        if (gscid === null || gscid === undefined) {
            throw new Error('Required parameter gscid was null or undefined when calling accountEquipoHandlerControllerDeleteGameSystem.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerDeleteGameSystem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<Array<GameSystemCategoryDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game-system-category/${encodeURIComponent(String(gscid))}/game-system/${encodeURIComponent(String(gid))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gscid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerDeleteGameSystemCategory(uid: string, aid: string, gscid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GameSystemCategoryDto>>;
    public accountEquipoHandlerControllerDeleteGameSystemCategory(uid: string, aid: string, gscid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerDeleteGameSystemCategory(uid: string, aid: string, gscid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerDeleteGameSystemCategory(uid: string, aid: string, gscid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerDeleteGameSystemCategory.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerDeleteGameSystemCategory.');
        }
        if (gscid === null || gscid === undefined) {
            throw new Error('Required parameter gscid was null or undefined when calling accountEquipoHandlerControllerDeleteGameSystemCategory.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<Array<GameSystemCategoryDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game-system-category/${encodeURIComponent(String(gscid))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerGetAccountEquipo(uid: string, aid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BasicAccountEquipoDto>;
    public accountEquipoHandlerControllerGetAccountEquipo(uid: string, aid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BasicAccountEquipoDto>>;
    public accountEquipoHandlerControllerGetAccountEquipo(uid: string, aid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BasicAccountEquipoDto>>;
    public accountEquipoHandlerControllerGetAccountEquipo(uid: string, aid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerGetAccountEquipo.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerGetAccountEquipo.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<BasicAccountEquipoDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerGetAccountEquipos(uid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<BasicAccountEquipoDto>>;
    public accountEquipoHandlerControllerGetAccountEquipos(uid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<BasicAccountEquipoDto>>>;
    public accountEquipoHandlerControllerGetAccountEquipos(uid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<BasicAccountEquipoDto>>>;
    public accountEquipoHandlerControllerGetAccountEquipos(uid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerGetAccountEquipos.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<BasicAccountEquipoDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerGetAccountGameSystemFlag(uid: string, aid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GameSystemEnabledDto>;
    public accountEquipoHandlerControllerGetAccountGameSystemFlag(uid: string, aid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GameSystemEnabledDto>>;
    public accountEquipoHandlerControllerGetAccountGameSystemFlag(uid: string, aid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GameSystemEnabledDto>>;
    public accountEquipoHandlerControllerGetAccountGameSystemFlag(uid: string, aid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerGetAccountGameSystemFlag.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerGetAccountGameSystemFlag.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<GameSystemEnabledDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game-system-flag`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerGetGame(uid: string, aid: string, gid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GameDto>;
    public accountEquipoHandlerControllerGetGame(uid: string, aid: string, gid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GameDto>>;
    public accountEquipoHandlerControllerGetGame(uid: string, aid: string, gid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GameDto>>;
    public accountEquipoHandlerControllerGetGame(uid: string, aid: string, gid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerGetGame.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerGetGame.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerGetGame.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<GameDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerGetGameSystem(uid: string, aid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GameSystemCategoryDto>>;
    public accountEquipoHandlerControllerGetGameSystem(uid: string, aid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerGetGameSystem(uid: string, aid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerGetGameSystem(uid: string, aid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerGetGameSystem.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerGetGameSystem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<GameSystemCategoryDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game-system`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerGetTeams(uid: string, aid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<TeamDto>>;
    public accountEquipoHandlerControllerGetTeams(uid: string, aid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<TeamDto>>>;
    public accountEquipoHandlerControllerGetTeams(uid: string, aid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<TeamDto>>>;
    public accountEquipoHandlerControllerGetTeams(uid: string, aid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerGetTeams.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerGetTeams.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<TeamDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/teams`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerGetTrackers(uid: string, aid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<TeamDto>>;
    public accountEquipoHandlerControllerGetTrackers(uid: string, aid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<TeamDto>>>;
    public accountEquipoHandlerControllerGetTrackers(uid: string, aid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<TeamDto>>>;
    public accountEquipoHandlerControllerGetTrackers(uid: string, aid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerGetTrackers.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerGetTrackers.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<TeamDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/trackers`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param newDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerPatchGameDate(uid: string, aid: string, gid: string, newDate: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GameDto>;
    public accountEquipoHandlerControllerPatchGameDate(uid: string, aid: string, gid: string, newDate: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GameDto>>;
    public accountEquipoHandlerControllerPatchGameDate(uid: string, aid: string, gid: string, newDate: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GameDto>>;
    public accountEquipoHandlerControllerPatchGameDate(uid: string, aid: string, gid: string, newDate: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerPatchGameDate.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerPatchGameDate.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerPatchGameDate.');
        }
        if (newDate === null || newDate === undefined) {
            throw new Error('Required parameter newDate was null or undefined when calling accountEquipoHandlerControllerPatchGameDate.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<GameDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}/date/${encodeURIComponent(String(newDate))}`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param gameTrackersDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerPatchGameTrackers(uid: string, aid: string, gid: string, gameTrackersDto: GameTrackersDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GameDto>;
    public accountEquipoHandlerControllerPatchGameTrackers(uid: string, aid: string, gid: string, gameTrackersDto: GameTrackersDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GameDto>>;
    public accountEquipoHandlerControllerPatchGameTrackers(uid: string, aid: string, gid: string, gameTrackersDto: GameTrackersDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GameDto>>;
    public accountEquipoHandlerControllerPatchGameTrackers(uid: string, aid: string, gid: string, gameTrackersDto: GameTrackersDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerPatchGameTrackers.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerPatchGameTrackers.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerPatchGameTrackers.');
        }
        if (gameTrackersDto === null || gameTrackersDto === undefined) {
            throw new Error('Required parameter gameTrackersDto was null or undefined when calling accountEquipoHandlerControllerPatchGameTrackers.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<GameDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}/trackers`,
            gameTrackersDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param sid 
     * @param aid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerReadGameFolders(uid: string, sid: string, aid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GameFolderDto>>;
    public accountEquipoHandlerControllerReadGameFolders(uid: string, sid: string, aid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GameFolderDto>>>;
    public accountEquipoHandlerControllerReadGameFolders(uid: string, sid: string, aid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GameFolderDto>>>;
    public accountEquipoHandlerControllerReadGameFolders(uid: string, sid: string, aid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerReadGameFolders.');
        }
        if (sid === null || sid === undefined) {
            throw new Error('Required parameter sid was null or undefined when calling accountEquipoHandlerControllerReadGameFolders.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerReadGameFolders.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<GameFolderDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/season/${encodeURIComponent(String(sid))}/game-folders`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param sid 
     * @param gameFolderId game folder id
     * @param firstInvolvedTeam first involved team
     * @param secondInvolvedTeam second involved team
     * @param date The date in Y-m-d format
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerReadGames(uid: string, aid: string, sid: string, gameFolderId?: string, firstInvolvedTeam?: string, secondInvolvedTeam?: string, date?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GameFilterDto>>;
    public accountEquipoHandlerControllerReadGames(uid: string, aid: string, sid: string, gameFolderId?: string, firstInvolvedTeam?: string, secondInvolvedTeam?: string, date?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GameFilterDto>>>;
    public accountEquipoHandlerControllerReadGames(uid: string, aid: string, sid: string, gameFolderId?: string, firstInvolvedTeam?: string, secondInvolvedTeam?: string, date?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GameFilterDto>>>;
    public accountEquipoHandlerControllerReadGames(uid: string, aid: string, sid: string, gameFolderId?: string, firstInvolvedTeam?: string, secondInvolvedTeam?: string, date?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerReadGames.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerReadGames.');
        }
        if (sid === null || sid === undefined) {
            throw new Error('Required parameter sid was null or undefined when calling accountEquipoHandlerControllerReadGames.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (gameFolderId !== undefined && gameFolderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gameFolderId, 'gameFolderId');
        }
        if (firstInvolvedTeam !== undefined && firstInvolvedTeam !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>firstInvolvedTeam, 'firstInvolvedTeam');
        }
        if (secondInvolvedTeam !== undefined && secondInvolvedTeam !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>secondInvolvedTeam, 'secondInvolvedTeam');
        }
        if (date !== undefined && date !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>date, 'date');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<GameFilterDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/season/${encodeURIComponent(String(sid))}/games`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerReadScheduledGames(uid: string, aid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GameFilterDto>>;
    public accountEquipoHandlerControllerReadScheduledGames(uid: string, aid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GameFilterDto>>>;
    public accountEquipoHandlerControllerReadScheduledGames(uid: string, aid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GameFilterDto>>>;
    public accountEquipoHandlerControllerReadScheduledGames(uid: string, aid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerReadScheduledGames.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerReadScheduledGames.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<GameFilterDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/games/scheduled`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerReadSeasons(uid: string, aid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<SeasonDto>>;
    public accountEquipoHandlerControllerReadSeasons(uid: string, aid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<SeasonDto>>>;
    public accountEquipoHandlerControllerReadSeasons(uid: string, aid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<SeasonDto>>>;
    public accountEquipoHandlerControllerReadSeasons(uid: string, aid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerReadSeasons.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerReadSeasons.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SeasonDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/seasons`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerRequestCsvConsume(uid: string, aid: string, gid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public accountEquipoHandlerControllerRequestCsvConsume(uid: string, aid: string, gid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public accountEquipoHandlerControllerRequestCsvConsume(uid: string, aid: string, gid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public accountEquipoHandlerControllerRequestCsvConsume(uid: string, aid: string, gid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerRequestCsvConsume.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerRequestCsvConsume.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerRequestCsvConsume.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}/request-csv-consume`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param gid 
     * @param token 
     * @param confirm 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerRequestCsvReply(gid: string, token: string, confirm: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public accountEquipoHandlerControllerRequestCsvReply(gid: string, token: string, confirm: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public accountEquipoHandlerControllerRequestCsvReply(gid: string, token: string, confirm: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public accountEquipoHandlerControllerRequestCsvReply(gid: string, token: string, confirm: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerRequestCsvReply.');
        }
        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling accountEquipoHandlerControllerRequestCsvReply.');
        }
        if (confirm === null || confirm === undefined) {
            throw new Error('Required parameter confirm was null or undefined when calling accountEquipoHandlerControllerRequestCsvReply.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/v1/account-equipos/game/${encodeURIComponent(String(gid))}/request-csv-reply/${encodeURIComponent(String(token))}/${encodeURIComponent(String(confirm))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerRequestGameCsv(uid: string, aid: string, gid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public accountEquipoHandlerControllerRequestGameCsv(uid: string, aid: string, gid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public accountEquipoHandlerControllerRequestGameCsv(uid: string, aid: string, gid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public accountEquipoHandlerControllerRequestGameCsv(uid: string, aid: string, gid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerRequestGameCsv.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerRequestGameCsv.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerRequestGameCsv.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}/request-csv`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gameDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerSaveGame(uid: string, aid: string, gameDto: GameDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GameDto>;
    public accountEquipoHandlerControllerSaveGame(uid: string, aid: string, gameDto: GameDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GameDto>>;
    public accountEquipoHandlerControllerSaveGame(uid: string, aid: string, gameDto: GameDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GameDto>>;
    public accountEquipoHandlerControllerSaveGame(uid: string, aid: string, gameDto: GameDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerSaveGame.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerSaveGame.');
        }
        if (gameDto === null || gameDto === undefined) {
            throw new Error('Required parameter gameDto was null or undefined when calling accountEquipoHandlerControllerSaveGame.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<GameDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game`,
            gameDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param sid 
     * @param gameFolderDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerSaveGameFolder(uid: string, aid: string, sid: string, gameFolderDto: GameFolderDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GameFolderDto>;
    public accountEquipoHandlerControllerSaveGameFolder(uid: string, aid: string, sid: string, gameFolderDto: GameFolderDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GameFolderDto>>;
    public accountEquipoHandlerControllerSaveGameFolder(uid: string, aid: string, sid: string, gameFolderDto: GameFolderDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GameFolderDto>>;
    public accountEquipoHandlerControllerSaveGameFolder(uid: string, aid: string, sid: string, gameFolderDto: GameFolderDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerSaveGameFolder.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerSaveGameFolder.');
        }
        if (sid === null || sid === undefined) {
            throw new Error('Required parameter sid was null or undefined when calling accountEquipoHandlerControllerSaveGameFolder.');
        }
        if (gameFolderDto === null || gameFolderDto === undefined) {
            throw new Error('Required parameter gameFolderDto was null or undefined when calling accountEquipoHandlerControllerSaveGameFolder.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<GameFolderDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/season/${encodeURIComponent(String(sid))}/game-folder`,
            gameFolderDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param seasonDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerSaveSeason(uid: string, aid: string, seasonDto: SeasonDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SeasonDto>;
    public accountEquipoHandlerControllerSaveSeason(uid: string, aid: string, seasonDto: SeasonDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SeasonDto>>;
    public accountEquipoHandlerControllerSaveSeason(uid: string, aid: string, seasonDto: SeasonDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SeasonDto>>;
    public accountEquipoHandlerControllerSaveSeason(uid: string, aid: string, seasonDto: SeasonDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerSaveSeason.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerSaveSeason.');
        }
        if (seasonDto === null || seasonDto === undefined) {
            throw new Error('Required parameter seasonDto was null or undefined when calling accountEquipoHandlerControllerSaveSeason.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<SeasonDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/season`,
            seasonDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param filePropsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerStartUploadGameVideo(uid: string, aid: string, gid: string, filePropsDto: FilePropsDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GameDto>;
    public accountEquipoHandlerControllerStartUploadGameVideo(uid: string, aid: string, gid: string, filePropsDto: FilePropsDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GameDto>>;
    public accountEquipoHandlerControllerStartUploadGameVideo(uid: string, aid: string, gid: string, filePropsDto: FilePropsDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GameDto>>;
    public accountEquipoHandlerControllerStartUploadGameVideo(uid: string, aid: string, gid: string, filePropsDto: FilePropsDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerStartUploadGameVideo.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerStartUploadGameVideo.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerStartUploadGameVideo.');
        }
        if (filePropsDto === null || filePropsDto === undefined) {
            throw new Error('Required parameter filePropsDto was null or undefined when calling accountEquipoHandlerControllerStartUploadGameVideo.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<GameDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}/video/start-upload`,
            filePropsDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gameSystemEnabledDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerUpdateAccountGameSystemFlag(uid: string, aid: string, gameSystemEnabledDto: GameSystemEnabledDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GameSystemEnabledDto>;
    public accountEquipoHandlerControllerUpdateAccountGameSystemFlag(uid: string, aid: string, gameSystemEnabledDto: GameSystemEnabledDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GameSystemEnabledDto>>;
    public accountEquipoHandlerControllerUpdateAccountGameSystemFlag(uid: string, aid: string, gameSystemEnabledDto: GameSystemEnabledDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GameSystemEnabledDto>>;
    public accountEquipoHandlerControllerUpdateAccountGameSystemFlag(uid: string, aid: string, gameSystemEnabledDto: GameSystemEnabledDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerUpdateAccountGameSystemFlag.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerUpdateAccountGameSystemFlag.');
        }
        if (gameSystemEnabledDto === null || gameSystemEnabledDto === undefined) {
            throw new Error('Required parameter gameSystemEnabledDto was null or undefined when calling accountEquipoHandlerControllerUpdateAccountGameSystemFlag.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<GameSystemEnabledDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game-system-flag`,
            gameSystemEnabledDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param sid 
     * @param fid 
     * @param gameFolderDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerUpdateGameFolder(uid: string, aid: string, sid: string, fid: string, gameFolderDto: GameFolderDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GameFolderDto>;
    public accountEquipoHandlerControllerUpdateGameFolder(uid: string, aid: string, sid: string, fid: string, gameFolderDto: GameFolderDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GameFolderDto>>;
    public accountEquipoHandlerControllerUpdateGameFolder(uid: string, aid: string, sid: string, fid: string, gameFolderDto: GameFolderDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GameFolderDto>>;
    public accountEquipoHandlerControllerUpdateGameFolder(uid: string, aid: string, sid: string, fid: string, gameFolderDto: GameFolderDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerUpdateGameFolder.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerUpdateGameFolder.');
        }
        if (sid === null || sid === undefined) {
            throw new Error('Required parameter sid was null or undefined when calling accountEquipoHandlerControllerUpdateGameFolder.');
        }
        if (fid === null || fid === undefined) {
            throw new Error('Required parameter fid was null or undefined when calling accountEquipoHandlerControllerUpdateGameFolder.');
        }
        if (gameFolderDto === null || gameFolderDto === undefined) {
            throw new Error('Required parameter gameFolderDto was null or undefined when calling accountEquipoHandlerControllerUpdateGameFolder.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<GameFolderDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/season/${encodeURIComponent(String(sid))}/game-folder/${encodeURIComponent(String(fid))}`,
            gameFolderDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param updateGameResultDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerUpdateGameResult(uid: string, aid: string, gid: string, updateGameResultDto: UpdateGameResultDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public accountEquipoHandlerControllerUpdateGameResult(uid: string, aid: string, gid: string, updateGameResultDto: UpdateGameResultDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public accountEquipoHandlerControllerUpdateGameResult(uid: string, aid: string, gid: string, updateGameResultDto: UpdateGameResultDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public accountEquipoHandlerControllerUpdateGameResult(uid: string, aid: string, gid: string, updateGameResultDto: UpdateGameResultDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerUpdateGameResult.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerUpdateGameResult.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerUpdateGameResult.');
        }
        if (updateGameResultDto === null || updateGameResultDto === undefined) {
            throw new Error('Required parameter updateGameResultDto was null or undefined when calling accountEquipoHandlerControllerUpdateGameResult.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}/result`,
            updateGameResultDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param gscid 
     * @param gameSystemDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerUpdateGameSystem(uid: string, aid: string, gid: string, gscid: string, gameSystemDto: GameSystemDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GameSystemCategoryDto>>;
    public accountEquipoHandlerControllerUpdateGameSystem(uid: string, aid: string, gid: string, gscid: string, gameSystemDto: GameSystemDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerUpdateGameSystem(uid: string, aid: string, gid: string, gscid: string, gameSystemDto: GameSystemDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerUpdateGameSystem(uid: string, aid: string, gid: string, gscid: string, gameSystemDto: GameSystemDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerUpdateGameSystem.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerUpdateGameSystem.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerUpdateGameSystem.');
        }
        if (gscid === null || gscid === undefined) {
            throw new Error('Required parameter gscid was null or undefined when calling accountEquipoHandlerControllerUpdateGameSystem.');
        }
        if (gameSystemDto === null || gameSystemDto === undefined) {
            throw new Error('Required parameter gameSystemDto was null or undefined when calling accountEquipoHandlerControllerUpdateGameSystem.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<Array<GameSystemCategoryDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game-system-category/${encodeURIComponent(String(gscid))}/game-system/${encodeURIComponent(String(gid))}`,
            gameSystemDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gscid 
     * @param gameSystemCategoryDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerUpdateGameSystemCategory(uid: string, aid: string, gscid: string, gameSystemCategoryDto: GameSystemCategoryDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GameSystemCategoryDto>>;
    public accountEquipoHandlerControllerUpdateGameSystemCategory(uid: string, aid: string, gscid: string, gameSystemCategoryDto: GameSystemCategoryDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerUpdateGameSystemCategory(uid: string, aid: string, gscid: string, gameSystemCategoryDto: GameSystemCategoryDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GameSystemCategoryDto>>>;
    public accountEquipoHandlerControllerUpdateGameSystemCategory(uid: string, aid: string, gscid: string, gameSystemCategoryDto: GameSystemCategoryDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerUpdateGameSystemCategory.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerUpdateGameSystemCategory.');
        }
        if (gscid === null || gscid === undefined) {
            throw new Error('Required parameter gscid was null or undefined when calling accountEquipoHandlerControllerUpdateGameSystemCategory.');
        }
        if (gameSystemCategoryDto === null || gameSystemCategoryDto === undefined) {
            throw new Error('Required parameter gameSystemCategoryDto was null or undefined when calling accountEquipoHandlerControllerUpdateGameSystemCategory.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<Array<GameSystemCategoryDto>>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game-system-category/${encodeURIComponent(String(gscid))}`,
            gameSystemCategoryDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param playByPlayDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerUpdatePlayByPlayRecords(uid: string, aid: string, gid: string, playByPlayDto: Array<PlayByPlayDto>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public accountEquipoHandlerControllerUpdatePlayByPlayRecords(uid: string, aid: string, gid: string, playByPlayDto: Array<PlayByPlayDto>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public accountEquipoHandlerControllerUpdatePlayByPlayRecords(uid: string, aid: string, gid: string, playByPlayDto: Array<PlayByPlayDto>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public accountEquipoHandlerControllerUpdatePlayByPlayRecords(uid: string, aid: string, gid: string, playByPlayDto: Array<PlayByPlayDto>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerUpdatePlayByPlayRecords.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerUpdatePlayByPlayRecords.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerUpdatePlayByPlayRecords.');
        }
        if (playByPlayDto === null || playByPlayDto === undefined) {
            throw new Error('Required parameter playByPlayDto was null or undefined when calling accountEquipoHandlerControllerUpdatePlayByPlayRecords.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}/pbp`,
            playByPlayDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param sid 
     * @param seasonDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerUpdateSeason(uid: string, aid: string, sid: string, seasonDto: SeasonDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SeasonDto>;
    public accountEquipoHandlerControllerUpdateSeason(uid: string, aid: string, sid: string, seasonDto: SeasonDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SeasonDto>>;
    public accountEquipoHandlerControllerUpdateSeason(uid: string, aid: string, sid: string, seasonDto: SeasonDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SeasonDto>>;
    public accountEquipoHandlerControllerUpdateSeason(uid: string, aid: string, sid: string, seasonDto: SeasonDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerUpdateSeason.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerUpdateSeason.');
        }
        if (sid === null || sid === undefined) {
            throw new Error('Required parameter sid was null or undefined when calling accountEquipoHandlerControllerUpdateSeason.');
        }
        if (seasonDto === null || seasonDto === undefined) {
            throw new Error('Required parameter seasonDto was null or undefined when calling accountEquipoHandlerControllerUpdateSeason.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<SeasonDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/season/${encodeURIComponent(String(sid))}`,
            seasonDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param tid 
     * @param teamDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerUpdateTeam(uid: string, aid: string, tid: string, teamDto: TeamDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TeamDto>;
    public accountEquipoHandlerControllerUpdateTeam(uid: string, aid: string, tid: string, teamDto: TeamDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TeamDto>>;
    public accountEquipoHandlerControllerUpdateTeam(uid: string, aid: string, tid: string, teamDto: TeamDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TeamDto>>;
    public accountEquipoHandlerControllerUpdateTeam(uid: string, aid: string, tid: string, teamDto: TeamDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerUpdateTeam.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerUpdateTeam.');
        }
        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling accountEquipoHandlerControllerUpdateTeam.');
        }
        if (teamDto === null || teamDto === undefined) {
            throw new Error('Required parameter teamDto was null or undefined when calling accountEquipoHandlerControllerUpdateTeam.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<TeamDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/team/${encodeURIComponent(String(tid))}`,
            teamDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param uploadId 
     * @param partId 
     * @param filePropsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerUploadGameVideo(uid: string, aid: string, gid: string, uploadId: string, partId: string, filePropsDto: FilePropsDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GameDto>;
    public accountEquipoHandlerControllerUploadGameVideo(uid: string, aid: string, gid: string, uploadId: string, partId: string, filePropsDto: FilePropsDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GameDto>>;
    public accountEquipoHandlerControllerUploadGameVideo(uid: string, aid: string, gid: string, uploadId: string, partId: string, filePropsDto: FilePropsDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GameDto>>;
    public accountEquipoHandlerControllerUploadGameVideo(uid: string, aid: string, gid: string, uploadId: string, partId: string, filePropsDto: FilePropsDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerUploadGameVideo.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerUploadGameVideo.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerUploadGameVideo.');
        }
        if (uploadId === null || uploadId === undefined) {
            throw new Error('Required parameter uploadId was null or undefined when calling accountEquipoHandlerControllerUploadGameVideo.');
        }
        if (partId === null || partId === undefined) {
            throw new Error('Required parameter partId was null or undefined when calling accountEquipoHandlerControllerUploadGameVideo.');
        }
        if (filePropsDto === null || filePropsDto === undefined) {
            throw new Error('Required parameter filePropsDto was null or undefined when calling accountEquipoHandlerControllerUploadGameVideo.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<GameDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}/video/${encodeURIComponent(String(uploadId))}/${encodeURIComponent(String(partId))}`,
            filePropsDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param filePartsConfirmDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerUploadGameVideoConfirm(uid: string, aid: string, gid: string, filePartsConfirmDto: FilePartsConfirmDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GameDto>;
    public accountEquipoHandlerControllerUploadGameVideoConfirm(uid: string, aid: string, gid: string, filePartsConfirmDto: FilePartsConfirmDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GameDto>>;
    public accountEquipoHandlerControllerUploadGameVideoConfirm(uid: string, aid: string, gid: string, filePartsConfirmDto: FilePartsConfirmDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GameDto>>;
    public accountEquipoHandlerControllerUploadGameVideoConfirm(uid: string, aid: string, gid: string, filePartsConfirmDto: FilePartsConfirmDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerUploadGameVideoConfirm.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerUploadGameVideoConfirm.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerUploadGameVideoConfirm.');
        }
        if (filePartsConfirmDto === null || filePartsConfirmDto === undefined) {
            throw new Error('Required parameter filePartsConfirmDto was null or undefined when calling accountEquipoHandlerControllerUploadGameVideoConfirm.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<GameDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}/video/confirm-upload`,
            filePartsConfirmDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param uid 
     * @param aid 
     * @param gid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEquipoHandlerControllerUploadGameVideoScheduleCut(uid: string, aid: string, gid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GameDto>;
    public accountEquipoHandlerControllerUploadGameVideoScheduleCut(uid: string, aid: string, gid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GameDto>>;
    public accountEquipoHandlerControllerUploadGameVideoScheduleCut(uid: string, aid: string, gid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GameDto>>;
    public accountEquipoHandlerControllerUploadGameVideoScheduleCut(uid: string, aid: string, gid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling accountEquipoHandlerControllerUploadGameVideoScheduleCut.');
        }
        if (aid === null || aid === undefined) {
            throw new Error('Required parameter aid was null or undefined when calling accountEquipoHandlerControllerUploadGameVideoScheduleCut.');
        }
        if (gid === null || gid === undefined) {
            throw new Error('Required parameter gid was null or undefined when calling accountEquipoHandlerControllerUploadGameVideoScheduleCut.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<GameDto>(`${this.configuration.basePath}/api/v1/account-equipos/user/${encodeURIComponent(String(uid))}/account-equipo/${encodeURIComponent(String(aid))}/game/${encodeURIComponent(String(gid))}/video/schedule-cut`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
