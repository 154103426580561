import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { EasyPlayerEvent } from '@handballai/stats-calculation';
import { BehaviorSubject } from 'rxjs';
import { CoreService } from 'src/app/shared-services/core.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerEventListConsumerService {

  constructor(
      private readonly logger: NGXLogger
  ) { }

  get selectedPlayerEventList$(): BehaviorSubject<EasyPlayerEvent[]> {
    return this._selectedPlayerEventList$;
  }
  private _core: CoreService;

  private _playerEventList: EasyPlayerEvent[] = [];
  private _selectedPlayerEventList$ = new BehaviorSubject<EasyPlayerEvent[]>([]);
  initCore(core: CoreService) {
      this._core = core;
  }


  public init(): void {
    this._playerEventList = [];
    this._selectedPlayerEventList$.next([]);
  }

  public addPlayerEvent(playerEvent: EasyPlayerEvent): void {
    this.logger.debug('PlayerEventListConsumerService.addPlayerEvent', playerEvent);
    this._playerEventList = [...this._playerEventList, playerEvent];
  }

  public selectPlayer(playerId: number): void {
    this._selectedPlayerEventList$.next([...this._playerEventList.filter(pl => pl.playerId === playerId)]);
  }

  public deSelectPlayer(): void {
    this._selectedPlayerEventList$.next([]);
  }
}
