import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SeasonDto } from 'src/app/api/hai-api';
import { CoreService } from 'src/app/shared-services/core.service';

@Component({
  selector: 'app-seasons-select',
  templateUrl: './seasons-select.component.html',
  styleUrls: ['./seasons-select.component.scss'],
})
export class SeasonsSelectComponent implements OnInit {

  private seasonsData: SeasonDto|SeasonDto[];

  @Input()
  multiple = true;

  @Input()
  set seasons(value: SeasonDto|SeasonDto[]) {
    if (this.seasonsData != value) {
      this.seasonsData = value;
      this.seasonsChange.emit(this.seasonsData);
    }
  }
  get seasons() { return this.seasonsData; }

  @Output()
  seasonsChange = new EventEmitter<SeasonDto|SeasonDto[]>();
  @Output()
  onChange = new EventEmitter<SeasonDto|SeasonDto[]>();

  seasons$: Observable<SeasonDto[]>;

  constructor(public core: CoreService) {
    this.seasons$ = core.seasonService.seasons$;
  }

  ngOnInit() {
    this.core.seasonService.seasons$?.subscribe(seasons => {
      if (seasons?.length === 1 && !this.seasonsData) {
        this.seasonsData = seasons;
        this.seasonsChange.emit(this.seasonsData);
      }
    });
  }

  onSeasonSelect() {
    this.onChange.emit(this.seasonsData);
  }

  async changeCurrentSeason(newCurrentSeason: SeasonDto) {
    for (const s of this.core.seasonService.seasons$.getValue()) if (s.isCurrent) {
      await this.core.seasonService.updateSeason({...s, isCurrent: false});
    }
    await this.core.seasonService.updateSeason({...newCurrentSeason, isCurrent: true});
  }

}
