import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';
import { CoreService } from 'src/app/shared-services/core.service';
import {
  SelectFolderForUploadModalComponent
} from '../../pages/players-module/components/select-folder-for-upload-modal/select-folder-for-upload-modal.component';
import { MyTeamFileDto, MyTeamFilesDto, MyTeamFolderDto } from '../../../api/hai-players-api';
import { PlayersService } from '../../pages/players-module/players.service';

@Component({
    selector: 'page-pdf-viewer',
    templateUrl: 'pdf-viewer.html',
    styleUrls: ['pdf-viewer.scss'],
    standalone: false
})
export class PdfViewerPage {
  title = "PDF Generated";
  pdfurl: string = '';
  blob: Blob;

  fileName: string = '';
  folders: MyTeamFolderDto[] = [];

  constructor(public core: CoreService, private modalCtrl: ModalController, navParams: NavParams, private playersService: PlayersService) {
    let title = navParams.get('title');
    this.folders = this.playersService.myTeamFolders$.getValue();
    if (title) this.title = title;
    let url = null;
    if (url = navParams.get('url')) {
      this.pdfurl = url;
    } else if (!navParams.get('data')) {
      this.dismiss()
    } else {
      this.blob = PdfViewerPage.b64toBlob(navParams.get('data'), "application/pdf", 512);
      this.pdfurl = window.URL.createObjectURL(this.blob);
    }

    if (navParams?.data?.fileName) {
      this.fileName = navParams.data.fileName;
    }
  }

  public async onUploadFileClick() {
    const accountEquipos = await this.core.accountEquipoService.accountEquipos$.getValue();
    const accountsWithManageMyTeamPermission = accountEquipos.filter(acc => acc.grants?.includes('manage_my_team') && acc.account.permissions.accessMyTeam);
    if (!accountsWithManageMyTeamPermission.length) {
      this.core.navCtrl.navigateForward('/my-team');
      this.dismiss();
    } else {
      const accountsPopover = await this.core.popoverCtrl.create({
        component: SelectFolderForUploadModalComponent,
        cssClass: 'select-folder-popover',
        mode: 'ios',
        componentProps: {
          accounts: accountsWithManageMyTeamPermission
        },
      });
      accountsPopover.onDidDismiss().then(async (accountsModalData: any) => {
        if (accountsModalData.data?.accountEquipoId) {
          const accountEquipoId = accountsModalData.data.accountEquipoId;
          const file: MyTeamFileDto = {
            name: this.fileName ? this.fileName + '-statistics.pdf' : new Date().getTime() + '-statistics.pdf',
            folderId: accountsModalData.data.folder.id,
            s3Url: 'EMPTY URL',
            type: 'Document'
          };
          await this.playersService.addNewFile(accountsModalData.data.folder.id, file, this.blob, accountEquipoId);
        }
      });
      await accountsPopover.present();
    }
  }

  public static b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

}
