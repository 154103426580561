import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VideoDownloadService {

    constructor(private http: HttpClient) { }

    downloadVideo(videoUrl: string): Observable<Blob> {
        return this.http.get(videoUrl, { responseType: 'blob' });
    }

}
