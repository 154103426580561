<div class="accordion">
  <div class="accordion-header" (click)="toggleAccordion()">
    <span class="chevron">{{ isOpen ? '▼' : '▶' }}</span>
    <span class="title">{{ title }}</span>
    <span class="description">{{ description }}</span>
  </div>
  <div class="accordion-content" *ngIf="isOpen">
    <ng-content></ng-content>
  </div>
</div>
