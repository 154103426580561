import { Component, Input, OnInit } from '@angular/core';
import {
  GameSystemPlayerStatsViewModel,
  GameSystemStatsViewModel
} from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-view.model';

@Component({
  selector: 'app-game-system-stats-container',
  templateUrl: './game-system-stats-container.component.html',
  styleUrls: ['./game-system-stats-container.component.scss'],
})
export class GameSystemStatsContainerComponent implements OnInit {

  public selectedTab = 'TEAM STATS';
  private _homeTeamName = '';
  private _homeGameSystemStatsView: GameSystemStatsViewModel[] = [];
  private _homePlayerGameSystemStatsView: GameSystemPlayerStatsViewModel[] = [];
  private _visitorTeamName = '';
  private _visitorGameSystemStatsView: GameSystemStatsViewModel[] = [];
  private _visitorPlayerGameSystemStatsView: GameSystemPlayerStatsViewModel[] = [];

  constructor() { }

  ngOnInit() {}

  get homeTeamName(): string {
    return this._homeTeamName;
  }

  @Input()
  set homeTeamName(value: string) {
    this._homeTeamName = value;
  }

  get homeGameSystemStatsView(): GameSystemStatsViewModel[] {
    return this._homeGameSystemStatsView;
  }

  @Input()
  set homeGameSystemStatsView(value: GameSystemStatsViewModel[]) {
    this._homeGameSystemStatsView = value;

  }

  get visitorTeamName(): string {
    return this._visitorTeamName;
  }

  @Input()
  set visitorTeamName(value: string) {
    this._visitorTeamName = value;
  }

  get visitorGameSystemStatsView(): GameSystemStatsViewModel[] {
    return this._visitorGameSystemStatsView;
  }

  @Input()
  set visitorGameSystemStatsView(value: GameSystemStatsViewModel[]) {
    this._visitorGameSystemStatsView = value;
  }

  get homePlayerGameSystemStatsView(): GameSystemPlayerStatsViewModel[] {
    return this._homePlayerGameSystemStatsView;
  }

  @Input()
  set homePlayerGameSystemStatsView(value: GameSystemPlayerStatsViewModel[]) {
    this._homePlayerGameSystemStatsView = value;
  }

  get visitorPlayerGameSystemStatsView(): GameSystemPlayerStatsViewModel[] {
    return this._visitorPlayerGameSystemStatsView;
  }

  @Input()
  set visitorPlayerGameSystemStatsView(value: GameSystemPlayerStatsViewModel[]) {
    this._visitorPlayerGameSystemStatsView = value;
  }
}
