/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlayerDto } from './playerDto';


export interface TeamDto { 
    /**
     * player id
     */
    id?: number;
    /**
     * Name of the team
     */
    name: string;
    /**
     * Division of the team
     */
    division: string;
    /**
     * Gender of the team
     */
    gender: string;
    /**
     * the account equipo assigned to the team
     */
    accountEquipoId: number;
    /**
     * internal team id, needs to be unique
     */
    internalTeamId: number;
    /**
     * Country Code (Alpha 2 Code)
     */
    country: string;
    /**
     * team shield url
     */
    shieldUrl?: string;
    /**
     * primary color
     */
    primaryColor?: string;
    /**
     * secondary color
     */
    secondaryColor?: string;
    /**
     * Players of the team
     */
    players: Array<PlayerDto>;
    /**
     * Deleted flag of the team
     */
    deleted: boolean;
}

