import { Injectable } from '@angular/core';
import { countrySet } from './country-set';
import { DivisionModel } from '../model/division.model';
import { GenderModel } from '../model/gender.model';
import { PositionModel } from '../model/position.model';
import { CoreService } from '../core.service';

@Injectable({
  providedIn: 'root'
})
export class BasicDataService {


  constructor() { }

  get countries(): {name: string, alpha2Code: string}[] {
    return countrySet;
  }

  get divisions(): DivisionModel[] {
    return this._divisions;
  }

  get genders(): GenderModel[] {
    return this._genders;
  }

  get positions(): PositionModel[] {
    return this._positions;
  }
  private _core: CoreService;


  private _divisions: DivisionModel[] = [
    {name: 'Division 1', code: 'div1'},
    {name: 'Division 2', code: 'div2'},
    {name: 'Division 3', code: 'div3'},
    {name: 'Division 4', code: 'div4'},
    {name: 'Other', code: 'other'}
  ];

  private _genders: GenderModel[] = [
    {name: 'Male', code: 'male'},
    {name: 'Female', code: 'female'}
  ];

  private _positions: PositionModel[] = [
    {name: 'Center', code: 'cb'},
    {name: 'Goalkeeper', code: 'gk'},
    {name: 'Leftback', code: 'lb'},
    {name: 'Leftwing', code: 'lw'},
    {name: 'Lineplayer', code: 'lp'},
    {name: 'Rightback', code: 'rb'},
    {name: 'Rightwing', code: 'rw'}
  ];

initCore(core: CoreService) {
      this._core = core;
  }
}
