import { Cell, Columns, Img, Table, Txt } from "pdfmake-wrapper";

export interface GoalTimeLineCombined {
  time: number;
  accumulated: number;
  visitorTeam?: boolean;
}

export const playerTableColumnsHelper = [
  { prop: 'playerName', name: 'Players' },
  { prop: 'minutes10Bucket', name: '10 min' },
  { prop: 'minutes20Bucket', name: '20 min' },
  { prop: 'minutes25Bucket', name: '25 min' },
  { prop: 'minutes30Bucket', name: '30 min' },
  { prop: 'extra1Bucket', name: 'OT' },
  { prop: 'minutes40Bucket', name: '40 min' },
  { prop: 'minutes50Bucket', name: '50 min' },
  { prop: 'minutes55Bucket', name: '55 min' },
  { prop: 'minutes60Bucket', name: '60 min' },
  { prop: 'extra2Bucket', name: 'OT' },
  { prop: 'percentageLostBalls', name: 'LB%' },
  { prop: 'percentageEfficiency', name: 'EFF%' },
  { prop: 'percentageShotEfficiency', name: 'SHOT%' },
  { prop: 'suspensions2Minutes', name: '2min' },
  { prop: 'assists', name: 'A' },
];
export const playerTableMobileColumnsHelper = [
  { prop: 'playerName', name: 'Players' },
  { prop: 'percentageLostBalls', name: 'LB%' },
  { prop: 'percentageEfficiency', name: 'EFF%' },
  { prop: 'percentageShotEfficiency', name: 'SHOT%' },
  { prop: 'suspensions2Minutes', name: '2min' },
];

export const playerTableColumnsWithETHelper = [
  { prop: 'playerName', name: 'Players' },
  { prop: 'minutes10Bucket', name: '10 min' },
  { prop: 'minutes20Bucket', name: '20 min' },
  { prop: 'minutes25Bucket', name: '25 min' },
  { prop: 'minutes30Bucket', name: '30 min' },
  { prop: 'extra1Bucket', name: 'OT' },
  { prop: 'minutes40Bucket', name: '40 min' },
  { prop: 'minutes50Bucket', name: '50 min' },
  { prop: 'minutes55Bucket', name: '55 min' },
  { prop: 'minutes60Bucket', name: '60 min' },
  { prop: 'extra2Bucket', name: 'OT' },
  { prop: 'extraTime1', name: 'ET1' },
  { prop: 'extraTime2', name: 'ET2' },
  { prop: 'penalties', name: 'P' },
  { prop: 'percentageLostBalls', name: 'LB%' },
  { prop: 'percentageEfficiency', name: 'EFF%' },
  { prop: 'percentageShotEfficiency', name: 'SHOT%' },
  { prop: 'suspensions2Minutes', name: '2min' },
  { prop: 'assists', name: 'A' },
];
//line after OT 1
//double line OT2
//dotted line between minut buckets

//team stats in box                                   done

//blend out position                                  done
//no goal parade replace stop with cross              done

//legend in header                                    done
// (2min) only and goalkeeper text float bottom       done

//27% save no images -> no saves are counted in playermodel?

export const goalKeeperTableColumnsHelper = [
  { prop: 'playerName', name: 'Players' },
  { prop: 'minutes10Bucket', name: '10 Min' },
  { prop: 'minutes20Bucket', name: '20 Min' },
  { prop: 'minutes25Bucket', name: '25 Min', },
  { prop: 'minutes30Bucket', name: '30 Min' },
  { prop: 'extra1Bucket', name: 'OT' },
  { prop: 'minutes40Bucket', name: '40 Min' },
  { prop: 'minutes50Bucket', name: '50 Min' },
  { prop: 'minutes55Bucket', name: '55 Min' },
  { prop: 'minutes60Bucket', name: '60 Min' },
  { prop: 'extra2Bucket', name: 'OT' },
  { prop: 'percentageSaves', name: '% SAVES' },
  { prop: 'percentageNoGoal', name: '% NOGOAL' },
  { prop: 'saves7Meter', name: '7M SAVES' }
];
export const goalKeeperTableMobileColumnsHelper = [
  { prop: 'playerName', name: 'Players' },
  { prop: 'percentageSaves', name: '% SAVES' },
  { prop: 'percentageNoGoal', name: '% NOGOAL' },
  { prop: 'saves7Meter', name: '7M SAVES' }
];

export const goalKeeperTableColumnsWithETHelper = [
  { prop: 'playerName', name: 'Players' },
  { prop: 'minutes10Bucket', name: '10 Min' },
  { prop: 'minutes20Bucket', name: '20 Min' },
  { prop: 'minutes25Bucket', name: '25 Min', },
  { prop: 'minutes30Bucket', name: '30 Min' },
  { prop: 'extra1Bucket', name: 'OT' },
  { prop: 'minutes40Bucket', name: '40 Min' },
  { prop: 'minutes50Bucket', name: '50 Min' },
  { prop: 'minutes55Bucket', name: '55 Min' },
  { prop: 'minutes60Bucket', name: '60 Min' },
  { prop: 'extra2Bucket', name: 'OT' },
  { prop: 'extraTime1', name: 'ET1' },
  { prop: 'extraTime2', name: 'ET2' },
  { prop: 'penalties', name: 'P' },
  { prop: 'percentageSaves', name: '% SAVES' },
  { prop: 'percentageNoGoal', name: '% NOGOAL' },
  { prop: 'saves7Meter', name: '7M SAVES' }
];

export const playerTableLegendMapHelper = [
  { prop: 'min2Suspensions', name: '2 Minutes Suspension', img: '2min.png' },
  { prop: 'meters7Goal', name: '7m Goal', img: '7mGoal.png' },
  { prop: 'assist', name: 'Assist', img: 'Assist.png' },
  { prop: 'goal', name: 'Goal', img: 'Goal.png' },
  { prop: 'goalKeeperGoal', name: 'Goalkeeper Goal', img: 'GoalkeeperGoal.png' },
  { prop: 'recievedGoal', name: 'Goalkeeper Goal Received', img: 'GoalkeeperGoalReceived.png' },
  { prop: 'goalKeeperNoGoal', name: 'Goalkeeper No Goal', img: 'GoalkeeperNoGoalReceived.png' },
  { prop: 'save', name: 'Goalkeeper Parade', img: 'GoalkeeperParade.png' },
  { prop: 'lostBalls', name: 'Lost Ball + Technical Mistake', img: 'LostBallTechnicalMistake.png' },
  { prop: 'meters7GkSaves', name: 'No Goal 7m Parade + Out', img: 'GoalkeeperParade.png' },
  { prop: 'meters7FailedShot', name: 'No Goal 7m Parade + Out', img: 'NoGoal7mParadeOut.png' },
  { prop: 'failedShot', name: 'No Goal Parade + Out', img: 'NoGoalParadeOut.png' },
  { prop: 'shotBlocked', name: 'Shot Blocked', img: 'ShotBlocked.png' },
  { prop: 'lostBallProvoke', name: 'Steal Ball', img: 'StealBall.png' },
];

export const playerTableLegendDisplayMapHelper = [
  { prop: 'min2Suspensions', name: '2 Minutes Suspension', img: '2min.png' },
  { prop: 'meters7Goal', name: '7m Goal', img: '7mGoal.png' },
  { prop: 'assist', name: 'Assist', img: 'Assist.png' },
  { prop: 'goal', name: 'Goal', img: 'Goal.png' },
  { prop: 'goalKeeperGoal', name: 'Goalkeeper Goal', img: 'GoalkeeperGoal.png' },
  { prop: 'recievedGoal', name: 'Goalkeeper Goal Received', img: 'GoalkeeperGoalReceived.png' },
  { prop: 'goalKeeperNoGoal', name: 'Goalkeeper No Goal', img: 'GoalkeeperNoGoalReceived.png' },
  { prop: 'save', name: 'Goalkeeper Parade', img: 'GoalkeeperParade.png' },
  { prop: 'lostBalls', name: 'LostBall + Technical Mistake', img: 'LostBallTechnicalMistake.png' },
  { prop: 'meters7FailedShot', name: 'No Goal 7m Parade + Out', img: 'NoGoal7mParadeOut.png' },
  { prop: 'failedShot', name: 'No Goal Parade + Out', img: 'NoGoalParadeOut.png' },
  { prop: 'shotBlocked', name: 'Shot Blocked', img: 'ShotBlocked.png' },
  { prop: 'lostBallProvoke', name: 'Steal Ball', img: 'StealBall.png' },
];

export const goalKeeperTableLegendMapHelper = [
  { prop: 'min2Suspensions', name: '2 Minutes Suspension', img: '2min.png' },
  { prop: 'meters7Goal', name: '7m Goal', img: '7mGoal.png' },
  { prop: 'assist', name: 'Assist', img: 'Assist.png' },
  { prop: 'goal', name: 'Goal', img: 'GoalkeeperGoal.png' },
  { prop: 'goalKeeperGoal', name: 'Goalkeeper Goal', img: 'GoalkeeperGoal.png' },
  { prop: 'recievedGoal', name: 'Goalkeeper Goal Received', img: 'GoalkeeperGoalReceived.png' },
  { prop: 'goalKeeperNoGoal', name: 'Goalkeeper No Goal', img: 'GoalkeeperNoGoalReceived.png' },
  { prop: 'save', name: 'Goalkeeper Parade', img: 'GoalkeeperParade.png' },
  { prop: 'lostBalls', name: 'Lost Ball + Technical Mistake', img: 'LostBallTechnicalMistake.png' },
  { prop: 'meters7GkSaves', name: 'No Goal 7m Parade + Out', img: 'GoalkeeperParade.png' },
  { prop: 'meters7FailedShot', name: 'No Goal 7m Parade + Out', img: 'NoGoal7mParadeOut.png' },
  { prop: 'failedShot', name: 'No Goal Parade + Out', img: 'NoGoalParadeOut.png' },
  { prop: 'shotBlocked', name: 'Shot Blocked', img: 'ShotBlocked.png' },
  { prop: 'lostBallProvoke', name: 'Steal Ball', img: 'StealBall.png' },
];

export const teamGoalByGoalColumnsHelper = [
  { prop: 'name', name: 'Team Name' }
];

export function generateGoalByGoalDataRowsHelper(_homeTeamLabel, _awayTeamLabel, _goalByGoal) {
  let _teamGoalByGoalRows = [
    [],
    []
  ];
  // Teams
  _teamGoalByGoalRows[0].push(_homeTeamLabel);
  _teamGoalByGoalRows[1].push(_awayTeamLabel);
  // generate data
  _goalByGoal.forEach((point) => {
    if (!point.visitorTeam) {
      _teamGoalByGoalRows[0].push(point.accumulated);
      _teamGoalByGoalRows[1].push("");
    } else {
      _teamGoalByGoalRows[0].push("");
      _teamGoalByGoalRows[1].push(point.accumulated);
    }
  });
  while (_teamGoalByGoalRows[0].length < 100) {
    _teamGoalByGoalRows[0].push("");
    _teamGoalByGoalRows[1].push("");
  }
  return _teamGoalByGoalRows;
}

export function generateGoalByGoalDataColumnsHelper() {
  let _teamGoalByGoalColumns = [].constructor(101).fill({ prop: 'goal', name: 'goal' });
  _teamGoalByGoalColumns[0] = { prop: 'name', name: 'Team Name' };
  return _teamGoalByGoalColumns;
}

export function combineAndSortGoalDataHelper(_homeGoalGraph, _visitorGoalGraph) {
  let _goalByGoal = [];
  _homeGoalGraph.forEach((point) => {
    _goalByGoal.push({ time: point.x as number, accumulated: point.y as number, visitorTeam: false })
  });
  _visitorGoalGraph.forEach((point) => {
    _goalByGoal.push({ time: point.x as number, accumulated: point.y as number, visitorTeam: true })
  });

  _goalByGoal.sort((a, b) => a.time - b.time);

  return _goalByGoal;
}

export const tableWidthsHelper = ['12%', '6.5%', '6.5%', '6.5%', '6.5%', '6%', '6.5%', '6.5%', '6.5%', '6.5%', '6%', '5%', '5.5%', '5%', '4%', '4%'];

export async function generatePlayerIconImageHelper(): Promise<Columns> {
  const legends = [];

  for (let symbol of playerTableLegendDisplayMapHelper) {
    legends.push(await new Img('/assets/playerstatsIcons/' + symbol.img).fit([12, 12]).alignment('center').build());
  }
  const columns = new Columns(legends).alignment('center');

  return columns.width('*');
}

export async function generatePlayerIconLegendHelper(): Promise<Columns> {
  const legends = [];
  for (let symbol of playerTableLegendDisplayMapHelper) {
    legends.push(new Txt(symbol.name).fontSize(8).alignment('center').end);
  }
  const columns = new Columns(legends).alignment('center');

  return columns.width('*');
}

export async function generateGoalByGoal(_goalByGoals, _home, _visitor, _homeColor, _visitorColor, sliceConfig?: number[]): Promise<Table> {
  
  let _goalByGoal = JSON.parse(JSON.stringify(_goalByGoals))
  _goalByGoal.forEach(goalByGoal => {
    goalByGoal.time = parseInt(goalByGoal.time)
  });

  let dataGoalByGoal = new Map();

  for (let i = 0; i <= 61; i++) {
    const arr = _goalByGoal.filter(el => el['time'] === i);

    if (arr.length) {
      dataGoalByGoal.set(i, {
        homeGoalByGoal: (arr.filter(goal => !goal['visitorTeam']) || [])
          .reduce((acc, v) => {
            if (acc <=  v['accumulated']) {
              return v['accumulated']
            }
            return acc
          }, 0)
        ,
        visitorGoalByGoal: (arr.filter(goal => goal['visitorTeam']) || [])
          .reduce((acc, v) => {
            {
              if (acc <=  v['accumulated']) {
                return v['accumulated']
              }
              return acc
            }
          }, 0)
      })
    } else {
      dataGoalByGoal.set(i, null)
    }
  }

  const arrayTmp = Array.from(dataGoalByGoal).slice(...sliceConfig)
  dataGoalByGoal = new Map(arrayTmp)

  let rows = [];
  rows.push([new Cell(new Txt(_home).end).end]);
  rows.push([new Cell(new Txt(_visitor).end).end]);
  let rowIndexes = [0, 1];

  function resolveGoalToGoalMapRows(value, key, map) {
    if (value === null) {
      rows[rowIndexes[0]].push(new Cell(new Txt('').end).end);
      rows[rowIndexes[1]].push(new Cell(new Txt('').end).end);
    }
    if (value) {
      rows[rowIndexes[0]].push(new Cell(new Txt(value['homeGoalByGoal'] > 0 ? value['homeGoalByGoal'] : '').end)
        .fillColor(value['homeGoalByGoal'] > 0 ? '#FFF' : '').end);
      rows[rowIndexes[1]].push(new Cell(new Txt(value['visitorGoalByGoal'] > 0 ? value['visitorGoalByGoal'] : '').end)
        .fillColor(value['visitorGoalByGoal'] > 0 ? '#FFF' : '').end);
    }
  }

  dataGoalByGoal.forEach(resolveGoalToGoalMapRows);

  rows.forEach(row => {
    row.forEach((cell, idx) => {
      cell['style'] = ['body__cell_b9']
      cell['color'] = '#1A375C'
      cell['margin'] = [0, 8, 0, 8]
      cell['alignment'] = idx === 0 ? 'left' : 'center'
    })
  })
  return new Table(rows)
}
