/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlayByPlayDto } from './playByPlayDto';
import { PlayerStatsDto } from './playerStatsDto';
import { TeamsStatsDto } from './teamsStatsDto';
import { PlayTimeDto } from './playTimeDto';


export interface GameDto { 
    /**
     * game id
     */
    id?: number;
    /**
     * folder id
     */
    folderId: number;
    /**
     * user id
     */
    accountEquipoId: number;
    /**
     * Home Team Name
     */
    home: string;
    /**
     * Visitor Team Name
     */
    visitor: string;
    /**
     * Home Team Goals
     */
    goalsHome: number;
    /**
     * Visitor Team Goals
     */
    goalsVisitor: number;
    /**
     * Date of the Game
     */
    date: string;
    /**
     * Id of the Home Team
     */
    homeId: number;
    /**
     * Id of the Visitor Team
     */
    visitorId: number;
    /**
     * first half end flag
     */
    firstHalfEnded: boolean;
    /**
     * second half end flag
     */
    secondHalfEnded: boolean;
    /**
     * game end flag
     */
    gameEnded: boolean;
    /**
     * PlayByPlay Dto list
     */
    playByPlayDto: Array<PlayByPlayDto>;
    /**
     * PlayTime Dto list
     */
    playTimeDto: Array<PlayTimeDto>;
    /**
     * Game share link hash
     */
    accessHash?: string;
    /**
     * Url of the video
     */
    videoUrl?: string;
    /**
     * Tells if the video is connected by scouting connect challenge
     */
    videoConnected?: boolean;
    /**
     * JSON string with serialized game entities
     */
    gameModelSnapshot: string;
    /**
     * Complete or Lite mode
     */
    gameType: string;
    /**
     * Live mode or video mode
     */
    gameMode: string;
    /**
     * Game status (scheduled, started, ended)
     */
    gameStatus: string;
    /**
     * UserId of csv request
     */
    csvRequestedBy?: number;
    /**
     * CSV Request reply
     */
    csvRequestAccept?: boolean;
    /**
     * UserId of person who tracked the game
     */
    trackedBy?: number;
    /**
     * App version used when tracking the game
     */
    versionNumber: string;
    /**
     * Game hosted video status
     */
    videoStatus: string;
    /**
     * PlayTime Dto list
     */
    trackers: Array<number>;
    /**
     * Video download presigned url
     */
    hostedVideoUrl?: string;
    /**
     * Video upload presigned url
     */
    videoUploadUrl?: string;
    /**
     * Video upload id for multipart uploads
     */
    videoUploadId?: string;
    /**
     * PlayerStatistics
     */
    playerStatsDto?: Array<PlayerStatsDto>;
    /**
     * TeamStatistics
     */
    teamStatsDto?: Array<TeamsStatsDto>;
}

