import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  templateUrl: './confirmation-modal.html',
  styleUrls: ['./confirmation-modal.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() title: string;

  constructor(private readonly popoverCntl: PopoverController) {}

  ngOnInit(): void {}

  confirmFolderChange(confirm: boolean) {
    this.popoverCntl.dismiss(confirm);
  }

  public static async Open(popoverCntl: PopoverController, props: any, cb: Function) {
    const popover = await popoverCntl.create({
      component: ConfirmationModalComponent,
      cssClass: 'confirm-popover',
      componentProps: props,
    });
    popover.onDidDismiss().then((d) => cb(d));
    await popover.present();
  }
}
