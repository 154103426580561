
<div class="confirm-modal-wrapper my-8" >
  <div class="py-4 w-full">
    <span>{{header | translate}}</span>
    <hr class="mx-auto my-2">
    <span class="explanation-text">{{subheader | translate}}</span>
  </div>
  <div class="button-wrapper mt-6">
    <span *ngIf="!disableCancelButton" (click)="onCancel()" class="close-btn">{{cancelButtonText || ('Close'|translate)}}</span>
    <ion-button color="primary" (click)="onConfirmName()">{{confirmButtonText || ('Confirm'|translate)}}</ion-button>
  </div>
</div>
