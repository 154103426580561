import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ScoutingPlaylistDto, ScoutingPlaylistVideoDto, WatchlistDto, WatchlistFolderDto } from 'src/app/api/hai-api';
import { TManageAccountEquipoGrants } from 'src/app/shared-services/account-equipo/account-equipo.service';

import { CoreService } from 'src/app/shared-services/core.service';
import { CommonUserModel } from 'src/app/shared-services/model/common-user.model';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class ScoutingService {
  currentUser: CommonUserModel;
  selectedEquipo: TManageAccountEquipoGrants;

  //Watchlist
  watchListFolders$ = new BehaviorSubject<WatchlistFolderDto[]>([]);
  watchLists$ = new BehaviorSubject<WatchlistDto[]>([]);

  playListFolders$ = new BehaviorSubject<ScoutingPlaylistDto[]>([]);
  videoFilter$ = new BehaviorSubject<ScoutingPlaylistVideoDto[]>([]);
  playlistUpdated$ = new BehaviorSubject<boolean>(false);

  constructor(private core: CoreService, private readonly logger: NGXLogger) {}

  public getUserAndEquipo() {
    this.currentUser = this.core.userService.user$.getValue();
    this.selectedEquipo = this.core.accountEquipoService.selectedAccountEquipo$.getValue();
  }

  public getWatchListData() {
    this.core.externalScoutingService
      .scoutingControllerGetWatchlistFolder(String(this.currentUser.id), String(this.selectedEquipo.id))
      .subscribe((watchListFolders) => {
        this.watchListFolders$.next(
          watchListFolders.filter((playList) => !playList.deleted).sort((a, b) => a.id - b.id)
        );
      });
  }

  public async editWatchlistFolder(folder) {
    await this.core.externalScoutingService
      .scoutingControllerPutWatchlistFolder(
        String(this.currentUser.id),
        String(this.selectedEquipo.id),
        folder.id,
        folder
      )
      .subscribe((res) => {
        this.getWatchListData();
        this.core.toastCtrl
          .create({
            icon: "checkmark-circle-outline",
            color: 'success',
            message: `Successfully saved on selected Watchlist!`,
            duration: 5000,
          })
          .then((value) => value.present());
      });
  }

  //PLAYLIST
  public async getPlayListData() {
    await this.core.loadingService.present();
    await this.core.externalScoutingService
      .scoutingControllerGetScoutingPlaylist(String(this.currentUser.id), String(this.selectedEquipo.id))
      .subscribe(async (playListFolders) => {
        await this.core.loadingService.dismiss();
        this.playlistUpdated$.next(true);
        this.playListFolders$.next(playListFolders.filter((playList) => !playList.deleted).sort((a, b) => a.id - b.id));
      }, async error => {
        await this.core.loadingService.dismiss();
        this.logger.error(error);
      });
  }

  public async createPlayList(folder) {
    await this.core.externalScoutingService
      .scoutingControllerPostScoutingPlaylist(String(this.currentUser.id), String(this.selectedEquipo.id), folder)
      .subscribe(async (res) => {
        this.videoFilter$.next([]);
        await this.getPlayListData();
        this.core.toastCtrl
          .create({
            icon: "checkmark-circle-outline",
            color: 'success',
            message: `Successfully`,
            duration: 5000,
          })
          .then((value) => value.present());
      });
  }

  public async editPlayList(playList) {
    await this.core.externalScoutingService
      .scoutingControllerPutScoutingPlaylist(
        String(this.currentUser.id),
        String(this.selectedEquipo.id),
        playList.id,
        playList
      )
      .subscribe(async (res) => {
        await this.getPlayListData();
        this.core.toastCtrl
          .create({
            icon: "checkmark-circle-outline",
            color: 'success',
            message: `Successfully!`,
            duration: 5000,
          })
          .then((value) => value.present());
      });
  }
}
