import { ChangeDetectionStrategy, Component, HostListener, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { CoreService} from 'src/app/shared-services/core.service';
import { QuickStatsColumnModel, QuickStatsColumnModelList } from '@handballai/stats-calculation';
import { CounterModel } from '@handballai/stats-calculation';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { SemaphorModel } from '@handballai/stats-calculation';
import { ConnectionGoals, Goals } from '@handballai/stats-calculation';
import { openPdfHelper } from 'src/app/shared-services/helper/realtime-pdf.helper';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  DoughnutController
} from 'chart.js';

Chart.register(
    ArcElement,
    Tooltip,
    Legend,
    Title,
    ChartDataLabels,
    DoughnutController
);

@Component({
    selector: 'app-quick-stats',
    templateUrl: './quick-stats.component.html',
    styleUrls: ['./quick-stats.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class QuickStatsComponent {

  @ViewChild('doughnut1Canvas', { static: false }) doughnut1Canvas: any;
  @ViewChild('doughnut2Canvas', { static: false }) doughnut2Canvas: any;
  @ViewChild('doughnut3Canvas', { static: false }) doughnut3Canvas: any;
  @ViewChild('doughnut4Canvas', { static: false }) doughnut4Canvas: any;

  doughnut1Chart: any;
  doughnut2Chart: any;
  doughnut3Chart: any;
  doughnut4Chart: any;

  chartOptions: any = {
    plugins: {
      title: {
        text: 'SAVES / GOALS %',
        display: true,
        font: {
          size: 13
        },
        color: '#0e375f'
      },
      datalabels: {
        color: '#0e375f',
        font: {
          size: 8
        },
        formatter: (value: number, context: any) => {
          if (!value) return '';
          const dataset = context.dataset;
          const total = dataset.data.reduce((acc: number, data: number) => acc + data, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value} [${percentage}%]`;
        }
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const datasetLabel = context.label;
            const datasetValue = context.dataset.data[context.dataIndex];

            const totalDataValue = context.dataset.data.reduce(
                (sum, currentValue) => sum + currentValue,
                0
            );
            const percentage = (datasetValue / totalDataValue) * 100;

            return `${datasetLabel}: ${datasetValue} [${percentage.toFixed(0)}%]`;
          }
        }
      }
    }
  };

  chart2Options: any = {
    plugins: {
      title: {
        text: 'GOALS / NO GOALS %',
        display: true,
        font: {
          size: 13
        },
        color: '#0e375f'
      },
      datalabels: {
        color: '#0e375f',
        font: {
          size: 8
        },
        formatter: (value: number, context: any) => {
          if (!value) return '';
          const dataset = context.dataset;
          const total = dataset.data.reduce((acc: number, data: number) => acc + data, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value} [${percentage}%]`;
        }
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const datasetLabel = context.label;
            const datasetValue = context.dataset.data[context.dataIndex];

            const totalDataValue = context.dataset.data.reduce(
                (sum, currentValue) => sum + currentValue,
                0
            );
            const percentage = (datasetValue / totalDataValue) * 100;

            return `${datasetLabel}: ${datasetValue} [${percentage.toFixed(0)}%]`;
          }
        }
      }
    }
  };

  //chartPlugins = [pluginLabels];
  slExtraLabels: Goals[] = [
    { position: '7MetersLabel', weight: 0, label: '7 Meters' },
    { position: 'OppositeFieldLabel', weight: 0, label: 'Opposite Field' },
  ];

  public cOptions: any = {
    title: {
      text: 'SAVES / GOALS %',
      display: true,
      fontSize: 13,
      fontColor: '#0e375f'
    },
    plugins: {
      labels: {
        fontSize: 8,
        fontColor: '#0e375f',
        render: (args: { value: number; percentage: number; label: string }) => `${args.value} [${args.percentage}%]`
      }
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const datasetLabel = data.labels[tooltipItem.index];
          const datasetValue = data.datasets[0].data[tooltipItem.index];

          const dataset = data.datasets[0];
          const value = dataset.data[tooltipItem.index] as number;
          const dataValues: number[] = dataset.data as number[];

          const totalDataValue = dataValues.reduce((sum, currentValue) => sum + currentValue, 0);
          const percentage = (value / totalDataValue) * 100;

          return `${datasetLabel}: ${datasetValue} [${percentage.toFixed(0)}%]`;
        }
      }
    },
    legend: {
      display: false
    },
  };

  public c2Options: any = {
    title: {
      text: 'GOALS / NO GOALS %',
      display: true,
      fontSize: 13,
      fontColor: '#0e375f'
    },
    plugins: {
      labels: {
        fontSize: 8,
        fontColor: '#0e375f',
        render: (args: { value: number; percentage: number; label: string }) => `${args.value} [${args.percentage}%]`
      }
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const datasetLabel = data.labels[tooltipItem.index];
          const datasetValue = data.datasets[0].data[tooltipItem.index];

          const dataset = data.datasets[0];
          const value = dataset.data[tooltipItem.index] as number;
          const dataValues: number[] = dataset.data as number[];

          const totalDataValue = dataValues.reduce((sum, currentValue) => sum + currentValue, 0);
          const percentage = (value / totalDataValue) * 100;

          return `${datasetLabel}: ${datasetValue} [${percentage.toFixed(0)}%]`;
        }
      }
    },
    legend: {
      display: false
    },
  };
  public circleColors: any[] = [{ backgroundColor: ['#ECB22E', '#41E6CD'] }];
  public circleColors2: any[] = [{ backgroundColor: ['#ECB22E', '#41E6CD'] }];

  public homeGoalSavesValues$ = new BehaviorSubject<number[]>([0, 0]);
  public homeGoalNoGoalValues$ = new BehaviorSubject<number[]>([0, 0]);
  public visitorGoalSavesValues$ = new BehaviorSubject<number[]>([0, 0]);
  public visitorGoalNoGoalValues$ = new BehaviorSubject<number[]>([0, 0]);


  public homeTeamColor = '#0E375F';
  public visitorTeamColor = '#41E6CD';
  
  public homeTeamName$ = new BehaviorSubject<string>('Home Team');
  public visitorTeamName$ = new BehaviorSubject<string>('Visitor Team');
  public svgCandGhome$ = new BehaviorSubject<SafeHtml>('');
  public svgSLhome$ = new BehaviorSubject<SafeHtml>('');
  public svgCandGvisitor$ = new BehaviorSubject<SafeHtml>('');
  public svgSLvisitor$ = new BehaviorSubject<SafeHtml>('');
  public selectedTeam$ = new BehaviorSubject<string|boolean>(false);
  public screenWidth$ = new BehaviorSubject<number>(0);
  
  private _svgCandGhomeModel: ConnectionGoals = { goals: [], connections: [] };
  private _svgSLhomeModel: ConnectionGoals = { goals: [], connections: [] };
  private _svgCandGvisitorModel: ConnectionGoals = { goals: [], connections: [] };
  private _svgSLvisitorModel: ConnectionGoals = { goals: [], connections: [] };
  private _homeQuickOverviewModel: QuickStatsColumnModel | undefined = undefined;
  private _visitorQuickOverviewModel: QuickStatsColumnModel | undefined = undefined;
  private _home7MetersGoals = 0;
  private _visitor7MetersGoals = 0;
  private _home7MetersFailedShoots = 0;
  private _visitor7MetersFailedShots = 0;
  private _semaphorModel: SemaphorModel[] = [];

  constructor(
    private readonly logger: NGXLogger,
    private readonly sanitizer: DomSanitizer,
    public readonly core: CoreService,
    private readonly modalCtrl: ModalController,
    private readonly loadingCtrl: LoadingController,
    private readonly toastCtrl: ToastController,
  ) {
    this.refreshSvg();
  }

  refreshSvg() {
    this.svgCandGhome$
        .next(this.sanitizer
            .bypassSecurityTrustHtml(this.core.getShootingFieldImg(this._svgCandGhomeModel, this.homeTeamColor, 'white')));
    this.svgSLhome$
        .next(this.sanitizer
            .bypassSecurityTrustHtml(this.core.getShootingFieldImg(this._svgSLhomeModel, this.homeTeamColor, 'white')));
    this.svgCandGvisitor$
        .next(this.sanitizer
            .bypassSecurityTrustHtml(this.core.getShootingFieldImg(this._svgCandGvisitorModel, this.visitorTeamColor)));
    this.svgSLvisitor$
        .next(this.sanitizer
            .bypassSecurityTrustHtml(this.core.getShootingFieldImg(this._svgSLvisitorModel, this.visitorTeamColor)));
  }

  //Window with input to avoid have more listeners to resize event
  @Input()
  set screenWidth(value: number) {
    this.setUpStatsView(value);
  }

  // Connections and goals (player positions)

  @Input()
  set homeGoalsConnectionsPlPosModel(value: ConnectionGoals) {
    if (!value) return;
    this._svgCandGhomeModel = value;
    this.refreshSvg();
  }

  @Input()
  set visitorGoalsConnectionsPlPosModel(value: ConnectionGoals) {
    if (!value) return;
    this._svgCandGvisitorModel = value;
    this.refreshSvg();
  }

  // Shooting locations

  @Input()
  set homeGoalsPositionModel(value: Goals[]) {
    if (!value) return;
    this._svgSLhomeModel.goals = value;
    this._svgSLhomeModel.goals.push(...this.slExtraLabels);
    this.refreshSvg();
  }

  @Input()
  set visitorGoalsPositionModel(value: Goals[]) {
    if (!value) return;
    this._svgSLvisitorModel.goals = value;
    this._svgSLvisitorModel.goals.push(...this.slExtraLabels);
    this.refreshSvg();
  }

  @Input()
  set quickStatsColumnModelList(value: QuickStatsColumnModelList) {
    if (value && value.length > 1) {
      this.logger.debug('QuickStatsComponent.quickStatsColumnModelList', value);
      this.homeTeamName$.next(value[0].teamName);
      this.visitorTeamName$.next(value[1].teamName);
      this._homeQuickOverviewModel = value[0];
      this._visitorQuickOverviewModel = value[1];
    }
  }

  @Input()
  set homeGoalCounters(value: CounterModel[]) {
    if (value.length > 1) {
      const visitorGoalSavesValues = [
        value.filter(c => c.counterType == 'GOAL')[0].counter,
        value.filter(c => c.counterType == 'SAVE')[0].counter,
      ];
      this.visitorGoalSavesValues$.next(visitorGoalSavesValues);
      const visitorGoalNoGoalValues = [
        value.filter(c => c.counterType == 'GOAL')[0].counter,
        value.filter(c => c.counterType == 'POST_OUT')[0].counter
        + value.filter(c => c.counterType == 'SAVE')[0].counter,
      ];
      this.visitorGoalNoGoalValues$.next(visitorGoalNoGoalValues);
      this._home7MetersGoals = value.filter(c => c.counterType === 'GOAL-7_METERS')[0].counter;
      this._home7MetersFailedShoots = value.filter(c => c.counterType === '7-M_FAILED_SHOT')[0].counter;

      setTimeout(() => {
        this.createDoughnutChart(visitorGoalSavesValues, ['GOALS', 'SAVES'], 'doughnut3Chart', 'doughnut3Canvas', this.chartOptions);
        this.createDoughnutChart(visitorGoalNoGoalValues, ['GOALS', 'NO GOALS'], 'doughnut4Chart', 'doughnut4Canvas', this.chart2Options);
      }, 200);
    }
  }

  @Input()
  set visitorGoalCounters(value: CounterModel[]) {
    if (value.length > 1) {
      const homeGoalSavesValues = [
        value.filter(c => c.counterType == 'GOAL')[0].counter,
        value.filter(c => c.counterType == 'SAVE')[0].counter,
      ];
      this.homeGoalSavesValues$.next(homeGoalSavesValues);
      const homeGoalNoGoalValues = [
        value.filter(c => c.counterType == 'GOAL')[0].counter,
        value.filter(c => c.counterType == 'POST_OUT')[0].counter
        + value.filter(c => c.counterType == 'SAVE')[0].counter,
      ];
      this.homeGoalNoGoalValues$.next(homeGoalNoGoalValues);
      this._visitor7MetersGoals = value.filter(c => c.counterType === 'GOAL-7_METERS')[0].counter;
      this._visitor7MetersFailedShots = value.filter(c => c.counterType === '7-M_FAILED_SHOT')[0].counter;
      setTimeout(() => {
        this.createDoughnutChart(homeGoalSavesValues, ['GOALS', 'SAVES'], 'doughnut1Chart', 'doughnut1Canvas', this.chartOptions);
        this.createDoughnutChart(homeGoalNoGoalValues, ['GOALS', 'NO GOALS'], 'doughnut2Chart', 'doughnut2Canvas', this.chart2Options);
      }, 200);
    }
  }

  createDoughnutChart(data: number[], labels: string[], chartType: string, canvasType: string, options: any) {
    if (!this[canvasType]) return;
    const ctx = this[canvasType].nativeElement.getContext('2d');

    if (this[chartType]) {
      this[chartType].destroy();
    }

    this[chartType] = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels,
        datasets: [{
          data: data,
          backgroundColor: this.circleColors[0].backgroundColor,
          borderColor: this.circleColors[0].borderColor,
          borderWidth: 1
        }]
      },
      options
    });
  }

  get semaphorModel(): SemaphorModel[] {
    return this._semaphorModel;
  }

  @Input()
  set semaphorModel(value: SemaphorModel[]) {
    this._semaphorModel = value;
  }

  get homeQuickOverviewModel(): QuickStatsColumnModel | undefined {
    return this._homeQuickOverviewModel;
  }

  get visitorQuickOverviewModel(): QuickStatsColumnModel | undefined {
    return this._visitorQuickOverviewModel;
  }

  get home7MetersGoals(): number {
    return this._home7MetersGoals;
  }

  get visitor7MetersGoals(): number {
    return this._visitor7MetersGoals;
  }

  get home7MetersFailedShoots(): number {
    return this._home7MetersFailedShoots;
  }

  get visitor7MetersFailedShots(): number {
    return this._visitor7MetersFailedShots;
  }

  public calculatePercentageItem(dividend: number, divisor: number): string {
    if (dividend && divisor) {
      return (divisor === 0 ? 0 : 100 * (dividend / divisor)).toFixed(0);
    }
    return '0';
  }
  
  public changeTeamMode(team: 'Home'|'Visitor'|false): void {
    this.selectedTeam$.next(team);
  }
  
  private setUpStatsView (width: number) {
    if (width > 590) {
      this.changeTeamMode(false);
    } else {
      if (this.screenWidth$.value > 590 || this.screenWidth$.value === 0) {
        this.changeTeamMode('Home')
      }
    }
    this.screenWidth$.next(width);
  }
}

