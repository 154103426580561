import { Component, Input, OnInit } from "@angular/core";
import { QuickStatsColumnModel } from "@handballai/stats-calculation";
import { SemaphorModel } from "@handballai/stats-calculation";
import { NGXLogger } from "ngx-logger";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-quick-stats-table",
  templateUrl: "./quick-stats-table.component.html",
  styleUrls: ["./quick-stats-table.component.scss"],
})
export class QuickStatsTableComponent implements OnInit {
  public selectedTeam$ = new BehaviorSubject<string|boolean>(false);
  
  private _homeQuickOverviewModel: QuickStatsColumnModel | undefined =
    undefined;
  private _visitorQuickOverviewModel: QuickStatsColumnModel | undefined =
    undefined;
  private _home7MetersGoals = 0;
  private _visitor7MetersGoals = 0;
  private _home7MetersFailedShoots = 0;
  private _visitor7MetersFailedShots = 0;
  private _showOneTeam = false;
  private _showDashBoard = false;
  private _showSaves = false;
  private _usePossessionEvents = false;
  private _semaphorModel: SemaphorModel[] = [];
  private _noHeaderColor = false;
  
  constructor(private readonly logger: NGXLogger) {}

  ngOnInit(): void {
    console.log("VISITOR", this._visitorQuickOverviewModel);
    console.log("HOME", this._homeQuickOverviewModel);
  }

  get homeQuickOverviewModel(): QuickStatsColumnModel | undefined {
    return this._homeQuickOverviewModel;
  }

  @Input()
  set homeQuickOverviewModel(value: QuickStatsColumnModel | undefined) {
    this._homeQuickOverviewModel = value;
    this.logger.debug("QuickStatsTableComponent.homeQuickOverviewModel", value);
  }

  get visitorQuickOverviewModel(): QuickStatsColumnModel | undefined {
    return this._visitorQuickOverviewModel;
  }

  @Input()
  set visitorQuickOverviewModel(value: QuickStatsColumnModel | undefined) {
    this._visitorQuickOverviewModel = value;
    this.logger.debug(
      "QuickStatsTableComponent.visitorQuickOverviewModel",
      value
    );
  }

  get home7MetersGoals(): number {
    return this._home7MetersGoals;
  }

  @Input()
  set home7MetersGoals(value: number) {
    this._home7MetersGoals = value;
  }

  get visitor7MetersGoals(): number {
    return this._visitor7MetersGoals;
  }

  @Input()
  set visitor7MetersGoals(value: number) {
    this._visitor7MetersGoals = value;
  }

  get home7MetersFailedShoots(): number {
    return this._home7MetersFailedShoots;
  }

  @Input()
  set home7MetersFailedShoots(value: number) {
    this._home7MetersFailedShoots = value;
  }

  get visitor7MetersFailedShots(): number {
    return this._visitor7MetersFailedShots;
  }

  @Input()
  set visitor7MetersFailedShots(value: number) {
    this._visitor7MetersFailedShots = value;
  }

  get showOneTeam(): boolean {
    return this._showOneTeam;
  }

  @Input()
  set showOneTeam(value: boolean) {
    this._showOneTeam = value;
  }

  get showDashBoard(): boolean {
    return this._showDashBoard;
  }

  @Input()
  set showDashBoard(value: boolean) {
    this._showDashBoard = value;
  }

  get showSaves(): boolean {
    return this._showSaves;
  }

  @Input()
  set showSaves(value: boolean) {
    this._showSaves = value;
  }

  @Input()
  set semaphorModel(value: SemaphorModel[]) {
    this._semaphorModel = value;
  }

  @Input()
  set usePossessionEvents(value: boolean) {
    this._usePossessionEvents = value;
  }

  @Input()
  set noHeaderColor(value : boolean){
    this._noHeaderColor = value;
  }

  get noHeaderColor(): boolean{
   return this._noHeaderColor;
  }

  @Input('selectedTeam')
  set selectedTeam(value: string | boolean) {
    this.selectedTeam$.next(value);
  }

  get homeSemaphor(): number | undefined {
    if (this._semaphorModel.length > 0) {
      return this._semaphorModel[0].percent > 100
        ? 100
        : this._semaphorModel[0].percent;
    }
    return undefined;
  }

  get visitorSemaphor(): number | undefined {
    if (this._semaphorModel.length > 0) {
      return this._semaphorModel[1].percent > 100
        ? 100
        : this._semaphorModel[1].percent;
    }
    return undefined;
  }

  get homePossessionsForCalculation(): number {
    return this._usePossessionEvents
      ? this.homeQuickOverviewModel?.totalPossessionEvents
      : this.homeQuickOverviewModel?.totalPossessions;
  }

  get visitorPossessionsForCalculation(): number {
    return this._usePossessionEvents
      ? this.visitorQuickOverviewModel?.totalPossessionEvents
      : this.visitorQuickOverviewModel?.totalPossessions;
  }

  public calculatePercentageItem(dividend: number, divisor: number): string {
    if (dividend && divisor) {
      return (divisor === 0 ? 0 : 100 * (dividend / divisor)).toFixed(0);
    }
    return "0";
  }
}
