<ion-label class="select-label">{{ 'Time of events' | translate }}</ion-label>
<ion-range
  style="padding-top: 0"
  slot="start"
  [pin]="true"
  [snaps]="true"
  [ticks]="true"
  [dualKnobs]="true"
  [min]="limits.lower"
  [max]="limits.upper"
  [value]="defaultValue"
  (ionChange)="fireChange($event)"
>
  <ion-label slot="start">{{ limits.lower }} {{ 'min' | translate }}</ion-label>
  <ion-label slot="end">{{ limits.upper }} {{ 'min' | translate }}</ion-label>
</ion-range>
