import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import {
  AdminAccountEquipoDto,
  CreateAccountDto,
  ExternalAccountsService,
  ManageAccountDto,
  PlanPermsDto,
  RedirectDto
} from 'src/app/api/hai-api';
import { CoreService } from '../core.service';
import { StorageService } from '../storage-service/storage.service';

export type TManageAccountsMap = { [id: string]: ManageAccountDto };

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  get accounts$() {
    return this._accounts$;
  }
  get plans$() {
    return this._plans$;
  }
  get accountsMap$() {
    return this._accountsMap$;
  }
  get lastAccountEquipos$() {
    return this._lastAccountEquipos$;
  }
  get lastAccountEquipo$() {
    return this._lastAccountEquipo$;
  }

  constructor(
    private logger: NGXLogger,
    private readonly externalAccountService: ExternalAccountsService
  ) {}
  private _core: CoreService;

  private _accounts$ = new BehaviorSubject<ManageAccountDto[]>(null);
  private _plans$ = new BehaviorSubject<PlanPermsDto[]>(null);
  private _accountsMap$ = new BehaviorSubject<TManageAccountsMap>(null);

  private _lastAccountEquipos$ = new BehaviorSubject<AdminAccountEquipoDto[]>(null);
  private _lastAccountEquipo$ = new BehaviorSubject<AdminAccountEquipoDto>(null);
  initCore(core: CoreService) {
      this._core = core;
  }

  resetAccountModel() {
    // throw new Error('Not yet implemented');
  }

  public onSubscriptionChange(accountId: string): void {
    this.paymentSubscribe(accountId).then(async ps => {
      window.location.href = ps.url;
      await this._core.loadingService.dismiss();
    }).catch(async err => {
      this.logger.error('Error subscribing', err);
      await this._core.loadingService.dismiss();
    });
  }

  paymentSubscribe(accountId: string, extra="") {
    return new Promise<RedirectDto>(async (ok, ko ) => {
      this._core.storageService.getAccessTokenAsObject().then((val) => {
        // const grantsMap = val.accounts
        this.externalAccountService.accountHandlerControllerAccountPaymentSubscribe(val.sub.toString(), accountId, extra)
          .subscribe(ae => {
            this.logger.debug('AccountService.GetAccounts() - account equipos read successfully');
            ok(ae);
          },
          error => {
            this.logger.error('AccountService.GetAccounts() - error reading account equipos!', error);
            ko(error);
          });
      });
    });
  }

  createAccount(accountCreate: CreateAccountDto) {
    return new Promise<ManageAccountDto>(async (ok, ko ) => {
      this._core.storageService.getAccessTokenAsObject().then((val) => {
        // const grantsMap = val.accounts
        this.externalAccountService.accountHandlerControllerCreateAccountByPlan(val.sub.toString(), accountCreate)
          .subscribe(ae => {
            this.logger.debug('AccountService.GetAccounts() - account equipos read successfully');
            ok(ae);
          },
          error => {
            this.logger.error('AccountService.GetAccounts() - error reading account equipos!', error);
            ko(error);
          });
      });
    });
  }

  createAccountWithoutPlan(accountCreate: ManageAccountDto) {
    return new Promise<ManageAccountDto>(async (ok, ko ) => {
      this._core.storageService.getAccessTokenAsObject().then((val) => {
        // const grantsMap = val.accounts
        this.externalAccountService.accountHandlerControllerCreateAccount(accountCreate)
          .subscribe(ae => {
            this.logger.debug('AccountService.GetAccounts() - account create successfully');
            ok(ae);
          },
          error => {
            this.logger.error('AccountService.GetAccounts() - error creating account !', error);
            ko(error);
          });
      });
    });
  }

  updateAccount(accountUpdate: ManageAccountDto) {
    return new Promise<ManageAccountDto>(async (ok, ko ) => {
      this._core.storageService.getAccessTokenAsObject().then((val) => {
        // const grantsMap = val.accounts
        this.externalAccountService.accountHandlerControllerUpdateAccount(accountUpdate.id.toString(), accountUpdate)
          .subscribe(ae => {
            this.logger.debug('AccountService.GetAccounts() - account create successfully');
            ok(ae);
          },
          error => {
            this.logger.error('AccountService.GetAccounts() - error creating account !', error);
            ko(error);
          });
      });
    });
  }

  getAccounts() {
    return new Promise<ManageAccountDto[]>((ok, ko ) => {
      this._core.storageService.getAccessTokenAsObject().then((val) => {
        // const grantsMap = val.accounts
        this.externalAccountService.accountHandlerControllerGetAccounts(val.sub.toString())
          .subscribe(ae => {
            this.logger.debug('AccountService.GetAccounts() - account equipos read successfully');
            this._accounts$.next(ae);
            this._accountsMap$.next(this.accounts$.value.reduce<TManageAccountsMap>((base, itm) => {
              base[itm.id] = itm;
              return base;
            }, {}));
            ok(this.accounts$.value);
          },
          error => {
            this.logger.error('AccountService.GetAccounts() - error reading account equipos!', error);
            ko(error);
          });
      });
    });
  }

  getAccountsByUserId(id: string) {
    return new Promise<ManageAccountDto[]>((ok, ko ) => {
        this.externalAccountService.accountHandlerControllerGetAccounts(id)
          .subscribe(ae => {
            this.logger.debug('AccountService.getAccountsById() - accounts read successfully');
            this._accounts$.next(ae);
            this._accountsMap$.next(this.accounts$.value.reduce<TManageAccountsMap>((base, itm) => {
              base[itm.id] = itm;
              return base;
            }, {}));
            ok(this.accounts$.value);
          },
          error => {
            this.logger.error('AccountService.getAccountsById() - error reading accounts!', error);
            ko(error);
          });
    });
  }

  getAvailablePlans() {
    return new Promise<PlanPermsDto[]>((ok, ko) => {
      this.externalAccountService.accountHandlerControllerGetPlans()
          .subscribe(plans => {
                this.logger.debug('AccountService.getAvailablePlans() - plans read successfully');
                this._plans$.next(plans);
                ok(this.plans$.value);
              },
              error => {
                this.logger.error('AccountService.getAvailablePlans() - error reading plans!', error);
                ko(error);
              });
    });
  }

  getAccountById(id: number) {
    return new Promise<ManageAccountDto>(async (ok, ko) => {
      const accounts = this._accounts$.value ?? await this.getAccounts();
      ok(accounts.filter(to => to.id == id)[0] ?? null);
    });
  }

  unselectSelectedAccounts() {
    this._accounts$.next([]);
  }

  selectedAccount(account: ManageAccountDto) {
    this._accounts$.next([account]);
  }

  async getAccountEquipos(aid: number) {
    return new Promise<AdminAccountEquipoDto[]>((ok, ko) => {
      this._core.storageService.getAccessTokenAsObject().then((jwt) => {
        this.externalAccountService.accountHandlerControllerGetAccountEquipos(jwt.sub.toString(), aid.toString())
          .subscribe(ae => {
            this.logger.debug('AccountService.GetAccountEquipos() - account equipos read successfully');
            this._lastAccountEquipos$.next(ae);
            ok(this._lastAccountEquipos$.value);
          },
          error => {
            this.logger.error('AccountService.GetAccountEquipos() - error reading account equipos!', error);
            ko(error);
          });
      });
    });
  }

  async getAccountEquiposByEquiposIds(aeids: string) {
    return new Promise<AdminAccountEquipoDto[]>((ok, ko) => {
      this.externalAccountService.accountHandlerControllerGetAccountEquiposByIds(aeids)
        .subscribe(ae => {
          this.logger.debug('AccountService.getAccountEquiposByEquiposIds() - account equipos read successfully');
          this._lastAccountEquipos$.next(ae);
          ok(this._lastAccountEquipos$.value);
        },
        error => {
          this.logger.error('AccountService.getAccountEquiposByEquiposIds() - error reading account equipos!', error);
          this._lastAccountEquipos$.next([]);
          ko(error);
        });
    });
  }

  async saveAccountName(aid: number, name: string): Promise<ManageAccountDto> {
    return new Promise<ManageAccountDto>((ok, ko) => {
      this.externalAccountService.accountHandlerControllerUpdateAccountName(aid.toString(), name)
        .subscribe(ae => {
          this.logger.debug('AccountService.saveAccountName() - account name saved successfully');
          ok(ae);
        },
        error => {
          this.logger.error('AccountService.saveAccountName() - error saving account name!', error);
          ko(error);
        });
    });
  }

  async saveAccountEquipo(accountEquipo: AdminAccountEquipoDto) {
    return new Promise<AdminAccountEquipoDto>((ok, ko) => {
      this._core.storageService.getAccessTokenAsObject().then((val) => {
        const observer = (!accountEquipo.id)
          ? this.externalAccountService.accountHandlerControllerPostAccountEquipo(val.sub.toString(), accountEquipo.account.id.toString(), accountEquipo)
          : this.externalAccountService.accountHandlerControllerPutAccountEquipo(val.sub.toString(), accountEquipo.account.id.toString(), accountEquipo.id.toString(), accountEquipo)
        ;

        observer.subscribe(ae => {
          this.logger.debug('AccountService.SaveAccountEquipo() - save account equipo successfully');
          this._lastAccountEquipo$.next(ae);
          this._core.userService.refreshToken();
          ok(this._lastAccountEquipo$.value);
        },
        error => {
          this.logger.error('AccountService.SaveAccountEquipo() - failed saving account equipo!', error);
          ko(error);
        });
      });
    });
  }

  async changeAccountPlan(account: ManageAccountDto, plan: string) {
    return new Promise<ManageAccountDto>((ok, ko) => {
      this._core.storageService.getAccessTokenAsObject().then((val) => {
        this.externalAccountService.accountHandlerControllerAccountChangePlan(val.sub.toString(), account.id.toString(), plan).subscribe(ae => {
          this.logger.debug('AccountService.SaveAccountEquipo() - save account equipo successfully');
          ok(ae);
        },
        error => {
          this.logger.error('AccountService.SaveAccountEquipo() - failed saving account equipo!', error);
          ko(error);
        });
      });
    });
  }

}
