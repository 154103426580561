import { GamePlayerModel } from 'src/app/shared-services/model/game.model';
import { GameModel, PlayerEventModel } from '@handballai/stats-calculation';
import { VideoPlayTimeContainer, VideoPlayTimeCounter } from 'src/app/shared-services/statistics/video/model/video-tracker.util';
import { NGXLogger } from 'ngx-logger';

export const populateGamePlayerMap = (gameModel: GameModel): Map<number, GamePlayerModel> =>
    [
        ...gameModel.home.currentField,
        ...gameModel.visitor.currentField,
        ...gameModel.home.currentBench,
        ...gameModel.visitor.currentBench
    ].reduce((playerMap, currPlayer) => {
        playerMap.set(currPlayer.id, currPlayer);
        return playerMap;
    }, new Map<number, GamePlayerModel>());

export const populateVideoPlayTimeMap = (
    playTimeCounter: VideoPlayTimeCounter,
    logger: NGXLogger,
    gameModel: GameModel
): Map<number, VideoPlayTimeContainer> =>
    [
            ...gameModel.home.currentField,
            ...gameModel.visitor.currentField,
            ...gameModel.home.currentBench,
            ...gameModel.visitor.currentBench
    ].reduce((playerMap, currPlayer) => {
            playerMap.set(currPlayer.id, new VideoPlayTimeContainer(
                playTimeCounter,
                logger,
                currPlayer.id,
                currPlayer
            ));
            return playerMap;
    }, new Map<number, VideoPlayTimeContainer>());


export const eventToPossessionMapper = (eventType: PlayerEventModel): boolean =>
    [
        '2_MIN',
        'YELLOW_CARD',
        'RED_CARD',
        'BLUE_CARD',
        'GOAL_ASSIST',
        'FOUL_COMMIT',
        'BLOCK',
        '2MIN_COMMIT',
        '7M_COMMIT',
        'SUSPENSION_TO_BENCH_YELLOW_CARD',
        'SUSPENSION_TO_BENCH_2_MIN',
        'SUSPENSION_TO_BENCH_RED_CARD',
        'SUSPENSION_TO_BENCH_BLUE_CARD',
        'TIME_OUT',
        '7M_SUSPENSION_COMMIT',
        'LOST_BALL_PROVOKE',
        'TECHNICAL_MISTAKE_PROVOKE',
        'GK_SAVE',
        'GK_GOAL',
        'SECOND_HALF_START'
    ].filter(ev => ev === eventType).length === 0;
