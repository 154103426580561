import { PlaytimeEventModel } from '@handballai/stats-calculation';
import { GameDto, PlayByPlayDto, PlayTimeDto } from 'src/app/api/hai-api';
import { PlayByPlayModel } from '@handballai/stats-calculation';
import { GameModel } from 'src/app/shared-services/model/game.model';
import { DateTime } from 'luxon';
import { GameType } from 'src/app/shared-services/game/game-type.model';
import { GameMode } from '../game/game-modes.model';
import { VideoStatus } from '../game/video-status.model';
import { GameStatus } from '../game/game-status.model';

export const transformPlayTimeEventsToServerModel = (playTimeEvents: PlaytimeEventModel[]): PlayTimeDto[] =>
    playTimeEvents.map(pt => ({
        event: pt.eventType,
        playerId: pt.playerId,
        timestamp: pt.eventTime.timestamp.toISO(),
        orderId: pt.seq,
        halftime: pt.eventTime.halftime,
        secondsSinceStartOfGame: pt.eventTime.secondsSinceStartOfGame,
        secondsSinceHalftime: pt.eventTime.secondsSinceHalftime,
        minutesSinceHalftime: pt.eventTime.minutesSinceHalftime,
        videoTimestamp: pt.videoTimestamp,
    }));

export const transformPlayByPlayToServerModel = (pbpRows: PlayByPlayModel[]): PlayByPlayDto[] =>
    pbpRows.map(pbp => ({
        id: pbp.id,
        event: pbp.event,
        orderId: pbp.orderId,
        // TODO: Check why this is sometimes a string
        timestamp: (typeof pbp.eventTime.timestamp == 'string') ?
            pbp.eventTime.timestamp as unknown as string : pbp.eventTime.timestamp.toISO(),
        secondsSinceStartOfGame: pbp.eventTime.secondsSinceStartOfGame,
        secondsSinceHalftime: pbp.eventTime.secondsSinceHalftime,
        minutesSinceHalftime: pbp.eventTime.minutesSinceHalftime,
        halftime: pbp.eventTime.halftime,
        teamName: pbp.teamName,
        offenseSystem: pbp.offenseSystem,
        teamMarker: pbp.teamMarker,
        teamId: pbp.teamId,
        shotLocation: pbp?.shotLocation,
        executionPosition: pbp?.executionPosition,
        possessions: pbp.possessions,
        possessionId: pbp.possessionId,
        phase: pbp.phase,
        offensePlayer: pbp.offensePlayer?.id,
        defensePlayer: pbp?.defensePlayer?.id,
        defenseSystem: pbp.defenseSystem,
        assistantPlayer: pbp?.assistantPlayer?.id,
        offense: pbp.offense.map(pl => pl.id).join(':'),
        defense: pbp.defense.map(pl => pl.id).join(':'),
        important: pbp.important ? pbp.important : false,
        gameSystem: pbp.gameSystem,
        videoTimestamp: pbp.videoTimestamp,
        eventDuration: pbp.eventTime.eventDuration,
    }));

export const transformGameModelToGameDto = (
    accountEquipoId: number,
    startTimeOfGame: DateTime,
    gameModel: GameModel,
    playByPlayList: PlayByPlayDto[],
    playTimeList: PlayTimeDto[],
    firstHalfEnded: boolean,
    secondHalfEnded: boolean,
    gameType: GameType,
    gameMode: GameMode,
    status: GameStatus,
    videoStatus: VideoStatus,
    versionNumber: string,
    trackedBy: number,
    trackers?: number[],
    videoUrl?: string,
): GameDto => ({
    date: startTimeOfGame.toISO(),
    home: gameModel.home.name,
    visitor: gameModel.visitor.name,
    homeId: gameModel.home.id,
    visitorId: gameModel.visitor.id,
    accountEquipoId: accountEquipoId,
    goalsHome: gameModel.scoreHome,
    goalsVisitor: gameModel.scoreHome,
    playTimeDto: playTimeList,
    playByPlayDto: playByPlayList,
    gameModelSnapshot: JSON.stringify(gameModel),
    firstHalfEnded: firstHalfEnded,
    secondHalfEnded: secondHalfEnded,
    gameEnded: secondHalfEnded,
    gameType: gameType,
    gameMode: gameMode,
    videoUrl: videoUrl,
    gameStatus: status,
    folderId: null,
    trackers: trackers ?? [],
    videoStatus,
    versionNumber,
    trackedBy,
});
