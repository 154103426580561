<div class="confirm-modal-wrapper my-8">
  <div class="header">
    <ion-item lines="none">
      <ion-label>
        <span *ngIf="isWatchList">{{ 'Folders' | translate }}</span>
        <span *ngIf="!isWatchList">{{ 'Playlist' | translate }}</span>
      </ion-label>
      <ion-button (click)="isWatchList ? addWatchlistFolder() : addPlaylistFolder()" color="secondary">
        <ion-icon name="add-outline"></ion-icon>
      </ion-button>
    </ion-item>
    <hr style="background-color: black; height: 3px" />
  </div>
  <ng-container *ngIf="!isWatchList">
    <ion-item lines="full" *ngFor="let folder of (scoutingService.playListFolders$ | async); let i = index"
              [color]="selectedPlaylistFolder?.id === folder.id ? 'alternative' : ''"
              class="item-default clickAllowedSource"
              (click)="selectFolderPlaylist(folder)"
    >
      <ion-avatar class="clickAllowedSource">
        <ion-icon style="zoom: 2.0" name="list-circle-outline"></ion-icon>
      </ion-avatar>

      <ion-label class="clickAllowedSource"> {{ folder.name }} </ion-label>
      <ion-buttons slot="end">
        <ion-button (click)="openVideoOptions($event, folder, 'folder')" color="secondary" class="game-options-btn">
          <ion-icon slot="icon-only" name="ellipsis-horizontal"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-item>
  </ng-container>
  <ng-container *ngIf="isWatchList">
    <ion-item lines="full" *ngFor="let folder of (scoutingService.watchListFolders$ | async); let i = index"
              [color]="selectedWatchlistFolder?.id === folder.id ? 'alternative' : ''"
              (click)="selectFolderWatchlist(folder)"
              class="item-default clickAllowedSource">
      <ion-avatar class="clickAllowedSource">
        <img class="clickAllowedSource" src="/assets/svg/folder.svg" />
      </ion-avatar>

      <ion-label class="clickAllowedSource"> {{ folder.name }} </ion-label>
    </ion-item>
  </ng-container>
</div>
