import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { SuspensionToBenchActionTypes } from 'src/app/shared-services/actions/action-types';
import { UiEventDispatcherService } from 'src/app/shared-services/ui-event-dispatcher/ui-event-dispatcher.service';
import { SuspensionToBenchSelectedEvent } from 'src/app/shared-services/ui-event-dispatcher/events/ui-events';
import { EventTime, TeamMarker } from '@handballai/stats-calculation';
import { GameType } from 'src/app/shared-services/game/game-type.model';
import { extractTeamFirstLetters } from '../../../components/score-board/score-board.component';
import { CoreService } from 'src/app/shared-services/core.service';

@Component({
  selector: 'app-team-select-popup',
  templateUrl: './team-select-popup.component.html',
  styleUrls: ['./team-select-popup.component.scss'],
})
export class TeamSelectPopupComponent implements OnInit {

  extractTeamFirstLetters = extractTeamFirstLetters;
  public homeTeamName: string;
  public visitorTeamName: string;
  public homeTeamColor: string;
  public visitorTeamColor: string;
  public teamsSwapped = false;

  constructor(
    private readonly core: CoreService,
    private readonly popoverController: PopoverController,
  ) {
    this.homeTeamName = core.gameService.homeName$.value;
    this.visitorTeamName = core.gameService.visitorName$.value;
    this.loadTeamColors();
  }

  ngOnInit() { }

  swapTeams() {
    this.teamsSwapped = !this.teamsSwapped;
    this.core.gameService.swapTeams();
  }

  async loadTeamColors() {
    let homeTeam = await this.core.storageService.storage.get('HAI_HOME_TEAM');
    this.homeTeamColor = homeTeam.primaryColor;
    let visitorTeam = await this.core.storageService.storage.get('HAI_VISITOR_TEAM');
    this.visitorTeamColor = homeTeam.primaryColor === visitorTeam.primaryColor ? visitorTeam.secondaryColor : visitorTeam.primaryColor;
  }

  @Input()
  isGameStartSelect = true;


  public async onTeamSelected(teamMarker: TeamMarker): Promise<void> {
    await this.popoverController.dismiss({data: teamMarker});
  }

}
