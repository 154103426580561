export const zeroPad = (num, places) => String(Math.trunc(num)).padStart(places, '0');


export const timerFormatHelper = (timeInSec: number): string =>
    `${zeroPad(Math.floor(timeInSec / 60), 2)}:${zeroPad((timeInSec % 60), 2)}`;

export const formatTimeToHoursMinutesAndSeconds = (seconds: number): string => {
  seconds = parseInt(seconds.toFixed(0));
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hoursString = hours.toString().padStart(2, '0');
  const minutesString = minutes.toString().padStart(2, '0');
  const secondsString = remainingSeconds.toString().padStart(2, '0');

  return `${hoursString}:${minutesString}:${secondsString}`;
};

