import { Component, Input, OnInit } from '@angular/core';
import { PlayByPlayModel } from '@handballai/stats-calculation';
import { CsvDownloadService } from 'src/app/shared-services/download/csv-download.service';
import { DateTime } from 'luxon';
import { AdvancedStatPlayerModel } from '@handballai/stats-calculation';
import { GameService } from 'src/app/shared-services/game/game.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountEquipoService, TManageAccountEquipoGrants } from 'src/app/shared-services/account-equipo/account-equipo.service';
import { GameDto } from 'src/app/api/hai-api';
import { CoreService } from 'src/app/shared-services/core.service';
import { GameMode } from 'src/app/shared-services/game/game-modes.model';

@Component({
    selector: 'app-historic-play-by-play',
    templateUrl: './historic-play-by-play.component.html',
    styleUrls: ['./historic-play-by-play.component.scss'],
    standalone: false
})
export class HistoricPlayByPlayComponent implements OnInit {

  private _players: AdvancedStatPlayerModel[] = [];
  private _playByPlayRecords: PlayByPlayModel[];
  private _startTimeOfGame: string;
  private _game: GameDto;
  private _editable: boolean;
  private _userId = new BehaviorSubject<number>(null);

  private _selectedAccountEquipo$ = new Observable<TManageAccountEquipoGrants>(null);
  get selectedAccountEquipo$() {
      return this._selectedAccountEquipo$;
  }

  constructor(
      private readonly csvDownloadService: CsvDownloadService,
      private readonly accountEquipoService: AccountEquipoService,
      private readonly gameService: GameService,
      public readonly core: CoreService,
  ) {
    this._selectedAccountEquipo$ = this.accountEquipoService.selectedAccountEquipo$;
    this.core.storageService.getAccessTokenAsObject().then((val) => {this._userId.next(val.sub);})
  }

  ngOnInit(): void {
  }

  get userId(): Observable<number> {
    return this._userId;
  }
  get players(): AdvancedStatPlayerModel[] {
    return this._players;
  }

  @Input()
  set players(value: AdvancedStatPlayerModel[]) {
    if (value) {
      this._players = value;
    }
  }

  @Input()
  set game(value: GameDto) {
    this._game = value;
  }
  get game(): GameDto {
    return this._game;
  }

  @Input()
  set editable(value: boolean) {
    this._editable = value;
  }
  get editable(): boolean {
    return this._editable;
  }

  get playByPlayRecords(): PlayByPlayModel[] {
    return this._playByPlayRecords;
  }

  @Input()
  set playByPlayRecords(value: PlayByPlayModel[]) {
    if (value) {
      this._playByPlayRecords = value;
    }
  }

  get startTimeOfGame(): string {
    return this._startTimeOfGame;
  }

  @Input()
  set startTimeOfGame(value: string) {
    this._startTimeOfGame = value;
  }


  public async onCsvRequest() {
    await this.csvDownloadService.csvRequest(this.game.id);
    this.game.csvRequestedBy = this._userId.getValue();
  }

  public async onSaveCsvClicked(): Promise<void> {
    if (this.accountEquipoService.selectedAccountEquipo$.getValue().account?.permissions.askDownloadPlayByPlay) {
      await this.csvDownloadService.csvRequestConsume(this.game.id);
    }
    this.csvDownloadService.downloadFileAsCsv(
        DateTime.fromISO(this._startTimeOfGame),
        this._playByPlayRecords,
        `${this.gameService.gameModel.home.name} vs ${this.gameService.gameModel.visitor.name}`,
    );
  }
}
