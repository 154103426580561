import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { GameModel, GamePlayerModel } from '../model/game.model';
import { TimeOutEvent } from './events/time-out-event';
import { DefenseChangeEvent } from './events/defense-events';
import { SuspensionActionTypes } from '../actions/action-types';
import { CoreService } from '../core.service';
import { CompleteGameEngine } from 'src/app/shared-services/game/complete-game-engine';
import { LiteGameEngine } from 'src/app/shared-services/game/lite-game-engine';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DefenseSystem, TeamMarker } from '@handballai/stats-calculation';
import { Timeout } from 'src/app/shared-services/game/timeout';
import { IGameInterface } from 'src/app/shared-services/game/game-interface';
import { GameType } from 'src/app/shared-services/game/game-type.model';
import { TimerModel } from 'src/app/shared-services/timer/timer-model';
import * as Sentry from '@sentry/browser';

@Injectable({
    providedIn: 'root'
})
export class GameService implements IGameInterface {

    constructor(private readonly logger: NGXLogger) {
        // Assign a default game engine
        this._gameEngine = new CompleteGameEngine(this.logger);
    }

    get gameModel(): GameModel  {
        return this._gameEngine.gameModel;
    }

    get gameId(): number {
        return this._gameEngine.gameId;
    }

    set gameId(value: number) {
        this._gameEngine.gameId = value;
    }

    get gameHash(): string {
        return this._gameEngine.gameHash;
    }

    get gameHash$() {
        return this._gameEngine.gameHash$;
    }

    set gameHash(value: string) {
        this._gameEngine.gameHash = value;
    }

    get homeBench$(): BehaviorSubject<GamePlayerModel[]> {
        return this._gameEngine.homeBench$;
    }

    get homeDefenseSystem$(): BehaviorSubject<DefenseSystem> {
        return this._gameEngine.homeDefenseSystem$;
    }

    get homeField$(): BehaviorSubject<GamePlayerModel[]> {
        return this._gameEngine.homeField$;
    }

    get homePlayers$(): BehaviorSubject<GamePlayerModel[]> {
        return this._gameEngine.homePlayers$;
    }

    get homeGameSuspended$(): BehaviorSubject<GamePlayerModel[]> {
        return this._gameEngine.homeGameSuspended$;
    }

    get homeName$(): BehaviorSubject<string> {
        return this._gameEngine.homeName$;
    }

    get homeScore$(): BehaviorSubject<number> {
        return this._gameEngine.homeScore$;
    }

    get homeSuspended$(): BehaviorSubject<GamePlayerModel[]> {
        return this._gameEngine.homeSuspended$;
    }

    get homeTimeOuts$(): BehaviorSubject<Timeout[]> {
        return this._gameEngine.homeTimeOuts$;
    }

    get visitorBench$(): BehaviorSubject<GamePlayerModel[]> {
        return this._gameEngine.visitorBench$;
    }

    get visitorDefenseSystem$(): BehaviorSubject<DefenseSystem> {
        return this._gameEngine.visitorDefenseSystem$;
    }

    get visitorField$(): BehaviorSubject<GamePlayerModel[]> {
        return this._gameEngine.visitorField$;
    }

    get visitorPlayers$(): BehaviorSubject<GamePlayerModel[]> {
        return this._gameEngine.visitorPlayers$;
    }

    get visitorGameSuspended$(): BehaviorSubject<GamePlayerModel[]> {
        return this._gameEngine.visitorGameSuspended$;
    }

    get visitorName$(): BehaviorSubject<string> {
        return this._gameEngine.visitorName$;
    }

    get visitorScore$(): BehaviorSubject<number> {
        return this._gameEngine.visitorScore$;
    }

    get visitorSuspended$(): BehaviorSubject<GamePlayerModel[]> {
        return this._gameEngine.visitorSuspended$;
    }

    get visitorTimeOuts$(): BehaviorSubject<Timeout[]> {
        return this._gameEngine.visitorTimeOuts$;
    }

    get gameMode$(): BehaviorSubject<GameType> {
        return this._gameModeWithoutDollar;
    }

    get liveMode(): 'LIVE_MODE' | 'POST_LIVE_VIDEO_MODE' {
        return this._liveMode;
    }

    get swappedTeams(): boolean {
        return this._swappedTeams;
    }

    private _swappedTeams = false;

    private _core: CoreService;

    private _gameEngine: CompleteGameEngine | LiteGameEngine;

    private _gameMode: GameType = 'COMPLETE_MODE'; // Defaults to Complete Mode
    // We do not use the $ convention here, to avoid GameStateHelper to be confused with automatic
    // property detection
    private _gameModeWithoutDollar = new BehaviorSubject<GameType>('COMPLETE_MODE');
    private _liveMode: 'LIVE_MODE' | 'POST_LIVE_VIDEO_MODE';
    private _completeGameTimerModel$: Observable<TimerModel>;
    private _timerModelSubscription: Subscription;

    initCore(core: CoreService) {
        this._core = core;
    }

    public swapTeams() {
        this._swappedTeams = !this._swappedTeams;
    }

    initGame(gameModel: GameModel, gameMode: GameType, liveMode: 'LIVE_MODE' | 'POST_LIVE_VIDEO_MODE'): void {
        if (!gameModel.home || !gameModel.visitor) {
            Sentry.captureMessage('gameModel.home or gameModel.visitor null: ' + JSON.stringify(gameModel));
        } else {
            this._gameMode = gameMode;
            this._gameModeWithoutDollar.next(this._gameMode);
            this._gameEngine = this._gameMode === 'COMPLETE_MODE' ? new CompleteGameEngine(this.logger) : new LiteGameEngine(this.logger);
            this._timerModelSubscription?.unsubscribe();
            this._gameEngine.initGame(gameModel);
            this._gameEngine.initCore(this._core);
            this._liveMode = liveMode;
            // TODO Memory leak should be done now
            if (this._gameEngine && (this._gameMode === 'COMPLETE_MODE')) {
                this._completeGameTimerModel$ = this._core.handballTimerService.gameCounter$;
                this._completeGameTimerModel$.subscribe(timer => {
                    this.performClockTickEvent(timer);
                });
            }
        }

    }

    applyTimeOut(timeOutEvent: TimeOutEvent): void {
        this._gameEngine.applyTimeOut(timeOutEvent);
    }

    undoTimeOut(timeOutEvent: TimeOutEvent): void  {
        this._gameEngine.undoTimeOut(timeOutEvent);
    }

    enableAllowedTimeOuts(): void {
        this._gameEngine.enableAllowedTimeOuts();
    }

    resetTimeOut(): void {
        this._gameEngine.resetTimeOut();
    }

    changeDefenseSystem(defenseChangeEvent: DefenseChangeEvent): void {
        this._gameEngine.changeDefenseSystem(defenseChangeEvent);
    }

    changeOffenseSystem(
        homeOffenseSystem: string,
        visitorOffenseSystem: string
    ): void {
        this._gameEngine.changeOffenseSystem(homeOffenseSystem, visitorOffenseSystem);
    }

    toggleFieldHome(disable: boolean): void {
        this._gameEngine.toggleFieldHome(disable);
    }

    toggleFieldHomeWithPlayer(player: GamePlayerModel): void {
        this._gameEngine.toggleFieldHomeWithPlayer(player);
    }

    toggleBenchHome(disable: boolean): void {
        this._gameEngine.toggleBenchHome(disable);
    }

    toggleSuspensionsHome(disable: boolean): void {
        this._gameEngine.toggleSuspensionsHome(disable);
    }

    toggleFieldVisitor(disable: boolean): void {
        this._gameEngine.toggleFieldVisitor(disable);
    }

    toggleFieldVisitorWithPlayer(player: GamePlayerModel): void {
        this._gameEngine.toggleFieldVisitorWithPlayer(player);
    }

    toggleBenchVisitor(disable: boolean): void {
        this._gameEngine.toggleBenchVisitor(disable);
    }

    toggleSuspensionsVisitor(disable: boolean): void {
        this._gameEngine.toggleSuspensionsVisitor(disable);
    }

    exchangePlayerHome(
        newFieldPlayer: GamePlayerModel,
        newBenchPlayer: GamePlayerModel
    ): void {
        this._gameEngine.exchangePlayerHome(newFieldPlayer, newBenchPlayer);
    }

    exchangePlayerVisitor(
        newFieldPlayer: GamePlayerModel,
        newBenchPlayer: GamePlayerModel
    ): void {
        this._gameEngine.exchangePlayerVisitor(newFieldPlayer, newBenchPlayer);
    }

    incrementHomeScore(): void {
        this._gameEngine.incrementHomeScore();
    }

    incrementVisitorScore(): void {
        this._gameEngine.incrementVisitorScore();
    }

    decrementHomeScore(): void {
        this._gameEngine.decrementHomeScore();
    }

    decrementVisitorScore(): void {
        this._gameEngine.decrementVisitorScore();
    }

    putPlayerOnBenchHome(playerOnBench: GamePlayerModel): void {
        this._gameEngine.putPlayerOnBenchHome(playerOnBench);
    }

    putPlayerOnBenchVisitor(playerOnBench: GamePlayerModel): void {
        this._gameEngine.putPlayerOnBenchVisitor(playerOnBench);
    }

    putPos1PlayerOnBenchHome(
        playerOnBench: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): GamePlayerModel {
        return this._gameEngine.putPos1PlayerOnBenchHome(playerOnBench, suspensionType);
    }

    putPos1PlayerOnBenchVisitor(
        playerOnBench: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): GamePlayerModel {
        return this._gameEngine.putPos1PlayerOnBenchVisitor(playerOnBench, suspensionType);
    }

    substitutePlayerFromBenchHome(
        substitutePlayer: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        this._gameEngine.substitutePlayerFromBenchHome(substitutePlayer, suspensionType);
    }

    substitutePlayerFromBenchVisitor(
        substitutePlayer: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        this._gameEngine.substitutePlayerFromBenchVisitor(substitutePlayer, suspensionType);
    }

    bringBackPlayerAfterSuspensionHome(newFieldPlayer: GamePlayerModel): void {
        this._gameEngine.bringBackPlayerAfterSuspensionHome(newFieldPlayer);
    }

    bringBackPlayerAfterSuspensionVisitor(newFieldPlayer: GamePlayerModel): void {
        this._gameEngine.bringBackPlayerAfterSuspensionVisitor(newFieldPlayer);
    }

    undoPlayerAfterSuspensionHome(newFieldPlayer: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        this._gameEngine.undoPlayerAfterSuspensionHome(newFieldPlayer, suspensionType);
    }

    undoPlayerAfterSuspensionVisitor(newFieldPlayer: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        this._gameEngine.undoPlayerAfterSuspensionVisitor(newFieldPlayer, suspensionType);
    }

    undoPos1AfterSuspensionHome(suspendedPos1: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        this._gameEngine.undoPos1AfterSuspensionHome(suspendedPos1, suspensionType);
    }

    undoPos1AfterSuspensionVisitor(suspendedPos1: GamePlayerModel, suspensionType: SuspensionActionTypes): void {
        this._gameEngine.undoPos1AfterSuspensionVisitor(suspendedPos1, suspensionType);
    }

    undoPos1SuspensionSubstituteHome(suspendedSubstitute: GamePlayerModel): void {
        this._gameEngine.undoPos1SuspensionSubstituteHome(suspendedSubstitute);
    }

    undoPos1SuspensionSubstituteVisitor(suspendedSubstitute: GamePlayerModel): void {
        this._gameEngine.undoPos1SuspensionSubstituteVisitor(suspendedSubstitute);
    }

    putPlayerOnGameSuspensionHome(playerOnGameSuspension: GamePlayerModel): void {
        this._gameEngine.putPlayerOnGameSuspensionHome(playerOnGameSuspension);
    }

    putPlayerOnGameSuspensionVisitor(playerOnGameSuspension: GamePlayerModel): void {
        this._gameEngine.putPlayerOnGameSuspensionVisitor(playerOnGameSuspension);
    }

    bringBackSubstituteForGameSuspendedPlayerHome(
        playerSubstituted: GamePlayerModel,
        playerMovedOut: GamePlayerModel
    ): void {
        this._gameEngine.bringBackSubstituteForGameSuspendedPlayerHome(
            playerSubstituted,
            playerMovedOut
        );
    }

    bringBackSubstituteForGameSuspendedPlayerVisitor(
        playerSubstituted: GamePlayerModel,
        playerMovedOut: GamePlayerModel
    ): void {
        this._gameEngine.bringBackSubstituteForGameSuspendedPlayerVisitor(playerSubstituted, playerMovedOut);
    }

    swapFieldPlayerHome(
        firstPlayer: GamePlayerModel,
        secondPlayer: GamePlayerModel
    ): void {
        this._gameEngine.swapFieldPlayerHome(firstPlayer, secondPlayer);
    }

    continueTrackingRemoveGameSuspendedPlayer(playerId: number, team: TeamMarker) {
        this._gameEngine.continueTrackingRemoveGameSuspendedPlayer(playerId, team);
    }
    continueTrackingSetPlayerPenalty(benchPlayer: GamePlayerModel, team: TeamMarker) {
        this._gameEngine.continueTrackingSetPlayerPenalty(benchPlayer, team);
    }

    replaceFieldPlayers(homeFieldPlayerIds: number[], visitorFieldPlayerIds: number[]) {
        this._gameEngine.replaceFieldPlayers(homeFieldPlayerIds, visitorFieldPlayerIds);
    }

    swapFieldPlayerVisitor(
        firstPlayer: GamePlayerModel,
        secondPlayer: GamePlayerModel
    ): void {
        this._gameEngine.swapFieldPlayerVisitor(firstPlayer, secondPlayer);
    }

    putPlayerOnBenchHomeWithoutPenalty(playerOnBench: GamePlayerModel): void {
        this._gameEngine.putPlayerOnBenchHomeWithoutPenalty(playerOnBench);
    }

    putPlayerOnBenchVisitorWithoutPenalty(playerOnBench: GamePlayerModel): void {
        this._gameEngine.putPlayerOnBenchVisitorWithoutPenalty(playerOnBench);
    }

    undoPlayerInGameHomeAfterPenalty(
        playerOnSuspension: GamePlayerModel,
        playerSelectedToGoBack: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        this._gameEngine.undoPlayerInGameHomeAfterPenalty(playerOnSuspension, playerSelectedToGoBack, suspensionType);
    }

    undoPlayerInGameVisitorAfterPenalty(
        playerOnSuspension: GamePlayerModel,
        playerSelectedToGoBack: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        this._gameEngine.undoPlayerInGameVisitorAfterPenalty(playerOnSuspension, playerSelectedToGoBack, suspensionType);
    }

    cancelPlayerSubstitutionPopupHome(
        playerOnSuspension: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        this._gameEngine.cancelPlayerSubstitutionPopupHome(playerOnSuspension, suspensionType);
    }

    cancelPlayerSubstitutionPopupVisitor(
        playerOnSuspension: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        this._gameEngine.cancelPlayerSubstitutionPopupVisitor(playerOnSuspension, suspensionType);
    }

    swapGoalkeeperHome(firstPlayer: GamePlayerModel, secondPlayer: GamePlayerModel): void {
        this._gameEngine.swapGoalkeeperHome(firstPlayer, secondPlayer);
    }

    swapGoalkeeperVisitor(firstPlayer: GamePlayerModel, secondPlayer: GamePlayerModel): void {
        this._gameEngine.swapGoalkeeperVisitor(firstPlayer, secondPlayer);
    }

    toggleSecondGoalkeeperHome(): void {
        this._gameEngine.toggleSecondGoalkeeperHome();
    }

    toggleSecondGoalkeeperVisitor(): void {
        this._gameEngine.toggleSecondGoalkeeperVisitor();
    }

    putPlayerOnBenchHomeLite(playerOnBench: GamePlayerModel, suspensionType: SuspensionActionTypes) {
        this._gameEngine.putPlayerOnBenchHomeLite(playerOnBench, suspensionType);
    }

    putPlayerOnBenchVisitorLite(playerOnBench: GamePlayerModel, suspensionType: SuspensionActionTypes) {
        this._gameEngine.putPlayerOnBenchVisitorLite(playerOnBench, suspensionType);
    }

    swapPlayerForFreeSlotIdHome(player: GamePlayerModel, slotId: number): void {
        this._gameEngine.swapPlayerForFreeSlotIdHome(player, slotId);
    }

    swapPlayerForFreeSlotIdVisitor(player: GamePlayerModel, slotId: number): void {
        this._gameEngine.swapPlayerForFreeSlotIdVisitor(player, slotId);
    }

    undoPos1PlayerSuspensionLiteHome(
        playerOnSuspension: GamePlayerModel,
        playerSelectedToGoBack: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        this._gameEngine.undoPos1PlayerSuspensionLiteHome(
            playerOnSuspension,
            playerSelectedToGoBack,
            suspensionType
        );
    }

    undoPos1PlayerSuspensionLiteVisitor(
        playerOnSuspension: GamePlayerModel,
        playerSelectedToGoBack: GamePlayerModel,
        suspensionType: SuspensionActionTypes
    ): void {
        this._gameEngine.undoPos1PlayerSuspensionLiteVisitor(
            playerOnSuspension,
            playerSelectedToGoBack,
            suspensionType
        );
    }

    homeEmptyGoal(): boolean {
        return this._gameEngine.homeEmptyGoal();
    }

    visitorEmptyGoal(): boolean {
        return this._gameEngine.visitorEmptyGoal();
    }

    swapGoalAdditionalPlayerWithGoalkeeperOnBenchHome(): void {
        this._gameEngine.swapGoalAdditionalPlayerWithGoalkeeperOnBenchHome();
    }
    swapGoalAdditionalPlayerWithGoalkeeperOnBenchVisitor(): void {
        this._gameEngine.swapGoalAdditionalPlayerWithGoalkeeperOnBenchVisitor();
    }

    performClockTickEvent(timer: TimerModel) {
        this._gameEngine.performClockTickEvent(timer);
    }

    deleteVideoSuspensionEventHome(
        playerId: number,
        suspensionType: SuspensionActionTypes,
        suspendedPlayerId: number
    ): boolean {
        return this._gameEngine.deleteVideoSuspensionEventHome(playerId, suspensionType, suspendedPlayerId);
    }

    deleteVideoSuspensionEventVisitor(
        playerId: number,
        suspensionType: SuspensionActionTypes,
        suspendedPlayerId: number
    ): boolean {
        return this._gameEngine.deleteVideoSuspensionEventVisitor(playerId, suspensionType, suspendedPlayerId);
    }

}
