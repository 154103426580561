import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import {
  ExternalAccountEquiposService,
  ExternalGamesService,
  ExternalStatsService,
  ExternalXpsService,
  FilePartDto,
  FilePropsDto,
  GameDto,
  GameFilterDto,
  GameFolderDto,
  GameSystemCategoryDto,
  GameSystemDto,
  GameTrackersDto,
  HalftimestampsDto,
  LineupStatsDto,
  PlayByPlayDto,
  PlayerStatsDto,
  ScoutingPlayByPlayDto,
  TeamsStatsDto,
  UpdateGameResultDto,
  XpsGameResponseDto,
} from 'src/app/api/hai-api';
import { GameModel } from "src/app/shared-services/model/game.model";
import { PlayByPlayModel } from "@handballai/stats-calculation";
import { PlaytimeEventModel } from "@handballai/stats-calculation";
import { catchError, concatMap, filter, finalize, map, tap } from 'rxjs/operators';
import { DateTime } from "luxon";
import {
  transformGameModelToGameDto,
  transformPlayByPlayToServerModel,
  transformPlayTimeEventsToServerModel,
} from "src/app/shared-services/game-data/game-data.helper";
import { ToastController } from "@ionic/angular";
import { BehaviorSubject, firstValueFrom, interval, Observable, of, Subscription } from 'rxjs';
import { GameFilterTableModel } from "src/app/shared-services/game-data/model/game-filter-table.model";
import { loggerFn } from "src/app/shared-services/helper/http-helper";
import { Router } from "@angular/router";
import { CoreService } from "../core.service";
import { GameType } from "src/app/shared-services/game/game-type.model";
import { db } from "src/app/db";
import { GameMode } from "../game/game-modes.model";
import { GameStatus } from "../game/game-status.model";
import { VideoUploadProgressStackModel } from "./model/video-upload-progress.model";
import { VideoStatus } from "../game/video-status.model";
import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from "@angular/common/http";
import { ScoutingFilters } from "src/app/main/pages/aehandler-module/pages/scouting-module/components/your-search/video-modal/video-modal.component";
import * as Sentry from '@sentry/browser';
import { VideoTrackerService } from '../statistics/video/video-tracker.service';
import { HandballTimerService } from "../timer/handball-timer.service";
import { generateHashFromBlob, generateVideoChallengesFromBlob } from "src/app/main/pages/aehandler-module/pages/game-module/pages/search-games/scouting-connect.helper";


function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response
}

function isHttpUploadProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (event.type === HttpEventType.UploadProgress)
}
function isHttpDownloadProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (event.type === HttpEventType.DownloadProgress)
}

export interface GeneratedGameStats {
  teamsStats: TeamsStatsDto[];
  playerStats: PlayerStatsDto[];
  lineupStats: LineupStatsDto[];
}

@Injectable({
  providedIn: "root",
})
export class GameDataService {
  private _playByPlayUploadQueue: Map<number, PlayByPlayModel>;
  private _playTimeRecordUploadQueue: Map<number, PlaytimeEventModel>;
  private timerSource$: Observable<number>;
  private timerSubscription: Subscription;
  private gameRunning: GameDto;

  private _existGameWithTaggingMode = false;
  private gameFolders: GameFolderDto[] = [];
  private _videoUpload$ = new BehaviorSubject<VideoUploadProgressStackModel>({progress: 100, uploads:[]});
  private _gameSystems$ = new BehaviorSubject<GameSystemCategoryDto[]>([]);
  private _gameFolders$ = new BehaviorSubject<GameFolderDto[]>([]);
  private _gameSystemEnabled$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly logger: NGXLogger,
    private readonly externalAccountEquipoService: ExternalAccountEquiposService,
    private readonly externalGameService: ExternalGamesService,
    private readonly toastCntl: ToastController,
    private readonly router: Router,
    private readonly externalXpsService: ExternalXpsService,
    private readonly externalStatsService: ExternalStatsService,
    private readonly videoTrackerService: VideoTrackerService,
    private readonly handballTimerService: HandballTimerService
  ) {}

  get existGameWithTaggingMode(): boolean {
    return this._existGameWithTaggingMode;
  }

  set existGameWithTaggingMode(value: boolean) {
    this._existGameWithTaggingMode = value;
  }

  get playByPlayUploadQueue(): Map<number, PlayByPlayModel> {
    return this._playByPlayUploadQueue;
  }
  get playTimeRecordUploadQueue(): Map<number, PlaytimeEventModel> {
    return this._playTimeRecordUploadQueue;
  }

  get videoUploadRawSnapshot(): VideoUploadProgressStackModel {
    return this._videoUpload$.value;
  }
  get videoUpload$(): Observable<VideoUploadProgressStackModel> {
    return this._videoUpload$.pipe(map(tick => {
      let progress=0, i=0;
      for (const gameId in this._videoUpload$.value.uploads) {
        const upload = this._videoUpload$.value.uploads[gameId];
        if (upload == undefined) {
          delete this._videoUpload$.value.uploads[gameId];
        } else if (upload.status == 'uploading') {
          progress += upload.progress;
          i++;
        }
      }
      tick.progress = this._videoUpload$.value.progress = progress / i;
      return tick;
    }));
  }

  get gameFilter$(): BehaviorSubject<GameFilterTableModel[]> {
    return this._gameFilter$;
  }

  get game$(): BehaviorSubject<GameDto> {
    return this._game$;
  }

  get gameFolders$(): BehaviorSubject<GameFolderDto[]> {
    return this._gameFolders$;
  }

  get gameSystems$(): BehaviorSubject<GameSystemCategoryDto[]> {
    return this._gameSystems$;
  }

  get playingPenalties(): boolean {
    return this.videoTrackerService.gameCounter$.value.halfTime === 'P' || this.handballTimerService.gameCounter$.value.halfTime === 'P';
  }

  public get playTimeQueue(): PlaytimeEventModel[] {
    return Array.from(this._playTimeRecordUploadQueue.values());
  }
  public get playByPlayQueue(): PlayByPlayModel[] {
    return Array.from(this._playByPlayUploadQueue.values());
  }

  get gameSystemEnabled$(): BehaviorSubject<boolean> {
    return this._gameSystemEnabled$;
  }

  public getGameIdRunning() {
    return this.gameRunning?.id;
  }
  public getGameRunning() {
    return this.gameRunning;
  }

  private _core: CoreService;

  private _gameFilter$ = new BehaviorSubject<GameFilterTableModel[]>([]);
  private _game$ = new BehaviorSubject<GameDto>(null);
  public historicGameRecalculationReady$ = new BehaviorSubject<boolean>(false);
  initCore(core: CoreService) {
    this._core = core;
  }

  async validateScoutingConnectVideo(gid: number, file: Blob): Promise<void> {
    const aid = await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    const user = await this._core.storageService.getUserAsPromise();
    const challenge = await firstValueFrom(this.externalAccountEquipoService.accountEquipoHandlerControllerGetVideoChallenge(`${user.id}`, `${aid}`, `${gid}`));
    console.log('VIDEO: challenge', challenge);
    const resultHash = await generateHashFromBlob(file, challenge.sliceStart / 100, challenge.sliceEnd / 100);
    const cres = await firstValueFrom(this.externalAccountEquipoService.accountEquipoHandlerControllerValidateVideoChallenge(`${user.id}`, `${aid}`, `${gid}`, resultHash));
    console.log('VIDEO: challenge response', cres);
  }

  async patchScoutingVideoHashFromBlob(gid: number, file: Blob): Promise<void> {
    const aid = await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    const user = await this._core.storageService.getUserAsPromise();
    const fileProps: FilePropsDto = { fileName: 'generate-challenge', mimetype: file.type, size: file.size, challenges: await generateVideoChallengesFromBlob(file) };
    console.log('VIDEO: FileProps', fileProps);
    const gameConfirm = (await firstValueFrom(this.externalAccountEquipoService.accountEquipoHandlerControllerPostVideoChallenges(`${user.id}`, `${aid}`, `${gid}`, fileProps)));
    console.log('VIDEO: Response event', gameConfirm);
  }



  videoUpload(gid: number, file: File) {
    const FILE_CHUNK_SIZE = 4.5 * 1024 * 1024 * 1024;
    const fileSize = file.size;
    const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;
    let start, end, blob;

    return new Promise<HttpResponse<unknown>>(async (ok, ko) => {
      try {
        const aid = await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
        const user = await this._core.storageService.getUserAsPromise();
        this._videoUpload$.next({
          ...this._videoUpload$.value,
          uploads: {
            ...this._videoUpload$.value.uploads,
            [gid]: {gameId: gid, status: 'uploading', progress: 0, remainingSecsEstimate: -1, scheduleOnFinish: false, file},
          },
        });

        const challenges = this._core.userService.checkForBoolPermission("scoutingConnect", true, false)
          ? await generateVideoChallengesFromBlob(file)
          : undefined;

        const fileProps: FilePropsDto = { fileName: file.name, mimetype: file.type, size: file.size, challenges };
        const uploadId = (await firstValueFrom(this.externalAccountEquipoService.accountEquipoHandlerControllerStartUploadGameVideo(`${user.id}`, `${aid}`, `${gid}`, fileProps))).videoUploadId;
        const uploadPartsArray: FilePartDto[] = [];

        setTimeout(async () => {
          for (let index = 1; index < NUM_CHUNKS + 1; index++) {
            start = (index - 1) * FILE_CHUNK_SIZE;
            end = (index) * FILE_CHUNK_SIZE;
            blob = (index < NUM_CHUNKS) ? file.slice(start, end) : file.slice(start);

            const videoUrl = (await firstValueFrom(this.externalAccountEquipoService.accountEquipoHandlerControllerUploadGameVideo(`${user.id}`, `${aid}`, `${gid}`, uploadId, index.toString(), fileProps))).videoUploadUrl;
            this._core.http.put(videoUrl, blob, {headers: {'ngsw-bypass': 'true', 'prevent-auth':'true'}, observe: 'events', reportProgress: true}).subscribe({
              next: async (ev) => {
                if (isHttpResponse(ev)) {
                  try {
                    uploadPartsArray.push({
                      ETag: ev.headers.get('ETag').replace(/[|&;$%@"<>()+,]/g, ''),
                      PartNumber: index
                    });
                    console.warn('Added part, '+uploadPartsArray.length + 'of' + NUM_CHUNKS);

                    if (uploadPartsArray.length >= NUM_CHUNKS) {
                      await firstValueFrom(this.externalAccountEquipoService.accountEquipoHandlerControllerUploadGameVideoConfirm(`${user.id}`, `${aid}`, `${gid}`, {
                        status: this._videoUpload$.value.uploads[gid].scheduleOnFinish?'scheduled':'aligning',
                        parts: uploadPartsArray,
                        uploadId,
                      }));
                      // console.log('VIDEO: Response event', ev);
                      this._videoUpload$.next({
                        ...this._videoUpload$.value,
                        uploads: {
                          ...this._videoUpload$.value.uploads,
                          [gid]: {gameId: gid, status: 'done', progress: 100, remainingSecsEstimate: 0, scheduleOnFinish: this._videoUpload$.value.uploads[gid].scheduleOnFinish, file},
                        },
                      });
                      setTimeout(() => {
                        this._videoUpload$.next({
                          ...this._videoUpload$.value,
                          uploads: {
                            ...this._videoUpload$.value.uploads,
                            [gid]: undefined,
                          },
                        });
                      }, 5000);
                      ok(ev);
                    }
                  } catch (err) {
                    console.error('Error on confirm', err);
                    this._videoUpload$.next({
                      ...this._videoUpload$.value,
                      uploads: {
                        ...this._videoUpload$.value.uploads,
                        [gid]: {gameId: gid, status: 'error', progress: 100, remainingSecsEstimate: 0, scheduleOnFinish: this._videoUpload$.value.uploads[gid].scheduleOnFinish, file},
                      },
                    });
                    ko(err);
                  }

                } else if (isHttpUploadProgressEvent(ev)) {
                  // console.log('VIDEO: Progress event', ev);
                  this._videoUpload$.next({
                    ...this._videoUpload$.value,
                    uploads: {
                      ...this._videoUpload$.value.uploads,
                      [gid]: {gameId: gid, status: 'uploading', progress: ev.loaded/ev.total, remainingSecsEstimate: -1, scheduleOnFinish: false, file},
                    },
                  });

                } else if (isHttpDownloadProgressEvent(ev)) {
                  // console.log('VIDEO: Progress event', ev);
                  const tick = this._videoUpload$.value;
                  tick.uploads[gid]
                  this._videoUpload$.next({
                    ...this._videoUpload$.value,
                    uploads: {
                      ...this._videoUpload$.value.uploads,
                      [gid]: {gameId: gid, status: 'persisting', progress: 100, remainingSecsEstimate: -1, scheduleOnFinish: false, file},
                    },
                  });
                }

              },
              error: (err) => {
                console.error(err);
                    this._videoUpload$.next({
                      ...this._videoUpload$.value,
                      uploads: {
                        ...this._videoUpload$.value.uploads,
                        [gid]: {gameId: gid, status: 'error', progress: 100, remainingSecsEstimate: 0, scheduleOnFinish: false, file},
                      },
                    });
                ko(err);
              },
            });
          }
        }, 4000);
      } catch (err) {
        console.error(err);
        this._videoUpload$.next({
          ...this._videoUpload$.value,
          uploads: {
            ...this._videoUpload$.value.uploads,
            [gid]: {gameId: gid, status: 'error', progress: 100, remainingSecsEstimate: 0, scheduleOnFinish: false, file},
          },
        });
        ko(err);
      }
    });
  }

  /* IN GAME UTILITIES */

  public async startNewGame(
    game: GameDto,
    playTimeRecords: PlaytimeEventModel[]
  ): Promise<void> {
    this.gameRunning = game;
    Sentry.setTag('gameId', game.id);
    this.createWss();
    this._playByPlayUploadQueue = new Map<number, PlayByPlayModel>();
    this._playTimeRecordUploadQueue = new Map<number, PlaytimeEventModel>();
    this._game$.next(null);

    // Read game system for use in realtime game tracking
    // we want to have it loaded at the start time of the game

    // await this.readGameSystems();
    // await this.getGameSystemEnabledFlag();

    // Create IndexedDB Game record
    const gameLocalEntity = {
      srvId: game.id,
      isUploadPending: 1,
      ...game,
    };
    // Ensure no extra data (ommited ones at indexed db model interface) saved
    delete gameLocalEntity.id;
    delete gameLocalEntity.playByPlayDto;
    delete gameLocalEntity.playTimeDto;
    await db.game.add(gameLocalEntity);
    this.addPlayTimeRecord(game.id, playTimeRecords);

    // this.startGamePushing();
  }

  public async createWss() {
    await this._core.websocketService.connectGamePush();
  }

  private intervalWorking = new BehaviorSubject<boolean>(false);
  public startGamePushing(): void {
    return;
    if (this.timerSource$ || this.timerSubscription) return;
    this.timerSource$ = interval(10000);
    this.timerSubscription = this.timerSource$.subscribe(async () => {
      if (this.intervalWorking.getValue() === true) return;
      try {
        this.intervalWorking.next(true);

        this.logger.debug(
          `GameDataService update trigger fired for ${this._playByPlayUploadQueue.size} pbp entries and ${this._playTimeRecordUploadQueue.size} pbt entries`,
          DateTime.now()
        );
        if (this._playByPlayUploadQueue.size > 0) {
          const tempPbpContent = Array.from(
            this._playByPlayUploadQueue.values()
          );
          try {
            await this.pushPlayByPlayToServer(
              this.getGameIdRunning(),
              tempPbpContent
            );
            tempPbpContent.reduce((pbpMap, curr) => {
              pbpMap.delete(curr.orderId);
              return pbpMap;
            }, this._playByPlayUploadQueue);
            // TODO: Store server ids or boolean flag to related indexedDB elements (not functional stuff)
          } catch (exc) {
            this.logger.error(
              "GameDataService - Error while uploading playByPlayRecord to server",
              exc
            );
          }
        }
        if (this._playTimeRecordUploadQueue.size > 0) {
          const tempPltContent = Array.from(
            this._playTimeRecordUploadQueue.values()
          );
          try {
            await this.pushPlayTimeRecordToServer(
              this.getGameIdRunning(),
              tempPltContent
            );
            tempPltContent.reduce((pltMap, curr) => {
              pltMap.delete(curr.seq);
              return pltMap;
            }, this._playTimeRecordUploadQueue);
            // TODO: Store server ids or boolean flag to related indexedDB elements (not functional stuff)
          } catch (exc) {
            this.logger.error(
              "GameDataService - Error while uploading playTimeRecord to server",
              exc
            );
          }
        }
      } catch (err) {
        this.logger.error("Interval unhandled error", err);
      } finally {
        this.intervalWorking.next(false);
      }
    });
  }

  public async stopGamePushing(): Promise<void> {
    return new Promise<void>((ok, ko) => {
      // Maybe there is a just sent message pending to ack that can cause duplicates
      this._core.websocketService.disconnect();
      // Old timer system
      this.timerSubscription?.unsubscribe();
      this.timerSource$ = undefined;
      if (this.intervalWorking.getValue() === true) {
        this.intervalWorking.subscribe((val) => {
          if (val === false) ok();
        });
        // Safety check for unafourtunately threads interfoliation
        if (this.intervalWorking.getValue() === false) ok();
      } else {
        ok();
      }
    });
  }

  public async addPlayPlayByPlayToGame(
    gameId: number,
    playByPlayRecords: PlayByPlayModel[]
  ): Promise<void> {
    for (const record of playByPlayRecords) {
      if (!this._playByPlayUploadQueue.has(record.orderId)) {
        // Create IndexedDB PlayPlayByPlayToGame record
        const pbpDto = transformPlayByPlayToServerModel([record])[0];

        record.indexedDbId = await db.playByPlay.add({
          // id?: number,
          srvId: false,
          gameSrvId: gameId,
          ...pbpDto,
        });

        this._playByPlayUploadQueue.set(record.orderId, record);
      }
    }
    this._core.websocketService.gamePushSyncTick();
  }

  public async addPlayTimeRecord(
    gameId: number,
    playTimeRecords: PlaytimeEventModel[]
  ): Promise<void> {
    for (const record of playTimeRecords) {
      if (!this._playTimeRecordUploadQueue.has(record.seq)) {
        // Create IndexedDB PlayTimeRecord record
        const ptDto = transformPlayTimeEventsToServerModel([record])[0];
        record.indexedDbId = await db.playTime.add({
          // id?: number,
          srvId: false,
          gameSrvId: gameId,
          ...ptDto,
        });

        this._playTimeRecordUploadQueue.set(record.seq, record);
      }
    }
  }

  /* EXTERNAL ACCESS METHODS */

  public async readGamesForFilter(gameFolder: string, selectedSeason?: number, firstInvolvedTeam?: string, date?: string): Promise<void> {
    this.logger.debug("GameDataService.saveGame() - read games for filter");
    await this._core.loadingService.present();
    const aid =
      await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    this._core.storageService
      .getUserAsObservable()
      .pipe(
        concatMap((user) =>
          this.externalAccountEquipoService.accountEquipoHandlerControllerReadGames(
            user.id.toString(),
            aid.toString(),
            selectedSeason ? selectedSeason.toString() : this._core.seasonService.selectedSeason$.value.id + "",
            gameFolder,
            firstInvolvedTeam,
            null,
            date
          )
        ),
        loggerFn(this.logger, "GameDataService.readGamesForFilter - before:"),
        map((games) =>
          games.map((g) => ({
            date: DateTime.fromISO(g.date).toLocaleString(DateTime.DATE_MED),
            result: `${g.goalsHome} : ${g.goalsVisitor}`,
            accessHash: g.accessHash,
            home: g.home,
            visitor: g.visitor,
            homeId: g.homeId,
            visitorId: g.visitorId,
            folderId: g.folderId,
            id: g.id,
            gameType: g.gameType as GameType,
            gameMode: g.gameMode as GameMode,
            gameStatus: g.gameStatus as GameStatus,
            videoStatus: g.videoStatus as VideoStatus,
            videoConnected: g.videoConnected,
            goalsHome: g.goalsHome,
            goalsVisitor: g.goalsVisitor
          }))
        ),
        loggerFn(this.logger, "GameDataService.readGamesForFilter - after:"),
        catchError(err => {
          this.logger.error(err);
          return of(null);
        }),
        finalize(async () => await this._core.loadingService.dismiss())
      )
      .subscribe((games) => this._gameFilter$.next(games));
  }

  public readGamesForUidAccountEquipoId(
    uid: string,
    aid: string,

  ): Observable<GameFilterDto[]> {
    this.logger.debug(
      "GameDataService.readGamesForUidAccountEquipoId() - read games for UserId Account Equipo Id"
    );
    return this.externalAccountEquipoService.accountEquipoHandlerControllerReadGames(
      uid,
      aid,
      this._core.seasonService.selectedSeason$.value.id.toString()
    );
  }

  public async readGameSystems(showLoading=true): Promise<GameSystemCategoryDto[]> {
    this.logger.debug("GameDataService.readGameSystems() - read games for filter");
    if (showLoading) await this._core.loadingService.present();
    const aid =
      await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    return new Promise((resolve, reject) => {
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerGetGameSystem(
              user.id.toString(),
              aid.toString()
            )
          ),
          loggerFn(this.logger, "GameDataService.readGameSystems:"),
          finalize(
            async () =>
              showLoading && (await this._core.loadingService.dismiss())
          ),
          catchError(err => {
            this.logger.error(err);
            return of(null);
          })
        )
        .subscribe(
          (games) => {
            if (games) {
              this._gameSystems$.next(games);
            }
            resolve(games);
          },
          (error) => reject(error)
        );
    });
  }

  public async createOrUpdateGameSystems(
    gameSystemCategoryId: string,
    gameSystem: GameSystemDto
  ): Promise<GameSystemCategoryDto[]> {
    return new Promise(async (resolve, reject) => {
      this.logger.debug(
        "GameDataService.createOrUpdateGameSystems() - read games for filter"
      );
      await this._core.loadingService.present();
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            gameSystem.id
              ? this.externalAccountEquipoService.accountEquipoHandlerControllerUpdateGameSystem(
                  user.id.toString(),
                  aid.toString(),
                  `${gameSystem.id}`,
                  gameSystemCategoryId,
                  gameSystem
                )
              : this.externalAccountEquipoService.accountEquipoHandlerControllerCreateGameSystem(
                  user.id.toString(),
                  aid.toString(),
                  gameSystemCategoryId,
                  gameSystem
                )
          ),
          loggerFn(this.logger, "GameDataService.createOrUpdateGameSystems:"),
          finalize(async () => await this._core.loadingService.dismiss())
        )
        .subscribe((games) => {
          this._gameSystems$.next(games);
          resolve(games);
        }, err => reject(err));
    });
  }

  public async deleteAllGameSystem(): Promise<void> {
    this.logger.debug(
      "GameDataService.createOrUpdateGameSystems() - read games for filter"
    );
    await this._core.loadingService.present();
    const aid =
      await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    this._core.storageService
      .getUserAsObservable()
      .pipe(
        concatMap((user) =>
          this.externalAccountEquipoService.accountEquipoHandlerControllerDeleteAllGameSystem(
            user.id.toString(),
            aid.toString()
          )
        ),
        loggerFn(this.logger, "GameDataService.createOrUpdateGameSystems:"),
        finalize(async () => await this._core.loadingService.dismiss())
      )
      .subscribe((games) => this._gameSystems$.next(games));
  }

  public async createOrUpdateGameSystemCategory(
    gameSystemCat: GameSystemCategoryDto
  ): Promise<void> {
    this.logger.debug(
      "GameDataService.createOrUpdateGameSystems() - read games for filter"
    );
    await this._core.loadingService.present();
    const aid =
      await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    this._core.storageService
      .getUserAsObservable()
      .pipe(
        concatMap((user) =>
          gameSystemCat.id
            ? this.externalAccountEquipoService.accountEquipoHandlerControllerUpdateGameSystemCategory(
                user.id.toString(),
                aid.toString(),
                `${gameSystemCat.id}`,
                gameSystemCat
              )
            : this.externalAccountEquipoService.accountEquipoHandlerControllerCreateGameSystemCategory(
                user.id.toString(),
                aid.toString(),
                gameSystemCat
              )
        ),
        loggerFn(this.logger, "GameDataService.createOrUpdateGameSystems:"),
        finalize(async () => await this._core.loadingService.dismiss())
      )
      .subscribe((games) => this._gameSystems$.next(games));
  }

  public async deleteGameSystems(
    gameSystemCategoryId: string,
    gameSystemId: string
  ): Promise<GameSystemCategoryDto[]> {
    return new Promise(async (resolve, reject) => {
      this.logger.debug("GameDataService.deleteGameSystems()");
      await this._core.loadingService.present();
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerDeleteGameSystem(
              user.id.toString(),
              aid.toString(),
              gameSystemCategoryId,
              gameSystemId
            )
          ),
          loggerFn(this.logger, "GameDataService.deleteGameSystems:"),
          finalize(async () => await this._core.loadingService.dismiss())
        )
        .subscribe((games) => {
          this._gameSystems$.next(games);
          resolve(games);
        }, err => reject(err));
    });
  }

  public async deleteGameSystemCategory(
    gameSystemCategoryId: string
  ): Promise<GameSystemCategoryDto[]> {
    return new Promise(async (resolve, reject) => {
      this.logger.debug("GameDataService.deleteGameSystems()");
      await this._core.loadingService.present();
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerDeleteGameSystemCategory(
              user.id.toString(),
              aid.toString(),
              gameSystemCategoryId
            )
          ),
          loggerFn(this.logger, "GameDataService.deleteGameSystemCategory:"),
          finalize(async () => await this._core.loadingService.dismiss())
        )
        .subscribe((games) => {
          this._gameSystems$.next(games);
          resolve(games);
        }, err => reject(err));
    });
  }

  public async createDefaultGameSystems(): Promise<void> {
    this.logger.debug("GameDataService.createDefaultGameSystems()");
    await this._core.loadingService.present();
    const aid =
      await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    this._core.storageService
      .getUserAsObservable()
      .pipe(
        concatMap((user) =>
          this.externalAccountEquipoService.accountEquipoHandlerControllerCreateDefaultGameSystem(
            user.id.toString(),
            aid.toString()
          )
        ),
        loggerFn(this.logger, "GameDataService.createDefaultGameSystems"),
        finalize(async () => await this._core.loadingService.dismiss())
      )
      .subscribe((games) => this._gameSystems$.next(games));
  }

  public async getGameSystemEnabledFlag(showLoading=true): Promise<void> {
    this.logger.debug('GameDataService.getGameSystemEnabledFlag');
    if (showLoading) await this._core.loadingService.present();
    const aid =
      await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    return new Promise((resolve, reject) => {
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerGetAccountGameSystemFlag(
              user.id.toString(),
              aid.toString()
            )
          ),
          loggerFn(this.logger, "GameDataService.getGameSystemEnabledFlag"),
          finalize(
            async () =>
              showLoading && (await this._core.loadingService.dismiss())
          ),
          catchError(err => {
            this.logger.error(err);
            return of(null);
          })
        )
        .subscribe(
          (gameSystemFlag) => {
            this._gameSystemEnabled$.next(gameSystemFlag.gameSystemEnabled);
            resolve();
          },
          (error) => reject(error)
        );
    });
  }

  public async setGameSystemEnabledFlag(
    gameSystemEnabled: boolean
  ): Promise<void> {
    this.logger.debug("GameDataService.setGameSystemEnabledFlag");
    await this._core.loadingService.present();
    const aid =
      await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    this._core.storageService
      .getUserAsObservable()
      .pipe(
        concatMap((user) =>
          this.externalAccountEquipoService.accountEquipoHandlerControllerUpdateAccountGameSystemFlag(
            user.id.toString(),
            aid.toString(),
            { gameSystemEnabled }
          )
        ),
        loggerFn(this.logger, "GameDataService.setGameSystemEnabledFlag"),
        finalize(async () => await this._core.loadingService.dismiss())
      )
      .subscribe((gameSystemFlag) =>
        this._gameSystemEnabled$.next(gameSystemFlag.gameSystemEnabled)
      );
  }

  public async readScheduledGamesForFilter(): Promise<void> {
    this.logger.debug(
      "GameDataService.saveGame() - read schedules games for filter"
    );
    await this._core.loadingService.present();
    const aid =
      await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    this._core.storageService
      .getUserAsObservable()
      .pipe(
        concatMap((user) =>
          this.externalAccountEquipoService.accountEquipoHandlerControllerReadScheduledGames(
            user.id.toString(),
            aid.toString()
          )
        ),
        loggerFn(this.logger, "GameDataService.readGamesForFilter - before:"),
        map((games) =>
          games.map(
            (g) =>
              ({
                date: DateTime.fromISO(g.date).toLocaleString(
                  DateTime.DATE_MED
                ),
                result: `${g.goalsHome} : ${g.goalsVisitor}`,
                accessHash: g.accessHash,
                home: g.home,
                visitor: g.visitor,
                homeId: g.homeId,
                visitorId: g.visitorId,
                id: g.id,
                gameType: g.gameType,
                gameMode: g.gameMode,
              } as GameFilterTableModel)
          )
        ),
        loggerFn(this.logger, "GameDataService.readGamesForFilter - after:"),
        finalize(async () => await this._core.loadingService.dismiss())
      )
      .subscribe((games) => this._gameFilter$.next(games));
  }

  public async saveEmptyGame(
    startTimeOfGame: DateTime,
    gameModel: GameModel,
    playTimeRecords: PlaytimeEventModel[],
    gameType: GameType,
    gameMode: GameMode,
    status: "scheduled" | "saved" | "started" = "scheduled",
    trackers?: number[]
  ): Promise<GameDto> {
    console.log("TEST.saveEmptyGame", gameType, gameMode);
    this.logger.debug(
      "GameDataService.saveEmptyGame() - save game: ",
      gameModel
    );
    const aid =
      await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    return this._core.storageService
      .getUserAsObservable()
      .pipe(
        concatMap((user) =>
          this.externalAccountEquipoService.accountEquipoHandlerControllerSaveGame(
            user.id.toString(),
            aid.toString(),
            this.transformToServerModel(
              aid,
              startTimeOfGame,
              gameModel,
              [],
              playTimeRecords,
              false,
              false,
              gameType,
              gameMode,
              status,
              'absent',
              trackers
            )
          )
        )
      )
      .toPromise();
  }

  public async changeDateToGame(gameId: number, date: Date): Promise<void> {
    return new Promise<void>(async (ok, ko) => {
      this.logger.debug("GameDataService.changeDateToGame() - gameId: ", gameId);
      await this._core.loadingService.present();
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerPatchGameDate(
              user.id.toString(),
              aid.toString(),
              gameId.toString(),
              (date as Date).toISOString()
            )
          ),
          loggerFn(
            this.logger,
            "GameDataService.deleteGamesForFilter - after:"
          ),
          finalize(async () => await this._core.loadingService.dismiss())
        )
        .subscribe(
          (game) => {
            this.logger.debug(
              "GameDataService.deleteGame() - date of game update successfully"
            );
            this.toastCntl
              .create({
                icon: "checkmark-circle-outline",
                color: "success",
                message: `Successfully date change to Game from server!`,
                duration: 1000,
              })
              .then((value) => value.present());
            ok();
          },
          (error) => {
            this.logger.error(
              "GameDataService.deleteGame() - error updating date of game!",
              error
            );
            ko();
          }
        );
    });
  }

  public async changeTrackersToGame(gameId: number, gameTrackersDto: GameTrackersDto): Promise<void> {
    return new Promise<void>(async (ok, ko) => {
      this.logger.debug("GameDataService.changeTrackersToGame() - gameId: ", gameId);
      await this._core.loadingService.present();
      const aid =
          await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
          .getUserAsObservable()
          .pipe(
              concatMap((user) =>
                  this.externalAccountEquipoService.accountEquipoHandlerControllerPatchGameTrackers(
                      user.id.toString(),
                      aid.toString(),
                      gameId.toString(),
                      gameTrackersDto
                  )
              ),
              loggerFn(
                  this.logger,
                  "GameDataService.changeTrackersToGame - after:"
              ),
              finalize(async () => await this._core.loadingService.dismiss()),
              catchError(err => {
                this.logger.error(err);
                return of(null);
              })
          )
          .subscribe(
              (game) => {
                this.logger.debug(
                    "GameDataService.changeTrackersToGame() - trackers of game update successfully"
                );
                this.toastCntl
                    .create({
                      icon: "checkmark-circle-outline",
                      color: "success",
                      message: `Successfully updated trackers of the Game!`,
                      duration: 1000,
                    })
                    .then((value) => value.present());
                ok();
              },
              (error) => {
                this.logger.error(
                    "GameDataService.changeTrackersToGame() - error updating trackers of game!",
                    error
                );
                ko();
              }
          );
    });
  }

  public async rollbackGame(gameId: number, mode: 'full'|'second-half'|'last-five'): Promise<void> {
    return new Promise<void>(async (ok, ko) => {
      this.logger.debug("GameDataService.rollbackGame() - gameId: ", gameId);
      await this._core.loadingService.present();
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerRollbackGame(
              user.id.toString(),
              aid.toString(),
              gameId.toString(),
              mode
            )
          ),
          loggerFn(
            this.logger,
            "GameDataService.deleteGamesForFilter - after:"
          ),
          finalize(async () => await this._core.loadingService.dismiss()),
          catchError(err => {
            this.logger.error(err);
            return of(null);
          })
        )
        .subscribe(
          (game) => {
            this.logger.debug(
              "GameDataService.rollbackGame() - game deleted successfully"
            );
            this.toastCntl
              .create({
                icon: "checkmark-circle-outline",
                color: "success",
                message: `Successfully deleted Game from server!`,
                duration: 1000,
              })
              .then((value) => value.present());
            ok();
          },
          (error) => {
            this.logger.error(
              "GameDataService.rollbackGame() - error deleting game!",
              error
            );
            ko();
          }
        );
    });
  }
  public async deleteGame(gameId: number): Promise<void> {
    return new Promise<void>(async (ok, ko) => {
      this.logger.debug("GameDataService.deleteGame() - gameId: ", gameId);
      await this._core.loadingService.present();
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerDeleteGame(
              user.id.toString(),
              aid.toString(),
              gameId.toString()
            )
          ),
          loggerFn(
            this.logger,
            "GameDataService.deleteGamesForFilter - after:"
          ),
          finalize(async () => await this._core.loadingService.dismiss()),
          catchError(err => {
            this.logger.error(err);
            return of(null);
          })
        )
        .subscribe(
          (game) => {
            this.logger.debug(
              "GameDataService.deleteGame() - game deleted successfully"
            );
            this.toastCntl
              .create({
                icon: "checkmark-circle-outline",
                color: "success",
                message: `Successfully deleted Game from server!`,
                duration: 1000,
              })
              .then((value) => value.present());
            ok();
          },
          (error) => {
            this.logger.error(
              "GameDataService.deleteGame() - error deleting game!",
              error
            );
            ko();
          }
        );
    });
  }
  public async readGame(
    gameId: number,
    accountEquipoOverride: number | null = null,
    showLoading = true,
    cleanOldGame = false,
    resolveAfterHistoricGameReload = false,
    // Prevent historic game recalculation through subject
    preventSubjectUpdate = false
  ): Promise<GameDto> {
    return new Promise<GameDto>(async (ok, ko) => {
      this.logger.debug("GameDataService.readGame() - read game: ", gameId);
      if (cleanOldGame) this.game$.next(null);
      if (showLoading) await this._core.loadingService.present();
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerGetGame(
              user.id.toString(),
              accountEquipoOverride === null
                ? aid.toString()
                : accountEquipoOverride.toString(),
              gameId.toString()
            )
          ),
          loggerFn(this.logger, "GameDataService.readGamesForFilter - after:"),
          finalize(
            async () =>
              showLoading && (await this._core.loadingService.dismiss())
          ),
            catchError(err => {
              this.logger.error(err);
              return of(null);
            })
        )
        .subscribe({
          next: async (game) => {
            this.logger.debug(
              "GameDataService.readGame() - game read successfully"
            );
            this.toastCntl
              .create({
                icon: "checkmark-circle-outline",
                color: "success",
                message: `Successfully read Game from server!`,
                duration: 1000,
              })
              .then((value) => value.present());
            if (!preventSubjectUpdate) {
              this.historicGameRecalculationReady$.next(false);
              this._game$.next(game);
              if (resolveAfterHistoricGameReload && this.historicGameRecalculationReady$.value === false) {
                await firstValueFrom(this.historicGameRecalculationReady$.pipe(filter(v => v)))
              }
            }
            ok(game);
          },
          error: (error) => {
            this.logger.error(
              "GameDataService.readGame() - error reading game!"
            );
            ko();
          }
        });
    });
  }
  public async readPublicGame(gameId: number, hash: string): Promise<void> {
    this.logger.debug("GameDataService.readPublicGame() - read game: ", gameId);
    // await this._core.loadingService.present();
    const aid =
      await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    this._core.storageService
      .getUserAsObservable()
      .pipe(
        concatMap((user) =>
          this.externalGameService.gameHandlerControllerGetGameByHash(
            gameId.toString(),
            hash
          )
        ),
        loggerFn(this.logger, "GameDataService.readGamesForFilter - after:")
        // finalize(async () => await this._core.loadingService.dismiss())
      )
      .subscribe(
        (game) => {
          this.logger.debug(
            "GameDataService.readPublicGame() - game read successfully"
          );
          this._game$.next(game);
        },
        (error) => {
          this.logger.error(
            "GameDataService.readPublicGame() - error reading game!"
          );
        }
      );
  }

  private async pushPlayByPlayToServer(
    gameId: number,
    playByPlayRecords: PlayByPlayModel[]
  ): Promise<boolean | any> {
    return new Promise<boolean | any>(async (resolve, reject) => {
      this.logger.debug(
        "GameDataService.pushPlayByPlayToServer - add playByPlay to game: ",
        playByPlayRecords,
        gameId
      );
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerAddPlayByPlayRecords(
              user.id.toString(),
              aid.toString(),
              gameId.toString(),
              transformPlayByPlayToServerModel(playByPlayRecords)
            )
          )
        )
        .subscribe(
          () => resolve(true),
          (error) => {
            reject(error);
          }
        );
    });
  }

  private async pushPlayTimeRecordToServer(
    gameId: number,
    playTimeRecords: PlaytimeEventModel[]
  ): Promise<boolean | any> {
    return new Promise<boolean | any>(async (resolve, reject) => {
      this.logger.debug(
        "GameDataService.addPlayTimeRecord - add playTime to game: ",
        playTimeRecords,
        gameId
      );
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerAddPlayTimeRecords(
              user.id.toString(),
              aid.toString(),
              gameId.toString(),
              transformPlayTimeEventsToServerModel(playTimeRecords)
            )
          )
        )
        .subscribe(
          () => resolve(true),
          (error) => {
            reject(error);
          }
        );
    });
  }

  public async updatePlayPlayByPlayToGame(
    gameId: number,
    playByPlayRecords: PlayByPlayModel[]
  ): Promise<boolean> {
    return this.updatePlayByPlayToGame(gameId, transformPlayByPlayToServerModel(playByPlayRecords));
  }

  public async regenerateVideoTimestamps(
    gameId: number,
    halfTimes: HalftimestampsDto[],
  ): Promise<GameDto> {
    return new Promise<GameDto>(async (resolve, reject) => {
      this.logger.debug(
        "GameDataService.regenerateVideoTimestamps - scheduled video cut again: ",
        gameId,
        halfTimes
      );
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerRegenerateVideoTimestamps(
              user.id.toString(),
              aid.toString(),
              gameId.toString(),
              halfTimes
            )
          )
        )
        .subscribe({
          next: (game) => {
            this._game$.next(game);
            resolve(game);
          },
          error: (error) => {
            this.logger.error(
              "GameDataService.updatePlayPlayByPlayToGame - error occurred: ",
              gameId
            );
            reject(error);
          }
        });
    });
  }

  public async uploadGameVideoScheduleCut(
    gameId: number,
  ): Promise<GameDto> {
    return new Promise<GameDto>(async (resolve, reject) => {
      this.logger.debug(
        "GameDataService.uploadGameVideoScheduleCut - scheduled video cut again: ",
        gameId
      );
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerUploadGameVideoScheduleCut(
              user.id.toString(),
              aid.toString(),
              gameId.toString(),
            )
          )
        )
        .subscribe({
          next: (game) => {
            this._game$.next(game);
            resolve(game);
          },
          error: (error) => {
            this.logger.error(
              "GameDataService.updatePlayPlayByPlayToGame - error occurred: ",
              gameId
            );
            reject(error);
          }
        });
    });
  }

  public async updatePlayByPlayToGame(
    gameId: number,
    playByPlayRecords: PlayByPlayDto[]
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      this.logger.debug(
        "GameDataService.updatePlayPlayByPlayToGame - update playByPlay to game: ",
        playByPlayRecords,
        gameId
      );
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerUpdatePlayByPlayRecords(
              user.id.toString(),
              aid.toString(),
              gameId.toString(),
              playByPlayRecords
            )
          )
        )
        .subscribe(
          () => resolve(true),
          (error) => {
            this.logger.error(
              "GameDataService.updatePlayPlayByPlayToGame - error occurred: ",
              playByPlayRecords,
              gameId
            );
            reject(error);
          }
        );
    });
  }

  public async deletePlayByPlay(
    gameId: number,
    playByPlay: PlayByPlayModel
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      this.logger.debug(
        'GameDataService.deletePlayByPlay - delete playByPlay:',
        playByPlay,
        gameId
      );
      const aid = await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerDeletePlayByPlayRecord(
              user.id.toString(),
              aid.toString(),
              gameId.toString(),
              playByPlay.id.toString()
            )
          )
        )
        .subscribe({
          next: () => resolve(true),
          error: (error) => {
            this.logger.error(
              'GameDataService.deletePlayByPlay - error occurred: ',
              playByPlay,
              gameId
            );
            reject(error);
          }
          });
    });
  }

  public async updateGame(
    gameId: number,
    updateGameResultDto: UpdateGameResultDto,
    accountEquipoOverride: number | null = null
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      this.logger.debug(
        "GameDataService.updateGame - post game result: ",
        updateGameResultDto,
        gameId
      );
      const maxStatsValue = Math.max(...updateGameResultDto.playerStatsDto.map(stats => stats.playerScore));
      updateGameResultDto.playerStatsDto.forEach(stats => {
        stats.score5 = this.calculateAlignedScore(stats.playerScore, 5, maxStatsValue);
      });
      const aid =
        await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalAccountEquipoService.accountEquipoHandlerControllerUpdateGameResult(
              user.id.toString(),
              accountEquipoOverride === null
                ? aid.toString()
                : accountEquipoOverride.toString(),
              gameId.toString(),
              updateGameResultDto
            )
          )
        )
        .subscribe({
          next: () => resolve(true),
          error: (error) => {
            this.logger.error(
              "GameDataService.addPlayTimeRecord - updateGame: ",
              updateGameResultDto,
              gameId
            );
            reject(error);
          }
        });
    });
  }

  public async updateGameStatistics(dryRun?: boolean): Promise<GeneratedGameStats> {
    // TODO: Ensure that we have an already readed game
    const playerStats = this._core.goalConsumerService.transformToPlayerStatisticsDto(
      this._core.teamOverviewSubConsumerService.getPlayerStatisticsDto()
    );
    const teamsStats =
      this._core.goalConsumerService.transformToTeamStatisticsDto(
        this._core.overviewConsumerService.generateTeamStatsDto()
      );
    const lineupStats = this._core.goalConsumerService.transformToLineupStatisticsDto();
    if (!dryRun) {
      await this.updateGame(this.game$.getValue().id, {
        firstHalfEnded: true,
        secondHalfEnded: true,
        gameEnded: true,
        gameStatus: "ended",
        goalsHome: teamsStats.filter(
          (ts) => ts.teamId === this._core.gameService.gameModel.home.id
        )[0].goals,
        goalsVisitor: teamsStats.filter(
          (ts) => ts.teamId === this._core.gameService.gameModel.visitor.id
        )[0].goals,
        playerStatsDto: playerStats,
        teamStatsDto: teamsStats,
        lineupStatsDto: lineupStats,
        playTimeDto: [],
        playByPlayDto: [],
        gameDateTime: this._core.gameService.gameModel.startDate.toISO(),
        folderId: this._core.gameDataService.game$.getValue().folderId,
      });
    }
    return {playerStats, teamsStats, lineupStats};
  }

  public async readGameFolders(selectedSeason?: number) {
    const val = await this._core.storageService.getAccessTokenAsObject();
    if (val) {
      const gameFoldersRes = await this.externalAccountEquipoService
        .accountEquipoHandlerControllerReadGameFolders(
          val.sub.toString(),
          selectedSeason ?
            selectedSeason.toString() :
            this._core.seasonService.selectedSeason$.getValue().id + "",
          this._core.accountEquipoService.selectedAccountEquipo$.getValue().id +
            ""
        )
        .pipe(
          tap((res) => {
            this.logger.debug(
              "GameDataService.readGameFolders() - Game Folders read successfully"
            );
          }),
            catchError(err => {
              this.logger.error(err);
              return of(null);
            })
        )
        .toPromise();

      this.gameFolders = gameFoldersRes;
      this._gameFolders$.next(this.gameFolders);
    }
  }

  public async createGameFolders(gameFolderName: string, selectedSeason: number) {
    const val = await this._core.storageService.getAccessTokenAsObject();
    if (val) {
      const accountEquipoId =
        this._core.accountEquipoService.selectedAccountEquipo$.getValue().id;
      const seasonId = selectedSeason;
      const gameFolder: GameFolderDto = {
        accountEquipoId: accountEquipoId,
        deleted: false,
        seasonId: seasonId,
        gameFolderName: gameFolderName,
      };
      await this.externalAccountEquipoService
        .accountEquipoHandlerControllerSaveGameFolder(
          val.sub.toString(),
          accountEquipoId + "",
          seasonId.toString(),
          gameFolder
        )
        .pipe(
          tap((res) => {
            this.logger.debug(
              "GameDataService.createGameFolders() - Game Folder created successfully"
            );
          })
        )
        .toPromise();

      this.readGameFolders(selectedSeason);
    }
  }

  public async updateGameFolders(gameFolder: GameFolderDto, selectedSeason: number) {
    const val = await this._core.storageService.getAccessTokenAsObject();
    if (val) {
      await this.externalAccountEquipoService
        .accountEquipoHandlerControllerUpdateGameFolder(
          val.sub.toString(),
          this._core.accountEquipoService.selectedAccountEquipo$.getValue().id +
            "",
          selectedSeason.toString(),
          gameFolder.id + "",
          gameFolder
        )
        .pipe(
          tap((res) => {
            this.logger.debug(
              "GameDataService.createGameFolders() - Game Folder updates successfully"
            );
          })
        )
        .toPromise();

      this.readGameFolders(selectedSeason);
    }
  }

  public transformToServerModel(
    accountEquipoId: number,
    startTimeOfGame: DateTime,
    gameModel: GameModel,
    pbp: PlayByPlayModel[],
    pt: PlaytimeEventModel[],
    firstHalfEnded: boolean,
    secondHalfEnded: boolean,
    gameType: GameType,
    gameMode: GameMode,
    status: GameStatus,
    videoStatus: VideoStatus,
    trackers?: number[],
    videoUrl?: string
  ): GameDto {
    return transformGameModelToGameDto(
      accountEquipoId,
      startTimeOfGame,
      gameModel,
      transformPlayByPlayToServerModel(pbp),
      transformPlayTimeEventsToServerModel(pt),
      firstHalfEnded,
      secondHalfEnded,
      gameType,
      gameMode,
      status,
      videoStatus,
      this._core.version.getFullVersionString(),
      this._core.userService.user$.getValue().id,
      trackers,
      videoUrl
    );
  }

  public async sendGameToXps(gameId: number): Promise<XpsGameResponseDto> {
    const aid =
      await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    return new Promise((resolve, reject) => {
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) =>
            this.externalXpsService.xpsHandlerControllerSendGameDataToXpsWithGetResponse(
              user.id.toString(),
              aid.toString(),
              gameId.toString()
            )
          ),
            catchError(err => {
              this.logger.error(err);
              return of(null);
            })
        )
        .subscribe(
          (value) => {
            this.logger.debug(
              "GameDataService.sendGameToXps for gameId with value",
              gameId,
              value
            );
            resolve(value);
          },
          (error) => {
            this.logger.error(
              "GameDataService.sendGameToXps for gameId with error",
              gameId,
              error
            );
            reject({
              status: false,
              message: error.toString(),
            } as XpsGameResponseDto);
          }
        );
    });
  }

  /* Scouting external services */


  public async getScoutingClipsForSelection(filter: {
    tid?: string|number,
    pid?: string|number,
    gameList: string,
    invertTarget?: boolean,
    targetAssist?: boolean;
    eventNames?: string[],// multiple events
    defenseSystem?: string[],
    scoutingFilters?: ScoutingFilters,
  }, returnEventsWithoutVideo: boolean = false): Promise<ScoutingPlayByPlayDto[]> {
    this.logger.debug(
      "GameDataService.getScoutingClipsForSelection() - get cutted video clips by filter selection"
    );
    const aid = await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    return new Promise((resolve, reject) => {
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) => {
            return filter.pid
              ? this.externalStatsService.statsHandlerControllerGetPlayByPlayForScoutingByPlayer(
                user.id.toString(),
                `${aid}`,
                `${filter.pid}`,
                filter.gameList,
                returnEventsWithoutVideo,
                filter.invertTarget,
                filter.scoutingFilters?.disableTeamFilter,
                filter.scoutingFilters?.requireDefensePlayer,
                filter.targetAssist,
                filter.eventNames?.join(','),
                filter.defenseSystem?.join(','),
                filter.scoutingFilters?.offenseSystem?.join(','),
                filter.scoutingFilters?.phase?.join(','),
                filter.scoutingFilters?.executionPosition?.join(','),
                filter.scoutingFilters?.shotLocation?.join(','),
                filter.scoutingFilters?.gameSystemAction,
                filter.scoutingFilters?.minSecondsSinceStartOfGame,
                filter.scoutingFilters?.maxSecondsSinceStartOfGame,
              )
              : this.externalStatsService.statsHandlerControllerGetPlayByPlayForScoutingByTeam(
                user.id.toString(),
                `${aid}`,
                `${filter.tid}`,
                filter.gameList,
                returnEventsWithoutVideo,
                filter.invertTarget,
                filter.scoutingFilters?.disableTeamFilter,
                filter.scoutingFilters?.requireDefensePlayer,
                filter.targetAssist,
                filter.eventNames?.join(','),
                filter.defenseSystem?.join(','),
                filter.scoutingFilters?.offenseSystem?.join(','),
                filter.scoutingFilters?.phase?.join(','),
                filter.scoutingFilters?.executionPosition?.join(','),
                filter.scoutingFilters?.shotLocation?.join(','),
                filter.scoutingFilters?.gameSystemAction,
                filter.scoutingFilters?.minSecondsSinceStartOfGame,
                filter.scoutingFilters?.maxSecondsSinceStartOfGame,
              )
          }),
          catchError(err => {
            this.logger.error(err);
            return of(null);
          })
        )
        .subscribe({
          next: (value) => {
            this.logger.debug(
              "GameDataService.getScoutingClipsForSelection value",
              value
            );
            resolve(value);
          },
          error: (error) => {
            this.logger.error(
              "GameDataService.getScoutingClipsForSelection error",
              error
            );
            reject({
              status: false,
              message: error.toString(),
            });
          }
        });
    });
  }

  public async getScoutingClipsByIds(filter: {
    tid?: string|number,
    pid?: string|number,
    pbpIds: string,
  }, returnEventsWithoutVideo: boolean = false): Promise<ScoutingPlayByPlayDto[]> {
    this.logger.debug(
      "GameDataService.getScoutingClipsByIds() - get cutted video clips by filter selection"
    );
    const aid = await this._core.storageService.getSelectedAccountEquipoIdAsPromise();
    return new Promise((resolve, reject) => {
      this._core.storageService
        .getUserAsObservable()
        .pipe(
          concatMap((user) => {
            return this.externalStatsService.statsHandlerControllerGetPlayByPlayForScoutingByIds(
              user.id.toString(),
              `${aid}`,
              filter.pbpIds,
              returnEventsWithoutVideo
            )
          })
        )
        .subscribe(
          (value) => {
            this.logger.debug(
              "GameDataService.getScoutingClipsByIds value",
              value
            );
            resolve(value);
          },
          (error) => {
            this.logger.error(
              "GameDataService.getScoutingClipsByIds error",
              error
            );
            reject({
              status: false,
              message: error.toString(),
            });
          }
        );
    });
  }

  private calculateAlignedScore(element: number, scoreValue: number, maxElement: number): number {
    const percentage = (element / maxElement) * 100;

    const alignedValue = (percentage / 100) * scoreValue;

    return Math.round(alignedValue * 10) / 10;
  }

}
