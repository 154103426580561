import { UiBaseCommand, UiCommandExecutionContext } from './ui-base-command';
import { GoalPopupCanceledEvent, GoalPopupSavedEvent, PlayerSelectEvent, ShowGoalPopupEvent } from '../events/ui-events';
import { eventChainFinished, extractTeamFromMarker } from './command-helper';
import {
    getGoalKeeperByTeamMarkerAsGamePlayer,
    getOpponentTeamMarker
} from 'src/app/shared-services/helper/statistics-helper';
import { GameGoalUndoEvent } from 'src/app/shared-services/ui-event-dispatcher/buffer/event-buffer.model';
import { PlayerEvent } from '@handballai/stats-calculation';
import { GameDataService } from 'src/app/shared-services/game-data/game-data.service';
import { GoalPopoverContainerComponent } from 'src/app/main/pages/aehandler-module/pages/game-module/pages/manage-module/pages/game3/goalpopup/goal-popover-container.component';

const isGameSystemEnabled = (gameDataService: GameDataService): string =>
    gameDataService.gameSystemEnabled$.value && gameDataService.gameSystems$.value.length > 0 && !gameDataService.playingPenalties
        ? 'global__popover global__popover--goal game-system'
        : 'global__popover global__popover--goal';

export class ShowGoalPopupCommand implements UiBaseCommand<ShowGoalPopupEvent> {

    async execute(actualEvent: ShowGoalPopupEvent, eventContext: UiCommandExecutionContext): Promise<void> {
        eventContext.logger.debug('ShowGoalPopupCommand.execute: ', actualEvent);
        const player = (<PlayerSelectEvent> eventContext.eventDispatcher.eventSlots[0]).payload.playerModel;
        const team = (<PlayerSelectEvent> eventContext.eventDispatcher.eventSlots[0]).payload.teamMarker;
        const fieldTeam = extractTeamFromMarker(team, eventContext.gameService.gameModel);
        const popover = await eventContext.popoverController.create({
            component: GoalPopoverContainerComponent,
            cssClass: isGameSystemEnabled(eventContext.gameDataService),
            // translucent: true,
            backdropDismiss: false,
            componentProps: {
                player: player,
                fieldTeam: fieldTeam.currentField.filter(p => p.id !== player.id),
                team: fieldTeam,
                teamMarker: team,
                homeTeamColor: actualEvent.payload.homeTeamColor,
                visitorTeamColor: actualEvent.payload.visitorTeamColor,
                goalActionType: actualEvent.payload.goalActionType,
                videoSrc: actualEvent.payload.videoSrc,
                videoCurrentTime: actualEvent.payload.videoCurrentTime
            }
        });
        await popover.present();
    }
}

export class GoalPopupCanceledCommand implements UiBaseCommand<GoalPopupCanceledEvent> {

    execute(actualEvent: GoalPopupCanceledEvent, eventContext: UiCommandExecutionContext): void {
        eventContext.logger.debug('GoalPopupCanceledCommand.execute: ', actualEvent);
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}

export class GoalPopupSavedCommand implements UiBaseCommand<GoalPopupSavedEvent> {
    execute(actualEvent: GoalPopupSavedEvent, eventContext: UiCommandExecutionContext): void {
        eventContext.logger.debug('GoalPopupSavedCommand.execute: ', actualEvent);
        eventContext.logger.debug('GoalPopupSavedCommand.execute:', eventContext.eventDispatcher.playerSelectEvent);
        if (eventContext.eventDispatcher.playerSelectEvent.payload.teamMarker === 'HOME') {
            if (actualEvent.payload.goalActionType === 'GOAL') {
                eventContext.gameService.incrementHomeScore();
            }
        } else {
            if (actualEvent.payload.goalActionType === 'GOAL') {
                eventContext.gameService.incrementVisitorScore();
            }
        }
        const playerSelectEvent = eventContext.eventDispatcher.playerSelectEvent;
        const phaseSelectedEvent = eventContext.eventDispatcher.phasesSelectEvent;

        // New goal event handling
        if (actualEvent.payload.goalActionType === 'GOAL') {
            eventContext
                .eventBufferService
                .addUndoEvent(new GameGoalUndoEvent({
                    teamMarker: eventContext.eventDispatcher.playerSelectEvent.payload.teamMarker
                }));
        }
        eventContext.eventBufferService.addPlayByPlayEvent(
            playerSelectEvent.payload.teamMarker,
            {
                eventType: actualEvent.payload.goalActionType,
                phase: phaseSelectedEvent?.payload?.phaseSelected ? phaseSelectedEvent.payload.phaseSelected : 'OFFENSE_POSITIONAL',
                eventTime: playerSelectEvent.timestamp,
                executionPosition: actualEvent.payload.executionPosition,
                shotLocation: actualEvent.payload.goalActionType === 'POST_OUT' ?
                    actualEvent.payload.postOutZone : actualEvent.payload.goalZone,
            } as PlayerEvent,
            playerSelectEvent.payload.teamMarker === 'HOME' ?
                eventContext.gameService.gameModel.home.offenseSystem :
                eventContext.gameService.gameModel.visitor.offenseSystem,
            playerSelectEvent.payload.playerModel,
            getGoalKeeperByTeamMarkerAsGamePlayer(
                getOpponentTeamMarker(playerSelectEvent.payload.teamMarker),
                eventContext.gameService.gameModel,
                true
            ),
            actualEvent.payload.playerAssist,
            actualEvent.payload.executionPosition,
            actualEvent.payload.goalActionType === 'POST_OUT' ? actualEvent.payload.postOutZone : actualEvent.payload.goalZone,
            actualEvent.payload.important,
            actualEvent.payload?.gameSystem
        );
        eventContext.eventBufferService.closeTransaction();
        eventChainFinished(
            eventContext.eventDispatcher,
            eventContext.actionService,
            eventContext.gameService
        );
    }
}
