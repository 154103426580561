import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { GamePlayerModel, GameTeamModel } from 'src/app/shared-services/model/game.model';
import { NGXLogger } from 'ngx-logger';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { GoalActionTypes } from 'src/app/shared-services/actions/action-types';
import { Observable } from 'rxjs';
import { GameSystemCategoryDto } from 'src/app/api/hai-api';
import { CoreService } from 'src/app/shared-services/core.service';
import { GameType } from 'src/app/shared-services/game/game-type.model';

@Component({
  selector: 'app-goal-popover-container',
  templateUrl: './goal-popover-container.component.html',
  styleUrls: ['./goal-popover-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoalPopoverContainerComponent implements OnInit {

  private _team: GameTeamModel;
  private _player: GamePlayerModel;
  private _fieldTeam: GamePlayerModel[];
  private _teamMarker: TeamMarker;
  private _goalActionType: GoalActionTypes;
  private readonly _gameSystem$: Observable<GameSystemCategoryDto[]>;
  private readonly _gameSystemEnabled$: Observable<boolean>;
  private readonly _gameMode$: Observable<GameType>;

  private _homeTeamColor: string;
  private _visitorTeamColor: string;

  constructor(
      private readonly logger: NGXLogger,
      private readonly core: CoreService
  ) {
    this._gameSystem$ = this.core.gameDataService.gameSystems$;
    this._gameMode$ = this.core.gameService.gameMode$;
    this._gameSystemEnabled$ = this.core.gameDataService.gameSystemEnabled$;
  }

  ngOnInit() {
  }


  get homeTeamColor(): string {
    return this._homeTeamColor;
  }

  @Input()
  set homeTeamColor(value: string) {
    this._homeTeamColor = value;
  }

  get visitorTeamColor(): string {
    return this._visitorTeamColor;
  }

  @Input()
  set visitorTeamColor(value: string) {
    this._visitorTeamColor = value;
  }

  get team(): GameTeamModel {
    return this._team;
  }

  @Input()
  set team(value: GameTeamModel) {
    this.logger.debug('GoalPopoverContainerComponent.team: ', value);
    this._team = value;
  }

  get player(): GamePlayerModel {
    return this._player;
  }

  @Input()
  set player(value: GamePlayerModel) {
    this.logger.debug('GoalPopoverContainerComponent.player: ', value);
    this._player = value;
  }

  get fieldTeam(): GamePlayerModel[] {
    return this._fieldTeam;
  }

  @Input()
  set fieldTeam(value: GamePlayerModel[]) {
    this._fieldTeam = value;
  }

  get teamMarker(): TeamMarker {
    return this._teamMarker;
  }

  @Input()
  set teamMarker(value: TeamMarker) {
    this._teamMarker = value;
  }

  get goalActionType(): GoalActionTypes {
    return this._goalActionType;
  }

  @Input()
  set goalActionType(value: GoalActionTypes) {
    this._goalActionType = value;
  }

  get gameSystem$(): Observable<GameSystemCategoryDto[]> {
    return this._gameSystem$;
  }

  get gameMode$(): Observable<GameType> {
    return this._gameMode$;
  }

  get gameSystemEnabled(): Observable<boolean> {
    return this._gameSystemEnabled$;
  }

  @Input() videoSrc: string;
  @Input() videoCurrentTime: number;
}
