/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ViewAccountDto } from './viewAccountDto';
import { AccountEquipoUserDto } from './accountEquipoUserDto';


export interface AdminAccountEquipoDto { 
    /**
     * acount id
     */
    id?: number;
    /**
     * Name of the account-equipo
     */
    name: string;
    /**
     * Name of the account
     */
    account?: ViewAccountDto;
    /**
     * Deleted flag of the player
     */
    deleted: boolean;
    /**
     * Game system enabled flag
     */
    gameSystemEnabled: boolean;
    /**
     * XPS Account Equipo Token
     */
    xpsToken?: string;
    /**
     * iframe Account Equipo Token
     */
    iframeToken?: string;
    /**
     * Extended coach tracking flag
     */
    extendedCoachTracking?: boolean;
    /**
     * Enable extra time and penalties flag
     */
    enabledExtraTimeAndPenalties?: boolean;
    /**
     * Users with access
     */
    users: Array<AccountEquipoUserDto>;
}

