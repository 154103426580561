/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilePartDto } from './filePartDto';


export interface FilePartsConfirmDto { 
    /**
     * File uploadId to confirm
     */
    uploadId: string;
    /**
     * File uploadId to confirm
     */
    status?: string;
    /**
     * File upload parts
     */
    parts: Array<FilePartDto>;
}

