import { Component, Input, OnInit} from '@angular/core';
import {
  GameSystemPlayerStatsViewModel
} from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-view.model';
import { NGXLogger } from 'ngx-logger';
import { sortByCategoryAndNumberOfActions } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/game-system.helper';
import { CoreService } from '../../../shared-services/core.service';

@Component({
    selector: 'app-game-system-player-stats',
    templateUrl: './game-system-player-stats.component.html',
    styleUrls: [
        './game-system-stats.component.scss',
        './game-system-player-stats.component.scss'
    ],
    standalone: false
})
export class GameSystemPlayerStatsComponent implements OnInit {

  private _homeTeamName = '';
  private _homeGameSystemStatsView: GameSystemPlayerStatsViewModel[] = [];
  private _visitorTeamName = '';
  private _visitorGameSystemStatsView: GameSystemPlayerStatsViewModel[] = [];
  private _columns1 = [
    { prop: 'playerDescription', name: 'Player Description'},
    { prop: 'actionDisplayName', name: 'Action Name'},
    { prop: 'categoryName', name: 'Category'},
    { prop: 'colorCode', name: 'Saves' },
    { prop: 'numberOfActionsPlayed', name: 'TIMES PLAYED' },
    { prop: 'numberOfGoals', name: 'N° GOALS' },
    { prop: 'numberOfSaves', name: 'N° SAVES' },
    { prop: 'numberOfPostOuts', name: 'N° POST / OUT' },
    { prop: 'numberOfActionsPlayedWith7Meters', name: 'N° 7 METERS' },
    { prop: 'numberOfFault', name: 'N° FAULT' },
    { prop: 'numberOfSuspension', name: 'N° SUSPENSION' },
    { prop: 'numberOfLostBall', name: 'N° LOST BALL' },
    { prop: 'percentageGoals', name: '% GOALS' },
    { prop: 'percentageNoGoals', name: '% NO GOALS' },
    { prop: 'percentage7MetersGoals', name: '% GOALS 7 METERS' },
  ];
  public tablesAllColsShown = false;
  public tableVisitorAllColsShown = false;

  constructor(
      private readonly logger: NGXLogger,
      public core: CoreService
  ) { }

  ngOnInit() {}

  public onExpandTableColumnsClick() {
    this.tablesAllColsShown = !this.tablesAllColsShown;
  }

  public onExpandVisitorTableColumnsClick() {
    this.tableVisitorAllColsShown = !this.tableVisitorAllColsShown;
  }


  get homeTeamName(): string {
    return this._homeTeamName;
  }

  @Input()
  set homeTeamName(value: string) {
    this._homeTeamName = value;
  }

  get homeGameSystemStatsView(): GameSystemPlayerStatsViewModel[] {
    return this._homeGameSystemStatsView;
  }

  @Input()
  set homeGameSystemStatsView(value: GameSystemPlayerStatsViewModel[]) {
    this._homeGameSystemStatsView = sortByCategoryAndNumberOfActions(value);
  }

  get visitorTeamName(): string {
    return this._visitorTeamName;
  }

  @Input()
  set visitorTeamName(value: string) {
    this._visitorTeamName = value;
  }

  get visitorGameSystemStatsView(): GameSystemPlayerStatsViewModel[] {
    return this._visitorGameSystemStatsView;
  }

  @Input()
  set visitorGameSystemStatsView(value: GameSystemPlayerStatsViewModel[]) {
    this._visitorGameSystemStatsView = sortByCategoryAndNumberOfActions(value);
  }

  get columns1() {
    return this._columns1;
  }


  public createGroupName(group: any): string {
    this.logger.debug('GameSystemPlayerStatsComponent.creatGroupName', group);
    return `${group.value[0].playerDescription}`;
  }

  public getRowClass = (row: any) => {
    if (row.row.colorCode === 'red') {
      return {
        'red-row-color': true
      };
    } else if (row.row.colorCode === 'blue') {
      return {
        'blue-row-color': true
      };
    } else if (row.row.colorCode === 'yellow') {
      return {
        'yellow-row-color': true
      };
    } else {
      return {
        'green-row-color': true
      };
    }
  }

}
