import { CounterModel } from '@handballai/stats-calculation';

export interface ExecutionPositionStructure {
    goalsLeftWing: number;
    goalsLeft6m: number;
    goalsCenter6m: number;
    goalsRight6m: number;
    goalsRightWing: number;
    goalsLeft9m: number;
    goalsCenter9m: number;
    goalsRight9m: number;
    goals7Meters: number;
    goalsOppositeField: number;
    shootsLeftWing: number;
    shootsLeft6m: number;
    shootsCenter6m: number;
    shootsRight6m: number;
    shootsRightWing: number;
    shootsLeft9m: number;
    shootsCenter9m: number;
    shootsRight9m: number;
    shoots7Meters: number;
    shootsOppositeField: number;
}

export interface ShotLocationStructure {
    goals1: number;
    goals2: number;
    goals3: number;
    goals4: number;
    goals5: number;
    goals6: number;
    goals7: number;
    goals8: number;
    goals9: number;
    shoots0: number;
    shoots1: number;
    shoots2: number;
    shoots3: number;
    shoots4: number;
    shoots5: number;
    shoots6: number;
    shoots7: number;
    shoots8: number;
    shoots9: number;
    shoots10: number;
    shoots11: number;
}

export interface ShotConnectionStructure {
    executionPositions: ExecutionPositionStructure;
    shotLocations: ShotLocationStructure;
}

export const executionPositionNameMap = new Map<string, string>([
    ['CONNECT_G_LEFT_WING', 'goalsLeftWing'],
    ['CONNECT_G_LEFT_6M', 'goalsLeft6m'],
    ['CONNECT_G_CENTER_6M', 'goalsCenter6m'],
    ['CONNECT_G_RIGHT_6M', 'goalsRight6m'],
    ['CONNECT_G_RIGHT_WING', 'goalsRightWing'],
    ['CONNECT_G_LEFT_9M', 'goalsLeft9m'],
    ['CONNECT_G_CENTER_9M', 'goalsCenter9m'],
    ['CONNECT_G_RIGHT_9M', 'goalsRight9m'],
    ['CONNECT_G_7_METERS', 'goals7Meters'],
    ['CONNECT_G_OPPOSITE_FIELD', 'goalsOppositeField'],
    ['CONNECT_S_LEFT_WING', 'shootsLeftWing'],
    ['CONNECT_S_LEFT_6M', 'shootsLeft6m'],
    ['CONNECT_S_CENTER_6M', 'shootsCenter6m'],
    ['CONNECT_S_RIGHT_6M', 'shootsRight6m'],
    ['CONNECT_S_RIGHT_WING', 'shootsRightWing'],
    ['CONNECT_S_LEFT_9M', 'shootsLeft9m'],
    ['CONNECT_S_CENTER_9M', 'shootsCenter9m'],
    ['CONNECT_S_RIGHT_9M', 'shootsRight9m'],
    ['CONNECT_S_7_METERS', 'shoots7Meters'],
    ['CONNECT_S_OPPOSITE_FIELD', 'shootsOppositeField'],
]);

const shotLocationMap = new Map<string, string>([
    ['1X', 'goals1'],
    ['2X', 'goals2'],
    ['3X', 'goals3'],
    ['4X', 'goals4'],
    ['5X', 'goals5'],
    ['6X', 'goals6'],
    ['7X', 'goals7'],
    ['8X', 'goals8'],
    ['9X', 'goals9'],
    ['0Y', 'shoots0'],
    ['1Y', 'shoots1'],
    ['2Y', 'shoots2'],
    ['3Y', 'shoots3'],
    ['4Y', 'shoots4'],
    ['5Y', 'shoots5'],
    ['6Y', 'shoots6'],
    ['7Y', 'shoots7'],
    ['8Y', 'shoots8'],
    ['9Y', 'shoots9'],
    ['10Y', 'shoots10'],
    ['11Y', 'shoots11'],
]);

const shotLocationRegexMap = new Map<string, RegExp>([
    ['1X', /(^|\D)1(X|$)/],
    ['2X', /(^|\D)2(X|$)/],
    ['3X', /(^|\D)3(X|$)/],
    ['4X', /(^|\D)4(X|$)/],
    ['5X', /(^|\D)5(X|$)/],
    ['6X', /(^|\D)6(X|$)/],
    ['7X', /(^|\D)7(X|$)/],
    ['8X', /(^|\D)8(X|$)/],
    ['9X', /(^|\D)9(X|$)/],
    ['0Y', /(^|\D)0(Y|$)/],
    ['1Y', /(^|\D)1(Y|$)/],
    ['2Y', /(^|\D)2(Y|$)/],
    ['3Y', /(^|\D)3(Y|$)/],
    ['4Y', /(^|\D)4(Y|$)/],
    ['5Y', /(^|\D)5(Y|$)/],
    ['6Y', /(^|\D)6(Y|$)/],
    ['7Y', /(^|\D)7(Y|$)/],
    ['8Y', /(^|\D)8(Y|$)/],
    ['9Y', /(^|\D)9(Y|$)/],
    ['10Y', /(^|\D)10(Y|$)/],
    ['11Y', /(^|\D)11(Y|$)/],
]);

export const aggregateByShotLocationAndExecutionPosition = (counters: CounterModel[]): ShotConnectionStructure => {
    const executionPosition = Array.from(executionPositionNameMap.keys()).reduce((accu, curr) => {
        accu[executionPositionNameMap.get(curr)] = counters
            .filter(co => co.counterType.includes(curr))
            .reduce((sum, elem) => sum + elem.counter, 0);
        return accu;
    }, {} as ExecutionPositionStructure);

    const shotLocation = Array.from(shotLocationMap.keys()).reduce((accu, curr) => {
        accu[shotLocationMap.get(curr)] = counters.filter(co => co.counterType.match(shotLocationRegexMap.get(curr)))
            .reduce((sum, elem) => sum + elem.counter, 0);
        return accu;
    }, {} as ShotLocationStructure);
    return {
        executionPositions: executionPosition,
        shotLocations: shotLocation
    };
};

export const aggregateByShooterLocation = (
    counters: CounterModel[],
    executionPositionGoal: string,
    executionPositionShoots: string
): ShotLocationStructure =>
    counters
        .filter(co => co.counterType.includes(executionPositionShoots))
        .reduce((accu, curr) => {
            const shotLocationPostfix = curr.counterType.split(`${executionPositionShoots}_`)[1];
            accu[shotLocationMap.get(shotLocationPostfix)] = curr.counter;
            return accu;
        }, counters
            .filter(co => co.counterType.includes(executionPositionGoal))
            .reduce((accu, curr) => {
                const shotLocationPostfix = curr.counterType.split(`${executionPositionGoal}_`)[1];
                if (!(curr.counterType === '0X' || curr.counterType === '10X' || curr.counterType === '11X')) {
                    accu[shotLocationMap.get(shotLocationPostfix)] = curr.counter;
                }
                return accu;
            }, {} as ShotLocationStructure));
