import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from '../../../../../shared-services/core.service';
import { PopoverController } from '@ionic/angular';
import { MyTeamFolderDto } from '../../../../../api/hai-players-api';

@Component({
    selector: 'app-add-folder-modal',
    templateUrl: './add-folder-modal.component.html',
    styleUrls: ['./add-folder-modal.component.scss'],
    standalone: false
})
export class AddFolderModalComponent implements OnInit {

  public formGroup: FormGroup;

  @Input() folder?: MyTeamFolderDto | undefined;

  constructor(private readonly fb: FormBuilder,
              private readonly core: CoreService,
              private readonly popoverController: PopoverController
  ) { }

  ngOnInit() {
    this.initForm();
  }

  public async onCancel(): Promise<void> {
    await this.popoverController.dismiss();
  }

  public async onConfirm(): Promise<void> {
    await this.popoverController.dismiss({ folder: this.formGroup.value });
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      name: [this.folder ? this.folder.name : '', [Validators.required]]
    });
  }

}
