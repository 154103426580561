<span class="close-icon" (click)="onCancel()">
    <ion-icon name="close-outline"></ion-icon>
  </span>
<div class="folder-access-modal confirm-modal-wrapper my-8" >
  <div class="w-full py-4" style="text-align: center;">
    <span style="padding: 15px; text-align: center">{{ 'Who has access to this folder ?' | translate}}</span>
    <hr class="mx-auto my-2">
  </div>
  <div class="display-flex-space-between">
    <button
        class="bulk-action-button rounded-xl px-8 py-3"
        (click)="onAddAllPlayersClick()"
    >
      <ion-icon class="bulk-action-icon" name="add-circle-outline"></ion-icon>
      {{ 'Add all the players' | translate }}
    </button>
    <button
        class="bulk-action-button rounded-xl px-8 py-3"
        (click)="onRemoveAllPlayerAccessesClick()"
    >
      <ion-icon class="bulk-action-icon" name="close-outline"></ion-icon>
      {{ 'Remove all the player access' | translate }}
    </button>
  </div>
  <div class="display-flex-space-between w-full" style="width: 100%">
    <div style="width: 75%; padding: 0 25px; margin: auto; max-height: 400px;" [style.overflow-y]="playersForFolder.length > 5 ? 'scroll' : ''">
      <ng-select
          class="mobile-tab-selector mt-4 mb-4"
          maxSelectedItems="1"
          [searchable]="true"
          placeholder="Add new player"
          [clearable]="true"
          appendTo="body"
          (change)="onPlayerAdd($event)" #select>
        <ng-container *ngFor="let player of availablePlayers">
          <ng-option *ngIf="!playersForFolderIds.includes(player.id)" [value]="player">
            <p>
              {{ player.firstName + ' ' + player.lastName }}
            </p>
          </ng-option>
        </ng-container>
      </ng-select>
      <div *ngFor="let player of playersForFolder" class="display-flex-space-between">
        <p style="margin-top: 20px;">
          {{ player.firstName + ' ' + player.lastName + ' - ' + player.linkedViaFolder }}
        </p>
        <button
            class="delete-btn edit-delete-button rounded-xl px-8 py-3"
            (click)="onRemovePlayerClick(player)"
        >
          <ion-icon style="font-size: 22px" name="trash-bin-outline"></ion-icon>
        </button>
      </div>
    </div>
  </div>
</div>
