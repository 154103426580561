<div class="grid-col">
  <table>
    <tr *ngIf="!(selectedTeam$ | async)" class="first-row" [ngClass]="noHeaderColor ? 'no-header-color-class' : 'header-color-class'">
      <th
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        <ng-content select="[first-column-header]"></ng-content>
      </th>
      <th [ngClass]="{ 'single-row': showOneTeam }">
        <ng-content select="[second-column-header]"></ng-content>
      </th>
      <th *ngIf="!showOneTeam">
        <ng-content select="[third-column-header]"></ng-content>
      </th>
    </tr>

    <tr *ngIf="homeQuickOverviewModel?.totalPlayedTime">
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Time" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        {{ homeQuickOverviewModel?.totalPlayedTime }}
      </td>
      <td
        class="value"
        *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'"
        [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Visitor' }"
      >
        {{ visitorQuickOverviewModel?.totalPlayedTime }}
      </td>
    </tr>
    <!-- TotalPossessions is going to be PossessionEvents-->
    <tr *ngIf="homeQuickOverviewModel?.totalPossessions">
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Possessions" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value possesions" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        {{ homeQuickOverviewModel?.totalPossessions | number: "1.0-1" }}
      </td>
      <td
        class="value possesions"
        *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'"
        [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Visitor' }"
      >
        {{ visitorQuickOverviewModel?.totalPossessions | number: "1.0-1" }}
      </td>
    </tr>
    <tr class="separator" *ngIf="homeQuickOverviewModel?.totalPossessionEvents">
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Possession Events" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        {{ homeQuickOverviewModel?.totalPossessionEvents | number: "1.0-1" }}
      </td>
      <td
        class="value"
        *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'"
        [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Visitor' }"
      >
        {{ visitorQuickOverviewModel?.totalPossessionEvents | number: "1.0-1" }}
      </td>
    </tr>
    <tr *ngIf="showDashBoard">
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Goals" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        {{ homeQuickOverviewModel?.totalGoals | number: "1.0-1" }}
      </td>
      <td
        class="value"
        *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'"
        [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Visitor' }"
      >
        {{ visitorQuickOverviewModel?.totalGoals | number: "1.0-1" }}
      </td>
    </tr>
    <tr *ngIf="showDashBoard && showSaves">
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Saves" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        {{ homeQuickOverviewModel?.totalSaves | number: "1.0-1" }}
      </td>
      <td
        class="value"
        *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'"
        [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Visitor' }"
      >
        {{ visitorQuickOverviewModel?.totalSaves | number: "1.0-1" }}
      </td>
    </tr>
    <tr *ngIf="showDashBoard">
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Failed Shots" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        {{ homeQuickOverviewModel?.totalFailedShots | number: "1.0-1" }}
      </td>
      <td
        class="value"
        *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'"
        [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Visitor' }"
      >
        {{ visitorQuickOverviewModel?.totalFailedShots | number: "1.0-1" }}
      </td>
    </tr>
    <tr *ngIf="showDashBoard">
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Posts / Out" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        {{ homeQuickOverviewModel?.totalPostOuts | number: "1.0-1" }}
      </td>
      <td
        class="value"
        *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'"
        [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Visitor' }"
      >
        {{ visitorQuickOverviewModel?.totalPostOuts | number: "1.0-1" }}
      </td>
    </tr>
    <tr class="separator" *ngIf="showDashBoard">
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Assists" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        {{ homeQuickOverviewModel?.totalAssist | number: "1.0-1" }}
      </td>
      <td
        class="value"
        *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'"
        [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Visitor' }"
      >
        {{ visitorQuickOverviewModel?.totalAssist | number: "1.0-1" }}
      </td>
    </tr>
    <!-- Temp removed -->
    <!--        <tr *ngIf="homeSemaphor && visitorSemaphor">-->
    <!--            <td class="label" [ngClass]="{'single-label':showOneTeam, 'label':!showOneTeam}">{{'VICTORY %'|translate}}</td>-->
    <!--            <td class="value home" [ngClass]="{'single-row':showOneTeam}">-->
    <!--            <span>-->
    <!--                <span [class.bold]="homeSemaphor > visitorSemaphor">-->
    <!--                    {{ homeSemaphor | number: '1.0-1' }} %-->
    <!--                </span>-->
    <!--            </span>-->
    <!--              <div class="meter">-->
    <!--                <span [style.width]="homeSemaphor"></span>-->
    <!--              </div>-->
    <!--            </td>-->
    <!--            <td class="value visitor" *ngIf="!showOneTeam">-->
    <!--            <span>-->
    <!--                <span [class.bold]="visitorSemaphor > homeSemaphor">-->
    <!--                    {{ visitorSemaphor | number: '1.0-1' }} %-->
    <!--                </span>-->
    <!--            </span>-->
    <!--              <div class="meter">-->
    <!--                <span [style.width]="visitorSemaphor"></span>-->
    <!--              </div>-->
    <!--            </td>-->
    <!--        </tr>-->

    <tr>
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "EFF %" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value home" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        <div class="numbers">
          <span>
            {{ homeQuickOverviewModel?.totalGoals | number: "1.0-1" }} /
            {{ homePossessionsForCalculation | number: "1.0-1" }}
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                homeQuickOverviewModel?.totalGoals,
                homePossessionsForCalculation
              ) >
              calculatePercentageItem(
                visitorQuickOverviewModel?.totalGoals,
                visitorPossessionsForCalculation
              )
            "
          >
            {{
              calculatePercentageItem(
                homeQuickOverviewModel?.totalGoals,
                homePossessionsForCalculation
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                homeQuickOverviewModel?.totalGoals,
                homePossessionsForCalculation
              ) + '%'
            "
          ></span>
        </div>
      </td>
      <td class="value visitor" *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'" [ngClass]="{ 'single-row': (selectedTeam$ | async) === 'Visitor' }">
        <div class="numbers">
          <span>
            {{ visitorQuickOverviewModel?.totalGoals | number: "1.0-1" }} /
            {{ visitorPossessionsForCalculation | number: "1.0-1" }}
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.totalGoals,
                visitorPossessionsForCalculation
              ) >
              calculatePercentageItem(
                homeQuickOverviewModel?.totalGoals,
                homePossessionsForCalculation
              )
            "
          >
            {{
              calculatePercentageItem(
                visitorQuickOverviewModel?.totalGoals,
                visitorPossessionsForCalculation
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.totalGoals,
                visitorPossessionsForCalculation
              ) + '%'
            "
          ></span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Shooting EFF %" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value home" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        <div class="numbers">
          <span>
            {{ homeQuickOverviewModel?.totalGoals | number: "1.0-1" }} /
            {{
              homeQuickOverviewModel?.totalFailedShots +
                homeQuickOverviewModel?.totalGoals | number: "1.0-1"
            }}
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                homeQuickOverviewModel?.totalGoals,
                homeQuickOverviewModel?.totalFailedShots +
                  homeQuickOverviewModel?.totalGoals
              ) >
              calculatePercentageItem(
                visitorQuickOverviewModel?.totalGoals,
                visitorQuickOverviewModel?.totalFailedShots +
                  visitorQuickOverviewModel?.totalGoals
              )
            "
          >
            {{
              calculatePercentageItem(
                homeQuickOverviewModel?.totalGoals,
                homeQuickOverviewModel?.totalFailedShots +
                  homeQuickOverviewModel?.totalGoals
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                homeQuickOverviewModel?.totalGoals,
                homeQuickOverviewModel?.totalFailedShots +
                  homeQuickOverviewModel?.totalGoals
              ) + '%'
            "
          ></span>
        </div>
      </td>
      <td class="value visitor" *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'" [ngClass]="{ 'single-row': (selectedTeam$ | async) === 'Visitor' }">
        <div class="numbers">
          <span>
            {{ visitorQuickOverviewModel?.totalGoals | number: "1.0-1" }} /
            {{
              visitorQuickOverviewModel?.totalFailedShots +
                visitorQuickOverviewModel?.totalGoals | number: "1.0-1"
            }}
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.totalGoals,
                visitorQuickOverviewModel?.totalFailedShots +
                  visitorQuickOverviewModel?.totalGoals
              ) >
              calculatePercentageItem(
                homeQuickOverviewModel?.totalGoals,
                homeQuickOverviewModel?.totalFailedShots +
                  homeQuickOverviewModel?.totalGoals
              )
            "
          >
            {{
              calculatePercentageItem(
                visitorQuickOverviewModel?.totalGoals,
                visitorQuickOverviewModel?.totalFailedShots +
                  visitorQuickOverviewModel?.totalGoals
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.totalGoals,
                visitorQuickOverviewModel?.totalFailedShots +
                  visitorQuickOverviewModel?.totalGoals
              ) + '%'
            "
          ></span>
        </div>
      </td>
    </tr>
    <tr class="separator">
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Lost Balls %" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value home" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        <div class="numbers">
          <span>
            {{ homeQuickOverviewModel?.totalLostBalls | number: "1.0-1" }} /
            {{ homePossessionsForCalculation | number: "1.0-1" }}
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                homeQuickOverviewModel?.totalLostBalls,
                homePossessionsForCalculation
              ) >
              calculatePercentageItem(
                visitorQuickOverviewModel?.totalLostBalls,
                visitorPossessionsForCalculation
              )
            "
          >
            {{
              calculatePercentageItem(
                homeQuickOverviewModel?.totalLostBalls,
                homePossessionsForCalculation
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                homeQuickOverviewModel?.totalLostBalls,
                homePossessionsForCalculation
              ) + '%'
            "
          ></span>
        </div>
      </td>
      <td class="value visitor" *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'" [ngClass]="{ 'single-row': (selectedTeam$ | async) === 'Visitor' }">
        <div class="numbers">
          <span>
            {{ visitorQuickOverviewModel?.totalLostBalls | number: "1.0-1" }} /
            {{ visitorPossessionsForCalculation | number: "1.0-1" }}
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.totalLostBalls,
                visitorPossessionsForCalculation
              ) >
              calculatePercentageItem(
                homeQuickOverviewModel?.totalLostBalls,
                homePossessionsForCalculation
              )
            "
          >
            {{
              calculatePercentageItem(
                visitorQuickOverviewModel?.totalLostBalls,
                visitorPossessionsForCalculation
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.totalLostBalls,
                visitorPossessionsForCalculation
              ) + '%'
            "
          ></span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Offense Positional %" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value home" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        <div class="numbers">
          <span>
            {{
              homeQuickOverviewModel?.offensePositionalGoals | number: "1.0-1"
            }}
            /
            {{
              homeQuickOverviewModel?.offensePositionalEffectivePossession
                | number: "1.0-1"
            }} ({{
              homeQuickOverviewModel?.offensePositionalPossession
                | number: "1.0-1"
            }})
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                homeQuickOverviewModel?.offensePositionalGoals,
                homeQuickOverviewModel?.offensePositionalEffectivePossession
              ) >
              calculatePercentageItem(
                visitorQuickOverviewModel?.offensePositionalGoals,
                visitorQuickOverviewModel?.offensePositionalEffectivePossession
              )
            "
          >
            {{
              calculatePercentageItem(
                homeQuickOverviewModel?.offensePositionalGoals,
                homeQuickOverviewModel?.offensePositionalEffectivePossession
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                homeQuickOverviewModel?.offensePositionalGoals,
                homeQuickOverviewModel?.offensePositionalEffectivePossession
              ) + '%'
            "
          ></span>
        </div>
      </td>
      <td class="value visitor" *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'" [ngClass]="{ 'single-row': (selectedTeam$ | async) === 'Visitor' }">
        <div class="numbers">
          <span>
            {{
              visitorQuickOverviewModel?.offensePositionalGoals
                | number: "1.0-1"
            }}
            /
            {{
              visitorQuickOverviewModel?.offensePositionalEffectivePossession
                | number: "1.0-1"
            }} ({{
              visitorQuickOverviewModel?.offensePositionalPossession
                | number: "1.0-1"
            }})
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.offensePositionalGoals,
                visitorQuickOverviewModel?.offensePositionalEffectivePossession
              ) >
              calculatePercentageItem(
                homeQuickOverviewModel?.offensePositionalGoals,
                homeQuickOverviewModel?.offensePositionalEffectivePossession
              )
            "
          >
            {{
              calculatePercentageItem(
                visitorQuickOverviewModel?.offensePositionalGoals,
                visitorQuickOverviewModel?.offensePositionalEffectivePossession
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.offensePositionalGoals,
                visitorQuickOverviewModel?.offensePositionalEffectivePossession
              ) + '%'
            "
          ></span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Fastbreak %" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value home" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        <div class="numbers">
          <span>
            {{ homeQuickOverviewModel?.fastBreakGoals | number: "1.0-1" }} /
            {{ homeQuickOverviewModel?.fastBreakEffectivePossession | number: "1.0-1" }} ({{ homeQuickOverviewModel?.fastBreakPossession | number: "1.0-1" }})
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                homeQuickOverviewModel?.fastBreakGoals,
                homeQuickOverviewModel?.fastBreakEffectivePossession
              ) >
              calculatePercentageItem(
                visitorQuickOverviewModel?.fastBreakGoals,
                visitorQuickOverviewModel?.fastBreakEffectivePossession
              )
            "
          >
            {{
              calculatePercentageItem(
                homeQuickOverviewModel?.fastBreakGoals,
                homeQuickOverviewModel?.fastBreakEffectivePossession
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                homeQuickOverviewModel?.fastBreakGoals,
                homeQuickOverviewModel?.fastBreakEffectivePossession
              ) + '%'
            "
          ></span>
        </div>
      </td>
      <td class="value visitor" *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'" [ngClass]="{ 'single-row': (selectedTeam$ | async) === 'Visitor' }">
        <div class="numbers">
          <span>
            {{ visitorQuickOverviewModel?.fastBreakGoals | number: "1.0-1" }} /
            {{
              visitorQuickOverviewModel?.fastBreakEffectivePossession | number: "1.0-1"
            }} ({{
              visitorQuickOverviewModel?.fastBreakPossession | number: "1.0-1"
            }})
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.fastBreakGoals,
                visitorQuickOverviewModel?.fastBreakEffectivePossession
              ) >
              calculatePercentageItem(
                homeQuickOverviewModel?.fastBreakGoals,
                homeQuickOverviewModel?.fastBreakEffectivePossession
              )
            "
          >
            {{
              calculatePercentageItem(
                visitorQuickOverviewModel?.fastBreakGoals,
                visitorQuickOverviewModel?.fastBreakEffectivePossession
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.fastBreakGoals,
                visitorQuickOverviewModel?.fastBreakEffectivePossession
              ) + '%'
            "
          ></span>
        </div>
      </td>
    </tr>
    <tr class="separator">
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Counter Goal %" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value home" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        <div class="numbers">
          <span>
            {{ homeQuickOverviewModel?.counterGoalGoals | number: "1.0-1" }} /
            {{
              homeQuickOverviewModel?.counterGoalEffectivePossession | number: "1.0-1"
            }} ({{
              homeQuickOverviewModel?.counterGoalPossession | number: "1.0-1"
            }})
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                homeQuickOverviewModel?.counterGoalGoals,
                homeQuickOverviewModel?.counterGoalEffectivePossession
              ) >
              calculatePercentageItem(
                visitorQuickOverviewModel?.counterGoalGoals,
                visitorQuickOverviewModel?.counterGoalEffectivePossession
              )
            "
          >
            {{
              calculatePercentageItem(
                homeQuickOverviewModel?.counterGoalGoals,
                homeQuickOverviewModel?.counterGoalEffectivePossession
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                homeQuickOverviewModel?.counterGoalGoals,
                homeQuickOverviewModel?.counterGoalEffectivePossession
              ) + '%'
            "
          ></span>
        </div>
      </td>
      <td class="value visitor" *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'" [ngClass]="{ 'single-row': (selectedTeam$ | async) === 'Visitor' }">
        <div class="numbers">
          <span>
            {{ visitorQuickOverviewModel?.counterGoalGoals | number: "1.0-1" }}
            /
            {{
              visitorQuickOverviewModel?.counterGoalEffectivePossession | number: "1.0-1"
            }} ({{
              visitorQuickOverviewModel?.counterGoalPossession | number: "1.0-1"
            }})
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.counterGoalGoals,
                visitorQuickOverviewModel?.counterGoalEffectivePossession
              ) >
              calculatePercentageItem(
                homeQuickOverviewModel?.counterGoalGoals,
                homeQuickOverviewModel?.counterGoalEffectivePossession
              )
            "
          >
            {{
              calculatePercentageItem(
                visitorQuickOverviewModel?.counterGoalGoals,
                visitorQuickOverviewModel?.counterGoalEffectivePossession
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.counterGoalGoals,
                visitorQuickOverviewModel?.counterGoalEffectivePossession
              ) + '%'
            "
          ></span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Superiority %" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value home" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        <div class="numbers">
          <span>
            {{ homeQuickOverviewModel?.superiorityGoals | number: "1.0-1" }} /
            {{
              homeQuickOverviewModel?.superiorityPossessions | number: "1.0-1"
            }}
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                homeQuickOverviewModel?.superiorityGoals,
                homeQuickOverviewModel?.superiorityPossessions
              ) >
              calculatePercentageItem(
                visitorQuickOverviewModel?.superiorityGoals,
                visitorQuickOverviewModel?.superiorityPossessions
              )
            "
          >
            {{
              calculatePercentageItem(
                homeQuickOverviewModel?.superiorityGoals,
                homeQuickOverviewModel?.superiorityPossessions
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                homeQuickOverviewModel?.superiorityGoals,
                homeQuickOverviewModel?.superiorityPossessions
              ) + '%'
            "
          ></span>
        </div>
      </td>
      <td class="value visitor" *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'" [ngClass]="{ 'single-row': (selectedTeam$ | async) === 'Visitor' }">
        <div class="numbers">
          <span>
            {{ visitorQuickOverviewModel?.superiorityGoals | number: "1.0-1" }}
            /
            {{
              visitorQuickOverviewModel?.superiorityPossessions
                | number: "1.0-1"
            }}
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.superiorityGoals,
                visitorQuickOverviewModel?.superiorityPossessions
              ) >
              calculatePercentageItem(
                homeQuickOverviewModel?.superiorityGoals,
                homeQuickOverviewModel?.superiorityPossessions
              )
            "
          >
            {{
              calculatePercentageItem(
                visitorQuickOverviewModel?.superiorityGoals,
                visitorQuickOverviewModel?.superiorityPossessions
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.superiorityGoals,
                visitorQuickOverviewModel?.superiorityPossessions
              ) + '%'
            "
          ></span>
        </div>
      </td>
    </tr>
    <tr>
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "Equality %" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value home" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        <div class="numbers">
          <span>
            {{ homeQuickOverviewModel?.equalityGoals | number: "1.0-1" }} /
            {{ homeQuickOverviewModel?.equalityPossessions | number: "1.0-1" }}
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                homeQuickOverviewModel?.equalityGoals,
                homeQuickOverviewModel?.equalityPossessions
              ) >
              calculatePercentageItem(
                visitorQuickOverviewModel?.equalityGoals,
                visitorQuickOverviewModel?.equalityPossessions
              )
            "
          >
            {{
              calculatePercentageItem(
                homeQuickOverviewModel?.equalityGoals,
                homeQuickOverviewModel?.equalityPossessions
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                homeQuickOverviewModel?.equalityGoals,
                homeQuickOverviewModel?.equalityPossessions
              ) + '%'
            "
          ></span>
        </div>
      </td>
      <td class="value visitor" *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'" [ngClass]="{ 'single-row': (selectedTeam$ | async) === 'Visitor' }">
        <div class="numbers">
          <span>
            {{ visitorQuickOverviewModel?.equalityGoals | number: "1.0-1" }} /
            {{
              visitorQuickOverviewModel?.equalityPossessions | number: "1.0-1"
            }}
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.equalityGoals,
                visitorQuickOverviewModel?.equalityPossessions
              ) >
              calculatePercentageItem(
                homeQuickOverviewModel?.equalityGoals,
                homeQuickOverviewModel?.equalityPossessions
              )
            "
          >
            {{
              calculatePercentageItem(
                visitorQuickOverviewModel?.equalityGoals,
                visitorQuickOverviewModel?.equalityPossessions
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                visitorQuickOverviewModel?.equalityGoals,
                visitorQuickOverviewModel?.equalityPossessions
              ) + '%'
            "
          ></span>
        </div>
      </td>
    </tr>
    <tr class="">
      <td
        class="label"
        [ngClass]="{ 'single-label': showOneTeam, label: !showOneTeam }"
      >
        {{ "7 Meters Goals %" | translate }}
      </td>
      <td *ngIf="(selectedTeam$ | async) !== 'Visitor'" class="value home" [ngClass]="{ 'single-row': showOneTeam || (selectedTeam$ | async) === 'Home' }">
        <div class="numbers">
          <span>
            {{ home7MetersGoals | number: "1.0-1" }} /
            {{ home7MetersGoals + home7MetersFailedShoots | number: "1.0-1" }}
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                home7MetersGoals,
                home7MetersGoals + home7MetersFailedShoots
              ) >
              calculatePercentageItem(
                visitor7MetersGoals,
                visitor7MetersGoals + visitor7MetersFailedShots
              )
            "
          >
            {{
              calculatePercentageItem(
                home7MetersGoals,
                home7MetersGoals + home7MetersFailedShoots
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                home7MetersGoals,
                home7MetersGoals + home7MetersFailedShoots
              ) + '%'
            "
          ></span>
        </div>
      </td>
      <td class="value visitor" *ngIf="!showOneTeam && (selectedTeam$ | async) !== 'Home'" [ngClass]="{ 'single-row': (selectedTeam$ | async) === 'Visitor' }">
        <div class="numbers">
          <span>
            {{ visitor7MetersGoals | number: "1.0-1" }} /
            {{
              visitor7MetersGoals + visitor7MetersFailedShots | number: "1.0-1"
            }}
          </span>
          <span
            class="percent"
            [class.bold]="
              calculatePercentageItem(
                visitor7MetersGoals,
                visitor7MetersGoals + visitor7MetersFailedShots
              ) >
              calculatePercentageItem(
                home7MetersGoals,
                home7MetersGoals + home7MetersFailedShoots
              )
            "
          >
            {{
              calculatePercentageItem(
                visitor7MetersGoals,
                visitor7MetersGoals + visitor7MetersFailedShots
              ) | number: "1.0-1"
            }}
            %
          </span>
        </div>

        <div class="meter">
          <span
            [style.width]="
              calculatePercentageItem(
                visitor7MetersGoals,
                visitor7MetersGoals + visitor7MetersFailedShots
              ) + '%'
            "
          ></span>
        </div>
      </td>
    </tr>
  </table>
</div>
