import { GameFilterTableModel } from 'src/app/shared-services/game-data/model/game-filter-table.model';
import { GameEndStorageModel } from 'src/app/shared-services/storage-service/model/game-end-storage.model';
import { CoreService } from 'src/app/shared-services/core.service';
import { LoadingController, ModalController } from '@ionic/angular';
import { PdfReportComponent } from 'src/app/main/pages/aehandler-module/pages/game-module/components/pdf-report/pdf-report.component';
import { transformPlayByPlayToServerModel } from 'src/app/shared-services/game-data/game-data.helper';

export const openPdfHelper = async (
    core: CoreService,
    loadingCtrl: LoadingController,
    modalCtrl: ModalController,
): Promise<void> => {
    const game: GameFilterTableModel = {
        id: core.gameService.gameId,
        home: core.gameService.gameModel.home.name,
        homeId: core.gameService.gameModel.home.id,
        visitor: core.gameService.gameModel.visitor.name,
        visitorId: core.gameService.gameModel.visitor.id,
        folderId: null,
        date: (new Date().toDateString()),
        accessHash: '',
        result: `${core.gameService.gameModel.scoreHome}:${core.gameService.gameModel.scoreVisitor}`,
        gameType: core.gameService.gameMode$.value,
        gameMode: core.gameService.liveMode,
        gameStatus: 'started',
        videoStatus: 'absent',
    };

    const endGameStorageModel = {
        gameId: core.gameService.gameId,
        updateGameResultDto: {
            firstHalfEnded: true,
            secondHalfEnded: false,
            gameEnded: false,
            goalsHome: core.gameService.gameModel.scoreHome,
            goalsVisitor: core.gameService.gameModel.scoreVisitor,
            playerStatsDto: core.goalConsumerService.transformToPlayerStatisticsDto(
                core.teamOverviewSubConsumerService.getPlayerStatisticsDto()
            ),
            teamStatsDto: core.goalConsumerService.transformToTeamStatisticsDto(
                core.overviewConsumerService.generateTeamStatsDto()
            ),
            lineupStatsDto: core.goalConsumerService.transformToLineupStatisticsDto(),
            playByPlayDto: transformPlayByPlayToServerModel(core.playByPlayProducerService.pbpRecords),
            playTimeDto: [],
            gameDateTime: core.gameService.gameModel.startDate.toISO(),
        }
    } as GameEndStorageModel;
    loadingCtrl.create({message: core.trans.instant('Generating PDF. Please wait')}).then((loading) => {
        loading.present();
        modalCtrl
            .create({
                component: PdfReportComponent,
                componentProps: {
                    localGame: {
                        date: game.date,
                        homeId: game.homeId,
                        visitorId: game.visitorId,
                        homeName: game.home,
                        visitorName: game.visitor,
                        gameType: game.gameType,
                        endGameStorageModel: endGameStorageModel,
                    },
                },
                cssClass: 'modal-invisible',
            })
            .then((m) => {
                m.onDidDismiss().then(() => loading.dismiss());
                m.present();
            });
    });
};
