import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AdminService, GameFilterDto, ManageAccountDto, UpdateUserDto } from 'src/app/api/hai-api';
import { CoreService } from '../core.service';
import { LoadingService } from '../loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  private _users$: BehaviorSubject<UpdateUserDto[]> = new BehaviorSubject<UpdateUserDto[]>([]);
  private _accounts$: BehaviorSubject<ManageAccountDto[]> = new BehaviorSubject<ManageAccountDto[]>([]);
  private _allAccounts$: BehaviorSubject<ManageAccountDto[]> = new BehaviorSubject<ManageAccountDto[]>([]);
  private _games$: BehaviorSubject<GameFilterDto[]> = new BehaviorSubject<GameFilterDto[]>([]);
  private _allGames$: BehaviorSubject<GameFilterDto[]> = new BehaviorSubject<GameFilterDto[]>([]);
  private _allAccountsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _allGamesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _allUsersCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private _usersLastPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _accountsLastPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _gamesLastPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly adminService: AdminService,
    private readonly logger: NGXLogger,
    private readonly loadingService: LoadingService
    ) { }

  get users$(): BehaviorSubject<UpdateUserDto[]> {
    return this._users$;
  }
  get accounts$(): BehaviorSubject<ManageAccountDto[]> {
    return this._accounts$;
  }
  get games$(): BehaviorSubject<GameFilterDto[]> {
    return this._games$;
  }
  get allGames$(): BehaviorSubject<GameFilterDto[]> {
    return this._allGames$;
  }

  get usersLastPage$(): BehaviorSubject<boolean> {
    return this._usersLastPage$;
  }
  get accountsLastPage$(): BehaviorSubject<boolean> {
    return this._accountsLastPage$;
  }
  get gamesLastPage$(): BehaviorSubject<boolean> {
    return this._gamesLastPage$;
  }

  get allAccountsCount$(): BehaviorSubject<number> {
    return this._allAccountsCount$;
  }

  get allGamesCount$(): BehaviorSubject<number> {
    return this._allGamesCount$;
  }

  get allUsersCount$(): BehaviorSubject<number> {
    return this._allUsersCount$;
  }

  get allAccounts$(): BehaviorSubject<ManageAccountDto[]> {
    return this._allAccounts$;
  }

  public async fetchUsers(page: number, pageSize: number, filter?: string) {
    await this.loadingService.present();
    const skip = page == 0 ? 0 : (page * pageSize);
    this.logger.info("AdminUserService: fetchUsers called with page: " + page + " and pageSize: " + pageSize + 1);
    this.adminService.adminControllerFindUsers(skip, pageSize, filter, 'response').subscribe({
      next: async (res) => {
        const value = res.body;
        this._allAccountsCount$.next(Number(res.headers.get('Total-Count')));
        this._users$.next(value);
        this.logger.info("AdminUserService: fetchUsers retrieved " + value.length + " records.");

        this._usersLastPage$.next(value.length < pageSize);
        await this.loadingService.dismiss();
      },
      error: async (e) => {
        this.logger.error(e);
        await this.loadingService.dismiss();
      }
    });
  }

  public async fetchAccounts(page: number, pageSize: number, filter?: string) {
    const skip = page == 0 ? 0 : (page * pageSize);
    await this.loadingService.present();
    this.logger.info("AdminUserService: fetchAccounts called with page: " + page + " and pageSize: " + pageSize + 1);
    this.adminService.adminControllerFindAccounts(skip, pageSize, filter, 'response').subscribe({
      next: async (res) => {
        const value = res.body;
        this._allAccountsCount$.next(Number(res.headers.get('Total-Count')));
        this._accounts$.next(value);
        this.logger.info("AdminUserService: fetchAccounts retrieved " + value.length + " records.");
        this._accountsLastPage$.next(value.length < pageSize);
        await this.loadingService.dismiss();
      },
      error: async (e) => {
        this.logger.error(e);
        await this.loadingService.dismiss();
      }
    });
  }

  public async getAllGames() {
    await this.loadingService.present();
    this.adminService.adminControllerFindGames(0, 15000).subscribe(async res => {
      this._allGames$.next(res);
      await this.loadingService.dismiss();
    }, error => this.logger.error(error));
  }

  public async fetchGames(page: number, pageSize: number, filter?: string, dateFrom?: string, dateTo?: string) {
    await this.loadingService.present();
    const skip = page == 0 ? 0 : (page * pageSize);
    this.logger.info("AdminUserService: fetchGames called with page: " + page + " and pageSize: " + pageSize + 1);
    this.adminService.adminControllerFindGames(skip, pageSize, filter, dateFrom, dateTo, 'response').subscribe({
      next: async (res) => {
        const value = res.body;
        this._allGamesCount$.next(Number(res.headers.get('Total-Count')));
        this._allGames$.next(value);
        this.logger.info("AdminUserService: fetchGames retrieved " + value.length + " records.");

        this.gamesLastPage$.next(value.length < pageSize);
        await this.loadingService.dismiss();
      },
      error: async (e) => {
        this.logger.error(e);
        await this.loadingService.dismiss();
      }
    });
  }
}
