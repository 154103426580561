import { Injectable } from '@angular/core';
import { CoreService } from 'src/app/shared-services/core.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class TimerWrapperService {

  private _core: CoreService;
  private _isRealTimeMode = true;
  private _currentSecondsOfGame = 0;

  constructor(
      private readonly logger: NGXLogger,
  ) { }

  public initCore(core: CoreService) {
    this._core = core;
  }

  public initTimeWrapperService(value: boolean) {
    this._currentSecondsOfGame = 0;
    this._isRealTimeMode = value;
  }

  public setCurrentSeconds(currentSeconds: number): void {
    this._currentSecondsOfGame = currentSeconds;
  }

  public currentGameSeconds(): number {
    this.logger.debug(
        'TimerWrapperService.currentGameSeconds realtime mode',
        this._isRealTimeMode,
        this._core.handballTimerService.gameCounter$.value.counterSecSinceStart,
        this._currentSecondsOfGame
    );
    if (this._isRealTimeMode) {
      return this._core.handballTimerService.gameCounter$.value.counterSecSinceStart;
    } else {
      return this._currentSecondsOfGame;
    }
  }
}
